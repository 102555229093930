import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription}  from 'rxjs';
import {ComboBoxComponent}            from '@progress/kendo-angular-dropdowns';
import {ObjectFactory}                from 'ts-tooling';
import {CampaignYearStore}            from '../../stores/login/campaignyear.store';
import {LanguageStore}                from '../../stores/translation/language.store';
import {ApDateService}                from '../../ap-core/services/ap-date-service';
import {CampaignYearService}          from '../../services/data/campaign-year.service';
import ICampaignYear = Data.Authentication.ICampaignYear;
import {filter}                       from 'rxjs/operators';

@Component({
  selector: 'ap-harvest-selection',
  templateUrl: 'ap-harvest-selection.component.html',
  styleUrls: ['ap-harvest-selection.component.scss']
})

export class ApHarvestSelectionComponent implements OnInit, OnDestroy {
  public harvestYearFilter: ICampaignYear[];
  public selectedYear: ICampaignYear;
  public tooltip: string;

  private _harvestYears: ICampaignYear[];
  private _subscriptions: Subscription[] = [];

  constructor(private campaignYearStore: CampaignYearStore,
              private languageStore: LanguageStore,
              private dateService: ApDateService,
              private campaignYearService: CampaignYearService) {
  }

  public ngOnInit(): void {
    this._subscriptions.push(this.campaignYearStore.Listen(s => s.data).subscribe(years => {
      this._harvestYears = years;
      this.harvestYearFilter = this._harvestYears;
    }));

    this._subscriptions.push(combineLatest([
      this.campaignYearStore.Listen(s => s.selectedYear),
      this.languageStore.SelectedLanguage$
    ]).pipe(
      filter(([selectedYear, selectedLanguage]) =>
        selectedYear != null && selectedLanguage != null
    )).subscribe(([year]) => {
      this._setSelectedCampaignYear(year);
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(d => d.unsubscribe());
  }

  public onHarvestYearClick(select: ICampaignYear): void {
    if (select) {
      if (!ObjectFactory.Equal(this.campaignYearStore.getSelectedCampaignYear(), select)) {
        this.campaignYearStore.selectCampaignYear(select);
        this.harvestYearFilter = this._harvestYears;
      } else {
        this._setSelectedCampaignYear(this.campaignYearStore.getSelectedCampaignYear());
      }
    }
  }

  public filterChange($event: string): void {
    this.harvestYearFilter = this._harvestYears.filter(f => f.Year.toString().toLowerCase().indexOf($event.toLowerCase()) !== -1);
  }

  public blur(comboBox: ComboBoxComponent): void {
    comboBox.writeValue(this.selectedYear);
  }

  public focus(): void {
    this.harvestYearFilter = this._harvestYears;
  }

  private _setSelectedCampaignYear(campaignYear: ICampaignYear): void {
    this.selectedYear = campaignYear;
    this.tooltip = this._generateTooltip(campaignYear?.Year);
  }

  private _generateTooltip(year: number): string {
    const campaignYearRange = this.campaignYearService.getCampaignYearRange(year);
    const currentFormat = this.languageStore.CurrentDateFormat;
    const startDate = this.dateService.dateToMoment(campaignYearRange.StartYear).format(currentFormat);
    const endDate = this.dateService.dateToMoment(campaignYearRange.EndYear).format(currentFormat);
    return `${startDate} - ${endDate}`;
  }
}

import {IStateStoreWritable} from '../../ap-interface/interfaces/store';
import {
  Store
}                            from '../index';
import {
  Injectable
}                            from '@angular/core';
import {
  ApCustomTypes,
  CropGroupsDelete,
  CropGroupsLoad,
  CropGroupsLoadSuccess,
  CropGroupsSave
}                            from 'invoker-transport';
import {
  CropGroupsSaveSuccess
}                            from '../../../../projects/invoker-transport/src/lib/actions/base-data';
import ICropGroups = Data.BaseData.ICropGroups;
import IFarmCropGroup = Data.BaseData.IFarmCropGroup;
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface ICropGroupStore extends IStateStoreWritable<ICropGroups> {
}

@Injectable({providedIn: 'root'})
export class CropGroupStore extends Store<ICropGroupStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      saved: false,
      saving: false
    });

    backend.registerObservable(CropGroupsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(CropGroupsSaveSuccess).subscribe(d => {
      super.SetSaveSuccessState();
    });
  }

  getCropGroups(): ICropGroups[] {
    return super.Listen(s => s.data).getValue();
  }

  getNoCropColor(): string {
    const noCropGroup = this.getCropGroups().find(cg => cg.Key === 'Global__CropGroup_Nothing');
    if (noCropGroup?.FarmColor && noCropGroup?.FarmColor?.length > 0) {
      return noCropGroup.FarmColor;
    } else if (noCropGroup?.Colordefault && noCropGroup?.Colordefault?.length > 0) {
      return noCropGroup.Colordefault;
    } else {
      return 'rgba(216, 216, 216, 1)';
    }
  }

  getGroupColorByCropGroupName(key: string): string {
    if (!key) {
      return 'rgba(255, 255, 255, 0)';
    }
    const group: ICropGroups = super.Listen(s => s.data).getValue().Find(c => c?.Key === key);
    return group.Colordefault;
  }

  /**
   *  load croptypes from Backend to the Store
   */
  public loadCropGroups(): void {
    this.DispatchBackend(new CropGroupsLoad([]));
  }

  /**
   *  saved croptypes to the database
   */
  public saveFarmCropGroups(cropGroups: ICropGroups): void {
    super.SetSaveState();
    this.DispatchBackend(new CropGroupsSave([
      {Name: 'cropGroup', Type: ApCustomTypes.Data_BaseData_CropGroups, Value: cropGroups},
    ]));
  }

  /**
   * delete croptypes to the database
   */
  public deleteFarmCropGroups(cropGroups: ICropGroups): void {
    super.SetSaveState();
    this.DispatchBackend(new CropGroupsDelete([
      {Name: 'cropGroup', Type: ApCustomTypes.Data_BaseData_CropGroups, Value: cropGroups},
    ]));
  }

  /**
   * override the Update with a custom Update Strategy
   */
  UpdateSource(operation: DatabaseNotifyOperation, item: any, model: string): void {
    if (operation === DatabaseNotifyOperation.Delete) {
      this.loadCropGroups();
      return;
    }
    if (operation === DatabaseNotifyOperation.Update || operation === DatabaseNotifyOperation.Insert) {
      super.Mutate(s => s.data, o => {
        if (!o) {
          super.SetLoadFinishState();
          return o;
        }
        const backendItem = item as ICropGroups;
        if (!backendItem) {
          // fallback case reload the Source
          this.loadCropGroups();
          return o;
        }
        const position = o.FindIndex(i => i.Id === backendItem.Id);
        const oldItem = o[position];
        o[position] = {...oldItem, FarmColor: backendItem.FarmColor};
        super.SetLoadFinishState();
        return o;
      });
      return;
    }
  }

  SetColor(id: number, color: string): void {
    super.Mutate(s => s.data, o => {
      const found = o.Find(cg => cg?.Id === id);
      if (found) {
        found.FarmColor = color;
        found.FarmCropGroup[0].Colorcode = color;
      }
      return o;
    });
  }

  SetColorAndGroup(id: number, color: string, groupId: number): void {
    super.Mutate(s => s.data, o => {
      const found = o.Find(cg => cg?.Id === id);
      if (found) {
        found.FarmColor = color;
        found.FarmCropGroup.Add({
          Colorcode: color,
          Cropgroupid: groupId,
        } as IFarmCropGroup);
      }
      return o;
    });
  }
}

import {BrowserModule}                                                from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {ApLayoutComponent}                                            from './layout/components/ap-layout.component';
import {BrowserAnimationsModule}                     from '@angular/platform-browser/animations';
import {BlockCopyDirective}                          from './ap-core/directives/block-copy.directive';
import {ApErrorHandler}                              from './ap-core/error-handling/ap-errorhandler';
import {ApLayoutModule}                              from './layout/ap-layout.module';
import {RoutesModule}                                from './routes.module';
import {ApTranslationPipe}                           from './translation/pipes/ap-translation.pipe';
import {MessageService}                              from '@progress/kendo-angular-l10n';
import {ApKendoMessageService}                       from './translation/ap-kendomessage.service';
import {
  ApSettingsEntryComponentsModule
}                                                    from './entry-components/ap-settings-entry-components/ap-settings-entry-components.module';
import {
  ApFieldManagementEntryComponentsModule
}                                                    from './entry-components/ap-field-management-entry-components/ap-field-management-entry-components.module';
import {
  ApBasedataEntryComponentsModule
}                                                    from './entry-components/ap-basedata-entry-components/ap-basedata-entry-components.module';
import {
  ApAdministrationEntryComponentsModule
}                                                    from './entry-components/ap-administration-entry-components/ap-administration-entry-components.module';
import {
  ApDocuEntryComponentsModule
}                                                    from './entry-components/ap-docu-entry-components/ap-docu-entry-components.module';
import {ApPermissionGuard}                           from './guards/ap-permission-guard.service';
import {ApDeactivateGuard}                           from './guards/ap-deactivate.guard';
import {PopupModule}                                 from '@progress/kendo-angular-popup';
import {NgScrollbarModule}                           from 'ngx-scrollbar';
import {AgriportStoreModule}                         from './stores/stores.module';
import {DatabaseNotifyStore}                         from './stores/database/notify.store';
import {GaugesModule}                                from '@progress/kendo-angular-gauges';
import {
  ApParameterEntryComponentsModule
}                                                    from './entry-components/ap-parameter-entry-components/ap-parameter-entry-components.module';
import {ServiceInjector}                             from './ap-utils/injector';
import {
  BurgerMenuEntryComponentsModule
}                                                    from './entry-components/burger-menu-entry-components/burger-menu-entry-components.module';
import {TreeViewModule}                              from '@progress/kendo-angular-treeview';
import {
  ColorLegendEntryComponentsModule
}                                                    from './entry-components/color-legend-entry-components/color-legend-entry-components.module';
import {
  ApDynamicComponentLoaderModule
}                                                    from './entry-components/ap-dynamic-component-loader/ap-dynamic-component-loader.module';
import {TooltipModule}                               from '@progress/kendo-angular-tooltip';
import {
  ApNutrientsManagementEntryComponentsModule
}                                                    from './entry-components/ap-nutrients-management-entry-components/ap-nutrients-management-entry-components.module';
import {NotificationModule}                          from '@progress/kendo-angular-notification';
import {
  ApNFertilizationEntryComponentsModule
}                                                    from './entry-components/ap-n-fertilization-entry-components/ap-n-fertilization-entry-components.module';
import {ApDeveloperGuard}                            from './guards/ap-developer-guard';
import {Config}                                      from 'invoker-transport';
import {APP_CONFIGURATION}                           from './ap-core/config';
import {EditorModule}                                from '@progress/kendo-angular-editor';
import {ApInvitationsGuard}                          from './guards/ap-invitations-guard';
import {
  PpEntryComponentsModule
}                         from './entry-components/plant-protection-entry-components/pp-entry-components.module';
import {ApSignalrService} from './ap-core/services/ap-signalr.service';

// export function initializeSignalr(signalrService: ApSignalrService): () => Promise<void> {
//   return () => signalrService.initializeConnection();
// }

@NgModule({
  declarations: [BlockCopyDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AgriportStoreModule,
    ApFieldManagementEntryComponentsModule, // load Entry Components for FieldManagement
    ApBasedataEntryComponentsModule, // load Entry Components for BaseData
    ApDocuEntryComponentsModule, // load Entry Components for Docu
    ApSettingsEntryComponentsModule, // load Entry Components for Settings
    ApAdministrationEntryComponentsModule, // load Entry Components for Administration
    ApNutrientsManagementEntryComponentsModule, // load Entry Components for Nutrients
    ApNFertilizationEntryComponentsModule, // load Entry Components for Nutrients
    PpEntryComponentsModule, // load entry components for plant protection
    ApParameterEntryComponentsModule,
    ApLayoutModule,
    BurgerMenuEntryComponentsModule,
    ColorLegendEntryComponentsModule,
    RoutesModule, // Application Routes for Lazy Loading Modules
    PopupModule,
    NgScrollbarModule,
    GaugesModule,
    TreeViewModule,
    ApDynamicComponentLoaderModule,
    TooltipModule,
    NotificationModule,
    EditorModule
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeSignalr,
    //   deps: [ApSignalrService],
    //   multi: false
    // },
    {
      provide: ErrorHandler,
      useClass: ApErrorHandler
    },
    {
      provide: MessageService,
      useClass: ApKendoMessageService
    },
    {
      provide: LOCALE_ID,
      useValue: 'en'
    },
    ApTranslationPipe,
    ApPermissionGuard,
    ApDeactivateGuard,
    ApInvitationsGuard,
    ApDeveloperGuard
  ],
  bootstrap: [
    ApLayoutComponent
  ]
})
export class AppModule {
  // import the DatabaseNotifyStore to connect when Agriport was loaded
  // this Service was not used on components it runs in the Background
  constructor(private databaseNotifyStore: DatabaseNotifyStore,
              private injector: Injector) {
    // register the Angular Injector to inject Angular Services in Typescript Classes
    // outside of Angular
    ServiceInjector.instance = injector;
    Config.BackendAddress = APP_CONFIGURATION.BackendAddress;
  }
}

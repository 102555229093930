import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import {SelectableSettings}                         from '@progress/kendo-angular-grid';
import {Observable, of}                             from 'rxjs';
import {
  INFertilizerNNeedGridItem
}                                                   from '../../../../n-fertilization/types/ap-n-fertilization-n-need-types';
import {INFertilizationNNeedStatsSumValuesData}     from './n-fertilization-n-need-stats-types';
import {
  ApDynGridPropertyColumnConfig
}                                                   from '../../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {ApTranslationService}                       from '../../../../ap-utils/service/ap-translation.service';
import {GetRoundNumericPipe}                        from '../../../../ap-utils';
import {
  ApDynGridGroupColumnConfig
}                                                   from '../../../../ap-dyngrids/config/ap-dyn-grid-group-column-config';
import {IBarLineAreaChartData}                      from '../../../../ap-interface';
import {IChartSet}                                  from '../../../../stores/statistic/statistic.store';
import {
  ApDynGridColumnConfigBase
}                                                   from '../../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {GetRoundNumericService}                     from '../../../../ap-utils/service/get-round-numeric.service';

@Component({
  selector: 'ap-n-fertilization-n-need-stats',
  templateUrl: 'n-fertilization-n-need-stats.component.html',
  styleUrls: ['n-fertilization-n-need-stats.component.scss']
})
export class ApNFertilizationNNeedStatsComponent implements OnInit, AfterContentInit {
  @Input() public data: INFertilizerNNeedGridItem[];
  @Input() public title: string;
  @Input() public emptyMessage = '';

  public sumValuesStatsColumns: ApDynGridColumnConfigBase[] = [];
  public sumValuesStatsItems$: Observable<INFertilizationNNeedStatsSumValuesData[]>;
  public barsData: IChartSet<IBarLineAreaChartData>[] = [];
  public gridSelectableSettings: SelectableSettings = {
    enabled: false,
    checkboxOnly: false,
    mode: 'single',
    cell: false,
    drag: false
  };

  private _zeroValuesToHandleOnChart: string[] = [];

  constructor(private translationService: ApTranslationService,
              private roundService: GetRoundNumericService,
              private roundNumericPipe: GetRoundNumericPipe) {
  }

  public ngOnInit(): void {
    this.sumValuesStatsColumns = this._getSumValuesStatsColumns();
  }

  public ngAfterContentInit(): void {
    const data = this._getTotalData();
    if (data && data.length > 0) {
      this.sumValuesStatsItems$ = of(data);
      const barsData = this._getTotalSumBarsData(data);
      this.barsData = [barsData];
    }
  }

  private _getSumValuesStatsColumns(): ApDynGridColumnConfigBase[] {
    return [
      new ApDynGridPropertyColumnConfig({
        title: 'Global__Crop',
        field: 'CropType',
        headerStyle: {'text-align': 'left'},
        style: {'font-weight': 600, 'background-color': 'silver'},
        cssClass: 'left',
        width: 100,
        filterable: false,
        sortable: false
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Base__Fields',
        field: 'FieldsSum',
        cssClass: 'right',
        headerStyle: {'text-align': 'center'},
        width: 60,
        filterable: false,
        sortable: false,
        pipes: [{
          pipe: this.roundNumericPipe,
          args: [0]
        }]
      }),
      new ApDynGridPropertyColumnConfig({
        title: `${this.translationService.translate('Global__Sum')} ${this.translationService.translate('Docu_Ini__Area')}`,
        field: 'AreaSum',
        cssClass: 'right',
        headerStyle: {'text-align': 'center'},
        width: 60,
        filterable: false,
        sortable: false,
        pipes: [{
          pipe: this.roundNumericPipe,
          args: [0]
        }]
      }),
      new ApDynGridGroupColumnConfig({
        title: `${this.translationService.translate('Global__Sum')} kg N`,
        groupColumns: [
          new ApDynGridPropertyColumnConfig({
            title: 'Settings__Label_Detraction',
            field: 'RemovalSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Nutrients__already_applied',
            field: 'AlreadyAppliedSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: `Global__Balance`,
            field: 'BalanceSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Nutrients__Need_FNV',
            field: 'MaxAllowanceSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Nutrients__Remaining',
            field: 'RemainingSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          })
        ]
      })
    ];
  }

  private _getTotalData(): INFertilizationNNeedStatsSumValuesData[] {
    return this.data?.reduce((gridData, currentItem, currentIndex, array) => {
      if (currentItem.MainCropType) {
        const existingGroup = gridData.find(item => item.CropType === currentItem.MainCropType);
        const removalSum = currentItem.Removal ? currentItem.Removal * currentItem.Area : 0;
        const alreadyAppliedSum = currentItem.AlreadyApplied ? currentItem.AlreadyApplied * currentItem.Area : 0;
        const balanceSum = currentItem.Balance ? currentItem.Balance * currentItem.Area : 0;
        const maxAllowanceSum = currentItem.MaxAllowance ? currentItem.MaxAllowance * currentItem.Area : 0;
        const remainingSum = currentItem.Remaining ? currentItem.Remaining * currentItem.Area : 0;
        if (existingGroup) {
          existingGroup.FieldsSum += 1;
          existingGroup.AreaSum += currentItem.Area;
          existingGroup.RemovalSum += removalSum;
          existingGroup.AlreadyAppliedSum += alreadyAppliedSum;
          existingGroup.BalanceSum += balanceSum;
          existingGroup.MaxAllowanceSum += maxAllowanceSum;
          existingGroup.RemainingSum += remainingSum;
        } else {
          gridData.push({
            CropType: currentItem.MainCropType,
            FieldsSum: 1,
            AreaSum: currentItem.Area,
            RemovalSum: removalSum,
            AlreadyAppliedSum: alreadyAppliedSum,
            BalanceSum: balanceSum,
            MaxAllowanceSum: maxAllowanceSum,
            RemainingSum: remainingSum
          });
        }
      }
      // Add total sum for each value
      if (currentIndex === array.length - 1) {
        gridData.push({
          CropType: '',
          FieldsSum: gridData.reduce((total, item) => total + item.FieldsSum, 0),
          AreaSum: gridData.reduce((total, item) => total + item.AreaSum, 0),
          RemovalSum: gridData.reduce((total, item) => total + item.RemovalSum, 0),
          AlreadyAppliedSum: gridData.reduce((total, item) => total + item.AlreadyAppliedSum, 0),
          BalanceSum: gridData.reduce((total, item) => total + item.BalanceSum, 0),
          MaxAllowanceSum: gridData.reduce((total, item) => total + item.MaxAllowanceSum, 0),
          RemainingSum: gridData.reduce((total, item) => total + item.RemainingSum, 0)
        });
      }
      return gridData;
    }, [] as INFertilizationNNeedStatsSumValuesData[]) ?? [];
  }

  private _getTotalSumBarsData(data: INFertilizationNNeedStatsSumValuesData[]): IChartSet<IBarLineAreaChartData> | null {
    const lastItemWithTotal = data[data.length - 1];
    this._saveItemsNamesWithZeroValues(lastItemWithTotal);
    const minAndMaxValue = this._getMinAndMaxScalesFromObject(lastItemWithTotal);
    const alreadyAppliedName = this.translationService.translate('Nutrients__already_applied');
    const removalName = this.translationService.translate('Settings__Label_Detraction');
    const balanceName = this.translationService.translate('Global__Balance');
    const maxAllowanceName = this.translationService.translate('Nutrients__Need_FNV');
    const remainingName = this.translationService.translate('Nutrients__Remaining');
    return {
      axes: {
        titleX: `${this.translationService.translate('Global__Sum')} kg N`,
        minX: minAndMaxValue?.min,
        maxX: minAndMaxValue?.max
      },
      noteDelegate: (item, _) => {
        let amount = '0';
        if (!this._zeroValuesToHandleOnChart.some(x => x === item.note)) {
          amount = this.roundNumericPipe.transform(item.value, 0);
        }
        return `${item.note}\n${amount}`;
      },
      data: {
        data: [
          {
            category: balanceName,
            value: lastItemWithTotal.RemovalSum ? lastItemWithTotal.RemovalSum : -1,
            color: 'rgb(214, 112, 0)',
            note: removalName
          },
          {
            category: balanceName,
            value: lastItemWithTotal.AlreadyAppliedSum ? lastItemWithTotal.AlreadyAppliedSum : -1,
            color: 'rgb(38, 115, 0)',
            note: alreadyAppliedName
          },
          {
            category: balanceName,
            value: lastItemWithTotal.BalanceSum ? lastItemWithTotal.BalanceSum : -1,
            color: 'rgb(0, 130, 255)',
            note: balanceName
          },
          {
            category: maxAllowanceName,
            value: lastItemWithTotal.MaxAllowanceSum ? lastItemWithTotal.MaxAllowanceSum : -1,
            color: 'rgb(214, 112, 0)',
            note: maxAllowanceName
          },
          {
            category: maxAllowanceName,
            value: lastItemWithTotal.AlreadyAppliedSum ? lastItemWithTotal.AlreadyAppliedSum : -1,
            color: 'rgb(38, 115, 0)',
            note: alreadyAppliedName
          },
          {
            category: maxAllowanceName,
            value: lastItemWithTotal.RemainingSum ? lastItemWithTotal.RemainingSum : -1,
            color: 'rgb(0, 130, 255)',
            note: remainingName
          }
        ],
        categories: [
          balanceName,
          maxAllowanceName
        ]
      }
    } as IChartSet<IBarLineAreaChartData>;
  }

  private _getMinAndMaxScalesFromObject(obj: INFertilizationNNeedStatsSumValuesData): {
    min: number | null,
    max: number | null
  } | null {
    if (!obj) {
      return {min: -100, max: 100};
    }
    const numberValues = Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (typeof value === 'number') {
        acc.push(value);
      }
      return acc;
    }, [] as number[]);
    if (!numberValues || numberValues.length < 1) {
      return {min: -100, max: 100};
    }
    const maxValue = Math.max(...numberValues);
    const minAbsValue = Math.abs(Math.min(...numberValues));
    if (maxValue === 0 || minAbsValue === 0) {
      return {
        min: minAbsValue === 0 ? -maxValue : null,
        max: maxValue === 0 ? minAbsValue : null
      };
    }
    const roundedMinAbsValue = this.roundService.roundAsNumber(minAbsValue, 0);
    let roundedMaxValue = this.roundService.roundAsNumber(maxValue, 0);
    if (roundedMaxValue * 3 < roundedMinAbsValue) {
      roundedMaxValue = this.roundService.roundAsNumber(roundedMinAbsValue / 2, 0);
    }
    return {
      min: -(roundedMinAbsValue + this.roundService.roundAsNumber(roundedMinAbsValue * 10 / 100, 0)),
      max: roundedMaxValue + this.roundService.roundAsNumber(roundedMaxValue / 2, 0)
    };
  }

  private _saveItemsNamesWithZeroValues(sumValuesData: INFertilizationNNeedStatsSumValuesData): void {
    this._zeroValuesToHandleOnChart = [];
    if (sumValuesData.RemovalSum === 0) {
      this._zeroValuesToHandleOnChart.push(this.translationService.translate('Settings__Label_Detraction'));
    }
    if (sumValuesData.AlreadyAppliedSum === 0) {
      this._zeroValuesToHandleOnChart.push(this.translationService.translate('Nutrients__already_applied'));
    }
    if (sumValuesData.BalanceSum === 0) {
      this._zeroValuesToHandleOnChart.push(this.translationService.translate('Global__Balance'));
    }
    if (sumValuesData.MaxAllowanceSum === 0) {
      this._zeroValuesToHandleOnChart.push(this.translationService.translate('Nutrients__Need_FNV'));
    }
    if (sumValuesData.RemainingSum === 0) {
      this._zeroValuesToHandleOnChart.push(this.translationService.translate('Nutrients__Remaining'));
    }
  }
}

import {Workbook, WorkbookSheet, WorkbookSheetRow, WorkbookSheetRowCell} from '@progress/kendo-angular-excel-export';
import {
  ApDynGridPropertyColumnConfig,
  PropertyTemplate
}                                                                        from './config/ap-dyn-grid-property-column-config';
import {
  ApDynGridDetailsGridConfig
}                                                                        from './config/details/ap-dyn-grid-details-grid-config';
import {ApDynGridColumnConfigBase}                                       from './config/ap-dyn-grid-column-config-base';
import {ObjectFactory}                                                   from 'ts-tooling';
import {orderBy, SortDescriptor}                                         from '@progress/kendo-data-query';
import {saveAs}                                                          from '@progress/kendo-file-saver';
import {
  ApDynGridGroupColumnConfig
}                                                                        from './config/ap-dyn-grid-group-column-config';
import {firstValueFrom}                                                  from 'rxjs';
import {ExcelExportEvent}                                                from '@progress/kendo-angular-grid';
import {
  ApKendoGridExtension
}                                                                        from '../ap-core/extensions/kendo/ap-kendo-grid-extension';
import {
  ApTranslationService
}                                                                        from '../ap-utils/service/ap-translation.service';
import {
  ApDynGridDetailsBaseConfig
}                                                                        from './config/details/ap-dyn-grid-details-base-config';

/**
 * Service for Kendo-To-Excel Export handling Master-Detail Grids
 */
export class ApDyngridsExcelService {
  private readonly _agriconShowHiddenProperties: boolean;
  private readonly _gridPaging: ApKendoGridExtension<any>;
  private readonly _translationService: ApTranslationService;

  constructor(agriconShowHiddenProperties: boolean,
              gridPaging: ApKendoGridExtension<any>,
              translationService: ApTranslationService) {
    this._agriconShowHiddenProperties = agriconShowHiddenProperties;
    this._gridPaging = gridPaging;
    this._translationService = translationService;
  }

  /**
   * Performs export of given master columns and their details to Excel
   * @param eventArgs
   * @param columns
   * @param details
   * @param xlsExportFileName
   */
  public async exportAsExcel(eventArgs: ExcelExportEvent,
                             columns: ApDynGridColumnConfigBase[],
                             details: ApDynGridDetailsBaseConfig,
                             xlsExportFileName: string): Promise<void> {
    const workbook = eventArgs.workbook as Workbook;
    const workSheetMaster = workbook.sheets[0];
    const workBookRows = workbook.sheets[0]?.rows as WorkbookSheetRow[];
    const dataRows = this._gridPaging?.getSelectedDataForExcel()?.data;

    // update excel sheet name and document's data
    workSheetMaster.name = this._translationService.translate('Data');
    workbook.options = {
      creator: 'Agricon GmbH',
      date: new Date()
    };

    // replace boolean values for better reading in final excel
    this.replaceBooleanValues(columns, workSheetMaster);

    // Get the default header styles.
    // Aternatively set custom styles for the details
    // https://www.telerik.com/kendo-angular-ui/components/excelexport/api/WorkbookSheetRowCell/
    const headerOptions = workBookRows[0].cells[0];
    const detailsGridConfig = details as ApDynGridDetailsGridConfig;
    const detailsField = detailsGridConfig?.field;
    const detailColumns: ApDynGridColumnConfigBase[] = detailsGridConfig?.columns?.filter(_ => _.xlsExport === true);

    // in case the grid is shown in admin mode (Strg-Alt-Space) all properties of the model are going to be exported
    this.addHiddenColumnsForAdmins(dataRows, detailsField, detailColumns);

    // handle master-detail grid (if any)
    if (detailsField?.length > 0 && detailColumns?.length > 0) {
      const workSheetDetails = ObjectFactory.Copy(workSheetMaster);
      workSheetDetails.rows = [ObjectFactory.Copy(workSheetMaster.rows[0])];
      workSheetDetails.name = this._translationService.translate('Global__Details');
      workbook.sheets.push(workSheetDetails);
      await this.injectDetailHeaders(detailColumns, workSheetDetails, headerOptions);
      const detailsSort: SortDescriptor[] = this._gridPaging._getSort(detailColumns);
      for (let mainRowIdx = 0; mainRowIdx < dataRows.length; mainRowIdx++) {
        // add the detail data
        const detailsData = dataRows[mainRowIdx][detailsField] as any[];
        if (detailsData?.length <= 0) {
          workSheetDetails.rows.push(ObjectFactory.Copy(workBookRows[mainRowIdx + 1]));
          continue;
        }
        const detailsSorted = orderBy(detailsData, detailsSort);
        for (const detailDataObject of detailsSorted) {
          const rowWithDetails = ObjectFactory.Copy(workBookRows[mainRowIdx + 1]);
          this.injectDetailValues(detailColumns, rowWithDetails, detailDataObject);
          workSheetDetails.rows.push(rowWithDetails);
        }
      }
    }

    // download to client
    this.downloadXls(xlsExportFileName, workbook);
  }

  /**
   * Replaces actual boolean values which would appear as "WAHR" or "FALSCH" in Excel with
   * "X" (=true) or nothing for false
   * @param columns
   * @param workSheetMaster
   * @private
   */
  private replaceBooleanValues(columns: ApDynGridColumnConfigBase[], workSheetMaster: WorkbookSheet): void {
    for (let cIdx = 0; cIdx < columns.length; cIdx++) {
      const propertyColumnConfig = columns[cIdx] as ApDynGridPropertyColumnConfig;
      if (propertyColumnConfig?.checkbox === true) {
        for (let rIdx = 1; rIdx < workSheetMaster.rows.length; rIdx++) {
          if (workSheetMaster.rows[rIdx].cells[cIdx].value === true) {
            workSheetMaster.rows[rIdx].cells[cIdx].value = 'X';
          } else {
            workSheetMaster.rows[rIdx].cells[cIdx].value = '';
          }
        }
      }
    }
  }

  /**
   * Writes column header names for details
   * @param detailColumns
   * @param workSheetDetails
   * @param detailHeaderOptions
   * @param groupPrefix
   * @private
   */
  private async injectDetailHeaders(detailColumns: ApDynGridColumnConfigBase[], workSheetDetails: WorkbookSheet,
                                    detailHeaderOptions: WorkbookSheetRowCell, groupPrefix: string = ''): Promise<void> {
    const headerRow = workSheetDetails.rows[0];
    const detailColumnsExport = detailColumns.FindAll(_ => _.xlsExport === true);
    for (const detailColumn of detailColumnsExport) {
      const groupColumn = detailColumn as ApDynGridGroupColumnConfig;
      if (!!groupColumn && groupColumn?.groupColumns?.length > 0) {
        let groupColumnTitle = await firstValueFrom(groupColumn.title);
        if (groupColumnTitle?.length > 0) {
          groupColumnTitle = this._translationService.translate(groupColumnTitle);
        }
        await this.injectDetailHeaders((detailColumn as ApDynGridGroupColumnConfig)?.groupColumns,
          workSheetDetails, detailHeaderOptions, `${groupColumnTitle}: `);
        continue;
      }

      let columnTitle = (detailColumn as any)?.title;
      if (columnTitle?.length > 0) {
        columnTitle = this._translationService.translate(columnTitle);
      }
      if (!columnTitle) {
        columnTitle = '';
      }
      // Creating dedicated columns for the details
      // The column width is used from grid definition, except
      // for group columns => there we need autoWidth to fit the longer header
      const isAutoWidth = detailColumn.width <= 0 || groupPrefix?.length > 0;
      workSheetDetails.columns.push({
        autoWidth: isAutoWidth,
        index: workSheetDetails.columns.length,
        width: !isAutoWidth ? detailColumn.width : undefined
      });
      headerRow.cells.push(Object.assign({}, detailHeaderOptions, {value: `${groupPrefix}${columnTitle}`, wrap: true}));
    }
  }

  /**
   * Writes cell values from details into excel workbook
   * @param detailColumns
   * @param rowWithDetails
   * @param detailDataObject
   * @private
   */
  private injectDetailValues(detailColumns: ApDynGridColumnConfigBase[], rowWithDetails: WorkbookSheetRow, detailDataObject: any): void {
    for (const detailColumn of detailColumns.FindAll(_ => _.xlsExport === true)) {
      if ((detailColumn as ApDynGridGroupColumnConfig)?.groupColumns?.length > 0) {
        const groupColumn: ApDynGridGroupColumnConfig = detailColumn as ApDynGridGroupColumnConfig;
        this.injectDetailValues(groupColumn.groupColumns, rowWithDetails, detailDataObject);
        continue;
      }
      let fieldName = detailColumn.field;
      if ((detailColumn as ApDynGridPropertyColumnConfig)?.template === PropertyTemplate.NUMBER) {
        fieldName = fieldName?.Replace('Rounded', '');
      } else if ((detailColumn as ApDynGridPropertyColumnConfig)?.template === PropertyTemplate.DATE) {
        fieldName = fieldName?.Replace('Midnight', '');
      }
      rowWithDetails.cells.push({
        value: fieldName?.length > 0 ? ObjectFactory.Get(detailDataObject, fieldName) : ''
      });
    }
  }

  /**
   * Triggers Excel Workbook download to client
   * @param xlsExportFileName
   * @param workbook
   * @private
   */
  private downloadXls(xlsExportFileName: string, workbook: Workbook): void {
    const xlsFilename = this._agriconShowHiddenProperties ?
      `${new Date().toISOString().split('.')[0]}_${document?.body?.querySelector('.ap-main-view > div > *:not(router-outlet)')?.nodeName?.toLowerCase()}.xlsx`
      : xlsExportFileName;
    // create a Workbook and save the generated data URL
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      saveAs(dataUrl, xlsFilename);
    });
  }

  /**
   * For Admins: in case the grid is shown in admin mode (Strg-Alt-Space) all properties of the model are going to be exported
   * @param dataRows
   * @param detailsField
   * @param detailColumns
   * @private
   */
  private addHiddenColumnsForAdmins(dataRows: any[], detailsField: string, detailColumns: ApDynGridColumnConfigBase[]): void {
    if (this._agriconShowHiddenProperties) {
      const dataWithDetailsObject = dataRows.FirstOrDefault(_ => _[detailsField]?.length > 0);
      if (dataWithDetailsObject && dataWithDetailsObject[detailsField]?.length > 0) {
        const hiddenDetailsProperties = Object.getOwnPropertyNames(dataWithDetailsObject[detailsField][0]);
        for (const hiddenDetailsProperty of hiddenDetailsProperties) {
          if (detailColumns.Any(_ => _.field === hiddenDetailsProperty)) {
            continue;
          }
          detailColumns.push(new ApDynGridPropertyColumnConfig(
            {
              title: hiddenDetailsProperty,
              field: hiddenDetailsProperty
            }));
        }
      }
    }
  }
}

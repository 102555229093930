<ng-container *ngIf="data?.length <= 0">
  <div class="empty-message">
    <p>{{ emptyMessage | translate }}</p>
  </div>
</ng-container>
<ng-container *ngIf="data?.length > 0">
  <div class="ap-planning-wizard-overview-stats">
    <div class="ap-planning-wizard-overview-stats-table">
      <h3>{{ title | translate }}</h3>
      <div>
        <ap-dyngrids class="ap-statistic-grid ap-statistic-grid-bold-footer-row"
                     [sortable]="false"
                     [canCreate]="false"
                     [canSearch]="false"
                     [checkboxDisabled]="true"
                     [pageable]="false"
                     [scrollable]="'none'"
                     [columns]="statColumns"
                     [items]="itemsStat$ | async"
                     [groupable]="false"
                     [reorderable]="false"
                     [selectable]="gridSelectableSettings">
        </ap-dyngrids>
      </div>
    </div>
  </div>
</ng-container>

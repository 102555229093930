import {animate, state, style, transition, trigger} from '@angular/animations';
import {MapViewMode, SidebarMode}                   from '../ap-interface';

export const CHANGE_WIDTH = trigger('changeWidth', [
  state(SidebarMode.OPEN, style({
    'flex-basis': '233px',
    width: '233px',
  })),
  state(SidebarMode.HALF, style({
    'flex-basis': '38px',
    width: '38px',
  })),
  state(SidebarMode.CLOSE, style({
    'flex-basis': '0px',
    width: '0px',
  })),
  state(SidebarMode.MOBILE, style({
    'flex-basis': '0px',
    width: '0px',
  })),
  transition(`${SidebarMode.CLOSE} => ${SidebarMode.OPEN}`, animate(200)),
  transition(`${SidebarMode.MOBILE} => ${SidebarMode.OPEN}`, animate(200)),
  transition(`${SidebarMode.OPEN} => ${SidebarMode.HALF}`, animate(200)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.OPEN}`, animate(200)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.CLOSE}`, animate(200)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.MOBILE}`, animate(200)),
]);

export const CHANGE_WIDTH_MAP_VIEW = trigger('changeWidthMapView', [
  state(MapViewMode.MAXIMIZE, style({
    width: '95%',
  })),
  state(MapViewMode.NORMAL, style({
    width: '50%',
  })),
  state(MapViewMode.HIDE, style({
    width: '0%',
  })),
  transition(`${MapViewMode.MAXIMIZE} => ${MapViewMode.NORMAL}`, animate(200)),
  transition(`${MapViewMode.NORMAL} => ${MapViewMode.HIDE}`, animate(200)),
  transition(`${MapViewMode.HIDE} => ${MapViewMode.NORMAL}`, animate(200)),
  transition(`${MapViewMode.NORMAL} => ${MapViewMode.MAXIMIZE}`, animate(200)),
]);

export const CHANGE_WIDTH_MAIN_VIEW = trigger('changeWidthMainView', [
  state(MapViewMode.MAXIMIZE, style({
    width: '5%',
  })),
  state(MapViewMode.NORMAL, style({
    width: '50%',
  })),
  state(MapViewMode.HIDE, style({
    width: '100%',
  })),
  transition(`${MapViewMode.MAXIMIZE} => ${MapViewMode.NORMAL}`, animate(200)),
  transition(`${MapViewMode.NORMAL} => ${MapViewMode.HIDE}`, animate(200)),
  transition(`${MapViewMode.HIDE} => ${MapViewMode.NORMAL}`, animate(200)),
  transition(`${MapViewMode.NORMAL} => ${MapViewMode.MAXIMIZE}`, animate(200)),
]);

import {Pipe, PipeTransform}       from '@angular/core';
import {ApGetCropStatisticService} from '../services/ap-get-crop-statistic.service';
import {IMainSecondCropChartData}  from '../../ap-interface/interfaces/charts/ap-main-second-crop-chart-data.interface';

@Pipe({
  name: 'getMainCropStatistic'
})
export class GetMainCropStatisticPipe implements PipeTransform {
  constructor(private cropStatistic: ApGetCropStatisticService) {
  }

  transform(): IMainSecondCropChartData[] {
    return this.cropStatistic.getForMainCrop();
  }
}

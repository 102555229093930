import {IAction, IApInvokerArguments} from '../../interfaces';
import IActions = Data.Tracelog.IActions;

export enum ApTraceLogActionsActions {
  LOAD_TRACE_LOAD_ACTIONS = '[trace_log_actions] load',
  LOAD_TRACE_LOAD_ACTIONS_SUCCESS = '[trace_log_actions] load success',
  LOAD_TRACE_LOAD_ACTIONS_FAIL = '[trace_log_actions] load fail',
}

export class LoadTraceLogActions implements IAction {
  readonly type = ApTraceLogActionsActions.LOAD_TRACE_LOAD_ACTIONS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadTraceLogActionsSuccess implements IAction {
  readonly type = ApTraceLogActionsActions.LOAD_TRACE_LOAD_ACTIONS_SUCCESS;

  constructor(public payload: IActions[]) {
  }
}

export class LoadTraceLogActionsFail implements IAction {
  readonly type = ApTraceLogActionsActions.LOAD_TRACE_LOAD_ACTIONS_FAIL;
}

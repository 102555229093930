import {Injectable}         from '@angular/core';
import {ApElementType}      from '../../ap-interface/enums/ap-elements-type.enum';
import {FertilizerStore}    from '../../stores/base-data/fertilizer.store';
import {IBaseFertStatistic} from '../../types/base-fertilization.types';
import {FertilizerService}  from './fertilizer.service';
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;
import INutrientFertilisationStatisticValue = Data.TaskManagement.INutrientFertilisationStatisticValue;
import {ObjectFactory}      from 'ts-tooling';

@Injectable({providedIn: 'root'})
export class BaseFertilizationService {
  constructor(private fertilizerStore: FertilizerStore,
              private fertilizerService: FertilizerService) {
  }

  public calculateNElementByLeadElement(productId: number | null | undefined,
                                        statistics: IFieldNutrientPlanningStatistic[] | null | undefined): IBaseFertStatistic | null {
    if (!productId || !statistics || statistics.length <= 0) {
      return null;
    }
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return null;
    }
    const leadNutrient = this.fertilizerService.getFertilizerLeadingElement(fertilizer) ?? ApElementType.P;
    const leadNutrientStatistic = this._getStatisticValue(statistics.find(_ => _.Band === leadNutrient - 1));
    if (!leadNutrientStatistic) {
      return null;
    }
    const leadFertilizerContent = this.fertilizerService.getFertilizerContent(fertilizer, leadNutrient, true);
    const factor = fertilizer.ContentN / leadFertilizerContent;
    if (!Number.isFinite(factor)) {
      return null;
    }
    leadNutrientStatistic.Min = leadNutrientStatistic.Min * factor;
    leadNutrientStatistic.Max = leadNutrientStatistic.Max * factor;
    leadNutrientStatistic.Average = leadNutrientStatistic.Average * factor;
    return leadNutrientStatistic;
  }

  public addProductAmountStatistic(productId: number | null | undefined, targetElement: number | null | undefined,
                                   statistics: INutrientFertilisationStatisticValue[] | null | undefined): INutrientFertilisationStatisticValue[] | null {
    if (!productId || !statistics || statistics.length <= 0) {
      return null;
    }
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return null;
    }
    const leadNutrient = this.fertilizerService.getFertilizerLeadingElement(fertilizer) ?? ApElementType.P;
    const leadFertilizerContent = this.fertilizerService.getFertilizerContent(fertilizer, targetElement ?? leadNutrient);

    const stat = statistics.find(_ => _.ElementId === targetElement ?? leadNutrient);
    const newStat = ObjectFactory.Copy(stat);
    newStat.Min = stat.Min / leadFertilizerContent;
    newStat.Max = stat.Max / leadFertilizerContent;
    newStat.Average = stat.Average / leadFertilizerContent;
    newStat.ElementId = -stat.ElementId;
    statistics.unshift(newStat);

    return statistics;
  }

  public calculateProductAmount(productId: number, targetElement: number | null | undefined, elementValue: number): number {
    if (!productId) {
      return null;
    }
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return null;
    }
    const leadNutrient = this.fertilizerService.getFertilizerLeadingElement(fertilizer) ?? ApElementType.P;
    const leadFertilizerContent = this.fertilizerService.getFertilizerContent(fertilizer, targetElement ?? leadNutrient);

    return elementValue / leadFertilizerContent;
  }

  private _getStatisticValue(statistic: {
    Min: number,
    Max: number,
    Mean: number
  } | null | undefined): IBaseFertStatistic {
    if (statistic && (statistic.Mean > 0 || statistic.Max > 0 || statistic.Min > 0)) {
      return {
        Min: statistic.Min,
        Max: statistic.Max,
        Average: statistic.Mean
      };
    }
    return null;
  }
}

import {AfterViewInit, Component, Input}             from '@angular/core';
import {CompositeFilterDescriptor, FilterDescriptor} from '@progress/kendo-data-query';
import {FilterService}                               from '@progress/kendo-angular-grid';

@Component({
  selector: 'ap-class-color-filter',
  templateUrl: 'ap-class-color-filter.component.html',
})
export class ApClassColorFilterComponent implements AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public items;
  @Input() public key;

  value: any;
  data = [
    'A-',
    'A+',
    'B-',
    'B+',
    'C-',
    'C+',
    'D-',
    'D+',
    'E',
  ];

  public ngAfterViewInit(): void {
    this.value = this.currentFilter.filters.Convert((f: FilterDescriptor) => f.value);
  }

  onChange(values: any[]): void {
    this.filterService.filter({
      filters: values.map(filterText => ({
        field: this.key,
        operator: v => {
          return filterText.ToUpperCase() === v.ToUpperCase();
        },
        value: filterText
      })),
      logic: 'or'
    });
  }
}

import IFertilizerOrg = Data.BaseData.IFertilizerOrg;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum FertilizerOrganicActions {
  FERTILIZER_ORGANIC_LOAD = '[fertilizer_organic] load',
  FERTILIZER_ORGANIC_LOAD_SUCCESS = '[fertilizer_organic] load success',
  FERTILIZER_ORGANIC_LOAD_FAIL = '[fertilizer_organic] load fail',
  FERTILIZER_ORGANIC_SELECTABLE_UPDATE = '[fertilizer_organic] selectable update',
  FERTILIZER_ORGANIC_CREATE = '[fertilizer_organic] create',
  FERTILIZER_ORGANIC_UPDATE = '[fertilizer_organic] update'
}

export class FertilizerOrganicLoad implements IAction {
  readonly type = FertilizerOrganicActions.FERTILIZER_ORGANIC_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FertilizerOrganicLoadSuccess implements IAction {
  readonly type = FertilizerOrganicActions.FERTILIZER_ORGANIC_LOAD_SUCCESS;

  constructor(public payload: IFertilizerOrg[]) {
  }
}

export class FertilizerOrganicLoadFail implements IAction {
  readonly type = FertilizerOrganicActions.FERTILIZER_ORGANIC_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class FertilizerOrganicSelectableUpdate implements IAction {
  readonly type = FertilizerOrganicActions.FERTILIZER_ORGANIC_SELECTABLE_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FertilizerOrganicCreate implements IAction {
  readonly type = FertilizerOrganicActions.FERTILIZER_ORGANIC_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FertilizerOrganicUpdate implements IAction {
  readonly type = FertilizerOrganicActions.FERTILIZER_ORGANIC_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {IAction} from '../../interfaces';
import IResultMessage = Invoker.IResultMessage;

export enum DatabaseNotifyActions {
  ON_NOTIFY = '[database] notify',
  REGISTER = '[database] notify register',
}

export class DatabaseNotify implements IAction {
  readonly type = DatabaseNotifyActions.ON_NOTIFY;

  constructor(public payload: IResultMessage) {
  }
}

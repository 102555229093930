import {AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {Subscription}                                                                  from 'rxjs';
import {ObjectFactory}                                                                             from 'ts-tooling';
import {TranslationStore}                                                                          from '../../../stores/translation/translation.store';
import {IChartSet}                                                                                 from '../../../stores/statistic/statistic.store';
import {GetSoilSampleClassColorService}                                                            from '../../../ap-utils/service/get-soil-sample-class-color.service';
import {
  IBarLineAreaChartData
}                                                                                                  from '../../../ap-interface';

@Component({
  selector: 'ap-histogram',
  templateUrl: 'histogram.component.html',
  styleUrls: ['histogram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistogramComponent implements AfterContentInit, OnDestroy {
  @Input()
  data: IChartSet<IBarLineAreaChartData>[];
  @Input()
  title: string;
  @Input()
  emptyMessage = '';
  @Input()
  decimalPlaces = 2;

  public calculatedData: { [key: string]: IChartSet<IBarLineAreaChartData> } = {};
  public bands: { id: string, name: string }[] = [];
  public bandsFilter: { id: string, name: string }[] = [];
  public selectedBand: { id: string, name: string } = {id: '', name: ''};
  public strokeColor = '#212529';

  private subscriptions: Subscription[] = [];
  private activeFilter = '';

  constructor(private changeDetectionRef: ChangeDetectorRef,
              private classColorService: GetSoilSampleClassColorService,
              private translationStore: TranslationStore) {
  }

  ngAfterContentInit(): void {
   this.readData(this.data);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  filterChange(filter: any): void {
    this.activeFilter = filter;
    this.bandsFilter = this.bands.FindAll(e => this.translationStore
      .FindTranslationForSelectedLanguage(e.id).Contains(filter));
  }

  onSelectionChange(item: { id: string, name: string }): void {
    if (!item) {
      return;
    }
    this.selectedBand = item;
  }

  private readData(data: IChartSet<IBarLineAreaChartData>[]): void {
    this.calculatedData = {};
    for (const d of ObjectFactory.Copy(data)) {
      if (!this.calculatedData[d.data.title]) {
        this.calculatedData[d.data.title] = {
          meta: null,
          step: d.step,
          valueType: d.valueType,
          axes: this.classColorService.getStatisticAxesConfig(d.valueType, d.axes.minX, d.axes.maxX),
          data: {
            data: d.data.data,
            categories: d.data.categories,
            title: this.translationStore.FindTranslationForSelectedLanguage(d.data.title),
            name: d.data.name,
          },
          decimalPlaces: d.decimalPlaces,
          color: d.color,
          visual: d.visual,
        };
        continue;
      }

      for (const cat of d.data.categories) {
        const idx = d.data.categories.IndexOf(cat);
        const count = d.data.data[idx];
        if (this.calculatedData[d.data.title].data.categories.Contains(cat)) {
          this.calculatedData[d.data.title].data.data[idx].value += count.value;
          continue;
        }
        this.calculatedData[d.data.title].data.categories.Add(cat);
        this.calculatedData[d.data.title].data.data.Add(count);
      }
    }

    this.bands = Object.keys(this.calculatedData).Convert(e => ({
      id: e,
      name: this.translationStore.FindTranslationForSelectedLanguage(e),
    }));
    if (this.activeFilter) {
      this.filterChange(this.activeFilter);
    } else {
      this.bandsFilter = this.bands;
    }
    if (!this.selectedBand.id) {
      this.selectedBand = this.bands[0] || {id: '', name: ''};
    }
    this.changeDetectionRef.detectChanges();
  }
}

import {AfterContentInit, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription}     from 'rxjs';
import * as moment                                                    from 'moment-timezone';
import {findIndex}                                                    from 'lodash';
import {
  ApDynGridColumnConfigBase
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {SelectionEvent}                                               from '@progress/kendo-angular-grid';
import {
  ApDynGridPropertyColumnConfig
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridStatusColumnConfig
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-status-column-config';
import {
  ApDynGridPagerConfigBase
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {
  ApDynGridPagerWizardConfig,
  ApDynGridPagerWizardSection
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {
  ApDynGridButtonColumnConfig
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-button-column-config';
import {APP_CONFIGURATION}            from '../../ap-core/config';
import {MapViewMode} from '../../ap-interface/enums';
import {MachineStore}                 from '../../stores/docu/machine.store';
import {CampaignYearStore}                                            from '../../stores/login/campaignyear.store';
import {MapViewStore}                                                 from '../../stores/layout/mapview.store';
import {ActionStore}                                                  from '../../stores/docu/action.store';
import {TranslationStore}                                             from '../../stores/translation/translation.store';
import {LanguageStore}                                                from '../../stores/translation/language.store';
import {MapStore}      from '../../stores/map/map.store';
import {filter, map}   from 'rxjs/operators';
import {ApDateService} from '../../ap-core/services/ap-date-service';
import {LoginStore}                                                   from '../../stores/login/login.store';
import IMachine = Data.DocuContext.Machine.IMachine;
import ILanguage = Data.Language.ILanguage;

/**
 * Component for the fleetmanagement
 */
@Component({
  selector: 'ap-fleetmanagement',
  template: `
    <ap-dyncomponent [caption]="'Global_Live_View'"
                     [headerIcon]="'ap-icon-field-works'"
                     [loading$]="locationsLoading$"
                     [columns]="columns"
                     [items]="items"
                     [pager]="pager"
                     [groupable]="true"
                     [selectable]="{checkboxOnly: true, mode: 'multiple', enabled: true}">
    </ap-dyncomponent>
  `,
})

export class ApFleetmanagementComponent implements OnInit, AfterContentInit, OnDestroy {
  public filter: string;
  public caption: string;
  public columns: ApDynGridColumnConfigBase[] = [];
  public machines: IMachine[] = [];
  public items: any[] = [];
  public pager: ApDynGridPagerConfigBase;
  public selected: any[];
  public selectedId;
  public locationsLoading$: Observable<boolean>;
  public moment = moment;
  public language: ILanguage;

  private loadingByInterval$ = new BehaviorSubject<boolean>(false);
  private subscriptions: Array<Subscription> = [];
  private machineInterval: number;
  private _onClick = new EventEmitter<any>();

  constructor(private actionStore: ActionStore,
              private campaignYearStore: CampaignYearStore,
              private machineStore: MachineStore,
              private mapStore: MapStore,
              private mapViewStore: MapViewStore,
              private translationStore: TranslationStore,
              private dateService: ApDateService,
              private languageStore: LanguageStore) {
    this._onClick.subscribe(event => {
      if (this.selectedId === event['Id']) {
        this.selectedId = undefined;
      } else {
        this.selectedId = event['Id'];
        if (this.mapViewStore.getMapViewMode() === MapViewMode.HIDE) {
          this.mapViewStore.showMapView();
        }
      }
      this.actionStore.changeSelectedLocation([event['Id']]);
    });
  }

  /**
   * returns the number of unprocessed actions.
   */
  private static _getLastActivityTime(timeString: string): number {
    const time = moment.tz(timeString, 'utc').valueOf();
    const now = moment.utc().valueOf();
    const difference = now - time;
    if (difference < 300000) {
      return 1;
    }
    if (difference < 1800000) {
      return 0;
    }
    return -1;
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    this.getDataFromStore();
  }

  ngAfterContentInit(): void {
    this.gridBuilder();
  }

  /**
   * build overview grid
   */
  gridBuilder(): void {
    this.columns = [
      new ApDynGridStatusColumnConfig({
        field: 'Status',
        class: 'StatusClass',
        description: 'StatusDesc',
        filterable: true,
        sortDesc: true,
        sortIndex: 0,
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'SourceItem.Name', title: 'Docu_Ini__Engine', width: 130
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'Sign', title: 'Docu_Ini__NumberPlate', width: 70
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'Kind', title: 'Docu_Ini__Type', width: 130, filterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'LastActivity', title: 'Docu_Last_Activity', width: 115, hide: true, format:
          `${this.language.DateFormat} ${this.language.TimeFormat}`
      }),
      new ApDynGridButtonColumnConfig({
        callback: this._onClick,
        imageUrl: APP_CONFIGURATION.ButtonZoomToMapImage,
        toggleable: true,
        selected: this.isItemSelected.bind(this),
        tooltip: 'Docu__Show_on_Map'
      })
    ];

    this.pager = new ApDynGridPagerWizardConfig(
      new ApDynGridPagerWizardSection(),
      new ApDynGridPagerWizardSection(),
      new ApDynGridPagerWizardSection()
    );
  }

  isItemSelected(item): boolean {
    return this.selectedId === item['Id'];
  }

  selectionChange(event: SelectionEvent): void {
    this.actionStore.changeSelectedLocation(event.selectedRows[0].dataItem);
  }

  /**
   * cleanup subscriptions
   */
  ngOnDestroy(): void {
    this.loadingByInterval$.next(false);
    this.subscriptions.forEach(d => {
      d.unsubscribe();
    });
    if (this.machineInterval) {
      clearInterval(this.machineInterval);
    }
    setTimeout(() => {
      this.mapStore.Layers.LocationsLayer.clear();
    }, 1);
  }

  /**
   * retrieves all data from the store
   */
  getDataFromStore(): void {
    this.language = this.languageStore.SelectedLanguage;
    this.subscriptions.push(this.campaignYearStore.Listen(s => s.selectedYear).subscribe(y => {
      if (y === null) {
        return;
      }
      clearInterval(this.machineInterval);
      setTimeout(() => {
        // Do all this stuff on top of the current stack.
        // This is needed to avoid conflicts with a possibly running interval.
        // Clearing the interval in advance only ensures that no new interval is started.
        this.loadingByInterval$.next(false);
        this.items = [];
        this.actionStore.loadLocations();
        this.machineInterval = setInterval( () => {
          // Whenever the interval passed we need to check if the previous load process
          // is finished already => if not we skip loading it again.
          if (!this.actionStore.LocationsLoading) {
            // During reloading the overview (with interval) we do not
            // want to have the loading indicator running everytime.
            // Therefore, we have this Subject to disable the loading indicator
            // when load was triggered by interval.
            this.loadingByInterval$.next(true);
            this.actionStore.loadLocations();
          }
        }, 5000);
      }, 0);
    }));

    // Show loading indicator only on intial load or when farm/campaignYear has been changed
    this.locationsLoading$ = combineLatest([
      this.actionStore.Listen(s => s.locationLoading),
      this.loadingByInterval$
    ]).pipe(
      map(([loading, loadingByInterval]) => loading && !loadingByInterval)
    );

    this.subscriptions.push(this.actionStore.Listen(s => s.selectedLocations).subscribe(d => {
      if (d == null) {
        return;
      }
      this.selected = d.map(f => f.Id.toString());
    }));

    this.subscriptions.push(this.machineStore.Listen(s => s.data).subscribe(m => {
      this.machines = m;
    }));

    this.subscriptions.push(
      combineLatest([
        this.actionStore.Listen(s => s.locations),
        this.actionStore.Listen(s => s.locationLoaded)
      ]).pipe(
        filter(([, locationsLoaded]) => locationsLoaded)
      ).subscribe(([locations]) => {
        if (locations == null) {
          this.items = [];
          return;
        }
        this.items = locations.map(l => {
          const machine = this.machines.find(m => m.Id === l.Id);
          const status = ApFleetmanagementComponent._getLastActivityTime(l.GpsDate + ' ' + l.GpsTime);
          const lastActivity = this.translationStore.FindTranslationForSelectedLanguage('Docu_Last_Activity');
          const minute = this.translationStore.FindTranslationForSelectedLanguage('Base__Minute_short');
          const and = this.translationStore.FindTranslationForSelectedLanguage('Base__and');
          const translations = [
            `${lastActivity} > 30 ${minute}`,
            `${lastActivity} > 5 ${minute} ${and} < 30 ${minute}`,
            `${lastActivity} < 5 ${minute}`
          ];
          let kind = '';
          if (machine) {
            const foundIndex = findIndex(this.machineStore.Listen(s => s.machineTypes).getValue(), ['Id', machine.Kind]);
            if (foundIndex !== -1) {
              kind = this.translationStore.FindTranslationForSelectedLanguage(
                this.machineStore.Listen(s => s.machineTypes).getValue()[foundIndex].Description);
            }
          }

          return {
            Id: l.Id,
            SourceItem: l,
            Status: status,
            StatusClass: status === 1 ? 'ap-status-green' : status === 0 ? 'ap-status-yellow' : 'ap-status-red',
            StatusDesc: translations[status + 1],
            Sign: machine ? machine.Sign : '',
            Kind: kind,
            LastActivity: this.dateService.toFarmDateFromUtcGetJsDate(l.GpsDate + ' ' + l.GpsTime)
          };
        });

        if (this.selectedId) {
          this.actionStore.changeSelectedLocation([this.selectedId]);
        }
      }));
    this.actionStore.changeSelectedLocation(this.selected.map(id => id));
  }
}

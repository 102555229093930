import {IAction, IApInvokerArguments} from '../../interfaces';

export enum PerformanceReportActions {
  PERFORMANCE_BOOK_WRITE = '[performance report] write',
  PERFORMANCE_BOOK_WRITE_SUCCESS = '[performance report] write success',
  PERFORMANCE_BOOK_WRITE_FAIL = '[performance report] write fail',
  PERFORMANCE_BOOK_DOWNLOAD = '[performance report] download',
  PERFORMANCE_BOOK_DOWNLOAD_SUCCESS = '[performance report] download success',
  PERFORMANCE_BOOK_DOWNLOAD_FINISH = '[performance report] download finish',
  PERFORMANCE_BOOK_DOWNLOAD_FAIL = '[performance report] download fail',
}

export class PerformanceReportWrite implements IAction {
  readonly type = PerformanceReportActions.PERFORMANCE_BOOK_WRITE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PerformanceReportWriteSuccess implements IAction {
  readonly type = PerformanceReportActions.PERFORMANCE_BOOK_WRITE_SUCCESS;

  constructor() {
  }
}

export class PerformanceReportWriteFail implements IAction {
  readonly type = PerformanceReportActions.PERFORMANCE_BOOK_WRITE_FAIL;

  constructor() {
  }
}

export class PerformanceReportDownload implements IAction {
  readonly type = PerformanceReportActions.PERFORMANCE_BOOK_DOWNLOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PerformanceReportDownloadSuccess implements IAction {
  readonly type = PerformanceReportActions.PERFORMANCE_BOOK_DOWNLOAD_SUCCESS;

  constructor(public payload: string[]) {
  }
}

export class PerformanceReportDownloadFinish implements IAction {
  readonly type = PerformanceReportActions.PERFORMANCE_BOOK_DOWNLOAD_FINISH;

  constructor() {
  }
}

export class PerformanceReportDownloadFail implements IAction {
  readonly type = PerformanceReportActions.PERFORMANCE_BOOK_DOWNLOAD_FAIL;

  constructor() {
  }
}

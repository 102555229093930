import {IAction, IApInvokerArguments} from '../../interfaces';
import ISensorFieldData = Data.Sensor.ISensorFieldData;

export enum SensorActions {
  SENSOR_FIELD_DATA_LOAD = '[sensor] field data load',
  SENSOR_FIELD_DATA_LOAD_SUCCESS = '[sensor] field data load success',
  SENSOR_RASTER_VALUES_LOAD = '[sensor] raster values load',
  SENSOR_RASTER_VALUES_LOAD_SUCCESS = '[sensor] raster values load success',
  SENSOR_RASTER_VALUES_LOAD_FAIL = '[sensor] raster values load fail',
}

export class SensorFieldDataLoad implements IAction {
  readonly type = SensorActions.SENSOR_FIELD_DATA_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SensorFieldDataLoadSuccess implements IAction {
  readonly type = SensorActions.SENSOR_FIELD_DATA_LOAD_SUCCESS;

  constructor(public payload: ISensorFieldData[]) {
  }
}

export class SensorRasterValuesLoad implements IAction {
  readonly type = SensorActions.SENSOR_RASTER_VALUES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SensorRasterValuesLoadSuccess implements IAction {
  readonly type = SensorActions.SENSOR_RASTER_VALUES_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SensorRasterValuesLoadFail implements IAction {
  readonly type = SensorActions.SENSOR_RASTER_VALUES_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'ap-icon-selector',
  templateUrl: 'ap-icon-selector.component.html'
})
export class ApIconSelectorComponent {
  @Input()
  public id: string;
  @Input()
  public cssClass = 'ap-icon';
}

import {ApDynGridPagerConfigBase, ApDynGridPagerControlType}       from './ap-dyn-grid-pager-config-base';
import {IGridPagerData, IWizardChoiceData, IWizardChoiceDataValue} from '../../ap-interface';
import {EventEmitter}                                              from '@angular/core';
import {ApDynformsConfigFieldset}                                  from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {FormGroup}                                                 from '@angular/forms';
import {ApDynformsConfigTabs}                                      from '../../ap-dynforms/config/ap-dynforms-config-tabs';
import {Observable, of}                                            from 'rxjs';
import {ApUtilService}                                             from '../../ap-utils/service/ap-util.service';

export interface IGridPagerSubmitData {
  ids: any[];
  items: any[];
  value: any;
  form: FormGroup;
}

export interface IGridPagerChoiceSelect {
  keys: any[];
  items: any[];
  form: FormGroup;
}

export interface IDynGridWizardChoiceData extends IWizardChoiceData {
  onSubmit?: EventEmitter<IGridPagerSubmitData>;
  formConfig?: ApDynformsConfigFieldset[] | ApDynformsConfigTabs[];
  onChoiceSelect?: EventEmitter<IGridPagerChoiceSelect>;
  values?: Array<IDynGridWizardChoiceDataValue>;
}

export interface IDynGridWizardChoiceDataValue extends IWizardChoiceDataValue {
  formConfig?: ApDynformsConfigFieldset[] | ApDynformsConfigTabs[];
  onChoiceSelect?: EventEmitter<IGridPagerChoiceSelect>;
  onSubmit?: EventEmitter<IGridPagerSubmitData>;
}

export interface IDynGridProperty {
  selectedItems: any[];
}

export interface IGridPagerCallBackData {
  ids: any[];
  items: any[];
  value: any;
}

export class PagerIconButton {
  icon: string;
  callback: EventEmitter<void>;
  tooltip?: string;
}

export class PagerLowerButton {
  name: string;
  callback: EventEmitter<void>;
  tooltip?: string;
}

export interface IDynGridWizardChoiceData extends IWizardChoiceData {
  callback?: EventEmitter<IGridPagerCallBackData>;
}

export class ApDynGridPagerConfig extends ApDynGridPagerConfigBase {
  public type = ApDynGridPagerControlType.Pager;

  public listMassEditing: Observable<Array<IDynGridWizardChoiceData>> = of([]);
  public apply: EventEmitter<IGridPagerData>;
  public columns: any[] = [];
  public delete: EventEmitter<any>;
  public showOnlyValues = true;
  public hasPermission = false;
  public preselect = true;
  public showWithoutSelection = false;
  public iconButtons: PagerIconButton[];
  public lowerButtons: PagerLowerButton[];

  constructor(options: {
    apply?: EventEmitter<IGridPagerData>,
    columns?: any[],
    delete?: EventEmitter<any>,
    showOnlyValues?: boolean,
    listMassEditing?: Array<IDynGridWizardChoiceData> | Observable<Array<IDynGridWizardChoiceData>>,
    hasPermission?: boolean,
    preselect?: boolean,
    showWithoutSelection?: boolean,
    iconButtons?: PagerIconButton[],
    lowerButtons?: PagerLowerButton[]
  } = {}) {
    super();
    this.apply = options.apply;
    this.columns = options.columns ? options.columns : [];
    this.delete = options.delete;
    this.listMassEditing = ApUtilService.asObservable(options.listMassEditing, []);
    this.showOnlyValues = options.showOnlyValues ? options.showOnlyValues : null;
    this.hasPermission = options.hasPermission ? options.hasPermission : false;
    this.showWithoutSelection = options.showWithoutSelection ? options.showWithoutSelection : false;
    this.preselect = options.preselect !== false;
    this.iconButtons = options.iconButtons;
    this.lowerButtons = options.lowerButtons;
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum VersionInfoActions {
  VERSION_INFO_LOAD = '[version] load',
  VERSION_INFO_LOAD_SUCCESS = '[version] load success',
}

export class VersionInfoLoad implements IAction {
  readonly type = VersionInfoActions.VERSION_INFO_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class VersionInfoLoadSuccess implements IAction {
  readonly type = VersionInfoActions.VERSION_INFO_LOAD_SUCCESS;

  constructor(public payload: string) {
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum FieldDiaryActions {
  FIELD_DIARY_DOWNLOAD = '[field diary] download',
  FIELD_DIARY_DOWNLOAD_SUCCESS = '[field diary] download success',
  FIELD_DIARY_DOWNLOAD_FINISH = '[field diary] download finish',
  FIELD_DIARY_DOWNLOAD_FAIL = '[field diary] download fail',
}

export class FieldDiaryDownload implements IAction {
  readonly type = FieldDiaryActions.FIELD_DIARY_DOWNLOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldDiaryDownloadSuccess implements IAction {
  readonly type = FieldDiaryActions.FIELD_DIARY_DOWNLOAD_SUCCESS;

  constructor(public payload: string[]) {
  }
}

export class FieldDiaryDownloadFinish implements IAction {
  readonly type = FieldDiaryActions.FIELD_DIARY_DOWNLOAD_FINISH;

  constructor() {
  }
}

export class FieldDiaryDownloadFail implements IAction {
  readonly type = FieldDiaryActions.FIELD_DIARY_DOWNLOAD_FAIL;

  constructor() {
  }
}

import {IStateStore, MapViewCurrentMenu, MapViewMode} from '../../ap-interface';
import {Store}                                        from '../index';
import {Injectable}                                   from '@angular/core';
import {ApMapInstance}                                from '../../ap-map';
import {Observable}                                   from 'rxjs';
import {ApSignalrService}                             from '../../ap-core/services/ap-signalr.service';

interface IMapViewStore extends IStateStore<any> {
  mode: MapViewMode;
  currentMenu: MapViewCurrentMenu;
}

@Injectable({providedIn: 'root'})
export class MapViewStore extends Store<IMapViewStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loading: false,
      loaded: false,
      mode: MapViewMode.NORMAL,
      currentMenu: MapViewCurrentMenu.MAP
    });
  }

  getMapViewMode$(): Observable<MapViewMode> {
    return super.Listen(s => s.mode);
  }

  getMapViewMode(): MapViewMode {
    return super.Listen(s => s.mode).getValue();
  }

  getMapViewCurrentMenu(): MapViewCurrentMenu {
    return super.Listen(s => s.currentMenu).getValue();
  }

  /**
   * Switches to normal-mode map in a synchronous mutation.
   * This improves performance of showing the map compared to the other mutations within a setTimeout
   */
  public goIntoMapMenuSync(): void {
    super.Mutate(s => s.mode, () => MapViewMode.NORMAL);
    super.Mutate(s => s.currentMenu, () => MapViewCurrentMenu.MAP);
    setTimeout(this.refreshMap.bind(this), 1);
  }

  public goIntoMapMenu(): void {
    super.Mutate(s => s.currentMenu, () => MapViewCurrentMenu.MAP);
    setTimeout(this.refreshMap.bind(this), 1);
  }

  public goIntoStatisticMenu(): void {
    super.Mutate(s => s.currentMenu, () => MapViewCurrentMenu.STATS);
  }

  public goIntoHelpMenu(): void {
    super.Mutate(s => s.currentMenu, () => MapViewCurrentMenu.HELP);
  }

  public hideMapView(): void {
    super.Mutate(s => s.mode, () => MapViewMode.HIDE);
    this.refreshMap();
  }

  public showMapView(): void {
    setTimeout(() => {
      super.Mutate(s => s.mode, () => MapViewMode.NORMAL);
      this.refreshMap();
    }, 1);
  }

  public refreshMap(): void {
    if (ApMapInstance.mapRef && ApMapInstance.mapRef.getTarget()) {
      ApMapInstance.updateView();
    }
  }

  public increaseMapViewMode(): void {
    switch (this.getMapViewMode()) {
      case MapViewMode.NORMAL:
        super.Mutate(s => s.mode, () => MapViewMode.MAXIMIZE);
        break;
      case MapViewMode.HIDE:
        super.Mutate(s => s.mode, () => MapViewMode.NORMAL);
        break;
    }
  }

  public decreaseMapViewMode(): void {
    switch (this.getMapViewMode()) {
      case MapViewMode.MAXIMIZE:
        super.Mutate(s => s.mode, () => MapViewMode.NORMAL);
        break;
      case MapViewMode.NORMAL:
        super.Mutate(s => s.mode, () => MapViewMode.HIDE);
        break;
    }
  }
}

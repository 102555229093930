import {NgModule}           from '@angular/core';
import {CommonModule}       from '@angular/common';
import {ApTooltipDirective} from './ap-tooltip.directive';
import {ApTooltipComponent} from './ap-tooltip.component';

@NgModule({
  declarations: [
    ApTooltipDirective,
    ApTooltipComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ApTooltipDirective,
    ApTooltipComponent
  ]
})
export class ApTooltipModule {
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IReadInstrumentModel = Data.DocuContext.Instrument.IReadInstrumentModel;

export enum InstrumentsActions {
  INSTRUMENTS_LOAD = '[instruments] load',
  INSTRUMENTS_LOAD_SUCCESS = '[instruments] load success',
  INSTRUMENTS_LOAD_FAIL = '[instruments] load fail',
  INSTRUMENTS_UPDATE = '[instruments] update',
  INSTRUMENTS_CREATE = '[instruments] create',
  INSTRUMENTS_DELETE = '[instruments] delete',
  INSTRUMENTS_DELETE_SUCCESS = '[instruments] delete success',
  INSTRUMENTS_SAVE_SUCCESS = '[instruments] save success',
  INSTRUMENTS_SAVE_FAIL = '[instruments] save fail',
}

export class InstrumentsLoad implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InstrumentsLoadSuccess implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_LOAD_SUCCESS;

  constructor(public payload: IReadInstrumentModel[]) {
  }
}

export class InstrumentsLoadFail implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class InstrumentsUpdate implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InstrumentsCreate implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InstrumentsDelete implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InstrumentsDeleteSuccess implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_DELETE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InstrumentsSaveSuccess implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_SAVE_SUCCESS;

  constructor(public payload: IReadInstrumentModel) {
  }
}

export class InstrumentsSaveFail implements IAction {
  readonly type = InstrumentsActions.INSTRUMENTS_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import ITimeZones = Data.BaseData.ITimeZones;
import ISettings = Data.BaseData.ISettings;

export enum SettingsActions {
  SETTINGS_LOAD = '[settings] load',
  SETTINGS_LOAD_SUCCESS = '[settings] load success',
  SETTINGS_LOAD_FAIL = '[settings] load fail',
  SETTINGS_UPDATE = '[settings] update',
  SETTINGS_UPDATE_SUCCESS = '[settings] update success',
  SETTINGS_UPDATE_FAIL = '[settings] update fail',
  SETTINGS_SAVE_SUCCESS = '[settings] save success',
  SETTINGS_SAVE_FAIL = '[settings] save fail',
  TIME_ZONE_LOAD = '[settings] available timezones load',
  TIME_ZONE_LOAD_SUCCESS = '[settings] available timezones load success',
  TIME_ZONE_LOAD_FAIL = '[settings] available timezones fail',
}

export class SettingsLoad implements IAction {
  readonly type = SettingsActions.SETTINGS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SettingsLoadSuccess implements IAction {
  readonly type = SettingsActions.SETTINGS_LOAD_SUCCESS;

  constructor(public payload: ISettings[]) {
  }
}

export class SettingsUpdate implements IAction {
  readonly type = SettingsActions.SETTINGS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SettingsUpdateSuccess implements IAction {
  readonly type = SettingsActions.SETTINGS_UPDATE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SettingsUpdateFail implements IAction {
  readonly type = SettingsActions.SETTINGS_UPDATE_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SettingsSaveSuccess implements IAction {
  readonly type = SettingsActions.SETTINGS_SAVE_SUCCESS;

  constructor(public payload: ISettings) {
  }
}

export class TimeZoneLoad implements IAction {
  readonly type = SettingsActions.TIME_ZONE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TimeZoneLoadSuccess implements IAction {
  readonly type = SettingsActions.TIME_ZONE_LOAD_SUCCESS;

  constructor(public payload: ITimeZones[]) {
  }
}

import ISlice = Data.DocuContext.Slice.ISlice;
import IMachineBreak = Data.DocuContext.Machine.IMachineBreak;
import ISliceProcessing = Data.DocuContext.Slice.ISliceProcessing;
import IAttachmentMapping = Data.DocuContext.Attachment.IAttachmentMapping;
import ICombinedSlice = Data.DocuContext.Slice.ICombinedSlice;
import IWorktypes = Data.BaseData.IWorktypes;
import * as moment        from 'moment';
import {Moment}           from 'moment';
import {TranslationStore} from '../../stores/translation/translation.store';

export class ApDocuUtils {
  static addWtUnit(unit: string, workType: IWorktypes, translationStore: TranslationStore, countyCode?: string): string {
    if (!workType || !workType.Wt_Unit) {
      return unit;
    }
    if (countyCode) {
      return `${unit} / ${translationStore.FindTranslation(workType.Wt_Unit, countyCode)}`;
    }
    return `${unit} / ${translationStore.FindTranslationForSelectedLanguage(workType.Wt_Unit)}`;
  }

  static getSliceProperty(slice: ISlice, name: string, property: string): number {
    return slice.Attachment ?
      ApDocuUtils.getSlicePropertyIntern(slice.Processing, slice.Attachment.Mapping, name, property) : 0;
  }

  static getSliceMovementProperty(slice: ICombinedSlice, name: string, property: string): number {
    return slice.Attachment ?
      ApDocuUtils.getSlicePropertyIntern(slice.MovementProcessing, slice.Attachment.Mapping, name, property) : 0;
  }

  static getSliceStandStillProperty(slice: ICombinedSlice, name: string, property: string): number {
    return slice.Attachment ?
      ApDocuUtils.getSlicePropertyIntern(slice.StandstillProcessing, slice.Attachment.Mapping, name, property) : 0;
  }

  static generateBreakMap(breaks: IMachineBreak[]): Map<string, object[]> {
    const keyMap = new Map<string, string>();

    keyMap.set('Speed', 'speed');
    keyMap.set('Fuel Rate', 'fuel_rate');
    keyMap.set('Engine Speed', 'engine_speed');
    keyMap.set('ApplicationRate', 'application_rate');
    keyMap.set('Tank', 'tank');
    keyMap.set('WorkState', 'work_state');

    const result = new Map<string, object[]>();
    breaks.forEach(br => {
      const key = keyMap.get(br.Property);
      if (result.has(key)) {
        result.get(key).push({value: br.Value, color: br.Color});
      } else {
        result.set(key, [{value: br.Value, color: br.Color}]);
      }
    });
    return result;
  }

  static formatDuration(durationMs: number): string {
    let duration = moment.duration(0);
    if (durationMs && durationMs > 0) {
      duration = moment.duration(durationMs);
    }
    return `${duration.asHours() < 10 ? '0' : ''}${Math.floor(duration.asHours())}${moment.utc(duration.asMilliseconds()).format(':mm')}`;
  }

  static getDurationOfSlicesInMs(slices: ISlice[]): number {
    if (!slices) {
      return 0;
    }
    return slices.map(s => s.Duration).reduce((acc, val) => acc + val) * 1000;
  }

  static getDurationOfSlices(slices: ISlice[]): Moment {
    return this.getDuration(new Date(this.getDurationOfSlicesInMs(slices)));
  }

  static getDuration(date: Date): Moment {
    return moment(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getSeconds(), date.getUTCMilliseconds()));
  }

  static getStandStillTimeOfSlicesInMs(slices: ISlice[]): number {
    if (!slices) {
      return 0;
    }
    let duration = 0;
    const standStill = slices.filter(s => s.Type === Data.Authentication.Enums.ActionType.No_Movement)
      .map(s => s.Duration);

    if (standStill.length) {
      duration = standStill.reduce((acc, val) => acc + val) * 1000;
    }
    return duration;
  }

  static getStandStillTimeOfSlices(slices: ISlice[]): Moment {
    return this.getDuration(new Date(this.getStandStillTimeOfSlicesInMs(slices)));
  }

  private static getSlicePropertyIntern(processing: ISliceProcessing[], mapping: IAttachmentMapping[],
                                        name: string, property: string): number {
    if (processing && processing.length) {
      for (let i = 0; i < mapping.length; ++i) {
        if (mapping[i]['Name'].toLowerCase() === name.toLowerCase()) {
          return processing[i][property];
        }
      }
    }
    return 0;
  }
}

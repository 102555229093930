<kendo-multiselect
  [class.dark-bg]="true"
  [data]="data"
  [filterable]="true"
  [valuePrimitive]="true"
  [value]="value"
  (valueChange)="onChange($event)">
  <ng-template kendoMultiSelectTagTemplate let-dataItem>
    <span [ngStyle]="dataItem | getSoilSampleFieldClassColor:[false]"
          [style.height]="'25px'"
          [style.width]="'100%'"
          [style.line-height]="'24px'"
          style="display: flex; justify-content: center; min-width: 55px"
          >
      {{dataItem}}
    </span>
  </ng-template>
  <ng-template kendoMultiSelectItemTemplate let-dataItem>
    <span [ngStyle]="dataItem | getSoilSampleFieldClassColor:[false]"
          [style.height]="'25px'"
          [style.width]="'100%'"
          [style.line-height]="'24px'"
          style="display: flex; justify-content: center"
         >
      {{dataItem}}
    </span>
  </ng-template>
</kendo-multiselect>

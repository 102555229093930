import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import {GridComponent}           from '@progress/kendo-angular-grid';
import {fromEvent, Subscription} from 'rxjs';
import {process}                 from '@progress/kendo-data-query';

@Directive({
  selector: '[gridDragAndDrop]'
})
export class GridDragAndDropDirective implements AfterViewInit, OnDestroy {

  @Output() rowDrop = new EventEmitter<{ sourceItem: any, destinationItem: any }>();

  private _draggedIndex;
  private _subscriptions: Subscription[] = [];

  constructor(private element: ElementRef,
              private gridComponent: GridComponent,
              private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    const rows = this.element.nativeElement.querySelectorAll('tbody tr');
    rows.forEach((row, i) => {
      this.renderer.setAttribute(row, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(row, 'dragstart');
      const dragOver = fromEvent<DragEvent>(row, 'dragover');
      const drop = fromEvent<DragEvent>(row, 'drop');

      this._subscriptions.push(dragStart.subscribe(() => {
        this._draggedIndex = i;
      }));

      this._subscriptions.push(dragOver.subscribe(e => {
        if (this._draggedIndex !== undefined) {
          e.preventDefault();
        }
      }));

      this._subscriptions.push(drop.subscribe(() => {
        const sourceIndex = this._draggedIndex + this.gridComponent.skip;
        const destinationIndex = i + this.gridComponent.skip;
        const items = process(Array.isArray(this.gridComponent.data) ?
          this.gridComponent.data :
          this.gridComponent.data.data, {
          skip: this.gridComponent.skip,
          take: this.gridComponent.pageSize,
          filter: this.gridComponent.filter,
          group: this.gridComponent.group,
          sort: this.gridComponent.sort
        });
        this.rowDrop.emit({
          sourceItem: items.data[sourceIndex],
          destinationItem: items.data[destinationIndex]
        });
        this._draggedIndex = undefined;
      }));
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}

import OlBingMapsSource              from 'ol/source/BingMaps';
import {ApBaseTileLayer}             from './ap-base-tile.layer';
import {MapStore}                    from '../../stores/map/map.store';
import {LayerGroupNames, LayerNames} from './layer-names';

const APIKey = 'AokX-S2lieXTaXG8pvEw3i2AKYuStBMK8RsUu6BDJ6hrL5AYv0IfQqM9zc-BAA-v';

export enum BingImagery {
  SATELLITE = 'Aerial',
  HYBRID = 'AerialWithLabelsOnDemand',
  STREET = 'RoadOnDemand',
  GRAYSCALE_ROAD = 'CanvasGray',
  LIGHT_ROAD = 'CanvasLight',
  DARK_ROADS = 'CanvasDark',
  BIRDSEYE = 'Birdseye',
  BIRDSEYE_LABELS = 'BirdseyeWithLabels',
  BIRDSEYE_V2 = 'BirdseyeV2',
  BIRDSEYE_V2_LABELS = 'BirdseyeV2WithLabels',
  STREETSIDE = 'Streetside',
}

export class ApBingLayer extends ApBaseTileLayer<OlBingMapsSource> {
  public Group = LayerGroupNames.BACKGROUND;

  constructor(mapStore: MapStore, set: BingImagery) {
    super(mapStore, LayerNames.BING_MAP, new OlBingMapsSource({
      key: APIKey,
      imagerySet: set,
      maxZoom: 19,
      hidpi: true,
      culture: 'de-DE'
    }));
  }

  public refresh(): void {
    this.source.clear();
    this.source.refresh();
  }
}

import {animate, state, style, transition, trigger} from '@angular/animations';
import {SidebarMode}                                from '../ap-interface';

export const LOGO_SCALE = trigger('logoScale', [
  state(SidebarMode.OPEN, style({
    top: '15px',
    left: '30px',
    height: '45px',
    width: '131px',
    'font-size': '45px'
  })),
  state(SidebarMode.HALF, style({
    top: '8px',
    left: '15px',
    height: '22px',
    width: '65px',
    'font-size': '22px'
  })),
  state(SidebarMode.CLOSE, style({
    top: '8px',
    left: '15px',
    height: '22px',
    width: '65px',
    'font-size': '22px'
  })),
  transition(`${SidebarMode.OPEN} => ${SidebarMode.HALF}`, animate(200)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.OPEN}`, animate(200)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.CLOSE}`, animate(200)),
  transition(`${SidebarMode.OPEN} => ${SidebarMode.CLOSE}`, animate(200)),
  transition(`${SidebarMode.CLOSE} => ${SidebarMode.OPEN}`, animate(200))
]);

import {IAction, IApInvokerArguments} from '../../interfaces';
import IExportFormat = Data.Common.IExportFormat;

export enum ExportFormatsActions {
  EXPORT_FORMATS_LOAD = '[export_formats] load',
  EXPORT_FORMATS_LOAD_SUCCESS = '[export_formats] load success',
}

export class ExportFormatsLoad implements IAction {
  readonly type = ExportFormatsActions.EXPORT_FORMATS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ExportFormatsLoadSuccess implements IAction {
  readonly type = ExportFormatsActions.EXPORT_FORMATS_LOAD_SUCCESS;

  constructor(public payload: IExportFormat[]) {
  }
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {isNumber, isString}                   from 'lodash';
import {combineLatest, Observable}            from 'rxjs';
import {IApMapColorLegend}                    from '../../ap-interface';
import {TranslationStore}                     from '../../stores/translation/translation.store';
import {ColorLegendStore}                     from '../../stores/map/color.legend.store';
import {map, tap}                             from 'rxjs/operators';
import {MapStore}                             from '../../stores/map/map.store';
import {ObjectFactory}                        from 'ts-tooling';

@Component({
  selector: 'ap-map-color-legend',
  templateUrl: './ap-map-color-legend.component.html',
  styleUrls: ['./ap-map-color-legend.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: []
})
export class ApMapColorLegendComponent implements OnInit {
  colorLegends$: Observable<IApMapColorLegend[]>;
  show = new Map<string, boolean>();

  constructor(private store: ColorLegendStore,
              private mapStore: MapStore,
              private translationStore: TranslationStore) {
  }

  ngOnInit(): void {
    this.colorLegends$ =
      combineLatest([this.store.Listen(s => s.colorLegends)])
        .pipe(
          map(([colorLegends]) =>
            ObjectFactory.Copy(Object.values(colorLegends).FindAll(legend => this.mapStore.Layers.AllLayers$.getValue().Find(l => l.name === legend.layerName)?.Visibility))),
          tap((colorLegends) => colorLegends.forEach(legend => this._setAnchorWidth(legend.title))),
          tap((colorLegends) => colorLegends.forEach(legend => {
            if (!this.show[legend.title]) {
              this.show[legend.title] = true;
            }
          }))
        );
  }

  escapeTitle(title: string): string {
    return CSS.escape(title.split(' ').join(''));
  }

  getLabel(color, colors, i): string {
    if (isNumber(color.label)) {
      if (+colors[0].label < +colors[1].label) {
        if (i === colors.length - 1) {
          if (+colors[i - 1].label + 1 !== +color.label) {
            return `>= ${color.label}`;
          }
        } else if (+color.label !== +colors[i + 1].label - 1) {
          return `${color.label} - ${(colors[i + 1].label - 1)}`;
        }
      } else if (+colors[0].label > +colors[1].label) {
        if (i === 0) {
          return `>= ${color.label}`;
        } else if (i === colors.length - 1) {
          if (+color.label > 0) {
            return `${color.label} - 0`;
          }
        } else if (+color.label !== +colors[i + 1].label + 1) {
          return `${colors[i].label} - ${(+colors[i + 1].label + 1)}`;
        }
      }
    } else if (isString(color.label)) {
      return this.translationStore.FindTranslationForSelectedLanguage(color.label);
    }

    return color.label;
  }

  open(title: string): void {
    const show = this.show.get(title);
    this.show.set(title, !show);
    this._setAnchorWidth(title);
  }

  private _setAnchorWidth(title: string): void {
    setTimeout(() => {
      const anchor = document.querySelector<HTMLElement>('#ColorLegendAnchor' + this.escapeTitle(title));
      if (!anchor) {
        return;
      }
      const popup = document.querySelector<HTMLElement>('#ColorLegendPopup' + this.escapeTitle(title));
      if (popup) {
        anchor.style.minWidth = 'min-content';
        anchor.style.width = window.getComputedStyle(popup).width;
      } else {
        anchor.style.minWidth = '';
        anchor.style.width = '';
      }
    }, 1);
  }

  close(layerName: string): void {
    this.mapStore.Layers.AllLayers$.getValue().filter(layer => layer.name === layerName).forEach(layer => layer.Visibility = false);
  }
}

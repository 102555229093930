import {IStateStore}                                             from '../../ap-interface/interfaces/store';
import {Store}                                                   from '../index';
import {Injectable}                                              from '@angular/core';
import {LoadUsergroups, LoadUsergroupsSuccess} from 'invoker-transport';
import IUserGroup = Data.AccessPermission.IUsergroup;
import {ApSignalrService}                                        from '../../ap-core/services/ap-signalr.service';

interface IUserGroupsStore extends IStateStore<IUserGroup> {
}

@Injectable({providedIn: 'root'})
export class UserGroupStore extends Store<IUserGroupsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(LoadUsergroupsSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  public getUserGroups(): IUserGroup[] {
    return super.Listen(s => s.data).getValue();
  }

  loadUserGroups(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new LoadUsergroups([]));
  }
}

import {NgModule}               from '@angular/core';
import {ApTranslationPipe}      from './pipes/ap-translation.pipe';
import {ApTranslationArrayPipe} from './pipes/ap-translation-array.pipe';
import {ApTranslationAsyncPipe} from './pipes/ap-translation-async.pipe';

@NgModule({
  declarations: [
    ApTranslationPipe,
    ApTranslationAsyncPipe,
    ApTranslationArrayPipe
  ],
  exports: [
    ApTranslationPipe,
    ApTranslationAsyncPipe,
    ApTranslationArrayPipe
  ],
  providers: [
    ApTranslationPipe,
    ApTranslationAsyncPipe,
    ApTranslationArrayPipe
  ]
})
export class ApTranslationModule {
}

import {Pipe, PipeTransform} from '@angular/core';
import {Moment}              from 'moment';
import {ApDateService}       from '../../ap-core/services/ap-date-service';


@Pipe({
  name: 'getLocalDate'
})
export class ApGetLocalDatePipe implements PipeTransform {
  constructor(private dateService: ApDateService) {
  }

  transform(date: Date | string = null, ...args: string[]): Moment {
    return this.dateService.toFarmDateFromUtc(date, args[0]);
  }
}

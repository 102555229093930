<kendo-chart [class]="'ap-dotted-chart'"
             [transitions]="data.Styles.Transition"
             [zoomable]="data.Styles.Zoomable"
             [pannable]="data.Styles.Pannable">
  <kendo-chart-series>
    <kendo-chart-series-item type="scatter"
                             xField="X"
                             yField="Y"
                             [data]="data.Data"
                             [color]="data.Styles.PointerColor"
                             [markers]="markers"
                             [highlight]="{markers: selectedMarker}">
      <kendo-chart-series-item-tooltip [background]="basicStyles.TooltipBackground"
                                       [color]="basicStyles.TooltipTextColor">
        <ng-template let-dataItem="dataItem">
          <span [innerHTML]="getSafeHtml(dataItem.TooltipText)"></span>
        </ng-template>
      </kendo-chart-series-item-tooltip>
    </kendo-chart-series-item>
    <kendo-chart-x-axis>
      <kendo-chart-x-axis-item [title]="{text: data.AxisX.Title | translate}"
                               [min]="data.AxisX.Min ?? 0"
                               [max]="data.AxisX.Max ?? 0"
                               [color]="basicStyles.StrokeColor"
      ></kendo-chart-x-axis-item>
    </kendo-chart-x-axis>
    <kendo-chart-y-axis>
      <kendo-chart-y-axis-item [title]="{text: data.AxisY.Title | translate}"
                               [min]="data.AxisY.Min ?? 0"
                               [max]="data.AxisY.Max ?? 0"
                               [color]="basicStyles.StrokeColor"
      ></kendo-chart-y-axis-item>
    </kendo-chart-y-axis>
  </kendo-chart-series>
</kendo-chart>

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import {
  RouterStore
}                                                                                               from '../../stores/router/router.store';
import {
  HttpClient
}                                                                                               from '@angular/common/http';
import {
  EditorComponent,
  Schema
}                                                                                               from '@progress/kendo-angular-editor';
import {
  HelpArticlesStore
}                                                                                               from '../../stores/common/help-articles.store';
import {
  LanguageStore
}                                                                                               from '../../stores/translation/language.store';
import {combineLatest, Subscription} from 'rxjs';
import {debounceTime, filter}        from 'rxjs/operators';
import {
  FormStore
}                                    from '../../stores/layout/form.store';
import {
  ApVisibleStates
}                                                                                               from '../../ap-interface';
import {
  ApEditorSchema
}                                                                                               from './ap-editor.schema';
import {
  FileState,
  UploadEvent
}                                                                                               from '@progress/kendo-angular-upload';
import {
  DialogService
}                                                                                               from '@progress/kendo-angular-dialog';
import {
  TranslationStore
}                                                                                               from '../../stores/translation/translation.store';
import {
  ModalDialogStore
}                                                                                               from '../../stores/dialog/modal.dialog.store';
import {
  IModalDialogData,
  ModalDialogButtonDisable
}                                                                                               from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {
  ApDynformsConfigFieldset
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigTextbox, ApDynformsConfigTextboxType
} from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {
  ApDynformsValidator
}                                                                                               from '../../ap-dynforms/ap-dynforms-validator';
import {Validators}                                                                             from '@angular/forms';
import {
  ApDynformsConfigUpload
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-upload';
import {
  ApDynformsConfigPlaceholder
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {
  ApPermissions
}                                                                                               from 'invoker-transport';
import {
  GetPermissionPipe
}                                                                                               from '../../ap-permission/pipes/get-permission.pipe';
import {
  Plugin
}                                                                                               from '@progress/kendo-editor-common';
import {
  ApHelpImageUpload
}                                                                                               from './ap-help-image.upload';
import ILanguage = Data.Language.ILanguage;

/**
 * DialogType definition
 */
export enum IHelpDialogType {
  Youtube,
  Image,
  Save
}

/**
 * Custom dialog data for different modal dialogs (youtube, image, ...)
 */
export interface IHelpDialogData {
  DialogType: IHelpDialogType;
  YoutubeLink: string;
  YoutubeWidth: number;
  YoutubeHeight: number;
  AllowedExtensions: Array<string>;
}

/**
 * Component for the help
 */
@Component({
  selector: 'ap-help',
  templateUrl: 'ap-help.component.html',
  styleUrls: ['ap-help.component.scss']
})
export class ApHelpComponent implements OnDestroy, AfterContentInit, OnInit {
  @ViewChild(EditorComponent, {static: true}) editor: EditorComponent;
  public isEditMode = false;
  public apEditorSchema: Schema = ApEditorSchema;
  public languageData: Array<any> = [];
  public hasEditPermission = false;
  public selectedLanguage: ILanguage;

  private _subscriptions: Subscription[] = [];
  private dialogApplyKey = 'applyDialog';
  private currentBase64Image: string | ArrayBuffer;
  private targetComponent: string;

  private defaultDialogData: IHelpDialogData = {
    DialogType: undefined,
    YoutubeLink: '',
    YoutubeWidth: 560,
    YoutubeHeight: 315,
    AllowedExtensions: ApHelpImageUpload.AllowedExtensions
  };
  public apHelpEditorPlugins = (defaultPlugins: Plugin[]): Plugin[] => [
    ...defaultPlugins,
    ApHelpImageUpload.EditorImagePastePlugin,
  ]

  constructor(private routerStore: RouterStore,
              private formStore: FormStore,
              private helpArticlesStore: HelpArticlesStore,
              private languageStore: LanguageStore,
              private _elementRef: ElementRef,
              private translationStore: TranslationStore,
              private modalDialogStore: ModalDialogStore,
              private permissionPipe: GetPermissionPipe) {
  }

  /**
   * Some initialization logic
   * Check if user has permission to edit
   */
  ngOnInit(): void {
    // Checks if user has the right to edit help articles
    let hasEditHelpPermission = false;
    this.permissionPipe.transform(ApPermissions.EDIT_HELP_ARTICLES).subscribe(permission => {
      hasEditHelpPermission = permission;
    });
    this.hasEditPermission = hasEditHelpPermission;
    }

  /**
   * Some more initialization logic
   * Subscriptions, languages, etc.
   */
  ngAfterContentInit(): void {
    /**
     * Inject css and fonts for editor's iframe
     */
    setTimeout(() => this.injectIFrameCss());

    /**
     * Listen for loading help article
     */
    this._subscriptions.push(this.helpArticlesStore.Listen(s => s.latestHelpArticle)
      .subscribe(helpArticle => {
        if (helpArticle && helpArticle.TargetComponent === this.helpArticlesStore.Listen(s => s.latestTargetComponent).value &&
          helpArticle.LanguageKey === this.languageStore.Listen(s => s.selectedLanguage).value?.Key) {
          this.targetComponent = this.helpArticlesStore.Listen(s => s.latestTargetComponent).value;
          // there are rare cases where updating the editor's value does not work properly (within kendo editor component).
          // not sure about the reason but it might be an inconsistent state of the client/kendo editor?!
          // that's why we explicitly use kendo API to set HTML content and catch possible errors
          try {
            this.editor.value = helpArticle?.ContentHtml;
          } catch (ex) {
            console.warn(`updating help failed: ${JSON.stringify(ex)}`);
          }
        }
    }));

    /**
     * Listen for loading all languages and handle language-change for editor
     */
    this._subscriptions.push(this.languageStore.Listen(s => s.data).subscribe(languages => {
      this.languageData = new Array<any>();
      if (languages && languages.length > 0) {
        languages.forEach(l => {
            this.languageData.Add({
              languageKey: l.Key,
              languageName: l.LanguageName,
              language: l,
              click: (dataItem) => {
                this.selectedLanguage = dataItem.language;
                this.helpArticlesStore.loadHelpArticle(this.targetComponent, this.selectedLanguage.Key);
              }
            });
          }
        );
      }
    }));

    /**
     * Listen to component, route or language changes to load corresponding help article
     */
    this._subscriptions.push(combineLatest([
      this.languageStore.Listen(s => s.selectedLanguage),
      this.routerStore.Listen(s => s),
      this.formStore.Listen(s => s.updateComponent),
      this.formStore.Listen(s => s.update)
    ]).pipe(
      filter(([lang, route, component, compVisibleState]) => !!lang && !!route)
    ).subscribe(([lang, route, component, compVisibleState]) => {
      this.selectedLanguage = lang;
      let newTargetComponent = route?.url;

      // remove optional parameters from URL to have the plain URL as reference for help articles
      if (this.targetComponent?.includes('?')) {
        newTargetComponent = this.targetComponent.slice(0, this.targetComponent.indexOf('?'));
      }

      // in case there is a 'fly-in'/entrycomponent we need to reference this as well
      // to have different help for this components/wizards
      if (compVisibleState === ApVisibleStates.IN &&
        component?.component?.ɵcmp?.selectors?.length > 0 &&
        component?.component?.ɵcmp?.selectors[0]?.length > 0) {
        newTargetComponent += `@${component.component.ɵcmp.selectors[0][0]}`;
      }

      // we need to check if the targetComponent actually changed.
      // there are cases where this subscription gets triggered because user navigated to another component (which sometimes includes closing forms)
      // therefore the targetComponent did not change => no reload of help
      if (!newTargetComponent || newTargetComponent.length <= 0 || newTargetComponent === this.targetComponent ||
        this.helpArticlesStore.Listen(s => s.latestTargetComponent).value === newTargetComponent) {
        return;
      }
      this.targetComponent = newTargetComponent;
      this.helpArticlesStore.loadHelpArticle(this.targetComponent, this.selectedLanguage.Key);
    }));

    /**
     * Listen to 'Close' of custom modal dialogs
     */
    this._subscriptions.push(this.modalDialogStore.Listen(s => s.result).subscribe(dialogResult => {
      if (dialogResult?.key !== this.dialogApplyKey) {
        return;
      }
      const dialogData = dialogResult.formValues as IHelpDialogData;
      switch (dialogData?.DialogType) {
        case IHelpDialogType.Image:
          ApHelpImageUpload.insertImage(this.editor?.view, this.currentBase64Image); // base64 encoded image is stored in temporary variable
          break;
        case IHelpDialogType.Youtube:
          this.insertYoutubeVideo(dialogData);
          break;
        case IHelpDialogType.Save:
          this.saveArticle();
          break;
      }
      this.modalDialogStore.sendResult('', undefined); // reset dialog
    }));
  }

  /**
   * User clicks 'Edit-Button' to open Toolbar and enable editing.
   */
  public edit(): void {
    if (!this.hasEditPermission) {
      return;
    }
    this.isEditMode = true;
  }

  /**
   * Injects/Updates editor's iframe css classes to have Agriport styles and fonts
   * @private
   */
  private injectIFrameCss(): void {
    const apFontCssLink = document.createElement('link');
    apFontCssLink.href = 'assets/agriport-fonts.css';
    apFontCssLink.rel = 'stylesheet';
    apFontCssLink.type = 'text/css';

    const apHelpCssLink = document.createElement('link');
    apHelpCssLink.href = 'assets/style/kendo-help.css';
    apHelpCssLink.rel = 'stylesheet';
    apHelpCssLink.type = 'text/css';

    this._elementRef.nativeElement.querySelector('iframe').contentDocument?.head?.appendChild(apFontCssLink);
    this._elementRef.nativeElement.querySelector('iframe').contentDocument?.head?.appendChild(apHelpCssLink);
  }

  /**
   * Ask if user really wants to save help-article for the selected language
   */
  public openDialogSave(): void {
    const dialogData = this.defaultDialogData;
    dialogData.DialogType = IHelpDialogType.Save;
    const modalDialogData: IModalDialogData = {
      title: `${this.translationStore.FindTranslationForSelectedLanguage('Global__Save')}`,
      formConfig: {
        fieldSets: [
          new ApDynformsConfigFieldset({
            key: '',
            columns: 2,
            label: 'Base_Nsensor__WouldSaveChanges',
            useMaxWidth: true,
            config: [
              new ApDynformsConfigTextbox({
                label: `\t${this.translationStore.FindTranslationForSelectedLanguage('Admin_Pages_Popups__Language')}: ${this.selectedLanguage.LanguageName}`,
                key: 'DialogType',
                value: dialogData.DialogType,
                type: ApDynformsConfigTextboxType.Hidden
              }),
              new ApDynformsConfigPlaceholder()
            ]
          })
        ]
      },
      show: true,
      buttons: [
        {
          key: 'cancel',
          text: 'Global__Cancel',
          primary: false
        },
        {
          key: this.dialogApplyKey,
          text: 'Global__Save',
          primary: true
        }
      ],
    } as IModalDialogData;
    this.modalDialogStore.setModalDialogData(modalDialogData);
  }

  /**
   * Actually saves the article
   * @private
   */
  private saveArticle(): void {
    if (!this.editor || !this.selectedLanguage || !this.hasEditPermission) {
      return;
    }
    this.helpArticlesStore.saveHelpArticle(this.targetComponent, this.selectedLanguage.Key, this.editor.value);
    this.isEditMode = false;
  }

  /**
   * Ask for youtube link and video dimensions
   */
  public openDialogYoutube(): void {
    const dialogData = this.defaultDialogData;
    dialogData.DialogType = IHelpDialogType.Youtube;
    const modalDialogData: IModalDialogData = {
      title: this.translationStore.FindTranslationForSelectedLanguage('kendo.dialog.insertYoutube'),
      formConfig: {
        fieldSets: [
          new ApDynformsConfigFieldset({
            key: '',
            columns: 1,
            useMaxWidth: true,
            config: [
              new ApDynformsConfigTextbox({
                key: 'YoutubeLink',
                label: 'Youtube Link',
                value: null,
                type: ApDynformsConfigTextboxType.Url,
                validators: [new ApDynformsValidator({
                  validator: Validators.required,
                  errorKey: 'Settings__Msg_Vali_Value_Required'
                }),
                  new ApDynformsValidator({
                    validator: Validators.pattern('^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube(-nocookie)?\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$'),
                    errorKey: 'Text_ValueInvalid'
                  })]
              }),
              new ApDynformsConfigTextbox({
                key: 'YoutubeWidth',
                label: 'kendo.editor.width',
                type: ApDynformsConfigTextboxType.Number,
                value: dialogData.YoutubeWidth,
                validators: [new ApDynformsValidator({
                  validator: Validators.required,
                  errorKey: 'Settings__Msg_Vali_Value_Required'
                })]
              }),
              new ApDynformsConfigTextbox({
                key: 'YoutubeHeight',
                label: 'kendo.editor.width',
                type: ApDynformsConfigTextboxType.Number,
                value: dialogData.YoutubeHeight,
                validators: [new ApDynformsValidator({
                  validator: Validators.required,
                  errorKey: 'Settings__Msg_Vali_Value_Required'
                })]
              }),
              new ApDynformsConfigTextbox({
                key: 'DialogType',
                value: dialogData.DialogType,
                type: ApDynformsConfigTextboxType.Hidden
              })
            ]
          })
        ]
      },
      show: true,
      buttons: [
        {
          key: 'cancel',
          text: 'Global__Cancel',
          primary: false
        },
        {
          key: this.dialogApplyKey,
          text: 'Global__Insert',
          primary: true,
          disable: ModalDialogButtonDisable.FormInvalid
        }
      ],
    } as IModalDialogData;
    this.modalDialogStore.setModalDialogData(modalDialogData);
  }

  /**
   * Actually embeds the youtube video as iframe into the DOM of the editor.
   * @param dialogData
   */
  public insertYoutubeVideo(dialogData: IHelpDialogData): void {
    if (!dialogData.YoutubeLink || dialogData.YoutubeLink === '') {
      return;
    }
    const youtubeEmbedLink = dialogData.YoutubeLink.replace('watch?v=', 'embed/');
    const editorTransaction = this.editor?.view?.state?.tr;
    if (!editorTransaction) {
      return;
    }
    editorTransaction.insert(editorTransaction.selection.from, this.editor.schema.nodes.iframe.create({
      src: youtubeEmbedLink,
      width: dialogData.YoutubeWidth,
      height: dialogData.YoutubeHeight,
    }));
    this.editor?.view?.dispatch(editorTransaction);
  }

  /**
   * Ask for image to be uploaded
   */
  public openDialogImage(): void {
    const dialogData = this.defaultDialogData;
    this.currentBase64Image = undefined;
    dialogData.DialogType = IHelpDialogType.Image;
    const modalDialogData: IModalDialogData = {
      title: this.translationStore.FindTranslationForSelectedLanguage('kendo.dialog.uploadInsertImage'),
      formConfig: {
        fieldSets: [
          new ApDynformsConfigFieldset({
            key: '',
            columns: 2,
            useMaxWidth: true,
            label: `${this.translationStore.FindTranslationForSelectedLanguage('UploadCaptionSelect')}
            (${this.translationStore.FindTranslationForSelectedLanguage('UploadRestrictionSize')}: ${(ApHelpImageUpload.MaxFileSizeBytes / 1024 / 1024)} MB)`,
            config: [
              new ApDynformsConfigUpload({
                key: 'Image',
                autoUpload: true,
                multiple: false,
                restrictions: dialogData.AllowedExtensions,
                maxFileSize: ApHelpImageUpload.MaxFileSizeBytes,
                uploadEventHandler: this.onUploadImage.bind(this)
              }),
              new ApDynformsConfigTextbox({
                key: 'DialogType',
                value: dialogData.DialogType,
                type: ApDynformsConfigTextboxType.Hidden
              })
            ]
          })
        ]
      },
      show: true,
      buttons: [
        {
          key: 'cancel',
          text: 'Global__Cancel',
          primary: false
        },
        {
          key: this.dialogApplyKey,
          text: 'Global__Insert',
          primary: true
        }
      ],
    } as IModalDialogData;
    this.modalDialogStore.setModalDialogData(modalDialogData);
  }

  /**
   * Custom upload implementation: Selected file is converted to base64 content
   * @param e
   */
  public onUploadImage(e: UploadEvent): void {
    if (e?.files?.length <= 0) {
      return;
    }
    const component = this;
    const file = e?.files[0].rawFile;
    ApHelpImageUpload.readImageToBase64(file, (base64Image) => {
      if (base64Image) {
        component.currentBase64Image = base64Image;
        e.files[0].state = FileState.Uploaded;
      } else {
        component.currentBase64Image = undefined;
        e.files[0].state = FileState.Failed;
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}

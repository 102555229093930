import {Component, ViewChild} from '@angular/core';
import {ApDynformsComponent}  from '../../ap-dynforms/ap-dynforms.component';

@Component({
  selector: 'field-distribution-legend-selector',
  template: `
    <kendo-dropdownlist [data]="nutrients"
                        [value]="data.currentNutrient"
                        [popupSettings]="{width: 'auto'}"
                        (valueChange)="change($event)">
      <ng-template kendoComboBoxItemTemplate
                   kendoDropDownListValueTemplate
                   let-dataItem>
        {{dataItem}}
      </ng-template>
    </kendo-dropdownlist>
  `,
})
export class FieldDistributionLegendSelectorComponent {
  static type = 'FieldDistributionLegendSelectorComponent';

  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;

  data: {
    currentNutrient: string;
    selectionChanged: (nutrient: string) => void;
  };
  nutrients = ['P', 'K', 'Mg', 'pH'];

  change(nutrient: any): void {
    this.data.selectionChanged(nutrient);
  }
}

import {filter, isString, map}       from 'lodash';
import {ApGeoJsonPolygon}            from '../../interfaces/ap-geojson.interface';
import {LayerGroupNames, LayerNames} from '../layer-names';
import {ApBaseVectorLayer}           from '../ap-base-vector.layer';
import OlFeature                     from 'ol/Feature';
import OlStyle                       from 'ol/style/Style';
import OlFormatGeoJSON               from 'ol/format/GeoJSON';
import OlStyleStroke                 from 'ol/style/Stroke';
import {MAP_PROJECTION}              from '../ap-map.settings';
import {MapStore}                    from '../../../stores/map/map.store';
import OlStyleFill                   from 'ol/style/Fill';
import OlStyleText                   from 'ol/style/Text';
import {ApMapInstance}               from '../../ap-map.instance';
import {asString}                    from 'ol/color';
import {FieldStore}                  from '../../../stores/farm/field.store';
import {BehaviorSubject}             from 'rxjs';
import ITaskManagementPlanBook = Data.TaskManagement.ITaskManagementPlanBook;
import {LayerZIndex}                 from '../layer-z-index';


export interface ITaskManagementLayerSource {
  FieldId: System.IGuid;
  Id: System.IGuid;
  Geom: NetTopologySuite.Geometries.IGeometry;
}

const color = [176, 203, 31, 1];

/**
 * the TaskManagement layer shows fields of planning (might be outdated geometries) and optional raster
 */
export class ApTaskManagementLayer extends ApBaseVectorLayer {
  /**
   * the Projection of the task management fields
   */
  static readonly projection: string = 'EPSG:4326';

  private mapSubscriptionComplete = false;
  /**
   * the Default Style
   */
  static readonly defaultStyle = function (p): OlStyle {
    return new OlStyle({
      stroke: new OlStyleStroke({
        color: asString(color),
        width: 7,
        lineCap: 'round'
      }),
      text: new OlStyleText({
        font: '14px/14px Titillium Web',
        text: ApMapInstance.mapStore.Layers.FieldDescriptionLayer.layer.getVisible() ? p.get('label') : '',
        overflow: true,
        stroke: new OlStyleStroke({
          color: '#fff',
          width: 11
        }),
        fill: new OlStyleFill({
          color: '#000'
        }),
        padding: [-5, -15, -5, -15] // [top, right, bottom, left].
      })
    });
  };

  /**
   * create a new Layer
   */
  constructor(mapStore: MapStore, fieldStore$: BehaviorSubject<FieldStore>, data: ITaskManagementPlanBook[] = []) {
    super(LayerNames.TASK_MANAGEMENT, LayerGroupNames.TASK_MANAGEMENT, mapStore);
    this.FixedZIndex = LayerZIndex.TASK_MANAGEMENT;
    fieldStore$.subscribe(fs => this.replaceSource(data, fs));

    // when map is fully initialized subscribe to layer events
    this.mapStore.mapInitialized.subscribe(() => {
      // avoid multiple subscriptions
      if (this.mapSubscriptionComplete) {
        return;
      }
      this.mapSubscriptionComplete = true;
      this.mapStore.Layers.FieldDescriptionLayer.layer.on('change:visible', () => {
        if (this.layer?.getVisible()) {
          this.setStyle(ApTaskManagementLayer.defaultStyle);
        }
      });
      // Update nutrient layer visibility whenever this layer's visibility changes.
      this.layer.on('change:visible', () => {
        this.updateNutrientLayerVisibility();
      });

      // Update nutrient layer visibility whenever nutrient layer map-url(Source) changes.
      this.mapStore.Layers.TaskManagementNutrientLayer.layer.getSource().on('change', () => {
        this.updateNutrientLayerVisibility();
      });
    });
  }

  /**
   * Updates visibility of corresponding nutrient distribution layer depending
   * on visibility of this layer and if nutrient-layer has valid map urls.
   */
  private updateNutrientLayerVisibility(): void {
    const nutrientLayerUrls = this.mapStore.Layers.TaskManagementNutrientLayer.layer?.getSource()?.getUrls();
    const hasValidUrl = !!nutrientLayerUrls && nutrientLayerUrls.length > 0 && nutrientLayerUrls[0] !== '';
    // Show nutrient layer only when this layer is visible and when nutrient layer has a valid url.
    this.mapStore.Layers.TaskManagementNutrientLayer.Visibility = this.Visibility && hasValidUrl;
  }

  /**
   * replace the Data Source of the Layer
   */
  replaceSource(data: ITaskManagementLayerSource[], fieldStore: FieldStore): void {
    const features = this._read(data, fieldStore);
    if (!features || features.length === 0) {
      this.clear();
    } else {
      this.readFeatures(this._read(data, fieldStore), ApTaskManagementLayer.defaultStyle);
    }
  }

  /**
   * add the Data to the Source of the Layer
   */
  appendSource(data: ITaskManagementLayerSource[], fieldStore: FieldStore): void {
    if (this.Features.length > 0) {
      super.extentSource(this._read(data, fieldStore));
    } else {
      this.replaceSource(data, fieldStore);
    }
  }

  ZoomIn(duration?: number): void {
    if (this.Features.length > 0) {
      super.ZoomIn(duration);
    }
  }

  /**
   * read the Geometry Data from the List of Task-Management plans into Openlayers Layer
   */
  private _read(data: ITaskManagementLayerSource[], fieldStore: FieldStore): OlFeature[] {
    const withGeometry = filter(data, d => d && d.Geom && isString(d.Geom));
    return map(withGeometry, plan => {
      const field = ApMapInstance.fieldStore.Fields.find(f => f.Id === plan.FieldId);
      const geom = new OlFormatGeoJSON({
        dataProjection: ApTaskManagementLayer.projection,
        featureProjection: MAP_PROJECTION
      });
      const feat = geom.readFeature({
        type: 'Feature',
        geometry: this._parseJson(plan.Geom),
        properties: {
          label: this.getFieldExpressionByField(field, fieldStore),
          layerName: LayerNames.TASK_MANAGEMENT
        }
      });
      feat.setId(`${plan.Id}`);
      return feat;
    });
  }

  /**
   * parse the GeoJson String
   */
  private _parseJson(data: string): ApGeoJsonPolygon {
    try {
      return JSON.parse(data);
    } catch (err) {
      return null;
    }
  }
}

import {Pipe, PipeTransform}           from '@angular/core';
import {ApDynGridPropertyColumnConfig} from '../../config/ap-dyn-grid-property-column-config';
import {Trace}                         from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'getText',
  pure: false // workaround for https://angular-7mlowu.stackblitz.io
})
export class GetTextPipe implements PipeTransform {

  @Trace()
  transform(value: any, gridColumn: ApDynGridPropertyColumnConfig): string {
    return value && gridColumn.unit ? `${value} ${gridColumn.unit}` : value;
  }

}

import {IAction, IStoreBackend}             from 'invoker-transport';
import {EventEmitter}                       from '@angular/core';
import {ReactiveStore, SafeBehaviorSubject} from 'ts-tooling';
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import IGuid = System.IGuid;

export interface IStreamWatch {
  watchStream: (stream: { action: (new (payload?: any) => any), todo: (payload) => void }) => IStreamWatch;
}

export class Store<T> {
  AfterDatabaseUpdate = new EventEmitter();
  private reactiveStore: ReactiveStore<T> = null;

  constructor(private b: IStoreBackend, defaultState: T, everyChange = false) {
    this.reactiveStore = new ReactiveStore<T>(defaultState, everyChange);
  }

  Listen<K>(selector: (d: T) => K): SafeBehaviorSubject<K> {
    return this.reactiveStore.Listen(selector);
  }

  Mutate<K>(selector: (d: T) => K, mutation: (s: K) => K): void {
    this.reactiveStore.Mutate(selector, mutation);
  }

  Clear(): void {
    this.Mutate(s => s['data'], () => []);
    this.Mutate(s => s['loading'], () => false);
    this.Mutate(s => s['loaded'], () => false);
  }

  DispatchBackend(action: IAction): IStreamWatch {
    this.b.send(action);
    const watcher: IStreamWatch = {
      watchStream: (stream) => {
        const obs = this.b.registerObservable(stream.action)
          .subscribe(d => {
            obs.unsubscribe();
            stream.todo(d);
          });
        return watcher;
      }
    };
    return watcher;
  }

  SetLoadState(): void {
    this.Mutate(s => s['loaded'], () => false);
    this.Mutate(s => s['loading'], () => true);
  }

  SetLoadFinishState(): void {
    this.Mutate(s => s['loaded'], () => true);
    this.Mutate(s => s['loading'], () => false);
  }

  SetLoadFailState(): void {
    this.Mutate(s => s['loaded'], () => false);
    this.Mutate(s => s['loading'], () => false);
  }

  SetSaveState(): void {
    this.Mutate(s => s['saved'], () => false);
    this.Mutate(s => s['saving'], () => true);
  }

  SetSaveSuccessState(): void {
    this.Mutate(s => s['saved'], () => true);
    this.Mutate(s => s['saving'], () => false);
  }

  SetSaveFailState(): void {
    this.Mutate(s => s['saved'], () => false);
    this.Mutate(s => s['saving'], () => false);
  }

  UpdateSource(operation: DatabaseNotifyOperation, item: any, model: string): void {
    const data = this.Listen(s => s['data']).getValue();
    const position = data?.FindIndex(i => !!i && !!item && i.Id === item.Id);
    const itemExistsInList = position >= 0;
    if (operation === Agriport.Invoker.Api.Database.DatabaseNotifyOperation.Insert || operation === DatabaseNotifyOperation.Update) {
      this.UpsertData(position, item);
      this.AfterDatabaseUpdate.emit();
      return;
    }
    if (operation === Agriport.Invoker.Api.Database.DatabaseNotifyOperation.Delete && itemExistsInList) {
      this.RemoveData(position);
      this.AfterDatabaseUpdate.emit();
      return;
    }
  }

  UpsertData(position: number, item: any): void {
    this.Mutate(s => s['data'], o => {
      if (position >= 0) {
        o[position] = item;
      } else {
        o.Add(item);
      }
      return o;
    });
  }

  RemoveData(position: number): void {
    if (position >= 0) {
      this.Mutate(s => s['data'], o => {
        o.RemoveAt(position);
        return o;
      });
    }
  }

  /**
   * refresh the Data in the Current Store
   */
  Reload(): void {
    this.Mutate(s => s, o => o);
  }

  /**
   * refresh the Data in the Current Store on data property
   */
  ReloadSource(): void {
    this.Mutate(s => s['data'], o => [...o]);
  }

  /**
   * select the Property with the name of key from the data Source of the given Id in the Store
   */
  public GetProperty<K extends { Id: IGuid | string | number }, L>(id: IGuid | string | number, selector: (element: K) => L): L {
    const instrument = this.Listen<K[]>(s => s['data']).getValue().Find(i => i.Id === id);
    return instrument ? selector(instrument) : null;
  }
}

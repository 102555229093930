import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {SidebarMode}                                          from '../../ap-interface';
import {SIDEBAR_ROTATE_ICON, SIDEBAR_TRANSLATE_TEXT}          from '../../ap-animation/sidbar.animation';
import {PanelBarExpandMode, PanelBarItemModel}                from '@progress/kendo-angular-layout';
import {Trace}                                                from '../../debug-utils/ApplicationTracer';
import {MenuStore}                                            from '../../stores/layout/menu.store';
import {RouterStore}                                          from '../../stores/router/router.store';
import {filter, map}                                          from 'rxjs/operators';
import {LoginStore}                                           from '../../stores/login/login.store';
import {NgScrollbar}                                          from 'ngx-scrollbar';
import IMenuEntry = Data.MenuStructure.IMenuEntry;
import IMenuChildren = Data.MenuStructure.IMenuChildren;
import {ApRoleTypeService}                                    from '../../services/common/ap-role-type.service';

@Component({
  selector: 'ap-menu',
  templateUrl: 'ap-menu.component.html',
  animations: [
    SIDEBAR_TRANSLATE_TEXT,
    SIDEBAR_ROTATE_ICON
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApMenuComponent {
  @ViewChild(NgScrollbar) ngScrollbar;
  @Input()
  public visiblePanel = true;
  public sidebarMode$ = this.menuStore.Listen(s => s.mode);
  public menuData = this.menuStore.Listen(s => s.data)
    .pipe(
      filter(d => !!d),
      map(menu =>
        this.loginStore.User && ApRoleTypeService.hasAgriconRole(this.loginStore.User) ?
          menu.FindAll(m => m.Order > 0) : menu.FindAll(m => m.Order > 0 && m.Id !== 1)));
  public bottomMenuData = this.menuStore.Listen(s => s.data)
    .pipe(
      filter(d => !!d),
      map(menu => menu.FindAll(m => m.Order < 0))
    );
  public url$ = this.routerStore.Listen(s => s.url);
  public userSelectedUrl = '';
  public panelBarExpandMode = PanelBarExpandMode;
  public pageViewMode$ = this.menuStore.Listen(s => s.mode);

  constructor(public menuStore: MenuStore,
              private routerStore: RouterStore,
              private loginStore: LoginStore) {
  }

  /**
   * Angular ngFor trackBy implementation to avoid flickering of menu when
   * menu items have changed
   */
  @Trace()
  public menuTrackBy(index: number, item): string {
    return item.Id;
  }

  /**
   * change the Sidebar Mode from Half to Open and otherwise
   */
  @Trace()
  public toggleSidebar(): void {
    if (this.menuStore.getViewMode() === SidebarMode.OPEN) {
      this.userSelectedUrl = '';
      this.menuStore.halfSidebar();
    } else {
      this.menuStore.openSidebar();
    }
  }

  /**
   * as per kendo documentation this is the recommended way to
   * track menu user selections
   */
  @Trace()
  public menuStateChange(data: Array<PanelBarItemModel>): void {
    const focusedMenuItem: PanelBarItemModel = data.FindAll(item => item.focused === true).FirstOrDefault();
    if (focusedMenuItem) {
      this.userSelectedUrl = focusedMenuItem.id;
    } else {
      this.userSelectedUrl = '';
    }

    // refresh scrollbar with timeout => necessary because menu-animation takes 200ms
    setTimeout(() => this.ngScrollbar?.update(), 300);
  }

  /**
   * Expands a main menu item
   * (only for collapsed menu)
   */
  @Trace()
  public expandItem(main: IMenuEntry): void {
    if (!this.visiblePanel) {
      if (main) {
        this.userSelectedUrl = main.Url;
      } else {
        this.userSelectedUrl = '';
      }
    }
  }

  /**
   * navigate to the Target with the Angular Router
   */
  @Trace()
  public async navigate(menu: IMenuEntry | IMenuChildren): Promise<void> {
    if (menu.CanNavigate) {
      this.routerStore.navigate([menu.Url]).then(() => {
      });
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'apPlantProtectionTypemapping'
})
export class ApPlantProtectionTypemappingPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'A':
        return 'AgriConnect_Arkadicid';
      case 'B':
        return 'AgriConnect_Bactericid';
      case 'F':
        return 'AgriConnect_Fungicide';
      case 'H':
        return 'AgriConnect_Herbicide';
      case 'I':
        return 'AgriConnect_Insecticide';
      case 'M':
        return 'AgriConnect_Moluskicid';
      case 'P':
        return 'AgriConnect_Bite_Protection_Products';
      case 'R':
        return 'AgriConnect_Rodenticid';
      case 'W':
        return 'AgriConnect_GrowRegulator';
      default:
        return value;
    }
  }
}

import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.3 183">
  <polygon points="145.1,74.2 178.1,90.8 94.5,132.8 94.3,132.7 10.9,90.8 43.8,74.2 38.4,71.5 4.4,88.6 0,90.8 4.4,93
\t92.2,137.2 92.2,137.2 93.5,137.8 94.5,138.3 95.5,137.8 184.6,93 189,90.8 184.6,88.6 150.6,71.5" class="hoverFill"/>
  <polygon points="94.5,146.2 89.2,143.5 39.8,118.7 10.7,133.4 94.2,175.4 94.5,175.5 178.3,133.4 149.1,118.7"/>
  <polygon points="94.5,7.5 10.6,49.7 94.2,91.7 94.5,91.9 178.4,49.7 94.7,7.6"/>
</svg>
`;

@Component({
  selector: 'ap-icon-farm-records',
  template: CONTENT
})
export class ApIconFarmRecordsComponent {
}

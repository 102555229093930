import {IStateStore} from '../../ap-interface/interfaces/store';
import {Store}       from '../index';
import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  SubFarmCreate,
  SubFarmDelete,
  SubFarmsLoad,
  SubFarmsLoadSuccess,
  SubFarmUpdate
}                    from 'invoker-transport';
import ISubFarms = Data.BaseData.ISubFarms;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface ISubFarmStore extends IStateStore<ISubFarms> {
}

@Injectable({providedIn: 'root'})
export class SubFarmStore extends Store<ISubFarmStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(SubFarmsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadSubFarms(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new SubFarmsLoad([]));
  }

  public updateSubFarm(subFarm: ISubFarms): void {
    this.DispatchBackend(new SubFarmUpdate([
      {Name: 'subFarm', Type: ApCustomTypes.Data_BaseData_SubFarms, Value: subFarm}
    ]));
  }

  public createSubFarm(subFarm: ISubFarms): void {
    this.DispatchBackend(new SubFarmCreate([
      {Name: 'subFarm', Type: ApCustomTypes.Data_BaseData_SubFarms, Value: subFarm}
    ]));
  }

  public deleteSubFarm(subFarm: ISubFarms): void {
    this.DispatchBackend(new SubFarmDelete([
      {Name: 'subFarm', Type: ApCustomTypes.Data_BaseData_SubFarms, Value: subFarm}
    ]));
  }
}

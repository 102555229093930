<div [class]="'ap-pager'">
  <div [class.ap-pagerCell]="true"
       [class.ap-pagerCell-left]="true">
    <div *ngIf="selectedKeys.length" class="ap-pager-selected-keys">
      {{selectedKeys && selectedKeys.length}} {{'Global__Selected' | translate}}
    </div>
  </div>
  <div [class.ap-pagerCell]="true"
       [class.ap-pagerCell-center]="true">
    <kendo-pager-prev-buttons *ngIf="itemCount > pageSize"></kendo-pager-prev-buttons>
    <!--kendo-pager-numeric-buttons *ngIf="itemCount > pageSize" [buttonCount]="buttonCount"></kendo-pager-numeric-buttons-->
    <ap-grid-pager-numeric *ngIf="itemCount > pageSize" [pagerContextService]="pagerContextService"></ap-grid-pager-numeric>
    <kendo-pager-next-buttons *ngIf="itemCount > pageSize"></kendo-pager-next-buttons>
  </div>
  <div [class.ap-pagerCell]="true"
       [class.ap-pagerCell-right]="true">
    <ap-grid-page-size (pageSizeChanged)="onPageSizeChanged($event)"></ap-grid-page-size>
    <kendo-pager-info></kendo-pager-info>
  </div>
</div>
<div>
  <div [class.float-left]="selectedKeys.length && iconButtons">
    <ap-grid-multi-select #gridMultiSelectComponent
                          *ngIf="hasMultiSelect"
                          [(selectedValue)]="selectedValue"
                          [(selectedColumn)]="selectedColumn"
                          (selectedColumnChange)="onSelectedColumnChange($event)"
                          (selectedValueChange)="onSelectedValueChange($event)"
                          [columnItemDisabled]="columnItemDisabled"
                          [valueItemDisabled]="valueItemDisabled"
                          [type]="type"
                          [values]="values"
                          [objects]="objects"
                          [preselect]="preselect"
                          [showOnlyValues]="showOnlyValues"
                          [showWithoutSelection]="showWithoutSelection"
                          [selectedKeys]="selectedKeys"
                          [formConfig]="formConfig"
                          [formDefaultValue]="formDefaultValue"
                          (applyClicked)="applyClicked.emit($event)"
                          (deleteClicked)="deleteClicked.emit($event)"
                          (startInAnimation)="startMultiSelectInAnimation.emit($event)"
                          (endInAnimation)="endMultiSelectInAnimation.emit($event)"
                          (startOutAnimation)="startMultiSelectOutAnimation.emit($event)"
                          (endOutAnimation)="endMultiSelectOutAnimation.emit($event)"
    ></ap-grid-multi-select>
  </div>
  <div *ngIf="lowerButtons" [class]="'float-left'">
    <kendo-buttongroup>
      <button *ngFor="let button of lowerButtons"
              kendoButton
              class="ap-pagerElement k-button k-primary button-important"
              [aptooltip]="button.tooltip | translate"
              [perpetual]="true"
              (click)="button.callback.emit()">
        <ap-responsive-text [key]="button.name | translate"></ap-responsive-text>
      </button>
    </kendo-buttongroup>
  </div>
  <div *ngIf="selectedKeys.length && iconButtons" [class]="'float-right'">
    <kendo-buttongroup>
      <button *ngFor="let button of iconButtons" kendoButton [icon]="button.icon"
              (click)="button.callback.emit()" [aptooltip]="button.tooltip | translate"
              [perpetual]="true"></button>
    </kendo-buttongroup>
  </div>
</div>

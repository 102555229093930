import {IStateStore}                  from '../../ap-interface';
import {Store}                        from '../index';
import {EventEmitter, Injectable}     from '@angular/core';
import {
  ApCropPastRotationLoadSuccess,
  ApCropRotationLoad,
  ApCropRotationLoadSuccess,
  ApCropRotationProcessCell,
  ApCropRotationProcessCellFail,
  ApCropRotationProcessCellSuccess,
  ApCustomTypes
}                                     from 'invoker-transport';
import {cloneDeep}                    from 'lodash';
import {distinctUntilChanged, filter}       from 'rxjs/operators';
import {ObjectFactory, SafeBehaviorSubject} from 'ts-tooling';
import {LoginStore}                         from '../login/login.store';
import {
  ApCropRotationAttributesLoad,
  ApCropRotationAttributesLoadSuccess,
  ApCropRotationAttributesTrigger,
  ApCropRotationPastLoad
}                                     from '../../../../projects/invoker-transport/src/lib/actions/farm';
import {CropTypeStore}                from '../base-data/crop.types.store';
import ICropRotationCell = Data.FieldManagement.ICropRotationCell;
import IFieldCropRotation = Data.FieldManagement.IFieldCropRotation;
import IFieldCropRotationHistory = Data.FieldManagement.IFieldCropRotationHistory;
import ICropRotationYield = Data.FieldManagement.ICropRotationYield;
import ICropRotationAttributes = Data.FieldManagement.ICropRotationAttributes;
import {ApSignalrService}             from '../../ap-core/services/ap-signalr.service';

export class FieldCropRotationHistory {
  [key: string]: { [key: number]: CropRotationYield };
}

export class CropRotationYield implements ICropRotationYield {
  MainCrop: number;
  MainCropStraw: boolean;
  MainCropYield: number;
  SecondCrop: number;
  SecondCropYield: number;
  MainCropName: string;
  SecondCropName: string;
}

interface ICropRotationStore extends IStateStore<IFieldCropRotation> {
  marked: ICropRotationCell[];
  saving: ICropRotationCell[];
  history: FieldCropRotationHistory;
  historyLoading: boolean;
  cropRotationAttributes: ICropRotationAttributes[];
  cropRotationAttributesLoading: false;
}

@Injectable({providedIn: 'root'})
export class CropRotationStore extends Store<ICropRotationStore> {
  public loginStoreFinish = new EventEmitter<LoginStore>();

  constructor(public backend: ApSignalrService,
              private cropTypeStore: CropTypeStore) {
    super(backend, {
      data: [],
      loading: false,
      loaded: false,
      marked: [],
      saving: [],
      history: {},
      historyLoading: false,
      cropRotationAttributes: [],
      cropRotationAttributesLoading: false,
    }, true);
    this.loginStoreFinish.subscribe(loginStore => {
      loginStore.FieldStore.Fields$.pipe(
        distinctUntilChanged((previous, current) =>
          previous.length === current.length &&
          previous.every((v, i) =>
            v.FieldName === current[i]?.FieldName &&
            v.FieldNumber === current[i]?.FieldNumber &&
            v.FieldSubnumber === current[i]?.FieldSubnumber)),
        filter((fields) => fields.length !== 0)
      ).subscribe(() => {
        this.LoadFieldCropRotationPast();
        this.LoadCropRotationAttributes();
      });

      loginStore.FieldStore.Fields$.pipe(
        distinctUntilChanged((previous, current) =>
          previous.length === current.length &&
          previous.every((v, i) =>
            v.FieldCrops.length === v.FieldCrops.length &&
            v.FieldCrops.every((fc, ifc) =>
              fc.Cropkey === current[i].FieldCrops[ifc]?.Cropkey &&
              fc.CroptypeSecondId === current[i]?.FieldCrops[ifc]?.CroptypeSecondId &&
              fc.StrawHarvested === current[i]?.FieldCrops[ifc]?.StrawHarvested &&
              fc.ExpectedYield === current[i]?.FieldCrops[ifc]?.ExpectedYield &&
              fc.ExpectedYieldSecond === current[i]?.FieldCrops[ifc]?.ExpectedYieldSecond
            )
          )
        )
      ).subscribe(() => {
        super.Mutate(s => s, o => {
          return o;
        });
      });
      backend.registerObservable(ApCropRotationProcessCellSuccess).subscribe(d => {
        const cells = d.Data as ICropRotationCell[];
        super.Mutate(s => s, o => {
          for (const cell of cells) {
            const line = o.data.Find((rotation) => rotation?.Id === cell.FieldId);
            if (line && line.Yields[cell.YearIndex + 1]) {
              this.fillCropRotationLine(line, cell);
            }
            const tmp = o.data.FindAll((rotation) => rotation.Id !== cell.FieldId);
            if (line) {
              tmp.AddRange([line]);
            }
            o.data = tmp;
            o.saving = o.saving.FindAll(i2 => i2.FieldId !== cell.FieldId && i2.Year !== cell.Year);
          }
          return o;
        });
        if (loginStore.CampaignYearStore.SelectedCampaignYear) {
          loginStore.FieldStore.loadFields(loginStore.CampaignYearStore.SelectedCampaignYear.DefaultStart);
        }
      });
    });
    backend.registerObservable(ApCropRotationProcessCellFail).subscribe(d => {
      const cells = d.Data as ICropRotationCell[];
      super.Mutate(s => s, o => {
        for (const cell of cells) {
          const line = o.data.Find(_ => _?.Id === cell.FieldId);
          const emptyValue = {
            MainCrop: null,
            MainCropYield: 0,
            MainCropStraw: false,
            SecondCrop: null,
            SecondCropYield: 0,
            YearIndex: cell.YearIndex,
            Year: cell.Year
          } as ICropRotationCell;
          if (line && line.Yields[cell.YearIndex + 1]) {
            this.fillCropRotationLine(line, emptyValue);
          } else {
            line?.Yields?.Add(emptyValue);
          }
          const tmp = o.data.FindAll(_ => _.Id !== cell.FieldId);
          if (line) {
            tmp.AddRange([line]);
          }
          o.data = tmp;
          o.saving = o.saving.FindAll(i2 => i2.FieldId !== cell.FieldId && i2.Year !== cell.Year);
        }
        return o;
      });
    });
    backend.registerObservable(ApCropRotationLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(ApCropRotationAttributesTrigger).subscribe(() => {
      this.LoadCropRotationAttributes();
    });
    backend.registerObservable(ApCropPastRotationLoadSuccess).subscribe(d => {
      super.Mutate(s => s.historyLoading, () => false);
      super.Mutate(s => s.history, () => {
        const history = {};
        d.Data.forEach((data: IFieldCropRotationHistory) => {
          const years = Object.keys(data.CropRotation);
          for (const year of years) {
            data.CropRotation[year].MainCropName = this.cropTypeStore.GetCropTypeName(data.CropRotation[year].MainCrop);
            data.CropRotation[year].SecondCropName = this.cropTypeStore.GetCropTypeName(data.CropRotation[year].SecondCrop);
          }
          history[data.FieldId as string] = data.CropRotation;
        });
        return history;
      });
    });
    backend.registerObservable(ApCropRotationAttributesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.cropRotationAttributes, () => d.Data);
      super.Mutate(s => s.cropRotationAttributesLoading, () => false);
    });
  }

  private fillCropRotationLine(line: IFieldCropRotation, cell: ICropRotationCell): void {
    line.Yields[cell.YearIndex + 1].MainCrop = cell.MainCrop;
    line.Yields[cell.YearIndex + 1].MainCropYield = cell.MainCropYield;
    line.Yields[cell.YearIndex + 1].MainCropStraw = cell.MainCropStraw;
    line.Yields[cell.YearIndex + 1].SecondCrop = cell.SecondCrop;
    line.Yields[cell.YearIndex + 1].SecondCropYield = cell.SecondCropYield;
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get GetAttributes$(): SafeBehaviorSubject<ICropRotationAttributes[]> {
    return this.Listen(s => s.cropRotationAttributes);
  }

  public get HistoryLoading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.historyLoading);
  }

  public get GetHistory$(): SafeBehaviorSubject<FieldCropRotationHistory> {
    return this.Listen(s => s.history);
  }

  public get GetHistory(): FieldCropRotationHistory {
    return this.GetHistory$.getValue();
  }

  public get FieldCropRotations$(): SafeBehaviorSubject<IFieldCropRotation[]> {
    return super.Listen(s => s.data);
  }

  public get FieldCropRotationsLoading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get FieldCropSaving$(): SafeBehaviorSubject<ICropRotationCell[]> {
    return super.Listen(s => s.saving);
  }

  public get CropRotation(): IFieldCropRotation[] {
    return super.Listen(s => s.data).getValue();
  }

  public get MarkedForSaving(): ICropRotationCell[] {
    return super.Listen(s => s.marked).getValue();
  }

  public LoadFieldCropRotation(): void {
    if (super.Listen(s => s.data).getValue().length === 0) {
      super.Mutate(s => s.loading, () => true);
    }
    this.DispatchBackend(new ApCropRotationLoad([]));
  }

  public LoadFieldCropRotationPast(): void {
    super.Mutate(s => s.historyLoading, () => true);
    super.Mutate(s => s.history, () => ({}));
    this.DispatchBackend(new ApCropRotationPastLoad([]));
  }

  public ProcessCells(cells: ICropRotationCell[]): void {
    super.Mutate(s => s.saving, () => cells);
    this.DispatchBackend(new ApCropRotationProcessCell([
      {Name: 'cells', Type: ApCustomTypes.Data_FieldManagement_CropRotationCell + '[]', Value: cells}
    ]));
  }

  public MarkForSaving(cells: ICropRotationCell[]): void {
    super.Mutate(s => s.marked, o => {
      const tmp = cloneDeep(o);
      for (const i of cells) {
        const idx = o.FindIndex(saving => saving.FieldId === i.FieldId && saving.Year === i.Year);
        if (idx !== -1) {
          tmp[idx] = i;
        } else {
          tmp.push(i);
        }
      }
      return tmp;
    });
  }

  public UnmarkFromSaving(cells: ICropRotationCell[]): void {
    super.Mutate(s => s.marked, o => {
      for (const i of cells) {
        o = o.FindAll(m => m.FieldId !== i.FieldId && m.Year !== i.Year);
      }
      return o;
    });
  }

  public LoadCropRotationAttributes(): void {
    super.Mutate(s => s.cropRotationAttributesLoading, () => true);
    this.DispatchBackend(new ApCropRotationAttributesLoad([]));
  }
}

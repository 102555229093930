import OlFeature          from 'ol/Feature';
import OlStyle            from 'ol/style/Style';
import OlCricleStyle      from 'ol/style/Circle';
import OlStyleStroke      from 'ol/style/Stroke';
import {unByKey}          from 'ol/Observable';
import {getVectorContext} from 'ol/render';
import {easeOut, easeIn}          from 'ol/easing';
import OlMap              from 'ol/Map';
import BaseLayer          from 'ol/layer/Base';
import {Color, asString}            from 'ol/color';

export interface IPingOptions {
  layer: BaseLayer;
  // the time of the animation in ms
  duration: number;
  // the feature to ping
  feature?: OlFeature;
  // a reference of the Openlayers Map
  map: () => OlMap;
  // the start radius of the Circle
  minRadius: number;
  // the end radius of the Circle
  maxRadius: number;
  // the width of the Circle Stroke
  strokeWidth: number;
  // the color of the Circle in hexcode or rgba notation
  color: Color;
  filter?: (f: OlFeature) => boolean;
}

/**
 * creates a Circle around the Geometry that grows
 */
export function pingLocation(opts: IPingOptions): void {
  if (!opts || !opts.feature || !opts.map()) {
    return;
  }
  if (opts.filter && !opts.filter(opts.feature)) {
    return;
  }
  if (opts.minRadius < 0) {
    opts.minRadius = 0;
  }
  if (opts.maxRadius < 0) {
    opts.maxRadius = 1;
  }
  if (opts.duration < 0) {
    opts.duration = 250;
  }
  if (opts.strokeWidth < 0.001) {
    opts.strokeWidth = 1;
  }
  if (!opts.color) {
    opts.color = [176, 203, 31, 1];
  }
  const start = Date.now();
  const flashGeom = opts.feature.getGeometry().clone();
  const listenerKey = opts.layer.on('postrender', animate);

  function animate(event): void {
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= opts.duration) {
      unByKey(listenerKey);
      return;
    }
    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / opts.duration;
    // radius will be 5 at start and 30 at end.
    const radius = easeOut(elapsedRatio) * (opts.maxRadius - opts.minRadius) + opts.minRadius;

    const style = new OlStyle({
      image: new OlCricleStyle({
        radius,
        stroke: new OlStyleStroke({
          color: asString(opts.color),
          width: opts.strokeWidth,
        }),
      }),
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    opts.map().render();
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IMachine = Data.DocuContext.Machine.IMachine;
import IMachineType = Data.BaseData.IMachineType;

export enum MachinesActions {
  MACHINES_LOAD = '[machines] load',
  MACHINES_LOAD_SUCCESS = '[machines] load success',
  MACHINES_LOAD_FAIL = '[machines] load fail',
  MACHINES_UPDATE = '[machines] update',
  MACHINES_CREATE = '[machines] create',
  MACHINES_DELETE = '[machines] delete',
  MACHINES_SAVE_SUCCESS = '[machines] save success',
  MACHINES_SAVE_FAIL = '[machines] save fail',
  MACHINES_GET_IN_USE_DAYS = '[machines] get in use days',
  MACHINES_GET_IN_USE_DAYS_SUCCESS = '[machines] get in use days success',
  MACHINES_GET_IN_USE_DAYS_FAIL = '[machines] get in use days fail',
  MACHINES_MERGE = '[machines] merge',
  MACHINE_TYPES_LOAD = '[machine types] load',
  MACHINE_TYPES_LOAD_SUCCESS = '[machine types] load success',
}

export class MachinesLoad implements IAction {
  readonly type = MachinesActions.MACHINES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesLoadSuccess implements IAction {
  readonly type = MachinesActions.MACHINES_LOAD_SUCCESS;

  constructor(public payload: IMachine[]) {
  }
}

export class MachinesUpdate implements IAction {
  readonly type = MachinesActions.MACHINES_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesCreate implements IAction {
  readonly type = MachinesActions.MACHINES_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesDelete implements IAction {
  readonly type = MachinesActions.MACHINES_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesSaveSuccess implements IAction {
  readonly type = MachinesActions.MACHINES_SAVE_SUCCESS;

  constructor(public payload: IMachine) {
  }
}

export class MachinesSaveFail implements IAction {
  readonly type = MachinesActions.MACHINES_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

export class MachinesGetInUseDays implements IAction {
  readonly type = MachinesActions.MACHINES_GET_IN_USE_DAYS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesGetInUseDaysSuccess implements IAction {
  readonly type = MachinesActions.MACHINES_GET_IN_USE_DAYS_SUCCESS;

  constructor(public payload: string[]) {
  }
}

export class MachinesGetInUseDaysFail implements IAction {
  readonly type = MachinesActions.MACHINES_GET_IN_USE_DAYS_FAIL;

  constructor(public payload: string) {
  }
}

export class MachineTypesLoad implements IAction {
  readonly type = MachinesActions.MACHINE_TYPES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachineTypesLoadSuccess implements IAction {
  readonly type = MachinesActions.MACHINE_TYPES_LOAD_SUCCESS;

  constructor(public payload: IMachineType[]) {
  }
}

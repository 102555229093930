import IMenuEntry = Data.MenuStructure.IMenuEntry;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum SidebarActions {
  SIDEBAR_ADD_DATASOURCE = '[sidebar] add datasource',
  SIDEBAR_ADD_DATASOURCE_SUCCESS = '[sidebar] add datasource success',
}

/**
 * fill Sidebar Data Source
 */
export class SidebarAddDatasource implements IAction {
  readonly type = SidebarActions.SIDEBAR_ADD_DATASOURCE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SidebarAddDatasourceSuccess implements IAction {
  readonly type = SidebarActions.SIDEBAR_ADD_DATASOURCE_SUCCESS;

  constructor(public payload: IMenuEntry[]) {
  }
}

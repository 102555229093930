import {Component} from '@angular/core';

const CONTENT = `
<svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
    <path d="m202 452c-2.628906 0-5.210938 1.070312-7.070312 2.929688-1.859376 1.859374-2.929688 4.441406-2.929688 7.070312s1.070312 5.210938 2.929688 7.070312c1.859374 1.859376 4.441406 2.929688 7.070312 2.929688s5.210938-1.070312 7.070312-2.929688c1.859376-1.859374 2.929688-4.441406 2.929688-7.070312s-1.070312-5.210938-2.929688-7.070312c-1.859374-1.859376-4.441406-2.929688-7.070312-2.929688zm0 0"/>
    <path class="hoverFill" d="m501.324219 136.320312c-14.210938-14.210937-37.332031-14.210937-51.542969 0l-45.78125 45.78125v-148.101562c0-18.746094-15.253906-34-34-34h-336c-18.746094 0-34 15.253906-34 34v444c0 18.746094 15.253906 34 34 34h336c18.746094 0 34-15.253906 34-34v-192.808594l97.324219-97.328125c14.210937-14.210937 14.210937-37.332031 0-51.542969zm-117.324219-102.320312v168.101562l-20 20v-172.101562c0-5.523438-4.476562-10-10-10h-66.832031v-20h82.832031c7.71875 0 14 6.28125 14 14zm-54.601562 274.246094 13.5-30.328125 16.828124 16.828125zm-62.230469-288.246094v40.75c0 6.203125-5.046875 11.25-11.25 11.25h-107.835938c-6.203125 0-11.25-5.046875-11.25-11.25v-40.75zm102.832031 472h-336c-7.71875 0-14-6.28125-14-14v-444c0-7.71875 6.28125-14 14-14h82.832031v20h-66.832031c-5.523438 0-10 4.476562-10 10v412c0 5.523438 4.476562 10 10 10h112c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-102v-392h56.832031v.75c0 17.230469 14.019531 31.25 31.25 31.25h107.835938c17.230469 0 31.25-14.019531 31.25-31.25v-.75h56.832031v182.101562l-11.390625 11.386719c-.863281.867188-1.5625 1.886719-2.0625 3.003907l-30.015625 67.417968c-1.683594 3.785156-.863281 8.210938 2.0625 11.140625 1.917969 1.914063 4.476562 2.929688 7.074219 2.929688 1.375 0 2.757812-.28125 4.066406-.863281l30.265625-13.476563v128.359375h-102c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h112c5.519531 0 10-4.476562 10-10v-147.265625l17.152344-7.636719c1.054687-.46875 2.011718-1.121094 2.847656-1.921875v172.824219c0 7.71875-6.28125 14-14 14zm79.910156-281.007812-72.824218 72.828124-23.261719-23.261718 90.167969-90.167969 23.261718 23.261719zm37.273438-37.269532-5.789063 5.785156-23.257812-23.257812 5.785156-5.785156c6.414063-6.414063 16.847656-6.414063 23.261719 0 6.410156 6.410156 6.410156 16.84375 0 23.257812zm0 0"/>
    <path d="m162 120h-64c-5.523438 0-10 4.476562-10 10v64c0 5.523438 4.476562 10 10 10h64c5.523438 0 10-4.476562 10-10v-64c0-5.523438-4.476562-10-10-10zm-10 64h-44v-44h44zm0 0"/>
    <path d="m162 230h-64c-5.523438 0-10 4.476562-10 10v64c0 5.523438 4.476562 10 10 10h64c5.523438 0 10-4.476562 10-10v-64c0-5.523438-4.476562-10-10-10zm-10 64h-44v-44h44zm0 0"/>
    <path d="m162 340h-64c-5.523438 0-10 4.476562-10 10v64c0 5.523438 4.476562 10 10 10h64c5.523438 0 10-4.476562 10-10v-64c0-5.523438-4.476562-10-10-10zm-10 64h-44v-44h44zm0 0"/>
    <path d="m233.578125 191.644531c1.953125 1.953125 4.511719 2.929688 7.070313 2.929688 2.558593 0 5.117187-.976563 7.070312-2.929688l45.148438-45.148437c3.902343-3.902344 3.902343-10.234375 0-14.140625-3.90625-3.90625-10.238282-3.90625-14.144532 0l-38.074218 38.074219-15.175782-15.175782c-3.90625-3.90625-10.234375-3.90625-14.144531 0-3.902344 3.90625-3.902344 10.238282 0 14.140625zm0 0"/>
    <path d="m233.578125 301.644531c1.875 1.875 4.417969 2.929688 7.070313 2.929688 2.652343 0 5.195312-1.054688 7.070312-2.929688l45.148438-45.144531c3.902343-3.90625 3.902343-10.238281 0-14.144531-3.90625-3.902344-10.238282-3.902344-14.144532 0l-38.074218 38.078125-15.175782-15.175782c-3.90625-3.90625-10.234375-3.90625-14.144531 0-3.902344 3.902344-3.902344 10.234376 0 14.140626zm0 0"/>
    <path d="m252.097656 396.144531 15.503906 15.5c1.953126 1.953125 4.511719 2.929688 7.070313 2.929688s5.117187-.976563 7.070313-2.929688c3.90625-3.90625 3.90625-10.238281 0-14.140625l-15.5-15.503906 15.5-15.503906c3.90625-3.902344 3.90625-10.234375 0-14.140625s-10.234376-3.90625-14.140626 0l-15.503906 15.5-15.5-15.5c-3.90625-3.902344-10.234375-3.90625-14.144531 0-3.902344 3.90625-3.902344 10.238281 0 14.140625l15.503906 15.503906-15.503906 15.503906c-3.902344 3.902344-3.902344 10.234375 0 14.140625 1.953125 1.953125 4.515625 2.929688 7.074219 2.929688s5.117187-.976563 7.070312-2.929688zm0 0"/>
</svg>
`;

@Component({
  selector: 'ap-documentation-icon',
  template: CONTENT,
})
export class ApDocumentationIconComponent {
}

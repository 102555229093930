import {Pipe, PipeTransform}  from '@angular/core';
import {ILegendSelectionItem} from '../../../../stores/map/map.legend.store';

@Pipe({
  name: 'getLegendSelectionValues'
})
export class GetLegendSelectionValuesPipe implements PipeTransform {

  transform(values: ILegendSelectionItem[] | ((...args: string[]) => ILegendSelectionItem[]), currentSelection: string): ILegendSelectionItem[] {
    let result: ILegendSelectionItem[];
    if (values === undefined || values === null) {
      result = [];
    } else if (Array.isArray(values)) {
      result = values;
    } else {
      result = values(...currentSelection.Split('_'));
    }
    return result;
  }
}

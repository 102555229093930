<ap-section-header *ngIf="caption">
  <ap-section-header-grid
    [iconId]="headerIcon"
    [caption]="caption"
    [filterPlaceholder]="'Global_Filter'"
    [filterTooltip]=""
    [createText]="'Docu_Ini__New'"
    [svg]="headerSvg"
    [showCanCreateButton]="false"
    [canCreate]="false"
    [canSearch]="false"
  ></ap-section-header-grid>
</ap-section-header>

<form *ngIf="showForm"
      [formGroup]="form"
      [class]="'k-form container ap-form'"
      [style.position]="'static'"
      [style.margin-top.px]="offsetTop"
      [style.margin-bottom.px]="offsetBottom"
      [style.width]="width"
      [class.dark-bg]="darkMode"
      [class.k-disabled]="loading$ | async"
      [@hideForm]="{value: formState, params: {display: display}}"
      (ngSubmit)="onSubmitDynForm()">
  <div *ngIf="tabs"
       [class]="'ap-tabstrip-small'"
       [class.dark-bg]="darkMode">
    <kendo-tabstrip #tabStrip (tabSelect)="tabSelect($event)">
      <kendo-tabstrip-tab *ngFor="let tab of tabs" [selected]="tab.selected"
                          [disabled]="(tab.disabledFn ? (tab.disabledFn | execFunction:form) : tab.disabled) | async">
        <ng-template kendoTabTitle>
          <ap-responsive-text [key]="tab.title" [class]="'ap-tabstrip-header'"></ap-responsive-text>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

  <div [ngClass]="'ap-form-content' + (isModalForm ? ' modal-form-full-width' : '')"
       [class.dark-bg]="darkMode"
       [class.no-header]="!caption"
       [class.no-action]="actionWrapper.children.length === 0">

    <ng-content select="[dynforms.before]"></ng-content>
    <fieldset *ngFor="let fieldset of fieldsets"
              [class]="fieldset.class"
              [id]="fieldset.key | sanitizeId"
              [style.border]="fieldset.legend == null ? 0 : ''">
      <legend *ngIf="!fieldset.hideLegend" [id]="fieldset.key + '_fs_legend' | sanitizeId"
              [class.dark-bg]="darkMode"
              [style.font-size.px]="fontSize + 3">{{ fieldset.asyncTranslation ? (fieldset.legend | translateAsync | async) : (fieldset.legend | translate)}}</legend>
      <label *ngIf="fieldset.label"
             [id]="fieldset.key + '_fs_label' | sanitizeId" [class]="'ap-dynform-fieldset-label'">
        {{fieldset.asyncTranslation ? (fieldset.label | translateAsync | async) : ((fieldset.label | translate))}}
      </label>
      <ng-container *ngIf="fieldset.before"
                    [ngTemplateOutlet]="fieldset.before"
      ></ng-container>
      <div [ngClass]="fieldset.useMaxWidth ? 'ap-dynform-fieldset-max' : 'ap-dynform-fieldset'"
           [style.column-gap]="getColumnGap | execFunction:fieldset:isStatic">
        <ng-container *ngFor="let ordered of fieldsetsOrder[fieldset.legend]; index as index">
          <ng-container *ngIf="ordered.config !== undefined">
            <ng-container [ngSwitch]="ordered.config.controlType">
              <ap-dynforms-daterange *ngSwitchCase="ApDynformsControltype.DateRange"
                                     [config]="ordered.config"
                                     [form]="form"
                                     [fontSize]="fontSize"
                                     [darkMode]="darkMode"
                                     [style.grid-column]="ordered.column + 1"
                                     [style.grid-row]="ordered.row + 1"
                                     [style.grid-column-end]="getSpan(ordered, fieldsetsOrder[fieldset.legend][index + 1], 'column')"
                                     [style.grid-row-end]="getSpan(ordered, fieldsetsOrder[fieldset.legend][index + 1], 'row')"
                                     [startPos]="ordered"
                                     [endPos]="fieldsetsOrder[fieldset.legend][index + 1]"
                                     [columnGap]="fieldset.columnGap ? ((40/fieldset.columns) / (fieldset.columns-1)) + '%' : '0%'">
              </ap-dynforms-daterange>
              <ap-dynforms-element *ngSwitchDefault
                                   [class]="'ap-dynform-element'"
                                   [asyncTranslation]="fieldset.asyncTranslation"
                                   [config]="ordered.config"
                                   [form]="form"
                                   [style.grid-column]="ordered.column + 1"
                                   [style.grid-row]="ordered.row + 1"
                                   [fontSize]="fontSize"
                                   [darkMode]="darkMode">
              </ap-dynforms-element>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ap-dynforms-array *ngFor="let config of fieldset.arrayConfig"
                         [config]="config"
                         [form]="form"
                         [darkMode]="darkMode"
                         [fontSize]="fontSize">
      </ap-dynforms-array>
    </fieldset>
    <ng-content select="[dynforms.after]"></ng-content>
  </div>
  <div #actionWrapper [class.ap-dynforms-action-wrapper]="actionWrapper.children.length !== 0">
    <ng-content select="[dynforms.action]"></ng-content>
  </div>
</form>
<div *ngIf="loading$ | async" class="k-loading-image"></div>

import {IAction, IApInvokerArguments} from '../../interfaces';
import IEffectiveRange = Data.BaseData.IEffectiveRange;

export enum EffectiveRangesAction {
  EFFECTIVE_RANGES_LOAD = '[effective ranges] load',
  EFFECTIVE_RANGES_LOAD_SUCCESS = '[effective ranges] load success',
}

export class EffectiveRangesLoad implements IAction {
  readonly type = EffectiveRangesAction.EFFECTIVE_RANGES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class EffectiveRangesLoadSuccess implements IAction {
  readonly type = EffectiveRangesAction.EFFECTIVE_RANGES_LOAD_SUCCESS;

  constructor(public payload: IEffectiveRange[]) {
  }
}

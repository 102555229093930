import {IStateStore}                         from '../../ap-interface/interfaces/store';
import {Store}                               from '../index';
import {Injectable}                          from '@angular/core';
import {TerminalsLoad, TerminalsLoadSuccess} from '../../../../projects/invoker-transport/src/lib/actions/base-data';
import ITerminals = Data.BaseData.ITerminals;
import {ApSignalrService}                    from '../../ap-core/services/ap-signalr.service';

interface ITerminalsStore extends IStateStore<ITerminals> {
}

@Injectable({providedIn: 'root'})
export class TerminalsStore extends Store<ITerminalsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: []
    });

    backend.registerObservable(TerminalsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => {
      this.ReloadSource();
    });
  }

  /**
   *  load terminals from Backend to the Store
   */
  public loadTerminals(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new TerminalsLoad([]));
  }

  public get Terminals(): ITerminals[] {
    return this.Listen(s => s.data).getValue();
  }
}

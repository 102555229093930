import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {get}                                                       from 'lodash';

@Component({
  selector: 'ap-status-switch',
  templateUrl: './ap-status-switch.component.html',
  styleUrls: ['./ap-status-switch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApStatusSwitchComponent {
  @Input() disableGreen = false;
  @Input() disableRed = false;
  @Input() disableYellow = false;
  @Input() readonly = false;

  @Input() set dataItem(value: any) {
    this._dataItem = value;
    this.updateState();
  }

  @Input() set field(fieldName: string) {
    this._fieldName = fieldName;
    this.updateState();
  }

  @Input() toolTipGreen: string;
  @Input() toolTipRed: string;
  @Input() toolTipYellow: string;
  @Input() showYellowState = true;
  @Output() stateChange = new EventEmitter<number>();

  // the actual state of the underlying dataitem
  public state: number = undefined;
  private _dataItem: any;

  // the field where to get the status from
  private _fieldName: string;
  // switch component can be disabled after user clicked
  public disabled = false;
  // When user clicked a new status it is stored here
  // and displayed as grey/intermediate state
  // whenever dataitem is refreshed the intermediate state is deleted again
  public intermediateState: number = undefined;

  /**
   * Method is called every time the dataitem  is updated by change detection
   */
  updateState(): void {
    if (!this._dataItem || !this._fieldName) {
      return;
    }
    // When user clicked a new status it is stored here
    // and displayed as grey/intermediate state
    // whenever dataitem is refreshed the intermediate state is deleted again
    this.intermediateState = undefined;
    this.disabled = false;
    this.state = get(this._dataItem, this._fieldName);
  }

  setState(state: number): void {
    if (this.state !== state) {
      if (!this.readonly) {
        this.state = state;
      }
      this.intermediateState = state;
      this.disabled = true;
      setTimeout(() => this.stateChange.emit(state), 0);
    }
  }
}

export class CssValueParser {
  public static getNumberValue(value: string): [number, string] {
    let val;
    let unit;
    if (value.includes('px')) {
      val = value.split('px')[0].trim();
      unit = 'px';
    }
    if (value.includes('%')) {
      val = value.split('%')[0].trim();
      unit = '%';
    }
    if (value.includes('em')) {
      val = value.split('em')[0].trim();
      unit = 'em';
    }
    if (value.includes('rem')) {
      val = value.split('rem')[0].trim();
      unit = 'rem';
    }
    if (value.includes('pt')) {
      val = value.split('pt')[0].trim();
      unit = 'pt';
    }
    const v = parseFloat(val);
    return isNaN(v) ? [0, ''] : [v, unit];
  }

  public static getCssVariable(variableName: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
  }

  public static hexToRGBA(hex: string, alpha: number): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}

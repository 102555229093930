<kendo-multiselect
  [data]="data"
  [filterable]="true"
  [valuePrimitive]="true"
  [value]="value"
  [className]="'status-switch-filter'"
  (valueChange)="onChange($event)">
  <ng-template kendoMultiSelectTagTemplate let-dataItem>
    <ap-icon-selector *ngIf="dataItem === -1"
                      [id]="'ap-times-circle-solid-icon'"
                      [cssClass]="'icon red'"
                      [class.checked]="true">
    </ap-icon-selector>
    <ap-icon-selector *ngIf="dataItem === 0"
                      [id]="'ap-exclamation-circle-solid-icon'"
                      [cssClass]="'icon yellow'"
                      [class.checked]="true">
    </ap-icon-selector>
    <ap-icon-selector *ngIf="dataItem === 1"
                      [id]="'ap-check-circle-solid-icon'"
                      [cssClass]="'icon green'"
                      [class.checked]="true">
    </ap-icon-selector>
  </ng-template>
  <ng-template kendoMultiSelectItemTemplate let-dataItem>
    <ap-icon-selector *ngIf="dataItem === -1"
                      [id]="'ap-times-circle-solid-icon'"
                      [cssClass]="'icon red status'"
                      [class.checked]="true">
    </ap-icon-selector>
    <ap-icon-selector *ngIf="dataItem === 0"
                      [id]="'ap-exclamation-circle-solid-icon'"
                      [cssClass]="'icon yellow status'"
                      [class.checked]="true">
    </ap-icon-selector>
    <ap-icon-selector *ngIf="dataItem === 1"
                      [id]="'ap-check-circle-solid-icon'"
                      [cssClass]="'icon green status'"
                      [class.checked]="true">
    </ap-icon-selector>
  </ng-template>
</kendo-multiselect>

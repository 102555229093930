import {IStateStoreWritable}             from '../../ap-interface/interfaces/store';
import {Store}                           from '../index';
import {Injectable}                      from '@angular/core';
import {ApCustomTypes} from 'invoker-transport';
import {
  UserContactsCreate,
  UserContactsDelete,
  UserContactsLoad,
  UserContactsLoadSuccess,
  UserContactsSaveFail,
  UserContactsSaveSuccess,
  UserContactsUpdate
}                                        from '../../../../projects/invoker-transport/src/lib/actions/base-data';
import {NotifyStore}                     from '../dialog/notify.store';
import IUserContacts = Data.BaseData.IUserContacts;
import {ApSignalrService}                from '../../ap-core/services/ap-signalr.service';

interface IUserContactsStore extends IStateStoreWritable<IUserContacts> {
}

@Injectable({providedIn: 'root'})
export class UserContactsStore extends Store<IUserContactsStore> {
  constructor(public backend: ApSignalrService,
              private notifyStore: NotifyStore) {
    super(backend, {
      loaded: false,
      loading: false,
      saved: false,
      saving: false,
      data: []
    });

    backend.registerObservable(UserContactsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(UserContactsSaveSuccess).subscribe(d => {
      super.SetSaveSuccessState();
    });
    backend.registerObservable(UserContactsSaveFail).subscribe(() => {
      super.SetSaveFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => {
      this.ReloadSource();
    });
  }

  /**
   *  load user_contacts from Backend to the Store
   */
  public loadUserContacts(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new UserContactsLoad([]));
  }

  /**
   *  create user_contact
   */
  public createUserContact(userContact: IUserContacts): void {
    this.SetSaveState();
    this.DispatchBackend(new UserContactsCreate([
      {Name: 'userContact', Type: ApCustomTypes.Data_BaseData_UserContacts, Value: userContact}
    ]));
  }

  /**
   *  updates user_contact
   */
  public updateUserContact(userContact: IUserContacts): void {
    this.SetSaveState();
    this.DispatchBackend(new UserContactsUpdate([
      {Name: 'userContact', Type: ApCustomTypes.Data_BaseData_UserContacts, Value: userContact}
    ]));
  }

  /**
   *  deletes user_contact
   */
  public deleteUserContact(userContact: IUserContacts): void {
    this.DispatchBackend(new UserContactsDelete([
      {Name: 'userContact', Type: ApCustomTypes.Data_BaseData_UserContacts, Value: userContact}
    ]));
  }

  public get UserContacts(): IUserContacts[] {
    return this.Listen(s => s.data).getValue();
  }
}

import {Injectable}          from '@angular/core';
import {
  ApCustomTypes,
  MachinesCreate,
  MachinesDelete,
  MachinesGetInUseDays,
  MachinesGetInUseDaysSuccess,
  MachinesLoad,
  MachinesLoadSuccess,
  MachinesSaveFail,
  MachinesSaveSuccess,
  MachinesUpdate,
  MachineTypesLoad,
  MachineTypesLoadSuccess,
  NetTypes
}                            from 'invoker-transport';
import {IStateStoreWritable} from '../../ap-interface';
import {Store}               from '../index';
import {SafeBehaviorSubject} from 'ts-tooling';
import IMachine = Data.DocuContext.Machine.IMachine;
import IMachineType = Data.BaseData.IMachineType;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IMachineStore extends IStateStoreWritable<IMachine> {
  days: string[];
  machineTypes: IMachineType[];
}

@Injectable({providedIn: 'root'})
export class MachineStore extends Store<IMachineStore> {
  constructor(private backend: ApSignalrService) {
    super(backend, {
      loading: false,
      loaded: false,
      data: [],
      days: [],
      machineTypes: [],
      saved: false,
      saving: false
    });

    // register Actions from Backend
    backend.registerObservable(MachineTypesLoadSuccess).subscribe(d => {
      super.Mutate((s) => s.machineTypes, () => d.Data);
    });
    backend.registerObservable(MachinesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(MachinesGetInUseDaysSuccess).subscribe(d => {
      super.Mutate(s => s.days, () => d.Data);
    });
    backend.registerObservable(MachinesSaveSuccess).subscribe(() => {
      super.SetSaveSuccessState();
    });
    backend.registerObservable(MachinesSaveFail).subscribe(() => {
      super.SetSaveFailState();
    });
  }

  public get Machines$(): SafeBehaviorSubject<IMachine[]> {
    return this.Listen(x => x.data);
  }

  /**
   *  inform the Backend to load all Machines for this Session
   */
  public loadMachines(): void {
    super.SetLoadState();
    super.Mutate(d => d.data, () => []);
    super.DispatchBackend(new MachinesLoad([]));
  }

  /**
   * inform the Backend to load all Days of a Machine that have Data
   */
  public getDaysInUse(machine: IMachine, start: Date, end: Date): void {
    super.Mutate(d => d.days, () => []);
    super.DispatchBackend(new MachinesGetInUseDays([
      {Name: 'machine', Type: ApCustomTypes.Data_DocuContext_Machine_Machine, Value: machine},
      {Name: 'start', Type: NetTypes.DATETIME, Value: start},
      {Name: 'end', Type: NetTypes.DATETIME, Value: end}
    ]));
  }

  /**
   * inform the backend to save the given Machine
   */
  public updateMachine(machine: IMachine): void {
    super.SetSaveState();
    super.DispatchBackend(new MachinesUpdate([
      {Name: 'machine', Type: ApCustomTypes.Data_DocuContext_Machine_Machine, Value: machine}
    ]));
  }

  /**
   * inform the backend to create the given Machine
   */
  public createMachine(machine: IMachine): void {
    super.SetSaveState();
    super.DispatchBackend(new MachinesCreate([
      {Name: 'machine', Type: ApCustomTypes.Data_DocuContext_Machine_Machine, Value: machine}
    ]));
  }

  /**
   * inform the Backend to delete the given Machine
   * that means set isDeleted to true!
   */
  deleteMachine(machine: IMachine): void {
    super.DispatchBackend(new MachinesDelete([
      {Name: 'machine', Type: ApCustomTypes.Data_DocuContext_Machine_Machine, Value: machine}
    ]));
  }

  public loadMachineTypes(): void {
    super.DispatchBackend(new MachineTypesLoad([]));
  }

  public getMachineById(guid: string | System.IGuid | null): IMachine | null {
    if (!guid) {
      return null;
    }
    return super.Listen(s => s.data).getValue().find(m => m.Id === guid);
  }
}

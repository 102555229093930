import {NgModule}          from '@angular/core';
import {CommonModule}      from '@angular/common';
import {GetPermissionPipe} from './pipes/get-permission.pipe';


@NgModule({
  declarations: [
    GetPermissionPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GetPermissionPipe,
  ],
  providers: [
    GetPermissionPipe,
  ]
})
export class ApPermissionModule {
}

<div #apMap
     id="olmap"
     [style.width]="'100%'"
     [style.height]="'100%'"
     [style.position]="'relative'">
  <ap-machine-popup></ap-machine-popup>
  <ap-sensor-point-popup></ap-sensor-point-popup>
  <ap-sensor-point-legend></ap-sensor-point-legend>
  <ap-map-zoom class="ap-map-zoom-controls"></ap-map-zoom>
  <ap-map-edit class="ap-map-edit"></ap-map-edit>
  <ap-legend-scroller></ap-legend-scroller>
  <ap-map-tree-view></ap-map-tree-view>
  <button *ngIf="deleteButtonVisible$ | async"
          class="ap-map-clearSensorButton"
          (click)="clearSensorPoints()">
    <span class="k-icon k-i-delete"></span>
  </button>
  <ap-map-context-menu></ap-map-context-menu>
  <ap-map-tooltip></ap-map-tooltip>
</div>

import {IAction, IApInvokerArguments} from '../../interfaces';
import IApplicationMode = Data.Common.IApplicationMode;

export enum ApplModesActions {
  APPL_MODES_LOAD = '[appl_modes] load',
  APPL_MODES_LOAD_SUCCESS = '[appl_modes] load success',
}

export class ApplModesLoad implements IAction {
  readonly type = ApplModesActions.APPL_MODES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ApplModesLoadSuccess implements IAction {
  readonly type = ApplModesActions.APPL_MODES_LOAD_SUCCESS;

  constructor(public payload: IApplicationMode[]) {
  }
}

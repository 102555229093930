import {Pipe, PipeTransform}                             from '@angular/core';
import {ApGetCropStatisticService, ISecondCropStatistic} from '../services/ap-get-crop-statistic.service';

@Pipe({
  name: 'getSecondCropStatistic'
})
export class GetSecondCropStatisticPipe implements PipeTransform {
  constructor(private cropStatistic: ApGetCropStatisticService) {
  }

  transform(): ISecondCropStatistic[] {
    return this.cropStatistic.getForSecondCrop();
  }
}

import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
}                       from '@angular/core';
import {ApTreeViewItem} from '../../ap-interface/interfaces/layout/ap-tree-view-item';
import {
  TreeItemLookup,
  TreeViewComponent
}                       from '@progress/kendo-angular-treeview';
import {
  Observable,
  of,
  Subscription
}                       from 'rxjs';

@Directive({
  selector: '[mapTreeView]'
})
export class MapTreeViewDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  items: ApTreeViewItem[] = [];

  private _subscriptions: Subscription[] = [];

  constructor(private kendoTreeView: TreeViewComponent) {
  }

  ngOnInit(): void {
    this.kendoTreeView.textField = 'text';
    this.kendoTreeView.children = (dataItem: any): Observable<any[]> => of(dataItem.items);
    this.kendoTreeView.hasChildren = (dataItem: any): boolean => !!dataItem.items;
    this.kendoTreeView.isChecked = (dataItem: any) => dataItem.value && dataItem.value() ? 'checked' : 'none';
    this._subscriptions.push(this.kendoTreeView.checkedChange.subscribe((e: TreeItemLookup) => {
      const item: ApTreeViewItem = e.item.dataItem;
      if (item.onChange && item.value) {
        item.onChange.Invoke(!item.value());
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.kendoTreeView.nodes = this.items;
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}

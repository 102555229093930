import ITranslation = Data.Language.ITranslation;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum TranslationActions {
  TRANSLATION_LOAD = '[translation] load',
  TRANSLATION_LOAD_SUCCESS = '[translation] load success',
  TRANSLATION_LOAD_FAIL = '[translation] load fail',
  TRANSLATION_REFRESH = '[translation] refresh',
  TRANSLATION_VERSIONS = '[translation] versions',
  TRANSLATION_EXPLICIT = '[translation] explicit',
  TRANSLATION_UPDATE_CACHE = '[translation] update cache',
  TRANSLATION_UPDATE = '[translation] update',
  TRANSLATION_CREATE = '[translation] create',
  TRANSLATION_SAVE_SUCCESS = '[translation] save success',
  TRANSLATION_SAVE_FAIL = '[translation] save fail',
  TRANSLATION_MISSING = '[translation] missing',
}

/**
 * start load Tranlsations from Cache or Backend
 */
export class TranslationLoad implements IAction {
  readonly type = TranslationActions.TRANSLATION_LOAD;
}

/**
 * take a List of translations and set it into State
 */
export class TranslationLoadSuccess implements IAction {
  readonly type = TranslationActions.TRANSLATION_LOAD_SUCCESS;

  constructor(public payload: { [key: string]: ITranslation }) {
  }
}

/**
 * Translations load fails
 */
export class TranslationLoadFail implements IAction {
  readonly type = TranslationActions.TRANSLATION_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * request an Update of Transaltions for Cache
 */
export class TranslationRefresh implements IAction {
  readonly type = TranslationActions.TRANSLATION_REFRESH;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load data from Cache to State
 */
export class TranslationExplicit implements IAction {
  readonly type = TranslationActions.TRANSLATION_EXPLICIT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TranslationUpdate implements IAction {
  readonly type = TranslationActions.TRANSLATION_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TranslationCreate implements IAction {
  readonly type = TranslationActions.TRANSLATION_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TranslationSaveSuccess implements IAction {
  readonly type = TranslationActions.TRANSLATION_SAVE_SUCCESS;

  constructor(public payload: ITranslation) {
  }
}

export class TranslationSaveFail implements IAction {
  readonly type = TranslationActions.TRANSLATION_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

export class TranslationVersions implements IAction {
  readonly type = TranslationActions.TRANSLATION_VERSIONS;

  constructor(public payload: string) {
  }
}

export class TranslationUpdateCache implements IAction {
  readonly type = TranslationActions.TRANSLATION_UPDATE_CACHE;

  constructor(public payload: string) {
  }
}

export class TranslationMissing implements IAction {
  readonly type = TranslationActions.TRANSLATION_MISSING;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

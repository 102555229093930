import {Store}                    from '../index';
import {EventEmitter, Injectable} from '@angular/core';
import {
  ApCustomTypes,
  CampaignYearLoad,
  CampaignYearLoadSuccess,
  CampaignYearSelect,
  InformCampaignYearChange
}                                 from 'invoker-transport';
import {IStateStore}              from '../../ap-interface';
import {FieldStore}               from '../farm/field.store';
import {VarietyStore}             from '../base-data/variety.store';
import {ActionStore}              from '../docu/action.store';
import {APP_CONFIGURATION}        from '../../ap-core/config';
import {FormStore}                from '../layout/form.store';
import {SoilSampleDateStore}      from '../nutrients/soilsampledate.store';
import {CropRotationStore}        from '../farm/crop.rotation.store';
import {SafeBehaviorSubject}      from 'ts-tooling';
import {ClientCache}              from '../local-cache';
import {NFertilizationStore}      from '../n-fertilization/n-fertilization.store';
import {combineLatest}            from 'rxjs';
import {filter}                   from 'rxjs/operators';
import {RouterStore}              from '../router/router.store';
import {ApDateService}            from '../../ap-core/services/ap-date-service';
import ICampaignYear = Data.Authentication.ICampaignYear;
import {FieldArchiveStore}        from '../farm/field-archive.store';
import {ApSignalrService}         from '../../ap-core/services/ap-signalr.service';

interface ICampaignYearStore extends IStateStore<ICampaignYear> {
  selectedYear: ICampaignYear;
  currentCampaignYear: number;
  farmWasChanged: boolean;
}

@Injectable({providedIn: 'root'})
export class CampaignYearStore extends Store<ICampaignYearStore> {
  public onSelectCampaignYear = new EventEmitter<ICampaignYear>();
  public onLoadingStoresComplete = new EventEmitter<void>();

  public get IsCurrentCampaignYear(): boolean {
    if (!this.SelectedCampaignYear) {
      return false;
    }
    return this.SelectedCampaignYear.Year === (new Date().getMonth() < 7 ? new Date().getFullYear() : new Date().getFullYear() + 1);
  }

  public get IsHighestCampaignYear(): boolean {
    if (!this.SelectedCampaignYear) {
      return false;
    }

    const maxYear = this.getCampaignYears().map(x => x.Year).Max();
    return maxYear === this.SelectedCampaignYear.Year;
  }

  constructor(public backend: ApSignalrService,
              private cropRotationStore: CropRotationStore,
              private fieldStore: FieldStore,
              private actionStore: ActionStore,
              private varietyStore: VarietyStore,
              private soilSampleDateStore: SoilSampleDateStore,
              private nFertilizationStore: NFertilizationStore,
              private formStore: FormStore,
              private routerStore: RouterStore,
              private dateService: ApDateService,
              private fieldArchiveStore: FieldArchiveStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      selectedYear: null,
      currentCampaignYear: CampaignYearStore._calculateCurrentCampaignYear(),
      farmWasChanged: false,
    });
    backend.registerObservable(CampaignYearLoadSuccess).subscribe(d => {
      this.Mutate(s => s.data, () => d.Data);
      this.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());

    this.backend.registerObservable(CampaignYearSelect)
      .subscribe(async (req) => {
        const d = this.Listen(s => s.data).getValue().Find(i => i.Year === req.Data);
        if (!d) {
          return;
        }
        ClientCache.writeValue(APP_CONFIGURATION.StoreKeys.LastVisitCampaignYear, req.Data);
        this.fieldStore.changeSelectedField([]);
        this.fieldStore.loadFields(d.DefaultStart);
        this.fieldArchiveStore.loadFieldArchive(d.DefaultStart);
        this.actionStore.loadFleetActions();
        this.varietyStore.loadVarieties();
        this.soilSampleDateStore.getFieldSampleDates();
        this.Mutate(s => s.selectedYear, () => d);
        this.nFertilizationStore.getFertilizationNeedValue();
        this.cropRotationStore.LoadFieldCropRotation();

        combineLatest([
          this.fieldStore.Loading$,
          this.actionStore.Loading$,
          this.varietyStore.Loading$,
          this.soilSampleDateStore.Loading$,
          this.nFertilizationStore.Loading$,
          this.cropRotationStore.Loading$,
        ]).pipe(
          filter(states => states.TrueForAll(state => state === false))
        ).subscribe(() => {
          this.onLoadingStoresComplete.emit();
        });
        this.SetLoadFinishState();
      });

    this.SelectedCampaignYear$.subscribe(cy => this.fieldStore.onSelectedCampaignYearChanged.next(cy));
  }

  public get SelectedCampaignYear$(): SafeBehaviorSubject<ICampaignYear> {
    return this.Listen(s => s.selectedYear);
  }

  public get SelectedCampaignYear(): ICampaignYear {
    return this.SelectedCampaignYear$.getValue();
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.loading);
  }

  public get Loaded$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.loaded);
  }

  public get FarmWasChanges$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.farmWasChanged);
  }

  public get FarmWasChanged(): boolean {
    return this.FarmWasChanges$.getValue();
  }

  private static _calculateCurrentCampaignYear(): number {
    return new Date().getMonth() > 6 ? new Date().getFullYear() + 1 : new Date().getFullYear();
  }

  isSelectedCampaignYear(obj: { ValidFrom: Date, ValidTo: Date }): boolean { // TODO: it may not working correctly
    const selectedCampaignYear = this.getSelectedCampaignYear();
    return obj.ValidFrom <= selectedCampaignYear.DefaultStart && obj.ValidTo >= selectedCampaignYear.DefaultEnd;
  }

  isDateInSelectedCampaignYear(date: Date): boolean {
    const selectedCampaignYear = this.getSelectedCampaignYear();
    const dateToCompare = this.dateService.getDateMidnight(date);
    return dateToCompare >= new Date(selectedCampaignYear.DefaultStart) && dateToCompare <= new Date(selectedCampaignYear.DefaultEnd);
  }

  getSelectedCampaignYear(): ICampaignYear {
    return this.Listen(s => s.selectedYear).getValue();
  }

  getCampaignYears(): ICampaignYear[] {
    return this.Listen(s => s.data).getValue();
  }

  /**
   * Load all campaign years for a Farm
   */
  loadCampaignYears(): void {
    this.SetLoadState();
    this.Mutate(s => s.data, () => []);
    this.DispatchBackend(new CampaignYearLoad([]));
  }

  /**
   * Change the current campaign year
   */
  selectCampaignYear(selection: ICampaignYear): void {
    if (this.formStore.tryCloseForm()) {
      const subscription = this.formStore.formCloseOnRequest.subscribe((closed) => {
        if (closed) {
          this.onSelectCampaignYear.emit(selection);
          this.SetLoadState();
          this.DispatchBackend(new InformCampaignYearChange([
            {Name: 'campaignYear', Type: ApCustomTypes.Data_Authentication_CampaignYear, Value: selection}
          ]));
        }
        subscription.unsubscribe();
      });
    } else if (this.routerStore.tryNavigate()) {
      const subscription = this.routerStore.navigateRequest.subscribe((navigate) => {
        if (navigate) {
          this.onSelectCampaignYear.emit(selection);
          this.SetLoadState();
          this.DispatchBackend(new InformCampaignYearChange([
            {Name: 'campaignYear', Type: ApCustomTypes.Data_Authentication_CampaignYear, Value: selection}
          ]));
        }
        subscription.unsubscribe();
      });
    } else {
      this.onSelectCampaignYear.emit(selection);
      this.SetLoadState();
      this.DispatchBackend(new InformCampaignYearChange([
        {Name: 'campaignYear', Type: ApCustomTypes.Data_Authentication_CampaignYear, Value: selection}
      ]));
    }
  }

  getDefaultStart(year: number): Date {
    if (year) {
      return this.Listen(s => s.data).value.FirstOrDefault(
        (c) => c.Year === year, {DefaultStart: new Date(`${year - 1}-08-01`)} as ICampaignYear).DefaultStart;
    }
    return this.getSelectedCampaignYear().DefaultStart;
  }
}

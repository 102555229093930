import {Component} from '@angular/core';

const CONTENT = `
    <svg viewBox="0 0 1608.4 557" data-cy="svg_ap_logo">
    <path fill="#76777B"
          d="M745.4,554h73l21-132c12,12,32,17,52,17c65,0,93-33,105-111c12-80-10-131-69-131h-124L745.4,554z M1444.4,253
          h36l-16,98c-10,61,19,84,56,84h50l8-51c-38,0-46-3-39-46l13-85h47l9-56h-47l14-86h-72l-14,86h-36L1444.4,253z M1248.4,434h72l22-139
          c13-24,32-26,54-26c14,0,28,0,34,1l11-70c-6-2-14-2-23-2c-27,0-50,16-65,31l5-33h-72L1248.4,434z M658.4,434h72l39-238h-73
          L658.4,434z M476.4,434h73l22-139c12-24,32-26,54-26c13,0,27,0,34,1l11-70c-6-2-14-2-24-2c-26,0-50,16-65,31l6-33h-73L476.4,434z
           M5.4,301c-19,93,15,136,59,136c26,0,56-9,67-26l-4,23h71l34-210c-23-22-76-29-111-28C77.4,197,24.4,203,5.4,301z M75.4,312
          c8-50,24-56,40-60c12-2,28-1,37,3l-15,100c-8,14-20,23-42,23C69.4,378,70.4,350,75.4,312z M216.4,557h104c72,0,106-31,115-93l43-268
          h-127c-54,0-92,23-105,105c-13,81,1,133,70,133c19,0,38-5,54-17l-6,37c-5,28-22,42-44,42h-95L216.4,557z M319.4,309
          c4-25,11-61,42-61h35l-19,122c-14,8-24,9-38,9C309.4,379,312.4,354,319.4,309z M846.4,375l21-125h36c26,0,27,28,21,64
          c-9,56-19,69-41,70C868.4,385,854.4,382,846.4,375z"/>
    <path fill="#B0CB1F"
          d="M1017.4,313c-13,84,10,124,91,124c83,0,112-21,126-108c7-43,5-76-8-97c-6,5-13,10-18,16c-13,13-30,37-29,56
          s-10,35-23,47c-7,6-14,12-23,15c-14,7-30,8-43-2c-12-9-17-24-14-38c3-16,9-34,17-52c-1-3-2-5-2-8c-3-13-8-34-24-38c-5-1-11-1-16,0
          c-1,0-2,0-4,0C1033.4,246,1024.4,273,1017.4,313z M1240.4,188c27-9,64-15,71-21c46-34,27-75,89-151c3-4,3-8,2-11c-2-4-5-5-10-5
          c-134,11-219,17-236,169c0,5,3,9,7,10c5,1,10-1,13-5c55-72,98-101,147-122c-85,47-203,206-215,279c-4,23,40-7,39-25
          C1144.4,260,1194.4,204,1240.4,188z M1122.4,260c-22-108-108-45-135-58c-41-19-28-75-46-114c-1-3-1-7,2-11c3-3,7-5,12-5
          c89,7,204-40,188,87c-1,5-5,9-10,11c-5,1-10-1-12-5c-17-33-50-59-109-54c60,21,112,27,121,125c0,2,0,4-1,6
          C1128.4,248,1125.4,254,1122.4,260z M760.4,71h46c2,0,4-2,5-4l4-28c0-1,0-2-1-4c-1-1-2-2-4-2h-46c-2,0-4,2-5,5l-4,27c0,2,0,3,1,4
          S758.4,71,760.4,71z M805.4,113h35c3,0,5-2,5-4l3-20c1-2,0-3-1-5c-1-1-2-1-4-1h-35c-2,0-5,1-5,4l-3,20c0,2,0,3,1,4
          S803.4,113,805.4,113z M850.4,41h24c3,0,5-2,6-4l2-13c0-2-1-3-2-4c-1-2-2-2-3-2h-25c-3,0-5,2-5,4l-2,13c-1,2,0,3,1,4
          C847.4,41,848.4,41,850.4,41z M896.4,89h30c3,0,5-2,5-5l3-17c0-1,0-3-1-4s-3-2-4-2h-30c-3,0-5,2-5,5l-3,17c0,1,0,3,1,4
          S894.4,89,896.4,89z M895.4,129h14c2,0,4-2,5-5l1-6c0-1,0-3-1-4s-3-2-4-2h-14c-3,0-5,2-6,5l-1,6c0,1,1,3,2,4S893.4,129,895.4,129z
           M706.4,176h60c3,0,6-2,6-5l9-53c0-2,0-4-2-5c-1-2-3-3-5-3h-59c-3,0-6,3-7,6l-8,53c0,2,0,3,1,5C703.4,175,704.4,176,706.4,176z"/>
    </svg>
`;

/**
 * The Agriport Logo as SVG Image
 */
@Component({
  selector: 'ap-logo',
  template: CONTENT
})
export class ApLogoIconComponent {
}

import {ILegendValue}         from '../../stores/map/map.legend.store';

export class NFertilizationLegend {

  public static N_COLORS = [
    [ // sn
      'rgba(207, 127, 23, 1.00 )',
      'rgba(239, 161, 59, 1.00 )',
      'rgba(248, 231, 28, 1.00 )',
      'rgba(253, 255, 206, 1.00 )',
      'rgba(189, 233, 158, 1.00 )',
      'rgba(90, 189, 97, 1.00 )',
      'rgba(7, 147, 77, 1.00 )',
      'rgba(5, 90, 91, 1.00 )',
      'rgba(22, 65, 102, 1.00 )',
      'rgba(8, 5, 91, 1.00 )',
    ],
    [ // n fertilization
      'rgb(215, 25, 28 )',
      'rgb(234, 100, 63 )',
      'rgb(253, 174, 97 )',
      'rgb(254, 215, 144 )',
      'rgb(255, 255, 191 )',
      'rgb(213, 236, 212 )',
      'rgb(171, 217, 233 )',
      'rgb(107, 170, 208 )',
      'rgb(44, 123, 182 )',
      'rgb(6, 21, 63)'
    ],
    [ // gbi
      'rgba(207, 127, 23, 1.00 )',
      'rgba(239, 161, 59, 1.00 )',
      'rgba(248, 231, 28, 1.00 )',
      'rgba(253, 255, 206, 1.00 )',
      'rgba(189, 233, 158, 1.00 )',
      'rgba(90, 189, 97, 1.00 )',
      'rgba(7, 147, 77, 1.00 )',
      'rgba(5, 90, 91, 1.00 )',
      'rgba(22, 65, 102, 1.00 )',
      'rgba(8, 5, 91, 1.00 )',
    ]
  ];

  public static getDynamicColorLegend(kind: 'uptake' | 'application' | 'gbi', min: number, max: number, stepSize: number): {
    color: string,
    title: string
  }[] {
    switch (kind) {
      case 'uptake':
        return this._buildDynamicColorLegend(NFertilizationLegend.N_COLORS[0], min, max, stepSize);
      case 'application':
        return this._buildDynamicColorLegend(NFertilizationLegend.N_COLORS[1], min, max, stepSize);
      case 'gbi':
        return this._buildDynamicColorLegend(NFertilizationLegend.N_COLORS[2], min, max, stepSize);
    }
  }

  private static _buildDynamicColorLegend(colors: string[], min: number, max: number, stepSize: number): ILegendValue[] {
    stepSize = Math.max(stepSize, (max - min) / colors.length);
    const values = colors.map((_, i) => Math.round(min + stepSize * i + Number.EPSILON));
    return colors.map((color, i) => ({color, title: `${values[i]}`, value: values[i]}));
  }
}

import IInvitations = Data.Authentication.IInvitations;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum InvitationsActions {
  INVITATIONS_DELETE = '[invitations] delete',
  INVITATIONS_DELETE_SUCCESS = '[invitations] delete success',
  INVITATIONS_LOAD = '[invitations] load',
  INVITATIONS_LOAD_BY_ID = '[invitations] load by id',
  INVITATIONS_LOAD_BY_ID_RESULT = '[invitations] load by id result',
  INVITATIONS_CHECK_BY_CODE = '[invitations] check by code',
  INVITATIONS_CHECK_BY_RESULT = '[invitations] check by code result',
  INVITATIONS_LOAD_SUCCESS = '[invitations] load success',
  INVITATIONS_LOAD_FAIL = '[invitations] load fail',
  INVITATIONS_CREATE = '[invitations] create',
  INVITATIONS_CREATE_SUCCESS = '[invitations] create success',
  INVITATIONS_CREATE_FAIL = '[invitations] create fail',
  INVITATIONS_CONFIRMED = '[invitations] confirmed',
  INVITATIONS_CONFIRMED_WITH_USER = '[invitations] confirmed with user',
  INVITATIONS_CONFIRMED_SUCCESS = '[invitations] confirmed success',
  INVITATIONS_CONFIRMED_FAIL = '[invitations] confirmed fail',
  INVITATIONS_CONFIRMED_CREATE_NEW_USER = '[invitations] confirmed create new user',
  INVITATIONS_UPDATE = '[invitations] update',
  INVITATIONS_UPDATE_SUCCESS = '[invitations] update success',
  INVITATIONS_RESEND = '[invitations] resend',
  INVITATIONS_RESEND_SUCCESS = '[invitations] resend success',
  INVITATIONS_RESEND_FAIL = '[invitations] resend fail'
}

/**
 * delete a existing User Invitation.
 *
 * @example
 * new InvitationsDelete([
 *  {
 *    Name: 'invitations',
 *    Type: ApCustomTypes.Data_Authentication_Invitations,
 *    Value: {
 *      Activation_Code: 0,
 *      Confirmation_Time: new Date(),
 *      Created: new Date(),
 *      Created_Userid: 0,
 *      DriverId: '',
 *      Email: '',
 *      Farmid: 0,
 *      Id: 0,
 *      Isdriver: false,
 *      Usergroupid: 0,
 *      Version: 1,
 *    },
 *  }
 * ]);
 */
export class InvitationsDelete implements IAction {
  readonly type = InvitationsActions.INVITATIONS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsCheckByCode implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CHECK_BY_CODE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsCheckByCodeResult implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CHECK_BY_RESULT;

  constructor(public payload: boolean) {
  }
}

export class InvitationsLoadById implements IAction {
  readonly type = InvitationsActions.INVITATIONS_LOAD_BY_ID;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsLoadByIdResult implements IAction {
  readonly type = InvitationsActions.INVITATIONS_LOAD_BY_ID_RESULT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsDeleteSuccess implements IAction {
  readonly type = InvitationsActions.INVITATIONS_DELETE_SUCCESS;

  constructor(public payload: string) {
  }
}

/**
 * load all Invitations for the Farm. Data from the Session was used so it requires a valid Session with Farm ID.
 *
 * @example
 * new InvitationsLoad([]);
 */
export class InvitationsLoad implements IAction {
  readonly type = InvitationsActions.INVITATIONS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsLoadSuccess implements IAction {
  readonly type = InvitationsActions.INVITATIONS_LOAD_SUCCESS;

  constructor(public payload: IInvitations[]) {
  }
}

export class InvitationsLoadFail implements IAction {
  readonly type = InvitationsActions.INVITATIONS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * create a new Invitation for a new User for the Farm.
 *
 * @example
 * new InvitationsCreate([
 *  {
 *    Name: 'invitation',
 *    Type: NetTypes.OBJECT,
 *    Value: {
 *      Email: 'example@test.de',
 *      UserGroupId: 1,
 *      IsDriver: true,
 *      DriverId: '9ba28b41-5e7e-4174-a89b-33443f646cba',
 *    },
 *  }
 * ]);
 */
export class InvitationsCreate implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsCreateSuccess implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CREATE_SUCCESS;

  constructor(public payload: boolean) {
  }
}

export class InvitationsCreateFail implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CREATE_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * the User confirms a invitation via E-Mail
 *
 * @example
 * new InvitationsConfirmed([
 *  {
 *    Name: 'id',
 *    Type: NetTypes.INT,
 *    Value: id,
 *  },
 *  {
 *    Name: 'activationCode',
 *    Type: NetTypes.INT,
 *    Value: activationCode,
 *  }
 * ]);
 */
export class InvitationsConfirmed implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CONFIRMED;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * confirm the Invitation with a existing User
 *
 * @example
 * new InvitationsConfirmedWithUser([
 *  {
 *    Name: 'id',
 *    Type: NetTypes.INT,
 *    Value: id,
 *  },
 *  {
 *    Name: 'activationCode',
 *    Type: NetTypes.INT,
 *    Value: activationCode,
 *  },
 *  {
 *    Name: 'newUser',
 *    Type: NetTypes.INT,
 *    Value: userId,
 *  }
 * ]);
 */
export class InvitationsConfirmedWithUser implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CONFIRMED_WITH_USER;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsConfirmedSuccess implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CONFIRMED_SUCCESS;

  constructor(public payload: boolean) {
  }
}

export class InvitationsConfirmedFail implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CONFIRMED_FAIL;

  constructor(public payload: string) {
  }
}

export class InvitationsConfirmedCreateNewUser implements IAction {
  readonly type = InvitationsActions.INVITATIONS_CONFIRMED_CREATE_NEW_USER;

  constructor(public payload: boolean) {
  }
}

/**
 * change the data of a existing Invitation.
 *
 * @example
 * new InvitationsUpdate([
 *  {
 *    Name: 'invitation',
 *    Type: NetTypes.OBJECT,
 *    Value: {
 *      Email: 'example@test.de',
 *    },
 *  },
 * ]);
 */
export class InvitationsUpdate implements IAction {
  readonly type = InvitationsActions.INVITATIONS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsUpdateSuccess implements IAction {
  readonly type = InvitationsActions.INVITATIONS_UPDATE_SUCCESS;

  constructor(public payload: IInvitations) {
  }
}

export class InvitationsResend implements IAction {
  readonly type = InvitationsActions.INVITATIONS_RESEND;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsResendSuccess implements IAction {
  readonly type = InvitationsActions.INVITATIONS_RESEND_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InvitationsResendFail implements IAction {
  readonly type = InvitationsActions.INVITATIONS_RESEND_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

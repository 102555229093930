import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
        <path d="M160,140.7,121.43,160V51.3L160,32ZM70.57,32,32,51.3V160l38.57-19.3Zm6.14,108.7L115.28,160V51.3L76.71,32Z"></path>
    </svg>
`;

@Component({
  selector: 'ap-map-icon',
  template: CONTENT
})
export class ApMapIconComponent {
}

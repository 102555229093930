import {AfterContentInit, Component, EventEmitter, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, Validators}                                         from '@angular/forms';
import {IApTranslationData}                                              from '../../ap-interface';
import {ApDynformsComponent}                                             from '../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsConfigFieldset
}                                                                        from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigComboBox
}                                                                        from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {ApDynformsValidator}                                             from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsConfigTextbox
}                                                                        from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {
  ApDynGridColumnConfigBase
}                                                                        from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPropertyColumnConfig
}                                                                        from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridPagerConfigBase
}                                                                        from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {
  ApDynGridImageColumnConfig
}                                                                        from '../../ap-dyngrids/config/ap-dyn-grid-image-column-config';
import {ApDynGridsComponent}                                             from '../../ap-dyngrids/ap-dyngrids.component';
import {FormStore}                                                       from '../../stores/layout/form.store';
import {
  TranslationStore
}                                                                        from '../../stores/translation/translation.store';
import {LanguageStore}                                                   from '../../stores/translation/language.store';
import {ListSortOrder}                                                   from 'ts-tooling';
import {
  ApDynGridButtonColumnConfig
}                                                                        from '../../ap-dyngrids/config/ap-dyn-grid-button-column-config';
import {
  IModalDialogData,
  ModalDialogButtonDisable
}                                                                        from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {ModalDialogStore}                                                from '../../stores/dialog/modal.dialog.store';
import {
  UserSettingsStore
}                                                                        from '../../stores/settings/usersettings.store';
import {Subscription}                                                    from 'rxjs';
import {
  GetCountryCodeFlagPipe
}                                                                        from '../../ap-utils/pipes/get-countrycode-flag.pipe';
import {
  GetPermissionPipe
}                                                                        from '../../ap-permission/pipes/get-permission.pipe';
import ITranslation = Data.Language.ITranslation;
import ILanguage = Data.Language.ILanguage;

@Component({
  selector: 'ap-translations-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption">
      <div dynforms.after>
        <ap-dyngrids [columns]="columns"
                     [items]="items"
                     [pageable]="false"
                     [groupable]="false"
                     [selectable]="{checkboxOnly: false, mode: 'single'}">
        </ap-dyngrids>
      </div>
      <div class="ap-form-actions" dynforms.action>
        <button id="button_translation_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-right"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
      </div>
    </ap-dynforms>`,
})
export class ApTranslationsEditComponent implements AfterContentInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: ITranslation;
  public isNewMode = false;
  public found: any;
  public translations: Array<ITranslation>;
  public key: string;
  public modul: string;
  public gridData: Array<IApTranslationData>;
  public languages: Array<ILanguage>;
  public allLanguages: Array<string> = [];
  public listModuls: Array<{ value: string }> = [];
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;

  @ViewChild(ApDynGridsComponent, {static: true}) dynGrid: ApDynGridsComponent;
  columns: ApDynGridColumnConfigBase[] = [];
  items: any[] = [];
  pager: ApDynGridPagerConfigBase;

  private _translations: Array<ITranslation>;
  private _onEdit = new EventEmitter<any>();
  private _editedTranslation: any;
  private _canTranslateCountryCodes: Array<string> = [];
  private _subscriptions: Array<Subscription> = [];

  constructor(private fb: FormBuilder,
              private formStore: FormStore,
              private translationStore: TranslationStore,
              private languageStore: LanguageStore,
              private permissionPipe: GetPermissionPipe,
              private modalDialogStore: ModalDialogStore,
              private userSettingsStore: UserSettingsStore,
              private getCountryCodeFlagPipe: GetCountryCodeFlagPipe) {
    this._subscriptions.Add(modalDialogStore.Listen(s => s.result).subscribe(res => {
      if (!res) {
        return;
      }
      if (res.key === undefined) {
        return;
      }
      if (!this._editedTranslation) {
        return;
      }
      if (res.key !== 'apply') {
        return;
      }
      this._editedTranslation.SourceItem.Translation = res.formValues.Translation;
      this._editedTranslation.SourceItem.Modul = this.dynForm.form.value.Modul;
      this._editedTranslation.SourceItem.TranslationKey = this.dynForm.form.value.TranslationKey;
      this.saveChanges(this._editedTranslation.SourceItem);
    }));
    this._subscriptions.Add(this._onEdit.subscribe(event => {
      if (this.dynForm.form.invalid) {
        this.dynForm.form.markAllAsTouched();
        return;
      }
      this._editedTranslation = event;
      const dialog = {
        title: this.translationStore.FindTranslationForSelectedLanguage('Global_Translation_Edit'),
        formConfig: {
          fieldSets: [
            new ApDynformsConfigFieldset({
              key: 'TranslationFieldSet',
              columns: 1,
              config: [
                new ApDynformsConfigTextbox({
                  key: 'Translation',
                  label: 'Global_Translation'
                })
              ]
            })
          ],
          values: {
            Translation: event.SourceItem.Translation
          }
        },
        show: true,
        buttons: [
          {
            key: 'cancel',
            text: 'Global__Cancel',
            primary: false
          },
          {
            key: 'apply',
            text: 'Global__Take',
            primary: true,
            disable: ModalDialogButtonDisable.FormInvalid,
          }
        ],
      } as IModalDialogData;
      this.modalDialogStore.setModalDialogData(dialog);
    }));
  }

  ngAfterContentInit(): void {
    this._subscriptions.Add(this.userSettingsStore.Listen(s => s.data).subscribe(s => {
      if (!s) {
        return;
      }
      if (!this.isNewMode) {
        this._canTranslateCountryCodes.AddRange(s.CountryCodes);
      }
    }));
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        Id: undefined,
        Modul: null,
        TranslationKey: null,
        translation: null,
        CountryCode: null,
        Version: undefined,
        VersionsIndex: ''
      };
    }
    this.caption = this.isNewMode ? 'Global_Translation_Create' : 'Global_Translation_Edit';
    this.formBuilder();
    this.getDataFromStore();
    this.gridBuilder();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }

  /**
   * retrieves all data from the store
   */
  getDataFromStore(): void {
    this.languages = this.languageStore.Listen(s => s.data).getValue();
    /*this._subscriptions.Add(this.translationStore.Listen(s => s.data).subscribe(translations => {
      if (translations == null) {
        return;
      }
      this._translations = Object.values(translations);
    }));*/
    this.languages.forEach(l => {
      this.allLanguages.push(l.Key);
    });
    this.allLanguages.Sort(ListSortOrder.ASC);
    if (this.isNewMode) {
      this._canTranslateCountryCodes.AddRange(this.allLanguages);
    }
    this.found = this._translations.filter(f => f.TranslationKey === this.data.TranslationKey);
    const moduls = this._translations.GroupKey(f => f.Modul);
    if (this.isNewMode) {
      this.key = null;
      this.modul = null;
    }
    moduls.Sort(ListSortOrder.ASC);
    moduls.forEach((m) => {
      this.listModuls.push({
        value: m
      });
    });
    this.createTable();
  }

  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'translation',
        config: [
          new ApDynformsConfigComboBox({
            key: 'Modul',
            valuePrimitive: true,
            value: this.data.Modul,
            valueField: 'value',
            textField: 'value',
            label: 'Global__Module',
            options: this.listModuls,
            disabled: !this.isNewMode,
            validators: [
              new ApDynformsValidator({validator: Validators.required, errorKey: 'Settings__Msg_Vali_Value_Required'}),
            ]
          }),

          new ApDynformsConfigTextbox({
            key: 'TranslationKey',
            label: 'Admin_Pages__Keys',
            value: this.data.TranslationKey,
            disabled: !this.isNewMode,
            validators: [
              new ApDynformsValidator({validator: Validators.required, errorKey: 'Settings__Msg_Vali_Value_Required'}),
            ]
          })
        ]
      })];
  }

  createTable(): void {
    const a = Array<IApTranslationData>();
    this.allLanguages.forEach((l: any) => {
      const tr = this.getTranslation(l);
      const c = {
        Id: tr !== null ? tr.Id : undefined,
        CountryCode: l,
        Modul: this.modul,
        TranslationKey: this.key,
        Translation: tr !== null ? tr.translation : '',
        Present: tr !== null
      } as IApTranslationData;
      a.push(c);
    });
    this.gridData = a;

    this.items = this.gridData.map(data => {
      return {
        Id: data.Id,
        SourceItem: data,
        Image: this.getCountryCodeFlagPipe.transform(data.CountryCode),
        StandardIcon: data.Present === true ? 'edit' : 'plus'
      };
    });
  }

  getTranslation(countryCode: string): ITranslation {
    const trans = this.found.Find(fr => fr.CountryCode === countryCode);
    if (trans !== undefined) {
      return trans;
    }
    return null;
  }

  public saveChanges(translation: any): void {
    if (translation.Present === false) {
      const tra = {
        Modul: translation.Modul,
        TranslationKey: translation.TranslationKey,
        CountryCode: translation.CountryCode,
        Translation: translation.Translation
      };
      this.translationStore.CreateTranslation(tra);
    } else {
      const tra = {
        Id: translation.Id,
        Translation: translation.Translation
      };
      this.translationStore.UpdateTranslation(tra);
    }
  }

  onCancelClick(): void {
    this.formStore.closeForm();
  }

  private gridBuilder(): void {
    this.columns = [
      new ApDynGridPropertyColumnConfig({
        field: 'SourceItem.CountryCode',
        title: 'Admin_Pages_Popups__Language',
        filterable: true,
        width: 70,
        headerFilterable: true
      }),
      new ApDynGridImageColumnConfig({
        title: 'Admin_Pages_Popups__Language',
        imageUrl: 'Image',
        width: 70,
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'SourceItem.Translation',
        title: 'Global_Translation',
        filterable: true,
        width: 200,
        headerFilterable: true
      }),
      new ApDynGridButtonColumnConfig(
        {
          disabled: (t) => !this._canTranslateCountryCodes.Contains(t.SourceItem.CountryCode),
          tooltip: 'Global__Edit',
          callback: this._onEdit,
          isStandardIcon: true,
          standardIcon: 'StandardIcon'
        }
      ),
    ];
    this.pager = new ApDynGridPagerConfigBase();
  }
}

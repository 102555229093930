import {IAction, IApInvokerArguments} from '../../interfaces';

enum MonitoringDetailActions {
  MONITORING_DETAIL_LOAD = '[monitoring-detail] load',
  MONITORING_DETAIL_LOAD_SUCCESS = '[monitoring-detail] load success',
  MONITORING_DETAIL_LOAD_FAIL = '[monitoring-detail] load fail',
}

export class MonitoringDetailLoad implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringDetailLoadSuccess implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringDetailLoadFail implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

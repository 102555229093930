import {Component, Input} from '@angular/core';
import {keys}             from 'lodash';

@Component({
  selector: 'ap-breadcrumbs-steper',
  templateUrl: 'ap-breadcrumbs-steper.component.html',
  styleUrls: ['ap-breadcrumbs-steper.component.scss']
})
export class ApBreadcrumbsSteperComponent {
  @Input()
  public columns: { [key: number]: string } = {};

  @Input()
  public selected: number;

  getKeys(): string[] {
    return keys(this.columns || {});
  }

  getInt(value: string): number {
    return parseInt(value, 10);
  }
}

import {Pipe, PipeTransform } from '@angular/core';
import {Trace}                 from '../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'execFunction'
})
export class ExecFunctionPipe implements PipeTransform {

  @Trace()
  transform(func: (...args: any[]) => any, ...args: any[]): any {
    return func(...args);
  }

}

import {Pipe, PipeTransform} from '@angular/core';
import * as moment           from 'moment';
import {TimeSpan}            from 'ts-tooling';
import {ApDateService}       from '../../ap-core/services/ap-date-service';
import {TranslationStore}    from '../translation/translation.store';

@Pipe({
  name: 'getTimeDurationString'
})
export class ApGetTimeDurationStringPipe implements PipeTransform {
  constructor(private dateService: ApDateService,
              private translationStore: TranslationStore) {
  }

  transform(date: Date): string {
    const convertedDate = this.dateService.toFarmDate(date);
    const farmZone = 'UTC';
    const diff = moment.tz(farmZone).valueOf() - convertedDate.valueOf();
    const duration = TimeSpan.FromMilliseconds(diff);
    if (duration.Hour === 0) {
      return duration.TotalMinutes.Floor() + ' ' + this.translationStore.FindTranslationForSelectedLanguage('Global__Min_Short');
    }
    if (duration.Hour < 24 && duration.Day === 0) {
      return duration.Hour + ' ' + this.translationStore.FindTranslationForSelectedLanguage('Global_Hour_Short');
    }
    if (duration.Day === 1) {
      return duration.Day + ' ' + this.translationStore.FindTranslationForSelectedLanguage('Global__Day');
    }
    if (duration.Day > 1 && duration.Day < 7) {
      return duration.Day + ' ' + this.translationStore.FindTranslationForSelectedLanguage('Global__Days');
    }
    return duration.TotalWeeks.Floor() + ' ' + this.translationStore.FindTranslationForSelectedLanguage('Global_Week_Short');
  }
}

import {ApDynGridPagerConfigBase, ApDynGridPagerControlType} from './ap-dyn-grid-pager-config-base';
import {EventEmitter}                                        from '@angular/core';
import {Observable, of}                                      from 'rxjs';

import {ApDynformsConfigFieldset} from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsConfigTabs}     from '../../ap-dynforms/config/ap-dynforms-config-tabs';

export interface IGridWizardSubmitData {
  ids: any[];
  items: any[];
  value: any;
}

export interface IDynGridWizardFormConfig {
  onSubmit?: EventEmitter<IGridWizardSubmitData>;
  config?: ApDynformsConfigFieldset[] | ApDynformsConfigTabs[];
  submitDisabled?: ((values: any) => boolean);
  showMassEdit?: boolean;
}

export class ApDynGridPagerWizardConfig extends ApDynGridPagerConfigBase {
  public type = ApDynGridPagerControlType.Wizard;
  public sections: ApDynGridPagerWizardSection[] = [];
  public dynGridWizardFormConfig: IDynGridWizardFormConfig;

  constructor(left: ApDynGridPagerWizardSection,
              center: ApDynGridPagerWizardSection,
              right: ApDynGridPagerWizardSection,
              options: {
                formConfig?: IDynGridWizardFormConfig,
              } = {}) {
    super();
    this.dynGridWizardFormConfig = options.formConfig;
    this.sections.push(({...left, align: 'left'}));
    this.sections.push(({...center, align: 'center'}));
    this.sections.push(({...right, align: 'right'}));
  }
}

export class ApDynGridPagerWizardSection {
  type = ApDynGridPagerWizardSectionType.None;
  align: string;
}

export class IconSubButton {
  tooltip: string;
  icon: string;
  callback: EventEmitter<void>;
  value: any;
  id?: any;
}

export class ApDynGridPagerWizardButton extends ApDynGridPagerWizardSection {
  type = ApDynGridPagerWizardSectionType.Button;
  id?: string;
  key: string;
  callback: EventEmitter<any>;
  active$ = new Observable<boolean>();
  iconButtons: IconSubButton[];
  // the optional value is passed to the callback on emit() for further processing in component
  value: any;

  constructor(key: string, callback: EventEmitter<any>, params: {
    active?: Observable<boolean>,
    iconButtons?: IconSubButton[],
    value?: any,
    id?: string
  } = {}) {
    super();
    this.key = key;
    this.callback = callback;
    this.id = params.id;
    if (params.active) {
      /*if (params.active instanceof AnonymousSubject) {
        this.active$ = (params.active as AnonymousSubject).asObservable()
          .pipe(
            map(o => o === true)
          );
      } else {*/
      this.active$ = params.active;
      // }
    } else {
      this.active$ = of(true);
    }
    if (params.iconButtons) {
      this.iconButtons = params.iconButtons;
    } else {
      this.iconButtons = [];
    }
    this.value = params.value;
  }
}

export class ApDynGridPagerWizardExport extends ApDynGridPagerWizardSection {
  type = ApDynGridPagerWizardSectionType.Export;

  pdf = true;
  xls = true;
  xlsCallback: EventEmitter<any> = null;
  customExports: {
    emitter: EventEmitter<any>,
    icon: string,
    tooltip: string,
    disabled$?: Observable<boolean>,
    type?: 'all' | 'selected'
  }[] = [];

  constructor(options: {
    pdf?: boolean,
    xls?: boolean,
    xlsCallback?: EventEmitter<any>,
    customIcons?: {
      emitter: EventEmitter<any>,
      icon: string, tooltip: string,
      disabled$?: Observable<boolean>,
      type?: 'all' | 'selected'
    }[]
  } = {}) {
    super();
    this.pdf = options.pdf !== false;
    this.xls = options.xls !== false;
    this.xlsCallback = options.xlsCallback || null;
    this.customExports = options.customIcons || [];
  }
}

export class ApDynGridPagerWizardBreadcrumbsStepper extends ApDynGridPagerWizardSection {
  type = ApDynGridPagerWizardSectionType.BreadcrumbsStepper;

  columns: string[];
  selected: number;

  constructor(columns: string[], selected: number) {
    super();
    this.columns = columns;
    this.selected = selected;
  }
}

export enum ApDynGridPagerWizardSectionType {
  None = 0,
  Button = 1,
  Export = 2,
  BreadcrumbsStepper = 3
}

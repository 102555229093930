import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
}                                                                   from '@angular/core';
import {
  ApDynformsConfigFieldset
}                                                                   from '../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynGridColumnConfigBase
}                                                                   from '../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {BehaviorSubject, Observable, of, Subscription}              from 'rxjs';
import {
  ApDynGridPagerConfigBase
}                                                                   from '../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {ApDynformsComponent}                                        from '../ap-dynforms/ap-dynforms.component';
import {RowArgs, SelectableSettings, SelectionEvent}                from '@progress/kendo-angular-grid';
import {AsyncValidatorFn, FormGroup, ValidatorFn}                   from '@angular/forms';
import {ApDynGridsComponent}                                        from '../ap-dyngrids/ap-dyngrids.component';
import {
  ApDynformsConfigTabs
}                                                                   from '../ap-dynforms/config/ap-dynforms-config-tabs';
import {ApDynformsValidator}                                        from '../ap-dynforms/ap-dynforms-validator';
import {
  ApDynGridDetailsBaseConfig
}                                                                   from '../ap-dyngrids/config/details/ap-dyn-grid-details-base-config';
import {delay, distinctUntilChanged, filter, map, mergeMap}         from 'rxjs/operators';
import {ObjectFactory}                                              from 'ts-tooling';
import {
  IApKendoGridDataSource
}                                                                   from '../ap-core/extensions/kendo/ap-kendo-grid-extension';
import {CompositeFilterDescriptor, GroupDescriptor, SortDescriptor} from '@progress/kendo-data-query';
import {
  SortSettings
}                                                                   from '@progress/kendo-angular-grid/dist/es2015/columns/sort-settings';

@Component({
  selector: 'ap-dyncomponent',
  templateUrl: './ap-dyncomponent.component.html',
})
export class ApDynComponentComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  @ViewChild(ApDynGridsComponent, {static: false}) dynGrid: ApDynGridsComponent;
  @Input() caption: string;
  @Input() canCreate = false;
  @Input() canSearch = false;
  @Input() details: ApDynGridDetailsBaseConfig;
  @Input() errorKeys: string[];
  @Input() columns: ApDynGridColumnConfigBase[] = [];
  @Input() exportFileName: string;
  @Input() fieldSets: ApDynformsConfigFieldset[] | ApDynformsConfigTabs[] = [];
  @Input() filterTooltip = '';
  @Input() formDefaultValue = {};
  @Input() formValidators: ApDynformsValidator<ValidatorFn>[];
  @Input() formAsyncValidators: ApDynformsValidator<AsyncValidatorFn>[];
  @Input() groupable = true;
  @Input() headerIcon: string;
  @Input() headerSvg;
  @Input() items: any[] = [];
  @Input() loading$: Observable<boolean> = of(false);
  @Input() pager: ApDynGridPagerConfigBase = new ApDynGridPagerConfigBase();
  @Input() pdfExportFileName: string;
  @Input() pdfExportTitle = '';
  @Input() reorderable: boolean;
  @Input() rowSelected: (e: RowArgs) => boolean;
  @Input() sortBySelection = false;
  @Input() selectable: SelectableSettings = {checkboxOnly: false, mode: 'multiple', enabled: true};
  @Input() xlsExportFileName: string;
  @Input() sortable: SortSettings;
  @Output() create = new EventEmitter<any>();
  @Output() edit = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<SelectionEvent>();
  @Output() apSubmit = new EventEmitter();
  @Output() gridInitialized = new EventEmitter(true);
  tabs: ApDynformsConfigTabs[];
  valid = false;
  Valid$ = new BehaviorSubject<boolean>(true);
  FormValues$ = new BehaviorSubject({});
  PagerValues$ = new BehaviorSubject({});
  gridSubject = new BehaviorSubject<ApDynGridsComponent>(undefined);
  gridDescriptors: {
    [key: string]: { filter: CompositeFilterDescriptor, groups: GroupDescriptor[], sort: SortDescriptor[] }
  } = {};

  private _subscriptions: Subscription[] = [];

  @ViewChild(ApDynGridsComponent, {static: false})
  set DynGrid(dynGrid: ApDynGridsComponent) {
    this.gridSubject.next(dynGrid);
  }

  get SelectedItems$(): Observable<any[]> {
    return this.gridSubject.pipe(
      filter((grid) => !!grid),
      mergeMap((grid) => grid.gridPaging.onSelectedItemChange),
    );
  }

  ngOnInit(): void {
    this._subscriptions.push(this.gridSubject.subscribe(value => {
      if (value != null && this.sortable != null) {
        value.gridPaging.sortable = this.sortable;
      }
    }));

    this._subscriptions.push(this.dynForm.form$.pipe(
      filter((form) => !!form),
      mergeMap((form) => form.valueChanges),
      distinctUntilChanged((previous, current) => ObjectFactory.Equal(previous, current)),
      map((values) => {
        const result = {};
        Object.keys(values).forEach((key) => {
          if (values[key] !== '') {
            result[key] = values[key];
          }
        });
        return result;
      })
    ).subscribe((values) => this.FormValues$.next(values)));

    this._subscriptions.push(this.dynForm.form$.pipe(
      filter((form) => !!form),
      mergeMap((form) => form.statusChanges),
      map((status) => status === 'VALID'),
      delay(0)
    ).subscribe((v) => {
      this.valid = v;
      this.Valid$.next(v);
    }));

    this._subscriptions.push(this.gridSubject.pipe(
      filter((grid) => !!grid),
      mergeMap((grid) => grid.wizardSubject$),
      filter((wizard) => !!wizard),
      mergeMap((wizard) => wizard.formValues$)
    ).subscribe((values) => this.PagerValues$.next(values)));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fieldsets']) {
      if (this.fieldSets && this.fieldSets.length) {
        const first = this.fieldSets[0];
        if (first instanceof ApDynformsConfigTabs) {
          this.tabs = this.fieldSets as ApDynformsConfigTabs[];
        } else if (first instanceof ApDynformsConfigFieldset) {
          this.fieldSets = this.fieldSets as ApDynformsConfigFieldset[];
        }
      }
    }
  }

  getForm(): FormGroup {
    return this.dynForm.form;
  }

  getFormValue(): any {
    return this.dynForm.form.getRawValue();
  }

  filterChange(f: string): void {
    if (this.dynGrid) {
      this.dynGrid.gridPaging.filterChange(f);
    }
  }

  getSelectedItems(): IApKendoGridDataSource[] {
    if (this.dynGrid) {
      return this.dynGrid.gridPaging.selectedItems;
    }
    return [];
  }

  gridIsInitialized(): void {
    this.gridInitialized.emit();
  }

  public saveGridDescriptors(key: string): void {
    const grid = this.gridSubject.getValue();
    if (grid) {
      this.gridDescriptors[key] = grid.gridPaging.getDescriptors();
    }
  }

  public loadGridDescriptors(key: string): void {
    setTimeout(() => {
      const grid = this.gridSubject.getValue();
      if (grid) {
        grid.gridPaging.setDescriptors(this.gridDescriptors[key]);
      }
    }, 0);
  }
}

import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.29 183">
    <path d="M58.89,57.44H17.63s-12.77,0-12.77,12.77v44.68c0,12.77,12.77,12.77,12.77,12.77V191.5H67.31V133.6c-7.61-2.71-12.76-9.78-12.76-18.71V70.21a20.08,20.08,0,0,1,4.34-12.77M195.15,70.21s0-12.77-12.77-12.77H141.11a20.13,20.13,0,0,1,4.34,12.77v44.68c0,8.93-5.15,16-12.77,18.71v57.9h49.7V127.66s12.77,0,12.77-12.77ZM176,31.91a19.15,19.15,0,1,0-19.15,19.15A19.15,19.15,0,0,0,176,31.91m-113.68,0A19.15,19.15,0,1,0,43.17,51.06,19.14,19.14,0,0,0,62.31,31.91" transform="translate(-4.86 -8.5)"/>
    <path d="M100.2,98l4.2-7.48,4.2-7.48L107,71l-1.59-12A7.09,7.09,0,0,1,95,59L93.41,71,91.8,83.08,96,90.56Zm39.17-30.79s0-13.06-13.06-13.06h-20.8a5.31,5.31,0,0,1-10.62,0H74.09S61,54.19,61,67.25v45.69C61,126,74.09,126,74.09,126v65.28h52.22V126s13.06,0,13.06-13ZM119.79,28.08A19.59,19.59,0,1,0,100.2,47.66a19.59,19.59,0,0,0,19.59-19.58" transform="translate(-4.86 -8.5)" class="hoverFill"/>
    <path d="M100.08,98.18l4.2-7.48,4.2-7.49L106.87,71.1l-1.58-12a7.1,7.1,0,0,1-10.43,0l-1.58,12L91.67,83.21l4.21,7.49Z" transform="translate(-4.86 -8.5)"/>
</svg>
`;

@Component({
  selector: 'ap-icon-administration',
  template: CONTENT
})
export class ApIconAdministrationComponent {
}

import IDataSetType = Data.Import.IDataSetType;
import IDataSetColumn = Data.Import.IDataSetColumn;
import IDataSet = Data.Import.IDataSet;
import ICsvType = Data.Import.ICsvType;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum ImportActions {
  IMPORT_DATASET_TYPES_LOAD = '[import_dataset_types] load',
  IMPORT_DATASET_TYPES_LOAD_SUCCESS = '[import_dataset_types] load success',
  IMPORT_DATASET_COLUMNS_LOAD = '[import_dataset_columns] load',
  IMPORT_DATASET_COLUMNS_LOAD_SUCCESS = '[import_dataset_columns] load success',
  IMPORT_DATASET_LOAD = '[import_dataset] load',
  IMPORT_DATASET_LOAD_SUCCESS = '[import_dataset] load success',
  LOAD_DATASET_MAP_PREVIEW = '[import_dataset] load map preview',
  LOAD_DATASET_MAP_PREVIEW_FAIL = '[import_dataset] load map preview fail',
  IMPORT_DATASET_DELETE = '[import_dataset] delete',
  IMPORT_DATASET_UPDATE = '[import_dataset] update',
  IMPORT_CSV_TYPES_LOAD = '[import_csv_types] load',
  IMPORT_CSV_TYPES_LOAD_SUCCESS = '[import_csv_types] load success',
  DOWNLOAD_CSV = '[download] csv',
  DOWNLOAD_CSV_SUCCESS = '[download] csv success',
  DOWNLOAD_CSV_ZIP = '[download] csv zip',
  DOWNLOAD_CSV_ZIP_SUCCESS = '[download] csv zip success',
  DOWNLOAD_LOG = '[download] log',
  DOWNLOAD_LOG_SUCCESS = '[download] log success',
}

export class ImportDataSetTypesLoad implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_TYPES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ImportDataSetTypesLoadSuccess implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_TYPES_LOAD_SUCCESS;

  constructor(public payload: IDataSetType[]) {
  }
}

export class ImportDataSetColumnsLoad implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_COLUMNS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ImportDataSetColumnsLoadSuccess implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_COLUMNS_LOAD_SUCCESS;

  constructor(public payload: IDataSetColumn[]) {
  }
}

export class ImportDataSetsLoad implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ImportDataSetsLoadSuccess implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_LOAD_SUCCESS;

  constructor(public payload: IDataSet[]) {
  }
}

/**
 * try delete import dataset
 */
export class ImportDataSetDelete implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadDatasetMapPreview implements IAction {
  readonly type = ImportActions.LOAD_DATASET_MAP_PREVIEW;

  constructor(public payload: string) {
  }
}

export class LoadDatasetMapPreviewFail implements IAction {
  readonly type = ImportActions.LOAD_DATASET_MAP_PREVIEW_FAIL;
}

export class ImportUpdate implements IAction {
  readonly type = ImportActions.IMPORT_DATASET_UPDATE;

  constructor(public payload: IDataSet) {
  }
}

export class ImportCsvTypesLoad implements IAction {
  readonly type = ImportActions.IMPORT_CSV_TYPES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ImportCsvTypesLoadSuccess implements IAction {
  readonly type = ImportActions.IMPORT_CSV_TYPES_LOAD_SUCCESS;

  constructor(public payload: ICsvType[]) {
  }
}

export class DownloadCsv implements IAction {
  readonly type = ImportActions.DOWNLOAD_CSV;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DownloadCsvSuccess implements IAction {
  readonly type = ImportActions.DOWNLOAD_CSV_SUCCESS;

  constructor(public payload: string[]) {
  }
}

export class DownloadCsvZip implements IAction {
  readonly type = ImportActions.DOWNLOAD_CSV_ZIP;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DownloadCsvZipSuccess implements IAction {
  readonly type = ImportActions.DOWNLOAD_CSV_ZIP_SUCCESS;

  constructor(public payload: string[]) {
  }
}

export class DownloadLog implements IAction {
  readonly type = ImportActions.DOWNLOAD_LOG;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DownloadLogSuccess implements IAction {
  readonly type = ImportActions.DOWNLOAD_LOG_SUCCESS;

  constructor(public payload: string[]) {
  }
}

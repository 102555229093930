import {Component} from '@angular/core';

const CONTENT = `
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <polygon points="166 164 22 164 22 32 30 32 30 156 166 156 166 164"></polygon>
            <rect x="84.01" y="58" width="24" height="90" class="hoverFill"></rect>
            <rect x="44" y="108" width="24" height="40" class="hoverFill"></rect>
            <rect x="124" y="88" width="24" height="60" class="hoverFill"></rect>
          </svg>
`;

@Component({
  selector: 'ap-statistic-icon',
  template: CONTENT
})
export class ApStatisticIconComponent {
}

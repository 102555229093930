<fieldset>
  <div [formGroup]="form"
       [style.display]="'grid'"
       [style.column-gap]="'8px'">
    <kendo-numerictextbox [formControlName]="'value'"
                          [format]="format"
                          [min]="range.min"
                          [max]="range.max"
                          [style.grid-row]="1"
                          [style.grid-column]="2"
                          [class]="'float-right'">
    </kendo-numerictextbox>
    <div [style.grid-row]="2"
         [style.grid-column]="1"
         [style.place-self]="'center'">
      <input type="checkbox"
             [formControlName]="'minEnabled'"
             [class]="'k-checkbox'"
             [id]="'min-filter'">
      <label [attr.for]="'min-filter'" [class]="'k-checkbox-label'">
        <ap-responsive-text [key]="'Global__Min'"></ap-responsive-text>
      </label>
    </div>
    <kendo-numerictextbox [formControlName]="'minValue'"
                          [format]="format"
                          [style.grid-row]="2"
                          [style.grid-column]="2"
                          [min]="range.min"
                          [max]="dynamicMax | async"
                          [class]="'float-right'">
    </kendo-numerictextbox>
    <div [style.grid-row]="3"
         [style.grid-column]="1"
         [style.place-self]="'center'">
      <input type="checkbox"
             [class]="'k-checkbox'"
             [formControlName]="'maxEnabled'"
             [id]="'max-filter'">
      <label [attr.for]="'max-filter'" [class]="'k-checkbox-label'">
        <ap-responsive-text [key]="'Global__Max'"></ap-responsive-text>
      </label>
    </div>
    <kendo-numerictextbox [formControlName]="'maxValue'"
                          [format]="format"
                          [style.grid-row]="3"
                          [style.grid-column]="2"
                          [min]="dynamicMin | async"
                          [max]="range.max"
                          [class]="'float-right'">
    </kendo-numerictextbox>
  </div>
</fieldset>

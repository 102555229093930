import {ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {
  NotifyStore
}                                                                              from '../../../stores/dialog/notify.store';
import {
  NotificationRef,
  NotificationService
}                                                                              from '@progress/kendo-angular-notification';
import {Subscription}                                                          from 'rxjs';
import {
  ChipType
}                                                                              from '@progress/kendo-angular-buttons/dist/es2015/chip/models/type';
import {
  ApNotifyMessageComponent
}                                                                              from '../ap-notify-message/ap-notify-message.component';
import {
  TranslationStore
}                                                                              from '../../../stores/translation/translation.store';
import {filter}                                                                from 'rxjs/operators';
import IApValidationResult = Data.Api.Validation.IApValidationResult;
import ApValidationLevel = Data.Api.Validation.ApValidationLevel;

/**
 * Component for displaying notifications from backend.
 */
@Component({
  selector: 'ap-notify',
  template: '',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ApNotifyComponent implements OnDestroy {

  @ViewChild('notificationTemplate', {read: TemplateRef})
  public notificationTemplate: TemplateRef<any>;
  private _subscriptions: Array<Subscription> = [];
  public notes: Array<IApValidationResult> = [];
  public notificationTypeMapping: Map<ApValidationLevel, string> = new Map([
    [ApValidationLevel.Info, 'info'],
    [ApValidationLevel.Warning, 'warning'],
    [ApValidationLevel.News, 'info'],
    [ApValidationLevel.Success, 'success'],
    [ApValidationLevel.Error, 'error'],
  ]);
  private currentlyDisplayedMessages: Array<IApValidationResult> = [];
  private notifications: NotificationRef[] = [];


  /**
   * the actual notes displayed on screen.
   */
  constructor(private notifyStore: NotifyStore,
              private notificationService: NotificationService,
              private translationStore: TranslationStore) {

    this._subscriptions.push(this.notifyStore
      .Listen(n => n.displayMessages)
      .subscribe(messages => {
        for (const n of this.notifications) {
          n.hide();
        }
        this.notifications = [];
        messages.forEach(message => {
          this.handleNotificationMessage(message);
        });
      }));
  }

  private static calcNotificationTimeout(message: IApValidationResult): number {
    let notificationTimeout = message.DisplayTimeout;
    // if no timeout is set => whole day.
    if (!notificationTimeout || notificationTimeout <= 0) {
      notificationTimeout = 24 * 60 * 60;
    }
    notificationTimeout = notificationTimeout * 1000;
    return notificationTimeout;
  }

  private handleNotificationMessage(message: IApValidationResult): void {
    this.translationStore.Listen(_ => _.loading).pipe(filter(translationsLoading => !translationsLoading)).subscribe(() => {
      if (!this.notifyStore.getDisplayMessages().Contains(message) ||
        !message || !message.ErrorKey || message.ErrorKey === '') {
        return;
      }

      if (this.currentlyDisplayedMessages.Contains(message)) {
        return;
      }

      this.currentlyDisplayedMessages.push(message);
      // (dirty) workaround for passing the message to the message-component.
      // if passing the message after creation of the component kendo position calculation
      // does not consider the new message -> notifcation not centered.
      ApNotifyMessageComponent.InitialMessage = this.translationStore.FindTranslationForSelectedLanguage(message.ErrorKey, message.Parameters);
      // ApTranslationService.translate(message.ErrorKey);

      const notificationRef: NotificationRef = this.notificationService.show({
        content: ApNotifyMessageComponent,
        position: {horizontal: 'center', vertical: 'top'},
        animation: {type: 'fade', duration: 300},
        hideAfter: ApNotifyComponent.calcNotificationTimeout(message),
        type: {style: this.notificationTypeMapping.get(message.Level) as ChipType, icon: true},
      });
      this.notifications.push(notificationRef);

      if (notificationRef) {
        this._subscriptions.push(notificationRef.afterHide
          .subscribe(() => {
            this.notifyStore.removeMessage(message);
            if (this.currentlyDisplayedMessages.Contains(message)) {
              this.currentlyDisplayedMessages.Remove(message);
            }
          }));
        this._subscriptions.push(notificationRef.content.instance.hide
          .subscribe(() => notificationRef.hide()));
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }
}

import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  NetTypes,
  SliceCalcBruttoNettoArea,
  SliceCombinedLoadByMachineAndDate,
  SliceCombinedLoadByMachineAndDateSuccess,
  SliceLoad,
  SliceLoadAll,
  SliceLoadSuccess
}                    from 'invoker-transport';
import {IStateStore} from '../../ap-interface';
import {Store}       from '../index';
import {isDate}      from 'lodash';
import ISliceFilter = Data.DocuContext.Slice.ISliceFilter;
import IMachine = Data.DocuContext.Machine.IMachine;
import ISlice = Data.DocuContext.Slice.ISlice;
import ICombinedSlice = Data.DocuContext.Slice.ICombinedSlice;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface ISliceStore extends IStateStore<ISlice> {
  combinedSlices: ICombinedSlice[];
}

@Injectable({providedIn: 'root'})
export class SliceStore extends Store<ISliceStore> {
  constructor(private backend: ApSignalrService) {
    super(backend, {
      loading: false,
      loaded: false,
      data: [],
      combinedSlices: []
    });
    backend.registerObservable(SliceLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SliceCombinedLoadByMachineAndDateSuccess).subscribe(d => {
      super.Mutate(s => s.combinedSlices, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  public loadAllSlices(): void {
    super.SetLoadState();
    super.Mutate(d => d.data, () => []);
    super.DispatchBackend(new SliceLoadAll([]));
  }

  public loadSlicesCombinedByMachineAndDate(machine: IMachine, from, to): void {
    const dateFrom = isDate(from) ? from.toISOString() : from;
    const dateTo = isDate(to) ? to.toISOString() : to;
    super.SetLoadState();
    super.Mutate(d => d.data, () => []);
    super.DispatchBackend(new SliceCombinedLoadByMachineAndDate([
        {Name: 'machine', Type: ApCustomTypes.Data_DocuContext_Machine_Machine, Value: machine},
        {Name: 'from', Type: NetTypes.DATETIME, Value: dateFrom},
        {Name: 'to', Type: NetTypes.DATETIME, Value: dateTo}
      ]
    ));
  }

  public loadSlices(farmId: number, from: Date, to: Date, filter: ISliceFilter): void {
    super.SetLoadState();
    super.Mutate(d => d.data, () => []);
    super.DispatchBackend(new SliceLoad([
        {Name: 'from', Type: NetTypes.DATETIME, Value: from},
        {Name: 'to', Type: NetTypes.DATETIME, Value: to},
        {Name: 'sliceFilter', Type: ApCustomTypes.Data_DocuContext_Slice_SliceFilter, Value: filter},
      ]
    ));
  }

  public async calculateBruttoNettoArea(slices: ISlice[], workingWidth: number): Promise<ISlice[]> {
    return new Promise(resolve => {
      this.DispatchBackend(new SliceCalcBruttoNettoArea([
        {Name: 'slices', Type: ApCustomTypes.Data_DocuContext_Slice_Slice + '[]', Value: slices},
        {Name: 'workingWidth', Type: NetTypes.DOUBLE, Value: workingWidth},
      ])).watchStream({
        action: SliceCalcBruttoNettoArea,
        todo: res => {
          resolve(res.Data);
        }
      });
    });
  }
}

import { Pipe, PipeTransform }         from '@angular/core';
import {ApDynGridColumnConfigBase}     from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridTemplateColumnConfig} from '../../config/ap-dyn-grid-template-column-config';

@Pipe({
  name: 'asTemplate'
})
export class AsTemplatePipe implements PipeTransform {

  transform(config: ApDynGridColumnConfigBase): ApDynGridTemplateColumnConfig {
    return config as ApDynGridTemplateColumnConfig;
  }

}

<div [formGroup]="formGroup">
  <div [formArrayName]="getArrayName()">
    <ng-container *ngFor="let value of arrayMax; index as valueIndex">
      <ng-container [formGroupName]="valueIndex.toString()">
        <ng-container *ngFor="let configRow of config.configChunk; index as rowIndex">
          <div [class]="'ap-dynform-fieldset'" [style.column-gap]="(40 / config.columns / (config.columns - 1)) + '%'">
            <ap-dynforms-element *ngFor="let elementConfig of configRow; index as columnIndex"
                                 [class]="'ap-dynform-element'"
                                 [parentKey]="config.key"
                                 [parentIndex]="valueIndex"
                                 [config]="elementConfig"
                                 [form]="getArrayFormGroup(value)"
                                 [style.grid-column]="columnIndex + 1"
                                 [style.grid-row]="rowIndex + 1"
                                 [fontSize]="fontSize"
                                 [darkMode]="darkMode">
            </ap-dynforms-element>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>


import {Pipe, PipeTransform}   from '@angular/core';
import {ApElementOxydTypeEnum} from '../../ap-interface';
import {FactorService}         from '../service/ap-factor.service';

/**
 * Umrechnungsfaktoren
 * gegeben gesucht Faktor
 * P2O5    P       0,4364
 * K2O     K       0,8300
 * MgO     Mg      0,6030
 *
 * gegeben gesucht Faktor
 * P       P2O5    2,2914
 * K       K2O     1,2050
 * Mg      MgO     1,6580
 */

@Pipe({
  name: 'getElementOxidCalculate'
})
export class GetElementOxidCalculatePipe implements PipeTransform {

  constructor(private factorService: FactorService) {
  }

  transform(value: number, unit: ApElementOxydTypeEnum, farmUnit: number): number {
    if (farmUnit === 1) {
      return value;
    } else if (farmUnit === 2) {
      let retval = value;
      switch (unit) {
        case ApElementOxydTypeEnum.P:
          retval = this.factorService.calculate(value, 'P', 'P2O5');
          break;
        case ApElementOxydTypeEnum.K:
          retval = this.factorService.calculate(value, 'K', 'K2O');
          break;
        case ApElementOxydTypeEnum.Mg:
          retval = this.factorService.calculate(value, 'Mg', 'MgO');
          break;
        case ApElementOxydTypeEnum.P2O5:
          retval = this.factorService.calculate(value, 'P2O5', 'P');
          break;
        case ApElementOxydTypeEnum.K2O:
          retval = this.factorService.calculate(value, 'K2O', 'K');
          break;
        case ApElementOxydTypeEnum.MgO:
          retval = this.factorService.calculate(value, 'MgO', 'Mg');
          break;
      }
      return retval;
    }
    return null;
  }
}

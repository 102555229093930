import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1050 1050">
<path d="M990,500c0,270.6-219.4,490-490,490C229.4,990,10,770.6,10,500C10,229.4,229.4,10,500,
10S990,229.4,990,500z M858.3,507.7v-17.6c0-49.7-89.7-63.2-95.3-64c-6.1-22-14.9-42.9-25.9-62.3c12.6-16.9,
56-80.3,24.7-111.6l-16.1-16.9c-26.8-26.7-95.1,15-111.7,25.8c-19.6-11.1-40.7-19.9-63-26c-3.6-21.2-19.2-95.5-63.2-95.5h-17.6c-37.8,
0-59.5,76.8-64.3,96c-22,6.1-42.8,15-62.1,26.1c-15.3-11.4-80.7-56.6-112.7-24.6l-16.9,14.3c-27.8,27.8,18.4,101.3,26.9,114.1c-10.7,
19.1-19.3,39.6-25.2,61.1c-18.5,2.9-97.3,18.2-97.3,63.5v17.6c0,39.3,82.6,61.1,97.7,64.8c6,21.4,14.6,41.7,25.4,60.6c-8.9,13.9-53.3,
87-25.8,114.6l16.1,13.3c36.3,36.3,112.2-23.3,112.2-23.3l-3.5-3.8c20.1,11.9,41.9,21.3,64.9,27.8c3.8,15.5,25.6,97.6,64.7,97.6h17.6c51.3,
0,64.1-95.8,64.1-95.8l-4.7-0.2c23.3-5.9,45.3-14.9,65.8-26.4c16,10.7,83.2,52.7,110.1,25.9l17.9-17.8c35.8-35.8-22.5-109.1-24.1-111.1c11.1-19.4,
19.9-40.2,26.1-62.1C782.9,566.8,858.3,545.2,858.3,507.7z M701,499.7c0,111.8-90.6,202.4-202.4,202.4c-111.8,0-202.4-90.6-202.4-202.4c0-111.8,
90.6-202.4,202.4-202.4C610.4,297.2,701,387.8,701,499.7z M633.5,499.7c0-74.5-60.4-135-134.9-135c-74.5,0-134.9,60.4-134.9,135c0,74.5,60.4,134.9,
134.9,134.9C573.1,634.6,633.5,574.2,633.5,499.7z M566,499.7c0,37.3-30.2,67.5-67.5,67.5c-37.3,0-67.5-30.2-67.5-67.5c0-37.3,
30.2-67.5,67.5-67.5C535.8,432.2,566,462.4,566,499.7z" class="hoverFill"/>
    </svg>
`;

@Component({
  selector: 'ap-icon-collapse',
  template: CONTENT
})
export class ApIconCollapsComponent {
}

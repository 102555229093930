import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators}                                       from '@angular/forms';
import {BehaviorSubject, Subscription}                                                       from 'rxjs';
import {TranslationStore}                                                                    from '../../stores/translation/translation.store';
import IAttachmentApplication = Data.DocuContext.Attachment.IAttachmentApplication;
import IWorktypes = Data.BaseData.IWorktypes;

/**
 * action applications
 */
@Component({
  selector: 'ap-action-applications',
  templateUrl: 'ap-action-applications.component.html',
})
export class ApActionApplicationsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() application: IAttachmentApplication;
  @Input() formGroup: FormGroup;
  @Input() index: number;
  @Input() workType: IWorktypes;
  @Output() delete = new EventEmitter<any>();
  @Output() applicationChange = new EventEmitter<IAttachmentApplication>(true);

  public form: FormGroup;
  public units: BehaviorSubject<{ value: string, text: string }[]> = new BehaviorSubject<{value: string, text: string}[]>([]);
  public value = 0;
  private _addControl = new EventEmitter<{ index: number, form: FormGroup }>(true);
  private _subscriptions: Subscription[] = [];
  private _newValues;

  /**
   * connect to the stores
   */
  constructor(private fb: FormBuilder,
              private translationStore: TranslationStore) {
    this._subscriptions.push(
      this._addControl.subscribe((d: { index: number, form: FormGroup }) => {
        if (!this.formGroup.get('Attachment.Application')) {
          if (!this.formGroup.get('Attachment')) {
            this.formGroup.addControl('Attachment', new FormGroup({}));
          }
          (this.formGroup.get('Attachment') as FormGroup).addControl('Application', new FormArray([]));
        }
        const application = this.formGroup.get('Attachment.Application') as FormArray;
        if (application) {
          if (application.at(d.index)) {
            application.setControl(d.index, d.form);
          } else {
            application.insert(d.index, d.form);
          }
        }
      })
    );
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    this.formBuilder();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('workType')) {
      this._setWorkTypeUnits();
      if (this.form && this.form.get('Unit')) {
        if (this.units.getValue().map(u => u.value).indexOf(this.form.get('Unit').value) === -1) {
          this.form.get('Unit').setValue(
            this.workType && this.workType.Unit.length ? this.workType.Unit[0] : '');
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.value = this.getValue(this.application.Value);
    this._setWorkTypeUnits();
    let unit = this.application.Unit;
    if (!unit) {
      unit = this.application.Unit[0];
    }
    this.form = this.fb.group({
      Key: [this.application.Key, [Validators.required]],
      Value: [this.value, Validators.required],
      Unit: unit,
    });

    this._subscriptions.push(this.form.valueChanges.subscribe((values: IAttachmentApplication) => {
      this._newValues = values;
    }));

    if (this.formGroup) {
      this._addControl.emit({
        index: this.index,
        form: this.form,
      });
    }
  }

  /**
   * on delete button click event
   */
  onDeleteClick(): void {
    this.delete.emit(this.index);
    const applications = this.formGroup.get('Attachment.Application') as FormArray;
    if (applications) {
      applications.removeAt(this.index);
    }
  }

  /**
   * get the value
   */
  getValue(value: any): number {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  }

  public onBlur(): void {
    if (!!this._newValues) {
      this.applicationChange.emit(this._newValues);
    }
  }

  private _setWorkTypeUnits(): void {
    const wtUnit = this.translationStore.FindTranslationForSelectedLanguage(this.workType.Wt_Unit);
    this.units.next(this.workType.Unit.map((u) => ({value: u, text: `${u} / ${wtUnit}`})));
  }
}

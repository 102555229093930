import {Component, Input, OnInit}          from '@angular/core';
import {ApDynGridStatusSwitchColumnConfig} from '../config/ap-dyn-grid-status-switch-column.config';
import {CompositeFilterDescriptor}         from '@progress/kendo-data-query';
import {FilterService}                     from '@progress/kendo-angular-grid';

@Component({
  selector: 'ap-status-switch-column-filter',
  template: `
    <ap-status-switch-filter [filterService]="filterService"
                             [currentFilter]="currentFilter"
                             [key]="config.field"
                             [items]="items">
    </ap-status-switch-filter>
  `,
  styles: []
})
export class ApStatusSwitchColumnFilterComponent {
  @Input() config: ApDynGridStatusSwitchColumnConfig;
  @Input() currentFilter: CompositeFilterDescriptor;
  @Input() filterService: FilterService;
  @Input() items: any[];
}

@Component({
  selector: 'ap-status-switch-column-group-header',
  template: `
    <div [class]="'status-switch-group-header'">
      {{'Global__Status' | translate}}{{': '}}
      <ap-icon-selector [id]="id"
                        [cssClass]="cssClass">
      </ap-icon-selector>
    </div>
  `,
  styles: []
})
export class ApStatusSwitchColumnGroupHeaderComponent implements OnInit {
  @Input() value: number;

  id: string;
  cssClass: string;

  ngOnInit(): void {
    switch (this.value) {
      case -1:
        this.id = 'ap-times-circle-solid-icon';
        this.cssClass = 'icon red';
        break;
      case 0:
        this.id = 'ap-exclamation-circle-solid-icon';
        this.cssClass = 'icon yellow';
        break;
      case 1:
        this.id = 'ap-check-circle-solid-icon';
        this.cssClass = 'icon green';
        break;
    }
  }
}

@Component({
  selector: 'ap-status-switch-column-cell',
  template: `
    <span [class]="config.cssClass | centerCell">
      <ap-status-switch [disableGreen]="config.disableGreenFn ?
                                        (config.disableGreenFn | execFunction:(dataItem)) :
                                        config.disableGreen | async"
                        [disableRed]="config.disableRedFn ?
                                      (config.disableRedFn | execFunction:(dataItem)) :
                                      config.disableRed | async"
                        [disableYellow]="config.disableYellowFn ?
                                         (config.disableYellowFn | execFunction:(dataItem)) :
                                         config.disableYellow | async"
                        [readonly]="config.readonly"
                        [dataItem]="dataItem"
                        [field]="config.field"
                        (stateChange)="config.stateChange?.emit(
                        {item: dataItem, state: $event})"
                        [showYellowState]="config.showYellowState"
                        [toolTipGreen]="config.toolTipGreen"
                        [toolTipRed]="config.toolTipRed">
      </ap-status-switch>
    </span>
  `,
  styles: []
})
export class ApStatusSwitchColumnCellComponent {
  @Input() config: ApDynGridStatusSwitchColumnConfig;
  @Input() dataItem: any;
}

import {NgModule}                             from '@angular/core';
import {ApMapComponent}                       from './components/ap-map.component';
import {ApMapZoomComponent}                   from './components/ap-map-zoom.component';
import {ApMapRoutes}                          from './ap-map.routes';
import {CommonModule}                         from '@angular/common';
import {ApMachinePopupComponent}              from './components/ap-machine-popup.component';
import {ApSensorPointPopupComponent}          from './components/ap-sensor-point-popup/ap-sensor-point-popup.component';
import {SensorPointLegendComponent}           from './components/sensor-point-legend/sensor-point-legend.component';
import {ApMapColorLegendComponent}            from './components/ap-map-color-legend.component';
import {ApTranslationModule}                  from '../translation/ap-translation.module';
import {ApMapEditComponent}                   from './components/ap-map-edit.component';
import {ToolBarModule}                        from '@progress/kendo-angular-toolbar';
import {ApUtilsModule}                        from '../ap-utils';
import {ButtonModule, ButtonsModule}          from '@progress/kendo-angular-buttons';
import {LayoutModule}                         from '@progress/kendo-angular-layout';
import {ApPermissionModule}                   from '../ap-permission/ap-permission.module';
import {ContextMenuModule}                    from '@progress/kendo-angular-menu';
import {ApMapContextMenuComponent}            from './components/ap-map-context-menu.component';
import {ComboBoxModule, DropDownsModule}      from '@progress/kendo-angular-dropdowns';
import {ApMapTreeViewComponent}               from './components/ap-map-tree-view.component';
import {TreeViewModule}                       from '@progress/kendo-angular-treeview';
import {MapTreeViewDirective}                 from './directives/map-tree-view.directive';
import {ApMapBurgerMenuComponent}             from './components/ap-map-burger-menu.component';
import {PopupModule}                          from '@progress/kendo-angular-popup';
import {CheckBoxModule, RadioButtonModule}    from '@progress/kendo-angular-inputs';
import {BodyModule, GridModule, SharedModule} from '@progress/kendo-angular-grid';
import {ApResponsiveTextModule}               from '../ap-responsive-text/ap-responsive-text.module';
import {NewFieldToolbarComponent}             from './components/edit/new-field-toolbar/new-field-toolbar.component';
import {ApDynamicComponentLoaderModule}       from '../entry-components/ap-dynamic-component-loader/ap-dynamic-component-loader.module';
import {ApTooltipModule}                      from '../ap-tooltip/ap-tooltip.module';
import {ApLayerExportComponent}               from './export/ap-layer-export.component';
import {LayerControllerComponent}             from './components/layer-controller/layer.controller.component';
import {GetPanelBarItemsPipe}                 from './components/layer-controller/get.panel.bar.items.pipe';
import {LayerPanelBarItemComponent}           from './components/layer-controller/layer-panel-bar-item.component';
import {GetLayerPipe}                         from './components/layer-controller/get.layer.pipe';
import {GetGroupLayerVisibilityPipe}          from './components/layer-controller/get.group.layer.visibility.pipe';
import {LayerControllerMapSelectorComponent}  from './components/layer-controller/layer.controller.map.selector.component';
import {ReactiveFormsModule}                  from '@angular/forms';
import {ApLegendScrollerComponent}            from './components/layer-controller/ap-legend-scroller.component';
import {NgScrollbarModule}                    from 'ngx-scrollbar';
import {ApLegendScrollerItemComponent}        from './components/layer-controller/ap-legend-scroller-item.component';
import {GetLayerValuesPipe}                   from './components/layer-controller/get.layer.values.pipe';
import {ApLegendColorItemComponent}           from './components/layer-controller/ap-legend-color-item.component';
import {GetUnitValuePipe}                     from './components/layer-controller/get.unit.value.pipe';
import { GetLegendSelectionValuesPipe } from './components/layer-controller/pipes/get-legend-selection-values.pipe';
import {ApMapTooltipComponent}                from './components/ap-map-tooltip.component';

@NgModule({
  declarations: [
    ApMapComponent,
    ApMapZoomComponent,
    ApMachinePopupComponent,
    ApSensorPointPopupComponent,
    SensorPointLegendComponent,
    ApMapEditComponent,
    NewFieldToolbarComponent,
    ApMapColorLegendComponent,
    ApMapContextMenuComponent,
    ApMapTooltipComponent,
    ApMapTreeViewComponent,
    MapTreeViewDirective,
    ApMapBurgerMenuComponent,
    ApLayerExportComponent,
    LayerControllerComponent,
    GetPanelBarItemsPipe,
    LayerPanelBarItemComponent,
    GetLayerPipe,
    GetGroupLayerVisibilityPipe,
    LayerControllerMapSelectorComponent,
    ApLegendScrollerComponent,
    ApLegendScrollerItemComponent,
    GetLayerValuesPipe,
    ApLegendColorItemComponent,
    GetUnitValuePipe,
    GetLegendSelectionValuesPipe,
  ],
  exports: [
    ApMapComponent,
    ApMapZoomComponent,
    ApMapEditComponent,
    NewFieldToolbarComponent,
    ApMachinePopupComponent,
    ApLayerExportComponent,
    ApMapTooltipComponent
  ],
  imports: [
    ApMapRoutes,
    CommonModule,
    ApTranslationModule,
    ToolBarModule,
    ApUtilsModule,
    ButtonModule,
    ButtonsModule,
    LayoutModule,
    ApPermissionModule,
    ContextMenuModule,
    ComboBoxModule,
    TreeViewModule,
    DropDownsModule,
    PopupModule,
    CheckBoxModule,
    GridModule,
    SharedModule,
    BodyModule,
    ApResponsiveTextModule,
    ApDynamicComponentLoaderModule,
    ApTooltipModule,
    RadioButtonModule,
    ReactiveFormsModule,
    NgScrollbarModule
  ],
  providers: [],
  entryComponents: []
})
export class ApMapModule {
}

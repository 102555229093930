<div class="container">
  <div class="wrapper">
    <div class="arrow-steps">
      <ng-container *ngFor="let key of getKeys()">
        <div class="step" [class.current]="getInt(key) === selected">
          {{columns[key] | translate}}
        </div>
      </ng-container>
    </div>
  </div>
</div>

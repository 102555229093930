/**
 * List of Track Property Names
 */
export class TrackPropertyNames {

  static readonly SPEED = 'speed';

  static readonly FUEL_RATE = 'fuel_rate';

  static readonly ENGINE_SPEED = 'engine_speed';

  static readonly APPLICATION_STATE = 'application_rate';

  static readonly TANK = 'tank';

  static readonly WORK_STATE = 'work_state';

  static getAll(): string[] {
    return [ this.SPEED, this.FUEL_RATE, this.ENGINE_SPEED, this.APPLICATION_STATE, this.TANK, this.WORK_STATE ];
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {Trace}               from '../../../debug-utils/ApplicationTracer';
import IMenuEntry = Data.MenuStructure.IMenuEntry;
import IMenuChildren = Data.MenuStructure.IMenuChildren;

@Pipe({
  name: 'filterByString'
})
export class FilterByStringPipe implements PipeTransform {

  @Trace()
  transform(main: IMenuEntry, ...args: [string, keyof IMenuChildren]): boolean {
    return main.Childs.filter(e => e[args[1]] === args[0]).length > 0;
  }

}

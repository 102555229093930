import {Component, Input}              from '@angular/core';
import {ApDynGridPropertyColumnConfig} from '../config/ap-dyn-grid-property-column-config';
import {CompositeFilterDescriptor}     from '@progress/kendo-data-query';
import {FilterService}                 from '@progress/kendo-angular-grid';
import {ApCalendarView}                from '../../ap-utils/enums/ApCalendarView';

@Component({
  selector: 'ap-property-column-filter',
  template: `
    <ng-container [ngSwitch]="config.filterType">
      <ap-date-range-filter *ngSwitchCase="'DATE'"
                            [key]="config.field"
                            [filterService]="filterService"
                            [currentFilter]="currentFilter"
                            [view]="calendarView.DAY"
                            [items]="items">
      </ap-date-range-filter>
      <ap-date-range-filter *ngSwitchCase="'MONTH'"
                            [key]="config.field"
                            [filterService]="filterService"
                            [currentFilter]="currentFilter"
                            [view]="calendarView.MONTH"
                            [items]="items">
      </ap-date-range-filter>
      <ap-date-range-filter *ngSwitchCase="'YEAR'"
                            [key]="config.field"
                            [filterService]="filterService"
                            [currentFilter]="currentFilter"
                            [view]="calendarView.YEAR"
                            [items]="items">
      </ap-date-range-filter>
      <ap-multi-select-filter *ngSwitchCase="'STRING'"
                              [isPrimitive]="true"
                              [filterService]="filterService"
                              [currentFilter]="currentFilter"
                              [key]="config.field"
                              [items]="items">
      </ap-multi-select-filter>
      <ap-checkbox-filter *ngSwitchCase="'CHECKBOX'"
                          [filterService]="filterService"
                          [currentFilter]="currentFilter"
                          [key]="config.field"
                          [items]="items">
      </ap-checkbox-filter>
      <ap-number-filter *ngSwitchCase="'NUMBER'"
                        [filterService]="filterService"
                        [currentFilter]="currentFilter"
                        [format]="config.format"
                        [key]="config.field"
                        [items]="items">
      </ap-number-filter>
      <ap-number-list-filter *ngSwitchCase="'NUMBER_LIST'"
                             [filterService]="filterService"
                             [currentFilter]="currentFilter"
                             [items]="items"
                             [key]="config.field">
      </ap-number-list-filter>
      <ap-object-filter *ngSwitchCase="'OBJECT'"
                        [filterService]="filterService"
                        [currentFilter]="currentFilter"
                        [items]="items"
                        [key]="config.field"
                        [pipes]="config.pipes">
      </ap-object-filter>
    </ng-container>
  `,
})
export class ApPropertyColumnFilterComponent {
  @Input() config: ApDynGridPropertyColumnConfig;
  @Input() currentFilter: CompositeFilterDescriptor;
  @Input() dataItem: any;
  @Input() filterService: FilterService;
  @Input() items: any[];
  public calendarView = ApCalendarView;
}

@Component({
  selector: 'ap-property-column-header',
  template: `
    <ap-responsive-text [key]="config.title"
                        [aptooltip]="(config.tooltip | execFunction | translate) | execPipes:config:'tooltipPipes' | async"
                        [extraLines]="config.headerExtraLines"
                        [breakLine]="config.breakHeaderLine"
                        [perpetual]="true">
    </ap-responsive-text>
  `,
})
export class ApPropertyColumnHeaderComponent {
  @Input() config: ApDynGridPropertyColumnConfig;
}

@Component({
  selector: 'ap-property-column-cell',
  template: `
    <ng-container *ngIf="config.checkbox">
        <span [class]="config.cssClass | centerCell">
          <input type="checkbox"
                 class="k-checkbox"
                 [checked]="dataItem[config.field]"
                 [id]="'propertyCheckboxId_' + config.field"
                 [disabled]="true"/>
          <label [class]="'k-checkbox-label'"
                 [for]="'propertyCheckboxId_' + config.field"></label>
        </span>
    </ng-container>
    <ng-container *ngIf="config.svg">
        <span [class]="config.cssClass | centerCell">
          <ap-svg [class]="config.svgClassField ? dataItem[config.svgClassField] : 'ap-svg-grey'"
                  [shape]="([dataItem[config.field]] | getFieldThumbnail).svgPath"
                  [viewBox]="([dataItem[config.field]] | getFieldThumbnail).viewBox"
                  [strokeWidth]="0"
                  [width]="32"
                  [height]="32"
                  [strokeColor]="'black'"></ap-svg>
        </span>
    </ng-container>
    <ng-container
      *ngIf="!config.checkbox && !config.svg">
      <ap-responsive-text
        *ngIf="((config | asProperty).columnLoading === undefined || dataItem[(config | asProperty).columnLoading] === false)"
        [multiline]="config?.multiline"
        [format]="config.format | getKendoDateFormat"
        [text]="((dataItem | getProperty:(config | asProperty).field | execPipes:config:'pipes' | async) | getText:config) | apCustomFormatPipe:config:dataItem"
        [class]="config | dynamicCell:dataItem"
        [tooltipOverride]="config.tooltip | execFunction:(dataItem)">
      </ap-responsive-text>
      <kendo-loader
        *ngIf="((config | asProperty).columnLoading !== undefined && dataItem[(config | asProperty).columnLoading] === true)"
        [size]="'small'" [class]="config.cssClass | centerCell">
      </kendo-loader>
    </ng-container>
  `,
})
export class ApPropertyColumnCellComponent {
  @Input() config: ApDynGridPropertyColumnConfig;
  @Input() dataItem: any;
}

import {EventEmitter, Injectable} from '@angular/core';
import {ISelectionChangeEvent}    from './ap-legend-scroller-item.component';
import {ILegend}                  from '../../../stores/map/map.legend.store';

@Injectable({providedIn: 'root'})
export class ApLegendScrollerService {
  public selectionChange = new EventEmitter<ISelectionChangeEvent>();
  public onSelectionKeyChange = new EventEmitter<{ l: ILegend, key: string }>();

  public generateSelectionKey(item: ILegend): string {
    if (!item) {
      return '';
    }
    let currentSelectionKey = '';
    if (Array.isArray(item.selectionLists) && item.selectionLists.length > 0) {
      for (const selection of item.selectionLists) {
        currentSelectionKey += '_' + selection.selectedValue.getValue()?.value;
      }
    }
    if (Array.isArray(item.subjects) && item.subjects.length > 0) {
      for (const subject of item.subjects) {
        currentSelectionKey += '_' + subject.getValue();
      }
    }
    if (currentSelectionKey.StartsWith('_')) {
      currentSelectionKey = currentSelectionKey.TrimStart('_');
    }

    this.onSelectionKeyChange.emit({l: item, key: currentSelectionKey});
    return currentSelectionKey;
  }
}

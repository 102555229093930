import {IStateStore}                      from '../../ap-interface';
import {Injectable}                       from '@angular/core';
import {Store}                            from '../index';
import {SafeBehaviorSubject}              from 'ts-tooling';
import {ApSignalrService}                 from '../../ap-core/services/ap-signalr.service';
import {
  SensorFieldDataLoad,
  SensorFieldDataLoadSuccess, SensorRasterValuesLoad, SensorRasterValuesLoadSuccess
} from '../../../../projects/invoker-transport/src/lib/actions/sensor';
import {
  NetTypes,
} from 'invoker-transport';
import ISensorFieldData = Data.Sensor.ISensorFieldData;
import IGuid = System.IGuid;

interface INFertilizationStore extends IStateStore<ISensorFieldData> {
  sensorRasterValues: number[][];
}

@Injectable({providedIn: 'root'})
export class SensorStore extends Store<INFertilizationStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      sensorRasterValues: []
    });

    backend.registerObservable(SensorFieldDataLoadSuccess).subscribe(d => {
      this.SetLoadFinishState();
      super.Mutate(s => s.data, () => d.Data);
    });

    backend.registerObservable(SensorRasterValuesLoadSuccess).subscribe(d => {
      let result: number[][] = [];
      if (d.Data) {
        result = JSON.parse(d.Data);
      }
      super.Mutate(s => s.sensorRasterValues, () => result);
    });
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public loadSensorFieldData(): void {
    this.SetLoadState();
    this.DispatchBackend(new SensorFieldDataLoad([]));
  }

  public loadSensorRasterValues(planBookIds: IGuid[]): void {
    super.Mutate(s => s.sensorRasterValues, () => []);
    this.DispatchBackend(new SensorRasterValuesLoad([
      {Name: 'planBookIds', Type: NetTypes.GUID + '[]', Value: planBookIds}
    ]));
  }

  public getSensorFieldData(fieldGeomId: IGuid): ISensorFieldData {
    return super.Listen(s => s.data).getValue()?.Find(_ => _?.FieldGeomId === fieldGeomId) as ISensorFieldData;
  }
}

import {IStateStore} from '../../ap-interface';
import {Store}       from '../index';
import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  SettingsLoad,
  SettingsLoadSuccess,
  SettingsUpdate,
  TimeZoneLoad,
  TimeZoneLoadSuccess
}                    from 'invoker-transport';
import {map}         from 'rxjs/operators';
import {Observable}  from 'rxjs';
import {
  SettingsUpdateFail,
  SettingsUpdateSuccess
}                    from '../../../../projects/invoker-transport/src/lib/actions/base-data';
import ISettings = Data.BaseData.ISettings;
import ITimeZones = Data.BaseData.ITimeZones;
import {SafeBehaviorSubject} from 'ts-tooling';
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface ISettingsStore extends IStateStore<ISettings> {
  timeZones: ITimeZones[];
  settingsSaved: boolean | undefined;
}

@Injectable({providedIn: 'root'})
export class SettingsStore extends Store<ISettingsStore> {

  private static mapFarmSettings(d: ISettings[]): ISettings {
    return d.length ? d[0] : {
      CreatedAt: new Date(),
      CreatedBy: 0,
      ChangedAt: null,
      ChangedBy: null,
      DeletedAt: null,
      DeletedBy: null,
      Version: 0,
      UseMethodEuf: false,
      UnitElementOxyd: 1,
      IsOxidFarm: false,
      SelectedLanguageKey: 'en',
      FarmTime: 'UTC',
      DigitsAfterDecimalPoint: 2,
      FarmId: 0,
      Id: 0,
    };
  }

  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      timeZones: [],
      settingsSaved: undefined
    });
    backend.registerObservable(SettingsLoad).subscribe(_ => {
      super.SetLoadState();
    });
    backend.registerObservable(SettingsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(TimeZoneLoadSuccess).subscribe(d => {
      super.Mutate(s => s.timeZones, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SettingsUpdateSuccess).subscribe(_ => {
      super.Mutate(s => s.settingsSaved, () => true);
      super.Mutate(s => s.settingsSaved, () => undefined);
    });
    backend.registerObservable(SettingsUpdateFail).subscribe(_ => {
      super.Mutate(s => s.settingsSaved, () => false);
      super.Mutate(s => s.settingsSaved, () => undefined);
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.loading);
  }

  public get FirstSetting$(): Observable<ISettings> {
    return super.Listen(s => s.data).pipe(map(SettingsStore.mapFarmSettings));
  }

  public get IsOxidFarm(): boolean {
    return SettingsStore.mapFarmSettings(super.Listen(s => s.data).getValue()).UnitElementOxyd === 2;
  }

  public get FirstSetting(): ISettings {
    return SettingsStore.mapFarmSettings(super.Listen(s => s.data).getValue());
  }

  public getTimeZones(): ITimeZones[] {
    return super.Listen(s => s.timeZones).getValue();
  }

  public loadSettings(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new SettingsLoad([]));
  }

  public loadTimeZones(): void {
    this.DispatchBackend(new TimeZoneLoad([]));
  }

  public updateSettings(settings: ISettings): void {
    super.Mutate(s => s.settingsSaved, () => undefined);
    super.DispatchBackend(new SettingsUpdate([
      {Name: 'settings', Type: ApCustomTypes.Data_BaseData_Settings, Value: settings}
    ]));
  }
}

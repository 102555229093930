import {IStateStore}                                       from '../../ap-interface/interfaces/store';
import {Store}                                             from '../index';
import {Injectable}                                        from '@angular/core';
import {LanduseLoad, LanduseLoadSuccess} from 'invoker-transport';
import {TranslationStore}                                  from '../translation/translation.store';
import ILandUse = Data.BaseData.ILanduse;
import {SafeBehaviorSubject}                               from 'ts-tooling';
import ILanduse = Data.BaseData.ILanduse;
import {ApSignalrService}                                  from '../../ap-core/services/ap-signalr.service';

interface ILandUseStore extends IStateStore<ILandUse> {
}

@Injectable({providedIn: 'root'})
export class LandUseStore extends Store<ILandUseStore> {
  constructor(public backend: ApSignalrService,
              private translationService: TranslationStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(LanduseLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data.Convert(i => {
        i.Key = this.translationService.FindTranslationForSelectedLanguage(i.Key);
        return i;
      }));
      super.SetLoadFinishState();
    });
  }

  public loadLandUse(): void {
    this.DispatchBackend(new LanduseLoad([]));
  }

  get LandUse$(): SafeBehaviorSubject<ILanduse[]> {
    return super.Listen(s => s.data);
  }

  get LandUse(): ILanduse[] {
    return this.LandUse$.getValue();
  }
}

import {Injectable}                                    from '@angular/core';
import {Store}                                         from '../index';
import {UnitsLoad, UnitsLoadSuccess} from 'invoker-transport';
import {IStateStore}                                   from '../../ap-interface';
import IUnit = Data.Common.IUnit;
import {ApSignalrService}                              from '../../ap-core/services/ap-signalr.service';

interface IUnitsState extends IStateStore<IUnit> {
}

export interface IUnitsStore {
  loadUnits(): void;

  getUnit(unit: string): IUnit;
}

@Injectable({providedIn: 'root'})
export class UnitsStore extends Store<IUnitsState> implements IUnitsStore {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(UnitsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadUnits(): void {
    this.DispatchBackend(new UnitsLoad([]));
  }

  public getUnit(unit: string): IUnit {
    return super.Listen(s => s.data).getValue().find(x => x.Description === unit);
  }

  public getUnitByKey(key: string): IUnit {
    return super.Listen(s => s.data).getValue().find(x => x.Key === key);
  }

  public getUnitWithUnit(unitId: number): IUnit {
    return super.Listen(s => s.data).getValue().find(x => x.Id === unitId);
  }
}

import {Subject}           from 'rxjs';
import Select              from 'ol/interaction/Select';
import MapBrowserEventType from 'ol/MapBrowserEventType';
import MapBrowserEvent     from 'ol/MapBrowserEvent';

/**
 * represent the Type of MapBrwoserPointerEvent from Openlayers
 */
export interface MapBrowserPointerEvent {
  coordinate: [number, number];
  dragging: boolean;
  frameState: any;
  map: any;
  originalEvent: any;
  pixel: [number, number];
  pointerEvent: any;
  target: any;
  type: string;
}

/**
 * holds Streams for the Map Events of Openlayers
 */
export class ApMapControlStream {
  /**
   * move Mouse over the Map
   */
  static listenPointerMove$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  /**
   * click with left Mouse Button in Map (don't release it)
   */
  static listenPointerDown$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  /**
   * release the left Mouse Button in Map
   */
  static listenPointerUp$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  static listenPointerOver$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  static listenPointerOut$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  static listenPointerLeave$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  static listenPointerEnter$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  /**
   * move the Map with pressed left Mouse Button
   */
  static listenPointerDrag$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  static listenPointerCancel$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  /**
   * all other Events they not catches now
   */
  static listenDefault$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  /**
   * click into Map
   */
  static listenClick$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  static listenDoubleClick$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  static listenSingleClick$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  /**
   * Mouse Wheel was Scroll Up
   */
  static listenScrollUp$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();
  /**
   * Mouse Wheel was Scroll Down
   */
  static listenScrollDown$: Subject<MapBrowserEvent> = new Subject<MapBrowserEvent>();

  /**
   * register on an Openlayers Map as Interaction
   */
  static interaction = new Select({
    condition: ApMapControlStream._incoming
  });

  /**
   * switch between different types and put it into the right stream
   */
  private static _incoming(event: MapBrowserEvent): boolean {
    switch (event.type) {
      case
      MapBrowserEventType.POINTERDOWN:
        ApMapControlStream.listenPointerDown$.next(event);
        break;
      case
      MapBrowserEventType.POINTERUP:
        ApMapControlStream.listenPointerUp$.next(event);
        break;
      case
      MapBrowserEventType.POINTERMOVE:
        ApMapControlStream.listenPointerMove$.next(event);
        break;
      case
      MapBrowserEventType.POINTEROVER:
        ApMapControlStream.listenPointerOver$.next(event);
        break;
      case
      MapBrowserEventType.POINTEROUT:
        ApMapControlStream.listenPointerOut$.next(event);
        break;
      case
      MapBrowserEventType.POINTERLEAVE:
        ApMapControlStream.listenPointerLeave$.next(event);
        break;
      case
      MapBrowserEventType.POINTERENTER:
        ApMapControlStream.listenPointerEnter$.next(event);
        break;
      case
      MapBrowserEventType.POINTERDRAG:
        ApMapControlStream.listenPointerDrag$.next(event);
        break;
      case
      MapBrowserEventType.POINTERCANCEL:
        ApMapControlStream.listenPointerCancel$.next(event);
        break;
      case
      MapBrowserEventType.CLICK:
        ApMapControlStream.listenClick$.next(event);
        break;
      case
      MapBrowserEventType.DBLCLICK:
        ApMapControlStream.listenDoubleClick$.next(event);
        break;
      case
      MapBrowserEventType.SINGLECLICK:
        ApMapControlStream.listenSingleClick$.next(event);
        break;
      case
      'wheel':
        if ((event.originalEvent as WheelEvent).deltaY < 0) {
          ApMapControlStream.listenScrollUp$.next(event);
        } else {
          ApMapControlStream.listenScrollDown$.next(event);
        }
        break;
      default:
        ApMapControlStream.listenDefault$.next(event);
    }
    return true;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {LoaderThemeColor}    from '@progress/kendo-angular-indicators';
import {StatusClass}         from '../../types/common-types';

@Pipe({
  name: 'translateKendoState',
})
export class TranslateKendoStatePipe implements PipeTransform {
  transform(value: StatusClass): LoaderThemeColor {
    switch (value) {
      case 'ap-status-red':
        return 'error';
      case 'ap-status-yellow':
        return 'warning';
      default:
        return 'success';
    }
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum PasswordActions {
  SET_NEW_PASSWORD = '[user] setPassword',
  SET_NEW_PASSWORD_SUCCESS = '[user] setPassword success',
  SET_NEW_PASSWORD_FAIL = '[user] setPassword fail',
  REQUEST_NEW_PASSWORD = '[user] requestPassword',
  REQUEST_NEW_PASSWORD_SUCCESS = '[user] requestPassword success',
  REQUEST_NEW_PASSWORD_FAIL = '[user] requestPassword fail',
}

/**
 * write the new Password into Database.
 *
 * @example
 * new SetNewPassword([
 *  {
 *    Name: 'activationKey',
 *    Type: NetTypes.STRING,
 *    Value: activationKey,
 *  },
 *  {
 *    Name: 'passwordHash',
 *    Type: NetTypes.STRING,
 *    Value: btoa(password),
 *  },
 *  {
 *    Name: 'cultureInfo',
 *    Type: NetTypes.STRING,
 *    Value: this.languageStore.SelectedLanguage.Key,
 *  },
 * ]);
 */
export class SetNewPassword implements IAction {
  readonly type = PasswordActions.SET_NEW_PASSWORD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SetNewPasswordSuccess implements IAction {
  readonly type = PasswordActions.SET_NEW_PASSWORD_SUCCESS;

  constructor(public payload: string) {
  }
}

export class SetNewPasswordFail implements IAction {
  readonly type = PasswordActions.SET_NEW_PASSWORD_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * Start a new Password Request by E-Mail Address.
 *
 * The user receives an e-mail with an activation URL and an activation code, provided the e-mail address is available
 * and correct. This allows the user to reset their password. If the user does not respond, the request for a new
 * password becomes invalid after 24 hours.
 *
 * @example
 * new RequestNewPassword([
 *  {
 *    Name: 'emailAddress',
 *    Type: NetTypes.STRING,
 *    Value: emailAddress,
 *  },
 *  {
 *    Name: 'cultureInfo',
 *    Type: NetTypes.STRING,
 *    Value: this.languageStore.SelectedLanguage.Key,
 *  },
 * ]);
 */
export class RequestNewPassword implements IAction {
  readonly type = PasswordActions.REQUEST_NEW_PASSWORD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class RequestNewPasswordSuccess implements IAction {
  readonly type = PasswordActions.REQUEST_NEW_PASSWORD_SUCCESS;

  constructor(public payload: string) {
  }
}

export class RequestNewPasswordFail implements IAction {
  readonly type = PasswordActions.REQUEST_NEW_PASSWORD_FAIL;

  constructor(public payload: string) {
  }
}

import {Injectable}                                                    from '@angular/core';
import {Store}                                                         from '../index';
import {ExportTargetsLoad, ExportTargetsLoadSuccess} from 'invoker-transport';
import {IStateStore}                                                   from '../../ap-interface';
import IExportTarget = Data.Common.IExportTarget;
import {ApSignalrService}                                              from '../../ap-core/services/ap-signalr.service';

interface IExportTargetsStore extends IStateStore<IExportTarget> {
}

@Injectable({providedIn: 'root'})
export class ExportTargetsStore extends Store<IExportTargetsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(ExportTargetsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadExportTargets(): void {
    this.DispatchBackend(new ExportTargetsLoad([]));
  }
}

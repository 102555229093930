import {Component} from '@angular/core';

const CONTENT = `
    <svg viewBox="0 0 1692 1692">
    <g transform="matrix(1,0,0,-1,159.45763,1293.0169)">
      <path d="m 1408 296 q 0 -27 -10 -70.5 Q 1388 182 1377 157 Q 1356 107 1255 51 Q 1161 0 1069 0 Q 1042 0 1016.5 3.5 Q 991 7 959 16 Q 927 25 911.5 30.5 Q 896 36 856 51 Q 816 66 807 69 Q 709 104 632 152 Q 504 231 367.5 367.5 Q 231 504 152 632 Q 104 709 69 807 Q 66 816 51 856 Q 36 896 30.5 911.5 Q 25 927 16 959 Q 7 991 3.5 1016.5 Q 0 1042 0 1069 q 0 92 51 186 q 56 101 106 122 q 25 11 68.5 21 q 43.5 10 70.5 10 q 14 0 21 -3 q 18 -6 53 -76 q 11 -19 30 -54 q 19 -35 35 -63.5 q 16 -28.5 31 -53.5 q 3 -4 17.5 -25 q 14.5 -21 21.5 -35.5 q 7 -14.5 7 -28.5 q 0 -20 -28.5 -50 q -28.5 -30 -62 -55 q -33.5 -25 -62 -53 q -28.5 -28 -28.5 -46 q 0 -9 5 -22.5 q 5 -13.5 8.5 -20.5 q 3.5 -7 14 -24 q 10.5 -17 11.5 -19 q 76 -137 174 -235 q 98 -98 235 -174 q 2 -1 19 -11.5 q 17 -10.5 24 -14 q 7 -3.5 20.5 -8.5 q 13.5 -5 22.5 -5 q 18 0 46 28.5 q 28 28.5 53 62 q 25 33.5 55 62 q 30 28.5 50 28.5 q 14 0 28.5 -7 q 14.5 -7 35.5 -21.5 q 21 -14.5 25 -17.5 q 25 -15 53.5 -31 q 28.5 -16 63.5 -35 q 35 -19 54 -30 q 70 -35 76 -53 q 3 -7 3 -21 Z" class="hoverFill"/>
    </g>
    </svg>
`;

@Component({
  selector: 'ap-contact-icon',
  template: CONTENT
})
export class ApContactIconComponent {
}

import OlTileLayer   from 'ol/layer/Tile';
import {ApBaseLayer} from './ap-base.layer';
import TileSource    from 'ol/source/Tile';
import {MapStore}    from '../../stores/map/map.store';

export class ApBaseTileLayer<T extends TileSource> extends ApBaseLayer<OlTileLayer> {
  source: T;

  constructor(mapStore: MapStore, name: string, source: T) {
    super(name, new OlTileLayer({
      source,
      preload: Infinity
    }), mapStore);
    this.source = source;
    this.layer.setZIndex(0);
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import INdiStatistic = Data.NDI.INdiStatistic;

export enum NdiActions {
  LOAD_STATISTICS = '[field] ndi statistic load',
  LOAD_STATISTICS_SUCCESS = '[field] ndi statistic load success',
  LOAD_STATISTICS_FAIL = '[field] ndi statistic load fail',
}

export class NStatisticLoad implements IAction {
  readonly type = NdiActions.LOAD_STATISTICS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NStatisticLoadSuccess implements IAction {
  readonly type = NdiActions.LOAD_STATISTICS_SUCCESS;

  constructor(public payload: INdiStatistic[]) {
  }
}

import {IStateStore}         from '../../ap-interface/interfaces/store';
import {Store}               from '../index';
import {Injectable}          from '@angular/core';
import {
  ApCustomTypes,
  HibernateModelNames,
  VarietyLoad,
  VarietyLoadSuccess,
  VarietySelectableUpdate
}                            from 'invoker-transport';
import {SafeBehaviorSubject} from 'ts-tooling';
import IVariety = Data.BaseData.IVarieties;
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import ISelectableVariety = Data.BaseData.ISelectableVariety;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IVarietyStore extends IStateStore<IVariety> {
}

@Injectable({providedIn: 'root'})
export class VarietyStore extends Store<IVarietyStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(VarietyLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  get Loading(): boolean {
    return this.Loading$.getValue();
  }

  get Loaded$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  get Loaded(): boolean {
    return this.Loaded$.getValue();
  }

  /**
   *  load varieties from Backend to the Store
   */
  public loadVarieties(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new VarietyLoad([]));
  }

  /**
   * updates selectableVariety via backend
   */
  public updateSelectableVariety(variety: IVariety): void {
    this.DispatchBackend(new VarietySelectableUpdate([
      {Name: 'variety', Type: ApCustomTypes.Data_BaseData_Varieties, Value: variety},
    ]));
  }

  /**
   * override the Update with a custom Update Strategy
   */
  UpdateSource(operation: DatabaseNotifyOperation, item: any, model: string): void {
    if (operation === DatabaseNotifyOperation.Insert || operation === DatabaseNotifyOperation.Delete) {
      if (model === HibernateModelNames.SELECTABLE_VARIETIES) {
        super.Mutate(s => s.data, o => {
          const backendItem = item as ISelectableVariety;
          if (!backendItem) {
            // fallback case reload the Source
            this.loadVarieties();
            return o;
          }
          if (operation === DatabaseNotifyOperation.Delete) {
            this.loadVarieties();
            return o;
          }
          if (operation === DatabaseNotifyOperation.Insert) {
            if (!o) {
              super.SetLoadFinishState();
              return o;
            }
            const position = o.FindIndex(i => i.Id === backendItem.Varietyid);
            const oldItem = o[position];
            o[position] = {...oldItem, Selectable: true};
            super.SetLoadFinishState();
            return o;
          }
          return undefined;
        });
      }
    } else {
      this.loadVarieties();
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getCountryCodeFlag'
})
export class GetCountryCodeFlagPipe implements PipeTransform {

  transform(countryCode: string): string {
    switch (countryCode) {
      case 'de':
        return '../../assets/img/flags/de.png';
      case 'en':
        return '../../assets/img/flags/gb.png';
      case 'lt':
        return '../../assets/img/flags/lt.png';
      case 'pl':
        return '../../assets/img/flags/pl.png';
      case 'hu':
        return '../../assets/img/flags/hu.png';
      case 'uk':
        return '../../assets/img/flags/ua.png';
      case 'lv':
        return '../../assets/img/flags/lv.png';
      case 'sr':
        return '../../assets/img/flags/rs.png';
      case 'et':
        return '../../assets/img/flags/ee.png';
      default:
        return '../../assets/img/flags/de.png';
    }
  }
}

<button #anchor (click)="togglePopup()"
        [class]="'ap-grid-pager-button ap-grid-pager-numeric-button'">{{currentPage | async}}</button>
<kendo-popup #popup
             *ngIf="show"
             [class]="'ap-grid-pager-numeric-popup'"
             [anchor]="anchor"
             [animate]="animate"
             [anchorAlign]="anchorAlign"
             [popupAlign]="popupAlign"
             (anchorViewportLeave)="show = false">
  <div [style.display]="'flex'"
       (wheel)="onWheel($event)">
    <button *ngIf="(chunks | async) && (chunks | async).length > 1"
            [disabled]="(chunkPage | async) === 0"
            [class]="'ap-grid-pager-button ap-grid-pager-ctrl-button'"
            [class.ap-grid-pager-button-disabled]="(chunkPage | async) === 0"
            (click)="changeChunkPage(-1)">
      &lang;
    </button>
    <fieldset>
      <div [class]="'ap-grid-pager-numeric-popup-grid'">
        <button
          *ngFor="let page of (pageView | async)"
          [class]="'ap-grid-pager-button ap-grid-pager-numeric-button'"
          [class.ap-grid-pager-numeric-current-button]="(currentPage | async) === page"
          [style.grid-column]="getColumn | execFunction:page:columns"
          [style.grid-row]="getRow | execFunction:page:rows"
          (click)="onPageChange(page)">
          {{page}}
        </button>
      </div>
    </fieldset>
    <button *ngIf="(chunks | async) && (chunks | async).length > 1"
            [disabled]="(chunkPage | async) === (chunks | async).length - 1"
            [class]="'ap-grid-pager-button ap-grid-pager-ctrl-button'"
            [class.ap-grid-pager-button-disabled]="(chunkPage | async) === (chunks | async).length - 1"
            (click)="changeChunkPage(1)">
      &rang;
    </button>
  </div>
</kendo-popup>

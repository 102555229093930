import {Pipe, PipeTransform}         from '@angular/core';
import {SimpleValue, SimpleValueSet} from '../charts/nutrients.stats.component';

@Pipe({
  name: 'joinPropertyValue',
})
export class JoinPropertyValuePipePipe implements PipeTransform {
  transform(value: SimpleValueSet, ...args: [(v: SimpleValue) => string]): string {
    if (!value || !args[0]) {
      return '';
    }
    return value.Convert(e => args[0](e)).Join(', ');
  }
}

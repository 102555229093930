import {IStateStore} from '../../ap-interface';
import {Injectable}  from '@angular/core';
import {Store}       from '../index';
import IWorktypeUnit = Data.BaseData.IWorktypeUnit;
import {WorktypeUnitLoad, WorktypeUnitLoadSuccess} from 'invoker-transport';
import {UnitsStore} from '../common/units.store';
import IUnit = Data.Common.IUnit;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

@Injectable({providedIn: 'root'})
export class WorktypeUnitStore extends Store<IStateStore<IWorktypeUnit>> {
  constructor(public backend: ApSignalrService,
              private unitStore: UnitsStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(WorktypeUnitLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  public loadWorktypeUnits(): void {
    this.DispatchBackend(new WorktypeUnitLoad([]));
  }

  public getWorktypeUnits(): IWorktypeUnit[] {
    return super.Listen(s => s.data).getValue();
  }

  public getUnitsByWorktype(worktypeId: number): IUnit[] {
    return super.Listen(s => s.data).getValue().FindAll(wu => wu?.WorktypeId === worktypeId).map(u => {
      return this.unitStore.getUnitWithUnit(u?.UnitId);
    });
  }
}

import {NgModule}        from '@angular/core';
import {ApMetricService} from './ap-metric.service';

@NgModule({
  imports: [],
  providers: [
    ApMetricService
  ],
})
export class ApMetricsModule {
}

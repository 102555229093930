import {IAction, IApInvokerArguments} from '../../interfaces';
import ISoilSampleField = Data.Nutrients.ISoilSampleField;

export enum ApKrigingActions {
  SOIL_SAMPLE_FIELD_INTERPOLATE = '[soilsamplefield] interpolate',
  SOIL_SAMPLE_FIELD_INTERPOLATE_SUCCESS = '[soilsamplefield] interpolate success',
  SOIL_SAMPLE_FIELD_INTERPOLATE_FAIL = '[soilsamplefield] interpolate fail',
  SOIL_SAMPLE_FIELD_STATE_LOAD = '[soilsamplefield] state load',
  SOIL_SAMPLE_FIELD_STATE_LOAD_SUCCESS = '[soilsamplefield] state load success',
  SOIL_SAMPLE_FIELD_STATE_LOAD_FAIL = '[soilsamplefield] state load fail',
  SOIL_SAMPLE_FIELD_DISTRIBUTION_COUNT_LOAD = '[soilsamplefield] dist count load',
  SOIL_SAMPLE_FIELD_DISTRIBUTION_COUNT_LOAD_SUCCESS = '[soilsamplefield] dist count load success',
  SOIL_SAMPLE_FIELD_DISTRIBUTION_COUNT_LOAD_FAIL = '[soilsamplefield] dist count load fail',
  RASTER_CREATE_ALL = '[raster] create all',
  RASTER_CREATE_ALL_SUCCESS = '[raster] create all success',
}

export class SoilSampleFieldInterpolate implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_INTERPOLATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSampleFieldInterpolateSuccess implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_INTERPOLATE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class SoilSampleFieldStateLoad implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_STATE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSampleFieldStateLoadSuccess implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_STATE_LOAD_SUCCESS;

  constructor(public payload: ISoilSampleField[]) {
  }
}

export class SoilSampleFieldStateLoadFail implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_STATE_LOAD_FAIL;
}

export class SoilSampleFieldDistributionCountLoad implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_DISTRIBUTION_COUNT_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSampleFieldDistributionCountLoadSuccess implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_DISTRIBUTION_COUNT_LOAD_SUCCESS;

  constructor(public payload: number) {
  }
}

export class SoilSampleFieldDistributionCountLoadFail implements IAction {
  readonly type = ApKrigingActions.SOIL_SAMPLE_FIELD_DISTRIBUTION_COUNT_LOAD_FAIL;
}

export class CreateAllRasters implements IAction {
  readonly type = ApKrigingActions.RASTER_CREATE_ALL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class CreateAllRastersSuccess implements IAction {
  readonly type = ApKrigingActions.RASTER_CREATE_ALL_SUCCESS;
}

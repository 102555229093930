import {Component} from '@angular/core';

const CONTENT = `
    <svg viewBox="0 0 488.634 488.634">
      <path d="M457.067,212.754H174.899c-0.379-47.906-39.436-86.772-87.435-86.772C39.235,125.982,0,165.219,0,213.447v61.732  c0,48.229,39.235,87.473,87.464,87.473c47.99,0,87.048-38.865,87.435-86.764h158.536v26.966c0,17.433,14.134,31.567,31.567,31.567  c9.48,0,17.887-4.27,23.675-10.889c5.788,6.619,14.196,10.889,23.675,10.889c17.433,0,31.567-14.135,31.567-31.567v-26.966h13.148  c17.433,0,31.567-14.134,31.567-31.567C488.634,226.888,474.5,212.754,457.067,212.754z M111.803,275.179  c0,13.418-10.921,24.339-24.338,24.339s-24.33-10.921-24.33-24.339v-61.732c0-13.417,10.913-24.33,24.33-24.33  s24.338,10.913,24.338,24.33V275.179z" class="hoverFill"/>
    </svg>
`;

@Component({
  selector: 'ap-legal-icon',
  template: CONTENT
})
export class ApLegalIconComponent {
}

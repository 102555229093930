import {NgModule}                                 from '@angular/core';
import {CommonModule}                             from '@angular/common';
import {DropDownsModule}                          from '@progress/kendo-angular-dropdowns';
import {ApTranslationModule}                      from '../../translation/ap-translation.module';
import {TrackPropertyComponent}                   from './track-property.component';
import {NFertilizationPropertyComponent}          from './n-fertilization-property.component';
import {ApResponsiveTextModule}                   from '../../ap-responsive-text/ap-responsive-text.module';
import {FieldDistributionLegendSelectorComponent} from './field-distribution-legend-selector.component';
import {ApDynformsModule}                         from '../../ap-dynforms/ap-dynforms.module';

const COMPONENTS = [TrackPropertyComponent, NFertilizationPropertyComponent, FieldDistributionLegendSelectorComponent];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    DropDownsModule,
    ApTranslationModule,
    ApResponsiveTextModule,
    ApDynformsModule
  ]
})
export class ColorLegendEntryComponentsModule {
  static COMPONENTS = COMPONENTS;
}

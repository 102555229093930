<kendo-multiselect
  [data]="dataFilter"
  [filterable]="true"
  [valuePrimitive]="false"
  [textField]="'key'"
  [valueField]="'value'"
  [value]="values"
  (valueChange)="onChange($event)"
  (filterChange)="onFilterChange($event)">
</kendo-multiselect>

import {Pipe, PipeTransform}       from '@angular/core';
import {ApDynGridColumnConfigBase} from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridEditColumnConfig} from '../../config/ap-dyn-grid-edit-column-config';
import {Trace}                     from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asEdit'
})
export class AsEditPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridEditColumnConfig {
    return config as ApDynGridEditColumnConfig;
  }

}

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef, HostListener,
  NgZone,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {combineLatest, Observable, sample, Subscription} from 'rxjs';
import {concat}                                          from 'lodash';
import {ApMapInstance}                                   from '../../ap-map';
import {ApMachinePopupComponent}                         from './ap-machine-popup.component';
import {ApSensorPointPopupComponent}                     from './ap-sensor-point-popup/ap-sensor-point-popup.component';
import {SensorPointLegendComponent}                      from './sensor-point-legend/sensor-point-legend.component';
import {ApMapColorLegendComponent}                       from './ap-map-color-legend.component';
import {DrawerItem}                                      from '@progress/kendo-angular-layout';
import {FieldStore}                                      from '../../stores/farm/field.store';
import {ActionStore}                                     from '../../stores/docu/action.store';
import {RouterStore}                                     from '../../stores/router/router.store';
import {MapStore}                                        from '../../stores/map/map.store';
import {ApMapContextMenuComponent}                       from './ap-map-context-menu.component';
import {MapViewStore}                                    from '../../stores/layout/mapview.store';
import {delay, distinctUntilChanged}                     from 'rxjs/operators';
import {MapViewMode}                                     from '../../ap-interface';
import {ApMapTooltipComponent}                           from './ap-map-tooltip.component';

/**
 * holds the Openlayers Map Instance
 */
@Component({
  selector: 'ap-map',
  templateUrl: 'ap-map.component.html',
  styleUrls: ['ap-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApMapComponent implements OnDestroy, AfterContentInit {
  @ViewChild('apMap', {static: true})
  map: ElementRef;
  @ViewChild(ApMachinePopupComponent, {static: true})
  machinePopup: ApMachinePopupComponent;
  @ViewChild(ApSensorPointPopupComponent, {static: true})
  sensorPointPopup: ApSensorPointPopupComponent;
  @ViewChild(SensorPointLegendComponent, {static: true})
  sensorPointLegend: SensorPointLegendComponent;
  @ViewChild(ApMapColorLegendComponent, {static: true})
  colorLegend: ApMapColorLegendComponent;
  @ViewChild(ApMapContextMenuComponent, {static: true})
  contextmenu: ApMapContextMenuComponent;
  @ViewChild(ApMapTooltipComponent, {static: true})
  tooltip: ApMapTooltipComponent;
  deleteButtonVisible$: Observable<boolean>;
  public expanded = false;
  public items: DrawerItem[] = [
    {text: 'Inbox', icon: 'k-i-inbox'},
    {text: 'Notifications', icon: 'k-i-bell'},
    {text: 'Favourites', icon: 'k-i-star-outline'},
    {text: 'Date', icon: 'k-i-calendar'}
  ];
  private _subscriptions: Subscription[] = [];

  constructor(private mapStore: MapStore,
              private mapViewStore: MapViewStore,
              private fieldStore: FieldStore,
              private actionStore: ActionStore,
              private routerStore: RouterStore,
              private ngZone: NgZone) {
  }
  /**
   * Triggers hiding the tooltip.
   */
  @HostListener('mouseleave')
  onMouseLeave(): void {
    ApMapInstance?.hideTooltip();
  }

  public switchExpanded(): void {
    this.expanded = !this.expanded;
  }

  ngAfterContentInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.deleteButtonVisible$ = this.mapStore.Editor.DeleteButtonVisible$;
      ApMapInstance.register(this.map.nativeElement);
      this._subscriptions = concat(this._subscriptions, [
        combineLatest([
          this.fieldStore.SelectedFields$,
          this.mapViewStore.getMapViewMode$().pipe(
            distinctUntilChanged((previous, current) =>
              previous !== MapViewMode.MAXIMIZE || current === MapViewMode.MAXIMIZE),
            sample(this.mapStore.mapInitialized.pipe(delay(500)))
          )
        ]).subscribe(([field]) => ApMapInstance.selectField(field)),
        this.actionStore.Listen(s => s.selectedLocations)
          .subscribe((location) => ApMapInstance.selectLocation(location)),
      ]);

      ApMapInstance.registerMachinePopup(this.machinePopup);
      ApMapInstance.registerSensorPointPopup(this.sensorPointPopup);
      ApMapInstance.registerSensorLegend(this.sensorPointLegend);
      ApMapInstance.registerContextMenu(this.contextmenu);
      ApMapInstance.registerTooltip(this.tooltip);
      setTimeout(() => ApMapInstance.registerMapHTMLElement(this.map.nativeElement));
    });
  }

  ngOnDestroy(): void {
    ApMapInstance.clear();
    for (const s of this._subscriptions) {
      s.unsubscribe();
    }
  }

  clearSensorPoints(): void {
    this.mapStore.Layers.FungiDetectLayer.clear();
    this.mapStore.Editor.HideDeleteButton();
    if (ApMapInstance.sensorPointPopup) {
      ApMapInstance.sensorPointPopup.hide();
      ApMapInstance.sensorPointPopup.clear();
    }
  }
}

<div #aphelp class="ap-help-base">
  <button *ngIf="hasEditPermission && !isEditMode" kendoButton [title]="'Global__Edit' | translate" icon="k-icon k-i-pencil" class="k-icon k-i-pencil" (click)="edit()" class="ap-help-edit-button"></button>
  <kendo-editor [iframe]="true" [readonly]="!isEditMode" [schema]="apEditorSchema" class="ap-help-editor" [plugins]="apHelpEditorPlugins">
      <kendo-toolbar *ngIf="!hasEditPermission" style="display:none">
        <!-- For regular users: without any toolbar the editor would still show a default toolbar => but we do not want to see anything
        We cannot use our custom toolbar and hiding it => could be made visible through developer console.
        -->
      </kendo-toolbar>
      <kendo-toolbar [style.display]="!isEditMode?'none':''">
        <ng-container *ngIf="hasEditPermission">
          <kendo-toolbar-dropdownbutton textField="languageName" [title]="'Lbl_LanguageSelector' | translate" icon="k-icon k-i-globe" showIcon="both" [data]="languageData" showText="true" [text]="selectedLanguage?.LanguageName"></kendo-toolbar-dropdownbutton>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
            <kendo-toolbar-colorpicker kendoEditorBackColor></kendo-toolbar-colorpicker>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button (click)="openDialogImage()" [title]="'kendo.dialog.uploadInsertImage' | translate" icon="k-icon k-i-image-light-dialog k-i-image-insert"></kendo-toolbar-button>
            <kendo-toolbar-button (click)="openDialogYoutube()" [title]="'kendo.dialog.insertYoutube' | translate" icon="k-icon k-i-film"></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
          <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
          <kendo-toolbar-buttongroup>
            <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
            <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
          </kendo-toolbar-buttongroup>
          <kendo-toolbar-button (click)="openDialogSave()" [title]="'Editor__ToolTipSave' | translate" icon="k-i-save k-i-floppy"></kendo-toolbar-button>
        </ng-container>
      </kendo-toolbar>
  </kendo-editor>
</div>

/**
 * represent the supported element oxyd types
 */
export enum ApElementOxydTypeEnum {
  P2O5 = 'P2O5',
  K2O = 'K2O',
  MgO = 'MgO',
  P = 'P',
  K = 'K',
  Mg = 'Mg'
}

import {ReactiveFormsModule}          from '@angular/forms';
import {NgModule}                     from '@angular/core';
import {ApDynformsComponent}          from './ap-dynforms.component';
import {ApDynformsElementComponent}   from './ap-dynforms-element.component';
import {ApTranslationModule}          from '../translation/ap-translation.module';
import {DropDownsModule}              from '@progress/kendo-angular-dropdowns';
import {DateInputsModule}             from '@progress/kendo-angular-dateinputs';
import {InputsModule}                 from '@progress/kendo-angular-inputs';
import {CommonModule}                 from '@angular/common';
import {ApUtilsModule}                from '../ap-utils';
import {ApSectionHeaderModule}        from '../ap-section-header/ap-section-header.module';
import {ApResponsiveTextModule}       from '../ap-responsive-text/ap-responsive-text.module';
import {ApDynformsArrayComponent}     from './ap-dynforms-array.component';
import {LayoutModule}                 from '@progress/kendo-angular-layout';
import {UploadModule}                 from '@progress/kendo-angular-upload';
import {HttpClientModule}             from '@angular/common/http';
import {SanitizeIdPipe}               from './sanitize-id.pipe';
import {ApDynformsDateRangeComponent} from './ap-dynforms-date-range.component';
import {PasswordStrengthComponent}    from './components/password-strength.component';
import {ButtonsModule}                from '@progress/kendo-angular-buttons';


@NgModule({
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    ApTranslationModule,
    DropDownsModule,
    DateInputsModule,
    InputsModule,
    ApSectionHeaderModule,
    CommonModule,
    ApUtilsModule,
    ApResponsiveTextModule,
    LayoutModule,
    UploadModule,
    ButtonsModule,
  ],
  declarations: [
    ApDynformsComponent,
    ApDynformsElementComponent,
    ApDynformsArrayComponent,
    SanitizeIdPipe,
    ApDynformsDateRangeComponent,
    PasswordStrengthComponent,
  ],
  exports: [
    ApDynformsComponent,
    ApDynformsElementComponent,
    PasswordStrengthComponent,
  ],
  providers: []
})
export class ApDynformsModule {
}

import {EventEmitter, Injectable}     from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {delay}                        from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApUtilService {
  static asObservable<T>(object: T | Observable<T>, defaultValue?: T): Observable<T> {
    if (object && object['subscribe'] && typeof object['subscribe'] === typeof function (): void {
    }) {
      return (object as Observable<T>).pipe(delay(0));
    } else if (typeof object === typeof undefined) {
      return of(defaultValue);
    } else {
      return of(object as T);
    }
  }

  static createEventEmitter<T>(generatorOrNext: (value: T) => void, subscriptions: Subscription[]): EventEmitter<T> {
    const emitter = new EventEmitter<T>();
    subscriptions.push(emitter.subscribe(generatorOrNext));
    return emitter;
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators}      from '@angular/forms';
import {ApDynformsConfigFieldset}     from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsConfigTextbox}      from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {merge}                        from 'lodash';
import {ApDynformsComponent}          from '../../ap-dynforms/ap-dynforms.component';
import {ApDynformsValidator}          from '../../ap-dynforms/ap-dynforms-validator';
import {DriverStore}                  from '../../stores/docu/driver.store';
import {FormStore}                    from '../../stores/layout/form.store';
import {LoginStore}                   from '../../stores/login/login.store';
import {Observable, Subscription}     from 'rxjs';
import IDriver = Data.DocuContext.Driver.IDriver;

/**
 * Drivers edit component.
 */
@Component({
  selector: 'ap-drivers-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [loading$]="saving$">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_drivers_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_drivers_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`,
})

/**
 * Drivers edit component.
 */
export class ApDriversEditComponent implements OnInit {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: any;
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public caption: string;
  public saving$: Observable<boolean>;
  private _subscriptions: Array<Subscription> = [];

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private driversStore: DriverStore,
              private loginStore: LoginStore) {
    this.saving$ = this.driversStore.Listen(s => s.saving);
    this._subscriptions.Add(this.driversStore.Listen(s => s.saved).subscribe(saved => {
        if (saved) {
          this.formStore.closeForm();
        }
      }
    ));
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        FarmId: this.loginStore.SelectedFarmId
      };
    } else {
      this.isNewMode = false;
    }
    this.caption = this.isNewMode ? 'Settings__Lbl_Worker_Create' : 'Settings__Lbl_Worker_Edit';
    this.formBuilder();
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'driver',
        config: [
          new ApDynformsConfigTextbox({
            key: 'Firstname',
            label: 'Docu_Ini__Forename',
            value: this.data.Firstname,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Name',
            label: 'Docu_Ini__Surname',
            value: this.data.Name,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),
        ]
      })];
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);

    if (this.isNewMode) {
      this.driversStore.createDriver(this.data as IDriver);
    } else {
      this.driversStore.updateDriver(this.data as IDriver);
    }
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

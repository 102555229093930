import {Pipe, PipeTransform}               from '@angular/core';
import {ApDynGridColumnConfigBase}         from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridStatusSwitchColumnConfig} from '../../config/ap-dyn-grid-status-switch-column.config';
import {Trace}                             from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asStatusSwitch'
})
export class AsStatusSwitchPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridStatusSwitchColumnConfig {
    return config as ApDynGridStatusSwitchColumnConfig;
  }

}

import {IAction, IApInvokerArguments} from '../../interfaces';
import ISubFarms = Data.BaseData.ISubFarms;

export enum SubfarmActions {
  SUB_FARM_LOAD = '[subFarm] load',
  SUB_FARM_LOAD_SUCCESS = '[subFarm] load success',
  SUB_FARM_LOAD_FAIL = '[subFarm] load fail',
  SUB_FARM_UPDATE = '[subFarm] update',
  SUB_FARM_CREATE = '[subFarm] create',
  SUB_FARM_DELETE = '[subFarm] delete',
  SUB_FARM_SAVE_SUCCESS = '[subFarm] save success',
  SUB_FARM_SAVE_FAIL = '[subFarm] save fail',
}

export class SubFarmsLoad implements IAction {
  readonly type = SubfarmActions.SUB_FARM_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SubFarmsLoadSuccess implements IAction {
  readonly type = SubfarmActions.SUB_FARM_LOAD_SUCCESS;

  constructor(public payload: ISubFarms[]) {
  }
}


export class SubFarmUpdate implements IAction {
  readonly type = SubfarmActions.SUB_FARM_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SubFarmCreate implements IAction {
  readonly type = SubfarmActions.SUB_FARM_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SubFarmDelete implements IAction {
  readonly type = SubfarmActions.SUB_FARM_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SubFarmSaveSuccess implements IAction {
  readonly type = SubfarmActions.SUB_FARM_SAVE_SUCCESS;

  constructor(public payload: ISubFarms) {
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IPlantProtectionProduct = Data.BaseData.IPlantProtectionProduct;

export enum PlantProtectionProductsActions {
  PLANT_PROTECTION_PRODUCTS_LOAD = '[plant_protection] products load',
  PLANT_PROTECTION_PRODUCTS_LOAD_SUCCESS = '[plant_protection] products load success',
  PLANT_PROTECTION_PRODUCTS_LOAD_FAIL = '[plant_protection] products load fail',
  PLANT_PROTECTION_PRODUCTS_SELECTABLE_UPDATE = '[plant_protection] products selectable update',
  PLANT_PROTECTION_PRODUCTS_SELECTABLE_UPDATE_SUCCESS = '[plant_protection] products selectable update success',
  PLANT_PROTECTION_PRODUCTS_SELECTABLE_UPDATE_FAIL = '[plant_protection] products selectable update fail',
  PLANT_PROTECTION_PRODUCTS_CREATE_NEW = '[plant_protection] products create new',
  PLANT_PROTECTION_PRODUCTS_CREATE_NEW_SUCCESS = '[plant_protection] products create new success',
  PLANT_PROTECTION_PRODUCTS_CREATE_NEW_FAIL = '[plant_protection] products create new fail'
}

/**
 * start load plant protection products
 */
export class PlantProtectionProductsLoad implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Plant Protections products load successful
 */
export class PlantProtectionProductsLoadSuccess implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_LOAD_SUCCESS;

  constructor(public payload: IPlantProtectionProduct[]) {
  }
}

/**
 * Plant Protection products load failed
 */
export class PlantProtectionProductsLoadFail implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * Update product's selectable property
 */
export class PlantProtectionProductsSelectableUpdate implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_SELECTABLE_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Plant Protection products update success
 */
export class PlantProtectionProductsSelectableUpdateSuccess implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_SELECTABLE_UPDATE_SUCCESS;

  constructor(public payload: string) {
  }
}

/**
 * Plant Protection products update success
 */
export class PlantProtectionProductsSelectableUpdateFail implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_SELECTABLE_UPDATE_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * Plant Protection products create new product
 */
export class PlantProtectionProductsCreateNew implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_CREATE_NEW;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Plant Protection products create new product success
 */
export class PlantProtectionProductsCreateNewSuccess implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_CREATE_NEW_SUCCESS;

  constructor(public payload: string) {
  }
}

/**
 * Plant Protection products create new product fail
 */
export class PlantProtectionProductsCreateNewFail implements IAction {
  readonly type = PlantProtectionProductsActions.PLANT_PROTECTION_PRODUCTS_CREATE_NEW_FAIL;

  constructor(public payload: string) {
  }
}

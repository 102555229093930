import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SoilGroupFormatService {
  private _values = [1, 2, 3, 4, 5, 6];
  private _labels = ['1', '2', '3', '4', '5', '6'];
  private _colors = ['#ffffb3', '#f2cc33', '#a6734d', '#267300', '#0070ff', '#b34d80'];
  public mapSingle(soilGroup: number): string {
    return this.mapSoilGroups(soilGroup);
  }

  public asString(soilGroups: number[]): string {
    return soilGroups.Convert(e => this.mapSoilGroups(e)).Join(',');
  }

  public colorCode(value: number | string): string {
    if (typeof value === typeof '') {
      return this.getValue(this._labels, value, this._colors, '#000000');
    }
    return this.getValue(this._values, value, this._colors, '#000000');
  }

  private getValue(search: any[], value: any, target: string[], defaultValue?: string): string {
    for (const key in search) {
      if (search[key] === value) {
        return target[key];
      }
    }
    return defaultValue || '';
  }

  private mapSoilGroups(value: number): string {
    return this.getValue(this._values, value, this._labels);
  }
}

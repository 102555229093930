import {Component, OnInit, ViewChild}         from '@angular/core';
import {FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {ApDynformsConfigFieldset}             from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsConfigComboBox}             from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {ApDynformsConfigDatepicker}           from '../../ap-dynforms/config/ap-dynforms-config-datepicker';
import {ApDynformsComponent}                  from '../../ap-dynforms/ap-dynforms.component';
import {merge}                                from 'lodash';
import {ApDynformsValidator}                  from '../../ap-dynforms/ap-dynforms-validator';
import {MachineStore}                         from '../../stores/docu/machine.store';
import {LoggerStore}                          from '../../stores/docu/logger.store';
import {MachineLoggerStore}                   from '../../stores/docu/machine.logger.store';
import {FormStore}                            from '../../stores/layout/form.store';
import {LoginStore}                           from '../../stores/login/login.store';
import {ApDateRangeValidator}                 from '../../ap-dynforms/validators/ap-date-range-validator';
import IMachinesLogger = Data.DocuContext.MachinesLogger.IMachinesLogger;
import ILogger = Data.DocuContext.Logger.ILogger;

/**
 * Component for editing or creating a machine-logger mapping.
 */
@Component({
  selector: 'ap-machines-logger-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [formValidators]="formValidators" [headerIcon]="'ap-icon-settings'">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_settings_machines_logger_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_settings_machines_logger_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`
})

/**
 * Component for editing or creating a machine-logger mapping.
 */
export class ApMachinesLoggerEditComponent implements OnInit {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: IMachinesLogger;
  public formConfig: ApDynformsConfigFieldset[];
  public formValidators: ApDynformsValidator<ValidatorFn>[];
  public isNewMode = false;
  public logger: ILogger[];
  public dateFrom: Date;
  public dateTo: Date;
  public caption: string;

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private machinesLoggerStore: MachineLoggerStore,
              private loggerStore: LoggerStore,
              private machineStore: MachineStore,
              private loginStore: LoginStore) {
    this.logger = this.loggerStore.getLogger();
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        Id: undefined,
        IsDeleted: false,
        Logger: null,
        Machine: null,
        FarmId: this.loginStore.SelectedFarmId,
        DateFrom: new Date(),
        DateTo: new Date(),
        CreatedAt: new Date(),
        CreatedBy: null,
        ChangedBy: null,
        ChangedAt: null,
        DeletedBy: null,
        DeletedAt: null,
        Version: 0,
      };
    } else {
      this.isNewMode = false;
    }

    this.dateFrom = new Date(this.data.DateFrom);
    this.dateTo = new Date(this.data.DateTo);
    this.caption = this.isNewMode ? 'Settings__Lbl_Loggerassignment_Create' : 'Settings__Lbl_Loggerassignment_Edit';
    this.formBuilder();
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.formValidators = [
      new ApDynformsValidator({
        validator: ApDateRangeValidator.ValidateDateRange('DateFrom', 'DateTo'),
        errorKey: 'AgriConnect_Error_MinMax'
      })
    ];
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'MachineLogger',
        config: [
          new ApDynformsConfigComboBox({
            key: 'Logger',
            value: this.data.Logger,
            valueField: 'Id',
            textField: 'Imei',
            valuePrimitive: false,
            label: 'Docu_Ini__Datalogger',
            options: this.logger,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
                always: true
              }),
            ]
          }),

          new ApDynformsConfigComboBox({
            key: 'Machine',
            value: this.data.Machine,
            valueField: 'Id',
            textField: 'Name',
            valuePrimitive: false,
            label: 'Docu_Ini__Engine',
            options: this.machineStore.Listen(s => s.data).getValue(),
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
                always: true
              }),
            ]
          }),

          new ApDynformsConfigDatepicker({
            key: 'DateFrom',
            label: 'Docu_Ini__From',
            value: new Date(this.data.DateFrom),
            formErrors: ['AgriConnect_Error_MinMax']
          }),

          new ApDynformsConfigDatepicker({
            key: 'DateTo',
            label: 'Docu_Ini__To',
            value: this.dateTo,
            formErrors: ['AgriConnect_Error_MinMax']
          })
        ]
      })];
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);

    if (this.isNewMode) {
      this.machinesLoggerStore.createMachinesLogger(this.data as IMachinesLogger);
    } else {
      this.machinesLoggerStore.updateMachinesLogger(this.data as IMachinesLogger);
    }
    this.formStore.closeForm();
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

import INotifications = Data.Common.INotifications;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum NotificationActions {
  NOTIFICATION_LOAD = '[notification] load',
  NOTIFICATION_LOAD_SUCCESS = '[notification] load success',
  NOTIFICATION_ADD = '[notification] add',
  NOTIFICATION_DELETE = '[notification] delete',
  NOTIFICATION_UPDATE = '[notification] update',
  NOTIFICATION_INSERT = '[notification] insert',
  NOTIFICATION_DELETE_ALL = '[notification] delete all',
}

export class NotificationLoad implements IAction {
  readonly type = NotificationActions.NOTIFICATION_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NotificationLoadSuccess implements IAction {
  readonly type = NotificationActions.NOTIFICATION_LOAD_SUCCESS;

  constructor(public payload: INotifications[]) {
  }
}

export class NotificationAdd implements IAction {
  readonly type = NotificationActions.NOTIFICATION_ADD;

  constructor(public payload: INotifications) {
  }
}

export class NotificationDelete implements IAction {
  readonly type = NotificationActions.NOTIFICATION_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NotificationUpdate implements IAction {
  readonly type = NotificationActions.NOTIFICATION_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NotificationInsert implements IAction {
  readonly type = NotificationActions.NOTIFICATION_INSERT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NotificationDeleteAll implements IAction {
  readonly type = NotificationActions.NOTIFICATION_DELETE_ALL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {Pipe, PipeTransform}    from '@angular/core';
import {SoilGroupFormatService} from '../service/soil-group-format.service';

@Pipe({
  name: 'soilGroupsFormat',
})
export class SoilGroupFormatPipe implements PipeTransform {
  constructor(private soilGroupFormatService: SoilGroupFormatService) {
  }

  transform(value: number[]): string {
    return this.soilGroupFormatService.asString(value);
  }
}

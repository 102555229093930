import {IAction, IApInvokerArguments} from '../../interfaces';
import IElement = Data.Common.IElement;

export enum ElementsActions {
  ELEMENTS_LOAD = '[elements] load',
  ELEMENTS_LOAD_SUCCESS = '[elements] load success',
}

export class ElementsLoad implements IAction {
  readonly type = ElementsActions.ELEMENTS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ElementsLoadSuccess implements IAction {
  readonly type = ElementsActions.ELEMENTS_LOAD_SUCCESS;

  constructor(public payload: IElement[]) {
  }
}

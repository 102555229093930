import {AfterViewInit, Component, Input}             from '@angular/core';
import {CompositeFilterDescriptor, FilterDescriptor} from '@progress/kendo-data-query';
import {FilterService}                               from '@progress/kendo-angular-grid';
import {IPipeDefinition}                             from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';

@Component({
  selector: 'ap-object-filter',
  templateUrl: './ap-object-filter.component.html'
})
export class ApObjectFilterComponent implements AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public key;
  @Input() public items = [];
  @Input() public pipes: IPipeDefinition[];

  public data: any[];
  public dataFilter: any[];
  public values: any[];

  constructor() {
  }

  ngAfterViewInit(): void {
    if (!this.items || this.items?.length === 0) {
      return;
    }
    const values = this.items.map((value) => {
      let key = value[this.key];
      this.pipes.forEach((def) => {
        key = def.pipe.transform(key, ...def.args);
      });
      return {key, value: value['Id']};
    });
    const map = {};
    values.forEach((value) => {
      if (Array.isArray(map[value.key])) {
        map[value.key].push(value.value);
      } else {
        map[value.key] = [value.value];
      }
    });
    this.data = Object.keys(map).map((key) => ({key, value: map[key]}));
    this.dataFilter = this.data;
    this.values = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
  }

  public onChange(values: { key: string, value: string[] }[]): void {
    // TODO: Das funktioniert nicht korrekt, beim zurücksetzen des Filters
    // Kendo setzt den Filter für die gewählte Spalte zurück
    // Wird ein Filter fest auf 'Id' gesetzt, weiß Kendo nicht, wann dieser zurückgesetzt
    // werden soll.
    this.filterService.filter({
      filters: values
        .map((value) => {
          return ([
            {
              field: this.key,
              operator: 'eq', // (item, value) => bool
              value
            } as unknown as CompositeFilterDescriptor,
            ...value.value.map((v) => (
              {
                field: 'Id',
                operator: 'eq',
                value: v
              } as unknown as CompositeFilterDescriptor)),
          ]);
        })
        .flat(),
      logic: 'or'
    });
  }

  public onFilterChange(filter: string): void {
    if (!this.data) {
      return;
    }
    this.dataFilter = this.data.FindAll((d: any) => {
      if (!d) {
        return (!d) === (!filter);
      }
      let value = d;
      if (typeof value !== typeof '') {
        value = d[this.key];
      }
      return value.ToLowerCase().IndexOf(filter.ToLowerCase()) !== -1;
    });
  }
}

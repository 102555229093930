<div [class]="'color-legend-container'">
  <div *ngFor="let colorLegend of (colorLegends$ | async)?.reverse()">
    <button
      #anchor
      kendoButton
      [id]="'ColorLegendAnchor' + (escapeTitle | execFunction:colorLegend.title)"
      [class]="'color-legend-anchor'"
      (click)="open(colorLegend.title)"
    >
      <ap-responsive-text [key]="colorLegend.title ? colorLegend.title : 'Farblegende'"></ap-responsive-text>
      <button kendoButton [look]="'flat'" [icon]="'close'" (click)="close(colorLegend.layerName)"></button>
    </button>
    <kendo-popup *ngIf="show.get(colorLegend.title)"
                 [id]="'ColorLegendPopup' + (escapeTitle | execFunction:colorLegend.title)"
                 [anchor]="anchor"
                 [animate]="{
                  direction: 'up',
                  duration: 333,
                  type: 'slide'
                 }"
                 [copyAnchorStyles]="true"
                 [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
                 [popupAlign]="{ horizontal: 'right', vertical: 'bottom' }">
      <div [class]="'ap-map-color-legend-controls'">
        <div *ngIf="colorLegend.title !== ''"
             class='legend-title'>{{colorLegend.title | translate}}</div>
        <div *ngIf="colorLegend.unit !== ''"
             class='legend-subtitle'>{{'Global__in' | translate}} {{colorLegend.unit | translate}}</div>
        <div class='legend-scale'>
          <ul class='legend-labels'>
            <li *ngFor="let color of colorLegend.colors; let i = index">
              <span [ngStyle]="{'background-color': color.color}"></span>
              {{getLabel(color, colorLegend.colors, i)}}
            </li>
          </ul>
        </div>
        <div *ngIf="colorLegend.component" [class]="'sub'">
          <ap-dynamic-component-loader [dynComp]="colorLegend.component"></ap-dynamic-component-loader>
        </div>
      </div>
    </kendo-popup>
  </div>
</div>

import {IStreamWatch, Store} from '../index';
import {Injectable}          from '@angular/core';
import {
  ApCustomTypes,
  BasicFertilisationPlanBooksDelete,
  BasicFertilisationPlanBooksSaveFail,
  BasicFertilisationPlanBooksSaveSuccess,
  BasicFertilisationPlanBooksUpdate,
  BasicFertilisationSummariesCreate,
  BasicFertilisationSummariesSaveFail,
  BasicFertilisationSummariesSaveSuccess,
  BasicFertilisationSummariesUpdate,
  LoadBasicFertilisationSummaries,
  LoadBasicFertilisationSummariesSuccess,
  NetTypes
}                            from 'invoker-transport';
import {IStateStore}         from '../../ap-interface';
import {SafeBehaviorSubject} from 'ts-tooling';
import IGuid = System.IGuid;
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IBasicFertilisationSummariesStore extends IStateStore<IBasicFertilisationSummaries> {
  saving: boolean;
  saved: boolean;
}

@Injectable({providedIn: 'root'})
export class BasicFertilisationSummariesStore extends Store<IBasicFertilisationSummariesStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      saving: false,
      saved: false
    });
    backend.registerObservable(LoadBasicFertilisationSummariesSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(BasicFertilisationSummariesSaveSuccess).subscribe(d => {
      super.SetSaveSuccessState();
      if (d.Data) {
        super.Mutate(s => s.data, o => {
          o = o.Replace(i => i.Id === d.Data.Id, d.Data);
          return o;
        });
      }
    });
    backend.registerObservable(BasicFertilisationSummariesSaveFail).subscribe(_ => {
      super.SetSaveFailState();
    });
    backend.registerObservable(BasicFertilisationPlanBooksSaveSuccess).subscribe(d => {
      super.SetSaveSuccessState();
      super.Mutate(s => s.data, o => {
        for (const iBasicFertilisationSummary of o) {
          const idx = iBasicFertilisationSummary.BasicFertilisationPlanBooks.FindIndex(planBook => planBook.Id === d.Data?.Id);
          if (idx >= 0) {
            iBasicFertilisationSummary.BasicFertilisationPlanBooks[idx] = d.Data;
          }
        }
        return o;
      });
    });
    backend.registerObservable(BasicFertilisationPlanBooksSaveFail).subscribe(_ => {
      super.SetSaveFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get Summaries$(): SafeBehaviorSubject<IBasicFertilisationSummaries[]> {
    return this.Listen((s) => s.data);
  }

  public get Summaries(): IBasicFertilisationSummaries[] {
    return this.Summaries$.getValue();
  }

  public get PlanBooks$(): SafeBehaviorSubject<IBasicFertilisationPlanBooks[]> {
    return this.Listen((s) => s.data.map(summary => summary.BasicFertilisationPlanBooks).flat());
  }

  public loadBasicFertilisationSummaries(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new LoadBasicFertilisationSummaries([]));
  }

  public createBasicFertilisation(basicFertilisationSummaries: IBasicFertilisationSummaries,
                                  withNotification: boolean = true,
                                  needRefreshSummaries: boolean = false): IStreamWatch {
    super.SetSaveState();
    return this.DispatchBackend(new BasicFertilisationSummariesCreate([
      {
        Name: 'summary',
        Type: ApCustomTypes.Data_TaskManagement_BasicFertilisationSummaries,
        Value: basicFertilisationSummaries
      },
      {Name: 'withNotification', Type: NetTypes.BOOL, Value: withNotification},
      {Name: 'needRefreshSummaries', Type: NetTypes.BOOL, Value: needRefreshSummaries}
    ]));
  }

  public updateBasicFertilisation(basicFertilisationSummaries: IBasicFertilisationSummaries,
                                  withNotification: boolean = true,
                                  needRefreshSummaries: boolean = true): IStreamWatch {
    super.SetSaveState();
    return this.DispatchBackend(new BasicFertilisationSummariesUpdate([
      {
        Name: 'summary',
        Type: ApCustomTypes.Data_TaskManagement_BasicFertilisationSummaries,
        Value: basicFertilisationSummaries
      },
      {Name: 'withNotification', Type: NetTypes.BOOL, Value: withNotification},
      {Name: 'needRefreshSummaries', Type: NetTypes.BOOL, Value: needRefreshSummaries}
    ]));
  }

  public updateBasicFertilisationPlanBooks(basicFertilisationPlanBooks: IBasicFertilisationPlanBooks[],
                                           withNotification: boolean = true,
                                           needRefreshSummaries: boolean = true): IStreamWatch {
    super.SetSaveState();
    return this.DispatchBackend(new BasicFertilisationPlanBooksUpdate([
      {
        Name: 'planBooks',
        Type: ApCustomTypes.Data_TaskManagement_BasicFertilisationPlanBooks + '[]',
        Value: basicFertilisationPlanBooks
      },
      {Name: 'withNotification', Type: NetTypes.BOOL, Value: withNotification},
      {Name: 'needRefreshSummaries', Type: NetTypes.BOOL, Value: needRefreshSummaries}
    ]));
  }

  public deleteBasicFertilisationPlanBooks(summaryId: IGuid | string, planBookIds: IGuid[] | string[],
                                           withNotification: boolean = true,
                                           needRefreshSummaries: boolean = false): IStreamWatch {
    super.SetSaveState();
    return super.DispatchBackend(new BasicFertilisationPlanBooksDelete([
      {Name: 'summaryId', Type: NetTypes.GUID, Value: summaryId},
      {Name: 'planBookIds', Type: NetTypes.GUID + '[]', Value: planBookIds},
      {Name: 'withNotification', Type: NetTypes.BOOL, Value: withNotification},
      {Name: 'needRefreshSummaries', Type: NetTypes.BOOL, Value: needRefreshSummaries}
    ]));
  }
}

import {Component} from '@angular/core';

const CONTENT = `
    <svg viewBox="0 0 1500 1500">
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
      <path d="M6095 12794 c-1354 -72 -2631 -552 -3669 -1378 -273 -218 -627 -554 -842 -801 -821 -944 -1342 -2077 -1518 -3305 -49 -339 -60 -507 -60 -910 0 -403 11 -571 60 -910 177 -1235 697 -2364 1527 -3314 132 -151 446 -464 592 -592 944 -821 2077 -1342 3305 -1518 339 -49 507 -60 910 -60 403 0 571 11 910 60 1128 162 2192 621 3075 1327 371 297 735 662 1031 1033 627 788 1071 1748 1263 2734 86 439 115 755 115 1240 0 403 -11 571 -60 910 -176 1228 -697 2361 -1518 3305 -128 146 -441 460 -592 592 -1039 907 -2282 1440 -3659 1568 -162 15 -716 27 -870 19z m595 -1869 c235 -65 463 -226 587 -416 92 -141 134 -296 134 -494 1 -177 -28 -302 -102 -448 -120 -238 -310 -399 -556 -473 -80 -24 -108 -27 -293 -32 -127 -2 -234 0 -278 7 -284 44 -580 257 -706 508 -40 80 -76 217 -85 326 -25 303 71 567 289 788 112 114 251 192 415 233 94 23 108 24 325 21 160 -2 219 -6 270 -20z m670 -5550 l0 -2845 380 0 380 0 0 -340 0 -340 -1720 0 -1720 0 0 340 0 340 380 0 380 0 0 2505 0 2505 -380 0 -380 0 0 340 0 340 1340 0 1340 0 0 -2845z" class="hoverFill"/>
    </g>
    </svg>
`;

@Component({
  selector: 'ap-imprint-icon',
  template: CONTENT
})
export class ApImprintIconComponent {
}

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApDynGridEditColumnConfig}                          from '../config/ap-dyn-grid-edit-column-config';
import {PopupSettings as ButtonPopupSettings}               from '@progress/kendo-angular-buttons/dist/es2015/listbutton/popup-settings';
import {ListItemModel}                                      from '@progress/kendo-angular-buttons';
import {DropDownButtonData}                                 from '../config/ap-dyn-grid-button-column-config';

interface CustomListItemModel extends ListItemModel {
  classList: string | string[] | ((dataItem) => string | string[]) | undefined;
  textFn: (dataItem) => string;
}

@Component({
  selector: 'ap-edit-column-cell',
  template: `
    <span [class]="config.cssClass | centerCell">
      <button *ngIf="!config.data"
              kendoGridEditCommand
              [disabled]="config.disabled$ | async"
              [primary]="true"
              [icon]="'pencil'"
              [aptooltip]="('Global__Edit' | translate) | execPipes:config:'tooltipPipes' | async"
              [perpetual]="true"></button>
      <kendo-dropdownbutton *ngIf="config.data"
                            [class]="'k-grid-edit-command'"
                            [data]="data"
                            [primary]="true"
                            [icon]="'menu'"
                            [aptooltip]="('Global__Edit' | translate) | execPipes:config:'tooltipPipes' | async"
                            [perpetual]="true"
                            [disabled]="config.disabled$ | async"
                            [popupSettings]="dropDownButtonPopupSettings">
        <ng-template kendoDropDownButtonItemTemplate let-item>
          <span [classList]="getClassList(item)">
            {{getText(item) | translate}}
          </span>
        </ng-template>
      </kendo-dropdownbutton>
      <button *ngIf="canInlineEdit" kendoGridSaveCommand
              [type]="'button'"
              [icon]="'check'"
              [aptooltip]="('Global__Save' | translate) | execPipes:config:'tooltipPipes' | async"
              [perpetual]="true"></button>
      <button *ngIf="canInlineEdit" kendoGridCancelCommand
              [type]="'button'"
              [icon]="'cancel'"
              [aptooltip]="('Global__Cancel' | translate) | execPipes:config:'tooltipPipes' | async"
              [perpetual]="true">
      </button>
    </span>
  `,
  styles: []
})
export class ApEditColumnCellComponent implements OnInit, OnChanges {
  dropDownButtonPopupSettings: ButtonPopupSettings = {
    align: 'right',
    animate: true,
    popupClass: 'ap-kendo-dropdown-button'
  };

  @Input() canInlineEdit = false;
  @Input() config: ApDynGridEditColumnConfig;
  @Input() dataItem: any;

  public data: CustomListItemModel[];

  constructor() {
  }

  ngOnInit(): void {
    this.data = this.config.data?.map((data) => {
      return {
        text: typeof data.text === 'string' ? data.text : '',
        textFn: typeof data.text === 'function' ? data.text : undefined,
        classList: typeof data.classList === 'function' ? data.classList : () => data.classList,
        click: () => data.callback.emit(this.dataItem),
        disabled: this._isDisabled(data)
      } as CustomListItemModel;
    }) ?? undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.data = this.config.data?.map((data) => {
      return {
        text: typeof data.text === 'string' ? data.text : '',
        textFn: typeof data.text === 'function' ? data.text : undefined,
        classList: data.classList,
        click: () => data.callback.emit(this.dataItem),
        disabled: this._isDisabled(data)
      } as CustomListItemModel;
    }) ?? undefined;
  }

  getText(item: CustomListItemModel): string {
    if (typeof item.textFn === 'function') {
      return  item.textFn(this.dataItem);
    } else if (typeof item.text === 'string') {
      return item.text;
    }
    return '';
  }

  getClassList(item: CustomListItemModel): string[] {
    if (typeof item.classList === 'function') {
      const res = item.classList(this.dataItem);
      return Array.isArray(res) ? res : [res];
    } else if (Array.isArray(item.classList)) {
      return item.classList;
    } else if (typeof item.classList === 'string') {
      return [item.classList];
    }
    return undefined;
  }

  private _isDisabled(data: DropDownButtonData): boolean {
    if (typeof data.disabled === 'function') {
      return  data.disabled(this.dataItem);
    } else if (typeof data.disabled === 'boolean') {
      return data.disabled;
    } else {
      return false;
    }
  }
}

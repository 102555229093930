import {IStateStoreWritable} from '../../ap-interface';
import {Store}               from '../index';
import {Injectable}          from '@angular/core';
import {
  ApCustomTypes,
  InstrumentsCreate,
  InstrumentsDelete,
  InstrumentsLoad,
  InstrumentsLoadSuccess,
  InstrumentsSaveFail,
  InstrumentsSaveSuccess,
  InstrumentsUpdate
}                            from 'invoker-transport';
import {MachineStore}        from './machine.store';
import {SafeBehaviorSubject} from 'ts-tooling';
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IResultMessage = Invoker.IResultMessage;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IInstrumentStore extends IStateStoreWritable<IInstrument> {
}

@Injectable({providedIn: 'root'})
export class InstrumentStore extends Store<IInstrumentStore> {
  constructor(public backend: ApSignalrService,
              private machineStore: MachineStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      saving: false,
      saved: false
    });

    backend.registerObservable(InstrumentsLoadSuccess)
      .subscribe(this.onInstrumentLoadFinish.bind(this));
    backend.registerObservable(InstrumentsSaveSuccess).subscribe(() => {
      super.SetSaveSuccessState();
    });
    backend.registerObservable(InstrumentsSaveFail).subscribe(() => {
      super.SetSaveFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.machineStore.ReloadSource());
  }

  public get Instruments$(): SafeBehaviorSubject<IInstrument[]> {
    return this.Listen(x => x.data);
  }

  /**
   *  load instruments from Backend to the Store
   */
  public loadInstruments(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new InstrumentsLoad([]));
  }

  /**
   * Updates the instrument via backend.
   */
  public updateInstrument(instrument: IInstrument): void {
    super.SetSaveState();
    this.DispatchBackend(new InstrumentsUpdate([
      {Name: 'instrument', Type: ApCustomTypes.Data_DocuContext_Instrument_Instrument, Value: instrument}
    ]));
  }

  /**
   * Creates the instrument via backend.
   */
  public createInstrument(instrument: IInstrument): void {
    super.SetSaveState();
    this.DispatchBackend(new InstrumentsCreate([
      {Name: 'instrument', Type: ApCustomTypes.Data_DocuContext_Instrument_Instrument, Value: instrument}
    ]));
  }

  /**
   * Deletes the instrument via backend.
   */
  public deleteInstrument(instrument: IInstrument): void {
    this.DispatchBackend(new InstrumentsDelete([
      {Name: 'instrument', Type: ApCustomTypes.Data_DocuContext_Instrument_Instrument, Value: instrument}
    ]));
  }

  public getInstrumentById(guid: string | System.IGuid | null): IInstrument | null {
    if (!guid) {
      return null;
    }
    return super.Listen(s => s.data).getValue().find(i => i.Id === guid);
  }

  private onInstrumentLoadFinish(d: IResultMessage): void {
    super.Mutate(s => s.data, () => d.Data);
    super.SetLoadFinishState();
  }
}

import {Store}                    from '../index';
import {EventEmitter, Injectable} from '@angular/core';
import {ApTranslationService}     from '../../ap-utils/service/ap-translation.service';
import {ILayerControlLayer}       from '../../ap-interface/map/layer-control-layer.interface';
import {orderBy}                  from '@progress/kendo-data-query';
import {SafeBehaviorSubject}      from 'ts-tooling';
import {BingImagery, LayerNames}  from '../../ap-map';
import {ApSignalrService}         from '../../ap-core/services/ap-signalr.service';

interface Map {
  value: BingImagery;
  text: string;
}

interface LayerItem {
  text: string;
  layer: ILayerControlLayer;
}

interface IMapLayerControlState {
  mapData: Map;
  maps: Map[];
  layers: ILayerControlLayer[];
  data: LayerItem[];
}

@Injectable({providedIn: 'root'})
export class MapLayerControlStore extends Store<IMapLayerControlState> {
  public onVisibilityChanged = new EventEmitter<{ layer: ILayerControlLayer, visibility: boolean }>();
  public onOrderChanged = new EventEmitter<{ layer: ILayerControlLayer, order: number }>();
  public onBackgroundMapChanged = new EventEmitter<BingImagery>();

  private translationKeys = {
    [LayerNames.FIELDS]: 'Global__Fields',
    [LayerNames.SOIL_SAMPLE_FIELDS]: 'Base_Pages__SampleFields',
    [LayerNames.LOCATIONS]: 'Settings__Lbl_Menu_Devices',
    [LayerNames.TRACK]: 'Docu__Driving_Lanes',
    [LayerNames.SOIL_SAMPLES]: 'DataContext_InterfaceMapping__Samples',
    [LayerNames.SOIL_SAMPLE_DISTRIBUTIONS]: 'Global__SoilSample_NutrientDistribution',
    [LayerNames.DISTRIBUTIONS]: 'Global__NutrientDistribution',
    [LayerNames.SOIL_GROUP_MAP]: 'Nutrients__Soilgroup',
    [LayerNames.NEED]: 'Nutrients__Need',
    [LayerNames.FUNGIDETECT]: 'Global__Fungidetect',
    [LayerNames.CONTACT]: 'Tip_ContactText',
    [LayerNames.EDIT]: 'Base__Editor',
    [LayerNames.TASK_MANAGEMENT]: 'Base__TaskWriter',
    [LayerNames.TASK_MANAGEMENT_NUTRIENT]: 'Global__NutrientDistribution',
    [LayerNames.NUTRIENT_PLANNINGS]: 'Global__Planning',
    [LayerNames.FIELDS_CROP]: 'Global__Crops',
    [LayerNames.SENSOR_POINTS]: 'Global__SamplePoints',
    [LayerNames.N_RASTER]: 'N__Sensor',
    [LayerNames.N_APPLICATION_MAP]: 'Global__Planning',
  };

  constructor(private backend: ApSignalrService,
              private translationService: ApTranslationService) {
    super(backend, {
      mapData: null,
      maps: [],
      layers: [],
      data: [],
    }, true);
  }

  get Maps$(): SafeBehaviorSubject<Map[]> {
    return this.Listen(s => s.maps);
  }

  get Maps(): Map[] {
    return this.Maps$.getValue();
  }

  get MapData$(): SafeBehaviorSubject<Map> {
    return this.Listen(s => s.mapData);
  }

  get MapData(): Map {
    return this.MapData$.getValue();
  }

  get Data$(): SafeBehaviorSubject<LayerItem[]> {
    return this.Listen(s => s.data);
  }

  get Data(): LayerItem[] {
    return this.Data$.getValue();
  }

  public refresh(param: {
    map: BingImagery,
    layers: ILayerControlLayer[],
  }): void {
    this.Mutate(s => s.maps, () => ([
      {value: BingImagery.GRAYSCALE_ROAD, text: this.translationService.translate('Base__Maps_Road')},
      {value: BingImagery.HYBRID, text: this.translationService.translate('Base__Maps_Hybrid')},
      {value: BingImagery.SATELLITE, text: this.translationService.translate('Base__Maps_Aerial')},
    ]));
    this.Mutate(s => s.mapData, () => this.Maps.Find(map => map.value === param.map));
    this.Mutate(s => s.layers, () => param.layers);
    this.Mutate(s => s.data, () => orderBy(this._layerControlLayerToLayerItem(param.layers), [{
      field: 'layer.Order',
      dir: 'asc'
    }]));
  }

  public reorder(sourceItem: LayerItem, destinationItem: LayerItem, step: number): void {
    const sourceOrder = sourceItem.layer.Order;
    const destinationOrder = destinationItem.layer.Order;
    if (sourceOrder < destinationOrder) {
      this.Data
        .FindAll(d => d.layer.Order > sourceOrder && d.layer.Order <= destinationOrder)
        .forEach(d => this.onOrderChanged.emit({
          layer: d.layer,
          order: d.layer.Order + step,
        }));
    } else if (sourceOrder > destinationOrder) {
      this.Data
        .FindAll(d => d.layer.Order < sourceOrder && d.layer.Order >= destinationOrder)
        .forEach(d => this.onOrderChanged.emit({
          layer: d.layer,
          order: d.layer.Order - step,
        }));
    }
    this.onOrderChanged.emit({
      layer: sourceItem.layer,
      order: destinationOrder,
    });
  }

  public move(item: LayerItem, dir: 1 | -1): void {
    const other = this.Data.FindAll(d => d.layer.Order === item.layer.Order + dir);
    if (other.Any()) {
      this.onOrderChanged.emit({
        layer: other[0].layer,
        order: other[0].layer.Order + dir,
      });

      this.onOrderChanged.emit({
        layer: item.layer,
        order: item.layer.Order - dir,
      });
    }
  }

  private _layerControlLayerToLayerItem(layers: ILayerControlLayer[]): LayerItem[] {
    return layers.map((layer) => ({
      text: this.translationService.translate(this.translationKeys[layer.name]),
      layer
    }));
  }
}

import {Component, Input}            from '@angular/core';
import {ApDynGridButtonColumnConfig} from '../config/ap-dyn-grid-button-column-config';

@Component({
  selector: 'ap-button-cell-column',
  template: `
    <span [class]="config.cssClass | centerCell">
          <button kendoButton
                  [disabled]="config.disabledFn ?
                              (config.disabledFn | execFunction:(dataItem)) :
                              config.disabled$ | async"
                  [icon]="config.isStandardIcon ? (dataItem | getProperty:config.standardIcon) : ((config.itemBusy$(dataItem) | async) ? 'loading' : undefined)"
                  [iconClass]="config.iconClass"
                  [primary]="true"
                  [imageUrl]="(config.itemBusy$(dataItem) | async) ? undefined : config.imageUrl"
                  (click)="config.callback.emit(dataItem)"
                  [style.cursor]="(config.itemBusy$(dataItem) | async) ? 'wait' : 'pointer'"
                  [style.padding]="config.iconClass ? '6px' : undefined"
                  [perpetual]="true"
                  [class.ap-state-active]="(config | asButton).selected(dataItem)"
                  [aptooltip]="(config.tooltip | translate) | execPipes:config:'tooltipPipes' | async">
          </button>

          <kendo-dropdownbutton *ngIf="config.data"
                                [data]="config.data"
                                [disabled]="config.disabledFn ?
                                    (config.disabledFn | execFunction:(dataItem)) :
                                    config.disabled$ | async"
                                [icon]="config.isStandardIcon ? (dataItem | getProperty:(config | asButton).standardIcon) : (((config | asButton).itemBusy$(dataItem) | async) ? 'loading' : undefined)"
                                [iconClass]="config.iconClass"
                                [primary]="true"
                                [imageUrl]="(config.itemBusy$(dataItem) | async) ? undefined : config.imageUrl"
                                (itemClick)="$event.callback.emit(dataItem)"
                                [style.cursor]="(config.itemBusy$(dataItem) | async) ? 'wait' : 'pointer'"
                                [style.padding]="config.iconClass ? '6px' : undefined"
                                [perpetual]="true"
                                [class.ap-state-active]="config.selected(dataItem)"
                                [aptooltip]="(config.tooltip | translate) | execPipes:config:'tooltipPipes' | async">
            <ng-template kendoDropDownButtonItemTemplate let-item>
              <span [classList]="item.classList">{{item.text}}</span>
            </ng-template>
          </kendo-dropdownbutton>
        </span>
  `,
  styles: []
})
export class ApButtonColumnCellComponent {
  @Input() config: ApDynGridButtonColumnConfig;
  @Input() dataItem: any;

  constructor() {
  }

}

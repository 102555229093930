<div [class]="(disabled ? 'k-disabled ' : '') + 'status-switch'">
  <label [class]="'container red'"
         [class.disabled]="disableRed"
         [aptooltip]="toolTipRed | translate"
         [perpetual]="true">
    <input [type]="'radio'" [name]="'radio'" [disabled]="disableRed" [checked]="state === -1" (click)="setState(-1)">
    <ap-icon-selector [id]="disableRed ?
                           'ap-ban-solid-icon' :
                           (state === -1 || intermediateState === -1)?
                             'ap-times-circle-solid-icon' :
                             'ap-times-circle-icon'"
                      [cssClass]="'icon red'"
                      [class.checked]="state === -1"
                      [aptooltip]="toolTipRed | translate">
    </ap-icon-selector>
  </label>
  <label *ngIf="showYellowState"
         [class]="'container yellow'"
         [class.disabled]="disableYellow"
         [aptooltip]="toolTipYellow | translate"
         [perpetual]="true">
    <input [type]="'radio'" [name]="'radio'" [disabled]="disableYellow" [checked]="state === 0" (click)="setState(0)">
    <ap-icon-selector [id]="disableYellow ?
                            'ap-ban-solid-icon' :
                             (state === 0 || intermediateState === 0) ?
                              'ap-exclamation-circle-solid-icon' :
                              'ap-exclamation-circle-icon'"
                      [cssClass]="'icon yellow'"
                      [class.checked]="state === 0">
    </ap-icon-selector>
  </label>
  <label [class]="'container green'"
         [class.disabled]="disableGreen"
         [aptooltip]="toolTipGreen | translate"
         [perpetual]="true">
    <input [type]="'radio'" [name]="'radio'" [disabled]="disableGreen" [checked]="state === 1" (click)="setState(1)">
    <ap-icon-selector [id]="disableGreen ?
                          'ap-ban-solid-icon' :
                          (state === 1 || intermediateState === 1)?
                            'ap-check-circle-solid-icon' :
                            'ap-check-circle-icon'"
                      [cssClass]="'icon green'"
                      [class.checked]="state === 1">
    </ap-icon-selector>
  </label>
</div>

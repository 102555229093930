import {IAction, IApInvokerArguments} from '../../interfaces';
import ICropTypes = Data.BaseData.ICropTypes;

export enum CroptypeActions {
  CROPTYPES_LOAD = '[croptypes] load',
  CROPTYPES_LOAD_SUCCESS = '[croptypes] load success',
  CROPTYPES_SELECTABLE_UPDATE = '[croptypes] selectable update',
  CROPTYPES_SAVE = '[croptypes] save',
}

export class CroptypesLoad implements IAction {
  readonly type = CroptypeActions.CROPTYPES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class CroptypesLoadSuccess implements IAction {
  readonly type = CroptypeActions.CROPTYPES_LOAD_SUCCESS;

  constructor(public payload: ICropTypes[]) {
  }
}

export class CroptypesSelectableUpdate implements IAction {
  readonly type = CroptypeActions.CROPTYPES_SELECTABLE_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class CroptypesSave implements IAction {
  readonly type = CroptypeActions.CROPTYPES_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

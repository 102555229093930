import {IAction, IApInvokerArguments} from '../../interfaces';
import IExportTarget = Data.Common.IExportTarget;

export enum ExportTargetsActions {
  EXPORT_TARGETS_LOAD = '[export_targets] load',
  EXPORT_TARGETS_LOAD_SUCCESS = '[export_targets] load success',
}

export class ExportTargetsLoad implements IAction {
  readonly type = ExportTargetsActions.EXPORT_TARGETS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ExportTargetsLoadSuccess implements IAction {
  readonly type = ExportTargetsActions.EXPORT_TARGETS_LOAD_SUCCESS;

  constructor(public payload: IExportTarget[]) {
  }
}

import {ApplicationRef, enableProdMode} from '@angular/core';
import {platformBrowserDynamic}         from '@angular/platform-browser-dynamic';
import {AppModule}                      from './app/app.module';
import {environment}                    from './environments/environment';
import {enableDebugTools}               from '@angular/platform-browser';
import './app/debug-utils/ApplicationTracer';
import 'invoker-transport';

if (environment.Production) {
  enableProdMode();
} else {
  window['mapFunctions'] = {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(moduleRef => {
    if (window.location.host === '127.0.0.1:9030' && !environment.Production) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      enableDebugTools(componentRef);
      console.log('Angular Profiler active');
    }
  })
  .catch(err => console.log(err));

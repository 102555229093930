import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable}                           from 'rxjs';

export class ApFormValidators {
  static controlEnabled(key: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        const ctrl = control.get(key);
        if (!ctrl) {
          reject();
        }
        setTimeout(() => resolve(control.status === 'DISABLED' ? {controlEnabled: true} : null), 0);
      });
    };
  }
}

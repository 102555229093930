import {Pipe, PipeTransform} from '@angular/core';
import {CenterCellService}   from '../services/center-cell.service';

@Pipe({
  name: 'centerCell'
})
export class CenterCellPipe implements PipeTransform {
  constructor(private centerCellService: CenterCellService) {}

  transform(value: string): string {
    return this.centerCellService.getCellCssClass(value);
  }
}

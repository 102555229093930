import {Component, OnInit, ViewChild} from '@angular/core';
import {Validators}                   from '@angular/forms';
import {ApDynformsConfigTextbox}      from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsConfigFieldset}     from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsValidator}          from '../../ap-dynforms/ap-dynforms-validator';
import {merge}                        from 'lodash';
import {ApDynformsComponent}          from '../../ap-dynforms/ap-dynforms.component';
import {SubFarmStore}                 from '../../stores/base-data/subfarm.store';
import {FormStore}                    from '../../stores/layout/form.store';
import {LoginStore}                   from '../../stores/login/login.store';
import ISubFarms = Data.BaseData.ISubFarms;

@Component({
  selector: 'ap-subfarms-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_subfarm_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_subfarm_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`
})

export class ApSubFarmsEditComponent implements OnInit {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: any;
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public caption: string;

  constructor(private formStore: FormStore,
              private subFarmStore: SubFarmStore,
              private loginStore: LoginStore) {
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        Id: undefined,
        FarmId: this.loginStore.SelectedFarmId,
        City: '',
        Email: '',
        Fax: '',
        Name: '',
        Nummer: '',
        Postalcode: '',
        Street: '',
        Telephone_Nr: ''
      };
    } else {
      this.isNewMode = false;
    }
    this.caption = this.isNewMode ? 'Settings__Lbl_Boa_Create' : 'Settings__Lbl_Boa_Edit';
    this.formBuilder();
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'subfarms',
        config: [
          new ApDynformsConfigTextbox({
            key: 'Nummer',
            label: 'Base__ManagementStyle_Number',
            value: this.data.Nummer,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Name',
            label: 'Global__Name',
            value: this.data.Name,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Street',
            label: 'Lbl_Order_Streetno',
            value: this.data.Street,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Postalcode',
            label: 'Global__ZipCode',
            value: this.data.Postalcode,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'City',
            label: 'Global__City',
            value: this.data.City,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Email',
            label: 'Global__Email',
            value: this.data.Email,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            }),
              new ApDynformsValidator({
                validator: Validators.pattern('^[-_.\\w]+@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.){1,300}[a-zA-Z]{2,9}$'),
                errorKey: 'Admin_Pages_Login_Reset_Password_Email_Error'
              })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Telephone_Nr',
            label: 'Global__Telephon',
            value: this.data.Telephone_Nr,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Fax',
            label: 'Docu_Ini__Fax',
            value: this.data.Fax,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          })
        ]
      })];
  }

  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    if (this.isNewMode) {
      this.subFarmStore.createSubFarm(this.data as ISubFarms);
    } else {
      this.subFarmStore.updateSubFarm(this.data as ISubFarms);
    }
    this.formStore.closeForm();
  }

  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

<kendo-multiviewcalendar
  kendoDateRangeSelection
  [id]="'DateRangeFilter'"
  [selectionRange]="selected"
  [focusedDate]="selected.start || selected.end"
  [max]="range.max"
  [min]="range.min"
  [bottomView]="view"
  [views]="1"
  (selectionRangeChange)="change($event)"
></kendo-multiviewcalendar>

import {ApElementType}           from '../../../ap-interface/enums/ap-elements-type.enum';
import {ApDynComponentComponent} from '../../../ap-dyncomponent/ap-dyncomponent.component';
import IGuid = System.IGuid;
import IRbStatistic = Data.Nutrients.IRbStatistic;
import IMachine = Data.DocuContext.Machine.IMachine;
import IDriver = Data.DocuContext.Driver.IDriver;
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;

export enum PlanningEntryComponentDataType {
  Create,
  Edit
}

export type PlanningEntryComponentData = {
  Type: PlanningEntryComponentDataType;
  Summary: IBasicFertilisationSummaries | null | undefined;
};

export type Limits = {
  Min: number,
  Max: number
};

export type CalculationValuesInput = {
  UnitId: number,
  ElementId: number,
  ProductId: number,
  Amount: number;
  ApplConst: number | null;
  ApplMax: number | null;
  ApplMin: number | null;
};

export type CalculationValuesOutput = {
  UnitId: number,
  ApplRate: number,
  ApplConst: number,
  ApplMax: number,
  ApplMin: number,
  HasApplMax: boolean,
  HasApplMin: boolean,
};

export type EditPlanningDataFormType = {
  NutrientAmount: number | null;
  GoodsAmount: number | null;
  PercentAmount: number | null;
  ApplMax: number | null;
  ApplMin: number | null;
  ApplConst: number | null;
  TotalSum: number | null;
};

export type PlanningEntryDataToEdit = {
  PlanId: IGuid | null;
  NutrientAmount: number | null;
  PercentAmount: number | null;
  ApplMax: number | null;
  ApplMin: number | null;
  ApplConst: number | null;
};

export type UpdatedPlanningEntry = {
  PlanId: IGuid;
  ApplMin: number | null;
  ApplMax: number | null;
  ApplConst: number | null;
};

export type RemainDemandType = {
  ElementType: ApElementType,
  Amount: number | '-';
};

export interface INutrientPlaningSummaryData {
  Id: IGuid;
  Title: string;
  Farm_Id: number;
  User_Appl_Min: number | null;
  User_Appl_Max: number | null;
  User_Appl_Const: number | null;
  User_Appl_Unit: number | null;
  User_Appl_Mode: number | null;
  User_Appl_Date: Date;
  User_Operation_Mode: number | null;
  User_Operation_Mode_Const_Only: boolean;
  User_Operation_Mode_Some_Const: boolean;
  Plan_Number: number | null;
  Statistic: any[];
  Nutrient_Planing_Data: INutrientPlaningData[];
  MinDate: Date;
  MaxDate: Date;
  Selected_Fields: any[];
  TotalFieldsArea: number | null;
  Product_Id: number | null;
  Group_Id: any;
  Element: number | null;
  RbStatistics: IRbStatistic[];
  Amount_Nutrient: number | null;
  Amount_Goods: number | null;
  Amount_Percent: number | null;
  Driver: IDriver | null;
  Instrument: IInstrument | null;
  Machine: IMachine | null;
}

export interface INutrientPlaningData {
  Id: IGuid;
  Summary_Id: IGuid;
  Operation_Mode: any;
  Appl_Mode: any;
  Element: any;
  Appl_Rate: number;
  Unit: any;
  Factor: number;
  Appl_Min: number;
  Appl_Max: number;
  Appl_Const: number;
  Valid_From: any;
  Valid_To: any;
  Note: string;
  Field_Geom_Id: IGuid;
  Croptype_Id: any;
  Croptype_Second_Id: any;
  Geom: any;
  Appl_Date: any;
  Statistic: any;
  Field_Id: IGuid;
  T_Start: any;
  T_End: any;
  Plan_Id: IGuid;
  Ready_Calculated: boolean;
  Ready: boolean;
  RbStatistic: any;
  Max: any;
  Field: any;
  Status: number;
  Driver: IDriver;
  Instrument: IInstrument;
  Machine: IMachine;
}

export interface FieldGeomAndPlanId {
  FieldGeomId: IGuid;
  PlanId: IGuid;
}

export interface INutrientPlanningEntryStepsConfig {
  initData(): void;

  setSubscriptions(dynComponent: ApDynComponentComponent): void;

  clearData(): void;
}

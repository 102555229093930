import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ApMapInstance, ApMapViews}                             from '../../ap-map';
import {MapStore}                                              from '../../stores/map/map.store';

/**
 * Component for map's zoom and info controls.
 */
@Component({
  selector: 'ap-map-zoom',
  templateUrl: 'ap-map-zoom.component.html',
  styleUrls: ['ap-map-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

/**
 * Component for map's zoom and info controls.
 */
export class ApMapZoomComponent {

  public showInfo = false;
  private userPositionPressed = false;

  constructor(private mapStore: MapStore) {
  }

  /**
   * User clicked info button
   */
  infoClicked(): void {
    this.showInfo = !this.showInfo;
  }

  /**
   * eventhandler for click of (+) button
   */
  zoomInClicked(): void {
    ApMapViews.olView.setZoom(this.mapStore.LastView.zoom + 1);
  }

  /**
   * eventhandler for click of (-) button
   */
  zoomOutClicked(): void {
    ApMapViews.olView.setZoom(this.mapStore.LastView.zoom - 1);
  }

  setNorthOrientation(): void {
    ApMapViews.olView.setRotation(0);
  }

  showUserPosition(): void {
    if (!this.userPositionPressed) {
      document.getElementById('ap-map-showLocation')?.classList.add('toggled');
      this.mapStore.Layers.UserLocationLayer.initUserPositionTracking();
    } else {
      document.getElementById('ap-map-showLocation')?.classList.remove('toggled');
      this.mapStore.Layers.UserLocationLayer.disableUserPositionTracking();
    }

    this.userPositionPressed = !this.userPositionPressed;
  }
}

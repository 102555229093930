<form [formGroup]="form">
  <div class="ap-dynform-fieldset">
    <div style="grid-area: 1 / 1 / auto / auto;">
      <label>
        <ap-responsive-text [key]="'Docu_Ini__Type'"></ap-responsive-text>
        <input
          kendoTextBox
          [formControlName]="'Key'"
          [type]="'text'"
          [class]="'ap-textbox-container'"
          [placeholder]="'Docu_Ini__Type' | translate"
          (blur)="onBlur()"/>
      </label>
    </div>
    <div style="grid-area: 1 / 3 / auto / auto;">
      <label>
        <ap-responsive-text [key]="'Amount'"></ap-responsive-text>
        <kendo-numerictextbox [formControlName]="'Value'"
                              [step]="0.05"
                              [min]="0"
                              (blur)="onBlur()"></kendo-numerictextbox>
      </label>
    </div>
    <div style="grid-area: 2 / 1 / auto / auto;">
      <label>
        <ap-responsive-text [key]="'Settings__Lbl_Menu_Unit'"></ap-responsive-text>
        <kendo-combobox [class]="'ap-dropdown-bright'"
                        [formControlName]="'Unit'"
                        [valuePrimitive]="true"
                        [valueField]="'value'"
                        [textField]="'text'"
                        [data]="units | async"
                        [allowCustom]="false"
                        (blur)="onBlur()">
        </kendo-combobox>
      </label>
    </div>
    <div style="grid-area: 2 / 3 / auto / auto;">
      <button style="margin-top: 7%;" kendoButton type="button"
              [icon]="'delete'" (click)="onDeleteClick()"></button>
    </div>
  </div>
</form>

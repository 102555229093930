import {ApDynformsConfigBase, ApDynformsControltype}    from './ap-dynforms-config-base';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {ApDynformsValidator}                            from '../ap-dynforms-validator';
import {AsyncValidatorFn, ValidatorFn}                  from '@angular/forms';
import {map}                                            from 'rxjs/operators';
import {get}                                            from 'lodash';
import {ApUtilService}                                  from '../../ap-utils/service/ap-util.service';
import {ObjectFactory}                                  from 'ts-tooling';
import {ApTranslationService}                           from '../../ap-utils/service/ap-translation.service';
import {TemplateRef}                                    from '@angular/core';
import {groupBy, GroupResult}                           from '@progress/kendo-data-query';

/**
 * configuration for dropdown control
 */
export class ApDynformsConfigComboBox extends ApDynformsConfigBase<any> {
  controlType = ApDynformsControltype.ComboBox;
  optionsIn: Observable<any[]> = of([]);
  options: Observable<any[]> = of([]);
  disabledOptions: Observable<(x) => boolean> = of(() => false);
  valueField: string;
  textField: string;
  valuePrimitive: boolean;
  placeholder: string;
  clearButton: boolean;
  loading$: Observable<boolean>;
  noDataString: string;
  noDataTemplate: TemplateRef<any>;
  groupByField: string;
  /**
   * <ng-template #ComboBoxItem let-id="Id">
   *  {{id}}
   * </ng-template>
   * @ViewChild('ComboBoxItem', {static: true}) comboBoxItem: TemplateRef<any>;
   */
  itemTemplate: TemplateRef<any>;

  private _filter = new BehaviorSubject<string>('');

  constructor(options: {
    value?: any,
    value$?: Observable<any>,
    key?: string,
    label?: string,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    listenUpdate?: Observable<any>[],
    options?: any[] | Observable<any[]>,
    disabledOptions?: ((x) => boolean) | Observable<(x) => boolean>,
    valueField?: string,
    textField?: string,
    valuePrimitive?: boolean,
    placeholder?: string,
    clearButton?: boolean,
    dependsOn?: string[],
    loading?: boolean | Observable<boolean>,
    sort?: (1 | -1),
    formErrors?: string[],
    translate?: boolean,
    itemTemplate?: TemplateRef<any>
    noData?: TemplateRef<any> | string,
    cssClass?: string,
    infoText?: string,
    groupByField?: string
  } = {}) {
    super(options);
    this.infoText = options.infoText || '';
    this.valuePrimitive = options.valuePrimitive !== false;
    this.optionsIn = ApUtilService.asObservable(options.options, []);
    this.groupByField = options.groupByField;

    if (options.translate) {
      if (!options.textField) {
        this.optionsIn = this.optionsIn.pipe(
          map((d) => d.map((s) => ApTranslationService.translate(s)))
        );
        this.value = ApTranslationService.translate(this.value);
      } else {
        this.optionsIn = this.optionsIn.pipe(
          map((d) => d.map((o) => {
            o = ObjectFactory.Copy(o);
            o[this.textField] = ApTranslationService.translate(o[this.textField]);
            if (this.groupByField?.length >= 0) {
              o[this.groupByField] = ApTranslationService.translate(o[this.groupByField]);
            }
            return o;
          }))
        );
      }
    }

    if (options.sort) {
      this.optionsIn = this.optionsIn.pipe(
        map(d => ObjectFactory.Copy(d).sort((a, b) =>
          (!!this.textField ? get(a, options.textField)?.toLowerCase() < get(b, options.textField)?.toLowerCase() : a < b) ? -options.sort : options.sort
        ))
      );
    }

    // this.options = this.options_in;
    this.options = combineLatest([this.optionsIn, this._filter]).pipe(
      map(([oIn, filterString]) =>
        {
          const filterOptions = oIn.FindAll(o => ApDynformsConfigComboBox._containsFilter(o, filterString, this.textField));
          return options.groupByField?.length >= 0 ?
            groupBy(filterOptions, [
              { field: this.groupByField }
            ]) as GroupResult[] : filterOptions;
        }
      ));

    this.disabledOptions = ApUtilService.asObservable(options.disabledOptions, () => false);
    this.valueField = options.valueField;
    this.textField = options.textField;
    this.placeholder = options.placeholder;
    this.clearButton = options.clearButton === true;
    this.loading$ = ApUtilService.asObservable(options.loading, false);
    this.itemTemplate = options.itemTemplate;
    if (typeof options.noData === 'object') {
      this.noDataTemplate = options.noData;
    } else if (typeof options.noData === 'string') {
      this.noDataString = options.noData;
    }
  }

  private static _containsFilter(o: any, filterString: string, textField: string): boolean {
    if (typeof o !== typeof '') {
      o = get(o, textField);
    }
    if (typeof o !== typeof '') {
      return true;
    }
    return o.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
  }

  filterChange(filterString: string): void {
    this._filter.next(filterString);
  }
}

import {ApDynformsConfigFieldset}      from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {AsyncValidatorFn, ValidatorFn} from '@angular/forms';
import {ApDynformsValidator}           from '../../ap-dynforms/ap-dynforms-validator';

export enum ModalDialogButtonDisable {
  Never,
  Always,
  FormInvalid
}

export interface IModalDialogButton {
  key: string;
  text: string;
  primary: boolean;
  disable: ModalDialogButtonDisable;
}

export interface IModalDialogFormConfig {
  fieldSets: ApDynformsConfigFieldset[];
  values: any;
  validators: ApDynformsValidator<ValidatorFn>[];
  asyncValidators: ApDynformsValidator<AsyncValidatorFn>[];
  width: any;
}

export interface IModalDialogResult {
  key: string;
  formValues?: any;
}

export interface IModalDialogData {
  title: string;
  busy: boolean;
  message: string;
  resultDelegate: (data: any, dialogResult: IModalDialogResult) => void;
  formConfig: IModalDialogFormConfig;
  show: boolean;
  buttons: IModalDialogButton[];
  values: any;
}

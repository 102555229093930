import {MessageService}   from '@progress/kendo-angular-l10n';
import {Injectable}       from '@angular/core';
// load the language packages from Kendo UI
import '@progress/kendo-angular-intl/locales/de/calendar';
import '@progress/kendo-angular-intl/locales/en/calendar';
import '@progress/kendo-angular-intl/locales/pl/calendar';
import '@progress/kendo-angular-intl/locales/lt/calendar';
import '@progress/kendo-angular-intl/locales/lv/calendar';
import '@progress/kendo-angular-intl/locales/et/calendar';
import '@progress/kendo-angular-intl/locales/sk/calendar';
import '@progress/kendo-angular-intl/locales/sr/calendar';
import '@progress/kendo-angular-intl/locales/uk/calendar';
import '@progress/kendo-angular-intl/locales/hu/calendar';
import '@progress/kendo-angular-intl/locales/hr/calendar';
import '@progress/kendo-angular-intl/locales/cs/calendar';
import '@progress/kendo-angular-intl/locales/ru/calendar';
import '@progress/kendo-angular-intl/locales/ro/calendar';

import '@progress/kendo-angular-intl/locales/de/numbers';
import '@progress/kendo-angular-intl/locales/en/numbers';
import '@progress/kendo-angular-intl/locales/pl/numbers';
import '@progress/kendo-angular-intl/locales/lt/numbers';
import '@progress/kendo-angular-intl/locales/lv/numbers';
import '@progress/kendo-angular-intl/locales/et/numbers';
import '@progress/kendo-angular-intl/locales/sk/numbers';
import '@progress/kendo-angular-intl/locales/sr/numbers';
import '@progress/kendo-angular-intl/locales/uk/numbers';
import '@progress/kendo-angular-intl/locales/hu/numbers';
import '@progress/kendo-angular-intl/locales/hr/numbers';
import '@progress/kendo-angular-intl/locales/cs/numbers';
import '@progress/kendo-angular-intl/locales/ru/numbers';
import '@progress/kendo-angular-intl/locales/ro/numbers';
import {LanguageStore}    from '../stores/translation/language.store';
import {TranslationStore} from '../stores/translation/translation.store';

@Injectable({providedIn: 'root'})
export class ApKendoMessageService extends MessageService {

  countryCode: string;

  constructor(private languageStore: LanguageStore,
              private translationStore: TranslationStore) {
    super();
    this.languageStore.Listen(s => s.selectedLanguage).subscribe(d => {
      if (!d) {
        return;
      }
      this.countryCode = d.Key;
      super.notify();
    });
  }

  public get(key: string): string {
    return this.translationStore.FindTranslation(key, this.countryCode);
  }
}

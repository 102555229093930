import {Component} from '@angular/core';

const CONTENT = `
    <svg viewBox="0 0 192 192">
      <path d="M32,28V164H68V28ZM50,152a10,10,0,1,1,10-10A10,10,0,0,1,50,152Zm10-48H40V36H60Z"/>
      <path d="M78,28V164h36V28ZM96,152a10,10,0,1,1,10-10A10,10,0,0,1,96,152Zm10-48H86V36h20Z"/>
      <path d="M149.53,24.15,114,29.63,134.64,164l35.58-5.48Zm-16.11,79.37L123.08,36.32l19.77-3,
                  10.34,67.21ZM150.6,149.4A10,10,0,1,1,159,138,10,10,0,0,1,150.6,149.4Z"
            class="hoverFill"/>
    </svg>
`;

@Component({
  selector: 'ap-basedata-icon',
  template: CONTENT
})
export class ApBasedataIconComponent {
}

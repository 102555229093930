import {NgModule}                                             from '@angular/core';
import {LayoutModule}                                         from '@progress/kendo-angular-layout';
import {CommonModule}                                         from '@angular/common';
import {FormsModule}                                          from '@angular/forms';
import {ButtonModule, ButtonsModule, DropDownButtonModule}    from '@progress/kendo-angular-buttons';
import {ToolBarModule}                                        from '@progress/kendo-angular-toolbar';
import {ApTranslationModule}                                  from '../translation/ap-translation.module';
import {CookieService}                                        from 'ngx-cookie-service';
import {ApSvgStringDirective}                                 from './directives/ap-svg-string.directive';
import {ApErrorhandlerService}                                from './error-handling/ap-errorhandler.service';
import {
  ApTableFooterComponent
}                                                             from './components/ap-table-footer/ap-table-footer.component';
import {ApIconModule}                                         from '../icons/ap-icon.module';
import {ApNotifyComponent}                                    from './components/ap-notify/ap-notify.component';
import {ApWindowHelperService}                                from './services/ap-window-helper.service';
import {
  AdViewContainerComponent
}                                                             from './components/ap-view-container/ap-view-container.component';
import {ApResponsiveDirective}                                from './directives/ap-responsive.directive';
import {ApGridComponent}                                      from './components/ap-grid/ap-grid.component';
import {ApViewContainerRefDirective}                          from './directives/ap-view-container-ref.directive';
import {DateInputsModule}                                     from '@progress/kendo-angular-dateinputs';
import {ApGridPagerComponent}                                 from './components/ap-grid-pager/ap-grid-pager.component';
import {FilterMenuModule, GridModule, PagerModule, PDFModule} from '@progress/kendo-angular-grid';
import {
  ApPercentBarComponent
}                                                             from './components/ap-percent-bar/ap-percent-bar.component';
import {
  ApGridWizardComponent
}                                                             from './components/ap-grid-wizard/ap-grid-wizard.component';
import {DropDownsModule}                                      from '@progress/kendo-angular-dropdowns';
import {ApMapModule}                                          from '../map/ap-map.module';
import {InputsModule}                                         from '@progress/kendo-angular-inputs';
import {ApHelpModule}                                         from '../help/ap-help.module';
import {
  ApBreadcrumbsSteperComponent
}                                                             from './components/ap-breadcrumps-stepper/ap-breadcrumbs-steper.component';
import {
  ApGridMulticheckFilterComponent
}                                                             from './components/ap-grid-multicheck-filter/ap-grid-multicheck-filter.component';
import {ApUtilsModule}                                        from '../ap-utils';
import {
  ApGridMultiSelectComponent
}                                                             from './components/ap-grid-multi-select/ap-grid-multi-select.component';
import {PopupModule}                                          from '@progress/kendo-angular-popup';
import {
  ApModalDialogComponent
}                                                             from './components/ap-modal-dialog/ap-modal-dialog.component';
import {DialogsModule}                                        from '@progress/kendo-angular-dialog';
import {ApDynformsModule}                                     from '../ap-dynforms/ap-dynforms.module';
import {ApResponsiveTextModule}                               from '../ap-responsive-text/ap-responsive-text.module';
import {ApTooltipModule}                                      from '../ap-tooltip/ap-tooltip.module';
import {
  ApModalDialogButtonDisablePipe
}                                                             from './components/ap-modal-dialog/ap-modal-dialog-button-disable.pipe';
import {ApPermissionModule}                                   from '../ap-permission/ap-permission.module';
import {TooltipModule}                                        from '@progress/kendo-angular-tooltip';
import {
  ApNotifyMessageComponent
}                                                             from './components/ap-notify-message/ap-notify-message.component';
import {
  ApStaticGridPagerComponent
}                                                             from './components/ap-static-grid-pager/ap-static-grid-pager.component';
import {
  ApGridPagerNumericComponent
}                                                             from './components/ap-grid-pager-numeric/ap-grid-pager-numeric.component';
import {LoaderModule}                                         from '@progress/kendo-angular-indicators';
import {IconModule, SVGIconModule}                            from '@progress/kendo-angular-icons';
import {ApFarmJobListComponent}                               from './components/ap-farm-job-list.component';
import {ProgressBarModule}                                    from '@progress/kendo-angular-progressbar';
import {NgScrollbarModule}                                    from 'ngx-scrollbar';
import {RunningTimePipe}                                      from './components/ap-farm.job-running-time.pipe';
import {
  ApGridPageSizeComponent
}                                                             from './components/ap-grid-page-size/ap-grid-page-size.component';

@NgModule({
  declarations: [
    ApSvgStringDirective,
    ApTableFooterComponent,
    AdViewContainerComponent,
    ApResponsiveDirective,
    ApGridComponent,
    ApViewContainerRefDirective,
    ApNotifyComponent,
    ApNotifyMessageComponent,
    ApGridPagerComponent,
    ApPercentBarComponent,
    ApGridWizardComponent,
    ApBreadcrumbsSteperComponent,
    ApGridMulticheckFilterComponent,
    ApGridMultiSelectComponent,
    ApGridMulticheckFilterComponent,
    ApModalDialogComponent,
    ApModalDialogButtonDisablePipe,
    ApStaticGridPagerComponent,
    ApGridPagerNumericComponent,
    ApFarmJobListComponent,
    RunningTimePipe,
    ApGridPageSizeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    ToolBarModule,
    DropDownButtonModule,
    ApTranslationModule,
    DateInputsModule,
    ApIconModule,
    ApMapModule,
    ApHelpModule,
    GridModule,
    PDFModule,
    DropDownsModule,
    LayoutModule,
    InputsModule,
    ApUtilsModule,
    DialogsModule,
    ApUtilsModule,
    PagerModule,
    PopupModule,
    FilterMenuModule,
    ApDynformsModule,
    ApResponsiveTextModule,
    ApTooltipModule,
    ApPermissionModule,
    TooltipModule,
    ButtonsModule,
    LoaderModule,
    SVGIconModule,
    ProgressBarModule,
    NgScrollbarModule,
    IconModule,
  ],
  providers: [
    CookieService,
    ApErrorhandlerService,
    ApWindowHelperService,
  ],
  exports: [
    ApSvgStringDirective,
    ApTableFooterComponent,
    ApResponsiveDirective,
    ApGridComponent,
    AdViewContainerComponent,
    ApNotifyComponent,
    ApGridPagerComponent,
    ApPercentBarComponent,
    ApGridWizardComponent,
    ApBreadcrumbsSteperComponent,
    ApGridMulticheckFilterComponent,
    ApModalDialogComponent,
    ApStaticGridPagerComponent,
    ApGridMultiSelectComponent,
    ApGridPagerNumericComponent,
    ApFarmJobListComponent,
    ApGridPageSizeComponent
  ]
})
export class ApCoreModule {
}

import {Component, OnInit} from '@angular/core';
import {MapViewMode}       from '../../../ap-interface';
import {ApPermissions}     from 'invoker-transport';
import {Observable}        from 'rxjs';
import {MapViewStore}      from '../../../stores/layout/mapview.store';


@Component({
  selector: 'ap-section-header',
  templateUrl: 'ap-section-header.component.html'
})
export class ApSectionHeaderComponent implements OnInit {
  public mapViewModes = MapViewMode;
  public mapViewState$: Observable<MapViewMode>;
  public ApPermissions = ApPermissions;

  constructor(private mapViewStore: MapViewStore) {
  }

  ngOnInit(): void {
    this.mapViewState$ = this.mapViewStore.Listen(s => s.mode);
  }

  openMap(): void {
    this.mapViewStore.goIntoMapMenu();
    this.mapViewStore.showMapView();
  }


  openStats(): void {
    this.mapViewStore.goIntoStatisticMenu();
    this.mapViewStore.showMapView();
  }


  openHelp(): void {
    this.mapViewStore.goIntoHelpMenu();
    this.mapViewStore.showMapView();
  }
}

import IUsergroup = Data.AccessPermission.IUsergroup;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum ApUsergroupsUserActions {
  LOAD_USERGROUPS_USER = '[usergroupsuser] load',
  LOAD_USERGROUPS_USER_SUCCESS = '[usergroupsuser] load success',
  LOAD_USERGROUPS_USER_FAIL = '[usergroupsuser] load fail',
  UPDATE_USERGROUPS_USER = '[usergroupsuser] update',
  UPDATE_USERGROUPS_USER_SUCCESS = '[usergroupsuser] update success',
}

export class LoadUsergroupsUser implements IAction {
  readonly type = ApUsergroupsUserActions.LOAD_USERGROUPS_USER;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadUsergroupsUserSuccess implements IAction {
  readonly type = ApUsergroupsUserActions.LOAD_USERGROUPS_USER_SUCCESS;

  constructor(public payload: IUsergroup[]) {
  }
}

export class UpdateUsergroupsUser implements IAction {
  readonly type = ApUsergroupsUserActions.UPDATE_USERGROUPS_USER;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UpdateUsergroupsUserSuccess implements IAction {
  readonly type = ApUsergroupsUserActions.UPDATE_USERGROUPS_USER_SUCCESS;
}

import {Pipe, PipeTransform}                   from '@angular/core';
import {FieldSoilPlanningYear, SimpleValueSet} from '../charts/nutrients.stats.component';
import {FieldStore}                            from '../../../stores/farm/field.store';
import {Create}                                from 'ts-tooling';

@Pipe({
  name: 'fieldSoilPlanningYearToSimpleValueSet',
})
export class FieldSoilPlanningYearToSimpleValueSetPipe implements PipeTransform {
  constructor(private fieldStore: FieldStore) {
  }

  transform(value: FieldSoilPlanningYear, ...args: any[]): SimpleValueSet {
    const result: SimpleValueSet = [];
    const field = this.fieldStore.getFieldById(value.fieldId);
    const geom = this.fieldStore.getCurrentFieldGeom(field);
    for (const year of value.dates) {
      result.Add({
        id: this.fieldStore.getFieldName(field),
        category: year.date,
        value: Create(geom?.AreaHa, 0) * year.percent / 100,
        meta: null,
        color: '',
      });
    }
    return result;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {IApPngPath}          from '../../ap-interface/interfaces/ap-png-path.interface';


@Pipe({
  name: 'convertBase64ToPng'
})
export class ConvertBase64ToPngPipe implements PipeTransform {
  transform(base64String: string, ...args: any[]): IApPngPath {
    const base64Image = base64String.split(';base64,').pop();
    return null;
  }
}

import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType,
  ApDynGridColumnHideConfig,
  IPipeDefinition
}                       from './ap-dyn-grid-column-config-base';
import {EventEmitter}   from '@angular/core';
import {Observable, of} from 'rxjs';

export class DropDownButtonData {
  text: string | ((dataItem) => string);
  classList?: string | string[] | ((dataItem: any) => string | string[]);
  callback: EventEmitter<any>;
  disabled?: boolean | ((item) => boolean);
}

export class ApDynGridButtonColumnConfig extends ApDynGridColumnConfigBase {
  public type = ApDynGridColumnControlType.Button;

  public callback: EventEmitter<any>;
  public data: DropDownButtonData[];
  public imageUrl: string;
  public tooltip: string;
  public disabledFn: (x) => boolean;
  public disabled$: Observable<boolean>;
  public itemBusy$: (x) => Observable<boolean>;
  public selected: (x) => boolean;
  public iconClass: string;
  public isStandardIcon: boolean;
  public standardIcon: string;

  constructor(options: {
    hide?: boolean | ApDynGridColumnHideConfig,
    sortIndex?: number,
    sortDesc?: boolean,
    groupIndex?: number,
    groupDesc?: boolean,
    headerStyle?: any,
    style?: any,
    callback?: EventEmitter<any>,
    imageUrl?: string,
    tooltip?: string,
    disabled?: Observable<boolean> | boolean | ((x) => boolean),
    itemBusy$?: (x) => Observable<boolean>,
    toggleable?: boolean,
    selected?: (x) => boolean,
    iconClass?: string,
    isStandardIcon?: boolean;
    standardIcon?: string;
    cssClass?: 'left' | 'right',
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
  } = {}) {
    super(options);
    this.width = 37;
    this.callback = options.callback;
    this.imageUrl = options.imageUrl;
    this.tooltip = options.tooltip ? options.tooltip : '';
    this.isStandardIcon = options.isStandardIcon ? options.isStandardIcon : false;
    this.standardIcon = options.isStandardIcon ? options.standardIcon : '';
    this.groupIndex = undefined;
    this.sortIndex = undefined;
    if (options.disabled instanceof Function) {
      this.disabledFn = options.disabled;
    } else if (options.disabled instanceof Observable) {
      this.disabled$ = options.disabled;
    } else if (options.disabled !== undefined) {
      this.disabled$ = of(options.disabled);
    } else {
      this.disabled$ = of(false);
    }
    if (options.itemBusy$) {
      this.itemBusy$ = options.itemBusy$;
    } else {
      this.itemBusy$ = () => of(false);
    }
    if (options.selected) {
      this.selected = options.selected;
    } else {
      this.selected = () => false;
    }
    this.iconClass = options.iconClass;
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'ap-svg',
  templateUrl: 'ap-svg.component.html'
})
export class ApFieldSvgComponent {
  @Input()
  public shape: string;

  @Input()
  public fillColor: string;

  @Input()
  public strokeColor: string;

  @Input()
  public strokeWidth: number;

  @Input()
  public width: number;

  @Input()
  public height: number;

  @Input()
  public viewBox: string;
}

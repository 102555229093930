import {Injectable} from '@angular/core';
import {
  ContentNRelatedPlanBookSourceType,
  IContentNRelatedPlanBook,
  IContentNRelatedPlanBookStatisticValue,
}                   from '../../types/n-fertilization.types';
import {
  FertilizerStore
}                   from '../../stores/base-data/fertilizer.store';
import {
  FieldStore
}                   from '../../stores/farm/field.store';
import {
  TranslationStore
}                   from '../../stores/translation/translation.store';
import {
  CropApplicationStore
}                   from '../../stores/task-management/crop-application.store';
import {
  FertilizationManagementService
}                   from './fertilization-management.service';
import {
  CampaignYearStore
}                   from '../../stores/login/campaignyear.store';
import {
  BaseFertilizationService
}                   from './base-fertilization.service';
import INFertilizationPlanBook = Data.TaskManagement.INFertilizationPlanBook;
import IOperationMode = Data.Common.IOperationMode;
import IDatasetFieldMap = Data.Sensor.IDatasetFieldMap;
import IField = Data.FieldManagement.IField;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;

@Injectable({providedIn: 'root'})
export class NFertilizationService {
  constructor(private fieldStore: FieldStore,
              private fertilizerStore: FertilizerStore,
              private translationStore: TranslationStore,
              private campaignYearStore: CampaignYearStore,
              private cropApplicationStore: CropApplicationStore,
              private baseFertilizationService: BaseFertilizationService,
              private fertilizationManagementService: FertilizationManagementService) {
  }

  public getContentNRelatedPlanBooks(field: IField,
                                     nFertPlanBooks: INFertilizationPlanBook[],
                                     logfiles: IDatasetFieldMap[],
                                     baseFertPlanBooks: IBasicFertilisationPlanBooks[] | null | undefined): IContentNRelatedPlanBook[] {
    const nFertApplications = this._getNFertilizationApplicationValues(field, nFertPlanBooks, logfiles);
    const baseFertApplications = this._getBaseFertNRelatedContentFromPlanBooks(field, baseFertPlanBooks);
    if (baseFertApplications && baseFertApplications.length > 0) {
      nFertApplications.push(...baseFertApplications);
    }
    return nFertApplications;
  }

  public calculateAlreadyAppliedFromContentNPlanBooks(field: IField,
                                                      nFertPlanBooks: INFertilizationPlanBook[],
                                                      logfiles: IDatasetFieldMap[],
                                                      baseFertPlanBooks: IBasicFertilisationPlanBooks[]): number | null {
    const contentNPlanBooks = this.getContentNRelatedPlanBooks(field, nFertPlanBooks, logfiles, baseFertPlanBooks);
    if (!contentNPlanBooks || contentNPlanBooks.length <= 0) {
      return null;
    }
    const alreadyAppliedValues = contentNPlanBooks.map(planBook => {
      return this.getAlreadyApplied(planBook?.NFertilization?.Average, planBook?.ProductId);
    });
    return alreadyAppliedValues.reduce((prev, current) => prev + current, 0);
  }

  public getAlreadyApplied(average: number | null, fertilizerId: number | null): number {
    if (!average || !fertilizerId) {
      return 0;
    }
    const fertilizer = this.fertilizerStore.getFertilizer(fertilizerId);
    if (!fertilizer) {
      return 0;
    }
    return average * ((fertilizer?.Efficiency ?? 100) / 100);
  }

  public calculateProductAmountFromPlanBooks(planBooks: INFertilizationPlanBook[] | null): number | null {
    if (!planBooks || planBooks.length <= 0) {
      return null;
    }
    const amountValues = planBooks.map(planBook => {
      const fieldGeom = this.fieldStore.getFieldGeomById(planBook.FieldGeomId);
      const productAmount = this.fertilizationManagementService.calculateProductAmountN(
        planBook.ProductId,
        planBook.ApplicationRate,
        fieldGeom?.AreaHa ?? 0,
        planBook.Factor
      );
      return productAmount ?? 0;
    });
    return amountValues.reduce((prev, current) => prev + current, 0);
  }

  public calculateRemainingValue(alreadyApplied: number | null, needValue: number | null): number | null {
    if (!needValue) {
      return null;
    }
    if (!alreadyApplied || alreadyApplied === 0) {
      return needValue - alreadyApplied ?? 0;
    }
    return needValue - alreadyApplied;
  }

  public getNUpTakes(rasterStatistic: IFieldNutrientPlanningStatistic[]): IContentNRelatedPlanBookStatisticValue | null {
    return this._getStatisticValueFromRasterStatistic(rasterStatistic, 0);
  }

  public getNApplications(rasterStatistic: IFieldNutrientPlanningStatistic[]): IContentNRelatedPlanBookStatisticValue | null {
    return this._getStatisticValueFromRasterStatistic(rasterStatistic, 1);
  }

  public getApplicationRateName(cropKey: number, ecValue: number, operationMode?: IOperationMode, shortVersion?: boolean): string {
    const application = this.cropApplicationStore.GetApplication(cropKey, ecValue, operationMode);
    return this.getApplicationRateNameByApplication(application, shortVersion);
  }

  public getApplicationRateNameByApplication(application: number, shortVersion?: boolean): string {
    switch (application) {
      case -1:
        return `${this.translationStore.FindTranslationForSelectedLanguage('Global__Scan')}`;
      case 0:
        if (shortVersion) {
          return this.translationStore.FindTranslationForSelectedLanguage('Global__N0');
        } else {
          return `${this.translationStore.FindTranslationForSelectedLanguage('AgriConnect_ApplNum_0')}`;
        }
      case 1:
        if (shortVersion) {
          return this.translationStore.FindTranslationForSelectedLanguage('Global__N1');
        } else {
          return `${this.translationStore.FindTranslationForSelectedLanguage('Global__1st')} ${this.translationStore.FindTranslationForSelectedLanguage('Global__N_ApplNum')}`;
        }
      case 2:
        if (shortVersion) {
          return this.translationStore.FindTranslationForSelectedLanguage('Global__N2');
        } else {
          return `${this.translationStore.FindTranslationForSelectedLanguage('Global__2nd')} ${this.translationStore.FindTranslationForSelectedLanguage('Global__N_ApplNum')}`;
        }
      case 3:
        if (shortVersion) {
          return this.translationStore.FindTranslationForSelectedLanguage('Global__N3');
        } else {
          return `${this.translationStore.FindTranslationForSelectedLanguage('Global__3rd')} ${this.translationStore.FindTranslationForSelectedLanguage('Global__N_ApplNum')}`;
        }
      case 4:
        if (shortVersion) {
          return this.translationStore.FindTranslationForSelectedLanguage('Global__N4');
        } else {
          return `${this.translationStore.FindTranslationForSelectedLanguage('Global__4th')} ${this.translationStore.FindTranslationForSelectedLanguage('Global__N_ApplNum')}`;
        }
    }
    return '';
  }

  public getApplicationNScanRateName(): string {
    return `${this.translationStore.FindTranslationForSelectedLanguage('Global__Scan')}`;
  }

  private _getBaseFertNRelatedContentFromPlanBooks(field: IField, planBooks: IBasicFertilisationPlanBooks[]): IContentNRelatedPlanBook[] {
    if (!field || !planBooks || planBooks.length <= 0) {
      return [];
    }
    return planBooks.filter(x => x.FieldId === field.Id && !x.DeletedAt && x.BookedAt && x.Product_Id)
      .filter(x => {
        const currentFertilizer = this.fertilizerStore.getFertilizer(x.Product_Id);
        return currentFertilizer?.ContentN && this.campaignYearStore.isDateInSelectedCampaignYear(new Date(x.ApplicationDate));
      })
      .map(planBook => {
        const contentNStatistic = this.baseFertilizationService.calculateNElementByLeadElement(planBook.Product_Id, planBook.Statistic);
        let fertilization: IContentNRelatedPlanBookStatisticValue = null;
        if (contentNStatistic) {
          fertilization = {
            Min: contentNStatistic.Min,
            Max: contentNStatistic.Max,
            Average: contentNStatistic.Average
          };
        }
        const item: IContentNRelatedPlanBook = {
          PlanBookId: planBook.Id,
          FieldId: field.Id,
          OperationModeId: planBook.Operation_Mode?.Id,
          FieldGeomId: planBook.Field_Geom_Id,
          ProductId: planBook.Product_Id,
          CropTypeId: planBook.Croptype_Id,
          EcValue: 0,
          Date: new Date(planBook.ApplicationDate),
          NUptake: null,
          NFertilization: fertilization,
          SourceType: ContentNRelatedPlanBookSourceType.BaseFertPlanBook
        };
        return item;
      });
  }

  private _getNFertilizationApplicationValues(field: IField, planBooks: INFertilizationPlanBook[], logfiles: IDatasetFieldMap[]): IContentNRelatedPlanBook[] {
    let bookedLogFiles: IContentNRelatedPlanBook[] = [];
    if (logfiles && logfiles?.length > 0) {
      bookedLogFiles = logfiles
        .filter(logFile => !!logFile && !logFile.NPlanBook?.DeletedBy && !!logFile.NPlanBook?.BookedBy && logFile.Status === 1 && logFile.FieldGeomId && this.fieldStore.getFieldByFieldGeomId(logFile.FieldGeomId)?.Id === field.Id)
        .map((logFile) => {
          const item: IContentNRelatedPlanBook = {
            PlanBookId: logFile.NPlanBook.Id,
            FieldId: field.Id,
            OperationModeId: logFile.NPlanBook.OperationMode?.Id,
            FieldGeomId: logFile.FieldGeomId,
            ProductId: logFile.NPlanBook.ProductId,
            CropTypeId: logFile.CropType,
            EcValue: logFile.Ec,
            Date: new Date(logFile.DataSet.Created),
            NUptake: null,
            NFertilization: null,
            SourceType: ContentNRelatedPlanBookSourceType.LogFilePlanBook
          };
          return item;
        });
    }

    if (!planBooks || planBooks.length <= 0) {
      return bookedLogFiles;
    }

    const result = bookedLogFiles
      .map(contentNPlanBook => contentNPlanBook.PlanBookId)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((planBookId) => planBooks.find((planBook) => planBook.Id === planBookId))
      .filter((planBook) => !!planBook)
      .map((planBook) => {
        const logFiles = bookedLogFiles.filter((contentNLogFile) => contentNLogFile.PlanBookId === planBook.Id);
        const nUpTake = this.getNUpTakes(planBook.RasterStatistics);
        const nApplications = this.getNApplications(planBook.RasterStatistics);
        const item: IContentNRelatedPlanBook = {
          PlanBookId: planBook.Id,
          FieldId: field.Id,
          OperationModeId: planBook.OperationMode.Id,
          FieldGeomId: planBook.FieldGeomId,
          ProductId: planBook.ProductId,
          CropTypeId: planBook.MainCropId,
          EcValue: logFiles.map((logFile) => logFile.EcValue).Min(),
          Date: new Date(logFiles.map((logFile) => logFile.Date.valueOf()).Min()),
          NUptake: nUpTake,
          NFertilization: nApplications,
          SourceType: ContentNRelatedPlanBookSourceType.NFertPlanBook
        };
        return item;
      });

    bookedLogFiles.filter((l) => !l.PlanBookId).forEach((l) => {
      result.push(l);
    });

    planBooks
      .filter(planBook => !!planBook && planBook.FieldId === field.Id && !!planBook.BookedBy && planBook.OperationMode?.Key === 'Global__RateConst' && !result.map((r) => r.PlanBookId).includes(planBook.Id))
      .forEach(planBook => {
        const nUpTake = this.getNUpTakes(planBook.RasterStatistics);
        const nApplications = this.getNApplications(planBook.RasterStatistics);
        const item: IContentNRelatedPlanBook = {
          PlanBookId: planBook.Id,
          FieldId: field.Id,
          OperationModeId: planBook.OperationMode.Id,
          FieldGeomId: planBook.FieldGeomId,
          ProductId: planBook.ProductId,
          CropTypeId: planBook.MainCropId,
          EcValue: planBook.ECValue,
          Date: new Date(planBook.ApplicationDate),
          NUptake: nUpTake,
          NFertilization: nApplications,
          SourceType: ContentNRelatedPlanBookSourceType.NFertPlanBook
        };
        result.push(item);
      });
    return result;
  }

  private _getStatisticValueFromRasterStatistic(rasterStatistic: IFieldNutrientPlanningStatistic[], index: number): IContentNRelatedPlanBookStatisticValue | null {
    if (!rasterStatistic || rasterStatistic.length <= 0 || rasterStatistic.length - 1 < index) {
      return null;
    }
    return this._getStatisticValue(rasterStatistic[index]);
  }

  private _getStatisticValue(statistic: {
    Min: number,
    Max: number,
    Mean: number
  } | null | undefined): IContentNRelatedPlanBookStatisticValue | null {
    if (statistic && (statistic.Mean > 0 || statistic.Max > 0 || statistic.Min > 0)) {
      return {
        Min: statistic.Min,
        Max: statistic.Max,
        Average: statistic.Mean
      };
    }
    return null;
  }
}

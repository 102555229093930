import {NgModule}                         from '@angular/core';
import {CommonModule}                     from '@angular/common';
import {ApDynformsModule}                 from '../../ap-dynforms/ap-dynforms.module';
import {ApTranslationModule}              from '../../translation/ap-translation.module';
import {ButtonModule}                     from '@progress/kendo-angular-buttons';
import {ApUtilsModule}                    from '../../ap-utils';
import {ApPermissionModule}               from '../../ap-permission/ap-permission.module';
import {ApParameterValueComponent}        from './ap-parameter-value.component';
import {ApParameterValueHistoryComponent} from './ap-parameter-value-history.component';
import {ApParameterComponent}             from './ap-parameter.component';
import {ApDynComponentModule}             from '../../ap-dyncomponent/ap-dyncomponent.module';
import {ApResponsiveTextModule}           from '../../ap-responsive-text/ap-responsive-text.module';
import {LayoutModule}                     from '@progress/kendo-angular-layout';

const COMPONENTS = [
  ApParameterValueComponent,
  ApParameterValueHistoryComponent,
  ApParameterComponent
];

@NgModule({
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    ApDynformsModule,
    ApTranslationModule,
    ButtonModule,
    ApUtilsModule,
    ApPermissionModule,
    ApDynComponentModule,
    ApResponsiveTextModule,
    LayoutModule
  ],
  exports: COMPONENTS
})
export class ApParameterEntryComponentsModule {
}

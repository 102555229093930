import {IStateStore} from '../../ap-interface/interfaces/store';
import {Injectable}  from '@angular/core';
import {Store}       from '../index';
import {
  ApCustomTypes,
  ParameterDefinitionAdd,
  ParameterDefinitionCreate,
  ParameterDefinitionLoad,
  ParameterDefinitionLoadSuccess,
  ParameterDefinitionUpdate
}                    from 'invoker-transport';
import IParameterDefinition = Data.Parameter.IParameterDefinition;
import IGuid = System.IGuid;
import ParameterRefObjectType = Data.Parameter.Enums.ParameterRefObjectType;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IParameterDefinitionStore extends IStateStore<IParameterDefinition> {
}

@Injectable({providedIn: 'root'})
export class ParameterDefinitionStore extends Store<IParameterDefinitionStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
    });

    backend.registerObservable(ParameterDefinitionLoadSuccess).subscribe(d => {
      this.SetLoadFinishState();
      super.Mutate(s => s.data, () => d.Data);
    });
    backend.registerObservable(ParameterDefinitionAdd).subscribe(d => {
      super.Mutate(s => s.data, o => {
        o.Add(d.Data);
        return o;
      });
    });
  }

  public loadParameterDefinition(): void {
    this.SetLoadState();
    this.DispatchBackend(new ParameterDefinitionLoad([]));
  }

  public getParameterDefinitionByRefObjectType(refObjectType: ParameterRefObjectType): IParameterDefinition {
    return super.Listen(s => s.data).getValue().Find(_ => _.ParameterRefObjectType === refObjectType);
  }

  public getParameterDefinitionById(definitionId: IGuid): IParameterDefinition {
    return super.Listen(s => s.data).getValue().Find(e => e.Id === definitionId);
  }

  public updateParameterDefinition(parameterDefinition: IParameterDefinition): void {
    this.DispatchBackend(new ParameterDefinitionUpdate([
      {Name: 'parameterDefinition', Type: ApCustomTypes.Data_Parameter_ParameterDefinition, Value: parameterDefinition},
    ]));
  }

  public createParameterDefinition(parameterDefinition: IParameterDefinition): void {
    this.DispatchBackend(new ParameterDefinitionCreate([
      {Name: 'parameterDefinition', Type: ApCustomTypes.Data_Parameter_ParameterDefinition, Value: parameterDefinition},
    ]));
  }
}

import {Injectable}                   from '@angular/core';
import * as download                  from 'downloadjs';
import {
  NetTypes,
  NutrientPlanningFail,
  NutrientPlanningPackageSuccess,
  NutrientPlanningRasterStatisticFail,
  NutrientPlanningRasterStatisticLoadSuccess,
  NutrientPlanningRasterStatisticRefresh,
  NutrientPlanningStart,
  NutrientPlanningSuccess
}                                     from 'invoker-transport';
import {SafeBehaviorSubject}          from 'ts-tooling';
import {Store}                        from '../index';
import {IStateStore}                  from '../../ap-interface';
import {NutrientPlanningPackageStart} from '../../../../projects/invoker-transport/src/lib/actions/nutrients';
import {MapFactoryRequestor}          from '../../map-factory/map-factory-requestor';
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;
import IRasterCreationResult = Data.Nutrients.IRasterCreationResult;
import IGuid = System.IGuid;
import {ApSignalrService}             from '../../ap-core/services/ap-signalr.service';

interface INutrientStore extends IStateStore<IFieldNutrientPlanningStatistic> {
  fieldNutrientPlanningStatistic: IFieldNutrientPlanningStatistic[];
}

@Injectable({providedIn: 'root'})
export class NutrientStore extends Store<INutrientStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      fieldNutrientPlanningStatistic: [],
    });
    backend.registerObservable(NutrientPlanningPackageSuccess).subscribe(d => {
      MapFactoryRequestor.getPackage(d.Data).then(s => {
        download(s, `${d.Data}.zip`, 'application/zip');
      }).catch(err => console.warn(`download of package ${d.Data} fails`, err));
    });
    backend.registerObservable(NutrientPlanningSuccess).subscribe(_ => {
    });
    backend.registerObservable(NutrientPlanningRasterStatisticRefresh).subscribe(d => {
      const statisticsToAdd = JSON.parse(d.Data) as IFieldNutrientPlanningStatistic[];
      super.Mutate(s => s.fieldNutrientPlanningStatistic, o => {
        if (o.Find(i => i?.FieldGeomId === statisticsToAdd[0]?.FieldGeomId)) {
          o = o.RemoveAll(i => i.FieldGeomId === statisticsToAdd[0].FieldGeomId);
          o = o.AddRange(statisticsToAdd);
        } else {
          o = o.AddRange(statisticsToAdd);
        }
        return o;
      });
    });
    backend.registerObservable(NutrientPlanningRasterStatisticFail).subscribe(d => {
      const result = JSON.parse(d.Data) as IRasterCreationResult;
      super.Mutate(s => s.fieldNutrientPlanningStatistic, o => {
        if (o.Find(i => !!i && !!result && i.FieldGeomId === result.Id)) {
          const stat = o.Find(i => i.FieldGeomId === result.Id)[0];
          if (stat) {
            stat.ErrorText = result.Message;
            o = o.Replace(i => i.FieldGeomId === result.Id, stat);
          }
        } else {
          const err = {FieldGeomId: result.Id, ErrorText: result.Message} as IFieldNutrientPlanningStatistic;
          o = o.Add(err);
        }
        return o;
      });
    });
    backend.registerObservable(NutrientPlanningFail).subscribe(_ => {
    });
    backend.registerObservable(NutrientPlanningRasterStatisticLoadSuccess).subscribe(d => {
      const statisticsToAdd = d.Data as IFieldNutrientPlanningStatistic[];
      super.Mutate(s => s.fieldNutrientPlanningStatistic, o => {
        for (const stat of statisticsToAdd) {
          o.Replace(i => i.FieldGeomId === stat.FieldGeomId, stat);
        }
        return o;
      });
    });
  }

  public get FieldNutrientPlanningStatistic$(): SafeBehaviorSubject<IFieldNutrientPlanningStatistic[]> {
    return super.Listen(s => s.fieldNutrientPlanningStatistic);
  }

  public startNutrientPlanning(planId: IGuid, fieldGeomId: IGuid, leadNutrient: number, fertilizerId: number, operationMode: number, amount: number, amountPercent: number, minValue: number, hasMinValue: boolean, maxValue: number, hasMaxValue: boolean, defaultValue: number, constantValue: number): void {
    this.removeNutrientPlanningStatisticFromStore(planId);
    this.DispatchBackend(new NutrientPlanningStart([
      {Name: 'planId', Type: NetTypes.GUID, Value: planId},
      {Name: 'fieldGeomId', Type: NetTypes.GUID, Value: fieldGeomId},
      {Name: 'leadNutrient', Type: NetTypes.INT, Value: leadNutrient},
      {Name: 'fertilizerId', Type: NetTypes.INT, Value: fertilizerId},
      {Name: 'operationMode', Type: NetTypes.INT, Value: operationMode},
      {Name: 'amount', Type: NetTypes.FLOAT, Value: amount},
      {Name: 'amountPercent', Type: NetTypes.FLOAT, Value: amountPercent},
      {Name: 'minValue', Type: NetTypes.FLOAT, Value: minValue},
      {Name: 'hasMinValue', Type: NetTypes.BOOL, Value: hasMinValue},
      {Name: 'maxValue', Type: NetTypes.FLOAT, Value: maxValue},
      {Name: 'hasMaxValue', Type: NetTypes.BOOL, Value: hasMaxValue},
      {Name: 'defaultValue', Type: NetTypes.FLOAT, Value: defaultValue},
      {Name: 'constantValue', Type: NetTypes.FLOAT, Value: constantValue}
    ]));
  }

  public removeNutrientPlanningStatisticFromStore(planId: IGuid): void {
    super.Mutate(s => s.fieldNutrientPlanningStatistic, o => {
      return o.RemoveAll(i => i.PlanId === planId);
    });
  }

  public clearNutrientPlanningStatistic(): void {
    super.Mutate(s => s.fieldNutrientPlanningStatistic, () => []);
  }

  public createNutrientPlanningPackage(summaryId: IGuid): void {
    this.DispatchBackend(new NutrientPlanningPackageStart([
      {Name: 'summaryId', Type: NetTypes.GUID, Value: summaryId}
    ]));
  }
}

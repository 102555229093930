import {Injectable}                  from '@angular/core';
import {ApDateService, DateDiffType} from '../../ap-core/services/ap-date-service';
import {GetRoundNumericService}      from '../../ap-utils/service/get-round-numeric.service';
import {IGridStatus}                 from '../../ap-interface/interfaces/ap-grid-status.interface';
import {ApColumnState}               from '../../ap-interface/enums/ap-column-state.enum';
import INdiStatistic = Data.NDI.INdiStatistic;

/**
 * Service for n-index and n-uptake calculations
 */
@Injectable({
  providedIn: 'root'
})
export class NdiService {
  constructor(private dateService: ApDateService,
              private roundNumericService: GetRoundNumericService) {
  }

  /**
   * Generic check if n-index statistics are valid for further usage or calculations
   */
  public isValidNIndexStatistic(ndiStat: INdiStatistic): boolean {
    const cloudPercent = !!ndiStat ? this.getCloudsPercent(ndiStat.Histogram as any, ndiStat.Cells) : undefined;
    const createdDate = !!ndiStat?.Created ? new Date(ndiStat.Created) : undefined;
    const nIndexStatus = this.getNIndexStatus(cloudPercent, createdDate);
    const hasValidNdiData = !!createdDate ? createdDate.getFullYear() > 2000 : false;
    return hasValidNdiData && this.isNIndexStatusValid(nIndexStatus);
  }

  public getCloudsPercent(histogram: { [key: string]: number }, total: number): number {
    const value = 0.0;
    let targetValueCounter = 0;
    for (const key of Object.keys(histogram)) {
      const histGroup = parseFloat(key);
      if (isNaN(histGroup)) {
        continue;
      }
      if (histGroup === value) {
        targetValueCounter += histogram[key];
      }
    }
    return this.roundNumericService.roundAsNumber(targetValueCounter * 100 / total);
  }

  public getNIndexStatus(cloudPercent: number | undefined, createdDate: Date | undefined): IGridStatus {
    const dateTimeUtc = this.dateService.getUtc();
    const dateDiffInDays = Math.abs(this.dateService.getDateDiff(dateTimeUtc, createdDate, DateDiffType.Days));
    const status: IGridStatus = {state: undefined, class: undefined, description: undefined};
    if (!createdDate || !Number.isFinite(cloudPercent)) {
      status.state = ApColumnState.Grey;
      status.class = 'ap-status-grey';
      status.description = 'Global__NDI_Status_Desc_grey';
    } else if (cloudPercent > 10 || dateDiffInDays > 14) {
      status.state = ApColumnState.Red;
      status.class = 'ap-status-red';
      status.description = 'Global__NDI_Status_Desc_red';
    } else if (dateDiffInDays > 7 && dateDiffInDays <= 14) {
      status.state = ApColumnState.Yellow;
      status.class = 'ap-status-yellow';
      status.description = 'Global__NDI_Status_Desc_yellow';
    } else {
      status.state = ApColumnState.Green;
      status.class = 'ap-status-green';
      status.description = 'Global__NDI_Status_Desc_green';
    }
    return status;
  }

  public isNIndexStatusValid(nIndexStatus: IGridStatus): boolean {
    return nIndexStatus.state === ApColumnState.Green || nIndexStatus.state === ApColumnState.Yellow;
  }
}

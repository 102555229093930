import {AfterContentInit, Component, EventEmitter, OnDestroy, ViewChild} from '@angular/core';
import {
  ApDynformsComponent
}                                                                        from '../../../ap-dynforms/ap-dynforms.component';
import {
  ModalDialogStore
}                                                                        from '../../../stores/dialog/modal.dialog.store';
import {combineLatest, Subscription}                                     from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap}                     from 'rxjs/operators';
import {ObjectFactory}                                                   from 'ts-tooling';
import {FormGroup}                                                       from '@angular/forms';

@Component({
  selector: 'ap-modal-dialog',
  templateUrl: 'ap-modal-dialog.component.html'
})
export class ApModalDialogComponent implements AfterContentInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: false}) form: ApDynformsComponent;
  public title$ = this.modalDialogStore.Listen(s => s.title);
  public busy$ = this.modalDialogStore.Listen(s => s.busy);
  public message$ = this.modalDialogStore.Listen(s => s.message);
  public dialogOpened$ = this.modalDialogStore.Listen(s => s.show);
  public dynFormConfig$ = this.modalDialogStore.Listen(s => s.formConfig);
  public buttons$ = this.modalDialogStore.Listen(s => s.buttons);
  public formValid: EventEmitter<boolean>;

  private _subscriptions: Subscription[] = [];

  constructor(private modalDialogStore: ModalDialogStore) {
  }

  public ngAfterContentInit(): void {
    this._subscriptions.push(combineLatest([this.dialogOpened$, this.dynFormConfig$])
      .pipe(
        map(([o, f]) => o && !!f),
        filter((b: boolean) => b)
      )
      .subscribe((): void => {
        setTimeout((): void => {
          if (this.form) {
            this.formValid = this.form.validChanges;
            this._subscriptions.push(this.form.form$.pipe(
                filter((form: FormGroup) => !!form),
                mergeMap((form: FormGroup) => form.valueChanges),
                distinctUntilChanged((previous, current) => ObjectFactory.Equal(previous, current)),
              ).subscribe((value): void => {
                this.modalDialogStore.setFormValues(this.form.form.getRawValue());
                this.modalDialogStore.setFormChanges(value);
              })
            );
          }
        }, 1);
      }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  public close(key: string): void {
    const formValues = this.form ? this.form.form.getRawValue() : undefined;
    this.modalDialogStore.setShow(false);
    this.modalDialogStore.setFormValues(formValues);
    this.modalDialogStore.sendResult(key, formValues);
  }
}

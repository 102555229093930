/**
 * List of Layer Names
 */
export class ApPropertiesStrings {
  static readonly MachinePopup = 'machine_popup';
  static readonly SensorPointPopup = 'sensor_point_popup';
  static readonly Name = 'name';
  static readonly Speed = 'speed';
  static readonly Date = 'date';
  static readonly LayerName = 'layer_name';
  static readonly Sign = 'sign';
}

import {APP_CONFIGURATION} from '../ap-core/config';
import * as jwtDecode      from 'jwt-decode';
import * as ax             from 'axios';

export class MapFactoryAuthenticator {
  static userInfo = {user: '', password: ''};

  static async getHash(): Promise<string> {
    const existsHash = localStorage.getItem(APP_CONFIGURATION.MapFactory.StoreKey);
    if (existsHash) {
      const decoded = jwtDecode.default(existsHash) as any;
      const duration = Math.floor(Date.now() / 1000) - decoded.time;
      if (duration >= 3600) {
        localStorage.setItem(APP_CONFIGURATION.MapFactory.StoreKey, await MapFactoryAuthenticator.requestToken());
      }
    } else {
      localStorage.setItem(APP_CONFIGURATION.MapFactory.StoreKey, await MapFactoryAuthenticator.requestToken());
    }
    return localStorage.getItem(APP_CONFIGURATION.MapFactory.StoreKey);
  }

  private static async requestToken(): Promise<string> {
    try {
      const res = await ax.default({
        method: 'POST',
        url: `${APP_CONFIGURATION.MapFactory.Address}/requestToken`,
        data: MapFactoryAuthenticator.userInfo,
      });
      return res.data;
    } catch (e) {
      console.warn(`error on authenticate on map factory ${e.message}`);
      return '';
    }
  }
}

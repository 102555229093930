import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormGroup, ValidatorFn, Validators}                                                   from '@angular/forms';
import {combineLatest, of, Subscription}                       from 'rxjs';
import {filter, map, skip, take}                               from 'rxjs/operators';
import {ApDynformsValidator}                                   from '../../ap-dynforms/ap-dynforms-validator';
import {ApDynformsComponent}                                   from '../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsConfigCheckbox
}                                                              from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  ApDynformsConfigFieldset
}                                                              from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigNumerictextbox
}                                                              from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApDynformsConfigTextbox
}                                                              from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {MAP_PROJECTION}                                        from '../../ap-map';
import {ApPolygonEditLayer}                                    from '../../ap-map/layers/ap-polygon-edit.layer';
import {ApGuidUtil}                                            from '../../ap-utils';
import {NotifyStore}                                           from '../../stores/dialog/notify.store';
import {CropRotationStore}                                     from '../../stores/farm/crop.rotation.store';
import {FieldStore}                                            from '../../stores/farm/field.store';
import {FormStore}                                             from '../../stores/layout/form.store';
import {CampaignYearStore}                                     from '../../stores/login/campaignyear.store';
import {LoginStore}                                            from '../../stores/login/login.store';
import {MapStore}                                              from '../../stores/map/map.store';
import {TranslationStore}                                      from '../../stores/translation/translation.store';
import {EditorService}                                         from '../../map/components/edit/editor.service';
import {ApEditStyles}                                          from '../../ap-map/layers/ap-edit-styles';
import {FieldFactoryService}                                   from '../../factories/field-factory.service';
import {GetRoundNumericService}                                from '../../ap-utils/service/get-round-numeric.service';
import {SettingsStore}                                         from '../../stores/base-data/settings.store';
import {GeoJSON}                                               from 'ol/format';
import {MapInteraction, MapInteractions}                       from '../../ap-map/interactions';
import {ModalDialogStore}                                      from '../../stores/dialog/modal.dialog.store';
import {ModalDialogPresets}                                    from '../../stores/dialog/modal.dialog.presets';
import {defaultStyle}                                          from '../../ap-map/layers/ap-fields.style';
import IField = Data.FieldManagement.IField;
import IGuid = System.IGuid;
import {
  ApDynformsConfigComboBox
}                                                              from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {CropTypeStore}                                         from '../../stores/base-data/crop.types.store';
import IFieldCrop = Data.FieldManagement.IFieldCrop;
import {FieldArchiveStore}                                     from '../../stores/farm/field-archive.store';

export class SplitFieldData {
  Id: IGuid;
  FieldName: string;
  FieldNumber: string;
  FieldSubnumber: string;
  TakeOverCropRotation = true;
  AreaSize = 0;

  MainCrop: number;
  SecondCrop: number;
  MainCropYieldUnit: number;
  SecondCropYieldUnit: number;
  RemoveResidues = false;
  Note: string;

  constructor(fieldName: string, fieldNumber: string, fieldSubnumber: string) {
    this.FieldName = fieldName;
    this.FieldNumber = fieldNumber;
    this.FieldSubnumber = fieldSubnumber;
  }
}

/**
 * Component for the edit field
 */
@Component({
  selector: 'ap-split-field',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [formValidators]="formValidators"
                 [loading$]="fieldsSaving$">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_field_management_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">
          {{ 'Global__Cancel' | translate }}
        </button>
        <button id="button_field_management_edit_save"
                type="button"
                [disabled]="(formIsInvalid$ | async) || (sketchIsInvalid$ | async) || (splitIsInvalid$ | async) || (splitLineNotDrawn$ | async)"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">
          {{ 'Global__Save' | translate }}
        </button>
      </div>
    </ap-dynforms>
  `,
})
export class ApSplitFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  private static ToBigGeometryNotification: Data.Api.Validation.IApValidationResult = {
    Counter: 1,
    DisplayTimeout: 0,
    ErrorKey: 'Global__Geometry_To_Big_Validation',
    Level: Data.Api.Validation.ApValidationLevel.Error,
    Parameters: [],
    UserName: null,
    UserId: null,
  };
  private static IntegrateGeometryFailNotification: Data.Api.Validation.IApValidationResult = {
    Counter: 1,
    DisplayTimeout: 0,
    ErrorKey: 'Global__Geometry_NotIntegrated',
    Level: Data.Api.Validation.ApValidationLevel.Error,
    Parameters: [],
    UserName: null,
    UserId: null,
  };
  private static SplitLineIncorrectNotification: Data.Api.Validation.IApValidationResult = {
    Counter: 1,
    DisplayTimeout: 10,
    ErrorKey: 'Fieldeditor_Split_Incorrect',
    Level: Data.Api.Validation.ApValidationLevel.Warning,
    Parameters: [],
    UserName: null,
    UserId: null,
  };

  constructor(private formStore: FormStore,
              private fieldStore: FieldStore,
              private translationService: TranslationStore,
              private mapStore: MapStore,
              private cropRotationStore: CropRotationStore,
              private cropTypeStore: CropTypeStore,
              private campaignYearStore: CampaignYearStore,
              private loginStore: LoginStore,
              private notifyStore: NotifyStore,
              private fieldFactory: FieldFactoryService,
              private modalDialogStore: ModalDialogStore,
              private modalDialogPresets: ModalDialogPresets,
              private roundNumericService: GetRoundNumericService,
              private settingsStore: SettingsStore,
              private fieldArchiveStore: FieldArchiveStore) {
  }

  @ViewChild(ApDynformsComponent, {static: true}) public dynForm: ApDynformsComponent;
  @ViewChild('ComboBoxCropTypeItem', {static: true}) public comboBoxCropTypeItem: TemplateRef<any>;
  public data: { OldField: SplitFieldData, FieldOne: SplitFieldData, FieldTwo: SplitFieldData };
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;
  public isNewMode = false;
  public fieldsSaving$ = of(false);
  public formIsInvalid$ = new EventEmitter<boolean>(false);
  public sketchIsInvalid$ = EditorService.ValidSketch$.pipe(map(val => !val));
  public splitIsInvalid$ = EditorService.ValidSplitLine$.pipe(map(val => !val));
  public splitLineNotDrawn$ = EditorService.SplitLineDrawn$.pipe(map(val => !val));
  public close = new EventEmitter<void>(true);
  public formValidators: ApDynformsValidator<ValidatorFn>[] = [
    new ApDynformsValidator({
      validator: ApSplitFieldComponent.fieldNameOrNrRequired,
      errorKey: 'Global__Field_Name_Or_Nr_Required',
    }),
    new ApDynformsValidator({
      validator: ApSplitFieldComponent.fieldNameOrNrRequired2,
      errorKey: 'Global__Field_Name_Or_Nr_Required',
    }),
    new ApDynformsValidator({
      validator: ApSplitFieldComponent.yieldRequiredIfMainCropIsSet,
      errorKey: 'Yield__required'
    }),
    new ApDynformsValidator({
      validator: ApSplitFieldComponent.yieldRequiredIfSecondCropIsSet,
      errorKey: 'Yield__required_2'
    }),
    new ApDynformsValidator({
      validator: ApSplitFieldComponent.yieldRequiredIfMainCropIsSet2,
      errorKey: 'Yield__required_3'
    }),
    new ApDynformsValidator({
      validator: ApSplitFieldComponent.yieldRequiredIfSecondCropIsSet2,
      errorKey: 'Yield__required_4'
    }),
  ];
  private _subscriptions: Array<Subscription> = [];

  private static fieldNameOrNrRequired(frm: FormGroup): { mismatch: boolean } | null {
    const fieldName = frm.get('FieldOne_FieldName')?.value;
    const fieldNr = frm.get('FieldOne_FieldNumber')?.value;
    const fieldTNr = frm.get('FieldOne_FieldSubnumber')?.value;
    if (!fieldName && !fieldNr && !fieldTNr) {
      return {mismatch: true};
    }
    return null;
  }

  private static fieldNameOrNrRequired2(frm: FormGroup): { mismatch: boolean } | null {
    const fieldName = frm.get('FieldTwo_FieldName')?.value;
    const fieldNr = frm.get('FieldTwo_FieldNumber')?.value;
    const fieldTNr = frm.get('FieldTwo_FieldSubnumber')?.value;
    if (!fieldName && !fieldNr && !fieldTNr) {
      return {mismatch: true};
    }
    return null;
  }

  private static yieldRequiredIfMainCropIsSet(frm: FormGroup): { missingYield: boolean } | null {
    return ApSplitFieldComponent.internalCropValidation(frm, 'FieldOne_MainCrop');
  }

  private static yieldRequiredIfSecondCropIsSet(frm: FormGroup): { missingYield: boolean } | null {
    return ApSplitFieldComponent.internalCropValidation(frm, 'FieldOne_SecondCrop');
  }

  private static yieldRequiredIfMainCropIsSet2(frm: FormGroup): { missingYield: boolean } | null {
    return ApSplitFieldComponent.internalCropValidation(frm, 'FieldTwo_MainCrop');
  }

  private static yieldRequiredIfSecondCropIsSet2(frm: FormGroup): { missingYield: boolean } | null {
    return ApSplitFieldComponent.internalCropValidation(frm, 'FieldTwo_SecondCrop');
  }

  private static internalCropValidation(frm: FormGroup, fieldName: string): { missingYield: boolean } | null {
    const mainCrop = frm.get(fieldName)?.value;
    const mainCropYieldControl = frm.get(fieldName + 'YieldUnit');
    const mainCropYield = mainCropYieldControl?.value;
    const validationError = {missingYield: true};

    if (parseFloat(mainCrop) > 0 && !(parseFloat(mainCropYield) >= 0)) {
      mainCropYieldControl?.setErrors(validationError);
      return validationError;
    }

    return null;
  }

  public ngOnInit(): void {
    this.fieldsSaving$ = this.fieldStore.Listen(s => s.saving);

    this._subscriptions.push(EditorService.ValidSketch$
      .subscribe(integrated => {
        if (integrated) {
          this.notifyStore.removeMessage(ApSplitFieldComponent.IntegrateGeometryFailNotification);
          this.mapStore.Layers.PolygonEditViewLayer.setStyle(defaultStyle);
        } else {
          this.notifyStore.addMessage(ApSplitFieldComponent.IntegrateGeometryFailNotification);
          this.mapStore.Layers.PolygonEditViewLayer.setStyle(ApEditStyles.invalidStyle);
        }
      }));

    this._subscriptions.push(EditorService.ValidSplitLine$
      .subscribe(splitCorrect => {
        if (splitCorrect) {
          this.notifyStore.removeMessage(ApSplitFieldComponent.SplitLineIncorrectNotification);
          this.mapStore.Layers.PolygonEditViewLayer.setStyle(defaultStyle);
        } else {
          this.notifyStore.addMessage(ApSplitFieldComponent.SplitLineIncorrectNotification);
          this.mapStore.Layers.PolygonEditViewLayer.setStyle(ApEditStyles.invalidStyle);
        }
      }));

    this._subscriptions.push(this.dynForm.initialized.subscribe(() => {
      this._subscriptions.push(this.dynForm.form.valueChanges.subscribe(() => {
        this.formIsInvalid$.emit(!this.dynForm.form.valid);
      }));
      this._subscriptions.push(this.dynForm.form.get('FieldOne_MainCrop')?.valueChanges?.subscribe(_ => {
        this.onMainCropChanged('FieldOne_');
      }));
      this._subscriptions.push(this.dynForm.form.get('FieldOne_SecondCrop')?.valueChanges?.subscribe(_ => {
        this.onSecondCropChanged('FieldOne_');
      }));

      this._subscriptions.push(this.dynForm.form.get('FieldTwo_MainCrop')?.valueChanges?.subscribe(_ => {
        this.onMainCropChanged('FieldTwo_');
      }));
      this._subscriptions.push(this.dynForm.form.get('FieldTwo_SecondCrop')?.valueChanges?.subscribe(_ => {
        this.onSecondCropChanged('FieldTwo_');
      }));
      this._subscriptions.push(this.dynForm.form.get('FieldOne_TakeOverCropRotation')?.valueChanges?.subscribe(val => {
        if (val === true) {
          this.onTakeOverCropRotation('FieldOne_');
        }
      }));
      this._subscriptions.push(this.dynForm.form.get('FieldTwo_TakeOverCropRotation')?.valueChanges?.subscribe(val => {
        if (val === true) {
          this.onTakeOverCropRotation('FieldTwo_');
        }
      }));
    }));

    this._subscriptions.push(this.mapStore.Listen(s => s.splitFieldResult).subscribe(x => {
      if (x != null && x.length === 2) {
        this.dynForm?.form?.get('FieldOne_Area')?.setValue(this.calculateAreaSizeStringByValue(x[0][1]));
        this.dynForm?.form?.get('FieldTwo_Area')?.setValue(this.calculateAreaSizeStringByValue(x[1][1]));
      }
    }));

    this._subscriptions.push(combineLatest([
      this.campaignYearStore.Listen((s) => s.selectedYear),
      this.loginStore.Listen((s) => s.farm.selectedFarm),
    ]).pipe(
      filter(([y, f]) => !!y && !!f),
      take(1),
    )
      .subscribe(([_, _1]) => {
        this.caption = this.translationService.FindTranslationForSelectedLanguage('Global__Split');
        this.formBuilder();
      }));

    // Clear value each time we enter the merge form
    this.fieldStore.clearFieldPlanBooksResult();
    this._subscriptions.push(this.modalDialogStore.Listen(s => s.result).pipe(skip(1)).subscribe(c => {
      if (c.key === 'confirm') {
        this.submitLogic();
      }
    }));

    this._subscriptions.push(this.fieldStore.Listen(s => s.fieldPlanBooksResult).subscribe(planBooks => {
      if (planBooks != null) {
        if (planBooks === true) {
          this.modalDialogStore.setModalDialogData(this.modalDialogPresets.ExistPlanBooksDialog(
            {title: 'HdrConfirm', message: 'Global__Dialog_Message_CheckPlanBooks'}));
        } else {
          this.submitLogic();
        }

        this.fieldStore.clearFieldPlanBooksResult();
      }
    }));
  }

  public ngAfterViewInit(): void {
    setTimeout(_ => {
      this.onTakeOverCropRotation('FieldOne_');
      this.onTakeOverCropRotation('FieldTwo_');
    }, 1);
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((d) => {
      d.unsubscribe();
    });
  }

  public formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        config: [
          new ApDynformsConfigNumerictextbox({
            key: 'FieldOne_FieldNumber',
            label: 'Global__FieldNumber',
            value: this.numberOrZero(this.data.FieldOne.FieldNumber),
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldOne_FieldSubnumber',
            label: 'Planning_Fieldpart',
            value: this.numberOrZero(this.data.FieldOne.FieldSubnumber),
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'FieldOne_FieldName',
            label: 'Global__FieldName',
            value: this.data.FieldOne.FieldName,
            formErrors: ['Global__Field_Name_Or_Nr_Required']
          }),
          new ApDynformsConfigCheckbox({
            key: 'FieldOne_TakeOverCropRotation',
            label: 'Global_TakeOverCropRotation',
            value: this.data.FieldOne.TakeOverCropRotation,
          }),
          new ApDynformsConfigTextbox({
            disabled: true,
            key: 'FieldOne_Area',
            label: 'Global__Area_Unit',
            validators: [
              new ApDynformsValidator({
                errorKey: 'Settings__Msg_Vali_Value_Required',
                validator: Validators.min(1),
              }),
            ],
            value: this.data.FieldOne.AreaSize,
          })
        ],
        key: 'field1',
        legend: 'Global__Field_One',
      }),
      new ApDynformsConfigFieldset({
        key: 'field1Crops',
        legend: (this.translationService.FindTranslationForSelectedLanguage('Global__Field_One') + ' ' + this.translationService.FindTranslationForSelectedLanguage('Docu_Ini__HarvestYear') + ' ' + this.campaignYearStore.getSelectedCampaignYear().Year),
        config: [
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'FieldOne_MainCrop',
            label: 'MainCrop',
            options: this._availableCropTypes(this.data.FieldOne.MainCrop),
            textField: 'Description',
            value: this.data.FieldOne.MainCrop,
            valueField: 'Id',
            valuePrimitive: true
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldOne_MainCropYieldUnit',
            label: 'Global__YieldUnit',
            value: this.data.FieldOne.MainCropYieldUnit,
            min: 0,
            disabled: !(this.data.FieldOne.MainCrop > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: unknown) => !(f['FieldOne_MainCrop'] > 0))),
            formErrors: ['Yield__required'],
            validators: [
              new ApDynformsValidator({
                validator: ApSplitFieldComponent.yieldRequiredIfMainCropIsSet,
                errorKey: 'Yield__required'
              })
            ]
          }),
          new ApDynformsConfigCheckbox({
            key: 'FieldOne_RemoveResidues',
            label: 'Nutrients_Pages_Popups__RemoveResidues',
            value: this.data.FieldOne.RemoveResidues,
          }),
          new ApDynformsConfigTextbox({
            key: 'FieldOne_Note',
            label: 'Global__Fieldcrop_Notes',
            value: this.data.FieldOne.Note
          }),
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'FieldOne_SecondCrop',
            label: 'SecondCrop',
            options: this._availableCropTypes(this.data.FieldOne.SecondCrop),
            textField: 'Description',
            validators: [],
            value: this.data.FieldOne.SecondCrop,
            valueField: 'Id',
            valuePrimitive: true
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldOne_SecondCropYieldUnit',
            label: 'Global__Yield_Removal_Unit',
            value: this.data.FieldOne.SecondCropYieldUnit,
            min: 0,
            disabled: !(this.data.FieldOne.SecondCrop > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: unknown) => !(f['FieldOne_SecondCrop'] > 0))),
            formErrors: ['Yield__required_2'],
            validators: [
              new ApDynformsValidator({
                validator: ApSplitFieldComponent.yieldRequiredIfSecondCropIsSet,
                errorKey: 'Yield__required_2'
              })
            ]
          })
        ]
      }),
      new ApDynformsConfigFieldset({
        config: [
          new ApDynformsConfigNumerictextbox({
            key: 'FieldTwo_FieldNumber',
            label: 'Global__FieldNumber',
            value: this.numberOrZero(this.data.FieldTwo.FieldNumber),
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldTwo_FieldSubnumber',
            label: 'Planning_Fieldpart',
            value: this.numberOrZero(this.data.FieldTwo.FieldSubnumber),
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'FieldTwo_FieldName',
            label: 'Global__FieldName',
            value: this.data.FieldTwo.FieldName,
            formErrors: ['Global__Field_Name_Or_Nr_Required']
          }),
          new ApDynformsConfigCheckbox({
            key: 'FieldTwo_TakeOverCropRotation',
            label: 'Global_TakeOverCropRotation',
            value: this.data.FieldTwo.TakeOverCropRotation,
          }),
          new ApDynformsConfigTextbox({
            disabled: true,
            key: 'FieldTwo_Area',
            label: 'Global__Area_Unit',
            validators: [
              new ApDynformsValidator({
                errorKey: 'Settings__Msg_Vali_Value_Required',
                validator: Validators.min(1),
              }),
            ],
            value: this.data.FieldTwo.AreaSize,
          }),
        ],
        key: 'field2',
        legend: 'Global__Field_Two',
      }),
      new ApDynformsConfigFieldset({
        key: 'field2Crops',
        legend: (this.translationService.FindTranslationForSelectedLanguage('Global__Field_Two') + ' ' + this.translationService.FindTranslationForSelectedLanguage('Docu_Ini__HarvestYear') + ' ' + this.campaignYearStore.getSelectedCampaignYear().Year),
        config: [
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'FieldTwo_MainCrop',
            label: 'MainCrop',
            options: this._availableCropTypes(this.data.FieldTwo.MainCrop),
            textField: 'Description',
            value: this.data.FieldTwo.MainCrop,
            valueField: 'Id',
            valuePrimitive: true
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldTwo_MainCropYieldUnit',
            label: 'Global__YieldUnit',
            value: this.data.FieldTwo.MainCropYieldUnit,
            min: 0,
            disabled: !(this.data.FieldTwo.MainCrop > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: unknown) => !(f['FieldTwo_MainCrop'] > 0))),
            formErrors: ['Yield__required_3'],
            validators: [
              new ApDynformsValidator({
                validator: ApSplitFieldComponent.yieldRequiredIfMainCropIsSet2,
                errorKey: 'Yield__required'
              })
            ]
          }),
          new ApDynformsConfigCheckbox({
            key: 'FieldTwo_RemoveResidues',
            label: 'Nutrients_Pages_Popups__RemoveResidues',
            value: this.data.FieldTwo.RemoveResidues,
          }),
          new ApDynformsConfigTextbox({
            key: 'FieldTwo_Note',
            label: 'Global__Fieldcrop_Notes',
            value: this.data.FieldTwo.Note
          }),
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'FieldTwo_SecondCrop',
            label: 'SecondCrop',
            options: this._availableCropTypes(this.data.FieldTwo.SecondCrop),
            textField: 'Description',
            validators: [],
            value: this.data.FieldTwo.SecondCrop,
            valueField: 'Id',
            valuePrimitive: true
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldTwo_SecondCropYieldUnit',
            label: 'Global__Yield_Removal_Unit',
            value: this.data.FieldTwo.SecondCropYieldUnit,
            min: 0,
            disabled: !(this.data.FieldTwo.SecondCrop > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: unknown) => !(f['FieldTwo_SecondCrop'] > 0))),
            formErrors: ['Yield__required_4'],
            validators: [
              new ApDynformsValidator({
                validator: ApSplitFieldComponent.yieldRequiredIfSecondCropIsSet2,
                errorKey: 'Yield__required_2'
              })
            ]
          })
        ]
      }),
    ];
  }

  private mapFormValues(target: IField, fieldPrefix: string, featureIndex: number): IField {
    target.Id = ApGuidUtil.generateNewGuid();
    target.FieldName = this.dynForm.form.get(fieldPrefix + 'FieldName').value;
    target.FieldNumber = this.dynForm.form.get(fieldPrefix + 'FieldNumber').value;
    target.FieldSubnumber = this.dynForm.form.get(fieldPrefix + 'FieldSubnumber').value;
    target.FarmId = this.loginStore.SelectedFarmId;
    target.ValidFrom = this.campaignYearStore.getSelectedCampaignYear().DefaultStart;

    const format = new GeoJSON({
      dataProjection: ApPolygonEditLayer.projection,
      featureProjection: MAP_PROJECTION,
    });
    target.DefaultGeom.Geom = format.writeGeometry(this.mapStore.Layers.PolygonEditViewLayer.Features[featureIndex].getGeometry()) as any;
    target.DefaultGeom.FarmId = this.loginStore.SelectedFarmId;
    target.DefaultGeom.FieldId = target.Id;
    target.DefaultGeom.ValidFrom = target.ValidFrom;
    target.FieldGeoms.Clear();
    target.FieldGeoms.Add(target.DefaultGeom);

    const mainCropId = this.dynForm.form.get(fieldPrefix + 'MainCrop').value;
    const secondCropId = this.dynForm.form.get(fieldPrefix + 'SecondCrop').value;
    if (mainCropId > 0) {
      const cropType = this._availableCropTypes(mainCropId).Find((ct) => ct?.Id === mainCropId);
      const cropTypeSecond = this._availableCropTypes(secondCropId).Find((ct) => ct?.Id === secondCropId);

      target.FieldCrops[0] = ({
        Cropkey: cropType ? cropType.CropKey : null,
        CroptypeId: mainCropId,
        CroptypeSecondId: secondCropId,
        MainSecondCropType: cropTypeSecond ? cropTypeSecond.CropKey : null,
        ExpectedYield: cropType ? this.dynForm.form.get(fieldPrefix + 'MainCropYieldUnit').value : null,
        ExpectedYieldSecond: cropTypeSecond ? this.dynForm.form.get(fieldPrefix + 'SecondCropYieldUnit').value : null,
        StrawHarvested: this.dynForm.form.get(fieldPrefix + 'RemoveResidues').value,
        Note: this.dynForm.form.get(fieldPrefix + 'Note').value
      } as IFieldCrop);
    }

    return target;
  }

  public onSubmitClick(): void {
    this.fieldStore.checkFieldsPlanBook([this.data.OldField.Id]);
  }

  private submitLogic(): void {
    this.loginStore.setLongOperationStatus(true);
    try {
      const newFieldOne: IField = this.mapFormValues(this.fieldFactory.CreateNew(), 'FieldOne_', 0);
      const newFieldTwo: IField = this.mapFormValues(this.fieldFactory.CreateNew(), 'FieldTwo_', 1);
      const cropRotationTransfer = [this.dynForm.form.get('FieldOne_TakeOverCropRotation').value, this.dynForm.form.get('FieldTwo_TakeOverCropRotation').value];

      MapInteraction.Remove(MapInteractions.MODIFY);
      this.fieldStore.splitFields(this.data.OldField.Id, [newFieldOne, newFieldTwo], cropRotationTransfer);

      this._subscriptions.Add(this.fieldStore.Listen(s => s.splitSuccess).subscribe(result => {
        if (result) {
          setTimeout(() => this.onCancelClick(), 0);
          this.loginStore.setLongOperationStatus(false);
          this.fieldStore.loadFields(this.loginStore.CampaignYearStore.getSelectedCampaignYear().DefaultStart);
          this.cropRotationStore.LoadFieldCropRotation();
          this.cropRotationStore.LoadFieldCropRotationPast();
          this.fieldArchiveStore.loadFieldArchive(this.loginStore.CampaignYearStore.getSelectedCampaignYear().DefaultStart);
        }
      }));

      this._subscriptions.Add(this.fieldStore.Listen(s => s.splitFailed).subscribe(result => {
        if (result) {
          setTimeout(() => this.onCancelClick(), 0);
          this.loginStore.setLongOperationStatus(false);
          this.fieldStore.loadFields(this.loginStore.CampaignYearStore.getSelectedCampaignYear().DefaultStart);
          this.fieldArchiveStore.loadFieldArchive(this.loginStore.CampaignYearStore.getSelectedCampaignYear().DefaultStart);
        }
      }));
    } catch (e) {
      console.warn(e);
      this.loginStore.setLongOperationStatus(false);
    }
  }

  public onCancelClick(): void {
    this.mapStore.Editor.CloseEditor(this.fieldStore);
    this.formStore.closeForm();
  }

  private calculateAreaSizeStringByValue(value: number): string {
    return this.roundNumericService.round(value, this.settingsStore.FirstSetting.DigitsAfterDecimalPoint);
  }

  private _availableCropTypes =
    (id?: number) => this.cropTypeStore.Listen((s) => s.data).getValue().filter((c) => c.Selected || c.Id === id)

  private numberOrZero(value: string): number {
    return isNaN(+value) ? 0 : +value;
  }

  private onMainCropChanged(fieldNamePrefix: string): void {
    const selectedCropId = this.dynForm?.form?.get(fieldNamePrefix + 'MainCrop')?.value;
    const yieldDefault = selectedCropId ? this.cropTypeStore.CropTypes.Find(c => c.Id === selectedCropId)?.ExpectedYield : undefined;
    this.dynForm?.form?.get(fieldNamePrefix + 'MainCropYieldUnit')?.setValue(yieldDefault);
  }

  private onSecondCropChanged(fieldNamePrefix: string): void {
    const selectedCropId = this.dynForm?.form?.get(fieldNamePrefix + 'SecondCrop')?.value;
    const yieldDefault = selectedCropId && this.cropTypeStore.CropTypes.Find(c => c.Id === selectedCropId) ? 0 : undefined;
    this.dynForm?.form?.get(fieldNamePrefix + 'SecondCropYieldUnit')?.setValue(yieldDefault);
  }

  private onTakeOverCropRotation(fieldNamePrefix: string): void {
    this.dynForm?.form?.get(fieldNamePrefix + 'MainCrop')?.setValue(this.data.OldField.MainCrop);
    this.dynForm?.form?.get(fieldNamePrefix + 'MainCropYieldUnit')?.setValue(this.data.OldField.MainCropYieldUnit);
    this.dynForm?.form?.get(fieldNamePrefix + 'SecondCrop')?.setValue(this.data.OldField.SecondCrop);
    this.dynForm?.form?.get(fieldNamePrefix + 'SecondCropYieldUnit')?.setValue(this.data.OldField.SecondCropYieldUnit);
    this.dynForm?.form?.get(fieldNamePrefix + 'RemoveResidues')?.setValue(this.data.OldField.RemoveResidues);
    this.dynForm?.form?.get(fieldNamePrefix + 'Note')?.setValue(this.data.OldField.Note);
  }
}

import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2} from '@angular/core';
import {ApTooltipService}                                                                from './ap-tooltip.service';

/**
 * Renders a Tooltip on a HTML Element that moved with the Cursor and has a Delay
 */
@Directive({
  selector: '[aptooltip]'
})
export class ApTooltipDirective implements AfterViewInit, OnDestroy {

  /**
   * indicates that the tooltip is currently displayed
   */
  private isShowingTooltip = false;
  /**
   * tooltip's text
   */
  private tooltipText = '';

  /**
   * based on css-setting (pointer-events property)
   * an element is marked as disabled
   * in order to make tooltip work:
   * - need to re-enable the element (to get pointer events)
   * - but suppressing all events to make it appear disabled
   */
  private disabled = false;

  /**
   * The Text that was display in the Tooltip Content
   */
  @Input()
  set aptooltip(text: string) {
    this.tooltipText = text;
  }

  /**
   * if true, tooltip is always displayed on hovering with mouse cursor
   * if false, tooltip is displayed on active text ellipsis
   */
  @Input()
  public perpetual = false;

  /**
   * constructor for some injections.
   */
  constructor(private renderer: Renderer2, private element: ElementRef, private apTooltipService: ApTooltipService) {
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    // to suppress click event and make element appear disabled.
    if (this.disabled) {
      event.stopPropagation();
    }
  }

  /**
   * Triggers showing the tooltip if necessary.
   */
  @HostListener('mouseenter')
  onMouseEnter(): void {
    if ((this.perpetual || this.element.nativeElement.offsetWidth < this.element.nativeElement.scrollWidth) &&
      this.tooltipText && this.tooltipText !== '') {
      this.isShowingTooltip = true;
      this.apTooltipService.Show(this.element, this.tooltipText);
    }
  }

  /**
   * Triggers hiding the tooltip.
   */
  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hideTooltip();
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.hideTooltip();
  }

  /**
   * some magic to have tooltips on disabled elements
   */
  ngAfterViewInit(): void {
    const hasNoPointerEvents = window.getComputedStyle(this.element.nativeElement, null)
      .getPropertyValue('pointer-events') === 'none';
    const isDisabledByAttribute = this.element.nativeElement.getAttribute('disabled') != null;

    // This only works for kendo components which are disabled by css and NOT by [disabled] attribute in element.
    if (hasNoPointerEvents && !isDisabledByAttribute) {
      this.renderer.setStyle(this.element.nativeElement,
        'pointer-events',
        'auto');
    }
  }

  ngOnDestroy(): void {
    this.hideTooltip();
  }

  private hideTooltip(): void {
    if (this.isShowingTooltip) {
      this.apTooltipService.Hide(this.element);
    }
  }
}

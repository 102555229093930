import ICampaignYear = Data.Authentication.ICampaignYear;
import {IAction, IApInvokerArguments} from '../../interfaces';

/**
 * Campaign year Actions
 */
export enum CampaignYearActions {
  CAMPAIGN_YEAR_LOAD = '[campaign_year] load',
  CAMPAIGN_YEAR_LOAD_SUCCESS = '[campaign_year] load success',
  CAMPAIGN_YEAR_LOAD_FAIL = '[campaign_year] load fail',
  CAMPAIGN_YEAR_SELECT = '[campaign_year] select',
  CAMPAIGN_YEAR_READ_FINISH = '[campaign_year] read finish',
  INFORM_CAMPAIGN_YEAR_CHANGE = '[campaign_year] inform change',
}

/**
 * start to Load the campaign years for a farm
 *
 * @example
 * new CampaignYearLoad([]);
 */
export class CampaignYearLoad implements IAction {
  readonly type = CampaignYearActions.CAMPAIGN_YEAR_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Write the loaded Campaignyears to the State
 */
export class CampaignYearLoadSuccess implements IAction {
  readonly type = CampaignYearActions.CAMPAIGN_YEAR_LOAD_SUCCESS;

  constructor(public payload: ICampaignYear[]) {
  }
}

/**
 * fail to Load the campaign Years
 */
export class CampaignYearLoadFail implements IAction {
  readonly type = CampaignYearActions.CAMPAIGN_YEAR_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * select a campaign year as current campaign year
 */
export class CampaignYearSelect implements IAction {
  readonly type = CampaignYearActions.CAMPAIGN_YEAR_SELECT;

  constructor(public payload: ICampaignYear) {
  }
}

export class CampaignYearReadFinish implements IAction {
  readonly type = CampaignYearActions.CAMPAIGN_YEAR_READ_FINISH;
}

/**
 * inform the Backend that a new CampaignYear wants to select by the Client.
 *
 * @example
 * new InformCampaignYearChange([
 *  {
 *    Name: 'campaignYear',
 *    Type: ApCustomTypes.Data_Authentication_CampaignYear,
 *    Value: {
 *      CopiedFields: [1,2],
 *      DefaultEnd: new Date(),
 *      DefaultStart: new Date(),
 *      FarmId: 1,
 *      FieldCount: 2,
 *      Id: 1,
 *      Year: 2021,
 *    },
 *  }
 * ]);
 */
export class InformCampaignYearChange implements IAction {
  readonly type = CampaignYearActions.INFORM_CAMPAIGN_YEAR_CHANGE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {Observable}                                  from 'rxjs';
import {ApUtilService}                               from '../../ap-utils/service/ap-util.service';
import {ApDynformsValidator}                         from '../ap-dynforms-validator';
import {AsyncValidatorFn, ValidatorFn}               from '@angular/forms';

export class ApDynformsConfigDateRange extends ApDynformsConfigBase<{ start: Date, end: Date }> {
  controlType = ApDynformsControltype.DateRange;
  dateCellDyer: (date: Date) => boolean;
  minDate$: Observable<Date>;
  maxDate$: Observable<Date>;
  format: string;
  labelStart: string;
  labelEnd: string;

  constructor(options: {
    value?: { start: Date, end: Date },
    value$?: Observable<{ start: Date, end: Date }>,
    key?: string,
    labelStart?: string,
    labelEnd?: string,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    minDate?: Date | Observable<Date>,
    maxDate?: Date | Observable<Date>,
    format?: string,
    dateCellDyer?: (date: Date) => boolean,
    dependsOn?: string[],
    formErrors?: string[],
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    cssClass?: string,
  }) {
    super(options);
    this.dateCellDyer = options.dateCellDyer ? options.dateCellDyer : () => false;
    this.minDate$ = ApUtilService.asObservable(options.minDate);
    this.maxDate$ = ApUtilService.asObservable(options.maxDate);
    this.format = options.format || '';
    this.labelStart = options.labelStart;
    this.labelEnd = options.labelEnd;
    if (this.value === undefined) {
      this.value = {
        start: null,
        end: null
      };
    }
  }
}


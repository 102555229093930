import {Injectable}                                   from '@angular/core';
import Polygon                                        from 'ol/geom/Polygon';
import {LineString}                                   from 'ol/geom';
import {getArea, getLength}                           from 'ol/sphere';
import {MapStore}                                     from '../../../stores/map/map.store';
import Feature                                        from 'ol/Feature';
import Overlay                                        from 'ol/Overlay';
import {toStringXY}                                   from 'ol/coordinate';
import {GeometryEditor}                               from '../../../ap-map/geometry.editor';
import {ApEditStyles}                                 from '../../../ap-map/layers/ap-edit-styles';
import {MapInteraction, MapInteractions}              from '../../../ap-map/interactions';
import {ApMapInstance, LayerNames}                    from '../../../ap-map';
import {GeometryEditorTool}                           from './ap-edit-tooltype';
import {FieldStore}                                   from '../../../stores/farm/field.store';
import GeometryType                                   from 'ol/geom/GeometryType';
import {Draw}                                         from 'ol/interaction';
import {ApGuidUtil}                                   from '../../../ap-utils';
import OverlayPositioning                             from 'ol/OverlayPositioning';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {filter}                                       from 'rxjs/operators';
import {defaultStyle, selectedStyle}                  from '../../../ap-map/layers/ap-fields.style';
import {ObjectFactory}                                from 'ts-tooling';
import LinearRing                                     from 'ol/geom/LinearRing';
import {LanguageStore}                                from '../../../stores/translation/language.store';
import {DrawEvent}                                    from 'ol/interaction/Draw';
import {NotifyStore}                                  from '../../../stores/dialog/notify.store';
import IField = Data.FieldManagement.IField;
import {ApDefined}                                    from '../../../ap-utils/static/ap-defined';

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  public static ValidSketch$ = new BehaviorSubject(true);
  public static ValidSplitLine$ = new BehaviorSubject(true);
  public static SplitLineDrawn$ = new BehaviorSubject(false);

  constructor(public mapStore: MapStore,
              private fieldStore: FieldStore,
              private languageStore: LanguageStore,
              private notifyStore: NotifyStore) {
    this.mapStore.Editor.startListening();
    this.mapStore.Editor.onDrawNew.subscribe(() => {
      this.mapStore.Editor.LockFeature(false);
      this.createMeasureTooltip();
      this.createHelpTooltip();
      this.startDrawNew();
    });
    this.mapStore.Editor.onDrawNewEnd.subscribe(() => {
      this.unsubscribeMouseOverFeature();
      this.removeTooltip();
    });
    this.mapStore.Editor.onSplit.subscribe(() => {
      this.mapStore.Editor.LockFeature(false);
      this.createHelpTooltip();
      this.enableSplit();
    });
    this.mapStore.Editor.onSplitEnd.subscribe(() => {
      EditorService.ValidSketch$.next(true);
      EditorService.ValidSplitLine$.next(true);
      EditorService.SplitLineDrawn$.next(false);
      this.unsubscribeMouseOverFeature();
      this.removeTooltip();
    });
    this.mapStore.Editor.onModify.subscribe(() => {
      this.enableModifyNewVertex([]);
    });
    this.mapStore.Editor.onDrawNewHole.subscribe(() => {
      this.enableDrawHole();
    });
    this.mapStore.Editor.onDeleteHole.subscribe(() => {
      this.endDrawHole();
      this.enableDeleteHole();
    });
    this.mapStore.Editor.onCloseEditor.subscribe(() => {
      this.fieldStore.Mutate(s => s.editFields, () => []);
      EditorService.removeDrawInteraction();
      EditorService.removeSnapInteraction();
      this.mapStore.Layers.PolygonEditLayer.RemoveSelectedFeatures();
      this.mapStore.Layers.PolygonEditLayer.clear();
      this.mapStore.Layers.PolygonEditViewLayer.clear();
      this.sketch = null;
    });
    this.mapStore.Editor.onDeleteSketchGeometry.subscribe(() => {
      (MapInteraction.FindByName(MapInteractions.DRAW) as Draw)?.abortDrawing();
    });
  }

  /**
   * Currently drawn feature.
   */
  private sketch: Feature;
  /**
   * The help tooltip element.
   */
  private helpTooltipElement;
  /**
   * Overlay to show the help messages.
   */
  private helpTooltip: Overlay;
  /**
   * The measure tooltip element.
   */
  private measureTooltipElement;
  /**
   * Overlay to show the measurement.
   */
  private measureTooltip: Overlay;
  private listenPolygonEditChange: Subscription = null;
  private listenIsEditChange: Subscription = null;
  private listenActiveToolChange: Subscription = null;
  private onMouseOverFeature: Subscription = null;

  /**
   * calculate the Area of a Polygon in ha or m² and get the value as formatted text or the number value
   *
   * @param polygon the Polygon Geometry
   * @param onlyHa only get value in ha
   * @param formatted use formatted string
   */
  private static FormatArea(polygon: Polygon, onlyHa = false, formatted = false): string | number {
    const area = getArea(polygon);
    let value: number;
    if (area > 1000 || onlyHa) {
      value = (area / 10000).Round(3);
      return formatted === true ? `${value} ha` : value;
    }
    value = area.Round(3);
    return formatted ? `${value} m<sup>2</sup>` : value;
  }

  /**
   * calculate the Length of a Line in m or km and get the value as formatted text or as number text
   */
  private static FormatLength(line: LineString, languageStore: LanguageStore, formatted = false): string | number {
    const length = getLength(line);
    let value: number;
    let unit = '';
    if (length >= 1000) {
      let precision = 3;
      if (length >= 1_000_000) {
        precision = 0;
      } else if (length >= 100_000) {
        precision = 1;
      } else if (length >= 10_000) {
        precision = 2;
      }
      value = (length / 1000).Round(precision);
      unit = 'km';
    } else {
      value = length.Round(3);
      unit = 'm';
    }
    const valueStr = languageStore.FormatNumber(value);
    return formatted === true ? `${valueStr} ${unit}` : valueStr;
  }

  private static removeDrawInteraction(): void {
    MapInteraction.Remove(MapInteractions.DRAW);
  }

  private static removeSnapInteraction(): void {
    MapInteraction.Remove(MapInteractions.SNAP);
  }

  private static removeLastPointFromDrawing(): void {
    const draw = MapInteraction.FindByName(MapInteractions.DRAW) as Draw;
    if (!draw) {
      return;
    }

    const comparePrecision = 8;
    // Touch screens fix for removing last point. There is no way to access public methods to get sketch coordinates
    // Idea of the fix : on touch screens there are two equal coordinates in the end of current sketch array
    // @ts-ignore
    if (draw.sketchCoords_ && draw.sketchCoords_.length > 0 && !Array.isArray(draw.sketchCoords_[0])
      // @ts-ignore
      && toStringXY(draw.sketchCoords_[draw.sketchCoords_.length - 1], comparePrecision) === toStringXY(draw.sketchCoords_[draw.sketchCoords_.length - 2], comparePrecision)) {
      draw.removeLastPoint();
    }
    draw.removeLastPoint();
  }

  private static finalizeDrawing(): void {
    const drawInteraction = MapInteraction.FindByName(MapInteractions.DRAW) as Draw;
    if (!drawInteraction) {
      return;
    }
    drawInteraction.finishDrawing();
  }

  private static restartSplit(): void {
    const drawInteraction = MapInteraction.FindByName(MapInteractions.DRAW) as Draw;
    if (!drawInteraction) {
      return;
    }

    drawInteraction.abortDrawing();
    drawInteraction.dispatchEvent('drawstart');
  }

  public StartDrawNewGeometry(): void {
    this.startListeningPolygonEditLayerChanges();
  }

  public StopDrawNewGeometry(): void {
    this.notListeningPolygonEditLayerChanges();
    this.removeTooltip();
  }

  public AbortDrawing(restart = false): void {
    MapInteraction.Remove(MapInteractions.DRAW);
    this.sketch = null;
    this.removeTooltip();
    if (restart) {
      this.mapStore.Editor.SetActiveTool(GeometryEditorTool.DRAW_NEW);
    }
  }

  public RemoveLastPointFromDrawing(): void {
    EditorService.removeLastPointFromDrawing();
  }

  public FinalizeDrawing(): void {
    EditorService.finalizeDrawing();
  }

  public RestartSplit(): void {
    EditorService.restartSplit();
  }

  private removeTooltip(): void {
    if (this.measureTooltipElement && this.measureTooltipElement.parentNode) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
  }

  private startListeningPolygonEditLayerChanges(): void {
    this.listenPolygonEditChange = combineLatest([
      this.mapStore.Editor.PolygonEditLayerChange$,
      this.mapStore.Editor.ActiveTool$,
    ])
      .pipe(filter(data => data[1] !== GeometryEditorTool.NONE))
      .subscribe(() => {
        // The PolygonEditLayer Geometries was changed reintegrate current editing Geometry
        // do it in timeout to force openlayers render the geometry first otherwise integration is useless
        setTimeout(() => this.reintegrateInsertedFeatures(), 1);
      });
  }

  private notListeningPolygonEditLayerChanges(): void {
    if (!this.listenPolygonEditChange) {
      return;
    }
    this.listenPolygonEditChange.unsubscribe();
    this.listenPolygonEditChange = null;
  }

  private async reintegrateInsertedFeatures(): Promise<void> {
    for (const feat of this.mapStore.Layers.PolygonEditLayer.SelectedFeatures) {
      const id = feat.getId().toString();
      const fittedFeature = await GeometryEditor
        .IntegrateNewFeature(feat, this.fieldStore);
      if (fittedFeature.Error) {
        EditorService.ValidSketch$.next(false);
        return;
      }
      EditorService.ValidSketch$.next(true);
      this.mapStore.Layers.PolygonEditLayer.Source.addFeature(fittedFeature.Feature);
      this.mapStore.Layers.PolygonEditLayer.selectFeature(id, ApEditStyles.selectedStyle);
      this.sketchGeometryChange(fittedFeature.Feature.getGeometry() as Polygon, true);
    }
  }

  private sketchGeometryChange(geom: Polygon, onlyHa = false): void {
    if (!geom || geom.getCoordinates().length < 1 || geom.getCoordinates()[0].length < 1) {
      console.warn(`invalid sketch geometry`);
      return;
    }

    const output = EditorService.FormatArea(geom, true) as number;
    const upper = geom.getCoordinates()[0].length;
    const tooltipCoord = geom.getCoordinates()[0][upper - 2];
    if (this.measureTooltipElement && upper > 2) {
      const line = new LineString([geom.getCoordinates()[0][upper - 3], tooltipCoord]);
      this.measureTooltipElement.innerHTML = EditorService.FormatLength(line, this.languageStore, true);
      this.measureTooltip.setPosition(tooltipCoord);
    }
    if (output > 1000) {
      this.mapStore.Layers.PolygonEditLayer.setStyle(ApEditStyles.invalidStyle);
    } else {
      this.mapStore.Layers.PolygonEditLayer.setStyle(ApEditStyles.selectedStyle);
    }
    this.mapStore.Editor.SetFeatureArea(output);
  }

  /**
   * copy the Field Geometries into the Polygon Edit Layer,
   * activate the edit mode and hide the Fields Layer
   */
  private listeningIsEditing(): void {
    this.listenIsEditChange = this.mapStore.Editor.ActiveTool$
      .subscribe(isEditing => {
        if (isEditing !== GeometryEditorTool.NONE) {
          const splitted: IField[][] = [[], []];
          for (const field of this.fieldStore.Fields) {
            const geom = this.fieldStore.getCurrentFieldGeom(field);
            if (geom && this.fieldStore.EditFields.Contains(geom.Id)) {
              splitted[0] = splitted[0].Add(ObjectFactory.Copy(field));
            } else {
              splitted[1] = splitted[1].Add(field);
            }
          }
          const data = this.mapStore.Layers.FieldsLayer.ReadFeature(splitted[0], this.fieldStore);
          this.mapStore.Layers.PolygonEditLayer.replaceSource(data);
          this.mapStore.Layers.FieldsLayer.Visibility = false;
          this.mapStore.Layers.FieldsCropLayer.Visibility = false;
          setTimeout(() => {
            for (const editedFieldId of this.fieldStore.EditFields) {
              this.mapStore.Layers.PolygonEditLayer.selectFeature(editedFieldId.toString(), ApEditStyles.selectedStyle);
            }
          }, 1);
          MapInteraction.Remove(MapInteractions.SELECT);
        } else {
          this.mapStore.Layers.PolygonEditLayer.replaceSource([]);
          this.mapStore.Layers.PolygonEditLayer.RemoveSelectedFeatures();
          this.mapStore.Layers.FieldsLayer.setStyle(defaultStyle);
          for (const field of this.fieldStore.getSelectedFields()) {
            const geom = this.fieldStore.getCurrentFieldGeom(field);
            this.mapStore.Layers.FieldsLayer.selectFeature(geom?.Id.toString(), selectedStyle);
          }
          this.mapStore.Layers.FieldsLayer.Visibility = true;
          this.mapStore.Layers.FieldsCropLayer.Visibility = true;
        }
      });
  }

  private listeningActiveTool(): void {
    // this.listenActiveToolChange = this.mapStore.Editor.ActiveTool$
    //   .subscribe(tool => {
    //     if (!ApMapInstance.mapRef) {
    //       console.warn('missing map reference');
    //       return;
    //     }
    //     MapInteraction.ClearInteractions();
    //     ApMapInstance.mapRef.removeEventListener('singleclick', () => true);
    //     switch (tool) {
    //       case GeometryEditorTool.DELETE:
    //         this.mapStore.Editor.SetDeleteGeometry(this.fieldStore.getSelectedFields());
    //         break;
    //       case GeometryEditorTool.NONE:
    //         break;
    //       case GeometryEditorTool.DRAW_NEW:
    //         this.startDrawNew();
    //         break;
    //       case GeometryEditorTool.MOVE_VERTEX:
    //         this.enableModifyNewVertex(this.mapStore.Layers.PolygonEditLayer.SelectedFeatures);
    //         break;
    //       case GeometryEditorTool.MOVE_SELECT_VERTEX:
    //         for (const fieldId of this.fieldStore.getSelectedFieldIds()) {
    //           const field = this.fieldStore.getFieldById(fieldId);
    //           if (!field.DefaultGeom || !field.DefaultGeom.Id) {
    //             continue;
    //           }
    //           this.mapStore.Layers.PolygonEditLayer.selectPolygon(field.DefaultGeom.Id.toString());
    //         }
    //         this.enableModifyVertex();
    //         break;
    //       case GeometryEditorTool.SPLIT:
    //         // this.enableSplit();
    //         break;
    //       case GeometryEditorTool.SELECT:
    //         break;
    //       case GeometryEditorTool.MERGE_AREA:
    //         // this.enableMergeArea();
    //         break;
    //       case GeometryEditorTool.DRAW_HOLE:
    //         this.enableDrawHole();
    //         break;
    //       case GeometryEditorTool.DELETE_HOLE:
    //         this.enableDeleteHole();
    //         break;
    //     }
    //   });
  }

  /**
   * Creates a new help tooltip
   */
  public createHelpTooltip(): void {
    if (!ApMapInstance.mapRef) {
      console.warn('missing map reference');
      return;
    }
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: OverlayPositioning.CENTER_LEFT,
    });
    ApMapInstance.mapRef.addOverlay(this.helpTooltip);
  }


  /**
   * Creates a new measure tooltip
   */
  public createMeasureTooltip(): void {
    if (!ApMapInstance.mapRef) {
      console.warn('missing map reference');
      return;
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'field_edit_area';

    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: OverlayPositioning.BOTTOM_CENTER,
    });
    ApMapInstance.mapRef.addOverlay(this.measureTooltip);
  }

  public startDrawNew(): void {
    const draw = GeometryEditor.DrawFeature(
      GeometryType.POLYGON,
      this.mapStore.Layers.PolygonEditLayer.innerSource,
      () => this.mapStore.Editor.CurrentArea$.getValue() > 1000 ? ApEditStyles.invalidStyle() : ApEditStyles.selectedStyle()) as Draw;

    ApDefined.waitUntilDefined(draw, 'on', () => {
      draw.on('drawstart', event => this.startDrawNewEvent(event));
      draw.on('drawend', (event) => this.endDrawNewEvent(event));
      this.onMouseOverFeature = ApMapInstance.FeaturesAtMousePosition[LayerNames.EDIT_VIEW]
        .pipe(
          filter(d => Array.isArray(d) &&
            d.FindAll(f => [GeometryType.POLYGON, GeometryType.MULTI_POLYGON]
              .Contains(f.getGeometry().getType())).length > 0),
        )
        .subscribe((d) => {
          const coords = draw['sketchCoords_'];
          if (!coords || !coords[coords.length - 1]) {
            // no last point to remove
            return;
          }
          const lastPoint = coords[coords.length - 1][coords[coords.length - 1].length - 2];
          const field = d.FirstOrDefault();
          if (!field || !GeometryEditor.PointInPolygon(field, lastPoint)) {
            return;
          }
          draw.removeLastPoint();
        });
      // add the Snap after drawing is important see https://openlayers.org/en/latest/examples/snap.html
      GeometryEditor.SnapToFeature(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditViewLayer.innerSource, 1.0);
    });
  }

  private unsubscribeMouseOverFeature(): void {
    if (this.onMouseOverFeature) {
      this.onMouseOverFeature.unsubscribe();
    }
  }

  private async endDrawNewEvent(event): Promise<void> {
    this.unsubscribeMouseOverFeature();
    this.removeTooltip();
    const err = await this.integrateNewFeature(event.feature);
    if (err) {
      EditorService.ValidSketch$.next(false);
    } else {
      EditorService.ValidSketch$.next(true);
    }
    EditorService.removeDrawInteraction();
    EditorService.removeSnapInteraction();
    // TODO: enable Modify Mode
    this.mapStore.Editor.PrepareModifyField(this.fieldStore.SelectedFields.Convert(f => this.fieldStore.getCurrentFieldGeom(f)?.Id).FindAll(id => !!id), this.fieldStore, false);
  }

  private startDrawNewEvent(event: DrawEvent): void {
    this.sketch = event.feature;
    this.sketch
      .getGeometry()
      .on('change', (evt) => this.sketchGeometryChange(evt.target as Polygon));
    event.feature.set('tag', 'start');
    this.mapStore.Editor.SetEditFeature(event.feature);
  }

  private enableSplit(): void {
    MapInteraction.Remove(MapInteractions.MODIFY);
    this.mapStore.Layers.PolygonEditViewLayer.setStyle(defaultStyle);
    const split = GeometryEditor.SplitByLine(GeometryType.LINE_STRING, this.mapStore.Layers.PolygonEditViewLayer, this.mapStore.Layers.FieldDescriptionLayer, this.mapStore.Editor, this.notifyStore);
    split.on('drawend', event => {
      MapInteraction.ClearSelection();
      event.Feature = null;
    });
  }

  private enableDrawHole(): void {
    MapInteraction.Remove(MapInteractions.MODIFY);
    const drawHole = GeometryEditor.DrawHole(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditLayer.Source) as Draw;
    GeometryEditor.SnapToFeature(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditLayer.Source);
    this.onMouseOverFeature = ApMapInstance.FeaturesAtMousePosition[LayerNames.EDIT]
      .subscribe((d) => {
        if (Array.isArray(d) &&
          d.FindAll(f => [GeometryType.POLYGON, GeometryType.MULTI_POLYGON]
            .Contains(f.getGeometry().getType())).length > 0) {
          return;
        }
        drawHole.removeLastPoint();
      });
    drawHole.on('drawend', evt => {
      evt.feature.set('tag', 'modifygeometry');
      const coordinates = (evt.feature.getGeometry() as Polygon).getCoordinates()[0];
      const lr = new LinearRing(coordinates);
      let feature = this.mapStore.Layers.PolygonEditLayer
        .Source.getFeatures()
        .FirstOrDefault(feat => this.fieldStore.EditFields.Convert(id => id.toString()).Contains(feat.getId().toString()));
      if (!feature) {
        feature = this.mapStore.Layers.PolygonEditLayer
          .Source.getFeatures().FirstOrDefault();
        if (!feature) {
          console.warn(`no feature to edit found`);
          this.closeDrawHole(drawHole, true);
          return;
        }
      }
      const geom = feature.getGeometry();
      if (!geom) {
        console.warn(`the edit polygon has no geometry`);
        this.closeDrawHole(drawHole, true);
        return;
      }
      geom.appendLinearRing(lr);
      feature.setGeometry(geom);
      feature.tag = '';
      const output = EditorService.FormatArea(feature.getGeometry() as Polygon, true) as number;
      this.mapStore.Editor.SetFeatureArea(output);
      this.closeDrawHole(drawHole, true, () => {
        this.mapStore.Layers.PolygonEditLayer.Source.removeFeature(evt.feature);
      });
    });
  }

  private endDrawHole(): void {
    MapInteraction.Remove(MapInteractions.DRAW);
    MapInteraction.Remove(MapInteractions.SNAP);
  }

  private enableModifyNewVertex(features: Feature[]): void {
    this.mapStore.Editor.LockFeature(true);
    MapInteraction.Remove(MapInteractions.DRAW);
    MapInteraction.Remove(MapInteractions.SNAP);
    const modify = GeometryEditor.ModifyLayerSourceFeature(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditLayer.layer, features);
    GeometryEditor.SnapToFeature(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditViewLayer.innerSource);
    modify.on('modifystart', () => this.startModifyVertex());
    modify.on('modifyend', async (event) => {
      this.endModifyVertex(event);
      await this.reintegrateInsertedFeatures();
    });

    // Trigger check for existing geometry intersections
    this.reintegrateInsertedFeatures();
  }

  private enableModifyVertex(): void {
    const modify = GeometryEditor.ModifyLayerSourceFeature(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditLayer.layer, this.mapStore.Layers.PolygonEditLayer.SelectedFeatures);
    GeometryEditor.SnapToFeature(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditLayer.innerSource);
    modify.on('modifystart', () => this.startModifyVertex());
    modify.on('modifyend', (event) => this.endModifyVertex(event));
  }

  private endModifyVertex(event): void {
    this.mapStore.Editor.SetEditFeature(event.features.getArray()[0]);
    const output = EditorService
      .FormatArea(this.mapStore.Layers.PolygonEditLayer.SelectedFeatures[0].getGeometry() as Polygon, true) as number;
    this.mapStore.Editor.SetFeatureArea(output);
  }

  private startModifyVertex(): void {
    const output = EditorService
      .FormatArea(this.mapStore.Layers.PolygonEditLayer.SelectedFeatures[0].getGeometry() as Polygon, true) as number;
    this.mapStore.Editor.SetFeatureArea(output);
  }

  private enableDeleteHole(): void {
    GeometryEditor.DeleteHole(GeometryType.POLYGON, this.mapStore.Layers.PolygonEditLayer.innerSource);
  }

  private async integrateNewFeature(feature: Feature): Promise<Error> {
    const id = ApGuidUtil.generateNewGuid();
    feature.set('insert', true);
    feature.set('tag', 'end');
    feature.setId(id);

    const fittedFeature = await GeometryEditor
      .IntegrateNewFeature(feature, this.fieldStore);
    this.mapStore.Layers.PolygonEditLayer.Source.addFeature(fittedFeature.Feature);
    this.mapStore.Layers.PolygonEditLayer.selectFeature(id, ApEditStyles.selectedStyle);

    this.mapStore.Editor.LockFeature(true);
    if (!ApMapInstance.IsFeatureInViewFull(fittedFeature.Feature)) {
      this.mapStore.Layers.PolygonEditLayer.ZoomToFeature(fittedFeature.Feature, 100);
    }
    return fittedFeature.Error;
  }

  private closeDrawHole(drawHole: Draw, reanable: boolean, afterAbortDraw: () => void = null): void {
    drawHole.abortDrawing();
    setTimeout(() => {
      if (typeof afterAbortDraw === 'function') {
        afterAbortDraw();
      }
      MapInteraction.Remove(MapInteractions.DRAW);
      MapInteraction.Remove(MapInteractions.SNAP);
      if (this.onMouseOverFeature) {
        this.onMouseOverFeature.unsubscribe();
      }
      if (reanable) {
        this.enableDrawHole();
      }
    }, 100);
  }
}

import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType,
  ApDynGridColumnHideConfig,
  IPipeDefinition
} from './ap-dyn-grid-column-config-base';

export enum FilterType {
  STRING = 'STRING',
  KENDO_DATE_RANGE = 'KENDO_DATE_RANGE',
  DATE = 'DATE',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  CHECKBOX = 'CHECKBOX',
  NUMBER = 'NUMBER',
  NUMBER_LIST = 'NUMBER_LIST',
  OBJECT = 'OBJECT'
}

export enum PropertyTemplate {
  NONE = 'NONE',
  NUMBER = 'NUMBER',
  DATE = 'DATE'
}

export class ApDynGridPropertyColumnConfig extends ApDynGridColumnConfigBase {
  public type = ApDynGridColumnControlType.Property;
  public checkbox: boolean;
  public filterable: boolean;
  public filterType: FilterType;
  public format: string;
  public svg: boolean;
  public svgClassField: string;
  public title: string;
  public unit: string;
  public formatCallback: (dataItem: any, value: any) => string;
  public headerExtraLines: string[] = [];
  public tooltip: (dataItem: any) => string;
  public columnLoading: string;
  public breakHeaderLine: boolean;
  public usedKendoDefaultFilter: 'text' | 'numeric' | 'boolean' | 'date';
  /**
   * Allows multiline texts in that particular column.
   * The text has to be pre-formatted with line breaks in order
   * to work properly with responsive text component and css Ellipsis ('...' abbreviation)
   */
  public multiline: boolean;
  public template: PropertyTemplate;

  constructor(options: {
    hide?: boolean | ApDynGridColumnHideConfig,
    sortIndex?: number,
    sortDesc?: boolean,
    groupIndex?: number,
    groupDesc?: boolean,
    headerStyle?: any,
    headerFilterable?: boolean,
    style?: any,
    width?: number,
    pdfExport?: boolean,
    xlsExport?: boolean,
    checkbox?: boolean,
    field?: string,
    filterable?: boolean,
    filterType?: FilterType,
    format?: string,
    svg?: boolean,
    svgClassField?: string,
    title?: string,
    unit?: string,
    formatCallback?: (dataItem: any, value: any) => string;
    tooltip?: (dataItem: any) => string,
    columnLoading?: string,
    cssClass?: 'left' | 'right' | 'center',
    dynCssClass?: (dataItem: any) => string,
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
    headerExtraLines?: string[],
    breakHeaderLine?: boolean,
    multiline?: boolean,
    template?: PropertyTemplate,
    sortable?: boolean
  } = {}) {
    super(options);
    this.checkbox = options.checkbox ? options.checkbox : false;
    this.field = options.field ? options.field : undefined;
    this.filterable = options.filterable === false ? false : true; // default is true and can explicitly set to false in component
    this.format = options.format ? options.format : '';
    this.svg = options.svg ? options.svg : false;
    this.svgClassField = options.svgClassField ? options.svgClassField : undefined;
    this.title = options.title ? options.title : ' ';
    this.headerFilterable = options.headerFilterable ? options.headerFilterable : false;
    this.formatCallback = options.formatCallback;
    this.unit = options.unit;
    this.tooltip = options.tooltip ? super.getTooltip(options.tooltip) : () => undefined;
    this.columnLoading = options.columnLoading ? options.columnLoading : undefined;
    this.headerExtraLines = options?.headerExtraLines ? options.headerExtraLines : [];
    this.breakHeaderLine = options?.breakHeaderLine ?? false;
    this.multiline = options?.multiline ?? false;
    this.template = options?.template ?? PropertyTemplate.NONE;

    // per styleguide discussion we are always using kendo default filter menu when having dates.
    if (this.format?.length > 0) {
      this.usedKendoDefaultFilter = 'date';
    } else {
      this.filterType = options.checkbox ? FilterType.CHECKBOX :
        options.filterType ? options.filterType : FilterType.STRING;
      this.usedKendoDefaultFilter = undefined;
    }

    // center checkbox column by default
    if (this.checkbox && !options.cssClass) {
      super.cssClass = 'center';
    }

    switch (options.template) {
      case PropertyTemplate.NUMBER:
        if (options.tooltip === undefined) {
          this.tooltip = (dataItem) => dataItem ? dataItem[options.field] : undefined;
        }
        if (options.cssClass === undefined) {
          this.cssClass = 'right';
        }
        this.filterType = FilterType.NUMBER;
        this.field = options.field + 'Rounded';
        break;
      case PropertyTemplate.DATE:
        this.field = options.field + 'Midnight';
        break;
    }
  }

  equals(config: ApDynGridColumnConfigBase): boolean {
    if (config instanceof ApDynGridPropertyColumnConfig) {
      return this.title === config.title && this.field === config.field;
    }
    return super.equals(config);
  }
}

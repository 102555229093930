import {IStateStoreWritable} from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {
  ApCustomTypes,
  NetTypes,
  TaskManagementPlanExport,
  TaskManagementPlanExportFail,
  TaskManagementPlanExportSuccess,
  TaskManagementPlanSummariesLoad,
  TaskManagementPlanSummariesLoadSuccess
}                            from 'invoker-transport';
import {
  TaskManagementPlanSummariesLoadById
}                            from '../../../../projects/invoker-transport/src/lib/actions/task-management';
import {SafeBehaviorSubject} from 'ts-tooling';
import ITaskManagementPlanSummary = Data.TaskManagement.ITaskManagementPlanSummary;
import ITaskManagementPlanBook = Data.TaskManagement.ITaskManagementPlanBook;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface ITaskManagementStore extends IStateStoreWritable<ITaskManagementPlanSummary> {
  exportSuccess: boolean;
  exportFail: boolean;
  exportedFile: ArrayBuffer;
}

@Injectable({providedIn: 'root'})
export class TaskManagementStore extends Store<ITaskManagementStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      exportedFile: null,
      loaded: false,
      loading: false,
      saved: false,
      saving: false,
      exportSuccess: false,
      exportFail: false
    });

    backend.registerObservable(TaskManagementPlanSummariesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.exportedFile, () => null);
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(TaskManagementPlanExportSuccess).subscribe(d => {
      super.SetSaveSuccessState();
      super.Mutate(s => s.exportSuccess, () => true);
      super.Mutate(s => s.exportedFile, () => d.Data);
    });

    backend.registerObservable(TaskManagementPlanExportFail).subscribe(d => {
      super.SetSaveFailState();
      super.Mutate(s => s.exportFail, () => true);
    });
  }

  public get Tasks$(): SafeBehaviorSubject<ITaskManagementPlanSummary[]> {
    return this.Listen(x => x.data);
  }

  public get Tasks(): ITaskManagementPlanSummary[] {
    return this.Listen(x => x.data).getValue();
  }

  /**
   *  load task management plan summaries from backend
   */
  public loadTaskManagementPlanSummaries(guid?: string | System.IGuid, bookings?: boolean): void {
    super.SetLoadState();
    super.Mutate(s => s.exportedFile, () => null);
    super.Mutate(s => s.data, () => []);

    // load plan for single plan-id
    if (guid?.toString()?.length > 0) {
      this.DispatchBackend(new TaskManagementPlanSummariesLoadById([
        {
          Name: 'planId',
          Type: NetTypes.GUID,
          Value: guid
        },
        {
          Name: 'bookings',
          Type: NetTypes.BOOL,
          Value: bookings
        }
      ]));
    } else {
      // load all
      this.DispatchBackend(new TaskManagementPlanSummariesLoad([]));
    }
  }

  public export(plansForExport: ITaskManagementPlanBook[]): void {
    super.SetSaveState();
    super.Mutate(s => s.exportSuccess, () => false);
    super.Mutate(s => s.exportFail, () => false);
    super.DispatchBackend(new TaskManagementPlanExport([
      {
        Name: 'plansForExport',
        Type: ApCustomTypes.Data_TaskManagement_TaskManagementPlanBook + '[]',
        Value: plansForExport
      }
    ]));
  }
}

import {Injectable}                 from '@angular/core';
import {Store}                      from '../index';
import {IStateStore}                from '../../ap-interface';
import {NetTypes} from 'invoker-transport';
import {SafeBehaviorSubject}        from 'ts-tooling';
import {
  RbCheckField,
  RbCheckFieldSuccess,
  RbCreate,
  RbCreateFail,
  RbCreateSuccess,
  RbLoad,
  RbLoadFail,
  RbLoadSuccess,
  RbRefresh
}                                   from '../../../../projects/invoker-transport/src/lib/actions/nutrients/rb.action';
import IGuid = System.IGuid;
import IRbStatistic = Data.Nutrients.IRbStatistic;
import {ApSignalrService}           from '../../ap-core/services/ap-signalr.service';

interface IRbStore extends IStateStore<IRbStatistic> {
  rb: IRbStatistic[];
  checkResult: string;
  checkLoaded: boolean;
  checkLoading: boolean;
}

@Injectable({providedIn: 'root'})
export class RbStore extends Store<IRbStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      rb: [],
      checkResult: null,
      checkLoaded: false,
      checkLoading: false,
    });

    backend.registerObservable(RbRefresh).subscribe(d => {
      const statisticsToAdd = JSON.parse(d.Data) as IRbStatistic;
      if (statisticsToAdd == null) {
        return;
      }
      super.Mutate(s => s.rb, o => {
        if (o.Find(i => !!i && !!statisticsToAdd && i.FieldGeomId === statisticsToAdd.FieldGeomId)) {
          o = o.RemoveAll(i => i.FieldGeomId === statisticsToAdd.FieldGeomId);
          o = o.Add(statisticsToAdd);
        } else {
          o = o.Add(statisticsToAdd);
        }
        return o;
      });
    });

    backend.registerObservable(RbCheckFieldSuccess).subscribe(d => {
      this.Mutate(s => s.checkLoaded, () => true);
      this.Mutate(s => s.checkLoading, () => false);
      super.Mutate(s => s.checkResult, () => JSON.parse(d.Data));
    });

    backend.registerObservable(RbCheckFieldSuccess).subscribe(_ => {
      this.Mutate(s => s.checkLoaded, () => false);
      this.Mutate(s => s.checkLoading, () => false);
      super.Mutate(s => s.checkResult, () => '');
    });

    backend.registerObservable(RbCreateSuccess).subscribe(_ => {
    });
    backend.registerObservable(RbLoadFail).subscribe(_ => {
      this.SetLoadFailState();
      //   const result = JSON.parse(d.Data) as IRasterCreationResult;
      //   super.Mutate(s => s.needLeftStatistic, o => {
      //     if (o.Find(i => i.FieldGeomId === result.Id)) {
      //       const stat = o.Find(i => i.FieldGeomId === result.Id)[0];
      //       stat.ErrorText = result.Message;
      //       o = o.Replace(i => i.FieldGeomId === result.Id, stat);
      //     } else {
      //       const err = {FieldGeomId: result.Id, ErrorText: result.Message} as IFieldNutrientPlanningStatistic;
      //       o = o.Add(err);
      //     }
      //     return o;
      //   });
      //
      // });
    });

    backend.registerObservable(RbCreateFail).subscribe(_ => {

    });
    backend.registerObservable(RbLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      const statisticsToAdd = d.Data as IRbStatistic[];
      super.Mutate(s => s.rb, o => {
        for (const stat of statisticsToAdd) {
          o.Replace(i => i.FieldGeomId === stat.FieldGeomId, stat);
        }
        return o;
      });
      super.SetLoadFinishState();
    });
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  createRb(fieldGeomId: IGuid): void {
    super.Mutate(s => s.rb, o => {
      if (o.Find(i => i?.FieldGeomId === fieldGeomId)) {
        o = o.RemoveAll(i => i.FieldGeomId === fieldGeomId);
      } else {
      }
      return o;
    });
    this.DispatchBackend(new RbCreate([
      {Name: 'fieldGeomId', Type: NetTypes.GUID, Value: fieldGeomId},
    ]));
  }

  loadRb(fieldGeomIds: string[]): void {
    super.SetLoadState();
    this.DispatchBackend(new RbLoad([
      {Name: 'fieldGeomIds', Type: NetTypes.GUID + '[]', Value: fieldGeomIds}
    ]));
  }

  checkField(fieldGeomId: IGuid): void {
    this.Mutate(s => s.checkLoaded, () => false);
    this.Mutate(s => s.checkLoading, () => true);
    this.DispatchBackend(new RbCheckField([
      {Name: 'fieldGeomId', Type: NetTypes.GUID, Value: fieldGeomId}
    ]));
  }

  get RbStatistic$(): SafeBehaviorSubject<IRbStatistic[]> {
    return super.Listen(s => s.rb);
  }

  get RbStatistic(): IRbStatistic[] {
    return this.RbStatistic$.getValue();
  }

  public getRbStatisticByFieldGeomId(fieldGeomId: string): IRbStatistic {
    return this.RbStatistic$.getValue().Find(e => e?.FieldGeomId === fieldGeomId);
  }
}

import {NgModule}                           from '@angular/core';
import {CommonModule}                       from '@angular/common';
import {ApDriversEditComponent}             from './ap-drivers-edit.component';
import {ApMachinesEditComponent}            from './ap-machines-edit.component';
import {ApInstrumentsEditComponent}         from './ap-instruments-edit.component';
import {ApFertilizerInorgsEditComponent}    from './ap-fertilizer-inorgs-edit.component';
import {ApFertilizerOrgsEditComponent}      from './ap-fertilizer-orgs-edit.component';
import {ApDynformsModule}                   from '../../ap-dynforms/ap-dynforms.module';
import {ApTranslationModule}                from '../../translation/ap-translation.module';
import {ApDynComponentModule}               from '../../ap-dyncomponent/ap-dyncomponent.module';
import {ApSubFarmsEditComponent}            from './ap-subfarms-edit.component';
import {ApUsersAssignmentFarmEditComponent} from './ap-users-assignment-farm-edit.component';
import {ApUsersInvitationEditComponent}     from './ap-users-invitation-edit.component';
import {ApTooltipModule}                    from '../../ap-tooltip/ap-tooltip.module';
import {ApUserContactsEditComponent}        from './ap-user-contacts-edit.component';

const COMPONENTS = [
  ApDriversEditComponent,
  ApMachinesEditComponent,
  ApInstrumentsEditComponent,
  ApFertilizerInorgsEditComponent,
  ApFertilizerOrgsEditComponent,
  ApSubFarmsEditComponent,
  ApUsersAssignmentFarmEditComponent,
  ApUsersInvitationEditComponent,
  ApUserContactsEditComponent
];

@NgModule({
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    ApDynformsModule,
    ApTranslationModule,
    ApDynComponentModule,
    ApTooltipModule
  ],
  exports: COMPONENTS,
})
export class ApBasedataEntryComponentsModule {
}

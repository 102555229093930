import {FormControl}   from '@angular/forms';
import {find, isEqual} from 'lodash';

export class ApDynformsValidations {
  static  validateList(list: any[]): (c: FormControl) => {validateList: boolean} {
    return function (c: FormControl): {validateList: boolean} {
      if (find(list, l => isEqual(l.Name, c.value))) {
        return ({validateList: true});
      } else { return null; }
    };
  }
}

import {ApDynGridColumnConfigBase, ApDynGridColumnControlType, IPipeDefinition} from './ap-dyn-grid-column-config-base';
import {Observable, of}                                                         from 'rxjs';
import {
  DropDownButtonData
}                                                                               from './ap-dyn-grid-button-column-config';

export class ApDynGridEditColumnConfig extends ApDynGridColumnConfigBase {
  public type = ApDynGridColumnControlType.Edit;
  public disabled$: Observable<boolean>;
  public data: DropDownButtonData[];

  public constructor(options: {
    disabled?: Observable<boolean> | boolean,
    data?: DropDownButtonData[],
    cssClass?: 'left' | 'right',
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
  } = {}) {
    super({});
    this.width = 37;
    this.pdfExport = false;
    this.xlsExport = false;
    this.groupIndex = undefined;
    this.sortIndex = undefined;
    if (options.disabled instanceof Observable) {
      this.disabled$ = options.disabled;
    } else if (options.disabled !== undefined) {
      this.disabled$ = of(options.disabled);
    } else {
      this.disabled$ = of(false);
    }
    this.data = options.data ? options.data.map(d => ({
      text: typeof d.text === 'function' ? d.text : (() => d.text),
      classList: typeof d.classList === 'function' ? d.classList :
        Array.isArray(d.classList) ? () => d.classList : typeof d.classList === typeof '' ? () => [d.classList] :
          () => undefined,
      callback: d.callback,
      disabled: d.disabled
    } as DropDownButtonData)) : undefined;
  }
}

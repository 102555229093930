import IGuid = System.IGuid;

export interface IContentNRelatedPlanBook {
  PlanBookId: IGuid;
  FieldId: IGuid;
  OperationModeId: number | null;
  ProductId: number | null;
  FieldGeomId: IGuid;
  CropTypeId: number | null;
  EcValue: number;
  Date: Date;
  NUptake: IContentNRelatedPlanBookStatisticValue | null;
  NFertilization: IContentNRelatedPlanBookStatisticValue | null;
  SourceType: ContentNRelatedPlanBookSourceType;
}

export interface IContentNRelatedPlanBookStatisticValue {
  Min: number;
  Max: number;
  Average: number;
}

export enum ContentNRelatedPlanBookSourceType {
  LogFilePlanBook = 0,
  NFertPlanBook = 1,
  BaseFertPlanBook = 2
}

import {AfterViewInit, Component, Input}             from '@angular/core';
import {CompositeFilterDescriptor, FilterDescriptor} from '@progress/kendo-data-query';
import {FilterService}                               from '@progress/kendo-angular-grid';

@Component({
  selector: 'ap-number-list-filter',
  templateUrl: 'ap-number-list-filter.component.html',
})
export class ApNumberListFilterComponent implements AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public items = [];
  @Input() public key = '';

  public data: number[] = [];
  public dataFilter: number[] = [];
  public selected: number[] = [];

  ngAfterViewInit(): void {
    this.data = [];
    if (!this.items || this.items?.length === 0) {
      return;
    }
    for (const item of this.items) {
      const elements = item[this.key];
      if (!Array.isArray(elements)) {
        continue;
      }
      for (const element of elements) {
        this.data.AddIfNotExists(element);
      }
    }
    this.dataFilter = this.data;
    this.selected = this.currentFilter.filters.Convert((f: FilterDescriptor) => f.value);
  }

  onChange(values: any[]): void {
    this.filterService.filter({
      filters: values.Convert(value => ({
        field: this.key,
        operator: v => v.Contains(value),
        value
      })),
      logic: 'or'
    });
  }

  onFilterChange(filter: string): void {
    if (!filter) {
      this.dataFilter = this.data;
      return;
    }
    let tmp = [filter];
    if (filter.Contains(',')) {
      tmp = filter.Split(',');
    }
    this.dataFilter = [];
    for (const t of tmp) {
      const found = this.data.Find(e => e === parseInt(t, 10));
      if (!found) {
        continue;
      }
      this.dataFilter.Add(found);
    }
  }
}

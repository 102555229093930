import {Route, RouterModule}           from '@angular/router';
import {PageMode}                      from '../ap-interface';
import {NgModule}                      from '@angular/core';
import {ApFleetmanagementComponent}    from './components/ap-fleetmanagement.component';
import {ApActionLookupComponent}       from './components/ap-action-lookup.component';
import {ApPermissionGuard}             from '../guards/ap-permission-guard.service';
import {ApBookingComponent}            from '../booking/components/ap-booking.component';
import {ApBookingArchiveComponent}     from '../booking/components/ap-booking-archive.component';
import {ApFieldWorksOverviewComponent} from './components/ap-field-works-overview.component';

const routes: Route[] = [
  {
    path: 'live-view',
    component: ApFleetmanagementComponent,
    canActivate: [ApPermissionGuard],
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    },
  },
  {
    path: 'measures',
    component: ApBookingComponent,
    canActivate: [ApPermissionGuard],
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    },
  },
  {
    path: 'booking',
    component: ApBookingArchiveComponent,
    canActivate: [ApPermissionGuard],
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    },
  },
  {
    path: 'action-lookup-overview',
    component: ApActionLookupComponent,
    canActivate: [ApPermissionGuard],
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    },
  },
  {
    path: 'overview',
    component: ApFieldWorksOverviewComponent,
    canActivate: [ApPermissionGuard],
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    },
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ApDocuRoutes {
}

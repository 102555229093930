import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {ApDynformsValidator}                         from '../ap-dynforms-validator';
import {Observable}                                  from 'rxjs';
import {AsyncValidatorFn, ValidatorFn}               from '@angular/forms';
import {ApUtilService}                               from '../../ap-utils/service/ap-util.service';
import {CalendarView}                                from '@progress/kendo-angular-dateinputs';

/**
 * configuration for datepicker control
 */
export class ApDynformsConfigDatepicker extends ApDynformsConfigBase<Date> {
  controlType = ApDynformsControltype.DatePicker;
  dateCellDyer: (date: Date) => boolean;
  format: string;
  minDate$: Observable<Date>;
  maxDate$: Observable<Date>;
  topView: CalendarView;
  bottomView: CalendarView;

  constructor(options: {
    value?: Date,
    value$?: Observable<Date>,
    key?: string,
    label?: string,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    listenUpdate?: Observable<any>[],
    format?: string,
    minDate?: Date | Observable<Date>,
    maxDate?: Date | Observable<Date>,
    dateCellDyer?: (date: Date) => boolean,
    dependsOn?: string[],
    formErrors?: string[],
    topView?: CalendarView,
    bottomView?: CalendarView,
    cssClass?: string,
    infoText?: string,
  } = {}) {
    super(options);
    this.dateCellDyer = options.dateCellDyer;
    this.format = options.format || '';
    this.minDate$ = ApUtilService.asObservable(options.minDate);
    this.maxDate$ = ApUtilService.asObservable(options.maxDate);
    this.dependsOn = options.dependsOn;
    this.topView = options.topView ? options.topView : 'century';
    this.bottomView = options.bottomView ? options.bottomView : 'month';
    this.infoText = options.infoText || '';
  }
}

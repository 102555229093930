import {FormGroup, ValidatorFn} from '@angular/forms';

/**
 * Form validator: Validates the range of 2 numeric values
 */
export class ApNumberRangeValidator {
  static ValidateNumberRange(smallerNumberControlName: string, biggerNumberControlName: string): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: any } | null => {
      if (!formGroup || formGroup == null) {
        return null;
      }

      const smallerNumberControl = formGroup.get(smallerNumberControlName);
      const biggerNumberControl = formGroup.get(biggerNumberControlName);
      if (!smallerNumberControl || !biggerNumberControl) {
        return null;
      }

      const smallerNumber = smallerNumberControl.value as number;
      const biggerNumber = biggerNumberControl.value as number;
      if (!smallerNumber || smallerNumber == null || !biggerNumber || biggerNumber == null) {
        return null;
      }

      if (smallerNumber > biggerNumber) {
        const validationResult = {};
        validationResult[`ValidateNumberRange_${smallerNumberControlName}_${biggerNumberControlName}`] = [smallerNumberControlName, biggerNumberControlName];
        return validationResult;
      }

      return null;
    };
  }
}

import IGuid = System.IGuid;
import IDatasetFieldMap = Data.Sensor.IDatasetFieldMap;
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;

export interface NFertilizationLogfileStatsData {
  Area: number;
  PlanBookId: IGuid;
  Logfiles: IDatasetFieldMap[];
  RasterStatistics: IFieldNutrientPlanningStatistic[];
}

export type MinAndMaxForLegend = {
  Min: number;
  Max: number;
};

export type ParticularStatisticItem = {
  Area: number;
  Statistic: IFieldNutrientPlanningStatistic;
};

export type FieldStatisticsForFiltering = {
  Key: number;
  Value: number,
  ItemBehavior: HistogramItemBehavior
};

export enum HistogramItemBehavior {
  Include = 0,
  Exclude = 1
}

import {IStateStore}         from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {
  NUptakeFactorsLoad,
  NUptakeFactorsLoadFailed,
  NUptakeFactorsLoadSuccess
}                            from 'invoker-transport';
import {SafeBehaviorSubject} from 'ts-tooling';
import INUptakeFactors = Data.Common.INUptakeFactors;
import IFieldCrop = Data.FieldManagement.IFieldCrop;
import ICropTypes = Data.BaseData.ICropTypes;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface INUptakeFactorsState extends IStateStore<INUptakeFactors> {
}

@Injectable({providedIn: 'root'})
export class NUptakeFactorsStore extends Store<INUptakeFactorsState> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });
    backend.registerObservable(NUptakeFactorsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(NUptakeFactorsLoadFailed).subscribe(_ => {
      super.Mutate(s => s.data, () => []);
      super.SetLoadFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get nUptakeFactors$(): SafeBehaviorSubject<INUptakeFactors[]> {
    return this.Listen(x => x.data);
  }

  public get nUptakeFactors(): INUptakeFactors[] {
    return this.Listen(x => x.data).getValue();
  }

  public loadNUptakeFactors(): void {
    this.DispatchBackend(new NUptakeFactorsLoad([]));
  }

  public getNUptakeFactorByFieldCropAndCropType(fieldCrop: IFieldCrop | null, cropType: ICropTypes | null): INUptakeFactors | null {
    if (!fieldCrop || !cropType?.Eppocode) {
      return null;
    }
    const nUptakeFactorsByCropTypeId = this.nUptakeFactors.filter(factor => factor.EppoCode === cropType.Eppocode);
    if (!nUptakeFactorsByCropTypeId) {
      return null;
    }
    return nUptakeFactorsByCropTypeId.find(factor => factor.EcMin <= fieldCrop.EcValue && factor.EcMax >= fieldCrop.EcValue);
  }
}

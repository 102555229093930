import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, Validators}                                               from '@angular/forms';
import * as moment                                                                              from 'moment';
import {
  ApDocuUtils
}                                                                                               from '../../docu/utils/ap-docu-utils';
import {
  ApDynformsConfigFieldset
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigDatepicker
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-datepicker';
import {
  ApDynformsConfigTimepicker
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-timepicker';
import {
  ApDynformsConfigComboBox
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigNumerictextbox
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApDynformsConfigCheckbox
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  ApDynComponentComponent
}                                                                                               from '../../ap-dyncomponent/ap-dyncomponent.component';
import {BehaviorSubject, combineLatest, Subscription}                                           from 'rxjs';
import {
  ApDynformsValidator
}                                                                                               from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsConfigTextbox
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {map}                                                                                    from 'rxjs/operators';
import {
  MachineStore
}                                                                                               from '../../stores/docu/machine.store';
import {
  DriverStore
}                                                                                               from '../../stores/docu/driver.store';
import {
  InstrumentStore
}                                                                                               from '../../stores/docu/instrument.store';
import {
  SettingsStore
}                                                                                               from '../../stores/base-data/settings.store';
import {
  MachineLoggerStore
}                                                                                               from '../../stores/docu/machine.logger.store';
import {
  DriverMachineStore
}                                                                                               from '../../stores/docu/driver.machine.store';
import {
  FieldStore
}                                                                                               from '../../stores/farm/field.store';
import {
  WorkTypesStore
}                                                                                               from '../../stores/base-data/worrktypes.store';
import {
  FormStore
}                                                                                               from '../../stores/layout/form.store';
import {
  FleetActionAttachmentStore
}                                                                                               from '../../stores/docu/fleet.action.attachment.store';
import {
  TranslationStore
}                                                                                               from '../../stores/translation/translation.store';
import {
  LoginStore
}                                                                                               from '../../stores/login/login.store';
import {
  LanguageStore
}                                                                                               from '../../stores/translation/language.store';
import {
  ApDateService
}                                                                                               from '../../ap-core/services/ap-date-service';
import {
  ApGetUtcDatePipe
}                                                                                               from '../../stores/pipes/ap-get-utc-date.pipe';
import {ObjectFactory}                                                                          from 'ts-tooling';
import {
  SliceStore
}                                                                                               from '../../stores/docu/slice.store';
import {ApGuidUtil}                                                                             from '../../ap-utils';
import IDriver = Data.DocuContext.Driver.IDriver;
import IDriverMachine = Data.DocuContext.DriversMachines.IDriverMachine;
import IField = Data.FieldManagement.IField;
import IFleetActionAttachment = Data.DocuContext.FleetManagement.IFleetActionAttachment;
import IWorktypes = Data.BaseData.IWorktypes;
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IFieldBase = Data.FieldManagement.IFieldBase;
import IMachine = Data.DocuContext.Machine.IMachine;
import ActionType = Data.Authentication.Enums.ActionType;

/**
 * Component for the edit action
 */
@Component({
  selector: 'ap-edit-action',
  template: `
    <ap-dyncomponent [fieldSets]="fieldsets"
                     [caption]="caption"
                     (apSubmit)="onSubmitClick()">
      <ng-container *ngIf="selectedWorkType && selectedWorkType.Unit.length" dynforms.after>
        <fieldset *ngFor="let formapp of applications; index as i">
          <legend>{{'Docu_Ini__Medium' | translate}} {{i + 1}}</legend>
          <div>
            <ap-action-applications [application]="formapp"
                                    (applicationChange)="applications[i] = $event"
                                    [formGroup]="dynComponent.getForm()"
                                    [index]="i"
                                    [workType]="selectedWorkType"
                                    (delete)="onDelete($event)">
            </ap-action-applications>
          </div>
        </fieldset>
        <fieldset>
          <div class="ap-dynform-fieldset">
            <div style="grid-area: 1 / 1 / auto / auto;">
              &nbsp;
            </div>
            <div style="grid-area: 1 / 2 / auto / auto;">
              <button *ngIf="applications.length < 3"
                      style="margin-left: 25%;margin-top: 5%;"
                      kendoButton type="button"
                      (click)="onAddApplicationClick()">{{'Global_Add_Application' | translate}}
              </button>
            </div>
            <div style="grid-area: 1 / 3 / auto / auto;">
              &nbsp;
            </div>
          </div>
        </fieldset>
      </ng-container>
      <div class="ap-form-actions" dynforms.action>
        <button id="button_action_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_action_edit_save"
                type="submit"
                class="k-button k-primary ap-form-button-right">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dyncomponent>
  `,
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApEditActionComponent implements OnInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true})
  public dynComponent: ApDynComponentComponent;
  public caption = '';
  public applications = [];
  public data: IFleetActionAttachment;
  public driverMachines: IDriverMachine[];
  public fields: IField[];
  public field: IField;
  public moment = moment;
  public drivers: IDriver[];
  public fromDate: Date;
  public toDate: Date;
  public fieldsets: ApDynformsConfigFieldset[] = [];
  public selectedWorkType: IWorktypes = null;
  public worktypes: IWorktypes[] = [];
  public valid = true;
  public harvestEnd: boolean;
  @Output()
  public closeMe = new EventEmitter<any>();

  private _machines = new BehaviorSubject<IMachine[]>([]);
  private _maxArea = new BehaviorSubject<number>(undefined);
  private _maxDuration = new BehaviorSubject<Date>(new Date(Date.UTC(0, 0, 0, 23, 59, 59)));
  private _minDate = new BehaviorSubject<Date>(undefined);
  private _subscriptions: Subscription[] = [];

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private fleetActionAttachmentStore: FleetActionAttachmentStore,
              private driverMachineStore: DriverMachineStore,
              private driverStore: DriverStore,
              private instrumentStore: InstrumentStore,
              private loginStore: LoginStore,
              private fieldStore: FieldStore,
              private sliceStore: SliceStore,
              private machinesLoggerStore: MachineLoggerStore,
              private machineStore: MachineStore,
              private settingsStore: SettingsStore,
              private translationStore: TranslationStore,
              private worktypeStore: WorkTypesStore,
              private dateService: ApDateService,
              private getUTCDatePipe: ApGetUtcDatePipe,
              private languageStore: LanguageStore) {
  }

  private get attValidControl(): AbstractControl {
    return this.dynComponent.getForm().get('Attachment.Valid');
  }

  private get attInstrumentControl(): AbstractControl {
    return this.dynComponent.getForm().get('Attachment.Instrument');
  }

  private get attWorkTypeControl(): AbstractControl {
    return this.dynComponent.getForm().get('Attachment.WorkType');
  }

  private get attWorkingWidthControl(): AbstractControl {
    return this.dynComponent.getForm().get('Attachment.WorkingWidth');
  }

  private get dateControl(): AbstractControl {
    return this.dynComponent.getForm().get('Date');
  }

  private get fieldControl(): AbstractControl {
    return this.dynComponent.getForm().get('Field');
  }

  private get workedAreaControl(): AbstractControl {
    return this.dynComponent.dynForm.form.get('Worked_Area');
  }

  private get timeFromControl(): AbstractControl {
    return this.dynComponent.getForm().get('TimeFrom');
  }

  private get timeToControl(): AbstractControl {
    return this.dynComponent.getForm().get('TimeTo');
  }

  private get durationControl(): AbstractControl {
    return this.dynComponent.getForm().get('Duration');
  }

  private get attMachineControl(): AbstractControl {
    return this.dynComponent.getForm().get('Attachment.Machine');
  }

  private get traversedAreaControl(): AbstractControl {
    return this.dynComponent.getForm().get('Traversed_Area');
  }

  /**
   * replace decimal
   */
  static replacedecimal(val, fixed): string {
    if (typeof val === typeof undefined || val === null) {
      return val;
    }
    let r;
    if (isNaN(fixed)) {
      r = val.toString().replace(/\./g, '.');
    } else {
      r = Number(val).toFixed(fixed).replace(/\./g, '.');
    }
    return r;
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    this.harvestEnd = this._getHarvestEnd(this.data);
    if (!!this.data) {
      if (this.data.Attachment.Application) {
        this.applications = this.data.Attachment.Application;
      }
      this.field = this.fieldStore.getFieldById(this.data.FieldId);
      let details = this.translationStore.FindTranslationForSelectedLanguage('Global__Details');
      if (this.field) {
        details = details.concat(`: ${this.field.FieldName}`);
      }
      this.fromDate = this.dateService.toFarmDateFromUtcGetJsDate(this.data.Attachment.From);
      this.toDate = this.dateService.toFarmDateFromUtcGetJsDate(this.data.Attachment.To);
      this._machines.next([this.data.Attachment.Machine]);
      this.caption = details;
    } else {
      this.caption = this.translationStore.FindTranslationForSelectedLanguage('Global__Action_generate');
    }
    this._getDataFromStore();
    this._formBuilder();
    this.dynComponent.dynForm.initialized.subscribe(() => {
      this.attWorkingWidthControl.valueChanges.subscribe(async ww => {
        if (!this.data || !this.data.Slices) {
          return;
        }
        this.data.Slices = await this.sliceStore.calculateBruttoNettoArea(this.data.Slices, ww);
        this.workedAreaControl.setValue(this.getNettoArea());
        this.traversedAreaControl.setValue(this.getBruttoArea());
      });
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * on submit button click event
   */
  onSubmitClick(): void {
    let data: IFleetActionAttachment = this.dynComponent.getForm().getRawValue();
    const harvestEnd = this.dynComponent.getFormValue()['HarvestEnd'];

    if (this.dynComponent.valid || (!data.Attachment.Valid && this.data)) {

      if (harvestEnd !== this.harvestEnd) {
        const field: IField = this.dynComponent.getFormValue()['Field'];
        if (field && field.FieldCrops.length) {
          if (this.data) {
            data = ObjectFactory.Merge(ObjectFactory.Copy(this.data), data);
          }
          const fieldCrop = field.FieldCrops[0];
          fieldCrop.HarvestAttachment = harvestEnd ? data.Attachment : null;
          this.fieldStore.saveFieldCrops([fieldCrop]);
        }
      }

      if (harvestEnd === this.harvestEnd || !harvestEnd) {
        if (this.data) {
          data = ObjectFactory.Copy(ObjectFactory.Merge(ObjectFactory.Copy(this.data), data));
          this._update(data);
        } else {
          this._save(data);
        }
      }

      this.formStore.closeForm();
    } else {
      this.dynComponent.dynForm.markAsTouched();
    }
  }

  /**
   * on cancel button click event
   */
  onCancelClick(): void {
    setTimeout(() => this.formStore.closeForm(), 1);
  }

  /**
   * on add application button click event
   */
  onAddApplicationClick(): void {
    const workType = this.dynComponent.getForm().get('Attachment.WorkType').value;
    if (workType) {
      this.applications.push({Key: '', Value: undefined, Unit: workType.Unit[0]});
    } else {
      this.applications.push({Key: '', Value: undefined, Unit: ''});
    }
  }

  /**
   * on delete application button click event
   */
  onDelete($event: number): void {
    this.applications.splice($event, 1);
  }

  /**
   * get the duration
   */
  getDuration(): Date {
    return this.data ? ApDocuUtils.getDurationOfSlices(this.data.Slices).toDate() : null;
  }

  /**
   * get the time of no use
   */
  getStandStillTime(): Date {
    return this.data ?
      ApDocuUtils.getStandStillTimeOfSlices(this.data.Slices).toDate() :
      ApDocuUtils.getDuration(new Date(0)).toDate();
  }

  getBruttoArea(): number {
    return this.data ? this.data.Slices.Reduce((val, s) => s.BruttoArea + val, 0) : 0;
  }

  getNettoArea(): number {
    return this.data ? this.data.Slices.Reduce((val, s) => s.NettoArea + val, 0) : 0;
  }

  /**
   * retrieves all data from the store
   */
  private _getDataFromStore(): void {
    this.fieldStore.Listen(s => s.data).subscribe(f => this.fields = f);
    this.worktypeStore.Listen(s => s.data).subscribe(w => {
      this.worktypes = w;
    });
    this.driverStore.Listen(s => s.data).subscribe(drivers => {
      this.drivers = [...drivers];
    });
    this.driverMachines = this.driverMachineStore.getDriverMachine();
  }

  /**
   * binding of validators
   */
  private _formBuilder(): void {
    const language = this.languageStore.SelectedLanguage;
    let driver = null;
    const requiredValidators = [];
    if (!!this.data) {
      driver = this.drivers.find((d) => d.Id === this.data.Attachment?.Driver?.Id);
      driver = driver ? driver : null;
    } else {
      requiredValidators.push(new ApDynformsValidator({validator: Validators.required, errorKey: ''}));
    }

    this.fieldsets = [
      new ApDynformsConfigFieldset({
        legend: 'Settings__Lbl_Measure_Edit',
        key: 'Settings__Lbl_Measure_Edit',
        config: [
          new ApDynformsConfigDatepicker({
            label: 'Docu_Ini__Date',
            key: 'Date',
            value: this.fromDate,
            disabled: !!this.data,
            format: language.DateFormat,
            validators: requiredValidators
          }),
          new ApDynformsConfigTimepicker({
            label: 'Docu_Ini__From',
            key: 'TimeFrom',
            value: this.fromDate,
            disabled: !!this.data,
            format: 'HH:mm',
            minSteps: 5,
            secSteps: 60,
            validators: requiredValidators
          }),
          new ApDynformsConfigComboBox({
            label: 'Docu_Ini__Field',
            key: 'Field',
            value: this.field ? this.field : {},
            options: this.fieldStore.Listen(s => s.data),
            loading: this.fieldStore.Listen(s => s.loading),
            valuePrimitive: false,
            disabled: !!this.data,
            valueField: 'Id',
            textField: 'FieldName',
            validators: requiredValidators
          }),
          new ApDynformsConfigTimepicker({
            label: 'Docu_Ini__To', key: 'TimeTo', value: this.toDate,
            disabled: !!this.data, format: 'HH:mm',
            minDate: this._minDate, minSteps: 5, secSteps: 60,
            validators: requiredValidators
          }),
          new ApDynformsConfigNumerictextbox({
            label: 'Global_Traversed_Area', key: 'Traversed_Area',
            disabled: true, value: this.getBruttoArea(),
          }),
          new ApDynformsConfigTimepicker({
            label: 'Docu_Ini__Duration',
            key: 'Duration',
            value: this.getDuration(),
            disabled: true,
            format: 'HH:mm',
            validators: requiredValidators
          }),
          new ApDynformsConfigNumerictextbox({
            label: 'Global_Worked_Area',
            key: 'Worked_Area',
            disabled: !!this.data, value: this.getNettoArea(),
            min: 0,
            max: this._maxArea,
            dynamicSteps: 0.1,
            validators: requiredValidators
          }),
          new ApDynformsConfigTimepicker({
            label: 'Docu_Ini__TimeOfNonuse',
            key: 'TimeOfNonuse',
            value: this.getStandStillTime(),
            disabled: !!this.data, format: 'HH:mm',
            maxDate: this._maxDuration,
            validators: requiredValidators,
            minSteps: 5,
            secSteps: 60
          }),
          new ApDynformsConfigCheckbox({
            label: 'Global_Valid_Measure',
            key: 'Attachment.Valid',
            value: this.data?.Attachment.Valid ?? true
          }),
          new ApDynformsConfigCheckbox({
            label: 'Docu_Pages__Harvest_Completion',
            key: 'HarvestEnd',
            value: this.harvestEnd
          }),
        ]
      }),
      new ApDynformsConfigFieldset({
        legend: 'Settings__Lbl_Menu_Resources',
        key: 'Settings__Lbl_Menu_Resources',
        config: [
          new ApDynformsConfigComboBox({
            label: 'Docu_Ini__Engine',
            key: 'Attachment.Machine',
            disabled: !!this.data,
            options: this._machines,
            value: this.data ? this.data.Attachment.Machine : null,
            valuePrimitive: false,
            textField: 'Name',
            valueField: 'Id',
            sort: 1,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })],
            dependsOn: [
              'Date'
            ]
          }),
          new ApDynformsConfigComboBox({
            label: 'Docu_Ini__Tool',
            key: 'Attachment.Instrument',
            textField: 'Name',
            options: this.instrumentStore.Listen(s => s.data),
            valueField: 'Id',
            valuePrimitive: false,
            value: this.data ? this.data.Attachment.Instrument : null,
            placeholder: 'Settings__Msg_Vali_Value_Required',
            sort: 1,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
              })]
          }),
          new ApDynformsConfigComboBox({
            label: 'Docu_Ini__WorkType',
            disabled: true,
            key: 'Attachment.WorkType',
            textField: 'Description',
            valueField: 'Id',
            options: this.worktypeStore.getWorkTypes(),
            valuePrimitive: false,
            sort: 1,
            value: this.data ? this.data.Attachment.WorkType : null,
          }),
          new ApDynformsConfigNumerictextbox({
            disabled: true,
            label: 'Docu_Ini__WorkingWidth',
            key: 'Attachment.WorkingWidth',
            value: this.data ? this.data.Attachment.WorkingWidth : 0,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
            decimals: 2,
            format: 'n1',
            step: 0.1
          }),
          new ApDynformsConfigComboBox({
            label: 'Docu_Ini__Motorist',
            key: 'Attachment.Driver',
            textField: 'FullName',
            options: this.drivers,
            valueField: 'Id',
            valuePrimitive: false,
            value: driver,
            sort: 1
          }),
          new ApDynformsConfigTextbox({
            label: 'Global__Comment',
            key: 'Attachment.Comment',
            value: this.data ? this.data.Attachment.Comment : ''
          }),
        ]
      }),
    ];

    setTimeout(() => {
      if (this.attValidControl) {
        this._subscriptions.push(this.attValidControl.valueChanges.subscribe(
          (valid) => this.valid = valid));
      }

      if (this.attInstrumentControl) {
        this._subscriptions.push(this.attInstrumentControl.valueChanges.subscribe(
          (instrument: IInstrument) => {
            this.attWorkTypeControl.setValue(instrument ? instrument.DefaultWorktype : null);
            this.attWorkingWidthControl.setValue(instrument ? instrument.DefaultWidth : null);
          }));
      }

      if (this.attWorkTypeControl) {
        this._subscriptions.push(this.attWorkTypeControl.valueChanges.subscribe(
          (workType: IWorktypes) => {
            this.selectedWorkType = workType;
          }));
        this.selectedWorkType = this.attWorkTypeControl.value;
      }

      if (!this.data) {
        this._subscriptions.push(combineLatest([
          this.machineStore.Listen(s => s.data),
          this.machinesLoggerStore.Listen(s => s.data),
          this.dateControl.valueChanges]).pipe(
          map(([machines, machinesLogger, dateValue]) => {
            const date: Date = dateValue;
            return machines.filter(m =>
              !machinesLogger.some(ml =>
                ml.Machine.Id === m.Id &&
                new Date(ml.DateFrom).valueOf() <= date.valueOf() &&
                date.valueOf() <= new Date(ml.DateTo).valueOf()));
          })
        ).subscribe(m => this._machines.next(m)));

        if (this.fieldControl) {
          this._subscriptions.push(this.fieldControl.valueChanges.subscribe(
            (fieldBase: IFieldBase) => {
              const field = this.fieldStore.getFieldById(fieldBase.Id);
              const geom = this.fieldStore.getCurrentFieldGeom(field);
              if (field && geom) {
                this.workedAreaControl.setValue(geom ? geom.AreaHa : 0);
                this._maxArea.next(geom ? geom.AreaHa : 0);
              } else {
                this.workedAreaControl.setValue(undefined);
                this._maxArea.next(undefined);
              }
            }
          ));
        }

        if (this.timeFromControl) {
          this._subscriptions.push(this.timeFromControl.valueChanges.subscribe(
            (from: Date) => {
              if (from) {
                this._minDate.next(from);
              } else {
                this._minDate.next(new Date(Date.UTC(0, 0, 0)));
              }
              const to: Date = this.timeToControl.value;
              if (to) {
                const duration = ApDocuUtils.getDuration(new Date(moment(to).diff(moment(from)))).toDate();
                this.durationControl.setValue(duration, {emitEvent: false});
                if (duration.toString() !== 'Invalid Date') {
                  this._maxDuration.next(duration);
                }
                if (to.valueOf() < from.valueOf()) {
                  this.timeToControl.setValue(from);
                }
              }
            }
          ));
        }

        if (this.timeToControl) {
          this._subscriptions.push(this.timeToControl.valueChanges.subscribe(
            (to: Date) => {
              const from: Date = this.timeFromControl.value;
              if (from) {
                const duration = ApDocuUtils.getDuration(new Date(moment(to).diff(moment(from)))).toDate();
                this.durationControl.setValue(duration, {emitEvent: false});
                if (duration.toString() !== 'Invalid Date') {
                  this._maxDuration.next(duration);
                }
              }
            }
          ));
        }

        if (this.attMachineControl) {
          this._subscriptions.push(this.attMachineControl.valueChanges.subscribe(
            (machine: IMachine) => {
              if (machine && this.attInstrumentControl) {
                this.attInstrumentControl.setValue(machine.DefaultInstrument);
              }
            }
          ));
        }
      }
    }, 1);
  }

  private _getHarvestEnd(fleetActionAttachment: IFleetActionAttachment): boolean {
    if (!!fleetActionAttachment) {
      const field = this.fieldStore.getFieldById(fleetActionAttachment.FieldId);
      if (field?.FieldCrops?.length) {
        const harvestEndAttachment = field.FieldCrops[0].HarvestAttachment;
        if (harvestEndAttachment) {
          return harvestEndAttachment.Id === fleetActionAttachment.Id;
        }
      }
    }
    return false;
  }

  private _save(data: any): void {
    const from = new Date(
      data.Date.getFullYear(), data.Date.getMonth(), data.Date.getDate(),
      data.TimeFrom.getHours(), data.TimeFrom.getMinutes(), data.TimeFrom.getSeconds());
    const to = new Date(
      data.Date.getFullYear(), data.Date.getMonth(), data.Date.getDate(),
      data.TimeTo.getHours(), data.TimeTo.getMinutes(), data.TimeTo.getSeconds());
    const duration = 3600 * data.Duration.getHours() + 60 * data.Duration.getMinutes() + data.Duration.getSeconds();

    data.Attachment.IsModified = true;
    data.Attachment.FarmId = this.loginStore.SelectedFarmId;
    data.Attachment.From = this.dateService.toUtc(from).format('YYYY-MM-DDTHH:mm:ss');
    data.Attachment.To = this.dateService.toUtc(to).format('YYYY-MM-DDTHH:mm:ss');
    const action: IFleetActionAttachment = {
      Id: null,
      Attachment: data.Attachment,
      Status: 1,
      FieldId: data.Field.Id,
      LastAction: from,
      Slices: [
        {
          BruttoArea: data.Traversed_Area,
          Way: 0,
          NettoArea: data.Worked_Area,
          Attachment: null,
          FieldIdOnEnd: data.Field.Id,
          FieldIdOnStart: data.Field.Id,
          Type: ActionType.Movement,
          Duration: duration,
          Id: ApGuidUtil.getEmptyGuid(),
          To: to,
          From: from,
          GroupId: 0,
          Hhid: data.Attachment.Machine.HHID,
          Imei: null,
          InField: true,
          NumPoints: 0,
          Valid: true,
          Processing: [],
          FarmId: this.loginStore.SelectedFarmId,
          CreatedAt: null,
          CreatedBy: null,
          ChangedAt: null,
          ChangedBy: null,
          DeletedAt: null,
          DeletedBy: null,
          Version: 0,
        }
      ]
    };
    this.fleetActionAttachmentStore.createFleetActionAttachment(action);
  }

  private _update(data: IFleetActionAttachment): void {
    data.Attachment.IsModified = true;
    this.fleetActionAttachmentStore.saveFleetActionAttachments(data);
  }
}

import IMachine = Data.DocuContext.Machine.IMachine;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum MachinesloggerActions {
  MACHINESLOGGER_LOAD = '[machineslogger] load',
  MACHINESLOGGER_LOAD_SUCCESS = '[machineslogger] load success',
  MACHINESLOGGER_UPDATE = '[machineslogger] update',
  MACHINESLOGGER_CREATE = '[machineslogger] create',
  MACHINESLOGGER_DELETE = '[machineslogger] delete',
  MACHINESLOGGER_SAVE_SUCCESS = '[machineslogger] save success',
}

export class MachinesLoggerLoad implements IAction {
  readonly type = MachinesloggerActions.MACHINESLOGGER_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesLoggerLoadSuccess implements IAction {
  readonly type = MachinesloggerActions.MACHINESLOGGER_LOAD_SUCCESS;

  constructor(public payload: IMachine[]) {
  }
}

export class MachinesLoggerUpdate implements IAction {
  readonly type = MachinesloggerActions.MACHINESLOGGER_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesLoggerCreate implements IAction {
  readonly type = MachinesloggerActions.MACHINESLOGGER_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachinesLoggerDelete implements IAction {
  readonly type = MachinesloggerActions.MACHINESLOGGER_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {Injectable}          from '@angular/core';
import {CampaignYearRange}   from '../../types/common-types';
import {ApDateService}       from '../../ap-core/services/ap-date-service';
import {DateRange, DateTime} from 'ts-tooling';

@Injectable({providedIn: 'root'})
export class CampaignYearService {

  constructor(private dateService: ApDateService) {
  }

  public isCampaignYearIntersects(from: Date, to: Date, campaignYear: CampaignYearRange): boolean {
    try {
      if (from > to) {
        console.warn(`'from' date is bigger then 'to; date`);
        return false;
      }
      const zoneName = DateTime.LocalZoneName();
      const campaignYearStart = DateTime.FromISOString(campaignYear.StartYear.toISOString(), zoneName);
      const campaignYearEnd = DateTime.FromISOString(campaignYear.EndYear.toISOString(), zoneName);
      const isoFrom = DateTime.FromISOString(from.toISOString(), zoneName);
      const isoTo = DateTime.FromISOString(to.toISOString(), zoneName);
      const range = new DateRange(isoFrom, isoTo);
      const yearRange = new DateRange(campaignYearStart, campaignYearEnd);
      return yearRange.Overlaps(range);
    } catch (ex) {
      console.warn(`Error in 'isCampaignYearIntersects'. Error object: ${JSON.stringify(ex)}`);
      return false;
    }
  }

  public getCampaignYearRange(year: number, endYear?: number): CampaignYearRange {
    const startYear = year - 1;
    const yearEnd = endYear ? endYear : year;
    return this._createCampaignYearRange(startYear, yearEnd);
  }

  public getCampaignYearByDate(date: Date): CampaignYearRange {
    if (!date) {
      const utcDate = this.dateService.getUtc();
      const farmDate = this.dateService.toFarmDateFromUtcGetJsDate(utcDate);
      return this.getCampaignYearByDate(farmDate);
    }
    const month = date.getMonth();
    const year = date.getFullYear();
    let yearStart: number;
    let yearEnd: number;
    if (month >= this._getCampaignYearStart(year).getMonth()) {
      yearStart = year;
      yearEnd = year + 1;
    } else {
      yearStart = year - 1;
      yearEnd = year;
    }
    return this._createCampaignYearRange(yearStart, yearEnd);
  }

  /**
   * Calculates the campaign year begin date from a given sample date.
   * Campaign years start on August 1st of each year.
   * If the sample date is before July, the campaign year starts on August 1st of the previous year.
   * If the sample date is July or later, the campaign year starts on August 1st of the current year.
   *
   * @param sampleDate - Input date to calculate campaign year start from
   * @returns Campaign year start date or null if input is null
   */
  public getCampaignYearBeginFromSampleDate(sampleDate: Date | undefined): Date | undefined {
    if (!sampleDate) {
      return undefined;
    }
    const year = sampleDate.getFullYear();
    const month = sampleDate.getMonth() + 1;
    const soilSampleCampaignYear = month < 7 ? year - 1 : year;
    return this._getCampaignYearStart(soilSampleCampaignYear);
  }

  private _createCampaignYearRange(yearStart: number, yearEnd: number): CampaignYearRange {
    const startCampaignYearDate = this._getCampaignYearStart(yearStart);
    const endCampaignYearDate = this._getCampaignYearEnd(yearEnd);
    return {
      CampaignYear: yearEnd,
      StartYear: startCampaignYearDate,
      EndYear: endCampaignYearDate
    };
  }

  private _getCampaignYearStart(year: number): Date {
    // month August
    return new Date(year, 7, 1, 0, 0, 0);
  }

  private _getCampaignYearEnd(year: number): Date {
    // month July
    return new Date(year, 6, 31, 23, 59, 59, 999);
  }
}

import {Component, Input}           from '@angular/core';
import {ApDynGridColorColumnConfig} from '../config/ap-dyn-grid-color-column-config';

@Component({
  selector: 'ap-color-column-cell',
  template: `
    <div [class]="config.cssClass | centerCell">
      <kendo-colorpicker
        [view]="'gradient'"
        [format]="'rgba'"
        [disabled]="!config.enable"
        (close)="config.callback.emit(dataItem)"
        [value]="dataItem[config.field]"
        (valueChange)="changeItem(dataItem, config.field, $event)">
      </kendo-colorpicker>
    </div>
  `,
  styles: []
})
export class ApColorColumnCellComponent {
  @Input() config: ApDynGridColorColumnConfig;
  @Input() dataItem: any;

  changeItem(dataItem, field: string, colorCode: string): void {
    dataItem[field] = colorCode;
  }
}

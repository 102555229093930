<svg
  [attr.height]="height"
  [attr.width]="width"
  [attr.viewBox]="viewBox">
  <path
    [attr.d]="shape"
    [attr.fill]="fillColor"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    fill-rule="evenodd">
  </path>
</svg>

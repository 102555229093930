import {Store}            from '../index';
import {Injectable}       from '@angular/core';
import {
  IModalDialogButton,
  IModalDialogData,
  IModalDialogFormConfig,
  IModalDialogResult
} from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {Observable}       from 'rxjs';
import {filter, map}      from 'rxjs/operators';
import {ObjectFactory}    from 'ts-tooling';
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IModalDialogStore {
  title: string;
  busy: boolean;
  message: string;
  show: boolean;
  buttons: IModalDialogButton[];
  result: IModalDialogResult;
  formConfig: IModalDialogFormConfig;
  formValues: any;
  formChanges: any;
  resultDelegate: (dialogResult: IModalDialogResult) => void;
}

@Injectable({providedIn: 'root'})
export class ModalDialogStore extends Store<IModalDialogStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      title: '',
      busy: false,
      message: '',
      show: false,
      buttons: [],
      result: {key: undefined, formValues: undefined},
      formConfig: undefined,
      formValues: {},
      formChanges: {},
      resultDelegate: undefined
    }, true);
  }

  public setModalDialogData(data: IModalDialogData): void {
    super.Mutate(s => s.buttons, () => data.buttons);
    super.Mutate(s => s.formConfig, () => data.formConfig);
    super.Mutate(s => s.message, () => data.message);
    super.Mutate(s => s.show, () => data.show);
    super.Mutate(s => s.busy, () => data.busy === true);
    super.Mutate(s => s.title, () => data.title);
    super.Mutate(s => s.formChanges, () => data.values);
    super.Mutate(s => s.resultDelegate, () => data.resultDelegate);
    super.Mutate(s => s.result, () => ({key: undefined, formValues: undefined} as IModalDialogResult));
  }

  public setShow(show: boolean): void {
    super.Mutate(s => s.show, () => show);
  }

  public sendResult(key: string, formValues: any): void {
    super.Mutate(s => s.result, () => ({key, formValues}));
    // to get rid of subscribe/unsubscribe mess with modal dialogs, lets use a more robust delegate handling
    // for now the delegate is optional
    const resultDelegate = super.Listen(s => s.resultDelegate)?.getValue();
    if (resultDelegate) {
      resultDelegate({key, formValues} as IModalDialogResult);
    }
  }

  public getFormValues(): any {
    return super.Listen(s => s.formValues).getValue();
  }

  public setFormValues(value: any): void {
    super.Mutate(s => s.formValues, () => value);
  }

  public setFormChanges(value: any): void {
    super.Mutate(s => s.formChanges, () => value);
  }

  public listenChanges(): Observable<any> {
    return super.Listen(s => s.formChanges).pipe(
      filter((v) => v != null),
      map(formChanges => ObjectFactory.Copy(formChanges))
    );
  }
}

import {AfterContentInit, Component, ViewChild} from '@angular/core';
import {Validators}                             from '@angular/forms';
import {find, merge}                            from 'lodash';
import {ApDynformsComponent}                    from '../../ap-dynforms/ap-dynforms.component';
import {ApDynformsConfigFieldset} from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigTextbox,
  ApDynformsConfigTextboxType
}                                 from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsValidator}      from '../../ap-dynforms/ap-dynforms-validator';
import {ApDynformsConfigCheckbox}               from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {ApDynformsConfigMultiSelect}            from '../../ap-dynforms/config/ap-dynforms-config-multiselect';
import {ApDynformsConfigComboBox}               from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {ApGetAvailableUserGroupsPipe}           from '../../stores/pipes/ap-get-available-usergroups.pipe';
import {ApGetAssignedUserGroupsPipe}            from '../../stores/pipes/ap-get-assigned-usergroups.pipe';
import {DriverStore}                            from '../../stores/docu/driver.store';
import {FarmUsersStore}                         from '../../stores/farm/farm.users.store';
import {FormStore}                              from '../../stores/layout/form.store';
import {TranslationStore}                       from '../../stores/translation/translation.store';
import {UserGroupStore}                         from '../../stores/settings/user.group.store';
import {Subscription}                           from 'rxjs';
import IDriver = Data.DocuContext.Driver.IDriver;
import IFarmUsers = Data.Authentication.IFarmUsers;


@Component({
  selector: 'ap-users-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_user_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_user_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`,
})
export class ApUsersEditComponent implements AfterContentInit {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: IFarmUsers;
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;
  public drivers: IDriver[];
  private _subscriptions: Array<Subscription> = [];
  public listDriverItems: Array<{ text: string, value: System.IGuid }> = [];
  public listUsergroupItems: Array<{ text: string, value: number }> = [];
  public assignedUserGroups: Array<{ text: string, value: number }> = [];

  constructor(private formStore: FormStore,
              private translationStore: TranslationStore,
              private driverStore: DriverStore,
              private farmUsersStore: FarmUsersStore,
              private userGroupsStore: UserGroupStore,
              private getAvailableUserGroups: ApGetAvailableUserGroupsPipe,
              private getAssignedUserGroups: ApGetAssignedUserGroupsPipe) {
  }

  ngAfterContentInit(): void {
    this.caption = 'Permission_Feature_Edit_Users';
    this.getDataFromStore();
    this.createDriverComboBox();
    this.formBuilder();
  }

  getDataFromStore(): void {
    this.drivers = this.driverStore.Listen(s => s.data).getValue();
    this.listUsergroupItems = this.getAvailableUserGroups.transform();
    this.assignedUserGroups = [];
    for (const userGroupUser of this.data.UsergroupUser) {
      this.assignedUserGroups.push({
        text: this.translationStore.FindTranslationForSelectedLanguage(userGroupUser?.Usergroup?.Name),
        value: userGroupUser?.Usergroup?.Id
      });
    }
    this.formBuilder();
  }

  createDriverComboBox(): void {
    this.listDriverItems.push({
      value: null,
      text: '<' + this.translationStore.FindTranslationForSelectedLanguage('Global_No_Worker') + '>'
    });

    this.listDriverItems = this.drivers.map(d => ({
      text: `${d.Name}, ${d.Firstname}`,
      value: d.Id
    })).sort((a, b) => a.text !== b.text ? a.text < b.text ? -1 : 1 : 0);
  }

  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigTextbox({
            key: 'UserName',
            label: 'Global__Username',
            type: ApDynformsConfigTextboxType.Text,
            value: this.data.User.UserName,
            disabled: true,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
              }),
              new ApDynformsValidator({
                validator: Validators.email,
                errorKey: 'Admin_Pages_Login_Reset_Password_Email_Error'
              }),
              new ApDynformsValidator({
                validator: Validators.nullValidator,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(1),
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'Email',
            label: 'Global__Email',
            type: ApDynformsConfigTextboxType.Email,
            value: this.data.User.EMail,
            disabled: true,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
              }),
              new ApDynformsValidator({
                validator: Validators.email,
                errorKey: 'Admin_Pages_Login_Reset_Password_Email_Error'
              }),
              new ApDynformsValidator({
                validator: Validators.nullValidator,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(1),
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigCheckbox({
            key: 'IsActive',
            label: 'Global__Active',
            value: this.data.IsActive,
          }),
          new ApDynformsConfigMultiSelect({
            key: 'UserGroups',
            value: this.assignedUserGroups,
            valueField: 'value',
            textField: 'text',
            disabled: true,
            label: 'Global_User_Groups',
            options: this.listUsergroupItems,
            valuePrimitive: false,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Select_Category'
            })]
          }),
          new ApDynformsConfigComboBox({
            key: 'Driver',
            valueField: 'value',
            textField: 'text',
            valuePrimitive: true,
            value: this.data.Driver !== null ? this.data.Driver.Id : null,
            label: 'Global__Worker',
            options: this.listDriverItems,
          })
        ]
      })
    ];
  }

  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    const farmUser = this.data as IFarmUsers;

    const driver = this.dynForm.form.get('Driver').value;
    if (driver === null) {
      farmUser.Driver = null;
    } else {
      farmUser.Driver = find(this.drivers, _ => _.Id === driver);
    }
    this.farmUsersStore.update(farmUser);
    this.formStore.closeForm();
  }

  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

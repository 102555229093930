import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  ApKendoGridExtension,
  IdValue
}                                                                 from '../ap-core/extensions/kendo/ap-kendo-grid-extension';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType
}                                                                 from './config/ap-dyn-grid-column-config-base';
import {IGridPagerData, IWizardChoiceData, MapViewMode}           from '../ap-interface';
import {
  ApDynGridPagerConfigBase,
  ApDynGridPagerControlType
}                                                                 from './config/ap-dyn-grid-pager-config-base';
import {
  ApDynGridPagerWizardConfig,
  ApDynGridPagerWizardSectionType
}                                                                 from './config/ap-dyn-grid-pager-wizard-config';
import {
  ApDynGridGroupColumnConfig
}                                                                 from './config/ap-dyn-grid-group-column-config';
import {
  IDynGridWizardChoiceData,
  IDynGridWizardChoiceDataValue,
  IGridPagerChoiceSelect
}                                                                 from './config/ap-dyn-grid-pager-config';
import {
  ApDynformsConfigFieldset
}                                                                 from '../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApGridPagerComponent
}                                                                 from '../ap-core/components/ap-grid-pager/ap-grid-pager.component';
import {get, isEqual}                                             from 'lodash';
import {
  ApDynformsConfigTabs
}                                                                 from '../ap-dynforms/config/ap-dynforms-config-tabs';
import {debounceTime, filter, map}                                from 'rxjs/operators';
import {
  Trace
}                                                                 from '../debug-utils/ApplicationTracer';
import {
  AsPagerPipe
}                                                                 from './pipes/convert/as-pager.pipe';
import {
  AsWizardButtonPipe
}                                                                 from './pipes/convert/as-wizard-button.pipe';
import {
  AsWizardExportPipe
}                                                                 from './pipes/convert/as-wizard-export.pipe';
import {
  ColumnBase,
  ColumnGroupComponent,
  ExcelExportEvent,
  GridComponent,
  RowArgs,
  RowClassArgs,
  SelectableSettings,
  SelectionEvent
}                                                                 from '@progress/kendo-angular-grid';
import {
  SettingsStore
}                                                                 from '../stores/base-data/settings.store';
import {
  LoginStore
}                                                                 from '../stores/login/login.store';
import {
  MapViewStore
}                                                                 from '../stores/layout/mapview.store';
import {
  LanguageStore
}                                                                 from '../stores/translation/language.store';
import {
  ApDynGridDetailsBaseConfig,
  ApDynGridDetailsConfigType
}                                                                 from './config/details/ap-dyn-grid-details-base-config';
import {
  PopupSettings as ButtonPopupSettings
}                                                                 from '@progress/kendo-angular-buttons';
import {
  ApCalendarView
}                                                                 from '../ap-utils/enums/ApCalendarView';
import {FormGroup}                                                from '@angular/forms';
import {
  ApGridWizardComponent
}                                                                 from '../ap-core/components/ap-grid-wizard/ap-grid-wizard.component';
import {
  FilterType
}                                                                 from './config/ap-dyn-grid-property-column-config';
import {
  ApDateService
}                                                                 from '../ap-core/services/ap-date-service';
import {
  GetRoundNumericService
}                                                                 from '../ap-utils/service/get-round-numeric.service';
import {
  ScrollMode
}                                                                 from '@progress/kendo-angular-grid/dist/es2015/scrolling/scrollmode';
import {Clipboard}                                                from '@angular/cdk/clipboard';
import {
  ApTranslationService
}                                                                 from '../ap-utils/service/ap-translation.service';
import {
  ApDyngridsExcelService
}                                                                 from './ap-dyngrids-excel.service';
import IFarm = Data.Authentication.IFarm;
import {
  SortSettings
}                                                                 from '@progress/kendo-angular-grid/dist/es2015/columns/sort-settings';
import {ApRoleTypeService}                                        from '../services/common/ap-role-type.service';
import {ApMapInstance}                                            from '../ap-map';
import {ApWindowHelperService}                                    from '../ap-core/services/ap-window-helper.service';

// TODO: implement in ts-tooling and replace it
function flatArray(arr: any[]): any[] {
  const tmp = [];
  for (const element of arr) {
    if (Array.isArray(element)) {
      tmp.AddRange(element);
      continue;
    }
    tmp.Add(element);
  }
  return tmp;
}

let observer;

/**
 * TODO InlineEdit für Tranlsation ermöglichen --> Jira Ticket angelegt https://jira.agricon.de/browse/APV49-822
 *
 * text-align: center;
 * display: flex;
 * align-items: center;
 * justify-content: center;
 */
@Component({
  selector: 'ap-dyngrids',
  templateUrl: './ap-dyngrids.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ApDynGridsComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit, OnChanges {

  @ViewChild('grid', {static: true}) grid: GridComponent;
  @ViewChild('gridPager', {static: false}) gridPager: ApGridPagerComponent;
  public ApDynGridColumnControlType = ApDynGridColumnControlType;
  public ApDynGridPagerControlType = ApDynGridPagerControlType;
  public ApDynGridPagerWizardSectionType = ApDynGridPagerWizardSectionType;
  public ApDynGridDetailsConfigType = ApDynGridDetailsConfigType;
  public gridSelectableSettings = new BehaviorSubject<SelectableSettings>({});
  public FilterTypes = FilterType;
  @Input() columns: ApDynGridColumnConfigBase[];
  @Input() details: ApDynGridDetailsBaseConfig;
  @Input() items: any[];
  @Input() loading$: Observable<true>;
  @Input() pager: ApDynGridPagerConfigBase = new ApDynGridPagerConfigBase();
  @Input() level = 0;
  @Input() exportFileName: string;
  @Input() pdfExportFileName: string;
  @Input() pdfExportTitle = '';
  @Input() xlsExportFileName: string;
  @Input() caption = '';
  @Input() headerIcon;
  @Input() headerSvg;
  @Input() filterTooltip = '';
  @Input() canSearch = false;
  @Input() canCreate = false;
  @Input() reorderable = false;
  @Input() pageable = true;
  @Input() groupable = true;
  @Input() sortBySelection = false;
  @Input() rowSelected: (e: RowArgs) => boolean;
  @Input() set selectable(selectableSetting: SelectableSettings) {
    this.gridSelectableSettings.next(
      this.calculateSelectableSettings(selectableSetting, ApMapInstance.isDebugModeEnabled));
  }
  get selectable(): SelectableSettings {
    return this.gridSelectableSettings?.value;
  }
  @Input() errorKeys: string[];
  @Input() canInlineEdit = false;
  @Input() checkboxDisabled = false;
  @Input() scrollable: ScrollMode;
  @Input() sortable: SortSettings;
  @Input() hideHeader = false;
  /**
   * In case our grid is embedded within a ng-template @ViewChild cannot get an instance
   * of our grid. The instance is needed to handle selection etc.
   * The property can be access from html-template and provides the grid's instance
   * through the BehaviorSubject
   */
  @Input() componentReference$: BehaviorSubject<ApDynGridsComponent>;
  @Output() edit = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<SelectionEvent>();
  @Output() create = new EventEmitter<any>();
  @Output() initialized = new EventEmitter(true);
  public gridPaging = new ApKendoGridExtension(this.dateService, this.roundNumericService);
  public mapViewModes = MapViewMode;
  public calendarView = ApCalendarView;
  public mapViewState$: Observable<MapViewMode>;
  public farm$: Observable<IFarm>;
  public formDefaultValue$: Observable<any>;
  public pagerFormConfig: ApDynformsConfigFieldset[] | ApDynformsConfigTabs[] = undefined;
  public pagerKeyWizardChoice: IDynGridWizardChoiceData = null;
  public pagerValueWizardChoice: IDynGridWizardChoiceDataValue = null;
  public _agriconShowHiddenProperties = false;
  public _agriconHiddenProperties: string[] = [];
  public checkboxColumnWidth = 30;
  public commandColumnWidth = 37;
  public wizardSubject$ = new BehaviorSubject<ApGridWizardComponent>(undefined);
  dropDownButtonPopupSettings: ButtonPopupSettings = {
    align: 'right',
    animate: true,
    popupClass: 'ap-kendo-dropdown-button'
  };
  public widthHiddenColumns$ = new BehaviorSubject<ApDynGridColumnConfigBase[]>([]);
  public pagerHeight$ = new BehaviorSubject<number>(0);
  private _agriconIsControlKeyDown = false;
  private _isControlKeyDown = false;
  private _agriconTextToCopy = '';
  private _subscriptions: Subscription[] = [];
  private _viewWidth$ = new BehaviorSubject(0);
  private _viewHeight$ = new BehaviorSubject(0);

  constructor(private mapViewStore: MapViewStore,
              public languageStore: LanguageStore,
              public asPagerPipe: AsPagerPipe,
              public asWizardButtonPipe: AsWizardButtonPipe,
              public settingsStore: SettingsStore,
              public asWizardExportPipe: AsWizardExportPipe,
              private loginStore: LoginStore,
              private dateService: ApDateService,
              private roundNumericService: GetRoundNumericService,
              private clipboard: Clipboard,
              private translationService: ApTranslationService,
              private windowHelperService: ApWindowHelperService) {
    if (this.sortable != null) {
      this.gridPaging.sortable = this.sortable;
    }
    this.gridPaging.sortBySelection = this.sortBySelection;
  }

  /**
   * Do some magic after everything has been initialized
   */
  ngAfterContentInit(): void {
    // In case the debug mode is enabled (available for Agricon admins only)
    // hidden columns will be shown as well
    this._subscriptions.push(this.loginStore.Listen(s => s.isDebugModeEnabled).subscribe(isDebugModeEnabled =>
    {
      this._agriconShowHiddenProperties = isDebugModeEnabled;
      this.toggleHiddenProperties();
      // update selectable settings depending on debugMode on/off
      this.gridSelectableSettings.next(this.calculateSelectableSettings(this.selectable, isDebugModeEnabled));
    }));
    }

  @ViewChild(ApGridWizardComponent, {static: false})
  set DynGrid(wizard: ApGridWizardComponent) {
    this.wizardSubject$.next(wizard);
  }

  private get _WidthHideColumns(): any[] {
    return flatArray(this.columns
      .map(c => {
        if (c.type === ApDynGridColumnControlType.ColumnGroup) {
          return [c, ...(c as ApDynGridGroupColumnConfig).groupColumns];
        }
        return [c];
      }))
      .filter((c: ApDynGridColumnConfigBase) => !c.hide.mapHide && !this.widthHiddenColumns$.value.Any(hc => {
        return c.equals(hc);
      }) && c.hide.widthHidePriority !== undefined)
      .sort(c => c.hide.widthHidePriority);
  }

  private get _TableWidth(): number {
    let currentWidth =
      this.grid.columns.filter(c => !c.hidden).map((c) => {
        const getColumns = (cb: ColumnBase) => {
          if (cb instanceof ColumnGroupComponent) {
            return flatArray([...cb.children.filter(child => !child.hidden).map(child => getColumns(child))]);
          }
          return cb;
        };
        return getColumns(c);
      }).reduce((acc, column: ColumnBase | ColumnBase[]) => {
        if (Array.isArray(column)) {
          return acc + column.reduce((a, col) => a + col.width, 0);
        }
        return acc + column.width;
      }, 0);
    if (this.details && this.details.type !== ApDynGridDetailsConfigType.None) {
      currentWidth += 32;
    }
    for (const group of this.grid.group) {
      currentWidth += 32;
    }
    return currentWidth + 18;
  }

  private static _getHeight(key: string): number {
    const element = document.querySelector(key);
    if (!element) {
      return 0;
    }
    const style = window.getComputedStyle(element);
    const toNumber = (s: string) => +s.split('px')[0];
    return toNumber(style.height)
      + toNumber(style.paddingTop) + toNumber(style.paddingBottom)
      + toNumber(style.borderTop) + toNumber(style.borderBottom)
      + toNumber(style.marginTop) + toNumber(style.marginBottom);
  }

  private static _isArray(x): boolean {
    return !!x && x instanceof Array;
  }

  private static _isObject(x): boolean {
    return !!x && typeof x === 'object';
  }

  private static _intersectObject(a, b, callCount = 0): any {
    callCount++;
    const result = {};

    if (([a, b]).every(this._isObject)) {
      Object.keys(a).forEach((key) => {
        const value = a[key];
        const other = b[key];

        if (value !== null && value !== undefined) {
          if (this._isCyclic(value)) {
            // do nothing
          } else if (this._isArray(value)) {
            result[key] = this._intersectArray(value, other);
          } else if (this._isObject(value)) {
            result[key] = this._intersectObject(value, other, callCount);
          } else if (value === other) {
            result[key] = value;
          }
        }
      });
    }

    return result;
  }

  private static _isCyclic(obj): boolean {
    const seenObjects = [];

    function detect(innerObj): boolean {
      if (innerObj && typeof innerObj === 'object') {
        if (seenObjects.indexOf(innerObj) !== -1) {
          return true;
        }
        seenObjects.push(innerObj);
        for (const key in innerObj) {
          if (innerObj.hasOwnProperty(key) && detect(innerObj[key])) {
            // console.log(innerObj, 'cycle at ' + key);
            return true;
          }
        }
      }
      return false;
    }

    return detect(obj);
  }

  private static _intersectArray(a: any[], b: any[]): any[] {
    const result = [];
    if (([a, b]).every(this._isArray)) {
      a.forEach(value => {
        if (b.find(v => isEqual(value, v)) !== undefined) {
          result.push(value);
        }
      });
    }
    return result;
  }

  private static _getDefaultObject<T>(selection: T[]): T {
    return selection.length ? selection.reduce((a, b) => this._intersectObject(a, b), selection[0]) : {};
  }

  @Trace()
  ngOnInit(): void {
    this.componentReference$?.next(this);
    this._getDataFromStore();
  }

  @Trace()
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columns']) {
      this.gridPaging.columnChange(this.columns);
    }
    if (changes['reorderable']) {
      this.gridPaging.reorderable = this.reorderable;
    }
    if (changes['items'] && changes['items'].currentValue) {
      this.toggleHiddenProperties();
      if (this.items.FindAll(item => !item).Count() > 0) {
        this.items = this.items.FindAll(item => !!item);
      }
      const selectedKeys = this.gridPaging.selectedKeys;
      this.gridPaging.init(this.items, this.columns);
      const keys = this.items.map(item => item.Id);
      this.gridPaging.setSelectedKeys(selectedKeys.filter(key => keys.indexOf(key) !== -1));
    }
    if (changes['rowSelected']) {
      if (typeof changes['rowSelected'].currentValue === 'function') {
        this.grid.rowSelected = this.rowSelected;
      }
    }
  }

  @Trace()
  ngAfterViewInit(): void {
    if (this.rowSelected) {
      if (typeof this.rowSelected === 'function') {
        this.grid.rowSelected = this.rowSelected;
      }
    }

    this.formDefaultValue$ =
      this.gridPaging.onSelectedItemChange.pipe(
        map(s => ApDynGridsComponent._getDefaultObject(s)),
      );

    const apMainViewElement = document.querySelector('.ap-main-view');
    if (apMainViewElement) {
      observer = new ResizeObserver((entries) => {
        const subject = {
          width: this._viewWidth$,
          height: this._viewHeight$
        };
        if (subject.width && subject.width.valueOf() !== entries[0].contentRect.width) {
          subject.width.next(entries[0].contentRect.width);
        }
        if (subject.height && subject.height.valueOf() !== entries[0].contentRect.height) {
          subject.height.next(entries[0].contentRect.height);
        }
      });
      observer?.observe(document.querySelector('.ap-main-view'));
    }

    this._subscriptions.push(this._viewWidth$.pipe(
      debounceTime(100),
      filter(width => !!width),
    ).subscribe((grid) => {
      const tableWidth = this._TableWidth;
      if (grid < tableWidth) {
        const columns = this._WidthHideColumns;

        let width = 0;
        while (columns.length !== 0 && grid < tableWidth - width) {
          const column = columns[0];
          columns.Remove(column);
          width += column.width;
          this.widthHiddenColumns$.next([...this.widthHiddenColumns$.value, column]);
        }
      } else if (this.widthHiddenColumns$.value.length !== 0) {
        let columns = this.widthHiddenColumns$.value;
        let width = columns.LastOrDefault().width;
        while (columns.length !== 0 && grid > tableWidth + width) {
          this.widthHiddenColumns$.next(
            columns.filter((v, i) => i < columns.length - 1));
          columns = this.widthHiddenColumns$.value;
          width += columns.length !== 0 ? columns.LastOrDefault().width : 0;
        }
      }
    }));
    this.initialized.emit();

    this._subscriptions.push(ApMapInstance.onFieldClicked.subscribe(field => {
      if (!field || field?.Id?.toString()?.length <= 0) {
        return;
      }

      const updatedSelectedKeys = this.gridPaging.selectedKeys?.slice() ?? [];
      const selectedFieldIndex = this.gridPaging?.selectedKeys?.FindIndex(id => id?.toString() === field?.Id?.toString());
      if (selectedFieldIndex >= 0) {
        updatedSelectedKeys.RemoveAt(selectedFieldIndex);
      } else {
        updatedSelectedKeys.Add(field?.Id?.toString());
      }
      this.gridPaging?.setSelectedKeys(updatedSelectedKeys, true);
      this.gridPaging?.updateGridView();
    }));
  }

  @Trace()
  ngOnDestroy(): void {
    this._subscriptions.filter(s => s.unsubscribe());
    const domObject = document.querySelector('.ap-main-view');
    if (domObject) {
      observer?.unobserve(domObject);
    }
    observer = null;
  }

  @Trace()
  editHandler(item): void {
    this.edit.emit(item.dataItem);
  }

  @Trace()
  createHandler(): void {
    this.create.emit();
  }

  @Trace()
  filterGrid(searchValue: string): void {
    this.gridPaging.filterChange(searchValue);
  }

  @Trace()
  clearFilters(): void {
    this.gridPaging.filter = { logic: 'and', filters: [] };
    this.gridPaging.updateGridView();
  }

  @Trace()
  itemDisabled(args: { index: number, dataItem: IWizardChoiceData }): boolean {
    return this.gridPaging.isWizardChoiceDisabled(args.dataItem);
  }

  @Trace()
  pagerApplyClicked(event: IGridPagerData): void {
    const cb =
      event.value && (event.value as IDynGridWizardChoiceDataValue).onSubmit ?
        (event.value as IDynGridWizardChoiceDataValue).onSubmit :
        (event.object as IDynGridWizardChoiceData)?.onSubmit;
    if (cb) {
      cb.emit({
        ids: this.gridPaging.selectedKeys,
        items: this.gridPaging.selectedItems,
        value: event.value,
        form: this._getPagerForm()
      });
    }
  }

  @Trace()
  public exportAsPdf(): void {
    this.grid.saveAsPDF();
  }

  @Trace()
  public exportAsXls(): void {
    this.grid.saveAsExcel();
  }

  @Trace()
  gridPagerSelectionChange(event: IDynGridWizardChoiceData): void {
    this.pagerKeyWizardChoice = event;
    this._setupPagerFormConfig();
  }

  @Trace()
  gridPagerSelectionValueChange(event: IDynGridWizardChoiceDataValue): void {
    this.pagerValueWizardChoice = event;
    this._setupPagerFormConfig();
  }

  @Trace()
  onSelectedKeysChange(keys: IdValue[]): void {
    this.gridPaging.onSelectedKeysChange(keys);
    if (this.gridPager) {
      if (keys.length === 0 && this.gridPager.gridMultiSelect) {
        this.gridPager.gridMultiSelect.unselect();
      }
      if (this.gridPaging.isWizardChoiceDisabled(this.pagerKeyWizardChoice)) {
        for (const obj of this.gridPager.Objects) {
          if (!this.gridPaging.isWizardChoiceDisabled(obj)) {
            this.gridPager.setSelectedColumn(obj);
            return;
          }
        }
      }
      if (this.gridPaging.isWizardChoiceDisabled(this.pagerValueWizardChoice)) {
        for (const value of this.gridPager.selectedColumn.values) {
          if (!this.gridPaging.isWizardChoiceDisabled(value)) {
            this.gridPager.setSelectedValue(value);
            break;
          }
        }
      }
    }
  }

  @Trace()
  setRowClass(context: RowClassArgs): {
    'no-details': boolean;
    error: boolean;
    master: boolean;
  } {
    const noDetails = this.details && this.details.hide(context.dataItem);
    return {
      'no-details': noDetails,
      error: this.gridPaging && this.errorKeys ? this.errorKeys.indexOf(context.dataItem['Id']) !== -1 : false, // .k-grid tr.error > td .k-grid tr.k-state-selected.error > td
      master: true,
    };
  }

  previousClicked(gridColumn: ApDynGridColumnConfigBase): void {
    switch (gridColumn.type) {
      case ApDynGridColumnControlType.ColumnGroup:
        (gridColumn as ApDynGridGroupColumnConfig).headerButtons.previousClicked.emit();
        break;
    }
  }

  nextClicked(gridColumn: ApDynGridColumnConfigBase): void {
    switch (gridColumn.type) {
      case ApDynGridColumnControlType.ColumnGroup:
        (gridColumn as ApDynGridGroupColumnConfig).headerButtons.nextClicked.emit();
        break;
    }
  }

  updateSelection(formValues: any): void {
    (this.pager as ApDynGridPagerWizardConfig).dynGridWizardFormConfig?.onSubmit?.emit({
      ids: this.gridPaging.selectedKeys,
      items: this.gridPaging.selectedItems,
      value: formValues,
    });
    this.clearSelection();
  }

  clearSelection(): void {
    this.gridPaging.setSelectedKeys([]);
  }

  /**
   * Handles pageSize changed of the grid's pager.
   * Occurs whenever user changed the pageSize from the corresponding dropdown
   */
  public onPageSizeChanged(pageSize: number): void {
    this.gridPaging.pageSize = pageSize;
    this.gridPaging.refresh();
  }

  /**
   * Event handler to monitor if user presses Control-Key
   * @param event the key eventArgs
   */
  @HostListener('document:keydown.control', ['$event']) onKeydownControlHandler(event: KeyboardEvent): void {
    this._isControlKeyDown = true;
    if (!ApRoleTypeService.hasAgriconRole(this.loginStore.User)) {
      return;
    }
    this._agriconIsControlKeyDown = true;
  }

  /**
   * Event handler to monitor if user presses Control-Key
   * @param event the key eventArgs
   */
  @HostListener('document:keyup.control', ['$event']) onKeyupControlHandler(event: KeyboardEvent): void {
    this._isControlKeyDown = false;
    if (!ApRoleTypeService.hasAgriconRole(this.loginStore.User)) {
      return;
    }
    this._agriconIsControlKeyDown = false;
    this._agriconTextToCopy = '';
  }

  /**
   * Toggles the hidden columns to display all hidden properties in the grid.
   */
  public toggleHiddenProperties(): void {
    // skip for:
    // - regular users
    // - all the logic if debug mode is disabled and no hidden properties are currently shown
    if (!ApRoleTypeService.hasAgriconRole(this.loginStore.User) ||
      !this._agriconShowHiddenProperties && this._agriconHiddenProperties?.length === 0) {
      return;
    }

    setTimeout(() => {
      try {
        if (!this._agriconShowHiddenProperties || !this.items || this.items.length < 1) {
          this._agriconHiddenProperties = [];
          return;
        }
        // collect all properties of the dataItem (also from SourceItem)
        let allProperties = this.items[0]?.SourceItem ? Object.getOwnPropertyNames(this.items[0].SourceItem) : [];
        allProperties = allProperties.map(p => {
          p = 'SourceItem.'.concat(p);
          return p;
        });

        allProperties.AddRangeIfNotExists(Object.getOwnPropertyNames(this.items[0]));

        // check if the property is already displayed in grid
        for (const property of allProperties) {
          if (property.toLowerCase() !== 'parent' &&
            property.toLowerCase() !== 'sourceitem' &&
            !this.columns.some(c => (c as any).field === property)) {
            this._agriconHiddenProperties.AddIfNotExists(property);
          }
        }
        // for better maintenance => write current component tag to console
        console.log(`::: ${document?.body?.querySelector('.ap-main-view > div > *:not(router-outlet)')?.nodeName?.toLowerCase()}`);
      }catch (e) {
        console.log(`Error while extending Grid to debugMode: ${JSON.stringify(e)}`);
      }
      finally {
        // Force update of grid view after columns have been changed programmatically
        // this.changeDetection.detectChanges();
        this.gridPaging.columnChange(this.columns);
      }
    }, 1);
  }

  public stringify(dataItem: any): string {
    if (dataItem) {
      return JSON.stringify(dataItem).replace(/^"(.+(?="$))"$/, '$1');
    }
    return '';
  }

  /**
   * Hidden Feature: Copies hidden column's value (on dblClick)
   * If user presses Control-Key while double klicking a list of values is copied to clipboard
   * @param dataItem to be copied
   * @param property dataItem's property to be copied
   */
  public copyToClipboard(dataItem: any, property: string): void {
    if (!dataItem || !ApRoleTypeService.hasAgriconRole(this.loginStore.User) || !this._agriconShowHiddenProperties) {
      return;
    }
    const currentTextToCopy = this.stringify(get(dataItem, property));
    if (!this._agriconIsControlKeyDown) {
      this.clipboard.copy(currentTextToCopy);
      return;
    }

    if (this._agriconTextToCopy?.length > 0) {
      this._agriconTextToCopy = `${this._agriconTextToCopy}, `;
    }
    this._agriconTextToCopy = `${this._agriconTextToCopy}'${currentTextToCopy}'`;
    this.clipboard.copy(this._agriconTextToCopy);
  }

  /**
   * Custom and technical excel export for Agricon support
   * @param eventArgs the excel export eventArgs
   */
  public async onExcelExport(eventArgs: ExcelExportEvent): Promise<void> {
    // Prevent automatically saving the file. We will save it manually after we fetch and add the details
    eventArgs.preventDefault();
    const dynGridsExcelService = new ApDyngridsExcelService(
      this._agriconShowHiddenProperties, this.gridPaging, this.translationService);
    await dynGridsExcelService.exportAsExcel(eventArgs, this.columns, this.details, this.xlsExportFileName);
  }

  /**
   * For performance improvements it is recommended to use trackBy function
   * to make it faster/easier for angular to track changes.
   * The item is identified by id instead of a complete object comparison
   * @param index of column
   * @param column the column's config (id is unique and generated on creation)
   */
  public trackByColumnFn(index: number, column: ApDynGridColumnConfigBase): string {
    return column?.id?.toString();
  }

  private _getPagerForm(): FormGroup | undefined {
    if (this.gridPager && this.gridPager.gridMultiSelect &&
      this.gridPager.gridMultiSelect && this.gridPager.gridMultiSelect.extendedInput) {
      return this.gridPager.gridMultiSelect.extendedInput.form;
    }
    return undefined;
  }

  private _setupPagerFormConfig(): void {
    let onChoiceSelect: EventEmitter<IGridPagerChoiceSelect>;
    if (this.pagerValueWizardChoice && this.pagerValueWizardChoice.formConfig) {
      this.pagerFormConfig = this.pagerValueWizardChoice.formConfig;
      onChoiceSelect = this.pagerValueWizardChoice.onChoiceSelect;
    } else if (this.pagerKeyWizardChoice && this.pagerKeyWizardChoice.formConfig) {
      this.pagerFormConfig = this.pagerKeyWizardChoice.formConfig;
      onChoiceSelect = this.pagerKeyWizardChoice.onChoiceSelect;
    } else {
      this.pagerFormConfig = undefined;
      return;
    }

    setTimeout(() => {
      this._setupSelection(onChoiceSelect);
    }, 1);
  }

  private _setupSelection(onChoiceSelect: EventEmitter<IGridPagerChoiceSelect>): void {
    if (onChoiceSelect) {
      onChoiceSelect.emit({
        form: this._getPagerForm(),
        items: this.gridPaging.selectedItems,
        keys: this.gridPaging.selectedKeys
      });
    }
  }

  private _getDataFromStore(): void {
    this.farm$ = this.loginStore.SelectedFarm$;
    this.mapViewState$ = this.mapViewStore.Listen(s => s.mode);
  }

  /**
   * Calculates the selectable settings (especially drag-selection feature)
   * depending on current grid selection mode and disables drag selection completely in case
   * of debug mode is turned on
   * @param selectableSetting
   * @param isDebugMode
   * @private
   */
  private calculateSelectableSettings(selectableSetting: SelectableSettings, isDebugMode: boolean): SelectableSettings {
    selectableSetting.drag = selectableSetting?.mode === 'multiple' && !isDebugMode && !this.windowHelperService.isTouchDevice();
    return selectableSetting;
  }

  /**
   * Custom handling of selectionChange event in case the user currently pressing Ctrl key and dragging mouse cursor.
   * By default, the grid does not add a new selection (from dragging with mouse) to the current selection
   * but replaces it. We need to aggregate everything together or invert blocks of selection.
   * @param eventArgs
   */
  public onSelectionChanged(eventArgs: SelectionEvent): void {
    // Implementation of Ctrl + drag (multiselect) feature which is not supported by default.
    // Aggregate all currently selected rows together. They might come from previous selection and from grid-drag feature.
    // While using kendoGrid drag-selection the eventArgs.ctrlKey is undefined - we have to monitor it on our own.
    // for regular selections in the grid the property is set properly
    if (!eventArgs || !this.gridPaging || this.selectable?.mode !== 'multiple' ||
      this.gridPaging?.keyColumn?.length <= 0 || !this._isControlKeyDown) {
      this.selectionChange.emit(eventArgs);
      return;
    }

    // kendo does not provide an indicator if drag-selection was used
    // but when using dragSelection the eventArgs.ctrlKey property is not defined,
    // while for all other cases its either true or false - sorry for that hack ;)
    const isDragSelection = eventArgs.ctrlKey == null;
    if (!isDragSelection) {
      this.selectionChange.emit(eventArgs);
      return;
    }

    const selectedKeys = [];
    // if new items get selected => include the deselected items as well.
    // All other rows get deselected by kendo when using drag selection => we want to avoid that when user press CTRL key.
    // Add them back to our selection.
    if (eventArgs.selectedRows?.length > 0) {
      this.addSelectedKeyIfNotExists(selectedKeys, eventArgs.selectedRows?.map(r => r.dataItem[this.gridPaging.keyColumn] ?? []));
      this.addSelectedKeyIfNotExists(selectedKeys, eventArgs.deselectedRows?.map(r => r.dataItem[this.gridPaging.keyColumn] ?? []));
      this.addSelectedKeyIfNotExists(selectedKeys, this.gridPaging.selectedKeys?.map(id => id));
    } else {
      // Drag selection within a block of already selected items => invert selection and deselect the block
      this.addSelectedKeyIfNotExists(selectedKeys, eventArgs.deselectedRows?.map(r => r.dataItem[this.gridPaging.keyColumn] ?? []));
    }
    this.gridPaging.setSelectedKeys(selectedKeys);
    this.selectionChange.emit(eventArgs);
  }

  /**
   * Helper methods to add unique values to an array
   * @param targetArray
   * @param sourceArray
   * @private
   */
  private addSelectedKeyIfNotExists(targetArray: any[], sourceArray: any[]): void {
    if (!targetArray) {
      targetArray = [];
    }
    if (!sourceArray || sourceArray.length <= 0) {
      return;
    }
    for (const selectedKey of sourceArray) {
      targetArray.AddIfNotExists(selectedKey);
    }
  }
}

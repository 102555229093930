import {Pipe, PipeTransform}         from '@angular/core';
import {ApDynGridColumnConfigBase}   from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridStatusColumnConfig} from '../../config/ap-dyn-grid-status-column-config';
import {Trace}                       from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asStatus'
})
export class AsStatusPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridStatusColumnConfig {
    return config as ApDynGridStatusColumnConfig;
  }

}

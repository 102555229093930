import {Injectable}              from '@angular/core';
import * as moment               from 'moment';
import {ApDateService}           from '../../ap-core/services/ap-date-service';
import {SettingsStore}           from '../../stores/base-data/settings.store';
import {MapStore}                from '../../stores/map/map.store';
import {ApLegendScrollerService} from '../../map/components/layer-controller/ap-legend-scroller.service';
import ISlice = Data.DocuContext.Slice.ISlice;
import IFleetActionAttachment = Data.DocuContext.FleetManagement.IFleetActionAttachment;
import IGuid = System.IGuid;

export interface ISelectedSlice {
  id: string;
  machineId: string;
  machineKind: number;
  fieldId: string;
  from: string;
  to: string;
}

@Injectable({providedIn: 'root'})
export class TrackLoaderService {
  public selectedSlices: ISelectedSlice[] = [];

  constructor(private dateService: ApDateService,
              private mapStore: MapStore,
              private legendScrollerService: ApLegendScrollerService,
              private settingsStore: SettingsStore) {
  }

  public clear(): void {
    this.selectedSlices = [];
  }

  public loadBySelectedSlice(selection: ISelectedSlice, farmId: number): void {
    if (!selection) {
      return;
    }
    this.load(selection.id, farmId, selection.from, selection.to, selection.machineId, selection.machineKind, selection.fieldId);
  }

  public loadBySlice(item: ISlice): void {
    if (!item) {
      return;
    }
    let from = item.From;
    let to = item.To;
    if (typeof item.From === typeof '') {
      from = new Date(item.From);
    }
    if (typeof item.To === typeof '') {
      to = new Date(item.To);
    }

    // show tracks for the exact slice period:
    const fromExpr = moment(from).format('YYYY-MM-DDTHH:mm:ss');
    const toExpr = moment(to).format('YYYY-MM-DDTHH:mm:ss');
    const machineId = item?.Attachment?.Machine?.Id;
    const machineKind = item?.Attachment?.Machine?.Kind;
    this.load(item.Id, item.FarmId, fromExpr, toExpr, machineId, machineKind);
  }

  public loadByFleetAction(item: IFleetActionAttachment): void {
    const firstSlice = item?.Slices.FirstOrDefault();
    const lastSlice = item?.Slices.LastOrDefault();
    if (!firstSlice || !lastSlice) {
      console.warn(`missing first or last slice in measure ${item?.Id}`);
      return;
    }

    let from = firstSlice.From;
    let to = lastSlice.To;
    if (typeof firstSlice.From === typeof '') {
      from = new Date(firstSlice.From);
    }
    if (typeof lastSlice.To === typeof '') {
      to = new Date(lastSlice.To);
    }

    // show tracks for the exact slice/attachment period.
    // otherwise we might get wrong slices due to UTC conversion
    const fromExpr = moment(from).format('YYYY-MM-DDTHH:mm:ss');
    const toExpr = moment(to).format('YYYY-MM-DDTHH:mm:ss');
    const machineId = item?.Attachment?.Machine?.Id;
    const machineKind = item?.Attachment?.Machine?.Kind;
    this.load(item.Id, firstSlice.FarmId, fromExpr, toExpr, machineId, machineKind, item.FieldId);
  }

  public removeUnreferencedIds(ids: IGuid[], farmId: number): void {
    this.selectedSlices = this.selectedSlices.RemoveAll(_ => !ids.Contains(_.id));
    this.setTrackUrl(farmId);
  }

  private load(id: IGuid, farmId: number, from: string, to: string, machineId: IGuid, machineKind: number, fieldId?: IGuid): void {
    if (!machineId || !machineKind || !from || !to) {
      console.warn(`no Machine Attachment`);
      return;
    }
    if (this.selectedSlices.Find(_ => _?.id === id?.toString())) {
      this.selectedSlices.RemoveAll(_ => _?.id === id?.toString());
    } else {
      this.selectedSlices.Add({
        id: id.toString(),
        fieldId: null,
        machineId: machineId.toString(),
        machineKind,
        from,
        to
      });
    }

    this.initLegend(machineKind);
    this.setTrackUrl(farmId);
  }

  private initLegend(machineKind: number): void {
    // set Legend selector
    if (this.mapStore.Legends?.TrackLegend?.selectionLists && this.mapStore.Legends?.TrackLegend?.selectionLists.length > 0) {
      const kindSelector = this.mapStore.Legends.TrackLegend.selectionLists.FirstOrDefault();
      const kindSelection = (kindSelector.values as any[]).Find(_ => _?.value === machineKind?.toString());
      if (kindSelection) {
        kindSelector.selectedValue.next(kindSelection);
        this.legendScrollerService.selectionChange.emit({
          legend: this.mapStore.Legends.TrackLegend,
          selectionKeys: this.legendScrollerService.generateSelectionKey(this.mapStore.Legends.TrackLegend).Split('_'),
        });
      } else {
        console.warn(`machine kind ${machineKind} no in Legend Selector`);
      }
    }
  }

  private setTrackUrl(farmId: number): void {
    this.mapStore.Layers.ChangeMapFactoryLayerUrlTrack(farmId, this.selectedSlices.Convert(_ => ({
      ..._,
      id: undefined,
    })), (store, sourceUrl) => store.Mutate(s => s.layer.trackUrl, () => sourceUrl));
  }
}

import {IStateStore}                from '../../ap-interface';
import {Injectable}                 from '@angular/core';
import {Store}                      from '../index';
import {NetTypes} from 'invoker-transport';
import {
  HelpArticlesLoad,
  HelpArticlesLoadSuccess,
  HelpArticlesSave
}                                   from '../../../../projects/invoker-transport/src/lib/actions/common/help-articles.action';
import IHelpArticles = Data.Common.IHelpArticles;
import {ApSignalrService}           from '../../ap-core/services/ap-signalr.service';

interface IHelpArticlesStore extends IStateStore<IHelpArticles> {
  latestHelpArticle: IHelpArticles;
  latestTargetComponent: string;
}

@Injectable({providedIn: 'root'})
export class HelpArticlesStore extends Store<IHelpArticlesStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      latestHelpArticle: null,
      latestTargetComponent: null
    });

    backend.registerObservable(HelpArticlesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.latestHelpArticle, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  public loadHelpArticle(targetComponent: string, languageKey: string): void {
    super.Mutate(s => s.latestHelpArticle, () => null);
    super.Mutate(s => s.latestTargetComponent, () => targetComponent);
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new HelpArticlesLoad([
      {Name: 'targetComponent', Type: NetTypes.STRING, Value: targetComponent},
      {Name: 'languageKey', Type: NetTypes.STRING, Value: languageKey}
    ]));
  }

  public saveHelpArticle(targetComponent: string, languageKey: string, contentHtml: string): void {
    this.DispatchBackend(new HelpArticlesSave([
      {Name: 'targetComponent', Type: NetTypes.STRING, Value: targetComponent},
      {Name: 'languageKey', Type: NetTypes.STRING, Value: languageKey},
      {Name: 'contentHtml', Type: NetTypes.STRING, Value: contentHtml}
    ]));
  }
}

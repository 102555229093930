import {AfterContentInit, Component, Input}                from '@angular/core';
import {IChartSet}                                         from '../../../stores/statistic/statistic.store';
import {geometry}                                          from '@progress/kendo-drawing';
import {SeriesVisualArgs}                                  from '@progress/kendo-angular-charts';
import {IBarLineAreaChartData, IBarLineAreaChartDataValue} from '../../../ap-interface';

export interface ChartSeriesData {
  chartSet: IChartSet<IBarLineAreaChartData>;
  seriesValues: Array<Array<IBarLineAreaChartDataValue>>;
}

const GENERIC_BAR_SIZE = 25;

@Component({
  selector: 'ap-generic-bar-chart',
  templateUrl: 'ap-generic-bar-chart.component.html',
  styleUrls: ['ap-generic-bar-chart.component.scss']
})
export class ApGenericBarChartComponent implements AfterContentInit {
  @Input() data: IChartSet<IBarLineAreaChartData>[];
  @Input() title = '';
  @Input() emptyMessage = '';
  @Input() zoomable = true;
  @Input() transitions = true;
  @Input() pannable = true;

  public strokeColor = '#212529';
  public backgroundColor = '#ffffffbf';
  public chartSeriesData: Array<ChartSeriesData> = [];

  public ngAfterContentInit(): void {
    this.chartSeriesData = new Array<ChartSeriesData>();
    for (const chartSet of this.data) {
      this.chartSeriesData.Add({
        chartSet,
        seriesValues: this.getSeriesValues(chartSet)
      });
    }
  }

  /**
   * Re-organize chart data: for one-bar diagrams each category is supposed to have one value.
   * If the series contain multiple values for one category a second, third,... bar is created.
   * @param chartSet the charset of a single diagram
   */
  public getSeriesValues(chartSet: IChartSet<IBarLineAreaChartData>): Array<Array<IBarLineAreaChartDataValue>> {
    const seriesValues = new Array<Array<IBarLineAreaChartDataValue>>(0);
    seriesValues[0] = [];

    if (!chartSet || !chartSet.data || !chartSet.data.data) {
      return seriesValues;
    }

    for (const dataValue of chartSet.data.data) {
      let dataAdded = false;
      const categoryIdx = chartSet?.data?.categories?.indexOf(dataValue.category);
      if (categoryIdx < 0) {
        continue;
      }
      for (const seriesValue of seriesValues) {
        if (!seriesValue.find(_ => _?.category === dataValue.category)) {
          seriesValue[categoryIdx] = dataValue;
          dataAdded = true;
          break;
        }
      }
      if (!dataAdded) {
        const seriesValue = new Array(chartSet.data.categories.length);
        seriesValue[categoryIdx] = dataValue;
        seriesValues.Add(seriesValue);
      }
    }
    return seriesValues;
  }

  /**
   * Defines some basic appearance parameters
   * @param e the series visual arguments
   */
  public visual(e: SeriesVisualArgs): any {
    // create the default visual
    const visual = e.createVisual();
    // scale it so that it has the predefined size
    visual.transform(geometry.transform().scale(GENERIC_BAR_SIZE / e.rect.size.width, 1, e.rect.center()));
    return visual;
  }

  /**
   * method to retrieve content of the tooltip (if defined)
   * @param dataItem the current bar dataItem
   * @param chartSet the current chart
   */
  public getTooltip(dataItem: any, chartSet: IChartSet<IBarLineAreaChartData>): string {
    if (chartSet?.tooltipDelegate) {
      return chartSet?.tooltipDelegate(dataItem, chartSet);
    }
    return dataItem?.value ?? '';
  }

  /**
   * method to retrieve content of the note (if defined)
   * @param chartSet the current chart
   */
  public getNote(chartSet: IChartSet<IBarLineAreaChartData>): (p1: any) => string {
    return (item: any) => {
      if (item && chartSet?.noteDelegate) {
        return chartSet.noteDelegate(item?.dataItem, chartSet);
      } else if (item && chartSet?.noteField !== '') {
        return item?.dataItem[chartSet?.noteField];
      }
      return '';
    };
  }
}

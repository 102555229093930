<div class="ap-section-header">

  <div class="ap-section-header-template">

    <ng-content></ng-content>

  </div>

  <div class="ap-section-header-controls" *ngIf="(mapViewState$ | async) === mapViewModes.HIDE">

    <button kendoButton (click)="openMap()" class="ap-section-header-control-button">

      <ap-map-icon class="ap-tabstrip-icon"></ap-map-icon>

    </button>

    <button (click)="openStats()" *ngIf="(ApPermissions.SHOW_STATISTICS | getPermission | async)"
            class="ap-section-header-control-button" kendoButton>

      <ap-statistic-icon class="ap-tabstrip-icon"></ap-statistic-icon>

    </button>

    <button kendoButton (click)="openHelp()" class="ap-section-header-control-button">

      <ap-help-icon class="ap-tabstrip-icon"></ap-help-icon>

    </button>

  </div>

</div>

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum SoilSamplePlanningReportActions {
  SOIL_SAMPLE_PLANNING_REPORT_DOWNLOAD = '[soilSamplePlanningReport] download',
  SOIL_SAMPLE_PLANNING_REPORT_DOWNLOAD_SUCCESS = '[soilSamplePlanningReport] download success'
}

export class SoilSamplePlanningReportDownload implements IAction {
  readonly type = SoilSamplePlanningReportActions.SOIL_SAMPLE_PLANNING_REPORT_DOWNLOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSamplePlanningReportDownloadSuccess implements IAction {
  readonly type = SoilSamplePlanningReportActions.SOIL_SAMPLE_PLANNING_REPORT_DOWNLOAD_SUCCESS;

  constructor(public payload: string[]) {
  }
}

import { NgModule }                from '@angular/core';
import { CommonModule }            from '@angular/common';
import { ApStatusSwitchComponent } from './components/ap-status-switch.component';
import {ApIconModule}              from '../icons/ap-icon.module';
import {ApTooltipModule}           from '../ap-tooltip/ap-tooltip.module';
import {ApTranslationModule}       from '../translation/ap-translation.module';

@NgModule({
  declarations: [ApStatusSwitchComponent],
  exports: [
    ApStatusSwitchComponent
  ],
  imports: [
    CommonModule,
    ApIconModule,
    ApTooltipModule,
    ApTranslationModule
  ]
})
export class ApStatusSwitchModule { }

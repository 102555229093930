import {IStateStore}      from '../../ap-interface';
import {Injectable}       from '@angular/core';
import {Store}            from '../index';
import {
  FarmLoad,
  FarmLoadFail,
  FarmLoadSuccess,
  FarmUpdateRegistryNumber,
  FarmUpdateRegistryNumberFail,
  FarmUpdateRegistryNumberSuccess,
  FarmUpdateCountry,
  FarmUpdateCountrySuccess,
  FarmUpdateCountryFail
}                         from 'invoker-transport';
import {NetTypes, ApCustomTypes}         from '../../../../projects/invoker-transport/src/lib/generated/backend.enums';
import IFarm = Data.Authentication.IFarm;
import ICountry = Data.Common.ICountry;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IFarmStore extends IStateStore<IFarm> {
  registryNumberUpdated: boolean | undefined;
  countryUpdated: boolean | undefined;
}

@Injectable({providedIn: 'root'})
export class FarmStore extends Store<IFarmStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      registryNumberUpdated: undefined,
      countryUpdated: undefined
    });
    backend.registerObservable(FarmLoad).subscribe(_ => {
      super.SetLoadState();
    });
    backend.registerObservable(FarmLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FarmLoadFail).subscribe(_ => {
      super.SetLoadFailState();
    });
    backend.registerObservable(FarmUpdateRegistryNumberSuccess).subscribe(_ => {
      super.Mutate(s => s.registryNumberUpdated, () => true);
      super.Mutate(s => s.registryNumberUpdated, () => undefined);
    });
    backend.registerObservable(FarmUpdateRegistryNumberFail).subscribe(_ => {
      super.Mutate(s => s.registryNumberUpdated, () => false);
      super.Mutate(s => s.registryNumberUpdated, () => undefined);
    });
    backend.registerObservable(FarmUpdateCountrySuccess).subscribe(_ => {
      super.Mutate(s => s.countryUpdated, () => true);
      super.Mutate(s => s.countryUpdated, () => undefined);
    });
    backend.registerObservable(FarmUpdateCountryFail).subscribe(_ => {
      super.Mutate(s => s.countryUpdated, () => false);
      super.Mutate(s => s.countryUpdated, () => undefined);
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadFarm(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new FarmLoad([]));
  }

  public updateRegistryNumber(registryNumber: string): void {
    super.Mutate(s => s.registryNumberUpdated, () => undefined);
    this.DispatchBackend(new FarmUpdateRegistryNumber([
      {Name: 'registryNumber', Type: NetTypes.STRING, Value: registryNumber}
    ]));
  }

  public updateFarmCountry(farmCountry: ICountry): void {
    super.Mutate(s => s.countryUpdated, () => undefined);
    this.DispatchBackend(new FarmUpdateCountry([
      {Name: 'country', Type: ApCustomTypes.Data_Common_Country, Value: farmCountry}
    ]));
  }
}

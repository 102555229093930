import {AfterContentInit, ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {Observable, Subscription}                                                          from 'rxjs';
import {IDonutPieChartData}                                                                from '../../../ap-interface';
import {
  ChartComponent
}                                                                                          from '@progress/kendo-angular-charts';
import {
  SettingsStore
}                                                                                          from '../../../stores/base-data/settings.store';
import {
  StatisticStore
}                                                                                          from '../../../stores/statistic/statistic.store';
import {
  TranslationStore
}                                                                                          from '../../../stores/translation/translation.store';
import {
  GetRoundNumericService
}                                                                                          from '../../../ap-utils/service/get-round-numeric.service';
import ISettings = Data.BaseData.ISettings;

@Component({
  selector: 'ap-donut-pie-chart',
  templateUrl: 'ap-donut-pie-chart.component.html',
  styleUrls: ['ap-donut-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApDonutPieChartComponent implements AfterContentInit, OnDestroy {
  @Input()
  data: IDonutPieChartData[];
  @Input()
  title: string;
  public types: Array<{ text: string, value: string }> = [];
  public type: any;
  public settings: ISettings;
  public centerUnit: string;
  public centerCount = 0;
  public strokeColor = '#212529';
  @ViewChild('chart', {static: false})
  private chart: ChartComponent;
  private _subscriptions: Array<Subscription> = [];

  constructor(private settingsStore: SettingsStore,
              private roundNumericService: GetRoundNumericService,
              private translationStore: TranslationStore,
              private statisticStore: StatisticStore) {
    this._subscriptions.push(this.settingsStore.FirstSetting$.subscribe(s => this.settings = s));
    this._subscriptions.push(this.statisticStore.Listen(s => s.data).subscribe(d => this.createChart(d)));
    this.types.push({
      value: 'donut',
      text: this.translationStore.FindTranslationForSelectedLanguage('Global_Charttype_Pie')
    }, {
      value: 'pie',
      text: this.translationStore.FindTranslationForSelectedLanguage('Global_Charttype_Donut')
    });
    this.type = this.types[0].value;
  }

  ngAfterContentInit(): void {

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }

  selectedValueChanged(value): void {
    if (this.type !== value) {
      this.type = value;
    }
  }

  public labelContent(e: any): string {
    const category = e.category;
    const value = this.roundNumericService.round(e.value, this.settings.DigitsAfterDecimalPoint);
    const unit = this.translationStore.FindTranslationForSelectedLanguage('Base__UnitHa');
    return `${category}: \n ${value} ` + unit;
  }

  private createChart(d): void {
    if (!d) {
      return;
    }
    this.centerUnit = this.translationStore.FindTranslationForSelectedLanguage('Base__UnitHa');
    let values = 0;
    for (const item of d) {
      values += item.value;
    }
    this.centerCount = this.roundNumericService.roundAsNumber(values, this.settings.DigitsAfterDecimalPoint);
  }
}

import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'notify-message',
  template: `
    <span>{{ message }}</span>
    <span class="k-icon k-i-close ap-notification-close-button" (click)="hideNotification($event)"></span>
  `
})

export class ApNotifyMessageComponent {
  // (dirty) workaround for passing the message to the message-component.
  // if passing the message after creation of the component kendo position calculation
  // does not consider the new message -> notifcation not centered.
  public static InitialMessage: string;
  @Output() public hide: EventEmitter<any> = new EventEmitter();
  public message = '';

  constructor() {
    this.message = ApNotifyMessageComponent.InitialMessage;
  }

  public hideNotification(event: Event): void {
    event.preventDefault();
    this.hide.emit();
  }
}

import {Injectable}                                                                       from '@angular/core';
import {Store}                                                                            from '../index';
import {ApCustomTypes, FactorsLoad, FactorsLoadSuccess, FactorsMissing} from 'invoker-transport';
import {IStateStore}                                                                      from '../../ap-interface';
import {UnitsStore}                                                                       from './units.store';
import IFactors = Data.Common.IFactors;
import IMissingFactors = Data.Tracelog.IMissingFactors;
import IUnit = Data.Common.IUnit;
import {
  ApSignalrService
}                                                                                         from '../../ap-core/services/ap-signalr.service';

interface IFactorsState extends IStateStore<IFactors> {
}

export interface IFactorsStore {
  loadFactors(): void;

  getFactors(): IFactors[];

  getFactor(source: IUnit | string, target: IUnit | string): IFactors;

  sendMissingFactor(payload: IMissingFactors): void;
}

@Injectable({providedIn: 'root'})
export class FactorsStore extends Store<IFactorsState> implements IFactorsStore {
  constructor(public backend: ApSignalrService,
              private unitsStore: UnitsStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(FactorsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadFactors(): void {
    this.DispatchBackend(new FactorsLoad([]));
  }

  getFactors(): IFactors[] {
    return super.Listen(s => s.data).getValue();
  }

  public getFactor(source: IUnit | string, target: IUnit | string): IFactors {
    let unitSource: IUnit;
    let unitTarget: IUnit;
    if (source.hasOwnProperty('Description')) {
      unitSource = source as IUnit;
    } else {
      unitSource = this.unitsStore.getUnit(source as string);
    }
    if (target.hasOwnProperty('Description')) {
      unitTarget = target as IUnit;
    } else {
      unitTarget = this.unitsStore.getUnit(target as string);
    }
    let factor = this.getFactors().find(_ => _.Source.Id === unitSource.Id && _.Target.Id === unitTarget.Id);
    if (factor === undefined) {
      factor = {Id: 0, Source: unitSource, Target: unitTarget, Factor: 1};
      this.sendMissingFactor({Id: 0, Source: unitSource.Id, Target: unitTarget.Id});
    }
    return factor;
  }

  public sendMissingFactor(payload: IMissingFactors): void {
    super.DispatchBackend(new FactorsMissing([
      {Name: 'missing', Type: ApCustomTypes.Data_Tracelog_MissingFactors, Value: payload}
    ]));
  }
}

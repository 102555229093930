import {Pipe, PipeTransform}           from '@angular/core';
import {ApDynGridColumnConfigBase}     from '../config/ap-dyn-grid-column-config-base';
import {MapViewMode}                   from '../../ap-interface/enums';
import {combineLatest, Observable}     from 'rxjs';
import {map}                           from 'rxjs/operators';

@Pipe({
  name: 'isHidden'
})
export class IsHiddenPipe implements PipeTransform {

  transform(value: ApDynGridColumnConfigBase, ...args: [Observable<MapViewMode>, Observable<ApDynGridColumnConfigBase[]>]): Observable<boolean> {
    return combineLatest([
      value.hide.selfHide,
      args[0],
      args[1]
    ]).pipe(map(([selfHide, mapView, widthHiddenColumns]) => {
        return selfHide || (mapView !== MapViewMode.HIDE &&
          (value.hide.mapHide || widthHiddenColumns.Any(v => v.equals(value))));
      }
    ));
  }

}

export interface IPlanningWizardOverviewStatsItem {
  Module: PlanningWizardOverviewModuleStats;
  OperationModeKey: string;
  NContent: number;
  Area: number;
  Min: number;
  Max: number;
  Average: number;
  ProductId?: number;
}

export interface IPlanningWizardOverviewStatsTableData {
  OperationModeName: string;
  FieldsSum: number;
  AreaSum: number;
  ProductAmountSum: number;
  ProductMin: number;
  ProductMax: number;
  ProductAverage: number;
  Average: number;
}

export enum PlanningWizardOverviewModuleStats {
  BaseFertilization = 0,
  NFertilization = 1,
  PlantProtection = 2
}

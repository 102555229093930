import {Injectable}                                                        from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable}                                                        from 'rxjs';
import {RouterStore}                                                       from '../stores/router/router.store';
import {InvitationStore}                                                   from '../stores/login/invitation.store';

@Injectable({providedIn: 'root'})
export class ApInvitationsGuard implements CanActivate {
  constructor(private routerStore: RouterStore,
              private invitationStore: InvitationStore) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkInvitation();
  }

  private async checkInvitation(): Promise<boolean> {
    return await this.invitationStore.hasInvitation(this.routerStore.QueryParams.ac);
  }
}

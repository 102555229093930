import {IAction} from '../../interfaces';

export class ClientOutdated implements IAction {
  readonly type = '[system] client outdated';

  constructor() {
  }
}

export class SessionOutdated implements IAction {
  readonly type = '[system] session outdated';

  constructor() {
  }
}

import {IStateStore}                                                         from '../../ap-interface/interfaces/store';
import {Store}                                                               from '../index';
import {Injectable}                                                          from '@angular/core';
import {SidebarAddDatasource, SidebarAddDatasourceSuccess} from 'invoker-transport';
import {ApVisibleStates, SidebarMode}                                        from '../../ap-interface/enums';
import {MapViewStore}                                                        from './mapview.store';
import IMenuEntry = Data.MenuStructure.IMenuEntry;
import {
  ApSignalrService
}                                                                            from '../../ap-core/services/ap-signalr.service';


interface IMenuStore extends IStateStore<IMenuEntry> {
  mode: SidebarMode;
  menuAnimationState: string;
  smallMenuAnimationState: string;
}

@Injectable({providedIn: 'root'})
export class MenuStore extends Store<IMenuStore> {
  constructor(public backend: ApSignalrService,
              private mapViewStore: MapViewStore) {
    super(backend, {
      data: [],
      loading: false,
      loaded: false,
      mode: SidebarMode.OPEN,
      menuAnimationState: 'out',
      smallMenuAnimationState: 'out'
    });

    backend.registerObservable(SidebarAddDatasourceSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  getSidebarExpand(): boolean {
    return super.Listen(s => s.mode).getValue() === SidebarMode.OPEN;
  }

  getViewMode(): SidebarMode {
    return super.Listen(s => s.mode).getValue();
  }

  public fillMenuData(): void {
    super.SetLoadState();
    this.DispatchBackend(new SidebarAddDatasource([]));
  }

  public openSidebar(): void {
    super.Mutate(s => s.mode, () => SidebarMode.OPEN);
    this.smallMenuFlyOut();
    this.menuFlyIn();
    setTimeout(this.mapViewStore.refreshMap.bind(this.mapViewStore), 300);
  }

  public closeSidebar(): void {
    super.Mutate(s => s.mode, () => SidebarMode.CLOSE);
    this.smallMenuFlyOut();
    this.menuFlyOut();
    setTimeout(this.mapViewStore.refreshMap.bind(this.mapViewStore), 300);
  }

  public halfSidebar(): void {
    super.Mutate(s => s.mode, () => SidebarMode.HALF);
    this.smallMenuFlyIn();
    this.menuFlyOut();
    setTimeout(this.mapViewStore.refreshMap.bind(this.mapViewStore), 300);
  }

  public menuFlyIn(): void {
    super.Mutate(s => s.menuAnimationState, () => ApVisibleStates.IN);
  }

  public menuFlyOut(): void {
    super.Mutate(s => s.menuAnimationState, () => ApVisibleStates.OUT);
  }

  public smallMenuFlyIn(): void {
    super.Mutate(s => s.smallMenuAnimationState, () => ApVisibleStates.IN);
  }

  public smallMenuFlyOut(): void {
    super.Mutate(s => s.smallMenuAnimationState, () => ApVisibleStates.OUT);
  }
}

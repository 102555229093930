import {IAction, IApInvokerArguments} from '../../interfaces';
import {DataStateChangeEvent}         from './progress.mapping';
import IField = Data.FieldManagement.IField;
import IFieldGrowth = Data.FieldManagement.IFieldGrowth;
import IFieldBase = Data.FieldManagement.IFieldBase;
import IGeoJsonPolygon = Data.Geometry.IGeoJsonPolygon;
import IResultMessage = Invoker.IResultMessage;

export enum FieldActions {
  FIELD_LOAD = '[field] load',
  FIELD_LOAD_FROM_DATERANGE = '[field] load from date range',
  FIELD_LOAD_FROM_DATERANGE_SUCCESS = '[field] load from date range success',
  FIELD_LOAD_FROM_DATERANGE_FAIL = '[field] load from date range fail',
  FIELD_LOAD_SUCCESS = '[field] load success',
  FIELD_LOAD_FAIL = '[field] load fail',
  FIELD_SELECT = '[field] select',
  FIELD_SELECT_CENTROID = '[field] selectcentroid',
  FIELD_SORT = '[field] sort',
  FIELD_SAVE = '[field] save',
  FIELD_DELETE = '[field] delete',
  FIELD_MERGE = '[field] merge',
  FIELD_MERGE_FAILED = '[field] merge failed',
  FIELD_MERGE_SUCCESS = '[field] merge success',
  FIELD_SPLIT = '[field] split',
  FIELD_SPLIT_SUCCESS = '[field] split success',
  FIELD_SPLIT_FAILED = '[field] split failed',
  FIELD_CROP_CREATE = '[field_crop] create',
  FIELD_CROP_LOAD = '[field_crop] load',
  FIELD_CROP_DELETE = '[field_crop] delete',
  FIELD_CROPS_SAVE = '[field_crops] save',
  FIELD_CROPS_SAVE_SUCCESS = '[field_crops] save success',
  FIELD_CROPS_SAVE_FAILED = '[field_crops] save failed',
  FIELD_UPDATE = '[field] update',
  FIELD_GROWTH_SAVE = '[field_growth] save',
  FIELD_GEOM_DELETE = '[field_geom] delete',
  FIELD_GROWTH_LOAD = '[growth] load',
  FIELD_GROWTH_LOAD_SUCCESS = '[growth] load success',
  FIELD_GROWTH_LOAD_FAIL = '[growth] load fail',
  FIELD_GROWTH_CREATE = '[field_growth] create',
  FIELD_CREATE = '[field] create',
  FIELD_INTEGRATE = '[field] integrate',
  FIELD_INTEGRATE_SUCCESS = '[field] integrate success',
  FIELD_SAVE_SUCCESS = '[field] save success',
  FIELD_SAVE_FAIL = '[field] save fail',
  FIELD_CROP_YEAR_LOAD = '[field] crops for year load',
  FIELD_CROP_YEAR_LOAD_SUCCESS = '[field] crops for year success',
  FIELD_CROP_YEAR_LOAD_FAIL = '[field] crops for year fail',
  FIELD_PLAN_BOOKS = '[field] check plannings',
  FIELD_PLAN_BOOKS_SUCCESS = '[field] check plannings success',
}

/**
 * load Fields from Backend
 */
export class FieldLoad implements IAction {
  readonly type = FieldActions.FIELD_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load Fields from Backend success
 */
export class FieldLoadSuccess implements IAction {
  readonly type = FieldActions.FIELD_LOAD_SUCCESS;

  constructor(public payload: IField[]) {
  }
}

/**
 * load Fields from Backend fail
 */
export class FieldLoadFail implements IAction {
  readonly type = FieldActions.FIELD_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class FieldLoadFromDateRange implements IAction {
  readonly type = FieldActions.FIELD_LOAD_FROM_DATERANGE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldLoadFromDateRangeSuccess implements IAction {
  readonly type = FieldActions.FIELD_LOAD_FROM_DATERANGE_SUCCESS;

  constructor(public payload: IFieldBase[]) {
  }
}

export class FieldLoadFromDateRangeFail implements IAction {
  readonly type = FieldActions.FIELD_LOAD_FROM_DATERANGE_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * load Growths from Backend
 */
export class FieldGrowthLoad implements IAction {
  readonly type = FieldActions.FIELD_GROWTH_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load Growths from Backend success
 */
export class FieldGrowthLoadSuccess implements IAction {
  readonly type = FieldActions.FIELD_GROWTH_LOAD_SUCCESS;

  constructor(public payload: IFieldGrowth[]) {
  }
}

/**
 * load Growths from Backend fail
 */
export class FieldGrowthLoadFail implements IAction {
  readonly type = FieldActions.FIELD_GROWTH_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * set Centroid of selected Fields
 */
export class FieldsSelectCentroid implements IAction {
  readonly type = FieldActions.FIELD_SELECT_CENTROID;

  constructor() {
  }
}

/**
 * select Fields in Grid
 */
export class FieldSelect implements IAction {
  readonly type = FieldActions.FIELD_SELECT;

  constructor(public payload: string[]) {
  }
}

/**
 * sort Fields in Grid
 */
export class FieldSort implements IAction {
  readonly type = FieldActions.FIELD_SORT;

  constructor(public payload: DataStateChangeEvent) {
  }

}

/**
 * try save Fields
 */
export class FieldSave implements IAction {
  readonly type = FieldActions.FIELD_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load FieldCrops from Backend
 */
export class FieldCropCreate implements IAction {
  readonly type = FieldActions.FIELD_CROP_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * try delete Field Crop
 */
export class FieldCropDelete implements IAction {
  readonly type = FieldActions.FIELD_CROP_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * try save Fields Crops
 */
export class FieldCropsSave implements IAction {
  readonly type = FieldActions.FIELD_CROPS_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * when Fields Crops saved successfully
 */
export class FieldCropsSaveSuccess implements IAction {
  readonly type = FieldActions.FIELD_CROPS_SAVE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * when Fields Crops saved failed
 */
export class FieldCropsSaveFailed implements IAction {
  readonly type = FieldActions.FIELD_CROPS_SAVE_FAILED;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * try save Fields Crops
 */
export class FieldUpdate implements IAction {
  readonly type = FieldActions.FIELD_UPDATE;

  constructor(public payload: IField) {
  }
}

/**
 * try save Fields Growths
 */
export class FieldGrowthSave implements IAction {
  readonly type = FieldActions.FIELD_GROWTH_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * try create Fields Growths
 */
export class FieldGrowthCreate implements IAction {
  readonly type = FieldActions.FIELD_GROWTH_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * try delete Field Geoms
 */
export class FieldGeomDelete implements IAction {
  readonly type = FieldActions.FIELD_GEOM_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * try delete Field
 */
export class FieldDelete implements IAction {
  readonly type = FieldActions.FIELD_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldMerge implements IAction {
  readonly type = FieldActions.FIELD_MERGE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldMergeFailed implements IAction {
  readonly type = FieldActions.FIELD_MERGE_FAILED;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldMergeSuccess implements IAction {
  readonly type = FieldActions.FIELD_MERGE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldSplit implements IAction {
  readonly type = FieldActions.FIELD_SPLIT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldSplitSuccess implements IAction {
  readonly type = FieldActions.FIELD_SPLIT_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldSplitFailed implements IAction {
  readonly type = FieldActions.FIELD_SPLIT_FAILED;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldCheckPlanBooks implements IAction {
  readonly type = FieldActions.FIELD_PLAN_BOOKS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldCheckPlanBooksSuccess implements IAction {
  readonly type = FieldActions.FIELD_PLAN_BOOKS_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldCreate implements IAction {
  readonly type = FieldActions.FIELD_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldIntegrate implements IAction {
  readonly type = FieldActions.FIELD_INTEGRATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldIntegrateSuccess implements IAction {
  readonly type = FieldActions.FIELD_INTEGRATE_SUCCESS;

  constructor(public payload: IGeoJsonPolygon) {
  }
}

export class FieldSaveFail implements IAction {
  readonly type = FieldActions.FIELD_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

export class FieldSaveSuccess implements IAction {
  readonly type = FieldActions.FIELD_SAVE_SUCCESS;

  constructor(public payload: string) {
  }
}

export class FieldCropYearLoad implements IAction {
  readonly type = FieldActions.FIELD_CROP_YEAR_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldCropYearLoadSuccess implements IAction {
  readonly type = FieldActions.FIELD_CROP_YEAR_LOAD_SUCCESS;

  constructor(public payload: IResultMessage) {
  }
}

export class FieldCropYearLoadFail implements IAction {
  readonly type = FieldActions.FIELD_CROP_YEAR_LOAD_FAIL;

  constructor() {
  }
}

<kendo-multiselect
  [class.dark-bg]="true"
  [data]="data"
  [filterable]="true"
  [valuePrimitive]="true"
  [value]="value"
  (valueChange)="onChange($event)">
  <ng-template kendoMultiSelectTagTemplate let-dataItem>
    <div *ngIf="dataItem === ApColumnState.Red" class="ap-status-red"></div>
    <div *ngIf="dataItem === ApColumnState.Yellow" class="ap-status-yellow"></div>
    <div *ngIf="dataItem === ApColumnState.Green" class="ap-status-green"></div>
    <div *ngIf="dataItem === ApColumnState.Grey" class="ap-status-grey"></div>
  </ng-template>
  <ng-template kendoMultiSelectItemTemplate let-dataItem>
    <div *ngIf="dataItem === ApColumnState.Red" class="ap-status-red"></div>
    <div *ngIf="dataItem === ApColumnState.Yellow" class="ap-status-yellow"></div>
    <div *ngIf="dataItem === ApColumnState.Green" class="ap-status-green"></div>
    <div *ngIf="dataItem === ApColumnState.Grey" class="ap-status-grey"></div>
  </ng-template>
</kendo-multiselect>

import {IStateStore} from '../../ap-interface/interfaces/store';
import {Store}       from '../index';
import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  LoadUsergroupsUser,
  LoadUsergroupsUserSuccess,
  NetTypes,
  UpdateUsergroupsUser
}                    from 'invoker-transport';
import IUsergroup = Data.AccessPermission.IUsergroup;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IUserGroupsUserStore extends IStateStore<IUsergroup> {
}

@Injectable({providedIn: 'root'})
export class UserGroupsUserStore extends Store<IUserGroupsUserStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(LoadUsergroupsUserSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  loadUserGroupsUser(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new LoadUsergroupsUser([]));
  }

  updateUserGroupsUser(list: IUsergroup[], userId: number): void {
    this.DispatchBackend(new UpdateUsergroupsUser([
      {Name: 'list', Type: ApCustomTypes.Data_AccessPermission_Usergroup + '[]', Value: list},
      {Name: 'userId', Type: NetTypes.INT, Value: userId},
    ]));
  }

  public getUserGroupsUser(): IUsergroup[] {
    return super.Listen(s => s.data).getValue();
  }
}

import {IStateStore} from '../../ap-interface/interfaces/store';
import {Injectable}  from '@angular/core';
import {Store}       from '../index';
import {
  NetTypes,
  ParameterValueHistoryLoad,
  ParameterValueHistoryLoadSuccess
}                    from 'invoker-transport';
import IGuid = System.IGuid;
import IParameterValueHistory = Data.Parameter.IParameterValueHistory;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IParameterValuesHistoryStore extends IStateStore<IParameterValueHistory> {
}

@Injectable({providedIn: 'root'})
export class ParameterValuesHistoryStore extends Store<IParameterValuesHistoryStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
    });

    backend.registerObservable(ParameterValueHistoryLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadParameterValueHistory(refId: IGuid): void {
    this.DispatchBackend(new ParameterValueHistoryLoad([
      {Name: 'refId', Type: NetTypes.GUID, Value: refId},
    ]));
  }
}

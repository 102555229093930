import {Injectable}        from '@angular/core';
import {CampaignYearStore} from '../../stores/login/campaignyear.store';
import {ApDateService}     from '../../ap-core/services/ap-date-service';

@Injectable({
  providedIn: 'root'
})
export class ApNutrientManagementDateService {

  constructor(private campaignYearStore: CampaignYearStore,
              private dateService: ApDateService) {
  }

  GetMinDate(): Date {
    return this.dateService.toFarmDate(this.campaignYearStore.getSelectedCampaignYear().DefaultStart).toDate();
  }

  GetMaxDate(year: number): Date {
    if (this.campaignYearStore.SelectedCampaignYear.Year === Math.max.apply(null, this.campaignYearStore.getCampaignYears().map((y) => y.Year))) {
      return this.dateService.toFarmDate(new Date(`${year.toString()}-07-31`)).toDate();
    }
    return this.dateService.toFarmDate(this.campaignYearStore.getSelectedCampaignYear().DefaultEnd).toDate();
  }

  GetOutputDate(): Date {
    if (this.campaignYearStore.SelectedCampaignYear.Year === Math.max.apply(null, this.campaignYearStore.getCampaignYears().map((y) => y.Year))) {
      return this.dateService.toFarmDate(new Date()).toDate();
    }
    return this.dateService.toFarmDate(this.campaignYearStore.getSelectedCampaignYear().DefaultStart).toDate();
  }
}

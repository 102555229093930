import * as _                        from 'lodash';
import {find, isString, keys, unset} from 'lodash';
import {buffer as OlBuffer, Extent}  from 'ol/extent';
import OlFeature                     from 'ol/Feature';
import Feature                       from 'ol/Feature';
import {GeoJSON}                     from 'ol/format';
import OlVectorLayer                 from 'ol/layer/Vector';
import VectorLayer                   from 'ol/layer/Vector';
import OlCluster                     from 'ol/source/Cluster';
import OlVectorSource                from 'ol/source/Vector';
import VectorSource                  from 'ol/source/Vector';
import {ApGeometryExtension}         from '../../ap-utils';
import {MapStore}                    from '../../stores/map/map.store';
import {GeometryChecker}             from '../geometry.checker';
import {StyleDefinition}             from '../ol.helper';
import {ApBaseLayer}                 from './ap-base.layer';
import {MAP_PROJECTION}              from './ap-map.settings';
import {ApMapViews}                  from './ap-map.views';
import {ApVectorSelection}           from './ap-vector.selection';
import {IPingOptions, pingLocation}  from './animations/pingLocation';
import OlStyle                       from 'ol/style/Style';
import OlStyleFill                   from 'ol/style/Fill';
import OlStyleStroke                 from 'ol/style/Stroke';
import {ApMapInstance}               from '../ap-map.instance';
import {FieldStore}                  from '../../stores/farm/field.store';
import {Create, StringFactory}       from 'ts-tooling';
import IField = Data.FieldManagement.IField;
import ISoilSampleField = Data.Nutrients.ISoilSampleField;

export enum LayerSyncStrategy {
  FORCE,
  RESTRAINED,
  DELETE,
}

/**
 * the Basic Vector Layer Representation
 */
export class ApBaseVectorLayer extends ApBaseLayer<OlVectorLayer> {
  public selectedFeatures: { [key: string]: ApVectorSelection } = {};
  private _fixedZIndex = -1;
  public innerSource: OlVectorSource = null;
  public Group = 'None';
  public pingLayer = new VectorLayer({
    declutter: true,
    source: new VectorSource({
      features: [],
    }),
    style: new OlStyle({
      fill: new OlStyleFill({
        color: 'rgba(0,0,0,0)',
      }),
      stroke: new OlStyleStroke({
        color: 'rgba(0,0,0,0)',
      }),
    }),
  });

  /**
   * create a Basic Vector Layer
   */
  constructor(layerName: string, layerGroup: string, mapStore: MapStore, private declutter = false, private pingOptions: IPingOptions = null) {
    super(layerName, new OlVectorLayer({
      renderMode: 'image',
      declutter,
      source: new OlVectorSource({}),
    } as any), mapStore);
    if (layerGroup) {
      this.Group = layerGroup;
    }
    this.pingLayer.getSource()?.on('addfeature', e => {
      if (pingOptions) {
        this.triggerPingLocation(e, this.pingOptions);
      }
    });
  }

  public get Visibility(): boolean {
    return this.layer.getVisible();
  }

  public set Visibility(value: boolean) {
    try {
      if (!ApMapInstance.mapRef) {
        return;
      }
      this.layer.setVisible(value);
      super.Visibility = value;
      this.pingLayer.setVisible(value);
    }catch (ex) {
      // in rare cases we got the following error:
      // >Cannot read properties of null (reading 'usedTiles')<
      // It could be reproduced by slowing down the browser (performance tab)
      // and clicking really fast between tabs (map/stat) and other modules.
      // The callstack pointed to this part of our code.
      // It seems a timing issue between disposing the map (when leaving the tab)
      // and clicking in the map or in the grid to focus a field
      // => this is not critical, and therefore we change it to 'Warning' in
      // order not to block our client with the client-error overlay
      console.warn('Updating visibility of layer failed because map is already disposed');
    }
  }

  public get FixedZIndex(): number {
    return this._fixedZIndex;
  }

  public set FixedZIndex(value: number) {
    this.layer.setZIndex(value);
    this.pingLayer.setZIndex(value - 1);
    this._fixedZIndex = value;
  }

  public get Order(): number {
    if (this.FixedZIndex > 0) {
      return this.FixedZIndex;
    }
    return this.layer.getZIndex();
  }

  public set Order(value: number) {
    if (this.FixedZIndex > 0) {
      return;
    }
    this.layer.setZIndex(value);
    this.pingLayer.setZIndex(value - 1);
  }

  /**
   * get the Open Layers Features fromthe Layer
   */
  get Features(): Feature[] {
    return this.Source.getFeatures();
  }

  protected getFieldExpressionByField(field: IField, fieldStore: FieldStore): string {
    const area = Create(fieldStore.getCurrentFieldGeom(field)?.AdminArea, 0);
    return this.getFieldExpressionByValues(field, area);
  }

  protected getFieldExpressionByValues(field: IField, area: number): string {
    const fieldExpr = field?.FieldName?.trim().length > 0 ? field?.FieldName?.trim() : '';
    const fieldFullName = `${field?.FieldNumber ?? ''}-${field?.FieldSubnumber ?? ''} ${fieldExpr}`;
    const areaExpr = this.getFieldAreaExpression(area);
    return `${fieldFullName}\n${areaExpr}`;
  }

  protected getSoilFieldExpressionByValues(field: ISoilSampleField, area: number): string {
    const fieldExpr = field?.FieldName?.trim().length > 0 ? field?.FieldName?.trim() : '';
    const fieldFullName = `${field?.FieldNumber ?? ''}-${field?.FieldPart ?? ''} ${fieldExpr}`;
    const areaExpr = this.getFieldAreaExpression(area);
    return `${fieldFullName}\n${areaExpr}`;
  }

  private getFieldAreaExpression(area: number): string {
    const areaHa = area > 0 ? area / 10000 : 0;
    const areaRounded = ApMapInstance.roundNumericPipe.transform(areaHa, ApMapInstance.settingsStore.FirstSetting.DigitsAfterDecimalPoint);
    return `${areaRounded} ${ApMapInstance.translationService.FindTranslationForSelectedLanguage('Base__UnitHa')}`;
  }

  /**
   * get the Bounding Box of all selected Features
   */
  get SelectionExtent(): Extent {
    const features: Feature[] = [];
    for (const key of Object.keys(this.selectedFeatures)) {
      features.Add(this.selectedFeatures[key].feature);
    }
    return ApGeometryExtension.calcExtentOfFeatures(features);
  }

  get SelectedFeatures(): Feature[] {
    const tmp: Feature[] = [];
    for (const key of Object.keys(this.selectedFeatures)) {
      tmp.Add(this.selectedFeatures[key].feature);
    }
    return tmp;
  }

  public SyncFeatures(features: Feature[], strategy = LayerSyncStrategy.FORCE): void {
    for (const feat of features) {
      const existsFeature = this.innerSource.getFeatureById(feat.getId());
      if (existsFeature && (strategy === LayerSyncStrategy.DELETE || strategy === LayerSyncStrategy.FORCE)) {
        this.innerSource.removeFeature(existsFeature);
      }
      if (strategy === LayerSyncStrategy.FORCE || strategy === LayerSyncStrategy.RESTRAINED) {
        this.innerSource.addFeature(feat);
      }
    }
  }

  public forFeaturesAtCoordinate(coordinate: any, callback: (f: OlFeature, l: OlVectorLayer) => void): void {
    for (const feat of GeometryChecker.IsPointInPolygon(this.Source.getFeatures(), coordinate)) {
      callback(feat, this.layer);
    }
  }

  public RemoveSelectedFeatures(): void {
    if (!this.innerSource || !this.innerSource.getFeatures() || this.innerSource.getFeatures().length < 1) {
      // nothing to remove
      this.selectedFeatures = {};
      return;
    }
    const numFeatures = this.innerSource.getFeatures().length;
    let removed = 0;
    for (const feat of this.SelectedFeatures) {
      const featureId = feat.getId();
      delete this.selectedFeatures[featureId];
      if (this.innerSource.getFeatureById(featureId)) {
        this.innerSource.removeFeature(feat);
      }
      removed++;
    }
    const allExpectedRemoved = this.innerSource.getFeatures().length === (numFeatures - removed);
    if (!allExpectedRemoved) {
      console.warn(`not all Selected Features was removed from Layer ${this.name}`);
    }
  }

  public GetSelectedFeaturesAsJSON(projection): { [key: string]: string } {
    const tmp = {};
    if (!this.selectedFeatures || !Object.keys(this.selectedFeatures).Any()) {
      return tmp;
    }
    const format = new GeoJSON({
      dataProjection: projection,
      featureProjection: MAP_PROJECTION,
    });
    for (const key in this.selectedFeatures) {
      if (!this.selectedFeatures.hasOwnProperty(key)) {
        continue;
      }
      const selection = this.selectedFeatures[key];
      tmp[selection.feature.getId().toString()] = format.writeGeometry(selection.feature.getGeometry());
    }
    return tmp;
  }

  /**
   * zoom to the Layer Extent
   */
  public ZoomIn(duration?: number): void {
    if (!this.layer) {
      console.warn('layer is undefined ignore zoom!');
    }
    setTimeout(() => {
      if (!this.Extent || this.Extent.length < 4) {
        console.warn('cannot zoom to field maybe invalid Geometry?');
        return;
      }
      for (const coord of this.Extent) {
        if (coord === Infinity) {
          console.warn(`invalid Extend of Geometry ${this.Extent}`);
          return;
        }
      }
      ApMapViews.olView.fit(this.Extent, {duration: duration || 1000});
    }, 1);
  }

  /**
   * zoom to a specific Feature by Id or Feature
   */
  public ZoomToFeature(id: string | OlFeature, offset = 0): void {
    const extent = isString(id) ?
      this.Source.getFeatureById(id).getGeometry().getExtent() :
      (id as OlFeature).getGeometry().getExtent();
    ApMapViews.olView.fit(OlBuffer(extent, offset), {duration: 500});
  }

  /**
   * select a Feature by Id in Map and Zoom to it
   */
  public selectFeature(id: string, selectStyle: StyleDefinition): void {
    if (!this.Source) {
      console.warn(`cannot select feature with id ${id} no source on layer`);
      return;
    }
    const feat = this.Source.getFeatureById(id);
    if (!feat) {
      console.warn(`cannot select feature with id ${id} no feature found on layer`);
      return;
    }
    this.selectedFeatures[id] = new ApVectorSelection(feat, feat.getStyle());
    feat.setStyle(selectStyle);
  }

  public setStyle(style: StyleDefinition): void {
    if (!this.Source) {
      console.warn(`cannot set style no source on layer`);
      return;
    }
    for (const feat of this.Source.getFeatures()) {
      feat.setStyle(style);
    }
  }

  public removeApFeature(id: string): void {
    const features = _.reject(this.Features, (f) => {
      return (f.getId() !== undefined && f.getId().toString() === id);
    });
    this.clear();
    this.readFeatures(features);
  }

  /**
   * zoom to the Selected Features
   */
  public fitSelection(buffer: number = null, callback: (p0: boolean) => void = null): void {
    if (!this.SelectionExtent || this.SelectionExtent.Any((e) => e === Infinity)) {
      return;
    }
    const opt = {duration: 500};
    if (callback !== null) {
      opt['callback'] = callback;
    }
    if (buffer === null) {
      ApMapViews.olView.fit(this.SelectionExtent, opt);
    } else {
      ApMapViews.olView.fit(OlBuffer(this.SelectionExtent, buffer), opt);
    }
  }

  /**
   * deselect a Feature
   */
  public deselectFeature(id: string): void {
    const exists = find(keys(this.selectedFeatures), (f) => f === id);
    if (exists) {
      this.selectedFeatures[exists].resetStyle();
      unset(this.selectedFeatures, exists);
    }
  }

  /**
   * clear the Feature Selection
   */
  public clearSelection(): void {
    for (const id of keys(this.selectedFeatures)) {
      this.deselectFeature(id);
    }
  }

  public readFeatures(features: OlFeature[], style?: StyleDefinition, clusterSize = null): void {
    this.innerSource = new OlVectorSource({});
    if (clusterSize) {
      this.layer.setSource(new OlCluster({
        distance: clusterSize,
        source: this.innerSource,
      }));
    } else {
      this.layer.setSource(this.innerSource);
    }
    if (style) {
      this.layer.setStyle(style);
    }
    if (this.pingOptions) {
      this.pingLayer?.getSource()?.clear();
    }
    for (const f of features) {
      this.innerSource.addFeature(f);
      if (!this.pingOptions) {
        continue;
      }
      if (f.getGeometry().getType() === 'Point') {
        this.pingLayer?.getSource()?.addFeature(f);
      }
    }
  }

  public extentSource(features: OlFeature[]): void {
    if (!this.innerSource) {
      this.readFeatures(features);
    } else {
      this.innerSource.addFeatures(features);
    }
  }

  public clear(): void {
    if (this.innerSource) {
      this.innerSource.clear();
    }
    if (this.pingLayer) {
      this.pingLayer.getSource()?.clear();
    }
  }

  private triggerPingLocation(e, pingOptions: IPingOptions): void {
    pingOptions.feature = e.feature;
    pingOptions.layer = this.pingLayer;
    pingLocation(pingOptions);
  }
}

import {Component, Input}            from '@angular/core';
import {SimpleValue, SimpleValueSet} from '../charts/nutrients.stats.component';
import {GetRoundNumericService}      from '../../../ap-utils/service/get-round-numeric.service';

@Component({
  selector: 'nutrient-infos-view',
  template: `
    <div class="row" [style]="rowNum | infoViewStyle:'row':maxRows">
      <div class="head" [style]="rowNum | infoViewStyle:'head':maxRows">
        <ap-responsive-text>
          {{[item[0]] | joinPropertyValue:idParser | translate}}
        </ap-responsive-text>
      </div>
      <div class="data" [style]="rowNum | infoViewStyle:'data':maxRows">
        <ap-responsive-text>
          {{item | joinPropertyValue:categoryParser | translate}}
        </ap-responsive-text>
      </div>
    </div>
  `,
  styles: [
      `
      div.row {
        display: flex;
      }
    `,
      `
      div.head {
        padding: 5px;
        background-color: silver;
        font-weight: bold;
        width: calc(20% - 13px);
      }
    `,
      `
      div.data {
        padding: 5px;
        width: calc(80% - 13px);
      }
    `,
  ]
})
export class NutrientInfosViewComponent {
  @Input()
  maxRows = 0;
  @Input()
  rowNum = 0;
  @Input()
  item: SimpleValueSet;

  constructor(private roundNumericService: GetRoundNumericService) {
  }

  idParser = (e: SimpleValue) => e?.id;
  categoryParser = (e: SimpleValue) => `${e?.category} (${this.roundNumericService.round(e.value, 2)} ha)`;
}

import {Component} from '@angular/core';

const CONTENT = `
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.51 199.55">
  <path d="M100,36.26a58.43,58.43,0,0,1,42.56,18.68h0a66.4,66.4,0,0,1,0,90.14,57.89,57.89,0,0,1-85.16,0h0a66.35,66.35,0,0,1,0-90.1l0,0h0A58.41,58.41,0,0,1,100,36.26m93.66,52.66c-.3-2.8-3.38-4.92-6.06-4.92a20.94,20.94,0,0,1-19.51-13.67,23.12,23.12,0,0,1,5.27-24.66,5.79,5.79,0,0,0,.57-7.53,96.06,96.06,0,0,0-14.93-16,5,5,0,0,0-7.17.61c-5.61,6.59-15.7,9-23.51,5.59-8.11-3.61-13.23-12.32-12.73-21.66A5.45,5.45,0,0,0,111,.89,89.81,89.81,0,0,0,100.48.22h-.77a89.62,89.62,0,0,0-9.95.61A5.45,5.45,0,0,0,85.1,6.54c.3,9.26-4.88,17.8-12.91,21.29-7.71,3.33-17.73.9-23.34-5.62a5,5,0,0,0-7.11-.64,97.29,97.29,0,0,0-15.25,16,5.79,5.79,0,0,0,.57,7.58A23,23,0,0,1,32.32,70,21.7,21.7,0,0,1,11.85,83.49h-.07a5.24,5.24,0,0,0-5.41,4.9,104.29,104.29,0,0,0-.63,11.18v.64a106.68,106.68,0,0,0,.59,10.87c.3,2.8,3.45,4.89,6.14,4.91h.09c8.19-.2,16,5.17,19.35,13.67a23.08,23.08,0,0,1-5.28,24.67,5.79,5.79,0,0,0-.57,7.52,96.67,96.67,0,0,0,14.91,16,5,5,0,0,0,7.18-.6c5.64-6.61,15.73-9.05,23.5-5.59,8.14,3.59,13.25,12.3,12.75,21.64A5.47,5.47,0,0,0,89,199.11a88.15,88.15,0,0,0,21.2.06,5.45,5.45,0,0,0,4.66-5.72c-.31-9.24,4.88-17.79,12.9-21.27,7.76-3.35,17.74-.9,23.35,5.61a5.07,5.07,0,0,0,7.12.64,96.87,96.87,0,0,0,15.24-16,5.79,5.79,0,0,0-.56-7.59A23,23,0,0,1,167.66,130a21.12,21.12,0,0,1,19.25-13.53H187l1.06,0h.12a5.34,5.34,0,0,0,5.46-4.9,105.58,105.58,0,0,0,0-22.69" transform="translate(-5.74 -0.22)"/>
  <path d="M100.17,138.26c-19.81,0-35.92-17-35.92-38s16.11-38,35.92-38,35.91,17,35.91,38-16.1,38-35.91,38m36.49-77.09a49.9,49.9,0,0,0-73.31,0l0,0a57.25,57.25,0,0,0,0,77.59h0a49.82,49.82,0,0,0,73.31,0,57.16,57.16,0,0,0,0-77.59Z" transform="translate(-5.74 -0.22)" class="hoverFill"/>
</svg>
`;

@Component({
  selector: 'ap-icon-settings',
  template: CONTENT
})
export class ApIconSettingsComponent {
}

export enum GeometryEditorTool {
  NONE = 'none',
  DRAW_NEW = 'draw_new',
  MOVE_VERTEX = 'move_vertex',
  MOVE_SELECT_VERTEX = 'move_select_vertex',
  SPLIT = 'split',
  SELECT = 'select',
  MERGE_AREA = 'merge_area',
  DRAW_HOLE = 'draw_hole',
  DELETE_HOLE = 'delete_hole',
  MODIFY = 'modify',
  DELETE = 'delete'
}

export enum GeometryEditorToolGroup {
  NONE = 'none',
  ALL = 'all',
  MODIFY = 'modify',
  NEW = 'new'
}

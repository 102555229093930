import {IStateStore}                  from '../../ap-interface/interfaces/store';
import {Store}                        from '../index';
import {Injectable}                   from '@angular/core';
import {ApMenuUrls, PageMode}         from '../../ap-interface/enums';
import {LoginStore}                   from '../login/login.store';
import {LanguageStore}                from '../translation/language.store';
import {TranslationStore}             from '../translation/translation.store';
import {IRouterStateUrl, RouterStore} from '../router/router.store';
import {merge}                        from 'rxjs';
import {ApSignalrService}             from '../../ap-core/services/ap-signalr.service';


interface IPageStore extends IStateStore<any> {
  mode: PageMode;
}

@Injectable({providedIn: 'root'})
export class PageStore extends Store<IPageStore> {
  constructor(public backend: ApSignalrService,
              private loginStore: LoginStore,
              private routerStore: RouterStore,
              private translationStore: TranslationStore,
              private languageStore: LanguageStore) {
    super(backend, {
      data: [],
      loading: false,
      loaded: false,
      mode: PageMode.LOADINGSCREEN,
    });

    this.loginStore.BeforeNavigateToLogin.Subscribe('PageStore', () => {
      super.Mutate(s => s.mode, () => PageMode.HALFSCREEN);
    });

    merge(
      this.routerStore.Listen(s => s),
      this.translationStore.Listen(s => s.loaded),
      this.languageStore.Listen(s => s.loaded),
    ).subscribe(d => {
      if (!d) {
        return;
      }
      this._redirect(d as IRouterStateUrl);
      // wait for Mutation to be complete
      setTimeout(() => this._setPageMode(), 1);
    });
  }

  private _redirect(routerState: IRouterStateUrl): void {
    if (routerState && routerState.url === ApMenuUrls.LOGIN) {
      this.loginStore.loginWithCookie();
    }
    if (routerState && routerState.data && routerState.data.login === true &&
      this.loginStore.IsLoggedIn$.getValue() === false) {
      this.routerStore.navigateToLogin().then(() => {
      });
    }
  }

  private _setPageMode(): void {
    const ROUTER_DATA = this.routerStore.Listen(s => s.data).getValue();
    const TRANSLATION_LOADED = this.translationStore.Listen(s => s.loaded).getValue();
    const LANGUAGE_LOADED = this.languageStore.Listen(s => s.loaded).getValue();
    if (!ROUTER_DATA) {
      return;
    }

    if (TRANSLATION_LOADED && LANGUAGE_LOADED) {
      if (ROUTER_DATA.layout === PageMode.FULLSCREEN) {
        super.Mutate(s => s.mode, () => PageMode.FULLSCREEN);
      } else {
        super.Mutate(s => s.mode, () => PageMode.HALFSCREEN);
      }
    } else {
      super.Mutate(s => s.mode, () => PageMode.LOADINGSCREEN);
    }
  }
}

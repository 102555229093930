import {IAction, IApInvokerArguments} from '../../interfaces';
import IOperationMode = Data.Common.IOperationMode;

export enum OperationModesActions {
  OPERATION_MODES_LOAD = '[operation_modes] load',
  OPERATION_MODES_LOAD_SUCCESS = '[operation_modes] load success',
}

export class OperationModesLoad implements IAction {
  readonly type = OperationModesActions.OPERATION_MODES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class OperationModesLoadSuccess implements IAction {
  readonly type = OperationModesActions.OPERATION_MODES_LOAD_SUCCESS;

  constructor(public payload: IOperationMode[]) {
  }
}

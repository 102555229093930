import {Pipe, PipeTransform} from '@angular/core';
import {SidebarMode}         from '../../../ap-interface/enums';
import {Trace}               from '../../../debug-utils/ApplicationTracer';
import {FilterByStringPipe}  from './filter-by-string.pipe';
import IMenuEntry = Data.MenuStructure.IMenuEntry;

@Pipe({
  name: 'isMainExpanded'
})
export class IsMainExpandedPipe implements PipeTransform {

  constructor(private filterByString: FilterByStringPipe) {
  }

  @Trace()
  transform(main: IMenuEntry, ...args: [string, SidebarMode, boolean, string]): boolean {
    // while switching to half-mode nothing
    if (args[1] === SidebarMode.HALF && args[2]) {
      return false;
    }

    if (args[2]) {
      // Check for big menu
      // user did not select any id => use default preselect
      if (args[3] !== '') {
        return main.Url === args[3] || this.filterByString.transform(main, args[3], 'Url');
      } else {
        return this.filterByString.transform(main, args[0], 'Url');
      }

    } else {
      // Check for collapsed menu
      return main.Url === args[3];
    }
  }

}

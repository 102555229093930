import {IStateStore}                       from '../../ap-interface/interfaces/store';
import {Injectable}                        from '@angular/core';
import {Store}                             from '../index';
import {
  ApCustomTypes,
  ExportShapeSoilSamplePlanning,
  ExportShapeSoilSamplePlanningSuccess,
  LoadSoilSamplePlanning,
  LoadSoilSamplePlanningSuccess,
  NetTypes,
  SaveSoilSamplePlanning
}                                          from 'invoker-transport';
import {SaveSoilSamplePlanningSaveSuccess} from '../../../../projects/invoker-transport/src/lib/actions/nutrients';
import ISoilSamplePlanning = Data.IpsPlanning.ISoilSamplePlanning;
import ISoilSamplePlanningExportData = Data.IpsPlanning.ISoilSamplePlanningExportData;
import {ApSignalrService}                  from '../../ap-core/services/ap-signalr.service';

interface ISoilSamplePlanningStore extends IStateStore<ISoilSamplePlanning> {
  shapeExport: ArrayBuffer;
}

@Injectable({providedIn: 'root'})
export class SoilSamplePlanningStore extends Store<ISoilSamplePlanningStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      shapeExport: null
    });

    backend.registerObservable(LoadSoilSamplePlanningSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(ExportShapeSoilSamplePlanningSuccess).subscribe(d => {
      super.Mutate(s => s.shapeExport, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(SaveSoilSamplePlanningSaveSuccess).subscribe(d => {
      if (d.Error) {
        console.warn(`error on save ${d.Error}`);
        return;
      }
      if (!Array.isArray(d.Data)) {
        console.warn(`expect array but was `, d.Data);
        return;
      }
      super.Mutate(s => s.data, o => {
        for (const plan of d.Data) {
          o.Replace(p => p.Id === plan.Id, plan, true);
        }
        return o;
      });
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadSoilSamplePlanning(): void {
    super.SetLoadState();
    super.Mutate(s => s.shapeExport, () => null);
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new LoadSoilSamplePlanning([]));
  }

  public saveSoilSamplePlannings(soilSamplePlannings: ISoilSamplePlanning[]): void {
    this.DispatchBackend(new SaveSoilSamplePlanning([
      {
        Name: 'soilSamplePlannings',
        Type: ApCustomTypes.Data_IpsPlanning_SoilSamplePlanning + '[]',
        Value: soilSamplePlannings
      }
    ]));
  }

  public exportShape(exportItems: ISoilSamplePlanningExportData[], targetDate: Date, filename: string, countryCode: string): void {
    super.SetLoadState();
    super.Mutate(s => s.shapeExport, () => null);
    this.DispatchBackend(new ExportShapeSoilSamplePlanning([
      {
        Name: 'exportItems',
        Type: ApCustomTypes.Data_IpsPlanning_SoilSamplePlanningExportData + '[]',
        Value: exportItems
      },
      {Name: 'targetDate', Type: NetTypes.DATETIME, Value: targetDate},
      {Name: 'filename', Type: NetTypes.STRING, Value: filename},
      {Name: 'countryCode', Type: NetTypes.STRING, Value: countryCode}
    ]));
  }
}

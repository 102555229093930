import IReadDriversMachinesModel = Data.DocuContext.DriversMachines.IReadDriversMachinesModel;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum DriverMachineActions {
  DRIVER_MACHINE_LOAD = '[driver_machine] load',
  DRIVER_MACHINE_LOAD_SUCCESS = '[driver_machine] load success',
  DRIVER_MACHINE_LOAD_FAIL = '[driver_machine] load fail',
  DRIVER_MACHINE_UPDATE = '[driver_machine] update',
  DRIVER_MACHINE_CREATE = '[driver_machine] create',
  DRIVER_MACHINE_DELETE = '[driver_machine] delete',
  DRIVER_MACHINE_DELETE_SUCCESS = '[driver_machine] delete success',
  DRIVER_MACHINE_SAVE_SUCCESS = '[driver_machine] save success',
  DRIVER_MACHINE_SAVE_FAIL = '[driver_machine] save fail',
}

export class DriverMachineLoad implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriverMachineLoadSuccess implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_LOAD_SUCCESS;

  constructor(public payload: IReadDriversMachinesModel[]) {
  }
}

export class DriverMachineLoadFail implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class DriversMachinesUpdate implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversMachinesCreate implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversMachinesDelete implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversMachinesDeleteSuccess implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_DELETE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversMachinesSaveSuccess implements IAction {
  readonly type = DriverMachineActions.DRIVER_MACHINE_SAVE_SUCCESS;

  constructor(public payload: IReadDriversMachinesModel) {
  }
}

import {Pipe, PipeTransform}             from '@angular/core';
import {ApDynGridColumnConfigBase}       from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridClassColorColumnConfig} from '../../config/ap-dyn-grid-class-color-column-config';
import {Trace}                           from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asClassColor'
})
export class AsClassColorPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase, ...args: any[]): ApDynGridClassColorColumnConfig {
    return config as ApDynGridClassColorColumnConfig;
  }

}

import VectorTileLayer                         from 'ol/layer/VectorTile';
import VectorTile                              from 'ol/source/VectorTile';
import MVT                                     from 'ol/format/MVT';
import {createXYZ}                             from 'ol/tilegrid';
import {getMapFactoryStyler, MapFactoryStyler} from './style';
import TileState                               from 'ol/TileState';
import * as ax                                 from 'axios';
import {MapFactoryAuthenticator}               from './authentication';
import OlFeature                               from 'ol/Feature';
import {MapStore}                              from '../stores/map/map.store';
import Feature                               from 'ol/Feature';
import {GeoJSON}                               from 'ol/format';
import Geometry                                from 'ol/geom/Geometry';
import {ApMapInstance}                         from '../ap-map';

let stylerInstance: MapFactoryStyler = null;

export class LayerFactory {

  static createMapFactoryLayer(name: string, address: string, url: string, filter: (f: Feature) => boolean, mapStore: MapStore): { name: string; layer: VectorTileLayer; source: VectorTile } {
    url = `${address}${url}`;
    if (!stylerInstance) {
      stylerInstance = getMapFactoryStyler(address, mapStore);
    }
    const s = LayerFactory.createMapFactorySource(url, filter);
    const l = new VectorTileLayer({
      renderMode: 'image',
      declutter: false,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      source: s,
      style: stylerInstance.styleFunction.bind(stylerInstance),
    });
    l.set('name', name);
    return {
      name,
      layer: l,
      source: s
    };
  }

  static createMapFactorySource(url: string, filter: (f: OlFeature) => boolean): VectorTile {
    return new VectorTile({
      format: ApMapInstance.isDebugModeEnabled ? new GeoJSON() : new MVT(),
      tileGrid: createXYZ({maxZoom: 22}),
      tilePixelRatio: 16,
      url,
      tileLoadFunction: (tile, src) => {
        const tmp = src.Split('?params=');
        tile.setLoader(async (extent, resolution, projection) => {
          ax.default({
            method: 'POST',
            url: tmp[0],
            responseType: 'arraybuffer',
            headers: {
              Authorization: await MapFactoryAuthenticator.getHash(),
            },
            data: btoa(tmp[1]),
          }).then((res) => {
            if (res.status !== 200) {
              tile.setState(TileState.ERROR);
              return;
            }
            let features: Feature<Geometry>[] = [];
            // While debugMode is enabled the map is rendered with full qualified feature instances
            // Those full features allows us to convert them to GeoJson
            // Without debugMode the maps consists of so called RenderFeatures which is
            // a lightweight feature implementation to increase performance
            if (ApMapInstance.isDebugModeEnabled) {
              try {
                // In order to generate valid geoJson output we need to convert from RenderFeature to a regular
                // feature and then convert it to GeoJson format using the correct projection (3857 => 4326
                // const geoJsonFormat = new GeoJSON();
                features = new MVT({featureClass: Feature as unknown as Feature})
                  .readFeatures(res.data, {
                    extent,
                    featureProjection: projection
                  }) as Feature[];
              } catch (e) {
                console.error(`Error while generating tiles for debugMode (full feature tiles): ${JSON.stringify(e)}`);
              }
            }
            // Regular RenderFeature for better performance
            else {
              const format = tile.getFormat(); // ol/format/MVT configured as source format
              features = format.readFeatures(res.data, {
                extent,
                featureProjection: projection
              });
            }
            if (typeof filter === 'function') {
              tile.setFeatures(features.filter(_ => filter(_)));
            } else {
              tile.setFeatures(features);
            }
          }).catch(() => {
            tile.setState(TileState.ERROR);
          });
        });
      }
    } as any);
  }
}

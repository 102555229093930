
export class ApDynGridPagerConfigBase {
  public type = ApDynGridPagerControlType.None;

  constructor () {
  }
}

export class ApDynGridPagerButton {

  constructor (options: {} = {}) {
  }
}

export enum ApDynGridPagerControlType {
  None = 0,
  Pager = 1,
  Wizard = 2
}

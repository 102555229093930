<ng-container *ngIf="!(data | async)">
  <div class="empty-message">
    <p>{{ emptyTitle | translate }}</p>
  </div>
</ng-container>
<ng-container *ngIf="(data | async)">
  <div class="ap-dashboard-stats">
    <h3>{{ 'InfoText_Greetings' | translate }} {{ (data | async).UserForename }}</h3>
  </div>
  <div class="ap-dashboard-stats-table-container">
    <table class="ap-dashboard-stats-table">
      <tbody>
      <tr>
        <td>{{ 'Global__General_Information' | translate }}</td>
        <td>
          {{ 'Nutrients_Pages__FieldArea' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/fieldmanagement/overview">&rarr;</a>
        </td>
        <td>{{ (data | async)?.General.AreaHa | getRoundNumeric }} ha</td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'Nutrients__CropRotation' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/fieldmanagement/crop-rotation">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="progressBarTemplate; context: {value: (data | async).General.CropRotationPercent}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'InfoText__Fields_With_Sampling' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/fieldmanagement/soil-sample-fields">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="progressBarTemplate; context: {value: (data | async).General.FieldsWithSamplingPercent}"
          ></ng-container>
        </td>
      </tr>
      <tr class="special-spacing">
        <td>
          {{ 'Global__BasicFertilization' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/nutrients/overview">&rarr;</a>
          <br/>
          <span class="un-bold">(% ha)</span>
        </td>
        <td colspan="2">
          <kendo-chart>
            <kendo-chart-area [height]="250">
              <kendo-chart-series>
                <kendo-chart-series-item *ngFor="let item of (data | async).BaseFertilisation.PlanningData"
                                         [data]="item.Data"
                                         [name]="item.TranslationKey | translate"
                                         [color]="item.Color"
                                         [border]="{width: 1,dashType: 'solid',color: borderColor}"
                                         [spacing]="0"
                                         [gap]="0.5"
                                         type="bar"
                                         stack="true">
                  <kendo-chart-series-item-tooltip [background]="'#ffffffbf'"
                                                   [border]="{color: borderColor,dashType: 'solid',width: 1}"
                                                   [color]="strokeColor"
                  >
                    <ng-template kendoChartSeriesTooltipTemplate
                                 let-value="value">
                      {{ value }}%
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend position="bottom"
                                  orientation="horizontal"
                                  [labels]="{color: strokeColor}"
              ></kendo-chart-legend>
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [color]="strokeColor"
                                             [min]="0"
                                             [max]="100"
                ></kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="(data | async).BaseFertilisation.Elements"
                                                [color]="strokeColor"
                ></kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
            </kendo-chart-area>
          </kendo-chart>
        </td>
      </tr>
      <tr class="special-spacing">
        <td>{{ 'Global__NFertilization' | translate }}</td>
        <td>
          {{ 'InfoText__Fields_With_Applications' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/n-fertilization/overview">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="progressBarTemplate; context: { value: (data | async).NFertilization.FieldsWithApplicationsPercent}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'Global__Logfiles' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/n-fertilization/logfiles">&rarr;</a>
        </td>
        <td>{{ (data | async)?.NFertilization.LogfilesTotalAmount }}</td>
      </tr>
      <tr>
        <td></td>
        <td>{{ 'Logfiles_booked' | translate }}</td>
        <td>
          <ng-container
            *ngTemplateOutlet="progressBarTemplate; context: { value: (data | async).NFertilization.LogfilesPercent}"
          ></ng-container>
        </td>
      </tr>
      <tr class="special-spacing">
        <td>
          {{ 'Base__TaskWriter' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/task-management/overview">&rarr;</a>
        </td>
        <td>{{ 'Open_Tasks' | translate }}</td>
        <td>
          {{ (data | async)?.Tasks.OpenFieldsCount }}
          {{ 'Global__Fields' | translate }}
          ({{ (data | async)?.Tasks.OpenFieldsAreaHa | getRoundNumeric }} ha)
        </td>
      </tr>
      <tr>
        <td></td>
        <td>{{ 'Base__TaskWriter' | translate }} {{ 'Task_Mgmt_Exported' | translate }}</td>
        <td>
          <ng-container
            *ngTemplateOutlet="progressBarTemplate; context: { value: (data | async).Tasks.ExportedTasksPercent}"
          ></ng-container>
        </td>
      </tr>
      <tr class="special-spacing">
        <td>{{ 'Base__BasicData' | translate }}</td>
        <td>
          {{ 'Global__CropTypes' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/basedata/croptypes">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="numberAmountTemplate; context: {value: (data | async)?.BaseData.CropTypes}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'Settings__Lbl_Menu_Inorganicfertilizers' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/basedata/fertilizers">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="numberAmountTemplate; context: {value: (data | async)?.BaseData.FertiliserMineral}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'Settings__Lbl_Menu_Organicfertilizers' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/basedata/fertilizers">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="numberAmountTemplate; context: {value: (data | async)?.BaseData.FertiliserOrganic}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'InfoText__Plant_Protection_Products' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/basedata/plant-protections">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="numberAmountTemplate; context: {value: (data | async)?.BaseData.PlantProtectionProducts}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'Docu_Ini__Engines' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/basedata/docu-ressources">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="numberAmountTemplate; context: {value: (data | async)?.BaseData.Machines}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'Docu_Ini__Implement' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/basedata/docu-ressources">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="numberAmountTemplate; context: {value: (data | async)?.BaseData.Implements}"
          ></ng-container>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          {{ 'Docu_Ini__Workers' | translate }}
          <a class="redirect-link" title="{{ linkTitle }}" routerLink="/basedata/docu-ressources">&rarr;</a>
        </td>
        <td>
          <ng-container
            *ngTemplateOutlet="numberAmountTemplate; context: {value: (data | async)?.BaseData.Workers}"
          ></ng-container>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-template #progressBarTemplate
             let-value="value"
>
  <div [style.display]="'flex'"
       [style.width]="'100%'"
  >
    <ap-percent-bar [style.flex-grow]="1"
                    [containerStyles]="{backgroundColor: 'white', border: '1px solid', borderColor: borderColor}"
                    [barStyles]="{backgroundColor: setBarColorDependingOnValue(value), color: strokeColor}"
                    [maximum]="100"
                    [showValue]="false"
                    [value]="value"
    ></ap-percent-bar>
    <div [style.width]="'45px'"
         [style.padding-left]="'10px'"
    >
      {{ value }}%
    </div>
  </div>
</ng-template>

<ng-template #numberAmountTemplate
             let-value="value"
>
  <span [ngStyle]="setStylesForAmountValues(value)">{{ value }}</span>
</ng-template>

import {
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
}                            from '@angular/core';
import {Align}               from '@progress/kendo-angular-popup';
import {PopupAnimation}      from '@progress/kendo-angular-popup/dist/es2015/models/popup-animation.interface';
import {PagerContextService} from '@progress/kendo-angular-grid';
import {filter, map}         from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription
}                            from 'rxjs';
import {PagerContextChanges} from '@progress/kendo-angular-grid/dist/es2015/pager/pager-context.service';

interface ApPagerContextService {
  total: number;
  skip: number;
  pageSize: number;
  changes: Subject<{ total: number, skip: number, pageSize: number }>;

  changePage(page: number): void;
}

@Component({
  selector: 'ap-grid-pager-numeric',
  templateUrl: './ap-grid-pager-numeric.component.html',
  styleUrls: ['./ap-grid-pager-numeric.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApGridPagerNumericComponent implements AfterContentInit, OnDestroy {
  @Input() pagerContextService: PagerContextService | ApPagerContextService;
  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', {read: ElementRef}) public popup: ElementRef;

  readonly columns = 5;
  readonly rows = 5;
  readonly dimension = this.columns * this.rows;

  public anchorAlign: Align = {horizontal: 'center', vertical: 'top'};
  public popupAlign: Align = {horizontal: 'center', vertical: 'bottom'};
  public animate: PopupAnimation = {type: 'slide', direction: 'up', duration: 200};
  public show = false;
  public currentPage = new BehaviorSubject(undefined);
  public chunks = new BehaviorSubject<number[][]>([]);
  public chunkPage = new BehaviorSubject<number>(0);
  public pageView: Observable<number[]>;

  private _subscriptions: Subscription[] = [];

  ngAfterContentInit(): void {
    this.currentPage.next(this.pagerContextService.skip / this.pagerContextService.pageSize + 1);
    this._subscriptions.push(this.pagerContextService.changes.pipe(
      map((e) => e.skip / e.pageSize + 1),
    ).subscribe((page) => this.currentPage.next(page)));

    this.chunks.next(this._getChunks(this.pagerContextService));
    this._subscriptions.push(this.pagerContextService.changes.pipe(map((e) =>
      this._getChunks(e)
    )).subscribe((chunks) => this.chunks.next(chunks)));

    this.pageView = combineLatest([
      this.chunks,
      this.chunkPage
    ]).pipe(
      filter(([chunks, page]) => page < chunks.length),
      map(([chunks, page]) => chunks[page])
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  getColumn(page: number, columns: number): number {
    return (page - 1) % columns + 1;
  }

  getRow(page: number, rows: number): number {
    return Math.floor((page - 1) / rows) % rows + 1;
  }

  onPageChange(newPage: any): void {
    this.pagerContextService.changePage(newPage - 1);
  }

  togglePopup(): void {
    this.show = !this.show;
    if (this.show) {
      this.chunkPage.next(Math.floor((this.currentPage.value - 1) / this.dimension));
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (!this._contains(event.target)) {
      this.show = false;
    }
  }

  changeChunkPage(n: number): void {
    this.chunkPage.next(this.chunkPage.value + n);
  }

  onWheel(event: WheelEvent): void {
    if (event.deltaY < 0 && this.chunkPage.value > 0) {
      this.changeChunkPage(-1);
    } else if (event.deltaY > 0 && this.chunkPage.value < (this.chunks.value.length - 1)) {
      this.changeChunkPage(1);
    }
  }

  private _contains(target: any): boolean {
    return this.anchor.nativeElement && this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false);
  }

  private _getChunks(e: PagerContextService | PagerContextChanges): any[] {
    const chunks = [];
    const arr = Array(Math.ceil(Math.max(1, e.total) / e.pageSize)).fill(undefined).map((v, i) => i + 1);
    do {
      chunks.push(arr.slice(chunks.length * this.dimension, chunks.length * this.dimension + this.dimension));
    } while (chunks.length * this.dimension < arr.length);
    return chunks;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {FactorService}       from '../service/ap-factor.service';
import IUnit = Data.Common.IUnit;

@Pipe({
  name: 'factorPipe'
})
export class FactorPipe implements PipeTransform {

  constructor(private factorService: FactorService) {
  }

  transform(value: number, source: IUnit, target: IUnit): number {
    return this.factorService.calculate(value, source, target);
  }
}

import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
        <path d="M96,32a64,64,0,1,0,64,64A64,64,0,0,0,96,32Zm5.4,110.1H87.69V128.4H101.4Zm15.18-46.33a39.47,39.47,0,0,1-15.18,12.07v13.7H87.69V104.41q3.38-2,5.87-3.74A57.17,57.17,0,0,0,99,96.3,30.6,30.6,0,0,0,103.84,91,27,27,0,0,0,107,84.66,23,23,0,0,0,108.25,77q0-10.27-10.28-10.27-5.77,0-8,3.45T87.69,80.43H74V77q0-11.72,6.18-17.85T98,53q11.73,0,17.88,6.18T122,77Q122,88.35,116.58,95.77Z"></path>
    </svg>
`;

@Component({
  selector: 'ap-help-icon',
  template: CONTENT
})
export class ApHelpIconComponent {
}

import {Injectable}             from '@angular/core';
import {GetRoundNumericService} from '../../ap-utils/service/get-round-numeric.service';
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;
import IRbStatistic = Data.Nutrients.IRbStatistic;
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;

@Injectable({providedIn: 'root'})
export class ApNutrientStatisticService {

  constructor(private roundNumericService: GetRoundNumericService) {
  }

  public getAverageNutrientFromStatistic(bandIdX: number, statistics: IFieldNutrientPlanningStatistic[]): any {
    if (bandIdX >= 0 && !!statistics) {
      const found = statistics.Find(_ => _.Band === bandIdX);
      if (!!found) {
        return this.roundNumericService.roundAsNumber(found.Mean);
      }
    }
    return '-';
  }

  public getAverageNutrientFromStatistics(bandIdX: number, summary: IBasicFertilisationSummaries, isBooking: boolean): any {
    let planBooks: Data.TaskManagement.IBasicFertilisationPlanBooks[];
    if (isBooking) {
      planBooks = summary.BasicFertilisationPlanBooks.filter(_ => _.BookedBy != null);
    } else {
      planBooks = summary.BasicFertilisationPlanBooks.filter(_ => _.BookedBy == null);
    }
    if (bandIdX >= 0 && !!planBooks) {
      let mean = 0;
      let areaSum = 0;
      planBooks.forEach(planBook => {
        const found = planBook.Statistic?.Find(_ => _?.Band === bandIdX);
        if (!!found) {
          areaSum += planBook.Area;
          mean += planBook.Area * found.Mean;
        }
      });
      mean /= areaSum;
      return this.roundNumericService.roundAsNumber(mean);
    }
    return '-';
  }

  public getMinRb(bandIdX: number, statistics: IRbStatistic): number {
    if (bandIdX >= 0 && !!statistics) {
      switch (bandIdX) {
        case 0:
          return statistics.RbOneStatisticP.Min;
        case 1:
          return statistics.RbOneStatisticK.Min;
        case 2:
          return statistics.RbOneStatisticMg.Min;
        case 3:
          return statistics.RbOneStatisticCao.Min;
        case 4:
          return statistics.RbTwoStatisticP.Min;
        case 5:
          return statistics.RbTwoStatisticK.Min;
        case 6:
          return statistics.RbTwoStatisticMg.Min;
        case 7:
          return statistics.RbTwoStatisticCao.Min;
        default:
          return 0;
      }
    }
    return 0;
  }

  public getMaxRb(bandIdX: number, statistics: IRbStatistic): number {
    if (bandIdX >= 0 && !!statistics) {
      switch (bandIdX) {
        case 0:
          return statistics.RbOneStatisticP.Max;
        case 1:
          return statistics.RbOneStatisticK.Max;
        case 2:
          return statistics.RbOneStatisticMg.Max;
        case 3:
          return statistics.RbOneStatisticCao.Max;
        case 4:
          return statistics.RbTwoStatisticP.Max;
        case 5:
          return statistics.RbTwoStatisticK.Max;
        case 6:
          return statistics.RbTwoStatisticMg.Max;
        case 7:
          return statistics.RbTwoStatisticCao.Max;
        default:
          return 0;
      }
    }
    return 0;
  }

  public getAverageRb(bandIdX: number, statistics: IRbStatistic): any {
    if (bandIdX >= 0 && !!statistics) {
      switch (bandIdX) {
        case 0:
          return statistics.RbOneAvgP;
        case 1:
          return statistics.RbOneAvgK;
        case 2:
          return statistics.RbOneAvgMg;
        case 3:
          return statistics.RbOneAvgCao;
        case 4:
          return statistics.RbTwoAvgP;
        case 5:
          return statistics.RbTwoAvgK;
        case 6:
          return statistics.RbTwoAvgMg;
        case 7:
          return statistics.RbTwoAvgCao;
        default:
          return '-';
      }
    }
    return '-';
  }
}

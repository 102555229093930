<div *ngIf="networkIsOffline">
  <div class="ap-offline-overlay"></div>
  <div class ="ap-fullscreen-spinner"
    style="z-index: 9999;">
    <span class="k-i-loading k-icon" style="font-size: 64px;"></span>
    <p>Waiting for network....</p>
  </div>
</div>
<ng-template #toolTipTemplate let-anchor>
  <ap-tooltip [text]="apTooltipService.ReadTooltipText(anchor)"></ap-tooltip>
</ng-template>
<ap-notify></ap-notify>
<div *ngIf="isDebugMode" class="ap-debug-indicator">DEBUG</div>
<div *ngIf="(pageMode$ | async) === pageModes.FULLSCREEN" class="ap-fullheight">
  <div class="ap-login-screen">
    <div class="ap-loginContentBlending66"></div>
    <div class="ap-loginContentBlending33"></div>
    <kendo-splitter orientation="vertical" class="ap-fullheight ap-transparent-background ap-login-content">
      <kendo-splitter-pane [resizable]="false" size="25%" class="ap-login-splitter-pane-header">
        <div class="ap-login-logo">
          <ap-logo></ap-logo>
        </div>
      </kendo-splitter-pane>
      <kendo-splitter-pane class="ap-login-splitter-pane">
        <router-outlet></router-outlet>
      </kendo-splitter-pane>
      <kendo-splitter-pane [resizable]="false">
        <div class="ap-login-footer">
          <div>
            <button kendoButton [class.dark-bg]="true" (click)="onLegalNoticeClick()" data-cy="login_page_button_imprint"
            >{{'Lbl_ImprintText' | translateAsync | async}}</button>
            <button kendoButton [class.dark-bg]="true" (click)="onContactClick()" data-cy="login_page_button_contact"
            >{{'Tip_ContactText' | translateAsync | async}}</button>
            <button kendoButton [class.dark-bg]="true" (click)="onTeamViewerClick()" data-cy="login_page_button_teamviewer"
            >{{'Global__Remote_Maintenance' | translateAsync | async}}</button>
            <kendo-dropdownbutton [class.dark-bg]="true"
                                  [data]="(languages$ | async)"
                                  [icon]="'globe-outline'"
                                  [textField]="'LanguageName'"
                                  (itemClick)="onChange($event)"
                                  data-cy="login_page_dropdown_language_select"
            >{{(language$ | async) ? (language$ | async).LanguageName : ''}}</kendo-dropdownbutton>
          </div>
        </div>
      </kendo-splitter-pane>
    </kendo-splitter>
  </div>
</div>
<ng-container *ngIf="(pageMode$ | async) === pageModes.HALFSCREEN" class="ap-fullheight">
  <ap-modal-dialog></ap-modal-dialog>
  <inner-loading [visible]="innerLoadingVisible$ | async" [text]="'Global__Farm_Loading'"></inner-loading>
  <inner-loading [visible]="longOperationOverlayVisible$ | async" [text]="'Global__Please_Wait'"></inner-loading>
  <inner-loading [visible]="clientErrorOverlayVisible$ | async" [text]="'Global__Client_Error_Occurred'" [iconClass]="'ap-error-image'"></inner-loading>
  <div class="ap-layout-wrapper">
    <div class="ap-header">
      <div class="ap-logo" [@logoScale]="(sidebarMode$ | async)">
        <ap-logo></ap-logo>
      </div>
      <div class="ap-farm-panel">
        <ap-farm-selection style="flex: 2 1 50%"></ap-farm-selection>
        <ap-harvest-selection></ap-harvest-selection>
        <ap-current-time></ap-current-time>
        <ap-task-list></ap-task-list>
        <ap-notification></ap-notification>
        <ap-user-profile></ap-user-profile>
      </div>
    </div>
    <div [class.ap-main-content]="true">
      <ng-container>
        <div [@animateChild] class="ap-sidebar" [@sidebarTranslate]="(sidebarMode$ | async)"
             (@sidebarTranslate.done)="onFinishChangeWidth($event)"
             (@sidebarTranslate.start)="onStartChangeWidth($event)">
          <ap-menu [visiblePanel]="visiblePanel"></ap-menu>
        </div>
      </ng-container>
      <div [class.ap-main-view]="true"
           [@changeWidthMainView]="mapViewMode$ | async">
        <ap-view-container></ap-view-container>
        <div [@translateComponent]="formState$ | async">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div [class.ap-tab-view]="true"
           [class.ap-box-shadow-left]="(mapViewMode$ | async) === mapViewModes.MAXIMIZE"
           [@changeWidthMapView]="mapViewMode$ | async"
           (@changeWidthMapView.start)="onMapViewChangeStart()"
           (@changeWidthMapView.done)="onMapViewChangeFinish()">
        <ap-tabstrip *ngIf="tabViewVisible"
                     [hasArrows]="false"
                     [hidden]="(mapViewMode$ | async) === mapViewModes.HIDE">
        </ap-tabstrip>
      </div>
    </div>
  </div>
</ng-container>
<div *ngIf="(pageMode$ | async) === pageModes.LOADINGSCREEN">
  <div class="ap-fullscreen-spinner"
    style="z-index: 2;">
    <span class="k-i-loading k-icon" style="font-size: 64px;"></span>
    <p>Loading....</p>
  </div>
</div>

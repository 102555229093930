import {AfterContentInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApDynformsComponent}                                       from '../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsConfigFieldset
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {FormBuilder, Validators}                                   from '@angular/forms';
import {
  ApDynformsConfigCheckbox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  ApDynformsConfigMultiSelect
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-multiselect';
import {Subscription}                                              from 'rxjs';
import {filter, find, merge, remove}                               from 'lodash';
import {ApDynformsValidator}                                       from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsConfigComboBox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApGetAvailableUserGroupsPipe
}                                                                  from '../../stores/pipes/ap-get-available-usergroups.pipe';
import {
  ApGetAssignedUserGroupsPipe
}                                                                  from '../../stores/pipes/ap-get-assigned-usergroups.pipe';
import {InstrumentStore}                                           from '../../stores/docu/instrument.store';
import {WorkTypesStore}                                            from '../../stores/base-data/worrktypes.store';
import {FarmUsersStore}                                            from '../../stores/farm/farm.users.store';
import {FormStore}                                                 from '../../stores/layout/form.store';
import {LoginStore}                                                from '../../stores/login/login.store';
import IFarmUser = Data.Authentication.IFarmUsers;
import IUsergroup = Data.AccessPermission.IUsergroup;
import IFarm = Data.Authentication.IFarm;
import IUser = Data.Authentication.IUser;

@Component({
  selector: 'ap-users-assignment-farm-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_users_assignment_farm_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_users_assignment_farm_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`
})
export class ApUsersAssignmentFarmEditComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: IFarmUser;
  public allFarms: IFarm[];
  public allUsers: IUser[];
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public caption: string;
  private subscriptions: Array<Subscription> = [];
  public listUsergroupItems: Array<{ text: string, value: number }> = [];
  public assignedUserGroups: Array<{ text: string, value: number }> = [];

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private instrumentsStore: InstrumentStore,
              private worktypeStore: WorkTypesStore,
              private loginStore: LoginStore,
              private farmUsersStore: FarmUsersStore,
              private getAvailableUserGroups: ApGetAvailableUserGroupsPipe,
              private getAssignedUserGroups: ApGetAssignedUserGroupsPipe) {
    this.allFarms = this.loginStore.Farms;
  }

  ngOnInit(): void {
    this.listUsergroupItems = [];
    this.assignedUserGroups = [];
    if (!this.data) {
      this.isNewMode = true;
      const user: IUser = {
        Id: undefined,
        EMail: '',
        SessionHash: '',
        RoleId: undefined,
        Phone: '',
        PasswordHash: '',
        Mobile: '',
        LastReadNews: undefined,
        Language: '',
        IsLocked: undefined,
        FAX: '',
        RoleType: undefined,
        Surname: '',
        UserName: '',
        Forename: '',
        IsConsultant: undefined,
        RedirectToNews: true
      };
      const usergroup: IUsergroup = {
        Farm_Id: undefined,
        Id: undefined,
        Name: '',
        Group: undefined,
        Group_Id: undefined
      };
      const usergroups: IUsergroup[] = [];
      usergroups.push(usergroup);
      this.data = {
        Id: undefined,
        User: user,
        UserId: undefined,
        BranchOfActivityId: undefined,
        Farm: this.loginStore.SelectedFarm,
        FarmId: this.loginStore.SelectedFarmId,
        IsActive: true,
        IsDriver: false,
        Driver: undefined,
        UsergroupUser: [],
      };
    } else {
      this.isNewMode = false;
    }
    this.caption = 'Global_User_Assignment_Edit';
    this.listUsergroupItems = this.getAvailableUserGroups.transform();
    this.assignedUserGroups = this.getAssignedUserGroups.transform();
    this.getDataFromStore();
    this.formBuilder();

  }

  onChanges(): void {
    setTimeout(() => {
      this.dynForm.form.get('FarmId').valueChanges.subscribe(val => {
        const notAssigned = [];
        const currentFarmUsers = filter(this.farmUsersStore.getFarmUsers(), sf => sf.FarmId === val);
        for (const aU of this.allUsers) {
          const found = find(currentFarmUsers, cFU => cFU.UserId === aU.Id);
          if (!found) {
            notAssigned.push(aU);
          }
        }
        if (this.isNewMode) {
          remove(this.allUsers, () => {
            return true;
          });
          for (const nA of notAssigned) {
            this.allUsers.push(nA);
          }
        }
      });
    }, 100);
  }


  /**
   * when the component is loaded
   */
  ngAfterContentInit(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }

  getDataFromStore(): void {

  }

  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigComboBox({
            key: 'FarmId',
            value: this.data.FarmId,
            valueField: 'Id',
            textField: 'FarmName',
            valuePrimitive: true,
            label: 'Global__Farm',
            options: this.allFarms,
            disabled: !this.isNewMode,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
                always: true
              }),
            ]
          }),

          new ApDynformsConfigComboBox({
            key: 'UserId',
            value: this.data.UserId,
            valueField: 'Id',
            textField: 'UserName',
            valuePrimitive: true,
            label: 'Global__Employees',
            options: this.allUsers,
            disabled: !this.isNewMode,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
                always: true
              }),
            ]
          }),

          new ApDynformsConfigMultiSelect({
            key: 'Usergroups',
            value: this.assignedUserGroups,
            valueField: 'value',
            textField: 'text',
            label: 'Global_User_Groups',
            options: this.listUsergroupItems,
            valuePrimitive: false,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
                always: true
              }),
            ]
          }),

          new ApDynformsConfigCheckbox({
            key: 'IsActive',
            label: 'Global__Active',
            value: this.data.IsActive,
          }),

          new ApDynformsConfigCheckbox({
            key: 'Worker',
            label: 'Global__Worker',
            value: this.data.IsDriver,
          }),
        ]
      })
    ];
    this.onChanges();
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    this.formStore.closeForm();
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

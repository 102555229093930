import {Pipe, PipeTransform}         from '@angular/core';
import {Trace}                       from '../../debug-utils/ApplicationTracer';
import {LanguageStore}               from '../../stores/translation/language.store';
import {TranslationStore}            from '../../stores/translation/translation.store';
import {BehaviorSubject, Observable} from 'rxjs';


/**
 * Translation Pipe
 */
@Pipe({
  name: 'translateAsync'
})
export class ApTranslationAsyncPipe implements PipeTransform {
  constructor(private languageStore: LanguageStore,
              private translationStore: TranslationStore) {
  }

  @Trace()
  transform(value: string): Observable<string> {
    return this.getObservable(value);
  }

  private getObservable(value: string): Observable<string> {
    const language = this.languageStore.SelectedLanguage;
    const stream = new BehaviorSubject<string>(
      this.translationStore.FindTranslation(value, language ? language.Key : 'en')
    );

    this.translationStore.translationsLoaded.subscribe(() => {
      stream.next(this.translationStore.FindTranslation(value, this.languageStore?.SelectedLanguage?.Key));
    });
    return stream;
  }
}

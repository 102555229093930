import {Component, OnInit} from '@angular/core';
import {Observable}        from 'rxjs';
import {ApTreeViewItem}    from '../../ap-interface/interfaces/layout/ap-tree-view-item';
import {MapTreeViewStore}  from '../../stores/map/map.treeview.store';
import 'ts-tooling';

@Component({
  selector: 'ap-map-tree-view',
  templateUrl: './ap-map-tree-view.component.html',
})
export class ApMapTreeViewComponent implements OnInit {

  nodes$: Observable<ApTreeViewItem[]>;

  constructor(private store: MapTreeViewStore) {
  }

  ngOnInit(): void {
    this.nodes$ = this.store.Listen(s => s.data);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import {CropTypeStore}         from '../../stores/base-data/crop.types.store';

@Pipe({
  name: 'apGetCropTypeNamePipe',
})
export class ApGetCropTypeNamePipe implements PipeTransform {

  constructor(private cropTypeStore: CropTypeStore) {
  }

  transform(cropTypeId: string): string {
    return this.cropTypeStore.GetCropTypeName(cropTypeId);
  }
}

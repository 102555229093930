import {Component, OnDestroy, Output}  from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {APP_CONFIGURATION}             from '../../config';
import {TranslationStore}              from '../../../stores/translation/translation.store';
import {CampaignYearStore}             from '../../../stores/login/campaignyear.store';

export interface ApGridPageSizeItem {
  text: string;
  value: number;
}

@Component({
  selector: 'ap-grid-page-size',
  templateUrl: 'ap-grid-page-size.component.html',
  styleUrls: ['ap-grid-page-size.component.scss'],
})
export class ApGridPageSizeComponent implements OnDestroy {
  /**
   * Raised whenever user changes grid page size in dropdown
   */
  @Output() public pageSizeChanged = new BehaviorSubject<number>(APP_CONFIGURATION.GridPageSize);
  public pageSizeItems: ApGridPageSizeItem[] = [];
  private _subscriptions: Subscription[] = [];
  public currentValue: ApGridPageSizeItem;

  constructor(private translationStore: TranslationStore,
              private campaignYearStore: CampaignYearStore) {

    for (const gridPageSizeOption of APP_CONFIGURATION.GridPageSizeOptions) {
      this.pageSizeItems.push({
        text: gridPageSizeOption.toString(),
        value: gridPageSizeOption
      });
    }
    this.resetToDefault();
    this.campaignYearStore.Listen(_ => _.selectedYear).subscribe(_ => {
      this.resetToDefault();
    });

    this.pageSizeItems.push({
      text: `(${this.translationStore.FindTranslationForSelectedLanguage('Global__All')})`,
      value: 999999
    });
  }

  private resetToDefault(): void {
    this.currentValue = this.pageSizeItems[0];
    this.pageSizeChanged.next(this.currentValue.value);
  }

  /**
   * Handles valueChange of pagesize dropdown
   */
  public valueChange(pageSizeItem: ApGridPageSizeItem): void {
    this.pageSizeChanged.next(pageSizeItem.value);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }
}

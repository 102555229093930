import ICropGroups = Data.BaseData.ICropGroups;
import {IAction, IApInvokerArguments} from '../../interfaces';

/**
 * Actions of the Cropgroup State
 */
export enum CropGroupsActions {
  CROP_GROUPS_LOAD = '[crop_groups] load',
  CROP_GROUPS_LOAD_SUCCESS = '[crop_groups] load success',
  CROP_GROUPS_LOAD_FAIL = '[crop_groups] load fail',
  CROP_GROUPS_SAVE = '[crop_groups] save',
  CROP_GROUPS_SAVE_SUCCESS = '[crop_groups] save success',
  CROP_GROUPS_SAVE_FAIL = '[crop_groups] save fail',
  CROP_GROUPS_DELETE = '[crop_groups] delete',
  CROP_GROUPS_DELETE_SUCCESS = '[crop_groups] delete success',
  CROP_GROUPS_DELETE_FAIL = '[crop_groups] delete fail',
}

/**
 * Start to Load Cropgroups
 */
export class CropGroupsLoad implements IAction {
  readonly type = CropGroupsActions.CROP_GROUPS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Load of Cropgroups was successful
 */
export class CropGroupsLoadSuccess implements IAction {
  readonly type = CropGroupsActions.CROP_GROUPS_LOAD_SUCCESS;

  constructor(public payload: ICropGroups[]) {
  }
}

/**
 * Start to Save Cropgroups
 */
export class CropGroupsSave implements IAction {
  readonly type = CropGroupsActions.CROP_GROUPS_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Save Cropgroups successful
 */
export class CropGroupsSaveSuccess implements IAction {
  readonly type = CropGroupsActions.CROP_GROUPS_SAVE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Start to Delete Cropgroups
 */
export class CropGroupsDelete implements IAction {
  readonly type = CropGroupsActions.CROP_GROUPS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Delete Cropgroups successful
 */
export class CropGroupsDeleteSuccess implements IAction {
  readonly type = CropGroupsActions.CROP_GROUPS_DELETE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Delete Cropgroups fails
 */
export class CropGroupsDeleteFail implements IAction {
  readonly type = CropGroupsActions.CROP_GROUPS_DELETE_FAIL;

  constructor(public payload: string) {
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {keys}                from 'lodash';

@Pipe({
  name: 'getStatisticKeys'
})
export class GetStatisticKeysPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return keys(value || {});
  }

}

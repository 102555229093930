import {IAction, IApInvokerArguments} from '../../interfaces';
import IHelpArticles = Data.Common.IHelpArticles;

export enum HelpArticlesActions {
  HELP_ARTICLES_LOAD = '[helparticle] load',
  HELP_ARTICLES_LOAD_SUCCESS = '[helparticle] load success',
  HELP_ARTICLES_SAVE = '[helparticle] save',
}

export class HelpArticlesLoad implements IAction {
  readonly type = HelpArticlesActions.HELP_ARTICLES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class HelpArticlesLoadSuccess implements IAction {
  readonly type = HelpArticlesActions.HELP_ARTICLES_LOAD_SUCCESS;

  constructor(public payload: IHelpArticles) {
  }
}

export class HelpArticlesSave implements IAction {
  readonly type = HelpArticlesActions.HELP_ARTICLES_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

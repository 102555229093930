import {NgModule}                             from '@angular/core';
import {CommonModule}                         from '@angular/common';
import {NFertilizationBurgerMenuComponent}    from './n-fertilization-burger-menu.component';
import {MapLayerControlComponent}             from './map-layer-control.component';
import {BodyModule, GridModule, SharedModule} from '@progress/kendo-angular-grid';
import {ApUtilsModule}                        from '../../ap-utils';
import {ButtonsModule}                        from '@progress/kendo-angular-buttons';
import {ApResponsiveTextModule}               from '../../ap-responsive-text/ap-responsive-text.module';
import {RadioButtonModule}                    from '@progress/kendo-angular-inputs';
import {LayoutModule}                         from '@progress/kendo-angular-layout';
import {ReactiveFormsModule}                  from '@angular/forms';
import {DropDownsModule}                      from '@progress/kendo-angular-dropdowns';

const COMPONENTS = [MapLayerControlComponent, NFertilizationBurgerMenuComponent];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    GridModule,
    SharedModule,
    ApUtilsModule,
    BodyModule,
    ButtonsModule,
    ApResponsiveTextModule,
    RadioButtonModule,
    LayoutModule,
    ReactiveFormsModule,
    DropDownsModule
  ],
})
export class BurgerMenuEntryComponentsModule {
  static COMPONENTS = COMPONENTS;
}

import {Pipe, PipeTransform} from '@angular/core';
import {FieldStore}          from '../../../stores/farm/field.store';
import {Create}              from 'ts-tooling';

@Pipe({
  name: 'nutrientStatsFields',
})
export class NutrientStatsFieldsPipe implements PipeTransform {
  constructor(private fieldStore: FieldStore) {
  }

  transform(series: any, ...args: any[]): string {
    if (args.length < 1) {
      return '';
    }
    const category = args[0];
    const fieldNames: string[] = [];
    const values: { [key: string]: number } = {};
    let area = 0;
    for (const item of series.data.FindAll(e => e.category === category)) {
      for (const field of item.fields) {
        const geom = this.fieldStore.getCurrentFieldGeom(field);
        if (!fieldNames.Contains(field.Id)) {
          fieldNames.Add(field.Id);
          area += Create(geom?.AreaHa, 0);
          values[field.Id] = 0 + item.value;
          continue;
        }
        values[field.Id] += item.value;
      }
    }

    return fieldNames
      .Convert(item => this.fieldStore.getFieldName(this.fieldStore.getFieldById(item)))
      .Join(', ');
  }
}

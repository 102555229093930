import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  GeoJsonGenerateByField,
  GeoJsonGenerateByMachine,
  GeoJsonGenerateBySlice,
  GeoJsonGenerateMultipoint,
  GeoJsonGenerateSuccess,
  NetTypes
}                    from 'invoker-transport';
import {IStateStore} from '../../ap-interface';
import {Store}       from '../index';
import {ApGuidUtil}  from '../../ap-utils';
import IGuid = System.IGuid;
import IMachine = Data.DocuContext.Machine.IMachine;
import IWorktypes = Data.BaseData.IWorktypes;
import IField = Data.FieldManagement.IField;
import IAttachment = Data.DocuContext.Attachment.IAttachment;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';


interface IGeoJsonStore extends IStateStore<any> {
  generating: boolean;
  generated: boolean;
  geoJson: {};
  pending: string[];
}

@Injectable({providedIn: 'root'})
export class GeoJsonStore extends Store<IGeoJsonStore> {
  constructor(private backend: ApSignalrService) {
    super(backend, {
      loading: false,
      loaded: false,
      data: [],
      generating: false,
      generated: false,
      geoJson: {},
      pending: [],
    });
    backend.registerObservable(GeoJsonGenerateSuccess).subscribe(d => {
      super.Mutate(s => s.pending, () => super.Listen(s => s.pending).getValue().filter(v => v !== d.Data.Key));
      super.Mutate(s => s.geoJson, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  generateGeoJsonBySlice(id: IGuid): void {
    if (!this.isPending(id as string)) {
      super.Mutate(s => s.generating, () => true);
      super.Mutate(s => s.generated, () => false);
      super.Mutate(s => s.geoJson, () => null);
      super.Mutate(s => s.pending, () => [...super.Listen(s => s.pending).getValue(), id.toString()]);
      this.DispatchBackend(new GeoJsonGenerateBySlice([
        {Name: 'sliceId', Type: NetTypes.GUID, Value: id}
      ]));
    }
  }

  generateGeoJsonByMachine(guid: IGuid, machine: IMachine, date: Date): void {
    if (!this.isPending(guid as string)) {
      super.Mutate(s => s.generating, () => true);
      super.Mutate(s => s.generated, () => false);
      super.Mutate(s => s.geoJson, () => null);
      super.Mutate(s => s.pending, () => [...super.Listen(s => s.pending).getValue(), guid]);
      this.DispatchBackend(new GeoJsonGenerateByMachine([
        {Name: 'guid', Type: NetTypes.GUID, Value: guid},
        {Name: 'machine', Type: ApCustomTypes.Data_DocuContext_Machine_Machine, Value: machine},
        {Name: 'date', Type: NetTypes.DATETIME, Value: date},
      ]));
    }
  }

  generateGeoJsonByField(attachment: IAttachment, field: IField, date: Date, workType: IWorktypes = null): void {
    if (!this.isPending(attachment.Id as string)) {
      super.Mutate(s => s.generating, () => true);
      super.Mutate(s => s.generated, () => false);
      super.Mutate(s => s.geoJson, () => null);
      super.Mutate(s => s.pending, () => [...super.Listen(s => s.pending).getValue(), attachment.Id]);
      this.DispatchBackend(new GeoJsonGenerateByField([
        {Name: 'attachmentId', Type: NetTypes.GUID, Value: attachment.Id},
        {Name: 'fieldId', Type: NetTypes.GUID, Value: field ? field.Id : ApGuidUtil.getEmptyGuid()},
        {Name: 'date', Type: NetTypes.DATETIME, Value: date},
        {Name: 'workType', Type: ApCustomTypes.Data_BaseData_Worktypes, Value: workType},
      ]));
    }
  }

  isPending(guid: string): boolean {
    return super.Listen(s => s.pending).getValue().indexOf(guid) !== -1;
  }

  clearGeoJson(): void {
    super.Mutate(s => s.generating, () => false);
    super.Mutate(s => s.generated, () => false);
    super.Mutate(s => s.geoJson, () => null);
    super.Mutate(s => s.pending, () => []);
  }

  generateGeoJson(datasetId: string, farmId: number): void {
    super.Mutate(s => s.generating, () => true);
    super.Mutate(s => s.generated, () => false);
    super.Mutate(s => s.geoJson, () => null);
    super.Mutate(s => s.pending, () => [...super.Listen(s => s.pending).getValue(), datasetId]);
    this.DispatchBackend(new GeoJsonGenerateMultipoint([
      {Name: 'datasetId', Type: NetTypes.GUID, Value: datasetId},
      {Name: 'farmId', Type: NetTypes.INT, Value: farmId},
    ]));
  }

}

import {Injectable}                       from '@angular/core';
import {DatabaseNotify} from 'invoker-transport';
import {CropGroupStore}                   from '../base-data/crop.groups.store';
import {PlantProtectionProductStore}      from '../base-data/plantprotection.product.store';
import {MachineStore}                     from '../docu/machine.store';
import {DriverStore}                      from '../docu/driver.store';
import {InstrumentStore}                  from '../docu/instrument.store';
import {CropTypeStore}                    from '../base-data/crop.types.store';
import {SettingsStore}                    from '../base-data/settings.store';
import {SubFarmStore}                     from '../base-data/subfarm.store';
import {MachineLoggerStore}               from '../docu/machine.logger.store';
import {FertilizerStore}                  from '../base-data/fertilizer.store';
import {VarietyStore}                     from '../base-data/variety.store';
import {SoilSamplePlanningStore}          from '../nutrients/soilsampleplanning.store';
import {TranslationStore}                 from '../translation/translation.store';
import {LoginStore}                       from '../login/login.store';
import {NewsStore}                        from '../dashboard/news.store';
import {ParameterDefinitionStore}         from '../parameter/parameter.definition.store';
import {ParameterValuesStore}             from '../parameter/parameter.values.store';
import {ParameterValuesHistoryStore}      from '../parameter/parameter.values.history.store';
import {UserContactsStore}                from '../base-data/user.contacts.store';
import {TerminalsStore}                   from '../base-data/terminals.store';
import {BasicFertilisationSummariesStore} from '../taskmanagement/basic.fertilisation.summaries.store';
import {LicenseStore}                     from '../farm/license.store';
import {FieldStore}                       from '../farm/field.store';
import IUpdateResultMessage = Invoker.IUpdateResultMessage;
import IResultMessage = Invoker.IResultMessage;
import {ApSignalrService}                 from '../../ap-core/services/ap-signalr.service';

@Injectable({providedIn: 'root'})
export class DatabaseNotifyStore {
  static connected = false;

  constructor(private backend: ApSignalrService,
              private machineStore: MachineStore,
              private driverStore: DriverStore,
              private instrumentStore: InstrumentStore,
              private cropTypeStore: CropTypeStore,
              private settingsStore: SettingsStore,
              private subFarmStore: SubFarmStore,
              private licenseStore: LicenseStore,
              private machineLoggerStore: MachineLoggerStore,
              private fertilizerStore: FertilizerStore,
              private varietyStore: VarietyStore,
              private plantProtectionProductStore: PlantProtectionProductStore,
              private cropGroupStore: CropGroupStore,
              private soilSamplePlanningStore: SoilSamplePlanningStore,
              private translationStore: TranslationStore,
              private newsStore: NewsStore,
              private parameterDefinitionStore: ParameterDefinitionStore,
              private parameterValuesStore: ParameterValuesStore,
              private parameterValuesHistoryStore: ParameterValuesHistoryStore,
              private loginStore: LoginStore,
              private userContactsStore: UserContactsStore,
              private terminalsStore: TerminalsStore,
              private basicFertilisationSummariesStore: BasicFertilisationSummariesStore,
              private fieldStore: FieldStore) {
    loginStore.BaseDataLoaded.Subscribe('NotifyStore', () => {
      if (DatabaseNotifyStore.connected !== true) {
        this.connect();
        DatabaseNotifyStore.connected = true;
      }
    });
  }

  connect(): void {
    this.backend.registerObservable(DatabaseNotify)
      .subscribe((input: IResultMessage) => {
        const d = input.Data as IUpdateResultMessage;
        const selectedStore = this[d.StoreName];
        if (!selectedStore) {
          return;
        }
        const updateMethod = selectedStore['UpdateSource'];
        if (typeof updateMethod !== typeof function (): void {
        }) {
          return;
        }
        updateMethod.call(selectedStore, d.Operation, d.Item, d.ModelName);
      });
  }
}

/**
 * dynamic form validator
 */
import {Observable, Subject} from 'rxjs';

export class ApDynformsValidator<T> {
  /**
   * a validator function.
   * might be a default angular validation or a custom one.
   */
  validator: T;
  /**
   * translation key (errorkey) which is displayed in case of failing validation
   */
  errorKey: string;
  /**
   * show validation error asap
   */
  always: boolean;
  /**
   * subject of all invalid ids
   */
  invalidIds: Subject<string[]> = undefined;

  /**
   * constructor
   */
  constructor(options: {
    validator?: T,
    errorKey?: string,
    always?: boolean,
    invalidIds?: Subject<string[]>,
    updateValidator?: Observable<any>[]
  } = {}) {
    this.validator = options.validator;
    this.errorKey = options.errorKey;
    this.always = options.always;
    this.invalidIds = options.invalidIds;
  }
}

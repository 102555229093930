import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {APP_CONFIGURATION}                           from '../../ap-core/config';

/**
 * configuration for upload control
 */
export class ApDynformsConfigUpload extends ApDynformsConfigBase<any> {
  controlType = ApDynformsControltype.Upload;
  autoUpload: boolean;
  uploadSaveUrl: string;
  uploadRemoveUrl: string;
  withCredentials: boolean;
  uploadProgressEventHandler: any;
  style: any;
  successEventHandler: any;
  uploadEventHandler: any;
  restrictions: any;
  minFileSize: number;
  maxFileSize: number;
  dropFilesHereText: string;
  selectText: string;
  uploadSelectedFilesText: string;
  clearSelectedFilesText: string;
  multiple: boolean;

  constructor(options: {
    key?: string,
    autoUpload?: boolean,
    uploadSaveUrl?: string,
    uploadRemoveUrl?: string,
    withCredentials?: boolean,
    uploadProgressEventHandler?: any,
    style?: any,
    successEventHandler?: any,
    uploadEventHandler?: any,
    restrictions?: any,
    minFileSize?: number,
    maxFileSize?: number,
    dropFilesHereText?: string,
    selectText?: string,
    uploadSelectedFilesText?: string,
    clearSelectedFilesText?: string,
    multiple?: boolean,
  } = {}) {
    super(options);
    this.uploadSaveUrl = options.uploadSaveUrl || APP_CONFIGURATION.UploadSaveUrl;
    this.uploadRemoveUrl = options.uploadRemoveUrl || APP_CONFIGURATION.UploadRemoveUrl;
    this.selectText = options.selectText || 'kendo.upload.select';
    this.uploadSelectedFilesText = options.uploadSelectedFilesText || 'UploadUpload';
    this.clearSelectedFilesText = options.clearSelectedFilesText || 'Global__Cancel';
    this.dropFilesHereText = options.dropFilesHereText || 'kendo.upload.dropFilesHere';
    this.autoUpload = options.autoUpload || false;
    this.multiple = options.multiple || true;
    this.style = options.style || 'background-color: transparent;';
    this.uploadEventHandler = options.uploadEventHandler || undefined;
    this.successEventHandler = options.successEventHandler || (e => true);
    this.uploadProgressEventHandler = options.uploadProgressEventHandler || (e => true);
    this.restrictions = {
      allowedExtensions: options.restrictions,
      maxFileSize: options.maxFileSize,
      minFileSize: options.minFileSize
    } || {allowedExtensions: ['.txt', '.csv']};
  }
}

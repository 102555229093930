import {IAction, IApInvokerArguments} from '../../interfaces';
import IResultMessage = Invoker.IResultMessage;

export enum MapActions {
  LOAD_FUNGI_DETECT_BY_DATASET = '[map] load fungi detect by dataset',
  GEOMETRY_EDITOR_INFORM_STATE_CHANGE = '[geometryeditor] inform state change',
  GEOMETRY_EDITOR_SET_OPEN_STATE = '[geometryeditor] set open state',
  GEOMETRY_EDITOR_SEND_UPDATE = '[geometryeditor] update send',
  GEOMETRY_EDITOR_UPDATE = '[geometryeditor] update',
  GEOMETRY_EDITOR_MERGE = '[geometryeditor] merge',
  GEOMETRY_EDITOR_MERGE_DONE = '[geometryeditor] merge done',
  GEOMETRY_EDITOR_SPLIT = '[geometryeditor] split',
  GEOMETRY_EDITOR_SPLIT_DONE = '[geometryeditor] split done',
}

export class MapLoadFungiDetect implements IAction {
  readonly type = MapActions.LOAD_FUNGI_DETECT_BY_DATASET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeometryEditorInformStateChange implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_INFORM_STATE_CHANGE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeometryEditorSetOpenState implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_SET_OPEN_STATE;

  constructor(public payload: IResultMessage) {
  }
}

export class GeometryEditorSendUpdate implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_SEND_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeometryEditorUpdate implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_UPDATE;

  constructor(public payload: IResultMessage) {
  }
}

export class GeometryEditorMerge implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_MERGE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeometryEditorMergeDone implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_MERGE_DONE;

  constructor(public payload: IResultMessage) {
  }
}

export class GeometryEditorSplit implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_SPLIT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeometryEditorSplitDone implements IAction {
  readonly type = MapActions.GEOMETRY_EDITOR_SPLIT_DONE;

  constructor(public payload: IResultMessage) {
  }
}

import {NgModule}                    from '@angular/core';
import {CommonModule}                from '@angular/common';
import {ApResponsiveTextModule}      from '../../ap-responsive-text/ap-responsive-text.module';
import {ApNutrientPlanningComponent} from './ap-nutrient-planning.component';
import {ButtonModule}                from '@progress/kendo-angular-buttons';
import {ApDynComponentModule}        from '../../ap-dyncomponent/ap-dyncomponent.module';
import {ApCoreModule}                from '../../ap-core/ap-core.module';
import {ApNutrientBookingComponent}  from './ap-nutrient-booking.component';
import {ApGetCropTypeNamePipe}       from '../../nutrient-management/pipes/ap-get-croptype-name.pipe';

const COMPONENTS = [ApNutrientPlanningComponent, ApNutrientBookingComponent];

@NgModule({
  imports: [
    CommonModule,
    ApResponsiveTextModule,
    ButtonModule,
    ApDynComponentModule,
    ApCoreModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [ApGetCropTypeNamePipe]
})
export class ApNutrientsManagementEntryComponentsModule {
}

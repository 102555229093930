import {Component, Input}     from '@angular/core';
import {Observable}           from 'rxjs';
import ISettings = Data.BaseData.ISettings;
import {SettingsStore}        from '../../../stores/base-data/settings.store';
import {BoxPlotChartDataItem}     from '../../../ap-interface';
import {IChartSet} from '../../../stores/statistic/statistic.store';

@Component({
  selector: 'ap-box-plot-chart',
  templateUrl: 'ap-box-plot-chart.component.html',
  styleUrls: ['ap-box-plot-chart.component.scss']
})

/**
 * Box-Plot chart component (example in Fields=> Sat)
 */
export class ApBoxPlotChartComponent {
  @Input()
  data: IChartSet<BoxPlotChartDataItem[]>[];
  @Input()
  title = '';

  public settings$: Observable<ISettings>;

  constructor(private settingsStore: SettingsStore) {
    this.settings$ = this.settingsStore.FirstSetting$;
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import ITerminals = Data.BaseData.ITerminals;

export enum TerminalsActions {
  TERMINALS_LOAD = '[terminals] load',
  TERMINALS_LOAD_SUCCESS = '[terminals] load success',
}

export class TerminalsLoad implements IAction {
  readonly type = TerminalsActions.TERMINALS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TerminalsLoadSuccess implements IAction {
  readonly type = TerminalsActions.TERMINALS_LOAD_SUCCESS;

  constructor(public payload: ITerminals[]) {
  }
}

import IVarieties = Data.BaseData.IVarieties;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum VarietyActions {
  VARIETY_LOAD = '[variety] load',
  VARIETY_LOAD_SUCCESS = '[variety] load success',
  VARIETY_SELECTABLE_UPDATE = '[variety] selectable update',
}

/**
 * start load the Varieties from Cache into State
 */
export class VarietyLoad implements IAction {
  readonly type = VarietyActions.VARIETY_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * load the Varieties from Cache into State success
 */
export class VarietyLoadSuccess implements IAction {
  readonly type = VarietyActions.VARIETY_LOAD_SUCCESS;

  constructor(public payload: IVarieties[]) {
  }
}

export class VarietySelectableUpdate implements IAction {
  readonly type = VarietyActions.VARIETY_SELECTABLE_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * represent the View State of the Map Control in Agriport
 */
export enum MapViewMode {
  HIDE = 'mapview_hidden',
  NORMAL = 'mapview_normal',
  MAXIMIZE = 'mapview_max',
}

/**
 * represent the Menus that con be shown with the Map Control
 */
export enum MapViewCurrentMenu {
  MAP = 'map',
  STATS = 'stats',
  HELP = 'help',
}

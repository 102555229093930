import {IAction, IApInvokerArguments} from '../../interfaces';
import IFactors = Data.Common.IFactors;

export enum FactorsActions {
  FACTORS_LOAD = '[factors] load',
  FACTORS_LOAD_SUCCESS = '[factors] load success',
  FACTORS_MISSING = '[factors] missing',
}

export class FactorsLoad implements IAction {
  readonly type = FactorsActions.FACTORS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FactorsLoadSuccess implements IAction {
  readonly type = FactorsActions.FACTORS_LOAD_SUCCESS;

  constructor(public payload: IFactors[]) {
  }
}

export class FactorsMissing implements IAction {
  readonly type = FactorsActions.FACTORS_MISSING;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

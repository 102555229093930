import {NgModule}                                from '@angular/core';
import {ApLogoIconComponent}                     from './components/ap-logo-icon.component';
import {ApBasedataIconComponent}                 from './components/ap-basedata-icon.component';
import {ApDashboardIconComponent}                from './components/ap-dashboard-icon.component';
import {ApIconSelectorComponent}                 from './components/ap-icon-selector.component';
import {CommonModule}                            from '@angular/common';
import {ApMapIconComponent}                      from './components/ap-map-icon.component';
import {ApStatisticIconComponent}                from './components/ap-statistic-icon.component';
import {ApHelpIconComponent}                     from './components/ap-help-icon.component';
import {ApArrowLeftComponent}                    from './components/ap-arrow-left.component';
import {ApArrowRightComponent}                   from './components/ap-arrow-right.component';
import {ApWarningIconComponent}                  from './components/ap-warning-icon.component';
import {ApErrorIconComponent}                    from './components/ap-error-icon.component';
import {ApInfoIconComponent}                     from './components/ap-info-icon.component';
import {ApFungidetectIconComponent}              from './components/ap-fungidetect-icon.component';
import {ApUploadIconComponent}                   from './components/ap-upload-icon.component';
import {ApDatabaseIconComponent}                 from './components/ap-database-icon.component';
import {ApFileIconComponent}                     from './components/ap-file-icon.component';
import {ApSuccessIconComponent}                  from './components/ap-success-icon.component';
import {ApDocumentationIconComponent}            from './components/ap-documentation-icon.component';
import {ApExclamationCircleRegularIconComponent} from './components/ap-exclamation-circle-regular-icon.component';
import {ApExclamationCircleSolidIconComponent}   from './components/ap-exclamation-circle-solid-icon.component';
import {ApBanSolidIconComponent}                 from './components/ap-ban-solid-icon.component';
import {ApCheckCircleRegularIconComponent}       from './components/ap-check-circle-regular-icon.component';
import {ApCheckCircleSolidIconComponent}         from './components/ap-check-circle-solid-icon.component';
import {ApTimesCircleRegularIconComponent}       from './components/ap-times-circle-regular-icon.component';
import {ApTimesCircleSolidIconComponent}         from './components/ap-times-circle-solid-icon.component';
import {ApContactIconComponent}                  from './components/ap-contact-icon.component';
import {ApLegalIconComponent}                    from './components/ap-legal-icon.component';
import {ApImprintIconComponent}                  from './components/ap-imprint-icon.component';
import {ApAgreementIconComponent}                from './components/ap-agreement-icon.component';
import {ApNewsIconComponent}                     from './components/ap-news-icon.components';
import {ApIconAdministrationComponent}           from './components/ap-icon-administration.components';
import {ApIconBaseFertilizationComponent}        from './components/ap-icon-base-fertilization.components';
import {ApIconBookingComponent}                  from './components/ap-icon-booking.components';
import {ApIconCollapsComponent}                  from './components/ap-icon-collapse.components';
import {ApIconEvaluationComponent}               from './components/ap-icon-evaluation.components';
import {ApIconFieldWorksComponent}               from './components/ap-icon-field-works.components';
import {ApIconFieldsComponent}                   from './components/ap-icon-fields.component';
import {ApIconImportComponent}                   from './components/ap-icon-import.components';
import {ApIconNFertilizationComponent}           from './components/ap-icon-n-fertilization.components';
import {ApIconPlantProtectionComponent}          from './components/ap-icon-plant-protection.components';
import {ApIconSeedingComponent}                  from './components/ap-icon-seeding.components';
import {ApIconSettingsComponent}                 from './components/ap-icon-settings.components';
import {ApIconTasksComponent}                    from './components/ap-icon-taks.components';
import {ApIconFarmRecordsComponent}              from './components/ap-icon-farm-records.component';

/**
 * Give some Components with SVG Images
 */
@NgModule({
  imports: [CommonModule],
  declarations: [
    ApArrowLeftComponent,
    ApArrowRightComponent,
    ApLogoIconComponent,
    ApBasedataIconComponent,
    ApDashboardIconComponent,
    ApIconSelectorComponent,
    ApMapIconComponent,
    ApStatisticIconComponent,
    ApHelpIconComponent,
    ApInfoIconComponent,
    ApWarningIconComponent,
    ApErrorIconComponent,
    ApSuccessIconComponent,
    ApFungidetectIconComponent,
    ApUploadIconComponent,
    ApDatabaseIconComponent,
    ApFileIconComponent,
    ApDocumentationIconComponent,
    ApExclamationCircleRegularIconComponent,
    ApExclamationCircleSolidIconComponent,
    ApBanSolidIconComponent,
    ApCheckCircleRegularIconComponent,
    ApCheckCircleSolidIconComponent,
    ApTimesCircleRegularIconComponent,
    ApTimesCircleSolidIconComponent,
    ApContactIconComponent,
    ApImprintIconComponent,
    ApLegalIconComponent,
    ApAgreementIconComponent,
    ApNewsIconComponent,
    ApIconAdministrationComponent,
    ApIconBaseFertilizationComponent,
    ApIconBookingComponent,
    ApIconCollapsComponent,
    ApIconEvaluationComponent,
    ApIconFieldWorksComponent,
    ApIconFieldsComponent,
    ApIconImportComponent,
    ApIconNFertilizationComponent,
    ApIconPlantProtectionComponent,
    ApIconSeedingComponent,
    ApIconSettingsComponent,
    ApIconTasksComponent,
    ApIconFarmRecordsComponent
  ], exports: [
    ApArrowLeftComponent,
    ApArrowRightComponent,
    ApLogoIconComponent,
    ApBasedataIconComponent,
    ApDashboardIconComponent,
    ApIconSelectorComponent,
    ApMapIconComponent,
    ApStatisticIconComponent,
    ApHelpIconComponent,
    ApInfoIconComponent,
    ApWarningIconComponent,
    ApErrorIconComponent,
    ApSuccessIconComponent,
    ApFungidetectIconComponent,
    ApUploadIconComponent,
    ApDatabaseIconComponent,
    ApFileIconComponent,
    ApDocumentationIconComponent,
    ApContactIconComponent,
    ApImprintIconComponent,
    ApLegalIconComponent,
    ApAgreementIconComponent,
    ApNewsIconComponent,
    ApExclamationCircleRegularIconComponent,
    ApExclamationCircleSolidIconComponent,
    ApCheckCircleRegularIconComponent,
    ApCheckCircleSolidIconComponent,
    ApTimesCircleRegularIconComponent,
    ApTimesCircleSolidIconComponent,
    ApIconAdministrationComponent,
    ApIconBaseFertilizationComponent,
    ApIconBookingComponent,
    ApIconCollapsComponent,
    ApIconEvaluationComponent,
    ApIconFieldWorksComponent,
    ApIconFieldsComponent,
    ApIconImportComponent,
    ApIconNFertilizationComponent,
    ApIconPlantProtectionComponent,
    ApIconSeedingComponent,
    ApIconSettingsComponent,
    ApIconTasksComponent,
    ApIconFarmRecordsComponent,
  ]
})
export class ApIconModule {
}

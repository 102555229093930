import {IStateStore}      from '../../ap-interface';
import {Store}            from '../index';
import {Injectable}       from '@angular/core';
import {
  ApCustomTypes,
  FertilizerInorganicCreate,
  FertilizerInorganicLoad,
  FertilizerInorganicLoadSuccess,
  FertilizerInorganicSelectableUpdate,
  FertilizerInorganicUpdate
}                         from 'invoker-transport';
import {TranslationStore} from '../translation/translation.store';
import IFertilizerInorganic = Data.BaseData.IFertilizerInorg;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IFertilizerInorganicStore extends IStateStore<IFertilizerInorganic> {
}

@Injectable({providedIn: 'root'})
export class FertilizerInorganicStore extends Store<IFertilizerInorganicStore> {
  constructor(public backend: ApSignalrService,
              public translationStore: TranslationStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });
    backend.registerObservable(FertilizerInorganicLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data.Convert(_ => {
        _.Prodname = this.translationStore.FindTranslationForSelectedLanguage(_.Prodname);
        _.Note = this.translationStore.FindTranslationForSelectedLanguage(_.Note);
        return _;
      }));
      super.SetLoadFinishState();
    });
  }

  public getFertilizerInorganic(): IFertilizerInorganic[] {
    return super.Listen(s => s.data).getValue();
  }

  /**
   *  load fertilizer inorganic from Backend to the Store
   */
  public loadFertilizerInorganic(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new FertilizerInorganicLoad([]));
  }

  /**
   * create fertilizer inorganic via backend
   */
  public createFertilizerInorganic(fertilizerInorganic: IFertilizerInorganic): void {
    this.DispatchBackend(new FertilizerInorganicCreate([
      {
        Name: 'fertilizerInorganic',
        Type: ApCustomTypes.Data_BaseData_FertilizerInorg,
        Value: this._mapFertilizer(fertilizerInorganic)
      }
    ]));
  }

  /**
   * update fertilizer inorganic via backend
   */
  public updateFertilizerInorganic(fertilizerInorganic: IFertilizerInorganic): void {
    this.DispatchBackend(new FertilizerInorganicUpdate([
      {
        Name: 'fertilizerInorganic',
        Type: ApCustomTypes.Data_BaseData_FertilizerInorg,
        Value: this._mapFertilizer(fertilizerInorganic)
      }
    ]));
  }

  /**
   * updates selectableFertilizer via backend
   */
  public updateSelectableFertilizer(fertilizerInorganic: IFertilizerInorganic): void {
    this.DispatchBackend(new FertilizerInorganicSelectableUpdate([
      {Name: 'fertilizerInorganic', Type: ApCustomTypes.Data_BaseData_FertilizerInorg, Value: fertilizerInorganic},
    ]));
  }

  private _mapFertilizer(fertilizer: IFertilizerInorganic): IFertilizerInorganic {
    fertilizer.RealContentCaO = fertilizer.ContentCaO;
    if (fertilizer.AlkalineEff) {
      if (fertilizer['AlkalineCaO'] > 0) {
        fertilizer.ContentCaO = fertilizer['AlkalineCaO'];
      } else {
        fertilizer.ContentCaO = fertilizer['ContentNeutralisationValue'];
      }
    }
    return fertilizer;
  }
}

import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, Subject}                                              from 'rxjs';
import {catchError, first, map, tap}                                      from 'rxjs/operators';

export const INVALID_ID = 'InvalidId::';

export class ApValidator {
  static validate(validatorFn: ValidatorFn, errorMessage: string, invalidIds?: Subject<string[]>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const result = validatorFn(control);
      this._sendItemIds(result, invalidIds);
      if (result) {
        return this._errorObject(errorMessage, result);
      }
      return result;
    };
  }

  static validateAsync(asyncValidatorFn: AsyncValidatorFn, errorMessage: string, invalidIds?: Subject<string[]>): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const result = asyncValidatorFn(control);
      if (result) {
        if (result instanceof Observable) {
          return result
            .pipe(
              tap(r => this._sendItemIds(r, invalidIds)),
              map(r => r ? this._errorObject(errorMessage, result) : null),
              catchError(() => null),
              first(() => true),
            );
        } else if (result instanceof Promise) {
          return result
            .then(r => {
              this._sendItemIds(r, invalidIds);
              return new Promise((resolve) => resolve(r ? this._errorObject(errorMessage, result) : r));
            })
            .catch(() => {
              this._sendItemIds(null, invalidIds);
              return new Promise((resolve) => resolve(null));
            });
        }
      }
      return result;
    };
  }

  private static _errorObject(errorMessage: string, errorMetaData: any): { [key: string]: any } {
    const errorObject = {};
    errorObject[errorMessage] = errorMetaData;
    return errorObject;
  }

  private static _sendItemIds(result: ValidationErrors, invalidIds: Subject<string[]>): void {
    if (invalidIds) {
      if (result) {
        invalidIds.next(
          Object.keys(result).filter(r => r.startsWith(INVALID_ID)).map(r => r.substring(INVALID_ID.length)));
      } else {
        invalidIds.next([]);
      }
    }
  }
}

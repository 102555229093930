<kendo-daterange [class]="'ap-dynform-fieldset'"
                 [style.column-gap]="columnGap">

  <div [class]="'ap-dynform-element'"
       [style.grid-column]="startPos.column + 1"
       [style.grid-row]="startPos.row + 1">
    <label [attr.for]="startKey"
           [id]="startKey + '_label'">
      <ap-responsive-text [key]="config.labelStart"></ap-responsive-text>
    </label>
    <kendo-dateinput kendoDateRangeStartInput
                     [id]="getHtmlId(startKey) | sanitizeId"
                     [formControl]="form.get(startKey)"
                     [class]="'ap-dropdown-bright ap-dynform-control'"
                     [min]="config.minDate$ | async"
                     [max]="config.maxDate$ | async"
                     [value]="config.value.start"
                     [class.dark-bg]="darkMode"
                     [class.k-disabled]="disabled$ | async"
                     [format]="(config.format ? config.format : language.DateFormat) | getKendoDateFormat"
                     [formatPlaceholder]="{
                                     year: 'Global__Year' | translate,
                                     month: 'Global__Month' | translate,
                                     day: 'Global__Day' | translate,
                                     hour: ' ', minute: ' ', second: ' '
                                   }">
    </kendo-dateinput>
    <div [class]="'ap-error'" [id]="startKey + '_error'">
      <ap-responsive-text *ngIf="getErrors(startKey).length > 0" [key]="getErrors(startKey)[0]"></ap-responsive-text>
    </div>
  </div>
  <div [class]="'ap-dynform-element'"
       [style.grid-column]="endPos.column + 1"
       [style.grid-row]="endPos.row + 1">
    <label [attr.for]="endKey"
           [id]="endKey + '_label'">
      <ap-responsive-text [key]="config.labelEnd"></ap-responsive-text>
    </label>
    <kendo-dateinput kendoDateRangeEndInput
                     [id]="getHtmlId(endKey) | sanitizeId"
                     [formControl]="form.get(endKey)"
                     [class]="'ap-dropdown-bright ap-dynform-control'"
                     [min]="config.minDate$ | async"
                     [max]="config.maxDate$ | async"
                     [value]="config.value.end"
                     [class.dark-bg]="darkMode"
                     [class.k-disabled]="disabled$ | async"
                     [format]="(config.format ? config.format : language.DateFormat) | getKendoDateFormat"
                     [formatPlaceholder]="{
                                     year: 'Global__Year' | translate,
                                     month: 'Global__Month' | translate,
                                     day: 'Global__Day' | translate,
                                     hour: ' ', minute: ' ', second: ' '
                                   }">
    </kendo-dateinput>
    <div [class]="'ap-error'" [id]="endKey + '_error'">
      <ap-responsive-text *ngIf="getErrors(endKey).length > 0" [key]="getErrors(endKey)[0]"></ap-responsive-text>
    </div>
  </div>
  <kendo-daterange-popup #popupComponent>
    <ng-template kendoDateRangePopupTemplate>
      <kendo-multiviewcalendar kendoDateRangeSelection
                               [views]="2">
        <ng-template kendoCalendarMonthCellTemplate let-date>
          <span [class.ap-datepicker-day-active]="config.dateCellDyer(date)"
                [class.ap-datepicker-day-inactive]="!config.dateCellDyer(date)">
            {{date.getDate()}}
          </span>
        </ng-template>
      </kendo-multiviewcalendar>
    </ng-template>
  </kendo-daterange-popup>
</kendo-daterange>

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum GeoJsonActions {
  GEOJSON_GENERATE_BY_SLICE = '[geojson] generate by slice',
  GEOJSON_GENERATE_BY_MACHINE = '[geojson] generate by machine',
  GEOJSON_GENERATE_BY_FIELD = '[geojson] generate by field',
  GEOJSON_GENERATE_SUCCESS = '[geojson] generate success',
  GEOJSON_GENERATE_FAIL = '[geojson] generate fail',
  GEOJSON_GENERATE_CLEAR = '[geojson] clear',
  GEOJSON_GENERATE_MULTIPOINT = '[geojson] generate multipoint',
}

export class GeoJsonGenerateBySlice implements IAction {
  readonly type = GeoJsonActions.GEOJSON_GENERATE_BY_SLICE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeoJsonGenerateByMachine implements IAction {
  readonly type = GeoJsonActions.GEOJSON_GENERATE_BY_MACHINE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeoJsonGenerateByField implements IAction {
  readonly type = GeoJsonActions.GEOJSON_GENERATE_BY_FIELD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class GeoJsonGenerateSuccess implements IAction {
  readonly type = GeoJsonActions.GEOJSON_GENERATE_SUCCESS;

  constructor(public payload: object) {
  }
}

export class GeoJsonGenerateMultipoint implements IAction {
  readonly type = GeoJsonActions.GEOJSON_GENERATE_MULTIPOINT;

  constructor(public  payload: IApInvokerArguments[]) {
  }
}

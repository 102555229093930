import {AfterContentInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription}                          from 'rxjs';
import {SelectableSettings}                                    from '@progress/kendo-angular-grid';
import {CampaignYearStore}                                     from '../../../../stores/login/campaignyear.store';
import {GetRoundNumericPipe}                                   from '../../../../ap-utils';
import {
  ApDynGridPropertyColumnConfig
}                                                              from '../../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {LanguageStore}                                         from '../../../../stores/translation/language.store';
import {SettingsStore}                                         from '../../../../stores/base-data/settings.store';
import {Create, ObjectFactory}                                 from 'ts-tooling';
import {
  ApDynGridGroupColumnConfig
}                                                              from '../../../../ap-dyngrids/config/ap-dyn-grid-group-column-config';
import {TranslationStore}                                      from '../../../../stores/translation/translation.store';
import {
  INFertilizationPlanningWizardSelectionStatsData
}                                                              from './ap-n-fertilization-planning-wizard-selection-stats-types';
import {
  ApNFertilizationCommonService
}                                                              from '../../../../n-fertilization/services/ap-n-fertilization.common.service';

@Component({
  selector: 'ap-n-fertilization-planning-wizard-selection-stats',
  templateUrl: 'ap-n-fertilization-planning-wizard-selection-stats.component.html',
  styleUrls: ['ap-n-fertilization-planning-wizard-selection-stats.scss']
})
export class ApNFertilizationPlanningWizardStatsComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() public data: INFertilizationPlanningWizardSelectionStatsData[];
  @Input() public title: string;
  @Input() public emptyMessage = '';
  public gridSelectableSettings: SelectableSettings = {
    enabled: false,
    checkboxOnly: false,
    mode: 'single',
    cell: false,
    drag: false
  };

  public readonly MAX_STAT_ITEMS = 25;
  public statColumns = [];
  public itemsStat$: Observable<INFertilizationPlanningWizardSelectionStatsData[]>;

  private subscriptions: Subscription[] = [];

  constructor(private translationStore: TranslationStore,
              private campaignYearStore: CampaignYearStore,
              private languageStore: LanguageStore,
              private settingsStore: SettingsStore,
              private roundNumericPipe: GetRoundNumericPipe,
              private fertilizationCommonService: ApNFertilizationCommonService) {
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.campaignYearStore.Listen(s => s.selectedYear).subscribe(_ => {
      this._buildGrids();
    }));
  }

  public ngAfterContentInit(): void {
    if (!this.data) {
      return;
    }
    if (!this.data || this.data?.length === 0) {
      this.itemsStat$ = of([]);
      return;
    }
    // Cannot use 'SortBy' here because it has problems if fieldname is undefined
    let statGridDataItems: INFertilizationPlanningWizardSelectionStatsData[] = ObjectFactory.Copy(this.data).sort((a, b) => {
      return (a.FieldName?.toLowerCase() < b.FieldName?.toLowerCase()) ? -1
        : ((b.FieldName?.toLowerCase() > a.FieldName?.toLowerCase()) ? 1 : 0);
    });
    if (statGridDataItems.length > this.MAX_STAT_ITEMS) {
      statGridDataItems = ObjectFactory.Copy(statGridDataItems.slice(0, this.MAX_STAT_ITEMS));
      statGridDataItems.push({
        FieldNumber: undefined,
        FieldName: this.translationStore.FindTranslationForSelectedLanguage('Nutrient__Param_ManyMore', [`${(this.data.length - this.MAX_STAT_ITEMS)}`]),
        FieldSubNumber: undefined,
        SensorDate: undefined,
        Area: undefined,
        SensorMinN: undefined,
        SensorMaxN: undefined,
        SensorAvgN: undefined
      });
    }
    const filteredItemsBySensor = this.data.filter(item => !!item.SensorAvgN);
    const selectedFields = filteredItemsBySensor.map(item => ({Area: item.Area, Average: item.SensorAvgN}));
    const sumDataRow: INFertilizationPlanningWizardSelectionStatsData = {
      FieldNumber: undefined,
      FieldName: undefined,
      FieldSubNumber: undefined,
      SensorDate: undefined,
      Area: this.data.reduce((a, b) => a + Create(b?.Area, 0), 0),
      SensorMinN: filteredItemsBySensor.reduce((a, b) => (b.SensorMinN < a) || (a === 0 && b.SensorMinN > -1) ? b.SensorMinN : a, 0),
      SensorMaxN: filteredItemsBySensor.reduce((a, b) => b.SensorMaxN > a && b.SensorMaxN > -1 ? b.SensorMaxN : a, 0),
      SensorAvgN: this.fertilizationCommonService.calculateAreaWeightedAverage(selectedFields)
    };
    statGridDataItems.push(sumDataRow);
    this.itemsStat$ = of(statGridDataItems);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  private _buildGrids(): void {
    this.statColumns = [
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini__Number',
        field: 'FieldNumber',
        width: 60,
        filterable: false,
        sortable: false
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini_Subnumber',
        field: 'FieldSubNumber',
        width: 60,
        filterable: false,
        sortable: false
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini__Name',
        field: 'FieldName',
        width: 150,
        filterable: false,
        sortable: false
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini__Area',
        field: 'Area',
        width: 60,
        filterable: false,
        sortable: false,
        cssClass: 'center',
        headerStyle: {'text-align': 'center', padding: '6px 6px 6px 5px'},
        tooltip: (dataItem) => dataItem['Area'],
        pipes: [{
          pipe: this.roundNumericPipe,
          args: [
            this.settingsStore.FirstSetting?.DigitsAfterDecimalPoint ?? 0,
          ]
        }]
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Global__Date',
        field: 'SensorDate',
        format: this.languageStore.CurrentDateFormat,
        width: 70,
        cssClass: 'center',
        headerStyle: {'text-align': 'center', padding: '6px 6px 6px 5px'},
        filterable: false,
        sortable: false,
      }),
      new ApDynGridGroupColumnConfig({
        title: 'N__Uptake',
        groupColumns: [
          new ApDynGridPropertyColumnConfig({
            title: 'Global__AvgShort',
            field: 'SensorAvgN',
            cssClass: 'center',
            headerStyle: {'text-align': 'center', padding: '6px 6px 6px 5px'},
            width: 105,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [
                this.settingsStore.FirstSetting?.DigitsAfterDecimalPoint ?? 0,
                GetRoundNumericPipe.NullToEmptyConfiguration
              ]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Global__Min',
            field: 'SensorMinN',
            cssClass: 'center',
            headerStyle: {'text-align': 'center', padding: '6px 6px 6px 5px'},
            width: 105,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [
                this.settingsStore.FirstSetting?.DigitsAfterDecimalPoint ?? 0,
                GetRoundNumericPipe.NullToEmptyConfiguration
              ]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Global__Max',
            field: 'SensorMaxN',
            cssClass: 'center',
            headerStyle: {'text-align': 'center', padding: '6px 6px 6px 5px'},
            width: 105,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [
                this.settingsStore.FirstSetting?.DigitsAfterDecimalPoint ?? 0,
                GetRoundNumericPipe.NullToEmptyConfiguration
              ]
            }]
          })
        ]
      })
    ];
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum FertilizerActions {
  FERTILIZER_USE_LOAD = '[fertilizer_use] load',
  FERTILIZER_USE_LOAD_SUCCESS = '[fertilizer_use] load success',
}

export class FertilizerUseLoad implements IAction {
  readonly type = FertilizerActions.FERTILIZER_USE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FertilizerUseLoadSuccess implements IAction {
  readonly type = FertilizerActions.FERTILIZER_USE_LOAD_SUCCESS;

  constructor(public payload: number) {
  }
}

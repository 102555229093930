import {IStateStore} from '../../ap-interface/interfaces/store';
import {Injectable}  from '@angular/core';
import {Store}       from '../index';
import {
  ApCustomTypes,
  NetTypes,
  ParameterValueCreate,
  ParameterValueDelete,
  ParameterValueLoad,
  ParameterValueLoadSuccess,
  ParameterValuesSaveOrUpdate,
  ParameterValueUpdate
}                    from 'invoker-transport';
import IParameterValue = Data.Parameter.IParameterValue;
import IGuid = System.IGuid;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IParameterValuesStore extends IStateStore<IParameterValue> {
}

@Injectable({providedIn: 'root'})
export class ParameterValuesStore extends Store<IParameterValuesStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
    });

    backend.registerObservable(ParameterValueLoadSuccess).subscribe(d => {
      this.SetLoadFinishState();
      super.Mutate(s => s.data, () => d.Data);
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadParameterValue(refId: IGuid): void {
    this.SetLoadState();
    this.DispatchBackend(new ParameterValueLoad([
      {Name: 'refId', Type: NetTypes.GUID, Value: refId},
    ]));
  }

  public updateParameterValue(parameterValue: IParameterValue): void {
    this.DispatchBackend(new ParameterValueUpdate([
      {Name: 'parameterValue', Type: ApCustomTypes.Data_Parameter_ParameterValue, Value: parameterValue},
    ]));
  }

  public deleteParameterValue(parameterValue: IParameterValue): void {
    this.DispatchBackend(new ParameterValueDelete([
      {Name: 'parameterValue', Type: ApCustomTypes.Data_Parameter_ParameterValue, Value: parameterValue},
    ]));
  }

  public createParameterValue(parameterValue: IParameterValue): void {
    this.DispatchBackend(new ParameterValueCreate([
      {Name: 'parameterValue', Type: ApCustomTypes.Data_Parameter_ParameterValue, Value: parameterValue},
    ]));
  }

  public updateParameterValues(parameterValues: IParameterValue[]): void {
    this.DispatchBackend(new ParameterValuesSaveOrUpdate([
      {Name: 'parameterValues', Type: ApCustomTypes.Data_Parameter_ParameterValue + '[]', Value: parameterValues},
    ]));
  }
}

import {IStateStore}         from '../../ap-interface/interfaces/store';
import {Store}               from '../index';
import {Injectable}          from '@angular/core';
import {
  ActionsApplicationAdd,
  ActionsApplicationDrop,
  FieldActionLoadSuccess,
  FieldActionsLoad,
  FleetActionLoadSuccess,
  FleetActionsLoad,
  FleetActionsUpdateSuccess,
  LocationsLoad,
  LocationsLoadSuccess,
  NetTypes
}                            from 'invoker-transport';
import {MapStore}            from '../map/map.store';
import {SafeBehaviorSubject} from 'ts-tooling';
import IFieldAction = Data.DocuContext.FleetManagement.IFieldAction;
import IFleetAction = Data.DocuContext.FleetManagement.IFleetAction;
import ILocationModel = Data.DocuContext.Location.ILocationModel;
import ILocationsEnvelop = Data.DocuContext.Location.ILocationsEnvelop;
import IGuid = System.IGuid;
import ICropTypes = Data.BaseData.ICropTypes;
import IWorktypes = Data.BaseData.IWorktypes;
import IReadApplicationModel = Data.DocuContext.ApplicationData.IReadApplicationModel;
import {SettingsStore}       from '../base-data/settings.store';
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IActionStore extends IStateStore<any> {
  fieldActions: IFieldAction[];
  fleetActions: IFleetAction[];
  locations: ILocationModel[];
  locationLoading: boolean;
  locationLoaded: boolean;
  selectedLocations: ILocationModel[];
}

@Injectable({providedIn: 'root'})
export class ActionStore extends Store<IActionStore> {
  constructor(public backend: ApSignalrService,
              private settingsStore: SettingsStore,
              private mapStore: MapStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      fieldActions: [],
      fleetActions: [],
      locations: [],
      selectedLocations: [],
      locationLoading: false,
      locationLoaded: false
    });

    backend.registerObservable(FieldActionLoadSuccess).subscribe(d => {
      super.Mutate(s => s.fieldActions, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FleetActionLoadSuccess).subscribe(d => {
      super.Mutate(s => s.fleetActions, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FleetActionsUpdateSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(LocationsLoadSuccess).subscribe(d => {
      const locationsEnvelop = d.Data as ILocationsEnvelop;
      // because of loading location within an interval there is a risk
      // that during the load-process of the interval the user switches to another farm.
      // Therefor we need to skip the load result whenever it differs to the currently selected farm
      // Have to use settingsStore to retrieve current farm because loginStore leads to circular dependency
      if (locationsEnvelop?.FarmId !== this.settingsStore.FirstSetting?.FarmId) {
        return;
      }

      this.mapStore.Layers.LocationsLayer.replaceSource(locationsEnvelop?.Locations ?? []);
      super.Mutate(s => s.locations, () => locationsEnvelop?.Locations ?? []);
      super.Mutate(s => s.locationLoaded, () => true);
      super.Mutate(s => s.locationLoading, () => false);
      super.SetLoadFinishState();
    });
  }

  /**
   * Subject which indicates if everything is currently loading
   */
  get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  /**
   * Indicates if everything is currently loading
   */
  get Loading(): boolean {
    return this.Loading$.getValue();
  }

  /**
   * Subject which indicates if everything has been loaded
   */
  get Loaded$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loaded);
  }

  /**
   * Indicates if everything has been loaded
   */
  get Loaded(): boolean {
    return this.Loaded$.getValue();
  }

  /**
   * Subject which indicates if locations (of machines) are currently loading from backend
   */
  get LocationsLoading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.locationLoading);
  }

  /**
   * Indicates if locations (of machines) are currently loading from backend
   */
  get LocationsLoading(): boolean {
    return this.LocationsLoading$.getValue();
  }

  getLocations(): ILocationModel[] {
    return super.Listen(s => s.locations).getValue();
  }

  /**
   *  load fleet actions from Backend to the Store
   */
  public loadFieldActions(from: Date, to: Date, fields: IGuid[], cropType: ICropTypes,
                          workType: IWorktypes, includeApproach: boolean): void {
    const cropKey = cropType ? cropType.CropKey : -1;
    const workTypeId = workType ? workType.Id : -1;
    super.SetLoadState();
    super.Mutate(s => s.fieldActions, () => []);
    this.DispatchBackend(new FieldActionsLoad([
      {Name: 'from', Type: NetTypes.DATETIME, Value: from},
      {Name: 'to', Type: NetTypes.DATETIME, Value: to},
      {Name: 'fieldIds', Type: NetTypes.GUID + '[]', Value: fields},
      {Name: 'cropKey', Type: NetTypes.INT, Value: cropKey},
      {Name: 'workTypeId', Type: NetTypes.INT, Value: workTypeId},
      {Name: 'includeApproach', Type: NetTypes.BOOL, Value: includeApproach},
    ]));
  }

  /**
   *  load fleet actions from Backend to the Store
   */
  public loadFleetActions(): void {
    super.SetLoadState();
    super.Mutate(s => s.fleetActions, () => []);
    this.DispatchBackend(new FleetActionsLoad([]));
  }

  /**
   *  load locations from Backend to the Store
   */
  public loadLocations(): void {
    super.SetLoadState();
    super.Mutate(s => s.locationLoaded, () => false);
    super.Mutate(s => s.locationLoading, () => true);
    this.DispatchBackend(new LocationsLoad([]));
  }

  /**
   * select locations in the Grid
   */
  public changeSelectedLocation(locationIds: IGuid[]): void {
    super.Mutate(s => s.selectedLocations, () => []);
    super.Mutate(s => s.selectedLocations, () => this.getLocations() ? this.getLocations().filter(loc => locationIds.indexOf(loc.Id) !== -1) : []);
  }

  public dropApplication(application: IReadApplicationModel, actionId: string): void {
    this.DispatchBackend(new ActionsApplicationDrop({
      application,
      actionId
    }));
  }

  public addApplication(application: IReadApplicationModel, actionId: string): void {
    this.DispatchBackend(new ActionsApplicationAdd({
      application,
      actionId
    }));
  }
}

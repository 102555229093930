import {AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ApDynGridColumnConfigBase
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPagerConfigBase
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription}       from 'rxjs';
import {ApGridPagerType, ApVisibleStates, IApPermissionData, MapViewMode}        from '../../ap-interface';
import {
  ApDynGridPagerConfig,
  IDynGridWizardChoiceData,
  IGridPagerSubmitData,
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-pager-config';
import {
  ApEditActionComponent
}                                                                                from '../../entry-components/ap-docu-entry-components/ap-edit-action.component';
import {ApDocuUtils}                     from '../../docu/utils/ap-docu-utils';
import {ApGuidUtil, GetRoundNumericPipe} from '../../ap-utils';
import {
  ApDynformsConfigFieldset
}                                        from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigComboBox
}                                                                                from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynGridPropertyColumnConfig,
  FilterType
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridButtonColumnConfig
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-button-column-config';
import {APP_CONFIGURATION}                                                       from '../../ap-core/config';
import {
  ApDynGridEditColumnConfig
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-edit-column-config';
import {
  ApDynGridStatusSwitchColumnConfig,
  IStateChange
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-status-switch-column.config';
import {
  ApDynformsConfigTextbox
}                                                                                from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {
  ApDynformsConfigArray
}                                                                                from '../../ap-dynforms/config/ap-dynforms-config-array';
import {
  ApDynformsConfigNumerictextbox
}                                                                                from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApDynformsConfigTabs
}                                                                                from '../../ap-dynforms/config/ap-dynforms-config-tabs';
import {
  ApDynComponentComponent
}                                                                                from '../../ap-dyncomponent/ap-dyncomponent.component';
import {filter, map}                                                             from 'rxjs/operators';
import {ApBookingUtils}                                                          from '../ap-booking-utils';
import {
  ApDynformsValidator
}                                                                                from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDatesOverlapValidator
}                                                                                from '../../ap-dynforms/validators/ap-dates-overlap-validator';
import {DriverStore}                                                             from '../../stores/docu/driver.store';
import {
  InstrumentStore
}                                                                                from '../../stores/docu/instrument.store';
import {
  SettingsStore
}                                                                                from '../../stores/base-data/settings.store';
import {ApPermissions}                                                           from 'invoker-transport';
import {FieldStore}                                                              from '../../stores/farm/field.store';
import {FormGroup}                                                               from '@angular/forms';
import {
  CampaignYearStore
}                                                                                from '../../stores/login/campaignyear.store';
import {FormStore}                                                               from '../../stores/layout/form.store';
import {
  MapViewStore
}                                                                                from '../../stores/layout/mapview.store';
import {
  FleetActionAttachmentStore
}                                                                                from '../../stores/docu/fleet.action.attachment.store';
import {
  TranslationStore
}                                                                                from '../../stores/translation/translation.store';
import {
  LanguageStore
}                                                                                from '../../stores/translation/language.store';
import {
  ApDateService
}                                                                                from '../../ap-core/services/ap-date-service';
import {ObjectFactory, StringFactory}                                            from 'ts-tooling';
import {
  TrackLoaderService
}                                                                                from '../../docu/services/track.loader.service';
import {MapStore}                                                                from '../../stores/map/map.store';
import {
  GetRoundNumericService
}                                                                                from '../../ap-utils/service/get-round-numeric.service';
import {LoginStore}                                                              from '../../stores/login/login.store';
import * as moment                                                               from 'moment-timezone';
import ISettings = Data.BaseData.ISettings;
import IDriver = Data.DocuContext.Driver.IDriver;
import IWorktypes = Data.BaseData.IWorktypes;
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IFleetActionAttachment = Data.DocuContext.FleetManagement.IFleetActionAttachment;
import IAttachmentApplication = Data.DocuContext.Attachment.IAttachmentApplication;
import IDateRange = Data.BaseData.IDateRange;

@Component({
  selector: 'ap-booking-archive',
  template: `
    <ap-dyncomponent #component
                     [caption]="'Global__Bookings'"
                     [headerIcon]="'ap-icon-field-works'"
                     [canSearch]="true"
                     [canCreate]="(ApPermissions.EDIT_BOOKINGS_CONSTANT | getPermission | async)"
                     [columns]="columns"
                     [loading$]="loading$"
                     [errorKeys]="errorKeys"
                     [items]="items"
                     [pager]="pager"
                     [selectable]="{checkboxOnly: true, mode: 'multiple', enabled: true}"
                     [sortable]="{mode: 'multiple'}"
                     (create)="onCreate()"
                     (edit)="onEdit($event)">
    </ap-dyncomponent>`,
})
export class ApBookingArchiveComponent implements OnInit, AfterContentInit, OnDestroy {

  constructor(private campaignYearStore: CampaignYearStore,
              private driverStore: DriverStore,
              private fieldStore: FieldStore,
              private fleetActionAttachmentStore: FleetActionAttachmentStore,
              private formStore: FormStore,
              private loginStore: LoginStore,
              private instrumentStore: InstrumentStore,
              private dateService: ApDateService,
              private mapViewStore: MapViewStore,
              private mapStore: MapStore,
              private roundNumericPipe: GetRoundNumericPipe,
              private roundNumericService: GetRoundNumericService,
              private settingsStore: SettingsStore,
              private translationStore: TranslationStore,
              private languageStore: LanguageStore,
              private trackLoaderService: TrackLoaderService) {
  }

  @ViewChild('component', {static: true}) dynComponent: ApDynComponentComponent;
  columns: ApDynGridColumnConfigBase[];
  items: any[] = [];
  errorKeys = [];
  loading$: Observable<boolean>;
  pager: ApDynGridPagerConfigBase;
  apPermissionData: IApPermissionData;
  ApPermissions = ApPermissions;

  private _applicationUnitSubject = new BehaviorSubject<{ value: string, text: string }[]>([]);
  private _driverEdit: IDriver[] = [];
  private _errorKeySubjects: Subject<string[]>[] = [];
  private _hideOnClose = false;
  private _instrumentEdit: IInstrument[];
  private _listMassEditing: IDynGridWizardChoiceData[] = [];
  private _mapViewMode: MapViewMode;
  private _settings: ISettings;
  private _subscriptions: Subscription[] = [];
  private _removeTimeout;
  private _selectedDateRanges$: Observable<IDateRange[]>;

  private generateApplicationLabel(applications: IAttachmentApplication[], newLine: string): string {
    let applicationLabel = '';
    if (StringFactory.IsNullOrEmpty(newLine)) {
      newLine = ', ';
    }
    if (Array.isArray(applications)) {
      for (const appl of applications) {
        if (applicationLabel.length > 0) {
          applicationLabel += newLine;
        }
        applicationLabel += `${appl.Key} ${this.roundNumericService.round(appl.Value)} ${appl.Unit}/ha`;
      }
    }
    return applicationLabel;
  }

  ngOnInit(): void {
    this._getDataFromStore();
    this._subscriptions.Add(this.mapStore.Layers.TrackLayer.onExtentLoadFinish.subscribe(() => {
      this.mapStore.Layers.TrackLayer.zoomToExtent();
    }));
    combineLatest([
      this.formStore.Listen(s => s.update),
      this.loginStore.SelectedFarm$,
    ]).pipe(
      filter(s => s[0] === ApVisibleStates.OUT && !!s[1])
    ).subscribe(s => {
      this.trackLoaderService.loadBySelectedSlice(this.trackLoaderService.selectedSlices.FirstOrDefault(), s[1].Id);
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => this.mapViewStore.hideMapView(), 0);
    this._selectedDateRanges$ = this.dynComponent.SelectedItems$.pipe(
      map(items => items.map(item => {
        const fleetActionAttachment = item['SourceItem'] as IFleetActionAttachment;
        return {
          Id: fleetActionAttachment.Id,
          Start: fleetActionAttachment.Attachment.From,
          End: fleetActionAttachment.Attachment.To
        } as IDateRange;
      }))
    );

    this._fillWizard();
    this._gridBuilder();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
    this.trackLoaderService.clear();
    this.mapStore.Layers.Clear();
  }

  onCreate(): void {
    this.formStore.openForm({component: ApEditActionComponent, data: null});
  }

  onEdit(event: any): void {
    if (this._mapViewMode !== MapViewMode.NORMAL) {
      this.mapViewStore.showMapView();
    }
    this.trackLoaderService.clear();
    this.trackLoaderService.loadByFleetAction(event.SourceItem);
    setTimeout(() => this.formStore.openForm({component: ApEditActionComponent, data: event.SourceItem}));
  }

  private _createEventEmitter<T>(generatorOrNext): EventEmitter<T> {
    const eventEmitter = new EventEmitter<T>();
    this._subscriptions.push(eventEmitter.subscribe(generatorOrNext));
    return eventEmitter;
  }

  private _fillWizard(): void {
    this._listMassEditing = [
      {
        id: 0,
        name: this.translationStore.FindTranslationForSelectedLanguage('Global__Edit'),
        type: ApGridPagerType.None,
        values: [],
        onSubmit: this._createEventEmitter<IGridPagerSubmitData>(this._saveFleetActionAttachment.bind(this)),
        formConfig: [
          new ApDynformsConfigTabs({
            title: 'Settings__Lbl_Menu_Resources',
            selected: true,
            fieldSets: [
              new ApDynformsConfigFieldset({
                key: 'booking-archive1',
                config: [
                  new ApDynformsConfigComboBox({
                    label: 'Docu_Ini__Tool',
                    key: 'SourceItem.Attachment.Instrument',
                    textField: 'Name',
                    options: this._instrumentEdit,
                    valueField: 'Id',
                    valuePrimitive: false,
                    value: null,
                    sort: 1,
                    asyncValidators: [
                      new ApDynformsValidator({
                        validator: ApDatesOverlapValidator.overlapCompare(
                          this.fleetActionAttachmentStore.Listen(s => s.instrumentDateRageAssignments),
                          this._selectedDateRanges$,
                        ),
                        errorKey: 'Docu__Vali_Msg_DateRange_Overlap_Selected',
                        invalidIds: this._getErrorKeySubject(),
                      }),
                      new ApDynformsValidator({
                        validator: ApDatesOverlapValidator.overlap(this._selectedDateRanges$),
                        errorKey: 'Docu__Vali_Msg_DateRange_Overlap_Selected',
                        invalidIds: this._getErrorKeySubject(),
                      })
                    ],
                    listenUpdate: [
                      this.fleetActionAttachmentStore.Listen(s => s.instrumentDateRageAssignments),
                      this._selectedDateRanges$
                    ]
                  }),
                  new ApDynformsConfigComboBox({
                    key: 'SourceItem.Attachment.Driver',
                    label: 'Docu_Ini__Motorist',
                    textField: 'FullName',
                    options: this._driverEdit,
                    valueField: 'Id',
                    valuePrimitive: false,
                    value: null,
                    sort: 1,
                    asyncValidators: [
                      new ApDynformsValidator({
                        validator: ApDatesOverlapValidator.overlapCompare(
                          this.fleetActionAttachmentStore.Listen(s => s.driverDateRangeAssignments),
                          this._selectedDateRanges$,
                        ),
                        errorKey: 'Docu__Vali_Msg_DateRange_Overlap_Selected',
                        invalidIds: this._getErrorKeySubject(),
                      }),
                      new ApDynformsValidator({
                        validator: ApDatesOverlapValidator.overlap(this._selectedDateRanges$),
                        errorKey: 'Docu__Vali_Msg_DateRange_Overlap_Selected',
                        invalidIds: this._getErrorKeySubject(),
                      })
                    ],
                    listenUpdate: [
                      this.fleetActionAttachmentStore.Listen(s => s.driverDateRangeAssignments),
                      this._selectedDateRanges$
                    ]
                  }),
                ]
              }),
            ],
          }),
          new ApDynformsConfigTabs({
            title: 'Global__Application',
            disabled: (form: FormGroup) => ApBookingUtils.disableApplications(form,
              'SourceItem.Attachment.Instrument', this._applicationUnitSubject, this.translationStore),
            fieldSets: [
              new ApDynformsConfigFieldset({
                key: 'booking-archive2',
                arrayConfig: [
                  new ApDynformsConfigArray({
                    config: [
                      new ApDynformsConfigTextbox({
                        key: 'Key',
                        label: 'Docu_Ini__Type',
                      }),
                      new ApDynformsConfigNumerictextbox({
                        label: 'Amount',
                        value: null,
                        key: 'Value',
                        min: 0,
                        spinners: false,
                        step: 0.05,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }),
                      new ApDynformsConfigComboBox({
                        key: 'Unit',
                        label: 'Settings__Lbl_Menu_Unit',
                        options: this._applicationUnitSubject,
                        valuePrimitive: true,
                        textField: 'text',
                        valueField: 'value'
                      }),
                    ],
                    min: 1,
                    max: 3,
                    columns: 3,
                    key: 'SourceItem.Attachment.Application',
                    validate: (v) => !!v['Key'],
                    extendOn: (v) => !!v['Key'] && !!v['Value'] && !!v['Unit'],
                  }),
                ]
              }),
            ]
          }),
          new ApDynformsConfigTabs({
            title: 'Global__Comment',
            fieldSets: [
              new ApDynformsConfigFieldset({
                key: 'booking-archive3',
                columns: 1,
                config: [
                  new ApDynformsConfigTextbox({
                    label: 'Global__Comment',
                    key: 'SourceItem.Attachment.Comment',
                  }),
                ]
              }),
            ],
          }),
        ],
      },
      {
        id: 1,
        name: this.translationStore.FindTranslationForSelectedLanguage('Global__Accept'),
        type: ApGridPagerType.None,
        disabled: ApBookingUtils.disableAccept,
        values: [],
        onSubmit: this._createEventEmitter<IGridPagerSubmitData>(
          (event: IGridPagerSubmitData) => this._setValid(event.items.map(i => i['SourceItem']), true))
      },
      {
        id: 2,
        name: this.translationStore.FindTranslationForSelectedLanguage('Global__Reset'),
        type: ApGridPagerType.None,
        values: [],
        onSubmit: this._createEventEmitter<IGridPagerSubmitData>(
          (event: IGridPagerSubmitData) => this._resetModified(event.items.map(i => i['SourceItem'])))
      },
      {
        id: 3,
        name: this.translationStore.FindTranslationForSelectedLanguage('Global__Reject'),
        type: ApGridPagerType.None,
        values: [],
        onSubmit: this._createEventEmitter<IGridPagerSubmitData>(
          (event: IGridPagerSubmitData) => this._setValid(event.items.map(i => i['SourceItem']), false))
      },
    ];
    this._subscribeToErrorKeySubjects();
  }

  private _getDataFromStore(): void {
    const deleteDriver = {
      Id: ApGuidUtil.getEmptyGuid(),
      FullName: `<${this.translationStore.FindTranslationForSelectedLanguage('Global__Delete')}>`,
    };

    const deleteWorkType: IWorktypes = {
      Id: -1,
      Description: `<${this.translationStore.FindTranslationForSelectedLanguage('Global__Delete')}>`,
      Wt_Unit: undefined,
      Shortname: undefined,
      Fertilisers: undefined,
      Category_Text: undefined,
      Category: undefined,
      Unit: undefined,
      SubCategory: undefined,
      Type: undefined
    };

    const deleteInstrument: IInstrument = {
      Id: ApGuidUtil.getEmptyGuid(),
      Name: `<${this.translationStore.FindTranslationForSelectedLanguage('Global__Delete')}>`,
      DefaultWorktype: deleteWorkType,
      DefaultWidth: null,
      IsDeleted: undefined,
      FarmId: undefined,
      Unit: undefined,
      CreatedAt: null,
      CreatedBy: null,
      ChangedAt: null,
      ChangedBy: null,
      DeletedAt: null,
      DeletedBy: null,
      Version: null,
      Type: null
    };

    this.loading$ = combineLatest(
      [this.fieldStore.Listen(s => s.loading), this.fleetActionAttachmentStore.Listen(s => s.loading)])
      .pipe(map(([f, a]) => f || a));

    this._subscriptions.push(this.settingsStore.FirstSetting$.subscribe(s => this._settings = s));

    this._subscriptions.push(
      combineLatest([
        this.fleetActionAttachmentStore.Listen(s => s.data),
        this.fieldStore.Listen(s => s.data)])
        .pipe(
          filter(([attachments, fields]) => fields.length !== 0 && !!attachments),
        ).subscribe(([attachments]) => {
          this.items = attachments.map((a: IFleetActionAttachment) => {
            const date = this.dateService.toFarmDateFromUtcGetJsDate(a.Attachment.From);
            const field = this.fieldStore.getFieldById(a.FieldId);
            const fieldName = this.fieldStore.getFieldName(field);
            return {
              Id: a.Id,
              SourceItem: a,
              Duration: ApDocuUtils.formatDuration(ApDocuUtils.getDurationOfSlicesInMs(a.Slices)),
              TimeOfNoUse: ApDocuUtils.formatDuration(ApDocuUtils.getStandStillTimeOfSlicesInMs(a.Slices)),
              Area: a.Slices.Reduce((val, s) => s.NettoArea + val, 0),
              From: moment(date).format('HH:mm'),
              FromDate: this.dateService.getDateMidnight(date),
              FieldNumber: field ? field.FieldNumber : '',
              FieldSubNumber: field ? field.FieldSubnumber : '',
              FieldName: fieldName,
              WorkTypeText: this.translationStore.FindTranslationForSelectedLanguage(a.Attachment.WorkType?.Description),
              DriverName: a.Attachment.Driver?.FullName ?? '',
              Application0:
                a.Attachment.Application && a.Attachment.Application[0] ? a.Attachment.Application[0].Key : '-',
              Application1:
                a.Attachment.Application && a.Attachment.Application[1] ? a.Attachment.Application[1].Key : '-',
              Application2:
                a.Attachment.Application && a.Attachment.Application[2] ? a.Attachment.Application[2].Key : '-',
              ApplicationLabel: this.generateApplicationLabel(a.Attachment.Application, ''),
            };
          });

          this.trackLoaderService.removeUnreferencedIds(this.items.Convert(_ => _.Id), this.items.FirstOrDefault()?.FarmId);

          if (attachments.find(a => !a.Attachment.IsModified)) {
            clearTimeout(this._removeTimeout);
            this._removeTimeout = setTimeout(() =>
              this.fleetActionAttachmentStore.removeUnmodifiedFleetActionAttachments(), 1000);
          }
        }
      )
    );

    this._subscriptions.push(this.driverStore.Listen(s => s.data).subscribe(drivers => {
      this._driverEdit = [...drivers, {
        Id: deleteDriver.Id,
        FarmId: 0,
        Firstname: '',
        FullName: '',
        ShortFullName: '',
        Name: '',
        IsDeleted: false,
        Version: 0,
        CreatedAt: null,
        CreatedBy: null,
        ChangedAt: null,
        ChangedBy: null,
        DeletedAt: null,
        DeletedBy: null,
      }];
    }));

    this._subscriptions.push(this.instrumentStore.Listen(s => s.data).subscribe(instruments => {
      this._instrumentEdit = [...instruments, deleteInstrument];
    }));

    this._subscriptions.push(this.campaignYearStore.Listen(s => s.selectedYear).subscribe(
      () => this.fleetActionAttachmentStore.loadFleetActionAttachmentsByModified(true)));
  }

  private _getErrorKeySubject(): Subject<string[]> {
    const subject = new Subject<string[]>();
    this._errorKeySubjects.push(subject);
    return subject;
  }

  private _gridBuilder(): void {
    this.languageStore.SelectedLanguage$.subscribe(l => {
      this.columns = [
        new ApDynGridPropertyColumnConfig({
          field: 'FromDate',
          title: 'Docu_Ini__Date',
          width: 110,
          format: l.DateFormat,
          sortIndex: 0,
          sortDesc: false,
          filterable: true,
          filterType: FilterType.DATE
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'From', title: 'Docu_Ini__StartTime', width: 100, hide: true,
          sortIndex: 1, sortDesc: false
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'Duration',
          title: 'Docu_Ini__Duration',
          width: 100,
          headerFilterable: true
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'SourceItem.Attachment.Machine.Name',
          title: 'Base__Engine',
          headerFilterable: true,
          filterable: true,
          width: 130
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'SourceItem.Attachment.Machine.Sign', title: 'Docu_Ini__NumberPlate',
          headerFilterable: true, hide: true, width: 100
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'SourceItem.Attachment.Instrument.Name',
          title: 'Docu_Ini__Tool',
          headerFilterable: true,
          filterable: true,
          width: 115
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'WorkTypeText',
          title: 'Docu_Ini__WorkType',
          headerFilterable: true,
          filterable: true,
          width: 120
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'DriverName',
          title: 'Docu_Ini__Motorist',
          hide: true,
          headerFilterable: true,
          filterable: true,
          width: 120
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'FieldNumber',
          title: 'Docu_Ini__Number',
          headerFilterable: true,
          width: 80,
          filterable: true,
          filterType: FilterType.NUMBER,
          cssClass: 'right',
          hide: true
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'FieldSubNumber',
          title: 'Docu_Ini_Subnumber',
          headerFilterable: true,
          filterType: FilterType.NUMBER,
          cssClass: 'right',
          width: 80,
          filterable: true,
          hide: true
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'FieldName',
          title: 'Docu_Ini__Field',
          headerFilterable: true,
          width: 100,
          filterable: true,
          groupDesc: false,
          groupIndex: 0
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'Area', title: 'Global_Worked_Area', width: 78, hide: true,
          unit: this.translationStore.FindTranslationForSelectedLanguage('Unit_ha'),
          filterable: true,
          filterType: FilterType.NUMBER,
          cssClass: 'right',
          tooltip: (dataItem) => `${this.roundNumericService.round(dataItem['Area'], -1)} ${this.translationStore.FindTranslationForSelectedLanguage('Unit_ha')}`,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [this._settings.DigitsAfterDecimalPoint]
            }
          ]
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'ApplicationLabel',
          title: 'Docu_Ini__Medium',
          hide: true,
          headerFilterable: true,
          tooltip: item => {
            return this.generateApplicationLabel(item.SourceItem.Attachment.Application, '<br>');
          },
        }),
        new ApDynGridStatusSwitchColumnConfig({
          field: 'SourceItem.Attachment.Status',
          readonly: true,
          filterable: true,
          defaultFilter: 1,
          disableGreen: (item) => ApBookingUtils.checkInvalid(item),
          stateChange: this._createEventEmitter(
            (event: IStateChange) => {
              switch (event.state) {
                case -1:
                  this._setValid(event.item['SourceItem'], false);
                  break;
                case 0:
                  this._resetModified(event.item['SourceItem']);
                  break;
                case 1:
                  this._setValid(event.item['SourceItem'], true);
                  break;
              }
            }),
        }),
        new ApDynGridButtonColumnConfig(
          {
            callback: this._createEventEmitter(this._showLineClick.bind(this)),
            disabled: (fleetActionAttachment) => fleetActionAttachment.SourceItem.Attachment.Logger === null,
            imageUrl: APP_CONFIGURATION.ButtonZoomToMapImage,
            toggleable: true, selected: this._isItemSelected.bind(this),
            tooltip: 'Permission_Feature_Show_Lanes'
          }),
        new ApDynGridEditColumnConfig({})
      ];

      this.pager = new ApDynGridPagerConfig({listMassEditing: this._listMassEditing, preselect: false});
    });
  }

  private _isItemSelected(item): boolean {
    const action = item as IFleetActionAttachment;
    return !!this.trackLoaderService.selectedSlices.Find(_ => _.id === action.Id);
  }

  private _resetModified(data: IFleetActionAttachment | IFleetActionAttachment[]): void {
    if (!(data instanceof Array)) {
      data = [data];
    }
    const c = ObjectFactory.Copy(data);
    for (const d of c) {
      d.Attachment.IsModified = false;
      d.Attachment.Valid = true;
    }
    this.fleetActionAttachmentStore.saveFleetActionAttachments(c);
  }

  private _saveFleetActionAttachment(data: IGridPagerSubmitData): void {
    ApBookingUtils.saveFleetActionAttachment(data, this.fleetActionAttachmentStore);
  }

  private _setValid(data: IFleetActionAttachment | IFleetActionAttachment[], valid: boolean): void {
    if (!(data instanceof Array)) {
      data = [data];
    }
    const c = ObjectFactory.Copy(data);
    for (const d of c) {
      d.Attachment.IsModified = true;
      d.Attachment.Valid = valid;
      if (d.Attachment.Comment === null) {
        d.Attachment.Comment = '';
      }
    }
    this.fleetActionAttachmentStore.saveFleetActionAttachments(c);
  }

  private _showLineClick(event): void {
    const dataItem: IFleetActionAttachment = event['SourceItem'];
    this.trackLoaderService.loadByFleetAction(dataItem);
    if (this.mapViewStore.getMapViewMode() === MapViewMode.HIDE && this.trackLoaderService.selectedSlices.Any()) {
      this._hideOnClose = true;
      this.mapViewStore.showMapView();
    }
  }

  private _subscribeToErrorKeySubjects(): void {
    this._subscriptions.push(
      combineLatest(this._errorKeySubjects)
        .pipe(
          map(arr => {
            let merge: string[] = [];
            arr.forEach(a => merge = merge.concat(a));
            return merge.filter((v, i, a) => a.indexOf(v) === i);
          })
        )
        .subscribe(keys => this.errorKeys = keys)
    );
  }
}

import {Component} from '@angular/core';

const CONTENT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.5 139.16">
  <g>
    <path d="M94.78,107.39a10,10,0,1,1-9.77,10,9.89,9.89,0,0,1,9.77-10m0-15.52c13.79,0,25,11.42,25,25.52s-11.17,25.52-25,25.52-25-11.42-25-25.52S81,91.87,94.78,91.87" transform="translate(-0.79 -26.11)" class="hoverFill"/>
    <path d="M190.07,106.75l-70.65.18a33.21,33.21,0,0,1,3.06,7h18.65v0h48.93l.23,0h.45v-.27a1.15,1.15,0,0,0,.32-.8v-5a1.06,1.06,0,0,0-1-1.09" transform="translate(-0.79 -26.11)"/>
    <path d="M198.19,115.79h-7.1l-.23-1.2h-6.79l4.09,5h10a1.1,1.1,0,0,0,1.1-1.1v-1.58a1.1,1.1,0,0,0-1.1-1.1" transform="translate(-0.79 -26.11)"/>
    <path d="M119,106.1h35a3,3,0,0,0,2.77-3.19v-1a3.34,3.34,0,0,1,.81-2.27,2.55,2.55,0,0,1,2-.93l5.49,0a2.65,2.65,0,0,0,2.37-1.53L169.16,94H107.59A32.77,32.77,0,0,1,119,106.1" transform="translate(-0.79 -26.11)"/>
    <path d="M64.16,94,65,98.5h1.33a3.07,3.07,0,0,1,2.87,3.21V103a3.31,3.31,0,0,0,1.34,2.71A33.47,33.47,0,0,1,82.14,94Z" transform="translate(-0.79 -26.11)"/>
    <path d="M58.2,64.92l5.16,27.91H84.15a28,28,0,0,1,22.21,0h65.73L187,65.93a3.28,3.28,0,0,0,.06-3.09,2.66,2.66,0,0,0-2.42-1.56l-123.72,0a2.6,2.6,0,0,0-2.14,1.13,3.23,3.23,0,0,0-.57,2.55" transform="translate(-0.79 -26.11)"/>
    <path d="M37.18,113.09H51.89v-1.24a2.85,2.85,0,0,0-2.78-2.9h-.18A2.86,2.86,0,0,1,46.15,106V94.52a2.94,2.94,0,0,1,.34-1.38H62a3.71,3.71,0,0,0,.08.73l.79,4a3.49,3.49,0,0,0,3.39,2.9h.6v.46a6.61,6.61,0,0,0,3,5.58,32.62,32.62,0,0,0-2.59,7.3l-6.8-4.9v3.83h1.92v.33a2.85,2.85,0,0,1-2.79,2.9H40a2.84,2.84,0,0,1-2.78-2.9Z" transform="translate(-0.79 -26.11)"/>
    <path d="M186.76,26.11,60,34.68c-1.5.09-2.7,4.27-2.7,9.36v6.65c0,5.17,1.23,9.39,2.75,9.39l126.72.09c1.52,0,2.76-4.21,2.76-9.38V35.48a23.5,23.5,0,0,0-.84-6.69c-.54-1.82-1.21-2.73-2-2.68" transform="translate(-0.79 -26.11)" class="hoverFill"/>
    <path d="M94.76,128.29A10.73,10.73,0,1,0,84,117.56a10.72,10.72,0,0,0,10.72,10.73" transform="translate(-0.79 -26.11)"/>
  </g>
  <rect x="6.16" y="120.35" width="181.53" height="3.54"/>
  <g>
    <rect x="6.16" y="120.34" width="181.53" height="3.54"/>
  </g>
  <rect x="15.67" y="156.78" width="27.45" height="3.54" transform="translate(-34.38 -16) rotate(-12.49)"/>
  <g>
    <rect x="15.67" y="156.79" width="27.46" height="3.54" transform="translate(-34.38 -16) rotate(-12.49)"/>
  </g>
  <rect x="88.69" y="157.29" width="27.45" height="3.54" transform="translate(-32.76 -0.2) rotate(-12.49)"/>
  <g>
    <rect x="88.69" y="157.29" width="27.46" height="3.54" transform="translate(-32.76 -0.2) rotate(-12.49)"/>
  </g>
  <rect x="56.07" y="157.79" width="27.45" height="3.54" transform="translate(-33.64 -7.24) rotate(-12.49)"/>
  <g>
    <rect x="56.07" y="157.79" width="27.46" height="3.54" transform="translate(-33.64 -7.24) rotate(-12.49)"/>
  </g>
  <rect x="123.32" y="158.4" width="27.45" height="3.54" transform="translate(-32.18 7.32) rotate(-12.49)"/>
  <g>
    <rect x="123.32" y="158.4" width="27.46" height="3.54" transform="matrix(0.98, -0.22, 0.22, 0.98, -32.19, 7.32)"/>
  </g>
  <rect x="160.97" y="157.54" width="27.45" height="3.54" transform="translate(-31.11 15.44) rotate(-12.49)"/>
  <g>
    <rect x="160.97" y="157.54" width="27.46" height="3.54" transform="translate(-31.11 15.44) rotate(-12.49)"/>
    <path d="M9.69,111.79a3.63,3.63,0,1,0-3.63-3.63,3.63,3.63,0,0,0,3.63,3.63" transform="translate(-0.79 -26.11)"/>
    <path d="M4.42,105.51a3.63,3.63,0,1,0-3.63-3.62,3.63,3.63,0,0,0,3.63,3.62" transform="translate(-0.79 -26.11)"/>
    <path d="M14.92,118.06a3.63,3.63,0,1,0-3.63-3.63,3.63,3.63,0,0,0,3.63,3.63" transform="translate(-0.79 -26.11)"/>
    <path d="M23.67,113.22a3.63,3.63,0,1,0-3.23-4,3.62,3.62,0,0,0,3.23,4" transform="translate(-0.79 -26.11)"/>
    <path d="M31.67,117.22a3.63,3.63,0,1,0-3.23-4,3.63,3.63,0,0,0,3.23,4" transform="translate(-0.79 -26.11)"/>
  </g>
</svg>
`;

@Component({
  selector: 'ap-icon-base-fertilization',
  template: CONTENT
})
export class ApIconBaseFertilizationComponent {
}

import {concat, difference}                                 from 'lodash';
import {ApGeoJsonPolygon}                                   from '../interfaces/ap-geojson.interface';
import {LayerGroupNames, LayerNames}                        from './layer-names';
import {ApBaseVectorLayer, LayerSyncStrategy}               from './ap-base-vector.layer';
import OlFeature                                            from 'ol/Feature';
import OlFormatGeoJSON                                      from 'ol/format/GeoJSON';
import {MAP_PROJECTION}                                     from './ap-map.settings';
import {ApMapControlStream}                                 from './ap-map-control.stream';
import {ApMapInstance}                                      from '../ap-map.instance';
import {ApMenuUrls}                                         from '../../ap-interface';
import MapBrowserEvent                                      from 'ol/MapBrowserEvent';
import {MapStore}                                           from '../../stores/map/map.store';
import {defaultStyle, defaultStyleLabelOnly, selectedStyle} from './ap-fields.style';
import Style                                                from 'ol/style/Style';
import ISoilSampleField = Data.Nutrients.ISoilSampleField;
import {LayerZIndex}                                        from './layer-z-index';

function _parseJson(data: string): ApGeoJsonPolygon {
  try {
    return JSON.parse(data);
  } catch (err) {
    return null;
  }
}

function _parseColor(field: ISoilSampleField): string {
  return 'rgba(55, 55, 55, 0.1)';
}

class ApBaseSampleFieldsLayer extends ApBaseVectorLayer {
  static readonly projection: string = 'EPSG:4326';

  constructor(layerName: string, mapStore: MapStore, data: ISoilSampleField[] = [], style: (f: OlFeature) => Style | Style[], declutter = false) {
    super(layerName, LayerGroupNames.BASIC, mapStore, declutter);
    this.FixedZIndex = LayerZIndex.SAMPLE_FIELDS;
    this.replaceSource(data, style);
  }

  replaceSource(data: ISoilSampleField[], style: (f: OlFeature) => Style | Style[]): OlFeature[] {
    const features = this.read(data);
    this.readFeatures(features, style);
    return features;
  }

  read(data: ISoilSampleField[]): OlFeature[] {
    const withGeometry = data.FindAll(f => f.Geom && typeof f.Geom === typeof '');

    return withGeometry.Convert((f: ISoilSampleField) => {
      const geom = new OlFormatGeoJSON({
        dataProjection: ApSampleFieldsLayer.projection,
        featureProjection: MAP_PROJECTION
      });
      const feat = geom.readFeature({
        type: 'Feature',
        geometry: _parseJson(f.Geom.toString()),
        properties: {
          label: this.getSoilFieldExpressionByValues(f, f.Area),
          layerName: LayerNames.SOIL_SAMPLE_FIELDS,
          color: _parseColor(f)
        }
      });
      feat.setId(f.Id.toString(10));
      return feat;
    });
  }
}

export class ApSampleFieldsDescriptionLayer extends ApBaseSampleFieldsLayer {
  static readonly projection: string = 'EPSG:4326';

  constructor(mapStore: MapStore, data: ISoilSampleField[] = []) {
    super(LayerNames.SOIL_SAMPLE_FIELDS_DESCRIPTION, mapStore, data, ApSampleFieldsDescriptionLayer.defaultStyle, true);
    this.Order = 99999;
  }

  static readonly defaultStyle = function (f): Style {
    return defaultStyleLabelOnly(f);
  };
}

/**
 * the Fields Layer represent the Fields of a Farm
 */
export class ApSampleFieldsLayer extends ApBaseSampleFieldsLayer {
  /**
   * the Projection of the Fields Layer Data comes from the Backend
   */
  static readonly projection: string = 'EPSG:4326';
  static drawSelectedStyle = true;

  /**
   * create a new Layer of Fields from a List of Fields
   */
  constructor(mapStore: MapStore, data: ISoilSampleField[] = []) {
    super(LayerNames.SOIL_SAMPLE_FIELDS, mapStore, data, ApSampleFieldsLayer.defaultStyle);
    ApMapControlStream.listenClick$.subscribe(event => this._selectFields(event));
  }

  /**
   * the Default Style of a Field Feature in the Map
   */
  static readonly defaultStyle = function (f): Style | Style[] {
    return defaultStyle(f);
  };

  /**
   * the Style of a selected Field Feature in the Map
   */
  static readonly selectedStyle = function (f): Style | Style[] {
    return ApSampleFieldsLayer.drawSelectedStyle ?
      selectedStyle(f) :
      ApSampleFieldsLayer.defaultStyle(f);
  };

  /**
   * read the Geometry Data from the List of Fields into Openlayers Layer
   */
  public ReadFeature(data: ISoilSampleField[]): OlFeature[] {
    return super.replaceSource(data, ApSampleFieldsLayer.defaultStyle);
  }

  public replaceSource(data: ISoilSampleField[]): OlFeature[] {
    if (this.mapStore.Layers) {
      this.mapStore.Layers.SampleFieldDescriptionLayer.replaceSource(data, ApSampleFieldsDescriptionLayer.defaultStyle);
    }
    return super.replaceSource(data, ApSampleFieldsLayer.defaultStyle);
  }

  public SyncFeatures(features: OlFeature[], strategy = LayerSyncStrategy.FORCE): void {
    if (this.mapStore.Layers) {
      this.mapStore.Layers.SampleFieldDescriptionLayer.SyncFeatures(features, strategy);
    }
    super.SyncFeatures(features, strategy);
  }

  /**
   * select a Field by Id from Fields Layer
   */
  selectField(id: number): void {
    this.selectFeature(id.toString(10), ApSampleFieldsLayer.selectedStyle);
  }

  /**
   * Set layer visibility
   */
  public set Visibility(value: boolean) {
    this.mapStore.Layers.SampleFieldDescriptionLayer.Visibility = value;
    super.Visibility = value;
  }

  /**
   * Gets layer visibility
   */
  public get Visibility(): boolean {
    return super.Visibility;
  }

  /**
   * deselect a Field in Fields Layer
   */
  deselectField(id: string): void {
    this.deselectFeature(id);
  }

  /**
   * select Fields in Map and Grid
   */
  private _selectFields(event: MapBrowserEvent): void {
    if (ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.FIELD_MANAGEMENT_OVERVIEW &&
      ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.NUTRIENTS_OVERVIEW) {
      return;
    }
    const myFeatures = event.map.getFeaturesAtPixel(event.pixel);
    const layerFeatures = myFeatures.FindAll(f => f.get('layerName') === LayerNames.SOIL_SAMPLE_FIELDS);
    const layerFeatureIds = layerFeatures.Convert(f => f.getId());
    if (layerFeatureIds.length > 0) {
      const currentSelected = ApMapInstance.fieldStore.getSelectedFields().Convert(f => f.Id);
      const notSelectedBefore = [];
      for (const id of layerFeatureIds) {
        const isInSelection = !!currentSelected.Find(c => c === id);
        if (!isInSelection) {
          notSelectedBefore.push(id);
        }
      }
      const notSelectedNow = difference(currentSelected, layerFeatureIds);
      const newSelection = concat(notSelectedBefore, notSelectedNow);
      ApMapInstance.fieldStore.changeSelectedField(newSelection);
    }
  }
}

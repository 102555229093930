import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  PanelBarItemModel
}                                                                                       from '@progress/kendo-angular-layout';
import {
  TranslationStore
}                                                                                       from '../../stores/translation/translation.store';
import {
  BurgerMenuStore
}                                                                                       from '../../stores/map/burger.menu.store';
import {map}                                                                            from 'rxjs/operators';
import {Observable}                                                                     from 'rxjs';
import {
  ApDynamicComponentModel
}                                                                                       from '../../entry-components/ap-dynamic-component-loader/ap-dynamic-component-loader.component';
import {
  MapViewStore
}                                                                                       from '../../stores/layout/mapview.store';
import {MapViewMode}                                                                    from '../../ap-interface/enums';

@Component({
  selector: 'ap-map-burger-menu',
  templateUrl: './ap-map-burger-menu.component.html',
  styleUrls: ['./ap-map-burger-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApMapBurgerMenuComponent implements OnInit {
  @ViewChild('apMapBurgerMenu', {static: false}) private apMapBurgerMenu: ElementRef;

  public show = false;
  public showMap$: Observable<boolean>;
  public items$: Observable<PanelBarItemModel[]>;

  constructor(private store: BurgerMenuStore,
              private translationStore: TranslationStore,
              private mapView: MapViewStore,
              private changeDetectorRef: ChangeDetectorRef) {
    this.showMap$ = this.mapView.Listen(s => s.mode).pipe(
      map(m => m !== MapViewMode.HIDE)
    );
  }

  ngOnInit(): void {
    this.items$ = this.store.Listen(s => s.data).pipe(
      map(d => this._toPanelBarItemModel(d))
    );
  }

  asComponent(content: string | ArrayBuffer | DocumentFragment): ApDynamicComponentModel {
    return content as unknown as ApDynamicComponentModel;
  }

  isComponent(content: string | ArrayBuffer | DocumentFragment): boolean {
    return content instanceof ApDynamicComponentModel;
  }

  isString(content: string | ArrayBuffer | DocumentFragment): boolean {
    return typeof content === 'string';
  }

  private _toPanelBarItemModel(data: any[]): PanelBarItemModel[] {
    return data.map(d => ({
      title: this.translationStore.FindTranslationForSelectedLanguage(d.title),
      id: d.title,
      content: d.content ? d.content : undefined,
      children: d.children ? this._toPanelBarItemModel(d.children) : [],
      expanded: d.expanded ? d.expanded : false
    } as PanelBarItemModel));
  }

  public onClick(): void {
    this.show = !this.show;
  }
}

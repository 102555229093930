import {Pipe, PipeTransform} from '@angular/core';
import ParameterRefObjectType = Data.Parameter.Enums.ParameterRefObjectType;


@Pipe({
  name: 'getParameterRefObjectType'
})
export class GetParameterRefObjectTypePipe implements PipeTransform {
  transform(parameterRefObjectType: ParameterRefObjectType): string {
    switch (parameterRefObjectType) {
      case Data.Parameter.Enums.ParameterRefObjectType.Field:
        // ToDo
        // return this.translationStore.FindTranslationForSelectedLanguage('');
        return 'Field';
    }
  }
}

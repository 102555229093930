import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
}                                  from '@angular/core';
import {ApStatsType}               from '../../ap-interface';
import {StatisticStore}            from '../../stores/statistic/statistic.store';
import {StatisticChartAreaService} from '../services/statistic-chart-area.service';
import {Subscription}              from 'rxjs';

/**
 * Component for the statistics
 */
@Component({
  selector: 'ap-statistics',
  templateUrl: 'ap-statistics.component.html',
  styleUrls: ['ap-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApStatisticsComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('apStatisticArea', {static: true})
  public apChartArea: ElementRef;
  public apChartType = ApStatsType;
  public data$ = this.statisticStore.Data$;
  public loading$ = this.statisticStore.Loading$;
  public selection$ = this.statisticStore.Selection$;
  public selected$ = this.statisticStore.SelectedValue$;
  public statisticsStyles: object;
  public statisticItemStyles: object;

  private _observer: ResizeObserver = null;
  private _subscriptions: Subscription[] = [];

  constructor(private statisticStore: StatisticStore,
              private statisticChartAreaService: StatisticChartAreaService) {
  }

  public ngOnInit(): void {
    this._setStatisticsStyles();
    this._setStatisticItemStyle();
  }

  public ngAfterContentInit(): void {
    this._observer = new ResizeObserver((entries) => {
      const entry = entries.FirstOrDefault();
      if (!entry) {
        return;
      }
      if (this.statisticChartAreaService.chartAreaWidth.getValue() !== entry.target.clientWidth) {
        this.statisticChartAreaService.updateChartWidth(entry.target.clientWidth);
      }
      if (this.statisticChartAreaService.chartAreaHeight.getValue() !== entry.target.clientHeight) {
        this.statisticChartAreaService.updateChartHeight(entry.target.clientHeight);
      }
    });
    if (!this.apChartArea?.nativeElement) {
      console.warn('missing chart area div');
      return;
    }
    this._observer.observe(this.apChartArea.nativeElement);
    this._subscriptions.push(this.data$.subscribe(data => {
      if (data) {
        this._setStatisticsStyles();
        this._setStatisticItemStyle();
      }
    }));
  }

  public ngOnDestroy(): void {
    if (!this.apChartArea?.nativeElement) {
      return;
    }
    this._observer.unobserve(this.apChartArea.nativeElement);
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  public yearChange(e: { text: string; value: number }): void {
    this.statisticStore.selectedValueChange.emit(e);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this._setStatisticsStyles();
    this._setStatisticItemStyle();
  }

  private _setStatisticsStyles(): void {
    const statisticItems = this.data$?.value;
    const styleObject = {};
    styleObject['width'] = '100%';
    styleObject['position'] = 'relative';
    styleObject['display'] = 'flex';
    styleObject['flex-direction'] = 'column';
    styleObject['height'] = '100%';
    styleObject['overflow-y'] = 'hidden';
    if (this._isNeedEnableScroll(statisticItems)) {
      styleObject['overflow-y'] = 'auto';
    }
    this.statisticsStyles = styleObject;
  }

  private _setStatisticItemStyle(): void {
    const statisticItems = this.data$?.value;
    const itemCount = statisticItems?.length;
    const styleObject = {};
    if (!itemCount || itemCount === 0) {
      styleObject['height'] = '100vH';
      this.statisticItemStyles = styleObject;
      return;
    }
    styleObject['height'] = `${(100 / itemCount)}vH`;
    this.statisticItemStyles = styleObject;
  }

  private _isNeedEnableScroll(statisticItems: any[]): boolean {
    return statisticItems.length === 1
      && (statisticItems[0].type === ApStatsType.BaseFertStats
        || statisticItems[0].type === ApStatsType.PlanningWizardOverviewStats
        || statisticItems[0].type === ApStatsType.NFertilizationLogfileStats
        || statisticItems[0].type === ApStatsType.NFertilizationNNeedStats
        || statisticItems[0].type === ApStatsType.NFertilizationPlanningWizardSelectionStats
        || statisticItems[0].type === ApStatsType.FieldsCropRotationStats
        || statisticItems[0].type === ApStatsType.DashboardStats);
  }
}

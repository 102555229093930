export class ApBase64Util {
  /**
   * Converts the given string to base64 expression.
   * The string may contain 2-byte characters (chinese, emojis, etc.)
   * Its an improved implementation over using plain 'window.btoa' which cannot
   * handle 2-byte characters (only latin-1)
   * @param value to be converted
   */
  public static stringToBase64(value: string): string {
    return window.btoa(
      this.stringFromCodePoint(
        this.encode(value)));
  }

  /**
   * Converts the given bytes to base64 expression
   * @param bytes to be converted
   */
  public static bytesToBase64(bytes: Uint8Array): string {
    return window.btoa(
      this.stringFromCodePoint(bytes));
  }

  private static encode(value: string): Uint8Array {
    return new TextEncoder().encode(value);
  }

  private static stringFromCodePoint(bytes: Uint8Array): string {
    return String.fromCodePoint(...bytes);
  }
}

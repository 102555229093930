import {Injectable}      from '@angular/core';
import {FertilizerStore} from '../../stores/base-data/fertilizer.store';

@Injectable({
  providedIn: 'root'
})
export class FertilizationManagementService {
  constructor(private fertilizerStore: FertilizerStore) {
  }

  public getFertilizerContentN(fertilizerId: number | null): number {
    const fertilizer = this.fertilizerStore.getFertilizer(fertilizerId);
    if (!fertilizer) {
      return 1;
    }
    let content = fertilizer.ContentN ?? 1;
    if (!fertilizer.IsOrganic) {
      content /= 100;
      if (fertilizer.IsLiquid) {
        content *= fertilizer.Density ?? 1;
      }
    }
    return content;
  }

  public calculateProductAmountN(fertilizerId: number | null, rate: number | null, area: number, factor?: number): number | null {
    if (!fertilizerId || !rate) {
      return null;
    }
    const fertilizerContent = this.getFertilizerContentN(fertilizerId);
    if (!fertilizerContent) {
      return null;
    }
    if (factor) {
      return rate * factor * area / fertilizerContent;
    }
    return rate * area / fertilizerContent;
  }
}

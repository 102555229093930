import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {map}                                                        from 'rxjs/operators';
import {JobsStore}                                                  from '../../stores/administration/jobs.store';
import JobStatus = Data.Job.JobStatus;

@Component({
  selector: 'ap-task-list',
  template: `
    <div class="ap-toolbar-control" #apToolbarTaskList>
      <button #taskListButton
              [class.notification-button]="true"
              [class.dark-bg]="true"
              kendoButton
              [id]="'task-list-button'"
              [look]="'outline'"
              [toggleable]="true"
              [icon]="'grid-layout'"
              class="notification-button"
              (click)="onClick()"
              [aptooltip]="'Global__TaskList' | translate" [perpetual]="!show">
        <span [ngStyle]="(jobsCount$ | async) <= 0 ? {'visibility': 'hidden'} : {'visibility':'visible'}"
              class="badge">{{ getJobCountBadge(jobsCount$ | async) }}</span>
      </button>
      <kendo-popup *ngIf="show"
                   class="ap-task-list-popup"
                   [anchor]="taskListButtonRef"
                   [animate]="{direction: 'up', duration: 133}"
                   [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
                   [popupAlign]="{ horizontal: 'right', vertical: 'bottom' }">
        <ap-farm-job-list></ap-farm-job-list>
      </kendo-popup>
    </div>
  `,
})
export class ApTaskListComponent implements AfterViewInit, OnDestroy {
  @ViewChild('apToolbarTaskList', {static: false}) private apToolbarTaskList: ElementRef;
  @ViewChild('taskListButton', {static: false}) public taskListButtonRef: ElementRef;
  public show = false;
  public outsideClickHandlerRef = this.outsideClickHandler.bind(this);
  public jobsCount$ = this.jobsStore.FarmJobs$.pipe(
    map(jobs =>
      jobs.FindAll(job => job.Status === JobStatus.Pending || job.Status === JobStatus.Running)?.length
    )
  );

  constructor(private jobsStore: JobsStore) {
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', this.outsideClickHandlerRef);
  }

  public outsideClickHandler($event: any): void {
    if (!this.show) {
      return;
    }
    // close popup on outside button-click:
    if (!this.apToolbarTaskList?.nativeElement?.contains($event.target)) {
      this.show = false;
    }
  }

  public onClick(): void {
    this.show = !this.show;
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.outsideClickHandlerRef);
  }

  public getJobCountBadge(jobsCount: number | null): string {
    if (jobsCount === null || !(jobsCount > 0)) {
      return '0';
    }
    if (jobsCount > 9) {
      return '9+';
    }
    return String(jobsCount);
  }
}

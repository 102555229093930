import {LayerGroupNames, LayerNames}           from './layer-names';
import {ApBaseVectorLayer}                     from './ap-base-vector.layer';
import OlFeature                               from 'ol/Feature';
import {concat, difference, filter, find, map} from 'lodash';
import OlFormatGeoJSON                         from 'ol/format/GeoJSON';
import {MAP_PROJECTION}                        from './ap-map.settings';
import {ApMapControlStream}                    from './ap-map-control.stream';
import {ApMapInstance}                         from '../ap-map.instance';
import Overlay                                 from 'ol/Overlay';
import * as moment                             from 'moment';
import {ApPropertiesStrings}                   from '../enums/ap-properties-strings';
import OlStyle                                 from 'ol/style/Style';
import OlStyleText                             from 'ol/style/Text';
import OlStyleStroke                           from 'ol/style/Stroke';
import OlStyleFill                             from 'ol/style/Fill';
import OlIcon                                  from 'ol/style/Icon';
import {ApMenuUrls}                            from '../../ap-interface';
import IconAnchorUnits                         from 'ol/style/IconAnchorUnits';
import OverlayPositioning                      from 'ol/OverlayPositioning';
import MapBrowserEvent                         from 'ol/MapBrowserEvent';
import Point                                   from 'ol/geom/Point';
import {MapStore}                              from '../../stores/map/map.store';
import ILocationModel = Data.DocuContext.Location.ILocationModel;

/**
 * the ApLocationLayer Layer
 */
export class ApLocationLayer extends ApBaseVectorLayer {
  /**
   * the Projection of the Data comes from the Backend
   */
  static readonly projection: string = 'EPSG:4326';

  static popup: Overlay;

  public dateTimeFormat: string;
  public unknown: string;

  private machinePosCache: { [key: string]: string } = {};

  static readonly iconStyle = (f) => {
    const props = f.getProperties();
    const now = new Date();
    const propsDate = new Date(props.date);
    const today = `${propsDate.getFullYear()}-${propsDate.getMonth()}-${propsDate.getDay()}` === `${now.getFullYear()}-${now.getMonth()}-${now.getDay()}`;
    let iconUrl = '/assets/svg/202108__traktor.svg';
    switch (props.kind) {
      case 1:
      case 16:
      case 17:
        iconUrl = '/assets/svg/202108__traktor.svg';
        break;
      case 2:
        iconUrl = '/assets/svg/202108__lkw.svg';
        break;
      case 3:
        iconUrl = '/assets/svg/202108__vw.svg';
        break;
      case 4:
        iconUrl = '/assets/svg/202108__xerion.svg';
        break;
      case 5:
        iconUrl = '/assets/svg/202108__pickup.svg';
        break;
      case 6:
        iconUrl = '/assets/svg/202108__vwbus.svg';
        break;
      case 7:
        iconUrl = '/assets/svg/202108__selbstfahrer_spritze.svg';
        break;
      case 8:
        iconUrl = '/assets/svg/202108__radlader.svg';
        break;
      case 9:
        iconUrl = '/assets/svg/202108__teleskoplader.svg';
        break;
      case 10:
        iconUrl = '/assets/svg/202108__sattelauflieger.svg';
        break;
      case 11:
        iconUrl = '/assets/svg/202108__raupentraktor.svg';
        break;
      case 12:
        iconUrl = '/assets/svg/202108__maehdrescher.svg';
        break;
      case 13:
        iconUrl = '/assets/svg/202108__haecksler.svg';
        break;
      case 14:
        iconUrl = '/assets/svg/202108__futtermischwagen.svg';
        break;
      case 15:
        iconUrl = '/assets/svg/202108_discovery.svg';
        break;
    }

    let iconText = f.get(ApPropertiesStrings.Name);
    const machineSign = f.get(ApPropertiesStrings.Sign);
    if (machineSign !== null) {
      iconText = iconText + '\n' + machineSign;
    }
    const opacity = today ? 1 : 0.25;
    return new OlStyle({
      text: new OlStyleText({
        offsetY: -90,
        font: '11px Calibri,sans-serif',
        text: iconText,
        backgroundFill: new OlStyleFill({
          color: `rgba(255,255,255,${opacity})`,
        }),
        backgroundStroke: new OlStyleStroke({
          color: `rgba(176, 203, 31, ${opacity})`,
          width: 2,
        }),
        stroke: new OlStyleStroke({
          color: `rgba(255,255,255,${opacity})`,
          width: 2,
        }),
        padding: [5, 5, 5, 5],
        fill: new OlStyleFill({
          color: `rgba(0,0,0,${opacity})`,
        })
      }),
      image: new OlIcon(({
        anchor: [25, 73],
        anchorXUnits: IconAnchorUnits.PIXELS,
        anchorYUnits: IconAnchorUnits.PIXELS,
        offset: [0, 0],
        opacity,
        scale: 1,
        src: iconUrl,
      }))
    });
  }

  /**
   * create a new Layer
   */
  constructor(mapStore: MapStore, data?: ILocationModel[]) {
    super(LayerNames.LOCATIONS, LayerGroupNames.FIELD_WORK, mapStore, false, {
      layer: null,
      map: () => ApMapInstance.mapRef,
      duration: 3000,
      minRadius: 5,
      maxRadius: 50,
      strokeWidth: 2,
      color: [176, 203, 31, 1],
      filter: f => {
        const props = f.getProperties();
        if (!props || !props.date || !props.name) {
          return false;
        }
        if (!this.machinePosCache[props.name]) {
          this.machinePosCache[props.name] = props.date;
          return true;
        }
        if (this.machinePosCache[props.name] !== props.date) {
          this.machinePosCache[props.name] = props.date;
          return true;
        }
        return false;
      },
    });
    this.replaceSource(data);
    ApMapControlStream.listenSingleClick$.subscribe(
      event => {
        this._selectLocations(event);
        this._setPopup(event);
      }
    );
  }

  setUnknown(unknown: string): void {
    this.unknown = unknown;
  }

  /**
   * replace the Data Source of the Layer
   */
  replaceSource(data?: ILocationModel[]): void {
    this.readFeatures(data ? this._read(data) : [], null, null);
  }

  /**
   * select locations in Map and Grid
   */
  private _selectLocations(event: MapBrowserEvent): void {
    const myFeatures = event.map.getFeaturesAtPixel(event.pixel);
    const layerFeatures = filter(myFeatures, f => f.get(ApPropertiesStrings.LayerName) === LayerNames.LOCATIONS);
    const layerFeatureIds = map(layerFeatures, f => f.getId());
    if (layerFeatureIds.length > 0) {
      const currentSelected = map(ApMapInstance.actionStore.getLocations(), f => f.Id);
      const notSelectedBefore = [];
      for (const id of layerFeatureIds) {
        const isInSelection = !!find(currentSelected, c => c === id);
        if (!isInSelection) {
          notSelectedBefore.push(id);
        }
      }
      const notSelectedNow = difference(currentSelected, layerFeatureIds);
      const newSelection = concat(notSelectedBefore, notSelectedNow);
      ApMapInstance.actionStore.changeSelectedLocation(newSelection);
    }
  }

  /**
   * set the popup
   */
  private _setPopup(event: MapBrowserEvent): void {
    if (!ApMapInstance.mapRef) {
      console.warn('missing map reference');
      return;
    }
    if (!ApMapInstance.machinePopup ||
      ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.FIELD_WORK_OVERVIEW) {
      return;
    }

    if (!ApLocationLayer.popup) {
      ApLocationLayer.popup = new Overlay({
        id: ApPropertiesStrings.MachinePopup,
        positioning: OverlayPositioning.BOTTOM_CENTER,
        stopEvent: false,
        offset: [0, -50]
      });
      ApMapInstance.mapRef.addOverlay(ApLocationLayer.popup);
    }
    const featureArray = event.map.getFeaturesAtPixel(event.pixel, {
      hitTolerance: 5,
      layerFilter: (l) => l.get('name') === this.name
    });
    const feature = featureArray ? featureArray[0] : null;

    if (feature) {
      const coordinates = (feature.getGeometry() as Point).getCoordinates();
      ApLocationLayer.popup.setPosition(coordinates);

      ApMapInstance.machinePopup.date = feature.get(ApPropertiesStrings.Date);
      ApMapInstance.machinePopup.speed = feature.get(ApPropertiesStrings.Speed) === '' ? '0' : feature.get(ApPropertiesStrings.Speed);
      ApMapInstance.machinePopup.name = feature.get(ApPropertiesStrings.Name);

      const element = ApMapInstance.machinePopup.getHTMLElement();

      if (ApMapInstance.machinePopup.name !== undefined) {
        ApMapInstance.machinePopup.show();
        ApLocationLayer.popup.setElement(element);
      } else {
        ApMapInstance.machinePopup.hide();
      }
    } else {
      ApMapInstance.machinePopup.hide();
    }
  }

  /**
   * select a location by Id from location layer
   */
  selectLocation(id: string): void {
    this.selectFeature(id, ApLocationLayer.iconStyle);
  }

  /**
   * read the Data into Openlayers Layer
   */
  private _read(data: ILocationModel[]): OlFeature[] {
    const fl = [];

    const geom = new OlFormatGeoJSON({
      dataProjection: ApLocationLayer.projection,
      featureProjection: MAP_PROJECTION,
    });
    for (const d of data) {
      const m = moment(d.GpsDate + ' ' + d.GpsTime, 'YYYY-MM-DD HH:mm:ss');
      const offset = m.utcOffset();
      const datetime = m.add(offset, 'minutes');
      const date = datetime ? datetime.format(this.dateTimeFormat) : this.unknown;

      const feat = geom.readFeature({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [d.Longitude, d.Latitude],
          },
          properties: {
            name: d.Name,
            kind: d.Kind,
            speed: d.Speed,
            date,
            layer_name: LayerNames.LOCATIONS,
            sign: d.Sign
          }
        }
      );
      feat.setId(d.Id.toString());
      feat.setStyle(ApLocationLayer.iconStyle);
      fl.push(feat);
    }
    return fl;
  }
}

import {IStateStore}                                                           from '../../ap-interface';
import {Store}                                                                 from '../index';
import {AgriportConstantsLoad, AgriportConstantsLoadSuccess} from 'invoker-transport';
import {Injectable}                                                            from '@angular/core';
import IAgriportConstants = Data.Common.IAgriportConstants;
import {
  ApSignalrService
}                                                                              from '../../ap-core/services/ap-signalr.service';

interface IAgriportConstantsStore extends IStateStore<IAgriportConstants> {
  constants: { [key: string]: string };
}

@Injectable({providedIn: 'root'})
export class AgriportConstantsStore extends Store<IAgriportConstantsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loading: false,
      loaded: false,
      data: [],
      constants: {}
    });

    this.backend.registerObservable(AgriportConstantsLoadSuccess).subscribe((d) => {
      super.Mutate(s => s.constants, () => {
        const result = {};
        d.Data.forEach((entry) => {
          result[entry['Key']] = entry['Value'];
        });
        return result;
      });
    });
  }

  public LoadAgriportConstants(): void {
    this.DispatchBackend(new AgriportConstantsLoad([]));
  }
}

import {Component} from '@angular/core';

const CONTENT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.69 196.37">
  <g>
    <path d="M157.39,67.12h9.32a9.59,9.59,0,0,1,8.86,5.53,8.75,8.75,0,0,1-1.9,9.89l-32.61,32.22a10,10,0,0,1-13.91,0L94.54,82.54a8.75,8.75,0,0,1-1.9-9.89,9.59,9.59,0,0,1,8.86-5.53h9.32V14.45a9.4,9.4,0,0,1,9.63-9.12h27.31a9.4,9.4,0,0,1,9.63,9.12Z" transform="translate(-23.65 0)"/>
    <path d="M42,51.67H33a9.34,9.34,0,0,1-6.74-15.8l31.61-33a9.35,9.35,0,0,1,13.48,0l31.62,33a9.34,9.34,0,0,1-6.74,15.8h-9v53.94A9.35,9.35,0,0,1,77.84,115H51.37A9.34,9.34,0,0,1,42,105.61ZM74.67,170.32a7.33,7.33,0,1,1-7.33,7.33,7.33,7.33,0,0,1,7.33-7.33m53.22,5.33a6.22,6.22,0,1,1-6.22,6.22,6.21,6.21,0,0,1,6.22-6.22m0-8.28a14.5,14.5,0,1,1-14.5,14.5,14.49,14.49,0,0,1,14.5-14.5M82.32,127.28H93.51a3.32,3.32,0,0,1,3.24,2.44l3.84,13.57a3.36,3.36,0,0,1-3.4,4.28l-2.53-.13a36.14,36.14,0,0,0-14.77-5.64l-.84-.39L79,130.67a3.36,3.36,0,0,1,3.37-3.39m37.48,24.84h.92a.41.41,0,0,1,.35.19.43.43,0,0,1,.07.38l-2.15,7a.46.46,0,0,1-.42.31h-.92a.42.42,0,0,1-.35-.18.42.42,0,0,1-.07-.4l2.15-6.95a.44.44,0,0,1,.42-.31m3.46,0h.93a.43.43,0,0,1,.35.19.4.4,0,0,1,.06.38l-2.15,7a.44.44,0,0,1-.42.31h-.92a.45.45,0,0,1-.35-.18.4.4,0,0,1-.06-.39l2.15-7a.43.43,0,0,1,.41-.31m3.47,0h.92a.44.44,0,0,1,.36.19.42.42,0,0,1,.06.38l-2.15,7a.44.44,0,0,1-.42.31h-.92a.45.45,0,0,1-.35-.18.46.46,0,0,1-.07-.39l2.15-7a.45.45,0,0,1,.42-.31m-24.67-25,5.16,20.12,24.29,2.3a5.42,5.42,0,0,1,4.92,5.42v9.49h-34.2a30.54,30.54,0,0,0-36.31-16.11l4.58-21.22h-3v-2.56a3.12,3.12,0,0,1,3.11-3.1h31a3.11,3.11,0,0,1,3.11,3.1v2.56ZM95.4,184.46h15.08a17,17,0,0,1-.19-2.59,17.57,17.57,0,0,1,7.62-14.5H103.42a30,30,0,0,1,1.14,4,6.47,6.47,0,0,1-6.34,7.79H96.43a21.86,21.86,0,0,1-1,5.28M74.67,150.22A27.44,27.44,0,0,1,101.52,172a3.37,3.37,0,0,1-3.3,4.05H96.43a21.82,21.82,0,0,0-37.14-13.91,2.61,2.61,0,0,1-.46-.42l-1-1.16a3.35,3.35,0,0,1,.49-4.94,27.28,27.28,0,0,1,16.38-5.43m63.26,6.52h.23a2.71,2.71,0,0,1,2.71,2.71v4.14a.91.91,0,0,1-.9.91h-2Zm-63.26,2.2A18.72,18.72,0,1,1,56,177.65a18.71,18.71,0,0,1,18.72-18.71" transform="translate(-23.65 0)"/>
    <path d="M48.42,105.61V48.22a3,3,0,0,0-2.94-2.95H33a2.93,2.93,0,0,1-2.12-5l31.61-33a2.94,2.94,0,0,1,4.25,0l31.61,33a2.94,2.94,0,0,1-2.12,5H83.73a3,3,0,0,0-2.94,2.95v57.39a3,3,0,0,1-3,3H51.36a3,3,0,0,1-2.94-3" transform="translate(-23.65 0)" class="hoverFill"/>
    <path d="M150.74,13.31V70.7a3,3,0,0,0,2.95,3h12.49a2.94,2.94,0,0,1,2.12,5l-31.61,33a2.94,2.94,0,0,1-4.25,0l-31.61-33a2.94,2.94,0,0,1,2.12-5h12.49a3,3,0,0,0,2.94-3V13.31a3,3,0,0,1,3-3h26.48a2.94,2.94,0,0,1,2.93,3" transform="translate(-23.65 0)" class="hoverFill"/>
    <path d="M120.47,152.26h1.42a.83.83,0,0,1,.54.17.29.29,0,0,1,.1.38l-3.31,6.66a.71.71,0,0,1-.65.29h-1.42a.83.83,0,0,1-.54-.16.3.3,0,0,1-.1-.38l3.31-6.66a.72.72,0,0,1,.65-.3" transform="translate(-23.65 0)"/>
    <path d="M123.71,152.35h1.41a.79.79,0,0,1,.55.17.28.28,0,0,1,.1.37l-3.31,6.67a.74.74,0,0,1-.65.29h-1.42a.83.83,0,0,1-.54-.17.28.28,0,0,1-.1-.37l3.31-6.67a.71.71,0,0,1,.65-.29" transform="translate(-23.65 0)"/>
    <path d="M126.94,152.43h1.42a.78.78,0,0,1,.54.18.28.28,0,0,1,.1.37l-3.31,6.66a.72.72,0,0,1-.65.3h-1.42a.83.83,0,0,1-.54-.17.3.3,0,0,1-.1-.38l3.31-6.66a.72.72,0,0,1,.65-.3" transform="translate(-23.65 0)"/>
    <path d="M74.55,184.61a6.9,6.9,0,1,0-6.9-6.9,6.9,6.9,0,0,0,6.9,6.9" transform="translate(-23.65 0)"/>
    <path d="M127.86,187.2a5.53,5.53,0,1,0-5.52-5.52,5.52,5.52,0,0,0,5.52,5.52" transform="translate(-23.65 0)"/>
    <path d="M82.33,127.47H93.86A3.42,3.42,0,0,1,97.19,130l4,14a3.46,3.46,0,0,1-3.5,4.4L95,148.24a37.4,37.4,0,0,0-15.21-5.81L79,142,78.87,131a3.47,3.47,0,0,1,3.46-3.5" transform="translate(-23.65 0)"/>
  </g>
</svg>
`;

@Component({
  selector: 'ap-icon-tasks',
  template: CONTENT
})
export class ApIconTasksComponent {
}

import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType,
  ApDynGridColumnHideConfig,
  IPipeDefinition
}                       from './ap-dyn-grid-column-config-base';
import {Observable, of} from 'rxjs';
import {EventEmitter}   from '@angular/core';

export interface IStateChange {
  item: any;
  state: number;
}

export class ApDynGridStatusSwitchColumnConfig extends ApDynGridColumnConfigBase {
  public type = ApDynGridColumnControlType.StatusSwitch;
  public disableGreen: Observable<boolean>;
  public disableGreenFn: (x) => boolean;
  public disableRed: Observable<boolean>;
  public disableRedFn: (x) => boolean;
  public disableYellow: Observable<boolean>;
  public disableYellowFn: (x) => boolean;
  public stateChange: EventEmitter<IStateChange>;
  public title: string;
  public readonly: boolean;
  public showYellowState: boolean;
  public toolTipGreen: string;
  public toolTipRed: string;
  public filterable: boolean;

  constructor(options: {
    hide?: boolean | ApDynGridColumnHideConfig,
    sortIndex?: number,
    sortDesc?: boolean,
    groupIndex?: number,
    groupDesc?: boolean,
    headerStyle?: any,
    style?: any,
    pdfExport?: boolean,
    xlsExport?: boolean,
    disableGreen?: boolean | Observable<boolean> | ((x) => boolean),
    disableRed?: boolean | Observable<boolean> | ((x) => boolean),
    disableYellow?: boolean | Observable<boolean> | ((x) => boolean),
    field?: string,
    stateChange?: EventEmitter<{ item: any, state: number }>,
    title?: string,
    readonly?: boolean,
    showYellowState?: boolean,
    toolTipGreen?: string,
    toolTipRed?: string,
    cssClass?: 'left' | 'right',
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
    filterable?: boolean,
    defaultFilter?: any
  } = {}) {
    super(options);
    if (options.disableGreen) {
      if (options.disableGreen instanceof Function) {
        this.disableGreenFn = options.disableGreen;
      } else if (options.disableGreen instanceof Observable) {
        this.disableGreen = options.disableGreen;
      } else {
        this.disableGreen = of(options.disableGreen);
      }
    }

    if (options.disableRed) {
      if (options.disableRed instanceof Function) {
        this.disableRedFn = options.disableRed;
      } else if (options.disableRed instanceof Observable) {
        this.disableRed = options.disableRed;
      } else {
        this.disableRed = of(options.disableRed);
      }
    }

    if (options.disableYellow) {
      if (options.disableYellow instanceof Function) {
        this.disableYellowFn = options.disableYellow;
      } else if (options.disableYellow instanceof Observable) {
        this.disableYellow = options.disableYellow;
      } else {
        this.disableYellow = of(options.disableYellow);
      }
    }

    this.stateChange = options.stateChange;
    this.title = options.title ? options.title : ' ';
    this.readonly = options.readonly;
    this.showYellowState = options.showYellowState !== undefined ? options.showYellowState : true;
    this.toolTipGreen = options.toolTipGreen ? options.toolTipGreen : ' ';
    this.toolTipRed = options.toolTipRed ? options.toolTipRed : ' ';
    this.filterable = options.filterable === false ? false : true; // default is true and can explicitly set to false in component
  }
}

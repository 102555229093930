export class ApDefined {
  public static async waitUntilDefinedAsync<T extends object>(
    object: T,
    functionName: keyof T,
    maxWaitTime: number = 2000,
    interval: number = 100
  ): Promise<void> {
    const startTime = Date.now();

    while (typeof object[functionName] !== 'function') {
      if (Date.now() - startTime > maxWaitTime) {
        throw new Error(`Timeout: Function ${String(functionName)} not defined within ${maxWaitTime}ms`);
      }
      await new Promise(resolve => setTimeout(resolve, interval));
    }
  }

  public static waitUntilDefined<T extends object>(
    object: T,
    functionName: keyof T,
    callback: (error?: Error) => void,
    maxWaitTime: number = 2000,
    interval: number = 100
  ): void {
    const startTime = Date.now();

    const checkFunction = () => {
      if (typeof object[functionName] === 'function') {
        callback();
      } else if (Date.now() - startTime > maxWaitTime) {
        throw new Error(`Timeout: Function ${String(functionName)} not defined within ${maxWaitTime}ms`);
      } else {
        setTimeout(checkFunction, interval);
      }
    };

    checkFunction();
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum UploadActions {
  UPLOAD_SEND = '[upload] send',
  UPLOAD_SEND_SUCCESS = '[upload] send success',
  UPLOAD_VERIFY = '[upload] verify',
  UPLOAD_LOG_VERIFY = '[upload] log verify',
  UPLOAD_VERIFY_SUCCESS = '[upload] verify success',
}

/**
 * send data to backend
 */
export class UploadSend implements IAction {
  readonly type = UploadActions.UPLOAD_SEND;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * send Verify to backend
 */
export class UploadVerify implements IAction {
  readonly type = UploadActions.UPLOAD_VERIFY;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * send Verify to backend success
 */
export class UploadVerifySuccess implements IAction {
  readonly type = UploadActions.UPLOAD_VERIFY_SUCCESS;

  constructor(public payload: any) {
  }
}

/**
 * send Verify to backend
 */
export class UploadLogVerify implements IAction {
  readonly type = UploadActions.UPLOAD_LOG_VERIFY;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {NgModule}                                              from '@angular/core';
import {ApLayoutComponent}                                     from './components/ap-layout.component';
import {LayoutModule}                                          from '@progress/kendo-angular-layout';
import {CommonModule}                                          from '@angular/common';
import {FormsModule}                                           from '@angular/forms';
import {ButtonModule, DropDownButtonModule, SplitButtonModule} from '@progress/kendo-angular-buttons';
import {ApIconModule}                                          from '../icons/ap-icon.module';
import {ToolBarModule}                                         from '@progress/kendo-angular-toolbar';
import {ApToolbarLogoComponent}                                from './components/ap-toolbar-logo.component';
import {ApMenuComponent}                                       from './components/ap-menu.component';
import {ApTranslationModule}                                   from '../translation/ap-translation.module';
import {ApCoreModule}                                          from '../ap-core/ap-core.module';
import {ApUserProfileComponent}                                from './components/ap-user-profile.component';
import {ApFarmSelectionComponent}                              from './components/ap-farm-selection.component';
import {ApHarvestSelectionComponent}                           from './components/ap-harvest-selection.component';
import {DropDownsModule}                                       from '@progress/kendo-angular-dropdowns';
import {ApLayoutRoutes}                                        from './ap-layout.routes';
import {ApPageNotFoundComponent}                               from './components/ap-page-not-found.component';
import {ApCurrentTimeComponent}                                from './components/ap-current-time.component';
import {ApMapModule}                                           from '../map/ap-map.module';
import {ApHelpModule}                                          from '../help/ap-help.module';
import {ApTooltipModule}                                       from '../ap-tooltip/ap-tooltip.module';
import {ApMetricsModule}                                       from '../modules/metrics/ap-metrics.module';
import {NgScrollbarModule}                                     from 'ngx-scrollbar';
import {ApUtilsModule}                                         from '../ap-utils';
import {FilterByStringPipe}                                    from './pipes/ap-menu/filter-by-string.pipe';
import {IsMainExpandedPipe}                                    from './pipes/ap-menu/is-main-expanded.pipe';
import {IsMainSelectedPipe}                                    from './pipes/ap-menu/is-main-selected.pipe';
import {AgriportStoreModule}                                   from '../stores/stores.module';
import {ApNotificationComponent}                               from './components/ap-notification.component';
import {PopupModule}                                           from '@progress/kendo-angular-popup';
import {BodyModule, GridModule, SharedModule}                  from '@progress/kendo-angular-grid';
import {ApResponsiveTextModule}                                from '../ap-responsive-text/ap-responsive-text.module';
import {RemoveUrlParamsPipe}                                   from './pipes/ap-menu/remove-url-params.pipe';
import {TooltipModule}                                         from '@progress/kendo-angular-tooltip';
import {InnerLoadingComponent}                                 from './components/inner.loading.component';
import {ApTaskListComponent}                                   from './components/ap-task-list.component';
import {ApTabStripComponent}                                   from './components/ap-tabstrip.component';
import {ApPermissionModule}                                    from '../ap-permission/ap-permission.module';
import {ApStatisticsModule}                                    from '../statistics/ap-statistics.module';
import {LoaderModule}                                          from '@progress/kendo-angular-indicators';

@NgModule({
  declarations: [
    ApLayoutComponent,
    ApToolbarLogoComponent,
    ApMenuComponent,
    ApPageNotFoundComponent,
    ApUserProfileComponent,
    ApFarmSelectionComponent,
    ApHarvestSelectionComponent,
    ApCurrentTimeComponent,
    FilterByStringPipe,
    IsMainExpandedPipe,
    IsMainSelectedPipe,
    RemoveUrlParamsPipe,
    IsMainSelectedPipe,
    ApNotificationComponent,
    InnerLoadingComponent,
    ApTaskListComponent,
    ApTabStripComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    ButtonModule,
    ToolBarModule,
    DropDownButtonModule,
    SplitButtonModule,
    DropDownsModule,
    ApMapModule,
    ApHelpModule,
    ApIconModule,
    ApTranslationModule,
    ApCoreModule,
    ApMetricsModule,
    ApMapModule,
    ApHelpModule,
    ApLayoutRoutes,
    ApTooltipModule,
    ApUtilsModule,
    NgScrollbarModule,
    AgriportStoreModule,
    PopupModule,
    GridModule,
    SharedModule,
    ApResponsiveTextModule,
    BodyModule,
    TooltipModule,
    ApPermissionModule,
    ApStatisticsModule,
    LoaderModule,
  ],
  exports: [
    ApLayoutComponent,
    ApTabStripComponent
  ],
  providers: [
    FilterByStringPipe,
  ]
})
export class ApLayoutModule {
}

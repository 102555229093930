import {Pipe, PipeTransform} from '@angular/core';
import {UserGroupsUserStore} from '../settings/user.group.user.store';
import {TranslationStore}    from '../translation/translation.store';
import {ObjectFactory}       from 'ts-tooling';
import IUsergroup = Data.AccessPermission.IUsergroup;

@Pipe({
  name: 'getAssignedUserGroups'
})
export class ApGetAssignedUserGroupsPipe implements PipeTransform {
  public listUserGroupsUserItems: Array<{ text: string, value: number }> = [];

  constructor(private userGroupsUserStore: UserGroupsUserStore,
              private translationStore: TranslationStore) {
  }

  public transform(): Array<{ text: string, value: number }> {
    this.listUserGroupsUserItems = [];
    const userGroups: IUsergroup[] = this.userGroupsUserStore.getUserGroupsUser();
    let copiedUserGroups = ObjectFactory.Copy(userGroups);
    copiedUserGroups = copiedUserGroups.sort((a, b) => a.Group_Id !== b.Group_Id ? a.Group_Id < b.Group_Id ? -1 : 1 : 0);
    if (copiedUserGroups && copiedUserGroups.length > 0) {
      for (const userGroup of copiedUserGroups) {
        this.listUserGroupsUserItems.push({
          text: this.translationStore.FindTranslationForSelectedLanguage(userGroup.Group?.Key),
          value: userGroup.Id
        });
      }
    }
    return this.listUserGroupsUserItems;
  }
}

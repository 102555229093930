import {Injectable}             from '@angular/core';
import {ServiceInjector}        from '../../ap-utils/injector';
import {AgriportConstantsStore} from '../../stores/common/agriport-constants.store';

@Injectable({
  providedIn: 'root'
})
export class AgriportConstantsService {

  public static GetConstant(key: string): string {
    return ServiceInjector.instance.get(AgriportConstantsService).GetConstant(key);
  }

  constructor(private store: AgriportConstantsStore) {
  }

  public GetConstant(key: string): string {
    return this.store.Listen((s) => s.constants).getValue()[key];
  }
}

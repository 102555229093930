import {IStateStore} from '../../ap-interface';
import {Injectable}  from '@angular/core';
import {Store}       from '../index';
import IEffectiveRange = Data.BaseData.IEffectiveRange;
import {EffectiveRangesLoad, EffectiveRangesLoadSuccess} from 'invoker-transport';
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

@Injectable({providedIn: 'root'})
export class EffectiveRangesStore extends Store<IStateStore<IEffectiveRange>> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(EffectiveRangesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadEffectiveRanges(): void {
    this.DispatchBackend(new EffectiveRangesLoad([]));
  }

  public getEffectiveRangeByKey(key: string): IEffectiveRange {
    return super.Listen(s => s.data).getValue().find(x => x.Key === key);
  }

  public getEffectiveRangeById(id: string): IEffectiveRange {
    return super.Listen(s => s.data).getValue().find(x => x.Id === id);
  }
}

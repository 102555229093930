import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable}                                                       from '@angular/core';
import {Observable}                                                       from 'rxjs';
import {ApMenuUrls}                                                       from '../ap-interface';
import {MenuStore}                                                        from '../stores/layout/menu.store';
import {NotifyStore}                                                      from '../stores/dialog/notify.store';
import {
  TranslationStore
}                                                                         from '../stores/translation/translation.store';
import {
  RemoveUrlParamsService
}                                                                         from '../services/router/remove-url-params.service';
import IMenuEntry = Data.MenuStructure.IMenuEntry;
import IApValidationResult = Data.Api.Validation.IApValidationResult;

@Injectable({providedIn: 'root'})
export class ApPermissionGuard implements CanActivate {
  private menuEntries: IMenuEntry[];

  constructor(private _router: Router,
              private menuStore: MenuStore,
              private removeUrlParams: RemoveUrlParamsService,
              private translationPipe: TranslationStore,
              private notifyStore: NotifyStore) {
    this.menuStore.Listen(s => s.data).subscribe(d => {
      if (!d) {
        return;
      }
      this.menuEntries = d;
    });
  }

  public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.menuEntries === undefined || !this.menuEntries) {
      return true;
    }

    const url = this.removeUrlParams.removeUrlParams(state.url.Split('?')[0]);
    let result = false;
    for (const entry of this.menuEntries) {
      if (entry.Url === url) {
        result = entry.Enabled;
      }
      if (!Array.isArray(entry.Childs)) {
        continue;
      }
      for (const children of entry.Childs) {
        if (children.Url === url) {
          result = children.Enabled;
        }
      }
    }

    if (!result) {
      const s = {} as IApValidationResult;
      s.DisplayTimeout = 5;
      s.ErrorKey = this.translationPipe.FindTranslationForSelectedLanguage('Global_No_Access_Right_Available');
      s.Level = Data.Api.Validation.ApValidationLevel.Info;
      this.notifyStore.addMessage(s);
      if (url !== ApMenuUrls.FIELD_MANAGEMENT_OVERVIEW) {
        this._router.navigate([ApMenuUrls.FIELD_MANAGEMENT_OVERVIEW]).then(() => {
        });
      }
    }
    return result;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import {ApGridPagerType, IGridPagerData, IWizardChoiceData, IWizardChoiceDataValue} from '../../../ap-interface';
import {map}                                                                        from 'lodash';
import {animate, state, style, transition, trigger}                                 from '@angular/animations';
import {
  ApDynformsConfigFieldset
}                                                                                   from '../../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsComponent
}                                                                                   from '../../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsConfigTabs
}                                 from '../../../ap-dynforms/config/ap-dynforms-config-tabs';
import {
  ItemDisabledFn
}                                 from '@progress/kendo-angular-dropdowns';
import {
  TranslationStore
}                                 from '../../../stores/translation/translation.store';
import {Observable, Subscription} from 'rxjs';
import {
  ApUtilService
}                                 from '../../../ap-utils/service/ap-util.service';
import {NumberFormatOptions}      from '@progress/kendo-angular-intl';
import {ApDynformsValidator}      from '../../../ap-dynforms/ap-dynforms-validator';
import {ValidatorFn}              from '@angular/forms';

@Component({
  selector: 'ap-grid-multi-select',
  templateUrl: './ap-grid-multi-select.component.html',
  styleUrls: ['./ap-grid-multi-select.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({opacity: 1, transform: 'scale(1)'})),
      transition(':enter', [
        style({opacity: 0, height: '0px', transform: 'scale(1)'}),
        animate('444ms cubic-bezier(.8, -0.6, 0.2, 2.5)')
      ]),
      transition(':leave',
        animate('556ms cubic-bezier(.8, -0.6, 0.2, 2.5)', style({
          opacity: 0,
          transform: 'scale(1)',
          height: '0px',
          'margin-top': '0px',
          'margin-left': '0px',
          'margin-right': '0px',
          'margin-bottom': '0px'
        })))
    ])
  ]
})
export class ApGridMultiSelectComponent implements OnInit, OnDestroy, OnChanges {

  constructor(private translationStore: TranslationStore) {
  }

  public gridPagerTypes = ApGridPagerType;
  public placeholder: string;

  @ViewChild('extendedInput', {static: false}) extendedInput: ApDynformsComponent;

  @Input() public selectedKeys: any[];
  @Input() public columns: IWizardChoiceData[];
  @Input() public showOnlyValues = false;
  @Input() public showWithoutSelection = false;
  @Input() public type: ApGridPagerType;
  @Input() public selectedColumn: IWizardChoiceData;
  @Input() public selectedValue: IWizardChoiceDataValue;
  @Input() public values: IWizardChoiceDataValue[];
  @Input() public formConfig: ApDynformsConfigFieldset[] | ApDynformsConfigTabs[] = null;
  @Input() public formValidators: ApDynformsValidator<ValidatorFn>[];
  @Input() public formDefaultValue: any;
  /**
   * Columns shown in the select box
   */
  @Input() public objects: Observable<Array<IWizardChoiceData>>;
  @Input() public preselect = true;

  @Output() public applyClicked = new EventEmitter<IGridPagerData>();
  @Output() public deleteClicked = new EventEmitter();
  @Output() public startInAnimation = new EventEmitter();
  @Output() public endInAnimation = new EventEmitter();
  @Output() public startOutAnimation = new EventEmitter();
  @Output() public endOutAnimation = new EventEmitter();
  @Output() public selectedColumnChange = new EventEmitter<IWizardChoiceData>(true);
  @Output() public selectedValueChange = new EventEmitter<IWizardChoiceDataValue>(true);
  @Output() public extendedInputFinish = new EventEmitter<void>(true);

  fieldSets: ApDynformsConfigFieldset[] = [];
  tabs: ApDynformsConfigTabs[] = null;
  private _subscriptions: Subscription[] = [];

  @Output() public formValueChanges = new EventEmitter<{ control: string, value: any }>(true);

  public decimalFormat: NumberFormatOptions = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    minimumIntegerDigits: 0
  };

  public defaultItem: { name: string; id: number } = {
    name: this.translationStore.FindTranslationForSelectedLanguage('Global__MassEdit'),
    id: null,
  };
  @Input() public columnItemDisabled: ItemDisabledFn = () => false;
  @Input() public valueItemDisabled: ItemDisabledFn = () => false;

  ngOnInit(): void {
    if (this.objects) {
      this.objects = ApUtilService.asObservable(this.objects);
      this._subscriptions.push(this.objects.subscribe((objects) => {
          this.columns = map(objects, f => ({
            ...f,
            name: this.translationStore.FindTranslationForSelectedLanguage(f.name)
          }));
          if (objects?.length > 1 && this.preselect) {
            this.selectedColumnChanged(this.objects[0]);
          }
        }
      ));
    }
    if (this.showOnlyValues) {
      if (this.values) {
        this.selectedValue = this.values.find(v => !!v);
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formConfig']) {
      if (this.formConfig && this.formConfig.length) {
        const first = this.formConfig[0];
        if (first instanceof ApDynformsConfigTabs) {
          this.tabs = this.formConfig as ApDynformsConfigTabs[];
        } else if (first instanceof ApDynformsConfigFieldset) {
          this.fieldSets = this.formConfig as ApDynformsConfigFieldset[];
        }
      } else {
        this.tabs = null;
        this.fieldSets = [];
      }
    }
    if (changes.selectedKeys) {
      if (!changes.selectedKeys.currentValue || changes.selectedKeys.currentValue.length < 1 && !this.preselect) {
        this.selectedColumnChanged(null);
      }
    }
    if (changes.selectedItems) {
      if (!changes.selectedItems.currentValue || changes.selectedItems.currentValue.length < 1 && !this.preselect) {
        this.selectedColumnChanged(null);
      }
    }
    if (changes.selectedItems) {
      if (!changes.selectedItems.currentValue || changes.selectedItems.currentValue.length < 1 && !this.preselect) {
        this.selectedColumnChanged(null);
      }
    }
  }

  /**
   * on column dropdown value changed
   */
  selectedValueChanged($event): void {
    this.selectedValue = $event;
    this.selectedValueChange.emit($event);
  }

  /**
   * on column dropdown value changed
   */
  selectedColumnChanged($event: IWizardChoiceData): void {
    this.type = $event?.type ?? null;
    this.values = $event?.values ?? null;
    const value = this.values && $event.value !== undefined ? this.values.find(v => v.id === $event.value) : null;
    this.selectedValue = value ?? null;
    this.selectedColumnChange.emit($event);
    this.placeholder = $event?.placeholder;
    if (this.type !== ApGridPagerType.DropDown) {
      this.selectedValueChange.emit(null);
    }
  }

  isApplyButtonEnabled(): boolean {
    const formValid = !this.extendedInput || this.extendedInput.form.valid;
    if (this.type === ApGridPagerType.None) {
      return formValid;
    } else {
      return this.selectedValue != null && this.selectedColumn != null && formValid;
    }
  }

  extractValueFromInputEvent($event: any): void {
    return $event.target.value;
  }

  /**
   * on apply button clicked emit the event.
   */
  applyButtonClicked(): void {
    const param: IGridPagerData = {
      object: this.selectedColumn,
      value: this.selectedValue
    };
    if (this.extendedInput) {
      param.form = this.extendedInput.form.value;
    }
    this.applyClicked.emit(param);
    this.selectedValue = null;
    this.unselect();
  }

  onFadeInOutStart(e): void {
    if (e && e.fromState === 'void') {
      this.startInAnimation.emit();
    } else if (e.toState === 'void') {
      this.startOutAnimation.emit();
    }
  }

  onFadeInOutDone(e): void {
    if (e && e.fromState === 'void') {
      this.endInAnimation.emit();
    } else if (e.toState === 'void') {
      this.endOutAnimation.emit();
    }
  }

  unselect(): void {
    this.type = null;
    this.selectedColumnChange.emit(null);
    this.selectedValueChange.emit(null);
  }

  getErrors(): string[] {
    // TodO Keine Validierung
    // const control = this.form.get(this.config.key);
    // if (control && control.errors) {
    //   each(this.config.validators, (validator: ApDynformsValidator<ValidatorFn>) => {
    //     if ((validator.always || control.touched || control.dirty) && control.errors[validator.errorKey]) {
    //       validationMessages.push(validator.errorKey);
    //     }
    //   });
    //
    //   each(this.config.asyncValidators, (validator: ApDynformsValidator<AsyncValidatorFn>) => {
    //     if ((validator.always || control.touched || control.dirty) && control.errors[validator.errorKey]) {
    //       validationMessages.push(validator.errorKey);
    //     }
    //   });
    // }
    //
    // if (this.form.errors) {
    //   each(this.config.formErrors, (formError: string) => {
    //     if (this.form.errors[formError]) {
    //       validationMessages.push(formError);
    //     }
    //   });
    // }

    return [];
  }
}

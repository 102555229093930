<kendo-panelbar [class]="'burger-menu-item'" [expandMode]="0">
  <kendo-panelbar-item [id]="'NFertilization.Logfiles'" [title]="''">

    <ng-template kendoPanelBarItemTitle>

      <ng-container
        *ngTemplateOutlet="selectionHeader;context:{id:'NFertilization.Logfiles',title:'Logfiles',group:logFileForm,control:'enabled'}">
      </ng-container>

    </ng-template>

    <kendo-panelbar-item [class]="'sub-item'" [id]="'NFertilization.Logfiles.Point'" [title]="''">
      <ng-template kendoPanelBarItemTitle>
        <ng-container
          *ngTemplateOutlet="selectionHeader;context:{id:'NFertilization.Logfiles.Point',title:'Punkt',group:logFileForm,control:'pointEnabled'}">
        </ng-container>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <ng-container
          *ngTemplateOutlet="selectionBody;context:{id:'NFertilization.Logfiles.Point',group:logFileForm,control:'point'}">
        </ng-container>
      </ng-template>
    </kendo-panelbar-item>

    <ng-template kendoPanelBarContent>
      <ng-container
        *ngTemplateOutlet="selectionBody;context:{id:'NFertilization.Logfiles',group:logFileForm,control:'area'}">
      </ng-container>
    </ng-template>
  </kendo-panelbar-item>

  <kendo-panelbar-item [id]="'NFertilization.Schlagübersicht'" [title]="''">
    <ng-template kendoPanelBarItemTitle>

      <ng-container
        *ngTemplateOutlet="selectionHeader;context:{id:'NFertilization.Schlagübersicht',title:'Schlagübersicht',group:fieldForm,control:'enabled'}">
      </ng-container>

    </ng-template>

    <kendo-panelbar-item [class]="'sub-item'" [id]="'NFertilization.Schlagübersicht.Point'" [title]="''">
      <ng-template kendoPanelBarItemTitle>
        <ng-container
          *ngTemplateOutlet="selectionHeader;context:{id:'NFertilization.Schlagübersicht.Point',title:'Punkt',group:fieldForm,control:'pointEnabled'}">
        </ng-container>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <ng-container
          *ngTemplateOutlet="selectionBody;context:{id:'NFertilization.Schlagübersicht.Point',group:fieldForm,control:'point'}">
        </ng-container>
      </ng-template>
    </kendo-panelbar-item>

    <ng-template kendoPanelBarContent>

      <ng-container
        *ngTemplateOutlet="selectionBody;context:{id:'NFertilization.Schlagübersicht',group:fieldForm,control:'area'}">
      </ng-container>

    </ng-template>

  </kendo-panelbar-item>

  <kendo-panelbar-item [title]="''">
    <ng-template kendoPanelBarItemTitle>

      <ng-container
        *ngTemplateOutlet="selectionHeader;context:{id:'NFertilization.Summenübersicht',title:'Summenübersicht',group:sumForm,control:'enabled'}">
      </ng-container>

    </ng-template>

  </kendo-panelbar-item>
</kendo-panelbar>

<ng-template #selectionHeader let-id="id" let-title="title" let-group="group" let-control="control">
  <input type="checkbox"
         [id]="id + '.Header'"
         [checked]="false"
         [formControl]="getFormControl(group, control)"
         [class]="'k-checkbox'"/>
  <label [attr.for]="id + '.Header'"
         [class]="'k-checkbox-label'">
  </label>
  <ap-responsive-text [key]="title"></ap-responsive-text>
</ng-template>

<ng-template #selectionBody let-id="id" let-group="group" let-control="control">
  <div [class]="'panel-content'">
    <form [formGroup]="group">
      <div>
        <input type="radio"
               [id]="id + '.NAufnahme'"
               [formControl]="getFormControl(group, control)"
               [name]="control"
               [value]="'NAufnahme'"
               (click)="remove(getFormControl(group, control), 'NAufnahme')"
               kendoRadioButton/>
        <label [attr.for]="id + '.NAufnahme'"
               [class]="'k-radio-label'">
          <ap-responsive-text [key]="'N-Aufnahme'"></ap-responsive-text>
        </label>
        <input type="radio"
               [id]="id + '.NEmpfehlung'"
               [formControl]="getFormControl(group, control)"
               [name]="control"
               [value]="'NEmpfehlung'"
               (click)="remove(getFormControl(group, control), 'NEmpfehlung')"
               kendoRadioButton/>
        <label [attr.for]="id + '.NEmpfehlung'"
               [class]="'k-radio-label'">
          <ap-responsive-text [key]="'N-Empfehlung'"></ap-responsive-text>
        </label>
      </div>
    </form>
  </div>
</ng-template>

import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {ApDynformsValidator}                         from '../ap-dynforms-validator';
import {AsyncValidatorFn, ValidatorFn}               from '@angular/forms';
import {Observable}                                  from 'rxjs';

export enum ApDynformsConfigTextboxType {
  Password = 'password',
  Text = 'text',
  Hidden = 'hidden',
  Url = 'url',
  Number = 'number',
  Email = 'email'
}

/**
 * configuration of textbox
 */
export class ApDynformsConfigTextbox extends ApDynformsConfigBase<string> {
  controlType = ApDynformsControltype.Textbox;
  type: string;
  autocomplete: string;

  constructor(options: {
    value?: any,
    value$?: Observable<any>,
    key?: string,
    label?: string,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    listenUpdate?: Observable<any>[],
    type?: ApDynformsConfigTextboxType,
    dependsOn?: string[],
    formErrors?: string[],
    cssClass?: string,
    autocomplete?: string
  } = {}) {
    super(options);
    this.type = options.type || ApDynformsConfigTextboxType.Text;
    this.autocomplete = options.autocomplete;
  }
}

import {Pipe, PipeTransform}           from '@angular/core';
import {ApDynGridPropertyColumnConfig} from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';

@Pipe({
  name: 'apCustomFormatPipe'
})
/**
 * Pipe handles custom formatting within kendo grid cells.
 * Like this the pure values remains the original type (e.g. number)
 * but the displayed text can be customized (e.g. '>5,00' or '5,00 kG')
 * Using this format pipe makes sure special filtering or sorting (numbers, dates etc.)
 * still works as expected.
 */
export class ApCustomFormatPipe implements PipeTransform {
  transform(value: any, gridColumn: ApDynGridPropertyColumnConfig, dataItem: any): string {
    if (!gridColumn?.formatCallback) {
      return value;
    }
    return gridColumn?.formatCallback(dataItem, value);
  }
}

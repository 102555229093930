import {Injectable}                                              from '@angular/core';
import {Store}                                                   from '../index';
import {IStateStore}                                             from '../../ap-interface';
import {JobsChange, JobsLoadSuccess, NetTypes} from 'invoker-transport';
import {
  JobsAllFarmLoad,
  JobsAllFarmLoadSuccess,
  JobsFarmLoad,
  JobsFarmLoadSuccess
}                                                                from '../../../../projects/invoker-transport/src/lib/actions/administration';
import {DateTime, SafeBehaviorSubject}                           from 'ts-tooling';
import {debounceTime, distinct, map}                             from 'rxjs/operators';
import {FieldStore}                                              from '../farm/field.store';
import {Observable}                                              from 'rxjs';
import {
  SoilSampleFieldStore
}                                                                from '../evaluation/soilsample.field.store';
import IJobs = Data.Job.IJobs;
import IJobsChanges = Data.Job.IJobsChanges;
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import JobStatus = Data.Job.JobStatus;
import IField = Data.FieldManagement.IField;
import ISoilSampleField = Data.Nutrients.ISoilSampleField;
import {ApMapInstance}                                           from '../../ap-map';
import {ApSignalrService}                                        from '../../ap-core/services/ap-signalr.service';

export const BU_JOB_NAME = 'REFRESH_BU_RASTER';
export const WSV_JOB_NAME = 'REFRESH_WSV_RASTER';
export const CE_JOB_NAME = 'REFRESH_CE_RASTER';
export const IR_JOB_NAME = 'REFRESH_IR_RASTER';
export const RB_JOB_NAME = 'RB_RASTER';
export const FIELD_RASTER_JOB_NAME = 'FIELD_RASTER';
export const N_PLANNING_JOB_NAME = 'N_PLANNING';
export const N_PLANNING_RASTER_JOB_NAME = 'N_PLANNING_RASTER';
export const N_TRANSFORM_JOB_NAME = 'N_TRANSFORM';

const JOB_DEBOUNCE_MS = 100;
const BU_WSV_JOB_NAMES = [BU_JOB_NAME, WSV_JOB_NAME];
const N_PLANNING_JOB_NAMES = [N_PLANNING_JOB_NAME, N_PLANNING_RASTER_JOB_NAME, N_TRANSFORM_JOB_NAME];
const BU_WSV_CE_IR_RUNNING_JOB_NAMES = [BU_JOB_NAME, WSV_JOB_NAME, CE_JOB_NAME, IR_JOB_NAME];
const RB_REFRESH_JOBS = [RB_JOB_NAME, FIELD_RASTER_JOB_NAME, WSV_JOB_NAME, CE_JOB_NAME, IR_JOB_NAME, BU_JOB_NAME];

const ALL_RUNNING_JOBS = (farmJobs: IFieldJobs) => farmJobs.job.Status === JobStatus.Pending || farmJobs.job.Status === JobStatus.Running;
const BU_RUNNING_JOBS = (farmJobs: IFieldJobs) => ALL_RUNNING_JOBS(farmJobs) && farmJobs.job.JobType.Name === BU_JOB_NAME;
const BU_WSV_RUNNING_JOBS = (farmJobs: IFieldJobs) => ALL_RUNNING_JOBS(farmJobs) && BU_WSV_JOB_NAMES.Contains(farmJobs.job.JobType.Name);
const CE_RUNNING_JOBS = (farmJobs: IFieldJobs) => ALL_RUNNING_JOBS(farmJobs) && farmJobs.job.JobType.Name === CE_JOB_NAME;
const BU_WSV_CE_IR_RUNNING_JOBS = (farmJobs: IFieldJobs) => ALL_RUNNING_JOBS(farmJobs) && BU_WSV_CE_IR_RUNNING_JOB_NAMES.Contains(farmJobs.job.JobType.Name);
const RB_RUNNING_JOBS = (farmJobs: IFieldJobs) => ALL_RUNNING_JOBS(farmJobs) && RB_JOB_NAME === farmJobs.job.JobType.Name;
const FIELD_RASTER_RUNNING_JOBS = (farmJobs: IFieldJobs) => ALL_RUNNING_JOBS(farmJobs) && FIELD_RASTER_JOB_NAME === farmJobs.job.JobType.Name;

export interface IJobsStoreState extends IStateStore<IJobs> {
  farmJobs: IJobs[];
  allFarmJobs: IJobs[];
  farmJobsLoaded: boolean;
  farmJobsLoading: boolean;
}

function getFieldIds(farmJobs: IFieldJobs, t: string[]): string[] {
  for (const field of farmJobs.fields) {
    if (!field || !field.Id) {
      continue;
    }
    const fieldId = field.Id.toString();
    if (t.Contains(fieldId)) {
      continue;
    }
    t = t.Add(fieldId);
  }
  return t;
}

function getSoilSampleFieldIds(farmJobs: IFieldJobs, t: number[]): number[] {
  for (const soilSampleField of farmJobs.soilSampleFields) {
    const soilSampleFieldId = soilSampleField.Id;
    if (t.Contains(soilSampleFieldId)) {
      continue;
    }
    t = t.Add(soilSampleFieldId);
  }
  return t;
}

@Injectable({providedIn: 'root'})
export class JobsStore extends Store<IJobsStoreState> {
  constructor(public backend: ApSignalrService,
              private fieldStore: FieldStore,
              private soilSampleFieldStore: SoilSampleFieldStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      farmJobs: [],
      allFarmJobs: [],
      farmJobsLoaded: false,
      farmJobsLoading: false,
    });

    backend.registerObservable(JobsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(JobsFarmLoadSuccess).subscribe(d => {
      super.Mutate(s => s.farmJobsLoading, () => false);
      super.Mutate(s => s.farmJobsLoaded, () => true);
      super.Mutate(s => s.farmJobs, () => d.Data);
    });

    backend.registerObservable(JobsAllFarmLoadSuccess).subscribe(d => {
      super.Mutate(s => s.farmJobsLoading, () => false);
      super.Mutate(s => s.farmJobsLoaded, () => true);
      super.Mutate(s => s.allFarmJobs, () => d.Data);
    });

    this.subscribeJobChanges();
  }

  public get AllJobs$(): SafeBehaviorSubject<IJobs[]> {
    return this.Listen(s => s.data);
  }

  public get AllJobs(): IJobs[] {
    return this.AllJobs$.getValue();
  }

  public get FarmJobs$(): Observable<IJobs[]> {
    return this.Listen(s => s.farmJobs).pipe(distinct());
  }

  public get FarmJobs(): IJobs[] {
    return this.Listen(s => s.farmJobs).getValue();
  }

  public BlockedFieldIdsBuWsvCeIrRbFieldRaster$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithFields(jobs, this.fieldStore).Reduce((t, farmJobs) => {
      if (!BU_WSV_CE_IR_RUNNING_JOBS(farmJobs) &&
          !RB_RUNNING_JOBS(farmJobs) &&
          !FIELD_RASTER_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return getFieldIds(farmJobs, t);
    }, [] as string[])));

  public BlockedFieldIdsBuWsvCeIrRbFieldRasterTooltip$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithFields(jobs, this.fieldStore).Reduce((t, farmJobs) => {
      if (t) {
        return t;
      }
      if (!BU_WSV_CE_IR_RUNNING_JOBS(farmJobs) &&
          !RB_RUNNING_JOBS(farmJobs) &&
          !FIELD_RASTER_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return farmJobs.job.Description;
    }, '')));

  public BlockedFieldIdsCe$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithFields(jobs, this.fieldStore).Reduce((t, farmJobs) => {
      if (!CE_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return getFieldIds(farmJobs, t);
    }, [] as string[])));

  public BlockedFieldIdsCeTooltip$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithFields(jobs, this.fieldStore).Reduce((t, farmJobs) => {
      if (t) {
        return t;
      }
      if (!CE_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return farmJobs.job.Description;
    }, '')));

  public BlockedFieldIdsBuWsv$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithFields(jobs, this.fieldStore).Reduce((t, farmJobs) => {
      if (!BU_WSV_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return getFieldIds(farmJobs, t);
    }, [] as string[])));

  public BlockedFieldIdsBuWsvTooltip$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithFields(jobs, this.fieldStore).Reduce((t, farmJobs) => {
      if (t) {
        return t;
      }
      if (!BU_WSV_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return farmJobs.job.Description;
    }, '')));

  public BlockedSoilSampleFieldIdsBu$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithSoilSampleFields(jobs, this.soilSampleFieldStore).Reduce((t, farmJobs) => {
      if (!BU_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return getSoilSampleFieldIds(farmJobs, t);
    }, [] as number[])));

  public BlockedSoilSampleFieldIdsBuTooltip$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => connectJobWithSoilSampleFields(jobs, this.soilSampleFieldStore).Reduce((t, farmJobs) => {
      if (t) {
        return t;
      }
      if (!BU_RUNNING_JOBS(farmJobs)) {
        return t;
      }
      return farmJobs.job.Description;
    }, '')));

  public FinishedNFertJobs$ = this.FarmJobs$.pipe(
    debounceTime(JOB_DEBOUNCE_MS),
    map(jobs => jobs.map(job => {
      return job.Status === JobStatus.Finished && N_PLANNING_JOB_NAMES.Contains(job.JobType.Name);
    }))
  );

  public loadFarmJobs(farmId: number, lastDays: number): void {
    super.Mutate(s => s.farmJobsLoaded, () => false);
    super.Mutate(s => s.farmJobsLoading, () => true);
    super.Mutate(s => s.farmJobs, () => []);
    this.DispatchBackend(new JobsFarmLoad([
      {Name: 'farmId', Type: NetTypes.INT, Value: farmId},
      {Name: 'lastDays', Type: NetTypes.INT, Value: lastDays},
    ]));
  }

  public loadAllFarmJobs(farmId: number, lastDays: number): void {
    super.Mutate(s => s.farmJobsLoaded, () => false);
    super.Mutate(s => s.farmJobsLoading, () => true);
    super.Mutate(s => s.allFarmJobs, () => []);
    this.DispatchBackend(new JobsAllFarmLoad([
      {Name: 'farmId', Type: NetTypes.INT, Value: farmId},
      {Name: 'lastDays', Type: NetTypes.INT, Value: lastDays},
    ]));
  }

  private subscribeJobChanges(): void {
    this.backend.registerObservable(JobsChange).subscribe(res => {
      const ch = res.Data as IJobsChanges;
      const existJob = this.AllJobs.FindIndex(j => j.Id === ch.JobId);
      const existsFarmJob = this.FarmJobs.FindIndex(j => j.Id === ch.JobId);
      if (ch.Operation === DatabaseNotifyOperation.Delete) {
        if (existJob >= 0) {
          this.Mutate(s => s.data, o => o.RemoveAt(existJob));
        }
        if (existsFarmJob >= 0) {
          this.Mutate(s => s.farmJobs, o => o.RemoveAt(existJob));
        }
        return;
      }
      if (existJob < 0) {
        this.Mutate(s => s.data, o => o.Add(ch.Job));
      } else {
        this.Mutate(s => s.data, o => o.Replace(j => j.Id === ch.Job.Id, ch.Job));
      }
      if (existsFarmJob < 0) {
        this.Mutate(s => s.farmJobs, o => o.Add(ch.Job));
      } else {
        this.Mutate(s => s.farmJobs, o => {
          const idx = o.FindIndex(j => j.Id === ch.Job.Id);
          if (idx < 0) {
            return o;
          }
          o[idx] = ch.Job;
          return o;
        });
      }

      // Force update of need-layer (RB1, RB2,...) after jobs which calculated RB have finished.
      // e.g.: after saving a new planning, modify a plan, delete a plan, run field_raster job, ...
      if (ch?.Operation === DatabaseNotifyOperation.Update &&
        ch?.Job?.Status === JobStatus.Finished && RB_REFRESH_JOBS.Contains(ch?.Job?.JobType?.Name)) {
        ApMapInstance.mapStore?.Layers?.NeedLayer?.forceUpdateDebounced();
      }
    });
  }
}

export interface IFieldJobs {
  soilSampleFields: ISoilSampleField[];
  fields: IField[];
  job: IJobs;
}

export function connectJobWithSoilSampleFields(jobs: IJobs[], soilSampleFieldStore: SoilSampleFieldStore): IFieldJobs[] {
  return jobs.Reduce((t, job) => {
    const res = {
      soilSampleFields: [],
      fields: [],
      job,
    } as IFieldJobs;
    if (job.JobType.Name !== BU_JOB_NAME) {
      return t;
    }
    if (!job.Input.StartsWith('{')) {
      return t;
    }
    let buInput = {} as { SampleFieldIds: number[] };
    try {
      buInput = JSON.parse(job.Input);
    } catch (e) {
      console.warn(`can't parse JSON buInput ${job.Input} for Job ${job.Id}`);
      return t;
    }
    if (!Array.isArray(buInput.SampleFieldIds)) {
      console.warn(`can't find SampleFieldIds in Job Input for Job ${job.Id}`);
      return t;
    }
    for (const soilSampleFieldId of buInput.SampleFieldIds) {
      const sampleField = soilSampleFieldStore.SoilSampleField.Find(sf => sf?.Id === soilSampleFieldId);
      if (!sampleField) {
        continue;
      }
      res.soilSampleFields = res.soilSampleFields.Add(sampleField);
    }
    t = t.Add(res);
    return t;
  }, [] as IFieldJobs[]);
}

let _jobs: IJobs[] = [];
let _jobResult = null;

export function connectJobWithFields(jobs: IJobs[], fieldStore: FieldStore): IFieldJobs[] {
  if (JSON.stringify(_jobs) !== JSON.stringify(jobs) || _jobResult === null) {
    _jobs = jobs;
    if (!jobs.Any()) {
      _jobResult = [];
    } else {
      // group Jobs By State and sort by creation time
      const groupedJobs = jobs
        .GroupBy(_ => _.Status);
      for (const jobState in groupedJobs) {
        if (!groupedJobs.hasOwnProperty(jobState)) {
          continue;
        }
        groupedJobs[jobState] = groupedJobs[jobState].sort((a, b) => {
          const aCreatedAt = DateTime.FromISOString(a.CreatedAt.toString());
          const bCreatedAt = DateTime.FromISOString(b.CreatedAt.toString());
          return aCreatedAt.Equals(bCreatedAt) ? 0 : aCreatedAt.IsBefore(bCreatedAt) ? 1 : -1;
        });
      }
      _jobResult = [
        ...(groupedJobs[JobStatus.Running] ?? []),
        ...(groupedJobs[JobStatus.Pending] ?? []),
        ...(groupedJobs[JobStatus.Finished] ?? []),
        ...(groupedJobs[JobStatus.Error] ?? []),
      ].Reduce((t, job) => {
        const res = {
          soilSampleFields: [],
          fields: [],
          job,
        } as IFieldJobs;

        switch (job.JobType.Name) {
          case 'REFRESH_BU_RASTER':
            if (!job.Input.StartsWith('{')) {
              return t;
            }
            let buInput = {} as { FieldGeomIds: string[] };
            try {
              buInput = JSON.parse(job.Input);
            } catch (e) {
              console.warn(`can't parse JSON buInput ${job.Input} for Job ${job.Id}`);
              return t;
            }
            if (!Array.isArray(buInput.FieldGeomIds)) {
              console.warn(`can't find FieldGeomIds in Job Input for Job ${job.Id}`);
              return t;
            }
            for (const fieldGeomId of buInput.FieldGeomIds) {
              const foundField = fieldStore.getFieldByFieldGeomId(fieldGeomId);
              if (!foundField) {
                continue;
              }
              res.fields = res.fields.Add(foundField);
            }
            break;
          case 'FIELD_RASTER':
          case 'RB_RASTER':
          case 'REFRESH_CE_RASTER':
          case 'REFRESH_WSV_RASTER':
            if (!job.Input?.StartsWith('{')) {
              return t;
            }
            let input = {} as { FieldGeomId: string };
            try {
              input = JSON.parse(job.Input);
            } catch (e) {
              console.warn(`can't parse JSON input ${job.Input} for Job ${job.Id}`);
              return t;
            }
            if (!input.FieldGeomId) {
              console.warn(`can't find FieldGeomId in Job Input for Job ${job.Id}`);
              return t;
            }
            const field = fieldStore.getFieldByFieldGeomId(input.FieldGeomId);
            res.fields = res.fields.Add(field);
            break;
          default:
            return t;
        }

        t = t.Add(res);
        return t;
      }, [] as IFieldJobs[]);
    }
  }
  return _jobResult;
}

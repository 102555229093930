import {Guid} from 'ts-tooling';

export class ApGuidUtil {
  public static getEmptyGuid(): string {
    return Guid.Empty.ToString();
  }
  public static generateNewGuid(): string {
    return new Guid().ToString();
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getBoolean'
})
export class GetBooleanPipe implements PipeTransform {

  transform(value: any, args?: any): boolean {
    return value === true;
  }
}

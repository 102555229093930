import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ILayerControlLayer}                     from '../../../ap-interface/map/layer-control-layer.interface';
import {SelectAllCheckboxState}                 from '@progress/kendo-angular-grid';

export interface ISelectionEvent {
  id: string;
  selected: boolean;
}

export interface ILayerPanelBarItem {
  group: string;
  layers: ILayerControlLayer[];
}

@Component({
  selector: 'layer-panel-bar-item',
  template: `
    <div class="ap-layer-control-item" [style]="{padding:padding}">
      <input type="checkbox"
             kendoCheckBox
             [id]="id"
             [indeterminate]="visibility === 'indeterminate'"
             [checked]="visibility === 'checked'"
             (change)="select()"/>
      <p>{{translationKeys[id] | translate}}</p>
    </div>
  `,
  styles: [
    `
      div.ap-layer-control-item {
        display: flex;
        cursor: pointer;
      }

      div.ap-layer-control-item > p {
        padding: 0 0 0 5px;
        margin: 0;
      }

      div.ap-layer-control-item > input {
        margin-top: 1px;
      }
    `,
  ],
})
export class LayerPanelBarItemComponent {
  @Input()
  public id = '';
  @Input()
  public visibility: SelectAllCheckboxState = 'unchecked';
  @Input()
  public translationKeys: { [key: string]: string } = {};
  @Input()
  public padding = '0';

  @Output()
  public visibilityChanged = new EventEmitter<ISelectionEvent>();

  public select(): void {
    this.visibility = this.visibility === 'checked' ? 'unchecked' : this.visibility === 'indeterminate' ? 'unchecked' : 'checked';
    this.visibilityChanged.emit({id: this.id, selected: this.visibility === 'checked'});
  }
}

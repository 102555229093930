import {
  CompositeFilterDescriptor,
  DataResult,
  filterBy,
  FilterDescriptor,
  GroupDescriptor,
  process,
  SortDescriptor,
  State
}                                                                      from '@progress/kendo-data-query';
import {ExcelExportData}                                               from '@progress/kendo-angular-excel-export';
import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType
}                                                                      from '../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import * as moment                                                    from 'moment';
import {BehaviorSubject}                                  from 'rxjs';
import {IWizardChoiceData, IWizardChoiceDataValue, IWizardProperties} from '../../../ap-interface';
import {DataStateChangeEvent, PageChangeEvent, SelectAllCheckboxState} from '@progress/kendo-angular-grid';
import {APP_CONFIGURATION}                                             from '../../config';
import {
  SortSettings
}                                                                      from '@progress/kendo-angular-grid/dist/es2015/columns/sort-settings';
import {
  ApDynGridGroupColumnConfig
}                                                                      from '../../../ap-dyngrids/config/ap-dyn-grid-group-column-config';
import {
  ApDynGridPropertyColumnConfig,
  PropertyTemplate
}                                                                      from '../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {ObjectFactory}                                                 from 'ts-tooling';
import {
  GetRoundNumericService
}                                                                      from '../../../ap-utils/service/get-round-numeric.service';
import {ApDateService}                                                 from '../../services/ap-date-service';
import IGuid = System.IGuid;

// TODO: implement in ts-tooling and replace it
function flatArray(arr: any[]): any[] {
  const tmp = [];
  for (const element of arr) {
    if (Array.isArray(element)) {
      tmp.AddRange(element);
      continue;
    }
    tmp.Add(element);
  }
  return tmp;
}

export type IdValue = number | string | IGuid;

export interface IApKendoGridDataSource {
  Id: IdValue;
}

/**
 * kendo grid extension
 */
export class ApKendoGridExtension<T extends IApKendoGridDataSource> {
  public sortByField = 'Id';
  public keyColumn = 'Id';

  public filterableColumns: string[] = [];

  public onSelectedChange = new BehaviorSubject<IdValue[]>([]);
  public onSelectedItemChange = new BehaviorSubject<T[]>([]);

  private readonly _sortBySelectionField = 'IsApSelectedInternal_dynGrid_';
  private readonly _sortBySelectionDescriptor: SortDescriptor[] = [{field: this._sortBySelectionField, dir: 'desc'}];
  public sortBySelection = false;

  /**
   * kendo grid Properties
   */
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public columns: ApDynGridColumnConfigBase[];
  public groups: GroupDescriptor[] = [];
  public filter: CompositeFilterDescriptor;
  public sort: SortDescriptor[] = [];
  public gridView: DataResult = {data: [], total: 0};
  public gridView$ = new BehaviorSubject<DataResult>(this.gridView);
  public items: T[] = [];
  public unfilteredItems: T[] = [];
  public pageSize = APP_CONFIGURATION.GridPageSize;
  public skip = 0;
  public selectedKeys: IdValue[] = [];
  public pageable = true;
  public reorderable = false;
  public sortable: SortSettings = {
    mode: 'single',
  };
  public moment = moment;

  public state: State = {
    skip: this.skip,
    take: this.pageSize,
    group: this.groups,
    sort: this.sort
  };

  constructor(private dateService: ApDateService,
              private roundNumericService: GetRoundNumericService,
              private sortSettings: SortSettings = null) {
    this.getAllDataForExcel = this.getAllDataForExcel.bind(this);
    this.getSelectedDataForExcel = this.getSelectedDataForExcel.bind(this);

    if (sortSettings != null) {
      this.sortable = sortSettings;
    }
  }

  /**
   * get selected items
   */
  public get selectedItems(): T[] {
    return this.onSelectedItemChange.getValue();
  }

  private static _extractGroups(columns: ApDynGridColumnConfigBase[]): ApDynGridColumnConfigBase[] {
    return columns.filter(c => c.type !== ApDynGridColumnControlType.ColumnGroup).concat(columns
      .filter(c => c.type === ApDynGridColumnControlType.ColumnGroup)
      .map(c => c as ApDynGridGroupColumnConfig)
      .reduce((agg, c) => agg.concat(ApKendoGridExtension._extractGroups(c.groupColumns)), []));
  }

  /**
   * init
   */
  public init(data: T[], columns: ApDynGridColumnConfigBase[], skip: number = this.skip, limit: number = this.pageSize): void {
    ApKendoGridExtension._extractGroups(this.columns)
      .filter((c) => c.type === ApDynGridColumnControlType.Property)
      .map((c) => c as ApDynGridPropertyColumnConfig)
      .filter((c) => c.template !== PropertyTemplate.NONE)
      .forEach((c) => {
        switch (c.template) {
          case PropertyTemplate.NUMBER:
            data = data.map((d) => {
              d = ObjectFactory.Copy(d);
              const original: number = ObjectFactory.Get(d, c.field.Replace('Rounded', ''));
              ObjectFactory.Set(d, c.field, !isNaN(+original) ? this.roundNumericService.roundAsNumber(original) : original);
              return d;
            });
            break;
          case PropertyTemplate.DATE:
            data = data.map((d) => {
              d = ObjectFactory.Copy(d);
              const original: Date = ObjectFactory.Get(d, c.field.Replace('Midnight', ''));
              ObjectFactory.Set(d, c.field, this.dateService.getDateMidnight(original));
              return d;
            });
            break;
        }
      });

    this.setSelectedKeys([]);
    this.items = data;
    this.unfilteredItems = data;
    this.skip = skip;
    this.pageSize = limit;
    this.loadData();
  }

  public setDefaultFilter(columns: ApDynGridColumnConfigBase[]): void {
    const filters = columns.filter((c) => c['defaultFilter'] !== undefined);
    this.filter = {
      filters: filters.map((f) => {
        return {
          field: f.field,
          operator: f.defaultFilter === null ? 'isnull' : 'eq',
          value: f.defaultFilter
        };
      }),
      logic: 'and',
    };
  }

  public columnChange(columns: ApDynGridColumnConfigBase[]): void {
    this.filterableColumns = this._getFilterColumns(columns);
    this.setDefaultFilter(columns);
    const groups = this._getGroups(columns);
    if (!ObjectFactory.Equal(groups, this._getGroups())) {
      this.groups = groups;
    }
    const sort = this._getSort(columns);
    if (!ObjectFactory.Equal(sort, this._getSort())) {
      this.sort = sort;
    }
    this.columns = columns;
    this.loadData();
  }

  public getDescriptors(): { filter: CompositeFilterDescriptor, groups: GroupDescriptor[], sort: SortDescriptor[] } {
    return {
      filter: this.filter,
      groups: this.groups,
      sort: this.sort,
    };
  }

  public setDescriptors(descriptors: {
    filter: CompositeFilterDescriptor,
    groups: GroupDescriptor[],
    sort: SortDescriptor[]
  }): void {
    if (descriptors) {
      this.onFilterChange(descriptors.filter);
      this.groupChange(descriptors.groups);
      this.sortChange(descriptors.sort);
    }
  }

  public _getSort(columns?: ApDynGridColumnConfigBase[]): SortDescriptor[] {
    return this._getSortBySelectionDescriptor().concat(this._flatColumns(columns ? columns : this.columns)
      .filter(c => c.sortIndex !== undefined)
      .sort((a, b) => a.sortIndex - b.sortIndex)
      .map((prop: ApDynGridPropertyColumnConfig) => ({
        field: prop.field,
        dir: prop.sortDesc ? 'desc' : 'asc'
      })));
  }

  /**
   * group change event
   */
  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = this.groups.filter(x => groups.some(y => x.field === y.field && y.dir !== undefined));
    this.groups.forEach(x => x.dir = groups.find(y => x.field === y.field).dir);
    this.groups = this.groups.concat(groups.filter(y => !this.groups.some(x => x.field === y.field)));
    this.loadData();
  }

  public getSelectedObjectsKey(key: keyof T): any[] {
    return this.items
      .filter(item => this.selectedItems.map(sl => sl.Id).indexOf(item.Id) !== -1)
      .map(item => item[key]);
  }

  /**
   * sort change event
   */
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = this.sort.filter(x => sort.some(y => x.field === y.field && y.dir !== undefined));
    this.sort.forEach(x => x.dir = sort.find(y => x.field === y.field).dir);
    this.sort = this.sort.concat(sort.filter(y => !this.sort.some(x => x.field === y.field)));
    this.sort = this._getSortBySelectionDescriptor().concat(this.sort.filter(s => s.dir !== undefined));
    this.loadData();
  }

  /**
   * data state change event
   */
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadData();
  }

  /**
   * refresh the datasource
   */
  public refresh(): void {
    setTimeout(() => {
      this.loadData();
    }, 1);
  }

  /**
   * on page change change event
   */
  public pageChange($event: PageChangeEvent): void {
    this.skip = $event.skip;
    this.pageSize = $event.take;
    this.loadItems();
  }

  /**
   * on selected keys change event
   */
  public onSelectedKeysChange(keys: IdValue[]): void {
    this.onSelectedChange.next(keys);
    this.onSelectedItemChange.next(this.items.FindAll(i => this.selectedKeys.Contains(i[this.keyColumn])));
    this._setSelectAllState();
    this.updateGridView();
  }

  public setSelectedKeys(keys: any[], validateKeys = false): void {
    if (!keys) {
      keys = [];
    }
    const selectedItems = this.items.FindAll(i => keys?.Contains(i[this.keyColumn]));
    // incoming keys might not match with the grid items. This might be the case when
    // map-selection has been used but the fieldId is not present in the current grid
    // => selecting valid(found) grid items, only:
    if (validateKeys) {
      keys = selectedItems?.map(i => i[this.keyColumn]);
      // in case the selected keys remain same => skip the update to avoid:
      // - flickering (map)
      // - unintended 'selectionChange' events when in fact nothing changed
      if (this.selectedKeys && keys && JSON.stringify(this.selectedKeys) === JSON.stringify(keys)) {
        return;
      }
    }
    this.selectedKeys = keys;
    this.onSelectedChange.next(keys);
    this.onSelectedItemChange.next(selectedItems);
    this._setSelectAllState();
  }

  /**
   * on selected all change event
   */
  public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
    if (checkedState === 'checked') {
      this.selectAllState = 'checked';
      this.setSelectedKeys(process(this.items, {filter: this.filter}).data.map((item) => item.Id));
    } else {
      this.selectAllState = 'unchecked';
      this.setSelectedKeys([]);
    }
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.setSelectedKeys(process(this.selectedItems, {filter: this.filter}).data.map(i => i.Id));
    this.updateGridView();
  }

  public filterChange(filterText: string): void {
    this.skip = 0;
    const columnFilters: FilterDescriptor[] = [];
    for (const column of this.filterableColumns) {
      this._flatColumns(this.columns)
        .FindAll((c) => c.type === ApDynGridColumnControlType.Property)
        .FindAll((c: ApDynGridPropertyColumnConfig) => c.headerFilterable)
        .FindAll((c: ApDynGridPropertyColumnConfig) => c.field === column)
        .forEach((c: ApDynGridPropertyColumnConfig) => {
          columnFilters.push({
            field: column,
            operator: this._getOperator(c, filterText),
            value: filterText
          });
        });
    }

    this.items = filterBy(this.unfilteredItems, {
      logic: 'or',
      filters: columnFilters,
    } as CompositeFilterDescriptor);

    this.updateGridView();
    this._setSelectAllState();
  }

  public getAllDataForExcel(): ExcelExportData {
    return {
      data: process(this.items, {
        sort: this.sort,
      }).data
    };
  }

  public getSelectedDataForExcel(): ExcelExportData {
    let itemsForExport = this.selectedItems;
    if (!itemsForExport || itemsForExport?.length <= 0) {
      itemsForExport = this.items;
    }
    return {
      data: process(itemsForExport, {
        sort: this.sort,
      }).data
    };
  }

  isWizardChoiceDisabled(dataItem: IWizardChoiceData | IWizardChoiceDataValue): boolean {
    if (!dataItem) {
      return false;
    }
    if (dataItem.disabled instanceof Function) {
      return dataItem.disabled({
        selectedKeys: this.selectedKeys,
        selectedItems: this.selectedItems
      } as IWizardProperties);
    } else if (dataItem.disabled) {
      return dataItem.disabled;
    }
    return false;
  }

  private _flatColumns(columns: ApDynGridColumnConfigBase[]): ApDynGridColumnConfigBase[] {
    if (!columns) {
      return [];
    }
    return flatArray(columns.map(c => {
      if (c.type === ApDynGridColumnControlType.ColumnGroup) {
        return this._flatColumns((c as ApDynGridGroupColumnConfig).groupColumns);
      } else {
        return c;
      }
    }));
  }

  private _getFilterColumns(columns?: ApDynGridColumnConfigBase[]): string[] {
    return this._flatColumns(columns ? columns : this.columns)
      .filter(c => c.headerFilterable)
      .map((prop: ApDynGridPropertyColumnConfig) => prop.field);
  }

  private _getGroups(columns?: ApDynGridColumnConfigBase[]): GroupDescriptor[] {
    return this._flatColumns(columns ? columns : this.columns)
      .filter(c => c.groupIndex !== undefined)
      .sort((a, b) => a.groupIndex - b.groupIndex)
      .map((prop: ApDynGridPropertyColumnConfig) => ({
        field: prop.field,
        dir: prop.groupDesc ? 'desc' : 'asc'
      }));
  }

  /**
   * load data
   */
  private loadData(): void {
    if (this.items === null) {
      this.gridView = {
        data: null,
        total: null
      };
      this.gridView$.next(this.gridView);
      return;
    } else {
      this.updateGridView();
    }
  }

  private loadItems(): void {
    this.updateGridView();
  }

  public updateGridView(): void {
    if (!this.items) {
      this.gridView = {
        data: null,
        total: null
      };
      this.gridView$.next(this._copyGridView(this.gridView));
      return;
    }
    this.state.skip = this.skip;
    this.state.take = this.pageSize;
    this.state.group = this.groups;
    this.state.sort = this.sort;
    this.state.filter = this.filter;
    this.gridView = process(this.items.FindAll(i => !!i), this.state);
    this.gridView$.next(this._copyGridView(this.gridView));
  }

  /**
   * Safe method to copy a gridView.
   * There were rare cases where JSON.stringify (within ObjectFactory.Copy(...)) was raising an
   * 'Invalid string length' exception. The reason this might be too large arrays.
   * So instead of using ObjectFactory.Copy(this.gridView) we use this method to copy each data
   * element one after another.
   * @param sourceGridView
   * @private
   */
  private _copyGridView(sourceGridView: DataResult): DataResult {
    if (sourceGridView == null || sourceGridView.data?.length <= 0) {
      return {data: [], total: 0};
    }
    const gridViewCopy = {data: [], total: sourceGridView.total};
    gridViewCopy.data = new Array(sourceGridView.data.length);
    for (let i = 0; i < sourceGridView.data.length; i++) {
      gridViewCopy.data[i] = ObjectFactory.Copy(sourceGridView.data[i]);
    }
    return gridViewCopy;
  }

  private _getOperator(column: ApDynGridPropertyColumnConfig, filterText: string): (value: any) => boolean {
    const item = this.unfilteredItems.find((i) => ObjectFactory.Get(i, column.field));
    if (typeof ObjectFactory.Get(item, column.field) === typeof new Date()) {
      if (column && column.format) {
        return (value) => moment(value).format(column.format).includes(filterText.toLowerCase());
      }
    }
    return (value) => String(value).includes(filterText.toLowerCase());
  }

  private _setSelectAllState(): void {
    this._updateSelectionState();
    const data = process(this.items, {filter: this.filter}).data;
    if (data.length === 0 || this.selectedKeys.length === 0) {
      this.selectAllState = 'unchecked';
    } else if (data.length === this.selectedKeys.length) {
      this.selectAllState = 'checked';
    } else {
      this.selectAllState = 'indeterminate';
    }
  }

  private _updateSelectionState(): void {
    if (!this.sortBySelection) {
      return;
    }
    for (const item of this.items) {
      item[this._sortBySelectionField] = this.selectedKeys?.Contains(item[this.keyColumn]);
    }
  }

  private _getSortBySelectionDescriptor(): SortDescriptor[] {
    if (!this.sortBySelection) {
      return [];
    }
    return this._sortBySelectionDescriptor;
  }
}

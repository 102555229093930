import IAccessPermissions = Data.AccessPermission.IAccessPermissions;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum ApAccessPermissionsActions {
  LOAD_ACCESS_PERMISSIONS = '[accesspermissions] load',
  LOAD_ACCESS_PERMISSIONS_SUCCESS = '[accesspermissions] load success',
  LOAD_ACCESS_PERMISSIONS_FAIL = '[accesspermissions] load fail',
  INSERT_PERMISSIONS = '[access_permissions] insert',
  DELETE_PERMISSIONS = '[access_permissions] delete',
}

export class LoadAccessPermissions implements IAction {
  readonly type = ApAccessPermissionsActions.LOAD_ACCESS_PERMISSIONS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadAccessPermissionsSuccess implements IAction {
  readonly type = ApAccessPermissionsActions.LOAD_ACCESS_PERMISSIONS_SUCCESS;

  constructor(public payload: IAccessPermissions[]) {
  }
}

export class LoadAccessPermissionsFail implements IAction {
  readonly type = ApAccessPermissionsActions.LOAD_ACCESS_PERMISSIONS_FAIL;
}

export class InsertAccessPermissions implements IAction {
  readonly type = ApAccessPermissionsActions.INSERT_PERMISSIONS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DeleteAccessPermissions implements IAction {
  readonly type = ApAccessPermissionsActions.DELETE_PERMISSIONS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import { Injectable }   from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RemoveUrlParamsService {

  constructor() {
  }

  removeUrlParams(url: string): string {
    return url.Split('?')[0];
  }
}

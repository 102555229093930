import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.16 114.32">
  <g>
    <path d="M145.73,63.38h18.85V75.25H150.16v-.07Zm18.85-3.45H143.77L141.38,56V53.21l9.09-4.69.12-.05.15,0,.15,0h10a6.69,6.69,0,0,1,1.61.2,4.71,4.71,0,0,1,1.28.56,2.17,2.17,0,0,1,.64.59.82.82,0,0,1,.16.44ZM84.8,110.83a12.58,12.58,0,1,1-12.28,12.58A12.43,12.43,0,0,1,84.8,110.83m0-15.61c15.18,0,27.5,12.62,27.5,28.19S100,151.59,84.8,151.59,57.29,139,57.29,123.41,69.61,95.22,84.8,95.22M29.05,111.66l-6.54,2.44a.83.83,0,0,1-.79-.11.87.87,0,0,1-.36-.72v-3.73a.88.88,0,0,0-.86-.88H18.68a.86.86,0,0,1-.86-.87v-3.37a.86.86,0,0,1,.56-.82l11.53-4.3-.77,8.09a26.65,26.65,0,0,0-.09,4.27M9.69,102.39h3.73a.87.87,0,0,1,.86.87v13.43a.87.87,0,0,1-.86.88h-3Zm4.5-3.71H9.5L8.93,87.28l-6.58.09L2,132.17c0,.33.45.59,1,.59H4.31v0c0,.16.22.3.49.3h.09c.27,0,.49.13.49.29v1.14c0,.16.22.29.49.29H7.34c.27,0,.5-.13.5-.29v-1.14c0-.16.22-.29.49-.29h.09c.27,0,.49-.14.49-.3v0h1.27c.55,0,1-.26,1-.59l-.54-10.9H20.5l9.93-3.7a15.39,15.39,0,0,0,3.82,5.24c7.23,6.56,10.94,5.17,18.68,4.28a34.47,34.47,0,0,1-.2-3.68c0-18.15,14.36-32.87,32.07-32.87s32.06,14.72,32.06,32.87a32.39,32.39,0,0,1-.28,4.31c5.58.76,11,1.7,16.1,2.8a12.32,12.32,0,0,0,3.44.23v2.38h37.77v-17H187.5a1,1,0,0,0,.93-1.11l0-5a1,1,0,0,0-.91-1.11l-19.59,0V50.18A4.11,4.11,0,0,0,167.3,48a5.39,5.39,0,0,0-1.71-1.72,8,8,0,0,0-2.2-1,9.62,9.62,0,0,0-2.5-.33H151.1l-.55,0-.54.09a4.26,4.26,0,0,0-.54.16,3.74,3.74,0,0,0-.51.22l-9.17,4.73a4.09,4.09,0,0,0-.67.44,3.64,3.64,0,0,0-.54.57,3.17,3.17,0,0,0-.42.79,2.72,2.72,0,0,0-.14.89v.3a35.26,35.26,0,0,0-8.91-2.27v-3c-7.61-2.92-15.75-1.64-21.22,0v1.75c-26.1.12-60,4.51-73.13,10.9a2.34,2.34,0,0,0-1.31,1.91L30.56,92.63l-16,6-.06,0a.8.8,0,0,1-.34.07" transform="translate(-2.04 -44.94)"/>
    <path d="M29.14,107.39,33.45,62.5a2.45,2.45,0,0,1,1.31-1.9c25.13-10.71,98.17-15.53,106.05-5.4l4.75,7.74,4.61,12.3,1.51,41.18c.29,7.89-10.84,15.9-19.27,14.09-5.12-1.1-10.48-2-16-2.8a33.73,33.73,0,0,0,.27-4.29c0-18.09-14.31-32.75-32-32.75s-32,14.66-32,32.75a34.14,34.14,0,0,0,.2,3.67c-5.76.66,1-.48-4.37.55a15.53,15.53,0,0,1-14.31-4.83,19.86,19.86,0,0,1-5.11-15.42" transform="translate(-2.04 -44.94)"/>
    <path d="M108,48c5.45-1.64,13.56-2.91,21.14,0v2.89A141.11,141.11,0,0,0,108,49.64Z" transform="translate(-2.04 -44.94)"/>
    <path d="M197.54,119.14h-8.42a.67.67,0,0,1-.66-.55l-.09-.54a.68.68,0,0,0-.66-.56h-7.22a.66.66,0,0,0-.61.39.69.69,0,0,0,.09.73l4.46,5.48a.63.63,0,0,0,.51.25h12.6a.68.68,0,0,0,.66-.69v-3.83a.68.68,0,0,0-.66-.69" transform="translate(-2.04 -44.94)"/>
    <path d="M13.71,144.88c.88,0,1.6-.45,1.6-1s-.72-1-1.6-1-1.6.45-1.6,1,.72,1,1.6,1" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M13.71,145.41a1.6,1.6,0,1,0-1.6-1.59,1.6,1.6,0,0,0,1.6,1.59" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M13.71,149.67a1.6,1.6,0,1,0-1.6-1.59,1.6,1.6,0,0,0,1.6,1.59" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M85.7,136.58A12.78,12.78,0,1,0,72.92,123.8,12.78,12.78,0,0,0,85.7,136.58" transform="translate(-2.04 -44.94)"/>
    <path d="M18,145.41a1.6,1.6,0,1,0-1.6-1.59,1.6,1.6,0,0,0,1.6,1.59" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M9.24,145.41a1.6,1.6,0,1,0-1.6-1.59,1.59,1.59,0,0,0,1.6,1.59" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M18,149.67a1.6,1.6,0,1,0-1.6-1.59,1.6,1.6,0,0,0,1.6,1.59" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M22.44,149.67a1.6,1.6,0,1,0-1.6-1.59,1.6,1.6,0,0,0,1.6,1.59" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M9.24,149.67a1.6,1.6,0,1,0-1.6-1.59,1.59,1.59,0,0,0,1.6,1.59" transform="translate(-2.04 -44.94)" class="hoverFill"/>
    <path d="M4.76,149.76a1.6,1.6,0,1,0-1.59-1.6,1.6,1.6,0,0,0,1.59,1.6" transform="translate(-2.04 -44.94)" class="hoverFill"/>
  </g>
  <rect x="7.75" y="110.47" width="177.06" height="3.45"/>
  <g>
    <rect x="7.75" y="110.47" width="177.06" height="3.45"/>
  </g>
  <rect x="31" y="92.72" width="3.45" height="11.48"/>
  <rect x="31" y="92.72" width="3.45" height="11.48"/>
  <rect x="138.08" y="92.72" width="3.45" height="11.48"/>
  <rect x="138.09" y="92.72" width="3.45" height="11.48"/>
  <rect x="180.92" y="92.72" width="3.45" height="11.48"/>
  <rect x="180.92" y="92.72" width="3.45" height="11.48"/>
  <rect x="116.67" y="92.72" width="3.45" height="11.48"/>
  <rect x="116.67" y="92.72" width="3.45" height="11.48"/>
  <rect x="49.36" y="92.72" width="3.45" height="11.48"/>
  <rect x="49.36" y="92.72" width="3.45" height="11.48"/>
  <rect x="159.5" y="92.72" width="3.45" height="11.48"/>
  <rect x="159.5" y="92.72" width="3.45" height="11.48"/>
</svg>
`;

@Component({
  selector: 'ap-icon-plant-protection',
  template: CONTENT
})
export class ApIconPlantProtectionComponent {
}

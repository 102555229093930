<div [class.ap-pager]="true">
  <div class="ap-pagerCell">
    <div *ngIf="showSelectedKeys && data.selectedKeys && data.selectedKeys.length">
      {{data.selectedKeys.length}} {{'Global__Selected' | translate}}
      <button *ngIf="formConfig && formConfig.config.length"
              kendoButton
              [class]="'k-primary'"
              [style.height]="'18px'"
              [style.width]="'18px'"
              [style.padding]="'0'"
              [style.margin-left]="'4px'"
              (click)="toggleMassEdit()">
        <span [class]="'k-icon ap-small k-icon-8'"
              [class.k-i-pencil]="!enableMassEdit"
              [class.k-i-minus]="enableMassEdit"></span>
      </button>
    </div>
  </div>
  <div class="ap-pagerCell">
    <kendo-pager-prev-buttons *ngIf="data.items && data.items.length > data.pageSize"></kendo-pager-prev-buttons>
    <!--kendo-pager-numeric-buttons *ngIf="itemCount > pageSize" [buttonCount]="buttonCount"></kendo-pager-numeric-buttons-->
    <ap-grid-pager-numeric *ngIf="data.items && data.items.length > data.pageSize"
                           [pagerContextService]="pagerContextService"></ap-grid-pager-numeric>
    <kendo-pager-next-buttons *ngIf="data.items && data.items.length > data.pageSize"></kendo-pager-next-buttons>
  </div>
  <div class="ap-pagerCell">
    <ap-grid-page-size (pageSizeChanged)="onPageSizeChanged($event)"></ap-grid-page-size>
    <kendo-pager-info></kendo-pager-info>
  </div>
</div>
<div *ngIf="formConfig && formConfig.config.length">
  <ap-dynforms [fieldsets]="formConfig.config"
               [fontSize]="14"
               [offsetTop]="5"
               [offsetBottom]="5"
               [darkMode]="true"
               [formState]="data.selectedKeys && data.selectedKeys.length && enableMassEdit ? 'in' : 'out'">
    <div dynforms.after [class]="'ap-form-action-underline'">
      <button autofocus
              kendoButton
              data-cy="ap_pager_apply_button"
              [disabled]="submitDisabled$ | async"
              [class]="'ap-pagerElement k-button k-primary button-important'"
              [type]="'submit'"
              [primary]="true"
              (click)="onApplyClick()">{{'Global__TakeOver' | translate}}
      </button>
      <button [class]="'k-button k-primary'" (click)="cancelClick.emit()">
        <span [class]="'k-icon k-i-close'"></span>
      </button>
    </div>
  </ap-dynforms>
</div>
<div [style.margin-top]="'6px'">
  <ng-content></ng-content>
</div>

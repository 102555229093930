import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {ApDynformsValidator}                         from '../ap-dynforms-validator';
import {Observable}                                  from 'rxjs';
import {AsyncValidatorFn, ValidatorFn}               from '@angular/forms';
import {TimePickerIncrementalSteps}                  from '@progress/kendo-angular-dateinputs';
import {ApUtilService}                               from '../../ap-utils/service/ap-util.service';

/**
 * configuration for datepicker control
 */
export class ApDynformsConfigTimepicker extends ApDynformsConfigBase<Date> {
  controlType = ApDynformsControltype.Timepicker;
  format: string;
  minDate$: Observable<Date>;
  maxDate$: Observable<Date>;
  nowButton: boolean;
  steps?: TimePickerIncrementalSteps;

  constructor(options: {
    value?: Date,
    value$?: Observable<Date>,
    key?: string,
    label?: string,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    listenUpdate?: Observable<any>[],
    format?: string,
    hSteps?: number,
    minSteps?: number,
    secSteps?: number,
    minDate?: Date | Observable<Date>,
    maxDate?: Date | Observable<Date>,
    nowButton?: boolean,
    dependsOn?: string[],
    formErrors?: string[],
    cssClass?: string,
  } = {}) {
    super(options);
    this.format = options.format || '';
    this.nowButton = options.nowButton;
    this.minDate$ = ApUtilService.asObservable(options.minDate);
    this.maxDate$ = ApUtilService.asObservable(options.maxDate);
    this.dependsOn = options.dependsOn;
    this.steps = {
      hour: options.hSteps || 1,
      minute: options.minSteps || 1,
      second: options.secSteps || 1
    };
  }
}

/**
 * represent the supported chart types
 */
export enum ApStatsType {
  Boxplot = 'boxplot',
  DonutPie = 'donutpie',
  AreaBarLine = 'areabarline',
  MainSecondCrop = 'mainsecondcrop',
  DateTimeSelection = 'datetimeselection',
  Histogram = 'histogram',
  Nutrients = 'nutrient',
  Planning = 'planning',
  GenericBars = 'genericbars',
  BaseFertStats = 'basefertstats',
  PlanningWizardOverviewStats = 'planningwizardoverviewstats',
  NFertilizationLogfileStats = 'nfertilizationlogfilestats',
  NFertilizationPlanningWizardSelectionStats = 'nfertilizationplanningwizardselectionstats',
  NFertilizationNNeedStats = 'nfertilizationnneedstats',
  FieldsCropRotationStats = 'fieldscroprotationstats',
  DashboardStats = 'dashboardstats'
}

import {AfterContentInit, Component, ViewChild} from '@angular/core';
import {ApDynformsComponent}                    from '../../ap-dynforms/ap-dynforms.component';
import {ApDynformsConfigFieldset}               from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {FormBuilder, Validators}                from '@angular/forms';
import {filter, find, merge, remove}            from 'lodash';
import {ApDynformsConfigComboBox}               from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {ApDynformsValidator}          from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsConfigTextbox,
  ApDynformsConfigTextboxType
}                                     from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApGetAvailableUserGroupsPipe} from '../../stores/pipes/ap-get-available-usergroups.pipe';
import {DriverStore}                            from '../../stores/docu/driver.store';
import {InvitationStore}                        from '../../stores/login/invitation.store';
import {FormStore}                              from '../../stores/layout/form.store';
import {TranslationStore}                       from '../../stores/translation/translation.store';
import IDriver = Data.DocuContext.Driver.IDriver;

@Component({
  selector: 'ap-users-invitation-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_invitation_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_invitation_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`
})

export class ApUsersInvitationEditComponent implements AfterContentInit {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: any;
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;
  public listUsergroupItems: Array<{ text: string, value: number }> = [];
  public isNewMode = false;
  public drivers: IDriver[];
  public listDriverItems: Array<{ text: string, value: System.IGuid }> = [];

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private invitationStore: InvitationStore,
              private translationStore: TranslationStore,
              private driverStore: DriverStore,
              private getAvailableUserGroups: ApGetAvailableUserGroupsPipe) {
  }

  ngAfterContentInit(): void {
    this.caption = 'Global_User_Create';
    this.getDataFromStore();
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        Id: undefined,
        Usergroupid: this.listUsergroupItems.find(_ => _.text === this.translationStore.FindTranslationForSelectedLanguage('Permission_Group_Administrator'))?.value,
        Isdriver: false,
        Email: '',
        DriverId: undefined
      };
    }
    this.createDriverCheckbox();
    this.formBuilder();
    this.check();
  }

  getDataFromStore(): void {
    this.drivers = this.driverStore.Listen(s => s.data).getValue();
    this.listUsergroupItems = this.getAvailableUserGroups.transform();
  }

  createDriverCheckbox(): void {
    this.listDriverItems.push({
      value: null,
      text: '<' + this.translationStore.FindTranslationForSelectedLanguage('Global_No_Worker') + '>'
    });
    this.listDriverItems.push({
      value: -1,
      text: '<' + this.translationStore.FindTranslationForSelectedLanguage('Global_New_Worker') + '>'
    });

    this.listDriverItems = this.drivers.map(d => ({
      text: `${d.Name}, ${d.Firstname}`,
      value: d.Id
    })).sort((a, b) => a.text !== b.text ? a.text < b.text ? -1 : 1 : 0);
  }

  check(): void {
    setTimeout(() => {
      const check = filter(this.invitationStore.getInvitation(), _ => _.Usergroupid === this.data.Usergroupid && _.Email === this.data.Email);
      for (const inv of check) {
        if (inv.Email === this.data.Email && inv.Usergroupid === this.data.Usergroupid) {
          remove(this.listUsergroupItems, _ => _.value === inv.Usergroupid);
        }
      }
    }, 100);
  }

  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigComboBox({
            key: 'Usergroupid',
            valueField: 'value',
            textField: 'text',
            valuePrimitive: true,
            value: this.data.Usergroupid,
            label: 'Global_User_Group',
            disabled: true,
            options: this.listUsergroupItems,
            /*validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
                always: true
              }),
            ]*/
          }),
          new ApDynformsConfigTextbox({
            key: 'Email',
            label: 'Global__Email',
            type: ApDynformsConfigTextboxType.Email,
            value: this.data.Email,
            disabled: !this.isNewMode,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
                always: true
              }),
              new ApDynformsValidator({
                validator: Validators.email,
                errorKey: 'Admin_Pages_Login_Reset_Password_Email_Error'
              }),
              new ApDynformsValidator({
                validator: Validators.nullValidator,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(1),
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.pattern('^[-_.\\w]+@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.){1,300}[a-zA-Z]{2,9}$'),
                errorKey: 'Admin_Pages_Login_Reset_Password_Email_Error'
              })
            ]
          }),
          new ApDynformsConfigComboBox({
            key: 'Driver',
            valueField: 'value',
            textField: 'text',
            valuePrimitive: true,
            value: this.data.DriverId || null,
            label: 'Global__Worker',
            options: this.listDriverItems,
          })
        ]
      })
    ];
  }

  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    const driver = this.dynForm.form.get('Driver').value;
    let isDriver = false;
    if (driver === -1) {
      isDriver = true;
    }
    const found = !!find(this.drivers, _ => _.Id === driver);
    if (found) {
      isDriver = true;
    }
    if (this.isNewMode) {
      const inv = {
        Email: this.data.Email,
        UserGroupId: this.data.Usergroupid,
        IsDriver: isDriver,
        DriverId: (isDriver === true && found === true) ? find(this.drivers, _ => _.Id === driver).Id : null
      };
      this.invitationStore.create(inv);
    } else {
      const inv = {
        Id: this.data.Id,
        UserGroupId: this.data.Usergroupid,
        IsDriver: isDriver,
        DriverId: (isDriver === true && found === true) ? find(this.drivers, _ => _.Id === driver).Id : null
      };
      this.invitationStore.update(inv);
    }
    this.formStore.closeForm();
  }

  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

import {AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApDynformsConfigFieldset}                                                 from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsConfigComboBox}                from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {ApDynformsValidator}                     from '../../ap-dynforms/ap-dynforms-validator';
import {Validators}                              from '@angular/forms';
import {ApDynformsComponent}                                      from '../../ap-dynforms/ap-dynforms.component';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {IApPermissionData}                                        from '../../ap-interface';
import {ApPermissions}                                            from 'invoker-transport';
import {SettingsStore}                           from '../../stores/base-data/settings.store';
import {TranslationStore}                        from '../../stores/translation/translation.store';
import {MapViewStore}  from '../../stores/layout/mapview.store';
import {map}   from 'rxjs/operators';
import {ObjectFactory} from 'ts-tooling';
import {FarmStore}                                                from '../../stores/farm/farm.store';
import {NotifyStore}                                              from '../../stores/dialog/notify.store';
import {ApDynformsConfigTextbox} from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import ISettings = Data.BaseData.ISettings;
import IFarm = Data.Authentication.IFarm;
import {CountryStore}                                             from '../../stores/common/country.store';
import ICountry = Data.Common.ICountry;

interface ISettingsSaveState {
  SettingsSaved: boolean | undefined;
  RegistryNumberSaved: boolean | undefined;
  CountrySaved: boolean | undefined;
}

type ElementOxyde = {
  Text: string;
  Value: number;
};

type DigitItem = {
  Value: number;
};

interface SettingsData {
  FarmName: string;
  CustomerNumber: string;
  Street: string;
  Street2: string;
  PostalCodePlusCity: string;
  State: string;
  RegistryNumber: string;
  DigitsAfterDecimalPoint: number;
  FarmTime: string;
  UnitElementOxyd: number;
  Country: ICountry;
}

@Component({
  selector: 'ap-settings-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [loading$]="loading$" [headerIcon]="'ap-icon-settings'">
      <div class="ap-form-actions" dynforms.action>
        <button data-cy="setting_general_page_button_submit"
                [disabled]="(dynForm.validChanges | async) !== true || (ApPermissions.EDIT_SETTINGS | getPermission | async) !== true || (this.disabledButton.asObservable() | async)"
                class="k-button k-primary button-important ap-form-button-right"
                autofocus
                type="submit" kendoButton [primary]="true"
                (click)="onSubmit()">
          {{ 'Global__Save' | translate }}
        </button>
      </div>
    </ap-dynforms>`
})
export class ApSettingsEditComponent implements OnInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public loading$: Observable<boolean>;
  public disabledButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;
  public ApPermissions = ApPermissions;
  public apPermissionData: IApPermissionData;

  // use behaviour subject to be able to bind observables to the dynform
  // when not using observables and switching the farm the dynform validators
  // stoped working because another instance of fieldset has been assigned to the form
  private _data: BehaviorSubject<SettingsData> = new BehaviorSubject<SettingsData>(null);
  private _setting: ISettings = null;
  private _subscriptions: Array<Subscription> = [];
  private _saveState: ISettingsSaveState;

  constructor(private translationStore: TranslationStore,
              private settingsStore: SettingsStore,
              private farmStore: FarmStore,
              private mapViewStore: MapViewStore,
              private notifyStore: NotifyStore,
              private countryStore: CountryStore) {
  }

  ngOnInit(): void {
    this.loading$ = combineLatest([
      this.settingsStore.Listen(s => s.loading),
      this.farmStore.Listen(s => s.loading),
      this.countryStore.Listen(s => s.loading)
    ]).pipe(
      map(([settingsLoading, farmLoading, countriesLoading]) => settingsLoading || farmLoading || countriesLoading)
    );
    this.countryStore.loadAllCountries();
    this.mapViewStore.hideMapView();
    this.caption = this.translationStore.FindTranslationForSelectedLanguage('Settings__Label_Settings');
    this.formConfig = this._getFieldSets();

    this._subscriptions.push(combineLatest([
      this.settingsStore.Listen(s => s.data),
      this.farmStore.Listen(s => s.data)
    ]).subscribe(([settings, farms]) => {
      if (settings && settings.length && farms && farms.length) {
        this._applyFormData(settings[0], farms[0]);
      }
    }));

    this._subscriptions.push(combineLatest([
      this.farmStore.Listen(x => x.registryNumberUpdated),
      this.farmStore.Listen(x => x.countryUpdated),
      this.settingsStore.Listen(x => x.settingsSaved)
    ]).subscribe(([registryNumberUpdated, countryUpdated, settingsSaved]) => {
      // check if the save operation finished with true or false.
      // Undefined is the initial value and means the operation did no finish, yet
      if (settingsSaved != null) {
        this._saveState.SettingsSaved = settingsSaved;
      }
      if (countryUpdated != null) {
        this._saveState.CountrySaved = countryUpdated;
      }
      if (registryNumberUpdated != null) {
        this._saveState.RegistryNumberSaved = registryNumberUpdated;
      }
      // after collecting the save state
      // => perform afterSave operations to enable/disable button
      // and show save success or fail message
      this._onAfterSave();
    }));

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  public onSubmit(): void {
    this.disabledButton.next(true);
    this._saveState = {
      CountrySaved: undefined,
      SettingsSaved: undefined,
      RegistryNumberSaved: undefined
    };
    if (this._isSettingsChanged(this.dynForm.form.value)) {
      this._setting.DigitsAfterDecimalPoint = this.dynForm.form.value['DigitsAfterDecimalPoint'];
      this._setting.FarmTime = this.dynForm.form.value['FarmTime'];
      this._setting.UnitElementOxyd = this.dynForm.form.value['UnitElementOxyd'];
      this.settingsStore.updateSettings(this._setting);
    } else {
      this._saveState.SettingsSaved = true;
    }
    const registryNumber = this.dynForm.form.value['RegistryNumber'];
    if (this._data.value?.RegistryNumber !== registryNumber) {
      this.farmStore.updateRegistryNumber(registryNumber);
    } else {
      this._saveState.RegistryNumberSaved = true;
    }
    const farmCountry = this.dynForm.form.value['Country'];
    if (this._data.value?.Country !== farmCountry) {
      this.farmStore.updateFarmCountry(farmCountry);
    } else {
      this._saveState.CountrySaved = true;
    }

    // in case no updates need we need to execute the post save operations at this point
    this._onAfterSave();
  }


  /**
   * Handles post save logic when all settings have been saved
   * - enable/disable the save button
   * - show save success or fail message
   * @private
   */
  private _onAfterSave(): void {
    const saveComplete = this._saveState && this._saveState.CountrySaved != null &&
      this._saveState.RegistryNumberSaved != null &&
      this._saveState.SettingsSaved != null;

    if (!saveComplete) {
      return;
    }

    // Check if all save operations were successful
    if (this._saveState.CountrySaved &&
      this._saveState.RegistryNumberSaved &&
      this._saveState.SettingsSaved) {
      this.notifyStore.showSaveSuccessMessage();
    } else {
      this.notifyStore.showSaveFailMessage();
    }
    this.disabledButton.next(!saveComplete);
  }

  private _applyFormData(setting: ISettings, farm: IFarm): void {
    // use timeout to avoid 'expression has been changed after it was checked' errors
    setTimeout(() => this._data.next( {
      FarmName: farm?.FarmName ? farm.FarmName : ' ',
      CustomerNumber: farm?.CustomerNumber ? farm.CustomerNumber : ' ',
      Street: farm?.Street ? farm.Street : ' ',
      Street2: farm?.Street2 ? farm.Street2 : ' ',
      PostalCodePlusCity: this._getPostCodePlusLocation(farm),
      State: farm?.State ? farm.State : ' ',
      RegistryNumber: farm?.RegistryNumber ? farm.RegistryNumber : ' ',
      DigitsAfterDecimalPoint: setting.DigitsAfterDecimalPoint,
      FarmTime: setting.FarmTime,
      UnitElementOxyd: setting.UnitElementOxyd,
      Country: farm?.Country
    }), 0);
    this._setting = ObjectFactory.Copy(setting);
  }

  private _getFieldSets(): ApDynformsConfigFieldset[] {
    return [
      new ApDynformsConfigFieldset({
        key: 'Global__Settings_Address',
        legend: 'Global__Settings_Address',
        config: [
          new ApDynformsConfigTextbox({
            key: 'CustomerNumber',
            label: 'Global__Farm_CustomerNumber',
            value$: this._data
              .pipe(map(x => x?.CustomerNumber)),
            disabled: true
          }),
          new ApDynformsConfigTextbox({
            key: 'FarmName',
            label: 'Global__Farm_CustomerName',
            value$: this._data
              .pipe(map(x => x?.FarmName)),
            disabled: true
          }),
          new ApDynformsConfigTextbox({
            key: 'Street',
            label: 'Global__Farm_Street',
            value$: this._data
              .pipe(map(x => x?.Street)),
            disabled: true
          }),
          new ApDynformsConfigTextbox({
            key: 'Street2',
            label: 'Global__Farm_Street2',
            value$: this._data
              .pipe(map(x => x?.Street2)),
            disabled: true
          }),
          new ApDynformsConfigTextbox({
            key: 'PostalCodePlusCity',
            label: 'Global__Farm_PostalCodePlusCity',
            value$: this._data
              .pipe(map(x => x?.PostalCodePlusCity)),
            disabled: true
          }),
          new ApDynformsConfigTextbox({
            key: 'State',
            label: 'Global__Farm_State',
            value$: this._data
              .pipe(map(x => x?.State)),
            disabled: true
          }),
          new ApDynformsConfigTextbox({
            key: 'RegistryNumber',
            label: 'Global__Farm_RegistryNumber',
            value$: this._data
              .pipe(map(x => x?.RegistryNumber)),
          })
        ]
      }),
      new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigComboBox({
            key: 'UnitElementOxyd',
            label: 'Settings__Lbl_Menu_Unit',
            value$: this._data
              .pipe(map(x => x?.UnitElementOxyd)),
            valueField: 'Value',
            textField: 'Text',
            options: this._getElementOxydSettings(),
            valuePrimitive: true,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Select_Category'
            })]
          }),
          new ApDynformsConfigComboBox({
            key: 'DigitsAfterDecimalPoint',
            label: 'Global__Display_Number_Decimal_Point',
            value$: this._data
              .pipe(map(x => x?.DigitsAfterDecimalPoint)),
            valueField: 'Value',
            textField: 'Value',
            options: this._getDigitsAfterDecimalPoint(),
            valuePrimitive: true,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Select_Category'
            })]
          }),
          new ApDynformsConfigComboBox({
            key: 'FarmTime',
            label: 'Base_Weather__LocaleTimeZone',
            value$: this._data
              .pipe(map(x => x?.FarmTime)),
            valueField: 'Name',
            textField: 'Name',
            options: this.settingsStore.getTimeZones(),
            valuePrimitive: true,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Select_Category'
            })]
          }),
          new ApDynformsConfigComboBox({
            key: 'Country',
            label: 'Global__Country',
            value$: this._data
              .pipe(map(x => x?.Country)),
            valueField: 'Id',
            textField: 'Id',
            options: this.countryStore.Countries$,
            valuePrimitive: false,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Select_Category'
            })]
          })
        ]
      })
    ];
  }

  private _getPostCodePlusLocation(farm: IFarm): string {
    let postalCodePlusLocation: string;
    if (farm.PostalCode) {
      postalCodePlusLocation = farm.PostalCode;
    }
    if (farm.Location) {
      if (postalCodePlusLocation) {
        postalCodePlusLocation += ' ' + farm.Location;
      } else {
        postalCodePlusLocation = farm.Location;
      }
    }
    if (!postalCodePlusLocation) {
      postalCodePlusLocation = ' ';
    }
    return postalCodePlusLocation;
  }

  private _isSettingsChanged(formValue: any): boolean {
    return this._data.value?.DigitsAfterDecimalPoint !== formValue['DigitsAfterDecimalPoint'] ||
      this._data.value?.FarmTime !== formValue['FarmTime'] ||
      this._data.value?.UnitElementOxyd !== formValue['UnitElementOxyd'];
  }

  private _getElementOxydSettings(): ElementOxyde[] {
    return [
      {Text: this.translationStore.FindTranslationForSelectedLanguage('Global__Element_Label'), Value: 1},
      {Text: this.translationStore.FindTranslationForSelectedLanguage('Global__Oxyd_Label'), Value: 2}
    ];
  }

  private _getDigitsAfterDecimalPoint(): DigitItem[] {
    return [
      {Value: 0},
      {Value: 1},
      {Value: 2},
      {Value: 3},
      {Value: 4},
      {Value: 5},
      {Value: 6}
    ];
  }
}

import {Pipe, PipeTransform}              from '@angular/core';
import {ApDynGridColumnConfigBase}        from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridButtonGroupColumnConfig} from '../../config/ap-dyn-grid-button-group-column-config';
import {Trace}                            from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asButtonGroup'
})
export class AsButtonGroupPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridButtonGroupColumnConfig {
    return config as ApDynGridButtonGroupColumnConfig;
  }

}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
}                                                                             from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormArray, FormGroup, ValidatorFn} from '@angular/forms';
import {ApDynformsConfigService}                                              from './ap-dynforms-config.service';
import {
  ApDynformsConfigFieldset
}                                                                             from './config/ap-dynforms-config-fieldset';
import {ApDynformsConfigBase, ApDynformsControltype}                          from './config/ap-dynforms-config-base';
import {BehaviorSubject, Observable, Subscription}                            from 'rxjs';
import {MapViewMode}                                                          from '../ap-interface';
import {animate, state, style, transition, trigger}                           from '@angular/animations';
import {ApDynformsConfigTabs}                                                 from './config/ap-dynforms-config-tabs';
import {SelectEvent, TabStripComponent}                                       from '@progress/kendo-angular-layout';
import {ApDynformsValidator}                                                  from './ap-dynforms-validator';
import {MapViewStore}                                                         from '../stores/layout/mapview.store';
import {
  TranslationStore
}                                                                             from '../stores/translation/translation.store';
import {Trace}                                                         from '../debug-utils/ApplicationTracer';
import {delay, distinctUntilChanged, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {ObjectFactory}                                                 from 'ts-tooling';

/**
 * Component for dynamic form
 */
@Component({
  selector: 'ap-dynforms',
  templateUrl: './ap-dynforms.component.html',
  providers: [ApDynformsConfigService],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('hideForm', [
      state('in', style({
        display: '{{display}}',
      }), {params: {display: 'inherit'}}),
      state('out', style({
        height: '0',
        margin: '0',
        display: 'grid',
        opacity: '0'
      })),
      transition('in <=> out', [
        style({display: 'grid'}),
        animate('300ms ease-in-out'),
      ]),
    ]),
  ],
})

/**
 * Component for dynamic form
 */
export class ApDynformsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('tabStrip', {static: false}) tabStrip: TabStripComponent;

  @Input() public fieldsets: ApDynformsConfigFieldset[] = [];
  @Input() public tabs: ApDynformsConfigTabs[];
  @Input() public formValidators: ApDynformsValidator<ValidatorFn>[];
  @Input() public formAsyncValidators: ApDynformsValidator<AsyncValidatorFn>[];
  @Input() public defaultValue: any;
  @Input() public caption: string;
  @Input() public headerIcon: string;
  @Input() public headerSvg;
  @Input() public fontSize = 16;
  @Input() public darkMode = false;
  @Input() public offsetTop = 0;
  @Input() public offsetBottom = 56;
  @Input() public formState = 'in';
  @Input() public width;
  @Input() public isModalForm = false;
  @Input() public display = 'inherit';
  @Input() public loading$: Observable<boolean>;
  @Input() public isStatic = false;
  @Output() public apSubmit: EventEmitter<any> = new EventEmitter();
  @Output() public validChanges = new EventEmitter<boolean>(true);
  @Output() public dirtyChanges = new EventEmitter<boolean>(true);
  @Output() public touchedChanges = new EventEmitter<boolean>(true);
  @Output() public pristineChanges = new EventEmitter<boolean>(true);
  @Output() public initialized = new EventEmitter(true);
  @Output() public formValueChanges = new EventEmitter<{ control: string, value: any }>(true);

  public showForm = true;
  public form: FormGroup;
  public fieldsetsOrder:
    { [fieldset: string]: { config: ApDynformsConfigBase<any>, column: number, row: number }[] } = {};
  public mapViewState$: Observable<MapViewMode>;
  public form$ = new BehaviorSubject<FormGroup>(undefined);

  /** @deprecated use FormValues$ */
  public formValues$ = this.form$.pipe(
    delay(0),
    switchMap((form) => form.valueChanges),
    distinctUntilChanged((previous, current) => {
      return ObjectFactory.Equal(previous, current);
    })
  );

  public FormValues$ = new BehaviorSubject({});

  public ApDynformsControltype = ApDynformsControltype;

  private _dependenciesSubscriptions: Subscription[] = [];
  private _listenerSubscriptions: Subscription[] = [];
  private _formValue = {};
  private _tabDisabled: BehaviorSubject<boolean>[] = [];
  private _tabSelected: number;
  private _tabSubscriptions: Subscription[] = [];
  private _pristine = true;
  private _dirty = false;
  private _touched = false;

  constructor(private configService: ApDynformsConfigService,
              private translationService: TranslationStore,
              private mapViewStore: MapViewStore) {
    this.caption = this.translationService.FindTranslationForSelectedLanguage(this.caption);
    this.mapViewState$ = this.mapViewStore.Listen(s => s.mode);
  }

  public static setErrorOnControl(control: AbstractControl, error: string, set: boolean): void {
    if (!error || !control) {
      return;
    }
    let newErrors = {...control.errors};
    delete newErrors[error];
    if (set) {
      newErrors[error] = true;
    } else if (Object.keys(newErrors).length < 1) {
      newErrors = null;
    }
    control.setErrors(newErrors);
  }

  private static _markAllControlsAsTouched(form: FormGroup | FormArray): void {
    Object.keys(form.controls).forEach((key: string) => {
      const control = form.get(key);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this._markAllControlsAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  ngOnInit(): void {
    this.buildForm();
    // CODE_REVIEW
    // emit the valid state so we can use the async pipe in the HTML templates
    this.form.statusChanges.subscribe((s) => {
      this.validChanges.emit(s === 'VALID');
    });
    this.pristineChanges.emit(this._pristine);
    this.dirtyChanges.emit(this._dirty);
    this.touchedChanges.emit(this._touched);
    this.initialized.emit();

    this._tabSubscriptions.push(this.form$.pipe(
      filter((form) => !!form),
      mergeMap((form) => form.valueChanges),
      distinctUntilChanged((previous, current) => ObjectFactory.Equal(previous, current)),
      map((values) => {
        const result = {};
        Object.keys(values).forEach((key) => {
          if (values[key] !== '') {
            result[key] = values[key];
          }
        });
        return result;
      })
    ).subscribe((values) => this.FormValues$.next(values)));
  }

  /**
   * EventHandler for Changes.
   * Whenever configuration changes -> rebuild form.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fieldsets) {
      if (!changes.fieldsets.firstChange) {
        this.buildForm();
      }
    }
    if (changes.tabs) {
      if (this.tabs && this.tabs.length) {
        this._setupTabs();
      }
      if (!changes.tabs.firstChange) {
        this.buildForm();
      }
    }
    if (changes.defaultValue) {
      this._formValue = this.defaultValue;
    }

    if (this.form) {
      if (this.form.controls) {
        for (const key in this.form.controls) {
          if (!this.form.controls.hasOwnProperty(key)) {
            continue;
          }
          this.form.controls[key].valueChanges.subscribe((v) => {
            this.formValueChanges.emit({control: key, value: v});
          });
        }
      }
      if (this.form.pristine !== this._pristine) {
        this._pristine = this.form.pristine;
        this.pristineChanges.emit(this._pristine);
      }
      if (this.form.dirty !== this._dirty) {
        this._dirty = this.form.dirty;
        this.dirtyChanges.emit(this._dirty);
      }
      if (this.form.touched !== this._touched) {
        this._touched = this.form.touched;
        this.touchedChanges.emit(this._touched);
      }
    }
  }

  @Trace()
  ngOnDestroy(): void {
    this._dependenciesSubscriptions.forEach(s => s.unsubscribe());
    this._listenerSubscriptions.forEach(s => s.unsubscribe());
    this._tabSubscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * builds dynamic form
   */
  buildForm(): void {
    this._ensureFieldSetsHaveValue();
    this._removeEmptyEntries();

    const fieldSets: ApDynformsConfigFieldset[] = [];
    if (this.tabs && this.tabs.length) {
      this.tabs.forEach(tab => fieldSets.push(...tab.fieldSets));
    } else {
      fieldSets.push(...this.fieldsets);
    }
    this.form = this.configService.toFormGroup(fieldSets, this.formValidators, this.formAsyncValidators);
    this._dependenciesSubscriptions =
      this.configService.subscribeToDependencies(this.form, fieldSets, this._dependenciesSubscriptions);
    this._listenerSubscriptions =
      this.configService.subscribeToListener(this.form, fieldSets, this._listenerSubscriptions);
    this._formValue = this.defaultValue;
    this.chunkFieldSet();
    this.form$.next(this.form);
  }

  chunkFieldSet(): void {
    this._ensureFieldSetsHaveValue();

    this.fieldsetsOrder = {};
    this.fieldsets.forEach(fieldset => {
      if (this.fieldsetsOrder[fieldset.legend] != null) {
        throw new Error('duplicated fieldset legend detected:' + fieldset.legend);
      }
      const configs = fieldset.config.reduce((result, config) => {
        result.push(config);
        if (config.controlType === ApDynformsControltype.DateRange) {
          result.push(undefined);
        }
        return result;
      }, []);
      this.fieldsetsOrder[fieldset.legend] =
        configs.map((v, i) => ({config: v, column: i % fieldset.columns, row: Math.floor(i / fieldset.columns)}));
    });
    setTimeout(() => {
      if (this._formValue) {
        this.form.patchValue(this._formValue);
      }
    }, 0);
  }

  onSubmitDynForm(): void {
    if (this.apSubmit) {
      this.apSubmit.emit();
    }
  }

  markAsTouched(): void {
    ApDynformsComponent._markAllControlsAsTouched(this.form);
  }

  tabSelect(select: SelectEvent): void {
    if (this._tabSelected !== select.index) {
      this._selectTab(select.index);
    }
  }

  getSpan(current: { column: number; row: number }, target: {
    column: number;
    row: number
  },      kind: 'column' | 'row'): string {
    let d;
    if (kind === 'column') {
      d = target.column - current.column + 1;
    } else {
      d = target.row - current.row + 1;
    }
    return `span ${d}`;
  }

  getColumnGap(fieldset: ApDynformsConfigFieldset, isStatic): string {
    let result = '0%';
    if (fieldset.columnGap) {
      if (isStatic) {
        result = ((80 / fieldset.columns) / (fieldset.columns - 1)) + 'px';
      } else {
        result = ((40 / fieldset.columns) / (fieldset.columns - 1)) + '%';
      }
    }
    return result;
  }

  private _setupTabs(): void {
    this._selectTab(this.tabs.findIndex(tab => tab.selected));
    this._tabDisabled = [];
    this._tabSubscriptions.forEach(s => s.unsubscribe());
    this._tabSubscriptions = [];
    this.tabs.forEach((tab, i) => {
      const subject = new BehaviorSubject(false);
      if (tab.disabled) {
        this._tabSubscriptions.push(tab.disabled.subscribe(b => {
          subject.next(b);
          if (b && this._tabSelected === i) {
            this._findAvailableTab();
          }
        }));
      }
      this._tabDisabled.push(subject);
    });
  }

  private _selectTab(index: number): void {
    if (this._tabSelected !== undefined) {
      this._formValue = ObjectFactory.Merge(this.defaultValue, this.form.value);
    }
    this._tabSelected = index;
    this.fieldsets = this.tabs[index].fieldSets;
    this.chunkFieldSet();
  }

  private _findAvailableTab(): void {
    if (this.tabStrip) {
      this.tabStrip.selectTab(this._tabDisabled.findIndex(t => !t.value));
    }
  }

  private _ensureFieldSetsHaveValue(): void {
    if (!this.fieldsets) {
      this.fieldsets = [];
    }
  }

  private _removeEmptyEntries(): void {
    this.fieldsets = this.fieldsets.filter(x => x !== undefined);
  }
}

import {Pipe, PipeTransform}        from '@angular/core';
import {ApDynGridColumnConfigBase}  from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridGroupColumnConfig} from '../../config/ap-dyn-grid-group-column-config';
import {Trace}                      from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asGroup'
})
export class AsGroupPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridGroupColumnConfig {
    return config as ApDynGridGroupColumnConfig;
  }

}

<div class="histogramcontainer">
  <ng-container *ngIf="groups.length <= 0">
    <div class="empty-message">
      <p>{{emptyMessage | translate}}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="groups.length > 0">
    <div class="diagram-controls">
      <kendo-combobox [data]="filteredGroups"
                      [(value)]="selectedGroup"
                      valueField="id"
                      textField="name"
                      [filterable]="true"
                      [allowCustom]="false"
                      (filterChange)="filterChange($event)"
                      (valueChange)="onSelectionChange($event)"
      ></kendo-combobox>
    </div>

    <h3 *ngIf="selectedGroup?.id !== '2'">{{title}}</h3>

    <div *ngIf="selectedGroup?.id === '1'" class="x4chart">
      <div>
        <div>
          <!-- P Chart -->
          <kendo-chart [categoryAxis]="{ categories: chartP?.data?.categories }">
            <kendo-chart-area [height]="chartHeight4X4 | async"
                              [width]="chartWidthHalf | async">
              <kendo-chart-title [text]="'Global__NutrientP_Full' | translate"
                                 [color]="strokeColor"></kendo-chart-title>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>

              <kendo-chart-series>
                <kendo-chart-series-item type="column"
                                         [color]="chartP?.color"
                                         [style]="'smooth'"
                                         [data]="chartP?.data?.data"
                                         [name]="chartP?.data?.name">
                  <kendo-chart-series-item-tooltip [background]="backgroundColor" [border]="backgroundColor"
                                                   [color]="'#000'">
                    <ng-template let-value="value">
                      {{value | getRoundNumeric:chartP?.decimalPlaces}} ha
                      (ca.{{value * 100 / chartP?.meta?.areaSum | getRoundNumeric:0}} %)
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>

              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: chartP?.axes?.titleX }"
                                             [color]="strokeColor"
                                             [min]="chartP?.axes?.minX"
                                             [max]="[chartP?.axes?.maxX, chartK?.axes?.maxX, chartMg?.axes?.maxX, chartPh?.axes?.maxX].Max()">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>

              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="chartP?.data?.categories"
                                                [title]="{ text: chartP?.axes?.titleY }"
                                                [labels]=""
                                                [color]="strokeColor">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
            </kendo-chart-area>
          </kendo-chart>
        </div>
        <div>
          <!-- Mg Chart -->
          <kendo-chart [categoryAxis]="{ categories: chartMg?.data?.categories }">
            <kendo-chart-area [height]="chartHeight4X4 | async"
                              [width]="chartWidthHalf | async">
              <kendo-chart-title [text]="'Global__NutrientMg_Full' | translate"
                                 [color]="strokeColor"></kendo-chart-title>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>

              <kendo-chart-series>
                <kendo-chart-series-item type="column"
                                         [color]="chartMg?.color"
                                         [style]="'smooth'"
                                         [data]="chartMg?.data?.data"
                                         [name]="chartMg?.data?.name">
                  <kendo-chart-series-item-tooltip [background]="backgroundColor" [border]="border"
                                                   [color]="strokeColor">
                    <ng-template let-value="value">
                      {{value | getRoundNumeric:chartMg?.decimalPlaces}} ha
                      (ca.{{value * 100 / chartMg?.meta?.areaSum | getRoundNumeric:0}} %)
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>

              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: chartMg?.axes?.titleX }"
                                             [color]="strokeColor"
                                             [min]="chartMg?.axes?.minX"
                                             [max]="[chartP?.axes?.maxX, chartK?.axes?.maxX, chartMg?.axes?.maxX, chartPh?.axes?.maxX].Max()">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>

              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="chartMg?.data?.categories"
                                                [title]="{ text: chartMg?.axes?.titleY }"
                                                [labels]=""
                                                [color]="strokeColor">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
            </kendo-chart-area>
          </kendo-chart>
        </div>
      </div>

      <div>
        <div>
          <!-- K Chart -->
          <kendo-chart [categoryAxis]="{ categories: chartK?.data?.categories }">
            <kendo-chart-area [height]="chartHeight4X4 | async"
                              [width]="chartWidthHalf | async">
              <kendo-chart-title [text]="'Global__NutrientK_Full' | translate"
                                 [color]="strokeColor"></kendo-chart-title>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>

              <kendo-chart-series>
                <kendo-chart-series-item type="column"
                                         [color]="chartK?.color"
                                         [style]="'smooth'"
                                         [data]="chartK?.data?.data"
                                         [name]="chartK?.data?.name">
                  <kendo-chart-series-item-tooltip [background]="backgroundColor" [border]="border"
                                                   [color]="strokeColor">
                    <ng-template let-value="value">
                      {{value | getRoundNumeric:chartK?.decimalPlaces}} ha
                      (ca.{{value * 100 / chartK?.meta?.areaSum | getRoundNumeric:0}} %)
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>

              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: chartK?.axes?.titleX }"
                                             [color]="strokeColor"
                                             [min]="chartK?.axes?.minX"
                                             [max]="[chartP?.axes?.maxX, chartK?.axes?.maxX, chartMg?.axes?.maxX, chartPh?.axes?.maxX].Max()">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>

              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="chartK?.data?.categories"
                                                [title]="{ text: chartK?.axes?.titleY }"
                                                [labels]=""
                                                [color]="strokeColor">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
            </kendo-chart-area>
          </kendo-chart>
        </div>
        <div>
          <!-- Ph Chart -->
          <kendo-chart [categoryAxis]="{ categories: chartPh?.data?.categories }">
            <kendo-chart-area [height]="chartHeight4X4 | async"
                              [width]="chartWidthHalf | async">
              <kendo-chart-title [text]="'Global__Nutrient_pH_Short' | translate"
                                 [color]="strokeColor"></kendo-chart-title>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>

              <kendo-chart-series>
                <kendo-chart-series-item type="column"
                                         [color]="chartPh?.color"
                                         [style]="'smooth'"
                                         [data]="chartPh?.data?.data"
                                         [name]="chartPh?.data?.name">
                  <kendo-chart-series-item-tooltip [background]="backgroundColor" [border]="border"
                                                   [color]="strokeColor">
                    <ng-template let-value="value">
                      {{value | getRoundNumeric:chartPh?.decimalPlaces}} ha
                      (ca.{{value * 100 / chartPh?.meta?.areaSum | getRoundNumeric:0}} %)
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>

              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: chartPh?.axes?.titleX }"
                                             [color]="strokeColor"
                                             [min]="chartPh?.axes?.minX"
                                             [max]="[chartP?.axes?.maxX, chartK?.axes?.maxX, chartMg?.axes?.maxX, chartPh?.axes?.maxX].Max()">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>

              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="chartPh?.data?.categories"
                                                [title]="{ text: chartPh?.axes?.titleY }"
                                                [labels]=""
                                                [color]="strokeColor">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
            </kendo-chart-area>
          </kendo-chart>
        </div>
      </div>
    </div>

    <div *ngIf="selectedGroup?.id === '2'">
      <!-- Sample Year -->
      <kendo-chart>
        <kendo-chart-area [height]="chartHeightNoTitleHalf | async"
                          [width]="chartWidth | async">
          <kendo-chart-tooltip [background]="backgroundColor" [border]="border" [color]="strokeColor">
            <ng-template kendoChartSeriesTooltipTemplate let-category="category" let-series="series">
              {{series | nutrientStatsFields:category}}
            </ng-template>
          </kendo-chart-tooltip>
          <ng-template kendoChartDonutCenterTemplate>
            {{title}}
          </ng-template>
          <kendo-chart-series>
            <kendo-chart-series-item type="donut" [data]="sampleDate"
                                     field="value" categoryField="category"
                                     [border]="border" [colorField]="'color'">
              <kendo-chart-series-item-labels
                [position]="'outsideEnd'"
                [content]="labelContentSamplingYear"
                [background]="backgroundColor" [border]="borderNone" [color]="strokeColor">
              </kendo-chart-series-item-labels>
            </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-legend [visible]="false"></kendo-chart-legend>
        </kendo-chart-area>
      </kendo-chart>

      <ng-container *ngFor="let year of fieldSoilPlanningYear; let i = index">
        <nutrient-infos-view [rowNum]="i" [maxRows]="fieldSoilPlanningYear.length"
                             [item]="year | fieldSoilPlanningYearToSimpleValueSet"></nutrient-infos-view>
      </ng-container>
    </div>

    <div *ngIf="selectedGroup?.id === '3'">
      <!-- SoilGroup -->
      <kendo-chart >
        <kendo-chart-area [height]="chartHeight | async"
                          [width]="chartWidth | async">
          <kendo-chart-series>
            <kendo-chart-series-item type="column" [missingValues]="'zero'"
                                     [style]="'smooth'"
                                     [data]="soilGroup"
                                     field="value" categoryField="category">
              <kendo-chart-series-item-tooltip [background]="backgroundColor" [border]="border" [color]="strokeColor">
                <ng-template let-value="value">
                  {{value | getRoundNumeric:2}} ha (ca.{{value * 100 / chartP?.meta?.areaSum | getRoundNumeric:0}} %)
                </ng-template>
              </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>
          </kendo-chart-series>

          <kendo-chart-legend [visible]="false"></kendo-chart-legend>

          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [title]=""
                                         [color]="strokeColor"
                                         [min]="soilGroupMin"
                                         [max]="soilGroupMax">
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="soilGroupCategories"
                                            [labels]=""
                                            [color]="strokeColor">
            </kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
        </kendo-chart-area>
      </kendo-chart>
    </div>

    <div *ngIf="selectedGroup?.id === '4'">
      <ng-container *ngFor="let info of infos; let i = index">
        <nutrient-infos-view [rowNum]="i" [maxRows]="infos.length" [item]="info"></nutrient-infos-view>
      </ng-container>
    </div>

  </ng-container>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {ApDynformsConfigFieldset}     from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsConfigTextbox}      from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynComponentComponent}      from '../../ap-dyncomponent/ap-dyncomponent.component';
import {ApDynformsConfigTextarea}     from '../../ap-dynforms/config/ap-dynforms-config-textarea';
import {ApDynformsConfigPlaceholder}  from '../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {FormStore}                    from '../../stores/layout/form.store';
import {TranslationStore}             from '../../stores/translation/translation.store';

// noinspection AngularUndefinedBinding
/**
 * Drivers edit component.
 */
@Component({
  selector: 'ap-jobs-edit',
  template: `
      <ap-dyncomponent [fieldSets]="formConfig" [caption]="caption">
          <div class="ap-form-actions" dynforms.action>
              <button id="button_jobs_edit_cancel"
                      type="button"
                      class="k-button k-primary ap-form-button-right"
                      (click)="onCancelClick()">{{'Global__Cancel' | translate}}
              </button>
          </div>
      </ap-dyncomponent>`,
})

/**
 * Drivers edit component.
 */
export class ApJobsEditComponent implements OnInit {
  @ViewChild(ApDynComponentComponent, {static: true}) dynComponent;
  public data: any;
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;

  constructor(private formStore: FormStore,
              private translationStore: TranslationStore) {
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    this.caption = 'Jobs_Details';
    this.formBuilder();
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'job1',
        legend: 'Base__BasicData',
        columns: 4,
        config: [
          new ApDynformsConfigTextbox({
            key: 'Id',
            label: 'Global__Id',
            disabled: true,
            value: this.data.SourceItem.Id
          }),
          new ApDynformsConfigTextbox({
            key: 'Status',
            label: 'Global__Status',
            disabled: true,
            value: this.translationStore.FindTranslationForSelectedLanguage(this.data.StatusDesc)
          }),
          new ApDynformsConfigTextbox({
            key: 'SourceItem.JobType.Name',
            label: 'Global_Type',
            disabled: true,
            value: this.data.SourceItem.JobType.Name
          }),
          new ApDynformsConfigPlaceholder(),
          new ApDynformsConfigTextbox({
            key: 'CreatedAt',
            label: 'Global__CreatedAt',
            disabled: true,
            value: this.data.CreatedAtFormatted
          }),
          new ApDynformsConfigTextbox({
            key: 'StartedAt',
            label: 'Global_Started_At',
            disabled: true,
            value: this.data.StartedAtFormatted
          }),
          new ApDynformsConfigTextbox({
            key: 'LastActivityAt',
            label: 'Global_LastActive_At',
            disabled: true,
            value: this.data.LastActivityFormatted
          }),
          new ApDynformsConfigTextbox({
            key: 'FinishedAt',
            label: 'Global__Finished_At',
            disabled: true,
            value: this.data.FinishedAtFormatted
          }),
          new ApDynformsConfigTextbox({
            key: 'Priority',
            label: 'Global_Priority',
            disabled: true,
            value: this.data.PriorityText
          }),
          new ApDynformsConfigTextbox({
            key: 'Condition',
            label: 'Global_Condition',
            disabled: true,
            value: this.data.Condition
          })
        ]
      }),
      new ApDynformsConfigFieldset({
        key: 'job1',
        legend: 'Global_Schedule',
        columns: 4,
        config: [
          new ApDynformsConfigTextbox({
            key: 'Schedule.Id',
            label: 'Global__Id',
            disabled: true,
            value: this.data.SourceItem.Schedule ? this.data.SourceItem.Schedule.Id : ' - '
          }),
          new ApDynformsConfigTextbox({
            key: 'ScheduledAtFormatted',
            label: 'Docu_Ini__StartTime',
            disabled: true,
            value: this.data.ScheduledAtFormatted
          }),
          new ApDynformsConfigTextbox({
            key: 'IntervalFormatted',
            label: 'Global__Interval',
            disabled: true,
            value: this.data.IntervalFormatted
          }),
          new ApDynformsConfigTextbox({
            key: 'Schedule.ScheduleEnd',
            label: 'Global_Finish',
            disabled: true,
            value: this.data.SourceItem.Schedule ? this.data.SourceItem.Schedule.ScheduleEnd : ' - '
          })
        ]
      }),
      new ApDynformsConfigFieldset({
        key: 'job1',
        legend: 'Global_IO_Values',
        columns: 2,
        useMaxWidth: true,
        config: [
          new ApDynformsConfigTextarea({
            key: 'Input',
            label: 'Global_Input',
            disabled: true,
            rows: 12,
            value: this.data.SourceItem.Input
              ? JSON.stringify(JSON.parse(this.data.SourceItem.Input), null, 4)
              : ''
          }),
          new ApDynformsConfigTextarea({
            key: 'Output',
            label: 'Global_Output',
            disabled: true,
            rows: 12,
            value: this.data.SourceItem.Output
              ? JSON.stringify(JSON.parse(this.data.SourceItem.Output), null, 4)
              : ''
          })
        ]
      })];
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

/**
 * some util Functions for Distribution Class Colors
 */
export class ApDistributionClassColors {
  /**
   * returns the Color Value of a Class Value in rgba Format
   */
  static getColorsFromValue(value: number): string {
    switch (value) {
      case 1:
        // A-
        return 'rgba(240, 10, 0, 1)';
      case 2:
        // A+
        return 'rgba(215, 80, 0, 1)';
      case 3:
        // B-
        return 'rgba(214, 112, 0, 1)';
      case 4:
        // B+
        return 'rgba(217, 160, 0, 1)';
      case 5:
        // C-
        return 'rgba(212, 221, 0, 1)';
      case 6:
        // C+
        return 'rgba(166, 212, 0, 1)';
      case 7:
        // D-
        return 'rgba(100, 171, 0, 1)';
      case 8:
        // D+
        return 'rgba(41, 134, 0, 1)';
      case 9:
        // E
        return 'rgba(0, 130, 255, 1)';
      default:
        return 'rgba(255, 255, 255, 0)';
    }
  }
}

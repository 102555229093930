import {IStateStore}         from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {
  FieldNutrientDistributionsLoad,
  FieldNutrientDistributionsLoadSuccess,
  FieldNutrientDistributionStatisticDataExcelExport,
  FieldNutrientDistributionStatisticDataExcelExportFail,
  FieldNutrientDistributionStatisticDataExcelExportSuccess,
  NetTypes,
  SampleMethodsLoad,
  SampleMethodsLoadSuccess,
  SampleRegionsLoad,
  SampleRegionsLoadSuccess
}                            from 'invoker-transport';
import {SafeBehaviorSubject} from 'ts-tooling';
import * as download         from 'downloadjs';
import IFieldNutrientsDistribution = Data.Nutrients.IFieldNutrientsDistribution;
import ISampleMethod = Data.Nutrients.ISampleMethod;
import ISampleRegion = Data.Nutrients.ISampleRegion;
import IGuid = System.IGuid;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IFieldNutrientDistributionStore extends IStateStore<IFieldNutrientsDistribution> {
  sampleMethods: ISampleMethod[];
  sampleRegions: ISampleRegion[];
  exportedStatisticFile: ArrayBuffer;
}

@Injectable({providedIn: 'root'})
export class FieldNutrientDistributionStore extends Store<IFieldNutrientDistributionStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      sampleMethods: [],
      sampleRegions: [],
      exportedStatisticFile: null
    });
    backend.registerObservable(FieldNutrientDistributionsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SampleMethodsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.sampleMethods, () => d.Data);
    });
    backend.registerObservable(SampleRegionsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.sampleRegions, () => d.Data);
    });
    backend.registerObservable(FieldNutrientDistributionStatisticDataExcelExportSuccess).subscribe(d => {
      super.Mutate(s => s.exportedStatisticFile, () => d.Data);
      super.SetLoadFinishState();
      if (d.Data && d.Data[0] && d.Data[1]) {
        download(d.Data[1], `${d.Data[0]}.xlsx`, 'application/excel');
      }
    });
    backend.registerObservable(FieldNutrientDistributionStatisticDataExcelExportFail).subscribe(() => {
      super.Mutate(s => s.exportedStatisticFile, () => null);
      super.SetLoadFailState();
    });
  }

  public get Distributions$(): SafeBehaviorSubject<IFieldNutrientsDistribution[]> {
    return super.Listen(s => s.data);
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get SampleMethods$(): SafeBehaviorSubject<ISampleMethod[]> {
    return super.Listen(s => s.sampleMethods);
  }

  public get SampleMethods(): ISampleMethod[] {
    return this.SampleMethods$.getValue();
  }

  public get SampleRegions$(): SafeBehaviorSubject<ISampleRegion[]> {
    return super.Listen(s => s.sampleRegions);
  }

  public get SampleRegions(): ISampleRegion[] {
    return this.SampleRegions$.getValue();
  }

  public loadFieldNutrientDistributions(fieldGeomIds: string[]): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new FieldNutrientDistributionsLoad([
      {Name: 'fieldGeomIds', Type: NetTypes.GUID + '[]', Value: fieldGeomIds},
    ]));
  }

  public loadSampleMethods(): void {
    this.DispatchBackend(new SampleMethodsLoad([]));
  }

  public loadSampleRegions(): void {
    this.DispatchBackend(new SampleRegionsLoad([]));
  }

  public statisticDataExcelExport(fileName: string, fieldGeometryIds: IGuid[]): void {
    super.Mutate(s => s.exportedStatisticFile, () => null);
    super.SetLoadState();
    this.DispatchBackend(new FieldNutrientDistributionStatisticDataExcelExport([
      {Name: 'fileName', Type: NetTypes.STRING, Value: fileName},
      {Name: 'fieldGeometryIds', Type: NetTypes.GUID + '[]', Value: fieldGeometryIds},
    ]));
  }
}

import {Component, Input, OnInit}  from '@angular/core';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query';
import {FilterService}               from '@progress/kendo-angular-grid';
import {ApDynGridStatusColumnConfig} from '../config/ap-dyn-grid-status-column-config';

@Component({
  selector: 'ap-status-column-filter',
  template: `
    <ap-status-filter [filterService]="filterService"
                      [currentFilter]="currentFilter"
                      [key]="config.field"
                      [items]="items">
    </ap-status-filter>
  `,
  styles: []
})
export class ApStatusColumnFilterComponent {
  @Input() config: ApDynGridStatusColumnConfig;
  @Input() currentFilter: CompositeFilterDescriptor;
  @Input() filterService: FilterService;
  @Input() items: any[];
}

@Component({
  selector: 'ap-status-column-header',
  template: `
    <ap-responsive-text [key]="config.showHeaderTitle ? config.title : '&nbsp;'">
    </ap-responsive-text>
  `,
  styles: []
})
export class ApStatusColumnHeaderComponent {
  @Input() config: ApDynGridStatusColumnConfig;
}

@Component({
  selector: 'ap-status-column-cell',
  template: `
    <span [class]="config.cssClass | centerCell">
      <div *ngIf="((config.pendingIds | async) | idIn:dataItem:'Id') === false"
           [class]="dataItem[config.class]"
           [aptooltip]="(dataItem[config.description] | translate) | execPipes:config:'tooltipPipes' | async"
           [perpetual]="true">
      </div>
      <div [aptooltip]="config.pendingTooltip | async | translate" [perpetual]="true">
        <kendo-loader *ngIf="(config.pendingIds | async) | idIn:dataItem:'Id'" size="small"
                      [themeColor]="dataItem[config.class] | translateKendoState"
                      [type]="'infinite-spinner'"></kendo-loader>
      </div>
    </span>
  `,
  styles: []
})
export class ApStatusColumnCellComponent {
  @Input() config: ApDynGridStatusColumnConfig;
  @Input() dataItem: any;
}

@Component({
  selector: 'ap-status-column-group-filter',
  template: `
    <span [class]="config.cssClass | centerCell">
      <div>
        {{'Global__Status' | translate}}{{':'}}
      </div>
      &nbsp;
      <div
           [class]="class"
           [aptooltip]="(description | translate) | execPipes:config:'tooltipPipes' | async"
           [perpetual]="true">
      </div>
    </span>
  `,
  styles: []
})
export class ApStatusColumnGroupFilterComponent implements OnInit {
  @Input() config: ApDynGridStatusColumnConfig;
  @Input() field: any;
  @Input() group: any;

  class: string;
  description: string;

  ngOnInit(): void {
    if (this.group.items.length !== 0) {
      this.class = this.group.items[0][this.config.class];
      this.description = this.group.items[0][this.config.description];
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {Trace}               from '../../../debug-utils/ApplicationTracer';
import {FilterByStringPipe}  from './filter-by-string.pipe';
import IMenuEntry = Data.MenuStructure.IMenuEntry;

@Pipe({
  name: 'isMainSelected'
})
export class IsMainSelectedPipe implements PipeTransform {

  constructor(private filterByString: FilterByStringPipe) {
  }

  @Trace()
  transform(main: IMenuEntry, ...args: [string, string]): boolean {
    // user did not select any id => use default preselect
    if (args[1] !== '') {
      return (this.filterByString.transform(main, args[0], 'Url') || main.Url === args[0]) ||
        (main.Url === args[1] || this.filterByString.transform(main, args[1], 'Url'));
    } else {
      return this.filterByString.transform(main, args[0], 'Url') || main.Url === args[0];
    }
  }

}

import IMachineBreak = Data.DocuContext.Machine.IMachineBreak;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum MachineBreaksActions {
  MACHINE_BREAKS_LOAD = '[machinebreaks] load',
  MACHINE_BREAKS_LOAD_SUCCESS = '[machinebreaks] load success',
}

export class MachineBreaksLoad implements IAction {
  readonly type = MachineBreaksActions.MACHINE_BREAKS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MachineBreaksLoadSuccess implements IAction {
  readonly type = MachineBreaksActions.MACHINE_BREAKS_LOAD_SUCCESS;

  constructor(public payload: IMachineBreak[]) {
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IActiveUser = Data.Authentication.IActiveUser;

export enum ActiveUsersActions {
  ACTIVE_USERS_LOAD = '[active-users] load',
  ACTIVE_USERS_LOAD_SUCCESS = '[active-users] load success',
  ACTIVE_USERS_KICK = '[active-users] kick',
  ACTIVE_USERS_GLOBAL_LOGOUT = '[active-users] global logout'
}

export class ActiveUsersLoad implements IAction {
  readonly type = ActiveUsersActions.ACTIVE_USERS_LOAD;
}

export class ActiveUsersLoadSuccess implements IAction {
  readonly type = ActiveUsersActions.ACTIVE_USERS_LOAD_SUCCESS;

  constructor(public payload: IActiveUser[]) {
  }
}

export class ActiveUsersKick implements IAction {
  readonly type = ActiveUsersActions.ACTIVE_USERS_KICK;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ActiveUsersGlobalLogout implements IAction {
  readonly type = ActiveUsersActions.ACTIVE_USERS_GLOBAL_LOGOUT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

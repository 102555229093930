import {IAction, IApInvokerArguments} from '../../interfaces';

export enum FarmActions {
  FARM_LOAD = '[farm] load',
  FARM_LOAD_SUCCESS = '[farm] load success',
  FARM_LOAD_FAIL = '[farm] load fail',
  FARM_UPDATE_REGISTRY_NUMBER = '[farm] update registry number',
  FARM_UPDATE_REGISTRY_NUMBER_SUCCESS = '[farm] update registry number success',
  FARM_UPDATE_REGISTRY_NUMBER_FAIL = '[farm] update registry number fail',
  INFORM_FARM_CHANGE = '[farm] inform farm change',
  INFORM_FARM_CHANGE_SUCCESS = '[farm] inform farm change success',
  FARM_UPDATE_COUNTRY = '[farm] update country',
  FARM_UPDATE_COUNTRY_SUCCESS = '[farm] update country success',
  FARM_UPDATE_COUNTRY_FAIL = '[farm] update country fail',
}

export class FarmLoad implements IAction {
  readonly type = FarmActions.FARM_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmLoadSuccess implements IAction {
  readonly type = FarmActions.FARM_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmLoadFail implements IAction {
  readonly type = FarmActions.FARM_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUpdateRegistryNumber implements IAction {
  readonly type = FarmActions.FARM_UPDATE_REGISTRY_NUMBER;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUpdateRegistryNumberSuccess implements IAction {
  readonly type = FarmActions.FARM_UPDATE_REGISTRY_NUMBER_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUpdateRegistryNumberFail implements IAction {
  readonly type = FarmActions.FARM_UPDATE_REGISTRY_NUMBER_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InformFarmChange implements IAction {
  readonly type = FarmActions.INFORM_FARM_CHANGE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class InformFarmChangeSuccess implements IAction {
  readonly type = FarmActions.INFORM_FARM_CHANGE_SUCCESS;

  constructor(public payload: number) {
  }
}

export class FarmUpdateCountry implements IAction {
  readonly type = FarmActions.FARM_UPDATE_COUNTRY;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUpdateCountrySuccess implements IAction {
  readonly type = FarmActions.FARM_UPDATE_COUNTRY_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUpdateCountryFail implements IAction {
  readonly type = FarmActions.FARM_UPDATE_COUNTRY_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
}                    from '@angular/router';
import {Injectable}  from '@angular/core';
import {Observable}  from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class ApDeveloperGuard implements CanLoad, CanActivate {
  constructor() {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return !environment.Production;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !environment.Production;
  }
}

import {AfterContentInit, ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {Subscription}                                                    from 'rxjs';
import * as moment                                                       from 'moment';
import {SettingsStore}                                                   from '../../stores/base-data/settings.store';
import {LanguageStore}                                                   from '../../stores/translation/language.store';
import {ApDateService}                                                   from '../../ap-core/services/ap-date-service';
import ISettings = Data.BaseData.ISettings;
import ILanguage = Data.Language.ILanguage;

@Component({
  selector: 'ap-current-time',
  templateUrl: 'ap-current-time.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApCurrentTimeComponent implements OnDestroy, AfterContentInit {
  private _subscriptions: Subscription[] = [];
  private _settings: ISettings;
  private _language: ILanguage = null;
  private _interval;

  time = this.dateService.toJsDate(moment.utc());

  get FarmSettings(): ISettings {
    return this._settings || ({
      FarmTime: 'UTC'
    } as ISettings);
  }

  get FormatDate(): string {
    if (!this._language) {
      return 'YYYY-MM-DD';
    }
    return this._language.DateFormat;
  }

  get FormatTime(): string {
    return 'HH:mm';
  }

  constructor(private dateService: ApDateService,
              private settingsStore: SettingsStore,
              private languageStore: LanguageStore) {
  }

  ngAfterContentInit(): void {
    this._subscriptions.push(this.settingsStore.FirstSetting$.subscribe(s => this._settings = s));
    this._subscriptions.push(this.languageStore.Listen(s => s.selectedLanguage)
      .subscribe(l => this._language = l));
    setTimeout(() => {
      this._interval = setInterval(() => {
        this.time = this.dateService.toJsDate(moment.utc());
      }, 10000);
    }, 1000);
  }

  ngOnDestroy(): void {
    for (const s of this._subscriptions) {
      s.unsubscribe();
    }
    clearInterval(this._interval);
  }
}

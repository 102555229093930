import { Injectable }     from '@angular/core';
import {TranslationStore} from '../../stores/translation/translation.store';
import {ServiceInjector}  from '../injector';

@Injectable({providedIn: 'root'})
export class ApTranslationService {

  static translate(key: string, countryCode?: string): string {
    return ServiceInjector.instance.get(ApTranslationService).translate(key, countryCode);
  }

  constructor(private translationStore: TranslationStore) {}

  translate(key: string, countryCode?: string): string {
    if (countryCode) {
      return this.translationStore.FindTranslation(key, countryCode);
    }
    return this.translationStore.FindTranslationForSelectedLanguage(key);
  }
}

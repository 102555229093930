import {IAction, IApInvokerArguments} from '../../interfaces';
import ITaskManagementPlanSummary = Data.TaskManagement.ITaskManagementPlanSummary;

export enum TaskManagementActions {
  TASK_MANAGEMENT_PLAN_SUMMARIES_LOAD = '[task_management_plan_summaries] load',
  TASK_MANAGEMENT_PLAN_SUMMARY_LOAD_BY_ID = '[task_management_plan_summaries] load by id',
  TASK_MANAGEMENT_PLAN_SUMMARIES_LOAD_SUCCESS = '[task_management_plan_summaries] load success',
  TASK_MANAGEMENT_PLAN_EXPORT = '[task_management_plan] export',
  TASK_MANAGEMENT_PLAN_PLAN_EXPORT_SUCCESS = '[task_management_plan] export success',
  TASK_MANAGEMENT_PLAN_PLAN_EXPORT_FAIL = '[task_management_plan] export fail'
}


export class TaskManagementPlanSummariesLoad implements IAction {
  readonly type = TaskManagementActions.TASK_MANAGEMENT_PLAN_SUMMARIES_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TaskManagementPlanSummariesLoadById implements IAction {
  readonly type = TaskManagementActions.TASK_MANAGEMENT_PLAN_SUMMARY_LOAD_BY_ID;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TaskManagementPlanSummariesLoadSuccess implements IAction {
  readonly type = TaskManagementActions.TASK_MANAGEMENT_PLAN_SUMMARIES_LOAD_SUCCESS;

  constructor(public payload: ITaskManagementPlanSummary[]) {
  }
}

export class TaskManagementPlanExport implements IAction {
  readonly type = TaskManagementActions.TASK_MANAGEMENT_PLAN_EXPORT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class TaskManagementPlanExportSuccess implements IAction {
  readonly type = TaskManagementActions.TASK_MANAGEMENT_PLAN_PLAN_EXPORT_SUCCESS;

  constructor(public payload: ArrayBuffer) {
  }
}

export class TaskManagementPlanExportFail implements IAction {
  readonly type = TaskManagementActions.TASK_MANAGEMENT_PLAN_PLAN_EXPORT_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import IFertilizerInorganic = Data.BaseData.IFertilizerInorg;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum FertilizerInorganicActions {
  FERTILIZER_INORGANIC_LOAD = '[fertilizer_inorganic] load',
  FERTILIZER_INORGANIC_LOAD_SUCCESS = '[fertilizer_inorganic] load success',
  FERTILIZER_INORGANIC_LOAD_FAIL = '[fertilizer_inorganic] load fail',
  FERTILIZER_INORGANIC_SELECTABLE_UPDATE = '[fertilizer_inorganic] selectable update',
  FERTILIZER_INORGANIC_CREATE = '[fertilizer_inorganic] create',
  FERTILIZER_INORGANIC_UPDATE = '[fertilizer_inorganic] update'
}

export class FertilizerInorganicLoad implements IAction {
  readonly type = FertilizerInorganicActions.FERTILIZER_INORGANIC_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FertilizerInorganicLoadSuccess implements IAction {
  readonly type = FertilizerInorganicActions.FERTILIZER_INORGANIC_LOAD_SUCCESS;

  constructor(public payload: IFertilizerInorganic[]) {
  }
}

export class FertilizerInorganicLoadFail implements IAction {
  readonly type = FertilizerInorganicActions.FERTILIZER_INORGANIC_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class FertilizerInorganicSelectableUpdate implements IAction {
  readonly type = FertilizerInorganicActions.FERTILIZER_INORGANIC_SELECTABLE_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FertilizerInorganicCreate implements IAction {
  readonly type = FertilizerInorganicActions.FERTILIZER_INORGANIC_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FertilizerInorganicUpdate implements IAction {
  readonly type = FertilizerInorganicActions.FERTILIZER_INORGANIC_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'inner-loading',
  template: `
    <div class="inner-loading-modal" *ngIf="visible">
      <div
        style="position: fixed; width: 20%; z-index: 2; top: 50%; left: 50%; margin-top: -5%; margin-left: -10%; padding: 10px; text-align: center;">
        <span class="{{iconClass}}" style="font-size: 64px;"></span>
        <p>{{text | translate}}</p>
      </div>
    </div>
  `,
  styles: [
    `
      .inner-loading-modal {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 9999;
      }
    `
  ],
})
export class InnerLoadingComponent {
  @Input()
  public visible = false;
  @Input()
  public text = '';
  @Input()
  public iconClass = 'k-i-loading k-icon';
}

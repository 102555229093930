import {IStateStore}      from '../../ap-interface';
import {Store}            from '../index';
import {Injectable}       from '@angular/core';
import {
  ApCustomTypes,
  FertilizerOrganicCreate,
  FertilizerOrganicLoad,
  FertilizerOrganicLoadSuccess,
  FertilizerOrganicSelectableUpdate,
  FertilizerOrganicUpdate
}                         from 'invoker-transport';
import {TranslationStore} from '../translation/translation.store';
import IFertilizerOrganic = Data.BaseData.IFertilizerOrg;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IFertilizerOrganicStore extends IStateStore<IFertilizerOrganic> {
}

@Injectable({providedIn: 'root'})
export class FertilizerOrganicStore extends Store<IFertilizerOrganicStore> {
  constructor(public backend: ApSignalrService,
              public translationStore: TranslationStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });
    backend.registerObservable(FertilizerOrganicLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data.Convert(_ => {
        _.Prodname = this.translationStore.FindTranslationForSelectedLanguage(_.Prodname);
        _.Note = this.translationStore.FindTranslationForSelectedLanguage(_.Note);
        return _;
      }));
      super.SetLoadFinishState();
    });
  }

  public getFertilizerOrganic(): IFertilizerOrganic[] {
    return super.Listen(s => s.data).getValue();
  }

  /**
   *  load fertilizer organic from Backend to the Store
   */
  public loadFertilizerOrganic(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new FertilizerOrganicLoad([]));
  }

  /**
   * create fertilizer organic via backend
   */
  public createFertilizerOrganic(fertilizerOrganic: IFertilizerOrganic): void {
    this.DispatchBackend(new FertilizerOrganicCreate([
      {
        Name: 'fertilizerOrganic',
        Type: ApCustomTypes.Data_BaseData_FertilizerOrg,
        Value: this._mapFertilizer(fertilizerOrganic)
      }
    ]));
  }

  /**
   * update fertilizer organic via backend
   */
  public updateFertilizerOrganic(fertilizerOrganic: IFertilizerOrganic): void {
    this.DispatchBackend(new FertilizerOrganicUpdate([
      {
        Name: 'fertilizerOrganic',
        Type: ApCustomTypes.Data_BaseData_FertilizerOrg,
        Value: this._mapFertilizer(fertilizerOrganic)
      }
    ]));
  }

  /**
   * updates selectableFertilizer via backend
   */
  public updateSelectableFertilizer(fertilizerOrganic: IFertilizerOrganic): void {
    this.DispatchBackend(new FertilizerOrganicSelectableUpdate([
      {Name: 'fertilizerOrganic', Type: ApCustomTypes.Data_BaseData_FertilizerOrg, Value: fertilizerOrganic},
    ]));
  }

  private _mapFertilizer(fertilizer: IFertilizerOrganic): IFertilizerOrganic {
    fertilizer.RealContentCaO = fertilizer.ContentCaO;
    if (fertilizer.AlkalineEff) {
      if (fertilizer['AlkalineCaO'] > 0) {
        fertilizer.ContentCaO = fertilizer['AlkalineCaO'];
      } else {
        fertilizer.ContentCaO = fertilizer['ContentNeutralisationValue'];
      }
    }
    return fertilizer;
  }
}

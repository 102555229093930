import {Observable}                      from 'rxjs';
import {ERROR_CONFIRM, ERROR_SEND}       from './ap-errorhandler.action';
import {Injectable}                      from '@angular/core';
import {ApCustomTypes} from 'invoker-transport';
import IClientException = Data.Client.IClientException;
import IResultMessage = Invoker.IResultMessage;
import {ApSignalrService}                from '../services/ap-signalr.service';

/**
 * Custom Error Handler that sends Errors to the Gateway to Log them
 */
@Injectable({providedIn: 'root'})
export class ApErrorhandlerService {
  /**
   * listen the Error Log confirmed
   */
  public confirmedError$: Observable<IResultMessage>;

  constructor(private backend: ApSignalrService) {
    this.confirmedError$ = this.backend.registerObservable(ERROR_CONFIRM);
  }

  /**
   * Sends error log information to Gateway/Socket.
   */
  LogClientError(message: string, farmId: number, stackTrace: string, fullState: string, meta: string): void {
    this.backend.send({
      type: ERROR_SEND,
      payload: [
        {
          Name: 'error', Type: ApCustomTypes.Data_Client_ClientException, Value:
            {
              Message: message,
              FarmId: farmId,
              StackTrace: stackTrace,
              FullState: fullState,
              Meta: meta
            } as IClientException
        }
      ]
    });
  }
}

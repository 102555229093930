<kendo-chart *ngFor="let chartSeries of data" [style.height]="(100/data.length)+'%'" [zoomable]="true" [pannable]="true">
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item>
      <kendo-chart-category-axis-item-labels color="black" [step]="chartSeries.step ?? 1" [rotation]="-45" [title]="{ text: chartSeries.axes.titleY | translate }">
      </kendo-chart-category-axis-item-labels>
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item [title]="{ text: chartSeries.axes.titleX | translate }"
                                 color="black">
    </kendo-chart-value-axis-item>
  </kendo-chart-value-axis>
  <kendo-chart-series>
  <kendo-chart-title text="{{title}}" color="black"></kendo-chart-title>
    <kendo-chart-series-item type="boxPlot"
                             [data]="chartSeries.data"
                             lowerField="lower" q1Field="q1" medianField="median"
                             q3Field="q3" upperField="upper" outliersField="outliers"
                             meanField="mean" categoryField="category"
                             colorField="color"
                             [whiskers]="{ width: 0, opacity: 0.5}"
                             [outliers]="{ border: {color: 'green'},type: 'circle'}"
                             [extremes]="{ border: { color: 'red'}, type: 'square',rotation: 45 }"
                             [median]="{width: 1}"
    >
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>


import {NgModule}                           from '@angular/core';
import {ApFleetmanagementOverviewComponent} from './components/ap-fleetmanagement-overview.component';
import {FormsModule, ReactiveFormsModule}   from '@angular/forms';
import {ApCoreModule}                       from '../ap-core/ap-core.module';
import {ApIconModule}                       from '../icons/ap-icon.module';
import {LayoutModule}                       from '@progress/kendo-angular-layout';
import {
  ButtonGroupModule,
  ButtonModule,
  DropDownButtonModule,
  SplitButtonModule
}                                           from '@progress/kendo-angular-buttons';
import {CommonModule}                       from '@angular/common';
import {
  ComboBoxModule,
  DropDownsModule
}                                           from '@progress/kendo-angular-dropdowns';
import {DateInputsModule}                   from '@progress/kendo-angular-dateinputs';
import {
  BodyModule,
  ExcelModule,
  FilterMenuModule,
  GridModule,
  HeaderModule,
  PagerModule,
  PDFModule,
  RowFilterModule
}                                           from '@progress/kendo-angular-grid';
import {ApTranslationModule}                from '../translation/ap-translation.module';
import {ApActionApplicationsComponent}      from './components/ap-action-applications.component';
import {ApFleetmanagementComponent}         from './components/ap-fleetmanagement.component';
import {ApDocuRoutes}                       from './ap-docu.routes';
import {ChartsModule}                       from '@progress/kendo-angular-charts';
import 'hammerjs';
import {ApActionLookupComponent}            from './components/ap-action-lookup.component';
import {ApUtilsModule}                      from '../ap-utils';
import {ApDynComponentModule}               from '../ap-dyncomponent/ap-dyncomponent.module';
import {ApResponsiveTextModule}             from '../ap-responsive-text/ap-responsive-text.module';
import {ApFieldWorksOverviewComponent}      from './components/ap-field-works-overview.component';

@NgModule({
  declarations: [
    ApFleetmanagementOverviewComponent,
    ApActionApplicationsComponent,
    ApFleetmanagementComponent,
    ApActionLookupComponent,
    ApFieldWorksOverviewComponent
  ],
  exports: [
    ApFleetmanagementOverviewComponent,
    ApActionApplicationsComponent,
    ApFleetmanagementComponent,
    ApActionLookupComponent
  ],
  imports: [
    ReactiveFormsModule,
    ApCoreModule,
    ApIconModule,
    LayoutModule,
    ButtonModule,
    CommonModule,
    SplitButtonModule,
    ComboBoxModule,
    DateInputsModule,
    DropDownsModule,
    DropDownButtonModule,
    ExcelModule,
    PDFModule,
    FormsModule,
    GridModule,
    ApTranslationModule,
    ApDocuRoutes,
    ChartsModule,
    HeaderModule,
    BodyModule,
    RowFilterModule,
    PagerModule,
    ButtonGroupModule,
    ApUtilsModule,
    FilterMenuModule,
    ApDynComponentModule,
    ApDynComponentModule,
    ApResponsiveTextModule,
  ],
})
export class ApDocuModule {
}

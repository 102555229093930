import {Pipe, PipeTransform}        from '@angular/core';
import {ApDynGridPagerConfigBase}   from '../../config/ap-dyn-grid-pager-config-base';
import {ApDynGridPagerWizardConfig} from '../../config/ap-dyn-grid-pager-wizard-config';
import {Trace}                      from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asWizard'
})
export class AsWizardPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridPagerConfigBase): ApDynGridPagerWizardConfig {
    return config as ApDynGridPagerWizardConfig;
  }

}

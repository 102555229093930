import {IAction, IApInvokerArguments} from '../../interfaces';
import IRbStatistic = Data.Nutrients.IRbStatistic;

export enum ApRbActions {
  RB_CREATE = '[rb] create',
  RB_CREATE_FAIL = '[rb] create fail',
  RB_CREATE_SUCCESS = '[rb] create success',
  RB_LOAD = '[rb] load',
  RB_LOAD_SUCCESS = '[rb] load success',
  RB_LOAD_FAIL = '[rb] load fail',
  RB_CHECK_FIELD = '[rb] check',
  RB_CHECK_FIELD_SUCCESS = '[rb] check success',
  RB_CHECK_FIELD_FAIL = '[rb] check fail',
  RB_REFRESH = '[rb] refresh'
}

export class RbCreate implements IAction {
  readonly type = ApRbActions.RB_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class RbCreateFail implements IAction {
  readonly type = ApRbActions.RB_CREATE_FAIL;

  constructor(public payload: string) {
  }
}

export class RbCreateSuccess implements IAction {
  readonly type = ApRbActions.RB_CREATE_SUCCESS;

  constructor(public payload: string) {
  }
}

export class RbLoad implements IAction {
  readonly type = ApRbActions.RB_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class RbLoadSuccess implements IAction {
  readonly type = ApRbActions.RB_LOAD_SUCCESS;

  constructor(public payload: IRbStatistic[]) {
  }
}

export class RbRefresh implements IAction {
  readonly type = ApRbActions.RB_REFRESH;

  constructor(public payload: IRbStatistic[]) {
  }
}

export class RbLoadFail implements IAction {
  readonly type = ApRbActions.RB_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class RbCheckField implements IAction {
  readonly type = ApRbActions.RB_CHECK_FIELD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class RbCheckFieldSuccess implements IAction {
  readonly type = ApRbActions.RB_CHECK_FIELD_SUCCESS;

  constructor(public payload: string) {
  }
}


export class RbCheckFieldFail implements IAction {
  readonly type = ApRbActions.RB_CHECK_FIELD_FAIL;

  constructor(public payload: string) {
  }
}

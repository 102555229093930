<ng-container *ngIf="data.length === 0">
  <div class="empty-message">
    <p>{{emptyMessage | translate}}</p>
  </div>
</ng-container>
<!--multiple charts => ngfor -->
<ng-container *ngIf="data.length > 0">
  <h3>{{ title | translate }}</h3>
  <!-- -2 in [style.height] because <h3> tag takes some place  -->
  <kendo-chart *ngFor="let chartSeries of chartSeriesData"
               [transitions]="transitions"
               [categoryAxis]="{ categories: chartSeries.chartSet?.data?.categories }"
               [style.height]="(100/data.length - 2)+'%'"
               [zoomable]="zoomable"
               [pannable]="pannable">
    <kendo-chart-series>
      <kendo-chart-title [color]="strokeColor" [text]="chartSeries.chartSet.data?.title | translate"></kendo-chart-title>

      <!-- ... with multiple bars per category, optional notes or optional tooltip -->
      <kendo-chart-series-item *ngFor="let seriesItem of chartSeries.seriesValues"
                               [colorField]="'color'"
                               [style]="'smooth'"
                               [data]="seriesItem"
                               [noteTextField]="(chartSeries.chartSet?.noteDelegate || chartSeries.chartSet?.noteField )? 'value' : undefined"
                               [notes]="(chartSeries.chartSet?.noteDelegate || chartSeries.chartSet?.noteField) ? {
                                    label: {
                                    position: 'outside',
                                    visible: !!(chartSeries.chartSet?.noteDelegate || chartSeries.chartSet?.noteField),
                                    content: getNote(chartSeries.chartSet)
                                }} : {}"
                               [name]="chartSeries.chartSet.data.name"
                               [visual]="visual">
        <kendo-chart-series-item-tooltip *ngIf="chartSeries?.chartSet.tooltipDelegate"  [background]="backgroundColor" [color]="'#000'">
          <ng-template let-dataItem="dataItem">
            {{getTooltip(dataItem, chartSeries.chartSet)}}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>

      <!-- axis definitions -->
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [title]="{ text: chartSeries.chartSet?.axes?.titleX | translate }"
                                     [color]="strokeColor"
                                     [min]="chartSeries.chartSet?.axes?.minX"
                                     [max]="chartSeries.chartSet?.axes?.maxX">
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [categories]="chartSeries.chartSet?.data.categories"
                                        [title]="{ text: chartSeries.chartSet?.axes?.titleY | translate }"
                                        [labels]=""
                                        [color]="strokeColor">
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
    </kendo-chart-series>
  </kendo-chart>
</ng-container>

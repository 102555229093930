import {IAction, IApInvokerArguments} from '../../interfaces';
import IParameterValueHistory = Data.Parameter.IParameterValueHistory;

export enum ParameterValuesHistoryActions {
  PARAMETER_VALUE_HISTORY_LOAD = '[parameter_value_history] load',
  PARAMETER_VALUE_HISTORY_LOAD_SUCCESS = '[parameter_value_history] load success',
  PARAMETER_VALUE_HISTORY_LOAD_FAIL = '[parameter_value_history] load fail'
}

export class ParameterValueHistoryLoad implements IAction {
  readonly type = ParameterValuesHistoryActions.PARAMETER_VALUE_HISTORY_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterValueHistoryLoadSuccess implements IAction {
  readonly type = ParameterValuesHistoryActions.PARAMETER_VALUE_HISTORY_LOAD_SUCCESS;

  constructor(public payload: IParameterValueHistory[]) {
  }
}

export class ParameterValueHistoryLoadFail implements IAction {
  readonly type = ParameterValuesHistoryActions.PARAMETER_VALUE_HISTORY_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

<div class="ap-toolbar-control">
  <kendo-combobox #comboBox
                  style="width: 100px;"
                  [class.dark-bg]="true"
                  [data]="harvestYearFilter"
                  [textField]="'Year'"
                  [valueField]="'Id'"
                  [filterable]="true"
                  [value]="selectedYear"
                  (valueChange)="onHarvestYearClick($event)"
                  (filterChange)="filterChange($event)"
                  (focus)="focus()"
                  (blur)="blur(comboBox)"
                  [aptooltip]="tooltip"
                  [perpetual]="true"
  ></kendo-combobox>
</div>

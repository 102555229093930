<kendo-dialog *ngIf="dialogOpened$ | async"
              [class]="'modal-dialog'" [class.ap-modal-view]="true" [title]="title$ | async"
              (close)="close('')" [minWidth]="250" [width]="600">
  <p *ngIf="message$ | async" style="margin: 30px; text-align: center; white-space: pre-wrap;">{{message$ | async}}</p>
  <kendo-loader *ngIf="busy$ | async" size="large" [type]="'pulsing'" [themeColor]="'success'" style="margin: 30px; text-align: center"></kendo-loader>
  <ap-dynforms *ngIf="(dynFormConfig$ | async) && (busy$ | async) === false"
               [offsetBottom]="0"
               [isStatic]="true"
               [isModalForm]="true"
               [fieldsets]="(dynFormConfig$ | async).fieldSets"
               [defaultValue]="(dynFormConfig$ | async).values"
               [formValidators]="(dynFormConfig$ | async).validators"
               [width]="(dynFormConfig$ | async)?.width">
  </ap-dynforms>
  <kendo-dialog-actions *ngIf="(busy$ | async) === false">
    <button *ngFor="let button of (buttons$ | async)"
            [id]="'ap-modal-dialog-' + button.key + '-button'"
            kendoButton
            [disabled]="button.disable | apModalDialogButtonDisable:(formValid | async)"
            [primary]="!!button.primary"
            (click)="close(button.key)">
      {{button.text | translate}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

import {Injectable}      from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class StatisticChartAreaService {
  private _chartAreaWidth = new BehaviorSubject(0);
  private _chartAreaHeight = new BehaviorSubject(0);

  get chartAreaHeight(): BehaviorSubject<number> {
    return this._chartAreaHeight;
  }

  get chartAreaWidth(): BehaviorSubject<number> {
    return this._chartAreaWidth;
  }

  updateChartWidth(num: number): void {
    this._chartAreaWidth.next(num);
  }

  updateChartHeight(num: number): void {
    this._chartAreaHeight.next(num);
  }
}

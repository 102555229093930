export enum ApOperationMode {
  AppMap = 1,
  NFert = 2,
  TargetRateN = 3,
  AbsN = 4,
  Bonitur = 5,
  Const = 6,
  TargetPs = 7,
  AbsPs = 8
}

import {NgModule}                          from '@angular/core';
import {CommonModule}                      from '@angular/common';
import {ApStatisticsRoutes}                from './ap-statistics.routes';
import {ApStatisticsComponent}             from './components/ap-statistics.component';
import {ApBoxPlotChartComponent}           from './components/charts/ap-box-plot-chart.component';
import {ChartsModule}                      from '@progress/kendo-angular-charts';
import {ApTranslationModule}               from '../translation/ap-translation.module';
import {ApBarLineAreaChartComponent}       from './components/charts/ap-bar-line-area-chart.component';
import {DropDownsModule}                   from '@progress/kendo-angular-dropdowns';
import {ApDonutPieChartComponent}          from './components/charts/ap-donut-pie-chart.component';
import 'hammerjs';
import {ApBoxPlotChartPipe}                from './components/pipes/ap-box-plot-chart.pipe';
import {ApDonutPieChartPipe}               from './components/pipes/ap-donut-pie-chart.pipe';
import {ApUtilsModule}                     from '../ap-utils';
import {DropDownButtonModule}              from '@progress/kendo-angular-buttons';
import {ApBarLineAreaChartPipe}            from './components/pipes/ap-bar-line-area-chart.pipe';
import {ApMainSecondCropChartComponent}    from './components/charts/ap-main-second-crop-chart.component';
import {ApDatetimeSelectionChartComponent} from './components/charts/ap-datetime-selection-chart.component';
import {AgriportStoreModule}               from '../stores/stores.module';
import {HistogramComponent}                from './components/charts/histogram.component';
import {GetDataKeysPipe}                   from './components/pipes/get-data-keys.pipe';
import {DynamicChartHeightPipe}            from './components/pipes/dynamic-chart-height.pipe';
import {ApLineChartComponent}              from './components/charts/ap-line-chart.component';
import {NutrientsStatsComponent}           from './components/charts/nutrients.stats.component';
import {NutrientStatsFieldsPipe}           from './components/pipes/nutrient-stats-fields.pipe';
import {NutrientInfosViewComponent}        from './components/views/nutrient-infos-view.component';
import {JoinPropertyValuePipePipe}         from './components/pipes/join-property-value.pipe';
import {StatisticChartAreaService}         from './services/statistic-chart-area.service';
import {ApResponsiveTextModule}            from '../ap-responsive-text/ap-responsive-text.module';
import {
  FieldSoilPlanningYearToSimpleValueSetPipe
}                                          from './components/pipes/field-soil-planning-year-to-simple-value-set.pipe';
import {PlanningStatsComponent}            from './components/charts/planning.stats.component';
import {ApGenericBarChartComponent}        from './components/charts/ap-generic-bar-chart.component';
import {ApBaseFertOverviewStatsComponent}  from './components/charts/ap-base-fert-overview-stats.component';
import {ApDynComponentModule}              from '../ap-dyncomponent/ap-dyncomponent.module';
import {
  ApNFertilizationPlanningWizardStatsComponent
}                                          from './components/charts/n-fertilization-planning-wizard/ap-n-fertilization-planning-wizard-selection-stats.component';
import {
  ApNFertilizationNNeedStatsComponent
}                                          from './components/charts/n-fertilization-n-need-stats/n-fertilization-n-need-stats.component';
import {
  ApPlanningWizardOverviewStatsComponent
}                                          from './components/charts/planning-wizard-overview-stats/ap-planning-wizard-overview-stats.component';
import {
  ApFieldsCropRotationStatsComponent
}                                          from './components/charts/fields-crop-rotation-stats/fields-crop-rotation-stats.component';
import {
  ApDashboardStatsComponent
}                                          from './components/charts/dashboard-stats/dashboard-stats.component';
import {ApCoreModule}                      from '../ap-core/ap-core.module';
import {InfoRowViewComponent}              from './components/views/info-row-view.component';
import {InfoViewStylePipe}                 from './components/pipes/info-view-style.pipe';
import {
  ApDottedHistogramChartComponent
}                                          from './components/charts/ap-dotted-histogram-chart/ap-dotted-histogram-chart.component';
import {
  ApNFertilizationLogfileStatsComponent
}                                          from './components/charts/n-fertilization-logfile-stats/n-fertilization-logfile-stats.component';

@NgModule({
  declarations: [
    ApStatisticsComponent,
    ApBoxPlotChartComponent,
    ApBarLineAreaChartComponent,
    ApDonutPieChartComponent,
    ApBoxPlotChartPipe,
    ApDonutPieChartPipe,
    ApBarLineAreaChartPipe,
    NutrientStatsFieldsPipe,
    ApMainSecondCropChartComponent,
    ApDatetimeSelectionChartComponent,
    HistogramComponent,
    GetDataKeysPipe,
    DynamicChartHeightPipe,
    ApLineChartComponent,
    ApDottedHistogramChartComponent,
    NutrientsStatsComponent,
    NutrientInfosViewComponent,
    JoinPropertyValuePipePipe,
    InfoViewStylePipe,
    FieldSoilPlanningYearToSimpleValueSetPipe,
    PlanningStatsComponent,
    ApGenericBarChartComponent,
    ApBaseFertOverviewStatsComponent,
    ApNFertilizationPlanningWizardStatsComponent,
    ApPlanningWizardOverviewStatsComponent,
    ApNFertilizationNNeedStatsComponent,
    ApFieldsCropRotationStatsComponent,
    ApDashboardStatsComponent,
    ApNFertilizationLogfileStatsComponent,
    InfoRowViewComponent
  ],
  providers: [
    StatisticChartAreaService,
  ],
  exports: [
    ApStatisticsComponent,
    ApBoxPlotChartComponent,
    ApBarLineAreaChartComponent,
    ApDonutPieChartComponent,
    ApMainSecondCropChartComponent,
    ApDatetimeSelectionChartComponent,
    ApLineChartComponent,
    ApDottedHistogramChartComponent,
    NutrientsStatsComponent,
    PlanningStatsComponent,
    ApGenericBarChartComponent,
    ApBaseFertOverviewStatsComponent,
    ApNFertilizationPlanningWizardStatsComponent,
    ApPlanningWizardOverviewStatsComponent,
    ApNFertilizationNNeedStatsComponent,
    ApFieldsCropRotationStatsComponent,
    ApDashboardStatsComponent
  ],
  imports: [
    ApStatisticsRoutes,
    CommonModule,
    ChartsModule,
    ApTranslationModule,
    DropDownsModule,
    DropDownButtonModule,
    ApUtilsModule,
    AgriportStoreModule,
    ApResponsiveTextModule,
    ApDynComponentModule,
    ApCoreModule
  ],
})
export class ApStatisticsModule {
}

import { NgModule }                from '@angular/core';
import { CommonModule }            from '@angular/common';
import { ApDynComponentComponent } from './ap-dyncomponent.component';
import {ApDynformsModule}          from '../ap-dynforms/ap-dynforms.module';
import {ApDynGridsModule}          from '../ap-dyngrids/ap-dyngrids.module';
import {ApCoreModule}              from '../ap-core/ap-core.module';
import {ApTranslationModule}       from '../translation/ap-translation.module';

@NgModule({
  declarations: [ApDynComponentComponent],
  exports: [
    ApDynComponentComponent,
    ApDynformsModule,
    ApDynGridsModule,
  ],
  imports: [
    CommonModule,
    ApDynformsModule,
    ApDynGridsModule,
    ApCoreModule,
    ApTranslationModule
  ]
})
export class ApDynComponentModule { }

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dynamicChartHeight',
})
export class DynamicChartHeightPipe implements PipeTransform {
  transform(value: number, ...args: [number, number]): any {
    return value * (args[0] || 65) + (args[1] || 82);
  }
}

import {IStateStore}                                                   from '../../ap-interface/interfaces/store';
import {Store}                                                         from '../index';
import {Injectable}                                                    from '@angular/core';
import {MachineBreaksLoad, MachineBreaksLoadSuccess} from 'invoker-transport';
import {SafeBehaviorSubject}                                           from 'ts-tooling';
import {MapStore}                                                      from '../map/map.store';
import IMachineBreak = Data.DocuContext.Machine.IMachineBreak;
import {ApSignalrService}                                              from '../../ap-core/services/ap-signalr.service';

interface IMachineBreaksStore extends IStateStore<IMachineBreak> {
}

@Injectable({providedIn: 'root'})
export class MachineBreaksStore extends Store<IMachineBreaksStore> {
  constructor(public backend: ApSignalrService,
              private mapStore: MapStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(MachineBreaksLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      this.mapStore.Legends.onMachineBreaksChanged.emit(d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get MachineBreaks$(): SafeBehaviorSubject<IMachineBreak[]> {
    return this.Listen(s => s.data);
  }

  public get MachineBreaks(): IMachineBreak[] {
    return this.MachineBreaks$.getValue();
  }

  public getMachineBraksForKind(machineKind: number): IMachineBreak[] {
    return this.MachineBreaks.FindAll(_ => _.Kind === machineKind);
  }

  public getMachineBraksForKindAndProperty(machineKind: number, property: string): IMachineBreak[] {
    return this.MachineBreaks.FindAll(_ => _.Kind === machineKind && _.Property === property);
  }

  loadMachineBreaks(): void {
    this.DispatchBackend(new MachineBreaksLoad([]));
  }
}

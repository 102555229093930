import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType,
  ApDynGridColumnHideConfig,
  IPipeDefinition
}                       from './ap-dyn-grid-column-config-base';
import {Observable, of} from 'rxjs';
import {EventEmitter}   from '@angular/core';

export interface IGridColumnGroupHeaderButtons {
  previousClicked: EventEmitter<void>;
  previousDisabled: Observable<boolean>;
  nextClicked: EventEmitter<void>;
  nextDisabled: Observable<boolean>;
}

export class ApDynGridGroupColumnConfig extends ApDynGridColumnConfigBase {
  public type = ApDynGridColumnControlType.ColumnGroup;
  public groupColumns: ApDynGridColumnConfigBase[];
  public title: Observable<string>;
  public headerButtons: IGridColumnGroupHeaderButtons;
  public dynamic: boolean;

  public constructor(options: {
    hide?: boolean | ApDynGridColumnHideConfig,
    sortIndex?: number,
    sortDesc?: boolean,
    groupIndex?: number,
    groupDesc?: boolean,
    headerStyle?: any,
    style?: any,
    width?: number,
    pdfExport?: boolean,
    xlsExport?: boolean,
    groupColumns?: ApDynGridColumnConfigBase[],
    headerButtons?: IGridColumnGroupHeaderButtons,
    dynamic?: boolean,
    title?: string | Observable<string>,
    cssClass?: 'left' | 'right',
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
  } = {}) {
    super(options);
    this.groupColumns = options.groupColumns ? options.groupColumns : [];
    if (options.title instanceof Observable) {
      this.title = options.title;
    } else {
      this.title = of(options.title ? options.title : ' ');
    }
    this.groupIndex = undefined;
    this.sortIndex = undefined;
    this.headerButtons = options.headerButtons;
    this.dynamic = options.dynamic === true;
  }

  equals(config: ApDynGridColumnConfigBase): boolean {
    if (config instanceof ApDynGridGroupColumnConfig) {
      return this.title === config.title && this.groupColumns.length === config.groupColumns.length &&
        !this.groupColumns.Any((c) => !config.groupColumns.Any((conf) => c.equals(conf)));
    }
    return super.equals(config);
  }
}

import {ApDynformsConfigBase}  from './ap-dynforms-config-base';
import {ApDynformsConfigArray} from './ap-dynforms-config-array';
import {TemplateRef}           from '@angular/core';

/**
 * configuration for fieldset
 */
export class ApDynformsConfigFieldset {
  /**
   * optional key for fieldset (will be used as id)
   */
  key: string;
  /**
   * translation key for fieldset's label
   */
  legend: string;
  /**
   * amounnt of fieldset columns.(default: 2)
   * controls are devided into specified amount of columns
   */
  columns: number;
  /**
   * additional text at which is displayed at top of the fieldset (translation key)
   */
  label: string;
  /**
   * a list of control configuration contained in this fieldset
   */
  config: ApDynformsConfigBase<any>[] = [];
  /**
   * Indicates that fieldset columns should use all of the available space.
   */
  useMaxWidth: boolean;
  /**
   * list of additional css class for fieldset
   */
  classList: string[];
  /**
   * Use column gap
   */
  columnGap: boolean;

  arrayConfig: ApDynformsConfigArray[] = [];

  before: TemplateRef<any> | null;

  /**
   * Setting if translation should be generated async-way with observables
   */
  asyncTranslation: boolean;

  /**
   * Optional setting to hide the fieldset's legend line and text.
   * This is useful to build individual form layouts where some fieldsets
   * may have different amount of columns
   */
  hideLegend = false;

  /**
   * Constructor
   */
  constructor(options: {
    legend?: string,
    columns?: number,
    label?: string,
    key: string,
    useMaxWidth?: boolean,
    config?: ApDynformsConfigBase<any>[],
    arrayConfig?: ApDynformsConfigArray[],
    formErrors?: string[],
    columnGap?: boolean,
    before?: TemplateRef<any> | null,
    classList?: string | string[],
    asyncTranslation?: boolean,
    hideLegend?: boolean
  }) {
    this.legend = options.legend;
    this.columns = options.columns || 2;
    this.config = options.config || [];
    this.label = options.label;
    this.key = options.key;
    this.useMaxWidth = options.useMaxWidth;
    this.arrayConfig = options.arrayConfig || [];
    this.columnGap = options.columnGap !== false;
    this.before = options.before || null;
    this.asyncTranslation = options.asyncTranslation === true;
    this.hideLegend = options.hideLegend === true;
    if (Array.isArray(options.classList)) {
      this.classList = options.classList;
    } else {
      this.classList = options.classList ? [options.classList] : [];
    }
  }

  get class(): string {
    return ['ap-fieldset'].concat(this.classList).reduce((a, b) => `${a} ${b}`, '');
  }
}

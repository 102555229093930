import {Component, Input}              from '@angular/core';
import {ApDynGridTemplateColumnConfig} from '../config/ap-dyn-grid-template-column-config';

@Component({
  selector: 'ap-template-column-header',
  template: `
    <ng-container
      [ngTemplateOutlet]="config.header"
      [ngTemplateOutletContext]="config | getTemplateContext"
    ></ng-container>
  `,
  styles: []
})
export class ApTemplateColumnHeaderComponent {
  @Input() config: ApDynGridTemplateColumnConfig;
}
@Component({
  selector: 'ap-template-column-cell',
  template: `
    <ng-container
      [ngTemplateOutlet]="config.template"
      [ngTemplateOutletContext]="config | getTemplateContext:dataItem"
    ></ng-container>
  `,
  styles: []
})
export class ApTemplateColumnCellComponent {
  @Input() config: ApDynGridTemplateColumnConfig;
  @Input() dataItem: any;
}

import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {combineLatest, Observable}                           from 'rxjs';
import {INVALID_ID}                                          from './ap-validator';
import {map}                                                 from 'rxjs/operators';
import {ApGuidUtil}                                          from '../../ap-utils';
import IDateRange = Data.BaseData.IDateRange;
import IDateRangeAssigment = Data.BaseData.IDateRangeAssigment;

export class ApDatesOverlapValidator {
  private static _validate(current: IDateRange, next: IDateRange): { [key: string]: boolean } | null {
    if (current.Id !== next.Id) {
      if (
        (current.Start < next.Start && next.Start < current.End) ||
        (current.Start < next.End && next.End < current.End) ||
        (next.Start < current.Start && current.Start < next.End) ||
        (next.Start < current.End && current.End < next.End)) {
        const errorObject = {};
        errorObject[`${INVALID_ID}${current.Id}`] = true;
        errorObject[`${INVALID_ID}${next.Id}`] = true;
        return errorObject;
      }
    }
    return null;
  }

  static overlapCompare(current: Observable<IDateRangeAssigment[]>, next: Observable<IDateRange[]>): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return combineLatest([current, next]).pipe(
        map(([assignments, dateRanges]) => {
          if (control.value && control.value.Id !== ApGuidUtil.getEmptyGuid() && assignments) {
            const assignment = assignments.find(a => a.Id === control.value.Id);
            let errorObject = {};
            if (assignment) {
              for (const c of assignment.DateRanges) {
                for (const n of dateRanges) {
                  const validate = this._validate(c, n);
                  if (validate) {
                    errorObject = {...errorObject, ...validate};
                  }
                }
              }
            }
            if (Object.keys(errorObject).length !== 0) {
              return errorObject;
            }
          }
          return null;
        }));
    };
  }

  static overlap(dateRanges$: Observable<IDateRange[]>): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      return dateRanges$.pipe(
        map((dateRanges) => {
          if (control.value && control.value.Id !== ApGuidUtil.getEmptyGuid()) {
            let errorObject = {};
            for (let i = 0; i < dateRanges.length; ++i) {
              for (let j = 0; j < i; ++j) {
                const validate = this._validate(dateRanges[i], dateRanges[j]);
                if (validate) {
                  errorObject = {...errorObject, ...validate};
                }
              }
            }
            if (Object.keys(errorObject).length !== 0) {
              return errorObject;
            }
          }
          return null;
        }));
    };
  }
}

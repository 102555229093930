import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'apBarLineAreaChart'
})
export class ApBarLineAreaChartPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const statstic = {};
    for (const sd of value) {
      if (!statstic[sd.category]) {
        statstic[sd.category] = [];
      }
      statstic[sd.category].push(sd);
    }
    return statstic;
  }

}

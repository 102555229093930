import {environment}       from '../../environments/environment';
import {IAppConfiguration} from '../ap-interface';
import {VERSION}           from '../../environments/version';

/**
 * Client Configuration Object
 */
export const APP_CONFIGURATION: IAppConfiguration = {
  BackendAddress: environment.BackendAddress,
  StartPage: environment.StartRoute,
  TooltipDelay: 2000,
  DefaultLanguage: 'en',
  MaxNotifications: 1,
  WriteMissingTranslations: environment.WriteMissingTranslations,
  SpecialDecimalPlaces: environment.SpecialDecimalPlaces,
  UploadSaveUrl: 'uploadSaveUrl',
  UploadRemoveUrl: 'uploadRemoveUrl',
  AcademyUrl: environment.AcademyUrl,
  TeamViewerUrl: environment.TeamViewerUrl,
  ButtonZoomToMapImage: '../assets/img/zoomtomap_small.png',
  RoundNumericPlaces: 2,
  ParallelFileUpload: environment.ParallelFileUpload,
  ProfilerOn: false,
  DebugMode: environment.Debug,
  LongRunningFunctionFilter: environment.LongRunningFunctionTimeout,
  GridPageSize: environment.GridPageSize,
  GridPageSizeOptions: environment.GridPageSizeOptions,
  StoreKeys: environment.StoreKeys,
  MapFactory: environment.MapFactory,
  Revision: VERSION,
};

<div [class.ap-tabstrip-with-arrows]="hasArrows" [class.ap-tabstrip]="!hasArrows">
    <kendo-tabstrip (tabSelect)="selectTab($event)">
      <!-- Maximize Map -->
      <kendo-tabstrip-tab [cssClass]="'ap-tabstrip-arrow-button-tab'" [disabled]="(mapViewMode$ | async) === mapViewModes.MAXIMIZE">
          <ng-template kendoTabTitle>
            <div class="ap-tabstip-arrows">
              <div class="k-icon k-i-arrow-chevron-left"></div>
              <div class="k-icon k-i-arrow-chevron-left ap-tabstrip-arrows-chevron"></div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      <!-- Map -->
      <kendo-tabstrip-tab [selected]="(currentMapViewMenu$ | async) === mapViewMenus.MAP">
          <ng-template kendoTabTitle>
            <ap-map-icon class="ap-tabstrip-icon"></ap-map-icon>
          </ng-template>
          <ng-template kendoTabContent>
              <ap-map></ap-map>
          </ng-template>
      </kendo-tabstrip-tab>
      <!-- Statistic -->
      <kendo-tabstrip-tab *ngIf="(ApPermissions.SHOW_STATISTICS | getPermission | async)"
                          [selected]="(currentMapViewMenu$ | async) === mapViewMenus.STATS">
        <ng-template kendoTabTitle>
          <ap-statistic-icon class="ap-tabstrip-icon"></ap-statistic-icon>
        </ng-template>
        <ng-template kendoTabContent>
          <ap-statistics></ap-statistics>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- Help -->
      <kendo-tabstrip-tab [selected]="(currentMapViewMenu$ | async) === mapViewMenus.HELP">
          <ng-template kendoTabTitle>
            <ap-help-icon class="ap-tabstrip-icon"></ap-help-icon>
          </ng-template>
          <ng-template kendoTabContent>
                <ap-help></ap-help>
          </ng-template>
      </kendo-tabstrip-tab>
      <!-- Minimize Map -->
      <kendo-tabstrip-tab  [cssClass]="'ap-tabstrip-arrow-button-tab'">
        <ng-template kendoTabTitle>
          <div class="ap-tabstip-arrows">
            <div class="k-icon k-i-arrow-chevron-right"></div>
            <div class="k-icon k-i-arrow-chevron-right ap-tabstrip-arrows-chevron"></div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>

import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
        <path d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z" class="hoverFill"/>
    </svg>
`;

@Component({
  selector: 'ap-fungidetect-icon',
  template: CONTENT
})
export class ApFungidetectIconComponent {
}

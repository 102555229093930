import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'apDonutPieChart'
})
export class ApDonutPieChartPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const statstic = {};
    for (const sd of value) {
      if (!statstic[sd.kind]) {
        statstic[sd.kind] = [];
      }
      statstic[sd.kind].push(sd);
    }
    return statstic;
  }

}

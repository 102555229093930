import {IWizardProperties}           from '../ap-interface/interfaces';
import {map, tap}                    from 'rxjs/operators';
import {get}                         from 'lodash';
import {FormGroup}                   from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {IGridPagerSubmitData}        from '../ap-dyngrids/config/ap-dyn-grid-pager-config';
import {ApDocuUtils}                 from '../docu/utils/ap-docu-utils';
import {FleetActionAttachmentStore}  from '../stores/docu/fleet.action.attachment.store';
import {TranslationStore}            from '../stores/translation/translation.store';
import IFleetActionAttachment = Data.DocuContext.FleetManagement.IFleetActionAttachment;
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IWorktypes = Data.BaseData.IWorktypes;
import IDriver = Data.DocuContext.Driver.IDriver;
import IAttachmentApplication = Data.DocuContext.Attachment.IAttachmentApplication;
import {ObjectFactory}               from 'ts-tooling';
import {ApGuidUtil}                  from '../ap-utils';

export class ApBookingUtils {
  static checkInvalid(dataItem): boolean {
    const sourceItem: IFleetActionAttachment = dataItem['SourceItem'];
    return !sourceItem.Attachment.Instrument || !sourceItem.Attachment.WorkType;
  }

  static disableAccept(properties: IWizardProperties): boolean {
    return properties.selectedItems.some(item => ApBookingUtils.checkInvalid(item));
  }

  static disableApplications(form: FormGroup,
                             instrument: string,
                             unitSubject: BehaviorSubject<{ value: string, text: string }[]>,
                             translationStore: TranslationStore): Observable<boolean> {
    return form.valueChanges.pipe(
      map(f => ApBookingUtils._getInstrumentDefaultWorkType(get(f, instrument))),
      map(wt => ({w: wt, u: wt ? translationStore.FindTranslationForSelectedLanguage(wt.Wt_Unit) : ''})),
      tap(wt => unitSubject.next(
        wt.w ? wt.w.Unit.map(u => ({value: u, text: `${u} / ${wt.u}`})) : [])),
      map(wt => !wt.w || wt.w.Unit.length === 0));
  }

  static saveFleetActionAttachment(data: IGridPagerSubmitData,
                                   fleetActionAttachmentStore: FleetActionAttachmentStore): void {
    if (!data.form) {
      return;
    }

    const fleetActionAttachments: IFleetActionAttachment[] =
      ObjectFactory.Copy(data).items.map(i => {
        let worktype: IWorktypes;
        const fleetActionAttachment: IFleetActionAttachment = i['SourceItem'];
        const driver = data.form.get('SourceItem.Attachment.Driver');
        if (driver) {
          const value: IDriver = driver.value;
          if (value) {
            if (value.Id === ApGuidUtil.getEmptyGuid()) {
              fleetActionAttachment.Attachment.Driver = null;
            } else {
              fleetActionAttachment.Attachment.Driver = value;
            }
          }
        }
        const instrument = data.form.get('SourceItem.Attachment.Instrument');
        if (instrument) {
          const value: IInstrument = instrument.value;
          if (value) {
            if (value.Id === ApGuidUtil.getEmptyGuid()) {
              fleetActionAttachment.Attachment.Instrument = null;
              fleetActionAttachment.Attachment.WorkType = null;
              fleetActionAttachment.Attachment.WorkingWidth = null;
              fleetActionAttachment.Attachment.Application = [];
            } else {
              fleetActionAttachment.Attachment.Instrument = value;
              fleetActionAttachment.Attachment.WorkType = value.DefaultWorktype;
              fleetActionAttachment.Attachment.WorkingWidth = value.DefaultWidth;
              if (!value.DefaultWorktype.Unit.length) {
                fleetActionAttachment.Attachment.Application = [];
              }
              worktype = value.DefaultWorktype;
            }
          }
        }

        const applications = data.form.get('SourceItem.Attachment.Application');
        if (applications && worktype) {
          const values: IAttachmentApplication[] = applications.value;
          if (values) {
            fleetActionAttachment.Attachment.Application = [];
            const validValues = values.filter(v => v.Key && v.Value > 0 && worktype.Unit.indexOf(v.Unit) !== -1);
            for (const application of validValues) {
              if (application.Key) {
                fleetActionAttachment.Attachment.Application.push(application);
              }
            }
          }
        }

        const comment = data.form.get('SourceItem.Attachment.Comment');
        if (comment) {
          fleetActionAttachment.Attachment.Comment = comment.value;
        } else if (fleetActionAttachment.Attachment.Comment === null) {
          fleetActionAttachment.Attachment.Comment = '';
        }

        if (fleetActionAttachment.Attachment.IsModified && fleetActionAttachment.Attachment.Valid &&
          (!fleetActionAttachment.Attachment.Instrument || !fleetActionAttachment.Attachment.WorkType)) {
          fleetActionAttachment.Attachment.IsModified = false;

        }

        return fleetActionAttachment;
      });

    fleetActionAttachmentStore.saveFleetActionAttachments(fleetActionAttachments);
  }

  private static _getInstrumentDefaultWorkType(instrument: IInstrument): IWorktypes | null {
    return instrument ? instrument.DefaultWorktype : null;
  }
}

import {ApBaseVectorLayer}           from './ap-base-vector.layer';
import 'ol/ol.css';
import OlStyle                       from 'ol/style/Style';
import OlStyleStroke                 from 'ol/style/Stroke';
import OlStyleFill                   from 'ol/style/Fill';
import OlStyleText                   from 'ol/style/Text';
import OlFeature                     from 'ol/Feature';
import OlFormatGeoJSON               from 'ol/format/GeoJSON';
import {LayerGroupNames, LayerNames} from './layer-names';
import {ApMapControlStream}          from './ap-map-control.stream';
import {MAP_PROJECTION}              from './ap-map.settings';
import {map}                         from 'lodash';
import {APP_CONFIGURATION}           from '../../ap-core/config';
import MapBrowserEvent               from 'ol/MapBrowserEvent';
import {MapStore}                    from '../../stores/map/map.store';
import IContactInformation = Data.Common.IContactInformation;

export class ApContactLayer extends ApBaseVectorLayer {
  /**
   * the Projection of the Fields Layer Data comes from the Backend
   */
  static readonly projection: string = 'EPSG:4326';

  static readonly defaultStyle = function (): OlStyle {
    return new OlStyle({
      stroke: new OlStyleStroke({
        color: '#319FD3',
        width: 1
      }),
      fill: new OlStyleFill({
        color: 'rgba(255, 255, 255, 0.5)'
      }),
      text: new OlStyleText({
        font: '12px Calibri,sans-serif',
        stroke: new OlStyleStroke({
          color: '#fff',
          width: 3
        }),
        fill: new OlStyleFill({
          color: '#000'
        }),
      }),
    });
  };

  constructor(mapStore: MapStore, data: IContactInformation[] = []) {
    super(LayerNames.CONTACT, LayerGroupNames.NONE, mapStore);
    this.replaceSource(data)
      .then(() => {
        ApMapControlStream.listenClick$.subscribe(() => this._click());
        ApMapControlStream.listenPointerMove$.subscribe(event => this._pointermove(event));
      });
  }

  private _pointermove(event: MapBrowserEvent): void {
    if (event.dragging) {
      return;
    }
    const myFeatures = event.map.getFeaturesAtPixel(event.pixel);
    if (!myFeatures) {
      // ApMapInstance.selectedFeature(myFeatures[0]);
    }
  }

  private _click(): void {
    this.displayFeatureInfo();
  }

  private displayFeatureInfo(): any {
    return null;
  }

  /**
   * replace the Data Source of the Layer
   */
  async replaceSource(data: IContactInformation[]): Promise<void> {
    if (!data) {
      return;
    }
    /*if (data.length > 1 && this.Features.length < 1) {
      const res = await fetch(`${APP_CONFIGURATION.BackendAddress}/assets/countries.json`);
      if (res.status >= 400) {
        console.warn(`can not load Countries`);
        return;
      }
      this.readFeatures(this._read(await res.json()), ApContactLayer.defaultStyle);
    }*/
  }

  /**
   * read the Geometry Data from the List of Fields into Openlayers Layer
   */
  private _read(data: any[]): OlFeature[] {
    return map(data, f => {
      const geom = new OlFormatGeoJSON({
        dataProjection: ApContactLayer.projection,
        featureProjection: MAP_PROJECTION
      });
      const feat = geom.readFeature(f);
      feat.setId(f.properties.name);
      return feat;
    });
  }
}

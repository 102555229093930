import { Pipe, PipeTransform } from '@angular/core';
import 'ts-tooling';

@Pipe({
  name: 'sanitizeId'
})
export class SanitizeIdPipe implements PipeTransform {

  transform(key: string, parentKey?: string, parentIndex?: number): any {
    if (!key) {
      return '';
    }
    let prefix = '';
    if (parentKey) {
      prefix = `${parentKey}_`;
    }
    if (parentIndex !== undefined) {
      prefix = `${prefix}${parentIndex}_`;
    }
    return prefix.concat(key).ReplaceAll('.', '_');
  }

}

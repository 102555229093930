import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';
import * as moment                               from 'moment/moment';

/**
 * Form validator: Validates the single date time form control for min/max
 */
export class ApDateMinMaxValidator {
  static ValidateDateRange(minDate?: Date, maxDate?: Date): ValidatorFn {
    return (dateControl: AbstractControl): { [key: string]: boolean } | null => {
      if (!dateControl) {
        return null;
      }

      const currentValue = dateControl.value as Date;

      if (minDate && currentValue < minDate) {
        return {minDateFailed: true};
      }

      if (maxDate && currentValue > maxDate) {
        return {maxDateFailed: true};
      }

      return null;
    };
  }

  static getYearRangeFormatted(dateFormat: string, minDate?: Date, maxDate?: Date): string {
    return `${minDate ? moment(minDate).format(dateFormat) : ''} - ${maxDate ? moment(maxDate).format(dateFormat) : ''}`;
  }
}

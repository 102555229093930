import {NgModule}                     from '@angular/core';
import {CommonModule}                 from '@angular/common';
import {ApSectionHeaderComponent}     from './components/ap-section-header/ap-section-header.component';
import {ApSectionHeaderGridComponent} from './components/ap-section-header-grid/ap-section-header-grid.component';
import {ApIconModule}                    from '../icons/ap-icon.module';
import {ButtonGroupModule, ButtonModule} from '@progress/kendo-angular-buttons';
import {ApUtilsModule}                   from '../ap-utils';
import {ApTranslationModule}          from '../translation/ap-translation.module';
import {ApTooltipModule}              from '../ap-tooltip/ap-tooltip.module';
import {TextBoxModule}                from '@progress/kendo-angular-inputs';
import {ApPermissionModule}           from '../ap-permission/ap-permission.module';
import {ApResponsiveTextModule}       from '../ap-responsive-text/ap-responsive-text.module';

@NgModule({
  declarations: [
    ApSectionHeaderComponent,
    ApSectionHeaderGridComponent,
  ],
  imports: [
    CommonModule,
    ApIconModule,
    ButtonModule,
    ApUtilsModule,
    ApTranslationModule,
    ApTooltipModule,
    TextBoxModule,
    ApPermissionModule,
    ApResponsiveTextModule,
    ButtonGroupModule
  ],
  exports: [
    ApSectionHeaderComponent,
    ApSectionHeaderGridComponent,
  ]
})
export class ApSectionHeaderModule {
}

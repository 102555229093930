import {APP_CONFIGURATION}                       from '../ap-core/config';
import {filter, pullAt, reduce, reverse, sortBy} from 'lodash';

export interface ITracerSet {
  callCount: number;
  avgTime: number;
  sumTime: number;
}

export function Trace(): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => any {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    if (!APP_CONFIGURATION.DebugMode) {
      return undefined;
    }

    // Ensure we have the descriptor that might been overridden by another decorator
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, propertyKey);
    }

    const originalMethod = descriptor.value;
    // Redefine the method to this new method who will call the original method
    // Use the function's this context instead of the value of this when log is called (no arrow function)
    descriptor.value = function (...args: any[]): any {
      const source = this.constructor.__source ? this.constructor.__source + '_' : '';
      const uniqueName = `${source}${this.constructor.name}_${propertyKey}`;
      const startTime = window.performance.now();
      // Call the original method
      const result = originalMethod.apply(this, args);
      const endTime = window.performance.now();
      const timespan = endTime - startTime;
      if (timespan >= APP_CONFIGURATION.LongRunningFunctionFilter) {
        if (!trace.data[uniqueName]) {
          trace.data[uniqueName] = {
            callCount: 0,
            avgTime: 0,
            sumTime: 0,
          };
        }
        trace.data[uniqueName].callCount += 1;
        trace.data[uniqueName].sumTime += timespan;
        trace.data[uniqueName].avgTime = trace.data[uniqueName].sumTime / trace.data[uniqueName].callCount;
      }
      return result;
    };

    return descriptor;
  };
}

export class ApplicationTracer {
  public data: { [key: string]: ITracerSet } = {};

  public getTraceForMethod(f: string): { [key: string]: ITracerSet } {
    const tmp = {};
    for (const key in this.data) {
      if (key.Contains(f)) {
        tmp[key] = this.data[key];
      }
    }
    return tmp;
  }

  public reset(): void {
    this.data = {};
  }

  public top10avgTime(): ITracerSet[] {
    return this._top10By('avgTime', ['callCount', 'sumTime']);
  }

  public top10sumTime(): ITracerSet[] {
    return this._top10By('sumTime', ['callCount', 'avgTime']);
  }

  public top10callCount(): ITracerSet[] {
    return this._top10By('callCount', ['avgTime', 'sumTime'], false);
  }

  private _top10By(valueKey: string, add: string[] = [], rev = true): ITracerSet[] {
    const sorted = sortBy(reduce(this.data, (current, val, key) => {
      const tmp = {key, [valueKey]: val[valueKey]};
      for (const propKey of add) {
        tmp[propKey] = val[propKey];
      }
      current.push(tmp);
      return current;
    }, []), (i) => i[valueKey]);
    const idxList = pullAt(sorted, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
    return rev === true ? reverse(filter(idxList, (i) => !!i)) : filter(idxList, (i) => !!i);
  }
}

// register tracer in browser
window['trace'] = new ApplicationTracer();
declare global {
  const trace: ApplicationTracer;
}

import {Injectable}        from '@angular/core';
import {TranslationStore}  from '../../stores/translation/translation.store';
import {ApplModesStore}    from '../../stores/common/appl-modes.store';
import {ApApplicationMode} from '../../ap-interface/enums/ap-application-mode.enums';
import IApplicationMode = Data.Common.IApplicationMode;

@Injectable({providedIn: 'root'})
export class GetApplicationModeService {
  constructor(private applicationModeStore: ApplModesStore,
              private translationStore: TranslationStore) {
  }

  public GetApplicationModeById(id: number): IApplicationMode {
    return this.applicationModeStore.Listen(s => s.data).value.Find(a => a?.Id === id);
  }

  public GetApplicationModeByKey(key: string): IApplicationMode {
    return this.applicationModeStore.Listen(s => s.data).value.Find(a => a?.Key === key);
  }

  public GetApplicationModeTranslation(mode: IApplicationMode): string {
    return this.translationStore.FindTranslationForSelectedLanguage(mode?.Key);
  }

  public GetApplicationModeStatusCss(mode: IApplicationMode): string {
    if (mode?.Id === ApApplicationMode.Constant) {
      return 'ap-status-yellow';
    }
    return 'ap-status-green';
  }
}

import {AfterContentInit, Component, OnDestroy, OnInit, ViewChild}    from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subscription} from 'rxjs';
import {ObjectFactory}                                                from 'ts-tooling';
import {filter, map, skip}                                            from 'rxjs/operators';
import {
  ApDynGridColumnConfigBase
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPagerWizardButton,
  ApDynGridPagerWizardConfig,
  ApDynGridPagerWizardSection
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {
  ApDynGridStatusColumnConfig
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-status-column-config';
import {
  ApDynGridPropertyColumnConfig,
  FilterType
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridGroupColumnConfig
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-group-column-config';
import {
  ApDynGridEditColumnConfig
}                                                                     from '../../ap-dyngrids/config/ap-dyn-grid-edit-column-config';
import {
  LanguageStore
}                                                                     from '../../stores/translation/language.store';
import {
  SettingsStore
}                                                                     from '../../stores/base-data/settings.store';
import {
  FieldStore
}                                                                     from '../../stores/farm/field.store';
import {
  ApTranslationService
}                                                                     from '../../ap-utils/service/ap-translation.service';
import {
  FormStore
}                                                                     from '../../stores/layout/form.store';
import {
  CampaignYearStore
}                                                                     from '../../stores/login/campaignyear.store';
import {
  ApUtilService
}                                                                     from '../../ap-utils/service/ap-util.service';
import {
  ApDynformsConfigFieldset
}                                                                     from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigDatepicker
}                                                                     from '../../ap-dynforms/config/ap-dynforms-config-datepicker';
import {
  ApDynformsConfigPlaceholder
}                                                                     from '../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {
  IModalDialogData,
  ModalDialogButtonDisable
}                                                                     from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {
  FertilizerStore
}                                                                     from '../../stores/base-data/fertilizer.store';
import {
  ModalDialogStore
}                                                                     from '../../stores/dialog/modal.dialog.store';
import {
  BasicFertilisationSummariesStore
}                                                                     from '../../stores/taskmanagement/basic.fertilisation.summaries.store';
import {
  ApDynformsConfigComboBox
}                                                                     from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  DriverStore
}                                                                     from '../../stores/docu/driver.store';
import {
  InstrumentStore
}                                                                     from '../../stores/docu/instrument.store';
import {
  MachineStore
}                                                                     from '../../stores/docu/machine.store';
import {
  ApDynformsConfigTimepicker
}                                                                     from '../../ap-dynforms/config/ap-dynforms-config-timepicker';
import {
  ApDynComponentComponent
}                                                                     from '../../ap-dyncomponent/ap-dyncomponent.component';
import {
  LoginStore
}                                                                     from '../../stores/login/login.store';
import {ApTimeSpanUtil, GetRoundNumericPipe}                          from '../../ap-utils';
import {
  NutrientManagementService
}                                                                     from '../../nutrient-management/service/nutrient-management.service';
import {
  JobsStore
}                                                                     from '../../stores/administration/jobs.store';
import {
  ApOperationMode
}                                                                     from '../../ap-interface/enums/ap-operation-mode.enum';
import {
  ApNutrientService
}                                                                     from '../../ap-utils/service/ap-nutrient.service';
import {
  ApDateService
}                                                                     from '../../ap-core/services/ap-date-service';
import {
  ColumnUnits,
  IApNutrientBookingComponentData,
  INutrientBookingGridData
}                                                                     from './ap-nutrient-booking-configs/nutrient-booking-types';
import {
  FertilizerService
}                                                                     from '../../services/data/fertilizer.service';
import {
  CampaignYearService
}                                                                     from '../../services/data/campaign-year.service';
import {
  FieldArchiveStore
}                                                                     from '../../stores/farm/field-archive.store';
import TaskManagementStatus = Data.TaskManagement.TaskManagementStatus;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;

@Component({
  selector: 'ap-nutrients-booking',
  template: `
    <ap-dyncomponent [caption]="caption"
                     [headerIcon]="'ap-icon-base-fertilization'"
                     [columns]="columns$ | async"
                     [canCreate]="false"
                     [items]="items$ | async"
                     [pager]="pager"
                     (edit)="onEdit($event)">
    </ap-dyncomponent>
  `,
  styles: []
})
export class ApNutrientBookingComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) public dynComponent: ApDynComponentComponent;
  public items$ = new BehaviorSubject<INutrientBookingGridData[]>([]);
  public columns$: Observable<ApDynGridColumnConfigBase[]> = of([]);
  public pager: ApDynGridPagerWizardConfig;
  public caption: string;

  private _columnUnits = new BehaviorSubject<ColumnUnits>({AverageUnitKey: '-', SumUnitKey: '-'});
  private _componentData: IApNutrientBookingComponentData;
  private _subscriptions: Subscription[] = [];

  constructor(private campaignYearStore: CampaignYearStore,
              private fieldStore: FieldStore,
              private formStore: FormStore,
              private languageStore: LanguageStore,
              private settingsStore: SettingsStore,
              private fertilizerStore: FertilizerStore,
              private modalDialogStore: ModalDialogStore,
              private basicFertilisationSummariesStore: BasicFertilisationSummariesStore,
              private driverStore: DriverStore,
              private dateService: ApDateService,
              private machineStore: MachineStore,
              private instrumentStore: InstrumentStore,
              private loginStore: LoginStore,
              private jobsStore: JobsStore,
              private translationService: ApTranslationService,
              private nutrientManagementService: NutrientManagementService,
              private nutrientService: ApNutrientService,
              private fertilizerService: FertilizerService,
              private campaignYearService: CampaignYearService,
              private fieldArchiveStore: FieldArchiveStore,
              private roundNumericPipe: GetRoundNumericPipe) {
  }

  public ngOnInit(): void {
    this._componentData = this.formStore.Listen(s => s.updateComponent).getValue().data;
    this.caption = this._componentData.IsBooked ? 'Global__Reset' : 'Global__Booking';
    this.columns$ = this._getColumns();
    this.pager = this._getPager();
    this._setItems();
  }

  public ngAfterContentInit(): void {
    this._subscriptions.push(
      this.dynComponent.SelectedItems$.subscribe((d: any[]) => this._onSelectionGridChange(d))
    );
    this._subscriptions.push(this.items$.subscribe((items: INutrientBookingGridData[]) => {
      const filedIdToSelect = this._componentData?.FieldIdToSelect;
      const itemsToSelect = items.filter(x => filedIdToSelect ? x?.Field?.Id === filedIdToSelect : true)
        .map(item => item.Id);
      setTimeout(() => this.dynComponent.dynGrid.gridPaging.setSelectedKeys(itemsToSelect));
    }));
  }

  public ngOnDestroy(): void {
    this.fieldStore.changeSelectedField([]);
    this._subscriptions?.forEach(x => x.unsubscribe());
  }

  public onEdit(dataItem: INutrientBookingGridData): void {
    const dialog = {
      title: this.translationService.translate('Global__Edit'),
      show: true,
      buttons: [
        {
          key: 'cancel',
          text: 'Global__Cancel',
          primary: false
        },
        {
          key: 'apply',
          text: 'Global__Ok',
          primary: true,
          disable: ModalDialogButtonDisable.FormInvalid,
        }
      ],
      formConfig: {
        fieldSets: this._getModalFieldSets(dataItem),
        validators: undefined,
        asyncValidators: undefined
      }
    } as IModalDialogData;
    const resultSubscription = this.modalDialogStore.Listen(s => s.result)
      .pipe(skip(1))
      .subscribe((result) => {
        if (result?.key === 'apply') {
          this._updateOverviewItems({ids: [dataItem['Id']], value: ObjectFactory.Copy(result.formValues)});
          resultSubscription.unsubscribe();
        } else if (result?.key === 'cancel') {
          resultSubscription.unsubscribe();
        }
      });
    this.modalDialogStore.setModalDialogData(dialog);
  }

  private _getColumns(): Observable<ApDynGridColumnConfigBase[]> {
    return combineLatest([
      this.languageStore.Listen(s => s.selectedLanguage),
      this.settingsStore.Listen(s => s.data),
      this._columnUnits
    ]).pipe(
      filter(([language, settings, _]) => !!language && settings.length !== 0),
      map(([language, settings, columnUnits]) => ({language, setting: settings[0], columnUnits})),
      map((p) => [
        new ApDynGridStatusColumnConfig({
          field: 'Status',
          class: 'StatusClass',
          description: 'StatusDesc',
          filterable: true,
          pending: this.jobsStore.BlockedFieldIdsBuWsvCeIrRbFieldRaster$,
          pendingTooltip: this.jobsStore.BlockedFieldIdsBuWsvCeIrRbFieldRasterTooltip$,
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Docu_Ini__Number',
          field: 'Field.FieldNumber',
          width: 60,
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Docu_Ini_Subnumber',
          field: 'Field.FieldSubnumber',
          width: 60,
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'Field.FieldName',
          title: 'Docu_Ini__Field',
          headerFilterable: true,
          width: 180,
          filterable: true
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Global__Area_Unit',
          field: 'Area',
          cssClass: 'right',
          filterType: FilterType.NUMBER,
          headerFilterable: true,
          width: 60,
          tooltip: (dataItem) => dataItem['Area'],
          hide: true,
          pipes: [{
            pipe: this.roundNumericPipe,
            args: [p.setting.DigitsAfterDecimalPoint]
          }]
        }),
        new ApDynGridGroupColumnConfig({
          title: 'Global__Product',
          groupColumns: [
            new ApDynGridPropertyColumnConfig({
              field: 'Product',
              title: 'Global__Product',
              width: 60
            }),
            new ApDynGridPropertyColumnConfig({
              field: 'Amount',
              title: `${this.translationService.translate('Global__Average')} [${this.translationService.translate(p.columnUnits.AverageUnitKey)}]`,
              cssClass: 'right',
              filterType: FilterType.NUMBER,
              width: 60,
              hide: true,
              pipes: [{
                pipe: this.roundNumericPipe,
                args: [0]
              }]
            }),
            new ApDynGridPropertyColumnConfig({
              field: 'Sum',
              title: `${this.translationService.translate('Global__Sum')} [${this.translationService.translate(p.columnUnits.SumUnitKey)}]`,
              cssClass: 'right',
              filterType: FilterType.NUMBER,
              width: 60,
              pipes: [{
                pipe: this.roundNumericPipe,
                args: [0]
              }]
            }),
          ]
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'ApplicationDate',
          title: 'Global_Application_Date',
          width: 80,
          filterable: true,
          format: this.languageStore.Listen(s => s.selectedLanguage).getValue().DateFormat,
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Docu_Ini__Duration',
          field: 'Duration',
          width: 60,
          format: 'HH:mm',
          hide: true
        }),
        new ApDynGridGroupColumnConfig({
          title: 'agrioptions_resources',
          hide: true,
          groupColumns: [
            new ApDynGridPropertyColumnConfig({
              field: 'Machine.NameWithSign',
              title: 'Base__Engine',
              width: 100,
              hide: true,
            }),
            new ApDynGridPropertyColumnConfig({
              field: 'Instrument.Name',
              title: 'AgriConnect_Ini__Tool',
              width: 100,
              hide: true
            }),
            new ApDynGridPropertyColumnConfig({
              field: 'Driver.ShortFullName',
              title: 'Docu_Ini__Motorist',
              width: 100,
              hide: true
            }),
          ]
        }),
        new ApDynGridEditColumnConfig({})
      ]),
    );
  }

  private _getPager(): ApDynGridPagerWizardConfig {
    const nextTitle = this._componentData.IsBooked ? 'Global__Reset' : 'Admin_Pages_Popups__Book';
    return new ApDynGridPagerWizardConfig(
      new ApDynGridPagerWizardButton('Global__Cancel', ApUtilService.createEventEmitter(this._formClose.bind(this), this._subscriptions)),
      new ApDynGridPagerWizardSection(),
      new ApDynGridPagerWizardButton(nextTitle, ApUtilService.createEventEmitter(this._booking.bind(this), this._subscriptions),
        {active: this.dynComponent.SelectedItems$.pipe(map((items) => items.length !== 0))}),
      {
        formConfig: {
          showMassEdit: false,
          onSubmit: ApUtilService.createEventEmitter(this._updateOverviewItems.bind(this), this._subscriptions),
          config: this._getPagerFieldSets()
        }
      }
    );
  }

  private _setItems(): void {
    let planBooks = this._componentData?.Summary?.BasicFertilisationPlanBooks?.filter(x => !x.DeletedAt) ?? [];
    if (this._componentData.IsBooked) {
      planBooks = planBooks.filter(x => x.BookedAt);
    } else {
      planBooks = planBooks.filter(x => !x.BookedAt);
    }
    const items: INutrientBookingGridData[] = planBooks.map(planBook => {
      const field = this.fieldStore.getFieldById(planBook.FieldId) ?? this.fieldArchiveStore.getFieldArchiveById(planBook.FieldId);
      // Workaround for summaries/planbooks with the wrong element (might be due to migration from 4.8)
      const element = planBook.Operation_Mode?.Id === ApOperationMode.Const ?
        this.nutrientManagementService.getFertilizerLeadingElement(planBook.Product_Id) :
        planBook.Element?.Id;
      const amountGood = this.nutrientService.convertNutrientToGoods(planBook.Product_Id, element, planBook.Appl_Rate);
      const driver = this.driverStore.getDriverById(planBook.ExportDriver);
      const machine = this.machineStore.getMachineById(planBook.ExportMachine);
      const instrument = this.instrumentStore.getInstrumentById(planBook.ExportInstrument);
      const fertilizer = this.fertilizerStore.getFertilizer(planBook.Product_Id);
      const area = this._getAreaHa(planBook);
      return {
        Id: planBook.Id,
        Status: planBook.Status,
        StatusClass: planBook.Status === TaskManagementStatus.Red ? 'ap-status-red' :
          planBook.Status === TaskManagementStatus.Yellow ? 'ap-status-yellow' : 'ap-status-green',
        StatusDesc: planBook.Status === TaskManagementStatus.Red ? '' :
          planBook.Status === TaskManagementStatus.Yellow ? '' : '',
        Field: field,
        Area: area,
        Product: this.fertilizerService.getFertilizerProductName(fertilizer),
        Amount: amountGood,
        Sum: amountGood * (area > 0 ? area : 1),
        ApplicationDate: this.dateService.toFarmDate(planBook.ApplicationDate).toDate(),
        Export: !!planBook.ExportedBy,
        Duration: this.dateService.getDateWithTimeFromMilliseconds(planBook.TEnd?.TotalMilliseconds ?? 0),
        Driver: driver,
        Machine: machine,
        Instrument: instrument,
        SourceItem: planBook
      } as INutrientBookingGridData;
    });
    this._applyDataForColumns(planBooks[0]);
    this.fieldStore.changeSelectedField(items.map(x => x.Field?.Id?.toString()));
    this.items$.next(items);
  }

  private _applyDataForColumns(item: IBasicFertilisationPlanBooks | undefined): void {
    const fertilizer = this.fertilizerStore.getFertilizer(item?.Product_Id);
    const columnUnits: ColumnUnits = {
      SumUnitKey: this.fertilizerService.getFertilizerUnit(fertilizer) ?? '-',
      AverageUnitKey: this.fertilizerService.getFertilizerUnit(fertilizer, true) ?? '-'
    };
    this._columnUnits.next(columnUnits);
  }

  private _booking(): void {
    const selectedItems = this.dynComponent.getSelectedItems() as INutrientBookingGridData[];
    if (!selectedItems || selectedItems.length <= 0) {
      return;
    }
    const planBooksForUpdate = selectedItems.map(selectedItem => {
      const copiedSourceItem = ObjectFactory.Copy(selectedItem.SourceItem);
      if (this._componentData.IsBooked) {
        copiedSourceItem.BookedAt = null;
        copiedSourceItem.BookedBy = null;
      } else {
        copiedSourceItem.BookedAt = new Date();
        copiedSourceItem.BookedBy = this.loginStore.UserId;
      }
      copiedSourceItem.TStart = ApTimeSpanUtil.getEmptyTimeSpan();
      copiedSourceItem.TEnd = ApTimeSpanUtil.getTimeSpanFromDate(selectedItem.Duration, true);
      copiedSourceItem.ApplicationDate = this.dateService.getDateNoon(selectedItem.ApplicationDate);
      copiedSourceItem.ExportDriver = selectedItem.Driver?.Id;
      copiedSourceItem.ExportMachine = selectedItem.Machine?.Id;
      copiedSourceItem.ExportInstrument = selectedItem.Instrument?.Id;
      return copiedSourceItem;
    });
    const planBooksForUpdateFiltered = planBooksForUpdate.filter(x => !!x);
    this.basicFertilisationSummariesStore.updateBasicFertilisationPlanBooks(planBooksForUpdateFiltered, true, true);
    this._formClose();
  }

  private _updateOverviewItems(submitData: { ids: any[], value: any }): void {
    this.items$.next(this.items$.getValue().map((planBook: INutrientBookingGridData) => {
      if (submitData.ids.includes(planBook.Id)) {
        if (!!submitData.value.ApplicationDate) {
          planBook.ApplicationDate = new Date(submitData.value.ApplicationDate);
        }
        if (!!submitData.value.Duration) {
          planBook.Duration = submitData.value.Duration;
        }
        if (!!submitData.value.Driver) {
          planBook.Driver = submitData.value.Driver;
        }
        if (!!submitData.value.Instrument) {
          planBook.Instrument = submitData.value.Instrument;
        }
        if (!!submitData.value.Machine) {
          planBook.Machine = submitData.value.Machine;
        }
      }
      return planBook;
    }));
  }

  private _onSelectionGridChange(currentSelected: INutrientBookingGridData[]): void {
    if (!currentSelected || currentSelected.length === 0) {
      this.fieldStore.changeSelectedField([]);
      return;
    }
    this.fieldStore.changeSelectedField(currentSelected.filter(x => x.Field?.Id).map(x => x.Field.Id.toString()));
  }

  private _getAreaHa(planBook: IBasicFertilisationPlanBooks): number {
    if (!planBook) {
      return 0;
    }
    if (planBook.Area > 0) {
      return planBook.Area / 10000;
    }
    const fieldGeom = this.fieldStore.getFieldGeomById(planBook.Field_Geom_Id);
    return fieldGeom?.AreaHa ?? 0;
  }

  private _formClose(): void {
    this.formStore.closeForm();
  }

  private _getPagerFieldSets(): ApDynformsConfigFieldset[] {
    const selectedCampaignYear = this.campaignYearStore.getSelectedCampaignYear().Year;
    const campaignYearRange = this.campaignYearService.getCampaignYearRange(selectedCampaignYear);
    return [
      new ApDynformsConfigFieldset({
        legend: 'Docu_Ini__Time',
        key: 'Time',
        useMaxWidth: true,
        config: [
          new ApDynformsConfigDatepicker({
            key: 'ApplicationDate',
            label: 'Global_Application_Date',
            minDate: campaignYearRange.StartYear,
            maxDate: campaignYearRange.EndYear,
          }),
          new ApDynformsConfigTimepicker({
            key: 'Duration',
            label: 'Docu_Ini__Duration',
            format: 'HH:mm',
            minSteps: 5,
            secSteps: 60
          })
        ],
      }),
      new ApDynformsConfigFieldset({
        legend: 'agrioptions_resources',
        key: 'Resources',
        useMaxWidth: true,
        config: [
          new ApDynformsConfigComboBox({
            key: 'Machine',
            label: 'Base__Engine',
            options: this.machineStore.Listen(_ => _.data),
            textField: 'NameWithSign',
            valueField: 'Id',
            valuePrimitive: false,
            value: null,
            sort: 1
          }),
          new ApDynformsConfigComboBox({
            key: 'Instrument',
            label: 'Docu_Ini__Tool',
            options: this.instrumentStore.Listen(s => s.data),
            textField: 'Name',
            valueField: 'Id',
            valuePrimitive: false,
            value: null,
            sort: 1
          }),
          new ApDynformsConfigComboBox({
            key: 'Driver',
            label: 'Docu_Ini__Motorist',
            options: this.driverStore.Listen(_ => _.data),
            textField: 'ShortFullName',
            valueField: 'Id',
            valuePrimitive: false,
            value: null,
            sort: 1
          }),
          new ApDynformsConfigPlaceholder()
        ],
      })
    ];
  }

  private _getModalFieldSets(dataItem: INutrientBookingGridData): ApDynformsConfigFieldset[] {
    const selectedCampaignYear = this.campaignYearStore.getSelectedCampaignYear().Year;
    const campaignYearRange = this.campaignYearService.getCampaignYearRange(selectedCampaignYear);
    return [
      new ApDynformsConfigFieldset({
        legend: 'Docu_Ini__Time',
        key: 'EditTime',
        useMaxWidth: true,
        config: [
          new ApDynformsConfigDatepicker({
            key: 'ApplicationDate',
            label: 'Global_Application_Date',
            value: dataItem.ApplicationDate,
            minDate: campaignYearRange.StartYear,
            maxDate: campaignYearRange.EndYear
          }),
          new ApDynformsConfigTimepicker({
            key: 'Duration',
            label: 'Docu_Ini__Duration',
            format: 'HH:mm',
            value: dataItem.Duration,
            minSteps: 5,
            secSteps: 60
          })
        ],
      }),
      new ApDynformsConfigFieldset({
        legend: 'agrioptions_resources',
        key: 'EditResources',
        useMaxWidth: true,
        config: [
          new ApDynformsConfigComboBox({
            key: 'Machine',
            label: 'Base__Engine',
            textField: 'NameWithSign',
            valueField: 'Id',
            valuePrimitive: false,
            value: dataItem.Machine,
            options: this.machineStore.Listen(_ => _.data),
            sort: 1
          }),
          new ApDynformsConfigComboBox({
            key: 'Instrument',
            label: 'Docu_Ini__Tool',
            textField: 'Name',
            valueField: 'Id',
            valuePrimitive: false,
            value: dataItem.Instrument,
            value$: this.modalDialogStore.listenChanges().pipe(
              filter(values => values['Machine'] || values['Instrument']),
              map(values => {
                if (values['Instrument']) {
                  return values['Instrument'];
                }
                const formValues = this.modalDialogStore.getFormValues();
                if (formValues['Instrument']) {
                  return formValues['Instrument'];
                }
                if (values['Machine'].DefaultInstrument) {
                  return values['Machine'].DefaultInstrument;
                }
                return null;
              })
            ),
            options: this.instrumentStore.Listen(s => s.data),
            sort: 1
          }),
          new ApDynformsConfigComboBox({
            key: 'Driver',
            label: 'Docu_Ini__Motorist',
            textField: 'ShortFullName',
            valueField: 'Id',
            valuePrimitive: false,
            value: dataItem.Driver,
            value$: this.modalDialogStore.listenChanges().pipe(
              filter(values => values['Machine'] || values['Driver']),
              map(values => {
                if (values['Driver']) {
                  return values['Driver'];
                }
                const formValues = this.modalDialogStore.getFormValues();
                if (formValues['Driver']) {
                  return formValues['Driver'];
                }
                if (values['Machine'].DefaultDriver) {
                  return values['Machine'].DefaultDriver;
                }
                return null;
              })
            ),
            options: this.driverStore.Listen(_ => _.data),
            sort: 1
          }),
          new ApDynformsConfigPlaceholder()
        ],
      })
    ];
  }
}

import {ApDynGridDetailsBaseConfig, ApDynGridDetailsConfigType} from './ap-dyn-grid-details-base-config';
import {ApDynGridColumnConfigBase}                              from '../ap-dyn-grid-column-config-base';
import {ApDynGridPagerConfigBase}                               from '../ap-dyn-grid-pager-config-base';
import {SelectableSettings}                                     from '@progress/kendo-angular-grid';

export class ApDynGridDetailsGridConfig extends ApDynGridDetailsBaseConfig {
  type = ApDynGridDetailsConfigType.Grid;
  columns: ApDynGridColumnConfigBase[];
  field: string;
  groupable: boolean;
  pager: ApDynGridPagerConfigBase;
  selectable: SelectableSettings;
  checkboxDisabled: boolean;

  constructor(options: {
    hide?: (x) => boolean,
    columns?: ApDynGridColumnConfigBase[],
    field?: string,
    groupable?: boolean,
    pager?: ApDynGridPagerConfigBase,
    selectable?: SelectableSettings,
    checkboxDisabled?: boolean,
  } = {}) {
    super(options);
    this.columns = options.columns ? options.columns : [];
    this.field = options.field ? options.field : '';
    this.groupable = options.groupable ? options.groupable : false;
    this.pager = options.pager ? options.pager : new ApDynGridPagerConfigBase();
    this.selectable = options.selectable ? options.selectable : {checkboxOnly: false, mode: 'multiple', enabled: true};
    this.checkboxDisabled = options.checkboxDisabled === true;
  }
}

import {Component, OnInit, ViewChild}   from '@angular/core';
import {FormBuilder, Validators}        from '@angular/forms';
import {ApDynformsConfigFieldset}       from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsConfigTextbox}        from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsValidator}            from '../../ap-dynforms/ap-dynforms-validator';
import {ApDynformsConfigComboBox}       from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {merge}                          from 'lodash';
import {ApDynformsComponent}            from '../../ap-dynforms/ap-dynforms.component';
import {map}                            from 'rxjs/operators';
import {InstrumentStore}                from '../../stores/docu/instrument.store';
import {ApDynformsConfigNumerictextbox} from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {WorkTypesStore}                 from '../../stores/base-data/worrktypes.store';
import {ListSortOrder, ObjectFactory}   from 'ts-tooling';
import {FormStore}                      from '../../stores/layout/form.store';
import {LoginStore}                     from '../../stores/login/login.store';
import {Observable, Subscription}       from 'rxjs';
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IWorktypes = Data.BaseData.IWorktypes;

/**
 * Component for editing or creating an instrument.
 */
@Component({
  selector: 'ap-instruments-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [loading$]="saving$">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_instruments_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_instruments_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`,
})
export class ApInstrumentsEditComponent implements OnInit {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: any;
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public worktypes: IWorktypes[];
  public caption: string;
  public saving$: Observable<boolean>;
  private _subscriptions: Array<Subscription> = [];

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private instrumentsStore: InstrumentStore,
              private worktypeStore: WorkTypesStore,
              private loginStore: LoginStore) {
    this.worktypes = this.worktypeStore.Listen(s => s.data).getValue();
    this.saving$ = this.instrumentsStore.Listen(s => s.saving);
    this._subscriptions.Add(this.instrumentsStore.Listen(s => s.saved).subscribe(saved => {
        if (saved) {
          this.formStore.closeForm();
        }
      }
    ));
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        FarmId: this.loginStore.SelectedFarmId,
        DefaultWorktype: null,
      };
    } else {
      this.isNewMode = false;
    }
    this.caption = this.isNewMode ? 'Global_Create_Instrument' : 'Global_Edit_Instrument';
    this.formBuilder();
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'instruments',
        config: [
          new ApDynformsConfigTextbox({
            key: 'Name',
            label: 'Docu_Ini__Description',
            value: this.data.Name,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'DefaultWidth',
            label: 'Docu_Ini__WorkingWidth',
            value: this.data.DefaultWidth,
            min: 0,
            max: 100,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                  validator: Validators.max(48), errorKey: 'Text_ValueInvalid'
                }
              ),
              new ApDynformsValidator({
                  validator: Validators.min(0), errorKey: 'Text_ValueInvalid'
                }
              )
            ]
          }),
          new ApDynformsConfigComboBox({
            key: 'DefaultWorktype',
            valuePrimitive: false,
            value: this.data.DefaultWorktype || null,
            valueField: 'Id',
            textField: 'Description',
            label: 'Docu_Ini__WorkType',
            options: this.worktypeStore.Listen(s => s.data).pipe(
              map((d) => ObjectFactory.Copy(d).SortBy(['Description'], [ListSortOrder.ASC]))
            ),
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          })
        ]
      })];
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    const instrument = this.data as IInstrument;
    instrument.Type = instrument.DefaultWorktype?.Type;
    if (this.isNewMode) {
      this.instrumentsStore.createInstrument(instrument);
    } else {
      this.instrumentsStore.updateInstrument(instrument);
    }
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

import {Pipe, PipeTransform}                                     from '@angular/core';
import {ApDynGridPagerWizardExport, ApDynGridPagerWizardSection} from '../../config/ap-dyn-grid-pager-wizard-config';
import {Trace}                                                   from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asWizardExport'
})
export class AsWizardExportPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridPagerWizardSection): ApDynGridPagerWizardExport {
    return config as ApDynGridPagerWizardExport;
  }

}

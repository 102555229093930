import {IAction, IApInvokerArguments} from '../../interfaces';
import IResultMessage = Invoker.IResultMessage;

export enum ApJobsActions {
  JOBS_LOAD = '[jobs] load',
  JOBS_LOAD_SUCCESS = '[jobs] load success',
  JOBS_FARM_LOAD = '[jobs] load farm',
  JOBS_FARM_LOAD_SUCCESS = '[jobs] load farm success',
  JOBS_FARM_LOAD_ALL = '[jobs] load farm all',
  JOBS_FARM_LOAD_ALL_SUCCESS = '[jobs] load farm all success',
  JOBS_CHANGE = '[jobs] change',
}

export class JobsLoad implements IAction {
  readonly type = ApJobsActions.JOBS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class JobsLoadSuccess implements IAction {
  readonly type = ApJobsActions.JOBS_LOAD_SUCCESS;

  constructor(public payload: IResultMessage[]) {
  }
}

export class JobsFarmLoad implements IAction {
  readonly type = ApJobsActions.JOBS_FARM_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class JobsFarmLoadSuccess implements IAction {
  readonly type = ApJobsActions.JOBS_FARM_LOAD_SUCCESS;

  constructor(public payload: IResultMessage) {
  }
}

export class JobsAllFarmLoad implements IAction {
  readonly type = ApJobsActions.JOBS_FARM_LOAD_ALL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class JobsAllFarmLoadSuccess implements IAction {
  readonly type = ApJobsActions.JOBS_FARM_LOAD_ALL_SUCCESS;

  constructor(public payload: IResultMessage) {
  }
}

export class JobsChange implements IAction {
  readonly type = ApJobsActions.JOBS_CHANGE;

  constructor(public payload: IResultMessage) {
  }
}

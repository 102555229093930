import IFleetAction = Data.DocuContext.FleetManagement.IFleetAction;
import IFleetActionAttachment = Data.DocuContext.FleetManagement.IFleetActionAttachment;
import IDateRangeAssigment = Data.BaseData.IDateRangeAssigment;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum FleetActionAttachmentActions {
  ATTACHMENT_DRIVER_ASSIGNMENT_GET = '[attachment] driver assigment get',
  ATTACHMENT_DRIVER_ASSIGNMENT_GET_SUCCESS = '[attachment] driver assigment get success',
  ATTACHMENT_DRIVER_ASSIGNMENT_GET_FAIL = '[attachment] driver assigment get fail',

  ATTACHMENT_INSTRUMENT_ASSIGNMENT_GET = '[attachment] instrument assigment get',
  ATTACHMENT_INSTRUMENT_ASSIGNMENT_GET_SUCCESS = '[attachment] instrument assigment get success',
  ATTACHMENT_INSTRUMENT_ASSIGNMENT_GET_FAIL = '[attachment] instrument assigment get fail',

  FIELD_ACTIONS_ATTACHMENT_LOAD = '[fleetactionattachment] load',
  FIELD_ACTIONS_ATTACHMENT_LOAD_SUCCESS = '[fleetactionattachment] load success',

  FLEET_ACTIONS_ATTACHMENT_LOAD_BY_MODIFIED = '[fleetactionattachment] load by modified',
  FLEET_ACTIONS_ATTACHMENT_LOAD_BY_MODIFIED_SUCCESS = '[fleetactionattachment] load by modified success',
  FLEET_ACTIONS_ATTACHMENT_LOAD_BY_MODIFIED_FAIL = '[fleetactionattachment] load by modified fail',

  FLEET_ACTIONS_ATTACHMENT_REMOVE_MODIFIED = '[fleetactionattachment] remove modified',
  FLEET_ACTIONS_ATTACHMENT_REMOVE_UNMODIFIED = '[fleetactionattachment] remove unmodified',

  FLEET_ACTION_ATTACHMENT_SAVE = '[fleetactionattachment] save',
  FLEET_ACTION_ATTACHMENT_SAVE_SUCCESS = '[fleetactionattachment] save success',

  FLEET_ACTION_ATTACHMENT_CREATE = '[fleetactionattachment] create',
  FLEET_ACTION_ATTACHMENT_CREATE_SUCCESS = '[fleetactionattachment] create success',
}

export class AttachmentDriverAssignmentGet implements IAction {
  readonly type = FleetActionAttachmentActions.ATTACHMENT_DRIVER_ASSIGNMENT_GET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class AttachmentDriverAssignmentGetSuccess implements IAction {
  readonly type = FleetActionAttachmentActions.ATTACHMENT_DRIVER_ASSIGNMENT_GET_SUCCESS;

  constructor(public payload: IDateRangeAssigment) {
  }
}

export class AttachmentInstrumentAssignmentGet implements IAction {
  readonly type = FleetActionAttachmentActions.ATTACHMENT_INSTRUMENT_ASSIGNMENT_GET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class AttachmentInstrumentAssignmentGetSuccess implements IAction {
  readonly type = FleetActionAttachmentActions.ATTACHMENT_INSTRUMENT_ASSIGNMENT_GET_SUCCESS;

  constructor(public payload: IDateRangeAssigment) {
  }
}

export class AttachmentInstrumentAssignmentGetFail implements IAction {
  readonly type = FleetActionAttachmentActions.ATTACHMENT_INSTRUMENT_ASSIGNMENT_GET_FAIL;

  constructor(public payload: string) {
  }
}

export class FleetActionAttachmentLoad implements IAction {
  readonly type = FleetActionAttachmentActions.FIELD_ACTIONS_ATTACHMENT_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FleetActionAttachmentLoadSuccess implements IAction {
  readonly type = FleetActionAttachmentActions.FIELD_ACTIONS_ATTACHMENT_LOAD_SUCCESS;

  constructor(public payload: IFleetAction) {
  }
}

export class FleetActionsAttachmentLoadByModified implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTIONS_ATTACHMENT_LOAD_BY_MODIFIED;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FleetActionsAttachmentLoadByModifiedSuccess implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTIONS_ATTACHMENT_LOAD_BY_MODIFIED_SUCCESS;

  constructor(public payload: IFleetAction[]) {
  }
}

export class FleetActionsAttachmentLoadByModifiedFail implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTIONS_ATTACHMENT_LOAD_BY_MODIFIED_FAIL;

  constructor(public payload: string) {
  }
}

export class FleetActionsAttachmentModifiedRemove implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTIONS_ATTACHMENT_REMOVE_MODIFIED;

  constructor() {
  }
}

export class FleetActionsAttachmentUnmodifiedRemove implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTIONS_ATTACHMENT_REMOVE_UNMODIFIED;

  constructor() {
  }
}

export class FleetActionAttachmentSave implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTION_ATTACHMENT_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FleetActionAttachmentSaveSuccess implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTION_ATTACHMENT_SAVE_SUCCESS;

  constructor(public payload: IFleetActionAttachment[]) {
  }
}

export class FleetActionAttachmentCreate implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTION_ATTACHMENT_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FleetActionAttachmentCreateSuccess implements IAction {
  readonly type = FleetActionAttachmentActions.FLEET_ACTION_ATTACHMENT_CREATE_SUCCESS;

  constructor(public payload: IFleetActionAttachment) {
  }
}

export enum AgriportConstantsEnum {
  BaseFertLicenseAmountAbs = 'BF_LICENSE_AMOUNT_ABS',
  BaseFertLicenseAmountDiff = 'BF_LICENSE_AMOUNT_DIFF',
  HistogrammExcludeLimit = 'HISTOGRAMM_EXCLUDE_LIMIT'
}

export enum BaseFertLicenseAmountAbs {
  Hectare = '0',
  Percent = '1'
}

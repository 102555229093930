import {ChangeDetectorRef, Component} from '@angular/core';
import OlStyle                        from 'ol/style/Style';
import {keys}                         from 'lodash';
import {IApLengend}                   from '../../../ap-map';
import {Color}                        from 'ol/color';

type IColor = string | Color | CanvasPattern | CanvasGradient;

@Component({
  selector: 'ap-sensor-point-legend',
  templateUrl: './sensor-point-legend.component.html',
  styleUrls: ['./sensor-point-legend.component.scss']
})
export class SensorPointLegendComponent implements IApLengend {
  visible = false;
  classification: { [key: string]: OlStyle };
  classificationProperty: string;

  constructor(private changeDetection: ChangeDetectorRef) {
  }

  getClassValues(): string[] {
    return keys(this.classification);
  }

  getColorStyleByKey(key: string): IColor {
    const defaultStyle = '#fff';
    if (this.classification) {
      const style = this.classification[key];
      return style ? style.getFill().getColor() : defaultStyle;
    }
    return defaultStyle;
  }

  fillClassification(property: string, classification: { [key: string]: OlStyle }): void {
    this.classification = classification;
    this.classificationProperty = property;
    this.changeDetection.detectChanges();
  }

  show(): void {
    this.visible = true;
    this.changeDetection.detectChanges();
  }

  hide(): void {
    this.visible = false;
    this.changeDetection.detectChanges();
  }
}

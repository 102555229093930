import {Pipe, PipeTransform} from '@angular/core';
import {TranslationStore}    from '../translation/translation.store';
import ParameterType = Data.Parameter.Enums.ParameterType;


@Pipe({
  name: 'getParameterType'
})
export class GetParameterTypePipe implements PipeTransform {
  constructor(private translationStore: TranslationStore) {
  }

  transform(parameterType: ParameterType): string {
    switch (parameterType) {
      case Data.Parameter.Enums.ParameterType.Boolean:
        return this.translationStore.FindTranslationForSelectedLanguage('Global_Yes_No');
      case Data.Parameter.Enums.ParameterType.List:
        return this.translationStore.FindTranslationForSelectedLanguage('Global__ListShort');
      case Data.Parameter.Enums.ParameterType.Number:
        return this.translationStore.FindTranslationForSelectedLanguage('Admin_Data_Collections__Number');
      case Data.Parameter.Enums.ParameterType.Text:
        return this.translationStore.FindTranslationForSelectedLanguage('Global_Text');
      case Data.Parameter.Enums.ParameterType.TimeStamp:
        return this.translationStore.FindTranslationForSelectedLanguage('Global_Timestamp');
    }
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as signalR               from '@microsoft/signalr';
import {retry}                    from 'async';
import {Guid}                     from 'ts-tooling';
import IResultMessage = Invoker.IResultMessage;
import {Config, IAction, IStoreBackend} from 'invoker-transport';
import {APP_CONFIGURATION} from '../config';

function supportWebWorkers(): boolean {
  return !!window && !!Worker;
}

const HUB_NAME = 'invokerHub';
const RETRY_COUNT = 50;
const RETRY_TIMEOUT = 200;

@Injectable({ providedIn: 'root' })
export class ApSignalrService implements IStoreBackend {

  constructor() {
    if (!ApSignalrService.established) {
      this.initializeConnection();
    }
  }

  private static tabId = new Guid().ToString();
  private static established = false;
  private static connection: signalR.HubConnection = null;
  private static dataParser = new Worker('assets/worker/unzip.js');
  private static dataPacker = new Worker('assets/worker/zip.js');
  private static _listeners: { [key: string]: EventEmitter<IResultMessage> } = {};

  public static isDebugMode = false;
  public static isLoggedIn = false;

  private static _parseData(data: string): IResultMessage {
    let DATA: any;
    try {
      DATA = JSON.parse(atob(data));
    } catch (error) {
      DATA = data;
    }
    return DATA;
  }

  private static _packData(action: any): string {
    let invokerArguments = '[]';
    if (action.payload && action.payload.map) {
      invokerArguments = JSON.stringify(action.payload.map((e) => {
        e.Value = JSON.stringify(e.Value);
        return e;
      }));
    }
    return btoa(`{"Context":"${action.type}","Arguments":${invokerArguments},"BrowserTabId":"${action.browserTabId}"}`);
  }

  private static _handleError(err: any, opt?: string): void {
    if (!err) { return; }
    let errorMsg = opt ? `"${opt}": ${ApSignalrService._stackErrorMessage(err)}` : ApSignalrService._stackErrorMessage(err);
    errorMsg += `\n\n${err.StackTraceString}`;
    const validationError = new Error(errorMsg);
    if (err['ValidationResults']) {
      validationError['ValidationResults'] = err.ValidationResults;
    }
    throw validationError;
  }

  private static _stackErrorMessage(err: System.IException): string {
    let msg = err.Message;
    let inner = err.InnerException;
    while (inner) {
      msg += `\n => ${inner.Message}`;
      inner = inner.InnerException;
    }
    return msg;
  }

  private initializeConnection(): void {
    ApSignalrService.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.buildUrl(), {
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Warning)
      .build();

    ApSignalrService.connection.onclose(() => this.handleDisconnection());
    ApSignalrService.connection.on('recieveResult', (raw) => this._handleIncoming(raw));

    // connect to the WebWorker Data Streams
    ApSignalrService.dataParser.addEventListener('message', (e) => this._incoming(e.data));
    ApSignalrService.dataParser.addEventListener('error', (err) => console.error(err));
    ApSignalrService.dataPacker.addEventListener('message', (e) => this._sendPacket(e.data));
    ApSignalrService.dataPacker.addEventListener('error', (err) => console.error(err));

    this.connect().then();
  }
  public cookieGetter: () => string = () => '';
  public versionGetter: () => string = () => APP_CONFIGURATION.Revision;

  async connect(): Promise<void> {
    if (ApSignalrService.established === true) {
      return;
    }
    try {
      ApSignalrService.established = true;
      ApSignalrService.connection.baseUrl =  this.buildUrl();
      await ApSignalrService.connection.start();
      console.log('Connected to backend.');
    } catch (err) {
      ApSignalrService.established = false;
      console.error('Failed to connect to backend:', err);
      setTimeout(() => this.connect(), 5000); // retry after 5 seconds
    }
  }

  private buildUrl(): string {
    return `${APP_CONFIGURATION.BackendAddress}/${HUB_NAME}?tabId=${ApSignalrService.tabId}&version=${this.versionGetter()}`;
  }

  public disconnect(): Promise<void> {
    ApSignalrService.established = false;
    return ApSignalrService.connection.stop();
  }

  private handleDisconnection(): void {
    ApSignalrService.established = false;
    console.log('Disconnected. Reconnecting...');
    setTimeout(() => this.connect(), 5000); // retry after 5 seconds
  }

  public getListenerByActionType(actionType: string): EventEmitter<IResultMessage> {
    if (!ApSignalrService._listeners[actionType]) {
      ApSignalrService._listeners[actionType] = new EventEmitter<IResultMessage>();
    }
    return ApSignalrService._listeners[actionType];
  }

  public registerObservable<T extends IAction>(action: (new (payload?: any) => T) | string): EventEmitter<IResultMessage> {
    const actionType = typeof action === 'string' ? action : new (action as new (payload?: any) => T)().type;
    return this.getListenerByActionType(actionType);
  }

  public send<T extends IAction>(action: T): EventEmitter<IResultMessage> {
    const listener = this.getListenerByActionType(action.type);
    if (ApSignalrService.isDebugMode && action?.type !== '[metrics] module change') {
      console.log(`%c${new Date().toISOString()}: ${action?.type?.padEnd(50, '.')} SENT`, 'color: orange; background: rgba(79, 79, 79, 0.5);');
    }
    this._handleSend(action);
    return listener;
  }

  private _sendPacket(pack: string): void {
    retry({
      times: RETRY_COUNT,
      interval: RETRY_TIMEOUT,
    }, (cb) => {
      try {
        // long story short:
        // During login (with credentials) we used to disconnect and reconnect signalR connection
        // in order to pass the generated cookieHash as url query param within the request.
        // The disconnect and reconnect after successful login caused some troubles
        // - automatic reconnect of client had side effects on login and could cause loops
        // - session handling in backend got corrupted because the Agriport-Session needs to be removed on disconnect
        // - Performance: it is faster to just continue instead of reconnecting
        // Instead of reconnecting after login we pass the cookieHash within our InvokerMessage
        ApSignalrService.connection.invoke('InvokerMessage', pack, ApSignalrService.isLoggedIn, this.cookieGetter())
          .then(() => cb())
          .catch(err => cb(err));
      } catch (err) {
        cb(err);
      }
    }, (err) => {
      if (err) {
        console.warn(`_sendPacket: error on send packet `, err, `SignalR established ${ApSignalrService.established}`);
      }
    });
  }

  private async _handleIncoming(raw: string): Promise<void> {
    if (supportWebWorkers()) {
      ApSignalrService.dataParser.postMessage(raw);
    } else {
      await this._incoming(ApSignalrService._parseData(raw));
    }
  }

  private _handleSend(msg: any): void {
    msg.browserTabId = ApSignalrService.tabId;
    if (supportWebWorkers()) {
      ApSignalrService.dataPacker.postMessage(msg);
    } else {
      this._sendPacket(ApSignalrService._packData(msg));
    }
  }

  private async _incoming(data: IResultMessage): Promise<void> {
    const listener = this.getListenerByActionType(data.Context);
    if (ApSignalrService.isDebugMode) {
      console.log(`%c${new Date().toISOString()}: ${data.Context?.padEnd(50, '.')} RECEIVED`, 'color: lightgreen; background: rgba(79, 79, 79, 0.5);');
    }
    if (listener) {
      listener.emit(data);
      ApSignalrService._handleError(data.Error, data.Context);
    }
  }
}

import IFarmUsers = Data.Authentication.IFarmUsers;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum LoginActions {
  USER_LOGIN_USERNAME = '[user] username',
  USER_LOGIN = '[user] login',
  USER_LOGIN_SUCCESS = '[user] login success',
  USER_LOGIN_FAIL = '[user] login fail',
  USER_LOGOUT = '[user] logout',
  USER_LOGOUT_SUCCESS = '[user] logout success',
  USER_LOGIN_DELAY = '[user] login delay',
  USER_LOGIN_STAY_SIGNED_IN = '[user] stay signed in',
  USER_LEAVE_PAGE = '[user] leave page',
  SET_COOKIE = 'setCookie',
  UPDATE_LOADING_PROGRESS = '[loading] update',
  UPDATE_PROGRESS_BAR_VISIBILITY = '[progress] update visibility',
  USER_LOGOUT_FROM_SYSTEM = '[user] logout from system',
  MAP_FACTORY_AUTHENTICATION_LOAD = '[farm] map factory authentication load',
  MAP_FACTORY_AUTHENTICATION_SUCCESS = '[farm] map factory authentication success',
}

export class UserLoginUsername implements IAction {
  readonly type = LoginActions.USER_LOGIN_USERNAME;

  constructor(public payload: string) {
  }
}

/**
 * Handle's the Authentication again the Invoker.
 *
 * it accepts only one Parameter that can be:
 * @example
 * // the Login Data
 * new UserLogin([
 *    {
 *      Name: 'data',
 *      Type: ApCustomTypes.Data_Authentication_Login,
 *      Value: {
 *        Email: email,
 *        Password: btoa(password),
 *        LoginTime: new Date().valueOf(),
 *        SessionDuration: this.getSessionDuration()
 *      } as ILogin
 *    }
 * ]);
 * // the Cookie Hash
 * new UserLogin([
 *    {
 *      Name: 'hash',
 *      Type: NetTypes.STRING,
 *      Value: hash
 *    }
 * ]);
 */
export class UserLogin implements IAction {
  readonly type = LoginActions.USER_LOGIN;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserLoginSuccess implements IAction {
  readonly type = LoginActions.USER_LOGIN_SUCCESS;

  constructor(public payload: IFarmUsers) {
  }
}

export class UserLoginFail implements IAction {
  readonly type = LoginActions.USER_LOGIN_FAIL;

  constructor(public payload: string) {
  }
}

/**
 * Invalidate the Session in the Invoker and logout the User.
 * The Search Id of the Session is the Cookie Hash
 *
 * @example
 * new UserLogout([
 *    {
 *      Name: 'hash',
 *      Type: NetTypes.STRING,
 *      Value: hash,
 *    }
 * ]);
 */
export class UserLogout implements IAction {
  readonly type = LoginActions.USER_LOGOUT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserLeavePage implements IAction {
  readonly type = LoginActions.USER_LEAVE_PAGE;
}

/**
 * Locks the login for a certain period of time in ms.
 *
 * @example
 * new UserLoginDelay(500);
 */
export class UserLoginDelay implements IAction {
  readonly type = LoginActions.USER_LOGIN_DELAY;

  constructor(public payload: number) {
  }
}

export class SetCookie implements IAction {
  readonly type = LoginActions.SET_COOKIE;
}

export class UpdateLoadingProgress implements IAction {
  readonly type = LoginActions.UPDATE_LOADING_PROGRESS;

  constructor(public payload: number) {
  }
}

export class UpdateProgressBarVisibility implements IAction {
  readonly type = LoginActions.UPDATE_PROGRESS_BAR_VISIBILITY;

  constructor(public payload: boolean) {
  }
}

export class LogoutFromSystem implements IAction {
  readonly type = LoginActions.USER_LOGOUT_FROM_SYSTEM;
}

export class MapFactoryAuthenticationLoad implements IAction {
  readonly type = LoginActions.MAP_FACTORY_AUTHENTICATION_LOAD;
}

export class MapFactoryAuthenticationSuccess implements IAction {
  readonly type = LoginActions.MAP_FACTORY_AUTHENTICATION_SUCCESS;

  constructor(public payload: { [key: string]: string }) {
  }
}

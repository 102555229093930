/**
 * represent the supported Input Control Types
 */
export enum ApGridPagerType {
  DropDown = 'dropDown',
  Decimal = 'decimal',
  Numeric = 'numeric',
  Calender = 'calender',
  CalenderMonthly = 'calendermonthly',
  None  = 'none',
  Text = 'text'
}

import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompositeFilterDescriptor}                                     from '@progress/kendo-data-query';
import {FilterService}                                                 from '@progress/kendo-angular-grid';
import {FormControl, FormGroup}                                        from '@angular/forms';
import {
  FilterDescriptor
}                                                                      from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import {combineLatest, Observable}                                     from 'rxjs';
import {map}                                                           from 'rxjs/operators';

@Component({
  selector: 'ap-number-filter',
  templateUrl: './ap-number-filter.component.html',
})
export class ApNumberFilterComponent implements OnInit, AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public items: any[] = [];
  @Input() public key: string;
  @Input() public format: string;
  @Output() public filterChanged = new EventEmitter<FilterDescriptor[]>(true);

  range = {min: undefined, max: undefined};

  minEnabledCtrl = new FormControl();
  minValueCtrl = new FormControl();
  maxEnabledCtrl = new FormControl();
  maxValueCtrl = new FormControl();
  valueCtrl = new FormControl();

  dynamicMin: Observable<number>;
  dynamicMax: Observable<number>;

  form: FormGroup = new FormGroup({
    minEnabled: this.minEnabledCtrl,
    minValue: this.minValueCtrl,
    maxEnabled: this.maxEnabledCtrl,
    maxValue: this.maxValueCtrl,
    value: this.valueCtrl
  });

  ngOnInit(): void {
    if (!this.items || this.items?.length === 0) {
      return;
    }

    this.range = this.items?.map((item) => item[this.key]).filter((i) => i !== undefined && !isNaN(parseFloat(i))).reduce((range, i: number) => {
      const parsedValue = parseFloat(i?.toString());
      return {
        min: range.min < parsedValue ? range.min : parsedValue,
        max: range.max > parsedValue ? range.max : parsedValue
      };
    }, this.range);

    this.dynamicMin = combineLatest([
      this.minEnabledCtrl.valueChanges,
      this.minValueCtrl.valueChanges,
      this.maxEnabledCtrl.valueChanges
    ]).pipe(
      map(([e, v]) => e && !!v ? Math.min(v, this.range.max) : this.range.min)
    );

    this.dynamicMax = combineLatest([
      this.maxEnabledCtrl.valueChanges,
      this.maxValueCtrl.valueChanges,
      this.minEnabledCtrl.valueChanges
    ]).pipe(
      map(([e, v]) => e && !!v ? Math.max(v, this.range.min) : this.range.max)
    );
  }

  ngAfterViewInit(): void {
    const currentFilters: FilterDescriptor[] = this.currentFilter?.filters as FilterDescriptor[];
    if (currentFilters && currentFilters.length === 2) {
      this.minEnabledCtrl.setValue(true);
      this.maxEnabledCtrl.setValue(true);
      this.minValueCtrl.setValue(currentFilters.find((f: FilterDescriptor) => f.operator === 'gte').value);
      this.maxValueCtrl.setValue(currentFilters.find((f: FilterDescriptor) => f.operator === 'lte').value);
    } else if (currentFilters && this.currentFilter.filters.length === 1) {
      switch (currentFilters[0].operator) {
        case 'eq':
          this.valueCtrl.setValue(currentFilters[0].value);
          this.minEnabledCtrl.setValue(false);
          this.maxEnabledCtrl.setValue(false);
          break;
        case 'gte':
          this.minValueCtrl.setValue(currentFilters[0].value);
          this.minEnabledCtrl.setValue(true);
          this.maxEnabledCtrl.setValue(false);
          break;
        case 'lte':
          this.maxValueCtrl.setValue(currentFilters[0].value);
          this.minEnabledCtrl.setValue(false);
          this.maxEnabledCtrl.setValue(true);
          break;
      }
    } else if (currentFilters && this.currentFilter.filters.length === 3) {
      // user entered single value but internally we used min/max to find rounded values
      // get user's original value from the dummy filter
      this.valueCtrl.setValue(currentFilters.find(_ => _.operator === 'isnotnull')?.value);
      this.minEnabledCtrl.setValue(false);
      this.maxEnabledCtrl.setValue(false);
    } else {
      this.minEnabledCtrl.setValue(false);
      this.maxEnabledCtrl.setValue(false);
    }

    if (this.minEnabledCtrl.value || this.maxEnabledCtrl.value) {
      this.valueCtrl.disable();
    }

    this.minEnabledCtrl.valueChanges.subscribe((v) => {
      if (v) {
        this.minValueCtrl.enable();
        this.valueCtrl.disable();
      } else {
        this.minValueCtrl.disable();
        if (!this.maxEnabledCtrl.value) {
          this.valueCtrl.enable();
        }
      }
    });

    this.minValueCtrl.valueChanges.subscribe(() => {
      this.setFilter();
    });
    if (!this.minEnabledCtrl.value) {
      this.minValueCtrl.disable();
    }

    this.maxEnabledCtrl.valueChanges.subscribe((v) => {
      if (v) {
        this.maxValueCtrl.enable();
        this.valueCtrl.disable();
      } else {
        this.maxValueCtrl.disable();
        if (!this.minEnabledCtrl.value) {
          this.valueCtrl.enable();
        }
      }
    });

    this.maxValueCtrl.valueChanges.subscribe(() => {
      this.setFilter();
    });
    if (!this.maxEnabledCtrl.value) {
      this.maxValueCtrl.disable();
    }

    this.valueCtrl.valueChanges.subscribe(() => {
      this.setFilter();
    });
  }

  setFilter(): void {
    const filters: FilterDescriptor[] = [];
    if (!this.minEnabledCtrl.value && !this.maxEnabledCtrl.value && !isNaN(parseFloat(this.valueCtrl.value))) {
      // Die Nutzereingabe definiert die Genauigkeit. Um auch die gerundeten Werte mit dem Filter
      // zu finden, muss hier rückwärts gerundet werden. So entsteht ein Zahlenbereich über den gefiltert wird.
      // Beispiele:
      // (1) Nutzereingabe: 15; Min: 14,5; Max 15,49999
      // (2) Nutzereingabe: 15,1; Min 15,05; Max: 15,149999

      const valueAsString = this.valueCtrl.value.toString().replace(',', '.');
      let decimalFactor = 1;
      if (valueAsString.includes('.')) {
        decimalFactor = Math.pow(10, valueAsString.substring(valueAsString.indexOf('.') + 1).length);
      }

      const valueAsUniformInteger = this.valueCtrl.value * decimalFactor;
      const minReverseRoundValue = (valueAsUniformInteger - 0.5) / decimalFactor;
      const maxReverseRoundValue = (valueAsUniformInteger + 0.49999999999) / decimalFactor;
      filters.push({
        field: this.key,
        operator: 'gte',
        value: minReverseRoundValue
      });
      filters.push({
        field: this.key,
        operator: 'lte',
        value: maxReverseRoundValue
      });
      // dummy filter to keep user original value when user wanted 'equals' but we are using min/max to find rounded numbers.
      // for user it should appear as a single number
      filters.push(({
        field: this.key,
        operator: 'isnotnull',
        value: this.valueCtrl.value
      }));
    }

    if (this.minEnabledCtrl.value) {
      filters.push({
        field: this.key,
        operator: 'gte',
        value: this.minValueCtrl.value
      });
    }

    if (this.maxEnabledCtrl.value) {
      filters.push({
        field: this.key,
        operator: 'lte',
        value: this.maxValueCtrl.value
      });
    }

    if (filters.length === 2 && filters[0].value > filters[1].value) {
      filters.Clear();
    }

    this.filterService?.filter({
      filters,
      logic: 'and'
    });

    if (this.filterChanged) {
      this.filterChanged.emit(filters);
    }
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IUserContacts = Data.BaseData.IUserContacts;

export enum UserContactsActions {
  USER_CONTACTS_LOAD = '[user_contacts] load',
  USER_CONTACTS_LOAD_SUCCESS = '[user_contacts] load success',
  USER_CONTACTS_UPDATE = '[user_contacts] update',
  USER_CONTACTS_CREATE = '[user_contacts] create',
  USER_CONTACTS_DELETE = '[user_contacts] delete',
  USER_CONTACTS_SAVE_SUCCESS = '[user_contacts] save success',
  USER_CONTACTS_SAVE_FAIL = '[user_contacts] save fail',
}

export class UserContactsLoad implements IAction {
  readonly type = UserContactsActions.USER_CONTACTS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserContactsLoadSuccess implements IAction {
  readonly type = UserContactsActions.USER_CONTACTS_LOAD_SUCCESS;

  constructor(public payload: IUserContacts[]) {
  }
}

export class UserContactsUpdate implements IAction {
  readonly type = UserContactsActions.USER_CONTACTS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserContactsCreate implements IAction {
  readonly type = UserContactsActions.USER_CONTACTS_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserContactsDelete implements IAction {
  readonly type = UserContactsActions.USER_CONTACTS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserContactsSaveSuccess implements IAction {
  readonly type = UserContactsActions.USER_CONTACTS_SAVE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserContactsSaveFail implements IAction {
  readonly type = UserContactsActions.USER_CONTACTS_SAVE_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {Component} from '@angular/core';

const CONTENT = `<svg
   width="131.23334mm"
   height="131.23334mm"
   viewBox="0 0 131.23334 131.23334"
   id="svg3850">
  <g
     id="layer1"
     transform="translate(-28.456855,-150.164)">
    <path
       d="m 94.073527,150.164 c -36.239191,0 -65.616672,29.37748 -65.616672,65.61667 0,36.23919 29.377481,65.61667 65.616672,65.61667 36.239183,0 65.616663,-29.37748 65.616663,-65.61667 0,-36.23919 -29.37748,-65.61667 -65.616663,-65.61667 z m 0,12.7 c 29.244923,0 52.916663,23.66725 52.916663,52.91667 0,29.24493 -23.66724,52.91667 -52.916663,52.91667 -29.24493,0 -52.916672,-23.66724 -52.916672,-52.91667 0,-29.24493 23.667244,-52.91667 52.916672,-52.91667"
       id="path130" />
    <path
       d="m 102.5851,181.87671 c 1.82163,0.0557 3.19746,1.53457 3.08877,3.4195 l -1.97847,36.10853 c -0.12359,1.02172 -0.96583,2.66155 -3.012,2.79939 l -13.105148,0.006 c -1.570138,-0.0635 -2.838747,-1.28245 -3.041524,-2.78166 l -1.975802,-36.21189 c -0.08734,-1.42503 0.90781,-3.17753 2.972744,-3.34956 z"
       id="path4718" />
    <path
       id="path4720"
       d="m 85.99228,232.06213 a 12.286249,12.241719 0 0 1 17.34801,0.96932 12.286249,12.241719 0 0 1 -0.97261,17.28514 12.286249,12.241719 0 0 1 -17.348038,-0.96886 12.286249,12.241719 0 0 1 0.972148,-17.28517 l 8.188065,9.12691 z" />
  </g>
</svg>`;

@Component({
  selector: 'ap-exclamation-circle-regular-icon',
  template: CONTENT,
})
export class ApExclamationCircleRegularIconComponent {
}

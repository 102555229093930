import {IStateStore} from '../../ap-interface/interfaces/store';
import {Store}       from '../index';
import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  DriverMachineLoad,
  DriverMachineLoadSuccess,
  DriversMachinesCreate,
  DriversMachinesDelete,
  DriversMachinesUpdate
}                    from 'invoker-transport';
import IDriverMachine = Data.DocuContext.DriversMachines.IDriverMachine;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IDriverMachineStore extends IStateStore<IDriverMachine> {
}

@Injectable({providedIn: 'root'})
export class DriverMachineStore extends Store<IDriverMachineStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(DriverMachineLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  getDriverMachine(): IDriverMachine[] {
    return super.Listen(s => s.data).getValue();
  }

  /**
   *  load drivers machines from Backend to the Store
   */
  public loadDriverMachine(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new DriverMachineLoad([]));
  }

  /**
   * updates driverMachines via backend
   */
  public updateDriverMachine(driverMachine: IDriverMachine): void {
    this.DispatchBackend(new DriversMachinesUpdate([
      {
        Name: 'driverMachine',
        Type: ApCustomTypes.Data_DocuContext_DriversMachines_DriverMachine,
        Value: driverMachine
      }
    ]));
  }

  /**
   * creates new driverMachines via backend
   */
  public createDriverMachine(driverMachine: IDriverMachine): void {
    this.DispatchBackend(new DriversMachinesCreate([
      {
        Name: 'driverMachine',
        Type: ApCustomTypes.Data_DocuContext_DriversMachines_DriverMachine,
        Value: driverMachine
      }
    ]));
  }

  /**
   * Deletes the driverMachine via backend.
   */
  public deleteDriverMachine(driverMachine: IDriverMachine): void {
    this.DispatchBackend(new DriversMachinesDelete([
      {
        Name: 'driverMachine',
        Type: ApCustomTypes.Data_DocuContext_DriversMachines_DriverMachine,
        Value: driverMachine
      }
    ]));
  }
}

import {Injectable}        from '@angular/core';
import {FertilizerStore}   from '../../stores/base-data/fertilizer.store';
import {ApOperationMode}   from '../../ap-interface/enums/ap-operation-mode.enum';
import {ApNutrientService} from '../../ap-utils/service/ap-nutrient.service';
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;

@Injectable({providedIn: 'root'})
export class NutrientManagementService {
  constructor(private fertilizerStore: FertilizerStore,
              private nutrientService: ApNutrientService) {
  }

  public getArea(summary: IBasicFertilisationSummaries, isBooking: boolean): number {
    let s: IBasicFertilisationPlanBooks[] | null;
    if (isBooking) {
      s = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy !== null);
    } else {
      s = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy === null);
    }
    return s.reduce((a, b) => a + b.Area / 10000, 0);
  }

  /**
   * Method getCalculateSumFertilizer calculates Sum of Fertilizer product in Basic Fertilization
   * by all planbooks available in summary
   * @param summary Summary to take Planbooks from
   * @param isBooking Flag that indicates what should be calculated, planned or booked records of basic fertilization
   */
  public getCalculateSumFertilizer(summary: IBasicFertilisationSummaries, isBooking: boolean): number {
    if (!summary || !summary.BasicFertilisationPlanBooks || summary.BasicFertilisationPlanBooks.length <= 0) {
      return 0;
    }
    let planBooks: IBasicFertilisationPlanBooks[] | null;
    if (isBooking) {
      planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy !== null);
    } else {
      planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy === null);
    }
    let applSum = 0;
    planBooks.forEach(planBook => {
      let planBookAppl: number;
      const planArea = planBook.Area / 10000;
      // Workaround for summaries/planbooks with the wrong element (might be due to migration from 4.8)
      const element = summary.User_Operation_Mode?.Id === ApOperationMode.Const && planBook.Operation_Mode?.Id === ApOperationMode.Const
        ? this.getFertilizerLeadingElement(planBook.Product_Id)
        : planBook.Element?.Id;
      if (planBook.Product_Id > 0) {
        planBookAppl = this.nutrientService.convertNutrientToGoods(planBook.Product_Id, element, planBook.Appl_Rate * planArea);
      } else {
        planBookAppl = planBook.Appl_Rate * planArea;
      }
      applSum += planBookAppl;
    });
    return applSum;
  }

  /**
   * Method getCalculateSumFertilizerBySinglePlanBook calculates Sum of Fertilizer product in single PlanBook of Basic Fertilization
   */
  public getCalculateSumFertilizerBySinglePlanBook(planBook: IBasicFertilisationPlanBooks): number {
    if (!planBook) {
      return 0;
    }

    const planArea = planBook.Area / 10000;
    // Workaround for summaries/planbooks with the wrong element (might be due to migration from 4.8)
    const element = planBook.Operation_Mode?.Id === ApOperationMode.Const
      ? this.getFertilizerLeadingElement(planBook.Product_Id)
      : planBook.Element?.Id;
    if (planBook.Product_Id > 0) {
      return this.nutrientService.convertNutrientToGoods(planBook.Product_Id, element, planBook.Appl_Rate * planArea);
    } else {
      return planBook.Appl_Rate * planArea;
    }
  }

  public getNutrientName(band: number): string {
    switch (band) {
      case 0:
        return 'p';
      case 1:
        return 'k';
      case 2:
        return 'mg';
      case 3:
        return 'ph';
      default:
        return '';
    }
  }

  /**
   * Bestimmt den Leitnährstoff eines Düngers
   * → Element mit höchstem Gehalt
   * 1 - P
   * 2 - K
   * 3 - Mg
   * 4 - CaO
   * @param productId the id des Düngers
   */
  public getFertilizerLeadingElement(productId: number): number {
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return null;
    }

    const elementContents = [fertilizer.ContentP, fertilizer.ContentK, fertilizer.ContentMg, fertilizer.ContentCaO];
    return elementContents.indexOf(Math.max(...elementContents)) + 1;
  }

  public getMin(summary: IBasicFertilisationSummaries, isBooking: boolean): number {
    if (!summary || !summary.BasicFertilisationPlanBooks || summary.BasicFertilisationPlanBooks.length <= 0) {
      return 0;
    }
    if (summary.User_Appl_Min !== null) {
      return summary.User_Appl_Min;
    }
    let planBooks: Data.TaskManagement.IBasicFertilisationPlanBooks[];
    if (isBooking) {
      planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy !== null);
    } else {
      planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy === null);
    }
    if (!planBooks || planBooks.length <= 0) {
      return 0;
    }
    let min = 0;
    let result = 0;
    let content = 0;
    const elementId = this.getFertilizerLeadingElement(planBooks[0].Product_Id);
    planBooks.forEach(planBook => {
      const found = planBook?.Statistic?.Find(_ => _.Band === elementId - 1);
      if (found !== null && found !== undefined) {
        if (found.Min > min) {
          min = found.Min;
        }
      }
    });
    const fertilizer = this.fertilizerStore.getFertilizer(planBooks[0].Product_Id);
    if (fertilizer) {
      switch (elementId) {
        case 1:
          content = fertilizer.ContentP > 0 ? fertilizer.ContentP : 1;
          break;
        case 2:
          content = fertilizer.ContentK > 0 ? fertilizer.ContentK : 1;
          break;
        case 3:
          content = fertilizer.ContentMg > 0 ? fertilizer.ContentMg : 1;
          break;
        case 4:
          content = fertilizer.ContentCaO > 0 ? fertilizer.ContentCaO : 1;
          break;
        case 5:
          content = fertilizer.ContentN > 0 ? fertilizer.ContentN : 1;
          break;
      }
      if (fertilizer.IsOrganic) {
        // Organic
        result = min / content;
      } else {
        // Inorganic
        result = min / content * 100;
      }
    }
    return result;
  }

  public getMax(summary: IBasicFertilisationSummaries, isBooking: boolean): number {
    if (!summary || !summary.BasicFertilisationPlanBooks || summary.BasicFertilisationPlanBooks.length <= 0) {
      return 0;
    }
    if (summary.User_Appl_Max !== null) {
      return summary.User_Appl_Max;
    }
    let planBooks: Data.TaskManagement.IBasicFertilisationPlanBooks[];
    if (isBooking) {
      planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy !== null);
    } else {
      planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy === null);
    }
    if (!planBooks || planBooks.length <= 0) {
      return 0;
    }
    let max = 0;
    let result = 0;
    let content = 0;
    const elementId = this.getFertilizerLeadingElement(planBooks[0].Product_Id);
    planBooks.forEach(planBook => {
      const found = planBook?.Statistic?.Find(_ => _.Band === elementId - 1);
      if (found !== null && found !== undefined) {
        if (found.Max > max) {
          max = found.Max;
        }
      }
    });
    const fertilizer = this.fertilizerStore.getFertilizer(planBooks[0].Product_Id);
    if (fertilizer) {
      switch (elementId) {
        case 1:
          content = fertilizer.ContentP > 0 ? fertilizer.ContentP : 1;
          break;
        case 2:
          content = fertilizer.ContentK > 0 ? fertilizer.ContentK : 1;
          break;
        case 3:
          content = fertilizer.ContentMg > 0 ? fertilizer.ContentMg : 1;
          break;
        case 4:
          content = fertilizer.ContentCaO > 0 ? fertilizer.ContentCaO : 1;
          break;
        case 5:
          content = fertilizer.ContentN > 0 ? fertilizer.ContentN : 1;
          break;
      }
      if (fertilizer.IsOrganic) {
        // Organic
        result = max / content;
      } else {
        // Inorganic
        result = max / content * 100;
      }
    }
    return result;
  }
}

<div class="ap-section-header-wrapper" style="display: grid; grid-template-columns: 34px auto 252px 63px">
    <div class="ap-section-header-icon-wrapper" *ngIf="!svg || !svg.svgPath">
        <ap-icon-selector [id]="iconId" [cssClass]="'ap-section-header-icon'"></ap-icon-selector>
    </div>
    <div style="padding-top: 5px;" *ngIf="svg && svg.svgPath">
      <ap-svg [shape]="svg.svgPath"
              [viewBox]="svg.viewBox"
              [fillColor]="'grey'"
              [strokeWidth]="0"
              [strokeColor]="'black'"
              [width]="30"
              [height]="30"></ap-svg>
    </div>
    <div class="ap-section-header-caption">
        <h1><ap-responsive-text [key]="caption" style="display:grid"></ap-responsive-text></h1>
    </div>
    <div class="ap-section-header-input-group ap-search-wrapper" [style.margin-left]="'-46px'">
      <ng-container *ngIf="canSearch">
        <div [style.border-color]="searchValue.length !== 0 ? '#b0cb1f !important': '#212529 !important'">
          <input kendoTextBox
                 spellcheck="false"
                 data-cy="grid_page_button_search"
                 [placeholder]="filterPlaceholder | translate"
                 [aptooltip]="filterTooltip"
                 [value]="searchValue"
                 (keyup)="onTextChange($event)">
        </div>
        <kendo-buttongroup>
          <button kendoButton class="k-button k-primary button-important"
                  [disabled]="searchValue.length === 0"
                  [primary]="true"
                  [icon]="'close'"
                  [toggleable]="false"
                  (click)="onResetClick()">
          </button>
          <button kendoButton class="k-button k-primary button-important"
                  [primary]="true"
                  [icon]="'filter-clear'"
                  [toggleable]="false"
                  (click)="onClearAllFiltersClick()">
          </button>
          <button kendoButton class="k-button k-primary button-important"
                  [primary]="true"
                  [icon]="'search'"
                  [toggleable]="false"
                  (click)="onSearchClick()">
          </button>
        </kendo-buttongroup>
      </ng-container>
    </div>
    <button kendoButton class="ap-section-header-create-button k-button k-primary button-important" *ngIf="showCanCreateButton"
            data-cy="grid_page_button_can_create"
            [disabled]="!canCreate"
            [primary]="true"
            [icon]="'plus'"
            (click)="onCreateClick()">{{createText | translate}}
    </button>
</div>

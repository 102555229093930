import {Component} from '@angular/core';

const CONTENT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.44 192.42">
  <g>
    <path d="M68.9,132.86h62.29V142H68.9Zm0-19.47h62.29v9.14H68.9Zm0,38.92h62.29v9.15H68.9ZM80.7,48H73A8,8,0,0,1,67.2,34.47l27-28.22a8,8,0,0,1,11.52,0l27,28.22A8,8,0,0,1,127,48H119.3V94.11a8,8,0,0,1-8,8H88.68a8,8,0,0,1-8-8Zm84,148.23V63.88L140.77,44.25a11.57,11.57,0,0,1-.51,1.39A14.33,14.33,0,0,1,127,54.38h-.84L147.65,72V179.14H52.35V44.49H59.3A14.45,14.45,0,0,1,62.58,30l2.51-2.62H35.28V196.21Z" transform="translate(-35.28 -3.79)"/>
    <path d="M86.19,93V44.73a2.48,2.48,0,0,0-2.47-2.47H73.21a2.48,2.48,0,0,1-1.79-4.19L98,10.33a2.45,2.45,0,0,1,3.57,0l26.58,27.74a2.48,2.48,0,0,1-1.79,4.19H115.88a2.48,2.48,0,0,0-2.48,2.47V93a2.48,2.48,0,0,1-2.47,2.48H88.66A2.48,2.48,0,0,1,86.19,93" transform="translate(-35.28 -3.79)" class="hoverFill"/>
  </g>
</svg>
`;

@Component({
  selector: 'ap-icon-import',
  template: CONTENT
})
export class ApIconImportComponent {
}

import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {
  ApKendoGridExtension
}                                                                     from '../../extensions/kendo/ap-kendo-grid-extension';
import {PagerContextService}                                          from '@progress/kendo-angular-grid';
import {ApDynformsComponent}                                          from '../../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsControltype
}                                                                     from '../../../ap-dynforms/config/ap-dynforms-config-base';
import {BehaviorSubject, Subscription}                                from 'rxjs';
import {delay, filter, map, mergeMap}                                 from 'rxjs/operators';
import {
  IDynGridWizardFormConfig
}                                                                     from '../../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {APP_CONFIGURATION}                                            from '../../config';

@Component({
  selector: 'ap-grid-wizard',
  templateUrl: 'ap-grid-wizard.component.html',
  styleUrls: ['ap-grid-wizard.component.scss'],
})
export class ApGridWizardComponent implements OnDestroy {
  @ViewChild(ApDynformsComponent, {static: false}) dynForm: ApDynformsComponent;
  @Input() public data: ApKendoGridExtension<any>;
  @Input() public formConfig: IDynGridWizardFormConfig;
  @Input() public showSelectedKeys = true;
  @Input() public enableMassEdit = true;
  @Output() public applyClick = new EventEmitter<any>(true);
  @Output() public cancelClick = new EventEmitter<void>(true);
  @Output() public pageSizeChanged = new BehaviorSubject<number>(APP_CONFIGURATION.GridPageSize);

  public formValues$ = new BehaviorSubject({});
  public submitDisabled$ = new BehaviorSubject<boolean>(false);

  private _formSubject$ = new BehaviorSubject<ApDynformsComponent>(undefined);
  private _subscriptions: Subscription[] = [];

  public constructor(public pagerContextService: PagerContextService) {
    this._subscriptions.push(this._formSubject$.pipe(
      filter((form) => !!form),
      mergeMap((form) => form.form.valueChanges),
      delay(0)
    ).subscribe((values) => this.formValues$.next(values)));
    this._subscriptions.push(this.formValues$.pipe(
      delay(1),
      map((values) => this.formConfig && this.formConfig.submitDisabled ?
        this.formConfig.submitDisabled(values) : false)
    ).subscribe((disabled) => this.submitDisabled$.next(disabled)));
  }

  @ViewChild(ApDynformsComponent)
  set DynForm(dynForm: ApDynformsComponent) {
    if (dynForm) {
      this.enableMassEdit = this.formConfig?.showMassEdit ?? true;
      const formValues = this._getFormValues();
      const submitDisabled = this.formConfig.submitDisabled ? this.formConfig.submitDisabled : () => false;
      this._formSubject$.next(dynForm);
      setTimeout(() => {
        this.submitDisabled$.next(submitDisabled(formValues));
        this.formValues$.next(formValues);
        dynForm.form.patchValue(formValues);
      }, 1);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  public onApplyClick(): void {
    this.applyClick.emit(this.dynForm.form.value);
  }

  public toggleMassEdit(): void {
    this.enableMassEdit = !this.enableMassEdit;
  }

  /**
   * Handles pageSizeChanged event of the gridpager.
   * Occurs whenever user changed the pageSize from the corresponding dropdown
   */
  public onPageSizeChanged(pageSize: number): void {
    this.pageSizeChanged.next(pageSize);
  }

  private _getFormValues(): any {
    const result = {};
    this.formConfig?.config?.forEach((fieldSet: any): void => {
      fieldSet.config
        .filter((config: any): boolean => config.controlType !== ApDynformsControltype.Placeholder)
        .forEach((config: any): void => {
            if (this.data.selectedItems.length !== 0 &&
              this.data.selectedItems.every((item) => item[config.key] === this.data.selectedItems[0][config.key])) {
              result[config.key] = this.data.selectedItems[0][config.key];
            } else {
              result[config.key] = undefined;
            }
          }
        );
    });
    return result;
  }
}

import {Injectable}                from '@angular/core';
import {GetRoundNumericService}    from './get-round-numeric.service';
import {APP_CONFIGURATION}         from '../../ap-core/config';
import {ApElementOxydTypeEnum}     from '../../ap-interface';
import {FactorService}             from './ap-factor.service';
import {SettingsStore}             from '../../stores/base-data/settings.store';
import {ApTranslationService}      from './ap-translation.service';
import {GetElementService}         from './ap-get-element.service';
import {FertilizerStore}           from '../../stores/base-data/fertilizer.store';
import {GetApplicationModeService} from './ap-get-applicationmode.service';
import {CampaignYearStore}         from '../../stores/login/campaignyear.store';
import {ApDateService}             from '../../ap-core/services/ap-date-service';
import {SampleDateObject}          from '../../stores/nutrients/soilsampledate.store';
import {CampaignYearService}       from '../../services/data/campaign-year.service';
import ISettings = Data.BaseData.ISettings;
import ISampleRegion = Data.Nutrients.ISampleRegion;
import IApplicationMode = Data.Common.IApplicationMode;
import ICropRotationAttributes = Data.FieldManagement.ICropRotationAttributes;

@Injectable({providedIn: 'root'})
export class ApNutrientService {
  private _settings: ISettings = null;

  constructor(private settingsStore: SettingsStore,
              private fertilizerStore: FertilizerStore,
              private elementService: GetElementService,
              private campaignYearStore: CampaignYearStore,
              private dateService: ApDateService,
              private factorService: FactorService,
              private translationService: ApTranslationService,
              private campaignYearService: CampaignYearService,
              private roundNumericService: GetRoundNumericService,
              private applicationModeService: GetApplicationModeService) {
    this.settingsStore.FirstSetting$.subscribe(s => this._settings = s);
  }

  public isOxidFarm(): boolean {
    return this._settings.UnitElementOxyd === 2;
  }

  public formatClassificationTooltip(value: number, hitClassE: boolean, method: string): string {
    if (value === undefined || value === null) {
      console.warn(`invalid value for class value in formatClassificationTooltip ${value}`);
      return '-';
    }

    const rounded = this.roundNumericService.round(value, APP_CONFIGURATION.SpecialDecimalPlaces.Distributions);
    let buffer = '';
    if (hitClassE) {
      buffer += '> ';
    }
    buffer += `${rounded}`;
    if (method) {
      buffer += ` (${method})`;
    } else {
      console.warn(`missing method in formatClassificationTooltip`);
    }
    return buffer;
  }

  public convertElementOxid(value: number, element: string, method: string): number {
    if (!this._settings) {
      console.warn(`can not convert Value no Settings found`);
      return value;
    }

    const isOxydMethod = method.EndsWith('_OXYD');
    if ((isOxydMethod && this.isOxidFarm()) ||
      (!isOxydMethod && !this.isOxidFarm())) {
      return value;
    }
    switch (element) {
      case ApElementOxydTypeEnum.P:
        if (isOxydMethod) {
          return this.factorService.calculate(value, ApElementOxydTypeEnum.P2O5, ApElementOxydTypeEnum.P);
        }
        return this.factorService.calculate(value, ApElementOxydTypeEnum.P, ApElementOxydTypeEnum.P2O5);
      case ApElementOxydTypeEnum.K:
        if (isOxydMethod) {
          return this.factorService.calculate(value, ApElementOxydTypeEnum.K2O, ApElementOxydTypeEnum.K);
        }
        return this.factorService.calculate(value, ApElementOxydTypeEnum.K, ApElementOxydTypeEnum.K2O);
      case ApElementOxydTypeEnum.Mg:
        if (isOxydMethod) {
          return this.factorService.calculate(value, ApElementOxydTypeEnum.MgO, ApElementOxydTypeEnum.Mg);
        }
        return this.factorService.calculate(value, ApElementOxydTypeEnum.Mg, ApElementOxydTypeEnum.MgO);
    }
    return value;
  }

  /**
   * Convert from Element to Product
   * @param productId of the fertiliser
   * @param element Lead nutrient
   * @param value the element amount
   */
  public convertNutrientToGoods(productId: number, element: number, value: number): number {
    if (productId === 0 || productId === null) {
      return value;
    }
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return value;
    }
    let content = 1;
    let result = value;
    switch (element) {
      case 1:
        content = fertilizer.ContentP > 0 ? fertilizer.ContentP : 1;
        break;
      case 2:
        content = fertilizer.ContentK > 0 ? fertilizer.ContentK : 1;
        break;
      case 3:
        content = fertilizer.ContentMg > 0 ? fertilizer.ContentMg : 1;
        break;
      case 4:
        content = fertilizer.ContentCaO > 0 ? fertilizer.ContentCaO : 1;
        break;
      case 5:
        content = fertilizer.ContentN > 0 ? fertilizer.ContentN : 1;
        break;
    }

    if (fertilizer.IsLiquid) {
      const density = fertilizer.Density !== null ? fertilizer.Density : 1;
      result = result / density;
    }

    if (fertilizer.IsOrganic) {
      // Organic
      return result / content;
    } else {
      // Inorganic
      return result / content * 100;
    }
  }

  /**
   * Defines the lead nutrient
   * → Element with the highest content
   * 1 -P
   * 2 - K
   * 3 - Mg
   * 4 - CaO
   * @param productId the id of the fertiliser
   */
  public getFertilizerLeadingElement(productId: number): number {
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return null;
    }

    const elementContents = [fertilizer.ContentP, fertilizer.ContentK, fertilizer.ContentMg, fertilizer.ContentCaO];
    return elementContents.indexOf(Math.max(...elementContents)) + 1;
  }

  public getFertilizerStatusClass(applicationMode: IApplicationMode, isBooking: boolean): string {
    if (isBooking) {
      return 'ap-status-grey';
    }
    return this.applicationModeService.GetApplicationModeStatusCss(applicationMode);
  }

  public getFertilizerStatusDescription(applicationMode: IApplicationMode, isBooking: boolean): string {
    let description = this.applicationModeService.GetApplicationModeTranslation(applicationMode);
    if (isBooking) {
      description = description + ' ' + `(${this.translationService.translate('Nutrients__Booked')})`;
    }
    return description;
  }

  public getGDStatusByCrAttribute(sampleDateObject: SampleDateObject, sampleRegions: ISampleRegion[], crAttribute: ICropRotationAttributes = null): [number, string] {
    const selectedCampaignYear = this.campaignYearStore.getSelectedCampaignYear();
    const sampleDateMidnight = sampleDateObject?.Date ? this.dateService.getDateMidnight(sampleDateObject.Date) : undefined;
    const soilSampleYearWithInterval = this._getEndOfCampaignYearWithSoilSampleInterval(sampleDateObject, sampleRegions);

    const hasNoSampleDate = !sampleDateObject?.Date;
    const hasNoCropInCurrentYear = !crAttribute || crAttribute.IsCropClosed === false;
    const hasNoWsvRaster = !crAttribute || crAttribute.HasWsvRaster === false;
    const hasNoIrRaster = !crAttribute || crAttribute.HasIrRaster === false;
    const hasNoRbRaster = !crAttribute || crAttribute.HasRbRaster === false;
    const sampleDateInFutureCampaignYear = sampleDateMidnight > new Date(selectedCampaignYear.DefaultEnd);
    const soilSampleWithIntervalOlderThenCurrentYear = !!soilSampleYearWithInterval && soilSampleYearWithInterval < new Date(selectedCampaignYear.DefaultStart);

    if (hasNoSampleDate || sampleDateInFutureCampaignYear || soilSampleWithIntervalOlderThenCurrentYear) {
      // grey Status
      return [2, 'Nutrient_Planning__No_Sample_Date_Found'];
    } else if (hasNoCropInCurrentYear) {
      // yellow Status
      return [0, 'Nutrient_Planning__No_Crop_In_Current_Year'];
    } else if (hasNoWsvRaster || hasNoIrRaster || hasNoRbRaster) {
      // red Status
      return [-1, 'Global__Please_Contact_The_Support'];
    }
    // green Status
    return [1, 'Global__Variable_Planning_Possible'];
  }

  /**
   * Convert from product to nutrient
   * @param productId of the fertiliser
   * @param element lead nutrient
   * @param value of the product
   * @param withOxid with oxid conversion
   */
  public convertGoodsToNutrient(productId: number, element: number, value: number, withOxid: boolean = true): number {
    if (productId === 0 || productId === null) {
      return value;
    }
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return value;
    }
    let content = 1;
    let result = value;
    let unit: ApElementOxydTypeEnum;
    switch (element) {
      case 1:
        content = fertilizer.ContentP > 0 ? fertilizer.ContentP : 1;
        unit = ApElementOxydTypeEnum.P;
        break;
      case 2:
        content = fertilizer.ContentK > 0 ? fertilizer.ContentK : 1;
        unit = ApElementOxydTypeEnum.K;
        break;
      case 3:
        content = fertilizer.ContentMg > 0 ? fertilizer.ContentMg : 1;
        unit = ApElementOxydTypeEnum.Mg;
        break;
      case 4:
        content = fertilizer.ContentCaO > 0 ? fertilizer.ContentCaO : 1;
        unit = undefined;
        break;
      case 5:
        content = fertilizer.ContentN > 0 ? fertilizer.ContentN : 1;
        unit = undefined;
        break;
    }

    if (fertilizer.IsLiquid) {
      const density = fertilizer.Density !== null ? fertilizer.Density : 1;
      result = result * density;
    }

    if (fertilizer.IsOrganic) {
      // Organic
      result = result * content;
    } else {
      // Inorganic/ mineral
      result = result * content / 100;
    }
    // convert from element to oxis if farm is oxid.
    if (withOxid) {
      return this.elementService.CalculateElementOxidValueByGivenElementValue(result, unit);
    }

    return result;
  }

  private _getEndOfCampaignYearWithSoilSampleInterval(sampleDateObject: SampleDateObject,
                                                      sampleRegions: ISampleRegion[]): Date | undefined {
    if (!sampleDateObject || !sampleDateObject.Date
      || !sampleDateObject.Region || !sampleRegions || sampleRegions.length === 0) {
      return undefined;
    }
    const sampleRegion = sampleRegions.find(x => x.Region === sampleDateObject.Region);
    if (!Number.isFinite(sampleRegion?.SoilSampleInterval)) {
      return undefined;
    }
    const soilSampleInterval = !sampleRegion.SoilSampleInterval ? 0 : sampleRegion.SoilSampleInterval;
    const soilSampleCampaignYearStart = this.campaignYearService.getCampaignYearBeginFromSampleDate(sampleDateObject.Date);
    const soilSampleLastCampaignYear = this.campaignYearService.getCampaignYearRange(
      soilSampleCampaignYearStart.getFullYear() + soilSampleInterval
    );
    return soilSampleLastCampaignYear.EndYear;
  }
}

import {EventEmitter, Injectable}   from '@angular/core';
import {MetricSendModuleUrl}        from './actions';
import {NetTypes} from 'invoker-transport';
import {ApSignalrService}           from '../../ap-core/services/ap-signalr.service';

@Injectable({providedIn: 'root'})
export class ApMetricService {

  constructor(private backend: ApSignalrService) {
  }

  public OnModuleChanged$ = new EventEmitter();

  public moduleChange(moduleUrl: string): void {
    this.backend.send(new MetricSendModuleUrl([
      {Name: 'moduleUrl', Type: NetTypes.STRING, Value: moduleUrl}
    ])).subscribe(() => this.OnModuleChanged$.emit());
  }
}

import {ApDynformsValidator}           from '../ap-dynforms-validator';
import {EventEmitter}                  from '@angular/core';
import {Observable}                    from 'rxjs';
import {AsyncValidatorFn, ValidatorFn} from '@angular/forms';
import {distinctUntilChanged}          from 'rxjs/operators';

/**
 * base configuration
 */
export class ApDynformsConfigBase<T> {
  /**
   * the value of the selector
   */
  dataCy: string;
  /**
   * the value of the corresponding control
   */
  value: T;
  /**
   * observable value of the corresponding control
   */
  value$: Observable<T>;
  /**
   * the reactive forms key (must be unique)
   */
  key: string;
  /**
   * translation key for the corresponding label
   */
  label: string;
  /**
   * observable translation key for the corresponding label
   */
  label$: Observable<string>;
  /**
   * switch for enabling/disabling the control
   */
  disabled: boolean;
  /**
   * observable switch for enabling/disabling the control
   */
  disabled$: Observable<boolean>;
  /**
   * controltype: textbox, checkbox, dropdown, ...
   */
  controlType: ApDynformsControltype;
  /**
   * a list of validators bound to the control
   */
  validators: ApDynformsValidator<ValidatorFn>[];
  /**
   * a list of async validators bound to the control
   */
  asyncValidators: ApDynformsValidator<AsyncValidatorFn>[];
  /**
   * a list of all form errors depend on this control
   */
  formErrors: string[];
  /**
   * array of all observables that causes the validators to update
   */
  listenUpdate: Observable<any>[];

  dependsOn: string[];
  /**
   * translation key for the info text
   */
  infoText: string;
  /**
   * a css class string added to the class attribute
   */
  cssClass = '';

  /**
   * Constructor: taking the control's configuration
   */
  constructor(options: {
    data_cy?: string,
    value?: T,
    value$?: Observable<T>,
    key?: string,
    label?: string,
    label$?: Observable<string>,
    disabled?: boolean,
    disabled$?: Observable<boolean>;
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    valueChange?: EventEmitter<any>,
    listenUpdate?: Observable<any>[],
    dependsOn?: string[],
    formErrors?: string[],
    infoText?: string,
    cssClass?: string,
  } = {}) {
    this.dataCy = options.data_cy || '';
    this.value = options.value;
    this.value$ = options.value$ ? options.value$.pipe(distinctUntilChanged()) : undefined;
    this.key = options.key || '';
    this.label = options.label || '';
    this.label$ = options.label$;
    this.disabled = options.disabled || false;
    this.disabled$ = options.disabled$;
    this.validators = options.validators || [];
    this.asyncValidators = options.asyncValidators || [];
    this.listenUpdate = options.listenUpdate ? options.listenUpdate : [];
    this.dependsOn = options.dependsOn;
    this.formErrors = options.formErrors || [];
    this.infoText = options.infoText || '';
    this.cssClass = options.cssClass || '';
  }
}

/**
 * enum of all available control types.
 */
export enum ApDynformsControltype {
  /**
   * A regular input control
   */
  Textbox = 'textbox',
  /**
   * multiline Textarea input
   */
  Textarea = 'textarea',
  /**
   * kendo datepicker
   */
  DatePicker = 'datepicker',
  /**
   * kendo combobox control
   */
  ComboBox = 'combobox',
  /**
   * checkbox with applied ap-styles.
   */
  Checkbox = 'checkbox',
  /**
   * numerictexbox with applied ap-styles.
   */
  NumericTextbox = 'numerictexbox',
  /**
   * kendo multiselect control
   */
  MultiSelect = 'multiselect',
  /**
   * placeholder for better styling possibilities
   */
  Placeholder = 'placeholder',
  /**
   * kendo timepicker
   */
  Timepicker = 'timepicker',
  /**
   * label with icon
   */
  LabelIcon = 'labelicon',
  /**
   * form array
   */
  Array = 'array',
  /**
   * kendo upload
   */
  Upload = 'upload',
  /**
   * kendo file select
   */
  FileSelect = 'fileselect',
  /**
   * kendo date range picker
   */
  DateRange = 'daterange',
  /**
   * password strength indicator
   */
  PasswordStrength = 'passwordstrength',
  /**
   * injecting a template (templateRef) in our forms
   */
  Template = 'template',
  /**
   * kendo button within a form
   */
  Button = 'button'
}

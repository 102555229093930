import {IAction, IApInvokerArguments} from '../../interfaces';
import ILicense = Data.Licensing.ILicense;

export enum LicenseActions {
  LICENSE_FARM_LOAD = '[license] farm load',
  LICENSE_FARM_LOAD_SUCCESS = '[license] farm load success',
  LICENSE_FARM_LOAD_ERROR = '[license] farm load error',
  LICENSE_UPDATE = '[license] update',
  LICENSE_SAVE_SUCCESS = '[license] save success',
  LICENSE_SAVE_FAILED = '[license] save failed',
  LICENSE_ADJUST_REQUEST = '[license] adjust request',
  LICENSE_ADJUST_REQUEST_FINISHED = '[license] adjust request finished'
}

/**
 * load the Licenses for the current selected Farm (in the Session). No parameter needs to be passed!
 *
 * @example
 * new LicenseFarmLoad([]);
 */
export class LicenseFarmLoad implements IAction {
  readonly type = LicenseActions.LICENSE_FARM_LOAD;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LicenseFarmLoadSuccess implements IAction {
  readonly type = LicenseActions.LICENSE_FARM_LOAD_SUCCESS;
  constructor(public payload: ILicense[]) {
  }
}

export class LicenseFarmLoadError implements IAction {
  readonly type = LicenseActions.LICENSE_FARM_LOAD_ERROR;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LicenseUpdate implements IAction {
  readonly type = LicenseActions.LICENSE_UPDATE;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LicenseSaveSuccess implements IAction {
  readonly type = LicenseActions.LICENSE_SAVE_SUCCESS;
  constructor(public payload: ILicense) {
  }
}

export class LicenseSaveFailed implements IAction {
  readonly type = LicenseActions.LICENSE_SAVE_FAILED;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LicenseAdjustRequest implements IAction {
  readonly type = LicenseActions.LICENSE_ADJUST_REQUEST;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LicenseAdjustRequestFinished implements IAction {
  readonly type = LicenseActions.LICENSE_ADJUST_REQUEST_FINISHED;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {Store}                                                          from '../index';
import {Injectable}                                                     from '@angular/core';
import {ApCustomTypes, HibernateModelNames, NetTypes} from 'invoker-transport';
import {
  PlantProtectionProductsLoad,
  PlantProtectionProductsLoadFail,
  PlantProtectionProductsLoadSuccess,
  PlantProtectionProductsSelectableUpdate,
  PlantProtectionProductsSelectableUpdateFail,
  PlantProtectionProductsSelectableUpdateSuccess,
  PlantProtectionProductsCreateNewFail,
  PlantProtectionProductsCreateNewSuccess,
  PlantProtectionProductsCreateNew
}                   from 'invoker-transport';
import {IStateStoreWritable}                                            from '../../ap-interface';
import {LoginStore}                                                     from '../login/login.store';
import {debounceTime}                                                   from 'rxjs/operators';
import {NotifyStore}                                                    from '../dialog/notify.store';
import {SafeBehaviorSubject}                                            from 'ts-tooling';
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import IPlantProtectionProduct = Data.BaseData.IPlantProtectionProduct;
import IPlantProtectionProductWriteable = Data.BaseData.IPlantProtectionProductWriteable;
import ISelectablePpProduct = Data.BaseData.ISelectablePpProduct;
import {
  ApSignalrService
}                                                                       from '../../ap-core/services/ap-signalr.service';

export enum IPlantProtectionLoadLevel {
  SelectedProducts,
  AllProducts
}

interface IPlantProtectionLoadState {
  farmId: number;
  loadLevel: IPlantProtectionLoadLevel;
}

interface IPlantProtectionProductStore extends IStateStoreWritable<IPlantProtectionProduct> {
  loadState: IPlantProtectionLoadState;
}

@Injectable({providedIn: 'root'})
export class PlantProtectionProductStore extends Store<IPlantProtectionProductStore> {
  private pendingDataLock = false;
  private pendingData: IPlantProtectionProduct[] = [];

  constructor(public backend: ApSignalrService,
              private notifyStore: NotifyStore,
              private loginStore: LoginStore) {
    super(backend, {
      loaded: false,
      loading: false,
      saved: false,
      saving: false,
      data: [],
      loadState: undefined
    });

    backend.registerObservable(PlantProtectionProductsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(PlantProtectionProductsLoadFail).subscribe(() => {
      super.Mutate(s => s.data, () => []);
      super.SetLoadFinishState();
    });

    backend.registerObservable(PlantProtectionProductsSelectableUpdateSuccess).subscribe(() => {
      this.notifyStore.showSaveSuccessMessage();
      super.SetSaveSuccessState();
      this.loadPlantProtectionProducts(false, true);
    });

    backend.registerObservable(PlantProtectionProductsSelectableUpdateFail).subscribe(() => {
      this.notifyStore.showSaveFailMessage();
      super.SetSaveFailState();
      this.loadPlantProtectionProducts(false, true);
    });

    backend.registerObservable(PlantProtectionProductsCreateNewSuccess).subscribe(() => {
      this.notifyStore.showSaveSuccessMessage();
      super.SetSaveSuccessState();
    });

    backend.registerObservable(PlantProtectionProductsCreateNewFail).subscribe(() => {
      this.notifyStore.showSaveFailMessage();
      super.SetSaveFailState();
    });

    // use of debounce in order to avoid performance issue when many records have been changed
    // otherwise many small updates trigger too many angular refresh events and everything gets really slow
    this.AfterDatabaseUpdate.pipe(
      debounceTime(500))
      .subscribe(() => {
        if (this.pendingDataLock) {
          // try again later
          this.AfterDatabaseUpdate.emit();
          return;
        }
        super.Mutate(s => s.data, () => this.pendingData);
        this.pendingData = [];
      });
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get Products$(): SafeBehaviorSubject<IPlantProtectionProduct[]> {
    return this.Listen(x => x.data);
  }

  public get Products(): IPlantProtectionProduct[] {
    return this.Listen(x => x.data).getValue();
  }

  /**
   *  load plant protection products from Backend to the Store
   *  There was a change in design concept of loading plant protection products.
   *  Only products get loaded which have an eppo_crop reference to one of the farm's selectableCrops.
   *  As a consequence the products might need an explicit reload. LiveStores would not cover this usecase.
   *  Therefor we need the forceReload option.
   */
  public loadPlantProtectionProducts(selectedOnly = true, forceReload?: boolean): void {
    const farm = this.loginStore.SelectedFarm;
    const lastLoadState = this.Listen(s => s.loadState).getValue();
    const newLoadState: IPlantProtectionLoadState = {
      farmId: farm?.Id,
      loadLevel: selectedOnly ? IPlantProtectionLoadLevel.SelectedProducts : IPlantProtectionLoadLevel.AllProducts
    };

    // We have many plant protection products. Therefore, we need to
    // load them carefully. Once they are loaded for a specific region the
    // updates from database are provided by DatabaseNotify => no reload needed
    if (forceReload || !lastLoadState || lastLoadState.farmId !== newLoadState.farmId ||
      lastLoadState.loadLevel < newLoadState.loadLevel) {
      super.SetLoadState();
      super.Mutate(s => s.loadState, () => newLoadState);
      this.DispatchBackend(new PlantProtectionProductsLoad([
        {Name: 'region', Type: NetTypes.STRING, Value: farm?.Country?.Id},
        {Name: 'selectedOnly', Type: NetTypes.BOOL, Value: selectedOnly}
      ]));
    }
  }

  public createNewProduct(plantProtectionProduct: IPlantProtectionProductWriteable): void {
    this.SetSaveState();
    this.DispatchBackend(new PlantProtectionProductsCreateNew([
      {Name: 'plantProtectionProduct', Type: ApCustomTypes.Data_BaseData_PlantProtectionProductWriteable, Value: plantProtectionProduct}
    ]));
  }

  /**
   * updates selectable plant protection products
   */
  public updateSelectablePlantProtectionProduct(plantProtectionProducts: IPlantProtectionProduct[]): void {
    this.SetSaveState();
    this.DispatchBackend(new PlantProtectionProductsSelectableUpdate([
      {
        Name: 'plantProtectionProducts',
        Type: ApCustomTypes.Data_BaseData_PlantProtectionProduct + '[]',
        Value: plantProtectionProducts
      },
    ]));
  }

  /**
   * override the Update with a custom Update Strategy
   */
  UpdateSource(operation: DatabaseNotifyOperation, item: any, model: string): void {
    // if we are currently in an active saving/loading process we do not need the live updates because
    // everything gets loaded after save/load.
    // Anyway this is useful if there is more than 1 user logged in on the same farm.
    if (this.Listen(s => s.saving).getValue() ||
      this.Listen(s => s.loading).getValue()) {
      return;
    }
    try {
      this.pendingDataLock = true;
      if (model === HibernateModelNames.SELECTABLE_PLANT_PROTECTION_PRODUCT) {
        if (!this.pendingData || this.pendingData?.length <= 0) {
          this.pendingData = this.Listen(s => s?.data).getValue();
        }
        if (!this.pendingData) {
          super.SetLoadFinishState();
          return;
        }
        const backendItem = item as ISelectablePpProduct;
        if (!backendItem) {
          // fallback case reload the Source based on last load state
          this.loadPlantProtectionProducts(
            this.Listen(s => s.loadState)
              .getValue()?.loadLevel === IPlantProtectionLoadLevel.SelectedProducts);
          return;
        }
        const position = this.pendingData.FindIndex(i => i.Id === backendItem.PpProductId);
        const oldItem = this.pendingData[position];
        this.pendingData = [...this.pendingData];
        this.pendingData[position] = {...oldItem, Selectable: backendItem.DeletedAt == null};
        this.AfterDatabaseUpdate?.emit();
        return;
      }
    } finally {
      this.pendingDataLock = false;
    }
  }

  public getProducts(): IPlantProtectionProduct[] {
    return super.Listen((s) => s.data).getValue();
  }
}

import { Pipe, PipeTransform }  from '@angular/core';
import {RemoveUrlParamsService} from '../../../services/router/remove-url-params.service';

@Pipe({
  name: 'removeUrlParams'
})
export class RemoveUrlParamsPipe implements PipeTransform {

  constructor(private removeUrlParams: RemoveUrlParamsService) {
  }

  transform(value: string, ...args: any[]): any {
    return this.removeUrlParams.removeUrlParams(value);
  }

}

import {Pipe, PipeTransform}         from '@angular/core';
import {ApDynGridColumnConfigBase}   from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridButtonColumnConfig} from '../../config/ap-dyn-grid-button-column-config';
import {Trace}                       from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asButton'
})
export class AsButtonPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridButtonColumnConfig {
    return config as ApDynGridButtonColumnConfig;
  }

}

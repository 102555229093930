import {ElementRef, Injectable, NgZone, Renderer2, TemplateRef} from '@angular/core';
import {PopupService}                                           from '@progress/kendo-angular-popup';
import {TooltipDirective}                                       from '@progress/kendo-angular-tooltip';

@Injectable({providedIn: 'root'})
export class ApTooltipService {

  /**
   * the attribute's name where the tooltip context can be found.
   */
  public TooltipAttribute = 'aptooltiptext';

  /**
   * tooltip delay in ms
   */
  private tooltipDelay = 200;

  /**
   * kendo tooltip directive instance.
   */
  private kendoTooltipDirective: TooltipDirective = null;

  /**
   * The renderer for setting and removing the attributes.
   */
  private renderer: Renderer2;

  private TooltipMap: {[key: string]: string} = {};

  /**
   * Initializes the tooltip (Should be called once, only)
   */
  public CreateKendoDirective(tooltipWrapper: ElementRef,
                              ngZone: NgZone,
                              renderer: Renderer2,
                              popupService: PopupService): TooltipDirective {
    this.renderer = renderer;
    this.kendoTooltipDirective = new TooltipDirective(tooltipWrapper, ngZone, renderer, popupService, null, null);
    this.kendoTooltipDirective.showAfter = this.tooltipDelay;
    this.kendoTooltipDirective.filter = '[' + this.TooltipAttribute + ']';
    return this.kendoTooltipDirective;
  }

  /**
   * 2nd step of initialization. For proper tooltip handling
   * some things need to be initialized after view has been initialized.
   */
  public Init(template: TemplateRef<any>): void {
    this.kendoTooltipDirective.tooltipTemplate = template;
    this.kendoTooltipDirective.ngOnInit();
  }

  /**
   * Reads the tooltip text from the element's tooltip-attribute
   */
  public ReadTooltipText(element: ElementRef): string {
    if (element == null || element.nativeElement == null) {
      return '';
    }

    return this.TooltipMap[element.nativeElement.getAttribute(this.TooltipAttribute)];
  }

  /**
   * Shows tooltip (by adding tooltip attribute)
   */
  public Show(element: ElementRef, value: string): void {
    const date = Date.now().toString();
    this.TooltipMap[date] = value;
    if (element == null || element.nativeElement == null) {
      return;
    }
    this.renderer.setAttribute(element.nativeElement, this.TooltipAttribute, date);
  }

  /**
   * Hides the tooltip (by removing tooltip attribute)
   */
  public Hide(element: ElementRef): void {
    if (element == null || element.nativeElement == null) {
      return;
    }
    this.kendoTooltipDirective.hide();
    delete this.TooltipMap[element.nativeElement.getAttribute(this.TooltipAttribute)];
    this.renderer.removeAttribute(element.nativeElement, this.TooltipAttribute);
  }
}

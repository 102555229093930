import {IAction, IApInvokerArguments} from '../../interfaces';
import IClient = Data.Tracelog.IClient;

export enum ApTraceLogClientActions {
  LOAD_TRACE_LOAD_CLIENT = '[trace_log_client] load',
  LOAD_TRACE_LOAD_CLIENT_SUCCESS = '[trace_log_client] load success',
}

export class LoadTraceLogClient implements IAction {
  readonly type = ApTraceLogClientActions.LOAD_TRACE_LOAD_CLIENT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadTraceLogClientSuccess implements IAction {
  readonly type = ApTraceLogClientActions.LOAD_TRACE_LOAD_CLIENT_SUCCESS;

  constructor(public payload: IClient[]) {
  }
}

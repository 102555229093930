import {Pipe, PipeTransform}      from '@angular/core';
import {ApDynGridPagerConfigBase} from '../../config/ap-dyn-grid-pager-config-base';
import {ApDynGridPagerConfig}     from '../../config/ap-dyn-grid-pager-config';
import {Trace}                    from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asPager'
})
export class AsPagerPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridPagerConfigBase): ApDynGridPagerConfig {
    return config as ApDynGridPagerConfig;
  }

}

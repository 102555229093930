import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'infoViewStyle',
})
export class InfoViewStylePipe implements PipeTransform {
  transform(rowNum: number, ...args: [string, number]): any {
    const maxRows = args[1] - 1 || 0;
    switch (args[0]) {
      case 'row':
        const rowStyle = {
          'margin-left': '5px',
          'margin-right': '5px',
        };
        if (rowNum >= maxRows) {
          rowStyle['margin-bottom'] = '5px';
          return rowStyle;
        }
        if (rowNum === 0) {
          rowStyle['margin-top'] = '5px';
          return rowStyle;
        }
        return rowStyle;
      case 'head':
        const headStyle = {
          'border-left': '1px solid rgba(0, 0, 0, 0.3)',
          'border-bottom': '1px solid rgba(0, 0, 0, 0.3)',
        };
        if (rowNum === 0) {
          headStyle['border-top'] = '1px solid rgba(0, 0, 0, 0.3)';
          return headStyle;
        }
        return headStyle;
      case 'data':
        const dataStyle = {
          'border-left': '1px solid rgba(0, 0, 0, 0.3)',
          'border-right': '1px solid rgba(0, 0, 0, 0.3)',
          'border-bottom': '1px solid rgba(0, 0, 0, 0.3)',
        };
        if (rowNum === 0) {
          dataStyle['border-top'] = '1px solid rgba(0, 0, 0, 0.3)';
          return dataStyle;
        }
        return dataStyle;
      default:
        return undefined;
    }
  }
}

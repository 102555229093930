import {NgModule}                   from '@angular/core';
import {CommonModule}               from '@angular/common';
import {ApDynComponentModule}       from '../../ap-dyncomponent/ap-dyncomponent.module';
import {ApStatisticsModule}         from '../../statistics/ap-statistics.module';
import {ApResponsiveTextModule}     from '../../ap-responsive-text/ap-responsive-text.module';
import {ApCoreModule}               from '../../ap-core/ap-core.module';
import {ButtonModule}               from '@progress/kendo-angular-buttons';
import {PpPlanningWizardComponent}  from './planning-wizard/pp-planning-wizard.component';
import {PpPlanShowComponent}        from './pp-plan-show.component';
import {PpPlanBookingComponent}     from './pp-plan-booking.component';
import {PpPlanBookingShowComponent} from './pp-plan-booking-show.component';
import {PpLogfileEditComponent}     from './pp-logfile-edit.component';
import {PpProductWizardComponent}   from './pp-product-wizard/pp-product-wizard.component';

@NgModule({
  declarations: [
    PpLogfileEditComponent,
    PpPlanningWizardComponent,
    PpPlanShowComponent,
    PpPlanBookingComponent,
    PpPlanBookingShowComponent,
    PpProductWizardComponent
  ],
  imports: [
    CommonModule,
    ApDynComponentModule,
    ApStatisticsModule,
    ApResponsiveTextModule,
    ApCoreModule,
    ButtonModule
  ]
})
export class PpEntryComponentsModule {
}

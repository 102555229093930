import {Component, Input}           from '@angular/core';
import {ApDynGridImageColumnConfig} from '../config/ap-dyn-grid-image-column-config';

@Component({
  selector: 'ap-image-column-cell',
  template: `
    <ap-responsive-text [class]="config.cssClass | centerCell"
                        [aptooltip]="(dataItem | getProperty:config.tooltip) | execPipes:config:'tooltipPipes' | async" [perpetual]="true">
      <img [src]="(dataItem | getProperty:config.imageUrl)"
           [style.height]="(dataItem | getProperty:config.imageHeight)"
           [style.width]="(dataItem | getProperty:config.imageWidth)">
    </ap-responsive-text>
  `,
  styles: [
  ]
})
export class ApImageColumnCellComponent {
  @Input() config: ApDynGridImageColumnConfig;
  @Input() dataItem: any;
}

import {Pipe, PipeTransform}        from '@angular/core';
import {ApDynGridColumnConfigBase}  from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridColorColumnConfig} from '../../config/ap-dyn-grid-color-column-config';
import {Trace}                      from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asColor'
})
export class AsColorPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridColorColumnConfig {
    return config as ApDynGridColorColumnConfig;
  }

}

<div class="ap-tabstrip-small">
  <kendo-tabstrip (tabSelect)="selectTab($event)" id="parameterTabStrip">
    <kendo-tabstrip-tab [selected]="true">
      <ng-template kendoTabTitle>
        <ap-responsive-text key="Global_Parameter" class="ap-tabstrip-header"></ap-responsive-text>
      </ng-template>
      <ng-template kendoTabContent>
        <ap-parameter-value #parameterValue [refObject]="refObject" [refId]="refId"></ap-parameter-value>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab>
      <ng-template kendoTabTitle>
        <ap-responsive-text key="Global_Parameter_History" class="ap-tabstrip-header"></ap-responsive-text>
      </ng-template>
      <ng-template kendoTabContent>
        <ap-parameter-value-history [refId]="refId"></ap-parameter-value-history>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

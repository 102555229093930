<div class="ap-toolbar-control" #apToolbarNotifications>
  <button #notificationToolbarButton
          [class.notification-button]="true"
          [class.dark-bg]="true"
          kendoButton
          [id]="'notification-button'"
          [look]="'outline'"
          [toggleable]="true"
          [icon]="'notification'"
          class="notification-button"
          (click)="onClick()"
          [aptooltip]="'Global_Notifications' | translate" [perpetual]="!show">
    <span *ngIf="(itemNewView | async).total > 0" class="badge">{{(itemNewView | async).total > 9 ? '9+' : (itemNewView | async).total}}</span>
  </button>
  <kendo-popup *ngIf="show"
               class="notification-popup"
               [anchor]="notificationToolbarButton"
               [animate]="{direction: 'up', duration: 133}"
               [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
               [popupAlign]="{ horizontal: 'right', vertical: 'bottom' }">
    <kendo-panelbar
      [id]="'notification-panelBar'"
      [items]="items"
      [animate]="false"
      [expandMode]="'PanelBarExpandMode.Full'"
      class="notification-panelBar">
      <ng-template kendoPanelBarItemTemplate let-item>
        <div class="ap-toolbar-popup-header">{{'Global_Notifications' | translate}}</div>
        <div *ngIf="(itemNewView | async).total > 0" style="text-align: left; padding: 2px 18px 2px;">
          <span style="font-weight: bold;text-transform: uppercase;">{{'Global__New' | translate}}</span>
        </div>
        <kendo-grid *ngIf="(itemNewView | async).total > 0"
                    [id]="'notificationNewGrid'"
                    [data]="itemNewView | async"
                    [scrollable]="'virtual'"
                    [height]="itemNewHeight"
                    [skip]="newSkip | async"
                    [pageSize]="9"
                    [rowHeight]="45"
                    [hideHeader]="true"
                    [navigable]="true"
                    (cellClick)="cellClick($event)"
                    (pageChange)="newPageChange($event)"
                    [loading]="notificationLoading$ | async"
                    [sort]="[{field:'Timestamp', dir: 'asc'}]"
                    style="overflow-y: hidden !important; max-height: 300px;">
          <kendo-grid-column [width]="70"
                             [style]="{'border-left-color':'#b0cb1f','border-left-width':'5px'}"
                             [field]="'Timestamp'"
                              class="whole-cell">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span style="text-align: center;" class="k-icon k-i-calendar"></span>
              <ap-responsive-text style="text-align: center;"  [text]="(dataItem.Timestamp | getTimeDurationString)" [aptooltip]="(dataItem.Timestamp | getLocalDate).format('DD.MM.YYYY HH:mm')" [perpetual]="true"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column  class="whole-cell" [style]="{'border-left':'none'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-responsive-text style="font-weight: bold; text-align: left;" key="{{dataItem.SourceItem.Caption}}"></ap-responsive-text>
              <ap-responsive-text style="text-align: left;" key="{{dataItem.SourceItem.Description}}"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [width]="30"  class="whole-cell" [style]="{'border-left':'none'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-responsive-text *ngIf="dataItem.Link !== null" style="text-align: center;" class="k-icon  k-i-link-horizontal" (click)="onItemClick(dataItem.SourceItem)" [aptooltip]="'Link_More' | translate" [perpetual]="true"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [width]="30"  class="whole-cell" [style]="{'border-left':'none'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-responsive-text style="text-align: center;" class="k-icon k-i-close-outline" (click)="onDeleteClick(dataItem.SourceItem)" [aptooltip]="'Global__Delete' | translate" [perpetual]="true"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
        <div *ngIf="(itemOldView | async).total > 0" style="text-align: left">
          <span style="font-weight: bold; padding: 5px 12px; text-transform: uppercase;">{{'Global_Formerly' | translate}}</span>
        </div>
        <kendo-grid *ngIf="(itemOldView | async).total > 0"
                    [id]="'notificationFormerlyGrid'"
                    [data]="itemOldView | async"
                    [hideHeader]="true"
                    [scrollable]="'virtual'"
                    [height]="itemOldHeight"
                    [skip]="oldSkip | async"
                    [navigable]="true"
                    [pageSize]="9"
                    [rowHeight]="45"
                    (cellClick)="cellClick($event)"
                    (pageChange)="oldPageChange($event)"
                    [loading]="notificationLoading$ | async"
                    style="overflow-y: hidden !important; max-height: 300px;">
          <kendo-grid-column [width]="70"
                             [style]="{'border-left-color':'transparent', 'border-left-width':'5px'}"
                             [field]="'Timestamp'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span style="text-align: center;" class="k-icon k-i-calendar"></span>
              <ap-responsive-text style="text-align: center;" [text]="(dataItem.Timestamp | getTimeDurationString)" [aptooltip]="(dataItem.Timestamp | getLocalDate).format('DD.MM.YYYY HH:mm')" [perpetual]="true"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [style]="{'border-left':'none'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-responsive-text style="font-weight: bold; text-align: left;" key="{{dataItem.SourceItem.Caption}}"></ap-responsive-text>
              <ap-responsive-text style="text-align: left;" key="{{dataItem.SourceItem.Description}}"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [width]="30" [style]="{'border-left':'none'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-responsive-text *ngIf="dataItem.Link !== null" id="notificationFormerlyGrid-link-button" style="text-align: center;" class="k-icon  k-i-link-horizontal" (click)="onItemClick(dataItem.SourceItem)" [aptooltip]="'Link_More' | translate" [perpetual]="true"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column [width]="30" [style]="{'border-left':'none'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-responsive-text style="text-align: center;" id="notificationFormerlyGrid-delete-button" class="k-icon k-i-close-outline" (click)="onDeleteClick(dataItem.SourceItem)" [aptooltip]="'Global__Delete' | translate" [perpetual]="true"></ap-responsive-text>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
        <div *ngIf="(itemNewView | async).total > 0 || (itemOldView | async).total > 0" style="text-align: right; padding: 8px 12px 6px;">
          <a>
            <span style="cursor: pointer;" (click)="onAllNotificationReadClick()">{{'Global_Mark_All_As_Read' | translate}}</span>
          </a>
          <span role="presentation" aria-hidden="true"> · </span>
          <a>
            <span style="cursor: pointer;" (click)="onAllNotificationDeleteClick()">{{'Global_Delete_All' | translate}}</span>
          </a>
        </div>
        <div *ngIf="(itemOldView | async).total === 0 && (itemNewView | async).total === 0" class="ap-no-items-available-text">
          <span id="global-no-notifications-available">{{'Global_No_Notifications_Available' | translate}}</span>
        </div>
      </ng-template>
    </kendo-panelbar>
  </kendo-popup>
</div>

import {Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup, ValidatorFn, Validators}                                 from '@angular/forms';
import * as OlFeature                                                       from 'ol/Feature';
import Feature                                                              from 'ol/Feature';
import OlFormatGeoJSON                                                      from 'ol/format/GeoJSON';
import {combineLatest, of, Subscription}                                    from 'rxjs';
import {filter, map, skip, take}                                            from 'rxjs/operators';
import {
  ApDynformsValidator
}                                                                           from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsComponent
}                                                                           from '../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsConfigCheckbox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  ApDynformsConfigComboBox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigFieldset
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigNumerictextbox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApDynformsConfigPlaceholder
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {
  ApDynformsConfigTextbox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {MAP_PROJECTION}                                                     from '../../ap-map';
import {
  ApPolygonEditLayer
}                                                                           from '../../ap-map/layers/ap-polygon-edit.layer';
import {ApGuidUtil}                                                         from '../../ap-utils';
import {
  CropTypeStore
}                                                                           from '../../stores/base-data/crop.types.store';
import {NotifyStore}                                                        from '../../stores/dialog/notify.store';
import {FieldStore}                                                         from '../../stores/farm/field.store';
import {FormStore}                                                          from '../../stores/layout/form.store';
import {
  CampaignYearStore
}                                                                           from '../../stores/login/campaignyear.store';
import {LoginStore}                                                         from '../../stores/login/login.store';
import {MapStore}                                                           from '../../stores/map/map.store';
import {
  TranslationStore
}                                                                           from '../../stores/translation/translation.store';
import {
  EditorService
}                                                                           from '../../map/components/edit/editor.service';
import {ApEditStyles}                                                       from '../../ap-map/layers/ap-edit-styles';
import {IFieldEditFormValues}                                               from './ap-edit-field.component';
import {
  FieldFactoryService
}                                                                           from '../../factories/field-factory.service';
import {
  IFieldGridData
}                                                                           from 'src/app/factories/field-grid-data-factory.service';
import {
  GetRoundNumericService
}                                                                           from '../../ap-utils/service/get-round-numeric.service';
import {
  SettingsStore
}                                                                           from '../../stores/base-data/settings.store';
import {
  ModalDialogStore
}                                                                           from '../../stores/dialog/modal.dialog.store';
import {
  ModalDialogPresets
}                                                                           from '../../stores/dialog/modal.dialog.presets';
import {
  GetNumericService
}                                                                           from '../../ap-utils/service/get-numeric.service';
import IField = Data.FieldManagement.IField;
import IFieldCrop = Data.FieldManagement.IFieldCrop;
import {
  FieldArchiveStore
}                                                                           from '../../stores/farm/field-archive.store';

export const MergeNewFieldName = 'Field__New_Field';

/**
 * Component for the edit field
 */
@Component({
  selector: 'ap-merge-field',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [formValidators]="formValidators"
                 [loading$]="fieldsSaving$">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_field_management_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">
          {{'Global__Cancel' | translate}}
        </button>
        <button id="button_field_management_edit_save"
                type="button"
                [disabled]="(formIsInvalid$ | async) || (invalidGeometry$ | async) || (toBigGeometry$ | async) || (notIntegrated$ | async)"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">
          {{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>
    <ng-template #ComboBoxCropTypeItem let-Description="Description" let-IsSystem="IsSystem">
      <span style="font-size: 16px;">{{Description}}
      </span>
    </ng-template>
  `,
})
export class ApMergeFieldComponent implements OnInit, OnDestroy {
  private static ToBigGeometryNotification: Data.Api.Validation.IApValidationResult = {
    Counter: 1,
    DisplayTimeout: 0,
    ErrorKey: 'Global__Geometry_To_Big_Validation',
    Level: Data.Api.Validation.ApValidationLevel.Error,
    Parameters: [],
    UserName: null,
    UserId: null,
  };
  private static IntegrateGeometryFailNotification: Data.Api.Validation.IApValidationResult = {
    Counter: 1,
    DisplayTimeout: 0,
    ErrorKey: 'Global__Geometry_NotIntegrated',
    Level: Data.Api.Validation.ApValidationLevel.Error,
    Parameters: [],
    UserName: null,
    UserId: null,
  };

  constructor(private formStore: FormStore,
              private fieldStore: FieldStore,
              private translationService: TranslationStore,
              private cropTypeStore: CropTypeStore,
              private mapStore: MapStore,
              private campaignYearStore: CampaignYearStore,
              private loginStore: LoginStore,
              private notifyStore: NotifyStore,
              private fieldFactory: FieldFactoryService,
              private modalDialogPresets: ModalDialogPresets,
              private modalDialogStore: ModalDialogStore,
              private roundNumericService: GetRoundNumericService,
              private settingsStore: SettingsStore,
              private numericService: GetNumericService,
              private fieldArchiveStore: FieldArchiveStore) {
  }

  @ViewChild(ApDynformsComponent, {static: true}) public dynForm: ApDynformsComponent;
  @ViewChild('ComboBoxCropTypeItem', {static: true}) public comboBoxCropTypeItem: TemplateRef<any>;
  public data: { CurrentItem: IFieldGridData, AllItems: IFieldGridData[], gridSelectedKeys: any[] };
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;
  public isNewMode = false;
  public fieldsSaving$ = of(false);
  public formIsInvalid$ = new EventEmitter<boolean>(false);
  public invalidGeometry$ = this.mapStore.Editor.FeatureLocked$.pipe(map((locked) => !locked));
  public notIntegrated$ = EditorService.ValidSketch$.pipe(map(integrated => !integrated));
  public toBigGeometry$ = this.mapStore.Editor.CurrentArea$.pipe(map((d) => d > 1000));
  public close = new EventEmitter<void>(true);
  public formValidators: ApDynformsValidator<ValidatorFn>[] = [
    new ApDynformsValidator({
      validator: ApMergeFieldComponent.fieldNameOrNrRequired,
      errorKey: 'Global__Field_Name_Or_Nr_Required',
    }),
    new ApDynformsValidator({
      validator: ApMergeFieldComponent.yieldRequiredIfCropIsSet,
      errorKey: 'Yield__required'
    }),
  ];
  private _subscriptions: Array<Subscription> = [];

  private static fieldNameOrNrRequired(frm: FormGroup): { mismatch: boolean } | null {
    const fieldName = frm.get('FieldName')?.value;
    const fieldNr = frm.get('FieldNumber')?.value;
    const fieldTNr = frm.get('FieldSubnumber')?.value;
    if (!fieldName && !fieldNr && !fieldTNr) {
      return {mismatch: true};
    }
    return null;
  }

  private static yieldRequiredIfCropIsSet(frm: FormGroup): { missingYield: boolean } | null {
    const mainCrop = frm.get('MainCrop')?.value;
    const mainCropYieldControl = frm.get('MainCrop_YieldUnit');
    const mainCropYield = mainCropYieldControl?.value;
    const secondCrop = frm.get('SecondCrop')?.value;
    const secondCropYieldControl = frm.get('SecondCrop_YieldUnit');
    const secondCropYield = secondCropYieldControl?.value;
    const validationError = {missingYield: true};

    // Validate if yield is present when crop is selected.
    // This validation is done on form level because 2 form controls are involved.
    // Besides that the error is set at the control directly to avoid having
    // an error message (errorkey) being shown at the control
    if (parseFloat(secondCrop) > 0 && !(parseFloat(secondCropYield) >= 0)) {
      secondCropYieldControl?.setErrors(validationError);
      return validationError;
    }
    if (parseFloat(mainCrop) > 0 && !(parseFloat(mainCropYield) >= 0)) {
      mainCropYieldControl?.setErrors(validationError);
      return validationError;
    }
    return null;
  }

  public ngOnInit(): void {
    this.fieldsSaving$ = this.fieldStore.Listen(s => s.saving);
    this._subscriptions.push(EditorService.ValidSketch$
      .subscribe(integrated => {
        if (integrated) {
          this.notifyStore.removeMessage(ApMergeFieldComponent.IntegrateGeometryFailNotification);
          this.mapStore.Layers.PolygonEditLayer.setStyle(ApEditStyles.selectedStyle);
        } else {
          this.notifyStore.addMessage(ApMergeFieldComponent.IntegrateGeometryFailNotification);
          this.mapStore.Layers.PolygonEditLayer.setStyle(ApEditStyles.invalidStyle);
        }
      }));
    this._subscriptions.push(this.dynForm.initialized.subscribe(() => {
      this._subscriptions.push(this.dynForm.form.valueChanges.subscribe(() => {
        this.formIsInvalid$.emit(!this.dynForm.form.valid);
      }));
      this._subscriptions.push(this.dynForm.form.get('FieldSelection')?.valueChanges?.subscribe(_ => {
        this.onFieldSelectionChanged();
      }));
      this._subscriptions.push(this.dynForm.form.get('MainCrop')?.valueChanges?.subscribe(_ => {
        this.onMainCropChanged();
      }));
      this._subscriptions.push(this.dynForm.form.get('SecondCrop')?.valueChanges?.subscribe(_ => {
        this.onSecondCropChanged();
      }));
    }));
    this._subscriptions.push(this.mapStore.Editor.DeleteGeometry$
      .pipe(filter((fields) => fields && fields.Any()))
      .subscribe(() => this.deleteGeometry()));
    this._subscriptions.push(this.mapStore.Editor.EditedFeature$
      .pipe(filter((feat) => !!feat))
      .subscribe((feat) => this.saveGeometry(feat)));
    this._subscriptions.push(this.mapStore.Editor.CurrentArea$
      .pipe(filter((area) => area >= 0))
      .subscribe((area) => {
        if (area > 1000) {
          this.notifyStore.addMessage(ApMergeFieldComponent.ToBigGeometryNotification);
        } else if (this.notifyStore.getNotify().length > 0) {
          this.notifyStore.removeMessage(ApMergeFieldComponent.ToBigGeometryNotification);
        }
        if (!this.data || !this.data.CurrentItem?.SourceItem.DefaultGeom || !this.dynForm || !this.dynForm.form || !this.dynForm.form.controls['Area']) {
          return;
        }

        this.dynForm?.form?.get('Area')?.setValue(this.calculateAreaSizeStringByValue(area));
      }));

    this._subscriptions.push(combineLatest([
      this.campaignYearStore.Listen((s) => s.selectedYear),
      this.loginStore.Listen((s) => s.farm.selectedFarm),
    ]).pipe(
      filter(([y, f]) => !!y && !!f),
      take(1),
    ).subscribe(([_, _1]) => {
      this.caption = this.translationService.FindTranslationForSelectedLanguage('Field__Merge');
      this.formBuilder();
    }));

    // Clear value each time we enter the merge form
    this.fieldStore.clearFieldPlanBooksResult();
    this._subscriptions.push(this.modalDialogStore.Listen(s => s.result).pipe(skip(1)).subscribe(c => {
      if (c.key === 'confirm') {
        this.submitLogic();
      }
    }));

    this._subscriptions.push(this.fieldStore.Listen(s => s.fieldPlanBooksResult).subscribe(planBooks => {
      if (planBooks != null) {
        if (planBooks === true) {
          this.modalDialogStore.setModalDialogData(this.modalDialogPresets.ExistPlanBooksDialog(
            {title: 'HdrConfirm', message: 'Global__Dialog_Message_CheckPlanBooks'}));
        } else {
          this.submitLogic();
        }

        this.fieldStore.clearFieldPlanBooksResult();
      }
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((d) => {
      d.unsubscribe();
    });
  }

  public formBuilder(): void {
    let fieldCrop: IFieldCrop;
    if (this.data && this.data.CurrentItem) {
      fieldCrop = this.data.CurrentItem.SourceItem.FieldCrops[0];
    }
    this.formConfig = [
      new ApDynformsConfigFieldset({
        config: [
          new ApDynformsConfigComboBox({
            key: 'FieldSelection',
            label: 'Field Selection',
            options: this.data.AllItems,
            textField: 'SourceItem.FieldName',
            validators: [],
            value: this.data.CurrentItem,
            valueField: 'Id',
            valuePrimitive: false,
            infoText: 'Field__Merge_Crop_rotation_transfer'
          })
        ],
        key: 'Pre-Setting',
        legend: 'Field__Merge_Pre_Setting',
      }),
      new ApDynformsConfigFieldset({
        config: [
          new ApDynformsConfigNumerictextbox({
            key: 'FieldNumber',
            label: 'Global__FieldNumber',
            value: this.data.CurrentItem?.SourceItem.FieldNumber,
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required,
              })
            ]
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldSubnumber',
            label: 'Planning_Fieldpart',
            value: this.data.CurrentItem?.SourceItem.FieldSubnumber,
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required,
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'FieldName',
            label: 'Global__FieldName',
            value: this.data.CurrentItem?.SourceItem.FieldName,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
          }),
        ],
        key: 'field1',
        legend: 'Global__Description',
      }),
      new ApDynformsConfigFieldset({
        config: [
          new ApDynformsConfigTextbox({
            disabled: true,
            key: 'Area',
            label: 'Global__Area_Unit',
            validators: [
              new ApDynformsValidator({
                errorKey: 'Settings__Msg_Vali_Value_Required',
                validator: Validators.min(1),
              }),
            ],
            value: this.calculateAreaSizeString(),
          }),
        ],
        key: 'field2',
        legend: 'Tip_InformationText',
      }),
      new ApDynformsConfigFieldset({
        config: [
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'MainCrop',
            label: 'MainCrop',
            options: this._availableCropTypes(fieldCrop ? fieldCrop.CroptypeId : undefined),
            textField: 'Description',
            validators: [],
            value: fieldCrop ? fieldCrop.CroptypeId : undefined,
            valueField: 'Id',
            valuePrimitive: true
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'MainCrop_YieldUnit',
            label: 'Global__YieldUnit',
            value: fieldCrop ? fieldCrop.ExpectedYield : undefined,
            min: 0,
            disabled: !(fieldCrop?.CroptypeId > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: IFieldEditFormValues) => !(f.MainCrop > 0)))
          }),
          new ApDynformsConfigCheckbox({
            key: 'RemoveResidues',
            label: 'Nutrients_Pages_Popups__RemoveResidues',
            value: fieldCrop ? fieldCrop.StrawHarvested : false,
          }),
          new ApDynformsConfigPlaceholder(),
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'SecondCrop',
            label: 'SecondCrop',
            options: this._availableCropTypes(fieldCrop ? fieldCrop.CroptypeSecondId : undefined),
            textField: 'Description',
            validators: [],
            value: fieldCrop ? fieldCrop.CroptypeSecondId : undefined,
            valueField: 'Id',
            valuePrimitive: true,
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'SecondCrop_YieldUnit',
            label: 'Global__Yield_Removal_Unit',
            value: fieldCrop ? fieldCrop.ExpectedYieldSecond : undefined,
            min: 0,
            disabled: !(fieldCrop?.MainSecondCropType > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: IFieldEditFormValues) => !(f.SecondCrop > 0)))
          }),
        ],
        key: 'field3',
        legend: (this.translationService.FindTranslationForSelectedLanguage('Docu_Ini__HarvestYear') + ' ' + this.campaignYearStore.getSelectedCampaignYear().Year),
      }),
    ];
  }

  private mapFormValues(target: IField, formValue: IFieldEditFormValues): IField {
    const cropType = this._availableCropTypes(formValue.MainCrop).Find((ct) => ct?.Id === formValue?.MainCrop);
    const cropTypeSecond = this._availableCropTypes(formValue.MainCrop).Find((ct) => ct?.Id === formValue?.SecondCrop);
    target.Id = ApGuidUtil.generateNewGuid();
    target.FieldName = formValue.FieldName;
    target.FieldNumber = formValue.FieldNumber;
    target.FieldSubnumber = formValue.FieldSubnumber;
    target.FarmId = this.loginStore.SelectedFarmId;
    target.ValidFrom = this.campaignYearStore.getSelectedCampaignYear().DefaultStart;
    target.FieldCrops[0] = ({
      Cropkey: cropType ? cropType.CropKey : null,
      CroptypeId: formValue.MainCrop,
      CroptypeSecondId: formValue.SecondCrop,
      MainSecondCropType: cropTypeSecond ? cropTypeSecond.CropKey : null,
      ExpectedYield: cropType ? formValue.MainCrop_YieldUnit : null,
      ExpectedYieldSecond: cropTypeSecond ? formValue.SecondCrop_YieldUnit : null,
      StrawHarvested: formValue.RemoveResidues,
    } as IFieldCrop);
    return target;
  }

  private submitLogic(): void {
    const takeFrom = (this.data.CurrentItem.Id !== -1) ? this.data.CurrentItem.SourceItem.Id : null;
    const newField: IField = this.mapFormValues(this.fieldFactory.CreateNew(), this.dynForm.form.value);

    if (!newField) {
      console.warn(`formular values was empty`, this.dynForm.form.value);
      return;
    }

    this.loginStore.setLongOperationStatus(true);
    try {
      newField.DefaultGeom.Geom = (this.pullSelectedGeometry() as any);
      newField.DefaultGeom.FarmId = this.loginStore.SelectedFarmId;
      newField.DefaultGeom.FieldId = newField.Id;
      newField.DefaultGeom.ValidFrom = newField.ValidFrom;
      newField.FieldGeoms.Clear();
      newField.FieldGeoms.Add(newField.DefaultGeom);
      newField.DefaultGeom = null;

      this.fieldStore.mergeFields(newField, this.data.AllItems.filter(x => x.Id !== -1).map(x => x.Id), takeFrom);
      this._subscriptions.Add(this.fieldStore.Listen(s => s.saved).subscribe(saved => {
        if (saved) {
          setTimeout(() => this.formStore.closeForm(), 0);
          this.loginStore.setLongOperationStatus(false);
        }
      }));

      this._subscriptions.Add(this.fieldStore.Listen(s => s.mergeFailed).subscribe(failed => {
        if (failed) {
          setTimeout(() => this.formStore.closeForm(), 0);
          this.loginStore.setLongOperationStatus(false);
          this.fieldStore.loadFields(this.loginStore.CampaignYearStore.getSelectedCampaignYear().DefaultStart);
          this.fieldArchiveStore.loadFieldArchive(this.loginStore.CampaignYearStore.getSelectedCampaignYear().DefaultStart);
        }
      }));

      this._subscriptions.Add(this.fieldStore.Listen(s => s.mergeSuccess).subscribe(success => {
        if (success) {
          this.fieldArchiveStore.loadFieldArchive(this.loginStore.CampaignYearStore.getSelectedCampaignYear().DefaultStart);
        }
      }));
    } catch (e) {
      console.warn(e);
      this.loginStore.setLongOperationStatus(false);
    }
  }

  public onSubmitClick(): void {
    this.fieldStore.checkFieldsPlanBook(this.data.AllItems.filter(x => x.Id !== -1).map(x => x.Id));
  }

  public onCancelClick(): void {
    this.mapStore.Editor.CloseEditor(this.fieldStore);
    this.formStore.closeForm();
  }

  public saveGeometry(feat: Feature): void {
    const format = new OlFormatGeoJSON();
    const jsonF = JSON.parse(format.writeFeature(feat));
    if (this.isNewMode) {
      this.data.CurrentItem.SourceItem.DefaultGeom.Id = jsonF.id;
    }
    this.data.CurrentItem.SourceItem.DefaultGeom.FarmId = this.data.CurrentItem.SourceItem.FarmId;
    const newFeature = feat.clone();
    const coords = newFeature.getGeometry().transform(MAP_PROJECTION, 'EPSG:4326');
    const jsonFe = JSON.parse(format.writeFeature(new OlFeature.default({geometry: coords})));
    this.data.CurrentItem.SourceItem.DefaultGeom.Geom = (JSON.stringify(jsonFe.geometry) as any);
  }

  private _availableCropTypes =
    (id?: number) => this.cropTypeStore.Listen((s) => s.data).getValue().filter((c) => c.Selected || c.Id === id)

  private pullSelectedGeometry(): string {
    const selectedFeatures = this.mapStore.Layers.PolygonEditLayer.GetSelectedFeaturesAsJSON(ApPolygonEditLayer.projection);
    return selectedFeatures[Object.keys(selectedFeatures)[0]];
  }

  private deleteGeometry(): void {
    if (!this.data.CurrentItem) {
      console.warn(`want to delete geometry but no geometry given to delete!`);
      return;
    }
    this.fieldStore.deleteFieldGeom(this.data.CurrentItem.SourceItem.DefaultGeom.Id);
    this.fieldStore.deleteFieldCrop(this.data.CurrentItem.SourceItem.FieldCrops[0]);
    this.mapStore.Editor.ShowEditbar(false);
    this.mapStore.Layers.PolygonEditLayer.clear();
    this.mapStore.Layers.FieldsLayer.Visibility = true;
    this.mapStore.Layers.FieldsCropLayer.Visibility = true;
    this.formStore.closeForm();
  }

  private onMainCropChanged(): void {
    const selectedCropId = this.dynForm?.form?.get('MainCrop')?.value;
    const yieldDefault = selectedCropId ? this.cropTypeStore.CropTypes.Find(c => c.Id === selectedCropId)?.ExpectedYield : undefined;
    this.dynForm?.form?.get('MainCrop_YieldUnit')?.setValue(yieldDefault);
  }

  private onSecondCropChanged(): void {
    const selectedCropId = this.dynForm?.form?.get('SecondCrop')?.value;
    const yieldDefault = selectedCropId && this.cropTypeStore.CropTypes.Find(c => c.Id === selectedCropId) ? 0 : undefined;
    this.dynForm?.form?.get('SecondCrop_YieldUnit')?.setValue(yieldDefault);
  }

  private onFieldSelectionChanged(): void {
    const selectedField = this.dynForm?.form?.get('FieldSelection')?.value;
    if (!selectedField) {
      return;
    }
    this.data.CurrentItem = this.data.AllItems.find(x => x.Id === selectedField.Id);
    this.dynForm?.form?.get('FieldNumber')?.setValue(this.numericService.getNumberOrZero(selectedField.SourceItem.FieldNumber));
    this.dynForm?.form?.get('FieldSubnumber')?.setValue(this.numericService.getNumberOrZero(selectedField.SourceItem.FieldSubnumber));
    if (selectedField.SourceItem.FieldName === this.translationService.FindTranslationForSelectedLanguage(MergeNewFieldName)) {
      this.dynForm?.form?.get('FieldName')?.setValue('');
    } else {
      this.dynForm?.form?.get('FieldName')?.setValue(selectedField.SourceItem.FieldName);
    }
    if (this.data.CurrentItem.SourceItem.FieldCrops.length > 0) {
      const fieldCrop = this.data.CurrentItem.SourceItem.FieldCrops[0];
      this.dynForm?.form?.get('MainCrop')?.setValue(fieldCrop.CroptypeId);
      this.dynForm?.form?.get('SecondCrop')?.setValue(fieldCrop.CroptypeSecondId);
      this.dynForm?.form?.get('RemoveResidues')?.setValue(fieldCrop.StrawHarvested);
      this.dynForm?.form?.get('MainCrop_YieldUnit')?.setValue(fieldCrop.ExpectedYield);
      this.dynForm?.form?.get('SecondCrop_YieldUnit')?.setValue(fieldCrop.ExpectedYieldSecond);
    } else {
      this.dynForm?.form?.get('MainCrop')?.setValue(null);
      this.dynForm?.form?.get('SecondCrop')?.setValue(null);
      this.dynForm?.form?.get('RemoveResidues')?.setValue(false);
    }
  }

  private calculateAreaSizeString(): string {
    return this.calculateAreaSizeStringByValue(this.data.AllItems.reduce((sum, it) => sum + this.getAreaValue(it.SourceItem), 0));
  }

  private calculateAreaSizeStringByValue(value: number): string {
    return this.roundNumericService.round(value, this.settingsStore.FirstSetting.DigitsAfterDecimalPoint);
  }

  private getAreaValue(field: IField): number {
    return field.DefaultGeom != null && field.DefaultGeom.AdminArea > 0 ? field.DefaultGeom.AdminArea / 10000 : 0;
  }
}

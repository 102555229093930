import {Component} from '@angular/core';

const CONTENT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.7 176.05">
  <g>
    <path d="M14.44,27.93V133.37a.19.19,0,0,1,0,.1h0l.13,0,78.5,21.33a11.29,11.29,0,0,1,1.53.53,13.71,13.71,0,0,1,1.47.68l30.11,16.53s0,0,0,0l0,0,.09,0,59.09-16.09.13,0h0v0a.16.16,0,0,0,0-.09l-.41-89.61A4.6,4.6,0,0,0,181,62l-31.45-1.15a11.49,11.49,0,0,1-1.89-.19,12.26,12.26,0,0,1-1.82-.47L46.38,27.47,46,27.36s0,0,0,0l-31.46.42h0l0,0v0a.16.16,0,0,0,0,.09M.15,133.37V27.93A16.15,16.15,0,0,1,4.32,17v0A13.83,13.83,0,0,1,14.38,12.4L45.84,12a13.62,13.62,0,0,1,2.21.13,13.15,13.15,0,0,1,2.14.54l99.62,32.79.15,0h0s0,0,0,0l35.56,1.3a13.84,13.84,0,0,1,9.84,4.69,16.16,16.16,0,0,1,4.06,10.76l.43,94a16.26,16.26,0,0,1-3,9.61h0a14.31,14.31,0,0,1-7.87,5.54l-59.21,16.13a13.26,13.26,0,0,1-10-1.22L89.66,169.8h0L11.05,148.45a14.3,14.3,0,0,1-7.87-5.52h0a16.38,16.38,0,0,1-3-9.55" transform="translate(-0.15 -11.97)" />
    <path d="M89.65,169.78h0Z" transform="translate(-0.15 -11.97)"/>
    <path d="M75.35,45.47v93.58a4.76,4.76,0,0,1-1.69,3.74,4,4,0,0,1-3.77.85l-8.18-2.22a4.62,4.62,0,0,1-3.32-4.59V42.78A4.75,4.75,0,0,1,60.17,39a4,4,0,0,1,3.89-.73l8.18,2.7a4.63,4.63,0,0,1,3.11,4.53" transform="translate(-0.15 -11.97)"/>
    <path d="M54.44,38.59v94.78a4.76,4.76,0,0,1-1.69,3.74A4,4,0,0,1,49,138l-8.18-2.23a4.62,4.62,0,0,1-3.32-4.59V36.89a4.58,4.58,0,0,1,4.33-4.74l2.91,0a3.84,3.84,0,0,1,1.34.21l5.27,1.73a4.66,4.66,0,0,1,3.11,4.54" transform="translate(-0.15 -11.97)"/>
    <path d="M33.52,37.06v90.63a4.76,4.76,0,0,1-1.69,3.74,4,4,0,0,1-3.76.86l-5.92-1.61a4.64,4.64,0,0,1-3.32-4.6V37.14a4.59,4.59,0,0,1,4.33-4.74l5.92-.08a4.06,4.06,0,0,1,3.14,1.37,4.75,4.75,0,0,1,1.3,3.37" transform="translate(-0.15 -11.97)"/>
    <path d="M78.94,142.37V107.42a4.58,4.58,0,0,1,4.39-4.74h8.18a4.58,4.58,0,0,1,4.38,4.74v37.64a4.75,4.75,0,0,1-1.77,3.81,4,4,0,0,1-3.89.72l-8.18-2.69a4.65,4.65,0,0,1-3.11-4.53" transform="translate(-0.15 -11.97)"/>
    <path d="M76.12,54.34c0-6.58,5-11.91,11.1-11.91s11.1,5.33,11.1,11.91-5,11.92-11.1,11.92-11.1-5.33-11.1-11.92m33.31,0c0-13.16-10-23.82-22.21-23.82S65,41.18,65,54.34,87.22,102,87.22,102s22.21-34.49,22.21-47.65" transform="translate(-0.15 -11.97)" class="hoverFill"/>
  </g>
</svg>

`;

@Component({
  selector: 'ap-icon-field-works',
  template: CONTENT
})
export class ApIconFieldWorksComponent {
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IUser = Data.Authentication.IUser;

export enum UsersActions {
  USERS_LOAD = '[users] load',
  USERS_LOAD_SUCCESS = '[users] load success',
  USERS_LOAD_FAIL = '[users] load fail',
  USER_CREATE = '[user] create',
  USER_CREATE_SUCCESS = '[user] create success',
  USER_CREATE_FAIL = '[user] create fail',
  USER_UPDATE_FULLNAME = '[user] update full name',
  USER_UPDATE_FULLNAME_SUCCESS = '[user] update full name success',
  USER_UPDATE_FULLNAME_FAIL = '[user] update full name fail',
  USER_UNIQUE_EMAIL = '[user] check unique email',
  USER_UNIQUE_EMAIL_RESULT = '[user] check unique email result',
  USER_UNIQUE_USERNAME = '[user] check unique username',
  USER_UNIQUE_USERNAME_RESULT = '[user] check unique username result',
  USER_RESET_REDIRECT_TO_NEWS = '[user] reset redirect to news',
  USER_RESET_REDIRECT_TO_NEWS_SUCCESS = '[user] reset redirect to news success'
}

export class UsersLoad implements IAction {
  readonly type = UsersActions.USERS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UsersLoadSuccess implements IAction {
  readonly type = UsersActions.USERS_LOAD_SUCCESS;

  constructor(public payload: IUser[]) {
  }
}

export class UsersLoadFail implements IAction {
  readonly type = UsersActions.USERS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class UserCreate implements IAction {
  readonly type = UsersActions.USER_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserCreateFail implements IAction {
  readonly type = UsersActions.USER_CREATE_FAIL;

  constructor(public payload: IUser) {
  }
}

export class UserCreateSuccess implements IAction {
  readonly type = UsersActions.USER_CREATE_SUCCESS;

  constructor(public payload: IUser) {
  }
}

export class UserUpdateFullName implements IAction {
  readonly type = UsersActions.USER_UPDATE_FULLNAME;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserUpdateFullNameSuccess implements IAction {
  readonly type = UsersActions.USER_UPDATE_FULLNAME_SUCCESS;

  constructor(public payload: IUser) {
  }
}

export class UserUpdateFullNameFail implements IAction {
  readonly type = UsersActions.USER_UPDATE_FULLNAME_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserCheckUniqueEmail implements IAction {
  readonly type = UsersActions.USER_UNIQUE_EMAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserCheckUniqueEmailResult implements IAction {
  readonly type = UsersActions.USER_UNIQUE_EMAIL_RESULT;

  constructor(public payload: boolean) {
  }
}

export class UserCheckUniqueUsername implements IAction {
  readonly type = UsersActions.USER_UNIQUE_USERNAME;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserCheckUniqueUsernameResult implements IAction {
  readonly type = UsersActions.USER_UNIQUE_USERNAME_RESULT;

  constructor(public payload: boolean) {
  }
}

export class UserResetRedirectToNews implements IAction {
  readonly type = UsersActions.USER_RESET_REDIRECT_TO_NEWS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UserResetRedirectToNewsSuccess implements IAction {
  readonly type = UsersActions.USER_RESET_REDIRECT_TO_NEWS_SUCCESS;

  constructor(public payload: IUser) {
  }
}


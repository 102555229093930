import OlFeature                                from 'ol/Feature';
import {createEmpty, extend, Extent, getCenter} from 'ol/extent';
import {Coordinate}                             from 'ol/coordinate';

/**
 * some basic Geometry Operations
 */
export class ApGeometryExtension {
  /**
   * calculates the center point of a list of Openlayers Features
   */
  static calcCenterOfFeatures(geoms: OlFeature[]): Coordinate {
    const ext = ApGeometryExtension.calcExtentOfFeatures(geoms);
    return getCenter(ext);
  }

  /**
   * calculate the Extent Bounding Box of a list of Openlayers Features
   */
  static calcExtentOfFeatures(geoms: OlFeature[]): Extent {
    const ext = createEmpty();
    for (const g of geoms) {
      extend(ext, g.getGeometry().getExtent());
    }
    return ext;
  }
}

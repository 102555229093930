import {IStateStoreWritable} from '../../ap-interface/interfaces/store';
import {Store}               from '../index';
import {Injectable}          from '@angular/core';
import {
  ApCustomTypes,
  LoggerCreate,
  LoggerDelete,
  LoggerLoad,
  LoggerLoadSuccess,
  LoggerSaveFail,
  LoggerSaveSuccess,
  LoggerUpdate
}                            from 'invoker-transport';
import ILogger = Data.DocuContext.Logger.ILogger;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

@Injectable({providedIn: 'root'})
export class LoggerStore extends Store<IStateStoreWritable<ILogger>> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      saving: false,
      saved: false
    });

    backend.registerObservable(LoggerLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(LoggerSaveSuccess).subscribe(() => {
      super.SetSaveSuccessState();
    });
    backend.registerObservable(LoggerSaveFail).subscribe(() => {
      super.SetSaveFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  getLogger(): ILogger[] {
    return super.Listen(s => s.data).getValue();
  }

  public loadLogger(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new LoggerLoad([]));
  }

  /**
   * Updates the logger via backend.
   */
  public updateLogger(logger: ILogger): void {
    super.SetSaveState();
    this.DispatchBackend(new LoggerUpdate([
      {Name: 'logger', Type: ApCustomTypes.Data_DocuContext_Logger_Logger, Value: logger}
    ]));
  }

  /**
   * Updates the logger via backend.
   */
  public createLogger(logger: ILogger): void {
    super.SetSaveState();
    this.DispatchBackend(new LoggerCreate([
      {Name: 'logger', Type: ApCustomTypes.Data_DocuContext_Logger_Logger, Value: logger}
    ]));
  }

  /**
   * Sets delete flag of the logger the via backend.
   */
  public deleteLogger(logger: ILogger): void {
    this.DispatchBackend(new LoggerDelete([
      {Name: 'logger', Type: ApCustomTypes.Data_DocuContext_Logger_Logger, Value: logger}
    ]));
  }
}

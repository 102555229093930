import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199 149.19">
  <polygon points="0 60.94 23.95 81.19 23.95 79.55 0 59.3 0 60.94"/>
  <g>
    <path d="M90.27,173.17c3.74,0,4.36-2.34,1.38-5.19L78.52,155.4a4,4,0,0,0-2.12-1.12l-6.47-1.17a4,4,0,0,1-1.9-.92L3.5,97.75a3.81,3.81,0,0,0-3-1v32.44c0,4.73.06,8.59.13,8.59,10.35,0,18.76,10.65,18.76,23.74v3c0,4.73,3.06,8.6,6.8,8.6Z" transform="translate(-0.5 -23.98)"/>
    <path d="M28.12,108.21l6.4,7a4.81,4.81,0,0,0,.57.54L68.9,142.37a3.87,3.87,0,0,0,5.68-.82L86.09,127a4,4,0,0,1,3.25-1.7h.46l2.74-5.1a4.87,4.87,0,0,1,.55-.84l7.09-9a5.12,5.12,0,0,1,.8-.81L152.51,68.5a5.85,5.85,0,0,0,2.09-4,6.71,6.71,0,0,0-1-4.64L131,26.21H99.27L37.18,71.47a5.45,5.45,0,0,0-2,2.94l-8.35,27.31a6.76,6.76,0,0,0,1.28,6.49" transform="translate(-0.5 -23.98)" class="hoverFill"/>
    <path d="M150.57,167.92l21.87-22a2.08,2.08,0,0,1,3.23.21l1.4,1.74a2.07,2.07,0,0,0,2.94.46l8.55-6.26a3,3,0,0,0,1.17-2.2,3.35,3.35,0,0,0-.69-2.49l-.36-.45a3.54,3.54,0,0,1,0-4.22l7.07-8.75a2.1,2.1,0,0,1,1.83-.87,2.19,2.19,0,0,1,1.75,1.11c.1.14.17,4.13.17,8.86v31.51c0,4.73-3.06,8.6-6.79,8.6H157.93c-3.74,0-7.08-.3-7.42-.67a3.48,3.48,0,0,1,.06-4.58m-9.9-135.46L182.91,94.2c1.55,2.27,4.17,2.76,6.19,1.16l4.64-3.65c3.17-2.5,5.76-8.42,5.76-13.14v-46c0-4.72-3.06-8.59-6.79-8.59H148.08c-3.74,0-7.05.36-7.36.79a7,7,0,0,0-.05,7.69" transform="translate(-0.5 -23.98)"/>
    <path d="M108.19,168l15.67-18.6a4.89,4.89,0,0,1,.5-.52l51.05-45.26a6,6,0,0,0,1.92-4,6.73,6.73,0,0,0-1.07-4.5L161.37,73c-1.49-2.2-4-2.69-5.94-1.14l-52.05,41.48a5.12,5.12,0,0,0-.8.81l-6.53,8.26a4.87,4.87,0,0,0-.55.84l-11.31,21a6.88,6.88,0,0,0,1,7.92l17.06,16.19a3.88,3.88,0,0,0,5.9-.41" transform="translate(-0.5 -23.98)" class="hoverFill"/>
    <path d="M197.36,117.45l-11.63-18.2m8.59,23.68-14.56-22.82" transform="translate(-0.5 -23.98)"/>
  </g>
</svg>

`;

@Component({
  selector: 'ap-icon-fields',
  template: CONTENT
})
export class ApIconFieldsComponent {
}

import {NgModule}                           from '@angular/core';
import {CommonModule}                       from '@angular/common';
import {ApEditActionComponent}              from './ap-edit-action.component';
import {ReactiveFormsModule}                from '@angular/forms';
import {ApTranslationModule}                from '../../translation/ap-translation.module';
import {ApCoreModule}                       from '../../ap-core/ap-core.module';
import {DatePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {ComboBoxModule}                     from '@progress/kendo-angular-dropdowns';
import {NumericTextBoxModule}               from '@progress/kendo-angular-inputs';
import {ApDocuModule}                       from '../../docu/ap-docu.module';
import {ApResponsiveTextModule}             from '../../ap-responsive-text/ap-responsive-text.module';
import {ApDynComponentModule}               from '../../ap-dyncomponent/ap-dyncomponent.module';

const COMPONENTS = [
  ApEditActionComponent
];

@NgModule({
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ApTranslationModule,
    ApCoreModule,
    DatePickerModule,
    TimePickerModule,
    ComboBoxModule,
    NumericTextBoxModule,
    ApDocuModule,
    ApResponsiveTextModule,
    ApDynComponentModule
  ],
  exports: COMPONENTS,
})
export class ApDocuEntryComponentsModule { }

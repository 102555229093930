import {Pipe, PipeTransform} from '@angular/core';
import {Trace}               from '../../debug-utils/ApplicationTracer';
import {LanguageStore}       from '../../stores/translation/language.store';
import {TranslationStore}    from '../../stores/translation/translation.store';

/**
 * Translation Pipe Interface
 */
export interface IApTranslationPipe {
  transform(value: string): string;
}

/**
 * Translation Pipe
 */
@Pipe({
  name: 'translate'
})
export class ApTranslationPipe implements PipeTransform, IApTranslationPipe {
  constructor(private languageStore: LanguageStore,
              private translationStore: TranslationStore) {
  }

  @Trace()
  transform(value: string): string {
    return this.translationStore.FindTranslation(value, this.languageStore?.SelectedLanguage?.Key);
  }
}

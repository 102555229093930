import {IAction, IApInvokerArguments} from '../../interfaces';
import IFieldBase = Data.FieldManagement.IFieldBase;

export enum FieldArchiveActions {
  FIELD_ARCHIVE_LOAD = '[field archive] load',
  FIELD_ARCHIVE_LOAD_SUCCESS = '[field archive] load success',
}

/**
 * load deleted/archive fields (lightweight version of field) from backend
 */
export class FieldArchiveLoad implements IAction {
  readonly type = FieldArchiveActions.FIELD_ARCHIVE_LOAD;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * success of load deleted/archive fields (lightweight version of field) from backend
 */
export class FieldArchiveLoadSuccess implements IAction {
  readonly type = FieldArchiveActions.FIELD_ARCHIVE_LOAD_SUCCESS;
  constructor(public payload: IFieldBase[]) {
  }
}

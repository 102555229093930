import IFarmUsers = Data.Authentication.IFarmUsers;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum FarmUsersActions {
  FARM_USERS_LOAD = '[farm_users] load',
  FARM_USERS_LOAD_SUCCESS = '[farm_users] load success',
  FARM_USERS_LOAD_FAIL = '[farm_users] load fail',
  FARM_USERS_UPDATE = '[farm_users] update',
  FARM_USERS_UPDATE_SUCCESS = '[farm_users] update success',
  FARM_USERS_UPDATE_FAIL = '[farm_users] update fail',
  FARM_USERS_DELETE = '[farm_users] delete',
  FARM_USERS_DELETE_SUCCESS = '[farm_users] delete success',
}

export class FarmUsersLoad implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUsersLoadSuccess implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_LOAD_SUCCESS;

  constructor(public payload: IFarmUsers[]) {
  }
}

export class FarmUsersLoadFail implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}


export class FarmUsersUpdate implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUsersUpdateSuccess implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_UPDATE_SUCCESS;

  constructor(public payload: IFarmUsers) {
  }
}

export class FarmUsersUpdateFail implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_UPDATE_FAIL;

  constructor(public payload: string) {
  }
}

export class FarmUsersDelete implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FarmUsersDeleteSuccess implements IAction {
  readonly type = FarmUsersActions.FARM_USERS_DELETE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import {IApMachinePopup}                          from '../../ap-map';

/**
 * Machine popup component (popup used in location layer on map)
 */
@Component({
  selector: 'ap-machine-popup',
  templateUrl: 'ap-machine-popup.component.html',
  styleUrls: ['ap-machine-popup.component.scss']
})
export class ApMachinePopupComponent implements IApMachinePopup {

  /**
   * shows or hides the popup with the use of *ngIf
   */
  public visible = false;

  /**
   * name to be displayed in popup
   */
  public name: string;

  /**
   * speed to be displayed in popup
   */
  public speed: string;

  /**
   * date to be displayed in popup
   */
  public date: string;

  /**
   * Initializes this instance
   */
  constructor(private elementRef: ElementRef,
              private changeDetection: ChangeDetectorRef) {
  }

  /**
   * Gets the component's element for further use in other classes/components.
   */
  public getHTMLElement(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  /**
   * Refreshes component data (Change detection)
   */
  public refreshComponent(): void {
    this.changeDetection.detectChanges();
  }

  /**
   * Displays the popup
   */
  public show(): void {
    this.visible = true;
    this.refreshComponent();
  }

  /**
   * Hides the popup
   */
  public hide(): void {
    this.visible = false;
    this.refreshComponent();
  }
}

import {Pipe, PipeTransform}         from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LanguageStore}               from '../../stores/translation/language.store';
import {TranslationStore}            from '../../stores/translation/translation.store';
import {ObjectFactory}               from 'ts-tooling';

@Pipe({
  name: 'translate_array'
})
export class ApTranslationArrayPipe implements PipeTransform {

  constructor(private languageStore: LanguageStore,
              private translationStore: TranslationStore) {
  }

  transform(values: any[], textField = ''): Observable<any[]> {
    if (textField !== '') {
      return this.getObservableObject(values, textField);
    } else {
      return this.getObservableString(values);
    }
  }

  private getObservableObject(values: any[], textField: string): Observable<any[]> {
    const stream = new BehaviorSubject<any[]>(
      values.map(value => this.updateTextField(value, textField))
    );

    this.translationStore.translationsLoaded.subscribe(() => {
      stream.next(values.map(value => this.updateTextField(value, textField)));
    });

    return stream;
  }

  private updateTextField(o: any, textField: string): any {
    o = ObjectFactory.Copy(o);
    o[textField] = this.translationStore.FindTranslation(o[textField], this.languageStore?.SelectedLanguage?.Key);
    return o;
  }

  private getObservableString(values: string[]): Observable<string[]> {
    const stream = new BehaviorSubject<string[]>(
      values.map(value => this.translationStore.FindTranslation(value, this.languageStore?.SelectedLanguage?.Key))
    );

    this.translationStore.translationsLoaded.subscribe(() => {
      stream.next(values.map(value => this.translationStore.FindTranslation(value, this.languageStore?.SelectedLanguage?.Key)));
    });

    return stream;
  }

}

import {Injectable}             from '@angular/core';
import {FieldStore}             from '../../stores/farm/field.store';
import {CropTypeStore}          from '../../stores/base-data/crop.types.store';
import {ApElementType}          from '../../ap-interface/enums/ap-elements-type.enum';
import {ApElementOxydTypeEnum}  from '../../ap-interface';
import {APP_CONFIGURATION}      from '../../ap-core/config';
import {ApTranslationService}   from './ap-translation.service';
import {GetElementService}      from './ap-get-element.service';
import {GetRoundNumericService} from './get-round-numeric.service';
import {FertilizerStore}        from '../../stores/base-data/fertilizer.store';
import {LanguageStore}          from '../../stores/translation/language.store';
import {ApDateService}          from '../../ap-core/services/ap-date-service';
import {ApGetCropService}       from '../../stores/services/ap-get-crop.service';
import IGuid = System.IGuid;


@Injectable({providedIn: 'root'})
/**
 * Generic service for generating tooltips
 */
export class ApTooltipContentService {
  constructor(private fieldStore: FieldStore,
              private languageStore: LanguageStore,
              private cropTypeStore: CropTypeStore,
              private fertilizerStore: FertilizerStore,
              private dateService: ApDateService,
              private cropService: ApGetCropService,
              private elementService: GetElementService,
              private translationService: ApTranslationService,
              private roundNumericService: GetRoundNumericService) {
  }

  /**
   * Generates tooltip showing a list of fields and their crop
   * @param data an array of elements containing at least FieldId and MainCropId
   */
  public getFieldsCropsTooltip(data: { FieldId: IGuid, MainCropId: number }[]): string {
    if (!data || data.length <= 0) {
      return '';
    }
    const formattedData = data.filter(x => x).map(item => {
      const fieldName = this.fieldStore.getFieldNameById(item.FieldId);
      const cropName = this.cropTypeStore.getDefaultCroptype(item.MainCropId)?.Description;
      return {
        FieldName: fieldName,
        CropName: cropName
      };
    });
    return this._getFieldsTooltip(formattedData);
  }

  public getFieldsCropsTooltipForBaseFertilization(data: { FieldId: IGuid, Year: number }[]): string {
    if (!data || data.length <= 0) {
      return '';
    }
    const formattedData = data.filter(x => x).map(item => {
      const fieldName = this.fieldStore.getFieldNameById(item.FieldId);
      const cropRotation = this.cropService.getCropRotationByYear(item.FieldId, item.Year);
      const cropName = this.cropService.getCropNameFromCropRotationYield(cropRotation);
      return {
        FieldName: fieldName,
        CropName: cropName
      };
    });
    return this._getFieldsTooltip(formattedData);
  }

  public getProductContentInfoTooltip(productId: number): string {
    if (productId === 0) {
      return '';
    }
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    if (!fertilizer) {
      return '';
    }
    let title = this.translationService.translate('Global__Content');
    if (fertilizer?.Unit?.Id === 9) {
      const kgm = this.translationService.translate('Global__Unit_kg') + '/' + this.translationService.translate('Global__UnitM3');
      title = title + ' [' + kgm + ']';
    }
    if (fertilizer?.Unit?.Id === 10) {
      const kgt = this.translationService.translate('Global__Unit_kg') + '/' + this.translationService.translate('Global__Unit_t');
      title = title + ' [' + kgt + ']';
    }
    if (fertilizer?.Unit?.Id === 6 || fertilizer?.Unit?.Id === 7) {
      title = title + ' [' + this.translationService.translate('Global__Unit_Percent') + ']';
    }
    const titlePP2O5 = this.elementService.GetElementStringByString(ApElementType.P);
    const titleKK2O = this.elementService.GetElementStringByString(ApElementType.K);
    const titleMgMgO = this.elementService.GetElementStringByString(ApElementType.Mg);
    const titleCaO = this.elementService.GetElementStringByString(ApElementType.CaO);
    const titleN = this.elementService.GetElementStringByString(ApElementType.N);
    const contentP = this.roundNumericService.roundAsNumber(this.elementService.CalculateElementOxidValueByGivenElementValue(fertilizer.ContentP, ApElementOxydTypeEnum.P), APP_CONFIGURATION.SpecialDecimalPlaces.OrgFertContent);
    const contentK = this.roundNumericService.roundAsNumber(this.elementService.CalculateElementOxidValueByGivenElementValue(fertilizer.ContentK, ApElementOxydTypeEnum.K), APP_CONFIGURATION.SpecialDecimalPlaces.OrgFertContent);
    const contentMg = this.roundNumericService.roundAsNumber(this.elementService.CalculateElementOxidValueByGivenElementValue(fertilizer.ContentMg, ApElementOxydTypeEnum.Mg), APP_CONFIGURATION.SpecialDecimalPlaces.OrgFertContent);
    const contentCaO = this.roundNumericService.roundAsNumber(fertilizer.ContentCaO, APP_CONFIGURATION.SpecialDecimalPlaces.OrgFertContent);
    const contentN = this.roundNumericService.roundAsNumber(fertilizer.ContentN, APP_CONFIGURATION.SpecialDecimalPlaces.OrgFertContent);

    return `<table style="width: 240px; border-collapse: collapse; height: 50px; border: 1px solid black;"><caption>${title}</caption>\n` +
      '<tbody>\n' +
      '<tr style="height: 20px;">\n' +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${titlePP2O5}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${titleKK2O}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${titleMgMgO}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${titleCaO}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${titleN}</td>\n` +
      '</tr>\n' +
      '<tr style="height: 18px;">\n' +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${contentP}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${contentK}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${contentMg}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${contentCaO}</td>\n` +
      `<td style="width: 60px; height: 18px; text-align: center; border: 1px solid black;">${contentN}</td>\n` +
      '</tr>\n' +
      '</tbody>\n' +
      '</table>';
  }

  public generateCropNoteTooltip(note: string): string {
    if (!note) {
      return '';
    }
    return `<div style="max-width: 240px;">${note}</div>`;
  }

  public planBooksApplicationDatesTooltip(data: { ApplicationDate: Date }[]): string {
    if (!data || data.length <= 0) {
      return '';
    }
    let fieldsTooltip = '<div style="display:grid; column-gap: 10px">';
    data.forEach(item => {
      const applicationDate = this.dateService.dateToMoment(item.ApplicationDate)
        .format(this.languageStore.CurrentDateFormat);
      fieldsTooltip += `<div style="grid-column: 1">${applicationDate ?? ''}</div>`;
    });
    fieldsTooltip += '</div>';
    return fieldsTooltip;
  }

  private _getFieldsTooltip(data: { FieldName: string | null, CropName: string | null }[]): string {
    let fieldsTooltip = '<div style="display:grid; column-gap: 10px">';
    for (const d of data) {
      fieldsTooltip += `<div style="grid-column: 1">${d.FieldName ?? ''}</div>`;
      fieldsTooltip += `<div style="grid-column: 2">\t\t${d.CropName ? d.CropName : '-'}</div>`;
    }
    fieldsTooltip += '</div>';
    return fieldsTooltip;
  }
}

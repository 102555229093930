<div style="display:flex">
  <kendo-dropdownlist class="ap-grid-pager-size-dropdown ap-dropdown ap-pagerElement dark-bg"
    [data]="pageSizeItems"
    [(value)]="currentValue"
    textField="text"
    valueField="value"
    [popupSettings]="{
          width: 'auto'
        }"
    (valueChange)="valueChange($event)">
    <ng-template kendoComboBoxItemTemplate kendoDropDownListValueTemplate let-dataItem>
      {{dataItem.text}}
    </ng-template>
  </kendo-dropdownlist>
</div>

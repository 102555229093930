export class ClientCache {
  static writeValue(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static readValue<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key));
  }

  static deleteValue(key: string): void {
    localStorage.removeItem(key);
  }
}

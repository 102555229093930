import {
  Component,
  Input, OnChanges, OnDestroy,
  OnInit, SimpleChanges
} from '@angular/core';
import {ApDynformsConfigArray}             from './config/ap-dynforms-config-array';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ApValidator}                       from './validators/ap-validator';
import {Subscription}                      from 'rxjs';

@Component({
  selector: 'ap-dynforms-array',
  templateUrl: './ap-dynforms-array.component.html'
})
export class ApDynformsArrayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: ApDynformsConfigArray;
  @Input() darkMode: boolean;
  @Input() fontSize: number;
  @Input() form: FormGroup;

  private _displayItems: number;
  private _subscriptions: Subscription[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this._displayItems = this.config.validate ? this.config.min : this.config.max;
    this._subscriptions.push(this.form.get(this.config.key).valueChanges.subscribe(values => {
      this._valuesChange(values);
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this._setupControls();
    }
    if (changes.config) {
      this._displayItems = this.config.validate ? this.config.min : this.config.max;
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  get arrayMax(): number[] {
    const array = [];
    for (let i = 0; i < this._displayItems; ++i) {
      array.push(i);
    }
    return array;
  }

  get formGroup(): FormGroup {
    const tokens = this.config.key.split('.');
    tokens.pop();
    if (tokens.length) {
      return this.form.get(tokens.join('.')) as FormGroup;
    }
    return this.form;
  }

  getArrayName(): string {
    return this.config.key.split('.').pop();
  }

  getArrayFormGroup(value: any): FormGroup {
    return this.form.get(`${this.config.key}.${value}`) as FormGroup;
  }

  private _setupControls(): void {
    const arrayControls: FormGroup[] = [];
    for (let i = 0; i < this.config.max; ++i) {
      const formGroup = new FormGroup({});
      for (const config of this.config.config) {
        const validators = config.validators ?
          config.validators.map(v => ApValidator.validate(v.validator, v.errorKey, v.invalidIds)) :
          null;

        const asyncValidators = config.asyncValidators ?
          config.asyncValidators.map(v => ApValidator.validateAsync(v.validator, v.errorKey, v.invalidIds)) :
          null;

        formGroup.addControl(config.key, new FormControl({
            value: isNaN(config.value) ? config.value || null : config.value,
            disabled: (config.disabled ? config.disabled : false),
          },
          {
            validators,
            asyncValidators,
            updateOn: 'blur'
          }
        ));
      }
      arrayControls.push(formGroup);
    }

    let group = this.form;
    let key = this.config.key;
    const tokens = key.split('.');
    for (let i = 0; i < tokens.length - 1; ++i) {
      if (!group.get(tokens[i])) {
        group.addControl(tokens[i], new FormGroup({}));
      }
      group = group.get(tokens[i]) as FormGroup;
      key = tokens[i + 1];
    }
    group.addControl(key, new FormArray(arrayControls));
  }

  private _valuesChange(values: any[]): void {
    if (this.config) {
      this.form.get(this.config.key).setValue(this._valuesValidate(values), {emitEvent: false});
    }
  }

  private  _valuesValidate(values: any[]): any[] {
    if (this.config.validate) {
      values.forEach(v => {
        if (!this.config.validate(v)) {
          for (const k of Object.keys(v)) {
            v[k] = null;
          }
        }
      });
      values.sort((a, b) => {
        a = this.config.validate(a) ? 1 : 0;
        b = this.config.validate(b) ? 1 : 0;
        return b - a;
      });

      const lastComplete = values.map(v => this.config.extendOn(v)).lastIndexOf(true) + 1;
      if (lastComplete !== this.config.max) {
        this._displayItems = Math.max(this.config.min, Math.min(lastComplete + 1, this.config.max));
      } else {
        this._displayItems = this.config.max;
      }
    }
    return values;
  }
}

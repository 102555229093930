import {IStateStore} from '../../ap-interface/interfaces/store';
import {Store}       from '../index';
import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  MachinesLoggerCreate,
  MachinesLoggerDelete,
  MachinesLoggerLoad,
  MachinesLoggerLoadSuccess,
  MachinesLoggerUpdate
}                    from 'invoker-transport';
import IMachineLogger = Data.DocuContext.MachinesLogger.IMachinesLogger;
import IMachinesLogger = Data.DocuContext.MachinesLogger.IMachinesLogger;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IMachineLoggerStore extends IStateStore<IMachineLogger> {
}

@Injectable({providedIn: 'root'})
export class MachineLoggerStore extends Store<IMachineLoggerStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(MachinesLoggerLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  getMachineLogger(): IMachineLogger[] {
    return super.Listen(s => s.data).getValue();
  }

  /**
   *  load machines logger from Backend to the Store
   */
  public loadMachinesLogger(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new MachinesLoggerLoad([]));
  }

  /**
   * Updates machinesLogger via backend graphql.
   */
  public updateMachinesLogger(machinesLogger: IMachineLogger): void {
    this.DispatchBackend(new MachinesLoggerUpdate([
      {
        Name: 'machinesLogger',
        Type: ApCustomTypes.Data_DocuContext_MachinesLogger_MachinesLogger,
        Value: machinesLogger
      }
    ]));
  }

  /**
   * Creates new machinesLogger via backend graphql.
   */
  public createMachinesLogger(machinesLogger: IMachinesLogger): void {
    this.DispatchBackend(new MachinesLoggerCreate([
      {
        Name: 'machinesLogger',
        Type: ApCustomTypes.Data_DocuContext_MachinesLogger_MachinesLogger,
        Value: machinesLogger
      }
    ]));
  }

  /**
   * Deletes machinesLogger via backend graphql.
   */
  public deleteMachinesLogger(machinesLogger: IMachinesLogger): void {
    this.DispatchBackend(new MachinesLoggerDelete([
      {
        Name: 'machinesLogger',
        Type: ApCustomTypes.Data_DocuContext_MachinesLogger_MachinesLogger,
        Value: machinesLogger
      }
    ]));
  }
}

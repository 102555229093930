import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getKendoDateFormat'
})
export class GetKendoDateFormatPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return value.split('Y').join('y').split('D').join('d');
  }
}

import {Injectable}                           from '@angular/core';
import {ChartSetValueType, IChartAxesOptions} from '../../stores/statistic/statistic.store';

export interface IImageStyle {
  display: string;
  'background-color': string;
  'text-align': string;
  color: string;
  'font-weight': string;
}

@Injectable({providedIn: 'root'})
export class GetSoilSampleClassColorService {
  private borderColor = '#76777b';
  private defaultColor = 'inherit';

  public getColor(classVal: string | number): string {
    let color = this.defaultColor;
    if (classVal === 'A-' || classVal === 1) {
      color = '#f00a00';
    } else if (classVal === 'A+' || classVal === 2) {
      color = '#d75000';
    } else if (classVal === 'B-' || classVal === 3) {
      color = '#d67000';
    } else if (classVal === 'B+' || classVal === 4) {
      color = '#d9a000';
    } else if (classVal === 'C-' || classVal === 5) {
      color = '#d4dd00';
    } else if (classVal === 'C+' || classVal === 6) {
      color = '#a6d400';
    } else if (classVal === 'D-' || classVal === 7) {
      color = '#64ab00';
    } else if (classVal === 'D+' || classVal === 8) {
      color = '#298600';
    } else if (classVal === 'E' || parseFloat(classVal?.toString()) >= 9) {
      color = '#0082ff';
    }
    return color;
  }

  public getImageStyle(classVal: string | number, border = false): IImageStyle {
    const color = this.getColor(classVal);
    const res = {
      display: 'flex',
      'background-color': color,
      'text-align': 'center',
      color: color !== this.defaultColor ? 'white' : 'inherit',
      'align-items': 'center',
      'font-weight': color !== this.defaultColor ? 'bold' : 'inherit'
    };
    if (border) {
      res['text-shadow'] = `-1px 0 ${this.borderColor}, 0 1px ${this.borderColor}, 1px 0 ${this.borderColor}, 0 -1px ${this.borderColor}`;
      res['border'] = `1px solid ${this.borderColor}`;
    }
    return res;
  }

  public getStatisticAxesConfig(type: ChartSetValueType, min?: number, max?: number): IChartAxesOptions {
    switch (type) {
      case ChartSetValueType.CLASS:
        return {
          titleX: 'ha',
          titleY: '',
          minX: min || 0,
          maxX: max,
        };
      default:
        return {
          titleX: '',
          titleY: '',
          minX: min || 0,
          maxX: max,
        };
    }
  }
}

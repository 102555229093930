import {IStateStore}                                                             from '../../ap-interface';
import {Store}                                                                   from '../index';
import {EventEmitter, Injectable}                                                from '@angular/core';
import {
  ApCustomTypes,
  FieldCheckPlanBooks,
  FieldCheckPlanBooksSuccess,
  FieldCreate,
  FieldCropDelete,
  FieldCropsSave,
  FieldCropsSaveFailed,
  FieldCropsSaveSuccess,
  FieldCropYearLoad,
  FieldCropYearLoadSuccess,
  FieldDelete,
  FieldGeomDelete,
  FieldIntegrate,
  FieldIntegrateSuccess,
  FieldLoad,
  FieldLoadFromDateRange,
  FieldLoadFromDateRangeSuccess,
  FieldLoadSuccess,
  FieldMerge,
  FieldMergeFailed,
  FieldSave,
  FieldSaveFail,
  FieldSaveSuccess,
  FieldSplit,
  FieldSplitFailed,
  FieldSplitSuccess,
  FieldUpdate,
  HibernateModelNames,
  NetTypes,
  ParameterValuesSaveRequest
}                                                                                from 'invoker-transport';
import {isDate}                                                                  from 'lodash';
import {MapStore}                                                                from '../map/map.store';
import {DateRange, DateTime, Guid, ObjectFactory, SafeBehaviorSubject, Throttle} from 'ts-tooling';
import {
  ApParameterComponent
}                                                                                from '../../entry-components/ap-parameter-entry-components/ap-parameter.component';
import {CropTypeStore}                                                           from '../base-data/crop.types.store';
import {SettingsStore}                                                           from '../base-data/settings.store';
import {BehaviorSubject}                                                         from 'rxjs';
import {CropRotationStore}                                                       from './crop.rotation.store';
import {NdiStore}                                                                from '../ndi/ndi.store';
import {CropGroupStore}                                                          from '../base-data/crop.groups.store';
import {FormStore}                                                               from '../layout/form.store';
import {
  ApTranslationService
}                                                                                from '../../ap-utils/service/ap-translation.service';
import {FieldArchiveStore}                                                       from './field-archive.store';
import {
  FieldMergeSuccess
}                                                                                from '../../../../projects/invoker-transport/src/lib/actions/farm';
import IField = Data.FieldManagement.IField;
import IFieldCrop = Data.FieldManagement.IFieldCrop;
import IGuid = System.IGuid;
import IFieldBase = Data.FieldManagement.IFieldBase;
import IFieldGeom = Data.FieldManagement.IFieldGeom;
import IResultMessage = Invoker.IResultMessage;
import IGeoJsonPolygon = Data.Geometry.IGeoJsonPolygon;
import ICropTypes = Data.BaseData.ICropTypes;
import ICampaignYear = Data.Authentication.ICampaignYear;
import IFieldIntegrateResult = Data.FieldManagement.IFieldIntegrateResult;
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IFieldStore extends IStateStore<IField> {
  selectedFields: IField[];
  selectedFieldsRange: [];
  editFields: IGuid[];
  hashMap: {};
  growths: [];
  saving: boolean;
  saved: boolean;
  mergeFailed: boolean;
  mergeSuccess: boolean;
  splitFailed: boolean;
  splitSuccess: boolean;
  fieldPlanBooksResult: boolean;
  campaignYearCrops: { [key: string]: IFieldCrop };
  fieldCropsSaved: boolean | undefined;
}

@Injectable({providedIn: 'root'})
export class FieldStore extends Store<IFieldStore> {
  public onSelectedCampaignYearChanged = new BehaviorSubject<ICampaignYear>(null);
  public onFieldsLoadFinish = new EventEmitter();
  public fieldsToIgnoreInSnap: string[] = [];

  constructor(public backend: ApSignalrService,
              private cropTypeStore: CropTypeStore,
              private cropRotationStore: CropRotationStore,
              private settingsStore: SettingsStore,
              private mapStore: MapStore,
              private cropGroupsStore: CropGroupStore,
              private formStore: FormStore,
              private ndiStore: NdiStore,
              private fieldArchiveStore: FieldArchiveStore,
              private translationService: ApTranslationService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      selectedFields: [],
      selectedFieldsRange: [],
      editFields: [],
      hashMap: {},
      growths: [],
      saving: false,
      saved: false,
      mergeFailed: false,
      mergeSuccess: false,
      splitFailed: false,
      splitSuccess: false,
      fieldPlanBooksResult: null,
      campaignYearCrops: {},
      fieldCropsSaved: undefined
    });
    mapStore.FieldStore$.next(this);
    this.onSelectedCampaignYearChanged.subscribe(year => this.mapStore.Legends.onCampaignYearChange.emit(year));
    backend.registerObservable(FieldLoadSuccess).subscribe(d => {
      const message = d as IResultMessage;
      if (message) {
        const fields = message.Data as IField[];
        if (!Array.isArray(fields)) {
          return;
        }
        super.Mutate(s => s.data, () => fields);
        this.loadFieldCropsForYear(
          (message.Data as IField[]).Convert((f: IField) => f.Id),
          this.onSelectedCampaignYearChanged.getValue()?.Year);
        this.ndiStore.loadNStatistics(this.FieldGeomIdList);
        Throttle({Leading: true, Timeout: 10000}, () => {
          this.mapStore.Layers.FieldsLayer.replaceSource(message.Data, this);
          this.mapStore.Layers.FieldsLayer.ZoomIn(500);
          setTimeout(() => {
            this.onFieldsLoadFinish.emit();
          }, 250);
        });
      }
    });
    backend.registerObservable(FieldCropYearLoadSuccess).subscribe(d => {
      const message = d as IResultMessage;
      if (message) {
        super.Mutate(s => s.campaignYearCrops, () => d.Data);
        super.SetLoadFinishState();
      }
    });
    backend.registerObservable(FieldLoadFromDateRangeSuccess).subscribe(d => {
      super.Mutate(s => s.selectedFieldsRange, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FieldDelete).subscribe(d => {
      if (d && d.Data) {
        const idsToRemove = d.Data as IGuid[];
        super.Mutate(
          s => s.data,
          o => {
            this.mapStore.Layers.RemoveFromFieldLayers(o.FindAll(field => idsToRemove.Contains(field.Id)), this);
            o = o.RemoveAll(field => idsToRemove.Contains(field.Id));
            return o;
          });
      }
    });
    backend.registerObservable(FieldCheckPlanBooksSuccess).subscribe(d => {
      super.Mutate(s => s.fieldPlanBooksResult, () => d.Data);
    });
    backend.registerObservable(FieldUpdate).subscribe(d => {
      const fieldsToAdd = d.Data as IField[];
      fieldsToAdd.forEach((fieldToAdd: IField) => {
        super.Mutate(s => s.data, o => {
          o = o.Replace(i => i.Id === fieldToAdd.Id, fieldToAdd);
          this.mapStore.Layers.RemoveFromFieldLayers([fieldToAdd], this);
          this.mapStore.Layers.AddToFieldLayers([fieldToAdd], this);
          return o;
        });
        super.Mutate(s => s.selectedFields, () => [fieldToAdd]);
      });
      this.cropRotationStore.LoadFieldCropRotation();
      this.cropRotationStore.LoadFieldCropRotationPast();
      this.formStore.closeForm();
      super.SetSaveSuccessState();
      this.mapStore.Layers.FieldsLayer.Visibility = true;
      this.mapStore.Layers.FieldsCropLayer.Visibility = true;
      this.Mutate(s => s.loading, () => false);
    });
    backend.registerObservable(FieldSaveSuccess).subscribe(() => {
      super.SetSaveSuccessState();
    });
    backend.registerObservable(FieldSaveFail).subscribe(() => {
      super.SetSaveFailState();
    });
    backend.registerObservable(FieldMergeFailed).subscribe(() => {
      this.Mutate(s => s.mergeFailed, () => true);
    });
    backend.registerObservable(FieldMergeSuccess).subscribe(() => {
      this.Mutate(s => s.mergeSuccess, () => true);
    });
    backend.registerObservable(FieldSplitFailed).subscribe(() => {
      this.Mutate(s => s.splitFailed, () => true);
    });
    backend.registerObservable(FieldSplitSuccess).subscribe(() => {
      this.Mutate(s => s.splitSuccess, () => true);
    });
    backend.registerObservable(FieldCropsSaveSuccess).subscribe(_ => {
      this.Mutate(s => s.fieldCropsSaved, () => true);
      this.Mutate(s => s.fieldCropsSaved, () => undefined);
    });
    backend.registerObservable(FieldCropsSaveFailed).subscribe(() => {
      this.Mutate(s => s.fieldCropsSaved, () => false);
      this.Mutate(s => s.fieldCropsSaved, () => undefined);
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
    this.Fields$.subscribe((d) => {
      this.mapStore.Layers.FieldsLayer.replaceSource(d, this);
      this.mapStore.Legends.onCropTypesChanged.emit(this.AllCropsInFields.filter(c => c !== null));
    });
  }

  get FieldGeomIdList(): IGuid[] {
    return this.Fields.Convert((f: IField) => {
      const fg = this.getCurrentFieldGeom(f);
      return fg ? fg.Id : null;
    }).FindAll(f => !!f);
  }

  get YearFieldCrops$(): SafeBehaviorSubject<{ [key: string]: IFieldCrop }> {
    return super.Listen(s => s.campaignYearCrops);
  }

  get YearFieldCrops(): { [key: string]: IFieldCrop } {
    return this.YearFieldCrops$.getValue();
  }

  get Fields$(): SafeBehaviorSubject<IField[]> {
    return super.Listen(s => s.data);
  }

  get Fields(): IField[] {
    return this.Fields$.getValue();
  }

  get SelectedFields$(): SafeBehaviorSubject<IField[]> {
    return this.Listen(s => s.selectedFields);
  }

  get SelectedFields(): IField[] {
    return this.SelectedFields$.getValue();
  }

  get EditFields(): IGuid[] {
    return this.EditFields$.getValue();
  }

  get EditFields$(): SafeBehaviorSubject<IGuid[]> {
    return this.Listen(s => s.editFields);
  }

  get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  get Loaded$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loaded);
  }

  get AllCropsInFields(): ICropTypes[] {
    const tmp: ICropTypes[] = [];
    const cy = this.onSelectedCampaignYearChanged.getValue();
    if (cy === null) {
      return [];
    }
    const cyStart = DateTime.FromISOString(cy.DefaultStart.toString(), this.settingsStore.FirstSetting.FarmTime).ToZone('UTC');
    const cyEnd = DateTime.FromISOString(cy.DefaultEnd.toString(), this.settingsStore.FirstSetting.FarmTime).ToZone('UTC');
    for (const f of this.Fields) {
      for (const fc of f.FieldCrops.FindAll(fc2 => {
        const cropStart = DateTime.FromISOString(fc2.ValidFrom.toString(), 'UTC');
        const cropEnd = DateTime.FromISOString(fc2.ValidTo.toString(), 'UTC');
        return !(cropEnd.IsBefore(cyStart) || cropStart.IsAfter(cyEnd));
      })) {
        const cropType = this.cropTypeStore.CropTypes.Find(ct => ct.Id === fc.CroptypeId);
        if (!cropType || tmp.some(x => x.Description === cropType.Description)) {
          continue;
        }
        tmp.AddIfNotExists(cropType);
      }
      if (!f.FieldCrops || f.FieldCrops?.length === 0 && !tmp.Exists(_ => _?.Id === -1)) {
        tmp.AddIfNotExists({Id: -1, Description: '-', Color: this.cropGroupsStore.getNoCropColor()} as ICropTypes);
      }
    }
    return tmp;
  }

  /**
   * load fields from Backend to the Store
   */
  public loadFields(campaignYearStart: Date | string): void {
    super.SetLoadState();
    super.Mutate(d => d.data, () => []);
    const year = isDate(campaignYearStart) ? (campaignYearStart as Date).toISOString() : campaignYearStart;
    this.DispatchBackend(new FieldLoad([
      {Name: 'validDate', Type: NetTypes.DATETIME, Value: year},
    ]));
  }

  public async integrateFeature(fieldPolygon: IGeoJsonPolygon, epsgCode: string, fieldGeomId: string): Promise<IFieldIntegrateResult> {
    const tmp = epsgCode.Split(':');
    if (tmp.length < 2) {
      throw new Error(`invalid epsg Code ${epsgCode}`);
    }
    const srId = parseInt(tmp[1], 10);
    if (isNaN(srId)) {
      throw new Error(`invalid epsg Code ${epsgCode}`);
    }
    return new Promise((resolve, reject) => {
      const ignoreIds: string[] = [fieldGeomId].concat(this.fieldsToIgnoreInSnap);
      super.DispatchBackend(new FieldIntegrate([
        {Name: 'fieldGeom', Type: ApCustomTypes.Data_Geometry_GeoJsonPolygon, Value: fieldPolygon},
        {Name: 'srId', Type: NetTypes.INT, Value: srId},
        {Name: 'skippingFieldGeomId', Type: NetTypes.GUID + '[]', Value: ignoreIds},
      ])).watchStream({
        action: FieldIntegrateSuccess,
        todo: payload => {
          if (payload.Error) {
            reject(payload.Error);
            return;
          }
          resolve(payload.Data);
        },
      });
    });
  }

  public loadFieldsFromRange(from: Date, to: Date): void {
    super.SetLoadState();
    super.Mutate(d => d.selectedFieldsRange, () => []);
    const dateFrom = isDate(from) ? from.toISOString() : from;
    const dateTo = isDate(to) ? to.toISOString() : to;
    this.DispatchBackend(new FieldLoadFromDateRange([
      {Name: 'validFrom', Type: NetTypes.DATETIME, Value: dateFrom},
      {Name: 'validTo', Type: NetTypes.DATETIME, Value: dateTo},
    ]));
  }

  /**
   * select Fields in the Grid
   */
  public changeSelectedField(fieldIds: string[]): void {
    const fields = [];
    fieldIds.forEach(id => {
      const found = this.Fields.Find(f => f.Id === id);
      if (found) {
        fields.Add(found);
      }
    });
    super.Mutate(s => s.selectedFields, () => fields);
  }

  public zoomFarmFields(): void {
    // in cases of initializations the zoomFarmFields might get
    // called even when the fieldsLayer is not yet filled (no fields)
    // in case the fieldsLayers does not have any feature it will lead to
    // an extent with geometry=Infinity => avoiding this with a return
    if (this.mapStore?.Layers?.FieldsLayer?.Features?.length <= 0) {
      return;
    }
    this.mapStore.Layers.FieldsLayer.ZoomIn(500);
  }

  public deselectField(fieldId: string): void {
    super.Mutate(s => s.selectedFields, (fields) => fields.filter((f) => f.Id !== fieldId));
  }

  /**
   *  saved crop to the database
   */
  public saveFieldCrops(fieldCrops: IFieldCrop[]): void {
    this.Mutate(s => s.fieldCropsSaved, () => undefined);
    this.DispatchBackend(new FieldCropsSave([
      {Name: 'fieldCrops', Type: ApCustomTypes.Data_FieldManagement_FieldCrop + '[]', Value: fieldCrops}
    ]));
  }

  public editFields(fieldGeomIds: IGuid[]): void {
    this.Mutate(s => s.editFields, () => fieldGeomIds);
  }

  /**
   *  delete crop to the database
   */
  public deleteFieldCrop(fieldCrop: IFieldCrop): void {
    this.DispatchBackend(new FieldCropDelete([
      {Name: 'fieldCrop', Type: ApCustomTypes.Data_FieldManagement_FieldCrop, Value: fieldCrop}
    ]));
  }

  /**
   *  saved field to the database
   */
  public deleteFieldGeom(id: IGuid): void {
    super.SetSaveState();
    this.DispatchBackend(new FieldGeomDelete([
      {Name: 'id', Type: NetTypes.GUID, Value: id},
    ]));
  }

  /**
   *  saved field to the database
   */
  public saveField(field: IField | IField[], start: Date, end: Date, fieldParameter?: ApParameterComponent): void {
    super.SetSaveState();
    field = Array.isArray(field) ? field : [field];
    // set real Crops
    field = field.Convert((f: IField) => {
      const originalField = ObjectFactory.Copy(this.Fields.Find(tmpF => tmpF.Id === f.Id));
      let fcIdx = originalField.FieldCrops.FindIndex(tmpFc => {
        const cyInterval = new DateRange(
          DateTime.FromISOString(start.toString(), 'UTC'),
          DateTime.FromISOString(end.toString(), 'UTC'));
        const fcInterval = new DateRange(
          DateTime.FromISOString(tmpFc.ValidFrom.toString(), 'UTC'),
          DateTime.FromISOString(tmpFc.ValidTo.toString(), 'UTC'),
        );
        return fcInterval.Overlaps(cyInterval);
      });
      const edited = f.FieldCrops.FirstOrDefault();
      if (edited) {
        if (fcIdx < 0) {
          originalField.FieldCrops.Add({
            Id: Guid.Empty.ToString(),
            FieldId: f.Id,
            CroptypeId: 0,
            ExpectedYield: 0,
            StrawHarvested: false,
            CroptypeSecondId: 0,
            ExpectedYieldSecond: 0,
            ValidFrom: start,
            ValidTo: end,
            ChangedAt: null,
            ChangedBy: 0,
            CreatedAt: null,
            CreatedBy: 0,
            DeletedAt: null,
            DeletedBy: 0,
            FarmId: f.FarmId,
            Version: 0,
            Variety: Guid.Empty.ToString(),
            ScenarioId: Guid.Empty.ToString(),
            Intercrop: 0,
            Note: '',
            HarvestAttachment: null,
            MainSecondCropType: 0,
            EppoCode: '',
            AdminArea: 0,
            Color: '',
            AreaHa: 0,
            Cropkey: 0,
            EcValue: null,
            CampaignYear: 0
          });
          fcIdx = 0;
        }
        originalField.FieldCrops[fcIdx].CroptypeId = edited.CroptypeId;
        originalField.FieldCrops[fcIdx].Cropkey = edited.Cropkey;
        originalField.FieldCrops[fcIdx].ExpectedYield = edited.ExpectedYield;
        originalField.FieldCrops[fcIdx].StrawHarvested = edited.StrawHarvested;
        originalField.FieldCrops[fcIdx].CroptypeSecondId = edited.CroptypeSecondId;
        originalField.FieldCrops[fcIdx].MainSecondCropType = edited.MainSecondCropType;
        originalField.FieldCrops[fcIdx].ExpectedYieldSecond = edited.ExpectedYieldSecond;
        originalField.FieldCrops[fcIdx].Note = edited.Note;
        f.FieldCrops = originalField.FieldCrops;
      }
      return f;
    });

    super.SetSaveState();
    this.DispatchBackend(new FieldSave([
      {Name: 'fields', Type: ApCustomTypes.Data_FieldManagement_Field + '[]', Value: field}
    ])).watchStream({
      action: ParameterValuesSaveRequest,
      todo: () => {
        // After successful saving fields (and their jobs etc.) the parameters are saved, too.
        fieldParameter?.Save();
      },
    });
  }

  public createField(field: IField, fieldParameter?: ApParameterComponent): void {
    this.DispatchBackend(new FieldCreate([
      {Name: 'field', Type: ApCustomTypes.Data_FieldManagement_Field, Value: field},
      {Name: 'fieldCrops', Type: ApCustomTypes.Data_FieldManagement_FieldCrop + '[]', Value: null},
    ])).watchStream({
      action: FieldUpdate,
      todo: () => {
        if (!fieldParameter) {
          return;
        }
        fieldParameter.Save();
      },
    });
  }

  /**
   *  delete field to the database
   */
  public deleteFields(fieldIds: any): void {
    this.DispatchBackend(new FieldDelete([
      {Name: 'fieldIds', Type: NetTypes.GUID + '[]', Value: fieldIds},
      {Name: 'useCampaignYearForPlannings', Type: NetTypes.BOOL, Value: true}
    ]));
  }

  public mergeFields(newField: IField, fieldIds: any, takeCropRotationFrom: IGuid): void {
    this.Mutate(s => s.mergeFailed, () => false);
    this.Mutate(s => s.mergeSuccess, () => false);
    this.Mutate(s => s.saved, () => false);

    this.DispatchBackend(new FieldMerge([
      {Name: 'field', Type: ApCustomTypes.Data_FieldManagement_Field, Value: newField},
      {Name: 'fieldIds', Type: NetTypes.GUID + '[]', Value: fieldIds},
      {Name: 'takeCropRotationFrom', Type: NetTypes.GUID + '?', Value: takeCropRotationFrom}
    ]));
  }

  public splitFields(oldFieldId: IGuid, newFields: any, transferCrops: any): void {
    this.Mutate(s => s.saved, () => false);
    this.Mutate(s => s.splitFailed, () => false);
    this.Mutate(s => s.splitSuccess, () => false);

    this.DispatchBackend(new FieldSplit([
      {Name: 'oldFieldId', Type: NetTypes.GUID, Value: oldFieldId},
      {Name: 'newFields', Type: ApCustomTypes.Data_FieldManagement_Field + '[]', Value: newFields},
      {Name: 'transferCrops', Type: NetTypes.BOOL + '[]', Value: transferCrops},
    ]));
  }

  public checkFieldsPlanBook(fieldIds: any): void {
    this.DispatchBackend(new FieldCheckPlanBooks([
      {Name: 'fieldIds', Type: NetTypes.GUID + '[]', Value: fieldIds},
    ]));
  }

  /**
   *  get from the current state the field
   */
  public getFieldById(fieldId: string | IGuid): IField {
    return this.Fields.Find(e => e.Id === fieldId);
  }

  public getFieldsById(fieldsId: IGuid[]): IField[] {
    return this.Fields.filter(x => fieldsId.some(id => id === x.Id));
  }

  public getFieldByFieldGeomId(fieldGeomId: string | IGuid): IField {
    return this.Fields.Find(e => Array.isArray(e.FieldGeoms) && e.FieldGeoms.Any(geom => geom.Id === fieldGeomId));
  }

  /**
   * Gets the field's full name or a nullValue representation
   * if the field could not be found
   * @param fieldId the field's id
   * @param nullValue nullValue substitute. Default: '-'
   */
  public getFieldNameById(fieldId: string | IGuid, nullValue = '-'): string {
    let field = this.getFieldById(fieldId) as IFieldBase;
    if (!field) {
      field = this.getFieldByFieldGeomId(fieldId);
    }
    if (!field) {
      field = this.fieldArchiveStore.getFieldArchiveById(fieldId);
    }
    return this.getFieldName(field, nullValue);
  }

  /**
   * Gets the field's full name or a nullValue representation
   * if the field could not be found
   * @param field the field object
   * @param nullValue nullValue substitute. Default: '-'
   */
  public getFieldName(field: {
    FieldName: string,
    FieldNumber: number,
    FieldSubnumber: number
  }, nullValue: string = '-'): string {
    if (!field) {
      return this.translationService.translate(nullValue);
    }
    const token = [];
    if (!!field.FieldNumber && !!field.FieldSubnumber) {
      token.push(`${field.FieldNumber}-${field.FieldSubnumber}`);
    } else if (!!field.FieldNumber) {
      token.push(field.FieldNumber);
    } else if (!!field.FieldSubnumber) {
      token.push(`-${field.FieldSubnumber}`);
    }
    if (!!field.FieldName) {
      token.push(field.FieldName);
    }
    return token.Join(' ');
  }

  /**
   * get a FieldGeom By Id
   */
  public getFieldGeomById(fieldGeomId: string | IGuid): IFieldGeom {
    for (const field of super.Listen(s => s.data).getValue()) {
      if (!field.FieldGeoms || field.FieldGeoms.length < 1) {
        continue;
      }
      for (const geom of field.FieldGeoms) {
        if (geom.Id === fieldGeomId) {
          return geom;
        }
      }
    }
    return null;
  }

  public getSelectedFields(): IField[] {
    const fields = super.Listen(s => s.selectedFields).getValue();
    if (fields === null || fields === undefined) {
      return [];
    }
    return fields;
  }

  public getSelectedFieldIds(): string[] {
    const fields = super.Listen(s => s.selectedFields).getValue();
    if (fields === null || fields === undefined) {
      return [];
    }
    const fieldIds = [];
    fields.forEach((f: IField) => {
      fieldIds.Add(f.Id.toString());
    });
    return fieldIds;
  }

  public getFieldNumberAndSubNumber(field: IFieldBase): string {
    if (!field?.FieldNumber && !field?.FieldSubnumber) {
      return '';
    }
    if (field.FieldNumber !== null) {
      if (field.FieldSubnumber !== null) {
        return `${field.FieldNumber} - ${field.FieldSubnumber}`;
      } else {
        return `${field.FieldNumber}`;
      }
    } else {
      if (field.FieldSubnumber !== null) {
        return `  - ${field.FieldSubnumber}`;
      } else {
        return '';
      }
    }
  }

  /**
   * get the Field Geom from the Field in a Campaign Year
   */
  public getFieldGeom(field: IField | string, year: ICampaignYear): IFieldGeom {
    if (typeof field === 'string') {
      field = this.getFieldById(field);
    }
    return field?.FieldGeoms?.FirstOrDefault((g) => {
      return g.ValidFrom <= year.DefaultStart &&
        g.ValidTo >= year.DefaultEnd &&
        // new Date('1970-01-01').getTime() === 0
        (g.DeletedAt?.valueOf() ?? new Date().getTime()) >= new Date().getTime();
    }) ?? field?.DefaultGeom;
  }

  /**
   * get the Field Geom from the Field in the current Campaign Year
   */
  public getCurrentFieldGeom(field: IField | string): IFieldGeom {
    return this.getFieldGeom(field, this.onSelectedCampaignYearChanged.getValue());
  }

  public clearFieldPlanBooksResult(): void {
    this.Mutate(s => s.fieldPlanBooksResult, () => null);
  }

  public loadFieldCropsForYear(fieldIds: IGuid[], year: number): void {
    this.Mutate(s => s.campaignYearCrops, () => ({}));
    this.backend.send(new FieldCropYearLoad([
      {Type: NetTypes.GUID + '[]', Name: 'fieldIds', Value: fieldIds},
      {Type: NetTypes.INT, Name: 'year', Value: year},
    ]));
  }

  public UpdateSource(operation: DatabaseNotifyOperation, item: any, model: string): void {
    if (model === HibernateModelNames.FIELD_CROPS) {
      if (!item?.Id) {
        return;
      }
      this._updateFieldCropsData([item as IFieldCrop], operation);
    } else {
      super.UpdateSource(operation, item, model);
    }
  }

  private _updateFieldCropsData(fieldCrops: IFieldCrop[], operation: DatabaseNotifyOperation): void {
    if (!fieldCrops) {
      return;
    }
    this.Mutate(s => s.data, fields => {
      const copiedFields = ObjectFactory.Copy(fields);
      copiedFields.forEach(field => {
        const fieldCropsForField = fieldCrops.filter(x => x.FieldId === field.Id);
        if (!fieldCropsForField || fieldCropsForField.length <= 0) {
          return;
        }
        if (!field.FieldCrops) {
          field.FieldCrops = [];
        }
        switch (operation) {
          case DatabaseNotifyOperation.Insert:
            fieldCropsForField.forEach((fieldCrop: IFieldCrop) => {
              field.FieldCrops.push(fieldCrop);
            });
            break;
          case DatabaseNotifyOperation.Update:
            field.FieldCrops = field.FieldCrops.map(filedCrop => {
              const fieldCropToReplace = fieldCropsForField.find(x => x.Id === filedCrop.Id);
              return fieldCropToReplace ?? filedCrop;
            });
            break;
          case DatabaseNotifyOperation.Delete:
            fieldCrops.forEach(fieldCrop => {
              field.FieldCrops = field.FieldCrops.filter(x => x.Id !== fieldCrop.Id);
            });
            break;
        }
      });
      return copiedFields;
    });
  }
}

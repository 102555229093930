<ng-container *ngIf="data?.length <= 0">
  <div class="empty-message">
    <p>{{emptyMessage | translate}}</p>
  </div>
</ng-container>
<ng-container *ngIf="data?.length > 0">
  <div class="ap-n-fertilization-n-need-stats">
    <div class="ap-n-fertilization-n-need-stats-table">
      <h3>{{title | translate}}*</h3>
      <div>
        <ap-dyngrids class="ap-statistic-grid ap-n-fertilization-n-need-stats-grid ap-statistic-grid-bold-footer-row"
                     [canCreate]="false"
                     [canSearch]="false"
                     [checkboxDisabled]="true"
                     [pageable]="false"
                     [scrollable]="'none'"
                     [columns]="sumValuesStatsColumns"
                     [items]="sumValuesStatsItems$ | async"
                     [groupable]="false"
                     [reorderable]="false"
                     [selectable]="gridSelectableSettings">
        </ap-dyngrids>
      </div>
    </div>
    <div class="ap-n-fertilization-n-need-stat-bars">
      <ap-generic-bar-chart [style.width]="'100%'"
                            [style.height]="'500px'"
                            [transitions]="false"
                            [zoomable]="false"
                            [pannable]="false"
                            [data]="barsData"
      ></ap-generic-bar-chart>
    </div>
  </div>
</ng-container>

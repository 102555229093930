import {Component, Input}                from '@angular/core';
import {ApDynGridClassColorColumnConfig} from '../config/ap-dyn-grid-class-color-column-config';
import {CompositeFilterDescriptor}       from '@progress/kendo-data-query';
import {FilterService}                   from '@progress/kendo-angular-grid';

@Component({
  selector: 'ap-class-color-column-filter',
  template: `
    <ap-class-color-filter [filterService]="filterService"
                           [currentFilter]="currentFilter"
                           [key]="config.key"
                           [items]="items">
    </ap-class-color-filter>
  `
})
export class ApClassColorColumnFilterComponent {
  @Input() config: ApDynGridClassColorColumnConfig;
  @Input() currentFilter: CompositeFilterDescriptor;
  @Input() filterService: FilterService;
  @Input() items: any[];
}

@Component({
  selector: 'ap-class-color-column-cell',
  template: `
    <span [class]="config.cssClass | centerCell">
      <span [ngStyle]="dataItem | getProperty:config.field"
            [style.height]="'29px'"
            [style.width]="'100%'"
            style="display: flex; align-items: center"
            [perpetual]="true"
            [aptooltip]="(dataItem | getProperty:config.tooltip) | execPipes:config:'tooltipPipes' | async">
        <ap-responsive-text
          [key]="(dataItem | getProperty:config.key | translate) | execPipes:config:'pipes' | async"></ap-responsive-text>
      </span>
    </span>
  `,
  styles: []
})
export class ApClassColorColumnCellComponent {
  @Input() config: ApDynGridClassColorColumnConfig;
  @Input() dataItem: any;
  @Input() inGroup: boolean;
}

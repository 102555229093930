import {animate, animateChild, query, state, style, transition, trigger} from '@angular/animations';
import {SidebarMode}                                                     from '../ap-interface';

const DURATION = 200;
const SIDEBAR_TRANSLATE_TEXT_IN = style({
  transform: 'translateX(0px)',
  opacity: '1',
});
const SIDEBAR_TRANSLATE_TEXT_OUT = style({
  transform: 'translateX(-250px)',
  opacity: '0',
});

const SIDEBAR_TOGGLE_ICON_ROTATE_LEFT = style({
  transform: 'rotateZ(0deg)'
});
const SIDEBAR_TOGGLE_ICON_ROTATE_RIGHT = style({
  transform: 'rotateZ(180deg)'
});

const SIDEBAR_WIDTH_FULL = style({
  'flex-basis': '233px',
  width: '233px',
});
const SIDEBAR_WIDTH_HALF = style({
  'flex-basis': '38px',
  width: '38px',
});
const SIDEBAR_WIDTH_NONE = style({
  'flex-basis': '0px',
  width: '0px',
});

export const SIDEBAR_TRANSLATE = trigger('sidebarTranslate', [
    state(SidebarMode.OPEN, SIDEBAR_WIDTH_FULL),
    state(SidebarMode.HALF, SIDEBAR_WIDTH_HALF),
    state(SidebarMode.CLOSE, SIDEBAR_WIDTH_NONE),
    state(SidebarMode.MOBILE, SIDEBAR_WIDTH_NONE),
    transition(`${SidebarMode.CLOSE} => ${SidebarMode.OPEN}`, animate(DURATION)),
    transition(`${SidebarMode.MOBILE} => ${SidebarMode.OPEN}`, animate(DURATION)),
    transition(`${SidebarMode.OPEN} => ${SidebarMode.HALF}`, animate(DURATION)),
    transition(`${SidebarMode.HALF} => ${SidebarMode.OPEN}`, animate(DURATION)),
    transition(`${SidebarMode.HALF} => ${SidebarMode.CLOSE}`, animate(DURATION)),
    transition(`${SidebarMode.HALF} => ${SidebarMode.MOBILE}`, animate(DURATION)),
  ])
;

export const SIDEBAR_TRANSLATE_TEXT = trigger('translateText', [
  state(SidebarMode.OPEN, SIDEBAR_TRANSLATE_TEXT_IN),
  state(SidebarMode.HALF, SIDEBAR_TRANSLATE_TEXT_OUT),
  state(SidebarMode.CLOSE, SIDEBAR_TRANSLATE_TEXT_OUT),
  state(SidebarMode.MOBILE, SIDEBAR_TRANSLATE_TEXT_OUT),
  transition(`${SidebarMode.CLOSE} => ${SidebarMode.OPEN}`, animate(DURATION)),
  transition(`${SidebarMode.MOBILE} => ${SidebarMode.OPEN}`, animate(DURATION)),
  transition(`${SidebarMode.OPEN} => ${SidebarMode.HALF}`, animate(DURATION)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.OPEN}`, animate(DURATION)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.CLOSE}`, animate(DURATION)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.MOBILE}`, animate(DURATION)),
]);

export const SIDEBAR_ROTATE_ICON = trigger('rotateIcon', [
  state(SidebarMode.OPEN, SIDEBAR_TOGGLE_ICON_ROTATE_LEFT),
  state(SidebarMode.HALF, SIDEBAR_TOGGLE_ICON_ROTATE_RIGHT),
  state(SidebarMode.CLOSE, SIDEBAR_TOGGLE_ICON_ROTATE_RIGHT),
  state(SidebarMode.MOBILE, SIDEBAR_TOGGLE_ICON_ROTATE_RIGHT),
  transition(`${SidebarMode.CLOSE} => ${SidebarMode.OPEN}`, animate(DURATION)),
  transition(`${SidebarMode.MOBILE} => ${SidebarMode.OPEN}`, animate(DURATION)),
  transition(`${SidebarMode.OPEN} => ${SidebarMode.HALF}`, animate(DURATION)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.OPEN}`, animate(DURATION)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.CLOSE}`, animate(DURATION)),
  transition(`${SidebarMode.HALF} => ${SidebarMode.MOBILE}`, animate(DURATION)),
]);

export const ANIMATE_CHILD = trigger('animateChild', [
  transition('* => *', [query('@*', animateChild(), {optional: true})])
]);

import {Component}            from '@angular/core';
import {map}                  from 'rxjs/operators';
import {JobsStore}            from '../../stores/administration/jobs.store';
import {Observable, of}       from 'rxjs';
import {ApTranslationService} from '../../ap-utils/service/ap-translation.service';
import JobStatus = Data.Job.JobStatus;


export interface IJobListItem {
  Count: number;
  Description: string;
  Status: number;
}

@Component({
  selector: 'ap-farm-job-list',
  template: `
    <div class="ap-toolbar-popup-header">{{ 'Global__TaskList' | translate }}</div>
    <div
      [style.height]="calcHeight(jobListItems$ | async) + 'px'"
      [style.position]="'relative'"
      [style.width]="'100%'">
      <div *ngIf="(jobListItems$ | async)?.length < 1" class="ap-no-items-available-text">
        {{ 'no_open_tasks' | translate }}
      </div>
      <div class="ap-tasks-list">
        <div class="ap-task-list-item" *ngFor="let jobItem of (jobListItems$ | async)">
          <div class="ap-task-list-item-header">
            <div class="farm-job-state-view">
              <kendo-icon *ngIf="jobItem.Status == 0" [themeColor]="'warning'" [name]="'clock'"></kendo-icon>
              <kendo-loader *ngIf="jobItem.Status == 1" size="small" [type]="'infinite-spinner'"
                            [themeColor]="'success'"></kendo-loader>
              <kendo-icon *ngIf="jobItem.Status > 1" [themeColor]="'success'" [name]="'check'"></kendo-icon>
              <kendo-icon *ngIf="jobItem.Status < 0" [themeColor]="'error'" [name]="'close'"></kendo-icon>
            </div>
            <div class="ap-task-list-item-label">
              {{ jobItem.Count }} {{ jobItem.Description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ApFarmJobListComponent {
  public jobListItems$: Observable<IJobListItem[]> = of([]);
  private PopupLineHeight = 53;
  private MaxPopupHeight = 530;

  constructor(private jobStore: JobsStore,
              private translationService: ApTranslationService) {
    this.jobListItems$ = this.jobStore.Listen(s => s.farmJobs).pipe(
      map((jobs) => {
        const jobListItems = new Array<IJobListItem>();
        const countPending = jobs.FindAll(j => j.Status === JobStatus.Pending)?.length;
        const countRunning = jobs.FindAll(j => j.Status === JobStatus.Running)?.length;
        if (countPending > 0) {
          jobListItems.push(
          {
            Count: countPending,
            Status: JobStatus.Pending,
              Description: this.translationService.translate('Pending_Jobs')
          });
        }
        if (countRunning > 0) {
          jobListItems.push(
            {
              Count: countRunning,
              Status: JobStatus.Running,
              Description:
                this.translationService.translate('Running_Jobs')
            });
        }
        return jobListItems;
    }));
  }

  public calcHeight(jobs: IJobListItem[] | null): number {
    if (!jobs || jobs.length === 0) {
      return 66; // dummy row
    }
    return Math.min(jobs.length * this.PopupLineHeight, this.MaxPopupHeight);
  }
}

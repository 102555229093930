import {Pipe, PipeTransform} from '@angular/core';
import {get}                 from 'lodash';
import {Trace}               from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'getProperty'
})
export class GetPropertyPipe implements PipeTransform {

  @Trace()
  transform(dataItem: any, ...field: string[]): any {
    return get(dataItem, field[0]);
  }

}

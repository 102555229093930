import {IAction, IApInvokerArguments} from '../../interfaces';

enum MonitoringFieldActions {
  MONITORING_FIELD_LOAD = '[monitoring-field] load',
  MONITORING_FIELD_LOAD_SUCCESS = '[monitoring-field] load success',
  MONITORING_FIELD_LOAD_FAIL = '[monitoring-field] load fail',
}

export class MonitoringFieldLoad implements IAction {
  readonly type = MonitoringFieldActions.MONITORING_FIELD_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringFieldLoadSuccess implements IAction {
  readonly type = MonitoringFieldActions.MONITORING_FIELD_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringFieldLoadFail implements IAction {
  readonly type = MonitoringFieldActions.MONITORING_FIELD_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

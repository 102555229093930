import {Injectable}                                        from '@angular/core';
import {IChartSet}                                         from '../../../stores/statistic/statistic.store';
import {IBarLineAreaChartData, IBarLineAreaChartDataValue} from '../../../ap-interface';
import {ILegendValue}                                      from '../../../stores/map/map.legend.store';
import {ApTranslationService}                              from '../../../ap-utils/service/ap-translation.service';
import {GetRoundNumericService}                            from '../../../ap-utils/service/get-round-numeric.service';
import {SettingsStore}                                     from '../../../stores/base-data/settings.store';

export type DiagramTableData = {
  AxesTitleX: string;
  AxesTitleY: string;
  DiagramTitle: string;
  RoundAreaNoteToFarmSettings: boolean;
  TotalArea: number;
  TotalCells: number;
  Categories: ILegendValue[];
  Statistics: DiagramTableStatisticObject[];
};

export type DiagramTableStatisticObject = {
  Histogram: { Key: number, Value: number }[]
};

@Injectable({providedIn: 'root'})
export class DiagramForHistogramStatisticsService {
  constructor(private settingsStore: SettingsStore,
              private translationService: ApTranslationService,
              private roundNumericService: GetRoundNumericService) {
  }

  public generateDiagram(diagramTableData: DiagramTableData): IChartSet<IBarLineAreaChartData>[] {
    if (!diagramTableData) {
      return [];
    }
    const diagram: IChartSet<IBarLineAreaChartData> = {
      axes: {
        titleX: diagramTableData?.AxesTitleX,
        titleY: diagramTableData?.AxesTitleY
      },
      data: {
        title: diagramTableData?.DiagramTitle,
        categories: [],
        data: []
      },
      noteField: 'note'
    } as IChartSet<IBarLineAreaChartData>;

    if (!diagramTableData.Categories || diagramTableData.Categories.length <= 0
      || diagramTableData.TotalCells <= 0 || diagramTableData.TotalArea <= 0) {
      return [diagram];
    }

    for (let i = 0; i < diagramTableData.Categories.length; i++) {
      const currentLegendValue = diagramTableData.Categories[i];
      const previousLegendValue = diagramTableData.Categories[i - 1];
      let classCells = 0;

      if (i === 0) {
        // first class => value is smaller or equals the class value
        classCells = diagramTableData.Statistics.reduce((sumTotalPercentageForClass, item) =>
          this._countHistogramValueCells(sumTotalPercentageForClass, item, (histKey) => {
            return histKey <= currentLegendValue.value;
          }), 0);

      } else if (i === diagramTableData.Categories.length - 1) {
        // last class => value is bigger or equals the class value
        // OR histKey bigger than previous value (for cases when histKey in a middle, higher than previous but lower than current)
        classCells = diagramTableData.Statistics.reduce((sumTotalPercentageForClass, item) =>
          this._countHistogramValueCells(sumTotalPercentageForClass, item, (histKey) => {
            return histKey >= currentLegendValue.value || histKey > previousLegendValue.value;
          }), 0);

      } else {
        // regular class => value bigger than previous class value and same or smaller than current class value
        classCells = diagramTableData.Statistics.reduce((sumTotalPercentageForClass, item) =>
          this._countHistogramValueCells(sumTotalPercentageForClass, item, (histKey) => {
            return histKey > previousLegendValue.value && histKey <= currentLegendValue.value;
          }), 0);
      }
      diagram.data.data.Add({
        category: currentLegendValue.title,
        value: (classCells / diagramTableData.TotalCells) * diagramTableData.TotalArea,
        color: currentLegendValue.color,
        note: ''
      } as IBarLineAreaChartDataValue);
      diagram.data.categories.AddIfNotExists(currentLegendValue.title);
    }

    const rounding = diagramTableData.RoundAreaNoteToFarmSettings
      ? this.settingsStore.FirstSetting.DigitsAfterDecimalPoint
      : 0;

    // calculate chart note texts
    // this needs to be done as part of the post-processing
    // when rounding the note's value it might lead to inconsistent results
    // e.g. instead of 100% the sum of all % values might be 101% or 99%
    // Here we adjust the values, so it matches the expectations
    this._calculateNoteTexts(diagram, diagramTableData.TotalArea, rounding);

    // calculate min/max of charts
    diagram.axes.minX = 0;
    diagram.axes.maxX = Math.max(...diagram.data.data.map(_ => _.value)) * 1.2;

    return [diagram];
  }

  /**
   * Counts the histogram cells for each n-index classification
   * This is need to calculate percentage and area for each n-index
   * @param sumTotalPercentageForClass
   * @param item an array item
   * @param comparer comparer for the n-index class. There are different comparisons if it's the first, the last or any other n-index class
   * @private
   */
  private _countHistogramValueCells(sumTotalPercentageForClass: number, item: DiagramTableStatisticObject, comparer: (histValue: number) => boolean): number {
    if (!item?.Histogram || item.Histogram.length <= 0) {
      return sumTotalPercentageForClass;
    }
    let sumItemPercentageForClass = 0;
    for (const histValue of item.Histogram) {
      // if histogram value matches the current n-index class => add it
      if (comparer(histValue.Key)) {
        sumItemPercentageForClass += histValue.Value;
      }
    }
    return sumTotalPercentageForClass + sumItemPercentageForClass;
  }

  /**
   * Generates the bar chart note shown on top of each bar
   * @param chartData the generated chart set
   * @param selectedArea the selected area
   * @param areaRounding define how much numbers will be after . in are property.
   * @private
   */
  private _calculateNoteTexts(chartData: IChartSet<IBarLineAreaChartData>, selectedArea: number, areaRounding: number = 0): void {
    if (chartData?.data?.data?.length <= 0) {
      return;
    }
    let percentSum = 0;
    let areaSum = 0;
    let biggestValueIdx = 0;
    let biggestArea = 0;
    const showExtendedArea = areaRounding > 0;
    // we are going to adjust the element with the biggest value
    for (let i = 0; i < chartData.data.data.length; i++) {
      const area = this.roundNumericService.roundAsNumber(chartData.data.data[i].value, areaRounding);
      const percent = this.roundNumericService.roundAsNumber(area / selectedArea * 100, 0);
      if (area > biggestArea) {
        biggestArea = area;
        biggestValueIdx = i;
      }
      percentSum += percent;
      areaSum += area;
      chartData.data.data[i].note = this._createNote(percent, area, showExtendedArea);
    }
    const selectedAreaRounding = this.roundNumericService.roundAsNumber(selectedArea, areaRounding);
    // Adjust the biggest value that total sum matches 100% and the selected area
    chartData.data.data[biggestValueIdx].note = this._createNote(
      this.roundNumericService.roundAsNumber(biggestArea / selectedArea * 100, 0) + 100 - percentSum,
      this.roundNumericService.roundAsNumber(biggestArea + selectedAreaRounding - areaSum, areaRounding),
      showExtendedArea);
  }

  /**
   * Creates the actual note text containing percentage and area values
   * @param percentage the percentage value of the bar
   * @param area the area value of the bar
   * @param showExtendedArea show full values of area, without '<' sign
   * @private
   */
  private _createNote(percentage: number, area: number, showExtendedArea: boolean): string {
    let areaNote: string;
    if (showExtendedArea) {
      areaNote = `${area} ${this.translationService.translate('Global__Area')}\n`;
    } else  {
      areaNote = `${(area < 1 ? '<1' : area)} ${this.translationService.translate('Global__Area')}\n`;
    }
    return `${areaNote} (${(percentage < 1 ? '<1' : percentage)} %)`;
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getSoilSampleClassName'
})
export class GetSoilSampleClassNamePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'A-';
      case 2:
        return 'A+';
      case 3:
        return 'B-';
      case 4:
        return 'B+';
      case 5:
        return 'C-';
      case 6:
        return 'C+';
      case 7:
        return 'D-';
      case 8:
        return 'D+';
    }
    if (value >= 9) {
      return 'E';
    }
    return '-';
  }
}

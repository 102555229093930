import {IAction, IApInvokerArguments} from '../../interfaces';
import IUserSettings = Data.Authentication.IUserSettings;

export enum ApUserSettingsActions {
  LOAD_USER_SETTINGS = '[usersettings] load',
  LOAD_USER_SETTINGS_SUCCESS = '[usersettings] load success',
}

export class LoadUserSettings implements IAction {
  readonly type = ApUserSettingsActions.LOAD_USER_SETTINGS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadUserSettingsSuccess implements IAction {
  readonly type = ApUserSettingsActions.LOAD_USER_SETTINGS_SUCCESS;

  constructor(public payload: IUserSettings) {
  }
}

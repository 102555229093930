import {concat, differenceBy, drop, findIndex, isArray, isEqual, isObject, PartialDeep, transform} from 'lodash';

/**
 * a Extension to use lodash functions easier and strongly typed
 */
export class ApLodash {
  /**
   * insert or replace Items in a List
   */
  public static upSert(source: any[], update: any[]): void {
    for (const u of update) {
      const idx = findIndex(source, (e) => e['Id'] === u['Id']);
      if (idx > -1) {
        source[idx] = u;
      } else {
        source.push(u);
      }
    }
  }

  /**
   * replace a Item in a Object Collection
   */
  public static replaceItem<T>(
    source: T[],
    item: T | T[],
    match: ((value: T) => {} | null | undefined) | string | [string, any] | PartialDeep<T>): T[] {
    if (!item) {
      return source;
    }
    if (!(item instanceof Array)) {
      item = [item];
    }
    return concat(
      differenceBy(source, item, match),
      item
    );
  }

  /**
   * get the Difference of two Objects
   */
  public static difference<T>(ref: T, dirty: T): T {
    // @ts-ignore

    return transform(ref, (result, value, key) => {
      if (!isEqual(value, dirty[key])) {
        result[key] = (isObject(value) && isObject(dirty[key])) ? ApLodash.difference(value, dirty[key]) : dirty[key];
      }
    });
  }

  public static dropItemFromArray<T>(source: T[], item: T, match: (e: T) => boolean): T[] {
    return drop(source, findIndex(source, match));
  }

  /**
   * checks an Array to be an valid Kendo Grid Datasource
   */
  public static isValidGridDataSource(source: any[]): boolean {
    return isArray(source) && source.length > 0;
  }
}

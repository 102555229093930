import {Injectable}                from '@angular/core';
import {
  INutrientPlaningSummaryData,
  INutrientPlanningEntryStepsConfig,
  PlanningEntryComponentData
}                                  from './nutrient-planning-types';
import { ApDynComponentComponent } from 'src/app/ap-dyncomponent/ap-dyncomponent.component';
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;
import IField = Data.FieldManagement.IField;
import {
  NPlanningEntryComponentData
} from '../../ap-n-fertilization-entry-components/ap-n-fertilization-planning-configs/n-fertilization-entry-planning-types';

@Injectable()
export class BasePlanningEntryService implements INutrientPlanningEntryStepsConfig {
    protected _isSaved: boolean | undefined;
    protected _isNewMode: boolean | undefined;
    protected _shouldDeselectFieldsOnClose: boolean | undefined;
    protected _selectedFields: IField[] = [];
    protected _componentData: PlanningEntryComponentData | NPlanningEntryComponentData;

    initData(): void {
      this._shouldDeselectFieldsOnClose = false;
      this._isNewMode = false;
      this._isSaved = false;
    }

    get getSelectedFields(): IField[] {
      return this._selectedFields;
    }

    setComponentData(data: PlanningEntryComponentData | NPlanningEntryComponentData): void {
      this._componentData = data;
    }

    setSubscriptions(dynComponent: ApDynComponentComponent): void {
    }

    clearData(): void {
    }
}

import {AfterViewInit, Component, EventEmitter, Output} from '@angular/core';
import {MapStore}                                       from '../../../stores/map/map.store';
import {MapLayerControlStore}                           from '../../../stores/map/map.layer.control.store';
import {FormControl, FormGroup}                         from '@angular/forms';
import {BingImagery}                                    from '../../../ap-map';

@Component({
  selector: 'layer-controller-map-selector',
  template: `
    <form [formGroup]="mapGroup">
      <div class="ap-layer-control-base-map-item" *ngFor="let m of (maps$ | async)">
        <input type="radio"
               kendoRadioButton
               formControlName="map"
               name="map"
               [id]="m.value"
               [value]="m.value"
               (change)="select(m.value)"/>
        <label class="k-label" [for]="m.value">{{m.text}}</label>
      </div>
    </form>
  `,
  styles: [
    `
      div.ap-layer-control-base-map-item {
        display: flex;
        padding: 5px;
        cursor: pointer;
      }

      div.ap-layer-control-base-map-item > input {
        margin-right: 5px;
      }
    `,
  ],
})
export class LayerControllerMapSelectorComponent implements AfterViewInit {
  @Output()
  public selectedMap = new EventEmitter<BingImagery>();
  public maps$ = this.mapLayerControlStore.Maps$;
  public mapSelectControl = new FormControl(null);
  public mapGroup = new FormGroup({
    map: this.mapSelectControl,
  });

  constructor(private mapStore: MapStore,
              private mapLayerControlStore: MapLayerControlStore) {
  }

  ngAfterViewInit(): void {
    this.mapStore.Layers.BackgroundMap$.subscribe(v => this.mapSelectControl.setValue(v));
  }

  select(value: BingImagery): void {
    this.selectedMap.emit(value);
  }
}

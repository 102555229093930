<ng-container *ngIf="data?.length <= 0">
  <div class="empty-message">
    <p>{{ emptyMessage | translate }}</p>
  </div>
</ng-container>
<ng-container *ngIf="data?.length > 0">
  <div class="ap-base-fert-stats">
    <h3>{{ title | translate }}</h3>
    <div class="ap-base-fert-stats-table">
      <h3>{{ 'Global__Demands' | translate }}*</h3>
      <div>
        <ap-dyngrids class="ap-statistic-grid"
                     [canCreate]="false"
                     [canSearch]="false"
                     [checkboxDisabled]="true"
                     [pageable]="false"
                     [scrollable]="'none'"
                     [columns]="stat1columns"
                     [items]="itemsStat1$ | async"
                     [groupable]="false"
                     [reorderable]="false"
                     [selectable]="gridSelectableSettings">
        </ap-dyngrids>
        *{{ 'Global__Demands_Note' | translate }}
      </div>
    </div>
    <div class="ap-base-fert-stats-table">
      <h3>{{ 'Global__Plannings' | translate }}</h3>
      <div>
        <ap-dyngrids class="ap-statistic-grid"
                     [canCreate]="false"
                     [canSearch]="false"
                     [checkboxDisabled]="true"
                     [pageable]="false"
                     [scrollable]="'none'"
                     [columns]="stat2columns"
                     [items]="itemsStat2$ | async"
                     [groupable]="false"
                     [reorderable]="false"
                     [selectable]="gridSelectableSettings">
        </ap-dyngrids>
      </div>
    </div>
    <div class="ap-base-fert-stats-table">
      <h3>{{ 'Global__Bookings' | translate }}</h3>
      <div>
        <ap-dyngrids class="ap-statistic-grid"
                     [canCreate]="false"
                     [canSearch]="false"
                     [checkboxDisabled]="true"
                     [pageable]="false"
                     [scrollable]="'none'"
                     [columns]="stat3columns"
                     [items]="itemsStat3$ | async"
                     [groupable]="false"
                     [reorderable]="false"
                     [selectable]="gridSelectableSettings">
        </ap-dyngrids>
      </div>
    </div>
  </div>
</ng-container>

import {IStateStore}           from '../../ap-interface';
import {Store}                 from '../index';
import {Injectable}            from '@angular/core';
import {
  ApCustomTypes,
  NotificationAdd,
  NotificationDelete,
  NotificationInsert,
  NotificationLoad,
  NotificationLoadSuccess,
  NotificationUpdate
}                              from 'invoker-transport';
import {NotificationDeleteAll} from '../../../../projects/invoker-transport/src/lib/actions/common';
import INotifications = Data.Common.INotifications;
import IGuid = System.IGuid;
import {ApSignalrService}      from '../../ap-core/services/ap-signalr.service';

interface INotificationStore extends IStateStore<INotifications> {
}

@Injectable({providedIn: 'root'})
export class NotificationStore extends Store<INotificationStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });
    backend.registerObservable(NotificationLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(NotificationAdd).subscribe(d => {
      super.Mutate(s => s.data, o => {
        o.Add(d.Data);
        return o;
      });
    });
    this.AfterDatabaseUpdate.subscribe(() => {
      this.ReloadSource();
    });
  }

  public getNotifications(): INotifications[] {
    return super.Listen(s => s.data).getValue();
  }

  public loadNotification(): void {
    this.DispatchBackend(new NotificationLoad([]));
  }

  public deleteAllNotifications(): void {
    this.DispatchBackend(new NotificationDeleteAll([]));
  }

  public deleteNotification(notification: INotifications): void {
    this.DispatchBackend(new NotificationDelete([
      {Name: 'notification', Type: ApCustomTypes.Data_Common_Notifications, Value: notification},
    ]));
  }

  public updateNotification(notification: INotifications): void {
    this.DispatchBackend(new NotificationUpdate([
      {Name: 'notification', Type: ApCustomTypes.Data_Common_Notifications, Value: notification},
    ]));
  }

  public insertNotification(notification: INotifications): void {
    this.DispatchBackend(new NotificationInsert([
      {Name: 'notification', Type: ApCustomTypes.Data_Common_Notifications, Value: notification},
    ]));
  }

  public ChangeWasRead(index: number, value: boolean): void {
    super.Mutate(s => s.data, o => {
      o[index].WasRead = value;
      return o;
    });
    this.updateNotification(super.Listen(s => s.data).getValue()[index]);
  }

  public ChangeWasReadWithId(id: IGuid, value: boolean): void {
    super.Mutate(s => s.data, o => {
      o.Find(i => i?.Id === id).WasRead = value;
      return o;
    });
    this.updateNotification(super.Listen(s => s.data).getValue().Find(i => i?.Id === id));
  }
}

import {NgModule}                  from '@angular/core';
import {CommonModule}              from '@angular/common';
import {ApResponsiveTextComponent} from './ap-responsive-text/ap-responsive-text.component';
import {ApTranslationModule}       from '../translation/ap-translation.module';
import {ApTooltipModule}           from '../ap-tooltip/ap-tooltip.module';
import {IconsModule}               from '@progress/kendo-angular-icons';

@NgModule({
  declarations: [
    ApResponsiveTextComponent,
  ],
  imports: [
    CommonModule,
    ApTranslationModule,
    ApTooltipModule,
    IconsModule
  ],
  exports: [
    ApResponsiveTextComponent,
  ]
})
export class ApResponsiveTextModule {
}

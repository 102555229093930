import {EventEmitter, Injectable} from '@angular/core';
import {Store}                    from '../index';
import {IStateStore}              from '../../ap-interface/interfaces/store';
import {
  CreateAllRasters,
  CreateAllRastersSuccess,
  NetTypes,
  SoilSampleFieldInterpolate,
  SoilSampleFieldInterpolateSuccess,
  SoilSampleFieldStateLoad
}                                 from 'invoker-transport';
import {SafeBehaviorSubject}      from 'ts-tooling';
import ISoilSampleField = Data.Nutrients.ISoilSampleField;
import IGuid = System.IGuid;
import {ApSignalrService}         from '../../ap-core/services/ap-signalr.service';

interface IKrigingStore extends IStateStore<ISoilSampleField> {
  interpolatedFieldId: -1;
  pending: { [key: number]: boolean };
}

@Injectable({providedIn: 'root'})
export class KrigingStore extends Store<IKrigingStore> {
  public OnInterpolationFinish = new EventEmitter(true);

  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      interpolatedFieldId: -1,
      pending: {}
    });

    backend.registerObservable(SoilSampleFieldStateLoad).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SoilSampleFieldInterpolateSuccess).subscribe(d => {
      super.Mutate(s => s.pending, (pending) => {
        delete pending[d.Data];
        return pending;
      });
    });
    backend.registerObservable(CreateAllRastersSuccess).subscribe(() => {
      this.OnInterpolationFinish.emit();
    });
  }

  get Pending$(): SafeBehaviorSubject<{ [key: number]: boolean }> {
    return this.Listen(s => s.pending);
  }

  loadSampleFieldState(): void {
    super.SetLoadState();
    super.Mutate(d => d.data, () => []);
    this.DispatchBackend(new SoilSampleFieldStateLoad([]));
  }

  startInterpolationForSampleField(sampleFieldIds: number[]): void {
    super.Mutate(s => s.pending, (pending) => {
      for (const sampleFieldId of sampleFieldIds) {
        pending[sampleFieldId] = true;
      }
      return pending;
    });
    this.DispatchBackend(new SoilSampleFieldInterpolate([
      {Name: 'sampleFieldIds', Type: NetTypes.INT + '[]', Value: sampleFieldIds}
    ]));
  }

  createAllRastersFor(fieldGeomId: string | IGuid): void {
    this.DispatchBackend(new CreateAllRasters([
      {Name: 'fieldGeomId', Type: NetTypes.GUID, Value: fieldGeomId},
      {Name: 'priority', Type: NetTypes.INT, Value: 10},
      {Name: 'notice', Type: NetTypes.STRING, Value: ''},
    ]));
  }
}

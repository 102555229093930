import {ApBaseTileLayer}      from './ap-base-tile.layer';
import {MapStore}             from '../../stores/map/map.store';
import WMTS                   from 'ol/source/WMTS';
import WMTSTileGrid           from 'ol/tilegrid/WMTS';
import {getWidth, getTopLeft} from 'ol/extent';
import {get}                  from 'ol/proj';

export class ApWMTSLayer extends ApBaseTileLayer<WMTS> {
  constructor(mapStore: MapStore) {

    const projection = get('EPSG:3857');
    const projectionExtent = projection.getExtent();
    const size = getWidth(projectionExtent) / 256;
    const resolutions = new Array(14);
    const matrixIds = new Array(14);
    for (let z = 0; z < 14; ++z) {
      // generate resolutions and matrixIds arrays for this WMTS
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = z;
    }

    super(mapStore, 'WMTS', new WMTS({
      layer: 'WMTS',
      matrixSet: 'world_grid',
      style: 'default',
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        matrixIds,
        resolutions,
      }),
      format: 'image/jpeg',
      requestEncoding: 'REST',
    }));
  }

  setUrl(url: string): void {
    this.source.setUrl(url);
    this.layer.setZIndex(2);
    this.Visibility = url !== '';
  }
}

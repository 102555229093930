import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {ApDynformsValidator}                         from '../ap-dynforms-validator';
import {Observable}                                  from 'rxjs';
import {AsyncValidatorFn, ValidatorFn}               from '@angular/forms';
import {map}                                         from 'rxjs/operators';
import {get}                                         from 'lodash';
import {ApUtilService}                               from '../../ap-utils/service/ap-util.service';
import {TemplateRef}                                 from '@angular/core';
import {ApTranslationService}                        from '../../ap-utils/service/ap-translation.service';
import {ObjectFactory}                               from 'ts-tooling';

/**
 * configuration for multiselect control
 */
export class ApDynformsConfigMultiSelect extends ApDynformsConfigBase<any> {
  controlType = ApDynformsControltype.MultiSelect;
  optionsIn: Observable<any[]>;
  options: Observable<any[]>;
  valueField: string;
  textField: string;
  valuePrimitive: boolean;
  placeholder: string;
  loading$: Observable<boolean>;
  clearButton: boolean;
  noDataString: string;
  noDataTemplate: TemplateRef<any>;

  private static _containsFilter(o: any, filterString: string, textField: string): boolean {
    if (typeof o !== typeof '') {
      o = get(o, textField);
    }
    if (typeof o !== typeof '') {
      return true;
    }
    return o.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
  }

  constructor(options: {
    value?: any[],
    value$?: Observable<any[]>,
    key?: string,
    label?: string,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    listenUpdate?: Observable<any>[]
    options?: any[] | Observable<any[]>,
    valueField?: string,
    textField?: string,
    valuePrimitive?: boolean,
    placeholder?: string,
    translate?: boolean,
    loading$?: Observable<boolean>,
    clearButton?: boolean,
    dependsOn?: string[],
    sort?: (1 | -1),
    formErrors?: string[],
    noData?: TemplateRef<any> | string,
    cssClass?: string,
  } = {}) {
    super(options);
    this.optionsIn = ApUtilService.asObservable(options.options, []);
    if (options.translate) {
      if (!options.textField) {
        this.optionsIn = this.optionsIn.pipe(
          map((d) => d.map((s) => ApTranslationService.translate(s)))
        );
        this.value = ApTranslationService.translate(this.value);
      } else {
        this.optionsIn = this.optionsIn.pipe(
          map((d) => d.map((o) => {
            o = ObjectFactory.Copy(o);
            o[this.textField] = ApTranslationService.translate(o[this.textField]);
            return o;
          }))
        );
      }
    }
    if (options.sort) {
      this.optionsIn = this.optionsIn.pipe(
        map(d => d.sort((a, b) =>
          (options.valuePrimitive ? a < b : get(a, options.textField) < get(b, options.textField)) ? -options.sort : options.sort
        ))
      );
    }
    this.options = this.optionsIn;
    this.valueField = options.valueField || '';
    this.textField = options.textField || '';
    if (options.valuePrimitive !== undefined) {
      this.valuePrimitive = options.valuePrimitive;
    } else {
      this.valuePrimitive = true;
    }
    this.placeholder = options.placeholder;
    this.loading$ = options.loading$;
    this.clearButton = options.clearButton ? options.clearButton : false;
    if (typeof options.noData === 'object') {
      this.noDataTemplate = options.noData;
    } else if (typeof options.noData === 'string') {
      this.noDataString = options.noData;
    }
  }

  filterChange(filterString: string): void {
    this.options = this.optionsIn.pipe(
      map(oIn => oIn.filter(o => ApDynformsConfigMultiSelect._containsFilter(o, filterString, this.textField)))
    );
  }
}

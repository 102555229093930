import {MapStore}                    from '../../stores/map/map.store';
import {LayerGroupNames, LayerNames} from './layer-names';
import OlGeolocation                 from 'ol/Geolocation';
import {ApMapViews}    from './ap-map.views';
import Feature         from 'ol/Feature';
import Style           from 'ol/style/Style';
import Fill            from 'ol/style/Fill';
import Point           from 'ol/geom/Point';
import {ApMapInstance} from '../ap-map.instance';
import OlIcon          from 'ol/style/Icon';
import {ApBaseVectorLayer} from './ap-base-vector.layer';
import {LayerZIndex} from './layer-z-index';

export class ApUserLocationLayer extends ApBaseVectorLayer {
  private geolocation: OlGeolocation;
  private accuracyFeature: Feature;
  private positionFeature: Feature;
  private userTrackingEnabled = false;

  constructor(mapStore: MapStore) {
    super(LayerNames.USER_LOCATION, LayerGroupNames.NONE, mapStore);
    this.FixedZIndex = LayerZIndex.USER_LOCATION;

    this.accuracyFeature = new Feature();
    this.accuracyFeature.setStyle(
      new Style({
        fill: new Fill({
          color: 'rgba(216,216,216,0.5)',
        })
      })
    );
    this.positionFeature = new Feature();
    this.positionFeature.setStyle(
      new Style({
        image:  new OlIcon(({
          src: '/assets/svg/position_pin.svg',
          scale: 1,
          opacity: 1,
          offset: [0, 0],
          anchor: [0.5, 0.94] // X offset in fraction (0-1) - half to the right, Y offset - full icon height to the top.
          // need to take into account small outer border and svg not taking whole height
          // Icon size is defined on SVG level inside width/height attributes because OpenLayers
          // sometimes working bad with size/width/height on style level
        }))
      }),
    );
  }

  public initUserPositionTracking(): void {
    this.userTrackingEnabled = true;

    if (this.layer.getSource().getFeatures().length === 0) {
      this.geolocation = new OlGeolocation({
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: ApMapViews.olView.getProjection(),
      });

      this.layer.getSource().addFeatures([this.accuracyFeature, this.positionFeature]);

      if (ApMapInstance.mapRef) {
        this.initializeEventHandlers();
      } else {
        setTimeout(() => this.initializeEventHandlers(), 300);
      }
    }

    this.mapStore.Layers.UserLocationLayer.Visibility = true;

    this.geolocation.setTracking(true);
    this.geolocation.dispatchEvent('change:position');
    this.geolocation.dispatchEvent('change:accuracyGeometry');
  }

  private initializeEventHandlers(): void {
    this.geolocation.on('change:position', () => {
      if (this.userTrackingEnabled) {
        const coordinates = this.geolocation.getPosition();
        this.positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        if (coordinates && ApMapInstance.mapRef) {
          ApMapViews.olView.setCenter(this.geolocation.getPosition());
          ApMapInstance.displayTooltipAt(ApMapInstance.mapRef.getPixelFromCoordinate(coordinates), 'font-size: 5vh;');
        }
      }
    });

    this.geolocation.on('change:accuracyGeometry', () => {
      if (this.userTrackingEnabled) {
        this.accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
      }
    });
  }

  public disableUserPositionTracking(): void {
    this.userTrackingEnabled = false;
    this.geolocation.setTracking(false);
    this.clearPositionRelatedGeometry();
  }

  private clearPositionRelatedGeometry(): void {
    this.positionFeature.setGeometry(undefined);
    this.accuracyFeature.setGeometry(undefined);
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IReadDriverModel = Data.DocuContext.Driver.IReadDriverModel;

export enum DriversActions {
  DRIVERS_LOAD = '[drivers] load',
  DRIVERS_LOAD_SUCCESS = '[drivers] load success',
  DRIVERS_LOAD_FAIL = '[drivers] load fail',
  DRIVERS_UPDATE = '[drivers] update',
  DRIVERS_CREATE = '[drivers] create',
  DRIVERS_DELETE = '[drivers] delete',
  DRIVERS_DELETE_SUCCESS = '[drivers] delete success',
  DRIVERS_SAVE_SUCCESS = '[drivers] save success',
  DRIVERS_SAVE_FAIL = '[drivers] save fail',
}

export class DriversLoad implements IAction {
  readonly type = DriversActions.DRIVERS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversLoadSuccess implements IAction {
  readonly type = DriversActions.DRIVERS_LOAD_SUCCESS;

  constructor(public payload: IReadDriverModel[]) {
  }
}

export class DriversLoadFail implements IAction {
  readonly type = DriversActions.DRIVERS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class DriversUpdate implements IAction {
  readonly type = DriversActions.DRIVERS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversCreate implements IAction {
  readonly type = DriversActions.DRIVERS_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversDelete implements IAction {
  readonly type = DriversActions.DRIVERS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversDeleteSuccess implements IAction {
  readonly type = DriversActions.DRIVERS_DELETE_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class DriversSaveSuccess implements IAction {
  readonly type = DriversActions.DRIVERS_SAVE_SUCCESS;

  constructor(public payload: IReadDriverModel) {
  }
}

export class DriversSaveFail implements IAction {
  readonly type = DriversActions.DRIVERS_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

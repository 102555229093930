import {Component, EventEmitter} from '@angular/core';
import {ColorLegendStore}        from '../../stores/map/color.legend.store';

export interface ITrackProperties {
  value: string;
  items: string[];
  change: EventEmitter<number>;
}

@Component({
  selector: 'track-property',
  template: `
    <kendo-dropdownlist
      [data]="data.items"
      [value]="data.value"
      [popupSettings]="{
        width: 'auto'
      }"
      (valueChange)="change($event)">
      <ng-template kendoComboBoxItemTemplate kendoDropDownListValueTemplate let-dataItem>
        {{dataItem | translate}}
      </ng-template>
    </kendo-dropdownlist>`
})
export class TrackPropertyComponent {
  static type = 'TrackPropertyComponent';

  data: ITrackProperties;

  constructor(private colorLegendStore: ColorLegendStore) {
  }

  change($event: string): void {
    this.colorLegendStore.valueChange(TrackPropertyComponent.type, this.data.items.indexOf($event));
  }
}

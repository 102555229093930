<ng-container *ngIf="data?.length <= 0">
  <div class="empty-message">
    <p>{{emptyMessage | translate}}</p>
  </div>
</ng-container>
<ng-container *ngIf="data?.length > 0">
  <h3>{{title | translate}}</h3>
  <div class="ap-n-fertilization-logs-stats">
    <ng-container *ngIf="(histogramData$ | async) as histogramData">
      <div class="ap-n-fertilization-logs-stats-diagram-container"
           *ngIf="histogramData.Data.length > 0">
        <ap-dotted-histogram-chart [class]="'ap-n-fertilization-logs-stats-diagram'"
                                   [data]="histogramData"
        ></ap-dotted-histogram-chart>
      </div>
    </ng-container>
    <ng-container *ngIf="(nUptakeBars$ | async) as nUptakeBars">
      <div class="ap-n-fertilization-logs-stats-diagram-container"
           *ngIf="nUptakeBars.length > 0">
        <ap-generic-bar-chart [class]="'ap-n-fertilization-logs-stats-diagram'"
                              [transitions]="false"
                              [zoomable]="false"
                              [pannable]="false"
                              [data]="nUptakeBars"
        ></ap-generic-bar-chart>
      </div>
    </ng-container>
    <ng-container *ngIf="(nApplicationBars$ | async) as nApplicationBars">
      <div class="ap-n-fertilization-logs-stats-diagram-container"
           *ngIf="nApplicationBars.length > 0">
        <ap-generic-bar-chart [class]="'ap-n-fertilization-logs-stats-diagram'"
                              [transitions]="false"
                              [zoomable]="false"
                              [pannable]="false"
                              [data]="nApplicationBars"
        ></ap-generic-bar-chart>
      </div>
    </ng-container>
    <ng-container *ngIf="(infoItems$ | async) as infoItems">
      <div class="ap-n-fertilization-logs-stats-table"
           *ngIf="infoItems.length > 0">
        <ng-container *ngFor="let info of infoItems; let i = index">
          <info-row-view [item]="info" [rowNum]="i" [maxRows]="infoItems.length"></info-row-view>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

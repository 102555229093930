import {Injectable}                  from '@angular/core';
import {TranslationStore}            from '../../stores/translation/translation.store';
import {SettingsStore}               from '../../stores/base-data/settings.store';
import {ApElementOxydTypeEnum}       from '../../ap-interface';
import {GetElementOxidCalculatePipe} from '../pipes/get-element-oxid-calculate.pipe';
import {ApElementType}               from '../../ap-interface/enums/ap-elements-type.enum';
import IElement = Data.Common.IElement;

@Injectable({providedIn: 'root'})
export class GetElementService {
  constructor(private translationStore: TranslationStore,
              private settingStore: SettingsStore,
              private elementOxideCalculate: GetElementOxidCalculatePipe) {
  }

  public getAllElements(): { [key: number]: string } {
    return {
      [ApElementType.P]: this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientP'),
      [ApElementType.K]: this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientK'),
      [ApElementType.Mg]: this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientMg'),
      [ApElementType.CaO]: this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientCaO'),
      [ApElementType.N]: this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientN')
    };
  }

  public GetElementStringByString(element: ApElementType): string {
    switch (element) {
      case ApElementType.P:
        return this.settingStore.IsOxidFarm
          ? this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientP2O5')
          : this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientP');
      case ApElementType.K:
        return this.settingStore.IsOxidFarm
          ? this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientK2O')
          : this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientK');
      case ApElementType.Mg:
        return this.settingStore.IsOxidFarm
          ? this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientMgO')
          : this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientMg');
      case ApElementType.CaO:
        return this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientCaO');
      case ApElementType.N:
        return this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientN');
      default:
        return '';
    }
  }

  public GetElementStringByIElement(element: IElement): string {
    return this.GetElementStringById(element.Id);
  }

  public GetElementStringById(id: number): string {
    switch (id) {
      case ApElementType.P:
        return this.settingStore.IsOxidFarm
          ? this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientP2O5')
          : this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientP');
      case ApElementType.K:
        return this.settingStore.IsOxidFarm
          ? this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientK2O')
          : this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientK');
      case ApElementType.Mg:
        return this.settingStore.IsOxidFarm
          ? this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientMgO')
          : this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientMg');
      case ApElementType.CaO:
        return this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientCaO');
      case ApElementType.N:
        return this.translationStore.FindTranslationForSelectedLanguage('Global__NutrientN');
      default:
        return '';
    }
  }

  public CalculateElementOxidValueByElementType(value: number, elementType: ApElementType): number {
    switch (elementType) {
      case ApElementType.P:
        return this.CalculateElementOxidValueByGivenElementValue(value, ApElementOxydTypeEnum.P);
      case ApElementType.K:
        return this.CalculateElementOxidValueByGivenElementValue(value, ApElementOxydTypeEnum.K);
      case ApElementType.Mg:
        return this.CalculateElementOxidValueByGivenElementValue(value, ApElementOxydTypeEnum.Mg);
      case ApElementType.CaO:
      case ApElementType.N:
      default:
        return value;
    }
  }

  public CalculateToElementByGivenValue(value: number, elementType: ApElementType, isValueInOxid: boolean, isNeedConvert: boolean): number {
    if (!isNeedConvert) {
      return value;
    }
    let oxidUnit: ApElementOxydTypeEnum;
    let elementUnit: ApElementOxydTypeEnum;
    switch (elementType) {
      case ApElementType.P:
        oxidUnit = ApElementOxydTypeEnum.P2O5;
        elementUnit = ApElementOxydTypeEnum.P;
        break;
      case ApElementType.K:
        oxidUnit = ApElementOxydTypeEnum.K2O;
        elementUnit = ApElementOxydTypeEnum.K;
        break;
      case ApElementType.Mg:
        oxidUnit = ApElementOxydTypeEnum.MgO;
        elementUnit = ApElementOxydTypeEnum.Mg;
        break;
      default:
        return value;
    }
    return this.CalculateElementOxidValueByGivenElementValue(value, isValueInOxid ? oxidUnit : elementUnit);
  }

  public CalculateElementOxidValueByGivenElementValue(value: any, unit: ApElementOxydTypeEnum): number {
    if (value === '-' || value === 0 || value === null || value === undefined) {
      return value;
    }
    return this.elementOxideCalculate.transform(
      value, unit, this.settingStore.FirstSetting.UnitElementOxyd
    );
  }
}

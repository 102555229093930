import {AfterContentInit, Component, Input, OnInit} from '@angular/core';

import {ApPermissions}                   from 'invoker-transport';
import {Observable}                      from 'rxjs';
import {MapViewCurrentMenu, MapViewMode} from '../../ap-interface';
import {MapViewStore}                    from '../../stores/layout/mapview.store';


export enum ApMainTabs {
  MaximizeMap = 0,
  Map = 1,
  Statistic = 2,
  Help = 3,
  MinimizeMap = 4
}

@Component({
  selector: 'ap-tabstrip',
  templateUrl: 'ap-tabstrip.component.html'
})
export class ApTabStripComponent implements OnInit, AfterContentInit {
  @Input()
  public hasArrows: boolean;
  public mapViewMenus = MapViewCurrentMenu;
  public mapViewModes = MapViewMode;
  public mapViewMode$: Observable<MapViewMode>;
  public currentMapViewMenu$: Observable<MapViewCurrentMenu>;
  public ApPermissions = ApPermissions;

  constructor(private mapViewStore: MapViewStore) {
  }

  ngOnInit(): void {
    this.currentMapViewMenu$ = this.mapViewStore.Listen(s => s.currentMenu);
    this.mapViewMode$ = this.mapViewStore.Listen(s => s.mode);
  }

  ngAfterContentInit(): void {
    if (this.mapViewStore.getMapViewCurrentMenu() === MapViewCurrentMenu.MAP) {
      setTimeout(this.mapViewStore.refreshMap.bind(this.mapViewStore), 1);
    }
  }

  public selectTab(e): void {
    switch (e.index) {
      case ApMainTabs.MaximizeMap:
        this.onMaximizeMap();
        e.preventDefault();
        break;
      case ApMainTabs.Map:
        this.mapViewStore.goIntoMapMenu();
        break;
      case ApMainTabs.Statistic:
        this.mapViewStore.goIntoStatisticMenu();
        break;
      case ApMainTabs.Help:
        this.mapViewStore.goIntoHelpMenu();
        break;
      case ApMainTabs.MinimizeMap:
        this.onMinimizeMap();
        e.preventDefault();
        break;
    }
  }

  onMaximizeMap(): void {
    this.mapViewStore.increaseMapViewMode();
  }

  onMinimizeMap(): void {
    this.mapViewStore.decreaseMapViewMode();
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {
  ApDynGridPagerWizardBreadcrumbsStepper,
  ApDynGridPagerWizardSection
}                            from '../../config/ap-dyn-grid-pager-wizard-config';
import {Trace}               from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asWizardBreadcrumbsStepper'
})
export class AsWizardBreadcrumpsStepperPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridPagerWizardSection): ApDynGridPagerWizardBreadcrumbsStepper {
    return config as ApDynGridPagerWizardBreadcrumbsStepper;
  }

}

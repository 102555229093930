import {AfterContentInit, Component, Input, OnDestroy} from '@angular/core';
import {Observable, Subscription}                      from 'rxjs';
import {StatisticStore}                                from '../../../stores/statistic/statistic.store';
import {IDonutPieChartData}                            from '../../../ap-interface/interfaces';

@Component({
  selector: 'ap-datetime-selection-chart',
  templateUrl: 'ap-datetime-selection-chart.component.html',
  styleUrls: ['ap-datetime-selection-chart.component.scss']
})
export class ApDatetimeSelectionChartComponent implements OnDestroy, AfterContentInit {
  @Input()
  data: IDonutPieChartData[];
  @Input()
  title: string;

  private subscriptions: Array<Subscription> = [];

  public dataArray: number[] = [];
  categories: Date[] = [];

  public transitions = false;
  public strokeColor = '#212529';
  public navigatorStep: number;
  public step: number;
  public min = 0;
  public max = 20;

  constructor(private statisticStore: StatisticStore) {
    this.navigatorStep = Math.floor(this.categories.length / 10);
  }

  ngAfterContentInit(): void {
    this.subscriptions.push(this.statisticStore.Listen(s => s.data).subscribe(stats => {
      if (!stats) {
        return;
      }
      stats.forEach(stat => {
        this.categories.push(stat.chartData.date);
        this.dataArray.push(stat.chartData.count);
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }

  public onSelectEnd(args: any): void {
    this.min = args.from;
    this.max = args.to;
    this.transitions = false;
    this.step = Math.floor((this.max - this.min) / 10);
  }
}

import IApValidationResult = Data.Api.Validation.IApValidationResult;
import {IAction} from '../../interfaces';

export enum NotifyActions {
  RECIEVE_MESSAGE = '[notify] recieve',
  ADD_MESSAGE = '[notify] add',
  ADD_MESSAGE_DEBOUNCE = '[notify] add debounce',
  REMOVE_MESSAGE = '[notify] remove',
  SAVE_MESSAGE = '[notify] save'
}

/***
 * IAction for adding (validation) messages.
 */
export class NotifyAddMessage implements IAction {
  readonly type = NotifyActions.ADD_MESSAGE;

  constructor(public payload: IApValidationResult) {
  }
}

/**
 * IAction for removing messages
 */
export class NotifyRemoveMessage implements IAction {
  readonly type = NotifyActions.REMOVE_MESSAGE;

  constructor(public payload: IApValidationResult) {
  }
}

/**
 * Recieve Message from Backend
 */
export class NotifyRecieveMessage implements IAction {
  readonly type = NotifyActions.RECIEVE_MESSAGE;
}

/**
 * Add a Message that was removed automatic by Timeout
 */
export class NotifyAddMessageDebounce implements IAction {
  readonly type = NotifyActions.ADD_MESSAGE_DEBOUNCE;

  constructor(public payload: IApValidationResult) {
  }
}

export class NotifySaveMessage implements IAction {
  readonly type = NotifyActions.SAVE_MESSAGE;

  constructor(public payload: boolean) {
  }
}

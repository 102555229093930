import {NgModule}                           from '@angular/core';
import {CommonModule}                       from '@angular/common';
import {ApTranslationsEditComponent}        from './ap-translations-edit.component';
import {ReactiveFormsModule}                from '@angular/forms';
import {ApTranslationModule}                from '../../translation/ap-translation.module';
import {ApCoreModule}                       from '../../ap-core/ap-core.module';
import {DropDownListModule}                 from '@progress/kendo-angular-dropdowns';
import {BodyModule, GridModule}             from '@progress/kendo-angular-grid';
import {ApDynformsModule}                   from '../../ap-dynforms/ap-dynforms.module';
import {ApDynComponentModule}               from '../../ap-dyncomponent/ap-dyncomponent.module';
import {ApResponsiveTextModule}             from '../../ap-responsive-text/ap-responsive-text.module';
import {ApTooltipModule}                    from '../../ap-tooltip/ap-tooltip.module';
import {TextBoxModule}                      from '@progress/kendo-angular-inputs';
import {ApJobsEditComponent}                from './ap-jobs-edit.component';
import {ApParameterDefinitionEditComponent} from './ap-parameter-definition-edit.component';
import {ApDynGridsModule}                   from '../../ap-dyngrids/ap-dyngrids.module';

const COMPONENTS = [ApTranslationsEditComponent, ApJobsEditComponent, ApParameterDefinitionEditComponent];

@NgModule({
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ApTranslationModule,
    ApCoreModule,
    DropDownListModule,
    GridModule,
    ApDynformsModule,
    ApDynGridsModule,
    ApDynComponentModule,
    ApResponsiveTextModule,
    ApTooltipModule,
    BodyModule,
    TextBoxModule,
  ],
  exports: COMPONENTS,
})
export class ApAdministrationEntryComponentsModule {
}

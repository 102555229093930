import {AfterContentInit, Component, Input, OnDestroy} from '@angular/core';
import {IChartSet}                                     from '../../../stores/statistic/statistic.store';
import {TranslationStore}                              from '../../../stores/translation/translation.store';
import {Border}                                        from '@progress/kendo-angular-charts';
import {StatisticChartAreaService}                     from '../../services/statistic-chart-area.service';
import {map}                                           from 'rxjs/operators';
import {FieldStore}                                    from '../../../stores/farm/field.store';
import {ObjectFactory}                                 from 'ts-tooling';
import {IBarLineAreaChartData}                         from '../../../ap-interface';
import IGuid = System.IGuid;

export type SimpleValue = { id: string, value: number, category: string, color: string, meta: any };
export type SimpleValueSet = SimpleValue[];
export type FieldSoilPlanningYear = { fieldId: IGuid, dates: { date: string, percent: number }[] };

@Component({
  selector: 'ap-nutrients-stats',
  templateUrl: 'nutrients.stats.component.html',
})
export class NutrientsStatsComponent implements AfterContentInit, OnDestroy {
  @Input()
  data: IChartSet<IBarLineAreaChartData>[];
  @Input()
  title: string;
  @Input()
  emptyMessage = '';
  @Input()
  decimalPlaces = 2;
  public groups: { id: string, name: string }[] = [];
  public filteredGroups: { id: string, name: string }[] = [];
  public selectedGroup: { id: string, name: string } = {id: '', name: ''};
  public chartP: IChartSet<IBarLineAreaChartData> = null;
  public chartK: IChartSet<IBarLineAreaChartData> = null;
  public chartMg: IChartSet<IBarLineAreaChartData> = null;
  public chartPh: IChartSet<IBarLineAreaChartData> = null;
  public sampleDate: { value: number, category: string, color: string, fieldIds: IGuid[], years: string[] }[] = [];
  public fieldSoilPlanningYear: FieldSoilPlanningYear[] = [];
  public soilGroup: SimpleValueSet = [];
  public soilGroupCategories: string[] = ['1', '2', '3', '4', '5', '6'];
  public soilGroupMin = 0;
  public soilGroupMax = 1;
  public infos: SimpleValueSet[] = [];
  public backgroundColor = '#ffffffbf';
  public strokeColor = '#212529';
  public borderNone: Border = {
    color: this.strokeColor,
    dashType: 'solid',
    width: 0,
  };
  public border: Border = {
    color: this.strokeColor,
    dashType: 'solid',
    width: 1,
  };
  // scale the chart height and takes the minimum height of 120px
  public chartHeight = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => v - 105 < 120 ? 120 : v - 105),
  );
  public chartHeightNoTitleHalf = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => (v - 50) / 2 < 120 ? 120 : (v - 50) / 2),
  );
  public chartHeight4X4 = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => (v / 2) - 52.5 < 120 ? 120 : (v / 2) - 52.5),
  );
  // scale the chart width and use the full width available
  public chartWidth = this.statisticChartAreaService.chartAreaWidth.pipe(
    map(v => v),
  );
  public chartWidthHalf = this.statisticChartAreaService.chartAreaWidth.pipe(
    map(v => v / 2),
  );

  constructor(private translationStore: TranslationStore,
              public statisticChartAreaService: StatisticChartAreaService,
              private fieldStore: FieldStore) {
  }

  ngAfterContentInit(): void {
    this.readData(this.data);
  }

  ngOnDestroy(): void {

  }

  filterChange(filter: any): void {
    this.filteredGroups = this.groups.FindAll(e => this.translationStore
      .FindTranslationForSelectedLanguage(e.id).Contains(filter));
  }

  onSelectionChange(item: { id: string, name: string }): void {
    if (!item) {
      return;
    }
    this.selectedGroup = item;
  }

  labelContentSamplingYear = (e: any): string => e.dataItem.years
    .FindAll((el: any) => el.category === e.dataItem.category)
    .Convert((el: any) => el.date)
    .Unique()
    .Join('\n')

  private readData(d: IChartSet<IBarLineAreaChartData>[]): void {
    this.groups.Clear();

    if (!d.Any()) {
      return;
    }

    this.groups.Add({
      id: '1',
      name: this.translationStore.FindTranslationForSelectedLanguage('Global__Nutrient_Classes')
    });
    /*this.groups.Add({
      id: '2',
      name: this.translationStore.FindTranslationForSelectedLanguage('Nutrient__Band_SoilPlanningYear')
    });*/
    this.groups.Add({id: '3', name: this.translationStore.FindTranslationForSelectedLanguage('Nutrients__Soilgroups')});
    this.groups.Add({
      id: '4',
      name: this.translationStore.FindTranslationForSelectedLanguage('Global__More_Informations')
    });
    this.filterChange('');

    const dc = ObjectFactory.Copy(d);
    this.chartP = this.processClassData(dc, 'Nutrient__Band_ClassP');
    this.chartK = this.processClassData(dc, 'Nutrient__Band_ClassK');
    this.chartMg = this.processClassData(dc, 'Nutrient__Band_ClassMg');
    this.chartPh = this.processClassData(dc, 'Nutrient__Band_ClassPh');

    this.processSampleYearData(dc);

    this.soilGroup = this.processSimpleValueSetData(dc, 'Nutrient__Band_SoilGroup');
    // for some reason the kendo bar chart skips categories without any data object
    // => therefore we have to add dummy data objects for the missing soil groups
    for (const soilGroupCategory of this.soilGroupCategories) {
      if (!this.soilGroup.some(s => s.category === soilGroupCategory)) {
        this.soilGroup.Add({value: 0, category: soilGroupCategory, id: '', color: '', meta: undefined});
      }
    }
    this.soilGroup = this.soilGroup.sort((a, b) => a.category?.localeCompare(b.category));
    this.soilGroupMax = this.soilGroup.Convert((e: any) => e.value).Sum();
    this.infos.Clear();
    this.infos.Add(this.processSimpleValueSetData(dc, 'Nutrient__Band_TypeOfUse', 'Nutrients__Landuse'));
    this.infos.Add(this.processSimpleValueSetData(dc, 'Nutrient__Band_Region', 'Nutrients_Pages_Popups__Region'));
    this.infos.Add(this.processSimpleValueSetData(dc, 'Nutrient__Band_MethodP', 'Nutrient__Band_MethodP'));
    this.infos.Add(this.processSimpleValueSetData(dc, 'Nutrient__Band_MethodK', 'Nutrient__Band_MethodK'));
    this.infos.Add(this.processSimpleValueSetData(dc, 'Nutrient__Band_MethodMg', 'Nutrient__Band_MethodMg'));
    this.infos.Add(this.processSimpleValueSetData(dc, 'Nutrient__Band_MethodPh', 'Nutrient__Band_MethodPh'));

    if (!this.selectedGroup?.id) {
      this.selectedGroup = this.groups.FirstOrDefault() ?? {id: '', name: ''};
    }
  }

  private processClassData(d: IChartSet<IBarLineAreaChartData>[], title: string): IChartSet<IBarLineAreaChartData> {
    if (d === null || d === undefined) {
      return null;
    }
    let result: IChartSet<IBarLineAreaChartData> = null;
    for (const dataset of d.FindAll(e => e.data?.title === title)) {
      if (!result) {
        result = this.getChartCopy(dataset);
        continue;
      }
      if (result.axes.maxX < dataset.axes.maxX) {
        result.axes.maxX = dataset.axes.maxX;
      }
      for (let i = 0, l = result.data.data.length; i < l; i++) {
        result.data.data[i].value += dataset.data.data[i] ? dataset.data.data[i].value || 0 : 0;
      }
    }
    return result;
  }

  private processSampleYearData(d: IChartSet<IBarLineAreaChartData>[]): void {
    this.sampleDate = [];
    if (!d || d?.length < 1) {
      return;
    }
    this.fieldSoilPlanningYear = d[0]?.meta?.fieldYears?.Copy();
    this.fieldSoilPlanningYear?.sort((a, b) => this.fieldStore.getFieldNameById(a.fieldId)
      .localeCompare(this.fieldStore.getFieldNameById(b.fieldId)));
    for (const dataset of d.FindAll(e => e.data?.title === 'Nutrient__Band_SoilPlanningYear')) {
      for (let i = 0, l = dataset.data.categories.length; i < l; i++) {
        const found = this.sampleDate.Find(e => e.category === dataset.data.categories[i]);
        if (found) {
          found.fieldIds.Add(dataset.meta.fieldId);
          found.value += dataset.data.data[i].value;
          continue;
        }
        this.sampleDate.Add({
          category: dataset.data.categories[i],
          value: dataset.data.data[i].value,
          color: dataset.color(dataset.data.categories[i]),
          fieldIds: [dataset.meta.fieldId],
          years: dataset.meta.years,
        });
      }
    }
  }

  private processSimpleValueSetData(d: IChartSet<IBarLineAreaChartData>[], title: string, id?: string): SimpleValueSet {
    const result = [];
    const filteredSets = d.FindAll(e => e.data?.title === title);
    const tmp: { [key: string]: { value: number, color: string } } = {};
    for (const item of filteredSets) {
      for (const key in item.data.categories) {
        if (!item.data.categories.hasOwnProperty(key)) {
          continue;
        }
        const category = item.data.categories[key];
        if (!tmp[category]) {
          tmp[category] = {
            value: item.data.data[key].value,
            color: item.color(category),
          };
          continue;
        }
        tmp[category].value += item.data.data[key].value;
      }
    }

    for (const key in tmp) {
      if (!tmp.hasOwnProperty(key)) {
        continue;
      }
      result.Add({
        id: id || '',
        value: tmp[key].value,
        category: key,
        color: tmp[key].color,
        meta: {
          areaSum: filteredSets[0].meta.areaSum,
        },
      });
    }

    const allValues = result.Convert((e: any) => e.value).Sum();
    if (allValues !== 0) {
      for (const item of result) {
        item.value = (item.value * 100 / allValues) * filteredSets[0].meta.areaSum / 100;
      }
    }
    return result;
  }

  private getChartCopy(dataset: IChartSet<IBarLineAreaChartData>): IChartSet<IBarLineAreaChartData> {
    if (!dataset) {
      return null;
    }
    return {
      valueType: dataset.valueType,
      axes: dataset.axes,
      decimalPlaces: dataset.decimalPlaces,
      meta: dataset.meta,
      step: dataset.step,
      noteField: dataset.noteField,
      color: dataset.color,
      visual: dataset.visual,
      tooltipDelegate: dataset.tooltipDelegate,
      noteDelegate: dataset.noteDelegate,
      data: {
        title: dataset.data?.title,
        name: dataset.data?.name,
        categories: dataset.data?.categories,
        data: [
          { value: dataset.data?.data[0]?.value ?? 0 },
          { value: dataset.data?.data[1]?.value ?? 0 },
          { value: dataset.data?.data[2]?.value ?? 0 },
          { value: dataset.data?.data[3]?.value ?? 0 },
          { value: dataset.data?.data[4]?.value ?? 0 },
          { value: dataset.data?.data[5]?.value ?? 0 },
          { value: dataset.data?.data[6]?.value ?? 0 },
          { value: dataset.data?.data[7]?.value ?? 0 },
          { value: dataset.data?.data[8]?.value ?? 0 }
        ]
      } as IBarLineAreaChartData
    } as IChartSet<IBarLineAreaChartData>;
  }
}

<ap-section-header *ngIf="caption">
  <ap-section-header-grid
    (createDataset)="createHandler()"
    (search)="filterGrid($event)"
    (clearAllFilters)="clearFilters()"
    [canCreate]="canCreate"
    [canSearch]="canSearch"
    [caption]="caption"
    [createText]="'Docu_Ini__New'"
    [filterPlaceholder]="'Global_Filter'"
    [filterTooltip]="filterTooltip"
    [iconId]="headerIcon"
    [svg]="headerSvg"
  ></ap-section-header-grid>
</ap-section-header>
<kendo-grid #grid
            (dataStateChange)="gridPaging.dataStateChange($event)"
            (edit)="editHandler($event)"
            (excelExport)="onExcelExport($event)"
            (filterChange)="gridPaging.onFilterChange($event)"
            (groupChange)="gridPaging.groupChange($event)"
            (pageChange)="gridPaging.pageChange($event)"
            (selectedKeysChange)="onSelectedKeysChange($event)"
            (selectionChange)="onSelectionChanged($event)"
            (sortChange)="gridPaging.sortChange($event)"
            [class.details]="level !== 0"
            [data]="gridPaging.gridView$ | async"
            [filter]="gridPaging.filter"
            [filterable]="'menu'"
            [group]="gridPaging.groups"
            [groupable]="groupable"
            [kendoGridSelectBy]="'Id'"
            [loading]="loading$ | async"
            [pageSize]="gridPaging.pageSize"
            [pageable]="pageable"
            [reorderable]="gridPaging.reorderable"
            [resizable]="_agriconShowHiddenProperties"
            [rowClass]="setRowClass.bind(this)"
            [scrollable]="scrollable ? scrollable :(level == 0 || _agriconShowHiddenProperties) ? 'scrollable' : 'none'"
            [selectable]="gridSelectableSettings | async"
            [hideHeader]="hideHeader"
            [selectedKeys]="gridPaging.selectedKeys"
            [skip]="gridPaging.skip"
            [sort]="gridPaging.sort"
            [sortable]="gridPaging.sortable">
  <kendo-grid-checkbox-column *ngIf="!checkboxDisabled"
                              [class.small-column]="true" [class]="'checkbox-cell-center'"
                              [headerClass]="'checkbox-cell-center'"
                              [width]="checkboxColumnWidth">
    <ng-template *ngIf="rowSelected === undefined && selectable.mode !== 'single'" kendoGridHeaderTemplate>
      <input (selectAllChange)="gridPaging.onSelectAllChange($event)" [id]="'selectAllCheckboxId' + level"
             [state]="gridPaging.selectAllState"
             class="k-checkbox"
             kendoGridSelectAllCheckbox>
      <label [for]="'selectAllCheckboxId' + level" class="k-checkbox-label"></label>
    </ng-template>
    <ng-template *ngIf="rowSelected !== undefined || selectable.mode === 'single'" kendoGridHeaderTemplate>
      &nbsp;
    </ng-template>
  </kendo-grid-checkbox-column>

  <ng-container *ngFor="let gridColumn of columns; trackBy: trackByColumnFn">

    <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Status"
                       [autoSize]="false"
                       [sortable]="gridColumn.sortable"
                       [class.small-column]="true"
                       [field]="(gridColumn | asStatus).field"
                       [filterable]="(gridColumn | asStatus).filterable"
                       [headerStyle]="gridColumn.headerStyle"
                       [hidden]="(gridColumn.hide.mapHide && (mapViewState$ | async) !== mapViewModes.HIDE) || (gridColumn.hide.selfHide | async)"
                       [minResizableWidth]="30"
                       [style]="gridColumn.style"
                       [title]="(gridColumn | asStatus).title | translate"
                       [width]="30">
      <ng-template *ngIf="(gridColumn | asStatus).filterable"
                   kendoGridFilterMenuTemplate
                   let-filter="filter"
                   let-filterService="filterService">
        <ap-status-column-filter
          [config]="gridColumn | asStatus"
          [currentFilter]="filter"
          [filterService]="filterService"
          [items]="items"
        ></ap-status-column-filter>
      </ng-template>
      <ng-template kendoGridHeaderTemplate>
        <ap-status-column-header [config]="gridColumn | asStatus"></ap-status-column-header>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-status-column-cell [config]="gridColumn | asStatus" [dataItem]="dataItem"></ap-status-column-cell>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-field="field" let-group="group">
        <ap-status-column-group-filter [config]="gridColumn | asStatus" [field]="field" [group]="group">
        </ap-status-column-group-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Property"
                       [field]="(gridColumn | asProperty).field"
                       [filter]="(gridColumn | asProperty).usedKendoDefaultFilter"
                       [filterable]="(gridColumn | asProperty).filterable"
                       [sortable]="(gridColumn | asProperty).sortable"
                       [format]="(gridColumn | asProperty).format | getKendoDateFormat"
                       [headerStyle]="gridColumn.headerStyle"
                       [hidden]="(gridColumn | isHidden:mapViewState$:widthHiddenColumns$) | async"
                       [style]="gridColumn.style"
                       [title]="(gridColumn | asProperty).title | translate"
                       [width]="gridColumn.width">
      <ng-template *ngIf="(gridColumn | asProperty).filterable && !(gridColumn | asProperty).usedKendoDefaultFilter"
                   kendoGridFilterMenuTemplate
                   let-dataItem
                   let-filter="filter"
                   let-filterService="filterService">
        <ap-property-column-filter
          [config]="gridColumn | asProperty"
          [currentFilter]="filter"
          [dataItem]="dataItem"
          [filterService]="filterService"
          [items]="items">
        </ap-property-column-filter>
      </ng-template>
      <ng-template kendoGridHeaderTemplate>
        <ap-property-column-header [config]="gridColumn | asProperty"></ap-property-column-header>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-property-column-cell (dblclick)="copyToClipboard(dataItem, gridColumn?.field)"
                                 [config]="gridColumn | asProperty"
                                 [dataItem]="dataItem"></ap-property-column-cell>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        {{(gridColumn | asProperty).title | translate}}:
        {{((gridColumn | asProperty).format ?
        (value | date: ((gridColumn | asProperty).format | getKendoDateFormat)) : value)
        | execPipes:gridColumn:'pipes' | async}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Template"
                       [headerStyle]="gridColumn.headerStyle"
                       [sortable]="gridColumn.sortable"
                       [hidden]="(gridColumn | isHidden:mapViewState$:widthHiddenColumns$) | async"
                       [style]="gridColumn.style"
                       [width]="gridColumn.width">
      <ng-template [ngIf]="(gridColumn | asTemplate).header" kendoGridHeaderTemplate>
        <ap-template-column-header [config]="gridColumn | asTemplate"></ap-template-column-header>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-template-column-cell [config]="gridColumn | asTemplate" [dataItem]="dataItem"></ap-template-column-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.ClassColor"
                       [minResizableWidth]="gridColumn.minWidth"
                       [title]="(gridColumn | asClassColor).title | translate"
                       [sortable]="(gridColumn | asClassColor).sortable"
                       [width]="gridColumn.width">
      <ng-template *ngIf="(gridColumn | asClassColor).filterable"
                   kendoGridFilterMenuTemplate
                   let-filter="filter"
                   let-filterService="filterService">
        <ap-class-color-column-filter
          [config]="gridColumn | asClassColor"
          [currentFilter]="filter"
          [filterService]="filterService"
          [items]="items">
        </ap-class-color-column-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-class-color-column-cell [config]="gridColumn | asClassColor"
                                    [dataItem]="dataItem"></ap-class-color-column-cell>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        {{(gridColumn | asClassColor).title | translate}}: {{value | execPipes:gridColumn:'pipes' | async}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Image"
                       [minResizableWidth]="gridColumn.minWidth"
                       [sortable]="(gridColumn | asImage).sortable"
                       [title]="(gridColumn | asImage).title | translate"
                       [width]="gridColumn.width">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-image-column-cell [config]="gridColumn | asImage" [dataItem]="dataItem"></ap-image-column-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Color"
                       [field]="(gridColumn | asColor).field"
                       [filterable]="(gridColumn | asColor).filterable"
                       [sortable]="(gridColumn | asColor).sortable"
                       [minResizableWidth]="gridColumn.minWidth"
                       [title]="(gridColumn | asColor).title | translate"
                       [width]="gridColumn.width">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-color-column-cell [config]="gridColumn | asColor" [dataItem]="dataItem"></ap-color-column-cell>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        {{(gridColumn | asColor).title | translate}}: {{value | execPipes:gridColumn:'pipes' | async}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.StatusSwitch"
                       [field]="(gridColumn | asStatusSwitch).field"
                       [filterable]="(gridColumn | asStatusSwitch).filterable"
                       [sortable]="(gridColumn | asStatusSwitch).sortable"
                       [minResizableWidth]="gridColumn.minWidth"
                       [title]="(gridColumn | asStatusSwitch).title | translate"
                       [width]="75">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-status-switch-column-cell [config]="gridColumn | asStatusSwitch"
                                      [dataItem]="dataItem"></ap-status-switch-column-cell>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <ap-status-switch-column-group-header [value]="value"></ap-status-switch-column-group-header>
      </ng-template>
      <ng-template *ngIf="(gridColumn | asStatusSwitch).filterable"
                   kendoGridFilterMenuTemplate
                   let-filter="filter"
                   let-filterService="filterService">
        <ap-status-switch-column-filter
          [config]="gridColumn | asStatusSwitch"
          [currentFilter]="filter"
          [filterService]="filterService"
          [items]="items">
        </ap-status-switch-column-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column-group *ngIf="gridColumn.type === ApDynGridColumnControlType.ColumnGroup"
                             [headerClass]="'ap-grid-group-header'"
                             [headerStyle]="gridColumn.headerStyle"
                             [hidden]="(gridColumn | isHidden:mapViewState$:widthHiddenColumns$) | async"
                             [style]="gridColumn.style"
                             [title]="(gridColumn | asGroup).title | async | translate"
                             [width]="gridColumn.width">

      <ng-template *ngIf="!!(gridColumn | asGroup).headerButtons" kendoGridHeaderTemplate>
        <div [style]="{display: 'flex'}">
          <button (click)="previousClicked(gridColumn)"
                  [class]="'column-group-button'"
                  [disabled]="(gridColumn | asGroup).headerButtons.previousDisabled | async"
                  [icon]="'arrow-left'"
                  kendoButton>
          </button>
          <ap-responsive-text [key]="(gridColumn | asGroup).title | async"></ap-responsive-text>
          <button (click)="nextClicked(gridColumn)"
                  [class]="'column-group-button'"
                  [disabled]="(gridColumn | asGroup).headerButtons.nextDisabled | async"
                  [icon]="'arrow-right'"
                  kendoButton>
          </button>
        </div>
      </ng-template>

      <ng-container *ngFor="let groupColumn of (gridColumn | asGroup).groupColumns">
        <kendo-grid-column *ngIf="groupColumn.type === ApDynGridColumnControlType.Status"
                           [autoSize]="false"
                           [class.small-column]="true"
                           [field]="(groupColumn | asStatus).field"
                           [filterable]="(groupColumn | asStatus).filterable"
                           [sortable]="(groupColumn | asStatus).sortable"
                           [headerStyle]="groupColumn.headerStyle"
                           [hidden]="(groupColumn.hide.mapHide && (mapViewState$ | async) !== mapViewModes.HIDE) || (groupColumn.hide.selfHide | async)"
                           [minResizableWidth]="30"
                           [style]="groupColumn.style"
                           [title]="(groupColumn | asStatus).title | translate"
                           [width]="30">
          <ng-template *ngIf="(groupColumn | asStatus).filterable"
                       kendoGridFilterMenuTemplate
                       let-filter="filter"
                       let-filterService="filterService">
            <ap-status-column-filter
              [config]="groupColumn | asStatus"
              [currentFilter]="filter"
              [filterService]="filterService"
              [items]="items"
            ></ap-status-column-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            <ap-status-column-header [config]="groupColumn | asStatus"></ap-status-column-header>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <ap-status-column-cell [config]="groupColumn | asStatus" [dataItem]="dataItem"></ap-status-column-cell>
          </ng-template>
          <ng-template kendoGridGroupHeaderTemplate let-field="field" let-group="group">
            <ap-status-column-group-filter [config]="groupColumn | asStatus" [field]="field" [group]="group">
            </ap-status-column-group-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="groupColumn.type === ApDynGridColumnControlType.ClassColor"
                           [field]="(groupColumn | asClassColor).key"
                           [filterable]="(groupColumn | asClassColor).filterable"
                           [sortable]="(groupColumn | asClassColor).sortable"
                           [title]="(groupColumn | asClassColor).title | translate"
                           [width]="groupColumn.width">
          <ng-template *ngIf="(groupColumn | asClassColor).filterable"
                       kendoGridFilterMenuTemplate
                       let-filter="filter"
                       let-filterService="filterService">
            <ap-class-color-column-filter
              [config]="groupColumn | asClassColor"
              [currentFilter]="filter"
              [filterService]="filterService"
              [items]="items">
            </ap-class-color-column-filter>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <ap-class-color-column-cell [config]="groupColumn | asClassColor"
                                        [dataItem]="dataItem"></ap-class-color-column-cell>
          </ng-template>
          <ng-template kendoGridGroupHeaderTemplate let-value="value">
            {{(groupColumn | asClassColor).title | translate}}: {{value | execPipes:groupColumn:'pipes' | async}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="groupColumn.type === ApDynGridColumnControlType.Property"
                           [field]="(groupColumn | asProperty).field"
                           [filter]="((groupColumn | asProperty).usedKendoDefaultFilter)"
                           [filterable]="(groupColumn | asProperty).filterable"
                           [sortable]="(groupColumn | asProperty).sortable"
                           [format]="(groupColumn | asProperty).format | getKendoDateFormat"
                           [headerStyle]="groupColumn.headerStyle"
                           [hidden]="(groupColumn | isHidden:mapViewState$:widthHiddenColumns$) | async"
                           [style]="groupColumn.style"
                           [title]="(groupColumn | asProperty).title | translate"
                           [width]="groupColumn.width">
          <ng-template
            *ngIf="(groupColumn | asProperty).filterable && !(groupColumn | asProperty).usedKendoDefaultFilter"
            kendoGridFilterMenuTemplate
            let-dataItem="dataItem"
            let-filter="filter"
            let-filterService="filterService">
            <ap-property-column-filter
              [config]="groupColumn | asProperty"
              [currentFilter]="filter"
              [dataItem]="dataItem"
              [filterService]="filterService"
              [items]="items"
            ></ap-property-column-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            <ap-property-column-header [config]="groupColumn | asProperty"></ap-property-column-header>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <ap-property-column-cell [config]="groupColumn | asProperty"
                                     [dataItem]="dataItem"></ap-property-column-cell>
          </ng-template>
          <ng-template kendoGridGroupHeaderTemplate let-value="value">
            {{(groupColumn | asProperty).title | translate}}:
            {{((groupColumn | asProperty).format ?
            (value | date: ((groupColumn | asProperty).format | getKendoDateFormat)) : value)
            | execPipes:gridColumn:'pipes' | async}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="groupColumn.type === ApDynGridColumnControlType.Template"
                           [headerStyle]="groupColumn.headerStyle"
                           [hidden]="(groupColumn | isHidden:mapViewState$:widthHiddenColumns$) | async"
                           [sortable]="gridColumn.sortable"
                           [style]="groupColumn.style"
                           [width]="groupColumn.width">
          <ng-template [ngIf]="(groupColumn | asTemplate).header" kendoGridHeaderTemplate>
            <ap-template-column-header [config]="groupColumn | asTemplate"></ap-template-column-header>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <ap-template-column-cell [config]="groupColumn | asTemplate"
                                     [dataItem]="dataItem"></ap-template-column-cell>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
    </kendo-grid-column-group>

    <kendo-grid-command-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Edit"
                               [headerStyle]="gridColumn.headerStyle"
                               [style]="gridColumn.style"
                               [width]="commandColumnWidth">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-edit-column-cell [canInlineEdit]="canInlineEdit" [config]="gridColumn | asEdit"
                             [dataItem]="dataItem"></ap-edit-column-cell>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-command-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Button"
                               [width]="commandColumnWidth">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-button-cell-column [config]="gridColumn | asButton" [dataItem]="dataItem"></ap-button-cell-column>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-command-column *ngIf="gridColumn.type === ApDynGridColumnControlType.ButtonGroup"
                               [width]="32 * (gridColumn | asButtonGroup).buttons.length + 24">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-button-group-column-cell [config]="gridColumn | asButtonGroup"
                                     [dataItem]="dataItem"></ap-button-group-column-cell>
      </ng-template>
    </kendo-grid-command-column>
  </ng-container>

  <!-- hidden columns for Agricon users -->
  <ng-container *ngIf="_agriconShowHiddenProperties">
    <kendo-grid-column *ngFor="let property of _agriconHiddenProperties"
                       [field]="property"
                       [title]="property"
                       [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ap-responsive-text (dblclick)="copyToClipboard(dataItem, property)"
                            [text]="stringify(dataItem | getProperty:(property))"></ap-responsive-text>
      </ng-template>
    </kendo-grid-column>
  </ng-container>

  <!-- Pager -->

  <ng-template *ngIf="!pager || pager.type === ApDynGridPagerControlType.None" kendoPagerTemplate>
  </ng-template>

  <ng-template *ngIf="pager && pager.type !== ApDynGridPagerControlType.None" kendoPagerTemplate>
    <ap-grid-wizard (applyClick)="updateSelection($event)"
                    (cancelClick)="clearSelection()"
                    (pageSizeChanged)="onPageSizeChanged($event)"
                    *ngIf="pager.type === ApDynGridPagerControlType.Wizard"
                    [data]="gridPaging"
                    [formConfig]="(pager | asWizard).dynGridWizardFormConfig"
                    [showSelectedKeys]="true">
      <ng-container *ngFor="let section of (pager | asWizard).sections; let i = index">
        <div [sizeMd]="(pager | asWizard).sections[1].type !== ApDynGridPagerWizardSectionType.BreadcrumbsStepper
              ? '33-100' : i === 1 ? '50-100' : '25-100'"
             [style.text-align]="section.align"
             apResponsive>
          <ng-container *ngIf="section.type === ApDynGridPagerWizardSectionType.None">
            &nbsp;
          </ng-container>
          <ng-container *ngIf="section.type === ApDynGridPagerWizardSectionType.Button">
            <ng-container>
              <ng-container *ngIf="i === 2">
                <button (click)="button.callback.emit(button.value)"
                        *ngFor="let button of (section | asWizardButton).iconButtons"
                        [aptooltip]="button.tooltip | translate"
                        [disabled]="((section | asWizardButton).active$ | async) !== true"
                        [id]="button.id"
                        [perpetual]="true"
                        [style.height]="'32px'"
                        [style.margin-right]="'4px'"
                        [style.padding]="'0'"
                        [style.width]="'32px'"
                        kendoButton>
                  <span [class]="'k-icon k-icon-64 ' + button.icon"></span>
                </button>
              </ng-container>
              <button
                (click)="this.asWizardButtonPipe.transform(section).callback.emit(this.asWizardButtonPipe.transform(section).value)"
                [class]="'k-button k-primary button-important'"
                [disabled]="((section | asWizardButton).active$ | async) !== true"
                [id]="(section | asWizardButton).id"
                [primary]="true"
                kendoButton>
                <ap-responsive-text [key]="(section | asWizardButton).key"></ap-responsive-text>
              </button>
              <ng-container *ngIf="i === 0">
                <button (click)="button.callback.emit(button.value)"
                        *ngFor="let button of (section | asWizardButton).iconButtons"
                        [aptooltip]="button.tooltip | translate"
                        [disabled]="((section | asWizardButton).active$ | async) !== true"
                        [id]="button.id"
                        [perpetual]="true"
                        [style.height]="'32px'"
                        [style.margin-left]="'4px'"
                        [style.padding]="'0'"
                        [style.width]="'32px'"
                        kendoButton>
                  <span [class]="'k-icon k-icon-64 ' + button.icon"></span>
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
          <ap-breadcrumbs-steper *ngIf="section.type === ApDynGridPagerWizardSectionType.BreadcrumbsStepper"
                                 [columns]="(section | asWizardBreadcrumbsStepper).columns"
                                 [selected]="(section | asWizardBreadcrumbsStepper).selected">
          </ap-breadcrumbs-steper>
          <ng-container *ngIf="section.type === ApDynGridPagerWizardSectionType.Export">
            <button *ngIf="(section | asWizardExport).pdf"
                    [aptooltip]="'Global__Save_PDF' | translate"
                    [id]="'KendoGridPDFExport'"
                    [perpetual]="true"
                    kendoButton kendoGridPDFCommand>
              <span [class]="'k-icon k-i-pdf k-icon-64'"></span>
            </button>
            <ng-container>
              <button (click)="this.asWizardExportPipe.transform(section).xlsCallback.emit()"
                      *ngIf="(section | asWizardExport).xlsCallback !== null"
                      [aptooltip]="'Global__Save_EXCEL' | translate"
                      [id]="'KendoGridXLSExport'" [perpetual]="true"
                      kendoButton>
                <span [class]="'k-icon k-i-xls k-icon-64'"></span>
              </button>
              <button *ngIf="(section | asWizardExport).xlsCallback === null"
                      [aptooltip]="'Global__Save_EXCEL' | translate"
                      [id]="'KendoGridXLSExport'"
                      [perpetual]="true"
                      kendoButton kendoGridExcelCommand>
                <span [class]="'k-icon k-i-xls k-icon-64'"></span>
              </button>
            </ng-container>
            <ng-container *ngFor="let customExport of (section | asWizardExport).customExports">
              <button (click)="customExport.emitter.emit(
                      customExport.type === 'selected' ? this.gridPaging.selectedItems : this.items)"
                      [aptooltip]="customExport.tooltip | translate"
                      [disabled]="customExport.disabled$ ? (customExport.disabled$ | async) : false"
                      [perpetual]="true"
                      kendoButton>
                <span [class]="'k-icon custom ' + customExport.icon + ' k-icon-64'"></span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ap-grid-wizard>
    <ap-grid-pager #gridPager
                   (applyClicked)="this.asPagerPipe.transform(pager).apply ? this.asPagerPipe.transform(pager).apply.emit($event) : pagerApplyClicked($event)"
                   (deleteClicked)="this.asPagerPipe.transform(pager).delete.emit($event)"
                   (pageSizeChanged)="onPageSizeChanged($event)"
                   (selectedColumnChange)="gridPagerSelectionChange($event)"
                   (selectedValueChange)="gridPagerSelectionValueChange($event)"
                   *ngIf="pager && pager.type === ApDynGridPagerControlType.Pager"
                   [columnItemDisabled]="itemDisabled.bind(this)"
                   [columns]="(pager | asPager).columns"
                   [formConfig]="pagerFormConfig"
                   [formDefaultValue]="formDefaultValue$ | async"
                   [hasPermission]="(pager | asPager).hasPermission"
                   [iconButtons]="(pager | asPager).iconButtons"
                   [lowerButtons]="(pager | asPager).lowerButtons"
                   [itemCount]="gridPaging.items ? gridPaging.items.length : 0"
                   [objects]="(pager | asPager).listMassEditing"
                   [pageSize]="gridPaging.pageSize"
                   [preselect]="(pager | asPager).preselect"
                   [selectedKeys]="gridPaging.selectedKeys"
                   [showOnlyValues]="(pager | asPager).showOnlyValues"
                   [showWithoutSelection]="(pager | asPager).showWithoutSelection"
                   [valueItemDisabled]="itemDisabled.bind(this)">
    </ap-grid-pager>
  </ng-template>

  <!-- Export -->
  <kendo-grid-excel *ngIf="_agriconShowHiddenProperties"
                    [creator]="'Agricon GmbH'" [fetchData]="gridPaging.getSelectedDataForExcel"
                    [fileName]="'Export.xlsx'"></kendo-grid-excel>

  <kendo-grid-excel *ngIf="!_agriconShowHiddenProperties" [creator]="'Agricon GmbH'"
                    [fetchData]="gridPaging.getSelectedDataForExcel"
                    [fileName]="(xlsExportFileName ? xlsExportFileName : exportFileName) + '.xlsx'">
    <ng-container *ngFor="let gridColumn of columns">
      <kendo-excelexport-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Status && gridColumn.xlsExport"
                                [field]="(gridColumn | asStatus).field + 'Desc'"
                                [title]="'Global__Status' | translate"></kendo-excelexport-column>
      <kendo-excelexport-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Property && gridColumn.xlsExport"
                                [field]="(gridColumn | asStatus).field"
                                [title]="(gridColumn | asStatus).title | translate"></kendo-excelexport-column>
      <kendo-excelexport-column-group
        *ngIf="gridColumn.type === ApDynGridColumnControlType.ColumnGroup && gridColumn.xlsExport"
        [title]="(gridColumn | asGroup).title | async | translate">
        <ng-container *ngFor="let groupColumn of (gridColumn | asGroup).groupColumns">
          <kendo-excelexport-column
            *ngIf="groupColumn.type === ApDynGridColumnControlType.Property && groupColumn.xlsExport"
            [field]="(groupColumn | asProperty).field"
            [title]="(groupColumn | asProperty).title | translate"></kendo-excelexport-column>
        </ng-container>
      </kendo-excelexport-column-group>
      <kendo-excelexport-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Color && gridColumn.xlsExport"
                                [field]="(gridColumn | asColor).field"
                                [title]="(gridColumn | asColor).title | translate">
      </kendo-excelexport-column>
    </ng-container>
  </kendo-grid-excel>

  <kendo-grid-pdf [fileName]="(pdfExportFileName ? pdfExportFileName : exportFileName) + '.pdf'"
                  [landscape]="true"
                  [repeatHeaders]="true"
                  [scale]="0.6"
                  paperSize="A4">
    <kendo-grid-pdf-margin bottom="2cm" left="1cm" right="1cm" top="2cm"></kendo-grid-pdf-margin>
    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="ap-export-pdf-page-template">
        <div class="ap-export-pdf-page-template-header">
          <div style="font-size: 1.5rem; color:black; padding-bottom: 0.5rem; font-weight: bolder">
            <ng-container *ngIf="pdfExportTitle !== ''">
              {{pdfExportTitle | translate}}
            </ng-container>
            <ng-container *ngIf="pdfExportTitle === ''">
              &nbsp;
            </ng-container>
          </div>
          <div class="ap-form-block" style="font-size: 0.85rem; color:black; padding-bottom: 0.5rem">
            <div apResponsive sizeMd="50-100">
              {{'Global__Company' | translate}}: {{(farm$ | async).FarmName}}
            </div>
            <div apResponsive sizeMd="50-100">
              {{'Global__CustomerNum' | translate}}: {{(farm$ | async).CustomerNumber}}
            </div>
          </div>
          <div style="position:absolute; right: 30px;top:0px;">
            <img alt="Agriport" src="../../../assets/svg/agriport-logo.svg" style="width:150px">
          </div>
        </div>
        <div class="ap-export-pdf-page-template-footer">
          {{'AgriConnect_Page' | translate}} {{ pageNum }} {{'kendo.grid.pagerOf' | translate}} {{ totalPages }}
          <div style="float: right">
            {{'Global__CreatedAt' | translate}}
            : {{(null | getLocalDate).format((languageStore.SelectedLanguage$ | async).DateFormat)}}
          </div>
        </div>
      </div>
    </ng-template>
    <ng-container *ngFor="let gridColumn of columns">

      <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Status"
                         [field]="(gridColumn | asStatus).field"
                         [headerStyle]="gridColumn.headerStyle"
                         [style]="gridColumn.style"
                         [title]="(gridColumn | asStatus).title | translate"
                         [width]="gridColumn.width">
        <ng-template kendoGridHeaderTemplate>
          <ap-status-column-header [config]="gridColumn | asStatus"></ap-status-column-header>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ap-status-column-cell [config]="gridColumn | asStatus" [dataItem]="dataItem"></ap-status-column-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.ClassColor && gridColumn.pdfExport"
                         [title]="(gridColumn | asClassColor).title | translate"
                         [width]="gridColumn.width">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ap-class-color-column-cell [config]="gridColumn | asClassColor" [dataItem]="dataItem"
                                      [inGroup]="false"></ap-class-color-column-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Property && gridColumn.pdfExport"
                         [field]="(gridColumn | asProperty).field"
                         [format]="(gridColumn | asProperty).format | getKendoDateFormat"
                         [headerStyle]="gridColumn.headerStyle"
                         [style]="gridColumn.style"
                         [title]="(gridColumn | asProperty).title | translate"
                         [width]="gridColumn.width">
        <ng-template kendoGridHeaderTemplate>
          <ap-responsive-text [key]="(gridColumn | asProperty).title"></ap-responsive-text>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ap-property-column-cell [config]="gridColumn | asProperty" [dataItem]="dataItem"></ap-property-column-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column-group *ngIf="gridColumn.type === ApDynGridColumnControlType.ColumnGroup"
                               [headerClass]="'ap-grid-group-header'"
                               [headerStyle]="gridColumn.headerStyle"
                               [style]="gridColumn.style"
                               [title]="(gridColumn | asGroup).title | async | translate"
                               [width]="gridColumn.width">
        <ng-container *ngFor="let groupColumn of (gridColumn | asGroup).groupColumns">
          <kendo-grid-column *ngIf="groupColumn.type === ApDynGridColumnControlType.ClassColor && groupColumn.pdfExport"
                             [title]="(groupColumn | asClassColor).title | translate"
                             [width]="groupColumn.width">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-class-color-column-cell [config]="groupColumn | asClassColor" [dataItem]="dataItem"
                                          [inGroup]="true"></ap-class-color-column-cell>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column *ngIf="groupColumn.type === ApDynGridColumnControlType.Property && groupColumn.pdfExport"
                             [field]="(groupColumn | asProperty).field"
                             [format]="(groupColumn | asProperty).format | getKendoDateFormat"
                             [headerStyle]="groupColumn.headerStyle"
                             [style]="groupColumn.style"
                             [title]="(groupColumn | asProperty).title | translate"
                             [width]="groupColumn.width">
            <ng-template kendoGridHeaderTemplate>
              <ap-property-column-header [config]="groupColumn | asProperty"></ap-property-column-header>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <ap-property-column-cell [config]="groupColumn | asProperty"
                                       [dataItem]="dataItem"></ap-property-column-cell>
            </ng-template>
          </kendo-grid-column>
        </ng-container>
      </kendo-grid-column-group>

      <kendo-grid-column *ngIf="gridColumn.type === ApDynGridColumnControlType.Color && gridColumn.pdfExport"
                         [field]="(gridColumn | asColor).field"
                         [filterable]="(gridColumn | asColor).filterable"
                         [title]="(gridColumn | asColor).title"
                         [width]="gridColumn.width">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ap-color-column-cell [config]="gridColumn | asColor" [dataItem]="dataItem"></ap-color-column-cell>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
  </kendo-grid-pdf>

  <ng-template [ngIf]="details">
    <ng-template [kendoGridDetailTemplateShowIf]="details.showIf.bind(details)" kendoGridDetailTemplate
                 let-dataItem>
      <ap-dyngrids *ngIf="details.type === ApDynGridDetailsConfigType.Grid"
                   [class]="'ap-details-grid'"
                   [checkboxDisabled]="(details | asDetailsGrid).checkboxDisabled"
                   [columns]="(details | asDetailsGrid).columns"
                   [groupable]="(details | asDetailsGrid).groupable"
                   [items]="dataItem[(details | asDetailsGrid).field]"
                   [sortable]="sortable"
                   [scrollable]="'scrollable'"
                   [level]="level + 1"
                   [pager]="(details | asDetailsGrid).pager"
                   [selectable]="(details | asDetailsGrid).selectable">
      </ap-dyngrids>
    </ng-template>
  </ng-template>

  <!-- Hidden context menu for agricon Strg-Alt-Space-->
</kendo-grid>
<kendo-contextmenu (select)="grid.saveAsExcel()" *ngIf="_agriconShowHiddenProperties" [target]="grid.wrapper">
  <ng-template kendoMenuItemTemplate let-item="item">
    <div style="padding: 10px;">
      {{item.text}}
    </div>
  </ng-template>
  <kendo-menu-item [text]="'Global__Save_EXCEL' | translate"></kendo-menu-item>
</kendo-contextmenu>

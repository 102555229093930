import {AfterContentInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {
  ParameterValuesHistoryStore
}                                                                 from '../../stores/parameter/parameter.values.history.store';
import {
  ApDynComponentComponent
}                                                                 from '../../ap-dyncomponent/ap-dyncomponent.component';
import {
  ApDynGridColumnConfigBase
}                                                                 from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPagerConfigBase
}                                                                 from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {
  ApDynGridPropertyColumnConfig
}                                                                 from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {ApPermissions}                                            from 'invoker-transport';
import {
  ApDynGridPagerWizardConfig,
  ApDynGridPagerWizardSection
}                                                                 from '../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {MapViewStore}                                             from '../../stores/layout/mapview.store';
import {GetPermissionPipe}                                        from '../../ap-permission/pipes/get-permission.pipe';
import {
  ParameterDefinitionStore
}                                                                 from '../../stores/parameter/parameter.definition.store';
import {TranslationStore}                                         from '../../stores/translation/translation.store';
import {GetBooleanPipe}                                           from '../../ap-utils';
import {
  ApDynGridImageColumnConfig
}                                                                 from '../../ap-dyngrids/config/ap-dyn-grid-image-column-config';
import {
  ApDynGridEditColumnConfig
}                                                                 from '../../ap-dyngrids/config/ap-dyn-grid-edit-column-config';
import {LanguageStore}                                            from '../../stores/translation/language.store';
import {Subscription}                                             from 'rxjs';
import {
  ApDynGridStatusColumnConfig
}                                                                 from '../../ap-dyngrids/config/ap-dyn-grid-status-column-config';
import * as moment                                                from 'moment';
import IParameterValueHistory = Data.Parameter.IParameterValueHistory;
import IGuid = System.IGuid;
import OperationType = Data.Parameter.Enums.OperationType;
import ParameterType = Data.Parameter.Enums.ParameterType;
import IParameterDefinition = Data.Parameter.IParameterDefinition;

@Component({
  selector: 'ap-parameter-value-history',
  template: `
    <ap-dyncomponent [selectable]="{checkboxOnly: false, mode: 'single', enabled: true}"
                     [columns]="columns"
                     [items]="items"
                     [loading$]="parameterValueHistoryLoading$"
                     [pager]="pager"
                     [groupable]="true"
    ></ap-dyncomponent>`
})
export class ApParameterValueHistoryComponent implements AfterContentInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) dynComponent: ApDynComponentComponent;
  @Input() refId: IGuid;
  columns: ApDynGridColumnConfigBase[] = [];
  pager: ApDynGridPagerConfigBase;
  parameterValueHistoryLoading$;
  items: any[] = [];

  private _subscriptions: Array<Subscription> = [];

  constructor(private parameterValuesHistoryStore: ParameterValuesHistoryStore,
              private parameterDefinitionStore: ParameterDefinitionStore,
              private translationStore: TranslationStore,
              private mapViewStore: MapViewStore,
              private permissionPipe: GetPermissionPipe,
              private booleanPipe: GetBooleanPipe,
              private languageStore: LanguageStore) {
  }

  ngAfterContentInit(): void {
    if (this.refId !== undefined) {
      this.parameterValuesHistoryStore.loadParameterValueHistory(this.refId);
    }
    this.parameterValueHistoryLoading$ = this.parameterValuesHistoryStore.Listen(s => s.loading);
    this._subscriptions.push(this.parameterValuesHistoryStore.Listen(s => s.data).subscribe(hist => {
        if (!hist) {
          return;
        }
        this.items = hist.map((h: IParameterValueHistory) => {
            const parameterDefinition = this.parameterDefinitionStore.getParameterDefinitionById(h.DefinitionId);
            const name = this.translationStore.FindTranslationForSelectedLanguage(parameterDefinition.TranslationKey);
            const status = h.OperationType === OperationType.Insert ? 1 : h.OperationType === OperationType.Update ? 0 : -1;
            const statusClass = h.OperationType === OperationType.Insert ? 'ap-status-green' : h.OperationType === OperationType.Update ? 'ap-status-yellow' : 'ap-status-red';
            const statusDesc = h.OperationType === OperationType.Insert ? 'Global_Inserted' : h.OperationType === OperationType.Update ? 'Global_Updated' : 'Global_Deleted';
            let oldValue = '';
            let newValue = '';
            switch (parameterDefinition.ParameterType) {
              case ParameterType.Text:
                oldValue = h.OldValueString;
                newValue = h.NewValueString;
                break;
              case ParameterType.Boolean:
                oldValue = this._booleanConverter(h.OldValueBoolean);
                newValue = this._booleanConverter(h.NewValueBoolean);
                break;
              case ParameterType.Number:
                oldValue = h.OldValueNumber.toString();
                newValue = h.NewValueNumber.toString();
                break;
              case ParameterType.List:
                oldValue = this._listConverter(h.OldValueListKey, parameterDefinition);
                newValue = this._listConverter(h.NewValueListKey, parameterDefinition);
                break;
            }
            return {
              Id: h.Id,
              SourceItem: h,
              Status: status,
              StatusClass: statusClass,
              StatusDesc: statusDesc,
              Name: name,
              OldValue: h.OperationType !== OperationType.Insert ? oldValue : '-',
              NewValue: h.OperationType !== OperationType.Delete ? newValue : '-',
              Info: parameterDefinition.Description,
              Image: '../../assets/img/info.png',
              ToolTip: this._createTooltip(h),
              Size: '24px',
            };
          }
        );
        this.gridBuilder();
      }
    ));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }

  private gridBuilder(): void {
    this.permissionPipe.transform(ApPermissions.EDIT_TRANSLATIONS).subscribe(() => {
      this.columns = [
        new ApDynGridStatusColumnConfig({
          field: 'Status', class: 'StatusClass', description: 'StatusDesc'
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'Name',
          title: 'Global__Name',
          headerFilterable: true,
          filterable: true,
          width: 180,
          sortIndex: 0,
          sortDesc: true,
          groupIndex: 0,
          groupDesc: false
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'OldValue',
          title: 'Global_Old_Value',
          filterable: true,
          width: 100,
          headerFilterable: true,
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'NewValue',
          title: 'Global_New_Value',
          filterable: true,
          width: 100,
          headerFilterable: true,
        }),
        new ApDynGridImageColumnConfig({
          title: 'Global__Info',
          imageUrl: 'Image',
          width: 50,
          tooltip: 'ToolTip',
          imageHeight: 'Size',
          imageWidth: 'Size'
        }),
        new ApDynGridEditColumnConfig({disabled: true})
      ];
    });
    this.pager = new ApDynGridPagerWizardConfig(
      new ApDynGridPagerWizardSection(),
      new ApDynGridPagerWizardSection(),
      new ApDynGridPagerWizardSection()
    );
  }

  private _listConverter(id: number, definition: IParameterDefinition): string {
    const found = definition.ListValues.Find(_ => _.Id === id);
    if (!found) {
      return '';
    }
    return found.Name;
  }

  private _booleanConverter(value: any): string {
    const retVal = this.booleanPipe.transform(value);
    if (retVal) {
      return this.translationStore.FindTranslationForSelectedLanguage('Global__Yes');
    }
    return this.translationStore.FindTranslationForSelectedLanguage('Global__No');
  }

  private _createTooltip(parameterValueHistory: IParameterValueHistory): string {
    const createdBy = parameterValueHistory.CreatedBy !== null ? parameterValueHistory.CreatedByName : '-';
    const createdAt = parameterValueHistory.CreatedAt !== null ? moment(parameterValueHistory.CreatedAt).format(this.languageStore.CurrentDateTimeFormat) : '-';

    const changedBy = parameterValueHistory.ChangedBy !== null ? parameterValueHistory.ChangedByName : '-';
    const changedAt = parameterValueHistory.ChangedAt !== null ? moment(parameterValueHistory.ChangedAt).format(this.languageStore.CurrentDateTimeFormat) : '-';

    const deletedBy = parameterValueHistory.DeletedBy !== null ? parameterValueHistory.DeletedByName : '-';
    const deletedAt = parameterValueHistory.DeletedAt !== null ? moment(parameterValueHistory.DeletedAt).format(this.languageStore.CurrentDateTimeFormat) : '-';

    return '<table style="width: 270px;">\n' +
      '<tbody>\n' +
      '<tr>\n' +
      '<td style="text-align: left;"><strong>' + this.translationStore.FindTranslationForSelectedLanguage('Global_Created_By') + '</strong></td>\n' +
      '<td style="width: 174px; text-align: left;">' + createdBy + '</td>\n' +
      '</tr>\n' +
      '<tr>\n' +
      '<td style="width: 104px; text-align: left;"><strong>' + this.translationStore.FindTranslationForSelectedLanguage('Global_Created_At') + '</strong></td>\n' +
      '<td style="width: 174px; text-align: left;">' + createdAt + '</td>\n' +
      '</tr>\n' +
      '<tr>\n' +
      '<td style="width: 104px; text-align: left;"><strong>' + this.translationStore.FindTranslationForSelectedLanguage('Global_Changed_By') + '</strong></td>\n' +
      '<td style="width: 174px; text-align: left;">' + changedBy + '</td>\n' +
      '</tr>\n' +
      '<tr>\n' +
      '<td style="width: 104px; text-align: left;"><strong>' + this.translationStore.FindTranslationForSelectedLanguage('Global_Changed_At') + '</strong></td>\n' +
      '<td style="width: 174px; text-align: left;">' + changedAt + '</td>\n' +
      '</tr>\n' +
      '<tr>\n' +
      '<td style="width: 104px; text-align: left;"><strong>' + this.translationStore.FindTranslationForSelectedLanguage('Global_Deleted_By') + '</strong></td>\n' +
      '<td style="width: 174px; text-align: left;">' + deletedBy + '</td>\n' +
      '</tr>\n' +
      '<tr>\n' +
      '<td style="width: 104px; text-align: left;"><strong>' + this.translationStore.FindTranslationForSelectedLanguage('Global_Deleted_At') + '</strong></td>\n' +
      '<td style="width: 174px; text-align: left;">' + deletedAt + '</td>\n' +
      '</tr>\n' +
      '</tbody>\n' +
      '</table>';
  }
}

import {IStateStore}                                               from '../../ap-interface';
import {Store}                                                     from '../index';
import {Injectable}                                                from '@angular/core';
import {NotifyRecieveMessage, NotifySaveMessage} from 'invoker-transport';
import {APP_CONFIGURATION}                                         from '../../ap-core/config';
import IApValidationResult = Data.Api.Validation.IApValidationResult;
import ApValidationLevel = Data.Api.Validation.ApValidationLevel;
import {ApSignalrService}                                          from '../../ap-core/services/ap-signalr.service';

interface INotifyStore extends IStateStore<IApValidationResult> {
  displayMessages: IApValidationResult[];
}

@Injectable({providedIn: 'root'})
export class NotifyStore extends Store<INotifyStore> {

  private maxNotifications = APP_CONFIGURATION.MaxNotifications;

  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loading: false,
      loaded: false,
      displayMessages: [],
    });
    backend.registerObservable(NotifySaveMessage).subscribe(d => {
      if (d.Data === true) {
        this.showSaveSuccessMessage();
      } else {
        this.showSaveFailMessage();
      }
    });
    this.backend.registerObservable(NotifyRecieveMessage).subscribe(d => this.addMessage(d.Data));
  }

  public getNotify(): IApValidationResult[] {
    return super.Listen(s => s.data).getValue();
  }

  public getDisplayMessages(): IApValidationResult[] {
    return super.Listen(s => s.displayMessages).getValue();
  }

  public showSaveSuccessMessage(): void {
    const successMessage = {
      DisplayTimeout: 5,
      Level: ApValidationLevel.Success,
      ErrorKey: 'Global__Successfully_Saved'
    } as IApValidationResult;
    this.addMessage(successMessage);
  }

  public showSaveFailMessage(): void {
    const failMessage = {
      DisplayTimeout: 5,
      Level: ApValidationLevel.Error,
      ErrorKey: 'Global__SaveDataFailed'
    } as IApValidationResult;
    this.addMessage(failMessage);
  }

  public addMessage(note: IApValidationResult): void {
    if (this.getNotify().FindIndex(d => {
      return d.ErrorKey === note.ErrorKey;
    }) < 0) {
      this.add(note);
    }
  }

  /**
   * Removes the given notification from notify store.
   */
  public removeMessage(note: IApValidationResult): void {
    this.remove(note);
  }

  private add(p: IApValidationResult): void {
    const found = this.getNotify().Find(i => i?.ErrorKey + i?.UserId === p?.ErrorKey + p?.UserId);
    if (found) {
      found.Counter++;
      const data = [...this.getNotify().FindAll(i => i?.ErrorKey + i?.UserId !== p?.ErrorKey + p?.UserId), found];
      super.Mutate(s => s.data, () => data);
      const displayMessages = this.getNotify().slice(0, this.maxNotifications);
      super.Mutate(s => s.displayMessages, () => displayMessages);
      return;
    }
    super.Mutate(s => s.data, () => [...this.getNotify(), p]);
    super.Mutate(s => s.displayMessages, () => this.getNotify().slice(0, this.maxNotifications));
  }

  private remove(p: IApValidationResult): void {
    super.Mutate(s => s.data, () => this.getNotify().FindAll(i => i.ErrorKey + i.UserId !== p.ErrorKey + p.UserId));
    super.Mutate(s => s.displayMessages, () => this.getNotify().slice(0, this.maxNotifications));
  }
}

import {NgModule}        from '@angular/core';
import {CommonModule}    from '@angular/common';
import {ApHelpRoutes}    from './ap-help.routes';
import {ApHelpComponent} from './components/ap-help.component';
import {ApIconModule}    from '../icons/ap-icon.module';

import {EditorModule}        from '@progress/kendo-angular-editor';
import {ToolBarModule}       from '@progress/kendo-angular-toolbar';
import {ApTranslationModule} from '../translation/ap-translation.module';
import {UploadModule}        from '@progress/kendo-angular-upload';


@NgModule({
  declarations: [ApHelpComponent],
  exports: [ApHelpComponent],
  imports: [ApHelpRoutes, CommonModule, ApIconModule, EditorModule, ToolBarModule, ApTranslationModule, UploadModule],
  providers: []
})
export class ApHelpModule {
}

import {Pipe, PipeTransform}       from '@angular/core';
import {ApDynGridColumnConfigBase} from '../config/ap-dyn-grid-column-config-base';
import {of}                        from 'rxjs';

@Pipe({
  name: 'execPipes',
})
export class ExecPipesPipe implements PipeTransform {
  transform(value: any, ...args: [ApDynGridColumnConfigBase, string]): any {
    const columnInfo = args[0];
    const pipes = args[0][args[1]];
    let res = value;
    for (const p of pipes) {
      res = p.pipe.transform(res, ...p.args);
    }
    if (!!res && (typeof res.subscribe === 'function' || typeof res.then === 'function')) {
      return res;
    }
    return of(res);
  }
}

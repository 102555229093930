import {IAction, IApInvokerArguments} from '../../interfaces';
import IFieldNutrientsDistribution = Data.Nutrients.IFieldNutrientsDistribution;
import ISampleMethod = Data.Nutrients.ISampleMethod;
import ISampleRegion = Data.Nutrients.ISampleRegion;

export enum FieldNutrientActions {
  FIELD_NUTRIENT_DISTRIBUTIONS_LOAD = '[field] nutrient distributions load',
  FIELD_NUTRIENT_DISTRIBUTIONS_LOAD_SUCCESS = '[field] nutrient distributions load success',
  FIELD_NUTRIENT_DISTRIBUTIONS_REFRESH = '[field] nutrient distributions refresh',
  FIELD_NUTRIENT_DISTRIBUTIONS_LOAD_FAIL = '[field] nutrient distributions load fail',
  FIELD_NUTRIENT_SAMPLE_METHOD_LOAD = '[sample] methods load',
  FIELD_NUTRIENT_SAMPLE_METHOD_LOAD_SUCCESS = '[sample] methods load success',
  FIELD_NUTRIENT_SAMPLE_REGION_LOAD = '[sample] regions load',
  FIELD_NUTRIENT_SAMPLE_REGION_LOAD_SUCCESS = '[sample] regions load success',
  FIELD_NUTRIENT_DISTRIBUTION_CHANGED = '[nutrient distribution] changed',
  FIELD_NUTRIENT_NEED_CHANGED = '[nutrient need] changed',
  FIELD_NUTRIENT_DISTRIBUTIONS_STATISTIC_DATA_EXCEL_EXPORT = '[field] excel export statistic data',
  FIELD_NUTRIENT_DISTRIBUTIONS_STATISTIC_DATA_EXCEL_EXPORT_SUCCESS = '[field] excel export statistic data success',
  FIELD_NUTRIENT_DISTRIBUTIONS_STATISTIC_DATA_EXCEL_EXPORT_FAIL = '[field] excel export statistic data fail',
}

export class FieldNutrientDistributionChanged implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_DISTRIBUTION_CHANGED;
}

export class FieldNutrientNeedChanged implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_NEED_CHANGED;
}

export class FieldNutrientDistributionsLoad implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_DISTRIBUTIONS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldNutrientDistributionsLoadSuccess implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_DISTRIBUTIONS_LOAD_SUCCESS;

  constructor(public payload: IFieldNutrientsDistribution[]) {
  }
}

export class SampleMethodsLoad implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_SAMPLE_METHOD_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SampleMethodsLoadSuccess implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_SAMPLE_METHOD_LOAD_SUCCESS;

  constructor(public payload: ISampleMethod[]) {
  }
}

export class SampleRegionsLoad implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_SAMPLE_REGION_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SampleRegionsLoadSuccess implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_SAMPLE_REGION_LOAD_SUCCESS;

  constructor(public payload: ISampleRegion[]) {
  }
}

export class FieldNutrientDistributionsRefresh implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_DISTRIBUTIONS_REFRESH;
}

export class FieldNutrientDistributionStatisticDataExcelExport implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_DISTRIBUTIONS_STATISTIC_DATA_EXCEL_EXPORT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldNutrientDistributionStatisticDataExcelExportSuccess implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_DISTRIBUTIONS_STATISTIC_DATA_EXCEL_EXPORT_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldNutrientDistributionStatisticDataExcelExportFail implements IAction {
  readonly type = FieldNutrientActions.FIELD_NUTRIENT_DISTRIBUTIONS_STATISTIC_DATA_EXCEL_EXPORT_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

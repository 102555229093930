import {Component, Input} from '@angular/core';

@Component({
  selector: 'ap-legend-color-item',
  template: `
    <div class="ap-legend-color-item">
      <div [class.ap-legend-color]="true"
           [class.open-border-top]="index === total-1"
           [style.background]="color"></div>
      <ap-responsive-text [text]="title"></ap-responsive-text>
    </div>
  `,
})
export class ApLegendColorItemComponent {
  @Input()
  public color = '';
  @Input()
  public title = '';
  @Input()
  public index = 0;
  @Input()
  public total = 0;
}

import {IStateStoreWritable} from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {
  ApCustomTypes,
  DriversCreate,
  DriversDelete,
  DriversLoad,
  DriversLoadSuccess,
  DriversSaveFail,
  DriversSaveSuccess,
  DriversUpdate
}                            from 'invoker-transport';
import {MachineStore}        from './machine.store';
import {SafeBehaviorSubject} from 'ts-tooling';
import IDriver = Data.DocuContext.Driver.IDriver;
import IResultMessage = Invoker.IResultMessage;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IDriverStore extends IStateStoreWritable<IDriver> {
}

@Injectable({providedIn: 'root'})
export class DriverStore extends Store<IDriverStore> {
  constructor(public backend: ApSignalrService,
              private machineStore: MachineStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      saving: false,
      saved: false
    });

    backend.registerObservable(DriversLoadSuccess)
      .subscribe(this.onDriverLoadSuccess.bind(this));
    backend.registerObservable(DriversSaveSuccess).subscribe(() => {
      super.SetSaveSuccessState();
    });
    backend.registerObservable(DriversSaveFail).subscribe(() => {
      super.SetSaveFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.machineStore.ReloadSource());
  }

  public get Drivers$(): SafeBehaviorSubject<IDriver[]> {
    return this.Listen(x => x.data);
  }

  /**
   *  load drivers from Backend to the Store
   */
  public loadDrivers(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    super.DispatchBackend(new DriversLoad([]));
  }

  /**
   * updates driver via backend
   */
  public updateDriver(driver: IDriver): void {
    super.SetSaveState();
    this.DispatchBackend(new DriversUpdate([
      {Name: 'driver', Type: ApCustomTypes.Data_DocuContext_Driver_Driver, Value: driver}
    ]));
  }

  /**
   * creates new driver via backend
   */
  public createDriver(driver: IDriver): void {
    super.SetSaveState();
    this.DispatchBackend(new DriversCreate([
      {Name: 'driver', Type: ApCustomTypes.Data_DocuContext_Driver_Driver, Value: driver}
    ]));
  }

  /**
   * Deletes the driver via backend.
   */
  public deleteDriver(driver: IDriver): void {
    this.DispatchBackend(new DriversDelete([
      {Name: 'driver', Type: ApCustomTypes.Data_DocuContext_Driver_Driver, Value: driver}
    ]));
  }

  public getDriverById(guid: string | System.IGuid | null): IDriver | null {
    if (!guid) {
      return null;
    }
    return super.Listen(s => s.data).getValue().find(d => d.Id === guid);
  }

  private onDriverLoadSuccess(d: IResultMessage): void {
    super.Mutate(s => s.data, () => d.Data);
    super.SetLoadFinishState();
  }
}

<div [class]="'n-fertilization-property'">
  <input type="checkbox"
         [id]="'Area'"
         [checked]="false"
         [class]="'k-checkbox'"/>
  <label [attr.for]="'Area'"
         [class]="'k-checkbox-label'">
    <ap-responsive-text [key]="'Flächendaten'"></ap-responsive-text>
  </label>
  <kendo-dropdownlist [data]="['N-Empfehlung', 'N-Aufnahme']"
                      [value]="'N-Aufnahme'">
  </kendo-dropdownlist>
  <input type="checkbox"
         [id]="'points'"
         [checked]="false"
         [class]="'k-checkbox'"/>
  <label [attr.for]="'points'"
         [class]="'k-checkbox-label'">
    <ap-responsive-text [key]="'Punktdaten'"></ap-responsive-text>
  </label>
</div>

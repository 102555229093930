import {AfterContentInit, AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MapStore}                                                          from '../../../stores/map/map.store';
import {BingImagery, LayerGroupNames, LayerNames}                          from '../../../ap-map';
import {map}                                                               from 'rxjs/operators';
import {ISelectionEvent}                                                   from './layer-panel-bar-item.component';
import {
  MapLayerControlStore
}                                                                          from '../../../stores/map/map.layer.control.store';
import {
  ILayerControlLayer
}                                                                          from '../../../ap-interface/map/layer-control-layer.interface';
import {SelectAllCheckboxState}                                            from '@progress/kendo-angular-grid';

interface ILayerGroupItem {
  group: {
    id: number;
    name: string;
    visibility: SelectAllCheckboxState;
  };
  layers: ILayerControlLayer[];
}

@Component({
  selector: 'layer-controller',
  template: `
    <div class="panelbar-wrapper">
      <kendo-panelbar [animate]="false" class="ap-layer-group">
        <kendo-panelbar-item class="ap-layer-control-layer-group"
                             [title]="null"
                             [expanded]="false">
          <ng-template kendoPanelBarItemTitle>
            {{layerGroupTranslations[(BackgroundLayerGroups$ | async).name] | translate}}
          </ng-template>
          <kendo-panelbar-item #mapSelector
                               class="ap-layer-control-layer-item"
                               [title]="null">
            <ng-template kendoPanelBarItemTitle>
              <layer-controller-map-selector (selectedMap)="selectMap($event)"></layer-controller-map-selector>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar-item>
      </kendo-panelbar>
      <kendo-panelbar [animate]="false" class="ap-layer-control">
        <kendo-panelbar-item *ngFor="let g of (LayerGroups$ | async); trackBy:layerGroupTrackBy"
                             class="ap-layer-control-layer-group"
                             [title]="null"
                             [expanded]="true">
          <ng-template kendoPanelBarItemTitle>
            <layer-panel-bar-item [id]="g.group.name"
                                  [visibility]="g.group.visibility"
                                  [translationKeys]="layerGroupTranslations"
                                  (visibilityChanged)="layerGroupSelectionChange($event)">
            </layer-panel-bar-item>
          </ng-template>
          <kendo-panelbar-item *ngFor="let l of g.layers"
                               class="ap-layer-control-layer-item"
                               [draggable]="false"
                               [title]="null"
                               (dragstart)="dragLayer(l)"
                               (dragover)="allowDropLayer($event, l)"
                               (dragend)="dropLayer()"
                               (click)="clickLayer($event, l)">
            <ng-template kendoPanelBarItemTitle>
              <layer-panel-bar-item [id]="l.name"
                                    [visibility]="l.Visibility ? 'checked' : 'unchecked'"
                                    [translationKeys]="layerNameTranslations"
                                    [padding]="'5px'">
              </layer-panel-bar-item>
            </ng-template>
          </kendo-panelbar-item>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </div>`,
})
export class LayerControllerComponent implements AfterViewInit, AfterContentInit {
  @ViewChild('mapSelector', {static: true, read: ElementRef}) mapSelector: ElementRef;

  constructor(private mapStore: MapStore,
              private mapLayerControlStore: MapLayerControlStore) {
  }

  public BackgroundLayerGroups$ = this.mapStore.Layers.AllLayerGroups$.pipe(
    map(g => g.Find(v => v?.name === LayerGroupNames.BACKGROUND))
  );
  public LayerGroups$ = this.mapStore.Layers.ActiveLayerGroups$.pipe(
    map(g => g.Convert(v => {
      let layers = this.mapStore.Layers.getLayersByGroup(v.name);
      if (layers) {
        layers = layers.sort((a, b) => a.Order > b.Order ? 1 : a.Order === b.Order ? 0 : -1);
      }
      return {
        group: {
          id: v.id,
          name: v.name,
          visibility: v.visibility,
        },
        layers,
      } as ILayerGroupItem;
    })),
  );

  public layerNameTranslations = {
    [LayerNames.FIELDS_DESCRIPTION]: 'Global__Fields',
    [LayerNames.SOIL_SAMPLE_FIELDS]: 'Base_Pages__SampleFields',
    [LayerNames.LOCATIONS]: 'Settings__Lbl_Menu_Devices',
    [LayerNames.TRACK]: 'Docu__Driving_Lanes',
    [LayerNames.SOIL_SAMPLES]: 'DataContext_InterfaceMapping__Samples',
    [LayerNames.SOIL_SAMPLE_DISTRIBUTIONS]: 'Global__SoilSample_NutrientDistribution',
    [LayerNames.DISTRIBUTIONS]: 'Global__NutrientDistribution',
    [LayerNames.SOIL_GROUP_MAP]: 'Nutrients__Soilgroup',
    [LayerNames.NEED]: 'Nutrients__Need',
    [LayerNames.FUNGIDETECT]: 'Global__Fungidetect',
    [LayerNames.CONTACT]: 'Tip_ContactText',
    [LayerNames.EDIT]: 'Base__Editor',
    [LayerNames.TASK_MANAGEMENT]: 'Base__TaskWriter',
    [LayerNames.TASK_MANAGEMENT_NUTRIENT]: 'Global__NutrientDistribution',
    [LayerNames.NUTRIENT_PLANNINGS]: 'Global__Planning',
    [LayerNames.FIELDS_CROP]: 'Global__Crops',
    [LayerNames.SENSOR_POINTS]: 'NUptake',
    [LayerNames.N_RASTER]: 'Lbl_NSensor',
    [LayerNames.PP_RASTER]: 'Base_PP_Type',
    [LayerNames.NDI]: 'Global__NDI_Name',
    [LayerNames.N_APPLICATION_MAP]: 'Global__Planning',
    [LayerNames.PP_APPLICATION_MAP]: 'Global__Planning',
    [LayerNames.N_UPTAKE]: 'NUptake_Sat',
  };

  public layerGroupTranslations = {
    [LayerGroupNames.NONE]: 'Global__Nothing',
    [LayerGroupNames.BACKGROUND]: 'AgriConnect_Map',
    [LayerGroupNames.BASIC]: 'Global_BaseData',
    [LayerGroupNames.BASE_FERTILISATION]: 'Global__BasicFertilization',
    [LayerGroupNames.LOGFILES]: 'Global__Logfiles',
    [LayerGroupNames.PLANT_PROTECTION]: 'Global__PlantProtection',
    [LayerGroupNames.SEED_MAPS]: 'Global__Seedmaps',
    [LayerGroupNames.FIELD_WORK]: 'Global__Documentation',
    [LayerGroupNames.TASK_MANAGEMENT]: 'Global_AgriConnect',
    [LayerGroupNames.FUNGIDETECT]: 'Global__Fungidetect',
    [LayerGroupNames.N_FERTILISATION]: 'Global__NFertilization',
  };

  private currentDragLayer: ILayerControlLayer = null;
  private currentDropLayer: ILayerControlLayer = null;

  ngAfterViewInit(): void {
    this.mapLayerControlStore.refresh({
      map: this.mapStore.Layers.BackgroundMap$.getValue(),
      layers: this.mapStore.Layers.AllLayers$.getValue(),
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      const mapSelector = this.mapSelector.nativeElement;
      mapSelector.click();
    }, 0);
  }

  public layerSelectionChange(e: ISelectionEvent): void {
    const layer = this.mapStore.Layers.getLayerByName(e.id);
    if (!layer) {
      return;
    }
    this.mapLayerControlStore.onVisibilityChanged.emit({layer, visibility: e.selected});
    this.mapStore.Layers.refreshActiveLayerGroups(layer).then();
  }

  public layerGroupSelectionChange(e: ISelectionEvent): void {
    for (const layer of this.mapStore.Layers.getLayersByGroup(e.id)) {
      this.mapLayerControlStore.onVisibilityChanged.emit({layer, visibility: e.selected});
      this.mapStore.Layers.refreshActiveLayerGroups(layer).then();
    }
  }

  dropLayer(): void {
    if (!this.currentDragLayer || !this.currentDropLayer) {
      this.currentDragLayer = null;
      this.currentDropLayer = null;
      return;
    }
    if (this.currentDragLayer.name === this.currentDropLayer.name) {
      this.currentDragLayer = null;
      this.currentDropLayer = null;
      return;
    }
    const group = this.mapStore.Layers.ActiveLayerGroups.Find(g => g.name === this.currentDragLayer.Group);
    if (!group) {
      this.currentDragLayer = null;
      this.currentDropLayer = null;
      return;
    }
    let id = group.id;
    const layers = this.mapStore.Layers.getLayersByGroup(group.name);
    const before = layers.FindAll(l => l.Order > this.currentDragLayer.Order && l.name !== this.currentDragLayer.name && l.name !== this.currentDropLayer.name);
    const between = layers.FindAll(l => l.Order < this.currentDragLayer.Order && l.Order > this.currentDropLayer.Order && l.name !== this.currentDragLayer.name && l.name !== this.currentDropLayer.name);
    const after = layers.FindAll(l => l.Order < this.currentDropLayer.Order && l.name !== this.currentDragLayer.name && l.name !== this.currentDropLayer.name);
    for (const layer of this.currentDragLayer.Order < this.currentDropLayer.Order ? [
      ...before,
      ...between,
      this.currentDragLayer,
      this.currentDropLayer,
      ...after,
    ] : [
      ...before,
      ...between,
      this.currentDropLayer,
      this.currentDragLayer,
      ...after,
    ]) {
      layer.Order = --id;
    }
    setTimeout(() => {
      this.mapStore.Layers.refreshActiveLayerGroups(this.currentDragLayer).then();
      this.currentDragLayer = null;
      this.currentDropLayer = null;
    }, 0);
  }

  dragLayer(l: ILayerControlLayer): void {
    this.currentDragLayer = l;
  }

  allowDropLayer(e: DragEvent, l: ILayerControlLayer): void {
    if (!this.currentDragLayer) {
      return;
    }
    if (l.Group === this.currentDragLayer.Group) {
      this.currentDropLayer = l;
      e.preventDefault();
      return;
    }
    this.currentDropLayer = null;
  }

  layerGroupTrackBy(idx: number, item: ILayerGroupItem): string {
    return `${item.group.id}_${item.group.name}_${item.group.visibility}_${item.layers
      .Convert(l => `${l.Group}_${l.name}_${l.Order}_${l.Visibility}`).Join('_')}`;
  }

  selectMap(m: BingImagery): void {
    this.mapLayerControlStore.onBackgroundMapChanged.emit(m);
  }

  clickLayer(e: MouseEvent, l: ILayerControlLayer): void {
    this.layerSelectionChange({
      id: l.name,
      selected: !l.Visibility,
    });
    e.preventDefault();
  }
}

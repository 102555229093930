import {Injectable}                                                             from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {
  ApDynformsConfigFieldset
}                                                                               from './config/ap-dynforms-config-fieldset';
import {ApDynformsControltype}                                                  from './config/ap-dynforms-config-base';
import {ApValidator}                                                            from './validators/ap-validator';
import {combineLatest, Subscription}                                            from 'rxjs';
import {delay, map}                                                             from 'rxjs/operators';
import {ApDynformsValidator}                                                    from './ap-dynforms-validator';

/**
 * dynamic form configuration service.
 * analyzing the dynamic form's configuration and building reactive form group.
 */
@Injectable({providedIn: 'root'})
export class ApDynformsConfigService {

  private static _setControl(control: AbstractControl, status: boolean): void {
    if (status) {
      control.enable();
    } else {
      control.disable();
    }
  }

  private static _addControl(group: FormGroup,
                             key: string,
                             value: any,
                             disabled: boolean,
                             validators: ValidatorFn | ValidatorFn[],
                             asyncValidators: AsyncValidatorFn | AsyncValidatorFn[]): void {
    group.addControl(key, new FormControl({
        value: isNaN(value) ? value || '' : value,
        disabled: (disabled === true),
      },
      {
        validators,
        asyncValidators,
        updateOn: 'change'
      }
    ));
  }

  /**
   * Subscribe all form control dependencies to other form control
   */
  subscribeToDependencies(form: FormGroup, fieldSets: ApDynformsConfigFieldset[], subscriptions: Subscription[]): Subscription[] {
    subscriptions.forEach(s => s.unsubscribe());
    subscriptions = [];
    fieldSets.forEach(fs => {
      fs.config.filter(c => c.dependsOn).forEach(c => {
        const depends: AbstractControl[] = [];
        const control = form.get(c.key);
        c.dependsOn.forEach(d => {
          const dependsControl = form.get(d);
          if (dependsControl) {
            depends.push(dependsControl);
          }
        });
        if (control && depends.length) {
          subscriptions.push(
            combineLatest(depends.map(d => d.statusChanges))
              .pipe(
                map(s => s.every(st => st === 'VALID'))
              )
              .subscribe(s => {
                ApDynformsConfigService._setControl(control, s);
              })
          );
          ApDynformsConfigService._setControl(control, depends.every(d => d.status === 'VALID'));
        }
      });
    });
    return subscriptions;
  }

  subscribeToListener(form: FormGroup, fieldSets: ApDynformsConfigFieldset[], subscriptions: Subscription[]): Subscription[] {
    subscriptions.forEach(s => s.unsubscribe());
    subscriptions = [];
    fieldSets.forEach(fs => {
      fs.config.filter(c => c.listenUpdate).forEach(c => {
        const control = form.get(c.key);
        if (control) {
          subscriptions.push(
            combineLatest(c.listenUpdate).pipe(delay(0)).subscribe(
              () => control.updateValueAndValidity()
            ));
        }
      });
    });
    return subscriptions;
  }

  /**
   * creating form group based on given configuration
   */
  toFormGroup(
    fieldsets: ApDynformsConfigFieldset[],
    formValidators: ApDynformsValidator<ValidatorFn>[],
    formAsyncValidators: ApDynformsValidator<AsyncValidatorFn>[]): FormGroup {
    const group = new FormGroup({});
    if (!fieldsets) {
      fieldsets = [];
    }

    fieldsets.forEach(fieldset => {
      const configs = fieldset.config.filter(c =>
        c.controlType !== ApDynformsControltype.Placeholder &&
        c.controlType !== ApDynformsControltype.Template &&
        c.controlType !== ApDynformsControltype.Button);
      // reactive form keys have to be unique.
      configs.map(c => c.key).forEach((value, index, array) => {
        const position = array.indexOf(value);
        if (position !== index) {
          // throw an exception in case a reactive form's key is used more than once.
          throw new Error(
            `Dynform configuration contains duplicate reactive key: '${value}' on position ${position} and ${index}`);
        }
        // throw an exception in case an array like structure is used.
        value.split('.').forEach(token => {
          if (!isNaN(parseFloat(token))) {
            throw new Error(`Numeric key ${token} in ${value} is not allowed`);
          }
        });
      });

      configs.forEach(config => {
        let item = group;
        config.key.split('.').forEach((val, idx, arr) => {
          if (idx !== arr.length - 1) {
            // PARENT.child
            if (!item.get(val)) {
              item.addControl(val, new FormGroup({}));
            }
            item = item.get(val) as FormGroup;
          } else {
            // parent.CHILD
            const validators = config.validators ?
              config.validators.map(v => ApValidator.validate(v.validator, v.errorKey, v.invalidIds)) :
              null;
            const asyncValidators = config.asyncValidators ?
              config.asyncValidators.map(v => ApValidator.validateAsync(v.validator, v.errorKey, v.invalidIds)) :
              null;
            if (config.controlType === ApDynformsControltype.DateRange) {
              ApDynformsConfigService._addControl(item, `${val}_Start`, config.value.start, config.disabled, validators, asyncValidators);
              ApDynformsConfigService._addControl(item, `${val}_End`, config.value.end, config.disabled, validators, asyncValidators);
            } else {
              ApDynformsConfigService._addControl(item, val, config.value, config.disabled, validators, asyncValidators);
            }
          }
        });
      });
    });

    if (formValidators) {
      group.setValidators(formValidators.map(v => ApValidator.validate(v.validator, v.errorKey)));
    }

    if (formAsyncValidators) {
      group.setAsyncValidators(formAsyncValidators.map(v => ApValidator.validateAsync(v.validator, v.errorKey)));
    }

    return group;
  }
}

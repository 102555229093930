import {IAction, IApInvokerArguments} from '../../interfaces';
import IFieldNutrientPlanningStatistic = Data.Nutrients.IFieldNutrientPlanningStatistic;
import IRasterCreationResult = Data.Nutrients.IRasterCreationResult;

export enum ApNutrientActions {
  NUTRIENT_PLANNING_START = '[nutrient_planning] start',
  NUTRIENT_PLANNING_FAIL = '[nutrient_planning] fail',
  NUTRIENT_PLANNING_SUCCESS = '[nutrient_planning] success',
  NUTRIENT_PLANNING_RASTER_STATISTIC_LOAD = '[nutrient_planning] get raster statistic load',
  NUTRIENT_PLANNING_RASTER_STATISTIC_LOAD_SUCCESS = '[nutrient_planning] get raster statistic load success',
  NUTRIENT_PLANNING_RASTER_STATISTIC_REFRESH = '[nutrient_planning] raster statistic refresh',
  NUTRIENT_PLANNING_RASTER_STATISTIC_FAIL = '[nutrient_planning] raster statistic fail',
  NUTRIENT_PLANNING_PACKAGE_START = '[nutrient_planning] start package',
  NUTRIENT_PLANNING_PACKAGE_FAIL = '[nutrient_planning] fail package',
  NUTRIENT_PLANNING_PACKAGE_SUCCESS = '[nutrient_planning] success package',
}

export class NutrientPlanningPackageStart implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_PACKAGE_START;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NutrientPlanningPackageFail implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_PACKAGE_FAIL;

  constructor(public payload: string) {
  }
}

export class NutrientPlanningPackageSuccess implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_PACKAGE_SUCCESS;

  constructor(public payload: string) {
  }
}

export class NutrientPlanningStart implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_START;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NutrientPlanningFail implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_FAIL;

  constructor(public payload: string) {
  }
}

export class NutrientPlanningSuccess implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_SUCCESS;

  constructor(public payload: string) {
  }
}

export class NutrientPlanningRasterStatisticLoad implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_RASTER_STATISTIC_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NutrientPlanningRasterStatisticLoadSuccess implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_RASTER_STATISTIC_LOAD_SUCCESS;

  constructor(public payload: IFieldNutrientPlanningStatistic[]) {
  }
}

export class NutrientPlanningRasterStatisticRefresh implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_RASTER_STATISTIC_REFRESH;

  constructor(public payload: IFieldNutrientPlanningStatistic[]) {
  }
}

export class NutrientPlanningRasterStatisticFail implements IAction {
  readonly type = ApNutrientActions.NUTRIENT_PLANNING_RASTER_STATISTIC_FAIL;

  constructor(public payload: IRasterCreationResult) {
  }
}

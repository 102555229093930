import {AfterContentInit, Directive, ElementRef, Input, OnInit} from '@angular/core';
import {CssValueParser}                                         from '../utils/ap-css-value.parser';

/**
 * let create a Responsive Grid so you can define relative widths
 * the Width was calculated in percentage and spaces like padding, margin and border was be noted
 */
@Directive({
  selector: '[apResponsive]'
})
export class ApResponsiveDirective implements AfterContentInit, OnInit {
  /**
   * Width of the Browser Window (outside)
   */
  private _windowX: number;
  /**
   * Height of the Browser Window (outside)
   */
  private _windowY: number;
  /**
   * size of padding left and right
   */
  private _elementPadding: number;
  /**
   * size of margin left and right
   */
  private _elementMargin: number;
  /**
   * size of border left and right
   */
  private _elementBorder: number;

  /**
   * the sum of padding, margin and border size
   */
  public get Space(): number {
    return (this._elementMargin || 0) + (this._elementPadding || 0) + (this._elementBorder || 0);
  }

  /**
   * size of column for small view
   */
  @Input()
  public sizeSm: string;

  /**
   * size of column for normal view
   */
  @Input()
  public sizeMd: string;

  /**
   * size of column for large view
   */
  @Input()
  public sizeLg: string;

  /**
   * size of column for big views
   */
  @Input()
  public sizeXl: string;

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    this._registerWindowEvents();
    this._calculateSpaces();
  }

  ngAfterContentInit(): void {
    (this.element.nativeElement as HTMLElement).style.cssFloat = 'left';
    this._windowX = window.innerWidth;
    this._windowY = window.innerHeight;
    this._calcWidth();
  }

  /**
   * register on window resize event to recalculate the widths
   */
  private _registerWindowEvents(): void {
    window.addEventListener('resize', () => {
      this._windowX = window.innerWidth;
      this._windowY = window.innerHeight;
      this._calcWidth();
    });
  }

  /**
   * reads the padding, margin and border spaces and write them to their variables in this directive
   */
  private _calculateSpaces(): void {
    const bl = CssValueParser.getNumberValue((this.element.nativeElement as HTMLElement).style.borderLeft);
    const br = CssValueParser.getNumberValue((this.element.nativeElement as HTMLElement).style.borderRight);

    if (bl[1] === br[1]) {
      this._elementBorder = bl[0] + br[0];
    } else {
      console.warn(`border cannot calculated border-left => ${bl[1]} and border-right => ${br[1]}`);
    }

    const pl = CssValueParser.getNumberValue((this.element.nativeElement as HTMLElement).style.paddingLeft);
    const pr = CssValueParser.getNumberValue((this.element.nativeElement as HTMLElement).style.paddingRight);

    if (pl[1] === pr[1]) {
      this._elementPadding = pl[0] + pr[0];
    } else {
      console.warn(`padding cannot calculated padding-left => ${pl[1]} and padding-right => ${pr[1]}`);
    }

    const ml = CssValueParser.getNumberValue((this.element.nativeElement as HTMLElement).style.marginLeft);
    const mr = CssValueParser.getNumberValue((this.element.nativeElement as HTMLElement).style.marginRight);

    if (ml[1] === mr[1]) {
      this._elementMargin = ml[0] + mr[0];
    } else {
      console.warn(`margin cannot calculated margin-left => ${ml[1]} and margin-right => ${mr[1]}`);
    }
  }

  /**
   * choose the right screen and calculate the width
   */
  private _calcWidth(): void {
    if (!this.sizeMd) {
      throw new Error(`you must define sizeMd on directive sun-responsive`);
    }
    const el: HTMLElement = this.element.nativeElement;
    if (this._windowX <= 480) {
      el.style.width = this._parseSize(this.sizeSm || this.sizeMd);
    } else if (this._windowX > 480 && this._windowX <= 1024) {
      el.style.width = this._parseSize(this.sizeMd);
    } else if (this._windowX > 1024 && this._windowX <= 1920) {
      el.style.width = this._parseSize(this.sizeLg || this.sizeMd);
    } else {
      el.style.width = this._parseSize(this.sizeXl || this.sizeMd);
    }
  }

  /**
   * calculate the width in percent for the column
   */
  private _parseSize(value: string): string {
    if (!value.includes('-')) {
      throw new Error(`missing "-" in input value: ${value}`);
    }
    const tmp = value.split('-');
    const base = parseInt(tmp[0], 10);
    const full = parseInt(tmp[1], 10);
    if (isNaN(base) || isNaN(full)) {
      throw new Error(`invalid number value in ${base}-${full}`);
    }
    const percent = base * 100 / full;
    const spacePercent = this.Space * 100 / this._windowX;
    return `${(percent - spacePercent)}%`;
  }
}

import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class PasswordStrengthService {
  private static strReverse(input: string): string {
    let newstring = '';
    for (let s = 0; s < input.length; s++) {
      newstring = input.charAt(s) + newstring;
    }
    return newstring;
  }

  public percent(pwd: string): number {
    // Simultaneous variable declaration and value assignment aren't supported in IE apparently
    // so I'm forced to assign the same value individually per var to support a crappy browser *sigh*
    let nScore = 0;
    let nLength = 0;
    let nAlphaUC = 0;
    let nAlphaLC = 0;
    let nNumber = 0;
    let nSymbol = 0;
    let nMidChar = 0;
    let nRequirements = 0;
    let nUnqChar = 0;
    let nRepChar = 0;
    let nRepInc = 0;
    let nConsecAlphaUC = 0;
    let nConsecAlphaLC = 0;
    let nConsecNumber = 0;
    let nConsecSymbol = 0;
    let nConsecCharType = 0;
    let nSeqAlpha = 0;
    let nSeqNumber = 0;
    let nSeqSymbol = 0;
    let nSeqChar = 0;
    let nReqChar = 0;
    const nMultMidChar = 2;
    const nMultConsecAlphaUC = 2;
    const nMultConsecAlphaLC = 2;
    const nMultConsecNumber = 2;
    const nMultSeqAlpha = 3;
    const nMultSeqNumber = 3;
    const nMultSeqSymbol = 3;
    const nMultLength = 4;
    const nMultNumber = 4;
    const nMultSymbol = 6;
    let nTmpAlphaUC = '';
    let nTmpAlphaLC = '';
    let nTmpNumber = '';
    let nTmpSymbol = '';
    const sAlphas = 'abcdefghijklmnopqrstuvwxyz';
    const sNumerics = '01234567890';
    const sSymbols = ')!@#$%^&*()';
    const nMinPwdLen = 8;

    if (pwd) {
      nScore = parseInt((pwd.length * nMultLength) as any, 10);
      nLength = pwd.length;
      const arrPwd = pwd.replace(/\s+/g, '').split(/\s*/);
      const arrPwdLen = arrPwd.length;

      /* Loop through password$ to check for Symbol, Numeric, Lowercase and Uppercase pattern matches */
      for (let a = 0; a < arrPwdLen; a++) {
        if (arrPwd[a].match(/[A-Z]/g)) {
          if (nTmpAlphaUC !== '') {
            // tslint:disable-next-line:triple-equals
            if ((nTmpAlphaUC + 1) as any == a) {
              nConsecAlphaUC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaUC = a as any;
          nAlphaUC++;
        } else if (arrPwd[a].match(/[a-z]/g)) {
          if (nTmpAlphaLC !== '') {
            // tslint:disable-next-line:triple-equals
            if ((nTmpAlphaLC + 1) as any == a) {
              nConsecAlphaLC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaLC = a as any;
          nAlphaLC++;
        } else if (arrPwd[a].match(/[0-9]/g)) {
          if (a > 0 && a < (arrPwdLen - 1)) {
            nMidChar++;
          }
          if (nTmpNumber !== '') {
            // tslint:disable-next-line:triple-equals
            if ((nTmpNumber + 1) as any == a) {
              nConsecNumber++;
              nConsecCharType++;
            }
          }
          nTmpNumber = a as any;
          nNumber++;
        } else if (arrPwd[a].match(/[^a-zA-Z0-9_]/g)) {
          if (a > 0 && a < (arrPwdLen - 1)) {
            nMidChar++;
          }
          if (nTmpSymbol !== '') {
            // tslint:disable-next-line:triple-equals
            if ((nTmpSymbol + 1) as any == a) {
              nConsecSymbol++;
              nConsecCharType++;
            }
          }
          nTmpSymbol = a as any;
          nSymbol++;
        }
        /* Internal loop through password$ to check for repeat characters */
        let bCharExists = false;
        for (let b = 0; b < arrPwdLen; b++) {
          // tslint:disable-next-line:triple-equals
          if (arrPwd[a] == arrPwd[b] && a != b) { /* repeat character exists */
            bCharExists = true;
            /*
            Calculate icrement deduction based on proximity to identical characters
            Deduction is incremented each time a new match is discovered
            Deduction amount is based on total password$ length divided by the
            difference of distance between currently selected match
            */
            nRepInc += Math.abs(arrPwdLen / (b - a));
          }
        }
        if (bCharExists) {
          nRepChar++;
          nUnqChar = arrPwdLen - nRepChar;
          nRepInc = (nUnqChar) ? Math.ceil(nRepInc / nUnqChar) : Math.ceil(nRepInc);
        }
      }

      /* Check for sequential alpha string patterns (forward and reverse) */
      for (let s = 0; s < 23; s++) {
        const sFwd = sAlphas.substring(s, parseInt((s + 3) as any, 10));
        const sRev = PasswordStrengthService.strReverse(sFwd);
        // tslint:disable-next-line:triple-equals
        if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) {
          nSeqAlpha++;
          nSeqChar++;
        }
      }

      /* Check for sequential numeric string patterns (forward and reverse) */
      for (let s = 0; s < 8; s++) {
        const sFwd = sNumerics.substring(s, parseInt((s + 3) as any, 10));
        const sRev = PasswordStrengthService.strReverse(sFwd);
        // tslint:disable-next-line:triple-equals
        if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) {
          nSeqNumber++;
          nSeqChar++;
        }
      }

      /* Check for sequential symbol string patterns (forward and reverse) */
      for (let s = 0; s < 8; s++) {
        const sFwd = sSymbols.substring(s, parseInt((s + 3) as any, 10));
        const sRev = PasswordStrengthService.strReverse(sFwd);
        // tslint:disable-next-line:triple-equals
        if (pwd.toLowerCase().indexOf(sFwd) != -1 || pwd.toLowerCase().indexOf(sRev) != -1) {
          nSeqSymbol++;
          nSeqChar++;
        }
      }

      /* Modify overall score value based on usage vs requirements */

      /* General point assignment */
      if (nAlphaUC > 0 && nAlphaUC < nLength) {
        nScore = parseInt((nScore + ((nLength - nAlphaUC) * 2)) as any, 10);
      }
      if (nAlphaLC > 0 && nAlphaLC < nLength) {
        nScore = parseInt((nScore + ((nLength - nAlphaLC) * 2)) as any, 10);
      }
      if (nNumber > 0 && nNumber < nLength) {
        nScore = parseInt((nScore + (nNumber * nMultNumber)) as any, 10);
      }
      if (nSymbol > 0) {
        nScore = parseInt((nScore + (nSymbol * nMultSymbol)) as any, 10);
      }
      if (nMidChar > 0) {
        nScore = parseInt((nScore + (nMidChar * nMultMidChar)) as any, 10);
      }

      /* Point deductions for poor practices */
      if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {  // Only Letters
        nScore = parseInt((nScore - nLength) as any, 10);
      }
      if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {  // Only Numbers
        nScore = parseInt((nScore - nLength) as any, 10);
      }
      if (nRepChar > 0) {  // Same character exists more than once
        nScore = parseInt((nScore - nRepInc) as any, 10);
      }
      if (nConsecAlphaUC > 0) {  // Consecutive Uppercase Letters exist
        nScore = parseInt((nScore - (nConsecAlphaUC * nMultConsecAlphaUC)) as any, 10);
      }
      if (nConsecAlphaLC > 0) {  // Consecutive Lowercase Letters exist
        nScore = parseInt((nScore - (nConsecAlphaLC * nMultConsecAlphaLC)) as any, 10);
      }
      if (nConsecNumber > 0) {  // Consecutive Numbers exist
        nScore = parseInt((nScore - (nConsecNumber * nMultConsecNumber)) as any, 10);
      }
      if (nSeqAlpha > 0) {  // Sequential alpha strings exist (3 characters or more)
        nScore = parseInt((nScore - (nSeqAlpha * nMultSeqAlpha)) as any, 10);
      }
      if (nSeqNumber > 0) {  // Sequential numeric strings exist (3 characters or more)
        nScore = parseInt((nScore - (nSeqNumber * nMultSeqNumber)) as any, 10);
      }
      if (nSeqSymbol > 0) {  // Sequential symbol strings exist (3 characters or more)
        nScore = parseInt((nScore - (nSeqSymbol * nMultSeqSymbol)) as any, 10);
      }

      /* Determine if mandatory requirements have been met and set image indicators accordingly */
      const arrChars = [nLength, nAlphaUC, nAlphaLC, nNumber, nSymbol];
      const arrCharsIds = ['nLength', 'nAlphaUC', 'nAlphaLC', 'nNumber', 'nSymbol'];
      const arrCharsLen = arrChars.length;
      for (let c = 0; c < arrCharsLen; c++) {
        // tslint:disable-next-line:triple-equals
        let minVal = 0;
        // tslint:disable-next-line:triple-equals
        if (arrCharsIds[c] == 'nLength') {
          minVal = parseInt((nMinPwdLen - 1) as any, 10);
        }
        // tslint:disable-next-line:triple-equals
        if (arrChars[c] == parseInt((minVal + 1) as any, 10)) {
          nReqChar++;
        } else if (arrChars[c] > parseInt((minVal + 1) as any, 10)) {
          nReqChar++;
        }
      }
      nRequirements = nReqChar;
      let nMinReqChars = 4;
      if (pwd.length >= nMinPwdLen) {
        nMinReqChars = 3;
      }
      if (nRequirements > nMinReqChars) {  // One or more required characters exist
        nScore = parseInt((nScore + (nRequirements * 2)) as any, 10);
      }

      /* Determine complexity based on overall score */
      if (nScore > 100) {
        nScore = 100;
      } else if (nScore < 0) {
        nScore = 0;
      }
    }
    return nScore;
  }
}

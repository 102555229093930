import ISlice = Data.DocuContext.Slice.ISlice;
import ICombinedSlice = Data.DocuContext.Slice.ICombinedSlice;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum SliceActions {
  SLICE_LOAD = '[slice] load',
  SLICE_LOAD_ALL = '[slice] load all',
  SLICE_COMBINED_LOAD_BY_MACHINE_AND_DATE = '[slice combined] load machine date',
  SLICE_COMBINED_LOAD_BY_MACHINE_AND_DATE_SUCCESS = '[slice combined] load machine date success',
  SLICE_LOAD_SUCCESS = '[slice] load success',
  SLICE_LOAD_FAIL = '[slice] load fail',
  SLICE_CALC_BRUTTO_NETTO_AREA = '[slice] calc brutto netto area',
}

export class SliceLoad implements IAction {
  readonly type = SliceActions.SLICE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SliceLoadAll implements IAction {
  readonly type = SliceActions.SLICE_LOAD_ALL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SliceCombinedLoadByMachineAndDate implements IAction {
  readonly type = SliceActions.SLICE_COMBINED_LOAD_BY_MACHINE_AND_DATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SliceCombinedLoadByMachineAndDateSuccess implements IAction {
  readonly type = SliceActions.SLICE_COMBINED_LOAD_BY_MACHINE_AND_DATE_SUCCESS;

  constructor(public payload: ICombinedSlice[]) {
  }
}

export class SliceLoadSuccess implements IAction {
  readonly type = SliceActions.SLICE_LOAD_SUCCESS;

  constructor(public payload: ISlice[]) {
  }
}

export class SliceLoadFail implements IAction {
  readonly type = SliceActions.SLICE_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class SliceCalcBruttoNettoArea implements IAction {
  readonly type = SliceActions.SLICE_CALC_BRUTTO_NETTO_AREA;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

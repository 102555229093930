import {Component, ComponentFactoryResolver, ViewChild} from '@angular/core';
import {ApViewContainerRefDirective}                    from '../../directives/ap-view-container-ref.directive';
import {ApViewContainerData, ApVisibleStates}           from '../../../ap-interface';
import {TRANSLATE_EDIT_FORM}                            from '../../../ap-animation/translate-panel-text.animation';
import {Observable}                                     from 'rxjs';
import {FormStore}                                      from '../../../stores/layout/form.store';
import {Trace}                                          from '../../../debug-utils/ApplicationTracer';
import {ObjectFactory}                                  from 'ts-tooling';

@Component({
  selector: 'ap-view-container',
  templateUrl: 'ap-view-container.component.html',
  animations: [TRANSLATE_EDIT_FORM]
})
export class AdViewContainerComponent {
  public formState$: Observable<ApVisibleStates>;
  @ViewChild(ApViewContainerRefDirective, {static: true}) adHost: ApViewContainerRefDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private formStore: FormStore) {
    this.formState$ = this.formStore.FormState$;
    this.formStore.NewComponent$.subscribe(d => {
      this.loadComponent(d);
    });
  }

  @Trace()
  loadComponent(component: any): void {
    if (!component || !this.adHost) {
      return;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component.component);

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    if (component.passReference === true) {
      (componentRef.instance as ApViewContainerData).data = component.data;
    } else {
      (componentRef.instance as ApViewContainerData).data = ObjectFactory.Copy(component.data);
    }
  }

  @Trace()
  destroyComponent(): void {
    if (this.adHost && this.adHost.viewContainerRef && this.formStore.FormState$.getValue() === ApVisibleStates.OUT) {
      this.adHost.viewContainerRef.clear();
    }
  }
}

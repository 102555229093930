import {Injectable}                                                                  from '@angular/core';
import {
  ApplicationDatesData,
  TooltipsData
}                                                                                    from '../components/configs/nutrient-management-common.types';
import {
  ApDateService,
  DateSort
}                                                                                    from '../../ap-core/services/ap-date-service';
import {
  ApTooltipContentService
}                                                                                    from '../../ap-utils/service/ap-tooltip-content.service';
import {
  CampaignYearService
}                                                                                    from '../../services/data/campaign-year.service';
import {ObjectFactory}                                                               from 'ts-tooling';
import {
  ApNutrientPlanningComponent
}                                                                                    from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-planning.component';
import {
  LoginStore
}                                                                                    from '../../stores/login/login.store';
import {
  BasicFertilisationSummariesStore
}                                                                                    from '../../stores/taskmanagement/basic.fertilisation.summaries.store';
import {
  FormStore
}                                                                                    from '../../stores/layout/form.store';
import {MapStore}                                                                    from '../../stores/map/map.store';
import {
  RouterStore
}                                                                                    from '../../stores/router/router.store';
import {
  FieldStore
}                                                                                    from '../../stores/farm/field.store';
import {
  StatisticStore
}                                                                                    from '../../stores/statistic/statistic.store';
import {
  ApOperationMode
}                                                                                    from '../../ap-interface/enums/ap-operation-mode.enum';
import {
  ApLegendScrollerService
}                                                                                    from '../../map/components/layer-controller/ap-legend-scroller.service';
import {
  NutrientManagementService
}                                                                                    from './nutrient-management.service';
import {
  ApNutrientBookingComponent
}                                                                                    from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-booking.component';
import {
  IApNutrientBookingComponentData
}                                                                                    from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-booking-configs/nutrient-booking-types';
import {skip}                                                                        from 'rxjs/operators';
import {
  ModalDialogStore
}                                                                                    from '../../stores/dialog/modal.dialog.store';
import {
  ModalDialogPresets
}                                                                                    from '../../stores/dialog/modal.dialog.presets';
import {
  PlanningEntryComponentDataType
}                                                                                    from '../../entry-components/ap-nutrients-management-entry-components/ap-nutrient-planning-configs/nutrient-planning-types';
import {BasicFertilisationPlanBooksSaveFail, BasicFertilisationPlanBooksSaveSuccess} from 'invoker-transport';
import {BehaviorSubject, Observable}                                                 from 'rxjs';
import {
  BasicFertilisationSummariesSaveFail,
  BasicFertilisationSummariesSaveSuccess
}                                                                                    from '../../../../projects/invoker-transport/src/lib/actions/taskmanagement';
import IGuid = System.IGuid;
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;

@Injectable({providedIn: 'root'})
export class BaseFertilizationCommonService {
  private _triggerGridUpdate = new BehaviorSubject<boolean>(true);
  private _previouslySelectedSummaryId: IGuid | undefined;
  private _mainComponentSelectionAvailability = true;

  public gridUpdate$: Observable<boolean> = this._triggerGridUpdate.asObservable();

  constructor(private mapStore: MapStore,
              private formStore: FormStore,
              private loginStore: LoginStore,
              private fieldStore: FieldStore,
              private routerStore: RouterStore,
              private statisticStore: StatisticStore,
              private modalDialogStore: ModalDialogStore,
              private modalDialogPresets: ModalDialogPresets,
              private basicFertilisationSummariesStore: BasicFertilisationSummariesStore,
              private dateService: ApDateService,
              private campaignYearService: CampaignYearService,
              private legendScrollerService: ApLegendScrollerService,
              private tooltipContentService: ApTooltipContentService,
              private nutrientManagementService: NutrientManagementService) {
  }

  public get selectedSummaryId(): IGuid | undefined {
    return this._previouslySelectedSummaryId;
  }

  public get mainComponentSelectionAvailability(): boolean {
    return this._mainComponentSelectionAvailability;
  }

  public setMainComponentSelectionAvailability(availableToUpdate: boolean): void {
    this._mainComponentSelectionAvailability = availableToUpdate;
  }

  public setIsAllowGridItemsUpdate(allow: boolean): void {
    this._triggerGridUpdate.next(allow);
  }

  public setSelectedSummaryKey(summaryId: IGuid | undefined): void {
    this._previouslySelectedSummaryId = summaryId;
  }

  public redirectSummaryToShowView(summaryId: IGuid, isBooked: boolean,
                                   returnUrl: string, fieldId: IGuid | null = null): void {
    this._redirectToTaskManagement(true, summaryId, isBooked, returnUrl, fieldId);
  }

  public redirectSummaryToExportView(summaryId: IGuid, isBooked: boolean,
                                     returnUrl: string, fieldId: IGuid | null = null): void {
    this._redirectToTaskManagement(false, summaryId, isBooked, returnUrl, fieldId);
  }

  public replanSummary(summary: IBasicFertilisationSummaries): void {
    const copiedSummary = ObjectFactory.Copy(summary);
    const planBooksToDelete = copiedSummary.BasicFertilisationPlanBooks.filter(x => !x.DeletedAt && !x.BookedAt).map(x => x.Id);
    if (!planBooksToDelete || planBooksToDelete.length <= 0) {
      return;
    }
    this.loginStore.setLongOperationStatus(true);
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.deleteBasicFertilisationPlanBooks(copiedSummary.Id, planBooksToDelete, false, true)
      .watchStream({
        action: BasicFertilisationPlanBooksSaveSuccess,
        todo: payload => {
          this.loginStore.setLongOperationStatus(false);
          if (!payload.Error) {
            this.formStore.openForm({
              component: ApNutrientPlanningComponent,
              data: {
                Summary: copiedSummary,
                Type: PlanningEntryComponentDataType.Edit
              }
            });
          }
        },
      })
      .watchStream({
        action: BasicFertilisationPlanBooksSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
          this.loginStore.setLongOperationStatus(false);
        }
      });
  }

  public saveSummary(summary: IBasicFertilisationSummaries): void {
    const copiedSummary = ObjectFactory.Copy(summary);
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.createBasicFertilisation(copiedSummary, true, true)
      .watchStream({
        action: BasicFertilisationSummariesSaveSuccess,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        },
      })
      .watchStream({
        action: BasicFertilisationSummariesSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        }
      });
  }

  public revertSummary(summary: IBasicFertilisationSummaries): void {
    const copiedSummary = ObjectFactory.Copy(summary);
    copiedSummary.DeletedAt = undefined;
    copiedSummary.DeletedBy = undefined;
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.updateBasicFertilisation(copiedSummary, false, true)
      .watchStream({
        action: BasicFertilisationSummariesSaveSuccess,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        },
      })
      .watchStream({
        action: BasicFertilisationSummariesSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        }
      });
  }

  public revertSummaryPlanBooks(planBooks: IBasicFertilisationPlanBooks[]): void {
    const copiedPlanBooks = ObjectFactory.Copy(planBooks);
    copiedPlanBooks.forEach(item => {
      item.DeletedAt = undefined;
      item.DeletedBy = undefined;
    });
    this.setIsAllowGridItemsUpdate(false);
    this.basicFertilisationSummariesStore.updateBasicFertilisationPlanBooks(copiedPlanBooks, false, true)
      .watchStream({
        action: BasicFertilisationPlanBooksSaveSuccess,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        },
      })
      .watchStream({
        action: BasicFertilisationPlanBooksSaveFail,
        todo: _ => {
          this.setIsAllowGridItemsUpdate(true);
        }
      });
  }

  public bookPlanBooks(summary: IBasicFertilisationSummaries, fieldId: IGuid | null = null): void {
    this.formStore.openForm({
      component: ApNutrientBookingComponent,
      data: {
        Summary: summary,
        FieldIdToSelect: fieldId,
        IsBooked: false
      } as IApNutrientBookingComponentData
    });
  }

  public unbookPlanBooks(summary: IBasicFertilisationSummaries, fieldId: IGuid | null = null): void {
    this.formStore.openForm({
      component: ApNutrientBookingComponent,
      data: {
        Summary: summary,
        FieldIdToSelect: fieldId,
        IsBooked: true
      } as IApNutrientBookingComponentData
    });
  }

  public deleteSummary(summary: IBasicFertilisationSummaries): void {
    this._deleteDialog(summary, (dataItem: IBasicFertilisationSummaries) => {
      const deletedAt = new Date();
      const deletedBy = this.loginStore.UserId;
      let canSummaryDelete = true;
      const copiedSummary = ObjectFactory.Copy(dataItem);
      copiedSummary.BasicFertilisationPlanBooks.forEach(i => {
        if (i.DeletedAt) {
          return;
        }
        if (!i.BookedBy) {
          i.DeletedBy = deletedBy;
          i.DeletedAt = deletedAt;
        } else {
          canSummaryDelete = false;
        }
      });
      if (canSummaryDelete) {
        copiedSummary.DeletedAt = deletedAt;
        copiedSummary.DeletedBy = deletedBy;
      }
      this.basicFertilisationSummariesStore.updateBasicFertilisation(copiedSummary, true, true);
    });
  }

  public unbookSummaryPlanBook(summary: IBasicFertilisationSummaries, planBookId: IGuid): void {
    const planBook = summary.BasicFertilisationPlanBooks.find(x => x.Id === planBookId);
    if (!planBook) {
      return;
    }
    const copiedPlanBook = ObjectFactory.Copy(planBook);
    copiedPlanBook.BookedAt = undefined;
    copiedPlanBook.BookedBy = undefined;
    this.basicFertilisationSummariesStore.updateBasicFertilisationPlanBooks([copiedPlanBook]);
  }

  public deleteSummaryPlanBook(summary: IBasicFertilisationSummaries, planBookId: IGuid): void {
    this._deleteDialog(summary, (dataItem: IBasicFertilisationSummaries) => {
      const deletedAt = new Date();
      const deletedBy = this.loginStore.UserId;
      const copiedSummary = ObjectFactory.Copy(dataItem);
      copiedSummary.BasicFertilisationPlanBooks.forEach(planBook => {
        if (planBookId === planBook.Id && planBook.BookedBy === null) {
          planBook.DeletedBy = deletedBy;
          planBook.DeletedAt = deletedAt;
        }
      });
      const isAtLeastOnePlanBookExist = copiedSummary.BasicFertilisationPlanBooks.some(x => !x.DeletedAt);
      if (!isAtLeastOnePlanBookExist) {
        copiedSummary.DeletedAt = deletedAt;
        copiedSummary.DeletedBy = deletedBy;
        this.basicFertilisationSummariesStore.updateBasicFertilisation(copiedSummary);
      } else {
        const planBook = copiedSummary.BasicFertilisationPlanBooks.find(x => x.Id === planBookId && !x.BookedBy);
        this.basicFertilisationSummariesStore.updateBasicFertilisationPlanBooks([ObjectFactory.Copy(planBook)]);
      }
    });
  }

  public revertLegends(): void {
    const legends = [
      'NutrientPlanningLegend',
      'TaskMgmtLegend',
      'NeedLegend'
    ];
    legends.forEach(legend => {
      this.mapStore.Legends.addLegend(this.mapStore.Legends[legend]);
    });
  }

  public handleLayers(disableLayers: string[], enableLayers: string[]): void {
    const layers = [
      'NutrientPlanningLayer',
      'TaskManagementLayer',
      'NeedLayer'
    ];
    const toDisable = disableLayers?.filter(x => !!x) ?? [];
    const toEnable = enableLayers?.filter(x => !!x) ?? [];
    if (toDisable.length <= 0 && toEnable.length <= 0) {
      return;
    }
    layers.forEach(layer => {
      const layerName = this.mapStore.Layers[layer]?.name;
      if (!layerName) {
        return;
      }
      if (toDisable.includes(layerName)) {
        this.mapStore.Layers[layer].clear();
        this.mapStore.Layers[layer].Visibility = false;
      }
      if (toEnable.includes(layerName)) {
        this.mapStore.Layers[layer].Visibility = true;
      }
    });
  }

  public handleLegends(selectedItemsLength: number, disableLegends: string[] = []): void {
    const legends = [
      'NutrientPlanningLegend',
      'TaskMgmtLegend',
      'NeedLegend',
    ];
    const layers = [
      'NutrientPlanningLayer',
      'TaskManagementLayer',
      'NeedLayer'
    ];
    const toDisable = disableLegends?.filter(x => !!x) ?? [];
    if (!selectedItemsLength || selectedItemsLength <= 0) {
      legends.forEach(legend => {
        this.mapStore.Legends.removeLegend(this.mapStore.Legends[legend]);
      });
    } else {
      legends.forEach((legend, index) => {
        if (this.mapStore.Layers[layers[index]]?.Visibility
          && this.mapStore.Legends[legend]?.title
          && !toDisable.includes(legend)) {
          this.mapStore.Legends.addLegend(this.mapStore.Legends[legend]);
        }
      });
    }
    legends.forEach(legend => {
      if (toDisable.includes(legend)) {
        this.mapStore.Legends.removeLegend(this.mapStore.Legends[legend]);
      }
    });
  }

  public onSelectionChange(summary: IBasicFertilisationSummaries | null, isBooking: boolean): void {
    if (summary) {
      this.statisticStore.removeStatisticData();
      const currentUrl = this.routerStore.Listen(s => s.url).getValue();
      let planBooks: IBasicFertilisationPlanBooks[];
      if (isBooking) {
        planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy != null);
      } else {
        planBooks = summary.BasicFertilisationPlanBooks.FindAll(_ => _.BookedBy == null);
      }
      const ids = planBooks.map(_ => _.Id);
      const fieldsIds = planBooks.map(_ => _.FieldId);
      const nutrient = planBooks[0].Element;
      let n = nutrient.ShortDescription;
      if (n === 'CaO') {
        n = 'ph';
      }
      if (summary.Statistic != null) {
        this.mapStore.Legends.onNutrientPlanningChanged.emit(summary);
        this.mapStore.Layers.NutrientPlanningLayerSourceInit(currentUrl, n, ids);
        setTimeout(() => this._updateNutrientPlanningLayer(planBooks[0]), 1);
      }
      this.fieldStore.changeSelectedField(fieldsIds.map((item) => item.toString()));
      // bind visibility of taskmanagement layer (showing field border of planning) to the nutrient planning layer
      // Furthermore move the nutrient planning layer (contours) behind the field layer to see the current field geometry
      // as well as the planning geometry and contours
      this.mapStore.Legends.refreshLegend(this.mapStore.Legends.NutrientPlanningLegend, true);
      this.mapStore.Layers.NutrientPlanningLayer.layer.setZIndex(this.mapStore.Layers.FieldsLayer.layer.getZIndex() - 1);
      this.mapStore.Layers.TaskManagementLayer.replaceSource(planBooks, this.fieldStore);
    } else {
      this.mapStore.Legends.onNutrientPlanningChanged.emit(null);
      this.statisticStore.removeStatisticData();
      this.fieldStore.zoomFarmFields();
    }
  }

  public getPlanBooksFieldsCount(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): string {
    if (!planBooks || planBooks.length <= 0) {
      return '0/0';
    }
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    return filteredPlanBooks.length + '/' + nonDeletedPlanBooks.length;
  }

  public getFieldIds(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): string[] {
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    if (!filteredPlanBooks || filteredPlanBooks.length <= 0) {
      return [];
    }
    return filteredPlanBooks.map(x => x.FieldId?.toString());
  }

  public getApplicationDatesData(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): ApplicationDatesData | null {
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    if (!filteredPlanBooks || filteredPlanBooks.length <= 0) {
      return null;
    }
    const planBooksApplicationDates = this._getPlanBookApplicationDates(planBooks, isBooked);
    const uniqueApplicationDates = this.dateService.getUniqDates(planBooksApplicationDates, DateSort.Ascending);
    return {
      Tooltip: this.tooltipContentService.planBooksApplicationDatesTooltip(uniqueApplicationDates.map(x => ({ApplicationDate: x}))),
      IsMoreThanOneUniqDate: uniqueApplicationDates.length > 1,
      NewestDate: uniqueApplicationDates[uniqueApplicationDates.length - 1]
    };
  }

  public getFieldCropsTooltip(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): string {
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    if (!filteredPlanBooks || filteredPlanBooks.length <= 0) {
      return '';
    }
    const tooltipData = filteredPlanBooks.map(x => {
      const applicationDate = new Date(x.ApplicationDate);
      const campaignYear = this.campaignYearService.getCampaignYearByDate(applicationDate);
      const item: TooltipsData = {
        FieldId: x.FieldId,
        ApplicationDate: applicationDate,
        Year: campaignYear.CampaignYear
      };
      return item;
    });
    return this.tooltipContentService.getFieldsCropsTooltipForBaseFertilization(tooltipData);
  }

  // #region "private Methods"

  private _getPlanBookApplicationDates(planBooks: IBasicFertilisationPlanBooks[], isBooked: boolean): Date[] {
    if (!planBooks || planBooks.length <= 0) {
      return [];
    }
    const nonDeletedPlanBooks = planBooks.filter(x => !x.DeletedAt);
    const filteredPlanBooks = nonDeletedPlanBooks.filter(x => isBooked ? x.BookedAt : !x.BookedAt);
    const planBooksApplicationDates = filteredPlanBooks.filter(x => x.ApplicationDate)
      .map(x => x.ApplicationDate);
    return planBooksApplicationDates.map(x => {
      const farmDate = this.dateService.toFarmDate(new Date(x)).toDate();
      return this.dateService.getDateMidnight(farmDate);
    });
  }

  private _updateNutrientPlanningLayer(planBook: IBasicFertilisationPlanBooks): void {
    if (this.mapStore.Layers.NutrientPlanningLayer.Legend && this.mapStore.Layers.NutrientPlanningLayer?.Legend()?.selectionLists?.length > 0) {
      const legendItems = this.mapStore.Layers.NutrientPlanningLayer.Legend().selectionLists.FirstOrDefault();
      const selectedLegendElement = (legendItems.values as any[])
        .Find(_ => _?.value === this._getElementShort(planBook, planBook.Operation_Mode.Id === ApOperationMode.Const).toUpperCase()); // To upper case because we need product instead of element by default
      if (selectedLegendElement) {
        legendItems.selectedValue.next(selectedLegendElement);
        this.legendScrollerService.selectionChange.emit({
          legend: this.mapStore.Legends.NutrientPlanningLegend,
          selectionKeys: this.legendScrollerService.generateSelectionKey(this.mapStore.Legends.NutrientPlanningLegend).Split('_'),
        });
      }
    }
  }

  private _getElementShort(planBook: IBasicFertilisationPlanBooks, isConstant: boolean): string {
    if (isConstant) {
      const leadingElement = this.nutrientManagementService.getFertilizerLeadingElement(planBook.Product_Id);
      switch (leadingElement) {
        case 1:
          return 'p';
        case 2:
          return 'k';
        case 3:
          return 'mg';
        case 4:
          return 'ph';
      }
      return 'p';
    }
    let elementShort = planBook?.Element?.ShortDescription?.toLowerCase();
    if (!elementShort || elementShort === '') {
      elementShort = 'p';
    } else if (elementShort === 'cao') {
      elementShort = 'ph';
    }

    return elementShort;
  }

  private _deleteDialog(dataItem: any, deleteCallback: (dataItem: any) => any): void {
    this.modalDialogStore.setModalDialogData(this.modalDialogPresets.DeleteDialog());
    const modalDialogSubscription = this.modalDialogStore.Listen(s => s.result)
      .pipe(skip(1))
      .subscribe((result) => {
        modalDialogSubscription.unsubscribe();
        if (result.key === 'delete') {
          deleteCallback(dataItem);
        }
      });
  }

  private _redirectToTaskManagement(isShowView: boolean, summaryId: IGuid, isBooked: boolean, returnUrl: string, fieldId: IGuid | null): void {
    if (!returnUrl) {
      returnUrl = '';
    }
    const navigateObject = {
      id: summaryId,
      url: returnUrl
    };
    if (isShowView) {
      navigateObject['show'] = true;
    }
    if (isBooked) {
      navigateObject['bookings'] = true;
    }
    if (fieldId) {
      navigateObject['fieldId'] = fieldId;
    }
    this.routerStore.navigate(['task-management/overview'], navigateObject).then();
  }

  //#endregion "private Methods"
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Validators}                              from '@angular/forms';
import {merge}                                   from 'lodash';
import {ApDynformsComponent}                     from '../../ap-dynforms/ap-dynforms.component';
import {ApDynformsConfigFieldset} from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigTextbox,
  ApDynformsConfigTextboxType
}                                 from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsValidator}      from '../../ap-dynforms/ap-dynforms-validator';
import {ApDynformsConfigCheckbox}                from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {FormStore}                               from '../../stores/layout/form.store';
import {UserContactsStore}                       from '../../stores/base-data/user.contacts.store';
import {Observable, Subscription}                from 'rxjs';
import IUserContacts = Data.BaseData.IUserContacts;

enum UserContactEditMode {
  CREATE = 0,
  EDIT = 1
}

@Component({
  selector: 'ap-user-contacts-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_user_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_user_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`,
})
export class ApUserContactsEditComponent implements OnInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: IUserContacts;
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;
  public saving$: Observable<boolean>;
  private editMode: UserContactEditMode;
  private _subscriptions: Array<Subscription> = [];

  constructor(private formStore: FormStore,
              private userContactsStore: UserContactsStore) {
    this.saving$ = this.userContactsStore.Listen(s => s.saving);
    this._subscriptions.Add(this.userContactsStore.Listen(s => s.saved).subscribe(saved => {
        if (saved) {
          this.formStore.closeForm();
        }
      }
    ));
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.editMode = UserContactEditMode.CREATE;
      this.data = {
        Id: undefined,
        Lastname: '',
        Firstname: '',
        FarmName: '',
        MailAddress: '',
        IsActive: false,
        FarmId: undefined,
        Version: 0,
        ChangedAt: undefined,
        ChangedBy: undefined,
        CreatedAt: undefined,
        CreatedBy: undefined,
        DeletedAt: undefined,
        DeletedBy: undefined
      };
    } else {
      this.editMode = UserContactEditMode.EDIT;
    }
    this.caption = this.editMode === UserContactEditMode.CREATE ? 'Global_Contact_Create' : 'Global_Contact_Edit';
    this.formBuilder();
  }


  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigTextbox({
            key: 'Firstname',
            label: 'Global__Forename',
            type: ApDynformsConfigTextboxType.Text,
            value: this.data?.Firstname,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
              }),
              new ApDynformsValidator({
                validator: Validators.nullValidator,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(1),
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'Lastname',
            label: 'Global__Name',
            type: ApDynformsConfigTextboxType.Text,
            value: this.data?.Lastname,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
              }),
              new ApDynformsValidator({
                validator: Validators.nullValidator,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(1),
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'FarmName',
            label: 'Global_Organization',
            type: ApDynformsConfigTextboxType.Text,
            value: this.data?.FarmName,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
              }),
              new ApDynformsValidator({
                validator: Validators.nullValidator,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(1),
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'MailAddress',
            label: 'Global__Email',
            type: ApDynformsConfigTextboxType.Email,
            value: this.data?.MailAddress,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required',
              }),
              new ApDynformsValidator({
                validator: Validators.email,
                errorKey: 'Admin_Pages_Login_Reset_Password_Email_Error'
              }),
              new ApDynformsValidator({
                validator: Validators.nullValidator,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(1),
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigCheckbox({
            key: 'IsActive',
            label: 'Global__Active',
            value: this.data?.IsActive,
          })
        ]
      })
    ];
  }

  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    if (this.editMode === UserContactEditMode.CREATE) {
      this.userContactsStore.createUserContact(this.data);
    } else {
      this.userContactsStore.updateUserContact(this.data);
    }
  }

  onCancelClick(): void {
    this.formStore.closeForm();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }
}

import {Injectable}                            from '@angular/core';
import {Store}                                 from '../index';
import {IStateStore}                           from '../../ap-interface/interfaces/store';
import {ApTreeViewItem}                        from '../../ap-interface/interfaces/layout/ap-tree-view-item';
import {CheckableSettings, SelectableSettings} from '@progress/kendo-angular-treeview';
import {MapStore}                              from './map.store';
import {EventHandler}                          from 'ts-tooling';
import {ILayerControlLayer}                    from '../../ap-interface/map/layer-control-layer.interface';
import {ApSignalrService}                      from '../../ap-core/services/ap-signalr.service';

interface IMapTreeViewStore extends IStateStore<ApTreeViewItem> {
  checkSettings: CheckableSettings;
  selectSettings: SelectableSettings;
}

@Injectable({providedIn: 'root'})
export class MapTreeViewStore extends Store<IMapTreeViewStore> {
  constructor(public backend: ApSignalrService,
              private mapStore: MapStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      checkSettings: {},
      selectSettings: {},
    });
  }

  setup(data: ApTreeViewItem[]): void {
    this.cleanup();
    super.Mutate(s => s.data, () => data);
  }

  cleanup(): void {
    super.Mutate(s => s.data, () => []);
  }

  getLayers(): ApTreeViewItem {
    return {
      text: 'Layer',
      items: [
        this._getLayerTreeViewItem('FieldsLayer', this.mapStore.Layers.FieldsLayer),
        this._getLayerTreeViewItem('LocationsLayer', this.mapStore.Layers.LocationsLayer),
        this._getLayerTreeViewItem('TrackLayer', this.mapStore.Layers.TrackLayer),
        this._getLayerTreeViewItem('SoilSampleLayer', this.mapStore.Layers.SoilSampleLayer),
        this._getLayerTreeViewItem('FieldDistributionLayer', this.mapStore.Layers.DistributionLayer),
        this._getLayerTreeViewItem('FungiDetectLayer', this.mapStore.Layers.FungiDetectLayer),
        this._getLayerTreeViewItem('ContactLayer', this.mapStore.Layers.ContactLayer),
        this._getLayerTreeViewItem('PolygonEditLayer', this.mapStore.Layers.PolygonEditLayer),
        this._getLayerTreeViewItem('TaskManagementLayer', this.mapStore.Layers.TaskManagementLayer),
      ]
    };
  }

  private _getLayerTreeViewItem(text: string, layer: ILayerControlLayer): ApTreeViewItem {
    const result = {
      text,
      value: (() => layer.Visibility),
      onChange: new EventHandler<boolean>(),
    };
    result.onChange.Subscribe(text, (b) => layer.Visibility = b);
    return result;
  }
}

import {TranslationStore}       from '../../stores/translation/translation.store';
import {Injectable}             from '@angular/core';
import {Create}                 from 'ts-tooling';
import {SettingsStore}          from '../../stores/base-data/settings.store';
import {GetRoundNumericService} from '../../ap-utils/service/get-round-numeric.service';

export interface FieldsAreaTitleItem {
  AreaHa: number | undefined;
  Selected: boolean;
}

@Injectable({providedIn: 'root'})
export class StatisticService {
  constructor(private settingsStore: SettingsStore,
              private translationStore: TranslationStore,
              private roundNumericService: GetRoundNumericService) {
  }

  public generateFieldsAreaTitle(items: FieldsAreaTitleItem[]): string {
    if (!items || items.length <= 0) {
      return this._generateFieldsAreaMessage(0, 0, 0, 0, 0);
    }
    const totalFieldsCount = items.length;
    const areaSum = this._getAreaSum(items);
    const selectedItems = items.filter(x => x.Selected);
    if (selectedItems.length > items.length) {
      return this._generateFieldsAreaMessage(totalFieldsCount, totalFieldsCount, areaSum, areaSum, 100);
    }
    const selectedFieldsCount = selectedItems.length;
    const percentBetweenFields = (selectedFieldsCount / totalFieldsCount) * 100;
    const selectedAreaSum = this._getAreaSum(selectedItems);
    return this._generateFieldsAreaMessage(totalFieldsCount, selectedFieldsCount, areaSum, selectedAreaSum, percentBetweenFields);
  }

  private _generateFieldsAreaMessage(total: number, selected: number,
                                     totalArea: number, selectedArea: number, percents: number): string {
    const globalFields = this.translationStore.FindTranslationForSelectedLanguage('Global__Fields');
    const globalHaUnit = this.translationStore.FindTranslationForSelectedLanguage('Global__ha_Unit');
    const digitsAfterDecimalPoint = this.settingsStore.FirstSetting.DigitsAfterDecimalPoint;
    const roundedTotal = this.roundNumericService.round(totalArea, digitsAfterDecimalPoint);
    const roundedSelected = this.roundNumericService.round(selectedArea, digitsAfterDecimalPoint);
    return `${selected} / ${total} ${globalFields} | ${roundedSelected} / ${roundedTotal} ${globalHaUnit} | ${Math.round(percents)} %`;
  }

  private _getAreaSum(items: FieldsAreaTitleItem[]): number {
    return items.Reduce((target, e) => Create(e.AreaHa, 0) + target, 0);
  }
}

import {Component, Input}       from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'ap-tooltip',
  templateUrl: './ap-tooltip.component.html',
  styles: []
})
export class ApTooltipComponent {
  @Input() text: string;
  token: string[];

  constructor(private sanitizer: DomSanitizer) {
  }

  getHtml(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}

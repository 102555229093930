import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType,
  ApDynGridColumnHideConfig,
  IPipeDefinition
} from './ap-dyn-grid-column-config-base';

export class ApDynGridImageColumnConfig extends ApDynGridColumnConfigBase {
  public type = ApDynGridColumnControlType.Image;
  public title: string;
  public imageUrl: string;
  public tooltip: string;
  public imageWidth: string;
  public imageHeight: string;

  public constructor(options: {
    hide?: boolean | ApDynGridColumnHideConfig,
    field?: string,
    title?: string,
    key?: string,
    sortIndex?: number,
    sortDesc?: boolean,
    groupIndex?: number,
    groupDesc?: boolean,
    headerStyle?: any,
    width?: number,
    imageWidth?: string,
    imageHeight?: string,
    imageUrl?: string,
    tooltip?: string,
    cssClass?: 'left' | 'right',
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
  } = {}) {
    super(options);
    this.imageUrl = options.imageUrl;
    this.tooltip = options.tooltip ? options.tooltip : '';
    this.title = options.title ? options.title : ' ';
    this.width = options.width;
    this.imageWidth = options.imageWidth ? options.imageWidth : '32px';
    this.imageHeight = options.imageHeight ? options.imageHeight : '32px';
    this.groupIndex = undefined;
    this.sortIndex = undefined;
  }
}

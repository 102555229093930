<div class="histogramcontainer">
  <ng-container *ngIf="bands.length <= 0">
    <div class="empty-message">
      <p>{{emptyMessage | translate}}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="bands.length > 0">
    <div class="diagram-controls">
      <kendo-combobox [data]="bandsFilter"
                      [(value)]="selectedBand"
                      valueField="id"
                      textField="name"
                      [filterable]="true"
                      [allowCustom]="false"
                      (filterChange)="filterChange($event)"
                      (valueChange)="onSelectionChange($event)"
      ></kendo-combobox>
    </div>
    <kendo-chart [categoryAxis]="{ categories: calculatedData[selectedBand?.id]?.data.categories }">
      <kendo-chart-area [height]="calculatedData[selectedBand?.id]?.data.categories.length | dynamicChartHeight:65:82">
        <kendo-chart-title [text]="title" [color]="strokeColor"></kendo-chart-title>
        <kendo-chart-legend [visible]="false"></kendo-chart-legend>

        <kendo-chart-series>
          <kendo-chart-series-item type="bar"
                                   [class.test]="true"
                                   [color]="calculatedData[selectedBand?.id]?.color"
                                   [style]="'smooth'"
                                   [data]="calculatedData[selectedBand?.id]?.data.data"
                                   [name]="calculatedData[selectedBand?.id]?.data.name">
            <kendo-chart-series-item-tooltip [background]="'#ffffffbf'" [border]="'#ffffffbf'" [color]="'#000'">
              <ng-template let-value="value">
                {{value | getRoundNumeric:calculatedData[selectedBand?.id]?.decimalPlaces}}
              </ng-template>
            </kendo-chart-series-item-tooltip>
          </kendo-chart-series-item>
        </kendo-chart-series>

        <kendo-chart-value-axis>
          <kendo-chart-value-axis-item [title]="{ text: calculatedData[selectedBand?.id]?.axes?.titleX }"
                                       [color]="strokeColor"
                                       [min]="calculatedData[selectedBand?.id]?.axes?.minX"
                                       [max]="calculatedData[selectedBand?.id]?.axes?.maxX">
          </kendo-chart-value-axis-item>
        </kendo-chart-value-axis>

        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item [categories]="calculatedData[selectedBand?.id]?.data.categories"
                                          [title]="{ text: calculatedData[selectedBand?.id]?.axes?.titleY }"
                                          [labels]=""
                                          [color]="strokeColor">
          </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
      </kendo-chart-area>
    </kendo-chart>
  </ng-container>
</div>

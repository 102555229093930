import {NgModule}                      from '@angular/core';
import {ApGetAvailableUserGroupsPipe}  from './pipes/ap-get-available-usergroups.pipe';
import {ApGetAssignedUserGroupsPipe}   from './pipes/ap-get-assigned-usergroups.pipe';
import {GetCropPipe}                   from './pipes/ap-get-crop.pipe';
import {GetSecondCropStatisticPipe}    from './pipes/ap-get-second-crop-statistic.pipe';
import {GetMainCropStatisticPipe}      from './pipes/ap-get-main-crop-statistic.pipe';
import {ApGetLocalDatePipe}            from './pipes/ap-get-local-date.pipe';
import {ApGetUtcDatePipe}              from './pipes/ap-get-utc-date.pipe';
import {GetParameterTypePipe}          from './pipes/ap-get-parameter-type.pipe';
import {GetParameterRefObjectTypePipe} from './pipes/ap-get-parameter-ref-object-type.pipe';
import {ApGetTimeDurationStringPipe}   from './pipes/ap-get-time-duration-string.pipe';

@NgModule({
  declarations: [
    ApGetAvailableUserGroupsPipe,
    ApGetAssignedUserGroupsPipe,
    GetCropPipe,
    GetMainCropStatisticPipe,
    GetSecondCropStatisticPipe,
    ApGetLocalDatePipe,
    ApGetUtcDatePipe,
    GetParameterTypePipe,
    GetParameterRefObjectTypePipe,
    ApGetTimeDurationStringPipe,
  ],
  exports: [
    ApGetAvailableUserGroupsPipe,
    ApGetAssignedUserGroupsPipe,
    GetCropPipe,
    GetMainCropStatisticPipe,
    GetSecondCropStatisticPipe,
    ApGetLocalDatePipe,
    ApGetUtcDatePipe,
    GetParameterTypePipe,
    GetParameterRefObjectTypePipe,
    ApGetTimeDurationStringPipe,
  ],
  providers: [
    ApGetAvailableUserGroupsPipe,
    ApGetAssignedUserGroupsPipe,
    GetCropPipe,
    GetMainCropStatisticPipe,
    GetSecondCropStatisticPipe,
    ApGetLocalDatePipe,
    ApGetUtcDatePipe,
    GetParameterTypePipe,
    GetParameterRefObjectTypePipe,
    ApGetTimeDurationStringPipe,
  ]
})
export class AgriportStoreModule {
}

import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  Type,
  ViewChild
}                                from '@angular/core';
import {HostDirective}           from './host.directive';
import {DYNAMIC_LOAD_COMPONENTS} from '../index';

export interface ApDynamicComponent {
  data: any;
}

export class ApDynamicComponentModel {
  constructor(public type: Type<any> | string, public data: any = {}) {
  }
}

@Component({
  selector: 'ap-dynamic-component-loader',
  template: `
    <ng-template host></ng-template>`,
})
export class ApDynamicComponentLoaderComponent implements OnInit {

  @ViewChild(HostDirective, {static: true}) host: HostDirective;
  @Input() dynComp: ApDynamicComponentModel;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    if (typeof this.dynComp.type === 'string') {
      this.dynComp.type = DYNAMIC_LOAD_COMPONENTS.find(c => c.type === this.dynComp.type);
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynComp.type);

    const viewContainerRef = this.host.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as ApDynamicComponent).data = this.dynComp.data;
  }
}

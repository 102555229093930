<kendo-dropdownlist [id]="'mapSwitch'"
                    [data]="mapLayerControlStore.Maps$ | async"
                    [textField]="'text'"
                    [valueField]="'value'"
                    [value]="mapLayerControlStore.MapData$ | async"
                    (valueChange)="mapsChange($event)"
                    >
</kendo-dropdownlist>
<kendo-grid gridDragAndDrop
            [id]="'mapLayerControlGrid'"
            [data]="mapLayerControlStore.Data$ | async"
            [scrollable]="'none'"
            (rowDrop)="rowDrop($event)">
  <kendo-grid-column>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="ap-map-layer-control-element">
        <input type="checkbox"
               [checked]="dataItem.layer.Visibility"
               [class]="'k-checkbox'"
               [id]="'mapLayerControlId_' + dataItem.text"
               (click)="clickCheckBox(dataItem)">
        <label [attr.for]="'mapLayerControlId_' + dataItem.text"
               [class]="'k-checkbox-label'">
          <ap-responsive-text [key]="dataItem.text"></ap-responsive-text>
        </label>
        <div [className]="'buttonsGroup'">
          <div [className]="'buttonsAlignment'">
            <button
              kendoButton
              [look]="'flat'"
              [icon]="'arrow-60-up'"
              [class]="'up'"
              (click)="reorder(dataItem, 1)">
            </button>
            <button
              kendoButton
              [look]="'flat'"
              [icon]="'arrow-60-down'"
              [class]="'down'"
              (click)="reorder(dataItem, -1)">
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

import {IStateStore}              from '../../ap-interface/interfaces/store';
import {Store}                    from '../index';
import {EventEmitter, Injectable} from '@angular/core';
import {ApStatsType}              from '../../ap-interface/enums';
import {AxisLabelVisualArgs}      from '@progress/kendo-angular-charts';
import {drawing}                  from '@progress/kendo-drawing';
import {SafeBehaviorSubject}      from 'ts-tooling';
import {map}                      from 'rxjs/operators';
import {Observable}               from 'rxjs';
import {ApSignalrService}         from '../../ap-core/services/ap-signalr.service';

export enum ChartSetValueType {
  CLASS,
  DATE,
  SOIL_GROUP,
  REGION,
  LANDUSE,
  METHOD,
  DEFAULT,
}

export interface IChartAxesOptions {
  titleX: string;
  titleY: string;
  maxX: number;
  minX: number;
}

export interface IChartSet<T> {
  valueType: ChartSetValueType;
  axes: IChartAxesOptions;
  data: T;
  visual?: (item: IChartSet<T>) => (e: AxisLabelVisualArgs) => drawing.Element;
  decimalPlaces?: number;
  color?: (p: any) => string;
  meta: { [key: string]: any };
  step: number;
  tooltipDelegate?: (item: any, charSet: IChartSet<T>) => string;
  noteDelegate?: (item: any, charSet: IChartSet<T>) => string;
  noteField?: string;
}

interface IStatisticStoreItem {
  chartData: any;
  type: ApStatsType;
  title: string;
  emptyMessage: string;
}

interface IStatisticStore extends IStateStore<IStatisticStoreItem> {
  selection: { text: string, value: number }[];
  selectedValue: number;
}

@Injectable({providedIn: 'root'})
export class StatisticStore extends Store<IStatisticStore> {
  public selectedValueChange = new EventEmitter<{ text: string, value: number }>();

  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loading: true,
      loaded: false,
      selection: [],
      selectedValue: 0,
    });
  }

  get Selection$(): SafeBehaviorSubject<{ text: string, value: number }[]> {
    return super.Listen(s => s.selection);
  }

  get SelectedValue$(): Observable<{ text: string, value: number }> {
    return super.Listen(s => s.selectedValue).pipe(
      map(v => ({text: v.toString(), value: v})),
    );
  }

  get Data$(): SafeBehaviorSubject<IStatisticStoreItem[]> {
    return super.Listen(s => s.data);
  }

  get Data(): IStatisticStoreItem[] {
    return this.Data$.getValue();
  }

  get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  get Loading(): boolean {
    return this.Loading$.getValue();
  }

  /**
   * Assigns a single statistic type to the statistic section
   * @param data the statistic type's data (different for every type of statistic)
   * @param statType the statistic type
   * @param title the statistic's title
   * @param emptyMessage optional text when statistic is empty
   */
  setStatisticData<T>(data: T[], statType: ApStatsType, title: string, emptyMessage = ''): void {
    super.Mutate(s => s.data, () => [{chartData: data, type: statType, title, emptyMessage}]);
  }

  /**
   * Adds another statistic type to the statistic section
   * @param data the statistic type's data (different for every type of statistic)
   * @param statType the statistic type
   * @param title the statistic's title
   * @param emptyMessage optional text when statistic is empty
   */
  addStatisticData<T>(data: T[], statType: ApStatsType, title: string, emptyMessage = ''): void {
    super.Mutate(s => s.data, storeObject => {
      if (!storeObject) {
        storeObject = [];
      }
      storeObject.push({chartData: data, type: statType, title, emptyMessage});
      return storeObject;
    });
  }

  /**
   * Die Funktion wird benötigt, um sicherzustellen,
   * dass die Statistik, die entfernt wird auch genau diesem Type entspricht.
   * Der Usecase, der das notwendig macht:
   * - Statistik wird in einer entry-component angezeigt (z.B. neue N-Planung)
   * - Nutzer navigiert über das Menü zu einer anderen Komponente mit Statistik (z.B. Feldübersicht)
   * - Im destroy der entry-component wird die Statistik sauber entfernt
   * - Allerdings wird das ngDestroy erst nach dem Init der neuen Komponente aufgerufen
   * - Dadurch wird im ngDestroy die neue Statistik der anderen Komponente entfernt
   */
  removeStatisticDataByType(typeToRemove: ApStatsType): void {
    super.Mutate(s => s.data, storeObject => {
      if (!storeObject) {
        storeObject = [];
        return storeObject;
      }
      storeObject.RemoveAll(s => s.type === typeToRemove);
      return storeObject;
    });
  }

  removeStatisticData(): void {
    super.Mutate(s => s.data, () => []);
  }

  addSelection(selection: { text: string, value: number }[]): void {
    super.Mutate(s => s.selection, () => selection);
  }

  clearSelection(): void {
    super.Mutate(s => s.selection, () => []);
  }

  selectFromSelection(value: number): void {
    if (!this.Selection$.getValue().Find(s => s?.value === value)) {
      return;
    }
    super.Mutate(s => s.selectedValue, () => value);
  }
}

import {schema, Schema} from '@progress/kendo-angular-editor';

const iframe = {
  // NodeSpec interface
  // http://prosemirror.net/docs/ref/#model.NodeSpec
  attrs: {
    src: {default: null},
    style: {default: null},
    width: {default: null},
    height: {default: null}
  },
  group: 'block',
  selectable: false,
  parseDOM: [{
    tag: 'iframe',
    getAttrs: (dom) => ({
      src: dom.getAttribute('src'),
      width: dom.getAttribute('width'),
      height: dom.getAttribute('height'),
      style: dom.getAttribute('style')
    })
  }],
  toDOM: node => {
    const attrs = {
      src: node.attrs.src,
      style: node.attrs.style,
      width: node.attrs.width,
      height: node.attrs.height,
      frameborder: '0',
      allowfullscreen: 'true'
    };
    return ['iframe', attrs];
  }
};

export {iframe};

// Append the new node.
let nodes: any = schema.spec.nodes;
nodes = nodes.addToEnd('iframe', iframe);
const marks = schema.spec.marks;

// Create the new schema.
export const ApEditorSchema: Schema = new Schema({nodes, marks});

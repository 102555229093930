import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {ApDynformsValidator}                         from '../ap-dynforms-validator';
import {AsyncValidatorFn, ValidatorFn}               from '@angular/forms';
import {Observable}                                  from 'rxjs';

/**
 * configuration of textarea
 */
export class ApDynformsConfigTextarea extends ApDynformsConfigBase<string> {
  controlType = ApDynformsControltype.Textarea;
  rows = 4;

  constructor(options: {
    value?: any,
    value$?: Observable<any>,
    key?: string,
    label?: string,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    rows?: number,
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    dependsOn?: string[],
    formErrors?: string[],
    cssClass?: string,
  } = {}) {
    super(options);
    this.rows = options.rows;
  }
}

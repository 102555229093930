import {ApDynformsConfigBase} from './ap-dynforms-config-base';
import {chunk}                from 'lodash';

export class ApDynformsConfigArray {
  columns: number;
  key: string;
  legend: string;
  label: string;
  min: number;
  max: number;
  validate: (x) => boolean;
  extendOn: (x) => boolean;
  config: ApDynformsConfigBase<any>[];
  configChunk: ApDynformsConfigBase<any>[][];

  constructor(options: {
    legend?: string,
    columns?: number,
    label?: string,
    key?: string,
    config?: ApDynformsConfigBase<any>[],
    min?: number,
    max?: number,
    validate?: (x) => boolean,
    extendOn?: (x) => boolean
  } = {}) {
    this.key = options.key;
    this.legend = options.legend;
    this.label = options.label;
    this.min = Math.min(Math.max(options.min, 0), Math.max(options.max, 0));
    this.max = Math.max(Math.max(options.min, 0), Math.max(options.max, 0));
    this.columns = options.columns;
    this.config = options.config;
    this.configChunk = chunk(options.config, this.columns);
    this.validate = options.validate;
    this.extendOn = options.extendOn ? options.extendOn : options.validate;
  }
}

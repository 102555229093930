import {
  AfterContentInit,
  Component,
  Input,
  OnDestroy, OnInit,
  ViewChild,
  ViewEncapsulation
}                                                 from '@angular/core';
import {ApDynformsConfigDateRange}                from './config/ap-dynforms-config-date-range';
import {AsyncValidatorFn, FormGroup, ValidatorFn} from '@angular/forms';
import {DateRangePopupComponent}                  from '@progress/kendo-angular-dateinputs';
import {LanguageStore}                            from '../stores/translation/language.store';
import ILanguage = Data.Language.ILanguage;
import {BehaviorSubject, Subscription}            from 'rxjs';
import {ApDynformsValidator}                      from './ap-dynforms-validator';

@Component({
  selector: 'ap-dynforms-daterange',
  templateUrl: './ap-dynforms-date-range.component.html',
  styleUrls: ['./ap-dynforms-date-range.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApDynformsDateRangeComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('popupComponent', {static: true}) popup: DateRangePopupComponent;

  @Input() config: ApDynformsConfigDateRange;
  @Input() startPos: { column: number, row: number };
  @Input() endPos: { column: number, row: number };
  @Input() darkMode = false;
  @Input() parentKey: string;
  @Input() parentIndex: number;
  @Input() form: FormGroup;
  @Input() fontSize = 16;
  @Input() columnGap;

  startKey: string;
  endKey: string;
  language: ILanguage;
  disabled$ = new BehaviorSubject<boolean>(false);
  private _subscriptions: Subscription[] = [];

  constructor(private languageStore: LanguageStore) {
    this.language = this.languageStore.SelectedLanguage;
  }

  public ngOnInit(): void {
    this.startKey = this.config.key + '_Start';
    this.endKey = this.config.key + '_End';
  }

  public ngAfterContentInit(): void {
    if (this.config.disabled$) {
      this._subscriptions.push(this.config.disabled$.subscribe((v) => {
        this.disabled$.next(v);
        if (v) {
          this.form.get(this.startKey).disable();
          this.form.get(this.endKey).disable();
        } else {
          this.form.get(this.startKey).enable();
          this.form.get(this.endKey).enable();
        }
      }));
    } else {
      this.disabled$.next(this.config.disabled === true);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * determins current validation errors(translation key)
   * for this dynamic form element/control
   */
  getErrors(key: string): string[] {
    const validationMessages: string[] = [];
    const control = this.form.get(key);
    if (control && control.errors) {
      this.config.validators.forEach((validator: ApDynformsValidator<ValidatorFn>) => {
        if ((validator.always || control.touched || control.dirty) && control.errors[validator.errorKey]) {
          validationMessages.push(validator.errorKey);
        }
      });

      this.config.asyncValidators.forEach((validator: ApDynformsValidator<AsyncValidatorFn>) => {
        if ((validator.always || control.touched || control.dirty) && control.errors[validator.errorKey]) {
          validationMessages.push(validator.errorKey);
        }
      });
    }

    if (this.form.errors) {
      this.config.formErrors.forEach((formError: string) => {
        if (this.form.errors[formError]) {
          validationMessages.push(formError);
        }
      });
    }

    return validationMessages.filter((message) => message !== '');
  }

  public getHtmlId(key): string {
    let prefix = '';
    if (this.parentKey) {
      prefix = `${this.parentKey}.`;
    }
    if (this.parentIndex !== undefined) {
      prefix = `${prefix}${this.parentIndex}.`;
    }
    return prefix.concat(key);
  }
}

import {animate, state, style, transition, trigger} from '@angular/animations';
import {ApVisibleStates}                            from '../ap-interface/enums';

export const TRANSLATE_COMPONENT = trigger('translateComponent', [
  state(ApVisibleStates.IN, style({
    display: 'none'
  })),
  state(ApVisibleStates.OUT, style({
    display: 'unset'
  })),
  transition(`${ApVisibleStates.IN} => ${ApVisibleStates.OUT}`, animate(100)),
  transition(`${ApVisibleStates.OUT} => ${ApVisibleStates.IN}`, animate(100)),
]);

import {Pipe, PipeTransform} from '@angular/core';
import {Moment}              from 'moment';
import {SettingsStore}       from '../base-data/settings.store';
import {ApDateService}       from '../../ap-core/services/ap-date-service';

@Pipe({
  name: 'getUtcDate'
})
export class ApGetUtcDatePipe implements PipeTransform {
  constructor(private settingsStore: SettingsStore,
              private dateService: ApDateService) {
  }

  transform(date: Date | string = null, ...args: string[]): Moment {
    return this.dateService.getUtcDate(date, args[0], this.settingsStore.FirstSetting);
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import ICropTypeSensorMap = Data.BaseData.ICropTypeSensorMap;

export enum CropTypeSensorMapActions {
  CROP_TYPE_SENSOR_MAP_LOAD = '[crop_type_sensor_map] load',
  CROP_TYPE_SENSOR_MAP_LOAD_SUCCESS = '[crop_type_sensor_map] load success',
}

/**
 * load crop type sensor map
 */
export class CropTypeSensorMapLoad implements IAction {
  readonly type = CropTypeSensorMapActions.CROP_TYPE_SENSOR_MAP_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * crop type sensor map load is success
 */
export class CropTypeSensorMapLoadSuccess implements IAction {
  readonly type = CropTypeSensorMapActions.CROP_TYPE_SENSOR_MAP_LOAD_SUCCESS;

  constructor(public payload: ICropTypeSensorMap[]) {
  }
}

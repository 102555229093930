import {AfterViewInit, Component, Input}             from '@angular/core';
import {FilterService}                               from '@progress/kendo-angular-grid';
import {CompositeFilterDescriptor, FilterDescriptor} from '@progress/kendo-data-query';
import {get}                                         from 'lodash';

@Component({
  selector: 'ap-multi-select-filter',
  templateUrl: './ap-multi-select-filter.component.html'
})
export class ApMultiSelectFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public filterService: FilterService;
  @Input() public items;
  @Input() public key;

  public data: any[];
  public dataFilter: any[];
  public value: any[];

  public ngAfterViewInit(): void {
    if (!this.items || this.items?.length === 0) {
      return;
    }
    this.data = Array.from(new Set(this.items.map(i => {
      const value = get(i, this.key);
      if (value === undefined) {
        return null;
      }
      return value;
    }))).sort();
    this.dataFilter = this.data;
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
  }

  public onChange(values: any[]): void {
    this.filterService.filter({
      filters: values.map(value => ({
        field: this.key,
        operator: value === null ? 'isnull' : 'eq',
        value
      })),
      logic: 'or'
    });
  }

  public onFilterChange(filter: string): void {
    if (!this.data) {
      this.dataFilter = [];
      return;
    }
    this.dataFilter = this.data.FindAll((d: any) => {
      if (!d) {
        return (!d) === (!filter);
      }
      let value = d;
      // in case the value is not a string and both value and textfield are
      // defined we are going to extract the value from the given object
      // otherwise we continue with the plain value and treat it as a string
      if (typeof value !== typeof '' && this.valueField && this.textField) {
        value = d[this.textField];
      }
      if (!value) {
        return false;
      }
      return value?.toString().ToLowerCase().IndexOf(filter.ToLowerCase()) !== -1;
    });
  }
}

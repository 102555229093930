import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CompositeFilterDescriptor, FilterDescriptor} from '@progress/kendo-data-query';
import {FilterService}                               from '@progress/kendo-angular-grid';

@Component({
  selector: 'ap-status-switch-filter',
  templateUrl: './ap-status-switch-filter.component.html',
  styleUrls: ['./ap-status-switch-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApStatusSwitchFilterComponent implements OnInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public items;
  @Input() public key;

  public data: any[];
  public value: any;

  public ngOnInit(): void {
    this.data = [-1, 0, 1];
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
  }

  public onChange(values: any[]): void {
    this.filterService.filter({
      filters: values.map(value => ({
        field: this.key,
        operator: value === null ? 'isnull' : 'eq',
        value
      })),
      logic: 'or'
    });
  }
}

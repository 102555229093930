import {IAction, IApInvokerArguments} from '../../interfaces';
import IBasicFertilisationSummaries = Data.TaskManagement.IBasicFertilisationSummaries;

export enum BasicFertilisationSummariesActions {
  LOAD_BASIC_FERTILISATION_SUMMARIES = '[basic_fertilisation_summaries] load',
  LOAD_BASIC_FERTILISATION_SUMMARIES_SUCCESS = '[basic_fertilisation_summaries] load success',
  LOAD_BASIC_FERTILISATION_SUMMARIES_FAIL = '[basic_fertilisation_summaries] load fail',
  BASIC_FERTILISATION_SUMMARIES_CREATE = '[basic_fertilisation_summaries] create',
  BASIC_FERTILISATION_SUMMARIES_UPDATE = '[basic_fertilisation_summaries] update',
  BASIC_FERTILISATION_SUMMARIES_SAVE_SUCCESS = '[basic_fertilisation_summaries] save success',
  BASIC_FERTILISATION_SUMMARIES_SAVE_FAIL = '[basic_fertilisation_summaries] save fail',
  BASIC_FERTILISATION_SUMMARIES_SET_STATUS = '[basic_fertilisation_summaries] set status',
  BASIC_FERTILISATION_SUMMARIES_DUPLICATE = '[basic_fertilisation_summaries] duplicate',
  BASIC_FERTILISATION_SUMMARIES_BOOKING = '[basic_fertilisation_summaries] booking',
  BASIC_FERTILISATION_SUMMARIES_RESET = '[basic_fertilisation_summaries] reset',
  BASIC_FERTILISATION_PLAN_BOOKS_UPDATE = '[basic_fertilisation_plan_books] update',
  BASIC_FERTILISATION_PLAN_BOOKS_SAVE_SUCCESS = '[basic_fertilisation_plan_books] save success',
  BASIC_FERTILISATION_PLAN_BOOKS_SAVE_FAIL = '[basic_fertilisation_plan_books] save fail',
  BASIC_FERTILISATION_PLAN_BOOKS_DELETE = '[basic_fertilisation_plan_books] delete',
  BASIC_FERTILISATION_SHOW_MAP = '[basic_fertilisation_summaries] show map',
}

export class LoadBasicFertilisationSummaries implements IAction {
  readonly type = BasicFertilisationSummariesActions.LOAD_BASIC_FERTILISATION_SUMMARIES;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadBasicFertilisationSummariesSuccess implements IAction {
  readonly type = BasicFertilisationSummariesActions.LOAD_BASIC_FERTILISATION_SUMMARIES_SUCCESS;

  constructor(public payload: IBasicFertilisationSummaries[]) {
  }
}

export class LoadBasicFertilisationSummariesFail implements IAction {
  readonly type = BasicFertilisationSummariesActions.LOAD_BASIC_FERTILISATION_SUMMARIES_FAIL;
}

export class BasicFertilisationSummariesCreate implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationSummariesUpdate implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationsShowMap implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SHOW_MAP;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationSummariesSaveSuccess implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_SAVE_SUCCESS;

  constructor(public payload: IBasicFertilisationSummaries) {
  }
}

export class BasicFertilisationSummariesSaveFail implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

export class BasicFertilisationSummariesSetStatus implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_SET_STATUS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationSummariesDuplicate implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_DUPLICATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationSummariesBooking implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_BOOKING;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationSummariesReset implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_SUMMARIES_RESET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationPlanBooksUpdate implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_PLAN_BOOKS_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class BasicFertilisationPlanBooksSaveSuccess implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_PLAN_BOOKS_SAVE_SUCCESS;

  constructor(public payload: IBasicFertilisationSummaries) {
  }
}

export class BasicFertilisationPlanBooksSaveFail implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_PLAN_BOOKS_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

export class BasicFertilisationPlanBooksDelete implements IAction {
  readonly type = BasicFertilisationSummariesActions.BASIC_FERTILISATION_PLAN_BOOKS_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

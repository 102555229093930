export enum ApDynGridDetailsConfigType {
  None = 'None',
  Grid = 'Grid',
}

export class ApDynGridDetailsBaseConfig {
  type = ApDynGridDetailsConfigType.None;
  hide: (x) => boolean = () => false;

  constructor(options: {
    hide?: (x) => boolean
  } = {}) {
    this.hide = options.hide ? options.hide : () => false;
  }

  showIf(dataItem, index: number): boolean {
    return !this.hide(dataItem);
  }
}

import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';
import {ApDynformsValidator}                         from '../ap-dynforms-validator';
import {AsyncValidatorFn, ValidatorFn}               from '@angular/forms';
import {combineLatest, Observable, of}               from 'rxjs';
import {map}                                         from 'rxjs/operators';
import {ApUtilService}                               from '../../ap-utils/service/ap-util.service';
import NumberFormatOptions = Intl.NumberFormatOptions;

/**
 * configuration for numerictextbox control
 */
export class ApDynformsConfigNumerictextbox extends ApDynformsConfigBase<number> {
  controlType = ApDynformsControltype.NumericTextbox;
  format: string | NumberFormatOptions;
  spinners: boolean;
  decimals: number;
  step: Observable<number>;
  min: Observable<number>;
  max: Observable<number>;
  autoCorrect = true;
  placeholder: string;
  readonly: boolean;

  constructor(options: {
    value?: number,
    value$?: Observable<number>,
    key?: string,
    label?: string,
    label$?: Observable<string>,
    disabled?: boolean,
    disabled$?: Observable<boolean>,
    decimals?: number,
    validators?: ApDynformsValidator<ValidatorFn>[],
    asyncValidators?: ApDynformsValidator<AsyncValidatorFn>[],
    listenUpdate?: Observable<any>[],
    format?: string,
    spinners?: boolean,
    step?: number | Observable<number>,
    min?: number | Observable<number>,
    max?: number | Observable<number>,
    autoCorrect?: boolean,
    minimumFractionDigits?: number,
    maximumFractionDigits?: number,
    dependsOn?: string[],
    dynamicSteps?: number | Observable<number>,
    formErrors?: string[],
    infoText?: string,
    cssClass?: string,
    placeholder?: string,
    readonly?: boolean
  } = {}) {
    super(options);
    this.spinners = options.spinners === undefined ? true : options.spinners;

    this.min = ApUtilService.asObservable(options.min);
    this.max = ApUtilService.asObservable(options.max);
    this.autoCorrect = options.autoCorrect !== false;
    this.infoText = options.infoText || '';
    this.placeholder = options.placeholder || '';
    this.readonly = !!options.readonly;

    if (options.step !== undefined) {
      this.step = ApUtilService.asObservable(options.step);
    } else if (options.dynamicSteps !== undefined) {
      const dynamicStep = ApUtilService.asObservable(options.dynamicSteps);
      this.step = combineLatest([this.min, this.max, dynamicStep]).pipe(
        map(([min, max, step]) => {
          if (min !== null && max !== null) {
            return Math.abs(max - min) * step;
          } else {
            return undefined;
          }
        }),
      );
    } else {
      this.step = of(1);
    }

    this.decimals = options.decimals;
    if (options.format) {
      this.format = options.format;
    } else if (options.minimumFractionDigits !== undefined || options.maximumFractionDigits !== undefined) {
      this.format = {
        maximumFractionDigits: options.maximumFractionDigits,
        minimumFractionDigits: options.minimumFractionDigits,
      };
    } else if (options.decimals !== undefined) {
      this.format = `n${this.decimals}`;
    }
  }
}

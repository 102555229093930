import {Component, ElementRef, ViewChild}             from '@angular/core';
import {ContextMenuComponent, ContextMenuSelectEvent} from '@progress/kendo-angular-menu';
import {ApMenuUrls}                                   from '../../ap-interface';
import {FieldStore}                                   from '../../stores/farm/field.store';
import {TranslationStore}                             from '../../stores/translation/translation.store';
import {Offset}                                       from '@progress/kendo-angular-popup';
import {ApMapInstance, LayerNames}                    from '../../ap-map';
import {RouterStore}                                  from '../../stores/router/router.store';
import {ApShowTrackOnMapService}                      from '../../ap-utils/service/ap-show-track-on-map.service';
import {KrigingStore}                                 from '../../stores/administration/kriging.store';
import {LoginStore}                                   from '../../stores/login/login.store';
import {FieldService}                                 from '../../field-management/components/field.service';
import {ApRoleTypeService}                            from '../../services/common/ap-role-type.service';

export interface IFieldContextMenuItem {
  text: string;
  key: string;
  args: any[];
}

export interface IFieldContextMenuItems {
  text: string;
  items: IFieldContextMenuItem[];
}

@Component({
  selector: 'ap-map-context-menu',
  template: `
    <kendo-contextmenu (select)="onSelect($event)"></kendo-contextmenu>
  `,
})
export class ApMapContextMenuComponent {

  @ViewChild(ContextMenuComponent, {static: true})
  contextMenu: ContextMenuComponent;

  constructor(private fieldStore: FieldStore,
              private fieldService: FieldService,
              private krigingStore: KrigingStore,
              private loginStore: LoginStore,
              private routerStore: RouterStore,
              private showTrackOnMapService: ApShowTrackOnMapService,
              private translationStore: TranslationStore) {
  }

  async onSelect($event: ContextMenuSelectEvent): Promise<void> {
    const args = $event.item.args;
    switch ($event.item.key) {
      case 'interpolate':
        if (ApRoleTypeService.hasAgriconRole(this.loginStore.User)) {
          // only the Admin can interpolate the Fields
          this.krigingStore.createAllRastersFor(args[1].id);
        }
        break;
      case 'navigate':
        await this.routerStore.navigate([args[0]], args[1].id);
        break;
      case 'execute':
        args[0](...args[1]);
        break;
      case 'newfield':
        this.fieldService.OpenCreateForm();
        break;
    }
  }

  setContextMenu(features: { layer: string, id: string | number }[]): void {
    this.contextMenu.items = [];
    features.forEach(f => {
      switch (f.layer) {
        case LayerNames.FIELDS:
          this.contextMenu.items.AddRange(this._getFieldContextMenu(f.id.toString()));
          break;
        case LayerNames.TRACK:
          this.contextMenu.items.AddRange(this._getTrackContextMenu());
          break;
      }
    });

    this._createFieldContextMenu();
  }

  private _createFieldContextMenu(): void {
    if (ApMapInstance.routerStore.CurrentUrl === ApMenuUrls.N_FERTILIZATION_LOG_FILES
      || ApMapInstance.routerStore.CurrentUrl === ApMenuUrls.FIELD_WORK_ACTION_LOOKUP) {
      this.contextMenu.items.AddRange([{
        text: this.translationStore.FindTranslationForSelectedLanguage('Global_Create_Field'),
        key: 'newfield',
        args: []
      }]);
    }
  }

  hide(): void {
    this.contextMenu.hide();
  }

  show(target: Offset | HTMLElement | ElementRef): void {
    if (this.contextMenu.items.length > 0) {
      this.contextMenu.show(target);
    }
  }

  private _getFieldContextMenu(id: string): IFieldContextMenuItems[] {
    const field = this.fieldStore.getFieldByFieldGeomId(id);
    const name = this.fieldStore.getFieldNameById(id);
    const fieldTranslation = this.translationStore.FindTranslationForSelectedLanguage('Global__Field');
    const items: IFieldContextMenuItem[] = [];
    items.Add({
      text: this.translationStore.FindTranslationForSelectedLanguage('Docu_Ini__FieldDiary'),
      key: 'navigate',
      args: [ApMenuUrls.EVALUATION_FIELD_DIARY, {id: field.Id.toString()}],
    });
    if (ApRoleTypeService.hasAgriconRole(this.loginStore.User)) {
      // add Admin Operations
      items.Add({
        text: this.translationStore.FindTranslationForSelectedLanguage('Nutrients__Create_Nutrient_Distribution_Map'),
        key: 'interpolate',
        args: [ApMenuUrls.EVALUATION_FIELD_DIARY, {id}],
      });
    }
    return [
      {
        text: name ? `${fieldTranslation}: ${name}` : fieldTranslation,
        items,
      }
    ];
  }

  private _getTrackContextMenu(): IFieldContextMenuItems[] {
    return [{
      text: this.translationStore.FindTranslationForSelectedLanguage('Global__Track'), items: [
        {
          text: this.translationStore.FindTranslationForSelectedLanguage('Api_Enums__Speed'),
          key: 'execute',
          args: [(s: ApShowTrackOnMapService) => s.setType(0), [this.showTrackOnMapService]]
        },
        {
          text: this.translationStore.FindTranslationForSelectedLanguage('Api_Enums__FuelRate'),
          key: 'execute',
          args: [(s: ApShowTrackOnMapService) => s.setType(1), [this.showTrackOnMapService]]
        },
        {
          text: this.translationStore.FindTranslationForSelectedLanguage('Api_Enums__EngineSpeed'),
          key: 'execute',
          args: [(s: ApShowTrackOnMapService) => s.setType(2), [this.showTrackOnMapService]]
        },
        {
          text: this.translationStore.FindTranslationForSelectedLanguage('Api_Enums__ApplicationRate'),
          key: 'execute',
          args: [(s: ApShowTrackOnMapService) => s.setType(3), [this.showTrackOnMapService]]
        },
        {
          text: this.translationStore.FindTranslationForSelectedLanguage('Api_Enums__Tank'),
          key: 'execute',
          args: [(s: ApShowTrackOnMapService) => s.setType(4), [this.showTrackOnMapService]]
        },
        {
          text: this.translationStore.FindTranslationForSelectedLanguage('Api_Enums__WorkState'),
          key: 'execute',
          args: [(s: ApShowTrackOnMapService) => s.setType(5), [this.showTrackOnMapService]]
        }
      ]
    }];
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IApSvgPath}                             from '../../../ap-interface/interfaces';

/**
 * a Section Header
 * every Section Header supports a Icon and a Caption
 * the Filter and Create Operation can be disabled by pass canCreate or canSearch false
 */
@Component({
  selector: 'ap-section-header-grid',
  templateUrl: 'ap-section-header-grid.component.html'
})
export class ApSectionHeaderGridComponent {
  /**
   * value that the User written in the Textbox
   */
  public searchValue = '';

  /**
   * Id of the Icon to show
   */
  @Input()
  public iconId: string;
  /**
   * the Caption to show
   * supports translation keys
   */
  @Input()
  public caption: string;
  /**
   * the Placeholder Text for the Filter Input
   * supports translation keys
   */
  @Input()
  public filterPlaceholder: string;
  /**
   * the Tooltip that is show when the Mouse is over the Input element
   * supports translation keys
   * TODO: set perpetual Property when is available
   */
  @Input()
  public filterTooltip: string;
  /**
   * the Text for the Create Button
   * supports translation keys
   */
  @Input()
  public createText = 'Global__New';
  /**
   * adds or remove the Create Button enabled by default
   */
  @Input()
  public canCreate = true;
  /**
   * adds or remove the Filter Controls enabled by default
   */
  @Input()
  public canSearch = true;

  /**
   * adds or remove the create button show by default
   */
  @Input()
  public showCanCreateButton = true;

  /**
   * Id of the Icon to show
   */
  @Input()
  public svg: IApSvgPath = undefined;

  /**
   * Event that is fired when the User click on the Search Button in Filter
   */
  @Output()
  public search = new EventEmitter<string>();
  /**
   * Event that is fired when the User click on the Create Button
   */
  @Output()
  public createDataset = new EventEmitter<void>();

  /**
   * Event that is fired when the User click on the Clear all filters Button
   */
  @Output()
  public clearAllFilters = new EventEmitter<void>();

  /**
   * Create Button Click
   */
  onCreateClick(): void {
    this.createDataset.emit();
  }

  /**
   * Search Button Click
   */
  onSearchClick(): void {
    this.search.emit(this.searchValue);
  }

  /**
   * keyup on the Filter Input
   */
  onTextChange(e): void {
    this.searchValue = e.target.value;
    this.onSearchClick();
  }

  onResetClick(): void {
    this.searchValue = '';
    this.search.emit(this.searchValue);
  }

  /**
   * Resets filter of section header instance.
   */
  public reset(): void {
    this.onResetClick();
  }

  onClearAllFiltersClick(): void {
    this.clearAllFilters.emit();
  }
}

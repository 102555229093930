<kendo-multiselect
  [class.dark-bg]="true"
  [data]="dataFilter"
  [filterable]="true"
  [valuePrimitive]="isPrimitive"
  [textField]="textField"
  [valueField]="valueField"
  [value]="value"
  (valueChange)="onChange($event)"
  (filterChange)="onFilterChange($event)">
</kendo-multiselect>

/**
 * represent the Modes of the Sidebar Menu
 */
export enum SidebarMode {
  // Full Size of the Sidebar Menu
  OPEN = 'SIDEBAR_OPEN',
  // Small Sidebar Menu
  HALF = 'SIDEBAR_HALF',
  // Sidebar Menu hidden
  CLOSE = 'SIDEBAR_CLOSE',
  // Sidebar Menu for mobile Devices (not used yet)
  MOBILE = 'SIDEBAR_MOBILE'
}

import { Pipe, PipeTransform }         from '@angular/core';
import {ApDynGridTemplateColumnConfig} from '../config/ap-dyn-grid-template-column-config';

@Pipe({
  name: 'getTemplateContext'
})
export class GetTemplateContextPipe implements PipeTransform {

  transform(config: ApDynGridTemplateColumnConfig, dataItem?: any): any {
    return config.getContext(dataItem);
  }

}

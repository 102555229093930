import {IStateStoreWritable} from '../../ap-interface';
import {Store}               from '../index';
import {Injectable}          from '@angular/core';
import {
  ApCustomTypes,
  NetTypes,
  UserCheckUniqueEmail,
  UserCheckUniqueEmailResult,
  UserCheckUniqueUsername,
  UserCheckUniqueUsernameResult,
  UserCreate,
  UserCreateSuccess,
  UsersLoad,
  UsersLoadSuccess,
  UserUpdateFullName,
  UserUpdateFullNameSuccess,
  UserUpdateFullNameFail,
  UserResetRedirectToNews
}                            from 'invoker-transport';
import IUser = Data.Authentication.IUser;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IUserStore extends IStateStoreWritable<IUser> {
  isUniqueEmail: boolean;
  isUniqueUsername: boolean;
  isNewUserCreated: IUser;
}

@Injectable({providedIn: 'root'})
export class UserStore extends Store<IUserStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      saved: false,
      saving: false,
      data: [],
      isUniqueEmail: false,
      isUniqueUsername: false,
      isNewUserCreated: undefined,
    });
    backend.registerObservable(UsersLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(UserCheckUniqueEmailResult).subscribe(d => {
      super.Mutate(s => s.isUniqueEmail, () => d.Data);
    });
    backend.registerObservable(UserCheckUniqueUsernameResult).subscribe(d => {
      super.Mutate(s => s.isUniqueUsername, () => d.Data);
    });
    backend.registerObservable(UserCreateSuccess).subscribe(d => {
      // TODO  s.data = ApLodash.replaceItem(s.data, savedUser, 'Id');
      super.Mutate(s => s.isNewUserCreated, () => d.Data);
    });
    backend.registerObservable(UserUpdateFullNameSuccess).subscribe(() => {
      this.SetSaveSuccessState();
    });
    backend.registerObservable(UserUpdateFullNameFail).subscribe(() => {
      this.SetSaveFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  /**
   *  load users from Backend to the Store
   */
  public loadUsers(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new UsersLoad([]));
  }

  /**
   *  create user
   */
  public createUser(user: IUser): void {
    this.DispatchBackend(new UserCreate([
      {Name: 'user', Type: ApCustomTypes.Data_Authentication_User, Value: user}
    ]));
  }

  public checkUniqueEmail(email: string): void {
    this.DispatchBackend(new UserCheckUniqueEmail([
      {Name: 'email', Type: NetTypes.STRING, Value: email}
    ]));
  }

  public checkUniqueUsername(username: string): void {
    this.DispatchBackend(new UserCheckUniqueUsername([
      {Name: 'username', Type: NetTypes.STRING, Value: username}
    ]));
  }

  public updateUserFullName(userId: number, forename: string, surname: string): void {
    this.SetSaveState();
    this.DispatchBackend(new UserUpdateFullName([
      {Name: 'userId', Type: NetTypes.INT, Value: userId},
      {Name: 'forename', Type: NetTypes.STRING, Value: forename},
      {Name: 'surname', Type: NetTypes.STRING, Value: surname},
    ]));
  }

  public resetRedirectToNews(userId: number): void {
    this.DispatchBackend(new UserResetRedirectToNews([
      {Name: 'userId', Type: NetTypes.INT, Value: userId}
    ]));
  }
}

import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class CenterCellService {
  public getCellCssClass(value: string): string {
    switch (value) {
      case 'left':
        return 'cell-content-left';
      case 'right':
        return 'cell-content-right';
      case 'center':
        return 'cell-content-center';
      default:
        return 'cell-content-left';
    }
  }
}

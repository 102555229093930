import {IStateStore}              from '../../ap-interface';
import {Store}                    from '../index';
import {EventEmitter, Injectable} from '@angular/core';
import {
  LanguageLoad,
  LanguageLoadSuccess,
  UserLoginSuccess,
  NetTypes
}                                 from 'invoker-transport';
import {filter}                    from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {ClientCache}               from '../local-cache';
import {APP_CONFIGURATION}        from '../../ap-core/config';
import {LanguageSelect}           from '../../../../projects/invoker-transport/src/lib/actions/translation';
import ILanguage = Data.Language.ILanguage;
import IResultMessage = Invoker.IResultMessage;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface ILanguageState extends IStateStore<ILanguage> {
  selectedLanguage: ILanguage;
}

@Injectable({providedIn: 'root'})
export class LanguageStore extends Store<ILanguageState> {

  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      selectedLanguage: null,
    });

    backend.registerObservable(LanguageLoadSuccess).subscribe((l: IResultMessage) => {
      super.Mutate(s => s.data, () => l.Data);
      this._setDefaultBrowserLanguage(l ? l.Data : []);
      this._setLanguageFromLocalStorage(l.Data);
      super.SetLoadFinishState();
      this.languageLoadComplete.emit();
    });

    combineLatest([
      super.Listen(s => s.selectedLanguage),
      backend.registerObservable(UserLoginSuccess)])
      .pipe(filter(([selectedLanguage]) => selectedLanguage != null))
      .subscribe(([selectedLanguage]) => {
        super.DispatchBackend(new LanguageSelect([
          {Name: 'selectedLanguageKey', Type: NetTypes.STRING, Value: selectedLanguage?.Key}
        ]));
      });
  }

  public get Loading$(): Observable<boolean> {
    return super.Listen(s => s.loading);
  }

  public get SelectedLanguage$(): Observable<ILanguage> {
    return super.Listen(s => s.selectedLanguage)
      .pipe(filter(e => !!e));
  }

  public get SelectedLanguage(): ILanguage {
    return super.Listen(s => s.selectedLanguage).getValue();
  }

  public get CurrentDateFormat(): string {
    return this.SelectedLanguage.DateFormat;
  }

  public get IsoDateFormat(): string {
    return 'YYYY-MM-DD';
  }

  public get CurrentTimeFormat(): string {
    return this.SelectedLanguage.TimeFormat;
  }

  public get IsoTimeFormat(): string {
    return 'HH:mm:ss';
  }

  public get CurrentDateTimeFormat(): string {
    return `${this.CurrentDateFormat} ${this.CurrentTimeFormat}`;
  }

  public get IsoDateTimeFormat(): string {
    return `${this.IsoDateFormat}T${this.IsoTimeFormat}`;
  }

  public get LanguageList(): ILanguage[] {
    return super.Listen(s => s.data).getValue();
  }

  public static DefaultLanguage = {
    Key: 'en',
    DateFormat: '',
    TimeFormat: '',
    LanguageName: 'English',
    Order: 1,
    ThousandSeperator: ',',
    DecimalSeperator: '.'
  } as ILanguage;

  public languageLoadComplete = new EventEmitter();

  public Load(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    super.DispatchBackend(new LanguageLoad([]));
  }

  public SelectLanguage(language: ILanguage): void {
    super.Mutate(s => s.selectedLanguage, () => language);
  }

  public FormatNumber(value: number): string {
    const language = this.SelectedLanguage || LanguageStore.DefaultLanguage;
    const isNegative: boolean = value < 0;
    const valueInString: string = Math.abs(value).toString();
    const parts: string[] = valueInString.split('.');
    const wholePart: string = parts[0];
    const decimalPart: string = parts[1] || '';
    let formattedWhole = '';
    let count = 0;
    for (let i = wholePart.length - 1; i >= 0; i--) {
      count++;
      formattedWhole = wholePart.charAt(i) + formattedWhole;
      if (count % 3 === 0 && i !== 0) {
        formattedWhole = language.ThousandSeperator + formattedWhole;
      }
    }
    let formattedStr: string = decimalPart ? formattedWhole + language.DecimalSeperator + decimalPart : formattedWhole;
    if (isNegative) {
      formattedStr = '-' + formattedStr;
    }
    return formattedStr;
  }

  public saveSelectedLanguageToLocalStorage(): void {
    if (this.SelectedLanguage?.Key) {
      ClientCache.writeValue(APP_CONFIGURATION.StoreKeys.LastUsedLanguageKey, this.SelectedLanguage?.Key);
    }
  }

  /**
   * set the default browser language
   */
  private _setDefaultBrowserLanguage(data: ILanguage[]): void {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language;
    if (lang.indexOf('-') !== -1) {
      lang = lang.split('-')[0];
    }
    if (lang.indexOf('_') !== -1) {
      lang = lang.split('_')[0];
    }
    const found = data.filter(_ => _.Key === lang);
    this.SelectLanguage(found ? found[0] : data[0]);
  }

  private _setLanguageFromLocalStorage(languages: ILanguage[]): void {
    const savedLanguageKey = ClientCache.readValue(APP_CONFIGURATION.StoreKeys.LastUsedLanguageKey);
    if (!savedLanguageKey) {
      return;
    }
    if (savedLanguageKey === this.SelectedLanguage?.Key) {
      return;
    }
    const language = languages.find(x => x.Key === savedLanguageKey);
    if (!language) {
      return;
    }
    this.SelectLanguage(language);
  }
}

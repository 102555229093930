import {Injectable}                                                    from '@angular/core';
import {Store}                                                         from '../index';
import {ExportFormatsLoad, ExportFormatsLoadSuccess} from 'invoker-transport';
import {IStateStore}                                                   from '../../ap-interface';
import IExportFormat = Data.Common.IExportFormat;
import {ApSignalrService}                                              from '../../ap-core/services/ap-signalr.service';

interface IExportFormatsStore extends IStateStore<IExportFormat> {
}

@Injectable({providedIn: 'root'})
export class ExportFormatsStore extends Store<IExportFormatsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(ExportFormatsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadExportFormats(): void {
    this.DispatchBackend(new ExportFormatsLoad([]));
  }
}

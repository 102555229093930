import {Component} from '@angular/core';

const CONTENT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.8 175.49">
  <g>
    <path d="M17.65,98.91h0a8.19,8.19,0,0,1,5.68,2.26,7.48,7.48,0,0,1,0,10.91,8.27,8.27,0,0,1-11.36,0,7.55,7.55,0,0,1-2.35-5.45h0A7.6,7.6,0,0,1,12,101.17a8.21,8.21,0,0,1,5.68-2.26m54.71,12h0A8.15,8.15,0,0,1,78,113.17a7.49,7.49,0,0,1,0,10.9,8.29,8.29,0,0,1-11.36,0,7.55,7.55,0,0,1-2.35-5.45h0a7.54,7.54,0,0,1,2.36-5.45,8.19,8.19,0,0,1,5.68-2.26m0-7h0a15.62,15.62,0,0,0-10.84,4.31,14.38,14.38,0,0,0-4.47,10.41h0A14.43,14.43,0,0,0,61.52,129a15.83,15.83,0,0,0,21.69,0,14.31,14.31,0,0,0,0-20.82,15.64,15.64,0,0,0-10.85-4.31m49,33.25a8.27,8.27,0,0,1,11.36,0,7.49,7.49,0,0,1,0,10.9,8.27,8.27,0,0,1-11.36,0,7.49,7.49,0,0,1,0-10.9m54.71-48a8.29,8.29,0,0,1,11.36,0,7.51,7.51,0,0,1,0,10.91,8.29,8.29,0,0,1-11.36,0,7.51,7.51,0,0,1,0-10.91m-9.37,13.91L137.21,129a17.54,17.54,0,0,1,4.92,5.16l29.52-25.89a17,17,0,0,1-2.19-1.78,16.67,16.67,0,0,1-2.72-3.38m-53.66,29.53L89.39,122.24a16.37,16.37,0,0,1-3,6.35L110.05,139a16.45,16.45,0,0,1,3-6.35M56.55,111.56l-21.46-4.7a16.09,16.09,0,0,1-1.62,6.81l21.46,4.71a16,16,0,0,1,1.62-6.82M181.79,79.91A15.57,15.57,0,0,0,171,84.23,14.28,14.28,0,0,0,171,105a15.79,15.79,0,0,0,21.68,0,14.3,14.3,0,0,0,0-20.81,15.58,15.58,0,0,0-10.84-4.32m-54.71,48a15.64,15.64,0,0,0-10.85,4.31,14.31,14.31,0,0,0,0,20.82,15.8,15.8,0,0,0,21.69,0,14.31,14.31,0,0,0,0-20.82,15.62,15.62,0,0,0-10.84-4.31m-109.43-36h0A15.58,15.58,0,0,0,6.81,96.22a14.39,14.39,0,0,0-4.48,10.41h0A14.39,14.39,0,0,0,6.81,117a15.79,15.79,0,0,0,21.68,0,14.28,14.28,0,0,0,0-20.81,15.56,15.56,0,0,0-10.84-4.31" transform="translate(-2.32 -13.15)" class="hoverFill"/>
    <path d="M149.13,13.15h-1.65V65.29c.57.38,1.12.77,1.65,1.18Z" transform="translate(-2.32 -13.15)"/>
    <path d="M156.66,21.12H155V72.66a29.26,29.26,0,0,1,1.65,2.74Z" transform="translate(-2.32 -13.15)"/>
    <path d="M132.43,21.12h1.65V72.66a29.26,29.26,0,0,0-1.65,2.74Z" transform="translate(-2.32 -13.15)"/>
    <path d="M164.18,29h-1.64V82.72a19.7,19.7,0,0,1,1.64-2.15Z" transform="translate(-2.32 -13.15)"/>
    <path d="M124.91,29h1.64V86c-.54,0-1.09-.06-1.64-.06Z" transform="translate(-2.32 -13.15)"/>
    <path d="M157.53,87a24.73,24.73,0,0,0-13-21.14,24.72,24.72,0,0,0-13,21.14,20.72,20.72,0,0,1,13,12.15,20.65,20.65,0,0,1,13-12.15" transform="translate(-2.32 -13.15)"/>
    <path d="M124.91,105.7V88c10.35,0,18.81,8.12,18.81,18v7.4l-1.42,1.24a21.15,21.15,0,0,0-17.39-9" transform="translate(-2.32 -13.15)"/>
    <path d="M159.8,88.47A18.31,18.31,0,0,0,145.37,106v6l14.11-12.37a20.64,20.64,0,0,1-.6-5,21,21,0,0,1,.92-6.16" transform="translate(-2.32 -13.15)"/>
    <path d="M124.91,120.71v-13A19,19,0,0,1,140.74,116l-6.48,5.68a23.63,23.63,0,0,0-7.18-1.11c-.73,0-1.45,0-2.17.1" transform="translate(-2.32 -13.15)"/>
    <path d="M143.72,157.7v2.82c-.32-.62-.67-1.23-1.05-1.82.21-.18.4-.36.6-.56s.3-.29.45-.44" transform="translate(-2.32 -13.15)"/>
    <path d="M164.18,148.84v-19.4a19.33,19.33,0,0,0-6.92,1.28l-7.87,6.9a21.29,21.29,0,0,1-4,18.21v4.69a21,21,0,0,1,18.81-11.34v-.34" transform="translate(-2.32 -13.15)"/>
    <path d="M124.91,170.58v-6.09c.72.06,1.44.1,2.17.1a23.45,23.45,0,0,0,14-4.58,17.39,17.39,0,0,1,2.66,9.23v19.4c-10.35,0-18.81-8.13-18.81-18.06" transform="translate(-2.32 -13.15)"/>
    <path d="M164.18,170.58v-19.4c-10.34,0-18.81,8.13-18.81,18.06v19.4c10.34,0,18.81-8.13,18.81-18.06" transform="translate(-2.32 -13.15)"/>
    <path d="M164.18,127.1v-2.45l-3.5,3.08a21.1,21.1,0,0,1,3.5-.29v-.34" transform="translate(-2.32 -13.15)"/>
    <path d="M101.11,13.15H99.46V65.29c.57.38,1.12.77,1.65,1.18Z" transform="translate(-2.32 -13.15)"/>
    <path d="M108.64,21.12H107V72.66a27.26,27.26,0,0,1,1.65,2.74Z" transform="translate(-2.32 -13.15)"/>
    <path d="M84.41,21.12h1.65V72.66a29.26,29.26,0,0,0-1.65,2.74Z" transform="translate(-2.32 -13.15)"/>
    <path d="M116.16,29h-1.64V86c.54,0,1.09-.06,1.64-.06Z" transform="translate(-2.32 -13.15)"/>
    <path d="M76.89,29h1.64V86C78,86,77.44,86,76.89,86Z" transform="translate(-2.32 -13.15)"/>
    <path d="M109.52,87a24.76,24.76,0,0,0-13-21.14A24.72,24.72,0,0,0,83.54,87a20.7,20.7,0,0,1,13,12.15,20.7,20.7,0,0,1,13-12.15" transform="translate(-2.32 -13.15)"/>
    <path d="M76.89,97.06V88C87.24,88,95.7,96.1,95.7,106v11h0c-.18-.36-.37-.71-.58-1.06a21.56,21.56,0,0,0-6.54-12.88,23.2,23.2,0,0,0-11.67-6" transform="translate(-2.32 -13.15)"/>
    <path d="M116.16,105.7V88c-10.34,0-18.81,8.12-18.81,18v11a21.06,21.06,0,0,1,18.81-11.34" transform="translate(-2.32 -13.15)"/>
    <path d="M76.89,148.84v-8.67a23.38,23.38,0,0,0,9-3.8l8.06,3.53a17.28,17.28,0,0,1,1.76,7.6v13a21,21,0,0,0-18.81-11.34v-.34" transform="translate(-2.32 -13.15)"/>
    <path d="M98.31,141.82a17.32,17.32,0,0,0-1,5.68v13a20.62,20.62,0,0,1,8.84-8.89,21.21,21.21,0,0,1-1.94-7.21Z" transform="translate(-2.32 -13.15)"/>
    <path d="M76.89,170.58v-19.4c10.35,0,18.81,8.13,18.81,18.06v19.4c-10.35,0-18.81-8.13-18.81-18.06" transform="translate(-2.32 -13.15)"/>
    <path d="M116.16,170.58v-8.65a22.8,22.8,0,0,1-5.28-3.79,22.51,22.51,0,0,1-3.75-4.72,18,18,0,0,0-9.78,15.82v19.4c10.35,0,18.81-8.13,18.81-18.06" transform="translate(-2.32 -13.15)"/>
    <path d="M116.16,123.27V107.71A18.93,18.93,0,0,0,99,118.45l14.59,6.4a22.24,22.24,0,0,1,2.6-1.58" transform="translate(-2.32 -13.15)"/>
    <path d="M53.09,13.15H51.44V65.29c.57.38,1.12.77,1.65,1.18Z" transform="translate(-2.32 -13.15)"/>
    <path d="M60.62,21.12H59V72.66a29.26,29.26,0,0,1,1.65,2.74Z" transform="translate(-2.32 -13.15)"/>
    <path d="M36.39,21.12H38V72.66a29.26,29.26,0,0,0-1.65,2.74Z" transform="translate(-2.32 -13.15)"/>
    <path d="M68.14,29H66.49V86q.83-.06,1.65-.06Z" transform="translate(-2.32 -13.15)"/>
    <path d="M28.87,29h1.64V86C30,86,29.42,86,28.87,86Z" transform="translate(-2.32 -13.15)"/>
    <path d="M61.49,87a24.73,24.73,0,0,0-13-21.14A24.74,24.74,0,0,0,35.52,87a20.72,20.72,0,0,1,13,12.15A20.68,20.68,0,0,1,61.49,87" transform="translate(-2.32 -13.15)"/>
    <path d="M29.83,88a18.69,18.69,0,0,1,17.39,14.06l-7.59-1.66a21.7,21.7,0,0,0-5.78-9.32,22.94,22.94,0,0,0-4-3.08" transform="translate(-2.32 -13.15)"/>
    <path d="M68.14,97V88a18.7,18.7,0,0,0-18.46,14.64l5.71,1.25c.25-.27.51-.53.78-.79a23.25,23.25,0,0,1,12-6.07" transform="translate(-2.32 -13.15)"/>
    <path d="M28.87,127.1v-1.3a22.94,22.94,0,0,0,5-3.63q.4-.39.78-.78l13,2.84c0,.51.07,1,.07,1.54v13a21,21,0,0,0-18.81-11.34v-.34" transform="translate(-2.32 -13.15)"/>
    <path d="M28.87,148.84v-19.4c10.35,0,18.81,8.13,18.81,18.06v13a21,21,0,0,0-18.81-11.34v-.34" transform="translate(-2.32 -13.15)"/>
    <path d="M68.14,148.84v-8.62a23.16,23.16,0,0,1-12-6.06l-.31-.3a17.75,17.75,0,0,0-6.53,13.64v13a21,21,0,0,1,18.81-11.34v-.34" transform="translate(-2.32 -13.15)"/>
    <path d="M28.87,170.58v-19.4c10.35,0,18.81,8.13,18.81,18.06v19.4c-10.35,0-18.81-8.13-18.81-18.06" transform="translate(-2.32 -13.15)"/>
    <path d="M68.14,170.58v-19.4c-10.34,0-18.81,8.13-18.81,18.06v19.4c10.35,0,18.81-8.13,18.81-18.06" transform="translate(-2.32 -13.15)"/>
    <path d="M49.37,124.61c0,.39,0,.77,0,1.16v13a20.41,20.41,0,0,1,5.15-6.43,21.71,21.71,0,0,1-4.09-7.51Z" transform="translate(-2.32 -13.15)"/>
    <path d="M45.56,13.15H43.92V66.47c.53-.41,1.08-.8,1.64-1.18Z" transform="translate(-2.32 -13.15)"/>
    <path d="M93.59,13.15H91.94V66.47c.53-.41,1.08-.8,1.65-1.18Z" transform="translate(-2.32 -13.15)"/>
    <path d="M141.6,13.15H140V66.47c.53-.41,1.08-.8,1.64-1.18Z" transform="translate(-2.32 -13.15)"/>
  </g>
</svg>
`;

@Component({
  selector: 'ap-icon-evaluation',
  template: CONTENT
})
export class ApIconEvaluationComponent {
}

import {Injectable}                           from '@angular/core';
import {CropTypeStore}                        from '../base-data/crop.types.store';
import {CampaignYearStore}                    from '../login/campaignyear.store';
import {DateRange, DateTime}                  from 'ts-tooling';
import {CampaignYearService}                  from '../../services/data/campaign-year.service';
import {CropRotationStore, CropRotationYield} from '../farm/crop.rotation.store';
import IField = Data.FieldManagement.IField;
import IFieldCrop = Data.FieldManagement.IFieldCrop;
import ICropTypes = Data.BaseData.ICropTypes;
import IGuid = System.IGuid;

@Injectable({providedIn: 'root'})
export class ApGetCropService {
  constructor(private cropTypeStore: CropTypeStore,
              private campaignYearStore: CampaignYearStore,
              private campaignYearService: CampaignYearService,
              private cropRotationStore: CropRotationStore) {
  }

  public getFieldCrop(field: IField): IFieldCrop | null {
    const campaignYear = this.campaignYearStore.getSelectedCampaignYear();
    return this.getFieldCropByDate(field, new Date(campaignYear.DefaultStart));
  }

  public getFieldCropByDate(field: IField, applicationDate: Date): IFieldCrop | null {
    if (!field?.FieldCrops || field.FieldCrops.length <= 0) {
      return null;
    }
    const campaignYear = this.campaignYearService.getCampaignYearByDate(applicationDate);
    return field.FieldCrops.find(_ => {
      if (!_) {
        return false;
      }
      return this.campaignYearService.isCampaignYearIntersects(new Date(_.ValidFrom), new Date(_.ValidTo), campaignYear);
    });
  }

  public getCropRotationByYear(fieldId: IGuid | null, year: number): CropRotationYield | null {
    if (!fieldId) {
      return null;
    }
    const fieldCropRotations = this.cropRotationStore.GetHistory[fieldId.toString()];
    if (!fieldCropRotations) {
      return null;
    }
    const cropRotationYear = fieldCropRotations[year];
    if (!cropRotationYear) {
      return null;
    }
    return cropRotationYear;
  }

  public getCropNameFromCropRotationYield(crop: CropRotationYield | null): string {
    if (!crop) {
      return '';
    }
    if (crop?.MainCropName) {
      return crop?.MainCropName;
    }
    if (crop?.SecondCropName) {
      return crop?.SecondCropName;
    }
    return '';
  }

  public getCropType(fieldCrop: IFieldCrop, getMainCrop: boolean): ICropTypes | null {
    if (!fieldCrop || !this._getCropTypeId(fieldCrop, getMainCrop)) {
      return null;
    }
    const cropTypeId = this._getCropTypeId(fieldCrop, getMainCrop);
    if (!cropTypeId) {
      return null;
    }
    const cropType: ICropTypes = this.cropTypeStore.getDefaultCroptype(cropTypeId);
    return cropType ?? null;
  }

  public getCrop(field: IField, getMainCrop: boolean, returns: 'object' | 'description' | 'shortname'): any {
    const DEFAULT_RESULT = undefined;
    const crop = this.getFieldCrop(field);
    if (!field || !field.FieldCrops || !Array.isArray(field.FieldCrops) || field.FieldCrops.length < 1 || !crop) {
      return returns === 'object' ? null : DEFAULT_RESULT;
    }
    const cropType = this.getCropType(crop, getMainCrop);
    switch (returns) {
      case 'description':
        return cropType?.Description ?? DEFAULT_RESULT;
      case 'object':
        return cropType ?? null;
      case 'shortname':
        return cropType?.Shortname ?? DEFAULT_RESULT;
    }
  }

  public parseMainCrop(item: IField): string {
    return this.getCrop(item, true, 'description');
  }

  public parseMainCropExpectedYield(item: IField): string {
    if (!item || !item.FieldCrops || !Array.isArray(item.FieldCrops) || item.FieldCrops.length < 1 || !item.FieldCrops[0].CroptypeId) {
      return '';
    }
    const campaignYear = this.campaignYearStore.getSelectedCampaignYear();
    const crop = item.FieldCrops.Find(fc => this.overlapsRanges(
      campaignYear.DefaultStart, campaignYear.DefaultEnd, fc?.ValidFrom, fc?.ValidTo));
    return (crop?.ExpectedYield != null && crop?.ExpectedYield >= 0) ?
      crop.ExpectedYield.toString() : '';
  }

  public parseSecondCropExpectedYield(item: IField): string {
    if (!item || !item.FieldCrops || !Array.isArray(item.FieldCrops) || item.FieldCrops.length < 1 || !item.FieldCrops[0].CroptypeSecondId) {
      return '';
    }
    const campaignYear = this.campaignYearStore.getSelectedCampaignYear();
    const crop = item.FieldCrops.Find(fc => this.overlapsRanges(
      campaignYear.DefaultStart, campaignYear.DefaultEnd, fc?.ValidFrom, fc?.ValidTo));
    return (crop?.ExpectedYieldSecond != null && crop?.ExpectedYieldSecond >= 0) ?
      crop?.ExpectedYieldSecond.toString() : '';
  }

  public parseSecondCrop(item: IField): string {
    return this.getCrop(item, false, 'description');
  }

  public parseStrawHarvested(item: IField): boolean {
    const DEFAULT_RESULT = false;
    if (!item || !item.FieldCrops || !Array.isArray(item.FieldCrops) || item.FieldCrops.length < 1 || !item.FieldCrops[0].StrawHarvested) {
      return DEFAULT_RESULT;
    }
    return item.FieldCrops[0].StrawHarvested ? item.FieldCrops[0].StrawHarvested : DEFAULT_RESULT;
  }

  private overlapsRanges(startA: Date, endA: Date, startB: Date, endB: Date): boolean {
    const cyInterval = new DateRange(
      DateTime.FromISOString(startA.toString(), 'UTC'),
      DateTime.FromISOString(endA.toString(), 'UTC'),
    );
    const fcInterval = new DateRange(
      DateTime.FromISOString(startB.toString(), 'UTC'),
      DateTime.FromISOString(endB.toString(), 'UTC'),
    );
    return fcInterval.Overlaps(cyInterval);
  }

  private _getCropTypeId(crop: IFieldCrop, mainCrop: boolean): number {
    return mainCrop ? crop.CroptypeId : crop.CroptypeSecondId;
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IParameterValue = Data.Parameter.IParameterValue;

export enum ParameterValuesActions {
  PARAMETER_VALUE_LOAD = '[parameter_value] load',
  PARAMETER_VALUE_LOAD_SUCCESS = '[parameter_value] load success',
  PARAMETER_VALUE_LOAD_FAIL = '[parameter_value] load fail',
  PARAMETER_VALUE_DELETE = '[parameter_value] delete',
  PARAMETER_VALUE_CREATE = '[parameter_value] create',
  PARAMETER_VALUE_UPDATE = '[parameter_value] update',
  PARAMETER_VALUES_SAVE_OR_UPDATE = '[parameter_values] save or update',
  PARAMETER_VALUES_SAVE_REQUEST = '[parameter_values] save request'
}

export class ParameterValueLoad implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterValueLoadSuccess implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUE_LOAD_SUCCESS;

  constructor(public payload: IParameterValue[]) {
  }
}

export class ParameterValueLoadFail implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUE_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class ParameterValueCreate implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUE_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterValueDelete implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUE_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterValueUpdate implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUE_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterValuesSaveOrUpdate implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUES_SAVE_OR_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterValuesSaveRequest implements IAction {
  readonly type = ParameterValuesActions.PARAMETER_VALUES_SAVE_REQUEST;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

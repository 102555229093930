import {ApBaseVectorLayer}           from './ap-base-vector.layer';
import {LayerGroupNames, LayerNames} from './layer-names';
import OlFeature                     from 'ol/Feature';
import OlStyle                       from 'ol/style/Style';
import OlStyleStroke                 from 'ol/style/Stroke';
import OlStyleFill                   from 'ol/style/Fill';
import OlCricleStyle                 from 'ol/style/Circle';
import GeoJSON                       from 'ol/format/GeoJSON';
import {MAP_PROJECTION}              from './ap-map.settings';
import {ApMapControlStream}          from './ap-map-control.stream';
import {ApMapInstance}               from '../ap-map.instance';
import Overlay                       from 'ol/Overlay';
import {ApPropertiesStrings}         from '../enums/ap-properties-strings';
import {keys}                        from 'lodash';
import {ApMenuUrls}                  from '../../ap-interface';
import OverlayPositioning            from 'ol/OverlayPositioning';
import MapBrowserEvent               from 'ol/MapBrowserEvent';
import {MapStore}                    from '../../stores/map/map.store';

const classificationColors = [
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#22CB04'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#3FD126'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#76DC64'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#C1ECB9'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#E1F2DE'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#A6CADF'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#91BED9'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#6AA9CE'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#3B8FC0'
      })
    }),
  }),
  new OlStyle({
    image: new OlCricleStyle({
      stroke: new OlStyleStroke({
        color: 'rgba(0, 0, 0, 0)',
        width: 1
      }),
      radius: 5,
      fill: new OlStyleFill({
        color: '#0772B1'
      })
    }),
  })
];

function getFirstFeatureProperties(f): any {
  let props = f.getProperties();
  while (props.hasOwnProperty('features')) {
    const firstFeature = props.features[0];
    if (!firstFeature) {
      return null;
    }
    props = firstFeature.getProperties();
  }
  return props;
}

export class ApFungiDetectLayer extends ApBaseVectorLayer {
  static readonly projection: string = 'EPSG:4326';
  static popup: Overlay;
  static currentClassificationProperty: string = null;
  static classification = null;
  static readonly defaultStyle = function (f): OlStyle {
    let style = null;
    if (!ApFungiDetectLayer.classification || Object.keys(ApFungiDetectLayer.classification).length !== 10) {
      style = new OlStyle({
        image: new OlCricleStyle({
          stroke: new OlStyleStroke({
            color: 'rgba(0, 0, 0, 0)',
            opacity: 0,
            width: 1
          } as any),
          radius: 5,
          fill: new OlStyleFill({
            color: '#0000ff'
          })
        }),
      });
    } else {
      const values = Object.keys(ApFungiDetectLayer.classification).map(v => parseFloat(v));
      const fValue = parseFloat(getFirstFeatureProperties(f)[ApFungiDetectLayer.currentClassificationProperty]);
      for (const v of values) {
        if (fValue <= v) {
          style = ApFungiDetectLayer.classification[v];
          break;
        }
      }
      if (!style) {
        style = ApFungiDetectLayer.classification[values[values.length - 1]];
      }
    }
    return style;
  };

  constructor(mapStore: MapStore, data?: string) {
    super(LayerNames.FUNGIDETECT, LayerGroupNames.FUNGIDETECT, mapStore);
    if (data) {
      this.replaceSource(data);
    }
    ApMapControlStream.listenSingleClick$.subscribe(event => {
      this._setPopup(event);
    });
  }

  replaceSource(data: string): void {
    this.readFeatures(this._read(data), ApFungiDetectLayer.defaultStyle, 10);
  }

  appendSource(data: string): void {
    if (!data) {
      return;
    }
    if (this.Features.length < 1) {
      this.readFeatures(this._read(data), ApFungiDetectLayer.defaultStyle, 10);
    } else {
      const features = this._read(data);
      super.extentSource(features);
    }
  }

  classify(prop: string): void {
    ApFungiDetectLayer.currentClassificationProperty = prop;
    const statistics = {
      min: 0,
      max: 0,
    };
    this.Features.forEach((f) => {
      const featureToShow = f.get('features') ? f.get('features')[0] : f;
      const value = parseFloat(featureToShow.get(prop));
      if (isNaN(value)) {
        return;
      }
      if (statistics.max === 0 || value > statistics.max) {
        statistics.max = value;
      }
      if (statistics.min === 0 || value < statistics.min) {
        statistics.min = value;
      }
    });
    ApFungiDetectLayer.classification = {};
    const step = (statistics.max - statistics.min) / 10;
    let counter = 0;
    for (let i = statistics.min; i < statistics.max; i += step) {
      if (counter === 9) {
        ApFungiDetectLayer.classification[statistics.max] = classificationColors[counter];
        break;
      } else {
        ApFungiDetectLayer.classification[i] = classificationColors[counter];
      }
      counter++;
    }
    if (ApMapInstance.sensorPointLegend) {
      ApMapInstance.sensorPointLegend.fillClassification(
        ApFungiDetectLayer.currentClassificationProperty,
        ApFungiDetectLayer.classification);
      ApMapInstance.sensorPointLegend.show();
    }
    this.layer.changed();
  }

  clear(): void {
    if (ApMapInstance.sensorPointLegend) {
      ApMapInstance.sensorPointLegend.hide();
    }
    super.clear();
  }

  private _read(data: string): OlFeature[] {
    try {
      const collection = JSON.parse(data);
      return (new GeoJSON()).readFeatures(collection, {
        dataProjection: ApFungiDetectLayer.projection,
        featureProjection: MAP_PROJECTION
      });
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  private _setPopup(event: MapBrowserEvent): void {
    if (!ApMapInstance.mapRef) {
      console.warn('missing map reference');
      return;
    }
    if (!ApMapInstance.sensorPointPopup ||
      (ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.IMPORT_OVERVIEW &&
        ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.FUNGI_DETECT_OVERVIEW)) {
      return;
    }

    ApMapInstance.sensorPointPopup.clear();

    if (!ApFungiDetectLayer.popup) {
      ApFungiDetectLayer.popup = new Overlay({
        id: ApPropertiesStrings.SensorPointPopup,
        positioning: OverlayPositioning.BOTTOM_CENTER,
        stopEvent: false,
        offset: [0, -15]
      });
      ApMapInstance.mapRef.addOverlay(ApFungiDetectLayer.popup);
    }

    const featureArray = event.map.getFeaturesAtPixel(
      event.pixel, {
        hitTolerance: 5,
        layerFilter: (l) => l.get('name') === this.name
      });
    const feature = featureArray ? featureArray[0] : null;
    if (feature) {
      const featureToShow = feature.get('features') ? feature.get('features')[0] : feature;
      const coordinates = featureToShow.getGeometry().getCoordinates();
      const properties = featureToShow.getProperties();

      ApFungiDetectLayer.popup.setPosition(coordinates);

      const propertyKeys = keys(properties).filter(k => k !== 'geometry');
      for (const key of propertyKeys) {
        if (!properties.hasOwnProperty(key)) {
          continue;
        }
        const propValue = properties[key];
        if (propValue) {
          ApMapInstance.sensorPointPopup.addValue(key, propValue.toString());
        }
      }

      const element = ApMapInstance.sensorPointPopup.getHTMLElement();
      ApMapInstance.sensorPointPopup.show();
      ApFungiDetectLayer.popup.setElement(element);
    } else {
      ApMapInstance.sensorPointPopup.hide();
    }
  }
}

import {Pipe, PipeTransform}                         from '@angular/core';
import {GetSoilSampleClassColorService, IImageStyle} from '../service/get-soil-sample-class-color.service';

@Pipe({
  name: 'getSoilSampleFieldClassColor'
})
export class GetSoilSampleFieldClassColorPipe implements PipeTransform {

  constructor(private getSoilSampleClassColorService: GetSoilSampleClassColorService) {}

  transform(value: string | number, args?: [boolean]): IImageStyle {
    return this.getSoilSampleClassColorService.getImageStyle(value, args[0]);
  }

}

<div [class]="multiline ? 'ap-responsive-text-multiline' : 'ap-responsive-text'"
     [aptooltip]="(tooltipOverride && tooltipOverride !== '') ? tooltipOverride : displayText.join(' ')"
     [perpetual]="tooltipOverride && tooltipOverride !== ''" [ngStyle]="breakLine ? {'display': 'contents'} : ''">
  <ng-content></ng-content>
  <span *ngFor="let display of displayText">{{display}}</span>
  <kendo-icon *ngIf="(infoText && infoText !== '')" [name]="'info'" [themeColor]="'success'" [aptooltip]="infoText | translate" [perpetual]="true" class="float-right"></kendo-icon>
</div>
<div *ngIf="extraLines && extraLines?.length !== 0" [class]="'ap-responsive-text'"
     [aptooltip]="(tooltipOverride && tooltipOverride !== '') ? tooltipOverride : extraLines.join(' ')"
     [perpetual]="tooltipOverride && tooltipOverride !== ''">
    <span *ngFor="let extraLine of extraLines">{{extraLine}}</span>
</div>

import {AfterContentInit, Injectable, OnDestroy} from '@angular/core';
import {
  ApEditFieldComponent
}                                                from '../../entry-components/ap-field-management-entry-components/ap-edit-field.component';
import {MapViewStore} from '../../stores/layout/mapview.store';
import {FieldStore}   from '../../stores/farm/field.store';
import {FormStore}    from '../../stores/layout/form.store';
import {MapStore}     from '../../stores/map/map.store';
import {Subscription} from 'rxjs';
import IFieldGeom = Data.FieldManagement.IFieldGeom;
import ICampaignYear = Data.Authentication.ICampaignYear;

@Injectable({
  providedIn: 'root'
})
export class FieldService implements OnDestroy {

  constructor(private mapViewStore: MapViewStore,
              private fieldStore: FieldStore,
              private formStore: FormStore,
              private mapStore: MapStore) { }

  private formCloseSubscription: Subscription = null;

  public OpenCreateForm(): void {
    if (this.formCloseSubscription === null) {
      this.formCloseSubscription = this.formStore.FormClose$.subscribe(() => this.mapStore.Editor.CloseEditor(this.fieldStore));
    }

    this.ShowMapGoIn();
    const gridSelectedKeys = this.fieldStore.getSelectedFieldIds();
    this.formStore.openForm({
      component: ApEditFieldComponent, data: {
        SourceItem: null,
        gridSelectedKeys,
      }
    });
    this.mapStore.Editor.PrepareNewField(gridSelectedKeys, this.fieldStore);
  }

  public ShowMapGoIn(): void {
    this.mapViewStore.showMapView();
    this.mapViewStore.goIntoMapMenu();
  }

  public getGeomStatus(fieldGeometry: IFieldGeom, campaignYear: ICampaignYear): number {
    if (!fieldGeometry || !campaignYear || !fieldGeometry?.ValidFrom || !fieldGeometry?.ValidTo) {
      return -1;
    }
    const notFieldGeometryValid =
      (fieldGeometry.ValidFrom < campaignYear.DefaultStart &&
        fieldGeometry.ValidTo < campaignYear.DefaultStart) ||
      (fieldGeometry.ValidFrom > campaignYear.DefaultEnd &&
        fieldGeometry.ValidTo > campaignYear.DefaultEnd);
    return notFieldGeometryValid ? -1 : 1;
  }

  ngOnDestroy(): void {
    this.formCloseSubscription.unsubscribe();
  }
}

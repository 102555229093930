import {EditorView}     from '@progress/kendo-angular-editor';
import {ApEditorSchema} from './ap-editor.schema';
import {Plugin}         from '@progress/kendo-editor-common';

/**
 * Agriport-Help Image Upload
 * Static class which offers functionality to paste/upload images into kendo help editor.
 */
export class ApHelpImageUpload {
  static MaxFileSizeBytes = 5 * 1024 * 1024;
  static AllowedExtensions = ['.jpg', '.jpeg', '.png', '.gif']; // for upload dialog
  static AllowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']; // for paste operations

  /**
   * ProseMirror API Plugin for kendo editor
   * => Takes care of pasting images.
   */
  static EditorImagePastePlugin = new Plugin({
    props: {
      handlePaste(view, event, slice): boolean {
        const files = event?.clipboardData?.files;
        if (files && files.length === 1) {
          // handle paste of image from clipboard AND copied file (to clipboard)
          ApHelpImageUpload.readImageToBase64(files[0], (base64Image) => {
            if (base64Image) {
              ApHelpImageUpload.insertImage(view, base64Image);
            }
          });
          return true;
        }
        return false;
      }
    }
  });

  /**
   * Reads content of uploaded or pasted image as base64
   * => when reading is done, the function delegate is called to continue image-insert process
   */
  static readImageToBase64(file: File, continueWith: (base64Image: string | ArrayBuffer) => void): void {
    // file size exceeded
    if (!file || file.size > ApHelpImageUpload.MaxFileSizeBytes) {
      return;
    }

    // file type not supported
    if (ApHelpImageUpload.AllowedFileTypes.indexOf(file.type) <= 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      continueWith(reader.result);
      reader.onload = null;
    };
    reader.onerror = (error) => {
      continueWith(undefined);
      reader.onerror = null;
    };
    reader.readAsDataURL(file);
  }

  /**
   * Insert <img> with base64 image-source into dom of editor
   */
  static insertImage(editorView: EditorView<any>, base64Image: string | ArrayBuffer): void {
    if (!editorView) {
      return;
    }
    const editorTransaction = editorView.state.tr;
    editorTransaction.insert(editorTransaction.selection.from, ApEditorSchema.nodes.image.create({
      src: base64Image
    }));
    editorView.dispatch(editorTransaction);
  }
}

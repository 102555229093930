import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnControlType,
  ApDynGridColumnHideConfig,
  IPipeDefinition
}                   from './ap-dyn-grid-column-config-base';
import {Observable} from 'rxjs';

export class ApDynGridStatusColumnConfig extends ApDynGridColumnConfigBase {
  public type = ApDynGridColumnControlType.Status;
  public field: string;
  public filterable: boolean;
  public showHeaderTitle: boolean;
  public title: string;
  public class: string;
  public description: string;
  public pendingIds: Observable<string[] | number[]>;
  public pendingTooltip: Observable<string>;

  constructor(options: {
    hide?: boolean | ApDynGridColumnHideConfig,
    sortIndex?: number,
    sortDesc?: boolean,
    groupIndex?: number,
    groupDesc?: boolean,
    headerStyle?: any,
    style?: any,
    pdfExport?: boolean,
    xlsExport?: boolean,
    field?: string,
    filterable?: boolean,
    showHeaderTitle?: boolean,
    title?: string,
    class?: string,
    description?: string,
    cssClass?: 'left' | 'right',
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
    pending?: Observable<string[] | number[]>,
    pendingTooltip?: Observable<string>,
  } = {}) {
    super(options);
    this.width = 30;
    this.field = options.field ? options.field : undefined;
    this.filterable = options.filterable === false ? false : true; // default is true and can explicitly set to false in component
    this.showHeaderTitle = options.showHeaderTitle ? options.showHeaderTitle : false;
    this.title = options.title ? options.title : ' ';
    this.class = options.class;
    this.description = options.description;
    this.pendingIds = options.pending;
    this.pendingTooltip = options.pendingTooltip;
  }
}

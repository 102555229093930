import {Pipe, PipeTransform}        from '@angular/core';
import {ApDynGridColumnConfigBase}  from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridImageColumnConfig} from '../../config/ap-dyn-grid-image-column-config';
import {Trace}                      from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asImage'
})
export class AsImagePipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridImageColumnConfig {
    return config as ApDynGridImageColumnConfig;
  }

}

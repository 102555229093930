import {IAction, IApInvokerArguments} from '../../interfaces';
import INUptakeFactors = Data.Common.INUptakeFactors;

export enum NUptakeFactorsActions {
  NUPTAKE_FACTORS_LOAD = '[nuptake_factors] load',
  NUPTAKE_FACTORS_LOAD_SUCCESS = '[nuptake_factors] load success',
  NUPTAKE_FACTORS_FACTORS_MISSING = '[nuptake_factors] load failed',
}

export class NUptakeFactorsLoad implements IAction {
  readonly type = NUptakeFactorsActions.NUPTAKE_FACTORS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NUptakeFactorsLoadSuccess implements IAction {
  readonly type = NUptakeFactorsActions.NUPTAKE_FACTORS_LOAD_SUCCESS;

  constructor(public payload: INUptakeFactors[]) {
  }
}

export class NUptakeFactorsLoadFailed implements IAction {
  readonly type = NUptakeFactorsActions.NUPTAKE_FACTORS_FACTORS_MISSING;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import INews = Data.Dashboard.INews;

export enum NewsActions {
  NEWS_LOAD = '[news] load',
  NEWS_LOAD_SUCCESS = '[news] load success',
  NEWS_ADD = '[news] add',
}

export class NewsLoad implements IAction {
  readonly type = NewsActions.NEWS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class NewsLoadSuccess implements IAction {
  readonly type = NewsActions.NEWS_LOAD_SUCCESS;

  constructor(public payload: INews[]) {
  }
}

export class NewsAdd implements IAction {
  readonly type = NewsActions.NEWS_ADD;

  constructor(public payload: INews) {
  }
}

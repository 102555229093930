import {IAction, IApInvokerArguments} from '../../interfaces';
import IUnit = Data.Common.IUnit;

export enum UnitsActions {
  UNITS_LOAD = '[units] load',
  UNITS_LOAD_SUCCESS = '[units] load success',
}

export class UnitsLoad implements IAction {
  readonly type = UnitsActions.UNITS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class UnitsLoadSuccess implements IAction {
  readonly type = UnitsActions.UNITS_LOAD_SUCCESS;

  constructor(public payload: IUnit[]) {
  }
}

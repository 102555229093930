import {Pipe, PipeTransform}    from '@angular/core';
import {GetRoundNumericService} from '../service/get-round-numeric.service';
import {LanguageStore}          from '../../stores/translation/language.store';

type ReplaceValues = {
  ToReplace: any[] | null | undefined;
  ReplaceWith: string | null | undefined;
};

@Pipe({
  name: 'getRoundNumeric'
})
export class GetRoundNumericPipe implements PipeTransform {
  public static readonly NullToEmptyConfiguration: ReplaceValues = { ToReplace: [null, undefined], ReplaceWith: '' };
  public static readonly NullAndZeroToEmptyConfiguration: ReplaceValues = { ToReplace: [null, undefined, 0], ReplaceWith: '' };

  constructor(private roundNumericService: GetRoundNumericService,
              private languageStore: LanguageStore) {
  }

  transform(value: any, round?: number, replace?: ReplaceValues): string {
    if (this.isNeedReplace(value, replace)) {
      return replace.ReplaceWith;
    }
    const roundResult: number = this.roundNumericService.roundAsNumber(value, round);
    if (this.isNeedReplace(roundResult, replace)) {
      return replace.ReplaceWith;
    }
    return this.languageStore.FormatNumber(roundResult);
  }

  private isNeedReplace(value: any, replace: ReplaceValues): boolean {
    return replace
      && replace.ReplaceWith !== undefined
      && replace.ToReplace
      && Array.isArray(replace.ToReplace)
      && replace.ToReplace.some(x => x === value);
  }
}

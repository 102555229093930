import OlStyle       from 'ol/style/Style';
import * as OlStyles from 'ol/style';
import * as OlGeom   from 'ol/geom';
import GeometryType  from 'ol/geom/GeometryType';
import Polygon       from 'ol/geom/Polygon';
import Point         from 'ol/geom/Point';
import LineString    from 'ol/geom/LineString';
import {Coordinate}  from 'ol/coordinate';
import {asString}    from 'ol/color';
import OlFeature     from 'ol/Feature';

const selectedColor = [176, 203, 31, 1];

export class ApEditStyles {

  static readonly defaultStyle = function (f): OlStyle {
    return new OlStyle({
      stroke: new OlStyles.Stroke({
        color: '#ffdc60',
        width: 1
      }),
      fill: new OlStyles.Fill({
        color: 'rgba(200, 200, 200, 0.1)',
      }),
      text: new OlStyles.Text({
        font: '22px Calibri,sans-serif',
        text: f.get('label'),
        stroke: new OlStyles.Stroke({
          color: '#ff4141',
          width: 2
        }),
        fill: new OlStyles.Fill({
          color: '#000'
        })
      })
    });
  };
  /**
   * the Style of a selected Field Feature in the Map
   */
  static readonly selectedStyle = function (): OlStyle[] {
    const image = new OlStyles.Circle({
      radius: 4,
      fill: new OlStyles.Fill({
        color: '#76E11D'
      }),
      stroke: new OlStyles.Stroke({
        color: '#000',
        width: 1,
        lineCap: 'round'
      })
    });
    return [
      new OlStyle({
        image,
        geometry(feature): OlGeom.MultiPoint {
          const geom = feature.getGeometry() as Polygon | Point | LineString;
          const geomType = geom.getType();
          switch (geomType) {
            case GeometryType.POINT:
              return new OlGeom.MultiPoint([geom.getCoordinates() as Coordinate]);
            case GeometryType.LINE_STRING:
            case GeometryType.LINEAR_RING:
              return new OlGeom.MultiPoint(geom.getCoordinates() as Coordinate[]);
            case GeometryType.POLYGON:
              const tmp: Coordinate[] = [];
              for (const ring of geom.getCoordinates() as Coordinate[][]) {
                tmp.push(...ring);
              }
              return new OlGeom.MultiPoint(tmp);
            default:
              return new OlGeom.MultiPoint([]);
          }
        }
      }),
      new OlStyle({
        stroke: new OlStyles.Stroke({
          color: '#000',
          width: 1,
          lineCap: 'round'
        }),
        fill: new OlStyles.Fill({
          color: '#000'
        }),
        geometry: (feature: OlFeature): OlGeom.MultiLineString => {
          const geom = feature.getGeometry() as Point | LineString | Polygon;
          switch (geom.getType()) {
            case GeometryType.LINE_STRING:
            case GeometryType.LINEAR_RING:
              return new OlGeom.MultiLineString([(geom.getCoordinates() as Coordinate[])]);
            case GeometryType.POLYGON:
              return new OlGeom.MultiLineString((geom.getCoordinates() as Coordinate[][]));
            default:
              return new OlGeom.MultiLineString([]);
          }
        },
      }),
      new OlStyle({
        stroke: new OlStyles.Stroke({
          color: asString(selectedColor),
          width: 5,
          lineCap: 'round'
        }),
        fill: new OlStyles.Fill({
          color: 'rgba(0, 0, 255, 0)'
        })
      })
    ];
  };

  static readonly invalidStyle = function (): OlStyle[] {
    const image = new OlStyles.Circle({
      radius: 4,
      fill: new OlStyles.Fill({
        color: '#76E11D'
      }),
      stroke: new OlStyles.Stroke({
        color: '#000',
        width: 1,
        lineCap: 'round'
      })
    });
    return [
      new OlStyle({
        image,
        geometry(feature): OlGeom.MultiPoint {
          const geom = feature.getGeometry() as Polygon | Point | LineString;
          const geomType = geom.getType();
          switch (geomType) {
            case GeometryType.POINT:
              return new OlGeom.MultiPoint([geom.getCoordinates() as Coordinate]);
            case GeometryType.LINE_STRING:
            case GeometryType.LINEAR_RING:
              return new OlGeom.MultiPoint(geom.getCoordinates() as Coordinate[]);
            case GeometryType.POLYGON:
              const tmp: Coordinate[] = [];
              for (const ring of geom.getCoordinates() as Coordinate[][]) {
                tmp.push(...ring);
              }
              return new OlGeom.MultiPoint(tmp);
            default:
              return new OlGeom.MultiPoint([]);
          }
        }
      }),
      new OlStyle({
        stroke: new OlStyles.Stroke({
          color: '#000',
          width: 1,
          lineCap: 'round'
        }),
        fill: new OlStyles.Fill({
          color: '#000'
        }),
        geometry: (feature: OlFeature): OlGeom.MultiLineString => {
          const geom = feature.getGeometry() as Point | LineString | Polygon;
          switch (geom.getType()) {
            case GeometryType.LINE_STRING:
            case GeometryType.LINEAR_RING:
              return new OlGeom.MultiLineString([(geom.getCoordinates() as Coordinate[])]);
            case GeometryType.POLYGON:
              return new OlGeom.MultiLineString((geom.getCoordinates() as Coordinate[][]));
            default:
              return new OlGeom.MultiLineString([]);
          }
        },
      }),
      new OlStyle({
        stroke: new OlStyles.Stroke({
          color: 'red',
          width: 5,
          lineCap: 'round'
        }),
        fill: new OlStyles.Fill({
          color: 'rgba(0, 0, 255, 0)'
        })
      })
    ];
  };
}


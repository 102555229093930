import ICropTypes = Data.BaseData.ICropTypes;
import ICropGroups = Data.BaseData.ICropGroups;
import IResultMessage = Invoker.IResultMessage;
import ISelectableCrops = Data.BaseData.ISelectableCrops;
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import {IStateStore}         from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {
  ApCustomTypes,
  CroptypesLoad,
  CroptypesLoadSuccess,
  CroptypesSave,
  HibernateModelNames,
  NetTypes
}                            from 'invoker-transport';
import {TranslationStore}    from '../translation/translation.store';
import {SafeBehaviorSubject} from 'ts-tooling';
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';


interface ICropTypeStore extends IStateStore<ICropTypes> {
  cropTypesNames: { [key: number]: string };
}

export interface IDefaultCroptypeGetter {
  getDefaultCroptype(cropTypeId: number): ICropTypes;
}

@Injectable({providedIn: 'root'})
export class CropTypeStore extends Store<ICropTypeStore> implements IDefaultCroptypeGetter {
  constructor(public backend: ApSignalrService,
              public translationStore: TranslationStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      cropTypesNames: {}
    });

    backend.registerObservable(CroptypesLoadSuccess)
      .subscribe(this.onLoadSuccess.bind(this));
  }

  public get CropTypes$(): SafeBehaviorSubject<ICropTypes[]> {
    return this.Listen(s => s.data);
  }

  public get CropTypes(): ICropTypes[] {
    return this.CropTypes$.getValue();
  }

  public get AvailableCropTypes(): ICropTypes[] {
    return this.CropTypes.FindAll(_ => _.Selected);
  }

  public get GetCropTypesNames$(): SafeBehaviorSubject<{ [key: number]: string }> {
    return this.Listen(s => s.cropTypesNames);
  }

  getDefaultCroptype(cropTypeId: number): ICropTypes {
    return super.Listen(s => s.data).getValue().Find(c => c?.Id === cropTypeId);
  }

  /**
   * start to loading all Croptypes
   */
  public loadCroptypes(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new CroptypesLoad([]));
  }

  /**
   * save the Croptype (only Yield are supported for now)
   */
  public saveCroptypes(cropType: ICropTypes[], yieldChanged: boolean): void {
    super.Mutate(s => s.loading, () => true);
    this.DispatchBackend(new CroptypesSave([
      {
        Name: 'cropTypes',
        Type: ApCustomTypes.Data_BaseData_CropTypes + '[]',
        Value: cropType
      },
      {
        Name: 'yieldChanged',
        Type: NetTypes.BOOL,
        Value: yieldChanged
      }
    ]));
  }

  public mapObjectValues(map): any {
    const result = {};
    if (map) {
      Object.keys(map).forEach((key) => {
        result[key] = this.Listen(s => s.cropTypesNames).getValue()[map[key]];
      });
    }
    return result;
  }

  /**
   * replace the CropGroup Color in the Croptypes List
   */
  public replaceGroupColor(group: ICropGroups): void {
    super.Mutate(s => s.data, o => o.Convert(i => {
      if (i.Groupname === group.Key) {
        return {
          ...i,
          Color: group.FarmColor,
        };
      }
      return i;
    }));
  }

  /**
   * override the Update with a custom Update Strategy
   */
  UpdateSource(operation: DatabaseNotifyOperation, item: any, model: string): void {
    item = this.translateCropType(item);
    switch (model) {
      case HibernateModelNames.CROP_TYPE_DETAILS:
        super.UpdateSource(operation, item, model);
        super.SetLoadFinishState();
        break;
      case HibernateModelNames.SELECTABLE_CROPS:
        if (operation === DatabaseNotifyOperation.Delete) {
          // can only refresh the complete CropType DataSource
          this.loadCroptypes();
          return;
        }
        if (operation === DatabaseNotifyOperation.Insert) {
          super.Mutate(s => s.data, o => {
            if (!o) {
              super.SetLoadFinishState();
              return o;
            }
            const backendItem = item as ISelectableCrops;
            if (!backendItem) {
              // fallback case reload the Source
              this.loadCroptypes();
              return o;
            }
            const position = o.FindIndex(i => i.Id === backendItem.CropkeyDetailId);
            const oldItem = o[position];
            o[position] = {...oldItem, Selected: true};
            super.SetLoadFinishState();
            return o;
          });
          return;
        }
        break;
    }
  }

  GetCropTypeName(cropTypeId: number | string): string {
    return super.Listen(s => s.cropTypesNames).getValue()[cropTypeId];
  }

  GetEppoCode(cropTypeId: number): string {
    return super.Listen(s => s.data).getValue().find((d) => d.Id === cropTypeId)?.Eppocode;
  }

  private onLoadSuccess(d: IResultMessage): void {
    const translatedCropTypes = d.Data.Convert(_ => this.translateCropType(_));
    super.Mutate(s => s.data, () => translatedCropTypes);
    super.Mutate(s => s.cropTypesNames, () => {
      const cropTypes: ICropTypes[] = translatedCropTypes;
      const result = {};
      cropTypes.forEach((c) => {
        result[c.Id] = c.Description;
      });
      return result;
    });
    super.SetLoadFinishState();
  }

  private translateCropType(item: ICropTypes): ICropTypes {
    const translatedItem = item as ICropTypes;
    if (!translatedItem) {
      return item;
    }
    translatedItem.Shortname = this.translationStore.FindTranslationForSelectedLanguage(translatedItem?.Shortname);
    translatedItem.Description = this.translationStore.FindTranslationForSelectedLanguage(translatedItem?.Description);
    return translatedItem;
  }
}

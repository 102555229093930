import {Component} from '@angular/core';

const CONTENT = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 313.49 182.59">
  <g>
    <path d="M128.9,82.29l-15.06,38.09a2,2,0,0,1-1.88,1.14l-38.88,0a2,2,0,0,1-1.66-.78L42.56,82.42a1.41,1.41,0,0,1-.06-1.69,2,2,0,0,1,1.74-.86l82.78.24a2,2,0,0,1,1.6.69,1.42,1.42,0,0,1,.28,1.49M68.55,124.94h48.32a1.07,1.07,0,0,1,1,1.1v1.79a1.07,1.07,0,0,1-1,1.09H68.55a1.07,1.07,0,0,1-1-1.09V126a1.07,1.07,0,0,1,1-1.1M42.33,46.31H128c2.66,0,4.84,2.9,4.84,6.48V70.31c0,3.56-2.18,6.47-4.84,6.47H42.33c-2.67,0-4.85-2.91-4.85-6.47V52.79c0-3.58,2.18-6.48,4.85-6.48" transform="translate(-3.37 -4.77)"/>
  </g>
  <rect x="0.4" y="178.6" width="183.76" height="3.58"/>
  <g>
    <rect x="0.4" y="178.6" width="183.76" height="3.58"/>
  </g>
  <rect x="2.3" y="160.64" width="3.58" height="11.47"/>
  <g>
    <rect x="2.3" y="160.64" width="3.58" height="11.47"/>
  </g>
  <rect x="24.53" y="160.64" width="3.58" height="11.47"/>
  <rect x="24.53" y="160.64" width="3.58" height="11.47"/>
  <rect x="68.99" y="160.64" width="3.58" height="11.47"/>
  <rect x="68.99" y="160.64" width="3.58" height="11.47"/>
  <rect x="91.22" y="160.64" width="3.58" height="11.47"/>
  <rect x="91.22" y="160.64" width="3.58" height="11.47"/>
  <rect x="135.67" y="160.64" width="3.58" height="11.47"/>
  <rect x="135.67" y="160.64" width="3.58" height="11.47"/>
  <rect x="180.13" y="160.64" width="3.58" height="11.47"/>
  <rect x="180.13" y="160.64" width="3.58" height="11.47"/>
  <rect x="113.44" y="160.64" width="3.58" height="11.47"/>
  <rect x="113.44" y="160.64" width="3.58" height="11.47"/>
  <rect x="46.76" y="160.64" width="3.58" height="11.47"/>
  <rect x="46.76" y="160.64" width="3.58" height="11.47"/>
  <rect x="157.9" y="160.64" width="3.58" height="11.47"/>
  <rect x="157.9" y="160.64" width="3.58" height="11.47"/>
  <g>
    <path d="M31.33,129.86a3.68,3.68,0,1,0-3.68-3.67,3.67,3.67,0,0,0,3.68,3.67" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M22.8,123.79a3.68,3.68,0,1,0-3.68-3.68,3.67,3.67,0,0,0,3.68,3.68" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M40,136.21a3.68,3.68,0,1,0-3.68-3.67A3.67,3.67,0,0,0,40,136.21" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M49.1,131.13a3.68,3.68,0,1,0-3.67-3.67,3.67,3.67,0,0,0,3.67,3.67" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M57.57,134.31a3.68,3.68,0,1,0-3.67-3.68,3.68,3.68,0,0,0,3.67,3.68" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M286.53,136.12a13,13,0,1,1-13,13,13,13,0,0,1,13-13m0-17.33a30.34,30.34,0,1,1-30.34,30.34,30.33,30.33,0,0,1,30.34-30.34M175.15,125a15.34,15.34,0,1,1-15.34,15.34A15.34,15.34,0,0,1,175.15,125m0-23.83A39.17,39.17,0,1,1,136,140.3a39.17,39.17,0,0,1,39.17-39.17m43.38,53.42h31.56a37.28,37.28,0,0,1-.4-5.42,36.78,36.78,0,0,1,15.94-30.34h-30.3a63.34,63.34,0,0,1,2.37,8.44,13.53,13.53,0,0,1-13.26,16.29h-3.75a45.3,45.3,0,0,1-2.16,11M175.15,82.9a57.42,57.42,0,0,1,56.19,45.65,7,7,0,0,1-6.9,8.47h-3.75A45.66,45.66,0,0,0,143,107.91a7.13,7.13,0,0,1-1-.88l-2.18-2.43a7,7,0,0,1,1-10.34A57.07,57.07,0,0,1,175.15,82.9M307.53,96.53H308a5.68,5.68,0,0,1,5.66,5.67v8.69a1.88,1.88,0,0,1-1.88,1.88h-4.27ZM191.15,34.87h23.43A7,7,0,0,1,221.36,40l8,28.39a7,7,0,0,1-7.12,9L217,77.09a75.65,75.65,0,0,0-30.92-11.81l-1.76-.83L184.11,42a7.05,7.05,0,0,1,7-7.11m78.44,52h1.93a.87.87,0,0,1,.73.37.9.9,0,0,1,.14.82l-4.5,14.55a.91.91,0,0,1-.87.65h-1.93a.91.91,0,0,1-.74-.38.94.94,0,0,1-.14-.81l4.5-14.55a.93.93,0,0,1,.88-.65m7.26,0h1.92a.92.92,0,0,1,.88,1.19l-4.5,14.55a.91.91,0,0,1-.88.65h-1.93a.89.89,0,0,1-.73-.38.88.88,0,0,1-.14-.81L276,87.53a.91.91,0,0,1,.88-.65m7.26,0H286a.92.92,0,0,1,.88,1.19l-4.51,14.55a.9.9,0,0,1-.87.65H279.6a.93.93,0,0,1-.88-1.19l4.51-14.55a.9.9,0,0,1,.88-.65M232.47,34.66l10.79,42.11,50.85,4.82a11.33,11.33,0,0,1,10.3,11.32v19.86H232.83a64,64,0,0,0-76-33.71l9.57-44.4H160.2V29.31a6.52,6.52,0,0,1,6.5-6.5h65a6.51,6.51,0,0,1,6.5,6.5v5.35Z" transform="translate(-3.37 -4.77)"/>
    <path d="M134.49,114.16l-19.21-4.08L115,110l-2.42,3.87-21.73-4.61L108.14,103l8.26,1.75,1.68.36,17.53,3.73c1.65.35,2.84,1.32,2.66,2.17l-.47,2.21c-.18.84-1.66,1.25-3.31.9" transform="translate(-3.37 -4.77)"/>
    <path d="M135.71,89.81H115.82l-1.56,4.3H92l15.58-9.69h28.09c1.68,0,3,.71,3,1.57v2.26c0,.86-1.37,1.57-3,1.57" transform="translate(-3.37 -4.77)"/>
    <path d="M187.74,4.87h24.78a6.77,6.77,0,0,1,6.82,6.7v3.85A4.3,4.3,0,0,1,215,19.67H185.25a4.3,4.3,0,0,1-4.33-4.25V11.57a6.77,6.77,0,0,1,6.82-6.7" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M187.74,4.87h24.78a6.77,6.77,0,0,1,6.82,6.7v3.85A4.3,4.3,0,0,1,215,19.67H185.25a4.3,4.3,0,0,1-4.33-4.25V11.57A6.77,6.77,0,0,1,187.74,4.87Z" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M175.35,155.54a15.24,15.24,0,1,0-15.24-15.24,15.24,15.24,0,0,0,15.24,15.24" transform="translate(-3.37 -4.77)"/>
    <path d="M189.76,34.87H215a7.51,7.51,0,0,1,7.3,5.51L230.94,71a7.58,7.58,0,0,1-7.67,9.64l-5.69-.28A81.49,81.49,0,0,0,184.29,67.6l-1.89-.89-.22-24.19a7.58,7.58,0,0,1,7.58-7.65" transform="translate(-3.37 -4.77)"/>
    <path d="M17.31,133.24a3.68,3.68,0,1,0-3.27-4,3.67,3.67,0,0,0,3.27,4" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M9.46,126.3a3.68,3.68,0,1,0-3.27-4,3.67,3.67,0,0,0,3.27,4" transform="translate(-3.37 -4.77)" class="hoverFill"/>
    <path d="M25.22,140.45a3.67,3.67,0,1,0-3.27-4,3.66,3.66,0,0,0,3.27,4" transform="translate(-3.37 -4.77)" class="hoverFill"/>
  </g>
</svg>
`;

@Component({
  selector: 'ap-icon-n-fertilization',
  template: CONTENT
})
export class ApIconNFertilizationComponent {
}

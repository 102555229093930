import {IStateStore} from '../../ap-interface/interfaces/store';
import {Store}       from '../index';
import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  FarmUsersDelete,
  FarmUsersDeleteSuccess,
  FarmUsersLoad,
  FarmUsersLoadSuccess,
  FarmUsersUpdate,
  FarmUsersUpdateSuccess
}                    from 'invoker-transport';
import {ApLodash}    from '../../ap-utils';
import IFarmUsers = Data.Authentication.IFarmUsers;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IFarmUsersStore extends IStateStore<IFarmUsers> {
}

@Injectable({providedIn: 'root'})
export class FarmUsersStore extends Store<IFarmUsersStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(FarmUsersLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FarmUsersUpdateSuccess).subscribe(d => {
      super.Mutate(s => s.data, o => ApLodash.replaceItem(o, d.Data, 'Id'));
      super.SetLoadFinishState();
    });
    backend.registerObservable(FarmUsersDeleteSuccess).subscribe(d => {
      const deletedFarmUserId = d.Data;
      super.Mutate(s => s.data, o => {
        o.RemoveAll(e => e.Id === deletedFarmUserId);
        return o;
      });
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  getFarmUsers(): IFarmUsers[] {
    return super.Listen(s => s.data).getValue();
  }


  public loadFarmUsers(): void {
    this.DispatchBackend(new FarmUsersLoad([]));
  }

  public update(farmUser: IFarmUsers): void {
    this.DispatchBackend(new FarmUsersUpdate([
      {Name: 'farmUser', Type: ApCustomTypes.Data_Authentication_FarmUsers, Value: farmUser}
    ]));
  }

  public delete(farmUser: IFarmUsers): void {
    this.DispatchBackend(new FarmUsersDelete([
      {Name: 'farmUser', Type: ApCustomTypes.Data_Authentication_FarmUsers, Value: farmUser}
    ]));
  }

}

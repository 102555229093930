import * as ax                   from 'axios';
import {MapFactoryAuthenticator} from './authentication';
import {APP_CONFIGURATION}       from '../ap-core/config';
import IGuid = System.IGuid;

export class MapFactoryRequestor {
  static async getPackage(packageId: IGuid): Promise<any> {
    if (!packageId) {
      return null;
    }
    const link = `${APP_CONFIGURATION.MapFactory.Address}/zip/con_mongo_raster/${packageId.toString()}`;
    const res = await ax.default({
      method: 'GET',
      url: link,
      responseType: 'arraybuffer',
      headers: {
        Authorization: await MapFactoryAuthenticator.getHash(),
      }
    });
    if (res.status >= 400) {
      return null;
    }
    return res.data;
  }

  static async getNutrientPlanning(fieldGeomId: IGuid): Promise<any> {
    if (!fieldGeomId) {
      return null;
    }
    const link = `${APP_CONFIGURATION.MapFactory.Address}/npl/con_mongo_raster/${fieldGeomId.toString()}`;
    const res = await ax.default({
      method: 'GET',
      url: link,
      responseType: 'arraybuffer',
      headers: {
        Authorization: await MapFactoryAuthenticator.getHash(),
      }
    });
    if (res.status >= 400) {
      return null;
    }
    return res.data;
  }

  static async getNutrientBooking(fieldGeomId: IGuid): Promise<any> {
    if (!fieldGeomId) {
      return null;
    }
    const link = `${APP_CONFIGURATION.MapFactory.Address}/nbk/con_mongo_raster/${fieldGeomId.toString()}`;
    const res = await ax.default({
      method: 'GET',
      url: link,
      responseType: 'arraybuffer',
      headers: {
        Authorization: await MapFactoryAuthenticator.getHash(),
      }
    });
    if (res.status >= 400) {
      return null;
    }
    return res.data;
  }

  static async getRb(fieldGeomId: IGuid): Promise<any> {
    if (!fieldGeomId) {
      return null;
    }
    const link = `${APP_CONFIGURATION.MapFactory.Address}/zip/con_mongo_raster/${fieldGeomId.toString()}`;
    const res = await ax.default({
      method: 'GET',
      url: link,
      responseType: 'arraybuffer',
      headers: {
        Authorization: await MapFactoryAuthenticator.getHash(),
      }
    });
    if (res.status >= 400) {
      return null;
    }
    return res.data;
  }
}

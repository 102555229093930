import {IAction, IApInvokerArguments} from '../../interfaces';
import IParameterDefinition = Data.Parameter.IParameterDefinition;

export enum ParameterDefinitionActions {
  PARAMETER_DEFINITION_LOAD = '[parameter_definition] load',
  PARAMETER_DEFINITION_LOAD_SUCCESS = '[parameter_definition] load success',
  PARAMETER_DEFINITION_CREATE = '[parameter_definition] create',
  PARAMETER_DEFINITION_UPDATE = '[parameter_definition] update',
  PARAMETER_DEFINITION_ADD = '[parameter_definition] add',
}

export class ParameterDefinitionLoad implements IAction {
  readonly type = ParameterDefinitionActions.PARAMETER_DEFINITION_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterDefinitionLoadSuccess implements IAction {
  readonly type = ParameterDefinitionActions.PARAMETER_DEFINITION_LOAD_SUCCESS;

  constructor(public payload: IParameterDefinition[]) {
  }
}

export class ParameterDefinitionCreate implements IAction {
  readonly type = ParameterDefinitionActions.PARAMETER_DEFINITION_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}


export class ParameterDefinitionUpdate implements IAction {
  readonly type = ParameterDefinitionActions.PARAMETER_DEFINITION_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ParameterDefinitionAdd implements IAction {
  readonly type = ParameterDefinitionActions.PARAMETER_DEFINITION_ADD;

  constructor(public payload: IParameterDefinition) {
  }
}

import {EventEmitter, Injectable}        from '@angular/core';
import {MapViewMode}                     from '../../ap-interface';
import {ApDocuUtils}                     from '../../docu/utils/ap-docu-utils';
import {camelCase, upperFirst}           from 'lodash';
import {TrackPropertyNames}              from '../../ap-map';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {MachineStore}                    from '../../stores/docu/machine.store';
import {MachineBreaksStore}              from '../../stores/docu/machine.breaks.store';
import {MapViewStore}                    from '../../stores/layout/mapview.store';
import {GeoJsonStore}                    from '../../stores/docu/geojson.store';
import {MapStore}                        from '../../stores/map/map.store';
import {ColorLegendStore}                from '../../stores/map/color.legend.store';
import {TrackPropertyComponent}          from '../../entry-components/color-legend-entry-components/track-property.component';
import {filter}                          from 'rxjs/operators';
import IAttachment = Data.DocuContext.Attachment.IAttachment;
import IField = Data.FieldManagement.IField;
import IWorktypes = Data.BaseData.IWorktypes;
import ISlice = Data.DocuContext.Slice.ISlice;
import IGuid = System.IGuid;
import IMachine = Data.DocuContext.Machine.IMachine;

@Injectable({
  providedIn: 'root'
})
export class ApShowTrackOnMapService {
  onMapClear = new EventEmitter();

  private _breaks = new Map<string, object[]>();
  private _json = {};
  private _mapViewMode: MapViewMode;
  private _types: { text: string, value: string }[] = [];
  private _type: { text: string, value: string };
  private _pendingSubjects = {};

  constructor(private colorLegendStore: ColorLegendStore,
              private machineBreaksStore: MachineBreaksStore,
              private geoJsonStore: GeoJsonStore,
              private machineStore: MachineStore,
              private mapStore: MapStore,
              private mapViewStore: MapViewStore) {
    this.mapViewStore.Listen(s => s.mode).subscribe(mapViewMode => {
      this._mapViewMode = mapViewMode;
    });

    this.geoJsonStore.Listen(s => s.geoJson).subscribe(geoJson => {
      if (geoJson === null) {
        return;
      }
      if (geoJson['Key'] !== undefined && geoJson['Value'] !== undefined) {
        this._json[geoJson['Key']] = JSON.parse(geoJson['Value']);
      }
    });

    this.geoJsonStore.Listen(s => s.pending).subscribe((p: string[]) => {
      const keys = Object.keys(this._pendingSubjects);
      keys.forEach(k => {
        this._pendingSubjects[k].next(p.indexOf(k) !== -1);
      });
    });

    this.machineBreaksStore.Listen(s => s.data).subscribe(breaks => {
      this._breaks = ApDocuUtils.generateBreakMap(breaks);
    });

    this.colorLegendStore.Listen(s => s.selectedValue).pipe(filter(v => !!v)).subscribe(v => {
      const i = v[TrackPropertyComponent.type];
      if (i !== undefined) {
        this.setType(i);
      }
    });

    const propertyNames = TrackPropertyNames.getAll();
    for (const name of propertyNames) {
      this._types.push({
        text: 'Api_Enums__' + upperFirst(camelCase(name)),
        value: name as string
      });
    }

    this._type = this._types[0];
  }

  private static _getUnit(type: string): string {
    return '';
  }

  isActive(id: string): boolean {
    return this._json.hasOwnProperty(id);
  }

  generateGeoJsonBySlice(slice: ISlice): void {
    const id = slice.Id as string;
    if (this._show(id, slice.Attachment.Machine)) {
      this.geoJsonStore.generateGeoJsonBySlice(id);
    }
    this._refreshMap();
  }

  generateGeoJsonByField(attachment: IAttachment, field: IField, date: Date, workType: IWorktypes = null): void {
    const id = attachment.Id as string;
    if (this._show(id, attachment.Machine)) {
      this.geoJsonStore.generateGeoJsonByField(attachment, field, date, workType);
    }
    this._refreshMap();
  }

  generateGeoJsonByMachine(guid: IGuid, machine: IMachine, from: Date): void {
    const id = guid as string;
    if (this._show(id, machine)) {
      this.geoJsonStore.generateGeoJsonByMachine(guid, machine, from);
    }
    this._refreshMap();
  }

  clear(): void {
    this._json = {};
    this.onMapClear.emit();
    this.geoJsonStore.clearGeoJson();
    this.colorLegendStore.resetColorLegend();
  }

  removedUnreferencedIds(ids: any[]): void {
    Object.keys(this._json).forEach(key => {
      if (ids.indexOf(key) === -1) {
        this._removeId(key);
      }
    });
  }

  getPendingSubject(item): Observable<boolean> {
    const id = item['Id'] as string;
    if (id === undefined) {
      return of(false);
    }
    if (!this._pendingSubjects[id]) {
      this._pendingSubjects[id] = new BehaviorSubject<boolean>(this.geoJsonStore.isPending(id));
    }
    return this._pendingSubjects[id];
  }

  setType(i: number): void {
    this._type = this._types[i < this._types.length ? i : 0];
  }

  private _show(id: string, machine: IMachine): boolean {
    if (this._mapViewMode !== MapViewMode.NORMAL) {
      this.mapViewStore.showMapView();
    }
    if (this._json.hasOwnProperty(id)) {
      this._removeId(id);
      return false;
    } else {
      return true;
    }
  }

  private _refreshMap(): void {
    setTimeout(this.mapViewStore.refreshMap.bind(this.mapViewStore), 1);
  }

  private _removeId(id): void {
    delete this._json[id];
    if (Object.keys(this._json).length === 0) {
      this.onMapClear.emit();
    }
  }
}

import {Injectable}                                          from '@angular/core';
import {Store}                                               from '../index';
import {ElementsLoad, ElementsLoadSuccess} from 'invoker-transport';
import {IStateStore}                                         from '../../ap-interface';
import IElement = Data.Common.IElement;
import {ApSignalrService}                                    from '../../ap-core/services/ap-signalr.service';

interface IElementsStore extends IStateStore<IElement> {
}

@Injectable({providedIn: 'root'})
export class ElementsStore extends Store<IElementsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(ElementsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get Elements(): IElement[] {
    return this.Listen(x => x.data).getValue();
  }

  public loadElements(): void {
    this.DispatchBackend(new ElementsLoad([]));
  }
}

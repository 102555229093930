import {IAction, IApInvokerArguments} from '../../interfaces';
import ICountry = Data.Common.ICountry;

export enum CountryAction {
  COUNTRY_LOAD = '[country] load',
  COUNTRY_LOAD_SUCCESS = '[country] load success',
}

export class CountryLoad implements IAction {
  readonly type = CountryAction.COUNTRY_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class CountryLoadSuccess implements IAction {
  readonly type = CountryAction.COUNTRY_LOAD_SUCCESS;

  constructor(public payload: ICountry[]) {
  }
}

import {Component, Input}                 from '@angular/core';
import {ApDynGridButtonGroupColumnConfig} from '../config/ap-dyn-grid-button-group-column-config';
import {AsButtonPipe}                     from '../pipes/convert/as-button.pipe';

@Component({
  selector: 'ap-button-group-column-cell',
  template: `
    <span [class]="config.cssClass | centerCell">

          <kendo-buttongroup [selection]="(config | asButtonGroup).singleSelection ? 'single' : 'multiple'">
            <button *ngFor="let button of (config | asButtonGroup).buttons"
                    kendoButton
                    [disabled]="button.disabledFn ? (button .disabledFn | execFunction:(dataItem)) : button.disabled$ | async"
                    [icon]="(button.itemBusy$(dataItem) | async) ? 'loading' : undefined"
                    [iconClass]="button.iconClass"
                    [primary]="true"
                    [imageUrl]="(button.itemBusy$(dataItem) | async) ? undefined : button.imageUrl"
                    (click)="button.callback.emit(dataItem)"
                    [style.cursor]="(button.itemBusy$(dataItem) | async) ? 'wait' : 'pointer'"
                    [style.height]="'32px'"
                    [style.width]="'32px'"
                    [style.padding]="button.iconClass ? '6px' : undefined"
                    [perpetual]="true"
                    [selected]="button.selected(dataItem)"
                    [aptooltip]="(button.tooltip | translate) | execPipes:config:'tooltipPipes' | async">
            </button>
          </kendo-buttongroup>
        </span>
  `,
  styles: [
  ]
})
export class ApButtonGroupColumnCellComponent {
  @Input() config: ApDynGridButtonGroupColumnConfig;
  @Input() dataItem: any;

  constructor() { }

}

import {ProjectionLike} from 'ol/proj';
import {GeoJSON}        from 'ol/format';
import {MAP_PROJECTION} from '..';

export class GeoJSONConverter {
  /**
   * convert a list of Geometry GeoJSON Strings into Openlayers Features
   *
   * @param geoms the geometry strings
   * @param projection the projection of the geometry strings
   * @param props the optional properties to write into the Features when undefined null was set into properties
   */
  static GeometryStringToFeature(geoms: string[], projection: ProjectionLike, props?: any[]): any[] {
    const format = new GeoJSON({
      dataProjection: projection,
      featureProjection: MAP_PROJECTION,
    });
    const result = [];
    for (let i = 0; i < geoms.length; i++) {
      const geometry = geoms[i];
      const properties = props ? props[i] || null : null;
      result.Add(format.readFeature({
        type: 'Feature',
        geometry: JSON.parse(geometry),
        properties,
      }));
    }
    return result;
  }
}

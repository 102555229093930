import {Injectable} from '@angular/core';

export interface IFieldNumerable {
  FieldNumber: number;
  FieldSubnumber: number;
}

@Injectable({providedIn: 'root'})
export class ApFieldFormatService {
  public formatFieldNumber(field: IFieldNumerable): string {
    return `${field?.FieldNumber} - ${field?.FieldSubnumber}`;
  }
}

import OlFeature from 'ol/Feature';
import OlStyle   from 'ol/style/Style';

export class ApVectorSelection {
  feature: OlFeature;
  defaultStyle: OlStyle | OlStyle[];

  constructor(feature: OlFeature, style: OlStyle | OlStyle[]) {
    this.feature = feature;
    this.defaultStyle = style;
  }

  resetStyle(): void {
    this.feature.setStyle(this.defaultStyle);
  }
}

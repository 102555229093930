import {AfterContentInit, Component, Input, OnDestroy} from '@angular/core';
import {Observable, of, Subscription}                  from 'rxjs';
import {
  SelectableSettings
}                                                      from '@progress/kendo-angular-grid';
import {
  ApDynGridPropertyColumnConfig
}                                                      from '../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApTranslationService
}                                                      from '../../../ap-utils/service/ap-translation.service';
import {
  CampaignYearStore
}                                                      from '../../../stores/login/campaignyear.store';
import {
  GetElementService
}                                                      from '../../../ap-utils/service/ap-get-element.service';
import {
  GetRoundNumericPipe
}                                                      from '../../../ap-utils';
import {
  ApElementOxydTypeEnum
}                                                      from '../../../ap-interface';
import {
  FertilizerStore
}                                                      from '../../../stores/base-data/fertilizer.store';
import {
  SettingsStore
}                                                      from '../../../stores/base-data/settings.store';
import {
  ApElementType
}                                                      from 'src/app/ap-interface/enums/ap-elements-type.enum';
import {
  ApNutrientService
}                                                      from '../../../ap-utils/service/ap-nutrient.service';
import {
  INutrientOverviewApplGridData,
  INutrientOverviewGridData,
  INutrientOverviewRemainGridData
}                                                      from '../../../nutrient-management/types/base-fertilization-overview.types';

export interface INutrientOverviewStat1Data {
  Element: string;
  CropRotationNeed: number;
  BookingsSum: number;
  Remain1: number;
  Remain2: number;
  PlanningsSum: number;
}

export interface INutrientOverviewStat2Data {
  RowHeaderText: string;
  ProductId: number;
  SubId?: number;
  AmountByYear: { [key: number]: number };
}

@Component({
  selector: 'ap-base-fert-overview-stats',
  templateUrl: 'ap-base-fert-overview-stats.component.html',
  styleUrls: ['ap-base-fert-overview-stats.component.scss']
})
export class ApBaseFertOverviewStatsComponent implements AfterContentInit, OnDestroy {
  @Input()
  public data: INutrientOverviewGridData[];
  @Input()
  public title: string;
  @Input()
  public emptyMessage = '';
  public gridSelectableSettings: SelectableSettings = {
    enabled: false,
    checkboxOnly: false,
    mode: 'single',
    cell: false,
    drag: false
  };

  public stat1columns = [];
  public stat2columns = [];
  public stat3columns = [];
  public itemsStat1$: Observable<INutrientOverviewStat1Data[]>;
  public itemsStat2$: Observable<INutrientOverviewStat2Data[]>;
  public itemsStat3$: Observable<INutrientOverviewStat2Data[]>;
  private subscriptions: Subscription[] = [];
  private elements: ApElementType[] = [ApElementType.P, ApElementType.K, ApElementType.Mg, ApElementType.CaO];
  private yearsBooking: { [key: number]: { From: Date, To: Date } } = {};
  private yearsPlanning: { [key: number]: { From: Date, To: Date } } = {};
  private rowHeaderColor = 'silver';

  constructor(private translationService: ApTranslationService,
              private campaignYearStore: CampaignYearStore,
              private elementService: GetElementService,
              private roundNumericPipe: GetRoundNumericPipe,
              private fertilizerStore: FertilizerStore,
              private nutrientService: ApNutrientService,
              private settingsStore: SettingsStore) {

    this.subscriptions.push(this.campaignYearStore.Listen(s => s.selectedYear).subscribe(selectedYear => {
      const numberOfYears = 6;
      this.yearsPlanning = {};
      this.yearsBooking = {};
      for (let i = 0; i < numberOfYears; i++) {
        const yearPlanning = selectedYear.Year - 1 + i;
        const fromPlanning = new Date(yearPlanning - 1, 7, 1); // starts 01.08. 00:00
        const toPlanning = new Date(yearPlanning, 6, 31, 23, 59, 59); // ends 31.07. 23:59
        this.yearsPlanning[yearPlanning] = {From: fromPlanning, To: toPlanning}; // .push(currentYear + i);

        const yearBooking = selectedYear.Year - numberOfYears + 1 + i;
        const fromBooking = new Date(yearBooking - 1, 7, 1); // starts 01.08. 00:00
        const toBooking = new Date(yearBooking, 6, 31, 23, 59, 59); // ends 31.07. 23:59
        this.yearsBooking[yearBooking] = {From: fromBooking, To: toBooking};
      }
      this.buildGrids();
    }));
  }

  private buildGrids(): void {
    this.stat1columns = [
      new ApDynGridPropertyColumnConfig(
        {
          title: '',
          field: 'Element',
          style: {'font-weight': 600, 'background-color': this.rowHeaderColor},
          cssClass: 'right',
          width: 100,
          filterable: false,
          sortable: false
        }),
      new ApDynGridPropertyColumnConfig(
        {
          title: 'Global__Crop_Rotation_Need',
          field: 'CropRotationNeed',
          cssClass: 'right',
          headerStyle: {'text-align': 'center'},
          width: 100,
          filterable: false,
          sortable: false,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [0]
            }
          ]
        }),
      new ApDynGridPropertyColumnConfig(
        {
          title: `${this.translationService.translate('Global__Sum')} ${this.translationService.translate('Global__Bookings')}`,
          field: 'BookingsSum',
          cssClass: 'right',
          headerStyle: {'text-align': 'center'},
          width: 100,
          filterable: false,
          sortable: false,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [0]
            }
          ]
        }),
      new ApDynGridPropertyColumnConfig(
        {
          title: 'Global__Remaining_Need_One_Short',
          field: 'Remain1',
          cssClass: 'right',
          headerStyle: {'text-align': 'center'},
          width: 100,
          filterable: false,
          sortable: false,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [0]
            }
          ]
        }),
      new ApDynGridPropertyColumnConfig(
        {
          title: `${this.translationService.translate('Global__Sum')} ${this.translationService.translate('Global__Plannings')}`,
          field: 'PlanningsSum',
          cssClass: 'right',
          headerStyle: {'text-align': 'center'},
          width: 100,
          filterable: false,
          sortable: false,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [0]
            }
          ]
        }),
      new ApDynGridPropertyColumnConfig(
        {
          title: 'Global__Remaining_Need_Two_Short',
          field: 'Remain2',
          cssClass: 'right',
          headerStyle: {'text-align': 'center'},
          width: 100,
          filterable: false,
          sortable: false,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [0]
            }
          ]
        })];

    this.stat2columns = [
      new ApDynGridPropertyColumnConfig(
        {
          title: '',
          field: 'RowHeaderText',
          style: {'font-weight': 600, 'background-color': this.rowHeaderColor},
          cssClass: 'left',
          width: 150,
          filterable: false,
          sortable: false
        })];


    this.stat3columns = [
      new ApDynGridPropertyColumnConfig(
        {
          title: '',
          field: 'RowHeaderText',
          style: {'font-weight': 600, 'background-color': this.rowHeaderColor},
          cssClass: 'left',
          width: 150,
          filterable: false,
          sortable: false
        })];

    // Create dynamic year columns
    for (const year of Object.keys(this.yearsPlanning)) {
      this.stat2columns.push(new ApDynGridPropertyColumnConfig(
        {
          title: year.toString(),
          field: 'AmountByYear.' + year,
          cssClass: 'right',
          headerStyle: {'text-align': 'center'},
          width: 80,
          filterable: false,
          sortable: false,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [0]
            }
          ]
        }));
    }

    for (const year of Object.keys(this.yearsBooking)) {
      this.stat3columns.push(new ApDynGridPropertyColumnConfig(
        {
          title: year.toString(),
          field: 'AmountByYear.' + year,
          cssClass: 'right',
          headerStyle: {'text-align': 'center'},
          width: 80,
          filterable: false,
          sortable: false,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [0]
            }
          ]
        }));
    }
  }

  private getElementOxidType(element: ApElementType): ApElementOxydTypeEnum {
    switch (element) {
      case ApElementType.P:
        return ApElementOxydTypeEnum.P;
      case ApElementType.K:
        return ApElementOxydTypeEnum.K;
      case ApElementType.Mg:
        return ApElementOxydTypeEnum.Mg;
      default:
        return undefined;
    }
  }

  ngAfterContentInit(): void {
    if (!this.data) {
      return;
    }
    // this.subscriptions.push(this.data$.subscribe(data => {
    if (!this.data || this.data?.length === 0) {

      this.itemsStat1$ = of([]);
      this.itemsStat2$ = of([]);
      this.itemsStat3$ = of([]);
    }

    this.itemsStat1$ = of(this.elements.map<INutrientOverviewStat1Data>(e => {
      // Nach Absprache mit Florian werden Einträge, die keinen Fruchtfolgebedarf haben
      // nicht in der Statistik beachtet. Auch rote Felder werden nun ausgeschlossen aus
      // der 1. Statistik (APV49-2393
      const dataWithCrNeed = this.data.FindAll(_ => !!_.CrNeeds && _.Status === 1);
      const cropRotationNeed = this.getCropRotationNeedSum(dataWithCrNeed, e);
      const remain1 = dataWithCrNeed.reduce((result, value) => {
        let rb = this.getRemainByElement(value.Remain1, e);
        if (!isNaN(parseFloat(value.Area?.toString()))) {
          rb = rb * value.Area;
        }
        return this.convertToTons(rb) + result;
      }, 0);
      const remain2 = dataWithCrNeed.reduce((result, value) => {
        let rb = this.getRemainByElement(value.Remain2, e);
        if (!isNaN(parseFloat(value.Area?.toString()))) {
          rb = rb * value.Area;
        }
        return this.convertToTons(rb) + result;
      }, 0);
      return {
        Element: `${this.translationService.translate(this.elementService.GetElementStringByString(e))} ${this.translationService.translate('Global__t_Unit')}`,
        CropRotationNeed: this.getValue(cropRotationNeed),
        BookingsSum: this.getValue(cropRotationNeed - remain1),
        Remain1: this.getValue(remain1),
        PlanningsSum: this.getValue(remain1 - remain2),
        Remain2: this.getValue(remain2)
      };
    }));


    this.itemsStat2$ = of([...this.elements.map<INutrientOverviewStat2Data>(e => {
      return {
        RowHeaderText: `${this.translationService.translate(this.elementService.GetElementStringByString(e))} ${this.translationService.translate('Global__t_Unit')}`,
        ProductId: -1,
        AmountByYear: this.getAmountByYear(this.data, e, false)
      };
    }), ...this.getProductItems(this.data, false)]);

    this.itemsStat3$ = of([...this.elements.map<INutrientOverviewStat2Data>(e => {
      return {
        RowHeaderText: `${this.translationService.translate(this.elementService.GetElementStringByString(e))} ${this.translationService.translate('Global__t_Unit')}`,
        ProductId: -1,
        AmountByYear: this.getAmountByYear(this.data, e, true)
      };
    }), ...this.getProductItems(this.data, true)]);
  }

  private getCropRotationNeedSum(data: INutrientOverviewGridData[], e: ApElementType): number {
    if (!data || data?.length <= 0) {
      return 0;
    }

    return this.elementService.CalculateElementOxidValueByGivenElementValue(data.reduce((result, d) => {
      let area = 1;
      if (!isNaN(parseFloat(d.Area?.toString()))) {
        area = d.Area;
      }
      switch (e) {
        case ApElementType.P:
          return this.convertToTons(d.CrNeeds.CropRotationNeedP * area) + result;
        case ApElementType.K:
          return this.convertToTons(d.CrNeeds.CropRotationNeedK * area) + result;
        case ApElementType.Mg:
          return this.convertToTons(d.CrNeeds.CropRotationNeedMg * area) + result;
        case ApElementType.CaO:
          return this.convertToTons(d.CrNeeds.CropRotationNeedCaO * area) + result;
        default:
          return result;
      }
    }, 0), this.getElementOxidType(e));
  }

  private getRemainByElement(data: INutrientOverviewRemainGridData, element: ApElementType): number {
    let remainValue: number;
    switch (element) {
      case ApElementType.P:
        remainValue = !isNaN(parseFloat(data?.P?.toString())) ? parseFloat(data?.P?.toString()) : 0;
        break;
      case ApElementType.K:
        remainValue = !isNaN(parseFloat(data?.K?.toString())) ? parseFloat(data?.K?.toString()) : 0;
        break;
      case ApElementType.Mg:
        remainValue = !isNaN(parseFloat(data?.Mg?.toString())) ? parseFloat(data?.Mg?.toString()) : 0;
        break;
      case ApElementType.CaO:
        remainValue = !isNaN(parseFloat(data?.CaO?.toString())) ? parseFloat(data?.CaO?.toString()) : 0;
        break;
      default:
        remainValue = 0;
    }
    return remainValue;
  }

  private convertToTons(valueInKg: number): number {
    if (!valueInKg || isNaN(parseFloat(valueInKg.toString()))) {
      return 0;
    }
    return valueInKg / 1000;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private calculateBookingPlanningSum(data: INutrientOverviewGridData[], element: ApElementType, productId: number, isBooked: boolean, from: Date = null, to: Date = null, subId?: number): number {
    let sum = 0;
    const processedPlanBookIds: string[] = [];
    for (const d of data) {
      if (!d.Applications || d.Applications?.length <= 0) {
        continue;
      }

      let applications: INutrientOverviewApplGridData[] = [];
      if (from !== null && to !== null) {
        applications = d.Applications.FindAll(a => a.IsBooked === isBooked && a.OutputDate >= from && a.OutputDate < to);
      } else {
        applications = d.Applications.FindAll(a => a.IsBooked === isBooked);
      }

      if (productId === 0) {
        applications = applications.FindAll(a => a.ProductId === productId && this._getPureNutrientSubId(a) === subId);
      } else if (!isNaN(productId) && productId >= -1) {
        applications = applications.FindAll(a => a.ProductId === productId);
      }

      sum += applications.reduce((result, appl) => {
        if ( processedPlanBookIds.Any(a => a === appl?.Id?.toString())) {
          return result;
        }
        processedPlanBookIds.push(appl.Id?.toString());
        let goodsAmount: number;
        if (!isNaN(productId) && productId >= -1) {
          const value = this.getRemainByElement(appl.Remain2, appl.LeadNutrient);
          const nutrientAmount = this.elementService.CalculateToElementByGivenValue(value, appl.LeadNutrient,
            this.settingsStore.IsOxidFarm, this.settingsStore.IsOxidFarm);
          goodsAmount = this.nutrientService.convertNutrientToGoods(productId, appl.LeadNutrient, nutrientAmount);
        } else {
          goodsAmount = this.convertToTons(this.getRemainByElement(appl.Remain2, element));
        }
        if (!isNaN(parseFloat(appl.Area?.toString()))) {
          goodsAmount = goodsAmount * appl.Area;
        }
        return goodsAmount + result;
      }, 0);
    }

    return sum;
  }

  private getProductItems(data: INutrientOverviewGridData[], isBooked: boolean): INutrientOverviewStat2Data[] {
    const items: INutrientOverviewStat2Data[] = [];
    const years = isBooked ? this.yearsBooking : this.yearsPlanning;
    const firstYear = years[Math.min(...Object.keys(years).map(y => parseFloat(y)))];
    const lastYear = years[Math.max(...Object.keys(years).map(y => parseFloat(y)))];
    for (const d of data) {
      if (!d?.Applications || d.Applications?.length <= 0) {
        continue;
      }
      for (const application of d.Applications) {
        // list products only if they have plannings or bookings within the displayed period of years
        if (application.IsBooked !== isBooked ||
          application.OutputDate > lastYear.To || application.OutputDate < firstYear.From) {
          continue;
        }

        const productItem = this._findProductItem(items, application);
        // if product not in list, yet
        if (!productItem) {
          if (application.ProductId !== 0) {
            let productUnitKey = this.fertilizerStore.getFertilizer(application.ProductId)?.Unit?.Key;
            let convertKgToTons = false;
            if (productUnitKey === 'Global__Unit_kg') {
              productUnitKey = 'Global__Unit_t';
              convertKgToTons = true;
            }
            items.push({
              RowHeaderText: `${application.Product} [${this.translationService.translate(productUnitKey)}]`,
              ProductId: application.ProductId,
              AmountByYear: this.getProductByYear(data, application.ProductId, isBooked, convertKgToTons)
            });
          } else {
            const subId = this._getPureNutrientSubId(application);
            items.push({
              RowHeaderText: `${application.Product}-${this.elementService.GetElementStringById(subId)} [${this.translationService.translate('Global__Unit_t')}]`,
              ProductId: 0,
              SubId: subId,
              AmountByYear: this.getProductByYear(data, application.ProductId, isBooked, true, subId)
            });
          }
        }
      }
    }
    // return items plus placeholder row
    if (items.length > 0) {
      return [...[{RowHeaderText: undefined, AmountByYear: undefined, ProductId: undefined}], ...items];
    }
    return items;
  }

  private getAmountByYear(data: INutrientOverviewGridData[], element: ApElementType, isBooked: boolean): {
    [key: number]: number
  } {
    const amountByYear = {};
    const years = isBooked ? this.yearsBooking : this.yearsPlanning;
    for (const year of Object.keys(years)) {
      amountByYear[year] = this.getValue(this.calculateBookingPlanningSum(data, element, undefined, isBooked, years[year].From, years[year].To));
    }
    return amountByYear;
  }

  private getProductByYear(data: INutrientOverviewGridData[], productId: number, isBooked: boolean, convertKgToTons: boolean, subId?: number): {
    [key: number]: number
  } {
    const amountByYear = {};
    const years = isBooked ? this.yearsBooking : this.yearsPlanning;
    for (const year of Object.keys(years)) {
      amountByYear[year] = this.getValue(this.calculateBookingPlanningSum(data, undefined, productId, isBooked, years[year].From, years[year].To, subId), convertKgToTons);
    }
    return amountByYear;
  }

  private getValue(value: number, convertKgToTons?: boolean): number {
    if (!value || value === 0) {
      return undefined;
    }
    if (convertKgToTons) {
      return this.convertToTons(value);
    }
    return value;
  }

  private _findProductItem(items: INutrientOverviewStat2Data[], application: INutrientOverviewApplGridData): INutrientOverviewStat2Data {
    if (application.ProductId !== 0) {
      return items.Find(_ => _.ProductId === application.ProductId);
    } else {
      return items.Find(_ => _.SubId === this._getPureNutrientSubId(application));
    }
  }

  private _getPureNutrientSubId(application: INutrientOverviewApplGridData): number {
    if (typeof application.Remain2.P === 'number') {
      return 1;
    } else if (typeof application.Remain2.K === 'number') {
      return 2;
    } else if (typeof application.Remain2.Mg === 'number') {
      return 3;
    } else if (typeof application.Remain2.CaO === 'number') {
      return 4;
    } else if (typeof application.Remain2.N === 'number') {
      return 5;
    }
    return undefined;
  }
}

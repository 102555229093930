import {IAction, IApInvokerArguments} from '../../interfaces';
import IPlantProtectionPlanBook = Data.TaskManagement.IPlantProtectionPlanBook;

export enum PlantProtectionPlanBooksActions {
  PLANT_PROTECTION_PLANBOOKS_LOAD = '[plant_protection] planbooks load',
  PLANT_PROTECTION_PLANBOOKS_LOAD_SUCCESS = '[plant_protection] planbooks load success',
  PLANT_PROTECTION_PLANBOOKS_LOAD_FAIL = '[plant_protection] planbooks load fail',
  PLANT_PROTECTION_PLANBOOKS_SAVE = '[plant_protection] planbooks save',
  PLANT_PROTECTION_PLANBOOK_SET = '[plant_protection] planbook set',
  PLANT_PROTECTION_LOGFILE_LOAD = '[plant_protection] logfile load',
  PLANT_PROTECTION_LOGFILE_LOAD_SUCCESS = '[plant_protection] logfile load success',
  PLANT_PROTECTION_LOGFILE_LOAD_FAILED = '[plant_protection] logfile load failed',
  PLANT_PROTECTION_LOGFILES_UPDATE = '[plant_protection] logfile save',
  PLANT_PROTECTION_LOGFILES_REMAP = '[plant_protection] logfile remap',
  PLANT_PROTECTION_LOGFILES_BOOK = '[plant_protection] logfile book',
  PLANT_PROTECTION_LOGFILES_UNBOOK = '[plant_protection] logfile unbook',
  PLANT_PROTECTION_SUMMARIES_DELETE = '[plant_protection] summaries delete'
}

/**
 * load plant protections from backend
 */
export class PlantProtectionPlanBooksLoad implements IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_PLANBOOKS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * plant protections load success
 */
export class PlantProtectionPlanBooksLoadSuccess implements IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_PLANBOOKS_LOAD_SUCCESS;

  constructor(public payload: IPlantProtectionPlanBook[]) {
  }
}

/**
 * plant protections load fail
 */
export class PlantProtectionPlanBooksLoadFail implements IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_PLANBOOKS_LOAD_FAIL;

  constructor(public payload: IPlantProtectionPlanBook[]) {
  }
}

/**
 * plant protections save
 */
export class PlantProtectionPlanBooksSave implements IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_PLANBOOKS_SAVE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * plant protections create
 */
export class PlantProtectionPlanBookSet implements IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_PLANBOOK_SET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionLogfilesLoad implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_LOGFILE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionLogfilesLoadSuccess implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_LOGFILE_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionLogfilesLoadFail implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_LOGFILE_LOAD_FAILED;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionLogfilesUpdate implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_LOGFILES_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionLogfilesRemap implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_LOGFILES_REMAP;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionLogfilesBook implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_LOGFILES_BOOK;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionLogfilesUnBook implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_LOGFILES_UNBOOK;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class PlantProtectionSummariesDelete implements  IAction {
  readonly type = PlantProtectionPlanBooksActions.PLANT_PROTECTION_SUMMARIES_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

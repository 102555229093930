import {AfterContentInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ApDynformsConfigFieldset
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {Validators}                                                from '@angular/forms';
import {
  FertilizerInorganicStore
}                                                                  from '../../stores/base-data/fertilizer.inorganic.store';
import {FormStore}                                                 from '../../stores/layout/form.store';
import {LoginStore}                                                from '../../stores/login/login.store';
import {BehaviorSubject, combineLatest, Subscription}              from 'rxjs';
import {FactorService}                                             from '../../ap-utils/service/ap-factor.service';
import {UnitsStore}                                                from '../../stores/common/units.store';
import {ApTranslationService}                                      from '../../ap-utils/service/ap-translation.service';
import {
  ApDynformsConfigNumerictextbox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {ApDynformsValidator}                                       from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsConfigComboBox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigCheckbox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  ApDynformsConfigTextbox
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsValidations}                                     from '../../ap-dynforms/ap-dynforms-validations';
import {
  ApDynformsConfigPlaceholder
}                                                                  from '../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {filter, map, switchMap}                                    from 'rxjs/operators';
import {ObjectFactory}                                             from 'ts-tooling';
import {
  ApDynComponentComponent
}                                                                  from '../../ap-dyncomponent/ap-dyncomponent.component';
import IUnit = Data.Common.IUnit;
import IFertilizerInorg = Data.BaseData.IFertilizerInorg;

/**
 * Fertilizer Inorganic component.
 */
@Component({
  selector: 'ap-fertilizer-inorganic-edit',
  template: `
    <ap-dyncomponent [fieldSets]="formConfig" [caption]="caption">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_fertilizer_inorg_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{ 'Global__Cancel' | translate }}
        </button>
        <button id="button_fertilizer_inorg_edit_save"
                type="button" [disabled]="saveButtonDisabled$ | async"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{ 'Global__Save' | translate }}
        </button>
      </div>
    </ap-dyncomponent>`
})
/**
 * Fertilizer Inorganic edit component.
 */
export class ApFertilizerInorgsEditComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) dynComponent: ApDynComponentComponent;
  public data: any;
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public units: IUnit[];
  public uniqueNames: any[];
  public format = 'n2';
  public caption: string;
  public saveDisabled$ = new BehaviorSubject<boolean>(false);
  public saveButtonDisabled$ = new BehaviorSubject<boolean>(true);

  private _subscriptions: Array<Subscription> = [];
  private _isLock = false;

  constructor(private formStore: FormStore,
              private fertilizerInorganicStore: FertilizerInorganicStore,
              private loginStore: LoginStore,
              private factorService: FactorService,
              private unitStore: UnitsStore,
              private translationService: ApTranslationService) {
  }

  /**
   * when the component is loaded
   */
  public ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        Id: '',
        FarmId: this.loginStore.SelectedFarmId,
        Selectable: true,
        Prodname: null,
        Note: null,
        IsLiquid: false,
        ContentCaO: 0,
        ContentK: 0,
        ContentMg: 0,
        ContentN: 0,
        ContentP: 0,
        ContentS: 0,
        Efficiency: 100,
        EquivCaO: 0,
        Density: 1,
        Unit: null,
        RealContentCaO: 0,
        AlkalineEff: false,
        AlkalineCaO: 0,
        ContentCaCO3: 0,
        ContentMgO: 0,
        ContentP2O5: 0,
        ContentK2O: 0,
        ContentMgCO3: 0
      };
    } else {
      this.isNewMode = false;
      this.data.ContentCaO = this.data.RealContentCaO;
    }
    this.caption = this.isNewMode ? 'Settings__Lbl_Fertilizer_Create' : 'Settings__Lbl_Fertilizer_Edit';
    this.units = this.unitStore.Listen(s => s.data).getValue();
    this.uniqueNames = this.fertilizerInorganicStore.getFertilizerInorganic().map((n) => ({Name: n.Prodname}));
    if (!this.isNewMode) {
      this.uniqueNames = this.uniqueNames.filter(uN => uN.Name !== this.data.Prodname);
    }
    this.data.Unit = this.unitStore.getUnit(this.data.IsLiquid ? 'l' : 'kg');
    this._formBuilder();
    this.dynComponent.dynForm.initialized.subscribe(() => {
      if (!this.isNewMode) {
        this.dynComponent.dynForm.form.controls['ContentP2O5'].setValue(this.factorService.calculate(this.data.ContentP, 'P', 'P2O5'));
        this.dynComponent.dynForm.form.controls['ContentK2O'].setValue(this.factorService.calculate(this.data.ContentK, 'K', 'K2O'));
        this.dynComponent.dynForm.form.controls['ContentMgO'].setValue(this.factorService.calculate(this.data.ContentMg, 'Mg', 'MgO'));
        this.dynComponent.dynForm.form.controls['ContentCaCO3'].setValue(this.factorService.calculate(this.data.ContentCaO, 'CaO', 'CaCO3'));
        this.dynComponent.dynForm.form.controls['ContentMgCO3'].setValue(this.factorService.calculate(this.factorService.calculate(this.data.ContentMg, 'Mg', 'MgO'), 'MgO', 'MgCO3'));
        if (this.data.AlkalineEff) {
          const tmp = this.dynComponent.dynForm.form.get('ContentMgO').value * 1.391;
          const cal = this.data.ContentCaO + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        }
        if (this.data.IsLiquid) {
          this.dynComponent.dynForm.form.controls['Density'].enable();
        } else {
          this.dynComponent.dynForm.form.controls['Density'].disable();
        }
      }
      this.dynComponent.dynForm.form.get('IsLiquid').valueChanges.subscribe((c) => {
        if (c) {
          this.data.Unit = this.unitStore.getUnit('l');
          this.dynComponent.dynForm.form.controls['UnitId'].setValue(this.data.Unit.Id);
          this.dynComponent.dynForm.form.controls['UnitId'].disable();
          this.dynComponent.dynForm.form.controls['Density'].enable();
        } else {
          this.data.Unit = this.unitStore.getUnit('kg');
          this.dynComponent.dynForm.form.controls['UnitId'].setValue(this.data.Unit.Id);
          this.dynComponent.dynForm.form.controls['UnitId'].disable();
          this.dynComponent.dynForm.form.controls['Density'].disable();
          this.dynComponent.dynForm.form.controls['Density'].setValue(1);
        }
      });
      this.dynComponent.dynForm.form.get('ContentP').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'P', 'P2O5');
      });
      this.dynComponent.dynForm.form.get('ContentP2O5').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'P2O5', 'P');
      });
      this.dynComponent.dynForm.form.get('ContentK').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'K', 'K2O');
      });
      this.dynComponent.dynForm.form.get('ContentK2O').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'K2O', 'K');
      });
      this.dynComponent.dynForm.form.get('ContentMg').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'Mg', 'MgO');
        setTimeout(() => this._calculateFormValue(c, 'Mg', 'MgCO3'), 1);
      });
      this.dynComponent.dynForm.form.get('ContentMgO').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'MgO', 'Mg');
        setTimeout(() => this._calculateFormValue(c, 'MgO', 'MgCO3'), 1);
        if (this.dynComponent.dynForm.form.get('AlkalineEff').value) {
          const tmp = c * 1.391;
          const cal = this.dynComponent.dynForm.form.get('ContentCaO').value + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        }
      });
      this.dynComponent.dynForm.form.get('ContentMgCO3').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'MgCO3', 'MgO');
        setTimeout(() => this._calculateFormValue(c, 'MgCO3', 'Mg'), 1);
        if (this.dynComponent.dynForm.form.get('AlkalineEff').value) {
          const tmp = this.dynComponent.dynForm.form.get('ContentMgO').value * 1.391;
          const cal = this.dynComponent.dynForm.form.get('ContentCaO').value + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        }
      });
      this.dynComponent.dynForm.form.get('ContentCaO').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'CaO', 'CaCO3');
        if (this.dynComponent.dynForm.form.get('AlkalineEff').value) {
          const tmp = this.dynComponent.dynForm.form.get('ContentMgO').value * 1.391;
          const cal = c + tmp;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(cal);
        }
      });
      this.dynComponent.dynForm.form.get('ContentCaCO3').valueChanges.subscribe((c) => {
        this._calculateFormValue(c, 'CaCO3', 'CaO');
      });
      this.dynComponent.dynForm.form.get('AlkalineEff').valueChanges.subscribe((c) => {
        if (c) {
          const CaO = this.dynComponent.dynForm.form.get('ContentCaO').value;
          const MgO = this.dynComponent.dynForm.form.get('ContentMgO').value;
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(CaO + MgO * 1.391);
        } else {
          this.dynComponent.dynForm.form.controls['ContentNeutralisationValue'].setValue(0);
        }
      });
      this._subscriptions.push(combineLatest([
        this.dynComponent.dynForm.form$.pipe(
          filter((form) => form != null),
          switchMap((form) => form.statusChanges),
          map((status) => status !== 'VALID')
        ), this.saveDisabled$])
        .subscribe((status) => {
          this.dynComponent.dynForm.form.markAllAsTouched();
          this.saveButtonDisabled$.next(status.Any((s) => s));
        })
      );
    });
  }

  public ngAfterContentInit(): void {
    setTimeout(() => this.saveButtonDisabled$.next(!this.dynComponent.dynForm.form?.valid));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(d => d?.unsubscribe());
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  public onCancelClick(): void {
    this.formStore.closeForm();
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  public onSubmitClick(): void {
    ObjectFactory.Merge(this.data, this.dynComponent.dynForm.form.value);
    if (this.data.IsLiquid === '') {
      this.data.IsLiquid = false;
    }

    if (this.data.IsLiquid) {
      this.data.Unit = this.unitStore.getUnit('l');
    } else {
      this.data.Unit = this.unitStore.getUnit('kg');
    }

    if (this.isNewMode) {
      this.data.FarmId = this.loginStore.SelectedFarmId;
      this.data.Id = 0;
      this.fertilizerInorganicStore.createFertilizerInorganic(this.data);
    } else {
      if (this.data.FarmId !== this.loginStore.SelectedFarmId) {
        this.data.FarmId = this.loginStore.SelectedFarmId;
        this.data.Id = 0;
      }
      this.fertilizerInorganicStore.updateFertilizerInorganic(this.data);
    }
    this.formStore.closeForm();
  }

  private _calculateFormValue(value: number, source: string, target: string): void {
    if (this._isLock) {
      return;
    }
    this._isLock = true;
    let next = this.factorService.calculate(value, source, target);
    if (next < 0) {
      next = 0;
    }
    const targetString = 'Content' + target;
    this.dynComponent.dynForm.form.get(targetString).setValue(next);
    setTimeout(() => this._isLock = false, 1);
  }

  /**
   * binding of validators
   */
  private _formBuilder(): void {
    this.formConfig = [this._getFertilizerFieldset(), this._getContentFieldset(), this._getAlkalineFieldset()];
  }

  private _getLabelWithUnit(text: string, unit: string): string {
    return this.translationService.translate(text) + ' [' + unit + ']';
  }

  private _getFertilizerFieldset(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'fertilizer1',
      config: [
        new ApDynformsConfigTextbox({
          key: 'Prodname',
          label: 'Global__Name',
          value: this.data.Prodname,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Fill_Name',
              always: true
            }),
            new ApDynformsValidator({
              validator: Validators.minLength(3),
              errorKey: 'Settings__Msg_Vali_Biggerthree_Short'
            }),
            new ApDynformsValidator({
              validator: ApDynformsValidations.validateList(this.uniqueNames),
              errorKey: 'Settings__Msg_Vali_DoubleName'
            })
          ]
        }),
        new ApDynformsConfigTextbox({
          key: 'Note',
          label: 'Global__Note',
          value: this.data.Note,
          validators: [
            new ApDynformsValidator({
              validator: Validators.maxLength(20),
              errorKey: 'Global_Not_More_Than_20_Characters'
            })
          ]
        }),
        new ApDynformsConfigCheckbox({
          key: 'IsLiquid',
          label: 'Liquid',
          value: this.data.IsLiquid,
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'Density',
          label: this._getLabelWithUnit('Global__Density', 'kg/l'),
          value: this.data.Density,
          format: this.format,
          min: 0.5,
          max: 2,
          disabled: !this.data.IsLiquid
        }),
        new ApDynformsConfigComboBox({
          key: 'UnitId',
          value: this.data.Unit != null ? this.data.Unit.Id : this.units.FirstOrDefault().Id,
          valueField: 'Id',
          textField: 'Description',
          label: 'Settings__Lbl_Menu_Unit',
          options: this.units,
          valuePrimitive: true,
          disabled: true,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
      ]
    });
  }

  private _getContentFieldset(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'fertilizer2',
      legend: 'Global__Content',
      config: [
        new ApDynformsConfigNumerictextbox({
          key: 'ContentN',
          label: this._getLabelWithUnit('Global__NutrientN', '%'),
          value: this.data.ContentN || 0,
          format: this.format,
          min: -100,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'Efficiency',
          label: this._getLabelWithUnit('Settings__Lbl_NEffectiveness', '%'),
          value: this.data.Efficiency,
          format: 'n0',
          decimals: 0,
          min: 0,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentS',
          label: this._getLabelWithUnit('Global__NutrientS', '%'),
          value: this.data.ContentS || 0,
          format: this.format,
          min: 0,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigPlaceholder(),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentP',
          label: this._getLabelWithUnit('Nutrients__P', '%'),
          value: this.data.ContentP || 0,
          format: this.format,
          min: 0,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentP2O5',
          label: this._getLabelWithUnit('Global__NutrientP2O5', '%'),
          value: this.data.ContentP2O5 || 0,
          format: this.format,
          min: 0,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentK',
          label: this._getLabelWithUnit('Global__NutrientK', '%'),
          value: this.data.ContentK || 0,
          format: this.format,
          min: 0,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentK2O',
          label: this._getLabelWithUnit('Global__NutrientK2O', '%'),
          value: this.data.ContentK2O || 0,
          format: this.format,
          min: 0,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentMg',
          label: this._getLabelWithUnit('Global__NutrientMg', '%'),
          value: this.data.ContentMg || 0,
          format: this.format,
          min: 0,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentMgO',
          label: this._getLabelWithUnit('Global__NutrientMgO', '%'),
          value: this.data.ContentMgO || 0,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigPlaceholder(),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentMgCO3',
          label: this._getLabelWithUnit('Global__Unit_MgCO3', '%'),
          value: this.data.ContentMgCO3 || 0,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentCaO',
          label: this._getLabelWithUnit('Global__NutrientCaO', '%'),
          value: this.data.ContentCaO || 0,
          infoText: 'Info__CaO',
          format: this.format,
          min: 0,
          max: 100,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'ContentCaCO3',
          label: this._getLabelWithUnit('Global__Unit_CaCO3', '%'),
          value: this.data.ContentCaCO3,
          format: this.format,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ]
        }),
        new ApDynformsConfigCheckbox({
          key: 'AlkalineEff',
          infoText: 'Info__Alkaline_Effective',
          label: 'Global__Alkaline_Effective',
          value: this.data.AlkalineEff
        }),
      ]
    });
  }

  private _getAlkalineFieldset(): ApDynformsConfigFieldset {
    return new ApDynformsConfigFieldset({
      key: 'fertilizer3',
      legend: 'Global__Basic_Effect',
      config: [
        new ApDynformsConfigNumerictextbox({
          key: 'ContentNeutralisationValue',
          infoText: 'Info__Neutralisation_Value',
          label: this._getLabelWithUnit('Global__Neutralisation_Value', '%'),
          value: this.data.ContentCaO + (this.data.ContentMgO * 1.391),
          format: this.format,
          disabled$: this.dynComponent.FormValues$.pipe(map((v: IFertilizerInorg) => {
            return !v.AlkalineEff || v.AlkalineCaO > 0;
          })),
          readonly: true
        }),
        new ApDynformsConfigNumerictextbox({
          key: 'AlkalineCaO',
          infoText: 'Info_Alkaline_Effective_Content',
          label: this._getLabelWithUnit('Global__Alkaline_Effective_Ingredient', '%'),
          value: this.data.AlkalineCaO,
          format: this.format,
          min: 0,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })
          ],
          disabled$: this.dynComponent.FormValues$.pipe(map((v: IFertilizerInorg) => !v.AlkalineEff))
        })
      ]
    });
  }
}

import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  NotificationStore
}                                                                                      from '../../stores/common/notification.store';
import {filter, map}                                                                   from 'rxjs/operators';
import {
  TranslationStore
}                                                                                      from '../../stores/translation/translation.store';
import * as moment                                                                     from 'moment';
import {
  RouterStore
}                                                                                      from '../../stores/router/router.store';
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent
}                                                         from '@progress/kendo-angular-grid';
import {DateTime, ListSortOrder, ObjectFactory, Throttle} from 'ts-tooling';
import {BehaviorSubject, combineLatest, Observable}       from 'rxjs';
import {
  process
}                                                         from '@progress/kendo-data-query';
import {
  PanelBarItemModel
}                                                         from '@progress/kendo-angular-layout';
import INotifications = Data.Common.INotifications;
import IGuid = System.IGuid;

interface INotify {
  Id: IGuid;
  SourceItem: INotifications;
  Timestamp: Date;
  Link?: string;
}

@Component({
  selector: 'ap-notification',
  templateUrl: 'ap-notification.component.html',
  styleUrls: ['ap-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApNotificationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('apToolbarNotifications', {static: false}) private apToolbarNotifications: ElementRef;
  public show = false;
  bellRings = false;
  moment = moment;
  public outsideClickHandlerRef = this.outsideClickHandler.bind(this);
  notificationLoading$ = this.notificationStore.Listen(s => s.loading);
  public items: Array<PanelBarItemModel> = [
    {
      title: this.translationStore.FindTranslationForSelectedLanguage('Global_Notifications'),
      content: 'First item content',
      expanded: true
    } as PanelBarItemModel
  ];
  itemsNew = new BehaviorSubject<INotify[]>([]);
  itemsOld = new BehaviorSubject<INotify[]>([]);
  itemNewHeight = 0;
  itemOldHeight = 0;
  newSkip = new BehaviorSubject<number>(0);
  oldSkip = new BehaviorSubject<number>(0);
  onNewMessages = this.notificationStore.Listen(s => s.data)
    .pipe(
      filter(d => !!d),
    ).subscribe(notifies => {
      const itemsNew = [];
      const itemsOld = [];
      for (const notify of ObjectFactory.Copy(notifies).SortBy(['Timestamp'], [ListSortOrder.DESC])) {
        if (notify.WasRead) {
          itemsOld.Add({
            Id: notify.Id,
            SourceItem: notify,
            Timestamp: notify.Timestamp,
            Link: notify.Link !== '' ? notify.Link : null
          });
        } else {
          itemsNew.Add({
            Id: notify.Id,
            SourceItem: notify,
            Timestamp: notify.Timestamp,
            Link: notify.Link !== '' ? notify.Link : null
          });
        }
        const current = new DateTime('UTC').ToUnixTimestamp(true);
        const actual = DateTime.FromISOString(notify.Timestamp.toString(), 'UTC').ToUnixTimestamp(true);
        const age = current - actual;
        if (age / 1000 < 5) {
          this.ringBell(1000);
        }
      }
      this.itemNewHeight = Math.min(138, itemsNew.length * 45) + 2;
      this.itemOldHeight = Math.min(138, itemsOld.length * 45) + 2;
      this.itemsNew.next(itemsNew);
      this.itemsOld.next(itemsOld);
    });

  itemNewView = this._toView(this.itemsNew, this.newSkip);
  itemOldView = this._toView(this.itemsOld, this.oldSkip);

  constructor(private notificationStore: NotificationStore,
              private translationStore: TranslationStore,
              private routerStore: RouterStore) {
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', this.outsideClickHandlerRef);
  }

  public outsideClickHandler($event: any): void {
    if (!this.show) {
      return;
    }
    // close popup on outside of button-click:
    if (!this.apToolbarNotifications?.nativeElement?.contains($event.target)) {
      this.show = false;
    }
  };

  ngOnDestroy(): void {
    this.onNewMessages.unsubscribe();
    document.removeEventListener('click', this.outsideClickHandlerRef);
  }

  public onClick(): void {
    this.show = !this.show;
  }

  async onItemClick(item: INotifications): Promise<void> {
    if (item.Link !== undefined) {
      if (item.Link !== null) {
        await this.routerStore.navigate([item.Link]);
      }
    }
  }

  onDeleteClick(item: INotifications): void {
    this.notificationStore.deleteNotification(item);
  }

  onAllNotificationReadClick(): void {
    this.notificationStore.getNotifications().forEach((i: any, idx: number) => {
      const source = i as INotifications;
      if (!source.WasRead) {
        this.notificationStore.ChangeWasRead(idx, true);
      }
    });
  }

  onAllNotificationDeleteClick(): void {
    this.notificationStore.deleteAllNotifications();
    // this.notificationStore.getNotifications().forEach((i: any) => {
    //   const source = i as INotifications;
    //   this.notificationStore.deleteNotification(source);
    // });
  }

  cellClick(cell: CellClickEvent): void {
    if (!cell) {
      return;
    }
    if (!cell.dataItem) {
      return;
    }
    if (!cell.dataItem.WasRead) {
      this.notificationStore.ChangeWasReadWithId(cell.dataItem.Id, true);
    }
  }

  private ringBell(duration = 3000): void {
    Throttle({
      Leading: true,
      Timeout: duration,
    }, () => {
      this.bellRings = true;
      setTimeout(() => {
        this.bellRings = false;
      }, duration);
    });
  }

  newPageChange(event: PageChangeEvent): void {
    this.newSkip.next(event.skip);
  }

  oldPageChange(event: PageChangeEvent): void {
    this.oldSkip.next(event.skip);
  }

  private _toView(items$: BehaviorSubject<INotify[]>, skip$: BehaviorSubject<number>): Observable<GridDataResult> {
    return combineLatest([items$, skip$]).pipe(map(([items, skip]) => process(items, {skip, take: 9})));
  }
}

import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ILayerControlLayer}                                    from '../../ap-interface/map/layer-control-layer.interface';
import {MapLayerControlStore}                                  from '../../stores/map/map.layer.control.store';
import {BingImagery}                                           from '../../ap-map';

interface LayerItem {
  text: string;
  layer: ILayerControlLayer;
}

@Component({
  selector: 'ap-map-layer-control',
  templateUrl: './map-layer-control.component.html',
  styleUrls: ['./map-layer-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapLayerControlComponent {
  static type = 'MapLayerControlComponent';

  constructor(public mapLayerControlStore: MapLayerControlStore) {
  }

  rowDrop(event: { sourceItem: LayerItem; destinationItem: LayerItem }): void {
    this.mapLayerControlStore.reorder(event.sourceItem, event.destinationItem, 1);
  }

  clickCheckBox(dataItem: LayerItem): void {
    this.mapLayerControlStore.onVisibilityChanged.emit({
      layer: dataItem.layer,
      visibility: !dataItem.layer.Visibility,
    });
  }

  reorder(dataItem: LayerItem, dir: 1 | -1): void {
    this.mapLayerControlStore.move(dataItem, dir);
  }

  mapsChange(event: { text: string, value: BingImagery }): void {
    this.mapLayerControlStore.onBackgroundMapChanged.emit(event.value);
  }
}

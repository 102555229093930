import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getUnitValue',
})
export class GetUnitValuePipe implements PipeTransform {
  transform(value: { [key: string]: string } | string, ...args: string[]): string {
    if (!value) {
      return '';
    }
    if (typeof value === typeof '') {
      return value as string;
    }
    return value[args[0]];
  }
}

import IWorktypeUnit = Data.BaseData.IWorktypeUnit;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum WorktypeUnitActions {
  WORKTYPE_UNIT_LOAD = '[worktype-unit] load',
  WORKTYPE_UNIT_LOAD_SUCCESS = '[worktype-unit] load success',
}

export class WorktypeUnitLoad implements IAction {
  readonly type = WorktypeUnitActions.WORKTYPE_UNIT_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class WorktypeUnitLoadSuccess implements IAction {
  readonly type = WorktypeUnitActions.WORKTYPE_UNIT_LOAD_SUCCESS;

  constructor(public payload: IWorktypeUnit[]) {
  }
}

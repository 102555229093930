import IField = Data.FieldManagement.IField;
import IFieldGeom = Data.FieldManagement.IFieldGeom;

export type DashboardData = {
  UserForename: string;
  General: GeneralFieldsData;
  BaseFertilisation: BaseFertilisationData;
  NFertilization: NFertilizationData;
  Tasks: TasksData;
  BaseData: BaseDataData;
};

export type GeneralFieldsData = {
  AreaHa: number;
  CropRotationPercent: number;
  FieldsWithSamplingPercent: number;
};

export type BaseFertilisationData = {
  Elements: string[];
  PlanningData: FieldDemandChartData[];
};

export type NFertilizationData = {
  FieldsWithApplicationsPercent: number;
  LogfilesTotalAmount: number;
  LogfilesPercent: number;
};

export type TasksData = {
  OpenFieldsCount: number;
  OpenFieldsAreaHa: number;
  ExportedTasksPercent: number;
};

export type BaseDataData = {
  CropTypes: number;
  FertiliserMineral: number;
  FertiliserOrganic: number;
  PlantProtectionProducts: number;
  Machines: number;
  Implements: number;
  Workers: number;
};

export type FieldDemandChartData = {
  Key: FieldDemandChartDataKey;
  TranslationKey: string;
  Color: string;
  Data: number[];
};

export type FieldDemandData = {
  Need: Elements,
  Remain1: Elements,
  Remain2: Elements,
};

export type ElementDemandSum = {
  Initial: number,
  Recorded: number,
  Planned: number,
};

export type Elements = {
  P: number,
  K: number,
  Mg: number,
  CaO: number
};

export type FieldWithFieldGeom = {
  Field: IField;
  FieldGeom: IFieldGeom | undefined;
};

export enum FieldDemandChartDataKey {
  'Empty' = 0,
  'Planned' = 1,
  'Recorded'= 2
}

import {Component, Input} from '@angular/core';

export type InfoRowViewObjectData = {
  Text: string;
  Link?: () => void | undefined;
};

export type InfoRowViewObject = {
  Head: string;
  Data: InfoRowViewObjectData[];
};

@Component({
  selector: 'info-row-view',
  template: `
    <div class="row" [style]="rowNum | infoViewStyle:'row':maxRows">
      <div class="head" [style]="rowNum | infoViewStyle:'head':maxRows">
        <ap-responsive-text>
          {{ item.Head | translate }}
        </ap-responsive-text>
      </div>
      <div class="data" [style]="rowNum | infoViewStyle:'data':maxRows">
        <ng-container *ngFor="let value of item.Data">
          <ng-container *ngIf="value.Link; else noLink">
            <a class="link" (click)="value.Link()">
              <ap-responsive-text>
                {{ value.Text }}
              </ap-responsive-text>
            </a>
          </ng-container>
          <ng-template #noLink>
            <ap-responsive-text>
              {{ value.Text }}
            </ap-responsive-text>
          </ng-template>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      div.row {
        display: flex;
      }
    `,
    `
      div.head {
        padding: 5px;
        background-color: silver;
        font-weight: bold;
        width: calc(20% - 13px);
      }
    `,
    `
      div.data {
        padding: 5px;
        width: calc(80% - 13px);
      }
    `,
    `
      a.link {
        cursor: pointer;
        color: #b0cb1f;
        text-decoration: underline;
      }
    `
  ]
})
export class InfoRowViewComponent {
  @Input() maxRows = 0;
  @Input() rowNum = 0;
  @Input() item: InfoRowViewObject;
}

import {Component, OnDestroy, OnInit}                 from '@angular/core';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {
  BaseDataData,
  BaseFertilisationData,
  DashboardData,
  ElementDemandSum,
  Elements,
  FieldDemandChartData,
  FieldDemandChartDataKey,
  FieldDemandData,
  FieldWithFieldGeom,
  GeneralFieldsData,
  NFertilizationData,
  TasksData
}                                                     from './dashboard-stats.types';
import {FieldStore}                                   from '../../../../stores/farm/field.store';
import {NFertilizationStore}                          from '../../../../stores/n-fertilization/n-fertilization.store';
import {LoginStore}                                   from '../../../../stores/login/login.store';
import {CampaignYearStore}                            from '../../../../stores/login/campaignyear.store';
import {Create}                                       from 'ts-tooling';
import {ApElementType}                                from '../../../../ap-interface/enums/ap-elements-type.enum';
import {GetRoundNumericService}                       from '../../../../ap-utils/service/get-round-numeric.service';
import {CropTypeStore}                                from '../../../../stores/base-data/crop.types.store';
import {FertilizerStore}                              from '../../../../stores/base-data/fertilizer.store';
import {CombinedFertilizer}                           from '../../../../types/common-types';
import {DriverStore}                                  from '../../../../stores/docu/driver.store';
import {MachineStore}                                 from '../../../../stores/docu/machine.store';
import {InstrumentStore}                              from '../../../../stores/docu/instrument.store';
import {PlantProtectionProductStore}                  from '../../../../stores/base-data/plantprotection.product.store';
import {ApGetCropService}                             from '../../../../stores/services/ap-get-crop.service';
import {debounceTime, filter, map}                    from 'rxjs/operators';
import {NFertilizationService}                        from '../../../../services/data/n-fertilization.service';
import {CampaignYearService}                          from '../../../../services/data/campaign-year.service';
import {CropRotationStore}                            from '../../../../stores/farm/crop.rotation.store';
import {RbStore}                                      from '../../../../stores/nutrients/rb.store';
import {
  ApNutrientStatisticService
}                                                     from '../../../../nutrient-management/service/ap-nutrient-statistic.service';
import {ApNutrientService}                            from '../../../../ap-utils/service/ap-nutrient.service';
import {SoilSampleDates, SoilSampleDateStore}         from '../../../../stores/nutrients/soilsampledate.store';
import {TranslationStore}                             from '../../../../stores/translation/translation.store';
import {CssValueParser}                               from '../../../../ap-core/utils/ap-css-value.parser';
import {TaskManagementStore}                          from '../../../../stores/task-management/task-management.store';
import {
  FieldNutrientDistributionStore
}                                                     from '../../../../stores/nutrients/field-nutrient-distributions.store';
import IGuid = System.IGuid;
import IField = Data.FieldManagement.IField;
import ICropTypes = Data.BaseData.ICropTypes;
import IDriver = Data.DocuContext.Driver.IDriver;
import IRbStatistic = Data.Nutrients.IRbStatistic;
import IMachine = Data.DocuContext.Machine.IMachine;
import ISampleRegion = Data.Nutrients.ISampleRegion;
import IDatasetFieldMap = Data.Sensor.IDatasetFieldMap;
import ICampaignYear = Data.Authentication.ICampaignYear;
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IPlantProtectionProduct = Data.BaseData.IPlantProtectionProduct;
import INFertilizationPlanBook = Data.TaskManagement.INFertilizationPlanBook;
import ICropRotationAttributes = Data.FieldManagement.ICropRotationAttributes;
import ITaskManagementPlanBook = Data.TaskManagement.ITaskManagementPlanBook;

@Component({
  selector: 'ap-dashboard-stats',
  templateUrl: 'dashboard-stats.component.html',
  styleUrls: ['dashboard-stats.component.scss']
})
export class ApDashboardStatsComponent implements OnInit, OnDestroy {

  constructor(private rbStore: RbStore,
              private fieldStore: FieldStore,
              private loginStore: LoginStore,
              private driverStore: DriverStore,
              private machineStore: MachineStore,
              private cropTypesStore: CropTypeStore,
              private instrumentStore: InstrumentStore,
              private fertilizerStore: FertilizerStore,
              private translationStore: TranslationStore,
              private campaignYearStore: CampaignYearStore,
              private cropRotationStore: CropRotationStore,
              private taskManagementStore: TaskManagementStore,
              private soilSampleDateStore: SoilSampleDateStore,
              private nFertilizationStore: NFertilizationStore,
              private plantProtectionProductStore: PlantProtectionProductStore,
              private fieldNutrientDistributionStore: FieldNutrientDistributionStore,
              private cropService: ApGetCropService,
              private nutrientService: ApNutrientService,
              private campaignYearService: CampaignYearService,
              private roundNumericService: GetRoundNumericService,
              private nFertilizationService: NFertilizationService,
              private nutrientStatisticService: ApNutrientStatisticService) {
  }

  public data = new BehaviorSubject<DashboardData>(undefined);
  public readonly emptyTitle = 'Global__Dashboard';
  public readonly strokeColor = '#212529';
  public readonly borderColor = 'gray';
  public readonly linkTitle = this.translationStore.FindTranslationForSelectedLanguage('InfoText__Follow_Here_To_Fill_Up_Bars');

  private _subscriptions: Subscription[] = [];
  private readonly _defaultElementCategory: Elements = {P: 0, K: 0, Mg: 0, CaO: 0};
  private readonly _greenStatusColor = CssValueParser.getCssVariable('--accent-color');
  private readonly _yellowStatusColor = CssValueParser.getCssVariable('--warning-color');
  private readonly _redStatusColor = CssValueParser.getCssVariable('--error-color');

  public ngOnInit(): void {
    this._subscriptions.push(combineLatest([
      this.campaignYearStore.SelectedCampaignYear$,
      this.fieldStore.Fields$
    ]).subscribe(([selectedYear, fields]) => {
      // TODO: Remove force reload when mechanism of clearing data will be introduced after changing selected year or farm
      this._loadData(fields, selectedYear, true);
    }));
    this._subscriptions.push(combineLatest([
      this.loginStore.User$, this.campaignYearStore.SelectedCampaignYear$, this.fieldStore.Fields$,
      this.fieldNutrientDistributionStore.SampleRegions$, this.rbStore.RbStatistic$, this.cropRotationStore.GetAttributes$,
      this.nFertilizationStore.PlanBooks$, this.nFertilizationStore.Logfiles$, this.taskManagementStore.Tasks$,
      this.soilSampleDateStore.Dates$, this.cropTypesStore.CropTypes$, this.plantProtectionProductStore.Products$,
      this.fertilizerStore.Fertilizer$, this.driverStore.Drivers$, this.machineStore.Machines$, this.instrumentStore.Instruments$,
      combineLatest([
        this.rbStore.Loading$, this.nFertilizationStore.Loading$,
        this.nFertilizationStore.LogfilesLoading$, this.plantProtectionProductStore.Loading$
      ]).pipe(
        map(loadingItems => loadingItems.some(x => x))
      )
    ]).pipe(
      debounceTime(500),
      filter(([, , , , , , , , , , , , , , , , loading]) => !loading)
    ).subscribe(([user, selectedCampaignYear, fields, sampleRegions,
                   rbStatistics, cropRotationAttributes,
                   nFertilizationPlanBooks, nFertilizationLogfiles, tasks,
                   soilSampleDates, cropTypes, plantProtectionProducts,
                   fertilizers, drivers, machines, instruments]) => {
        const fieldsWithFieldGeoms = fields.map(field => this._getFieldWithFieldGeom(field, selectedCampaignYear));
        const campaignYearRange = this.campaignYearService.getCampaignYearRange(selectedCampaignYear.Year);
        const filteredNFertPlanBooks = nFertilizationPlanBooks?.filter(x => {
          const applicationDate = new Date(x.ApplicationDate);
          return !x.DeletedAt
            && this.campaignYearService.isCampaignYearIntersects(applicationDate, applicationDate, campaignYearRange);
        }) ?? [];
        const filteredNFertLogfiles = nFertilizationLogfiles?.filter(x => {
          const dataSet = x?.DataSet;
          const dataSetCreated = new Date(dataSet?.Created);
          return dataSet
            && this.campaignYearService.isCampaignYearIntersects(dataSetCreated, dataSetCreated, campaignYearRange);
        }) ?? [];
        const filteredTasks = tasks?.map(x => x.PlanBooks).flat().filter(x => {
          const applDate = new Date(x.ApplDate);
          return this.campaignYearService.isCampaignYearIntersects(applDate, applDate, campaignYearRange);
        }) ?? [];
        this.data.next({
          UserForename: user.Forename,
          General: this._generateGeneral(fieldsWithFieldGeoms, soilSampleDates),
          BaseFertilisation: this._generateBaseFertilisationData(fieldsWithFieldGeoms, rbStatistics,
            cropRotationAttributes, soilSampleDates, sampleRegions),
          NFertilization: this._generaNFertilization(fieldsWithFieldGeoms, filteredNFertPlanBooks, filteredNFertLogfiles),
          Tasks: this._generateTasks(fieldsWithFieldGeoms, filteredTasks),
          BaseData: this._generateBaseData(cropTypes, plantProtectionProducts, fertilizers, drivers, machines, instruments)
        });
      }
    ));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(x => x?.unsubscribe());
  }

  public setBarColorDependingOnValue(value: number): string {
    switch (true) {
      case value >= 90:
        return this._greenStatusColor;
      case value >= 50 && value <= 90:
        return this._yellowStatusColor;
      default:
        return this._redStatusColor;
    }
  }

  public setStylesForAmountValues(value: number): any {
    if (value <= 0) {
      return {
        color: this._redStatusColor,
        fontWeight: 'bold'
      };
    }
    return {
      color: this.strokeColor,
    };
  }

  //#region "Generate data"

  private _generateGeneral(fieldsWithFieldGeoms: FieldWithFieldGeom[], soilSampleDates: SoilSampleDates): GeneralFieldsData {
    const totalFieldsAreaHa = this._getFieldsArea(fieldsWithFieldGeoms);
    const fieldsWithCropRotation = fieldsWithFieldGeoms?.filter(fieldWithFieldGeom => {
      const fieldCrop = this.cropService.getFieldCrop(fieldWithFieldGeom.Field);
      const mainCrop = this.cropService.getCropType(fieldCrop, true);
      const secondCrop = this.cropService.getCropType(fieldCrop, false);
      return mainCrop || secondCrop;
    }) ?? [];
    const cropRotationFieldsAreaHa = this._getFieldsArea(fieldsWithCropRotation);
    let soilSampleFieldsAreaHa = 0;
    if (Object.keys(soilSampleDates).length > 0) {
      soilSampleFieldsAreaHa = fieldsWithFieldGeoms?.reduce((totalArea, fieldWithFieldGeom) => {
        const sampleDateObject = soilSampleDates[fieldWithFieldGeom.Field.Id as string];
        if (sampleDateObject) {
          return totalArea + Create(fieldWithFieldGeom.FieldGeom?.AreaHa, 0);
        }
        return totalArea;
      }, 0) ?? 0;
    }
    return {
      AreaHa: totalFieldsAreaHa,
      CropRotationPercent: this._getPercent(cropRotationFieldsAreaHa, totalFieldsAreaHa),
      FieldsWithSamplingPercent: this._getPercent(soilSampleFieldsAreaHa, totalFieldsAreaHa)
    };
  }

  private _generateBaseFertilisationData(fieldsWithFieldGeoms: FieldWithFieldGeom[], rbStatistics: IRbStatistic[],
                                         cropRotationAttributes: ICropRotationAttributes[],
                                         soilSampleDates: SoilSampleDates,
                                         sampleRegions: ISampleRegion[]): BaseFertilisationData {
    const elements = Object.keys(ApElementType).filter(k => isNaN(Number(k)) && k !== 'N');
    const fieldsCropRotationDemand = fieldsWithFieldGeoms.filter(fieldGeom => fieldGeom)
      .map(item => {
        const rbResult = rbStatistics.find(x => x?.FieldGeomId === item.FieldGeom?.Id);
        const cropRotationAttribute = cropRotationAttributes.find(x => x?.FieldGeomId === item.FieldGeom?.Id);
        const sampleDateObject = Object.keys(soilSampleDates).length > 0
          ? soilSampleDates[item.Field.Id as string]
          : undefined;
        const status = this.nutrientService.getGDStatusByCrAttribute(sampleDateObject, sampleRegions, cropRotationAttribute);
        const area = item.FieldGeom?.AreaHa ?? 0;
        const fieldCropRotationDemand: FieldDemandData = {
          Need: status[0] === 1 ? {
            P: this.roundNumericService.roundAsNumber((cropRotationAttribute?.CropRotationNeed?.CropRotationNeedP ?? 0)) * area,
            K: this.roundNumericService.roundAsNumber((cropRotationAttribute?.CropRotationNeed?.CropRotationNeedK ?? 0)) * area,
            Mg: this.roundNumericService.roundAsNumber((cropRotationAttribute?.CropRotationNeed?.CropRotationNeedMg ?? 0)) * area,
            CaO: this.roundNumericService.roundAsNumber((cropRotationAttribute?.CropRotationNeed?.CropRotationNeedCaO ?? 0)) * area
          } : this._defaultElementCategory,
          Remain1: status[0] === 1 ? {
            P: this._getAverageRb(0, rbResult) * area,
            K: this._getAverageRb(1, rbResult) * area,
            Mg: this._getAverageRb(2, rbResult) * area,
            CaO: this._getAverageRb(3, rbResult) * area
          } : this._defaultElementCategory,
          Remain2: status[0] === 1 ? {
            P: this._getAverageRb(4, rbResult) * area,
            K: this._getAverageRb(5, rbResult) * area,
            Mg: this._getAverageRb(6, rbResult) * area,
            CaO: this._getAverageRb(7, rbResult) * area
          } : this._defaultElementCategory
        };
        return fieldCropRotationDemand;
      });
    const statisticData = this._getStatisticData(elements.length);
    const restStatisticItem = statisticData.find(x => x.Key === FieldDemandChartDataKey.Empty);
    const recordedStatisticItem = statisticData.find(x => x.Key === FieldDemandChartDataKey.Recorded);
    const plannedStatisticItem = statisticData.find(x => x.Key === FieldDemandChartDataKey.Planned);
    let index = 0;
    elements.forEach(element => {
      const elementDemand = fieldsCropRotationDemand.reduce((current, previous) => {
        current.Initial += previous.Need[element];
        current.Recorded += previous.Remain1[element];
        current.Planned += previous.Remain2[element];
        return current;
      }, {Initial: 0, Recorded: 0, Planned: 0} as ElementDemandSum);
      const recorded = (elementDemand.Initial - elementDemand.Recorded) / elementDemand.Initial;
      const planned = ((elementDemand.Initial - elementDemand.Planned) / elementDemand.Initial) - recorded;
      const rest = restStatisticItem.Data[index] = elementDemand.Planned / elementDemand.Initial;
      recordedStatisticItem.Data[index] = this.roundNumericService.roundAsNumber(recorded * 100, 2);
      plannedStatisticItem.Data[index] = this.roundNumericService.roundAsNumber(planned * 100, 2);
      restStatisticItem.Data[index] = this.roundNumericService.roundAsNumber(rest * 100, 2);
      index += 1;
    });
    return {
      Elements: elements,
      PlanningData: statisticData
    };
  }

  private _generaNFertilization(fieldsWithFieldGeoms: FieldWithFieldGeom[], planBooks: INFertilizationPlanBook[],
                                logFiles: IDatasetFieldMap[]): NFertilizationData {
    const noneDeclinedLogFilesWithPlanBooks = logFiles.filter(x => x.Status !== -1);
    const recordedLogFilesWithPlanBooks = logFiles.filter(x => x?.Status === 1 && x?.NPlanBook?.BookedAt);
    const fieldsWithApplication: FieldWithFieldGeom[] = [];
    planBooks.filter(x => x.BookedAt).forEach(planBook => {
      const application = this.nFertilizationService.getNApplications(planBook.RasterStatistics);
      const fieldWithFieldGeom = fieldsWithFieldGeoms.find(item => item.Field.Id === planBook.FieldId);
      if (application && fieldWithFieldGeom && !fieldsWithApplication.some(x => x.Field.Id === planBook.FieldId)) {
        fieldsWithApplication.push(fieldWithFieldGeom);
      }
    });
    const totalArea = this._getFieldsArea(fieldsWithFieldGeoms);
    const fieldsWithApplicationsArea = this._getFieldsArea(fieldsWithApplication);
    return {
      FieldsWithApplicationsPercent: this._getPercent(fieldsWithApplicationsArea, totalArea),
      LogfilesTotalAmount: noneDeclinedLogFilesWithPlanBooks.length,
      LogfilesPercent: this._getPercent(recordedLogFilesWithPlanBooks.length, noneDeclinedLogFilesWithPlanBooks.length)
    };
  }

  private _generateTasks(fieldsWithFieldGeoms: FieldWithFieldGeom[], tasks: ITaskManagementPlanBook[]): TasksData {
    const plannedFieldIds: IGuid[] = tasks.filter(x => !x.BookedAt).map(x => x.FieldId);
    const openedFields = plannedFieldIds.map(fieldId => fieldsWithFieldGeoms.find(x => x.Field.Id === fieldId));
    const openedFieldsArea = this._getFieldsArea(openedFields);
    const exportedTasks = tasks.filter(x => x.ExportedAt);
    return {
      OpenFieldsCount: openedFields.length,
      OpenFieldsAreaHa: openedFieldsArea,
      ExportedTasksPercent: this._getPercent(exportedTasks.length, tasks.length)
    };
  }

  private _generateBaseData(cropTypes: ICropTypes[], plantProtectionProducts: IPlantProtectionProduct[],
                            fertilizers: CombinedFertilizer[], drivers: IDriver[],
                            machines: IMachine[], instruments: IInstrument[]): BaseDataData {
    return {
      CropTypes: cropTypes?.filter(x => !x.DeletedAt && x.Selected)?.length ?? 0,
      FertiliserMineral: fertilizers?.filter(x => !x.DeletedAt && !x.IsOrganic && x.Selectable)?.length ?? 0,
      FertiliserOrganic: fertilizers?.filter(x => !x.DeletedAt && x.IsOrganic && x.Selectable)?.length ?? 0,
      PlantProtectionProducts: plantProtectionProducts?.filter(x => !x.DeletedAt && x.Selectable)?.length ?? 0,
      Machines: machines?.filter(x => !x.DeletedAt)?.length ?? 0,
      Implements: instruments?.filter(x => !x.DeletedAt)?.length ?? 0,
      Workers: drivers?.filter(x => !x.DeletedAt)?.length ?? 0
    };
  }

  //#endregion "Generate data"

  //#region "Private methods"

  private _loadData(fields: IField[], selectedCampaignYear: ICampaignYear, forceReload: boolean): void {
    if (!fields || fields.length <= 0) {
      return;
    }
    const rbStatistics = this.rbStore.RbStatistic;
    const plantProtectionProducts = this.plantProtectionProductStore.Products;
    const nFertilizationPlanBooks = this.nFertilizationStore.PlanBooks;
    const nFertilizationLogfiles = this.nFertilizationStore.Logfiles;
    const tasks = this.taskManagementStore.Tasks;
    if (!rbStatistics || rbStatistics.length <= 0 || forceReload) {
      const fieldsWithFieldGeoms = fields.map(field => this._getFieldWithFieldGeom(field, selectedCampaignYear));
      const fieldGeomsId = fieldsWithFieldGeoms.filter(x => x.FieldGeom)
        .map(x => x.FieldGeom.Id.toString());
      this.rbStore.loadRb(fieldGeomsId);
    }
    if (!plantProtectionProducts || plantProtectionProducts.length <= 0 || forceReload) {
      this.plantProtectionProductStore.loadPlantProtectionProducts(true, false);
    }
    if (!nFertilizationPlanBooks || nFertilizationPlanBooks.length <= 0 || forceReload) {
      this.nFertilizationStore.LoadNFertilizationPlanningSummaries();
    }
    if (!nFertilizationLogfiles || nFertilizationLogfiles.length <= 0 || forceReload) {
      this.nFertilizationStore.LoadLogfiles();
    }
    if (!tasks || tasks.length <= 0 || forceReload) {
      this.taskManagementStore.loadTaskManagementPlanSummaries();
    }
  }

  private _getAverageRb(bandIdX: number, statistic: IRbStatistic): number {
    const averageRb = this.nutrientStatisticService.getAverageRb(bandIdX, statistic);
    if (!averageRb || averageRb === '-') {
      return 0;
    }
    return this.roundNumericService.roundAsNumber(averageRb, 5);
  }

  private _getFieldsArea(fieldsWithFieldGeoms: FieldWithFieldGeom[]): number {
    if (!fieldsWithFieldGeoms || fieldsWithFieldGeoms.length <= 0) {
      return 0;
    }
    return fieldsWithFieldGeoms.reduce((totalAreaHa, fieldWithFieldGeom) => {
      if (!fieldWithFieldGeom?.FieldGeom) {
        return totalAreaHa;
      }
      return totalAreaHa + Create(fieldWithFieldGeom.FieldGeom.AreaHa, 0);
    }, 0);
  }

  private _getPercent(value: number, totalAmount: number): number {
    if (!value || !totalAmount) {
      return 0;
    }
    return this.roundNumericService.roundAsNumber(value / totalAmount * 100, 2);
  }

  private _getStatisticData(elementsCount: number): FieldDemandChartData[] {
    return [
      {
        Key: FieldDemandChartDataKey.Recorded,
        TranslationKey: 'Nutrients__Booked',
        Color: CssValueParser.getCssVariable('--accent-color'),
        Data: Array.from({length: elementsCount}, (_, _1) => 0)
      },
      {
        Key: FieldDemandChartDataKey.Planned,
        TranslationKey: 'Nutrients__Scheduled',
        Color: CssValueParser.getCssVariable('--warning-color'),
        Data: Array.from({length: elementsCount}, (_, _1) => 0)
      },
      {
        Key: FieldDemandChartDataKey.Empty,
        TranslationKey: '',
        Color: CssValueParser.getCssVariable('--unknown-color'),
        Data: Array.from({length: elementsCount}, (_, _1) => 0)
      }
    ];
  }

  private _getFieldWithFieldGeom(field: IField, selectedCampaignYear: ICampaignYear): FieldWithFieldGeom {
    const fieldGeom = this.fieldStore.getFieldGeom(field, selectedCampaignYear);
    return {
      Field: field,
      FieldGeom: fieldGeom ?? undefined
    };
  }

  //#endregion "Private methods"
}

import IContactInformation = Data.Common.IContactInformation;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum ContactInformationActions {
  CONTACT_INFORMATION_LOAD = '[contact_information] load',
  CONTACT_INFORMATION_LOAD_SUCCESS = '[contact_information] load success',
}

export class ContactInformationLoad implements IAction {
  readonly type = ContactInformationActions.CONTACT_INFORMATION_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ContactInformationLoadSuccess implements IAction {
  readonly type = ContactInformationActions.CONTACT_INFORMATION_LOAD_SUCCESS;

  constructor(public payload: IContactInformation[]) {
  }
}

import {IApMapColorLegend, IApMapColorLegendItem} from '../../ap-interface/interfaces';
import {Store}                                    from '../index';
import {Injectable}                               from '@angular/core';
import {ApDynamicComponentModel}                  from '../../entry-components/ap-dynamic-component-loader/ap-dynamic-component-loader.component';
import {ApSignalrService}                         from '../../ap-core/services/ap-signalr.service';

interface IColorLegendState {
  colorLegends: { [key: string]: IApMapColorLegend };
  selectedValue: any;
}

@Injectable({providedIn: 'root'})
export class ColorLegendStore extends Store<IColorLegendState> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      colorLegends: {},
      selectedValue: {}
    });
  }

  private static _getColors(items: any[]): IApMapColorLegendItem[] {
    const colors: IApMapColorLegendItem[] = [];

    for (const item of items) {
      if (item instanceof Array && (item as []).length >= 2 &&
        typeof item[0] === typeof '' && typeof item[0] === typeof '') {

        colors.push({color: item[0], label: item[1]});

      } else if (typeof item === typeof {} && (item as {}).hasOwnProperty('color')) {

        if ((item as {}).hasOwnProperty('label')) {
          colors.push({color: item['color'], label: item['label']});
        } else if ((item as {}).hasOwnProperty('value')) {
          colors.push({color: item['color'], label: item['value']});
        }
      }
    }

    return colors;
  }

  public setColorLegend(layer: { Visibility: boolean, name: string }, items: any[], title: string, options: {
    unit?: string,
    component?: ApDynamicComponentModel,
    value?: string
  }): void {
    super.Mutate(s => s.colorLegends, (map) => {
      map[title] = {
        colors: ColorLegendStore._getColors(items),
        title,
        unit: options.unit ? options.unit : '',
        component: options.component ? options.component : undefined,
        value: options.value ? options.value : '',
        layerName: layer.name,
      };
      return map;
    });
  }

  public resetColorLegend(title?: string): void {
    if (title === undefined) {
      super.Mutate(s => s.colorLegends, () => ({}));
      super.Mutate(s => s.selectedValue, () => ({}));
    } else {
      super.Mutate(s => s.colorLegends, (map) => {
        delete map[title];
        return map;
      });
    }
  }

  public valueChange(key, value: any): void {
    super.Mutate(s => s.selectedValue, () => {
      const o = {};
      o[key] = value;
      return o;
    });
  }
}

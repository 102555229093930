import {IStateStore}               from '../../ap-interface';
import {Store}                     from '../index';
import {Injectable}                from '@angular/core';
import {
  ImportCsvTypesLoad,
  ImportCsvTypesLoadSuccess,
  ImportDataSetColumnsLoad,
  ImportDataSetColumnsLoadSuccess,
  ImportDataSetDelete,
  ImportDataSetsLoad,
  ImportDataSetsLoadSuccess,
  ImportDataSetTypesLoad,
  ImportDataSetTypesLoadSuccess,
  ImportUpdate,
  NetTypes
}                                  from 'invoker-transport';
import {
  DownloadCsv,
  DownloadCsvSuccess, DownloadCsvZip, DownloadCsvZipSuccess,
  DownloadLog,
  DownloadLogSuccess
} from '../../../../projects/invoker-transport/src/lib/actions/import';
import * as download               from 'downloadjs';
import {combineLatest, Observable} from 'rxjs';
import {map}                       from 'rxjs/operators';
import IDataSetType = Data.Import.IDataSetType;
import IDataSetColumn = Data.Import.IDataSetColumn;
import ICsvType = Data.Import.ICsvType;
import IGuid = System.IGuid;
import IImportDataSet = Data.Import.IImportDataSet;
import ICampaignYear = Data.Authentication.ICampaignYear;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IImportStore extends IStateStore<IImportDataSet> {
  types: IDataSetType[];
  columns: IDataSetColumn[];
  loadingMapPreview: string;
  csvTypes: ICsvType[];
  updatedDataSet: IImportDataSet;
  loadingImportDataSets: boolean;
  datasetsCampaignYear: ICampaignYear;
}

@Injectable({providedIn: 'root'})
export class ImportStore extends Store<IImportStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loading: false,
      loaded: false,
      types: [],
      columns: [],
      loadingMapPreview: null,
      csvTypes: [],
      updatedDataSet: null,
      loadingImportDataSets: false,
      datasetsCampaignYear: null
    });

    backend.registerObservable(ImportDataSetTypesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.types, () => d.Data);
    });

    backend.registerObservable(ImportDataSetColumnsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.columns, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(ImportDataSetsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.loadingImportDataSets, () => false);
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(ImportCsvTypesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.csvTypes, () => d.Data);
      super.SetLoadFinishState();
    });

    backend.registerObservable(ImportUpdate).subscribe(d => {
      super.Mutate(s => s.updatedDataSet, () => d.Data);
    });

    backend.registerObservable(DownloadCsvSuccess).subscribe(d => {
      download(d.Data[1], d.Data[0], 'text/csv');
      super.SetLoadFinishState();
    });

    backend.registerObservable(DownloadCsvZipSuccess).subscribe(d => {
      download(d.Data[1], d.Data[0], 'application/zip');
      super.SetLoadFinishState();
    });

    backend.registerObservable(DownloadLogSuccess).subscribe(d => {
      download(d.Data[1], d.Data[0]);
      super.SetLoadFinishState();
    });
  }

  public Loading$(): Observable<boolean> {
    return combineLatest([
      super.Listen(s => s.loading),
      super.Listen(s => s.loadingImportDataSets)
    ]).pipe(map((loadings) => loadings.Any((l) => l)));
  }

  public loadImportDataSetTypes(): void {
    super.SetLoadState();
    this.DispatchBackend(new ImportDataSetTypesLoad([]));
  }

  public loadImportCsvTypes(): void {
    this.DispatchBackend(new ImportCsvTypesLoad([]));
  }

  public loadImportDataSetColumns(): void {
    this.DispatchBackend(new ImportDataSetColumnsLoad([]));
  }

  public loadImportDataSets(campaignYear: ICampaignYear): void {
    const currentCampaignYear = super.Listen(s => s.datasetsCampaignYear).getValue();
    if (currentCampaignYear?.Year === campaignYear?.Year && currentCampaignYear?.FarmId === campaignYear?.FarmId) {
      return;
    }
    super.Mutate(s => s.loadingImportDataSets, () => true);
    this.DispatchBackend(new ImportDataSetsLoad([]));
  }

  public deleteImportDataSet(id: IGuid): void {
    super.SetLoadState();
    this.DispatchBackend(new ImportDataSetDelete([
      {Name: 'dataSetId', Type: NetTypes.GUID, Value: id}
    ]));
  }

  public startLoadMapPreview(datasetId: string): void {
    super.Mutate(s => s.loadingMapPreview, () => datasetId);
  }

  public finishLoadMapPreview(): void {
    super.Mutate(s => s.loadingMapPreview, () => null);
  }

  public downloadCsv(mongoFileName: string | string[]): void {
    super.SetLoadState();
    if (Array.isArray(mongoFileName)) {
      this.DispatchBackend(new DownloadCsvZip([
        {Name: 'mongoFileIds', Type: NetTypes.STRING + '[]' , Value: mongoFileName}
      ]));
    } else {
      this.DispatchBackend(new DownloadCsv([
        {Name: 'mongoFileId', Type: NetTypes.STRING , Value: mongoFileName}
      ]));
    }
  }

  public downloadLog(mongoFileName: string): void {
    super.SetLoadState();
    this.DispatchBackend(new DownloadLog([
      {Name: 'mongoFileId', Type: NetTypes.STRING, Value: mongoFileName}
    ]));
  }
}

import ILocationModel = Data.DocuContext.Location.ILocationModel;
import IFleetAction = Data.DocuContext.FleetManagement.IFleetAction;
import IFieldAction = Data.DocuContext.FleetManagement.IFieldAction;
import {IAction, IApDropApplicationParamsInterface, IApInvokerArguments} from '../../interfaces';

export enum ActionActions {
  ACTIONS_APPLICATION_DROP = '[actions] application drop',
  ACTIONS_APPLICATION_ADD = '[actions] application add',

  FIELD_ACTIONS_LOAD = '[fieldactions] load',
  FIELD_ACTIONS_LOAD_SUCCESS = '[fieldactions] load success',
  FIELD_ACTIONS_LOAD_FAIL = '[fieldactions] load fail',

  FLEET_ACTIONS_LOAD = '[fleetactions] load',
  FLEET_ACTIONS_LOAD_SUCCESS = '[fleetactions] load success',
  FLEET_ACTIONS_LOAD_FAIL = '[fleetactions] load fail',

  FLEET_ACTIONS_LOAD_BY_FIELD = '[fleetactions] load by field',
  FLEET_ACTIONS_LOAD_BY_FIELD_SUCCESS = '[fleetactions] load success by field success',
  FLEET_ACTIONS_LOAD_BY_FIELD_FAIL = '[fleetactions] load fail by field fail',

  LOCATIONS_LOAD = '[locations] load',
  LOCATIONS_LOAD_SUCCESS = '[locations] load success',
  LOCATIONS_SELECT = '[locations] select',
}

export class ActionsApplicationDrop implements IAction {
  readonly type = ActionActions.ACTIONS_APPLICATION_DROP;

  constructor(public payload: IApDropApplicationParamsInterface) {
  }
}

export class ActionsApplicationAdd implements IAction {
  readonly type = ActionActions.ACTIONS_APPLICATION_ADD;

  constructor(public payload: IApDropApplicationParamsInterface) {
  }
}

export class FieldActionsLoad implements IAction {
  readonly type = ActionActions.FIELD_ACTIONS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FieldActionLoadSuccess implements IAction {
  readonly type = ActionActions.FIELD_ACTIONS_LOAD_SUCCESS;

  constructor(public payload: IFieldAction[]) {
  }
}

export class FieldActionLoadFail implements IAction {
  readonly type = ActionActions.FIELD_ACTIONS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class FleetActionsLoad implements IAction {
  readonly type = ActionActions.FLEET_ACTIONS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FleetActionLoadSuccess implements IAction {
  readonly type = ActionActions.FLEET_ACTIONS_LOAD_SUCCESS;

  constructor(public payload: IFleetAction[]) {
  }
}

export class FleetActionLoadFail implements IAction {
  readonly type = ActionActions.FLEET_ACTIONS_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class FleetActionsUpdate implements IAction {
  readonly type = ActionActions.FLEET_ACTIONS_LOAD_BY_FIELD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class FleetActionsUpdateSuccess implements IAction {
  readonly type = ActionActions.FLEET_ACTIONS_LOAD_BY_FIELD_SUCCESS;

  constructor(public payload: IFleetAction) {
  }
}

export class FleetActionsUpdateFail implements IAction {
  readonly type = ActionActions.FLEET_ACTIONS_LOAD_BY_FIELD_FAIL;

  constructor(public payload: string) {
  }
}

export class LocationsLoad implements IAction {
  readonly type = ActionActions.LOCATIONS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LocationsLoadSuccess implements IAction {
  readonly type = ActionActions.LOCATIONS_LOAD_SUCCESS;

  constructor(public payload: ILocationModel[]) {
  }
}

export class LocationsSelect implements IAction {
  readonly type = ActionActions.LOCATIONS_SELECT;

  constructor(public payload: number[]) {
  }
}

import {Pipe, PipeTransform} from '@angular/core';

export enum AreaUnit {
  SQUARE_METER,
  HEKTAR,
}

@Pipe({
  name: 'areaUnitConverter'
})
export class AreaUnitConverterPipe implements PipeTransform {

  transform(value: any, args?: AreaUnit[]): any {
    switch (args[0]) {
      case AreaUnit.SQUARE_METER:
        switch (args[1]) {
          case AreaUnit.HEKTAR:
            return value / 10000;
          case AreaUnit.SQUARE_METER:
            return value;
        }
        break;
      case AreaUnit.HEKTAR:
        switch (args[1]) {
          case AreaUnit.HEKTAR:
            return value;
          case AreaUnit.SQUARE_METER:
            return value * 10000;
        }
        break;
    }
    return 0;
  }
}

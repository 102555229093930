import {IStateStore}         from '../../ap-interface';
import {Store}               from '../index';
import {Injectable}          from '@angular/core';
import {
  ApCustomTypes,
  InvitationsConfirmed,
  InvitationsConfirmedCreateNewUser,
  InvitationsConfirmedSuccess,
  InvitationsConfirmedWithUser,
  InvitationsCreate,
  InvitationsDelete,
  InvitationsLoad,
  InvitationsLoadSuccess,
  InvitationsUpdate,
  NetTypes
}                            from 'invoker-transport';
import {
  InvitationsCheckByCode,
  InvitationsCheckByCodeResult,
  InvitationsLoadById,
  InvitationsLoadByIdResult,
  InvitationsResend,
  InvitationsResendFail,
  InvitationsResendSuccess
}                            from '../../../../projects/invoker-transport/src/lib/actions/login';
import {IApInvokerArguments} from '../../../../projects/invoker-transport/src/lib/interfaces';
import IInvitations = Data.Authentication.IInvitations;
import IResultMessage = Invoker.IResultMessage;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';

interface IInvitationStore extends IStateStore<IInvitations> {
  isInvitationResend: boolean | undefined;
  isSuccessful: boolean;
  emailSend: boolean;
  message: string;
  createNewUser: boolean;
}

@Injectable({providedIn: 'root'})
export class InvitationStore extends Store<IInvitationStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      isSuccessful: false,
      message: '',
      emailSend: false,
      createNewUser: false,
      isInvitationResend: undefined
    });
    backend.registerObservable(InvitationsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(InvitationsConfirmedCreateNewUser).subscribe(d => {
      super.Mutate(s => s.createNewUser, () => d.Data);
    });
    backend.registerObservable(InvitationsConfirmedSuccess).subscribe(d => {
      super.Mutate(s => s.isSuccessful, () => d.Data);
    });
    backend.registerObservable(InvitationsResendSuccess).subscribe(_ => {
      super.Mutate(s => s.isInvitationResend, () => true);
      super.Mutate(s => s.isInvitationResend, () => undefined);
    });
    backend.registerObservable(InvitationsResendFail).subscribe(_ => {
      super.Mutate(s => s.isInvitationResend, () => false);
      super.Mutate(s => s.isInvitationResend, () => undefined);
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public getInvitation(): IInvitations[] {
    return super.Listen(s => s.data).getValue();
  }

  public loadInvitations(): void {
    this.DispatchBackend(new InvitationsLoad([]));
  }

  public async hasInvitation(code: number | string): Promise<boolean> {
    return new Promise((res, rej) => {
      const params = [] as IApInvokerArguments[];
      if (typeof code === typeof '' && !isNaN(parseInt(code as string, 10))) {
        params.Add({Name: 'code', Type: NetTypes.INT, Value: parseInt(code as string, 10)});
      } else {
        params.Add({Name: 'code', Type: NetTypes.INT, Value: code});
      }
      this.DispatchBackend(new InvitationsCheckByCode(params)).watchStream({
        action: InvitationsCheckByCodeResult,
        todo: (exists: IResultMessage) => {
          if (exists.Error) {
            rej(exists.Error);
            return;
          }
          res(exists.Data);
        },
      });
    });
  }

  public async fetchInvitationById(id: number): Promise<IInvitations> {
    return new Promise((res, rej) => {
      this.DispatchBackend(new InvitationsLoadById([
        {Name: 'id', Type: NetTypes.INT, Value: id},
      ])).watchStream({
        action: InvitationsLoadByIdResult,
        todo: (msg: IResultMessage) => {
          if (msg.Error) {
            rej(msg.Error);
            return;
          }
          res(msg.Data.FirstOrDefault());
        }
      });
    });
  }

  public create(invitation: any): void {
    this.DispatchBackend(new InvitationsCreate([
      {Name: 'invitation', Type: NetTypes.OBJECT, Value: invitation},
    ]));
  }

  public update(invitation: any): void {
    this.DispatchBackend(new InvitationsUpdate([
      {Name: 'invitation', Type: NetTypes.OBJECT, Value: invitation},
    ]));
  }

  public confirmed(id: number, activationCode: number): void {
    this.DispatchBackend(new InvitationsConfirmed([
      {Name: 'id', Type: NetTypes.INT, Value: id},
      {Name: 'activationCode', Type: NetTypes.INT, Value: activationCode}
    ]));
  }

  public confirmedWithUser(id: number, activationCode: number, user: number): void {
    this.DispatchBackend(new InvitationsConfirmedWithUser([
      {Name: 'id', Type: NetTypes.INT, Value: id},
      {Name: 'activationCode', Type: NetTypes.INT, Value: activationCode},
      {Name: 'newUser', Type: NetTypes.INT, Value: user}
    ]));
  }

  public delete(invitations: IInvitations): void {
    this.DispatchBackend(new InvitationsDelete([
      {Name: 'invitations', Type: ApCustomTypes.Data_Authentication_Invitations, Value: invitations}
    ]));
  }

  public resend(email: string): void {
    this.DispatchBackend(new InvitationsResend([
      {Name: 'email', Type: NetTypes.STRING, Value: email}
    ]));
  }
}

import {Injectable}                                          from '@angular/core';
import {Store}                                               from '../index';
import {CountryLoad, CountryLoadSuccess} from 'invoker-transport';
import {IStateStore}                                         from '../../ap-interface';
import ICountry = Data.Common.ICountry;
import {SafeBehaviorSubject}                                 from 'ts-tooling';
import {ApSignalrService}                                    from '../../ap-core/services/ap-signalr.service';

@Injectable({providedIn: 'root'})
export class CountryStore extends Store<IStateStore<ICountry>> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(CountryLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  /**
   * Triggers load of all available countries
   */
  public loadAllCountries(): void {
    this.DispatchBackend(new CountryLoad([]));
  }

  /**
   * Gets all available countries as observable
   */
  get Countries$(): SafeBehaviorSubject<ICountry[]> {
    return super.Listen(s => s.data);
  }

  /**
   * Gets all available countries as array
   */
  get Countries(): ICountry[] {
    return this.Countries$.getValue();
  }
}

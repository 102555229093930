import {FormGroup, ValidatorFn} from '@angular/forms';

/**
 * Form validator: Validates the range of 2 dates (from/to).
 */
export class ApDateRangeValidator {
  static ValidateDateRange(dateFromControlName: string, dateToControlName: string): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: boolean } | null => {
      if (!formGroup) {
        return null;
      }

      const dateFromControl = formGroup.get(dateFromControlName);
      const dateToControl = formGroup.get(dateToControlName);
      if (!dateFromControl || !dateToControl) {
        return null;
      }

      const dateFrom = dateFromControl.value as Date;
      const dateTo = dateToControl.value as Date;
      if (dateFrom == null || dateTo == null) {
        return null;
      }

      if (dateFrom > dateTo) {
        return {ValidateDateRange: false};
      }

      return null;
    };
  }
}

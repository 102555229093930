import {AfterViewInit, Component, Renderer2, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup}        from '@angular/forms';
import {PanelBarComponent}                              from '@progress/kendo-angular-layout';
import {fromEvent}                                      from 'rxjs';

@Component({
  selector: 'ap-n-fertilization-burger-menu',
  templateUrl: './n-fertilization-burger-menu.component.html',
})
export class NFertilizationBurgerMenuComponent implements AfterViewInit {
  static type = 'NFertilizationBurgerMenuComponent';

  @ViewChild(PanelBarComponent, {static: true}) panelBar: PanelBarComponent;

  logFileForm: FormGroup = new FormGroup({
    area: new FormControl(),
    enabled: new FormControl(false),
    point: new FormControl(),
    pointEnabled: new FormControl(false),
  });

  fieldForm: FormGroup = new FormGroup({
    area: new FormControl(),
    enabled: new FormControl(false),
    point: new FormControl(),
    pointEnabled: new FormControl(false),
  });

  sumForm: FormGroup = new FormGroup({
    enabled: new FormControl(false),
  });

  draggedIndex: number;

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.panelBar.contentChildItems.forEach((item, i) => {
      const header = item.header.nativeElement;
      this.renderer.setAttribute(header, 'draggable', 'true');
      const drag = fromEvent<DragEvent>(header, 'dragstart');
      const over = fromEvent<DragEvent>(header, 'dragover');
      const drop = fromEvent<DragEvent>(header, 'drop');

      drag.subscribe(() => {
        this.draggedIndex = i;
      });

      over.subscribe((e) => {
        if (this.draggedIndex !== undefined) {
          e.preventDefault();
        }
      });

      drop.subscribe(() => {
        if (i !== this.draggedIndex) {
        }
      });
    });
  }

  getFormControl(control, name): FormControl {
    return control.get(name);
  }

  remove(control: AbstractControl, name): void {
    if (control.value === name) {
      control.setValue(null);
    }
  }
}

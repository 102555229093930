import {ApDynformsConfigFieldset} from './ap-dynforms-config-fieldset';
import {Observable}               from 'rxjs';
import {ApUtilService}            from '../../ap-utils/service/ap-util.service';

export class ApDynformsConfigTabs {
  title: string;
  selected: boolean;
  fieldSets: ApDynformsConfigFieldset[];
  disabled: Observable<boolean>;
  disabledFn: (x) => Observable<boolean>;

  constructor(options: {
    title?: string,
    selected?: boolean,
    fieldSets?: ApDynformsConfigFieldset[];
    disabled?: boolean | Observable<boolean> | ((x) => Observable<boolean>)
  } = {}) {
    this.title = options.title;
    this.selected = options.selected || false;
    this.fieldSets = options.fieldSets;
    if (options.disabled instanceof Function) {
      this.disabledFn = options.disabled;
    } else {
      this.disabled = ApUtilService.asObservable(options.disabled);
    }
  }
}

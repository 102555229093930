import {concat, difference, filter, find, isArray, isString, map}               from 'lodash';
import {
  ApGeoJsonPolygon
}                                                                               from '../interfaces/ap-geojson.interface';
import {LayerGroupNames, LayerNames}                                            from './layer-names';
import {ApBaseVectorLayer, LayerSyncStrategy}                                   from './ap-base-vector.layer';
import OlFeature                                                                from 'ol/Feature';
import OlFormatGeoJSON                                                          from 'ol/format/GeoJSON';
import {MAP_PROJECTION}                                                         from './ap-map.settings';
import {ApMapControlStream}                                                     from './ap-map-control.stream';
import {ApMapInstance}                                                          from '../ap-map.instance';
import {ApMenuUrls}                                                             from '../../ap-interface';
import MapBrowserEvent                                                          from 'ol/MapBrowserEvent';
import {MapStore}                                                               from '../../stores/map/map.store';
import {defaultStyle, defaultStyleLabelOnly, selectedStyle, withCropColorStyle} from './ap-fields.style';
import Style                                                                    from 'ol/style/Style';
import {FieldStore}                                                             from '../../stores/farm/field.store';
import {BehaviorSubject}                                                        from 'rxjs';
import IField = Data.FieldManagement.IField;
import {LayerZIndex}                                                            from './layer-z-index';

function _parseJson(data: string): ApGeoJsonPolygon {
  try {
    return JSON.parse(data);
  } catch (err) {
    return null;
  }
}

function _parseMainSecondCrop(field: IField): any {
  const mainCrop = _parseMainCrop(field);
  const secondCrop = _parseSecondCrop(field);
  return mainCrop.concat('\n', secondCrop);
}

function _parseMainCrop(item: IField): string {
  const DEFAULT_RESULT = ApMapInstance.translationService.FindTranslationForSelectedLanguage('Global__CropGroup_Nothing');
  if (!item || !item.FieldCrops || !isArray(item.FieldCrops) || item.FieldCrops.length < 1 || !item.FieldCrops[0].CroptypeId) {
    return '';
  }
  // there might be cases when cropType is null and following code causes a 'NullReferenceException'
  // even if cropType is checked for null before accessing cropType.Description
  try {
    const cropType = ApMapInstance.croptypeStore.getDefaultCroptype(item.FieldCrops[0].CroptypeId);
    return ApMapInstance.translationService.FindTranslationForSelectedLanguage('MainCrop') + ': ' + cropType ? cropType.Description : DEFAULT_RESULT;
  } catch (Exception) {
    return DEFAULT_RESULT;
  }
}

function _parseSecondCrop(item: IField): string {
  const DEFAULT_RESULT = ApMapInstance.translationService.FindTranslationForSelectedLanguage('Global__CropGroup_Nothing');
  if (!item || !item.FieldCrops || !isArray(item.FieldCrops) || item.FieldCrops.length < 1 || !item.FieldCrops[0].CroptypeSecondId) {
    return '';
  }
  // there might be cases when cropType is null and following code causes a 'NullReferenceException'
  // even if cropType is checked for null before accessing cropType.Description
  try {
    const cropType = ApMapInstance.croptypeStore.getDefaultCroptype(item.FieldCrops[0].CroptypeSecondId);
    return ApMapInstance.translationService.FindTranslationForSelectedLanguage('SecondCrop') + ': ' + cropType ? cropType.Description : DEFAULT_RESULT;
  } catch (Exception) {
    return DEFAULT_RESULT;
  }
}

function _parseColor(field: IField): string {
  if (!field || !field.FieldCrops || !isArray(field.FieldCrops) || field.FieldCrops.length < 1 || !field.FieldCrops[0].CroptypeId) {
    return ApMapInstance.cropGroupsStore?.getNoCropColor();
  }
  const crop = ApMapInstance.croptypeStore.getDefaultCroptype(field.FieldCrops[0].CroptypeId);
  if (crop) {
    return crop.Color;
  }
  return ApMapInstance.cropGroupsStore?.getNoCropColor();
}

class ApBaseFieldsLayer extends ApBaseVectorLayer {
  static readonly projection: string = 'EPSG:4326';

  constructor(layerName: string, layerGroupName: string, mapStore: MapStore, fieldStore$: BehaviorSubject<FieldStore>, data: IField[] = [], style: (f: OlFeature) => Style | Style[], declutter: boolean) {
    super(layerName, layerGroupName, mapStore, declutter);
    fieldStore$.subscribe(fs => this.replaceSource(data, fs, style));
  }

  replaceSource(data: IField[], fieldStore: FieldStore, style: (f: OlFeature) => Style | Style[]): OlFeature[] {
    const features = this.read(data, fieldStore);
    this.readFeatures(features, style);
    return features;
  }

  read(data: IField[], fieldStore: FieldStore): OlFeature[] {
    const withGeometry = filter(data, f => isString(fieldStore.getCurrentFieldGeom(f)?.Geom));
    return map(withGeometry, f => {
      const fieldGeom = fieldStore.getCurrentFieldGeom(f);
      const geom = new OlFormatGeoJSON({
        dataProjection: ApFieldsLayer.projection,
        featureProjection: MAP_PROJECTION
      });
      const feat = geom.readFeature({
        type: 'Feature',
        geometry: _parseJson(fieldGeom.Geom.toString()),
        properties: {
          label: this.getFieldExpressionByField(f, fieldStore),
          layerName: LayerNames.FIELDS,
          color: _parseColor(f)
        }
      });
      feat.setId(fieldGeom?.Id.toString());
      return feat;
    });
  }
}

export class ApFieldsCropLayer extends ApBaseFieldsLayer {
  static readonly projection: string = 'EPSG:4326';

  static readonly defaultStyle = function (f): Style {
    return withCropColorStyle(f);
  };

  constructor(mapStore: MapStore, fieldStore$: BehaviorSubject<FieldStore>, data: IField[] = []) {
    super(LayerNames.FIELDS_CROP, LayerGroupNames.BASIC, mapStore, fieldStore$, data, ApFieldsCropLayer.defaultStyle, false);
    this.FixedZIndex = LayerZIndex.CROPTYPES;
    mapStore.DrawCropColor$.subscribe(draw => {
      this.Visibility = draw;
      if (this.mapStore.Layers) {
        this.mapStore.Layers.OnLayerChange.emit();
      }
    });
  }

  public get Visibility(): boolean {
    return super.Visibility;
  }

  public set Visibility(value: boolean) {
    if (this.mapStore.Legends) {
      this.mapStore.Legends.refreshLegend(this.mapStore.Legends.CropTypeLegend, value && this.mapStore.Legends.CropTypeLegendHasElements);
    }
    super.Visibility = value;
  }
}

export class ApFieldsDescriptionLayer extends ApBaseFieldsLayer {
  static readonly projection: string = 'EPSG:4326';

  constructor(mapStore: MapStore, fieldStore$: BehaviorSubject<FieldStore>, data: IField[] = []) {
    super(LayerNames.FIELDS_DESCRIPTION, LayerGroupNames.BASIC, mapStore, fieldStore$, data, ApFieldsDescriptionLayer.defaultStyle, true);
    this.FixedZIndex = LayerZIndex.FIELDS_DESCRIPTION;
  }

  static readonly defaultStyle = function (f): Style {
    return defaultStyleLabelOnly(f);
  };

  /**
   * Set layer visibility
   */
  public set Visibility(value: boolean) {
    // Never hide fields layer.
    // But in case it got hidden we show it again together with description layer
    if (value) {
      this.mapStore.Layers.FieldsLayer.Visibility = value;
    }
    super.Visibility = value;
  }

  /**
   * Gets layer visibility
   */
  public get Visibility(): boolean {
    return super.Visibility;
  }

  public SyncFeatures(features: OlFeature[], strategy = LayerSyncStrategy.FORCE): void {
    // Clearing the features style, so it will be taken from Description Layer itself
    features.forEach(f => f.setStyle(null));
    super.SyncFeatures(features, strategy);
  }
}

/**
 * the Fields Layer represent the Fields of a Farm
 */
export class ApFieldsLayer extends ApBaseFieldsLayer {
  /**
   * the Projection of the Fields Layer Data comes from the Backend
   */
  static readonly projection: string = 'EPSG:4326';
  static drawSelectedStyle = true;

  /**
   * create a new Layer of Fields from a List of Fields
   */
  constructor(mapStore: MapStore, fieldStore$: BehaviorSubject<FieldStore>, data: IField[] = []) {
    super(LayerNames.FIELDS, undefined, mapStore, fieldStore$, data, ApFieldsLayer.defaultStyle, false);
    this.FixedZIndex = LayerZIndex.FIELDS;
    mapStore.DrawSelectedStyle$.subscribe(v => {
      ApFieldsLayer.drawSelectedStyle = v;
      this.redraw();
    });
    ApMapControlStream.listenClick$.subscribe(event => this._selectFields(event));
  }

  /**
   * the Default Style of a Field Feature in the Map
   */
  static readonly defaultStyle = function (f): Style | Style[] {
    return defaultStyle(f);
  };

  /**
   * the Style of a selected Field Feature in the Map
   */
  static readonly selectedStyle = function (f): Style | Style[] {
    return ApFieldsLayer.drawSelectedStyle ?
      selectedStyle(f) :
      ApFieldsLayer.defaultStyle(f);
  };

  /**
   * read the Geometry Data from the List of Fields into Openlayers Layer
   */
  public ReadFeature(data: IField[], fieldStore: FieldStore): OlFeature[] {
    return super.replaceSource(data, fieldStore, ApFieldsLayer.defaultStyle);
  }

  public replaceSource(data: IField[], fieldStore: FieldStore): OlFeature[] {
    if (this.mapStore.Layers) {
      this.mapStore.Layers.FieldsCropLayer.replaceSource(data, fieldStore, ApFieldsCropLayer.defaultStyle);
      this.mapStore.Layers.FieldDescriptionLayer.replaceSource(data, fieldStore, ApFieldsDescriptionLayer.defaultStyle);
    }
    return super.replaceSource(data, fieldStore, ApFieldsLayer.defaultStyle);
  }

  public SyncFeatures(features: OlFeature[], strategy = LayerSyncStrategy.FORCE): void {
    if (this.mapStore.Layers) {
      this.mapStore.Layers.FieldsCropLayer.SyncFeatures(features, strategy);
      this.mapStore.Layers.FieldDescriptionLayer.SyncFeatures(features, strategy);
    }
    super.SyncFeatures(features, strategy);
  }

  /**
   * select a Field by Id from Fields Layer
   */
  selectField(id: string): void {
    this.selectFeature(id, ApFieldsLayer.selectedStyle);
  }

  /**
   * deselect a Field in Fields Layer
   */
  deselectField(id: string): void {
    this.deselectFeature(id);
  }

  /**
   * select Fields in Map and Grid
   */
  private _selectFields(event: MapBrowserEvent): void {
    if (ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.FIELD_MANAGEMENT_OVERVIEW &&
      ApMapInstance.routerStore.CurrentUrl !== ApMenuUrls.NUTRIENTS_OVERVIEW) {
      return;
    }
    const myFeatures = event.map.getFeaturesAtPixel(event.pixel);
    const layerFeatures = filter(myFeatures, f => f.get('layerName') === LayerNames.FIELDS);
    const layerFeatureIds = map(layerFeatures, f => f.getId());
    if (layerFeatureIds.length > 0) {
      const currentSelected = map(ApMapInstance.fieldStore.getSelectedFields(), f => f.Id);
      const notSelectedBefore = [];
      for (const id of layerFeatureIds) {
        const isInSelection = !!find(currentSelected, c => c === id);
        if (!isInSelection) {
          notSelectedBefore.push(id);
        }
      }
      const notSelectedNow = difference(currentSelected, layerFeatureIds);
      const newSelection = concat(notSelectedBefore, notSelectedNow);
      ApMapInstance.fieldStore.changeSelectedField(newSelection);
    }
  }
}

import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IntlService}                                from '@progress/kendo-angular-intl';
import {TranslationStore}                           from '../../stores/translation/translation.store';

@Component({
  selector: 'ap-responsive-text',
  templateUrl: 'ap-responsive-text.component.html',
  styles: [':host {width:100%}']
})
export class ApResponsiveTextComponent implements OnChanges {
  @Input() key: string;
  @Input() text: string;
  @Input() tooltipOverride: string;
  @Input() format = '';
  @Input() extraLines: string[] = [];
  @Input() infoText = '';
  /**
   * Creates 2 lines out of the given text to still have ellipsis (...) functionality
   */
  @Input() breakLine = false;
  /**
   * If true, the given text is taken as pre-formatted text including line breaks
   */
  @Input() multiline = false;
  displayText: string[] = [];

  constructor(private intl: IntlService,
              private translationStore: TranslationStore) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text || changes.key) {
      if (!!this.key) {
        this.displayText = [this.translationStore.FindTranslationForSelectedLanguage(this.key)];
      } else if (!!this.format) {
        this.displayText = [this.intl.toString(this.text, this.format)];
      } else {
        this.displayText = [this.text];
      }
      if (this.extraLines && this.extraLines.length > 0) {
        for (let i = 0; i < this.extraLines.length; i++) {
          this.extraLines[i] = this.translationStore.FindTranslationForSelectedLanguage(this.extraLines[i]);
        }
      }

      if (this.breakLine) {
        const half = Math.ceil(this.displayText[0].length / 2);
        const token = this.displayText[0].split(' ');
        let idx = 0;
        let length = 0;
        while (Math.abs(half - length) > Math.abs(half - length + token[idx].length)) {
          length += token[idx].length;
          idx++;
        }
        this.displayText = [
          token.filter((v, i) => i <= idx).join(' '),
          token.filter((v, i) => i > idx).join(' '),
        ];
      }
    }
  }
}

import {ApMapInstance} from './ap-map.instance';
import Interaction     from 'ol/interaction/Interaction';
import Select          from 'ol/interaction/Select';
import {environment}   from '../../environments/environment';

if (!environment.Production) {
  if (!window['mapFunctions']) {
    window['mapFunctions'] = {};
  }
  setTimeout(() => {
    window['mapFunctions'].getDrawInteraction = () => MapInteraction.FindByName(MapInteractions.DRAW);
  }, 1);
}

export enum MapInteractions {
  DRAW = 'draw',
  SELECT = 'select',
  MODIFY = 'modify',
  SNAP = 'snap',
}

export enum Keys {
  NAME = 'name',
  LAYER_NAME = 'layerName',
  REMOVED_FEATURE = 'removeFeat',
}

export class MapInteraction {
  static get Interactions(): Interaction[] {
    if (!ApMapInstance.mapRef) {
      return null;
    }
    return ApMapInstance.mapRef.getInteractions().getArray();
  }

  static Add(action: Interaction, append = false): Interaction {
    if (!ApMapInstance.mapRef) {
      return undefined;
    }
    if (append === true) {
      ApMapInstance.mapRef.removeInteraction(action);
    }
    ApMapInstance.mapRef.addInteraction(action);
    return action;
  }

  static FindByName(name: string): Interaction {
    return MapInteraction.Interactions ? MapInteraction.Interactions.Find(e => e?.get(Keys.NAME) === name) : null;
  }

  static Remove(action: MapInteractions): boolean {
    let actionToRemove = MapInteraction.FindByName(action);
    let removed = false;
    while (actionToRemove && ApMapInstance.mapRef) {
      ApMapInstance.mapRef.removeInteraction(actionToRemove);
      actionToRemove = MapInteraction.FindByName(action);
      removed = true;
    }
    return removed;
  }

  static ClearSelection(): void {
    const selectionInteraction = MapInteraction.FindByName(MapInteractions.SELECT) as Select;
    if (selectionInteraction !== null) {
      selectionInteraction.getFeatures().clear();
    }
  }

  static ClearInteractions(): void {
    for (const action of [MapInteractions.DRAW, MapInteractions.SELECT, MapInteractions.MODIFY]) {
      MapInteraction.Remove(action);
    }
  }
}

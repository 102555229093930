import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  TranslationStore
}                                                                                 from '../../stores/translation/translation.store';
import {FormControl}                                                              from '@angular/forms';
import {
  PasswordStrengthService
}                                                                                 from '../services/password-strength.service';

@Component({
  selector: 'pwd-strength',
  template: `
    <div class="pwd-strength-wrapper">
      <div class="pwd-strength-background">
        <input type="text"
               [formControl]="control"
               [id]="id"
               [style]="{display: 'none'}"/>
      </div>
      <div class="pwd-strength-marker" [style]="markerStyle"></div>
      <div class="ap-error" *ngIf="control?.errors && control?.errors[lowSecurityPasswordError]">
        <ap-responsive-text [key]="lowSecurityPasswordError"></ap-responsive-text>
      </div>
    </div>
  `,
  styles: [
    `
      .pwd-strength-wrapper {
        width: 100%;
        height: 27px;
        padding-top: 5px;
      }

      .pwd-strength-background {
        background: linear-gradient(90deg, rgba(238, 0, 0, 1) 0%, rgba(188, 60, 0, 1) 25%, rgba(207, 114, 45, 1) 50%, rgba(229, 196, 41, 1) 75%, rgba(58, 155, 76, 1) 100%);
        height: 22px;
      }

      .pwd-strength-marker {
        height: 22px;
        background: black;
        width: 1px;
        margin-left: 0;
        border: 1px solid black;
        margin-top: -23px;
      }
    `
  ]
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  constructor(private translationStore: TranslationStore,
              private passwordStrengthService: PasswordStrengthService) {
  }

  private static labelTranslationKey = 'Control__Password_Strength_Label';
  private static tooltipTranslationKey = 'Admin_Pages_Password_Minimum_Requirement_Tip';
  private static stateVeryWeakTranslationKey = 'Admin_Pages_Password_State_Very_Weak';
  private static stateWeakTranslationKey = 'Admin_Pages_Password_State_Weak';
  private static stateGoodTranslationKey = 'Admin_Pages_Password_State_Good';
  private static stateStrongTranslationKey = 'Admin_Pages_Password_State_Strong';
  private static stateVeryStrongTranslationKey = 'Admin_Pages_Password_State_Very_Strong';

  @Input()
  public control: FormControl;
  @Input()
  public value = '';
  @Input()
  public id = '';

  @Output()
  public strengthChanged = new EventEmitter<number>();
  @Output()
  public labelChanged = new EventEmitter<string>(true);

  public lowSecurityPasswordError = 'PwRecover_ValidationInfoLowSecurityPassword';
  public markerStyle = {
    margin: `-23px 0px 0px 0%`,
  };

  private labelSuffix = '';
  private tooltip = '';
  private tooltipText = '';
  private statesTextVeryWeak = '';
  private statesTextWeak = '';
  private statesTextGood = '';
  private statesTextStrong = '';
  private statesTextVeryStrong = '';

  ngOnInit(): void {
    this.translationStore.Listen(s => s.loaded).subscribe(_ => {
      if (_ === false) {
        return;
      }
      this.labelSuffix = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.labelTranslationKey);
      this.tooltip = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.tooltipTranslationKey);
      this.tooltipText = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.labelTranslationKey);
      this.statesTextVeryWeak = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.stateVeryWeakTranslationKey);
      this.statesTextWeak = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.stateWeakTranslationKey);
      this.statesTextGood = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.stateGoodTranslationKey);
      this.statesTextStrong = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.stateStrongTranslationKey);
      this.statesTextVeryStrong = this.translationStore.FindTranslationForSelectedLanguage(PasswordStrengthComponent.stateVeryStrongTranslationKey);

      this.labelChanged.emit(`${this.labelSuffix} ${this.statesTextVeryWeak}`);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.control) {
      const strength = this.passwordStrengthService.percent(changes.value.currentValue);
      this.markerStyle = {margin: `-23px 0px 0px ${strength}%`};
      this.control.setValue(strength);
      if (strength < 1) {
        this.labelChanged.emit(`${this.labelSuffix} ${this.statesTextVeryWeak}`);
        this.control.setErrors({[this.lowSecurityPasswordError]: true});
      } else if (strength <= 19) {
        this.labelChanged.emit(`${this.labelSuffix} ${this.statesTextVeryWeak}`);
        this.control.setErrors({[this.lowSecurityPasswordError]: true});
      } else if (strength <= 49) {
        this.labelChanged.emit(`${this.labelSuffix} ${this.statesTextWeak}`);
        this.control.setErrors({[this.lowSecurityPasswordError]: true});
      } else if (strength <= 59) {
        this.labelChanged.emit(`${this.labelSuffix} ${this.statesTextGood}`);
        this.control.setErrors(null);
      } else if (strength <= 79) {
        this.labelChanged.emit(`${this.labelSuffix} ${this.statesTextStrong}`);
        this.control.setErrors(null);
      } else {
        this.labelChanged.emit(`${this.labelSuffix} ${this.statesTextVeryStrong}`);
        this.control.setErrors(null);
      }
    }
  }
}

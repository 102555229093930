import {Store}                                                                               from '../index';
import {EventEmitter, Injectable}                                                            from '@angular/core';
import {ActivatedRouteSnapshot, Data, Params, Router, RouterStateSnapshot, RoutesRecognized} from '@angular/router';
import {
  Trace
}                                                                                            from '../../debug-utils/ApplicationTracer';
import {ApMenuUrls}                                                                          from '../../ap-interface';
import {
  APP_CONFIGURATION
}                                                                                            from '../../ap-core/config';
import {
  RemoveUrlParamsService
}                                                                                            from '../../services/router/remove-url-params.service';
import {
  UrlEncoderService
}                                                                                            from '../../services/router/url-encoder.service';
import {ClientCache}                                                                         from '../local-cache';
import {
  FormStore
}                                                   from '../layout/form.store';
import {Subscription}                               from 'rxjs';
import {
  ApDeveloperGuard
}                                                   from '../../guards/ap-developer-guard';
import {MapStore}                                   from '../map/map.store';
import {SafeBehaviorSubject}                        from 'ts-tooling';
import {FieldStore}                                 from '../farm/field.store';
import {filter, skip}                               from 'rxjs/operators';
import {IModalDialogData, ModalDialogButtonDisable} from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {ModalDialogStore}                           from '../dialog/modal.dialog.store';
import {ApTranslationService}                       from '../../ap-utils/service/ap-translation.service';
import {ApSignalrService}                           from '../../ap-core/services/ap-signalr.service';

export interface IRouterStateUrl {
  url: string;
  changeMainModule: boolean;
  queryParams: Params;
  params: Params;
  data: Data;
}

@Injectable({providedIn: 'root'})
export class RouterStore extends Store<IRouterStateUrl> {
  private _navigationSubscription: Subscription;
  private _mapLoadCompleteSubscription: Subscription;
  private _layerMapControlItemsSubscription: Subscription;
  private _blockNavigation = false;
  private _blockMessage: string;

  navigateRequest = new EventEmitter<boolean>();

  constructor(public backend: ApSignalrService,
              private formStore: FormStore,
              private fieldStore: FieldStore,
              private removeUrlParams: RemoveUrlParamsService,
              private router: Router,
              private developerGuard: ApDeveloperGuard,
              private urlEncoder: UrlEncoderService,
              private mapStore: MapStore,
              private modalDialogStore: ModalDialogStore,
              private translationService: ApTranslationService) {
    super(backend, {
      data: [],
      url: '',
      changeMainModule: false,
      queryParams: null,
      params: null,
    });
    router.events.subscribe(e => {
      if (e instanceof RoutesRecognized) {
        this.serialize(e.state);
      }
    });
  }

  get CurrentUrl$(): SafeBehaviorSubject<string> {
    return super.Listen(s => s.url);
  }

  get CurrentUrl(): string {
    return this.CurrentUrl$.getValue();
  }

  get QueryParams$(): SafeBehaviorSubject<any> {
    return super.Listen(s => s.queryParams);
  }

  get QueryParams(): any {
    return this.QueryParams$.getValue();
  }

  @Trace()
  public async loadContactPage(): Promise<void> {
    await this.router.navigate([ApMenuUrls.CONTACT]);
  }

  @Trace()
  public async navigateToLogin(): Promise<void> {
    await this.router.navigate([ApMenuUrls.LOGIN]);
  }

  @Trace()
  public async navigateToPasswordRecovery(): Promise<void> {
    await this.router.navigate(([ApMenuUrls.LOGIN_PASSWORD_RECOVER]));
  }

  public blockNavigation(messageKey: string): void {
    this._blockMessage = messageKey;
    this._blockNavigation = true;
  }

  public releaseNavigation(): void {
    this._blockNavigation = false;
  }

  public tryNavigate(): boolean {
    if (this._blockNavigation) {
      this.modalDialogStore.setModalDialogData({
        formConfig: undefined,
        show: true,
        buttons: [
          {
            disable: ModalDialogButtonDisable.Never,
            key: 'Y',
            primary: true,
            text: 'Global__Ok'
          },
          {
            disable: ModalDialogButtonDisable.Never,
            key: 'N',
            primary: false,
            text: 'Global__Cancel'
          },
        ],
        title: this.translationService.translate('Global__Warning'),
        message: this.translationService.translate(this._blockMessage)
      } as IModalDialogData);
      const subscription = this.modalDialogStore.Listen(s => s.result).pipe(skip(1)).subscribe((result) => {
        this.navigateRequest.emit(result.key === 'Y');
        subscription.unsubscribe();
      });
      return true;
    }
    return false;
  }

  @Trace()
  public async navigate(url: string[], extras = {}): Promise<void> {
    if (this.formStore.tryCloseForm()) {
      this._navigationSubscription?.unsubscribe();
      this._navigationSubscription = this.formStore.formCloseOnRequest.subscribe(async (closed) => {
        if (closed) {
          await this.router.navigate(url, {queryParams: this.urlEncoder.encode(extras)});
        }
        this._navigationSubscription?.unsubscribe();
      });
    } else if (this.tryNavigate()) {
      this._navigationSubscription?.unsubscribe();
      this._navigationSubscription = this.navigateRequest.subscribe(async (navigate) => {
        if (navigate) {
          await this.router.navigate(url, {queryParams: this.urlEncoder.encode(extras)});
        }
        this._navigationSubscription?.unsubscribe();
      });
    } else {
      await this.router.navigate(url, {queryParams: this.urlEncoder.encode(extras)});
    }
  }

  private serialize(routerState: RouterStateSnapshot): void {
    const {url} = routerState;
    const queryParams = this.urlEncoder.decode(routerState.root.queryParams);

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const {params} = state;
    const {data} = state;

    if (data.login && data.login === true) {
      ClientCache.writeValue(APP_CONFIGURATION.StoreKeys.LastVisitUrl,
        this.removeUrlParams.removeUrlParams(url));
      ClientCache.writeValue(APP_CONFIGURATION.StoreKeys.LastVisitUrlParams, queryParams);
    }
    this.handleLayerGroups();

    super.Mutate(s => s, o => {
      const mainModuleBefore = o.url.Split('/')[0];
      const mainModuleAfter = url.Split('/')[0];
      o.changeMainModule = mainModuleBefore !== mainModuleAfter;
      if (o.changeMainModule) {
        this.fieldStore.changeSelectedField([]);
      }
      o.url = url;
      o.queryParams = queryParams;
      o.params = params;
      o.data = data;
      return o;
    });
  }

  private handleLayerGroups(): void {
    for (const layer of this.mapStore.Layers.AllLayers$.getValue()) {
      layer.Visibility = false;
    }
    this.mapStore.Layers.setActiveLayerGroups([]).then();
    if (!this._layerMapControlItemsSubscription) {
      this._layerMapControlItemsSubscription = this.mapStore.Layers.LayerMapControlItems$
        .pipe(filter(v => !!v && Object.keys(v).length > 0))
        .subscribe(() => {
          this.readLayerMapControlItems();
        });
    }
    this.readLayerMapControlItems();
  }

  private readLayerMapControlItems(): void {
    if (!this._mapLoadCompleteSubscription) {
      this._mapLoadCompleteSubscription = this.mapStore.mapInitialized.subscribe(() => {
        this.forceModulLayerState();
      });
    }
    this.forceModulLayerState();
  }

  private forceModulLayerState(): void {
    setTimeout(() => {
      let url = super.Listen(d => d.url).getValue();
      // do not take optional route parameters into consideration
      url = url?.split('?')[0];
      const modulLayers = this.mapStore.Layers.LayerMapControlItems$.getValue()[url];
      if (!modulLayers) {
        return;
      }
      // hide all Layers
      for (const layer of this.mapStore.Layers.AllLayers$.getValue()) {
        layer.Visibility = false;
      }
      // add needed groups to control
      this.mapStore.Layers.setActiveLayerGroups(modulLayers.groups).then();
      // show active Layers
      for (const layer of modulLayers.activeLayers) {
        if (!layer) {
          continue;
        }
        layer.Visibility = true;
      }
      for (const g of this.mapStore.Layers.AllLayerGroups$.getValue()) {
        let id = g.id;
        for (const l of this.mapStore.Layers.getLayersByGroup(g.name)) {

          if (l.FixedZIndex > 0) {
           l.Order = l.FixedZIndex;
          } else {
            l.Order = --id;
          }
        }
      }
    }, 0);
  }
}

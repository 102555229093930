import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
}                                                                                          from '@angular/core';
import {filter, map}                                                                       from 'rxjs/operators';
import {
  ParameterDefinitionStore
}                                                                                          from '../../stores/parameter/parameter.definition.store';
import {
  ParameterValuesStore
}                                                                                          from '../../stores/parameter/parameter.values.store';
import {
  IGridPagerData,
  IWizardChoiceData,
}                                                                                          from '../../ap-interface/interfaces';
import {
  ApDynGridColumnConfigBase
}                                                                                          from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPagerConfigBase
}                                                                                          from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {
  ApDynformsConfigFieldset
}                                                                                          from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynGridStatusColumnConfig
}                                                                                          from '../../ap-dyngrids/config/ap-dyn-grid-status-column-config';
import {
  ApDynGridPropertyColumnConfig
}                                                                                          from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {ApGridPagerType}                                                                   from '../../ap-interface';
import {ApPermissions}                                                                     from 'invoker-transport';
import {
  ApDynGridPagerConfig,
  IDynGridWizardChoiceData
}                                                                                          from '../../ap-dyngrids/config/ap-dyn-grid-pager-config';
import {
  GetPermissionPipe
}                                                                                          from '../../ap-permission/pipes/get-permission.pipe';
import {BehaviorSubject, combineLatest, Observable, Subscription}                          from 'rxjs';
import {
  TranslationStore
}                                                                                     from '../../stores/translation/translation.store';
import {ApGuidUtil, GetBooleanPipe, GetElementOxidCalculatePipe, GetRoundNumericPipe} from '../../ap-utils';
import {
  LanguageStore
}                                                                                     from '../../stores/translation/language.store';
import {
  SettingsStore
}                                                                                          from '../../stores/base-data/settings.store';
import {
  ApDynGridButtonColumnConfig
}                                                                                          from '../../ap-dyngrids/config/ap-dyn-grid-button-column-config';
import {
  ApDynComponentComponent
}                                                                                          from '../../ap-dyncomponent/ap-dyncomponent.component';
import {
  LoginStore
}                                                                                          from '../../stores/login/login.store';
import {
  ApDynGridImageColumnConfig
}                                                                                          from '../../ap-dyngrids/config/ap-dyn-grid-image-column-config';
import {
  IModalDialogData,
  IModalDialogResult,
  ModalDialogButtonDisable
}                                                                                          from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {
  ApDynformsConfigTextbox
}                                                                                          from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {
  ModalDialogStore
}                                                                                          from '../../stores/dialog/modal.dialog.store';
import {
  ApDynformsConfigComboBox
}                                                                                          from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigNumerictextbox
}                                                                                          from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApDynformsValidator
}                                                                                          from '../../ap-dynforms/ap-dynforms-validator';
import {Validators}                                                                        from '@angular/forms';
import {ObjectFactory}                                                                     from 'ts-tooling';
import {
  CampaignYearStore
}                                                                                          from '../../stores/login/campaignyear.store';
import {
  ApDynformsConfigDatepicker
}                                                                                          from '../../ap-dynforms/config/ap-dynforms-config-datepicker';
import * as moment                                                                         from 'moment';
import IParameterDefinition = Data.Parameter.IParameterDefinition;
import ILanguage = Data.Language.ILanguage;
import ISettings = Data.BaseData.ISettings;
import IGuid = System.IGuid;
import ParameterType = Data.Parameter.Enums.ParameterType;
import ParameterRefObjectType = Data.Parameter.Enums.ParameterRefObjectType;
import IParameterValue = Data.Parameter.IParameterValue;

interface IParameterItem {
  Id: IGuid;
  SourceItem: IParameterValue;
  Status: number;
  StatusClass: string;
  StatusDesc: string;
  ParaDef: IParameterDefinition;
  Name: string;
  Value: string;
  StandardIcon: string;
  InfoIcon: string;
  Mandatory: boolean;
  Info: string;
  ValidFrom: Date;
  ValidTo: Date;
  Image: string;
  ToolTip: string;
  Size: string;
  Unit: string;
}

export enum ApParameterGridPagerActions {
  DELETE = 0,
  ADD = 1
}

@Component({
  selector: 'ap-parameter-value',
  template: `
    <ap-dyncomponent [columns]="columns"
                     [items]="items | async"
                     [loading$]="parameterValuesLoading$"
                     [pager]="pager">
    </ap-dyncomponent>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ApParameterValueComponent implements AfterContentInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) dynComponent: ApDynComponentComponent;
  @Input() refId: IGuid;
  @Input() refObject: ParameterRefObjectType;
  columns: ApDynGridColumnConfigBase[] = [];
  items = new BehaviorSubject([]);
  pager: ApDynGridPagerConfigBase;
  parameterValuesLoading$ = combineLatest([
    this.parameterValuesStore.Listen(s => s.loading),
    this.parameterDefinitionStore.Listen(s => s.loading)
  ]).pipe(map(([value, definition]) => value || definition));

  private _subscriptions: Array<Subscription> = [];
  private _applyClicked = new EventEmitter<any>();
  private _onEdit = new EventEmitter<any>(true);
  private _settings: ISettings;
  private _editedParameterValue: any;
  private _createParameterList: { [id: string]: IParameterValue } = {};
  private _updateParameterList: { [id: string]: IParameterValue } = {};
  private _deleteParameterList: { [id: string]: IParameterValue } = {};

  constructor(private parameterDefinitionStore: ParameterDefinitionStore,
              private campaignYearStore: CampaignYearStore,
              private parameterValuesStore: ParameterValuesStore,
              private permissionPipe: GetPermissionPipe,
              private translationStore: TranslationStore,
              private roundNumericPipe: GetRoundNumericPipe,
              private elementOxideCalculate: GetElementOxidCalculatePipe,
              private booleanPipe: GetBooleanPipe,
              private languageStore: LanguageStore,
              private settingsStore: SettingsStore,
              private loginStore: LoginStore,
              private modalDialogStore: ModalDialogStore) {
    this._subscriptions.push(this._applyClicked.subscribe((e) => this._onApplyClicked(e)));
    this._subscriptions.push(this.modalDialogStore.Listen(s => s.result).subscribe(c => this.modalDialogResult(c)));
    this._subscriptions.push(this._onEdit.subscribe((event) => this._openEditDialog(event.ParaDef, event.SourceItem)));
  }

  public getElement(event: any): any {
    switch (event.ParaDef.type) {
      case 'text':
        return new ApDynformsConfigTextbox({
          key: 'Parameter',
          label: event.ParaDef.TranslationKey
        });
      case 'boolean':
        return new ApDynformsConfigComboBox({
          key: 'Parameter3',
          value: event.SourceItem.ValueBoolean === true ? 1 : -1,
          valueField: 'id',
          textField: 'label',
          label: event.ParaDef.TranslationKey,
          options: [
            {
              id: 1,
              label: this.translationStore.FindTranslationForSelectedLanguage('Global__Yes')
            },
            {
              id: -1,
              label: this.translationStore.FindTranslationForSelectedLanguage('Global__No')
            }
          ]
        });
      case 'number':
        return new ApDynformsConfigNumerictextbox({
          key: 'Parameter1',
          label: event.ParaDef.TranslationKey,
          value: event.SourceItem.ValueNumber
        });
      case 'list':
        return new ApDynformsConfigComboBox({
          key: 'Parameter2',
          value: event.SourceItem.ValueListKey,
          valueField: 'Id',
          textField: 'Name',
          label: event.ParaDef.TranslationKey,
          options: event.ParaDef.ListValues
        });
    }
  }

  ngAfterContentInit(): void {
    this.getDataFromStore();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }

  gridBuilder(): void {
    this.columns = [
      new ApDynGridStatusColumnConfig({
        field: 'Status', class: 'StatusClass', description: 'StatusDesc'
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'Name',
        title: 'Global__Name',
        width: 200,
        sortIndex: 0,
        sortDesc: false,
        filterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'Value',
        title: 'Global_Value',
        width: 200,
        filterable: true,
      }),
      new ApDynGridImageColumnConfig({
        title: 'Global__Info',
        imageUrl: 'Image',
        width: 40,
        tooltip: 'ToolTip',
        imageHeight: 'Size',
        imageWidth: 'Size'
      }),
      new ApDynGridButtonColumnConfig(
        {
          // disabled: (t) => !this._canTranslateCountryCodes.Contains(t.SourceItem.CountryCode),
          tooltip: 'Global__Edit',
          callback: this._onEdit,
          isStandardIcon: true,
          standardIcon: 'StandardIcon'
        }
      )
    ];

    this.permissionPipe.transform(ApPermissions.EDIT_CROPTYPES).subscribe(permission => {
      this.pager = new ApDynGridPagerConfig({
        listMassEditing: this.fillWizardChoiceData(),
        apply: this._applyClicked,
        hasPermission: permission,
        showWithoutSelection: true,
        preselect: false,
      });
    });
  }

  fillWizardChoiceData(): Observable<(IDynGridWizardChoiceData | IWizardChoiceData)[]> {
    return combineLatest([
      this.dynComponent.SelectedItems$,
      this.items,
      this.parameterDefinitionStore.Listen(s => s.data).asObservable()
    ]).pipe(
      map(([selectedItems, items, defs]) => {
        const result = [];
        if (selectedItems.length) {
          result.push({
            id: ApParameterGridPagerActions.DELETE,
            name: this.translationStore.FindTranslationForSelectedLanguage('Global__Delete'),
            type: ApGridPagerType.None,
            values: null,
            disabled: !this.dynComponent.getSelectedItems().Any
          } as IWizardChoiceData);
        }

        const itemDefinitions = items.map((item) => item.ParaDef.Id);
        defs.filter((def) => !itemDefinitions.includes(def.Id)).forEach((def) => {
          let config;
          switch (def.ParameterType) {
            case ParameterType.Text:
              config = new ApDynformsConfigTextbox({
                key: 'Value',
                label: this.translationStore.FindTranslationForSelectedLanguage(def.TranslationKey),
                value: def.StandardValue,
                validators: [
                  new ApDynformsValidator({validator: Validators.required, errorKey: ''}),
                ]
              });
              break;
            case ParameterType.Boolean:
              config = new ApDynformsConfigComboBox({
                key: 'Value',
                label: this.translationStore.FindTranslationForSelectedLanguage(def.TranslationKey),
                textField: 'label',
                valueField: 'id',
                options: [
                  {
                    id: 1,
                    label: this.translationStore.FindTranslationForSelectedLanguage('Global__Yes')
                  },
                  {
                    id: -1,
                    label: this.translationStore.FindTranslationForSelectedLanguage('Global__No')
                  }
                ],
                value: def.StandardValue,
                validators: [
                  new ApDynformsValidator({validator: Validators.required, errorKey: ''}),
                ]
              });
              break;
            case ParameterType.Number:
              config = new ApDynformsConfigNumerictextbox({
                key: 'Value',
                label: this.translationStore.FindTranslationForSelectedLanguage(def.TranslationKey),
                value: def.StandardValue.ToInteger(),
                validators: [
                  new ApDynformsValidator({validator: Validators.required, errorKey: ''}),
                ]
              });
              break;
            case ParameterType.List:
              config = new ApDynformsConfigComboBox({
                key: 'Value',
                label: this.translationStore.FindTranslationForSelectedLanguage(def.TranslationKey),
                options: def.ListValues.map((v) => ({id: v.Id, label: v.Name})),
                textField: 'label',
                valueField: 'id',
                value: +def.StandardValue,
                validators: [
                  new ApDynformsValidator({validator: Validators.required, errorKey: ''}),
                ]
              });
              break;
          }
          result.push({
            id: def.Id,
            name: this.translationStore.FindTranslationForSelectedLanguage(def.TranslationKey),
            type: ApGridPagerType.None,
            onSubmit: this._applyClicked,
            formConfig: [
              new ApDynformsConfigFieldset({
                key: this.translationStore.FindTranslationForSelectedLanguage(def.TranslationKey),
                config: [
                  config,
                  new ApDynformsConfigDatepicker({
                    key: 'Date',
                    label: 'Docu_Ini__ValidFrom',
                    value: new Date(`${this.campaignYearStore.getSelectedCampaignYear().Year}`),
                    minDate: new Date(`${this.campaignYearStore.getSelectedCampaignYear().Year}`),
                    bottomView: 'decade',
                    topView: 'decade',
                    format: 'YYYY'
                  })
                ]
              })
            ]
          } as IDynGridWizardChoiceData);
        });
        return result;
      })
    );
  }

  public Save(): void {
    Object.keys(this._createParameterList).forEach((key) => {
      this.parameterValuesStore.createParameterValue(this._createParameterList[key]);
    });
    this.parameterValuesStore.updateParameterValues(
      Object.keys(this._updateParameterList).map((key) => this._updateParameterList[key]));
    Object.keys(this._deleteParameterList).map((key) => this._deleteParameterList[key]).forEach((parameter) => {
      this.parameterValuesStore.deleteParameterValue(parameter);
    });
  }

  private modalDialogResult(result: IModalDialogResult): void {
    if (result.key === 'apply' && this._editedParameterValue) {
      this._update(this._editedParameterValue, result.formValues.Parameter, result.formValues.ValidFrom);
    }
  }

  private getDataFromStore(): void {
    this.parameterDefinitionStore.getParameterDefinitionByRefObjectType(this.refObject);
    if (this.refId !== undefined) {
      this.parameterValuesStore.loadParameterValue(this.refId);
    }
    this._subscriptions.push(this.settingsStore.FirstSetting$.subscribe(s => this._settings = s));
    this._subscriptions.push(
      combineLatest([
        this.parameterValuesStore.Listen(s => s.data),
        this.parameterDefinitionStore.Listen(s => s.data)
          .pipe(filter((definition) => definition !== undefined && definition.length !== 0)),
        this.languageStore.SelectedLanguage$
      ]).subscribe(([values, _, language]) => {
        this.items.next(values.map((v: IParameterValue) => this._createItem(v, language)));
        this.gridBuilder();
      })
    );
  }

  private _onApplyClicked(data: IGridPagerData): void {
    if (data.object.id === ApParameterGridPagerActions.DELETE) {
      this.dynComponent.getSelectedItems()
        .filter((row) => row['SourceItem'] !== undefined && !row['Mandatory'])
        .map((row) => row['SourceItem'])
        .forEach((item) => this._delete(item));
    } else if (this._deleteParameterList[data.object.id] !== undefined) {
      this._update(this._deleteParameterList[data.object.id], data.value);
    } else {
      this._create(data.object.id, data.form.Value, data.form.Date);
    }
  }

  private _booleanConverter(value: any): string {
    const retVal = this.booleanPipe.transform(value);
    if (retVal) {
      return this.translationStore.FindTranslationForSelectedLanguage('Global__Yes');
    }
    return this.translationStore.FindTranslationForSelectedLanguage('Global__No');
  }

  private _listConverter(id: number, definition: IParameterDefinition): string {
    const found = definition.ListValues.Find(_ => _.Id === id);
    if (!found) {
      return '';
    }
    return found.Name;
  }

  private _createTooltip(parameterDefinition: IParameterDefinition, parameterValue: IParameterValue, language: ILanguage): string {
    const description = this.translationStore.FindTranslationForSelectedLanguage(parameterDefinition.Description);
    const unit = parameterDefinition.Unit !== null ? parameterDefinition.Unit : '-';
    const validFrom = moment(parameterValue.ValidFrom).format(language.DateFormat);
    const validTo = moment(parameterValue.ValidTo).format(language.DateFormat);
    return `
      <table [style]="'width: 270px'">
        <tbody>
          <tr>
            <td [style]="'text-align: left'">
              <strong>${this.translationStore.FindTranslationForSelectedLanguage('Nutrients_Pages_Popups__Description')}</strong>
            </td>
            <td [style]="'width: 174px; text-align: left'">
              ${description}
            </td>
          </tr>
          <tr>
            <td [style]="'text-align: left'">
                <strong>${this.translationStore.FindTranslationForSelectedLanguage('Settings__Lbl_Menu_Unit')}</strong>
            </td>
            <td [style]="'width: 174px; text-align: left'">
              ${unit}
            </td>
          </tr>
          <tr>
            <td [style]="'text-align: left'">
                <strong>${this.translationStore.FindTranslationForSelectedLanguage('Global_Mandatory')}</strong>
            </td>
            <td [style]="'width: 174px; text-align: left'">
              ${this._booleanConverter(parameterDefinition.Mandatory)}
            </td>
          </tr>
          <tr>
            <td [style]="'text-align: left'">
                <strong>${this.translationStore.FindTranslationForSelectedLanguage('Docu_Ini__ValidFrom')}</strong>
            </td>
            <td [style]="'width: 174px; text-align: left'">
              ${validFrom}
            </td>
          </tr>
          <tr>
            <td [style]="'text-align: left'">
                <strong>${this.translationStore.FindTranslationForSelectedLanguage('Docu_Ini__ValidTo')}</strong>
            </td>
            <td [style]="'width: 174px; text-align: left'">
              ${validTo}
            </td>
          </tr>
        </tbody>
      </table>
    `;
  }

  private _createItem(value: IParameterValue, language: ILanguage): IParameterItem {
    const parameterDefinition = this.parameterDefinitionStore.getParameterDefinitionById(value.DefinitionId);
    const name = this.translationStore.FindTranslationForSelectedLanguage(parameterDefinition.TranslationKey);
    const validFrom = new Date(value.ValidFrom);
    const validTo = new Date(value.ValidTo);
    let display = '';
    switch (parameterDefinition.ParameterType) {
      case ParameterType.Text:
        display = value.ValueString;
        break;
      case ParameterType.Boolean:
        display = this._booleanConverter(value.ValueBoolean);
        break;
      case ParameterType.Number:
        display = value.ValueNumber.toString();
        break;
      case ParameterType.List:
        display = this._listConverter(value.ValueListKey, parameterDefinition);
        break;
    }
    return {
      Id: value.Id,
      SourceItem: value,
      Status: value.Valid ? 1 : -1,
      StatusClass: value.Valid ? 'ap-status-green' : 'ap-status-red',
      StatusDesc: value.Valid ? 'Global__Active' : 'Global__Inactive',
      ParaDef: parameterDefinition,
      Name: name,
      Value: display,
      StandardIcon: 'edit',
      InfoIcon: 'info',
      Mandatory: parameterDefinition.Mandatory,
      Info: parameterDefinition.Description,
      ValidFrom: validFrom,
      ValidTo: validTo,
      Image: '../../assets/img/info.png',
      ToolTip: this._createTooltip(parameterDefinition, value, language),
      Size: '24px',
      Unit: parameterDefinition.Unit !== null ? parameterDefinition.Unit : '-'
    };
  }

  private _create(definitionId, value, date?): void {
    const definition = this.parameterDefinitionStore.getParameterDefinitionById(definitionId);
    const campaignYearStart = this.campaignYearStore.getDefaultStart(date ? date.getFullYear() : undefined);
    const newParameterValue = {
      DefinitionId: definitionId,
      FarmId: this.loginStore.SelectedFarmId,
      RefId: this.refId,
      Id: ApGuidUtil.generateNewGuid(),
      Valid: true,
      ValidFrom: new Date(campaignYearStart),
      ValidTo: new Date('9999-07-31 23:59:59'),
      ValueNumber: definition.ParameterType === ParameterType.Number ? value : null,
      ValueBoolean: definition.ParameterType === ParameterType.Boolean ? value === 1 : null,
      ValueString: definition.ParameterType === ParameterType.Text ? value : null,
      ValueTimestamp: definition.ParameterType === ParameterType.TimeStamp ? value : null,
      ValueListKey: definition.ParameterType === ParameterType.List ? value : null,
      CreatedAt: null,
      CreatedBy: null,
      ChangedAt: null,
      ChangedBy: null,
      DeletedAt: null,
      DeletedBy: null,
      Version: 0,
    } as IParameterValue;
    this._createParameterList[newParameterValue.DefinitionId.toString()] = newParameterValue;
    delete this._updateParameterList[newParameterValue.DefinitionId.toString()];
    delete this._deleteParameterList[newParameterValue.DefinitionId.toString()];
    this.languageStore.SelectedLanguage$.subscribe(l => {
      this.items.next(this.items.value.concat(this._createItem(newParameterValue, l)));
    });
  }

  private _update(item: IParameterValue, value, date?): void {
    let display;
    item = ObjectFactory.Copy(item);
    const definition = this.parameterDefinitionStore.getParameterDefinitionById(item.DefinitionId);
    switch (definition.ParameterType) {
      case ParameterType.Text:
        item.ValueString = value;
        display = value;
        break;
      case ParameterType.Boolean:
        item.ValueBoolean = value !== -1;
        if (value !== -1) {
          display = this.translationStore.FindTranslationForSelectedLanguage('Global__Yes');
        } else {
          display = this.translationStore.FindTranslationForSelectedLanguage('Global__No');
        }
        break;
      case ParameterType.Number:
        item.ValueNumber = value;
        display = value.toString();
        break;
      case ParameterType.List:
        item.ValueListKey = value;
        display = this._listConverter(value, definition);
        break;
      case ParameterType.TimeStamp:
        item.ValueTimestamp = value;
        display = value;
        break;
    }

    if (date !== undefined) {
      item.ValidFrom = new Date(this.campaignYearStore.getDefaultStart(date.getFullYear()));
    }

    if (this._createParameterList[item.DefinitionId.toString()] !== undefined) {
      this._createParameterList[item.DefinitionId.toString()] = item;
    } else {
      this._updateParameterList[item.DefinitionId.toString()] = item;
      delete this._deleteParameterList[item.DefinitionId.toString()];
    }

    this.languageStore.SelectedLanguage$.subscribe(l => {
      this.items.next(
        this.items.getValue()
          .filter((v) => v.SourceItem.DefinitionId !== item.DefinitionId)
          .concat(this._createItem(item, l)));
    });
  }

  private _delete(item: IParameterValue): void {
    if (this._createParameterList[item.DefinitionId.toString()] !== undefined) {
      delete this._createParameterList[item.DefinitionId.toString()];
      this.items.next(this.items.value.filter((i) => i.Id !== item.Id));
    } else {
      this._deleteParameterList[item.DefinitionId.toString()] = item;
      delete this._updateParameterList[item.DefinitionId.toString()];
      this.items.next(this.items.value.filter((i) => this._deleteParameterList[i.SourceItem.DefinitionId] === undefined));
    }
    this.items.next(this.items.value.filter((v) => v.SourceItem.DefinitionId !== item.DefinitionId));
  }

  private _openEditDialog(parameterDefinition, item): void {
    this._editedParameterValue = ObjectFactory.Copy(item);
    const dialog = {
      title: this.translationStore.FindTranslationForSelectedLanguage('Global_Parameter_Edit'),
      formConfig: {
        fieldSets: [
          new ApDynformsConfigFieldset({
            key: 'ParameterFieldSet',
            columns: 2,
            config: this._getEditFieldSets(parameterDefinition, item)
          })
        ],
        values: {
          ParameterValue: item
        }
      },
      show: true,
      buttons: [
        {
          key: 'cancel',
          text: 'Global__Cancel',
          primary: false
        },
        {
          key: 'apply',
          text: 'Global__Take',
          primary: true,
          disable: ModalDialogButtonDisable.FormInvalid,
        }
      ],
    } as IModalDialogData;
    this.modalDialogStore.setModalDialogData(dialog);
  }

  private _getEditFieldSets(parameterDefinition, item): ApDynformsConfigDatepicker[] {
    const result = [];
    switch (parameterDefinition.ParameterType) {
      case ParameterType.Text:
        result.push(new ApDynformsConfigTextbox({
          key: 'Parameter',
          label: parameterDefinition.TranslationKey,
          value: item.ValueString,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            }),
            new ApDynformsValidator({
              validator: Validators.pattern(parameterDefinition.Regex),
              errorKey: 'Text_ValueInvalid'
            })
          ]
        }));
        break;
      case ParameterType.Boolean:
        result.push(new ApDynformsConfigComboBox({
          key: 'Parameter',
          value: item.ValueBoolean === true ? 1 : -1,
          valueField: 'id',
          textField: 'label',
          label: parameterDefinition.TranslationKey,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            }),
            new ApDynformsValidator({
              validator: Validators.pattern(parameterDefinition.Regex),
              errorKey: 'Text_ValueInvalid'
            })
          ],
          options: [
            {
              id: 1,
              label: this.translationStore.FindTranslationForSelectedLanguage('Global__Yes')
            },
            {
              id: -1,
              label: this.translationStore.FindTranslationForSelectedLanguage('Global__No')
            }
          ]
        }));
        break;
      case ParameterType.Number:
        result.push(new ApDynformsConfigNumerictextbox({
          key: 'Parameter',
          label: parameterDefinition.TranslationKey,
          value: item.ValueNumber,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            }),
            new ApDynformsValidator({
              validator: Validators.pattern(parameterDefinition.Regex),
              errorKey: 'Text_ValueInvalid'
            })
          ]
        }));
        break;
      default:
        result.push(new ApDynformsConfigComboBox({
          key: 'Parameter',
          value: item.ValueListKey,
          valueField: 'Id',
          textField: 'Name',
          label: parameterDefinition.TranslationKey,
          options: parameterDefinition.ListValues,
          validators: [
            new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            }),
            new ApDynformsValidator({
              validator: Validators.pattern(parameterDefinition.Regex),
              errorKey: 'Text_ValueInvalid'
            })
          ]
        }));
    }
    const minDate = new Date(`${this.campaignYearStore.getSelectedCampaignYear().Year}`);
    const currentDate = new Date(item.ValidFrom);
    result.push(new ApDynformsConfigDatepicker({
      key: 'ValidFrom',
      label: 'Docu_Ini__ValidFrom',
      value: currentDate < minDate ? minDate : currentDate,
      minDate,
      bottomView: 'decade',
      topView: 'decade',
      format: 'YYYY'
    }));
    return result;
  }
}

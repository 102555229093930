import {EventEmitter, Injectable}           from '@angular/core';
import {Store}                              from '../index';
import {IStateStore}                        from '../../ap-interface';
import {ApMapInstance}                      from '../../ap-map';
import {ILastView}                          from '../../ap-interface/interfaces/map/lastview';
import {LanguageStore}                      from '../translation/language.store';
import {TranslationStore}                   from '../translation/translation.store';
import Map                                  from 'ol/Map';
import {Coordinate}                         from 'ol/coordinate';
import {ColorLegendStore}                   from './color.legend.store';
import {GetSoilSampleClassColorService}     from '../../ap-utils/service/get-soil-sample-class-color.service';
import {MapLayerControlStore}               from './map.layer.control.store';
import {ILayerState, MapLayerStore}         from './map.layer.store';
import {IGeometryEditorState, MapEditStore} from './map.edit.store';
import {SafeBehaviorSubject}                from 'ts-tooling';
import {ILegendState, MapLegendStore}       from './map.legend.store';
import {FieldStore}                         from '../farm/field.store';
import {BehaviorSubject}                    from 'rxjs';
import {GetElementService}                  from '../../ap-utils/service/ap-get-element.service';
import {GetRoundNumericService}             from '../../ap-utils/service/get-round-numeric.service';
import {BaseFertilizationService}           from '../../services/data/base-fertilization.service';
import {FertilizerStore}                    from '../base-data/fertilizer.store';
import {FertilizationManagementService}     from '../../services/data/fertilization-management.service';
import {ApSignalrService}                   from '../../ap-core/services/ap-signalr.service';
import {SettingsStore}                      from '../base-data/settings.store';
import IField = Data.FieldManagement.IField;

export interface IMapStore extends IStateStore<any> {
  lastView: ILastView;
  fieldSelectionOn: boolean;
  fieldSelection: IField[];
  drawCropColor: boolean;
  drawSelectedStyle: boolean;
  layer: ILayerState;
  editor: IGeometryEditorState;
  legends: ILegendState;
  mergeFieldResult: string;
  splitFieldResult: any[];
}

@Injectable({providedIn: 'root'})
export class MapStore extends Store<IMapStore> {
  public Layers: MapLayerStore = null;
  public Editor: MapEditStore = null;
  public Legends: MapLegendStore = null;
  public FieldStore$ = new BehaviorSubject<FieldStore>(null);
  public isMap = false;
  public mapInitialized = new EventEmitter();

  constructor(public backend: ApSignalrService,
              private languageStore: LanguageStore,
              private settingsStore: SettingsStore,
              private translationStore: TranslationStore,
              private colorLegendStore: ColorLegendStore,
              private mapLayerControl: MapLayerControlStore,
              private elementService: GetElementService,
              private fertilizerStore: FertilizerStore,
              private baseFertilizationService: BaseFertilizationService,
              private roundNumericService: GetRoundNumericService,
              private getSoilSampleClassColorService: GetSoilSampleClassColorService,
              public fertilizationManagementService: FertilizationManagementService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      lastView: null,
      fieldSelectionOn: false,
      fieldSelection: [],
      drawCropColor: true,
      drawSelectedStyle: true,
      layer: MapLayerStore.DefaultState,
      editor: MapEditStore.DefaultState,
      legends: MapLegendStore.DefaultState,
      mergeFieldResult: null,
      splitFieldResult: null
    }, true);
    this.Layers = new MapLayerStore(this, this.FieldStore$, this.roundNumericService,
      this.mapLayerControl, this.colorLegendStore, this.getSoilSampleClassColorService,
      this.languageStore, this.translationStore);
    this.Editor = new MapEditStore(this);
    this.Legends = new MapLegendStore(this, this.settingsStore, this.translationStore,
      this.baseFertilizationService, this.fertilizerStore, this.elementService,
      this.fertilizationManagementService);
  }

  /**
   * get the Center Point of the Map
   */
  public get Center(): Coordinate {
    return ApMapInstance.mapRef ? ApMapInstance.mapRef.getView().getCenter() : null;
  }

  public get DrawCropColor$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.drawCropColor);
  }

  public get DrawSelectedStyle$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.drawSelectedStyle);
  }

  public get LastView(): ILastView {
    return super.Listen(s => s.lastView).getValue();
  }

  public get Map(): Map {
    return ApMapInstance.mapRef;
  }

  public EnableDistributionView(): void {
    this.DrawCropColor(false);
    this.DrawSelectedStyle(false);
  }

  public DisableDistributionView(): void {
    this.DrawCropColor(true);
    this.DrawSelectedStyle(true);
  }

  public DrawCropColor(value: boolean): void {
    this.Mutate(s => s.drawCropColor, () => value);
  }

  public DrawSelectedStyle(value: boolean): void {
    this.Mutate(s => s.drawSelectedStyle, () => value);
  }

  public SetLastView(view: ILastView): void {
    super.Mutate(s => s.lastView, () => view);
  }
}

import {ApBaseTileLayer} from './ap-base-tile.layer';
import {MapStore}        from '../../stores/map/map.store';
import XYZ               from 'ol/source/XYZ';

export class ApXYZLayer extends ApBaseTileLayer<XYZ> {
  constructor(mapStore: MapStore) {
    super(mapStore, 'XYZ', new XYZ());
  }

  setUrl(url: string): void {
    this.source.setUrl(url);
    this.layer.setZIndex(2);
    this.Visibility = url !== '';
  }
}

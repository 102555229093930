<div #apMapBurgerMenu>
  <button
    #anchor
    kendoButton
    [id]="'map-burger-menu'"
    [icon]="'menu'"
    [look]="'flat'"
    (click)="onClick()"
  ></button>
  <kendo-popup *ngIf="show && (showMap$ | async)"
               [style]="{width: '250px'}"
               [anchor]="anchor"
               [animate]="{direction: 'down', duration: 133, type: 'expand'}"
               [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"
               [popupAlign]="{ horizontal: 'right', vertical: 'top' }">
    <layer-controller></layer-controller>
  </kendo-popup>
</div>

import {Injectable}    from '@angular/core';
import {StringFactory} from 'ts-tooling';

@Injectable({
  providedIn: 'root'
})
export class UrlEncoderService {

  private static _encode(value: string): string {
    return encodeURIComponent(btoa(value));
  }

  private static _decode(value: string): string {
    return atob(decodeURIComponent(value));
  }

  encode(queryParams: any): any {
    let paramStr = '';
    if (!queryParams || Object.keys(queryParams).length < 1) {
      return {};
    }
    for (const param of Object.keys(queryParams)) {
      if (StringFactory.IsNullOrEmpty(queryParams[param])) {
        continue;
      }
      if (paramStr.length > 0) {
        paramStr += '&';
      }
      paramStr += `${param}=${queryParams[param]}`;
    }
    return StringFactory.IsNullOrEmpty(paramStr) ? {} : {params: UrlEncoderService._encode(paramStr)};
  }

  decode(queryParams: any): any {
    if (!queryParams.params) {
      return queryParams;
    }
    const result = {};
    for (const p of UrlEncoderService._decode(queryParams.params).Split('&')) {
      const [key, value] = p.Split('=');
      result[key] = value;
    }
    return result;
  }
}

import {AfterContentInit, Component, Input, OnDestroy} from '@angular/core';
import {Observable, Subscription}                      from 'rxjs';
import {IChartSet}                                     from '../../../stores/statistic/statistic.store';
import {Border}                                        from '@progress/kendo-angular-charts';
import {map}                                           from 'rxjs/operators';
import {TranslationStore}                              from '../../../stores/translation/translation.store';
import {StatisticChartAreaService}                     from '../../services/statistic-chart-area.service';
import {FieldStore}                                    from '../../../stores/farm/field.store';
import {ObjectFactory}                                 from 'ts-tooling';
import {GetElementService}                             from '../../../ap-utils/service/ap-get-element.service';
import {ApElementType}                                 from '../../../ap-interface/enums/ap-elements-type.enum';
import {IBarLineAreaChartData}                         from '../../../ap-interface';

@Component({
  selector: 'ap-planning-stats',
  templateUrl: 'planning.stats.component.html',
  styleUrls: ['planning.stats.component.scss']
})
export class PlanningStatsComponent implements AfterContentInit, OnDestroy {
  @Input()
  data: IChartSet<IBarLineAreaChartData>[];
  @Input()
  title: string;
  @Input()
  decimalPlaces = 2;
  @Input()
  public emptyMessage = 'Global__Please_Select_Planning';
  public chartP: IChartSet<IBarLineAreaChartData> = null;
  public chartK: IChartSet<IBarLineAreaChartData> = null;
  public chartMg: IChartSet<IBarLineAreaChartData> = null;
  public chartPh: IChartSet<IBarLineAreaChartData> = null;
  public strokeColor = '#212529';
  public border: Border = {
    color: this.strokeColor,
    dashType: 'solid',
    width: 1,
  };
  public summaryString = '';
  // scale the chart height and takes the minimum height of 120px
  public chartHeight = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => v - 105 < 120 ? 120 : v - 105),
  );
  public chartHeightNoTitle = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => v - 50 < 120 ? 120 : v - 50),
  );
  public chartHeightNoTitleHalf = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => (v - 50) / 2 < 120 ? 120 : (v - 50) / 2),
  );
  public chartHeight4X4 = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => (v / 2) - 52.5 < 120 ? 120 : (v / 2) - 52.5),
  );
  // scale the chart width and use the full width available
  public chartWidth = this.statisticChartAreaService.chartAreaWidth.pipe(
    map(v => v),
  );
  public chartWidthHalf = this.statisticChartAreaService.chartAreaWidth.pipe(
    map(v => v / 2),
  );
  private subscriptions: Subscription[] = [];
  private activeFilter = '';

  public titlePP2O5: string;
  public titleKK2O: string;
  public titleMgMgO: string;
  public titleCaO: string;

  constructor(private translationStore: TranslationStore,
              public statisticChartAreaService: StatisticChartAreaService,
              private fieldStore: FieldStore,
              private elementService: GetElementService) {
  }

  ngAfterContentInit(): void {
    this.readData(this.data);
    this.titlePP2O5 = this.elementService.GetElementStringByString(ApElementType.P);
    this.titleKK2O = this.elementService.GetElementStringByString(ApElementType.K);
    this.titleMgMgO = this.elementService.GetElementStringByString(ApElementType.Mg);
    this.titleCaO = this.elementService.GetElementStringByString(ApElementType.CaO);
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  filterChange(filter: any): void {
    this.activeFilter = filter;
  }

  private readData(d: IChartSet<IBarLineAreaChartData>[]): void {
    if (!d.Any()) {
      this.summaryString = '';
      return;
    }
    const dc = ObjectFactory.Copy(d);
    this.chartP = this.processClassData(dc, 'p');
    this.chartK = this.processClassData(dc, 'k');
    this.chartMg = this.processClassData(dc, 'mg');
    this.chartPh = this.processClassData(dc, 'ph');
    this.createDescription(dc);
  }

  private createDescription(d: IChartSet<IBarLineAreaChartData>[]): any {
    const chartSet = d.FirstOrDefault();
    const averageSymbol = this.translationStore.FindTranslationForSelectedLanguage('Global__Avg_Symbol');
    const minString = this.translationStore.FindTranslationForSelectedLanguage('Global__Min_Short');
    const maxString = this.translationStore.FindTranslationForSelectedLanguage('Global__Max_Short');
    const sumString = this.translationStore.FindTranslationForSelectedLanguage('Global__Sum');
    const productString = this.translationStore.FindTranslationForSelectedLanguage('Global__Product');
    if (chartSet.meta) {
      if (chartSet.meta?.min !== '-' && chartSet.meta?.max !== '-') {
        this.summaryString = `${productString}: ${chartSet.meta?.product}  ${averageSymbol}${chartSet.meta?.average}  ${minString}: ${chartSet.meta?.min}  ${maxString}: ${chartSet.meta?.max}  ${sumString}: ${chartSet.meta?.sum}`;
      } else {
        this.summaryString = `${productString}: ${chartSet.meta?.product}  ${averageSymbol}${chartSet.meta?.average}  ${sumString}: ${chartSet.meta?.sum}`;
      }
    } else {
      this.summaryString = '';
    }
  }

  private processClassData(d: IChartSet<IBarLineAreaChartData>[], title: string): IChartSet<IBarLineAreaChartData> {
    let result: IChartSet<IBarLineAreaChartData> = null;
    for (const dataset of d.FindAll(e => e.data?.title === title)) {
      if (!result) {
        result = dataset;
        continue;
      }
      if (result.axes.maxX < dataset.axes.maxX) {
        result.axes.maxX = dataset.axes.maxX;
      }
      for (let i = 0, l = result.data?.data?.length; i < l; i++) {
        result.data.data[i].value += dataset.data.data[i] ? dataset.data.data[i].value || 0 : 0;
      }
    }
    return result;
  }
}

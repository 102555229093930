import {IStateStore} from '../../ap-interface/interfaces/store';
import {Store}       from '../index';
import {Injectable}  from '@angular/core';
import {
  ApCustomTypes,
  DeleteAccessPermissions,
  InsertAccessPermissions,
  LoadAccessPermissions,
  LoadAccessPermissionsSuccess,
  NetTypes
}                    from 'invoker-transport';
import IAccessPermissions = Data.AccessPermission.IAccessPermissions;
import IUsergroup = Data.AccessPermission.IUsergroup;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IAccessPermissionsStore extends IStateStore<IAccessPermissions> {
}

@Injectable({providedIn: 'root'})
export class AccessPermissionsStore extends Store<IAccessPermissionsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(LoadAccessPermissionsSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  getAccessPermissions(): IAccessPermissions[] {
    return super.Listen(s => s.data).getValue();
  }

  loadAccessPermissions(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new LoadAccessPermissions([]));
  }

  insertPermission(farmId: number, userGroup: IUsergroup, featureId: number): void {
    this.DispatchBackend(new InsertAccessPermissions([
      {Name: 'farmId', Type: NetTypes.INT, Value: farmId},
      {Name: 'userGroup', Type: ApCustomTypes.Data_AccessPermission_Usergroup, Value: userGroup},
      {Name: 'featureId', Type: NetTypes.INT, Value: featureId}
    ]));
  }

  deletePermission(farmId: number, userGroup: IUsergroup, featureId: number): void {
    this.DispatchBackend(new DeleteAccessPermissions([
      {Name: 'farmId', Type: NetTypes.INT, Value: farmId},
      {Name: 'userGroup', Type: ApCustomTypes.Data_AccessPermission_Usergroup, Value: userGroup},
      {Name: 'featureId', Type: NetTypes.INT, Value: featureId}
    ]));
  }
}

import {ApVisibleStates, IStateStore}               from '../../ap-interface';
import {Store}                                      from '../index';
import {EventEmitter, Injectable}                   from '@angular/core';
import {Trace}                                      from '../../debug-utils/ApplicationTracer';
import {filter, skip}                               from 'rxjs/operators';
import {Observable}                                 from 'rxjs';
import {SafeBehaviorSubject}                        from 'ts-tooling';
import {ModalDialogStore}                           from '../dialog/modal.dialog.store';
import {IModalDialogData, ModalDialogButtonDisable} from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {ApTranslationService}                       from '../../ap-utils/service/ap-translation.service';
import {ApSignalrService}                           from '../../ap-core/services/ap-signalr.service';

interface IFormStore extends IStateStore<any> {
  create: ApVisibleStates;
  update: ApVisibleStates;
  updateComponent: { component: any, data: any };
}

@Injectable({providedIn: 'root'})
export class FormStore extends Store<IFormStore> {
  formCloseOnRequest = new EventEmitter<boolean>();

  constructor(public backend: ApSignalrService,
              public modalDialogStore: ModalDialogStore,
              public translationService: ApTranslationService) {
    super(backend, {
      data: [],
      loading: false,
      loaded: false,
      create: ApVisibleStates.OUT,
      update: ApVisibleStates.OUT,
      updateComponent: null,
    }, true);
  }

  /**
   * triggers when the Form State switches to OUT
   */
  get FormClose$(): Observable<ApVisibleStates> {
    return super.Listen(s => s.update)
      .pipe(filter(state => state === ApVisibleStates.OUT));
  }

  /**
   * triggers when the Form tate switches to IN
   */
  get FormOpen$(): Observable<ApVisibleStates> {
    return super.Listen(s => s.update)
      .pipe(filter(state => state === ApVisibleStates.IN));
  }

  /**
   * triggers when the Form State changes
   */
  get FormState$(): SafeBehaviorSubject<ApVisibleStates> {
    return super.Listen(s => s.update);
  }

  get FormState(): ApVisibleStates {
    return this.FormState$.getValue();
  }

  /**
   * triggers when a new Component was passed in the Store
   */
  get NewComponent$(): SafeBehaviorSubject<any> {
    return super.Listen(s => s.updateComponent);
  }

  @Trace()
  public switchComponent(component: { component: any, data: any }): void {
    super.Mutate(s => s.updateComponent, () => component);
  }

  @Trace()
  public openForm(component: { component: any, data: any }): void {
    if (component) {
      this.switchComponent(component);
    }
    super.Mutate(s => s.update, () => ApVisibleStates.IN);
  }

  @Trace()
  public closeForm(): void {
    super.Mutate(s => s.update, () => ApVisibleStates.OUT);
  }

  public tryCloseForm(): boolean {
    if (super.Listen(s => s.update).getValue() === ApVisibleStates.IN) {
      this.modalDialogStore.setModalDialogData({
        formConfig: undefined,
        show: true,
        buttons: [
          {
            disable: ModalDialogButtonDisable.Never,
            key: 'Y',
            primary: true,
            text: 'Global__Ok'
          },
          {
            disable: ModalDialogButtonDisable.Never,
            key: 'N',
            primary: false,
            text: 'Global__Cancel'
          },
        ],
        title: this.translationService.translate('Global__Warning'),
        message: this.translationService.translate('Global__Unsaved_Data')
      } as IModalDialogData);
      const subscription = this.modalDialogStore.Listen(s => s.result).pipe(skip(1)).subscribe((result) => {
        if (result.key === 'Y') {
          this.closeForm();
        }
        this.formCloseOnRequest.emit(result.key === 'Y');
        subscription.unsubscribe();
      });
      return true;
    }
    return false;
  }
}

import {Component, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup, ValidatorFn, Validators}                                 from '@angular/forms';
import * as OlFeature                                                       from 'ol/Feature';
import Feature                                                              from 'ol/Feature';
import OlFormatGeoJSON                                                      from 'ol/format/GeoJSON';
import {combineLatest, of, Subscription}                                    from 'rxjs';
import {filter, map, take}                                                  from 'rxjs/operators';
import {
  ApDynformsValidator
}                                                                           from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynformsComponent
}                                                                           from '../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsConfigCheckbox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  ApDynformsConfigComboBox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigFieldset
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigNumerictextbox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApDynformsConfigTextbox
}                                                                           from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {MAP_PROJECTION}                                                     from '../../ap-map';
import {
  ApPolygonEditLayer
}                                                                           from '../../ap-map/layers/ap-polygon-edit.layer';
import {
  CropTypeStore
}                                                                           from '../../stores/base-data/crop.types.store';
import {NotifyStore}                                                        from '../../stores/dialog/notify.store';
import {FieldStore}                                                         from '../../stores/farm/field.store';
import {FormStore}                                                          from '../../stores/layout/form.store';
import {
  CampaignYearStore
}                                                                           from '../../stores/login/campaignyear.store';
import {LoginStore}                                                         from '../../stores/login/login.store';
import {MapStore}                                                           from '../../stores/map/map.store';
import {
  TranslationStore
}                                                                           from '../../stores/translation/translation.store';
import {
  ApParameterComponent
}                                                                           from '../ap-parameter-entry-components/ap-parameter.component';
import {
  LanguageStore
}                                                                           from '../../stores/translation/language.store';
import {
  EditorService
}                                                                           from '../../map/components/edit/editor.service';
import {ApEditStyles}                                                       from '../../ap-map/layers/ap-edit-styles';
import {
  FieldFactoryService
}                                                                           from '../../factories/field-factory.service';
import IField = Data.FieldManagement.IField;
import ParameterRefObjectType = Data.Parameter.Enums.ParameterRefObjectType;
import IFieldCrop = Data.FieldManagement.IFieldCrop;
import ApValidationLevel = Data.Api.Validation.ApValidationLevel;

export interface IFieldEditFormValues {
  FieldName: string;
  FieldNumber: number;
  FieldSubnumber: number;
  Landuse: number;
  MainCrop: number;
  SecondCrop: number;
  MainCrop_YieldUnit: number;
  SecondCrop_YieldUnit: number;
  RemoveResidues: boolean;
  Note: string;
}

/**
 * Component for the edit field
 */
@Component({
  selector: 'ap-edit-field',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [formValidators]="formValidators"
                 [loading$]="fieldsSaving$">
      <div dynforms.after>
        <ap-parameter [refObject]="parameterRefObjectType" [refId]="data.SourceItem.Id"></ap-parameter>
      </div>
      <div class="ap-form-actions" dynforms.action>
        <button id="button_field_management_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">
          {{ 'Global__Cancel' | translate }}
        </button>
        <button id="button_field_management_edit_save"
                type="button"
                [disabled]="(formIsInvalid$ | async) || (invalidGeometry$ | async) || (toBigGeometry$ | async) || (notIntegrated$ | async)"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">
          {{ 'Global__Save' | translate }}
        </button>
      </div>
    </ap-dynforms>
    <ng-template #ComboBoxCropTypeItem let-Description="Description" let-IsSystem="IsSystem">
      <span style="font-size: 16px;">{{ Description }}
      </span>
    </ng-template>
  `,
})
export class ApEditFieldComponent implements OnInit, OnDestroy {
  private static ToBigGeometryNotification: Data.Api.Validation.IApValidationResult = {
    Counter: 1,
    DisplayTimeout: 0,
    ErrorKey: 'Global__Geometry_To_Big_Validation',
    Level: Data.Api.Validation.ApValidationLevel.Error,
    Parameters: [],
    UserName: null,
    UserId: null,
  };
  private static IntegrateGeometryFailNotification: Data.Api.Validation.IApValidationResult = {
    Counter: 1,
    DisplayTimeout: 0,
    ErrorKey: 'Global__Geometry_NotIntegrated',
    Level: Data.Api.Validation.ApValidationLevel.Error,
    Parameters: [],
    UserName: null,
    UserId: null,
  };

  constructor(private formStore: FormStore,
              private fieldStore: FieldStore,
              private translationService: TranslationStore,
              private languageStore: LanguageStore,
              private cropTypeStore: CropTypeStore,
              private mapStore: MapStore,
              private campaignYearStore: CampaignYearStore,
              private loginStore: LoginStore,
              private notifyStore: NotifyStore,
              private fieldFactory: FieldFactoryService) {
  }

  @ViewChild(ApDynformsComponent, {static: true}) public dynForm: ApDynformsComponent;
  @ViewChild(ApParameterComponent, {static: true}) public parameter: ApParameterComponent;
  @ViewChild('ComboBoxCropTypeItem', {static: true}) public comboBoxCropTypeItem: TemplateRef<any>;
  public data: { SourceItem: IField, gridSelectedKeys: any[] };
  public formConfig: ApDynformsConfigFieldset[];
  public caption: string;
  public isNewMode = false;
  public fieldsSaving$ = of(false);
  public parameterRefObjectType: ParameterRefObjectType.Field;
  public formIsInvalid$ = new EventEmitter<boolean>(false);
  public invalidGeometry$ = this.mapStore.Editor.FeatureLocked$.pipe(map((locked) => !locked));
  public notIntegrated$ = EditorService.ValidSketch$.pipe(map(integrated => !integrated));
  public toBigGeometry$ = this.mapStore.Editor.CurrentArea$.pipe(map((d) => d > 1000));
  public close = new EventEmitter<void>(true);
  public formValidators: ApDynformsValidator<ValidatorFn>[] = [
    new ApDynformsValidator({
      validator: ApEditFieldComponent.fieldNameOrNrRequired,
      errorKey: 'Global__Field_Name_Or_Nr_Required',
    }),
    new ApDynformsValidator({
      validator: ApEditFieldComponent.yieldRequiredIfMainCropIsSet,
      errorKey: 'Yield__required'
    }),
    new ApDynformsValidator({
      validator: ApEditFieldComponent.yieldRequiredIfSecondCropIsSet,
      errorKey: 'Yield__required_2'
    }),
  ];
  private _subscriptions: Array<Subscription> = [];
  private _originalGeometry: any;

  private static fieldNameOrNrRequired(frm: FormGroup): { mismatch: boolean } | null {
    const fieldName = frm.get('FieldName')?.value;
    const fieldNr = frm.get('FieldNumber')?.value;
    const fieldTNr = frm.get('FieldSubnumber')?.value;
    if (!fieldName && !fieldNr && !fieldTNr) {
      return {mismatch: true};
    }
    return null;
  }

  private static yieldRequiredIfSecondCropIsSet(frm: FormGroup): { missingYield: boolean } | null {
    const secondCrop = frm.get('SecondCrop')?.value;
    const secondCropYieldControl = frm.get('SecondCrop_YieldUnit');
    const secondCropYield = secondCropYieldControl?.value;
    const validationError = {missingYield: true};

    if (parseFloat(secondCrop) > 0 && !(parseFloat(secondCropYield) >= 0)) {
      secondCropYieldControl?.setErrors(validationError);
      return validationError;
    }

    return null;
  }

  private static yieldRequiredIfMainCropIsSet(frm: FormGroup): { missingYield: boolean } | null {
    const mainCrop = frm.get('MainCrop')?.value;
    const mainCropYieldControl = frm.get('MainCrop_YieldUnit');
    const mainCropYield = mainCropYieldControl?.value;
    const validationError = {missingYield: true};

    if (parseFloat(mainCrop) > 0 && !(parseFloat(mainCropYield) >= 0)) {
      mainCropYieldControl?.setErrors(validationError);
      return validationError;
    }

    return null;
  }

  public ngOnInit(): void {
    this.fieldsSaving$ = this.fieldStore.Listen(s => s.saving);
    this._subscriptions.push(EditorService.ValidSketch$
      .subscribe(integrated => {
        if (integrated) {
          this.notifyStore.removeMessage(ApEditFieldComponent.IntegrateGeometryFailNotification);
          this.mapStore.Layers.PolygonEditLayer.setStyle(ApEditStyles.selectedStyle);
        } else {
          this.notifyStore.addMessage(ApEditFieldComponent.IntegrateGeometryFailNotification);
          this.mapStore.Layers.PolygonEditLayer.setStyle(ApEditStyles.invalidStyle);
        }
      }));
    this._subscriptions.push(this.dynForm.initialized.subscribe(() => {
      this._subscriptions.push(this.dynForm.form.valueChanges.subscribe(() => {
        this.formIsInvalid$.emit(!this.dynForm.form.valid);
      }));
      this._subscriptions.push(this.dynForm.form.get('MainCrop')?.valueChanges?.subscribe(_ => {
        this.onMainCropChanged();
      }));
      this._subscriptions.push(this.dynForm.form.get('SecondCrop')?.valueChanges?.subscribe(_ => {
        this.onSecondCropChanged();
      }));
    }));
    this._subscriptions.push(this.mapStore.Editor.DeleteGeometry$
      .pipe(filter((fields) => fields && fields.Any()))
      .subscribe(() => this.deleteGeometry()));
    this._subscriptions.push(this.mapStore.Editor.EditedFeature$
      .pipe(filter((feat) => !!feat))
      .subscribe((feat) => this.saveGeometry(feat)));
    this._subscriptions.push(this.mapStore.Editor.CurrentArea$
      .pipe(filter((area) => area >= 0))
      .subscribe((area) => {
        if (area > 1000) {
          this.notifyStore.addMessage(ApEditFieldComponent.ToBigGeometryNotification);
        } else if (this.notifyStore.getNotify().length > 0) {
          this.notifyStore.removeMessage(ApEditFieldComponent.ToBigGeometryNotification);
        }
        if (!this.data || !this.data.SourceItem?.DefaultGeom || !this.dynForm || !this.dynForm.form || !this.dynForm.form.controls['Area']) {
          return;
        }
        this.data.SourceItem.DefaultGeom.AdminArea = Math.round(area * 10000);
        this.data.SourceItem.DefaultGeom.AreaHa = area;
        this.dynForm.form.controls['Area'].setValue(this.languageStore.FormatNumber(area));
      }));

    this._subscriptions.push(combineLatest([
      this.campaignYearStore.Listen((s) => s.selectedYear),
      this.loginStore.Listen((s) => s.farm.selectedFarm),
    ]).pipe(
      filter(([y, f]) => !!y && !!f),
      take(1),
    )
      .subscribe(([y, f]) => {
        if (!this.data.SourceItem) {
          this.isNewMode = true;
          this.data.SourceItem = this.fieldFactory.CreateNew({
            FarmId: f.Id,
            ValidFrom: y.DefaultStart,
            'DefaultGeom.ValidFrom': y.DefaultStart,
            'DefaultGeom.FarmId': f.Id
          });
        } else {
          this.isNewMode = false;
          if (!this.data.SourceItem?.DefaultGeom) {
            this.data.SourceItem.DefaultGeom = {
              AdminArea: 0,
              AreaHa: 0,
              ChangedAt: null,
              ChangedBy: null,
              CreatedAt: new Date(),
              CreatedBy: null,
              DeletedAt: null,
              DeletedBy: null,
              FarmId: f.Id,
              FieldId: undefined,
              Geom: undefined,
              Id: undefined,
              Note: '',
              ValidFrom: y.DefaultStart,
              ValidTo: new Date('9999-07-31 23:59:59'),
              Version: 0,
              CampaignYear: 0,
              FieldGeomPackages: [],
              Status: ''
            };
          }
        }
        this.caption = this.isNewMode ? 'Global_Create_Field' : 'Global_Edit_Field';
        this.formBuilder();
      }));
    this._originalGeometry = this.pullSelectedGeometry() as any;
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach((d) => d.unsubscribe());
  }

  public formBuilder(): void {
    let fieldCrop: IFieldCrop;
    if (this.data.SourceItem?.FieldCrops && this.data.SourceItem?.FieldCrops[0]) {
      fieldCrop = this.data.SourceItem.FieldCrops[0];
    }
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'field1',
        legend: 'Global__Description',
        config: [
          new ApDynformsConfigNumerictextbox({
            key: 'FieldNumber',
            label: 'Global__FieldNumber',
            value: this.data.SourceItem?.FieldNumber,
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'FieldSubnumber',
            label: 'Planning_Fieldpart',
            value: this.data.SourceItem?.FieldSubnumber,
            min: 0,
            decimals: 0,
            format: 'n0',
            maximumFractionDigits: 0,
            formErrors: ['Global__Field_Name_Or_Nr_Required'],
            validators: [
              new ApDynformsValidator({
                errorKey: 'Global__Field_Name_Or_Nr_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            key: 'FieldName',
            label: 'Global__FieldName',
            value: this.data.SourceItem?.FieldName,
            formErrors: ['Global__Field_Name_Or_Nr_Required']
          })
        ]
      }),
      new ApDynformsConfigFieldset({
        key: 'field2',
        legend: 'Tip_InformationText',
        config: [
          new ApDynformsConfigTextbox({
            disabled: true,
            key: 'Area',
            label: 'Global__Area_Unit',
            value: this.languageStore.FormatNumber(this.data.SourceItem?.DefaultGeom != null && this.data.SourceItem?.DefaultGeom.AdminArea > 0 ? this.data.SourceItem?.DefaultGeom.AdminArea / 10000 : 0),
            validators: [
              new ApDynformsValidator({
                errorKey: 'Settings__Msg_Vali_Value_Required',
                validator: Validators.min(1)
              })
            ]
          }),
          new ApDynformsConfigTextbox({
            disabled: true,
            key: 'SoilGroups',
            label: 'Nutrients_Pages__SoilGroup',
            value: null // not yet available. Maybe in future we take it from wsv_raster_meta_data
          })
        ]
      }),
      new ApDynformsConfigFieldset({
        key: 'field3',
        legend: (this.translationService.FindTranslationForSelectedLanguage('Docu_Ini__HarvestYear') + ' ' + this.campaignYearStore.getSelectedCampaignYear().Year),
        config: [
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'MainCrop',
            label: 'MainCrop',
            options: this._availableCropTypes(fieldCrop ? fieldCrop.CroptypeId : undefined),
            textField: 'Description',
            value: fieldCrop ? fieldCrop.CroptypeId : undefined,
            valueField: 'Id',
            valuePrimitive: true
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'MainCrop_YieldUnit',
            label: 'Global__YieldUnit',
            value: fieldCrop ? fieldCrop.ExpectedYield : undefined,
            min: 0,
            disabled: !(fieldCrop?.CroptypeId > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: IFieldEditFormValues) => !(f.MainCrop > 0))),
            formErrors: ['Yield__required'],
            validators: [
              new ApDynformsValidator({
                validator: ApEditFieldComponent.yieldRequiredIfMainCropIsSet,
                errorKey: 'Yield__required'
              })
            ]
          }),
          new ApDynformsConfigCheckbox({
            key: 'RemoveResidues',
            label: 'Nutrients_Pages_Popups__RemoveResidues',
            value: fieldCrop ? fieldCrop.StrawHarvested : false,
          }),
          new ApDynformsConfigTextbox({
            key: 'Note',
            label: 'Global__Fieldcrop_Notes',
            value: fieldCrop?.Note ?? ''
          }),
          new ApDynformsConfigComboBox({
            itemTemplate: this.comboBoxCropTypeItem,
            key: 'SecondCrop',
            label: 'SecondCrop',
            options: this._availableCropTypes(fieldCrop ? fieldCrop.CroptypeSecondId : undefined),
            textField: 'Description',
            validators: [],
            value: fieldCrop ? fieldCrop.CroptypeSecondId : undefined,
            valueField: 'Id',
            valuePrimitive: true
          }),
          new ApDynformsConfigNumerictextbox({
            key: 'SecondCrop_YieldUnit',
            label: 'Global__Yield_Removal_Unit',
            value: fieldCrop ? fieldCrop.ExpectedYieldSecond : undefined,
            min: 0,
            disabled: !(fieldCrop?.MainSecondCropType > 0),
            disabled$: this.dynForm.formValues$.pipe(map((f: IFieldEditFormValues) => !(f.SecondCrop > 0))),
            formErrors: ['Yield__required_2'],
            validators: [
              new ApDynformsValidator({
                validator: ApEditFieldComponent.yieldRequiredIfSecondCropIsSet,
                errorKey: 'Yield__required_2'
              })
            ]
          })
        ]
      }),
    ];
    if (!this.isNewMode) {
      this.formConfig.push(
        new ApDynformsConfigFieldset({
          key: 'Parameter',
          legend: 'Global_Parameter'
        }),
      );
    }
  }

  public onSubmitClick(): void {
    this.data.SourceItem = this.mapFormValues(this.data.SourceItem, this.dynForm.form.value);
    if (!this.data.SourceItem || !this.data.SourceItem.DefaultGeom) {
      console.warn(`formular values was empty`, this.dynForm.form.value);
      return;
    }
    let fieldSaved = false;
    if (this.isNewMode) {
      this.data.SourceItem.DefaultGeom.Geom = (this.pullSelectedGeometry() as any);
      this.data.SourceItem.DefaultGeom.Status = null;
      if (this.data.SourceItem.FieldGeoms.length === 0) {
        this.data.SourceItem.FieldGeoms.Add(this.data.SourceItem.DefaultGeom);
      } else {
        this.data.SourceItem.FieldGeoms[0] = this.data.SourceItem.DefaultGeom;
      }
      this.data.SourceItem.DefaultGeom = undefined;
      this.fieldStore.createField(this.data.SourceItem, this.parameter);
      // for second attempt if field save will fail with error
      this.data.SourceItem.DefaultGeom = this.data.SourceItem.FieldGeoms[0];
    } else {
      const selectedGeometry = this.pullSelectedGeometry() as any;
      const geomModified = selectedGeometry !== this._originalGeometry;
      if (selectedGeometry === undefined || selectedGeometry === null) {
        this.notifyStore.addMessage({
          Counter: 1,
          DisplayTimeout: 5,
          ErrorKey: 'Global__No_Geometry_For_Harvest_Year',
          Level: ApValidationLevel.Error,
          UserId: null,
          UserName: null,
          Parameters: null
        });
      } else {
        // replace geom only if user modified it
        if (geomModified) {
          this.data.SourceItem.DefaultGeom.Geom = selectedGeometry;
          this.data.SourceItem.DefaultGeom.Status = null;
          this.data.SourceItem.FieldGeoms[0] = this.data.SourceItem.DefaultGeom;
        }
        this.fieldStore.saveField(
          this.data.SourceItem,
          this.campaignYearStore.SelectedCampaignYear.DefaultStart,
          this.campaignYearStore.SelectedCampaignYear.DefaultEnd,
          this.parameter);
        fieldSaved = true;
      }

      if (fieldSaved) {
        // Eine mögliche Lösung um zu verhindern, dass in aktuell nicht nachvollziehbaren Fällen, dass Form offen bleibt,
        // obwohl die Nachricht kommt, dass erfolgreich gespeichert wurde.
        this._subscriptions.Add(this.fieldStore.Listen(s => s.saved).subscribe(saved => {
            if (saved) {
              setTimeout(() => this.formStore.closeForm(), 0);
            }
          }
        ));
      }
      // Saving field parameters is done by 'watchStream' in fieldStore after successful save of fields
    }
  }

  public onCancelClick(): void {
    this.mapStore.Editor.CloseEditor(this.fieldStore);
    this.formStore.closeForm();
  }

  public saveGeometry(feat: Feature): void {
    const format = new OlFormatGeoJSON();
    const jsonF = JSON.parse(format.writeFeature(feat));
    if (this.isNewMode) {
      this.data.SourceItem.DefaultGeom.Id = jsonF.id;
    }
    this.data.SourceItem.DefaultGeom.FarmId = this.data.SourceItem.FarmId;
    const newFeature = feat.clone();
    const coords = newFeature.getGeometry().transform(MAP_PROJECTION, 'EPSG:4326');
    const jsonFe = JSON.parse(format.writeFeature(new OlFeature.default({geometry: coords})));
    this.data.SourceItem.DefaultGeom.Geom = (JSON.stringify(jsonFe.geometry) as any);
  }

  private _availableCropTypes =
    (id?: number) => this.cropTypeStore.Listen((s) => s.data).getValue().filter((c) => c.Selected || c.Id === id)

  private mapFormValues(target: IField, formValue: IFieldEditFormValues): IField {
    const cropType = this._availableCropTypes(formValue.MainCrop).Find((ct) => ct?.Id === formValue?.MainCrop);
    const cropTypeSecond = this._availableCropTypes(formValue.MainCrop).Find((ct) => ct?.Id === formValue?.SecondCrop);
    target.FieldName = formValue.FieldName;
    target.FieldNumber = formValue.FieldNumber;
    target.FieldSubnumber = formValue.FieldSubnumber;
    target.FieldCrops[0] = ({
      Cropkey: cropType ? cropType.CropKey : null,
      CroptypeId: formValue.MainCrop,
      CroptypeSecondId: formValue.SecondCrop,
      MainSecondCropType: cropTypeSecond ? cropTypeSecond.CropKey : null,
      ExpectedYield: cropType ? formValue.MainCrop_YieldUnit : null,
      ExpectedYieldSecond: cropTypeSecond ? formValue.SecondCrop_YieldUnit : null,
      StrawHarvested: formValue.RemoveResidues,
      Note: formValue.Note
    } as IFieldCrop);
    return target;
  }

  private pullSelectedGeometry(): string {
    const selectedFeatures = this.mapStore.Layers.PolygonEditLayer.GetSelectedFeaturesAsJSON(ApPolygonEditLayer.projection);
    return selectedFeatures[Object.keys(selectedFeatures)[0]];
  }

  private deleteGeometry(): void {
    if (!this.data.SourceItem) {
      console.warn(`want to delete geometry but no geometry given to delete!`);
      return;
    }
    this.fieldStore.deleteFieldGeom(this.data.SourceItem.DefaultGeom.Id);
    this.fieldStore.deleteFieldCrop(this.data.SourceItem.FieldCrops[0]);
    this.mapStore.Editor.ShowEditbar(false);
    this.mapStore.Layers.PolygonEditLayer.clear();
    this.mapStore.Layers.FieldsLayer.Visibility = true;
    this.mapStore.Layers.FieldsCropLayer.Visibility = true;
    this.formStore.closeForm();
  }

  private onMainCropChanged(): void {
    const selectedCropId = this.dynForm?.form?.get('MainCrop')?.value;
    const yieldDefault = selectedCropId ? this.cropTypeStore.CropTypes.Find(c => c.Id === selectedCropId)?.ExpectedYield : undefined;
    this.dynForm?.form?.get('MainCrop_YieldUnit')?.setValue(yieldDefault);
  }

  private onSecondCropChanged(): void {
    const selectedCropId = this.dynForm?.form?.get('SecondCrop')?.value;
    const yieldDefault = selectedCropId && this.cropTypeStore.CropTypes.Find(c => c.Id === selectedCropId) ? 0 : undefined;
    this.dynForm?.form?.get('SecondCrop_YieldUnit')?.setValue(yieldDefault);
  }
}

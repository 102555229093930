import {Injectable}                          from '@angular/core';
import {CookieService}                       from 'ngx-cookie-service';
import {ApSignalrService}                                      from './ap-signalr.service';
import {Config, LoginActions, NetTypes, UserLogin, UserLogout} from 'invoker-transport';

function getHoursForMonth(month: number, year: number): number {
  if ([0, 2, 4, 6, 7, 9, 11].Contains(month)) {
    return 31 * 24;
  }
  if ([3, 5, 8, 10].Contains(month)) {
    return 30 * 24;
  }
  if (month !== 1) {
    return 0;
  }
  return (year % 4) === 0 ? 29 * 24 : 28 * 24;
}

export enum AgriportSessionDuration {
  DefaultSessionAgeHours = 24,
  ExtendedSessionAgeHours = getHoursForMonth(
    new Date().getMonth(), new Date().getFullYear())
}

/**
 * Service for cookies
 */
@Injectable({providedIn: 'root'})
export class ApCookieService {
  public getSessionDuration: () => number = null;
  public afterSetCookie: () => void = null;
  public onSetCookie: (arg: { data: string, durationHours: number }) => any;
  public onRemoveCookie: () => any;
  public onGetCookie: () => string;

  /**
   * connect to the stores
   */
  constructor(private cookieService: CookieService,
              private backend: ApSignalrService) {
    this.backend.cookieGetter = () => this.getCookie();
    this.backend.getListenerByActionType(LoginActions.SET_COOKIE)
      .subscribe((d) => {
        if (!d.Data) {
          return;
        }
        this.setCookie(d.Data, this.getSessionDuration ? this.getSessionDuration() : AgriportSessionDuration.DefaultSessionAgeHours);
        setTimeout(() => this.login(), 1);
      });
    this.backend.getListenerByActionType(LoginActions.USER_LOGIN_SUCCESS)
      .subscribe(() => {
        ApSignalrService.isLoggedIn = true;
      });
    this.backend.getListenerByActionType(LoginActions.USER_LOGOUT_FROM_SYSTEM)
      .subscribe(() => {
        ApSignalrService.isLoggedIn = false;
      });
  }

  public login(): void {
    const hash = this.getCookie();
    if (hash) {
      this.backend.send(new UserLogin([
        {Name: 'hash', Type: NetTypes.STRING, Value: hash}
      ]));
    }
  }

  public logout(): void {
    const hash = this.getCookie();
    if (hash) {
      this.backend.send(new UserLogout([
        {Name: 'hash', Type: NetTypes.STRING, Value: hash},
      ]));
      ApSignalrService.isLoggedIn = false;
      this.deleteCookie();
    }
  }

  /**
   * set the cookie
   */
  setCookie(data: string, durationHours: number): void {
    if (!this.onSetCookie || typeof this.onSetCookie !== 'function') {
      const needsSecureCookie = Config.BackendAddress.Contains('https:');
      const expireDate = new Date();
      expireDate.setHours(expireDate.getHours() + durationHours);
      this.cookieService.set('Agriport', data, expireDate, '/', null, needsSecureCookie, 'Strict');
    } else {
      this.onSetCookie({
        data, durationHours,
      });
    }
  }

  /**
   * delete the cookie
   */
  deleteCookie(): void {
    if (!this.onRemoveCookie || typeof this.onRemoveCookie !== 'function') {
      this.cookieService.delete('Agriport', '/');
    } else {
      this.onRemoveCookie();
    }
  }

  /**
   * get the cookie
   */
  getCookie(): string {
    if (!this.onGetCookie || typeof this.onGetCookie !== 'function') {
      return this.cookieService.get('Agriport');
    } else {
      return this.onGetCookie();
    }
  }
}

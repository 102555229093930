import {NgModule}                      from '@angular/core';
import {CommonModule}                  from '@angular/common';
import {ApLoggerEditComponent}         from './ap-logger-edit.component';
import {ApMachinesLoggerEditComponent} from './ap-machines-logger-edit.component';
import {ApSettingsEditComponent}       from './ap-settings-edit.component';
import {ApDynformsModule}              from '../../ap-dynforms/ap-dynforms.module';
import {ApTranslationModule}           from '../../translation/ap-translation.module';
import {ButtonModule}                  from '@progress/kendo-angular-buttons';
import {ApUtilsModule}                 from '../../ap-utils';
import {ApUsersEditComponent}          from './ap-users-edit.component';
import {ApPermissionModule}            from '../../ap-permission/ap-permission.module';

const COMPONENTS = [
  ApLoggerEditComponent,
  ApMachinesLoggerEditComponent,
  ApSettingsEditComponent,
  ApUsersEditComponent,
];

@NgModule({
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    ApDynformsModule,
    ApTranslationModule,
    ButtonModule,
    ApUtilsModule,
    ApPermissionModule
  ],
  exports: COMPONENTS
})
export class ApSettingsEntryComponentsModule {
}

import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {CompositeFilterDescriptor, FilterDescriptor}        from '@progress/kendo-data-query';
import {FilterService}                                      from '@progress/kendo-angular-grid';

@Component({
  selector: 'ap-checkbox-filter',
  templateUrl: './ap-checkbox-filter.component.html',
  styleUrls: ['./ap-checkbox-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApCheckboxFilterComponent implements AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public key;
  @Input() public items: any[];

  data = [];
  value = true;

  constructor() {
  }

  ngAfterViewInit(): void {
    if (!this.items || this.items?.length === 0) {
      return;
    }
    this.data =
      this.items
        .map(i => i[this.key])
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a) => a ? -1 : 1);
    this.value = this.currentFilter.filters.reduce((v: boolean, f: FilterDescriptor) => v && f.value, true);
    this.valueChange(this.value);
  }

  valueChange(value: boolean): void {
    this.filterService.filter({
      filters: [{
        field: this.key,
        operator: 'eq',
        value,
      }],
      logic: 'and'
    });
  }
}

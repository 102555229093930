import {Injectable}                              from '@angular/core';
import {TranslationStore}                        from '../../stores/translation/translation.store';
import {OperationModesEnum, OperationModesStore} from '../../stores/common/operation-modes.store';
import {ApplicationModesEnum, ApplModesStore}    from '../../stores/common/appl-modes.store';
import IOperationMode = Data.Common.IOperationMode;
import IApplicationMode = Data.Common.IApplicationMode;

@Injectable({providedIn: 'root'})
export class GetOperationModeService {
  constructor(private applicationModeStore: ApplModesStore,
              private operationModeStore: OperationModesStore,
              private translationStore: TranslationStore) {
  }

  public GetApplicationMode(mode: IOperationMode): IApplicationMode {
    if (mode.Key === OperationModesEnum.Constant) {
      return this.applicationModeStore.Listen(s => s.data).value.Find(a => a?.Key === ApplicationModesEnum.Constant);
    } else {
      return this.applicationModeStore.Listen(s => s.data).value.Find(a => a?.Key === ApplicationModesEnum.Variable);
    }
  }

  public GetOperationModeByLoggerId(id: number): IOperationMode {
    return this.operationModeStore.Listen(s => s.data).value.Find(o => o?.LoggerId === id);
  }

  public GetOperationMode(mode: IOperationMode): string {
    return this.translationStore.FindTranslationForSelectedLanguage(mode?.Key);
  }

  public getOperationModeById(id: number): IOperationMode {
    return this.operationModeStore.Listen(s => s.data).getValue().find(x => x.Id === id);
  }

  public getOperationModeByKey(key: string): IOperationMode {
    return this.operationModeStore.Listen(s => s.data).getValue().find(x => x.Key === key);
  }

  public getOperationModeByLoggerId(loggerId: number): IOperationMode {
    return this.operationModeStore.Listen(s => s.data).getValue().find(x => x.LoggerId === loggerId);
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';

export enum SoilSampleDateAction {
  SOIL_SAMPLE_DATES_GET = '[soilsample] dates get',
  SOIL_SAMPLE_DATES_GET_SUCCESS = '[soilsample] dates get success',
}

export class SoilSampleDatesGet implements IAction {
  readonly type = SoilSampleDateAction.SOIL_SAMPLE_DATES_GET;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSampleDatesGetSuccess implements IAction {
  readonly type = SoilSampleDateAction.SOIL_SAMPLE_DATES_GET_SUCCESS;

  constructor(public payload: any[]) {
  }
}

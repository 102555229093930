import VectorTile                           from 'ol/source/VectorTile';
import VectorTileLayer                      from 'ol/layer/VectorTile';
import {LayerFactory}                       from '../../map-factory/layer.factory';
import {LayerGroupNames}                    from './layer-names';
import {ILegend}                            from '../../stores/map/map.legend.store';
import {MapStore}                           from '../../stores/map/map.store';
import {buffer as OlBuffer, extend, Extent} from 'ol/extent';
import {EventEmitter}                       from '@angular/core';
import {ApMapViews}                         from './ap-map.views';
import * as ax                              from 'axios';
import {MapFactoryAuthenticator}            from '../../map-factory/authentication';
import {Geometry}                           from 'ol/geom';
import GeoJSON                              from 'ol/format/GeoJSON';
import OlFormatGeoJSON                      from 'ol/format/GeoJSON';
import {StringFactory}                      from 'ts-tooling';
import OlFeature                            from 'ol/Feature';
import VectorSource                         from 'ol/source/Vector';
import VectorImageLayer                     from 'ol/layer/VectorImage';
import {debounceTime}                       from 'rxjs/operators';

const FORCE_UPDATE_DEBOUNCE_MS = 500;
export interface IMapFactoryLayerDelegates {
  legendGetter?: () => ILegend | null;
  tooltipGenerator?: (featureData: { [key: string]: any }) => string;
  featureFilter?: (f: OlFeature) => boolean;
}

export class MapFactoryLayer {
  public name = '';
  public FixedZIndex = -1;
  public source: VectorTile;
  public layer: VectorTileLayer;
  public featureLayer = new VectorImageLayer({
    source: new VectorSource({
      features: [],
    })
  });
  public Group = LayerGroupNames.NONE;
  public legend: () => ILegend;
  public mapStore: MapStore = null;
  public extent: Extent = null;
  public onExtentLoadFinish = new EventEmitter();

  private tooltipGenerator?: (featureProperties: { [key: string]: any }) => string;
  private address = '';
  private url = '';
  private onForceUpdate = new EventEmitter();

  constructor(layerName: string, layerGroup: string, address: string, url: string, mapStore: MapStore,
              delegates: IMapFactoryLayerDelegates, fixedZIndex = -1) {
    if (fixedZIndex > 0) {
      this.FixedZIndex = fixedZIndex;
    }
    this.address = address;
    this.url = url;
    this.tooltipGenerator = delegates.tooltipGenerator;
    const tmp = LayerFactory.createMapFactoryLayer(layerName, address, url, delegates.featureFilter, mapStore);
    this.name = layerName;
    this.layer = tmp.layer;
    this.source = tmp.source;
    this.legend = delegates.legendGetter;
    this.mapStore = mapStore;
    if (layerGroup) {
      this.Group = layerGroup;
    }
    this.layer.setVisible(false);
    this.featureLayer.setVisible(false);
    // update the URL to be save the cache is cleared
    this.update(url);
    // Debounce map update in order to avoid flickering in case there are many subsequent updates within short time
    this.onForceUpdate.pipe(
      debounceTime(FORCE_UPDATE_DEBOUNCE_MS)).subscribe(() => {
      this.forceUpdate(this.url);
    });
  }

  get Visibility(): boolean {
    return this.layer.getVisible();
  }

  set Visibility(value: boolean) {
    this.layer.setVisible(value);
    if (this.mapStore.Legends) {
      this.mapStore.Legends.refreshLegend(this.legend(), value);
    }
    this.featureLayer.setVisible(value);
  }

  get Order(): number {
    return this.layer.getZIndex();
  }

  set Order(value: number) {
    this.layer.setZIndex(value);
    this.featureLayer.setZIndex(value);
  }

  public async loadFeatures(address: string, url: string, filter: (f: { type: string }) => boolean): Promise<void> {
    // TODO: implement loading Feature Data from MapFactory
    const tmp = url.Split('?params=');
    const paths = tmp[0].Split('/');
    const reqUrl = `${address}/geometry/${paths[1]}/${paths[2]}`;
    ax.default({
      method: 'POST',
      url: reqUrl,
      responseType: 'json',
      headers: {
        Authorization: await MapFactoryAuthenticator.getHash(),
      },
      data: btoa(tmp[1]),
    }).then(d => {
      if (!Array.isArray(d.data)) {
        console.warn(`expect ${reqUrl} returns a Array becomes: `, d.data);
        return;
      }
      this.featureLayer?.getSource()?.clear();
      if (d.data.length < 1) {
        return;
      }
      const geometries = d.data[0];
      if (!Array.isArray(geometries)) {
        console.warn(`expect ${reqUrl} returns a Array of Geometries becomes: `, geometries);
        return;
      }
      if (geometries.length < 1) {
        return;
      }

      const selectedGeometries = geometries.FindAll(filter);
      const format = new OlFormatGeoJSON();
      for (const geom of selectedGeometries) {
        const feature = format.readFeature(geom);
        (this.featureLayer?.getSource() as VectorSource)?.addFeature(feature);
      }
    });
  }

  get Legend(): () => ILegend {
    return this.legend;
  }

  set Legend(legend: () => ILegend) {
    this.legend = legend;
  }

  public forceUpdate(url: string): void {
    if (!this.layer) {
      return;
    }
    this.source.setUrl(url);
    this.source.changed();
    this.source.clear();
    this.loadExtends(url).then();
    this.url = url;
  }

  /**
   * Force update of map after debounce of 500ms.
   * Debounce map update in order to avoid flickering in case there are many consecutive updates within short time
   * (.e.g. due to job updates)
   */
  public forceUpdateDebounced(): void {
    this.onForceUpdate?.emit();
  }

  public clear(): void {
    if (this.featureLayer) {
      this.featureLayer.getSource()?.clear();
    }
    this.forceUpdate('');
  }

  public update(url: string): void {
    if (Array.isArray(this.source.getUrls()) && this.source.getUrls().Contains(url)) {
      return;
    }
    this.forceUpdate(url);
  }

  public reload(): void {
    this.forceUpdate(this.url);
  }

  public zoomToExtent(): void {
    if (!this.extent) {
      console.warn(`skip zoomToExtent there is no Extent in MapFactoryLayer ${this.name}`);
      return;
    }
    setTimeout(() => {
      ApMapViews.olView.fit(OlBuffer(this.extent, 100), {duration: 500});
    }, 300);
  }

  private async loadExtends(url: string): Promise<void> {
    if (StringFactory.IsNullOrEmpty(url)) {
      return;
    }
    const tmp = url.Split('?params=');
    const extentUrl = tmp[0].Replace('/{z}/{x}/{y}', '');
    const res = await ax.default({
      method: 'POST',
      url: extentUrl.ReplaceAll('/map/', '/extent/'),
      responseType: 'arraybuffer',
      headers: {
        Authorization: await MapFactoryAuthenticator.getHash(),
      },
      data: btoa(tmp[1]),
    });
    if (res.status !== 200) {
      return;
    }
    const boxes: Geometry[] = [];
    const enc = new TextDecoder('utf-8');
    const str = enc.decode(res.data);
    const geoJsonArray = StringFactory.IsNullOrEmpty(str) ? [] : JSON.parse(str);
    const format = new GeoJSON();
    for (const geom of geoJsonArray) {
      boxes.Add(format.readGeometry(geom));
    }
    if (boxes.length < 1) {
      return;
    }
    const ext = boxes[0].getExtent();
    if (boxes.length === 1) {
      this.extent = ext;
      this.onExtentLoadFinish.emit();
      return;
    }
    for (const box of boxes) {
      extend(ext, box.getExtent());
    }
    this.extent = ext;
    this.onExtentLoadFinish.emit();
    return;
  }

  public generateTooltip(featureProperties: { [key: string]: any }): string {
    if (!this.tooltipGenerator) {
      return undefined;
    }
    return this.tooltipGenerator(featureProperties);
  }
}

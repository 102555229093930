import Draw                    from 'ol/interaction/Draw';
import GeometryType            from 'ol/geom/GeometryType';
import VectorSource            from 'ol/source/Vector';
import Style                   from 'ol/style/Style';
import {Keys, MapInteractions} from './interactions';
import Select                  from 'ol/interaction/Select';
import * as OlCondition        from 'ol/events/condition';
import VectorLayer             from 'ol/layer/Vector';
import Snap                    from 'ol/interaction/Snap';
import Modify                  from 'ol/interaction/Modify';
import Feature                 from 'ol/Feature';
import Collection              from 'ol/Collection';
import {MapBrowserEvent}       from 'ol';

export type StyleDefinition = Style | Style[] | ((f) => Style | Style[]);

export class OlHelper {
  static DrawVector(type: GeometryType, source: VectorSource, style?: StyleDefinition): Draw {
    const action = new Draw({
      type,
      source,
      style,
      freehand: false,
      freehandCondition: () => false,
    });
    action.set(Keys.NAME, MapInteractions.DRAW);
    return action;
  }

  static Select(layer: VectorLayer, style: StyleDefinition): Select {
    const select = new Select({
      layers: [layer],
      style,
    });
    select.set(Keys.NAME, MapInteractions.SELECT);
    return select;
  }

  static SelectByClick(layer: VectorLayer, style: StyleDefinition): Select {
    const action = new Select({
      layers: [layer],
      style,
      toggleCondition: OlCondition.always,
      condition: OlCondition.click
    });
    action.set(Keys.NAME, MapInteractions.SELECT);
    return action;
  }

  static Snap(source: VectorSource, tolerance = 10): Snap {
    const snap = new Snap({
      source,
      pixelTolerance: tolerance,
    });
    snap.set(Keys.NAME, MapInteractions.SNAP);
    return snap;
  }

  static ModifySelected(select: Feature[], source?: VectorSource, style?: StyleDefinition): Modify {
    const modify = new Modify({
      features: new Collection(select),
      style,
      source,
      deleteCondition: (evt: MapBrowserEvent) => evt.type === 'dblclick',
    });
    modify.set(Keys.NAME, MapInteractions.MODIFY);
    return modify;
  }
}

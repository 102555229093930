import {Component, OnDestroy, OnInit} from '@angular/core';
import {map}                          from 'rxjs/operators';
import {GeometryEditorTool}           from '../ap-edit-tooltype';
import {EditorService}                from '../editor.service';
import {FieldStore}                   from '../../../../stores/farm/field.store';
import {ModalDialogStore}             from '../../../../stores/dialog/modal.dialog.store';
import {
  IModalDialogData,
  ModalDialogButtonDisable
}                                     from '../../../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {filter, Subscription}         from 'rxjs';
import {ApTranslationService}         from '../../../../ap-utils/service/ap-translation.service';

@Component({
  selector: 'ap-new-field-toolbar',
  template: `
    <div class="field_edit_toolbar">
      <button kendoButton
              [id]="'New_Island_Field_Geometry'"
              class="k-button k-primary button-important"
              [disabled]="IsDrawHoleDisabled$ | async"
              [class.ap-active]="IsDrawHoleFieldActive$ | async"
              (click)="NewHoleFieldClick()"
              [aptooltip]="'Fieldeditor_Draw_Inner_Surfaces' | translate" [perpetual]="true"
              [imageUrl]="'../assets/img/fieldeditor/v2/field_new_island.svg'">
      </button>
      <button kendoButton
              [id]="'Delete_Island_Field_Geometry'"
              class="k-button k-primary button-important"
              [disabled]="IsDeleteHoleDisabled$ | async"
              [class.ap-active]="IsDeleteHoleActive$ | async"
              (click)="DeleteHoleFieldClick()"
              [aptooltip]="'Fieldeditor_Delete_Inner_Surfaces' | translate" [perpetual]="true"
              [imageUrl]="'../assets/img/fieldeditor/v2/field_delete_island.svg'">
      </button>
      <button kendoButton
              [id]="'Delete_Field_Geometry'"
              class="k-button k-primary button-important"
              [disabled]="IsDeleteDisabled$ | async"
              (click)="DeleteNewFieldClick()"
              [aptooltip]="'Global__Cancel' | translate" [perpetual]="true"
              [imageUrl]="'../assets/img/fieldeditor/v2/delete.svg'">
      </button>
      <button kendoButton
              [id]="'Undo_Point_New_Field_Geometry'"
              class="k-button k-primary button-important"
              [disabled]="IsUndoDisabled$ | async"
              (click)="UndoLastPointClick()"
              [aptooltip]="'Fieldeditor_Undo_Last_Point' | translate" [perpetual]="true"
              [imageUrl]="'../assets/img/fieldeditor/v2/undo.svg'">
      </button>
      <button kendoButton
              [id]="'Finish_Field_Geometry'"
              class="k-button k-primary button-important"
              [disabled]="IsFinishDisabled$ | async"
              (click)="FinalizeGeometryClick()"
              [aptooltip]="'Fieldeditor_Finish_Geometry' | translate" [perpetual]="true"
              [imageUrl]="'../assets/img/fieldeditor/v2/save.svg'">
      </button>
    </div>
  `,
  styles: [
    `
      .field_edit_toolbar {
        color: white;
      }

      .ap-active {
        background-color: #76E11D !important;
      }

      .field_edit_toolbar button {
        margin-right: 2px;
      }
    `,
  ]
})
export class NewFieldToolbarComponent implements OnInit, OnDestroy {
  IsNewFieldDisabled$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => tool !== GeometryEditorTool.DRAW_NEW));
  IsNewFieldActive$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => tool === GeometryEditorTool.DRAW_NEW));
  IsEditDisabled$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => ![
    GeometryEditorTool.MODIFY,
    GeometryEditorTool.DRAW_HOLE,
    GeometryEditorTool.DELETE_HOLE,
  ].Contains(tool)));
  IsEditFieldActive$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => tool === GeometryEditorTool.MODIFY));
  IsDrawHoleDisabled$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => ![
    GeometryEditorTool.MODIFY,
    GeometryEditorTool.DRAW_HOLE,
    GeometryEditorTool.DELETE_HOLE,
  ].Contains(tool)));
  IsDrawHoleFieldActive$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => tool === GeometryEditorTool.DRAW_HOLE));
  IsDeleteHoleDisabled$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => ![
    GeometryEditorTool.MODIFY,
    GeometryEditorTool.DRAW_HOLE,
    GeometryEditorTool.DELETE_HOLE,
  ].Contains(tool)));
  IsDeleteHoleActive$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => tool === GeometryEditorTool.DELETE_HOLE));
  IsDeleteDisabled$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => ![
    GeometryEditorTool.DRAW_NEW,
    GeometryEditorTool.SPLIT
  ].Contains(tool)));
  IsFinishDisabled$ = this.editorService.mapStore.Editor.ActiveTool$.pipe(map(tool => ![
    GeometryEditorTool.DRAW_NEW,
    GeometryEditorTool.DRAW_HOLE,
    GeometryEditorTool.SPLIT
  ].Contains(tool)));
  IsUndoDisabled$ = this.editorService.mapStore.Editor.ActiveTool$
    .pipe(map(tool => ![
      GeometryEditorTool.DRAW_NEW,
      GeometryEditorTool.DRAW_HOLE,
      GeometryEditorTool.SPLIT
    ].Contains(tool)));
  private listenSketchGeometry: Subscription = null;

  constructor(private editorService: EditorService,
              private fieldStore: FieldStore,
              private modalDialogStore: ModalDialogStore,
              private translationService: ApTranslationService) {
  }

  ngOnInit(): void {
    this.listenSketchGeometry = this.modalDialogStore.Listen(s => s.result)
      .pipe(filter(v => [
        'editor_delete_geometry',
        'editor_delete_hole_geometry',
      ].Contains(v.key)))
      .subscribe(() => {
        this.editorService.mapStore.Editor.DeleteSketchGeometry();
      });
  }

  ngOnDestroy(): void {
    if (this.listenSketchGeometry) {
      this.listenSketchGeometry.unsubscribe();
      this.listenSketchGeometry = null;
    }
  }

  public EditNewFieldClick(): void {
    if (this.editorService.mapStore.Editor.ActiveTool === GeometryEditorTool.MODIFY) {
      return;
    }
    const prepareEditLayer = ![
      GeometryEditorTool.DRAW_HOLE,
      GeometryEditorTool.DELETE_HOLE,
    ].Contains(this.editorService.mapStore.Editor.ActiveTool);
    this.editorService.mapStore.Editor.PrepareModifyField(this.fieldStore.SelectedFields.Convert(f => this.fieldStore.getCurrentFieldGeom(f)?.Id).FindAll(id => !!id), this.fieldStore, prepareEditLayer);
  }

  public FinalizeGeometryClick(): void {
    if (![
      GeometryEditorTool.DRAW_NEW,
      GeometryEditorTool.SPLIT
    ].Contains(this.editorService.mapStore.Editor.ActiveTool)) {
      return;
    }

    this.editorService.FinalizeDrawing();
  }

  public DeleteNewFieldClick(): void {
    if (this.editorService.mapStore.Editor.ActiveTool === GeometryEditorTool.DRAW_HOLE) {
      this.modalDialogStore.setModalDialogData({
        buttons: [
          {
            key: 'editor_delete_hole_geometry',
            text: 'Global__Ok',
            primary: true,
            disable: ModalDialogButtonDisable.Never,
          },
          {
            key: 'editor_cancel_delete_hole_geometry',
            text: 'Global__Cancel',
            primary: true,
            disable: ModalDialogButtonDisable.Never,
          },
        ],
        busy: false,
        show: true,
        title: 'Global__Delete_Hole_Cancel_Title',
        message: 'Global__Delete_Hole_Cancel_Text',
      } as IModalDialogData);
    } else if (this.editorService.mapStore.Editor.ActiveTool === GeometryEditorTool.DRAW_NEW) {
      this.modalDialogStore.setModalDialogData({
        buttons: [
          {
            key: 'editor_delete_geometry',
            text: 'Global__Ok',
            primary: true,
            disable: ModalDialogButtonDisable.Never,
          },
          {
            key: 'editor_cancel_delete_geometry',
            text: 'Global__Cancel',
            primary: true,
            disable: ModalDialogButtonDisable.Never,
          },
        ],
        busy: false,
        show: true,
        title: this.translationService.translate('Global__New_Geometry_Cancel_Title'),
        message: this.translationService.translate('Global__New_Geometry_Cancel_Text'),
      } as IModalDialogData);
    } else if (this.editorService.mapStore.Editor.ActiveTool === GeometryEditorTool.SPLIT) {
      this.editorService.RestartSplit();
    }
  }

  public NewHoleFieldClick(): void {
    if (this.editorService.mapStore.Editor.ActiveTool === GeometryEditorTool.DRAW_HOLE) {
      return;
    }
    this.editorService.mapStore.Editor.PrepareDrawNewHole();
  }

  public DeleteHoleFieldClick(): void {
    if (this.editorService.mapStore.Editor.ActiveTool === GeometryEditorTool.DELETE_HOLE) {
      return;
    }
    this.editorService.mapStore.Editor.PrepareDeleteHole();
  }

  public NewFieldClick(): void {
    if (this.editorService.mapStore.Editor.ActiveTool === GeometryEditorTool.DRAW_NEW) {
      return;
    }
    this.editorService.mapStore.Editor.PrepareNewField(this.fieldStore.SelectedFields.Convert(f => this.fieldStore.getCurrentFieldGeom(f)?.Id).FindAll(id => !!id), this.fieldStore);
  }

  public UndoLastPointClick(): void {
    if (![
      GeometryEditorTool.DRAW_NEW,
      GeometryEditorTool.DRAW_HOLE,
      GeometryEditorTool.SPLIT
    ].Contains(this.editorService.mapStore.Editor.ActiveTool)) {
      return;
    }
    this.editorService.RemoveLastPointFromDrawing();
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ComboBoxComponent}            from '@progress/kendo-angular-dropdowns';
import {LoginStore}                   from '../../stores/login/login.store';
import {TranslationStore}             from '../../stores/translation/translation.store';
import {ApRoleTypeService}            from '../../services/common/ap-role-type.service';
import {ObjectFactory}                from 'ts-tooling';
import IFarm = Data.Authentication.IFarm;

@Component({
  selector: 'ap-farm-selection',
  templateUrl: 'ap-farm-selection.component.html'
})
export class ApFarmSelectionComponent implements OnInit, OnDestroy {
  public farm: IFarm;
  public farmFilter: IFarm[];

  private _farms: IFarm[];
  private _subscriptions = [];

  constructor(private loginStore: LoginStore,
              private translationStore: TranslationStore) {
  }

  public ngOnInit(): void {
    this._subscriptions.push(this.loginStore.SelectedFarm$.subscribe(selectedFarms => {
      this.farm = selectedFarms;
    }));
    this._subscriptions.push(this.loginStore.Farms$.subscribe(farms => {
      this._farms = this._sortFarms(ObjectFactory.Copy(farms));
      this.farmFilter = this._sortFarms(this._farms);
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(d => d?.unsubscribe());
  }

  public onItemClicked(e: any): void {
    if (e) {
      this.loginStore.changeSelectedFarm(e);
      this.farmFilter = this._farms;
    } else {
      this.farm = this.loginStore.SelectedFarm;
    }
  }

  public getToolTip(): string {
    let toolTip = this.translationStore.FindTranslationForSelectedLanguage('Global__CustomerNumber')
      + ': '
      + this.farm?.CustomerNumber;
    if (ApRoleTypeService.hasAgriconRole(this.loginStore.User)) {
      toolTip = toolTip + '</br>' + this.translationStore.FindTranslationForSelectedLanguage('Global__FarmId')
        + ': '
        + this.farm?.Id;
    }
    return toolTip + '</br>' + this.translationStore.FindTranslationForSelectedLanguage('Global__Country')
      + ': '
      + this.farm?.Country?.Id;
  }

  /**
   * Search for customer number, farm name or farm town
   */
  public filterChange($event: string): void {
    const searchValue = $event?.toLowerCase();
    this.farmFilter = this._farms.filter(f =>
      f?.FarmName?.toLowerCase()?.includes(searchValue) ||
      f?.CustomerNumber?.toLowerCase()?.includes(searchValue) ||
      f?.Location?.toLowerCase()?.includes(searchValue) ||
      f?.Id?.toString()?.includes(searchValue)
    );
  }

  public blur(comboBox: ComboBoxComponent): void {
    comboBox.writeValue(this.farm);
  }

  public focus(): void {
    this.farmFilter = this._farms;
  }

  private _sortFarms(farms: IFarm[]): IFarm[] {
    if (!farms) {
      return [];
    }
    return farms.sort((firstFarm, secondFarm) => firstFarm.FarmName.localeCompare(secondFarm.FarmName));
  }
}

import {animate, state, style, transition, trigger} from '@angular/animations';
import {ApVisibleStates}                            from '../ap-interface';

export const TRANSLATE_PANEL_TEXT = trigger('translatePanelText', [
  state('in', style({
    transform: 'translateX(0px)'
  })),
  state('out', style({
    transform: 'translateX(-250px)'
  })),
  transition(`in => out`, animate(200)),
  transition(`out => in`, animate(200)),
]);

export const TRANSLATE_EDIT_FORM = trigger('transalteEditForm', [
  state(ApVisibleStates.OUT, style({
    transform: 'translateX(-100%)',
    display: 'none'
  })),
  state(ApVisibleStates.IN, style({
    transform: 'translateX(0%)',
    display: 'block'
  })),
  transition(`${ApVisibleStates.IN} => ${ApVisibleStates.OUT}`, animate(200)),
  transition(`${ApVisibleStates.OUT} => ${ApVisibleStates.IN}`, animate(200)),
]);

<kendo-dropdownlist [class]="'checkbox-filter'"
                    [data]="data"
                    [value]="value"
                    (valueChange)="valueChange($event)">
  <ng-template kendoDropDownListItemTemplate let-dataItem>
    <span [class]="'checkbox-filter-content'">
      <input type="checkbox"
             [id]="'CheckboxFilterItem' + dataItem + 'Id'"
             [disabled]="true"
             [checked]="dataItem"
             [class]="'k-checkbox'">
      <label [attr.for]="'CheckboxFilterItem' + dataItem + 'Id'" [class]="'k-checkbox-label'">
        &nbsp;
      </label>
    </span>
  </ng-template>
  <ng-template kendoDropDownListValueTemplate let-dataItem>
    <span [class]="'checkbox-filter-content'">
      <input type="checkbox"
             [id]="'CheckboxFilterValue' + dataItem + 'Id'"
             [disabled]="true"
             [checked]="dataItem"
             [class]="'k-checkbox'">
      <label [attr.for]="'CheckboxFilterValue' + dataItem + 'Id'" [class]="'k-checkbox-label'">
        &nbsp;
      </label>
    </span>
  </ng-template>
</kendo-dropdownlist>

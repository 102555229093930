import {IAction, IApInvokerArguments} from '../../interfaces';
import IUsergroup = Data.AccessPermission.IUsergroup;

export enum ApUsergroupsActions {
  LOAD_USERGROUPS = '[usergroups] load',
  LOAD_USERGROUPS_SUCCESS = '[usergroups] load success',
  LOAD_USERGROUPS_FAIL = '[usergroups] load fail',
}

export class LoadUsergroups implements IAction {
  readonly type = ApUsergroupsActions.LOAD_USERGROUPS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadUsergroupsSuccess implements IAction {
  readonly type = ApUsergroupsActions.LOAD_USERGROUPS_SUCCESS;

  constructor(public payload: IUsergroup[]) {
  }
}

export class LoadUsergroupsFail implements IAction {
  readonly type = ApUsergroupsActions.LOAD_USERGROUPS_FAIL;
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IApPermissionData}                  from '../../ap-interface';
import {ApPermissions}                      from 'invoker-transport';
import {MapStore}                           from '../../stores/map/map.store';
import {map}                                from 'rxjs/operators';
import {GeometryEditorTool}                 from './edit/ap-edit-tooltype';

/**
 * Component for map's zoom and info controls.
 */
@Component({
  selector: 'ap-map-edit',
  templateUrl: 'ap-map-edit.component.html',
  styleUrls: ['ap-map-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

/**
 * Component for map's zoom and info controls.
 */
export class ApMapEditComponent {
  public apPermissionData: IApPermissionData;
  public ApPermissions = ApPermissions;
  public IsEditing$ = this.mapStore.Editor.ActiveTool$.pipe(map(tool => tool !== GeometryEditorTool.NONE));

  constructor(private mapStore: MapStore) {
  }
}

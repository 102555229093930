import {Store}                                                       from '../index';
import {Injectable}                                                  from '@angular/core';
import {LoadUserSettings, LoadUserSettingsSuccess} from 'invoker-transport';
import IUserSettings = Data.Authentication.IUserSettings;
import {ApSignalrService}                                            from '../../ap-core/services/ap-signalr.service';

interface IUserSettingsStore {
  loaded: boolean;
  loading: boolean;
  data: IUserSettings;
}

@Injectable({providedIn: 'root'})
export class UserSettingsStore extends Store<IUserSettingsStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: null,
    });

    backend.registerObservable(LoadUserSettingsSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  public loadUserSettings(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => null);
    this.DispatchBackend(new LoadUserSettings([]));
  }
}

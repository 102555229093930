import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators}                                             from '@angular/forms';
import {
  ApDynformsConfigFieldset
}                                                                            from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigTextbox
}                                                                            from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {merge}                                                               from 'lodash';
import {
  ApDynformsComponent
}                                                                            from '../../ap-dynforms/ap-dynforms.component';
import {
  ApDynformsValidator
}                                                                            from '../../ap-dynforms/ap-dynforms-validator';
import {FormStore}                                                           from '../../stores/layout/form.store';
import {
  ParameterDefinitionStore
}                                                                            from '../../stores/parameter/parameter.definition.store';
import {
  ApDynformsConfigComboBox
}                                                                            from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsConfigMultiSelect
}                                                                            from '../../ap-dynforms/config/ap-dynforms-config-multiselect';
import {
  ApDynformsConfigCheckbox
}                                                                            from '../../ap-dynforms/config/ap-dynforms-config-checkbox';
import {
  LanguageStore
}                                                                            from '../../stores/translation/language.store';
import {
  ApDynGridColumnConfigBase
}                                                                            from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPagerConfigBase
}                                                                            from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {
  ApDynGridPropertyColumnConfig
}                                                                            from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridPagerConfig
}                                                                            from '../../ap-dyngrids/config/ap-dyn-grid-pager-config';
import {IGridPagerData, IWizardChoiceData}                                   from '../../ap-interface/interfaces';
import {ApGridPagerType}                                                     from '../../ap-interface/enums';
import {
  TranslationStore
}                                                                            from '../../stores/translation/translation.store';
import {
  ApDynComponentComponent
}                                                                            from '../../ap-dyncomponent/ap-dyncomponent.component';
import IParameterDefinition = Data.Parameter.IParameterDefinition;
import ParameterRefObjectType = Data.Parameter.Enums.ParameterRefObjectType;
import ParameterType = Data.Parameter.Enums.ParameterType;
import IListValuesMapping = Data.Parameter.IListValuesMapping;

@Component({
  selector: 'ap-parameter-definition-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption">
      <ng-template [ngIf]="data.TypeId === 4" dynforms.after>
        <ap-dyncomponent [columns]="columns"
                         [items]="items"
                         [pager]="pager"
                         [selectable]="{checkboxOnly: true, mode: 'single'}">
        </ap-dyncomponent>
      </ng-template>
      <div class="ap-form-actions" dynforms.action>
        <button id="button_drivers_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_drivers_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ApParameterDefinitionEditComponent implements OnInit {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: IParameterDefinition;
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public caption: string;

  @ViewChild(ApDynComponentComponent, {static: false}) dynComponent: ApDynComponentComponent;
  columns: ApDynGridColumnConfigBase[] = [];
  items: any[] = [];
  pager: ApDynGridPagerConfigBase;

  public parameterRefObjectType: ParameterRefObjectType;
  public parameterType: ParameterType;
  listLanguage: string[] = [];
  listTypes = [];
  listRefObjects = [];
  listMassEditing = [];
  applyClicked = new EventEmitter<any>();

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private parameterDefinitionStore: ParameterDefinitionStore,
              private languageStore: LanguageStore,
              private translationStore: TranslationStore) {
    this.languageStore.LanguageList.forEach(l => {
      this.listLanguage.Add(l.Key);
    });
    this.createList();
    this.createRefObjectList();
    this.fillWizardChoiceData();
    this.applyClicked.subscribe((e) => this._onApplyClicked(e));
  }

  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        ParameterRefObjectType: undefined,
        ParameterType: undefined,
        ListValues: [],
        TranslationKey: '',
        StandardValue: '',
        Description: '',
        Mandatory: false,
        Countries: [],
        Name: '',
        RefObjectId: undefined,
        Regex: null,
        TypeId: undefined,
        Unit: null,
        Version: 0,
        Id: '00000000-0000-0000-0000-000000000000'
      };
    } else {
      this.isNewMode = false;
    }
    this.caption = this.isNewMode ? 'Global_Parameter_Definition_Create' : 'Global_Parameter_Definition_Edit';
    this.formBuilder();
    this.gridBuilder();
  }

  gridBuilder(): void {
    this.items = this.data.ListValues.map(listValue => {
      return {
        Id: listValue.Id,
        SourceItem: listValue,
      };
    });
    this.columns = [
      new ApDynGridPropertyColumnConfig({
        field: 'SourceItem.Id',
        title: 'Admin_Pages__Keys',
        filterable: true,
        width: 70,
        headerFilterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'SourceItem.Name',
        title: 'Global__Name',
        filterable: true,
        width: 70,
        headerFilterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'SourceItem.TranslationKey',
        title: 'Global_Translation_Key',
        filterable: true,
        width: 200,
        headerFilterable: true
      })
    ];
    this.pager = new ApDynGridPagerConfig({
      listMassEditing: this.listMassEditing,
      apply: this.applyClicked,
      hasPermission: true,
      showWithoutSelection: true,
      preselect: false
    });
  }

  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'parameterDefinition',
        config: [
          new ApDynformsConfigTextbox({
            key: 'Name',
            label: 'Global__Name',
            value: this.data.Name,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),
          new ApDynformsConfigTextbox({
            key: 'Description',
            label: 'Nutrients_Pages_Popups__Description',
            value: this.data.Description,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),
          new ApDynformsConfigTextbox({
            key: 'TranslationKey',
            label: 'Global_Translation_Key',
            value: this.data.TranslationKey,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),
          new ApDynformsConfigComboBox({
            key: 'Type',
            label: 'Tasks_ViewModel__Type',
            value: this.data.TypeId,
            valueField: 'Id',
            textField: 'Key',
            options: this.listTypes,
          }),
          new ApDynformsConfigComboBox({
            key: 'RefObject',
            label: 'Global_Reference_Object',
            value: this.data.RefObjectId,
            valueField: 'Id',
            textField: 'Key',
            options: this.listRefObjects
          }),
          new ApDynformsConfigTextbox({
            key: 'StandardValue',
            label: 'Global_Default_Value',
            value: this.data.StandardValue,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),
          new ApDynformsConfigTextbox({
            key: 'Unit',
            label: 'Settings__Lbl_Menu_Unit',
            value: this.data.Unit,
          }),
          new ApDynformsConfigMultiSelect({
            key: 'Countries',
            label: 'Global_Countries',
            value: this.data.Countries,
            options: this.listLanguage,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),
          new ApDynformsConfigCheckbox({
            key: 'Mandatory',
            label: 'Global_Mandatory',
            value: this.data.Mandatory
          }),
          new ApDynformsConfigTextbox({
            key: 'RegEx',
            label: 'Global_Regular_Expression',
            value: this.data.Regex,
          })
        ]
      })];
    this.dynForm.initialized.subscribe(() => {
      this.dynForm.form.get('Type').valueChanges.subscribe(d => {
        this.data.TypeId = d;
      });
    });
  }

  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    if (this.isNewMode) {
      this.parameterDefinitionStore.createParameterDefinition(this.data as IParameterDefinition);
    } else {
      this.parameterDefinitionStore.updateParameterDefinition(this.data as IParameterDefinition);
    }
    this.formStore.closeForm();
  }

  onCancelClick(): void {
    this.formStore.closeForm();
  }

  fillWizardChoiceData(): void {
    const deleteItem = {
      id: 0,
      name: this.translationStore.FindTranslationForSelectedLanguage('Global__Delete'),
      type: ApGridPagerType.None,
      values: null,
    } as IWizardChoiceData;
    this.listMassEditing.push(deleteItem);
    const item = {
      id: 1,
      name: this.translationStore.FindTranslationForSelectedLanguage('Global_Add_Entry'),
      type: ApGridPagerType.None,
      values: null,
    } as IWizardChoiceData;
    this.listMassEditing.push(item);
    const nameItem = {
      id: 2,
      name: this.translationStore.FindTranslationForSelectedLanguage('Global_Edit_Name'),
      type: ApGridPagerType.Text,
      values: null,
    } as IWizardChoiceData;
    this.listMassEditing.push(nameItem);

    const translationKeyItem = {
      id: 3,
      name: this.translationStore.FindTranslationForSelectedLanguage('Global_Edit_Key'),
      type: ApGridPagerType.Text,
      values: null,
    } as IWizardChoiceData;
    this.listMassEditing.push(translationKeyItem);
  }

  createRefObjectList(): void {
    const field = {
      Id: 0,
      Key: 'Field'
    };
    this.listRefObjects.Add(field);
  }

  createList(): void {
    const bool = {
      Id: 0,
      Key: this.translationStore.FindTranslationForSelectedLanguage('Global_Yes_No')
    };
    this.listTypes.Add(bool);
    const n = {
      Id: 1,
      Key: this.translationStore.FindTranslationForSelectedLanguage('Admin_Data_Collections__Number')
    };
    this.listTypes.Add(n);
    const text = {
      Id: 2,
      Key: this.translationStore.FindTranslationForSelectedLanguage('Global_Text')
    };
    this.listTypes.Add(text);
    const timestamp = {
      Id: 3,
      Key: this.translationStore.FindTranslationForSelectedLanguage('Global_Timestamp')
    };
    this.listTypes.Add(timestamp);
    const list = {
      Id: 4,
      Key: this.translationStore.FindTranslationForSelectedLanguage('Global__ListShort')
    };
    this.listTypes.Add(list);
  }

  private _onApplyClicked(data: IGridPagerData): void {
    if (data.object.id === 1) {
      const newItem = ({
        Id: this.data.ListValues.length,
        Name: this.translationStore.FindTranslationForSelectedLanguage('Global_Please_Fill_Out'),
        TranslationKey: this.translationStore.FindTranslationForSelectedLanguage('Global_Please_Fill_Out')
      } as IListValuesMapping);
      this.data.ListValues.Add(newItem);
      this.gridBuilder();
    }

    if (data.object.id === 2) {
      const rows = this.dynComponent.getSelectedItems();
      rows.forEach(row => {
        const item = row['SourceItem'] as IListValuesMapping;
        if (item) {
          const found = this.data.ListValues.Find(_ => _.Id === item.Id);
          found.Name = data.value;
          this.gridBuilder();
        }
      });
    }

    if (data.object.id === 3) {
      const rows = this.dynComponent.getSelectedItems();
      rows.forEach(row => {
        const item = row['SourceItem'] as IListValuesMapping;
        if (item) {
          const found = this.data.ListValues.Find(_ => _.Id === item.Id);
          found.TranslationKey = data.value;
          this.gridBuilder();
        }
      });
    }

    if (data.object.id === 0) {
      const rows = this.dynComponent.getSelectedItems();
      rows.forEach(row => {
        const item = row['SourceItem'] as IListValuesMapping;
        if (item) {
          const found = this.data.ListValues.Find(_ => _.Id === item.Id);
          this.data.ListValues.Remove(found);
          this.gridBuilder();
        }
      });
    }
  }
}

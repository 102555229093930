<div class="histogramcontainer">
  <ng-container *ngIf="summaryString === ''">
    <div class="empty-message">
      <p>{{emptyMessage | translate}}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="summaryString !== ''">
    <h3>{{title}}</h3>

<!--    <div class="x4chart">-->
<!--      <div>-->
<!--        <div>-->
<!--          &lt;!&ndash; P Chart &ndash;&gt;-->
<!--          <kendo-chart [categoryAxis]="{ categories: chartP?.data.categories }">-->
<!--            <kendo-chart-area [height]="chartHeight4X4 | async"-->
<!--                              [width]="chartWidthHalf | async">-->
<!--              <kendo-chart-title [text]="titlePP2O5" [color]="strokeColor"></kendo-chart-title>-->
<!--              <kendo-chart-legend [visible]="false"></kendo-chart-legend>-->

<!--              <kendo-chart-series>-->
<!--                <kendo-chart-series-item type="bar"-->
<!--                                         [color]="chartP?.color"-->
<!--                                         [style]="'smooth'"-->
<!--                                         [data]="chartP?.data.data"-->
<!--                                         [name]="chartP?.data.name">-->
<!--                  <kendo-chart-series-item-tooltip [background]="'#ffffffbf'" [border]="'#ffffffbf'" [color]="'#000'">-->
<!--                    <ng-template let-value="value">-->
<!--                      {{value | getRoundNumeric:chartP?.decimalPlaces}} (ca. {{value*100/chartP?.meta?.areaSum | getRoundNumeric:0}} %)-->
<!--                    </ng-template>-->
<!--                  </kendo-chart-series-item-tooltip>-->
<!--                </kendo-chart-series-item>-->
<!--              </kendo-chart-series>-->

<!--              <kendo-chart-value-axis>-->
<!--                <kendo-chart-value-axis-item [title]="{ text: chartP?.axes?.titleX }"-->
<!--                                             [color]="strokeColor"-->
<!--                                             [min]="chartP?.axes?.minX"-->
<!--                                             [max]="chartP?.axes?.maxX">-->
<!--                </kendo-chart-value-axis-item>-->
<!--              </kendo-chart-value-axis>-->

<!--              <kendo-chart-category-axis>-->
<!--                <kendo-chart-category-axis-item [categories]="chartP?.data.categories"-->
<!--                                                [labels]=""-->
<!--                                                [color]="strokeColor">-->
<!--                </kendo-chart-category-axis-item>-->
<!--              </kendo-chart-category-axis>-->
<!--            </kendo-chart-area>-->
<!--          </kendo-chart>-->
<!--        </div>-->
<!--        <div>-->
<!--          &lt;!&ndash; Mg Chart &ndash;&gt;-->
<!--          <kendo-chart [categoryAxis]="{ categories: chartMg?.data.categories }">-->
<!--            <kendo-chart-area [height]="chartHeight4X4 | async"-->
<!--                              [width]="chartWidthHalf | async">-->
<!--              <kendo-chart-title [text]="titleMgMgO" [color]="strokeColor"></kendo-chart-title>-->
<!--              <kendo-chart-legend [visible]="false"></kendo-chart-legend>-->

<!--              <kendo-chart-series>-->
<!--                <kendo-chart-series-item type="bar"-->
<!--                                         [color]="chartMg?.color"-->
<!--                                         [style]="'smooth'"-->
<!--                                         [data]="chartMg?.data.data"-->
<!--                                         [name]="chartMg?.data.name">-->
<!--                  <kendo-chart-series-item-tooltip [background]="'#ffffffbf'" [border]="'#ffffffbf'" [color]="'#000'">-->
<!--                    <ng-template let-value="value">-->
<!--                      {{value | getRoundNumeric:chartMg?.decimalPlaces}} ({{value*100/chartMg?.meta?.areaSum | getRoundNumeric:0}} %)-->
<!--                    </ng-template>-->
<!--                  </kendo-chart-series-item-tooltip>-->
<!--                </kendo-chart-series-item>-->
<!--              </kendo-chart-series>-->

<!--              <kendo-chart-value-axis>-->
<!--                <kendo-chart-value-axis-item [title]="{ text: chartMg?.axes?.titleX }"-->
<!--                                             [color]="strokeColor"-->
<!--                                             [min]="chartMg?.axes?.minX"-->
<!--                                             [max]="chartMg?.axes?.maxX">-->
<!--                </kendo-chart-value-axis-item>-->
<!--              </kendo-chart-value-axis>-->

<!--              <kendo-chart-category-axis>-->
<!--                <kendo-chart-category-axis-item [categories]="chartMg?.data.categories"-->
<!--                                                [labels]=""-->
<!--                                                [color]="strokeColor">-->
<!--                </kendo-chart-category-axis-item>-->
<!--              </kendo-chart-category-axis>-->
<!--            </kendo-chart-area>-->
<!--          </kendo-chart>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div>-->
<!--        <div>-->
<!--          &lt;!&ndash; K Chart &ndash;&gt;-->
<!--          <kendo-chart [categoryAxis]="{ categories: chartK?.data.categories }">-->
<!--            <kendo-chart-area [height]="chartHeight4X4 | async"-->
<!--                              [width]="chartWidthHalf | async">-->
<!--              <kendo-chart-title [text]="titleKK2O" [color]="strokeColor"></kendo-chart-title>-->
<!--              <kendo-chart-legend [visible]="false"></kendo-chart-legend>-->

<!--              <kendo-chart-series>-->
<!--                <kendo-chart-series-item type="bar"-->
<!--                                         [color]="chartK?.color"-->
<!--                                         [style]="'smooth'"-->
<!--                                         [data]="chartK?.data.data"-->
<!--                                         [name]="chartK?.data.name">-->
<!--                  <kendo-chart-series-item-tooltip [background]="'#ffffffbf'" [border]="'#ffffffbf'" [color]="'#000'">-->
<!--                    <ng-template let-value="value">-->
<!--                      {{value | getRoundNumeric:chartK?.decimalPlaces}} ({{value*100/chartK?.meta?.areaSum | getRoundNumeric:0}} %)-->
<!--                    </ng-template>-->
<!--                  </kendo-chart-series-item-tooltip>-->
<!--                </kendo-chart-series-item>-->
<!--              </kendo-chart-series>-->

<!--              <kendo-chart-value-axis>-->
<!--                <kendo-chart-value-axis-item [title]="{ text: chartK?.axes?.titleX }"-->
<!--                                             [color]="strokeColor"-->
<!--                                             [min]="chartK?.axes?.minX"-->
<!--                                             [max]="chartK?.axes?.maxX">-->
<!--                </kendo-chart-value-axis-item>-->
<!--              </kendo-chart-value-axis>-->

<!--              <kendo-chart-category-axis>-->
<!--                <kendo-chart-category-axis-item [categories]="chartK?.data.categories"-->
<!--                                                [labels]=""-->
<!--                                                [color]="strokeColor">-->
<!--                </kendo-chart-category-axis-item>-->
<!--              </kendo-chart-category-axis>-->
<!--            </kendo-chart-area>-->
<!--          </kendo-chart>-->
<!--        </div>-->
<!--        <div>-->
<!--          &lt;!&ndash; Ph Chart &ndash;&gt;-->
<!--          <kendo-chart [categoryAxis]="{ categories: chartPh?.data.categories }">-->
<!--            <kendo-chart-area [height]="chartHeight4X4 | async"-->
<!--                              [width]="chartWidthHalf | async">-->
<!--              <kendo-chart-title [text]="titleCaO" [color]="strokeColor"></kendo-chart-title>-->
<!--              <kendo-chart-legend [visible]="false"></kendo-chart-legend>-->

<!--              <kendo-chart-series>-->
<!--                <kendo-chart-series-item type="bar"-->
<!--                                         [color]="chartPh?.color"-->
<!--                                         [style]="'smooth'"-->
<!--                                         [data]="chartPh?.data.data"-->
<!--                                         [name]="chartPh?.data.name">-->
<!--                  <kendo-chart-series-item-tooltip [background]="'#ffffffbf'" [border]="'#ffffffbf'" [color]="'#000'">-->
<!--                    <ng-template let-value="value">-->
<!--                      {{value | getRoundNumeric:chartPh?.decimalPlaces}} ({{value*100/chartPh?.meta?.areaSum | getRoundNumeric:0}} %)-->
<!--                    </ng-template>-->
<!--                  </kendo-chart-series-item-tooltip>-->
<!--                </kendo-chart-series-item>-->
<!--              </kendo-chart-series>-->

<!--              <kendo-chart-value-axis>-->
<!--                <kendo-chart-value-axis-item [title]="{ text: chartPh?.axes?.titleX }"-->
<!--                                             [color]="strokeColor"-->
<!--                                             [min]="chartPh?.axes?.minX"-->
<!--                                             [max]="chartPh?.axes?.maxX">-->
<!--                </kendo-chart-value-axis-item>-->
<!--              </kendo-chart-value-axis>-->

<!--              <kendo-chart-category-axis>-->
<!--                <kendo-chart-category-axis-item [categories]="chartPh?.data.categories"-->
<!--                                                [labels]=""-->
<!--                                                [color]="strokeColor">-->
<!--                </kendo-chart-category-axis-item>-->
<!--              </kendo-chart-category-axis>-->
<!--            </kendo-chart-area>-->
<!--          </kendo-chart>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </ng-container>
  <div style="align-items: center">
    <ng-container *ngIf="summaryString !== ''">
      <h3>{{summaryString}}</h3>
    </ng-container>
  </div>
</div>

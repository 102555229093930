import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Validators}                              from '@angular/forms';
import {merge}                                   from 'lodash';
import {ApDynformsConfigFieldset}                from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsConfigTextbox}                 from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsValidator}                     from '../../ap-dynforms/ap-dynforms-validator';
import {LoggerStore}                             from '../../stores/docu/logger.store';
import {FormStore}                               from '../../stores/layout/form.store';
import {LoginStore}                              from '../../stores/login/login.store';
import {Observable, Subscription}                from 'rxjs';
import {ApDynformsConfigComboBox}                from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {ApDynformsComponent}                     from '../../ap-dynforms/ap-dynforms.component';
import {filter, map, mergeMap, startWith}        from 'rxjs/operators';
import ILogger = Data.DocuContext.Logger.ILogger;
import {ApRoleTypeService}                       from '../../services/common/ap-role-type.service';

/**
 * Component for editing or creating a logger
 */
@Component({
  selector: 'ap-logger-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [loading$]="saving$" [headerIcon]="'ap-icon-settings'">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_settings_logger_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{ 'Global__Cancel' | translate }}
        </button>
        <button id="button_settings_logger_edit_save"
                type="button" [disabled]="(dynForm.validChanges | async) !== true"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{ 'Global__Save' | translate }}
        </button>
      </div>
    </ap-dynforms>`
})

/**
 * Component for editing or creating a logger
 */
export class ApLoggerEditComponent implements OnInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: ILogger;
  public formConfig: ApDynformsConfigFieldset[];
  public isNewMode = false;
  public caption: string;
  public saving$: Observable<boolean>;
  private SendTargetHansenhof = {key: 1, text: 'Hansenhof'};
  private SendTargetLogicway = {key: 2, text: 'Logicway'};
  private SendTargetAgricon = {key: 3, text: 'Agricon'};
  private SendTargetControlKey = 'SendTarget';
  private _subscriptions: Array<Subscription> = [];

  constructor(private formStore: FormStore,
              private loggerStore: LoggerStore,
              private loginStore: LoginStore) {
    this.saving$ = this.loggerStore.Listen(s => s.saving);
    this._subscriptions.Add(this.loggerStore.Listen(s => s.saved).subscribe(saved => {
        if (saved) {
          this.formStore.closeForm();
        }
      }
    ));
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        Id: undefined,
        Imei: '',
        Typ: null,
        SendTarget: this.SendTargetHansenhof.key,
        IsDeleted: false,
        FarmId: this.loginStore.SelectedFarmId,
        CreatedAt: new Date(),
        CreatedBy: null,
        ChangedBy: null,
        ChangedAt: null,
        DeletedBy: null,
        DeletedAt: null,
        Version: 0,
        Password: null,
        NewPassword: null,
        PwSalt: null
      };
    } else {
      this.isNewMode = false;
    }
    this.caption = this.isNewMode ? 'Settings__Lbl_Logger_Create' : 'Settings__Lbl_Logger_Edit';
    this.formBuilder();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'logger',
        config: [
          new ApDynformsConfigTextbox({
            key: 'Imei',
            label: 'Docu_Ini__Imei',
            value: this.data.Imei,
            validators: [
              new ApDynformsValidator({
                validator: Validators.maxLength(15),
                errorKey: 'Global__Imei_Demands_15_Characters'
              }),
              new ApDynformsValidator({
                validator: Validators.minLength(15),
                errorKey: 'Global__Imei_Demands_15_Characters'
              }),
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Fill_Imei'
              })
            ]
          }),

          new ApDynformsConfigTextbox({
            key: 'Typ',
            label: 'Docu_Ini__Type',
            value: this.data.Typ
          })
        ]
      })];

    if (ApRoleTypeService.hasAgriconRole(this.loginStore.User)) {
      this.formConfig[0].config.Add(new ApDynformsConfigComboBox({
        key: this.SendTargetControlKey,
        value: this.data.SendTarget,
        label: 'Global__Source',
        options: [
          this.SendTargetHansenhof,
          this.SendTargetLogicway,
          this.SendTargetAgricon
        ],
        textField: 'text',
        valueField: 'key',
        validators: [
          new ApDynformsValidator({validator: Validators.required, errorKey: 'Settings__Msg_Vali_Value_Required'}),
        ]
      }));

      this.formConfig[0].config.Add(new ApDynformsConfigTextbox({
        key: 'newpassword',
        label: 'Admin_Pages_Popups__NewPassword',
        value: this.data.NewPassword,
        disabled$: this.dynForm.form$.pipe(
          filter((form) => !!form),
          mergeMap((form) => form.controls[this.SendTargetControlKey].valueChanges.pipe(
            map(v => !this.isAgriconLogger(v)),
            startWith(!this.isAgriconLogger(this.data.SendTarget))
          )))
      }));
    }
  }

  private isAgriconLogger(sendTarget: number): boolean {
    return sendTarget === this.SendTargetAgricon.key;
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    if (this.isNewMode) {
      this.loggerStore.createLogger(this.data);
    } else {
      this.loggerStore.updateLogger(this.data);
    }
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  onCancelClick(): void {
    this.formStore.closeForm();
  }
}

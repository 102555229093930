import {ApGuidUtil} from '../ap-utils';
import IField = Data.FieldManagement.IField;
import {Injectable}      from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FieldFactoryService {
  constructor() {}

  /// <summary>
  /// generates IField type with empty values combined with given templateObject
  /// optional: templateObject - object to copy properties from to newly created object
  /// </summary>
  CreateNew(templateObject?: any): IField {
    const newField: IField = {
      ChangedAt: null,
      ChangedBy: null,
      CreatedAt: new Date(),
      CreatedBy: null,
      DeletedAt: null,
      DeletedBy: null,
      FarmId: null,
      FieldBlock: '',
      FieldCrops: [],
      FieldGeoms: [],
      FieldGroup: undefined,
      FieldId: 0,
      FieldName: '',
      FieldNumber: 0,
      FieldSubnumber: 0,
      FieldType: 0,
      Id: ApGuidUtil.generateNewGuid(),
      Note: '',
      ValidFrom: new Date(),
      ValidTo: new Date('9999-07-31 23:59:59'),
      Version: 0,
      CampaignYear: 0,
      DefaultGeom: {
        AdminArea: 0,
        AreaHa: 0,
        ChangedAt: null,
        ChangedBy: null,
        CreatedAt: new Date(),
        CreatedBy: null,
        DeletedAt: null,
        DeletedBy: null,
        FarmId: null,
        FieldId: undefined,
        Geom: undefined,
        Id: undefined,
        Note: '',
        ValidFrom: new Date(),
        ValidTo: new Date('9999-07-31 23:59:59'),
        Version: 0,
        CampaignYear: 0,
        FieldGeomPackages: [],
        Status: ''
      },
    };

    if (templateObject != null) {
      Object.keys(templateObject).map(prop => {
        if (prop.indexOf('.') >= 0) {
          // Safe to consider there is only 1 dot because Field object nesting complexity is 2
          const pair = prop.split('.');
          newField[pair[0]][pair[1]] = templateObject[prop];
        } else {
          if (newField.hasOwnProperty(prop)) {
            newField[prop] = templateObject[prop];
          } else {
            console.warn('There is no property ' + prop + ' in IField interface. Please check your input parameter.');
          }
        }
      });
    }

    return newField;
  }
}

import {NgModule}                          from '@angular/core';
import {CommonModule}                      from '@angular/common';
import {ApDynamicComponentLoaderComponent} from './ap-dynamic-component-loader.component';
import {HostDirective}                     from './host.directive';


@NgModule({
  declarations: [
    ApDynamicComponentLoaderComponent,
    HostDirective
  ],
  exports: [
    ApDynamicComponentLoaderComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ApDynamicComponentLoaderModule {
}

import {Pipe, PipeTransform} from '@angular/core';
import {Trace}               from '../../debug-utils/ApplicationTracer';
import {ApGetCropService}    from '../services/ap-get-crop.service';
import IField = Data.FieldManagement.IField;

@Pipe({
  name: 'getCrop'
})
export class GetCropPipe implements PipeTransform {
  constructor(private service: ApGetCropService) {
  }

  @Trace()
  transform(item: IField, getMainCrop: boolean, returns: 'object' | 'description' | 'shortname'): any {
    return this.service.getCrop(item, getMainCrop, returns);
  }
}

import OL3Parser                               from 'jsts/org/locationtech/jts/io/OL3Parser';
import * as OlGeom from 'ol/geom';
import LinearRing               from 'ol/geom/LinearRing';
import OlFeature, {FeatureLike} from 'ol/Feature';
import OlPoint                  from 'ol/geom/Point';

const Ol3Parser = new OL3Parser();
Ol3Parser['inject'](OlGeom.Point, OlGeom.LineString, LinearRing, OlGeom.Polygon, OlGeom.MultiPoint,
  OlGeom.MultiLineString, OlGeom.MultiPolygon);

export class GeometryChecker {
  static IsPointInPolygon(features: OlFeature[], coordinates: number[]): OlFeature[] {
    const parser = new OL3Parser();
    parser['inject'](OlGeom.Point, OlGeom.LineString, LinearRing, OlGeom.Polygon, OlGeom.MultiPoint,
      OlGeom.MultiLineString, OlGeom.MultiPolygon);

    const res: OlFeature[] = [];
    const ptFeature = new OlFeature({geometry: new OlPoint(coordinates)});
    for (const feat of features) {
      const ntsFeature = parser.read(feat.getGeometry());
      const pt = parser.read(ptFeature.getGeometry());
      if (ntsFeature.contains(pt)) {
        res.Add(feat);
      }
    }
    return res;
  }

  static IsPointInFeature(features: FeatureLike[], coordinates: number[]): FeatureLike[] {
    const parser = new OL3Parser();
    parser['inject'](OlGeom.Point, OlGeom.LineString, LinearRing, OlGeom.Polygon, OlGeom.MultiPoint,
      OlGeom.MultiLineString, OlGeom.MultiPolygon);

    const res: FeatureLike[] = [];
    const ptFeature = new OlFeature({geometry: new OlPoint(coordinates)});
    for (const feat of features) {
      const ntsFeature = parser.read(feat.getGeometry());
      const pt = parser.read(ptFeature.getGeometry());
      if (ntsFeature.contains(pt)) {
        res.Add(feat);
      }
    }
    return res;
  }
}

export enum FieldCropRotationPriority {
  MainCrop = 0,
  SecondCrop = 1
}

export type FieldsCropRotationStatsData = {
  CropPriority: FieldCropRotationPriority;
  CropTypesPerYear: IFieldsCropRotationPerYear;
};

export type IFieldsCropRotationPerYear = {
  [year: number]: IFieldsCropRotationAggregated[];
};

export type IFieldsCropRotationAggregated = {
  CropTypeName: string;
  Color: string;
  AreaHa: number;
};

export type IFieldsCropRotationTableData = {
  CropTypeName: string;
  CropYearArea: { [key: number]: number };
};

export type FieldCropRotationPrioritySelection = {
  Text: string;
  Value: FieldCropRotationPriority;
};

export type ChartBarItemValue = {
  Name: string;
  Color: string;
  Data: BarDataType[];
};

export type BarDataType = {
  Percent: number;
  AreaHa: number;
};

export type CropTableData = {
  FieldCropsData: IFieldsCropRotationTableData[];
  ChartBarData: ChartBarItemValue[];
};

import {Injectable}                                            from '@angular/core';
import {Store}                                                 from '../index';
import {ApplModesLoad, ApplModesLoadSuccess} from 'invoker-transport';
import {IStateStore}                                           from '../../ap-interface';
import IApplicationMode = Data.Common.IApplicationMode;
import {ApSignalrService}                                      from '../../ap-core/services/ap-signalr.service';

interface IApplModesStore extends IStateStore<IApplicationMode> {
}

export enum ApplicationModesEnum {
  Constant = 'Global__RateConst',
  Variable = 'Global__RateVariable'
}

@Injectable({providedIn: 'root'})
export class ApplModesStore extends Store<IApplModesStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(ApplModesLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadApplModes(): void {
    this.DispatchBackend(new ApplModesLoad([]));
  }
}

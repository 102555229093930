import {NgModule}                                 from '@angular/core';
import {GetStatisticKeysPipe}                     from './pipes/get-statistic-keys.pipe';
import {GetRoundNumericPipe}                      from './pipes/get-round-numeric.pipe';
import {GetBooleanPipe}                           from './pipes/get-boolean.pipe';
import {GetElementOxidCalculatePipe}              from './pipes/get-element-oxid-calculate.pipe';
import {AreaUnitConverterPipe}                    from './pipes/area-unit-converter.pipe';
import {GetSoilSampleFieldClassColorPipe}         from './pipes/get-soil-sample-field-class-color.pipe';
import {GetSoilSampleClassNamePipe}               from './pipes/get-soil-sample-class-names.pipe';
import {ApMultiSelectFilterComponent}             from './components/ap-multi-select-filter.component';
import {DropDownListModule, MultiSelectModule}    from '@progress/kendo-angular-dropdowns';
import {ApStatusFilterComponent}                  from './components/ap-status-filter.component';
import {ButtonGroupModule, ButtonModule}          from '@progress/kendo-angular-buttons';
import {CommonModule}                             from '@angular/common';
import {GetKendoDateFormatPipe}                   from './pipes/get-kendo-date-format.pipe';
import {ApFieldSvgComponent}                      from './components/ap-svg.component';
import {ApPlantProtectionTypemappingPipe}         from './pipes/ap-plant-protection-typemapping.pipe';
import {GetSoilSampleFieldColorPipe}              from './pipes/get-soil-sample-field-color.pipe';
import {GetReplacePipe}                           from './pipes/get-replace.pipe';
import {GetMaxValuePipe}                          from './pipes/get-max-value.pipe';
import {GetMinValuePipe}                          from './pipes/get-min-value.pipe';
import {SafePipe}                                 from './pipes/ap-safe-domsanitizer.pipe';
import {ConvertBase64ToPngPipe}                   from './pipes/ap-convert-base64-to-png.pipe';
import {GetCountryCodeFlagPipe}                   from './pipes/get-countrycode-flag.pipe';
import {GridDragAndDropDirective}                 from './directives/grid-drag-and-drop.directive';
import {ExecFunctionPipe}                         from './pipes/exec-function.pipe';
import {DomObserverDirective}                     from './directives/dom-observer.directive';
import {ApDateRangeFilterComponent}               from './components/ap-date-range-filter.component';
import {DateRangeModule, MultiViewCalendarModule} from '@progress/kendo-angular-dateinputs';
import {ApCheckboxFilterComponent}                from './components/ap-checkbox-filter.component';
import {ApNumberFilterComponent}                  from './components/ap-number-filter.component';
import {ApTranslationModule}                      from '../translation/ap-translation.module';
import {NumericTextBoxModule}                     from '@progress/kendo-angular-inputs';
import {ApResponsiveTextModule}                   from '../ap-responsive-text/ap-responsive-text.module';
import {ReactiveFormsModule}                      from '@angular/forms';
import {ApClassColorFilterComponent}              from './components/ap-class-color-filter.component';
import {SoilGroupFormatPipe}                      from './pipes/soil-group-format.pipe';
import {ApNumberListFilterComponent}              from './components/ap-number-list-filter.component';
import {FactorPipe}                               from './pipes/ap-factor.pipe';
import {ApObjectFilterComponent}                  from './components/ap-object-filter.component';
import {ApStatusSwitchFilterComponent}            from './components/ap-status-switch-filter.component';
import {ApIconModule}                             from '../icons/ap-icon.module';
import {ApCustomFormatPipe}                       from './pipes/ap-custom-format.pipe';

@NgModule({
  declarations: [
    GetStatisticKeysPipe,
    GetRoundNumericPipe,
    GetBooleanPipe,
    GetElementOxidCalculatePipe,
    AreaUnitConverterPipe,
    GetSoilSampleFieldClassColorPipe,
    GetSoilSampleClassNamePipe,
    ApMultiSelectFilterComponent,
    ApStatusFilterComponent,
    GetKendoDateFormatPipe,
    ApFieldSvgComponent,
    ApPlantProtectionTypemappingPipe,
    GetSoilSampleFieldColorPipe,
    GetReplacePipe,
    GetMaxValuePipe,
    GetMinValuePipe,
    ConvertBase64ToPngPipe,
    SafePipe,
    GetCountryCodeFlagPipe,
    GridDragAndDropDirective,
    ExecFunctionPipe,
    DomObserverDirective,
    ApDateRangeFilterComponent,
    ApCheckboxFilterComponent,
    ApNumberFilterComponent,
    ApClassColorFilterComponent,
    ApNumberListFilterComponent,
    SoilGroupFormatPipe,
    FactorPipe,
    ApObjectFilterComponent,
    ApStatusSwitchFilterComponent,
    ApCustomFormatPipe
  ],
  exports: [
    GetStatisticKeysPipe,
    GetRoundNumericPipe,
    GetBooleanPipe,
    GetElementOxidCalculatePipe,
    AreaUnitConverterPipe,
    GetSoilSampleFieldClassColorPipe,
    GetSoilSampleClassNamePipe,
    ApMultiSelectFilterComponent,
    ApStatusFilterComponent,
    GetKendoDateFormatPipe,
    ApFieldSvgComponent,
    ApPlantProtectionTypemappingPipe,
    GetSoilSampleFieldColorPipe,
    GetReplacePipe,
    GetMaxValuePipe,
    GetMinValuePipe,
    ConvertBase64ToPngPipe,
    SafePipe,
    GetCountryCodeFlagPipe,
    GridDragAndDropDirective,
    ExecFunctionPipe,
    DomObserverDirective,
    ApDateRangeFilterComponent,
    ApCheckboxFilterComponent,
    ApNumberFilterComponent,
    ApClassColorFilterComponent,
    ApNumberListFilterComponent,
    FactorPipe,
    ApObjectFilterComponent,
    ApStatusSwitchFilterComponent,
    ApCustomFormatPipe
  ],
  imports: [
    MultiSelectModule,
    ButtonGroupModule,
    ButtonModule,
    CommonModule,
    MultiViewCalendarModule,
    DateRangeModule,
    DropDownListModule,
    ApTranslationModule,
    NumericTextBoxModule,
    ApResponsiveTextModule,
    ReactiveFormsModule,
    ApIconModule,
  ],
  providers: [
    GetRoundNumericPipe,
    GetKendoDateFormatPipe,
    GetElementOxidCalculatePipe,
    GetBooleanPipe,
    ApPlantProtectionTypemappingPipe,
    AreaUnitConverterPipe,
    GetSoilSampleFieldClassColorPipe,
    GetSoilSampleClassNamePipe,
    GetSoilSampleFieldColorPipe,
    GetReplacePipe,
    ConvertBase64ToPngPipe,
    SafePipe,
    GetCountryCodeFlagPipe,
    SoilGroupFormatPipe,
    FactorPipe,
    ApCustomFormatPipe
  ],
})
export class ApUtilsModule {
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import ISoilSamplePlanning = Data.IpsPlanning.ISoilSamplePlanning;

export enum ApSoilSamplePlanningActions {
  LOAD_SOIL_SAMPLE_PLANNING = '[soilsampleplanning] load',
  LOAD_SOIL_SAMPLE_PLANNING_SUCCESS = '[soilsampleplanning] load success',
  LOAD_SOIL_SAMPLE_PLANNING_FAIL = '[soilsampleplanning] load fail',
  SAVE_SOIL_SAMPLE_PLANNING = '[soilsampleplanning] save',
  SAVE_SOIL_SAMPLE_PLANNING_SUCCESS = '[soilsampleplanning] save success',
  EXPORT_SHAPE_SOIL_SAMPLE_PLANNING = '[soilsampleplanning] exportshape',
  EXPORT_SHAPE_SOILSAMPLE_PLANNING_SUCCESS = '[soilsampleplanning] exportshape success',
  EXPORT_SHAPE_SOILSAMPLE_PLANNING_FAIL = '[soilsampleplanning] exportshape fail',
}

export class LoadSoilSamplePlanning implements IAction {
  readonly type = ApSoilSamplePlanningActions.LOAD_SOIL_SAMPLE_PLANNING;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoadSoilSamplePlanningSuccess implements IAction {
  readonly type = ApSoilSamplePlanningActions.LOAD_SOIL_SAMPLE_PLANNING_SUCCESS;

  constructor(public payload: ISoilSamplePlanning[]) {
  }
}

export class SaveSoilSamplePlanning implements IAction {
  readonly type = ApSoilSamplePlanningActions.SAVE_SOIL_SAMPLE_PLANNING;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SaveSoilSamplePlanningSaveSuccess implements IAction {
  readonly type = ApSoilSamplePlanningActions.SAVE_SOIL_SAMPLE_PLANNING_SUCCESS;

  constructor(public payload: ISoilSamplePlanning[]) {
  }
}

export class ExportShapeSoilSamplePlanning implements IAction {
  readonly type = ApSoilSamplePlanningActions.EXPORT_SHAPE_SOIL_SAMPLE_PLANNING;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ExportShapeSoilSamplePlanningSuccess implements IAction {
  readonly type = ApSoilSamplePlanningActions.EXPORT_SHAPE_SOILSAMPLE_PLANNING_SUCCESS;

  constructor(public payload: ArrayBuffer) {
  }
}

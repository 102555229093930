import {Injectable} from '@angular/core';
import IUser = Data.Authentication.IUser;
import UserRoleType = Data.Authentication.Enums.UserRoleType;

/**
 * Service for handling user's RoleId and its assignment to different
 * agriport roles and permissions
 */
@Injectable({
  providedIn: 'root'
})
export class ApRoleTypeService {

  /**
   * Checks if the given user has AT LEAST the default (roleId=0) user permission/role
   * Every agriport user should have this permission
   * @param user the user to check
   */
  public static hasDefaultRole(user: IUser): boolean {
    return this.hasRole(user, UserRoleType.Default);
  }

  /**
   * Checks if the given user has AT LEAST the AGRICON-Backoffice (roldeId=1) user permission/role
   * Every agriport user should have this permission
   * @param user the user to check
   */
  public static hasAgriconRole(user: IUser): boolean {
    return this.hasRole(user, UserRoleType.Admin);
  }
  /**
   * Checks if the given user has AT LEAST the DEVELOPER (roldeId=10) user permission/role
   * @param user the user to check
   */
  public static hasDeveloperRole(user: IUser): boolean {
    return this.hasRole(user, UserRoleType.Developer);
  }

  /**
   * Generic method to check if the given user has AT LEAST the given role / permission
   * @param user the user to be checked
   * @param role the role to verify
   * @private
   */
  private static hasRole(user: IUser, role: UserRoleType): boolean {
    return user?.RoleType >= role;
  }
}

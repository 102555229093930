import IFieldCropRotation = Data.FieldManagement.IFieldCropRotation;
import ICropRotationCell = Data.FieldManagement.ICropRotationCell;
import IFieldCropRotationHistory = Data.FieldManagement.IFieldCropRotationHistory;
import {IAction, IApInvokerArguments} from '../../interfaces';
import ICropRotationAttributes = Data.FieldManagement.ICropRotationAttributes;

export enum ApCropRotationActions {
  LOAD = '[crop rotation] load',
  SUCCESS = '[crop rotation] load success',
  FAIL = '[crop rotation] load fail',
  LOAD_PAST = '[crop rotation] load past',
  LOAD_PAST_SUCCESS = '[crop rotation] load past success',
  MARK_FOR_SAVING = '[crop rotation] mark for saving',
  UNMARK_FROM_SAVING = '[crop rotation] unmark from saving',
  PROCESS_CELL = '[crop rotation] process cell',
  PROCESS_CELL_SUCCESS = '[crop rotation] process cell success',
  PROCESS_CELL_FAIL = '[crop rotation] process cell fail',
  LOAD_CROP_ROTATION_ATTRIBUTES= '[crop rotation] attributes load',
  LOAD_CROP_ROTATION_ATTRIBUTES_SUCCESS= '[crop rotation] attributes load success',
  LOAD_CROP_ROTATION_ATTRIBUTES_TRIGGER= '[crop rotation] attributes load trigger'
}

export class ApCropRotationLoad implements IAction {
  readonly type = ApCropRotationActions.LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ApCropRotationLoadSuccess implements IAction {
  readonly type = ApCropRotationActions.SUCCESS;

  constructor(public payload: IFieldCropRotation[]) {
  }
}

export class ApCropRotationLoadFail implements IAction {
  readonly type = ApCropRotationActions.FAIL;
}

export class ApCropRotationPastLoad implements IAction {
  readonly type = ApCropRotationActions.LOAD_PAST;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ApCropPastRotationLoadSuccess implements IAction {
  readonly type = ApCropRotationActions.LOAD_PAST_SUCCESS;

  constructor(public payload: IFieldCropRotationHistory[]) {
  }
}

export class ApCropRotationProcessCell implements IAction {
  readonly type = ApCropRotationActions.PROCESS_CELL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ApCropRotationProcessCellSuccess implements IAction {
  readonly type = ApCropRotationActions.PROCESS_CELL_SUCCESS;

  constructor(public payload: ICropRotationCell[]) {
  }
}

export class ApCropRotationProcessCellFail implements IAction {
  readonly type = ApCropRotationActions.PROCESS_CELL_FAIL;
}

export class ApCropRotationMarkForSaving implements IAction {
  readonly type = ApCropRotationActions.MARK_FOR_SAVING;

  constructor(public payload: ICropRotationCell[]) {
  }
}

export class ApCropRotationUnMarkForSaving implements IAction {
  readonly type = ApCropRotationActions.UNMARK_FROM_SAVING;

  constructor(public payload: ICropRotationCell[]) {
  }
}
export class ApCropRotationAttributesLoad implements IAction {
  readonly type = ApCropRotationActions.LOAD_CROP_ROTATION_ATTRIBUTES;
  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class ApCropRotationAttributesLoadSuccess implements IAction {
  readonly type = ApCropRotationActions.LOAD_CROP_ROTATION_ATTRIBUTES_SUCCESS;

  constructor(public payload: ICropRotationAttributes[]) {
  }
}

export class ApCropRotationAttributesTrigger implements IAction {
  readonly type = ApCropRotationActions.LOAD_CROP_ROTATION_ATTRIBUTES_TRIGGER;

  constructor(public payload: ICropRotationAttributes[]) {
  }
}

<div [formGroup]="form">
  <ng-container [ngSwitch]="config.controlType">
    <ng-container *ngSwitchCase="ApDynformsControltype.Checkbox">

    </ng-container>
    <label *ngSwitchDefault
           [attr.for]="config.key"
           [id]="config.key + '_label'">
      <ap-responsive-text [text]="asyncTranslation ? (config.label | translateAsync | async) : (config.label | translate)" [infoText]="config.infoText"></ap-responsive-text>
    </label>
  </ng-container>
  <div [ngSwitch]="config.controlType">
    <div *ngSwitchCase="ApDynformsControltype.Placeholder" [id]="config.key | sanitizeId:parentKey:parentIndex"></div>
    <div *ngSwitchCase="ApDynformsControltype.Template"
         [class]="config.cssClass">
      <ng-container [ngTemplateOutlet]="config.value"></ng-container>
    </div>
    <div *ngSwitchCase="ApDynformsControltype.Button"
         [class]="config.cssClass">
      <button kendoButton
              [disabled]="config.disabledButton$ | async"
              [type]="'button'"
              [class]="'k-button k-primary button-important'"
              (click)="config.callback?.emit($event)">
        <ap-responsive-text [key]="config.value"></ap-responsive-text>
      </button>
    </div>
    <ng-container *ngSwitchCase="ApDynformsControltype.Textbox">
      <input *ngIf="config.type !== ApDynformsConfigTextboxType.Password"
             kendoTextBox
             [formControl]="form.get(config.key)"
             [class]="'ap-textbox-container ap-dynform-control ' + config.cssClass"
             [placeholder]="asyncTranslation ? (config.label | translateAsync | async) : (config.label | translate)"
             [id]="config.key | sanitizeId:parentKey:parentIndex"
             [type]="config.type"
             [autocomplete]="config.autocomplete"
             [class.dark-bg]="darkMode">
      <kendo-textbox *ngIf="config.type === ApDynformsConfigTextboxType.Password"
                     [id]="config.key | sanitizeId:parentKey:parentIndex"
                     [formControl]="form.get(config.key)"
                     [title]="config.key | sanitizeId:parentKey:parentIndex"
                     [class]="'ap-password-textbox' + config.cssClass"
                     [placeholder]="asyncTranslation ? (config.label | translateAsync | async) : (config.label | translate)"
                     [class.dark-bg]="darkMode"
      >
        <ng-template kendoTextBoxSuffixTemplate>
          <button
            kendoButton
            type="button"
            look="clear"
            [icon]="'preview'"
            (click)="togglePasswordVisibility()"
          ></button>
        </ng-template>
      </kendo-textbox>

    </ng-container>
    <textarea *ngSwitchCase="ApDynformsControltype.Textarea"
              kendoTextArea
              [formControlName]="config.key"
              [class]="'ap-textbox-container ap-dynform-control ' + config.cssClass"
              [rows]="config.rows"
              [placeholder]="config.label | translate"
              [id]="config.key | sanitizeId:parentKey:parentIndex"
              [class.dark-bg]="darkMode"
    ></textarea>

    <kendo-numerictextbox *ngSwitchCase="ApDynformsControltype.NumericTextbox"
                          [autoCorrect]="config.autoCorrect"
                          [formControl]="form.get(config.key)"
                          [format]="config.format"
                          [spinners]="config.spinners && form.get(config.key).enabled"
                          [step]="config.step | async"
                          [class]="'ap-dropdown-bright ap-dynform-control ' + config.cssClass"
                          [class.k-disabled]="disabled$ | async"
                          [class.ap-readonly]="config.readonly"
                          [min]="config.min | async"
                          [max]="config.max | async"
                          [class.dark-bg]="darkMode"
                          [placeholder]="config.placeholder | translate"
                          [decimals]="config.decimals"
                          [id]="config.key | sanitizeId:parentKey:parentIndex"
                          [readonly]="config.readonly">
    </kendo-numerictextbox>

    <kendo-combobox *ngSwitchCase="ApDynformsControltype.ComboBox"
                    [id]="config.key | sanitizeId:parentKey:parentIndex"
                    [formControl]="form.get(config.key)"
                    [class]="'ap-dropdown-bright ap-dynform-control ' + config.cssClass"
                    [class.k-disabled]="disabled$ | async"
                    [textField]="config.textField"
                    [valueField]="config.valueField"
                    [valuePrimitive]="config.valuePrimitive"
                    [clearButton]="config.clearButton"
                    [itemDisabled]="config.disabledOptions | async"
                    [data]="config.options | async"
                    [allowCustom]="false"
                    [filterable]="true"
                    [class.dark-bg]="darkMode"
                    [loading]="config.loading$ | async"
                    (filterChange)="config.filterChange($event)"
                    [placeholder]="config.placeholder | translate"
                    (blur)="config.filterChange('')">
      <ng-template *ngIf="config.itemTemplate !== undefined" kendoComboBoxItemTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="config.itemTemplate" [ngTemplateOutletContext]="dataItem"></ng-container>
      </ng-template>
      <ng-template *ngIf="config.noDataTemplate !== undefined" kendoComboBoxNoDataTemplate>
        <ng-container [ngTemplateOutlet]="config.noDataTemplate"></ng-container>
      </ng-template>
      <ng-template *ngIf="config.noDataString !== undefined" kendoComboBoxNoDataTemplate>
        <ng-container *ngIf="config.noDataString !== undefined">{{config.noDataString | translate}}</ng-container>
      </ng-template>
      <ng-template *ngIf="config.groupByField !== undefined" kendoComboBoxItemTemplate let-dataItem>
        <div class="ap-combobox-grouped-item">{{dataItem[config.textField]}}</div>
      </ng-template>
    </kendo-combobox>

    <kendo-multiselect *ngSwitchCase="ApDynformsControltype.MultiSelect"
                       [id]="config.key | sanitizeId:parentKey:parentIndex"
                       [formControl]="form.get(config.key)"
                       [class]="'ap-dropdown-bright ap-dynform-control ' + config.cssClass"
                       [class.k-disabled]="disabled$ | async"
                       [textField]="config.textField"
                       [valueField]="config.valueField"
                       [data]="config.options | async"
                       [valuePrimitive]="config.valuePrimitive"
                       [filterable]="true"
                       [clearButton]="config.clearButton"
                       [class.dark-bg]="darkMode"
                       [loading]="config.loading$ | async"
                       (filterChange)="config.filterChange($event)"
                       [placeholder]="config.placeholder | translate"
                       (blur)="config.filterChange('')"
                       [allowCustom]="false">
      <ng-template *ngIf="config.itemTemplate !== undefined" kendoMultiSelectItemTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="config.itemTemplate" [ngTemplateOutletContext]="dataItem"></ng-container>
      </ng-template>
      <ng-template *ngIf="config.noDataTemplate !== undefined" kendoComboBoxNoDataTemplate>
        <ng-container [ngTemplateOutlet]="config.noDataTemplate"></ng-container>
      </ng-template>
      <ng-template *ngIf="config.noDataString !== undefined" kendoComboBoxNoDataTemplate>
        <ng-container *ngIf="config.noDataString !== undefined">{{config.noDataString | translate}}</ng-container>
      </ng-template>
    </kendo-multiselect>

    <kendo-datepicker *ngSwitchCase="ApDynformsControltype.DatePicker"
                      [id]="config.key | sanitizeId:parentKey:parentIndex"
                      [formControl]="form.get(config.key)"
                      [min]="config.minDate$ | async"
                      [max]="config.maxDate$ | async"
                      [class]="'ap-dropdown-bright ap-dynform-control ' + config.cssClass"
                      [class.dark-bg]="darkMode"
                      [class.k-disabled]="disabled$ | async"
                      [format]="(config.format ? config.format : language.DateFormat) | getKendoDateFormat"
                      [formatPlaceholder]="{
                                   year: 'Global__Year' | translate,
                                   month: 'Global__Month' | translate,
                                   day: 'Global__Day' | translate,
                                   hour: ' ', minute: ' ', second: ' '
                                 }"
                      [activeView]="config.bottomView"
                      [topView]="config.topView"
                      [bottomView]="config.bottomView">
      <ng-template kendoCalendarHeaderTitleTemplate let-title let-date="date">
        {{((GetCalendarItem | execFunction:calendarView$:date:'MMMM YYYY') | async)}}
      </ng-template>
      <ng-template kendoCalendarNavigationItemTemplate let-title let-date="date" let-view="activeView">
        {{((GetCalendarItem | execFunction:calendarView$:date:'MMM') | async)}}
      </ng-template>
      <ng-template *ngIf="config.dateCellDyer !== undefined" kendoCalendarCellTemplate let-date>
        <span [class.ap-datepicker-day-active]="config.dateCellDyer(date)"
              [class.ap-datepicker-day-inactive]="!config.dateCellDyer(date)">
          {{date.getDate()}}
        </span>
      </ng-template>
      <kendo-datepicker-messages
        [today]="'Global__Today' | translate"
      ></kendo-datepicker-messages>
    </kendo-datepicker>

    <!--<kendo-fileselect *ngSwitchCase="ApDynformsControltype.FileSelect"
                      [id]="htmlId | sanitizeId"
    >
    </kendo-fileselect>!-->

    <kendo-upload *ngSwitchCase="ApDynformsControltype.Upload"
                  [id]="config.key | sanitizeId:parentKey:parentIndex"
                  [formControl]="form.get(config.key)"
                  [autoUpload]="config.autoUpload"
                  [saveUrl]="config.uploadSaveUrl"
                  [removeUrl]="config.uploadRemoveUrl"
                  [withCredentials]="config.withCredentials"
                  [class.k-disabled]="config.disabled"
                  (uploadProgress)="config.uploadProgressEventHandler($event)"
                  kendoUploadDropzone
                  [style]="config.style"
                  (success)="config.successEventHandler($event)"
                  (upload)="config.uploadEventHandler($event)"
                  [restrictions]="config.restrictions"
                  [multiple]="config.multi">
      <kendo-upload-messages
        [dropFilesHere]="config.dropFilesHereText | translate"
        [select]="config.selectText | translate"
        [uploadSelectedFiles]="config.uploadSelectedFilesText | translate"
        [clearSelectedFiles]="config.clearSelectedFilesText | translate">
      </kendo-upload-messages>
    </kendo-upload>

    <kendo-timepicker *ngSwitchCase="ApDynformsControltype.Timepicker"
                      [id]="config.key | sanitizeId:parentKey:parentIndex"
                      [formControl]="form.get(config.key)"
                      [class]="'ap-dropdown-bright ap-dynform-control ' + config.cssClass"
                      [class.k-disabled]="disabled$ | async"
                      [min]="config.minDate$ | async"
                      [max]="config.maxDate$ | async"
                      [format]="config.format ? config.format : language.TimeFormat"
                      [nowButton]="config.nowButton"
                      [steps]="config.steps"
                      [title]="config.title">
    </kendo-timepicker>

    <div *ngSwitchCase="ApDynformsControltype.LabelIcon">
      <div *ngIf="config.rightToLeft">
        <label class="k-form-field" style="height: 44px;">
          <div style="display:grid;">
            <label *ngIf="config.label1 !== ''">{{config.label1 | translate}}: {{config.text1}}</label>
            <label *ngIf="config.label2 !== ''">{{config.label2 | translate}}: <a
              href="mailto:{{config.text2}}">{{config.text2}}</a></label>
          </div>
          <div style="width: 10%; float:left; min-width: 50px">
            <img [src]=config.src [width]="config.srcWidth" [height]="config.srcHeight">
          </div>
        </label>
      </div>
      <div *ngIf="!config.rightToLeft">
        <label class="k-form-field" style="height: 44px;">
          <div style="width: 15%; float:left; min-width: 50px">
            <img [src]=config.src [width]="config.srcWidth" [height]="config.srcHeight">
          </div>
          <div style="display:grid;">
            <label *ngIf="config.label1 !== ''">{{config.label1 | translate}}: {{config.text1}}</label>
            <label *ngIf="config.label2 !== ''">{{config.label2 | translate}}: <a
              href="mailto:{{config.text2}}">{{config.text2}}</a></label>
          </div>
        </label>
      </div>
    </div>

    <div *ngSwitchCase="ApDynformsControltype.Checkbox" [class]="config.cssClass" style="display: flex;">
      <input type="checkbox"
             [formControl]="form.get(config.key)"
             [class]="'k-checkbox'"
             [id]="config.key | sanitizeId:parentKey:parentIndex"
             style="margin-top: 1px; min-width: 16px;"/>
      <label [attr.for]="config.key | sanitizeId:parentKey:parentIndex" [class]="'k-checkbox-label'">
        <ap-responsive-text [key]="config.label"></ap-responsive-text>
      </label>
      <ap-responsive-text [infoText]="config.infoText"></ap-responsive-text>
    </div>

    <div *ngSwitchCase="ApDynformsControltype.Array">
      <ng-container [formArrayName]="config.key">
        <ng-container></ng-container>
      </ng-container>
    </div>

    <div *ngSwitchCase="ApDynformsControltype.PasswordStrength">
      <pwd-strength *ngIf="config.value$"
                    [control]="form.get(config.key)"
                    [id]="config.key | sanitizeId:parentKey:parentIndex"
                    [value]="(config.value$ | async)?.toString()"
                    (labelChanged)="currentLabelChanged($event)"
      ></pwd-strength>
      <pwd-strength *ngIf="!config.value$"
                    [control]="form.get(config.key)"
                    [id]="config.key | sanitizeId:parentKey:parentIndex"
                    [value]="config.value?.toString()"
                    (labelChanged)="currentLabelChanged($event)"
      ></pwd-strength>
    </div>
  </div>
  <div [class]="'ap-error'" [id]="config.key + '_error'">
    <ap-responsive-text *ngIf="getErrors().length > 0" [text]="getErrors()[0] | translateAsync | async"></ap-responsive-text>
  </div>
</div>

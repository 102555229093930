import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'idIn'
})
export class IdInPipe implements PipeTransform {
  transform(looking: string[] | number[], dataItem: any, idKey: string): boolean {
    return looking ? looking.Any(l => l.toString() === dataItem[idKey].toString()) : false;
  }
}

import {AfterContentInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SelectableSettings}                                    from '@progress/kendo-angular-grid';
import {
  BarDataType,
  ChartBarItemValue,
  CropTableData,
  FieldCropRotationPriority,
  FieldCropRotationPrioritySelection,
  FieldsCropRotationStatsData,
  IFieldsCropRotationAggregated,
  IFieldsCropRotationTableData
}                                                              from './fields-crop-rotation-stats.types';
import {
  ApDynGridPropertyColumnConfig
}                                                              from '../../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApTranslationService
}                                                              from '../../../../ap-utils/service/ap-translation.service';
import {GetRoundNumericPipe}                                   from '../../../../ap-utils';
import {
  ApDynGridColumnConfigBase
}                                                              from '../../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  GetRoundNumericService
}                                                              from '../../../../ap-utils/service/get-round-numeric.service';
import {CampaignYearStore}                                     from '../../../../stores/login/campaignyear.store';
import {SettingsStore}                                         from '../../../../stores/base-data/settings.store';
import {BehaviorSubject, Subscription}                         from 'rxjs';
import {Border}                                                from '@progress/kendo-angular-charts';
import {map}                                                   from 'rxjs/operators';
import {StatisticChartAreaService}                             from '../../../services/statistic-chart-area.service';

@Component({
  selector: 'ap-fields-crop-rotation-stats',
  templateUrl: 'fields-crop-rotation-stats.component.html',
  styleUrls: ['fields-crop-rotation-stats.component.scss']
})
export class ApFieldsCropRotationStatsComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() public data: FieldsCropRotationStatsData[] = [];
  @Input() public title = '';
  @Input() public emptyMessage = '';

  public dropdownSelectionItems: FieldCropRotationPrioritySelection[] = [];
  public selectedCropPriority = new BehaviorSubject<FieldCropRotationPrioritySelection>(null);
  public cropStatsColumns: ApDynGridColumnConfigBase[] = [];
  public cropTableData$ = new BehaviorSubject<IFieldsCropRotationTableData[]>([]);
  public chartBarData$ = new BehaviorSubject<ChartBarItemValue[]>([]);
  public cropYears: number[] = [];
  public chartBarRange: { min: number, max: number } = {min: 0, max: 100};
  public gridSelectableSettings: SelectableSettings = {
    enabled: false,
    checkboxOnly: false,
    mode: 'single',
    cell: false,
    drag: false
  };
  public backgroundColor = '#ffffffbf';
  public strokeColor = '#212529';
  public border: Border = {
    color: this.strokeColor,
    dashType: 'solid',
    width: 1
  };
  public decimalsAfterDigit = 0;
  public chartWidth = this.statisticChartAreaService.chartAreaWidth.pipe(
    map(v => v - 100),
  );
  public chartHeight = this.statisticChartAreaService.chartAreaHeight.pipe(
    map(v => v > 599 ? v / 2 : 600),
  );

  private _subscriptions: Subscription[] = [];

  constructor(private translationService: ApTranslationService,
              private roundService: GetRoundNumericService,
              private roundNumericPipe: GetRoundNumericPipe,
              private settingsStore: SettingsStore,
              private statisticChartAreaService: StatisticChartAreaService,
              private campaignYearStore: CampaignYearStore) {
  }

  public ngOnInit(): void {
    this.decimalsAfterDigit = this.settingsStore.FirstSetting.DigitsAfterDecimalPoint;
    this.cropYears = this._getCropYears(this.data);
    this.dropdownSelectionItems = this._getItemsForSelectionDropdown();
    this.selectedCropPriority.next(this.dropdownSelectionItems[0]);
    this.cropStatsColumns = this._getCropStatsColumns();
  }

  public ngAfterContentInit(): void {
    this._subscriptions.push(this.selectedCropPriority.subscribe(selectedCrop => {
      const dataResult = this._getCropTableData(this.data, selectedCrop.Value);
      this.cropTableData$.next(dataResult.FieldCropsData);
      this.chartBarData$.next(dataResult.ChartBarData);
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions?.forEach(x => x?.unsubscribe());
  }

  public getTooltipText(categoryName: string, value: BarDataType): string {
    const roundedPercent = this.roundService.roundAsNumber(value.Percent, 2);
    const roundedValue = this.roundService.roundAsNumber(value.AreaHa, this.decimalsAfterDigit);
    return `${categoryName}: <br> ${roundedValue} ha (${roundedPercent} %)`;
  }

  private _getCropTableData(data: FieldsCropRotationStatsData[], selectedPriority: FieldCropRotationPriority): CropTableData {
    const fieldsCropRotationTableData: IFieldsCropRotationTableData[] = [];
    const chartBarItemValues: ChartBarItemValue[] = [];
    const startYear = this.cropYears[0];
    const endYear = this.cropYears[this.cropYears.length - 1];
    data.filter(x => x.CropPriority === selectedPriority).forEach((item) => {
      this.cropYears.forEach(year => {
        const yearIndex = year - startYear;
        item.CropTypesPerYear[year].forEach((crop: IFieldsCropRotationAggregated) => {
          let cropTypeIndex = fieldsCropRotationTableData.findIndex(x => x.CropTypeName === crop.CropTypeName);
          if (cropTypeIndex < 0) {
            fieldsCropRotationTableData.push({
              CropTypeName: crop.CropTypeName,
              CropYearArea: {}
            });
            cropTypeIndex = fieldsCropRotationTableData.length - 1;
          }
          const areaHa = crop?.AreaHa ?? 0;
          if (!fieldsCropRotationTableData[cropTypeIndex].CropYearArea[year]) {
            fieldsCropRotationTableData[cropTypeIndex].CropYearArea[year] = areaHa;
          } else {
            fieldsCropRotationTableData[cropTypeIndex].CropYearArea[year] += areaHa;
          }
          let chartBarValueIndex = chartBarItemValues.findIndex(x => x.Name === crop.CropTypeName);
          if (chartBarValueIndex < 0) {
            const charBarObject: ChartBarItemValue = {
              Name: crop.CropTypeName,
              Color: crop.Color,
              Data: []
            };
            for (let index = 0; index <= (endYear - startYear); index++) {
              charBarObject.Data[index] = {AreaHa: 0, Percent: 0};
            }
            chartBarItemValues.push(charBarObject);
            chartBarValueIndex = chartBarItemValues.length - 1;
          }
          chartBarItemValues[chartBarValueIndex].Data[yearIndex].AreaHa += areaHa;
        });
        const totalArea = chartBarItemValues.reduce((accumulator, currentValue) => accumulator + currentValue.Data[yearIndex].AreaHa, 0);
        chartBarItemValues.forEach(chartBarItem => {
          chartBarItem.Data[yearIndex].Percent = (chartBarItem.Data[yearIndex].AreaHa / totalArea) * 100;
        });
      });
    });
    const currentYear = this.campaignYearStore.SelectedCampaignYear.Year;
    const sortedResultMap = fieldsCropRotationTableData.sort((first, second) => {
      const firstAreaHa = first.CropYearArea[currentYear] ?? 0;
      const secondAreaHa = second.CropYearArea[currentYear] ?? 0;
      return secondAreaHa - firstAreaHa;
    });
    return {FieldCropsData: sortedResultMap, ChartBarData: chartBarItemValues};
  }

  private _getCropStatsColumns(): ApDynGridColumnConfigBase[] {
    const columns: ApDynGridColumnConfigBase[] = [
      new ApDynGridPropertyColumnConfig({
        title: 'Global__Crop',
        field: 'CropTypeName',
        headerStyle: {'text-align': 'left'},
        style: {'font-weight': 600, 'background-color': 'silver'},
        cssClass: 'left',
        width: 100,
        filterable: false,
        sortable: false
      })
    ];
    this.cropYears.forEach(year => {
      columns.push(new ApDynGridPropertyColumnConfig({
        title: `${year}`,
        field: `CropYearArea.${year}`,
        headerStyle: {'text-align': 'center'},
        cssClass: 'right',
        width: 60,
        filterable: false,
        sortable: false,
        pipes: [{
          pipe: this.roundNumericPipe,
          args: [
            this.decimalsAfterDigit,
            GetRoundNumericPipe.NullAndZeroToEmptyConfiguration
          ]
        }]
      }));
    });
    return columns;
  }

  private _getItemsForSelectionDropdown(): FieldCropRotationPrioritySelection[] {
    return [
      {
        Text: this.translationService.translate('MainCrop'),
        Value: FieldCropRotationPriority.MainCrop
      },
      {
        Text: this.translationService.translate('SecondCrop'),
        Value: FieldCropRotationPriority.SecondCrop
      }
    ];
  }

  private _getCropYears(data: FieldsCropRotationStatsData[]): number[] {
    return !data || data.length <= 0
      ? []
      : Object.keys(data[0].CropTypesPerYear).map(x => Number(x)).sort((a, b) => a - b);
  }
}

import {AfterViewInit, Component, Input, ViewEncapsulation} from '@angular/core';
import {FilterService}                                      from '@progress/kendo-angular-grid';
import {SelectionRange}                                     from '@progress/kendo-angular-dateinputs';
import {CompositeFilterDescriptor, FilterDescriptor}        from '@progress/kendo-data-query';
import {ApDateService}                                      from '../../ap-core/services/ap-date-service';
import {ApCalendarView}                                     from '../enums/ApCalendarView';

@Component({
  selector: 'ap-date-range-filter',
  templateUrl: './ap-date-range-filter.component.html',
  styleUrls: ['ap-date-range-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApDateRangeFilterComponent implements AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public items: any[] = [];
  @Input() public key: string;
  @Input() public view: ApCalendarView = ApCalendarView.DAY;

  public range = {min: undefined, max: undefined};
  public selected: SelectionRange = {start: undefined, end: undefined};

  constructor(private dateService: ApDateService) {
  }

  ngAfterViewInit(): void {
    if (!this.items || this.items?.length === 0) {
      return;
    }
    this.range = this.items.map((item) => item[this.key] as Date).filter((i) => !!i && isNaN(new Date(i).getDate())).reduce((range, item) => {
      const itemDate = new Date(item);
      // create proper javascript date to be able to compare dates!
      return {
        max: new Date(range.max > itemDate ? range.max : itemDate),
        min: new Date(range.min < itemDate ? range.min : itemDate)
      };
    }, this.range);
    if (this.currentFilter.filters.length) {
      this.selected.start = this.currentFilter.filters.reduce((value: Date, f: FilterDescriptor) => value < f.value ? value : f.value, undefined);
      this.selected.end = this.currentFilter.filters.reduce((value: Date, f: FilterDescriptor) => value > f.value ? value : f.value, undefined);
    } else {
      // default selection is the end of the range
      // => otherwise the calendar might appear empty if the range is completly in the past
      this.selected.start = this.range.max;
      this.selected.start = this.range.max;
    }
  }

  change(event: SelectionRange): void {
    if (event.start === undefined || event.end === undefined) {
      return;
    }
    const start = this._getStart(event.start);
    const end = this._getEnd(event.end);
    this.filterService.filter({
      filters: [
        {
          field: this.key,
          operator: 'gte',
          value: start
        },
        {
          field: this.key,
          operator: 'lte',
          value: end
        }
      ],
      logic: 'and'
    });
  }

  private _getStart(date: Date): Date {
    let start = date;
    switch (this.view) {
      case ApCalendarView.DAY:
        start = new Date(start.setHours(0, 0, 0, 0));
        break;
      case ApCalendarView.MONTH:
        start = new Date(start.getFullYear(), start.getMonth(), 1);
        break;
    }
    return this.dateService.toFarmDate(start).toDate();
  }

  private _getEnd(date: Date): Date {
    let end = date;
    switch (this.view) {
      case ApCalendarView.DAY:
        end = new Date(end.setHours(23, 59, 59, 999));
        break;
      case ApCalendarView.MONTH:
        end = new Date(end.getFullYear(), end.getMonth() + 1, 0);
        end = new Date(end.setHours(23, 59, 59, 999));
        break;
    }
    return this.dateService.toFarmDate(end).toDate();
  }
}

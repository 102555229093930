import {Pipe, PipeTransform} from '@angular/core';
import {replace}             from 'lodash';

@Pipe({
  name: 'getReplace'
})
export class GetReplacePipe implements PipeTransform {

  transform(value: string, find: string, withReplace: string): string {
    let result = value;
    do {
      result = replace(result, find, withReplace);
    }
    while (result.includes(find));
    return result;
  }
}

import {TimeSpan}            from 'ts-tooling';
import ITimeSpan = System.ITimeSpan;

export class ApTimeSpanUtil {
  public static getEmptyTimeSpan(): ITimeSpan {
    return {
      Days: 0,
      Hours: 0,
      Minutes: 0,
      Seconds: 0,
      Milliseconds: 0,
      TotalDays: 0,
      TotalHours: 0,
      TotalMinutes: 0,
      TotalSeconds: 0,
      TotalMilliseconds: 0,
      Ticks: 0,
      Microseconds: 0,
      TotalMicroseconds: 0,
      Nanoseconds: 0,
      TotalNanoseconds: 0
    };
  }

  public static getTimeSpanFromDate(date: Date, useEmptyOnNull: boolean = false): ITimeSpan | null {
    if (!date) {
      return useEmptyOnNull ? this.getEmptyTimeSpan() : null;
    }
    const timeSpan = TimeSpan.FromJavaScriptDate(date, true);
    return {
      Days: timeSpan.Day,
      Hours: timeSpan.Hour,
      Minutes: timeSpan.Minute,
      Seconds: timeSpan.Second,
      Milliseconds: timeSpan.Millisecond,
      TotalDays: timeSpan.TotalDays,
      TotalHours: timeSpan.TotalHours,
      TotalMinutes: timeSpan.TotalMinutes,
      TotalSeconds: timeSpan.TotalMilliseconds,
      TotalMilliseconds: timeSpan.TotalMilliseconds,
      Ticks: timeSpan.TotalMilliseconds * 10000,
      Microseconds: 0,
      TotalMicroseconds: 0,
      Nanoseconds: 0,
      TotalNanoseconds: 0
    };
  }
}

import {IStateStore} from '../../ap-interface/interfaces/store';
import {Injectable}  from '@angular/core';
import {Store}       from '../index';
import {
  ApCustomTypes,
  AttachmentDriverAssignmentGet,
  AttachmentDriverAssignmentGetSuccess,
  AttachmentInstrumentAssignmentGet,
  AttachmentInstrumentAssignmentGetSuccess,
  FleetActionAttachmentCreate,
  FleetActionAttachmentLoad,
  FleetActionAttachmentLoadSuccess,
  FleetActionAttachmentSave,
  FleetActionAttachmentSaveSuccess,
  FleetActionsAttachmentLoadByModified,
  FleetActionsAttachmentLoadByModifiedSuccess,
  NetTypes
}                    from 'invoker-transport';
import {ApLodash}    from '../../ap-utils';
import IDateRangeAssigment = Data.BaseData.IDateRangeAssigment;
import IFleetActionAttachment = Data.DocuContext.FleetManagement.IFleetActionAttachment;
import IGuid = System.IGuid;
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

interface IFleetActionAttachmentStore extends IStateStore<IFleetActionAttachment> {
  driverDateRangeAssignments: IDateRangeAssigment[];
  instrumentDateRageAssignments: IDateRangeAssigment[];
  filter: (IFleetActionAttachment) => boolean;
}

@Injectable({providedIn: 'root'})
export class FleetActionAttachmentStore extends Store<IFleetActionAttachmentStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      driverDateRangeAssignments: [],
      instrumentDateRageAssignments: [],
      filter: () => true,
    });

    backend.registerObservable(AttachmentDriverAssignmentGetSuccess).subscribe(d => {
      super.Mutate(s => s.driverDateRangeAssignments, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(AttachmentInstrumentAssignmentGetSuccess).subscribe(d => {
      super.Mutate(s => s.instrumentDateRageAssignments, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FleetActionAttachmentLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FleetActionsAttachmentLoadByModifiedSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(FleetActionAttachmentSaveSuccess).subscribe(d => {
      super.Mutate(s => s.data,
        () => ApLodash.replaceItem(super.Listen(s => s.data).getValue(), d.Data,
          f => d.Data.map((i) => i.Id).indexOf(f.Id) !== -1));
    });
  }

  public getDriverAssignment(): void {
    super.DispatchBackend(new AttachmentDriverAssignmentGet([]));
  }

  public getInstrumentAssignments(): void {
    super.DispatchBackend(new AttachmentInstrumentAssignmentGet([]));
  }

  public loadFleetActionAttachments(fieldId: IGuid | IGuid[]): void {
    if (!(fieldId instanceof Array)) {
      fieldId = [fieldId];
    }
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    super.DispatchBackend(new FleetActionAttachmentLoad([
      {Name: 'fieldIds', Type: NetTypes.GUID + '?[]', Value: fieldId},
    ]));
  }

  public loadFleetActionAttachmentsByModified(modified: boolean): void {
    super.SetLoadState();
    super.DispatchBackend(new FleetActionsAttachmentLoadByModified([
      {Name: 'modified', Type: NetTypes.BOOL, Value: modified}
    ]));
  }

  public removeModifiedFleetActionAttachments(): void {
    const filter = (a: IFleetActionAttachment) => !a.Attachment.IsModified;
    super.Mutate(s => s.filter, () => filter);
    super.Mutate(s => s.data,
      () => super.Listen(s => s.data).getValue().filter(filter));
  }

  public removeUnmodifiedFleetActionAttachments(): void {
    const filter = (a: IFleetActionAttachment) => a.Attachment.IsModified;
    super.Mutate(s => s.filter, () => filter);
    super.Mutate(s => s.data,
      () => super.Listen(s => s.data).getValue().filter(filter));
  }

  public saveFleetActionAttachments(data: IFleetActionAttachment | IFleetActionAttachment[]): void {
    if (!(data instanceof Array)) {
      data = [data];
    }
    data.forEach((d) => {
      if (d.Attachment && typeof d.Attachment.Comment !== typeof '') {
        d.Attachment.Comment = '';
      }
    });
    super.DispatchBackend(new FleetActionAttachmentSave([
      {
        Name: 'fleetActionAttachments',
        Type: ApCustomTypes.Data_DocuContext_FleetManagement_FleetActionAttachment + '[]',
        Value: data
      }
    ]));
  }

  public createFleetActionAttachment(data: IFleetActionAttachment): void {
    super.DispatchBackend(new FleetActionAttachmentCreate([
      {
        Name: 'fleetActionAttachment',
        Type: ApCustomTypes.Data_DocuContext_FleetManagement_FleetActionAttachment,
        Value: data
      }
    ]));
  }
}

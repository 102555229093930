import {IAction, IApInvokerArguments} from '../../interfaces';
import ILanguage = Data.Language.ILanguage;

export enum LanguageActions {
  LANGUAGE_LOAD = '[language] load',
  LANGUAGE_LOAD_SUCCESS = '[language] load success',
  LANGUAGE_SELECT = '[language] select',
}

/**
 * load the supported Languages from Gateway
 */
export class LanguageLoad implements IAction {
  readonly type = LanguageActions.LANGUAGE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

/**
 * Language request was ok and has the List of available Languages
 */
export class LanguageLoadSuccess implements IAction {
  readonly type = LanguageActions.LANGUAGE_LOAD_SUCCESS;

  constructor(public payload: ILanguage[]) {
  }
}

/**
 * the User select a Language from List of available Languages
 */
export class LanguageSelect implements IAction {
  readonly type = LanguageActions.LANGUAGE_SELECT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

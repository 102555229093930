import {AfterContentInit, AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators}                                                                from '@angular/forms';
import {BehaviorSubject, Observable, Subscription}                                              from 'rxjs';
import {isDate}                                                                                 from 'lodash';
import {
  ApDynformsConfigFieldset
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigComboBox
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {
  ApDynformsValidator
}                                                                                               from '../../ap-dynforms/ap-dynforms-validator';
import {
  ApDynComponentComponent
}                                                                                               from '../../ap-dyncomponent/ap-dyncomponent.component';
import {
  ApDynGridColumnConfigBase
}                                                                                               from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApDynGridPropertyColumnConfig
}                                                                                               from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridButtonColumnConfig
}                                                                                               from '../../ap-dyngrids/config/ap-dyn-grid-button-column-config';
import {
  APP_CONFIGURATION
}                                                                                               from '../../ap-core/config';
import {
  ApDynGridPagerConfigBase
}                                                                                               from '../../ap-dyngrids/config/ap-dyn-grid-pager-config-base';
import {
  ApDynGridPagerWizardButton,
  ApDynGridPagerWizardConfig,
  ApDynGridPagerWizardSection
}                                                                                               from '../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {
  ApDynformsConfigPlaceholder
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {
  MachineStore
}                                                                                               from '../../stores/docu/machine.store';
import {
  SettingsStore
}                                                                                               from '../../stores/base-data/settings.store';
import {
  MachineBreaksStore
}                                                                                               from '../../stores/docu/machine.breaks.store';
import {
  CampaignYearStore
}                                                                                               from '../../stores/login/campaignyear.store';
import {
  MapViewStore
}                                                                                               from '../../stores/layout/mapview.store';
import {
  GeoJsonStore
}                                                                                               from '../../stores/docu/geojson.store';
import {
  SliceStore
}                                                                                               from '../../stores/docu/slice.store';
import {
  LanguageStore
}                                                                                               from '../../stores/translation/language.store';
import {
  MapStore
}                                                                                               from '../../stores/map/map.store';
import {
  LoginStore
}                                                                                               from '../../stores/login/login.store';
import {
  ApDynformsConfigDateRange
}                                                                                               from '../../ap-dynforms/config/ap-dynforms-config-date-range';
import {delay, distinctUntilChanged, filter, map}                                               from 'rxjs/operators';
import {
  ApFormValidators
}                                                                                               from '../../ap-dynforms/validators/ap-form-validators';
import {ObjectFactory}                                                                          from 'ts-tooling';
import {
  ApDateService
}                                                                                               from '../../ap-core/services/ap-date-service';
import {
  TrackLoaderService
}                                                                                               from '../services/track.loader.service';
import {
  IGridData,
  MapViewMode
}                                                                                               from '../../ap-interface';
import {
  ApDocuUtils
}                                                                                               from '../utils/ap-docu-utils';
import IMachine = Data.DocuContext.Machine.IMachine;
import ISlice = Data.DocuContext.Slice.ISlice;
import ICombinedSlice = Data.DocuContext.Slice.ICombinedSlice;
import ILanguage = Data.Language.ILanguage;

interface ICombinedSliceGridData extends IGridData<ICombinedSlice> {
  From: Date;
  To: Date;
  Duration: string;
  StandstillTime: string;
  MovementTime: string;
}

@Component({
  selector: 'ap-action-lookup',
  template: `
    <ap-dyncomponent [caption]="'Docu__Driving_Lanes'"
                     [headerIcon]="'ap-icon-field-works'"
                     [filterTooltip]="'filterTooltip'"
                     [fieldSets]="fieldsets"
                     [columns]="columns"
                     [items]="items"
                     [loading$]="loading$"
                     [selectable]="{checkboxOnly: true, mode: 'multiple', enabled: true}"
                     [formAsyncValidators]="formValidators"
                     [pager]="pager">
      <div class="ap-form-actions" dynforms.action>
        <button [disabled]="disableNext$ | async"
                id="button_next"
                type="button"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global_Next_With_Arrow' | translate}}
        </button>
      </div>
    </ap-dyncomponent>`,
})
export class ApActionLookupComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) dynComponent: ApDynComponentComponent;
  days: number[];
  dateFrom;
  dateTo;
  minDate: Date = new Date('1900-01-01');
  maxDate: Date = new Date('2099-12-31');
  language: ILanguage;
  columns: ApDynGridColumnConfigBase[] = [];
  fieldsets: ApDynformsConfigFieldset[] = [];
  items: ICombinedSliceGridData[] = [];
  loading$: Observable<boolean>;
  pager: ApDynGridPagerConfigBase;
  onBack = new EventEmitter<any>();
  disableNext$ = new BehaviorSubject<boolean>(true);
  formValidators = [
    new ApDynformsValidator({validator: ApFormValidators.controlEnabled('Date_Start')}),
    new ApDynformsValidator({validator: ApFormValidators.controlEnabled('Date_End')}),
  ];

  private subscriptions: Array<Subscription> = [];
  private formSubscriptions: Array<Subscription> = [];
  private selectedSlices: { id: string, machineId: string, from: string, to: string }[] = [];

  private machine: IMachine;
  private machines = new BehaviorSubject<any[]>([]);
  private formMinDate = new BehaviorSubject<Date>(this.minDate);
  private formMaxDate = new BehaviorSubject<Date>(this.maxDate);
  private mapClick = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private campaignYearStore: CampaignYearStore,
              private loginStore: LoginStore,
              private geoJsonStore: GeoJsonStore,
              private machineBreaksStore: MachineBreaksStore,
              private machinesStore: MachineStore,
              private mapStore: MapStore,
              private mapViewStore: MapViewStore,
              private settingsStore: SettingsStore,
              private sliceStore: SliceStore,
              private languageStore: LanguageStore,
              private dateService: ApDateService,
              private trackLoaderService: TrackLoaderService) {
  }

  ngOnInit(): void {
    this.getDataFromStore();
    this.days = [];
  }

  ngAfterContentInit(): void {
    this.subscribeToEvents();
    this.formBuilder();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.dynComponent.Valid$.pipe(delay(1)).subscribe((valid) => this.disableNext$.next(!valid)));
    this.subscribeToFormControls();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(d => {
      d.unsubscribe();
    });
    this.formSubscriptions.forEach(d => {
      d.unsubscribe();
    });
    this.mapStore.Layers.Clear();
    this.trackLoaderService.clear();
  }

  getDataFromStore(): void {
    this.loading$ = this.sliceStore.Listen(s => s.loading);

    this.subscriptions.push(this.sliceStore.Listen(s => s.combinedSlices).subscribe(slices => {
      this.items = slices.map(slice => {
          return {
            Id: slice.Id,
            SourceItem: slice,
            From: this.dateService.toJsDate(this.dateService.toFarmDateFromUtc(new Date(slice.From))),
            To: this.dateService.toJsDate(this.dateService.toFarmDateFromUtc(new Date(slice.To))),
            Duration: ApDocuUtils.formatDuration(slice.Duration * 1000),
            StandstillTime: ApDocuUtils.formatDuration(slice.StandstillTime * 1000),
            MovementTime: ApDocuUtils.formatDuration(slice.MovementTime * 1000)
          };
        }
      );
    }));

    this.subscriptions.push(this.machinesStore.Listen(s => s.data).subscribe(machines => {
      this.machines.next(machines);
    }));

    this.subscriptions.push(this.machinesStore.Listen(s => s.days).subscribe(d => {
      if (!d) {
        return;
      }
      this.days = [];
      d.forEach(day => {
        this.days.push(new Date(Date.parse(day)).valueOf());
      });
    }));

    this.subscriptions.push(this.loginStore.SelectedFarm$.subscribe(() => {
      this.machine = undefined;
      this.dateFrom = undefined;
      this.dateTo = undefined;
      const form = this.dynComponent.getForm();
      let markUntouched = false;
      if (form && this.fieldsets[0]) {
        const machineControl = form.get(this.fieldsets[0].key);
        if (machineControl) {
          machineControl.setValue(null);
        }
        markUntouched = true;
      }
      if (form && this.fieldsets[2]) {
        const dateStartControl = form.get(`${this.fieldsets[2].key}_Start`);
        const dateEndControl = form.get(`${this.fieldsets[2].key}_End`);
        if (dateStartControl && dateEndControl) {
          dateStartControl.setValue(null);
          dateEndControl.setValue(null);
        }
        markUntouched = true;
      }
      if (markUntouched) {
        form.markAsUntouched();
      }
      if (this.columns.length) {
        this.onBackClick();
      }
    }));

    this.subscriptions.push(this.campaignYearStore.Listen(s => s.selectedYear).subscribe(y => {
      if (y === null) {
        return;
      }
      // reset min/max on dateRangeControl to avoid invalid min/max combinations
      // from previous values (e.g. when user comes from an old camapign year)
      this.formMinDate.next(undefined);
      this.formMaxDate.next(undefined);
      this.minDate = isDate(y.DefaultStart) ? y.DefaultStart : new Date(y.DefaultStart);
      this.maxDate = isDate(y.DefaultEnd) ? y.DefaultEnd : new Date(y.DefaultEnd);
      this.dateFrom = null;
      this.dateTo = null;
      if (this.minDate && this.maxDate && this.maxDate > this.minDate) {
        this.formMinDate.next(this.minDate);
        this.formMaxDate.next(this.maxDate);
      }
      this.loadDayList();
    }));
  }

  subscribeToEvents(): void {
    this.mapClick.subscribe(item => {
      this._showTrackOnMap(item);
    });
    this.onBack.subscribe(() => {
      this.onBackClick();
    });
    this.subscriptions.Add(this.mapStore.Layers.TrackLayer.onExtentLoadFinish.subscribe(() => {
      this.mapStore.Layers.TrackLayer.zoomToExtent();
    }));
  }

  subscribeToFormControls(): void {
    this.formSubscriptions.forEach(d => {
      d.unsubscribe();
    });
    this.formSubscriptions.push(this.dynComponent.FormValues$.pipe(
      map((values) => values['Machine'] as IMachine),
      distinctUntilChanged((a, b) => ObjectFactory.Equal(a, b)),
      filter((machine) => !!machine),
    ).subscribe(
      (machine) => {
        this.machine = machine;
        this.loadDayList();
      }
    ));
  }

  formBuilder(): void {
    this.languageStore.SelectedLanguage$.subscribe(language => {
      this.fieldsets = [
        new ApDynformsConfigFieldset({
          key: 'actionLookup',
          config: [
            new ApDynformsConfigComboBox({
              key: 'Machine',
              options: this.machines,
              textField: 'NameWithSign',
              valuePrimitive: false,
              valueField: 'Id',
              value: this.machine || null,
              label: 'Docu_Pages__Engine',
              validators: [
                new ApDynformsValidator({validator: Validators.required, errorKey: ''}),
              ]
            }),
            new ApDynformsConfigPlaceholder(),
            new ApDynformsConfigDateRange({
              key: 'Date',
              disabled$: this.machinesStore.Listen(s => s.days).pipe(map((d) => d.length === 0)),
              format: language.DateFormat,
              dateCellDyer: this.isInUse.bind(this),
              value: {start: this.dateFrom, end: this.dateTo},
              labelStart: 'Docu_Ini__From',
              labelEnd: 'Docu_Ini__To',
              minDate: this.formMinDate,
              maxDate: this.formMaxDate,
              dependsOn: [
                'Machine',
              ],
              validators: [
                new ApDynformsValidator({validator: Validators.required, errorKey: ''}),
              ]
            })
          ]
        })
      ];
    });
  }

  gridBuilder(): void {
    this.languageStore.SelectedLanguage$.subscribe(language => {
      this.columns = [
        new ApDynGridPropertyColumnConfig(
          {
            title: 'Docu_Ini__From',
            field: 'From',
            format: `${language.DateFormat} HH:mm`,
            width: 150
          }),
        new ApDynGridPropertyColumnConfig(
          {
            title: 'Docu_Ini__To',
            field: 'To',
            format: `${language.DateFormat} HH:mm`,
            width: 150
          }),
        new ApDynGridPropertyColumnConfig(
          {
            title: 'Docu_Data__Action',
            field: 'MovementTime',
            width: 100
          }),
        new ApDynGridPropertyColumnConfig(
          {
            title: 'Docu_MachineAction__StandStill',
            field: 'StandstillTime',
            width: 100
          }),
        new ApDynGridPropertyColumnConfig(
          {
            title: 'Docu_Ini__Engine',
            field: 'SourceItem.Attachment.Machine.NameWithSign'
          }),
        new ApDynGridButtonColumnConfig(
          {
            callback: this.mapClick,
            imageUrl: APP_CONFIGURATION.ButtonZoomToMapImage,
            selected: this._isItemSelected.bind(this),
            tooltip: 'Settings__Msg_Loading_Track_Button'
          })
      ];
    });

    this.pager = new ApDynGridPagerWizardConfig(
      new ApDynGridPagerWizardButton('Global_Back_With_Arrow', this.onBack),
      new ApDynGridPagerWizardSection(),
      new ApDynGridPagerWizardSection()
    );
  }

  loadDayList(): void {
    if (this.machine) {
      this.machinesStore.getDaysInUse(this.machine, this.minDate, this.maxDate);
    }
  }

  isInUse(day: Date): boolean {
    return this.days.indexOf(day.valueOf()) !== -1;
  }

  onSubmitClick(): void {
    const value = this.dynComponent.getForm().value;
    this.dateFrom = value['Date_Start'];
    this.dateTo = value['Date_End'];
    const from = this.dateService.toUtc(this.dateFrom).format('YYYY-MM-DD HH:mm:ss');
    const to = this.dateService.toUtc(this.dateTo).add({
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 999
    }).format('YYYY-MM-DD HH:mm:ss');

    this.sliceStore.loadSlicesCombinedByMachineAndDate(this.machine, from, to);
    this.gridBuilder();
    this.fieldsets = [];
  }

  onBackClick(): void {
    this.items?.forEach(_ => {
      // remove slices from map:
      if (this.trackLoaderService.selectedSlices.Find(s => s?.id === _?.Id?.toString())) {
        this._showTrackOnMap(_); // removes slice when it is displayed already
      }
    });
    this.columns = [];
    this.formBuilder();
    this.mapStore.Layers.TrackLayer.clear();
    setTimeout(() => this.subscribeToFormControls(), 1);
  }

  private _isItemSelected(dataItem: any): boolean {
    const slice = dataItem['SourceItem'] as ISlice;
    return !!this.trackLoaderService.selectedSlices.Find(_ => _?.id === slice?.Id?.toString());
  }

  private _showTrackOnMap(dataItem): void {
    this.trackLoaderService.loadBySlice(dataItem['SourceItem'] as ISlice);
    if (this.mapViewStore.getMapViewMode() === MapViewMode.HIDE && this.trackLoaderService.selectedSlices.Any()) {
      this.mapViewStore.showMapView();
    }
  }
}

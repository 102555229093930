import {AfterViewInit, Component, Input}             from '@angular/core';
import {CompositeFilterDescriptor, FilterDescriptor} from '@progress/kendo-data-query';
import {FilterService}                               from '@progress/kendo-angular-grid';
import {get}                                         from 'lodash';
import {ApColumnState}                               from '../../ap-interface/enums/ap-column-state.enum';

@Component({
  selector: 'ap-status-filter',
  templateUrl: './ap-status-filter.component.html'
})
export class ApStatusFilterComponent implements AfterViewInit {
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public items;
  @Input() public key;

  public data: any[];
  public value: any;

  protected readonly ApColumnState = ApColumnState;

  public ngAfterViewInit(): void {
    if (!this.items || this.items?.length === 0) {
      return;
    }
    this.data = Array.from(new Set(this.items.map(i => get(i, this.key)))).sort();
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
  }

  public onChange(values: any[]): void {
    this.filterService.filter({
      filters: values.map(value => ({
        field: this.key,
        operator: value === null ? 'isnull' : 'eq',
        value
      })),
      logic: 'or'
    });
  }
}

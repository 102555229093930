import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GetRoundNumericService}                     from '../../../../ap-utils/service/get-round-numeric.service';
import {CssValueParser}                             from '../../../../ap-core/utils/ap-css-value.parser';
import {DottedChart}                                from './ap-dotted-histogram-chart.types';
import {DomSanitizer, SafeHtml}                     from '@angular/platform-browser';

@Component({
  selector: 'ap-dotted-histogram-chart',
  templateUrl: 'ap-dotted-histogram-chart.component.html',
  styleUrls: ['ap-dotted-histogram-chart.component.scss']
})
export class ApDottedHistogramChartComponent implements OnChanges {
  @Input() data: DottedChart;

  public basicStyles = {
    StrokeColor: '#212529',
    TooltipBackground: '#ffffffbf',
    TooltipTextColor: '#000'
  };
  public markers = {
    size: 8,
    background: CssValueParser.hexToRGBA(CssValueParser.getCssVariable('--accent-color'), 0.5),
    border: {width: 0}
  };
  public selectedMarker = {
    size: 8,
    background: 'red',
    border: {width: 0}
  };

  constructor(private sanitizer: DomSanitizer,
              private getRoundNumericService: GetRoundNumericService) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this._updateData(changes['data'].currentValue);
    }
  }

  public getSafeHtml(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  private _updateData(data: DottedChart | undefined): void {
    const updateData = this._generateDefaultObject(data);
    this.markers.background = updateData.Styles.MarkerColor;
    this.selectedMarker.background = updateData.Styles.PointerColor;
    this.data = updateData;
  }

  private _generateDefaultObject(data: DottedChart | undefined): DottedChart {
    const charData = data?.Data ?? [];
    const getXValues = charData.map(x => x.X);
    const getYValues = charData.map(x => x.Y);
    return {
      ChartTitle: data?.ChartTitle ?? '',
      AxisX: {
        Title: data?.AxisX?.Title ?? '',
        Min: data?.AxisX?.Min ?? this._getMin(getXValues),
        Max: data?.AxisX?.Max ?? this._getMax(getXValues)
      },
      AxisY: {
        Title: data?.AxisY?.Title ?? '',
        Min: data?.AxisY?.Min ?? this._getMin(getYValues),
        Max: data?.AxisY?.Max ?? this._getMax(getYValues)
      },
      Styles: {
        Transition: data?.Styles?.Transition ?? false,
        Zoomable: data?.Styles?.Zoomable ?? false,
        Pannable: data?.Styles?.Pannable ?? false,
        MarkerColor: data?.Styles?.MarkerColor ?? this.markers.background,
        PointerColor: data?.Styles?.PointerColor ?? this.selectedMarker.background,
        ShowTooltip: data?.Styles?.ShowTooltip ?? false
      },
      Data: charData
    };
  }

  private _getMin(values: number[] | undefined): number | undefined {
    if (!values || values.length === 0) {
      return undefined;
    }
    const minValue = values.reduce((result, value) => Math.min(result, value), values[0]);
    const percent = minValue * 0.1;
    return this.getRoundNumericService.roundAsNumber(minValue - percent, 0);
  }

  private _getMax(values: number[] | undefined): number | undefined {
    if (!values || values.length === 0) {
      return undefined;
    }
    const maxValue = values.reduce((result, value) => Math.max(result, value), values[0]);
    const percent = maxValue * 0.1;
    return this.getRoundNumericService.roundAsNumber(maxValue + percent, 0);
  }
}

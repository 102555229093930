import {Component} from '@angular/core';

const CONTENT = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.63 123.98">
  <g>
    <path d="M114.74,100.2c-4.25,0-7.7,3.9-7.7,8.71s3.45,8.7,7.7,8.7,7.69-3.9,7.69-8.7-3.45-8.71-7.69-8.71m0-10.44c-9.3,0-16.84,8.45-16.84,18.88s7.54,18.89,16.84,18.89,16.83-8.46,16.83-18.89S124,89.76,114.74,89.76" transform="translate(0 -26.45)"/>
    <path d="M76.19,60.94h0c-2.73,0-5,2.51-5,5.57v.08A5.52,5.52,0,0,1,73.65,66h6.23a5.32,5.32,0,0,1,1.27.15,5.27,5.27,0,0,0-5-5.23" transform="translate(0 -26.45)"/>
    <path d="M107.35,79.24l-15.19,3a1.36,1.36,0,0,0-1,1.57l.29,1.78L81.7,87.53a4.29,4.29,0,0,0-.36-1l-.55-1.06a3.49,3.49,0,0,0-3.49-2c-5.4.58-19.66.65-27.24.64L48.45,88c-.08.17-.15.34-.23.49h36.6a17.31,17.31,0,0,1,12.7-.37l11.13-2.2v2.57h5.74V86.83a12.86,12.86,0,0,1-7-7.59" transform="translate(0 -26.45)"/>
    <path d="M71.37,82l-2,4.76a3.8,3.8,0,0,1-1.27,1.65h-4L29.34,67.3l3.87-3.62a3.25,3.25,0,0,1,3.58-.7L69.35,76.46a3.78,3.78,0,0,1,2.11,2.29A4.43,4.43,0,0,1,71.37,82" transform="translate(0 -26.45)"/>
    <path d="M117.3,86.73c0,.14,0,.27,0,.41s0,.24,0,.36a17.43,17.43,0,0,1,10.66.9,7.47,7.47,0,0,0,.11-1.26,6.66,6.66,0,0,0-.33-2.11q-5.26.63-10.44,1.7" transform="translate(0 -26.45)"/>
    <path d="M164.61,84.67c0,.32-.05.65-.05,1a10,10,0,0,0,.41,2.81H180a9.84,9.84,0,0,0,.31-1.43,13.17,13.17,0,0,1-1.64-.22,123.93,123.93,0,0,0-14.1-2.13" transform="translate(0 -26.45)"/>
    <path d="M122.22,27.22c-.62.08-1.33.17-2.1.29A7.3,7.3,0,0,1,122,30.4l.51-.08c2.68-.34,5.4-.52,8.1-.62,4.74-.21,9.55-.13,14.18,0,7.13.22,14.31.69,21.41,1.44,2.44.26,4.9.54,7.32,1l1.29.22c-10.54-5.54-37.78-7-52.61-5.1" transform="translate(0 -26.45)"/>
    <path d="M190.42,71.28l-4.51-24.73a1.36,1.36,0,0,0-.34-.69l-9.29-10.29c-1.19-1.32-36.39-4.84-53.77-2.45v.06c0,4.07-2.94,7.36-6.57,7.36a6,6,0,0,1-3.2-.93l-4,35.7c-.59,5.23,6.61,10.61,11.75,9.4a134.77,134.77,0,0,1,30.63-3.37,137,137,0,0,1,28,2.79,8.82,8.82,0,0,0,8.69-3.06,11.49,11.49,0,0,0,2.58-9.79" transform="translate(0 -26.45)" class="hoverFill"/>
    <path d="M115.94,28.53a4.43,4.43,0,0,0-4.14,4.65,4.17,4.17,0,1,0,8.29,0,4.43,4.43,0,0,0-4.15-4.65" transform="translate(0 -26.45)" class="hoverFill"/>
    <path d="M198.42,104.22h-7.88l-.25-1.48h-7.53l4.53,6.14h11.13a1.3,1.3,0,0,0,1.21-1.36v-2a1.29,1.29,0,0,0-1.21-1.35" transform="translate(0 -26.45)"/>
    <path d="M39.36,91.16h-5a1.29,1.29,0,0,0-1.21,1.36v6.15A1.29,1.29,0,0,0,34.41,100h7.52a7.07,7.07,0,0,1-2.57-5.55Z" transform="translate(0 -26.45)"/>
    <path d="M103.44,91.16H65a7.34,7.34,0,0,1,.65,3.09v.23A7,7,0,0,1,63.05,100h34a21.33,21.33,0,0,1,6.36-8.87" transform="translate(0 -26.45)"/>
    <path d="M191.89,91.16H125.94A21.33,21.33,0,0,1,132.3,100h59.59a1.3,1.3,0,0,0,1.21-1.36V92.52a1.3,1.3,0,0,0-1.21-1.36" transform="translate(0 -26.45)"/>
    <path d="M44.38,101.17l-2.62,4.22-3.63,5.85V112a8,8,0,0,1,5.5,7.91,9.14,9.14,0,0,1-.53,3.08h5.46a10.2,10.2,0,0,1-.91-4.24c0-5.14,3.72-9.31,8.3-9.31a7.58,7.58,0,0,1,4,1.13v-9.26H45.41a5.07,5.07,0,0,1-1-.1" transform="translate(0 -26.45)"/>
    <path d="M56,111.29c-3.65,0-6.6,3.32-6.6,7.41s2.95,7.42,6.6,7.42c3.31,0,6-2.73,6.53-6.29l-5.72,3a2.32,2.32,0,0,1-3.21-1.32l-1.29-3.12a2.86,2.86,0,0,1,1.17-3.6L58.77,112a5.9,5.9,0,0,0-2.82-.71" transform="translate(0 -26.45)"/>
    <path d="M67.81,117a8.21,8.21,0,0,0-.5,2.83c0,4.08,3,7.39,6.58,7.39a5.82,5.82,0,0,0,2.39-.51,5.79,5.79,0,0,1-.37-.61l-4-7.78-3.32-1a4.41,4.41,0,0,1-.78-.32" transform="translate(0 -26.45)"/>
    <path d="M65.29,110.09,53.94,116a1.46,1.46,0,0,0-.59,1.81l1.3,3.12a1.16,1.16,0,0,0,1.6.66l10.39-5.41a6,6,0,0,1-1.51-5.54c.05-.19.1-.37.16-.55" transform="translate(0 -26.45)"/>
    <path d="M76.06,109.64l-5.25-1.58A3.67,3.67,0,0,0,66.3,111a4.23,4.23,0,0,0,2.6,5.06l3.8,1.14L77,125.47A3.48,3.48,0,0,0,82,127a4.44,4.44,0,0,0,1.36-5.65l-5-9.64A4.22,4.22,0,0,0,78,111a3.68,3.68,0,0,0-1.91-1.4" transform="translate(0 -26.45)"/>
    <path d="M45.4,83.59l-2.12,1.73a4.12,4.12,0,0,0-1.5,3.29v5.87a3.88,3.88,0,0,0,3.62,4.08H59.58a3.87,3.87,0,0,0,3.62-4.08v-.23a4.06,4.06,0,0,0-1.89-3.57l-12-7.37a3.25,3.25,0,0,0-3.87.28" transform="translate(0 -26.45)"/>
    <path d="M36.36,113.61c-3.07,0-5.57,2.8-5.57,6.25s2.5,6.26,5.57,6.26,5.58-2.81,5.58-6.26-2.5-6.25-5.58-6.25" transform="translate(0 -26.45)"/>
    <path d="M73.65,68.74h6.23a3.17,3.17,0,0,1,1.76.51l12.19,7.59a4.1,4.1,0,0,1,1.86,3.56h0a5.36,5.36,0,0,1,0,.7l-1.73.07c-2.84.09-5.68.14-8.52.18q-5.65.08-11.33.07a7.45,7.45,0,0,0-.37-3.7,6.25,6.25,0,0,0-3.5-3.81L70,73.85v-1a3.87,3.87,0,0,1,3.63-4.07" transform="translate(0 -26.45)"/>
    <path d="M14.93,126a3.18,3.18,0,1,0-3.18-3.17A3.17,3.17,0,0,0,14.93,126" transform="translate(0 -26.45)" class="hoverFill"/>
    <path d="M114.62,117.27c4,0,7.29-3.79,7.29-8.45s-3.26-8.44-7.29-8.44-7.28,3.77-7.28,8.44,3.26,8.45,7.28,8.45" transform="translate(0 -26.45)"/>
  </g>
  <rect x="8.4" y="104.94" width="183.78" height="3.58"/>
  <rect x="8.4" y="104.94" width="183.78" height="3.58"/>
  <rect x="17.22" y="141.84" width="27.79" height="3.59" transform="translate(-30.49 -16.24) rotate(-12.56)"/>
  <g>
    <rect x="17.22" y="141.84" width="27.8" height="3.58" transform="translate(-30.33 -16.32) rotate(-12.49)"/>
  </g>
  <rect x="91.15" y="142.35" width="27.79" height="3.59" transform="translate(-28.83 -0.15) rotate(-12.56)"/>
  <g>
    <rect x="91.15" y="142.35" width="27.8" height="3.58" transform="translate(-28.69 -0.32) rotate(-12.49)"/>
  </g>
  <rect x="58.12" y="142.86" width="27.79" height="3.59" transform="translate(-29.73 -7.32) rotate(-12.56)"/>
  <g>
    <rect x="58.12" y="142.86" width="27.8" height="3.58" transform="translate(-29.58 -7.45) rotate(-12.49)"/>
  </g>
  <rect x="126.21" y="143.48" width="27.8" height="3.59" transform="translate(-28.1 7.29) rotate(-12.49)"/>
  <g>
    <rect x="126.21" y="143.48" width="27.8" height="3.58" transform="translate(-28.1 7.29) rotate(-12.49)"/>
  </g>
  <rect x="164.32" y="142.6" width="27.79" height="3.59" transform="translate(-27.13 15.77) rotate(-12.56)"/>
  <g>
    <rect x="164.32" y="142.61" width="27.8" height="3.58" transform="matrix(0.98, -0.22, 0.22, 0.98, -27.01, 15.51)"/>
    <path d="M26.68,126a3.18,3.18,0,1,0-3.18-3.17A3.17,3.17,0,0,0,26.68,126" transform="translate(0 -26.45)" class="hoverFill"/>
    <path d="M3.18,126A3.18,3.18,0,1,0,0,122.86,3.17,3.17,0,0,0,3.18,126" transform="translate(0 -26.45)" class="hoverFill"/>
  </g>
</svg>
`;

@Component({
  selector: 'ap-icon-seeding',
  template: CONTENT
})
export class ApIconSeedingComponent {
}

import { Pipe, PipeTransform } from '@angular/core';
import {ApDynGridColumnConfigBase} from '../config/ap-dyn-grid-column-config-base';
import {CenterCellService} from '../services/center-cell.service';

@Pipe({
  name: 'dynamicCell'
})
export class DynamicCellPipe implements PipeTransform {

  constructor(private centerCellService: CenterCellService) {
  }

  transform(config: ApDynGridColumnConfigBase, dataItem: any): unknown {
    let result = this.centerCellService.getCellCssClass(config.cssClass);
    if (config.dynCssClass != null) {
      result += config.dynCssClass(dataItem);
    }
    return result;
  }

}

import {Pipe, PipeTransform}        from '@angular/core';
import {ApDynGridDetailsGridConfig} from '../../config/details/ap-dyn-grid-details-grid-config';
import {ApDynGridDetailsBaseConfig} from '../../config/details/ap-dyn-grid-details-base-config';

@Pipe({
  name: 'asDetailsGrid'
})
export class AsDetailsGridPipe implements PipeTransform {

  transform(value: ApDynGridDetailsBaseConfig): ApDynGridDetailsGridConfig {
    return value as ApDynGridDetailsGridConfig;
  }

}

import {IStateStore}                                                        from '../../ap-interface';
import {Store}                                                              from '../index';
import {Injectable}                                                         from '@angular/core';
import {FertilizerUseLoad, HibernateModelNames, NetTypes} from 'invoker-transport';
import {FertilizerOrganicStore}                                             from './fertilizer.organic.store';
import {FertilizerInorganicStore}                                           from './fertilizer.inorganic.store';
import {map}                                                                from 'rxjs/operators';
import {combineLatest, Observable}                                          from 'rxjs';
import {
  FertilizerUseLoadSuccess
}                                                                           from '../../../../projects/invoker-transport/src/lib/actions/base-data';
import ISelectableFertilizer = Data.BaseData.ISelectableFertilizer;
import DatabaseNotifyOperation = Agriport.Invoker.Api.Database.DatabaseNotifyOperation;
import IFertilizerOrg = Data.BaseData.IFertilizerOrg;
import IFertilizerInorg = Data.BaseData.IFertilizerInorg;
import {
  ApSignalrService
}                                                                           from '../../ap-core/services/ap-signalr.service';

interface IFertilizerStore extends IStateStore<ISelectableFertilizer> {
  CountInUse: number;
}

@Injectable({providedIn: 'root'})
export class FertilizerStore extends Store<IFertilizerStore> {
  constructor(public backend: ApSignalrService,
              private fertilizerOrganicStore: FertilizerOrganicStore,
              private fertilizerInorganicStore: FertilizerInorganicStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      CountInUse: null
    });
    backend.registerObservable(FertilizerUseLoadSuccess).subscribe(d => {
      super.Mutate(s => s.CountInUse, () => d.Data);
      super.SetLoadFinishState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public loadFertilizerIsInUse(fertilizerId: number): void {
    super.Mutate(s => s.CountInUse, () => null);
    this.DispatchBackend(new FertilizerUseLoad([
      {Name: 'fertilizerId', Type: NetTypes.INT, Value: fertilizerId}
    ]));
  }

  public get Fertilizer(): Array<IFertilizerOrg | IFertilizerInorg> {
    return [
      ...this.fertilizerOrganicStore.Listen(s => s.data).getValue().filter(f => f.Selectable),
      ...this.fertilizerInorganicStore.Listen(s => s.data).getValue().filter(f => f.Selectable)
    ];
  }

  public get Fertilizer$(): Observable<Array<IFertilizerOrg | IFertilizerInorg>> {
    return combineLatest([
      this.fertilizerOrganicStore.Listen(s => s.data),
      this.fertilizerInorganicStore.Listen(s => s.data)
    ]).pipe(
      map(([o, i]) => {
        return [...o.filter(f => f.Selectable), ...i.filter(f => f.Selectable)];
      })
    );
  }

  public get OrganicFertilizer$(): Observable<Array<IFertilizerOrg>> {
    return this.fertilizerOrganicStore.Listen(s => s.data).pipe(
      map((o) => {
        return o.filter(f => f.Selectable);
      })
    );
  }

  public get InorganicFertilizer$(): Observable<Array<IFertilizerInorg>> {
    return this.fertilizerInorganicStore.Listen(s => s.data).pipe(
      map((o) => {
        return o.filter(f => f.Selectable);
      })
    );
  }

  /**
   * override the Update with a custom Update Strategy
   */
  public UpdateSource(operation: DatabaseNotifyOperation, item: any, model: string): void {
    if (operation === DatabaseNotifyOperation.Insert || operation === DatabaseNotifyOperation.Delete) {
      if (model === HibernateModelNames.SELECTABLE_FERTILIZER) {
        super.Mutate(s => s.data, o => {
          if (!item) {
            // fallback case reload the Source
            this.fertilizerOrganicStore.loadFertilizerOrganic();
            this.fertilizerInorganicStore.loadFertilizerInorganic();
            return o;
          }
          this.fertilizerOrganicStore.loadFertilizerOrganic();
          this.fertilizerInorganicStore.loadFertilizerInorganic();
          return o;
        });
      }
    } else {
      this.fertilizerOrganicStore.loadFertilizerOrganic();
      this.fertilizerInorganicStore.loadFertilizerInorganic();
    }
  }

  public getFertilizer(productId: any): IFertilizerOrg | IFertilizerInorg {
    return [...this.fertilizerOrganicStore.Listen(s => s.data).getValue(),
      ...this.fertilizerInorganicStore.Listen(s => s.data).getValue()]
      .Find((d) => d?.Id === productId);
  }

  public getGroup(productId: number): number {
    return this.fertilizerInorganicStore.Listen(s => s.data).getValue().map((f) => f.Id).includes(productId)
      ? 0
      : this.fertilizerOrganicStore.Listen(s => s.data).getValue().map((f) => f.Id).includes(productId) ? 1 : -1;
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {Observable}          from 'rxjs';
import {map}                 from 'rxjs/operators';
import {LoginStore}          from '../../stores/login/login.store';
import IAccessPermissions = Data.AccessPermission.IAccessPermissions;
import IFarmUsers = Data.Authentication.IFarmUsers;
import IGroupMapping = Data.AccessPermission.IGroupMapping;
import {ApRoleTypeService}   from '../../services/common/ap-role-type.service';

@Pipe({
  name: 'getPermission'
})
export class GetPermissionPipe implements PipeTransform {
  constructor(private loginStore: LoginStore) {
  }

  transform(value: any): Observable<boolean> {
    return this.loginStore.UserPermissions$.pipe(
      map(permissionData => {
        if (!permissionData ||
          !Array.isArray(permissionData.Permissions) || !Array.isArray(permissionData.FarmUsers) ||
          !permissionData.User || !permissionData.Farm) {
          return false;
        }
        let retval = false;
        const permissions = permissionData.Permissions;
        const result = permissions
          .Find((_: IAccessPermissions) => _.Feature_Key === value);
        const user = permissionData.User;

        if (ApRoleTypeService.hasAgriconRole(user)) {
          return true;
        }

        const farmUsers = permissionData.FarmUsers;
        const farm = permissionData.Farm;
        const foundFarmUser: IFarmUsers = farmUsers
          .Find((_: IFarmUsers) => _.UserId === user.Id && _.FarmId === farm.Id);
        if (!result || !foundFarmUser || !Array.isArray(foundFarmUser.UsergroupUser)) {
          return retval;
        }
        for (const ug of foundFarmUser.UsergroupUser) {
          const check = result.Groups
            .Find((_: IGroupMapping) => _.usergroup_id === ug.Usergroup.Id);
          if (check) {
            if (check.access_permission) {
              retval = true;
            }
          }
        }
        return retval;
      })
    );
  }
}

import {Injectable} from '@angular/core';
import {Store}      from '../index';
import {IStateStore} from '../../ap-interface';
import IGuid = System.IGuid;
import IFieldBase = Data.FieldManagement.IFieldBase;
import {NetTypes} from 'invoker-transport';
import {
  FieldArchiveLoad,
  FieldArchiveLoadSuccess
}                                   from '../../../../projects/invoker-transport/src/lib/actions/farm/field-archive.action';
import {isDate} from 'moment';
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';

@Injectable({providedIn: 'root'})
export class FieldArchiveStore extends  Store<IStateStore<IFieldBase>> {

  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: []});

    backend.registerObservable(FieldArchiveLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  public loadFieldArchive(campaignYearStart: Date | string): void {
    super.SetLoadState();
    const campaignYearStartIso = isDate(campaignYearStart) ? (campaignYearStart as Date).toISOString() : campaignYearStart;
    super.Mutate(d => d.data, () => []);
    this.DispatchBackend(new FieldArchiveLoad([
      {Name: 'campaignYearStart', Type: NetTypes.DATETIME, Value: campaignYearStartIso},
    ]));
  }

  public getFieldArchiveById(fieldId: string | IGuid): IFieldBase {
    return super.Listen(s => s.data)?.value?.Find(e => e.Id?.toString() === fieldId?.toString());
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IAgriportConstants = Data.Common.IAgriportConstants;

export enum AgriportConstantsActions {
  AgriportConstantsLoad = '[agriport constants] load',
  AgriportConstantsLoadSuccess = '[agriport constants] load success',
}

export class AgriportConstantsLoad implements IAction {
  readonly type = AgriportConstantsActions.AgriportConstantsLoad;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class AgriportConstantsLoadSuccess implements IAction {
  readonly type = AgriportConstantsActions.AgriportConstantsLoadSuccess;

  constructor(public payload: IAgriportConstants[]) {
  }
}

import {Injectable} from '@angular/core';

export enum NetworkStatus {
  Online,
  Offline
}

/**
 * Helper Instance to interact with the Browser Window Element
 */
@Injectable({providedIn: 'root'})
export class ApWindowHelperService {
  /**
   * handle Events on Window resize
   */
  public registerOnResize(method: EventListener): void {
    window.addEventListener('resize', method);
  }

  /**
   * open a Url in a new Tab
   */
  public openSite(url: string): void {
    window.open(url);
  }

  public registerNetworkStatus(networkStatusDelegate: (networkStatus: NetworkStatus) => void): void {
    window.addEventListener('offline', () => {
     networkStatusDelegate(NetworkStatus.Offline);
    });
    window.addEventListener('online', () => {
      networkStatusDelegate(NetworkStatus.Online);
    });
  }

  /**
   * Detects if agriport is running on a touch device
   */
  public isTouchDevice(): boolean {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
  }
}

import {Injectable}         from '@angular/core';
import {SettingsStore}      from '../../stores/base-data/settings.store';
import * as moment          from 'moment-timezone';
import {Moment}             from 'moment';
import {DateTime, TimeSpan} from 'ts-tooling';
import ISettings = Data.BaseData.ISettings;

export enum DateDiffType {
  Days,
  Hours,
  Minutes,
  Seconds
}

export enum DateSort {
  None,
  Ascending,
  Descending
}

/**
 * Service for proper date/timezone handling
 */
@Injectable({providedIn: 'root'})
export class ApDateService {
  constructor(private settingsStore: SettingsStore) {
  }

  public getUtc(): Date {
    return moment.utc().toDate();
  }

  public dateToMoment(date: Date): moment.Moment {
    return moment(date);
  }

  public getDateDiff(firstDate: Date, secondDate: Date, diffType: DateDiffType): number {
    switch (diffType) {
      case DateDiffType.Days:
        return this.dateToMoment(firstDate).diff(secondDate, 'days');
      case DateDiffType.Hours:
        return this.dateToMoment(firstDate).diff(secondDate, 'hours');
      case DateDiffType.Minutes:
        return this.dateToMoment(firstDate).diff(secondDate, 'minutes');
      case DateDiffType.Seconds:
        return this.dateToMoment(firstDate).diff(secondDate, 'seconds');
      default:
        return this.dateToMoment(firstDate).diff(secondDate, 'seconds');
    }
  }

  public getUniqDates(dates: Date[], sort: DateSort = DateSort.None): Date[] | null {
    if (!dates || dates.length <= 0) {
      return null;
    }
    const result = dates.reduce((uniqueDates, currentDate) => {
      if (uniqueDates.length === 0) {
        return [currentDate];
      }
      if (uniqueDates.every(x => Math.abs(this.getDateDiff(x, currentDate, DateDiffType.Seconds)) > 0)) {
        uniqueDates.push(currentDate);
      }
      return uniqueDates;
    }, []);
    if (sort !== DateSort.None) {
      return result.sort((firstDate, secondDate) => {
        if (sort === DateSort.Descending) {
          return secondDate.getTime() - firstDate.getTime();
        }
        return firstDate.getTime() - secondDate.getTime();
      });
    }
    return result;
  }

  public getDateNoon(date: Date, day?: number): Date {
    if (!date) {
      return null;
    }
    const dayNumber: number = day ? day : date.getDate();
    return new Date(date.getFullYear(), date.getMonth(), dayNumber, 12);
  }

  public getDateNoonToFarmTime(date: Date, day?: number): Date {
    const noonDate: Date = this.getDateNoon(date, day);
    if (!noonDate) {
      return null;
    }
    return this.toFarmDate(noonDate).toDate();
  }

  public getDateMidnight(date: Date): Date {
    if (!date) {
      return null;
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  /**
   * Converts the time part of the given date to the end of the day: 23:59:59.999
   */
  public getEndOfDayDate(date: Date): Date {
    if (!date) {
      return null;
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return new Date(date.setHours(23, 59, 59, 999));
  }

  public getUtcDate(date: Date | string, format: string, settings: ISettings): Moment {
    if (date === null) {
      return moment.tz(date, settings.FarmTime).tz('utc');
    } else if (typeof (date) === typeof (' ')) {
      if (format) {
        return moment.tz(date as string, format, settings.FarmTime).tz('utc');
      } else {
        return moment.tz(date, settings.FarmTime).tz('utc');
      }
    } else {
      return moment.tz(date, settings.FarmTime).tz('utc');
    }
  }

  public toJsDate(givenMoment: Moment): Date {
    return new Date(
      givenMoment.year(),
      givenMoment.month(),
      givenMoment.date(),
      givenMoment.hour(),
      givenMoment.minutes(),
      givenMoment.second()
    );
  }

  /**
   * Converts JS-date with any timezone to Moment object with farm's timezone.
   * Most likely a new date object created by kendo or client will have default browser/machine timezone.
   */
  public toFarmDate(date: Date): Moment {
    return moment(date).tz(this.settingsStore.FirstSetting.FarmTime, true);
  }

  /**
   * Converts UTC dates (most likely from backend)
   *  - JS-date or
   *  - date string representation
   *  to a Moment object.
   */
  public toFarmDateFromUtc(date: Date | string, format?: string): Moment {
    const farmTime = this.settingsStore.FirstSetting.FarmTime;
    if (date === null) {
      return moment.utc(moment()).tz(farmTime);
    } else if (typeof (date) === typeof (' ')) {
      if (format) {
        return moment(date as string, format).tz('utc', true).tz(farmTime);
      } else {
        return moment(date).tz('utc', true).tz(farmTime);
      }
    }
    return moment(date).tz('utc', true).tz(farmTime);
  }

  public toFarmDateFromUtcGetJsDate(date: Date | string, format?: string): Date {
    return this.toJsDate(this.toFarmDateFromUtc(date, format));
  }

  public toUtc(date: Date | string, format?: string): Moment {
    const farmTime = this.settingsStore.FirstSetting.FarmTime;
    if (date instanceof Date) {
      date = moment(date).format('YYYY-MM-DD HH:mm:ss');
      format = 'YYYY-MM-DD HH:mm:ss';
    }
    if (format && typeof date === 'string') {
      return moment(date, format).tz(farmTime, true).tz('utc');
    } else {
      return moment(date).tz(farmTime, true).tz('utc');
    }
  }

  /*
   * calculate a TimeSpan between now and the given Date in (UTC!!!!)
   * when the date is a string only ISO Strings are supported (2020-01-01T00:00:00.000)
   */
  public getNowDifferenceUTC(date1: Date | string, date2: Date | string): TimeSpan {
    if (!date1 || !date2) {
      return TimeSpan.FromMilliseconds(0);
    }
    const ds1 = this._parseDateToISOString(date1);
    const ds2 = this._parseDateToISOString(date2);
    if (!ds1 || !ds2) {
      return TimeSpan.FromMilliseconds(0);
    }

    const n = DateTime.FromISOString(ds1);
    const d = DateTime.FromISOString(ds2);
    const ms = Math.abs(n.ToUnixTimestamp() - d.ToUnixTimestamp());
    return TimeSpan.FromMilliseconds(ms);
  }

  public getDateWithTimeFromMilliseconds(timeInMilliseconds: number): Date {
    const timeSpan = TimeSpan.FromMilliseconds(timeInMilliseconds);
    const date = new Date();
    date.setHours(timeSpan.Hour);
    date.setMinutes(timeSpan.Minute);
    date.setSeconds(timeSpan.Second);
    date.setMilliseconds(timeSpan.Millisecond);
    return date;
  }

  private _parseDateToISOString(date: Date | string): string {
    if (typeof date === typeof '') {
      return date as string;
    } else {
      return (date as Date).toISOString();
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {UserGroupStore}      from '../settings/user.group.store';
import {TranslationStore}    from '../translation/translation.store';
import {ObjectFactory}       from 'ts-tooling';

@Pipe({
  name: 'getAvailableUserGroups'
})

export class ApGetAvailableUserGroupsPipe implements PipeTransform {
  public listUserGroupItems: Array<{ text: string, value: number }> = [];

  constructor(private userGroupsStore: UserGroupStore,
              private translationStore: TranslationStore) {
  }

  transform(): Array<{ text: string, value: number }> {
    this.listUserGroupItems = [];
    const userGroups = ObjectFactory.Copy(this.userGroupsStore.getUserGroups());
    userGroups.sort((a, b) => a.Group_Id !== b.Group_Id ? a.Group_Id < b.Group_Id ? -1 : 1 : 0);
    for (const ug of userGroups) {
      this.listUserGroupItems.push({
        text: this.translationStore.FindTranslationForSelectedLanguage(ug.Name),
        value: ug.Id
      });
    }
    return this.listUserGroupItems;
  }
}

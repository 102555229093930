<kendo-chart (selectEnd)="onSelectEnd($event)" [transitions]="transitions"
             [categoryAxis]="[{
              categories: categories, min: min, max: max,
              labels: { step: step }, majorGridLines: { step: step }, majorTicks: { step: step }
            }, {
              categories: categories, name: 'navigatorAxis',
              labels: { step: navigatorStep }, majorGridLines: { step: navigatorStep }, majorTicks: { step: navigatorStep },
              pane: 'navigator', select: { from: min, to: max } }]"
             [valueAxis]="[{}, { name: 'valueNavigatorAxis', pane: 'navigator' }]"
             [panes]="[{}, { name: 'navigator', height: 100 }]">
  <kendo-chart-tooltip>
    <ng-template kendoChartSeriesTooltipTemplate
                 let-value="value" let-category="category" let-series="series">
      {{ category }}: {{ value }}
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-title class="ap-section-header-caption" text="{{title}}" position="top" [color]="strokeColor"></kendo-chart-title>
  <kendo-chart-series>
    <kendo-chart-series-item type="line" [data]="dataArray" [color]="strokeColor">
    </kendo-chart-series-item>
    <kendo-chart-series-item type="area" [data]="dataArray" axis="valueNavigatorAxis" categoryAxis="navigatorAxis" [color]="strokeColor">
    </kendo-chart-series-item>
  </kendo-chart-series>
  <kendo-chart-legend [labels]="{color: strokeColor}"></kendo-chart-legend>
</kendo-chart>

import {Injectable}           from '@angular/core';
import {ApTranslationService} from '../../ap-utils/service/ap-translation.service';
import {CombinedFertilizer}   from '../../types/common-types';
import {ApElementType}        from '../../ap-interface/enums/ap-elements-type.enum';

@Injectable({
  providedIn: 'root'
})
export class FertilizerService {
  constructor(private translationService: ApTranslationService) {
  }

  public getFertilizerProductName(fertilizer: CombinedFertilizer | null): string {
    if (!fertilizer) {
      return this.translationService.translate('Nutrient__PureNutrient');
    }
    return fertilizer?.Prodname ?? '-';
  }

  public getFertilizerUnit(fertilizer: CombinedFertilizer | null, perHa = false): string {
    if (!fertilizer) {
      return this.translationService.translate('Nutrient__PureNutrient');
    }
    const unit = this.translationService.translate(fertilizer.Unit.BaseUnit ? fertilizer.Unit.BaseUnit.Key : fertilizer.Unit.Key);
    if (perHa) {
      return `${unit}/${this.translationService.translate('Global__Unit_ha')}`;
    } else {
      return unit;
    }
  }

  /**
   * Defines the lead nutrient
   * → Element with the highest content
   * 1 -P
   * 2 - K
   * 3 - Mg
   * 4 - CaO
   * @param fertilizer the id of the fertiliser
   */
  public getFertilizerLeadingElement(fertilizer: CombinedFertilizer | null): ApElementType {
    if (!fertilizer) {
      return null;
    }
    const elementContents = [fertilizer.ContentP, fertilizer.ContentK, fertilizer.ContentMg, fertilizer.ContentCaO];
    return elementContents.indexOf(Math.max(...elementContents)) + 1;
  }

  public getFertilizerContent(fertilizer: CombinedFertilizer | null, contentElement: ApElementType, ignoreOrganicCalculation: boolean = false): number {
    let result = 0;
    if (!fertilizer) {
      return result;
    }
    switch (contentElement) {
      case ApElementType.P:
        result = fertilizer.ContentP;
        break;
      case ApElementType.K:
        result = fertilizer.ContentK;
        break;
      case ApElementType.Mg:
        result = fertilizer.ContentMg;
        break;
      case ApElementType.CaO:
        result = fertilizer.ContentCaO;
        break;
      case ApElementType.N:
        result = fertilizer.ContentN;
        break;
    }
    if (ignoreOrganicCalculation) {
      return result;
    }
    if (fertilizer.IsLiquid) {
      const density = fertilizer.Density ?? 1;
      result *= density;
    }
    if (!fertilizer.IsOrganic) {
      result /= 100;
    }
    return result;
  }
}

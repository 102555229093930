/**
 * represent the Main Page Mode
 */
export enum PageMode {
  // Login Screen
  FULLSCREEN = 'FULLSCREEN',
  // Agriport Screen
  HALFSCREEN = 'HALFSCREEN',
  // Loading Screen (before anything is loaded)
  LOADINGSCREEN = 'LOADINGSCREEN',
}

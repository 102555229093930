import OlView           from 'ol/View';
import {MAP_PROJECTION} from './ap-map.settings';

/**
 * Settings of the Map View
 */
export class ApMapViews {
  static olView: OlView = new OlView({
    center: [0, 0],
    zoom: 2,
    projection: MAP_PROJECTION
  });
}

<div [class]="'barlineareacontainer'">
  <kendo-combobox *ngIf="statstic !== null"
                  [class]="'ap-dropdown-bright'"
                  [valuePrimitive]="true"
                  [data]="typesFilter"
                  (valueChange)="selectedValueChanged($event)"
                  [value]="type"
                  [filterable]="true"
                  [allowCustom]="false"
                  (filterChange)="filterChange($event)">
  </kendo-combobox>
  <kendo-chart *ngFor="let key of (data | apBarLineAreaChart | getStatisticKeys)" [zoomable]="true" [pannable]="true" style="height: 895px;">
    <kendo-chart-series>
      <kendo-chart-title [class]="'ap-section-header-caption'" [text]="title" [position]="'top'" [color]="strokeColor"></kendo-chart-title>
      <kendo-chart-legend
        [position]="'bottom'"
        [orientation]="'horizontal'"
        [align]="'start'"
        [background]="'#fcf7f8'"
        [border]="{color:'rgba(0, 0, 0, 0.05)', dashType: 'solid', width: 1}"
        [margin]="10"
        [padding]="15"
        [width]="150"
        [reverse]="true"
        [visible]="true"
        [labels]="{color: strokeColor}"
      >
      </kendo-chart-legend>
      <kendo-chart-category-axis-item [categories]="categories">
        <kendo-chart-category-axis-item-labels [color]="strokeColor"></kendo-chart-category-axis-item-labels>
      </kendo-chart-category-axis-item>
      <kendo-chart-series-item *ngFor="let series of data; let outermost = last;"
                               [type]="type"
                               [name]="series.name"
                               [data]="series.data"
                               [field]="'value'" [categoryField]="'category'"
                               [colorField]="'color'">
        <kendo-chart-series-item-labels
          [position]="'outsideEnd'"
          [content]="labelContent.bind(this)"
          [color]="'#fff'"
          [background]="'none'">
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>

    </kendo-chart-series>
    <div [class]="'overlay'" *ngIf="(data | apBarLineAreaChart | getStatisticKeys).length === 0">
      <div>
        {{'Global__NoData' | translate}}
      </div>
    </div>
  </kendo-chart>
</div>

import {Component} from '@angular/core';

const CONTENT = `
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
  <path d="M167.4,118.7v57.8c0,3.6-2.9,6.5-6.5,6.5h-32c-3.6,0-6.5-2.9-6.5-6.5v-32c0-3.6-2.9-6.5-6.5-6.5h-32
\tc-3.6,0-6.5,2.9-6.5,6.5v32c0,3.6-2.9,6.5-6.5,6.5h-32c-3.6,0-6.5-2.9-6.5-6.5v-57.8c0-2.1,0.9-4,2.6-5.2l61.1-45.8
\tc2.4-1.8,5.5-1.8,7.9,0l61.1,45.8C166.5,114.8,167.4,116.6,167.4,118.7L167.4,118.7z"/>
  <path d="M99.8,58.2l-74.7,58c-4.5,3.5-10.3,4.1-15.4,1.6c-5.1-2.5-8.2-7.5-8.2-13.1v-12c0-4.6,2-8.7,5.7-11.6l83.6-64.9
\tc5.3-4.1,12.6-4.1,17.9,0l83.6,64.9c3.6,2.8,5.7,7,5.7,11.6v12c0,5.6-3.1,10.7-8.2,13.1c-5.1,2.5-11,1.9-15.4-1.6L99.8,58.2
\tL99.8,58.2z"/>
  <path d="M179.4,109.8l-75.6-58.7c-2.4-1.9-5.6-1.9-8,0l-75.6,58.7c-2,1.6-4.6,1.8-6.9,0.7c-2.3-1.1-3.7-3.3-3.7-5.9v-12
\tc0-2.1,0.9-3.9,2.5-5.2l83.6-64.9c2.4-1.9,5.6-1.9,8,0l83.6,64.9c1.6,1.3,2.5,3.1,2.5,5.2v12c0,2.6-1.4,4.8-3.7,5.9
\tC184,111.6,181.4,111.4,179.4,109.8L179.4,109.8z" class="hoverFill"/>
</svg>
`;

@Component({
  selector: 'ap-dashboard-icon',
  template: CONTENT
})
export class ApDashboardIconComponent {
}

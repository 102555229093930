import ILanduse = Data.BaseData.ILanduse;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum LanduseActions {
  LANDUSE_LOAD = '[landuse] load',
  LANDUSE_LOAD_SUCCESS = '[landuse] load success',
  LANDUSE_LOAD_FAIL = '[landuse] load fail',
}

export class LanduseLoad implements IAction {
  readonly type = LanduseActions.LANDUSE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LanduseLoadSuccess implements IAction {
  readonly type = LanduseActions.LANDUSE_LOAD_SUCCESS;

  constructor(public payload: ILanduse[]) {
  }
}

export class LanduseLoadFail implements IAction {
  readonly type = LanduseActions.LANDUSE_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

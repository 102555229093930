<ap-dynforms *ngIf="(selectedKeys && selectedKeys.length > 0) || showWithoutSelection"
             #extendedInput
             [fieldsets]="fieldSets"
             [defaultValue]="formDefaultValue"
             [tabs]="tabs"
             [fontSize]="14"
             [offsetTop]="5"
             [offsetBottom]="5"
             [darkMode]="true"
             [formValidators]="formValidators"
             [formState]="formConfig ? 'in' : 'out'"
             (initialized)="extendedInputFinish.emit()"
             (formValueChanges)="formValueChanges.emit($event)"
></ap-dynforms>
<div *ngIf="(selectedKeys && selectedKeys.length > 0 || showWithoutSelection)"
     [@fadeInOut] class="ap-pager"
     (@fadeInOut.start)="onFadeInOutStart($event)"
     (@fadeInOut.done)="onFadeInOutDone($event)">
  <div *ngIf="columns && columns.length > 0" class="ap-pagerCell">
    <kendo-dropdownlist *ngIf="!showOnlyValues || type === gridPagerTypes.None"
                        data-cy="ap_pager_selector_1"
                        [class]="'ap-pagerElement ap-dropdown dark-bg'"
                        [data]="columns"
                        [valueField]="'id'"
                        [textField]="'name'"
                        [value]="selectedColumn"
                        [defaultItem]="defaultItem"
                        [itemDisabled]="columnItemDisabled"
                        (valueChange)="selectedColumnChanged($event)">
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <div [aptooltip]="dataItem.tooltip" [perpetual]="true" [attr.disabled]="columnItemDisabled(dataItem)">
          {{dataItem.name}}
        </div>
      </ng-template>
    </kendo-dropdownlist>
    <kendo-dropdownlist *ngIf="type === gridPagerTypes.DropDown"
                        data-cy="ap_pager_selector_2"
                        [class]="'ap-pagerElement ap-dropdown dark-bg'"
                        [itemDisabled]="valueItemDisabled"
                        [valuePrimitive]="false"
                        [textField]="'label'"
                        [valueField]="'id'"
                        [defaultItem]="{id: undefined, label: placeholder | translate}"
                        [data]="values"
                        [(value)]="selectedValue"
                        (valueChange)="selectedValueChanged($event)">
    </kendo-dropdownlist>
    <kendo-numerictextbox *ngIf="type === gridPagerTypes.Decimal"
                          data-cy="ap_pager_numeric_input"
                          style="height: 36px; margin-right: 10px !important;"
                          [(value)]="selectedValue"
                          [placeholder]="placeholder | translate"
                          [format]="decimalFormat"
                          [min]="0"
                          [max]="9999">
    </kendo-numerictextbox>
    <kendo-numerictextbox *ngIf="type === gridPagerTypes.Numeric"
                          data-cy="ap_pager_numeric_input"
                          style="height: 36px; margin-right: 10px !important;"
                          [(value)]="selectedValue"
                          [placeholder]="placeholder | translate"
                          [format]="'n'"
                          [min]="0"
                          [max]="9999"
                          [autoCorrect]="true">
    </kendo-numerictextbox>
    <kendo-datepicker *ngIf="type === gridPagerTypes.Calender"
                      data-cy="ap_pager_date_picker"
                      class="ap-dropdown-bright"
                      style="padding: 2px !important; border: 1px solid #f5f6f8 !important; border-radius: 5px !important;margin-right:10px;"
                      [(value)]="selectedValue"
                      [format]="'dd.MM.yyyy'"
                      [placeholder]="placeholder | translate"
                      (valueChange)="selectedValueChanged($event)">
    </kendo-datepicker>
    <kendo-datepicker *ngIf="type === gridPagerTypes.CalenderMonthly"
                      data-cy="ap_pager_date_picker_month"
                      class="ap-dropdown-bright"
                      style="padding: 2px !important; border: 1px solid #f5f6f8 !important; border-radius: 5px !important;margin-right:10px;"
                      [activeView]="'year'"
                      [bottomView]="'year'"
                      [topView]="'year'"
                      [(value)]="selectedValue"
                      [format]="'MM/yyyy'"
                      [placeholder]="placeholder | translate"
                      (valueChange)="selectedValueChanged($event)">
    </kendo-datepicker>
    <input *ngIf="type === gridPagerTypes.Text"
           data-cy="ap_pager_text_input"
           id="gridPagerInputTextId"
           class="ap-textbox-container"
           style="margin-right:10px;"
           kendoTextBox
           [value]="selectedValue"
           (input)="selectedValueChanged(extractValueFromInputEvent($event))">
    <label for="gridPagerInputTextId"></label>
    <button [disabled]="!isApplyButtonEnabled()"
            data-cy="ap_pager_apply_button"
            class="ap-pagerElement k-button k-primary button-important"
            autofocus (click)="applyButtonClicked()"
            type="submit" kendoButton [primary]="true">{{'Global__TakeOver' | translate}}</button>
    <button *ngIf="formConfig" class="k-button k-primary" (click)="unselect()">
      <span class="k-icon k-i-close"></span>
    </button>
  </div>
  <ng-content></ng-content>
  <div [class]="'ap-error'">
    <ap-responsive-text *ngIf="getErrors().length > 0" [key]="getErrors()[0]"></ap-responsive-text>
  </div>
</div>

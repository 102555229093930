import {Store}                    from '../index';
import {EventEmitter, Injectable} from '@angular/core';
import {
  NetTypes,
  TranslationCreate,
  TranslationSaveSuccess,
  TranslationUpdate
}                                 from 'invoker-transport';
import {
  APP_CONFIGURATION
}                                 from '../../ap-core/config';
import {
  LanguageStore
}                                 from './language.store';
import * as axios                 from 'axios';
import IMissingTranslations = Data.Tracelog.IMissingTranslations;
import {ApSignalrService}         from '../../ap-core/services/ap-signalr.service';

export interface ITranslationState {
  loaded: boolean;
  loading: boolean;
}

@Injectable({providedIn: 'root'})
export class TranslationStore extends Store<ITranslationState> {
  public translationsLoaded = new EventEmitter();
  /**
   * For performance reasons we keep translations in a simple datastructure
   * without observables
   * @private
   */
  private _translationCache: Map<string, string> = new Map<string, string>();

  constructor(public backend: ApSignalrService,
              public languageStore: LanguageStore) {
    super(backend, {
      loaded: false,
      loading: false
    });

    backend.registerObservable(TranslationSaveSuccess).subscribe(d => {
      super.Mutate(s => s.loading, () => false);
      const savedTranslation = d.Data;
      if (savedTranslation) {
        const key = `${savedTranslation.CountryCode}_${savedTranslation.TranslationKey}`;
        this._translationCache.set(key, savedTranslation.translation);
      }
    });
  }

  private static _fillParameter(value: string, params: string[] = []): string {
    for (let pIdx = 0; pIdx < params?.length; pIdx++) {
      if (!params.hasOwnProperty(pIdx)) {
        continue;
      }
      const findResult = '{' + pIdx + '}';
      const replacer = params[pIdx];
      if (!value) {
        continue;
      }
      value = value.split(findResult).join(replacer);
    }
    return value;
  }

  /**
   * 1. request all Translation Versions mapped by Translation ID from the Backend
   * 2. get the Difference from the IndexDB
   * 3. update IndexDB Translations
   * 4. load IndexDB Translations into the State
   */
  public LoadTranslations(key: string): void {
    this._translationCache = new Map<string, string>();
    this.Mutate(s => s.loaded, () => false);
    this.Mutate(s => s.loading, () => true);
    axios.default.get('/translations/' + key).then(d => {
      if (d.status >= 400) {
        this.Mutate(s => s.loaded, () => false);
        this.Mutate(s => s.loading, () => false);
        this.translationsLoaded.emit();
        return;
      }
      for (const k of Object.getOwnPropertyNames(d.data)) {
        this._translationCache.set(k, d.data[k]?.translation);
      }
      this.Mutate(s => s.loaded, () => true);
      this.Mutate(s => s.loading, () => false);
      this.translationsLoaded.emit();
    });
  }


  /**
   * looking for a Translation in the State and returns the Translation in de or en or as Translation Key
   */
  public FindTranslation(value: string, countryCode: string, params: string[] = []): string {
    if (!value || !value.trim()) {
      return '';
    }
    const key = `${countryCode}_${value}`;
    const defaultKey = `${APP_CONFIGURATION.DefaultLanguage}_${value}`;
    let translation = this.GetTranslationByKey(key);
    if (!translation) {
      translation = this.GetTranslationByKey(defaultKey);
      if (!translation) {
        // disabled for performance testing
        // if (APP_CONFIGURATION.WriteMissingTranslations && (value?.includes('_') || value?.includes('kendo'))) {
        //   this._missingTranslations[key] = {
        //     Id: 0,
        //     TranslationKey: value,
        //     CountryCode: countryCode || 'en',
        //     LastUsed: new Date(),
        //     UsedCount: -1
        //   };
        //   this.SendMissingTranslation(this._missingTranslations[key]);
        // }
        return value;
      }
    }
    return TranslationStore._fillParameter(translation, params);
  }

  /**
   * informs the Backend for a missing Translation Key
   */
  public SendMissingTranslation(payload: IMissingTranslations): void {
    // disabled for performance testing
    // super.DispatchBackend(new TranslationMissing([
    //   {Name: 'missing', Type: ApCustomTypes.Data_Tracelog_MissingTranslations, Value: payload}
    // ]));
  }

  /**
   * find the Translation for the Selected Language
   */
  public FindTranslationForSelectedLanguage(value: string, params: string[] = []): string {
    if (!this.languageStore?.SelectedLanguage) {
      return value;
    }
    return this.FindTranslation(value, this.languageStore?.SelectedLanguage?.Key, params);
  }

  /**
   * returns the Translation by the Translation Key
   * it must exist otherwise an Error was thrown
   */
  public GetTranslationByKey(key: string): string {
    if (this._translationCache) {
      return this._translationCache.get(key);
    } else {
      return undefined;
    }
  }

  public UpdateTranslation(translation: any): void {
    super.DispatchBackend(new TranslationUpdate([
      {Name: 'translation', Type: NetTypes.OBJECT, Value: translation}
    ]));
  }

  public CreateTranslation(translation: any): void {
    super.DispatchBackend(new TranslationCreate([
      {Name: 'translation', Type: NetTypes.OBJECT, Value: translation}
    ]));
  }
}

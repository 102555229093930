import {Pipe, PipeTransform} from '@angular/core';
import {ILegendValue}        from '../../../stores/map/map.legend.store';

@Pipe({
  name: 'getLayerValues',
})
export class GetLayerValuesPipe implements PipeTransform {
  transform(value: { [key: string]: ILegendValue[] } | ILegendValue[] | ((...args: string[]) => ILegendValue[]), ...args: string[]): ILegendValue[] {
    if (typeof value === 'function') {
      return value(...args[0]?.Split('_'));
    }
    if (Array.isArray(value)) {
      return value;
    }
    if (typeof args[0] !== typeof '' || !Array.isArray(value[args[0]])) {
      return [];
    }
    return value[args[0]];
  }
}

import {Pipe, PipeTransform}           from '@angular/core';
import {ApDynGridColumnConfigBase}     from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridPropertyColumnConfig} from '../../config/ap-dyn-grid-property-column-config';
import {Trace}                         from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asProperty'
})
export class AsPropertyPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridPropertyColumnConfig {
    return config as ApDynGridPropertyColumnConfig;
  }

}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators}                 from '@angular/forms';
import {ApDynformsConfigTextbox}                 from '../../ap-dynforms/config/ap-dynforms-config-textbox';
import {ApDynformsConfigComboBox}                from '../../ap-dynforms/config/ap-dynforms-config-combobox';
import {ApDynformsConfigFieldset}                from '../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {ApDynformsValidator}                     from '../../ap-dynforms/ap-dynforms-validator';
import {merge}                                   from 'lodash';
import {ApDynformsComponent}                     from '../../ap-dynforms/ap-dynforms.component';
import {MachineStore}                            from '../../stores/docu/machine.store';
import {DriverStore}                             from '../../stores/docu/driver.store';
import {InstrumentStore}                         from '../../stores/docu/instrument.store';
import {FormStore}                               from '../../stores/layout/form.store';
import {TranslationStore}                        from '../../stores/translation/translation.store';
import {LoginStore}                              from '../../stores/login/login.store';
import {Observable, Subscription}                from 'rxjs';
import IMachine = Data.DocuContext.Machine.IMachine;
import IInstrument = Data.DocuContext.Instrument.IInstrument;
import IDriver = Data.DocuContext.Driver.IDriver;

/**
 * Component for editing or creating a machine.
 */
@Component({
  selector: 'ap-machines-edit',
  template: `
    <ap-dynforms [fieldsets]="formConfig" [caption]="caption" [loading$]="saving$">
      <div class="ap-form-actions" dynforms.action>
        <button id="button_machines_edit_cancel"
                type="button"
                class="k-button k-primary ap-form-button-left"
                (click)="onCancelClick()">{{'Global__Cancel' | translate}}
        </button>
        <button id="button_machines_edit_save"
                type="button" [disabled]="!dynForm.form.valid"
                class="k-button k-primary ap-form-button-right"
                (click)="onSubmitClick()">{{'Global__Save' | translate}}
        </button>
      </div>
    </ap-dynforms>`
})

/**
 * Component for editing or creating a machine.
 */
export class ApMachinesEditComponent implements OnInit, OnDestroy {
  @ViewChild(ApDynformsComponent, {static: true}) dynForm: ApDynformsComponent;
  public data: IMachine;
  public isNewMode = false;
  public formConfig: ApDynformsConfigFieldset[];
  public drivers: IDriver[];
  public instruments: IInstrument[];
  public caption: string;
  public saving$: Observable<boolean>;
  private _subscriptions: Array<Subscription> = [];

  constructor(private formStore: FormStore,
              private fb: FormBuilder,
              private driversStore: DriverStore,
              private machinesStore: MachineStore,
              private instrumentStore: InstrumentStore,
              private translationStore: TranslationStore,
              private loginStore: LoginStore) {
    this.drivers = this.driversStore.Listen(s => s.data).getValue();
    this.instruments = this.instrumentStore.Listen(s => s.data).getValue();
    this.saving$ = this.machinesStore.Listen(s => s.saving);
    this._subscriptions.Add(this.machinesStore.Listen(s => s.saved).subscribe(saved => {
        if (saved) {
          this.formStore.closeForm();
        }
      }
    ));
  }

  /**
   * when the component is loaded
   */
  ngOnInit(): void {
    if (!this.data) {
      this.isNewMode = true;
      this.data = {
        DefaultDriver: null,
        DefaultInstrument: null,
        DefaultWidth: 0,
        NameWithSign: null,
        HHID: 0,
        Id: undefined,
        IsDeleted: false,
        Kind: 0,
        Name: '',
        Sign: '',
        FarmId: this.loginStore.SelectedFarmId,
        CreatedAt: null,
        CreatedBy: null,
        ChangedAt: null,
        ChangedBy: null,
        DeletedAt: null,
        DeletedBy: null,
        Version: 0,
      };
    } else {
      this.isNewMode = false;
    }
    this.caption = this.isNewMode ? 'Settings__Lbl_Device_Create' : 'Settings__Lbl_Device_Edit';
    this.formBuilder();
  }

  /**
   * binding of validators
   */
  formBuilder(): void {
    this.formConfig = [
      new ApDynformsConfigFieldset({
        key: 'machines',
        config: [
          new ApDynformsConfigComboBox({
            key: 'Kind',
            value: this.data.Kind,
            valuePrimitive: true,
            valueField: 'Id',
            textField: 'Description',
            label: 'Docu_Ini__Type',
            options: this.machinesStore.Listen(s => s.machineTypes),
            translate: true,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Name',
            label: 'Docu_Ini__Name',
            value: this.data.Name,
            validators: [new ApDynformsValidator({
              validator: Validators.required,
              errorKey: 'Settings__Msg_Vali_Value_Required'
            })]
          }),

          new ApDynformsConfigTextbox({
            key: 'Sign',
            label: 'Docu_Ini__NumberPlate',
            value: this.data.Sign,
            validators: [
              new ApDynformsValidator({
                validator: Validators.maxLength(20),
                errorKey: 'Global_Not_More_Than_20_Characters'
              }),
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),

          new ApDynformsConfigComboBox({
            key: 'DefaultInstrument',
            value: this.data.DefaultInstrument ? this.data.DefaultInstrument.Id : null,
            valuePrimitive: true,
            valueField: 'Id',
            textField: 'Name',
            label: 'Docu_Ini__Tool_Default',
            options: this.instruments,
          }),

          new ApDynformsConfigComboBox({
            key: 'DefaultDriver',
            value: this.data.DefaultDriver ? this.data.DefaultDriver.Id : null,
            valuePrimitive: true,
            valueField: 'Id',
            textField: 'ShortFullName',
            label: 'Docu_Ini__Motorist_Default',
            options: this.drivers,
          })

        ]
      })];
  }

  /**
   * Submitting the changes.
   * Either creating a new object or updating the current.
   */
  onSubmitClick(): void {
    merge(this.data, this.dynForm.form.value);
    this.data.DefaultInstrument = this.instruments.Find(ins => ins.Id === this.dynForm.form.get('DefaultInstrument').value);
    this.data.DefaultDriver = this.drivers.Find(ins => ins.Id === this.dynForm.form.get('DefaultDriver').value);
    if (this.isNewMode) {
      this.machinesStore.createMachine(this.data as IMachine);
    } else {
      this.machinesStore.updateMachine(this.data as IMachine);
    }
  }

  /**
   * Cancel clicked eventHandler - closes form.
   */
  onCancelClick(): void {
    this.formStore.closeForm();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(d => {
      d.unsubscribe();
    });
  }
}

import {IAction, IApInvokerArguments} from '../../interfaces';
import IReadLoggerModel = Data.DocuContext.Logger.IReadLoggerModel;

export enum LoggerActions {
  LOGGER_LOAD = '[logger] load',
  LOGGER_LOAD_SUCCESS = '[logger] load success',
  LOGGER_UPDATE = '[logger] update',
  LOGGER_CREATE = '[logger] create',
  LOGGER_DELETE = '[logger] delete',
  LOGGER_SAVE_SUCCESS = '[logger] save success',
  LOGGER_SAVE_FAIL = '[logger] save fail',
}

export class LoggerLoad implements IAction {
  readonly type = LoggerActions.LOGGER_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoggerLoadSuccess implements IAction {
  readonly type = LoggerActions.LOGGER_LOAD_SUCCESS;

  constructor(public payload: IReadLoggerModel[]) {
  }
}

export class LoggerUpdate implements IAction {
  readonly type = LoggerActions.LOGGER_UPDATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoggerCreate implements IAction {
  readonly type = LoggerActions.LOGGER_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoggerDelete implements IAction {
  readonly type = LoggerActions.LOGGER_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class LoggerSaveSuccess implements IAction {
  readonly type = LoggerActions.LOGGER_SAVE_SUCCESS;

  constructor(public payload: IReadLoggerModel) {
  }
}

export class LoggerSaveFail implements IAction {
  readonly type = LoggerActions.LOGGER_SAVE_FAIL;

  constructor(public payload: string) {
  }
}

import {ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import {IApDynamicPointDataPopup}                 from '../../../ap-map';
import {unset}                                    from 'lodash';
import {ApMenuUrls}                               from '../../../ap-interface';
import {RouterStore}                              from '../../../stores/router/router.store';
import {MapStore}                                 from '../../../stores/map/map.store';

@Component({
  selector: 'ap-sensor-point-popup',
  templateUrl: './ap-sensor-point-popup.component.html',
  styleUrls: ['./ap-sensor-point-popup.component.scss']
})
export class ApSensorPointPopupComponent implements IApDynamicPointDataPopup {
  /**
   * holds all the Point Data in a Label => Value Map
   */
  valueMap: { [p: string]: string } = {};

  /**
   * shows or hides the popup with the use of *ngIf
   */
  visible = false;

  get classifyButtonVisible(): boolean {
    return this.routerStore.CurrentUrl === ApMenuUrls.IMPORT_OVERVIEW;
  }

  /**
   * Initializes this instance
   */
  constructor(private elementRef: ElementRef,
              private changeDetection: ChangeDetectorRef,
              private mapStore: MapStore,
              private routerStore: RouterStore) {
  }

  getKeys(): string[] {
    return Object.keys(this.valueMap);
  }

  /**
   * add a Value to the valueMap
   * @param key the Text that renders as label for the Value
   * @param value the Value represent as String
   */
  addValue(key: string, value: string): void {
    this.valueMap[key] = value;
  }

  /**
   * remove a Value from valueMap
   * @param key the Key of the Value to remove from valueMap
   */
  removeValue(key: string): void {
    unset(this.valueMap, key);
  }

  clear(): void {
    this.valueMap = {};
  }

  /**
   * Gets the component's element for further use in other classes/components.
   */
  getHTMLElement(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  /**
   * Refreshes component data (Change detection)
   */
  refreshComponent(): void {
    this.changeDetection.detectChanges();
  }

  /**
   * Displays the popup
   */
  show(): void {
    this.visible = true;
    this.refreshComponent();
  }

  /**
   * Hides the popup
   */
  hide(): void {
    this.visible = false;
    this.refreshComponent();
  }

  classify(key: string): void {
    this.mapStore.Layers.FungiDetectLayer.classify(key);
  }
}

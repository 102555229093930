import OlStyle from 'ol/style/Style';
import OlStyleStroke        from 'ol/style/Stroke';
import OlStyleFill   from 'ol/style/Fill';
import OlStyleText   from 'ol/style/Text';
import {asString}    from 'ol/color';
import Style                from 'ol/style/Style';

export const color = [44, 50, 55, 1];
export const selectedColor = [176, 203, 31, 1];
export const shadowColor = [255, 255, 255, 0.7];

export function defaultStyle(f): Style | Style[] {
  return [
    new OlStyle({
      stroke: new OlStyleStroke({
        color: asString(color),
        width: 2
      }),
      zIndex: 1
    }),
    new OlStyle({
      stroke: new OlStyleStroke({
        color: asString(shadowColor),
        width: 7
      }),
      zIndex: 0
    })
  ];
}

export function defaultStyleLabelOnly(f): OlStyle {
  return new OlStyle({
    stroke: new OlStyleStroke({
      color: 'rgba(0,0,0,0)',
      width: 0
    }),
    text: new OlStyleText({
      font: '14px/14px Titillium Web',
      text: f.get('label'),
      overflow: true,
      stroke: new OlStyleStroke({
        color: '#fff',
        width: 11
      }),
      fill: new OlStyleFill({
        color: '#000'
      }),
      padding: [-5, -15, -5, -15] // [top, right, bottom, left].
    })
  });
}

export function withCropColorStyle(f): OlStyle {
  return new OlStyle({
    stroke: new OlStyleStroke({
      color: 'rgba(0,0,0,0)',
      width: 0,
    }),
    fill: new OlStyleFill({
      color: f.get('color'),
    })
  });
}

export function selectedStyle(f): Style | Style[] {
  return [
    new OlStyle({
      stroke: new OlStyleStroke({
        color: asString(color),
        width: 2,
        lineCap: 'round'
      }),
      zIndex: 1
    }),
    new OlStyle({
      stroke: new OlStyleStroke({
        color: asString(selectedColor),
        width: 7
      }),
      zIndex: 0
    })
  ];
}

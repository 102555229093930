import IWorktypes = Data.BaseData.IWorktypes;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum WorktypeActions {
  WORKTYPE_LOAD = '[worktype] load',
  WORKTYPE_LOAD_SUCCESS = '[worktype] load success',
}

export class WorktypeLoad implements IAction {
  readonly type = WorktypeActions.WORKTYPE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class WorktypeLoadSuccess implements IAction {
  readonly type = WorktypeActions.WORKTYPE_LOAD_SUCCESS;

  constructor(public payload: IWorktypes[]) {
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getSoilSampleFieldColor'
})
export class GetSoilSampleFieldColorPipe implements PipeTransform {

  transform(value: string | number, args?: any): any {
    const color = '#000000';

    const classVal = value;
    if (classVal === 'A-' || classVal === 1) {
      return '#f00a00';
    } else if (classVal === 'A+' || classVal === 2) {
      return '#d75000';
    } else if (classVal === 'B-' || classVal === 3) {
      return '#d67000';
    } else if (classVal === 'B+' || classVal === 4) {
      return '#d9a000';
    } else if (classVal === 'C-' || classVal === 5) {
      return '#d4dd00';
    } else if (classVal === 'C+' || classVal === 6) {
      return '#a6d400';
    } else if (classVal === 'D-' || classVal === 7) {
      return '#64ab00';
    } else if (classVal === 'D+' || classVal === 8) {
      return '#298600';
    } else if (classVal === 'E' || classVal >= 9) {
      return '#0082ff';
    }
    return color;
  }
}

/**
 * the Base for all Layers in Agriport
 */
import {MapStore} from '../../stores/map/map.store';

export class ApBaseLayer<T> {
  /**
   * the Layer Name must be unique to identify the Layer
   */
  name = '';
  /**
   * the Layer instance Reference the Type was given by the generic Parameter
   */
  layer: T;

  /**
   * create a new Base Layer
   */
  constructor(name: string, olLayer: T, protected mapStore: MapStore) {
    this.name = name;
    this.layer = olLayer;
    (this.layer as any).set('name', name);
  }

  /**
   * get Visibility of the Layer
   */
  get Visibility(): boolean {
    return (this.layer as any).getVisible();
  }

  /**
   * set Visibility of the Layer
   */
  set Visibility(value: boolean) {
    (this.layer as any).setVisible(value);
  }

  /**
   * get the Z Index of the Layer
   */
  get Order(): number {
    return (this.layer as any).getZIndex();
  }

  /**
   * set the Z Index of the Layer
   */
  set Order(value: number) {
    (this.layer as any).setZIndex(value);
  }

  /**
   * get the Extent of all the Features in the Layer
   */
  get Extent(): [number, number, number, number] {
    return this.Source.getExtent();
  }

  /**
   * get the Openlayers Source of the Layer
   */
  get Source(): any {
    return (this.layer as any).getSource();
  }

  /**
   * calling the Style Function of the Layer
   */
  redraw(): void {
    (this.layer as any).setStyle((this.layer as any).getStyle());
  }
}

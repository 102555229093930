import {AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnHideConfig
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {FieldStore}                                                              from '../../stores/farm/field.store';
import {
  ApDynGridPropertyColumnConfig,
  FilterType
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {IGridData, MapViewMode}                                                  from '../../ap-interface';
import {
  GetPermissionPipe
}                                                                                from '../../ap-permission/pipes/get-permission.pipe';
import {filter, map}                                                             from 'rxjs/operators';
import {
  GetRoundNumericService
}                                                                                from '../../ap-utils/service/get-round-numeric.service';
import {
  ApDynGridPagerConfig
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-pager-config';
import {
  ApDynGridDetailsGridConfig
}                                                                                from '../../ap-dyngrids/config/details/ap-dyn-grid-details-grid-config';
import {
  LanguageStore
}                                                                                from '../../stores/translation/language.store';
import {
  TranslationStore
}                                                                                from '../../stores/translation/translation.store';
import {
  CampaignYearStore
}                                                                                from '../../stores/login/campaignyear.store';
import {
  FleetActionAttachmentStore
}                                                                                from '../../stores/docu/fleet.action.attachment.store';
import {combineLatest, Subscription}                                             from 'rxjs';
import {ApDocuUtils}                                                             from '../utils/ap-docu-utils';
import {
  ApGetCropService
}                                                                                from '../../stores/services/ap-get-crop.service';
import {
  ApDateService
}                                                                                from '../../ap-core/services/ap-date-service';
import {
  ApDynComponentComponent
}                                                                                from '../../ap-dyncomponent/ap-dyncomponent.component';
import {
  ApDynGridButtonColumnConfig
}                                                                                from '../../ap-dyngrids/config/ap-dyn-grid-button-column-config';
import {APP_CONFIGURATION}                                                       from '../../ap-core/config';
import {
  TrackLoaderService
}                                                                                from '../services/track.loader.service';
import {MapStore}                                                                from '../../stores/map/map.store';
import {GetRoundNumericPipe}                                                     from '../../ap-utils';
import {
  SettingsStore
}                                                                                from '../../stores/base-data/settings.store';
import {
  MapViewStore
}                                                                                from '../../stores/layout/mapview.store';
import * as moment                                                               from 'moment';
import ICropTypes = Data.BaseData.ICropTypes;
import IFleetActionAttachment = Data.DocuContext.FleetManagement.IFleetActionAttachment;
import ISettings = Data.BaseData.ISettings;
import IField = Data.FieldManagement.IField;

interface IAttachmentGridData extends IGridData<IFleetActionAttachment> {
  Date: Date;
  TimeFrom: string;
  TimeTo: string;
  Duration: string;
  TraversedArea: number;
  WorkedArea: number;
  Application0: string;
  WorkTypeText: string;
}

interface IFieldGridData extends IGridData<IField> {
  NrSNr: string;
  Geom: any;
  Area: any;
  MainCrop: any;
  SecondCrop: any;
  MainCropExpectedYield: any;
  SecondCropExpectedYield: any;
  StrawHarvested: any;
  Attachments: IAttachmentGridData[];
}

@Component({
  selector: 'ap-field-works-overview',
  template: `
    <ap-dyncomponent [caption]="'Admin_Pages__Overview'"
                     [headerIcon]="'ap-icon-field-works'"
                     [items]="items$ | async"
                     [details]="details"
                     [loading$]="loading$"
                     [columns]="columns"
                     [pager]="pager">
    </ap-dyncomponent>
  `,
  styles: []
})
export class ApFieldWorksOverviewComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild(ApDynComponentComponent, {static: true}) dynComponent: ApDynComponentComponent;
  columns: ApDynGridColumnConfigBase[] = [];
  pager = new ApDynGridPagerConfig();
  private mapClick = new EventEmitter<any>();
  loading$ = combineLatest([
    this.fieldStore.Listen(s => s.loading).asObservable(),
    this.fleetActionAttachmentStore.Listen(s => s.loading).asObservable(),
  ]).pipe(map(([l0, l1]) => l0 || l1));

  items$ = combineLatest([
    this.fieldStore.Fields$.asObservable(),
    this.fleetActionAttachmentStore.Listen(s => s.data).asObservable()
  ]).pipe(
    filter(([f, a]) => !!f && !!a),
    map(([field, attachments]) => field.Convert<IFieldGridData>((f) => {
        const geom = this.fieldStore.getCurrentFieldGeom(f);
        const area = geom && geom.AdminArea > 0 ? geom.AdminArea / 10000 : '-';
        const mainCrop: ICropTypes = this.cropService.getCrop(f, true, 'object');
        const subCrop: ICropTypes = this.cropService.getCrop(f, false, 'object');
        return {
          Id: f.Id,
          SourceItem: f,
          NrSNr: this.fieldStore.getFieldNumberAndSubNumber(f),
          Geom: geom,
          Area: area === '-' ? area : this.roundNumericService.roundAsNumber(area),
          MainCrop: mainCrop ? mainCrop.Description : '-',
          SecondCrop: subCrop ? subCrop.Description : '-',
          MainCropExpectedYield: mainCrop ? this.roundNumericService.roundAsNumber(mainCrop.ExpectedYield) : '-',
          SecondCropExpectedYield: subCrop ? this.roundNumericService.roundAsNumber(subCrop.ExpectedYield) : '-',
          StrawHarvested: f.FieldCrops[0] ? f.FieldCrops[0].StrawHarvested : false,
          Attachments: attachments.FindAll((a) => a.FieldId === f.Id && a.Status === 1)
            .Convert<IAttachmentGridData>((a) => {
              const from = this.dateService.toFarmDateFromUtcGetJsDate(new Date(a.Attachment.From));
              const to = this.dateService.toFarmDateFromUtcGetJsDate(new Date(a.Attachment.To));
              return {
                Id: a.Attachment.Id,
                SourceItem: a,
                Date: from,
                TimeFrom: moment(from).format('HH:mm'),
                TimeTo: moment(to).format('HH:mm'),
                Duration: moment(ApDocuUtils.getDurationOfSlices(a.Slices).toDate()).format('HH:mm'),
                TraversedArea: a.Slices.Reduce((acc, val) => acc + val.BruttoArea, 0),
                WorkedArea: a.Slices.Reduce((acc, val) => acc + val.NettoArea, 0),
                WorkTypeText: this.translationStore.FindTranslationForSelectedLanguage(a.Attachment?.WorkType?.Description),
                Application0: a.Attachment.Application && a.Attachment.Application[0]
                  ? a.Attachment.Application[0].Key : '-'
              };
            })
        };
      })
    ));
  details: ApDynGridDetailsGridConfig;
  private onSelectionChanged: Subscription = null;
  private _subscriptions: Subscription[] = [];
  private _settings: ISettings;

  constructor(private campaignYearStore: CampaignYearStore,
              private cropService: ApGetCropService,
              private dateService: ApDateService,
              private fieldStore: FieldStore,
              private fleetActionAttachmentStore: FleetActionAttachmentStore,
              private languageStore: LanguageStore,
              private permissionPipe: GetPermissionPipe,
              private roundNumericService: GetRoundNumericService,
              private roundNumericPipe: GetRoundNumericPipe,
              private translationStore: TranslationStore,
              private settingsStore: SettingsStore,
              private mapStore: MapStore,
              private mapViewStore: MapViewStore,
              private trackLoaderService: TrackLoaderService) {
  }

  ngOnInit(): void {
    this._subscriptions.push(this.campaignYearStore.Listen(s => s.selectedYear).subscribe(
      () => this.fleetActionAttachmentStore.loadFleetActionAttachmentsByModified(true)));
    this.onSelectionChanged = this.dynComponent.SelectedItems$.subscribe(d => this.selectionChange(d as IFieldGridData[]));
    this._subscriptions.Add(this.mapStore.Layers.TrackLayer.onExtentLoadFinish.subscribe(() => {
      this.mapStore.Layers.TrackLayer.zoomToExtent();
    }));
    this._subscriptions.push(this.settingsStore.FirstSetting$.subscribe(s => this._settings = s));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
    if (this.onSelectionChanged) {
      this.onSelectionChanged.unsubscribe();
    }
    this.mapStore.Layers.Clear();
    this.trackLoaderService.clear();
  }

  ngAfterContentInit(): void {
    this.gridBuilder();
    this.mapClick.subscribe(item => {
      this._showTrackOnMap(item);
    });
  }

  gridBuilder(): void {
    this.columns = this.columns = [
      new ApDynGridPropertyColumnConfig({
        title: 'Global__NumberAbbr',
        field: 'SourceItem.FieldNumber',
        hide: true,
        filterable: true,
        filterType: FilterType.NUMBER,
        cssClass: 'right',
        headerFilterable: true,
        width: 90
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Global__FieldPartShort',
        field: 'SourceItem.FieldSubnumber',
        hide: true,
        filterable: true,
        filterType: FilterType.NUMBER,
        cssClass: 'right',
        headerFilterable: true,
        width: 90
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Global__NumberAbbr',
        field: 'NrSNr',
        width: 75,
        filterable: true,
        headerFilterable: true,
        hide: new ApDynGridColumnHideConfig({
          mapHide: false,
          selfHide: this.mapViewStore.Listen(s => s.mode).pipe(map((m) => m !== MapViewMode.NORMAL)),
        })
      }),
      new ApDynGridPropertyColumnConfig(
        {
          title: 'Global__FieldName',
          field: 'SourceItem.FieldName',
          filterable: true,
          headerFilterable: true,
          sortIndex: 0,
          sortDesc: false,
          width: 160,
          cssClass: 'left'
        }),
      new ApDynGridPropertyColumnConfig({
        title: 'Global__Area_Unit',
        field: 'Area',
        headerFilterable: true,
        width: 90,
        cssClass: 'right', filterType: FilterType.NUMBER,
        pipes: [
          {
            pipe: this.roundNumericPipe,
            args: [
              this._settings.DigitsAfterDecimalPoint,
            ]
          }
        ]
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'MainCrop',
        field: 'MainCrop',
        filterable: true,
        headerFilterable: true,
        width: 160
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'MainCropExpectedYield',
        title: 'Global__YieldUnit',
        hide: true,
        headerFilterable: true,
        width: 80,
        cssClass: 'right', filterType: FilterType.NUMBER,
        pipes: [
          {
            pipe: this.roundNumericPipe,
            args: [
              this._settings.DigitsAfterDecimalPoint,
            ]
          }
        ]
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'StrawHarvested',
        title: 'Nutrients_Pages__StrawRemoved',
        hide: true,
        checkbox: true,
        width: 80
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'SecondCrop',
        title: 'SecondCrop',
        hide: true,
        filterable: true,
        headerFilterable: true,
        width: 160
      }),
      new ApDynGridPropertyColumnConfig({
        field: 'SecondCropExpectedYield',
        title: 'Global__YieldUnit',
        hide: true,
        headerFilterable: true,
        width: 80,
        cssClass: 'right', filterType: FilterType.NUMBER,
        pipes: [
          {
            pipe: this.roundNumericPipe,
            args: [
              this._settings.DigitsAfterDecimalPoint,
            ]
          }
        ]
      }),
    ];

    this.details = new ApDynGridDetailsGridConfig({
      hide: (dataItem: IFieldGridData) => dataItem.Attachments.length === 0,
      checkboxDisabled: true,
      field: 'Attachments',
      columns: [
        new ApDynGridPropertyColumnConfig({
          title: 'Docu_Ini__Date',
          field: 'Date',
          format: this.languageStore.SelectedLanguage.DateFormat,
          sortIndex: 0,
          width: 90
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Docu_Ini__From',
          field: 'TimeFrom',
          width: 80
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Docu_Ini__To',
          field: 'TimeTo',
          // format: 'HH:mm',
          width: 80
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Docu_Ini__Duration',
          field: 'Duration',
          // format: 'HH:mm',
          width: 80
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'SourceItem.Attachment.Machine.Name',
          title: 'Base__Engine',
          headerFilterable: true,
          filterable: true,
          width: 140
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'SourceItem.Attachment.Machine.Sign',
          title: 'Docu_Ini__NumberPlate',
          headerFilterable: true,
          hide: true,
          width: 100
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'SourceItem.Attachment.Instrument.Name',
          title: 'Docu_Ini__Tool',
          headerFilterable: true,
          filterable: true,
          width: 115,
          hide: true
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'WorkTypeText',
          title: 'Docu_Ini__WorkType',
          headerFilterable: true,
          filterable: true,
          width: 115,
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'DriverName',
          title: 'Docu_Ini__Motorist',
          hide: true,
          headerFilterable: true,
          filterable: true,
          width: 135
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Global_Traversed_Area',
          field: 'TraversedArea',
          hide: true,
          unit: this.translationStore.FindTranslationForSelectedLanguage('Global__Unit_ha'),
          width: 135,
          tooltip: item => `${this.roundNumericService.round(item['TraversedArea'], -1)} ${this.translationStore.FindTranslationForSelectedLanguage('Global__Unit_ha')}`,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [this._settings.DigitsAfterDecimalPoint],
            }
          ],
        }),
        new ApDynGridPropertyColumnConfig({
          title: 'Global_Worked_Area',
          field: 'WorkedArea',
          hide: true,
          unit: this.translationStore.FindTranslationForSelectedLanguage('Global__Unit_ha'),
          width: 135,
          tooltip: item => `${this.roundNumericService.round(item['WorkedArea'], -1)} ${this.translationStore.FindTranslationForSelectedLanguage('Global__Unit_ha')}`,
          pipes: [
            {
              pipe: this.roundNumericPipe,
              args: [this._settings.DigitsAfterDecimalPoint],
            }
          ],
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'Application0',
          title: 'Docu_Ini__Medium',
          headerFilterable: true,
          width: 130
        }),
        new ApDynGridPropertyColumnConfig({
          field: 'SourceItem.Attachment.WorkType.Comment',
          title: 'Global__Comment',
          headerFilterable: true,
          hide: true
        }),
        new ApDynGridButtonColumnConfig(
          {
            callback: this.mapClick,
            imageUrl: APP_CONFIGURATION.ButtonZoomToMapImage,
            selected: this._isItemSelected.bind(this),
            tooltip: 'Settings__Msg_Loading_Track_Button'
          })
      ]
    });
  }

  private _isItemSelected(dataItem): boolean {
    const action = dataItem['SourceItem'] as IFleetActionAttachment;
    return !!this.trackLoaderService.selectedSlices.Find(_ => _?.id === action?.Id?.toString());
  }

  private _showTrackOnMap(dataItem): void {
    const action = dataItem['SourceItem'] as IFleetActionAttachment;
    this.trackLoaderService.loadByFleetAction(action);
    if (this.mapViewStore.getMapViewMode() === MapViewMode.HIDE && this.trackLoaderService.selectedSlices.Any()) {
      this.mapViewStore.showMapView();
    }
  }

  private selectionChange(d: IFieldGridData[]): void {
    this.fieldStore.changeSelectedField(d.Convert(e => e.Id.toString()));
  }
}

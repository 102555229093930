import {ApDynformsConfigBase, ApDynformsControltype} from './ap-dynforms-config-base';

/**
 * configuration for placeholder control
 */
export class ApDynformsConfigPlaceholder extends ApDynformsConfigBase<any> {
  controlType = ApDynformsControltype.Placeholder;

  constructor(options: {
    key?: string,
    label?: string} = {}) {
    super(options);
  }
}

export enum LEGEND_IDS {
  INVALID,
  CROP_TYPE,
  SOIL_GROUP,
  NUTRIENT_DISTRIBUTION,
  TRACK,
  NEED,
  NUTRIENT_PLANNING,
  N_LOG_INTERPOLATION,
  PP_LOG_INTERPOLATION,
  LOG,
  NDI,
  TASK_MANAGEMENT,
  N_PLANNING,
  N_UPTAKE
}

import {IStateStore}      from '../../ap-interface';
import {Store}            from '../index';
import {Injectable}       from '@angular/core';
import {
  CropTypeSensorMapLoad,
  CropTypeSensorMapLoadSuccess
}                         from '../../../../projects/invoker-transport/src/lib/actions/base-data/croptypesensormap.action';
import {CropTypeStore}    from './crop.types.store';
import {ApSignalrService} from '../../ap-core/services/ap-signalr.service';
import ICropTypeSensorMap = Data.BaseData.ICropTypeSensorMap;

interface ICropTypeSensorMapStore extends IStateStore<ICropTypeSensorMap> {
}

@Injectable({providedIn: 'root'})
export class CropTypeSensorMapStore extends Store<ICropTypeSensorMapStore> {
  constructor(public backend: ApSignalrService,
              public cropTypeStore: CropTypeStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(CropTypeSensorMapLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
  }

  /**
   *  load crop type sensor map from Backend to the Store
   */
  public loadCropTypeSensorMap(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new CropTypeSensorMapLoad([]));
  }

  public getCropTypeMapId(cropTypeDetailsId: number): number {
    const crop = this.cropTypeStore.getDefaultCroptype(cropTypeDetailsId);
    if (crop) {
      const resultCrop = this.cropTypeStore.Listen(s => s.data).getValue().Find(_ => _.CropKey === crop?.CropKey && _.FarmId === -1);
      if (resultCrop) {
        return resultCrop.SensorTypeId ?? -1;
      }
    }
    return -1;
  }
}

<ap-dynforms (apSubmit)="apSubmit.emit()"
             [caption]="caption"
             [defaultValue]="formDefaultValue"
             [fieldsets]="fieldSets"
             [formAsyncValidators]="formAsyncValidators"
             [formValidators]="formValidators"
             [headerIcon]="headerIcon"
             [headerSvg]="headerSvg"
             [loading$]="loading$"
             [hidden]="fieldSets.length === 0 && !tabs"
             [tabs]="tabs">
  <ng-content dynforms.before select="[dynforms.before]"></ng-content>
  <ng-content dynforms.after select="[dynforms.after]"></ng-content>
  <ng-content dynforms.action select="[dynforms.action]"></ng-content>
</ap-dynforms>
<ap-dyngrids (create)="create.emit()"
             (edit)="edit.emit($event)"
             (initialized)="gridIsInitialized()"
             (selectionChange)="selectionChange.emit($event)"
             *ngIf="columns && columns.length !== 0"
             [canCreate]="canCreate"
             [canSearch]="canSearch"
             [caption]="caption"
             [columns]="columns"
             [details]="details"
             [errorKeys]="errorKeys"
             [exportFileName]="exportFileName"
             [groupable]="groupable"
             [headerIcon]="headerIcon"
             [headerSvg]="headerSvg"
             [items]="items"
             [loading$]="loading$"
             [pager]="pager"
             [pdfExportTitle]="pdfExportTitle"
             [reorderable]="reorderable"
             [rowSelected]="rowSelected"
             [selectable]="selectable"
             [sortBySelection]="sortBySelection"
             [xlsExportFileName]="xlsExportFileName">
</ap-dyngrids>

import {Injectable}               from '@angular/core';
import {Store}                    from '../index';
import {BurgerMenuItem}           from '../../ap-interface/interfaces/map/burger-menu-item';
import {MapLayerControlComponent} from '../../entry-components/burger-menu-entry-components/map-layer-control.component';
import 'ts-tooling';
import {ApDynamicComponentModel}  from '../../entry-components/ap-dynamic-component-loader/ap-dynamic-component-loader.component';
import {ApSignalrService}         from '../../ap-core/services/ap-signalr.service';

interface IBurgerMenuState {
  data: BurgerMenuItem[];
}

@Injectable({providedIn: 'root'})
export class BurgerMenuStore extends Store<IBurgerMenuState> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [
        {
          title: 'AgriConnect_Map',
          content: new ApDynamicComponentModel(MapLayerControlComponent),
        },
      ]
    });
  }

  addControl(title: string, component: any, data?: any): void {
    super.Mutate(s => s.data, (d) =>
      d.Insert(d.length, {
        title,
        content: new ApDynamicComponentModel(component, data),
      }));
  }

  removeControl(title): void {
    super.Mutate(s => s.data, (d) => d.RemoveAll(i => i.title === title));
  }
}

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
}                                    from '@angular/core';
import {Observable, Subscription}    from 'rxjs';
import {ChartComponent, LegendTitle} from '@progress/kendo-angular-charts';
import {
  IMainSecondCropChartData
}                                    from '../../../ap-interface/interfaces/charts/ap-main-second-crop-chart-data.interface';
import {SettingsStore}               from '../../../stores/base-data/settings.store';
import {StatisticStore}              from '../../../stores/statistic/statistic.store';
import {TranslationStore}            from '../../../stores/translation/translation.store';
import {GetRoundNumericService}      from '../../../ap-utils/service/get-round-numeric.service';

@Component({
  selector: 'ap-main-second-crop-chart',
  templateUrl: 'ap-main-second-crop-chart.component.html',
  styleUrls: ['ap-main-second-crop-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ApMainSecondCropChartComponent implements AfterContentInit, OnDestroy {
  @Input()
  data: IMainSecondCropChartData[];
  @Input()
  title: string;
  @Input()
  selection$: Observable<{ text: string, value: number }[]>;
  @Input()
  selected$: Observable<{ text: string, value: number }>;
  @Output()
  selectedChange = new EventEmitter<{ text: string, value: number }>();
  public settings$ = this.settingsStore.FirstSetting$;
  public centerUnit: string;
  public strokeColor = '#212529';
  public legendTitle: LegendTitle = {
    text: this.translationStore.FindTranslationForSelectedLanguage('Global__Legend'),
    font: '18px Arial',
  };
  @ViewChild('chart', {static: false})
  private chart: ChartComponent;
  private _subscriptions: Array<Subscription> = [];

  constructor(private settingsStore: SettingsStore,
              private roundNumericService: GetRoundNumericService,
              private translationStore: TranslationStore,
              private statisticStore: StatisticStore) {
    this.centerUnit = this.translationStore.FindTranslationForSelectedLanguage('Base__UnitHa');
  }

  public ngAfterContentInit(): void {
    this._subscriptions.push(this.selectedChange.subscribe(
      (v) => this.statisticStore.selectFromSelection(v.value))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(d => d.unsubscribe());
  }

  public tooltipTextColor(data: any): string {
    return this._isWhiteColor(data.color) ? '#000000' : data.color;
  }

  public labelContent = (e: any) => {
    const value = this.roundNumericService.roundAsNumber(e.value, this.settingsStore.FirstSetting.DigitsAfterDecimalPoint);
    const unit = this.translationStore.FindTranslationForSelectedLanguage('Base__UnitHa');
    const percentPerValue = value / this.getCenterCount(this.data);
    const percent = this.roundNumericService.roundAsNumber(percentPerValue * 100, this.settingsStore.FirstSetting.DigitsAfterDecimalPoint);
    return `${e.category}: \n ${value} ` + unit + ` (${percent}%)`;
  }

  public getCenterCount(d: any): number {
    if (!d) {
      return 0;
    }
    let values = 0;
    for (const item of d) {
      if (item.prio === 0) {
        for (const val of item.data) {
          values += val.value;
        }
      }
    }
    return this.roundNumericService.roundAsNumber(values, this.settingsStore.FirstSetting.DigitsAfterDecimalPoint);
  }

  private _isWhiteColor(color: string): boolean {
    return color === '#ffffff' || color === '#fff' || color === 'white';
  }
}

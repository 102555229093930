<div class="ap-toolbar-control" style="justify-content: flex-end">
    <kendo-combobox #comboBox
                    style="width:100%; max-width: 300px; color: #f5f6f8;"
                    [class.dark-bg] = "true"
                    [data]="farmFilter"
                    [textField]="'FarmName'"
                    [valueField]="'Id'"
                    [filterable]="true"
                    [value]="farm"
                    (valueChange)="onItemClicked($event)"
                    (filterChange)="filterChange($event)"
                    (focus)="focus()"
                    (blur)="blur(comboBox)"
                    [aptooltip]="getToolTip()"
                    [perpetual]="true">
    </kendo-combobox>
</div>

import {Pipe, PipeTransform}                                     from '@angular/core';
import {ApDynGridPagerWizardButton, ApDynGridPagerWizardSection} from '../../config/ap-dyn-grid-pager-wizard-config';
import {Trace}                                                   from '../../../debug-utils/ApplicationTracer';

@Pipe({
  name: 'asWizardButton'
})
export class AsWizardButtonPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridPagerWizardSection): ApDynGridPagerWizardButton {
    return config as ApDynGridPagerWizardButton;
  }

}

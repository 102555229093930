import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {Pixel}                                               from 'ol/pixel';

@Component({
  selector: 'ap-map-tooltip',
  template: `
    <div #apMapTooltip *ngIf="data?.length>0" class="ap-map-tooltip k-tooltip k-widget" [ngStyle]="{'left': '5px', 'top': '5px'}">
      <ap-responsive-text [text]="data" [multiline]="true" [style]="style"></ap-responsive-text>
    </div>
  `,
})
/**
 * Component for generic map tooltip
 */
export class ApMapTooltipComponent {
  @ViewChild('apMapTooltip') public tooltipRef: ElementRef;
  public data = '';
  public style = '';
  public left = '0px';
  public top = '0px';

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  /**
   * Shows a map tooltip at the given pixel/position with the given content.
   * If the content is empty the tooltip is hidden
   */
  public show(tooltipContent: string, style: string = ''): void {
    if (!tooltipContent || tooltipContent?.length <= 0) {
      this.data = undefined;
      return;
    }
    this.data = tooltipContent;
    this.style = style;
    this.changeDetectorRef.detectChanges();
  }
}

import {IStateStore}                                                    from '../../ap-interface/interfaces/store';
import {Store}                                                          from '../index';
import {Injectable}                                                     from '@angular/core';
import {NetTypes, NewsAdd, NewsLoad, NewsLoadSuccess} from 'invoker-transport';
import {NotifyStore}                                                    from '../dialog/notify.store';
import INews = Data.Dashboard.INews;
import IApValidationResult = Data.Api.Validation.IApValidationResult;
import {
  ApSignalrService
}                                                                       from '../../ap-core/services/ap-signalr.service';

interface INewsStore extends IStateStore<INews> {
}

@Injectable({providedIn: 'root'})
export class NewsStore extends Store<INewsStore> {
  constructor(public backend: ApSignalrService,
              private notifyStore: NotifyStore) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
    });

    backend.registerObservable(NewsLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(NewsAdd).subscribe(d => {
      const newNews = {
        DisplayTimeout: 5,
        Level: Data.Api.Validation.ApValidationLevel.News,
        ErrorKey: 'Global_New_News'
      } as IApValidationResult;
      this.notifyStore.addMessage(newNews);
      super.Mutate(s => s.data, o => {
        o.Add(d.Data);
        return o;
      });
    });
  }

  public loadNews(region: string): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new NewsLoad([
      {Name: 'region', Type: NetTypes.STRING, Value: region}
    ]));
  }
}

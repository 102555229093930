import { Pipe, PipeTransform }    from '@angular/core';
import {ModalDialogButtonDisable} from '../../../ap-interface/interfaces/ap-modaldialog-data.interface';

@Pipe({
  name: 'apModalDialogButtonDisable'
})
export class ApModalDialogButtonDisablePipe implements PipeTransform {

  transform(value: ModalDialogButtonDisable, formValid: boolean): any {
    switch (value) {
      case ModalDialogButtonDisable.Never:
        return false;
      case ModalDialogButtonDisable.Always:
        return true;
      case ModalDialogButtonDisable.FormInvalid:
        return !formValid;
    }
    return false;
  }

}
